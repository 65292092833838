import React from "react";
import "./Card.css";
import TransactionNumber from "../../TransactionNumber";
import { useNavigate } from "react-router-dom";
import BackButton from "../../BackButton";
type CardHeaderProps = {
  children: React.ReactNode;
  wrapperClass?: string;
  headerActions?: React.ReactNode;
  key?: string;
  transactionNumber?: number | string;
  isBackButtonEnabled?: boolean;
};
export const CardHeader = (props: CardHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={`card-header  reusable-card-header list-card-header p-0 border-0 ${
        props.wrapperClass ? props.wrapperClass : ""
      }`}
      key={props.key}
    >
      {" "}
      <div className="d-flex justify-content-center align-items-center">
        {props.isBackButtonEnabled === true && (
          <BackButton onClick={() => navigate(-1)} />
        )}
        <h1 className="mt-0">{props.children}</h1>
      </div>
      {props.transactionNumber ? (
        <TransactionNumber
          transactionNumber={Number(props.transactionNumber)}
        />
      ) : null}
      <div className="reusable-card-header-actions">
        {props.headerActions ? props.headerActions : ""}
      </div>
    </div>
  );
};
