import React from "react";
import DetailsTableContainer from "../../../common/components/detailsPage/DetailsTableContainer";
import EmptyReport from "../../../common/components/EmptyReport";
import { Cell, Row } from "react-table";
import { TableData } from "../../../../types";

type Props = {
  columns: any[];
  data: TableData[];
  className?: string;
  navigation?: (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => void;
  haveColumnClassName: boolean;
};
function BalanceTable({
  columns,
  data,
  className,
  navigation,
  haveColumnClassName,
}: Props) {
  return (
    <div className="table-wrapper">
      <DetailsTableContainer
        columns={columns}
        data={data}
        className={`table report-table soa-table balance-report-table ${className}`}
        hiddenColumns={["action"]}
        navigation={navigation}
        haveColumnClassName={haveColumnClassName}
      >
        <EmptyReport heading="No transactions found!" alt="Empty Report" />
      </DetailsTableContainer>
    </div>
  );
}

export default BalanceTable;
