import React from "react";
import { CreateUserRoleValues, ObjectType } from "../../../../../../../types";
type Props = {
  inputs: CreateUserRoleValues;
  formErrors: ObjectType;
  backendError: { [key: string]: any };
  inputChangeHandler: (e: any) => void;
  grantFullAccessHandler: (e: any) => void;
};
const RoleFormInputs = (props: Props) => {
  return (
    <section className="role-management w-100">
      <div className="role-data">
        <div className="control-group">
          <label htmlFor="name" className="required">
            Role Name{" "}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={props.inputs.name || ""}
            className={
              props.formErrors.name || props.backendError.name
                ? "form-control error"
                : "form-control"
            }
            placeholder="Enter role name"
            onChange={props.inputChangeHandler}
            maxLength={50}
          />
          <span className="error">
            {props.formErrors.name || props.backendError.name}
          </span>
        </div>
        <div className="control-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={props.inputs.description || ""}
            className="form-control"
            placeholder="Enter a short description"
            rows={2}
            onChange={props.inputChangeHandler}
          ></textarea>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="grantaccess"
            name="grant_full_access"
            onChange={props.grantFullAccessHandler}
            checked={props.inputs.grant_full_access}
          />
          <label className="form-check-label" htmlFor="grantaccess">
            Grant full access (Similar to admin user)
          </label>
        </div>
      </div>
    </section>
  );
};

export default RoleFormInputs;
