import { toast } from "react-toastify";
import { useAppDispatch } from "../../../app/hooks";
import { ErrorImg } from "../../../assets/images";
import { proformaDetails, deleteProformaDetails } from "./proformaSlice";
import { joinTransactionNumber } from "../../../helpers/prefixHelper";
import DeleteModal from "../../common/components/DeleteModal";
import { useEffect, useRef } from "react";

type Props = {
  deleteInvoiceId: number;
  deleteInvoiceItemId: string;
  // page: number;
  // itemsPerPage: number;
  // dateSortOrder: string;
  refreshInvoices: (action: string) => void;
  organizationId: number;
};
const DeleteProformaInvoice = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteInvoiceId]);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteProformaDetails({
          deleteProformaId: props.deleteInvoiceId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (
          Object.keys(deleteRoleResponse).length &&
          !("error" in deleteRoleResponse)
        ) {
          props.refreshInvoices("delete");
          isDeletedRef.current = true;
          $(".close-btn").click();
          // await dispatch(
          //   invoiceList({
          //     page: props.page,
          //     itemsPerPage: props.itemsPerPage,
          //     dateSortOrder: props.dateSortOrder,
          //     orgId: props.organizationId,
          //     orgIds: [],
          //     statusList: [],
          //     customerList: [],
          //   })
          // );
          toast.success("Invoice deleted successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          $(".close-btn").click();
          // toast.error("Delete in invoice action failed!", {
          //   icon: ({ theme, type }) => (
          //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
          //   ),
          //   toastId: "delete-invoice-failed-error",
          //   closeButton: false,
          //   className: "toast-error",
          //   position: "top-center",
          // });
        }
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete Proforma Invoice!"
      subHeading="Are you sure you want to delete the proforma invoice"
      transactionName={joinTransactionNumber(props.deleteInvoiceItemId)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteProformaInvoice;
