import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";
import {
  deleteSubscriptionDetails,
} from "../subscriptionSlice";

type Props = {
  deleteSubscriptionId: number;
  deleteSubscriptionName: string;
  deleteActionType: string;
  refreshPlan: (action: string) => void;
  organizationId: number;
};
const SubscriptionDelete = (props: Props) => {

  const dispatch = useAppDispatch();
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const subscriptionModalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteRoleResponseAction = await dispatch(
      deleteSubscriptionDetails({
        deleteSubscriptionId: props.deleteSubscriptionId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (
        Object.keys(deleteRoleResponse).length &&
        !("error" in deleteRoleResponse)
      ) {
        props.refreshPlan(props.deleteActionType);
        $(".close-btn").click();
        toast.success("Subscription deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
  };

  return (
    <DeleteModal
      heading={`Delete Subscription!`}
      subHeading={`Are you sure you want to delete the subscription`}
      transactionName={joinTransactionNumber(props.deleteSubscriptionName)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={subscriptionModalSubmitHandler}
    />
  );
};
export default SubscriptionDelete;
