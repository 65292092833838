import { ObjectType } from "../../../../types";
import CancelIcon from "../../../../assets/images/cancel-icon.svg";
import "../../../../index.css";
import './Button.css';

type Props = {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  style?: ObjectType;
  type: "button" | "submit" | "reset" | undefined;
};
export const RedCrossCancelButton = (props: Props) => {
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={props.className}
      hidden={props.isHidden}
      disabled={props.isDisabled}
      style={props.style}
    >
      <span className="red-cross-cancel-btn">
        <img src={CancelIcon} alt="Cancel" />
      </span>
    </button>
  );
};
