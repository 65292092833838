import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { InfoIconImg } from "../../../../assets/images";
import {
  create,
  getCoaTreeView,
  fetchAccountType,
  getCoaListView,
  accountTreeModalSelector,
  getCoaTreeViewInAddAccount,
  checkAccountNameExist,
  amountCalculation,
  checkGLCodeExists,
} from "../coaSlice";
import {
  currenciesSelector,
  latestCurrencyRateSelector,
  exchangeApiStatusSelector,
} from "../../../common/commonSlice";
import "../ChartOfAccounts.css";
import { COA, ObjectType } from "../../../../types";
import Select from "react-select";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import { toast } from "react-toastify";
import CustomTooltips from "../../../common/components/Tooltip";
import InputPrefix from "../../../common/components/formPrefixInputField/InputPrefix";
import ExchangeRate from "../../../common/components/ExchangeRate";
import {
  getFormatedDate,
  splitNumberByDecimalPoint,
} from "../../../../helpers/helper";
import Loader from "../../components/Loader";
import { getRoundOffAmount } from "../../../../helpers/roundOffHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../hooks/useCommon";
import AddAccountTreeView from "./AddAccountTreeView";
import InfoTooltip from "../../../common/components/InfoTooltip";
import { getFiscalYear } from "../../../../helpers/fiscalYearHelper";

type Props = {
  page: number;
  itemsPerPage: number;
  accountName: string;
  nameSort: string;
  typeSort: string;
  balanceSort: string;
  superParentId: number;
};
const Create: React.FC<Props> = ({
  page,
  itemsPerPage,
  accountName,
  nameSort,
  typeSort,
  balanceSort,
  superParentId,
}) => {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const currencies = useAppSelector(currenciesSelector);
  const accountsTreeData = useAppSelector(accountTreeModalSelector);
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const initialValues = {
    account_name: "",
    description: "",
    opening_balance: "",
    gl_start_code: "",
    currency_id: 0,
    is_category: false,
  };
  let interval: any = null;
  const [inputs, setInputs] = useState<COA>(initialValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [parentId, setParentId] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState("");
  const [bankCurrencyCode, setBankCurrencyCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [parentGlStartCode, setParentGlStartCode] = useState("");
  const [parentGlEndCode, setParentGlEndCode] = useState("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState("");
  const [isCategory, setIsCategory] = useState(false);
  const [isGLCodeAvailable, setGLCodeAvailable] = useState(true);
  const [isAccNameAvailable, setAccNameAvailable] = useState(true);
  const [isCategoryChecked, setIsCategoryChecked] = useState(false);
  const [isAccountToggle, setIsAccountToggle] = useState(false);
  const exchangeRef = useRef<any>([]);

  const fiscal_year_date = getFiscalYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "current"
  );

  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;

  useEffect(() => {
    fetchAccountTreeViewInModal();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (
      inputs.gl_start_code?.toString() !== parentGlStartCode?.toString() &&
      inputs.gl_start_code?.toString() !== parentGlEndCode?.toString()
    ) {
      glCodeExist(inputs.gl_start_code, signal);
    }
  }, [inputs.gl_start_code]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getOpeningBalanceConversion(inputs.opening_balance, signal);
    return () => {
      controller.abort();
    };
  }, [inputs.opening_balance, exchangeRateValues.id, inputs.currency_id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    isAccountNameExist(inputs.account_name.trim(), "account_name", signal);
    return () => {
      controller.abort();
    };
  }, [inputs.account_name]);

  useEffect(() => {
    inputs.currency_id &&
      setInputs({ ...inputs, conversion_id: exchangeRateValues.id });
  }, [exchangeRateValues.id]);

  useEffect(() => {
    if (isCategoryChecked) {
      setInputs({
        ...inputs,
        opening_balance: "",
        gl_start_code: "",
        currency_id: 0,
      });
    }
  }, [isCategoryChecked]);

  useEffect(() => {
    let inputData = { ...inputs };
    if (inputData.opening_balance) {
      const updatedCurrencyCode = getCurrencyCode(inputs.currency_id);
      const updatedDecimals = decimalPlaceOfCurrency(
        updatedCurrencyCode,
        orgCurrencyList
      );
      inputData.opening_balance = getRoundOffAmount(
        Number(inputData.opening_balance),
        updatedDecimals
      );
      setInputs(inputData);
    }
  }, [inputs.currency_id]);

  const getCurrencyCode = (id: number) => {
    const code = currencies?.filter((curr) => {
      return curr.id === id;
    })[0]?.code;
    return code ? code : "";
  };

  /**
   * API call for fetching COA API response
   */
  const fetchAccountTreeViewList = async () => {
    await dispatch(
      getCoaTreeView({ name: "", orgId: currentUserInfo.organization_id })
    );
  };
  /**
   * API call for getting opening balance conversion
   */
  const getOpeningBalanceConversion = async (
    opening_balance: number | string | undefined,
    signal?: Object
  ) => {
    if (inputs.currency_id && opening_balance) {
      const responseAction = await dispatch(
        amountCalculation({
          orgId: currentUserInfo.organization_id,
          values: {
            conversion_date: getFormatedDate(new Date()),
            currency_id: inputs.currency_id,
            organization_id: currentUserInfo.organization_id,
            conversion_id: exchangeRateValues.id,
            amount: opening_balance,
            org_currency_id: currentUserInfo.organization_currency,
          },
          signal: signal ? signal : {},
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setConvertedAmount(response.converted_amount);
        }
      }
    }
  };
  /**
   * Function to get account tree view response in create modal
   */
  const fetchAccountTreeViewInModal = async () => {
    await dispatch(getCoaTreeViewInAddAccount(currentUserInfo.organization_id));
  };
  /**
   * Input change handler in Add Account form fields
   */
  const inputChangeHandler = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    const isCategoryValue = e.target.checked;

    if (name === "gl_start_code") {
      const glCodeRegex = /^[0-9\b]+$/;
      if (value === "" || glCodeRegex.test(value)) {
        setInputs((values) => ({ ...values, [name]: value }));
      }
    } else if (name === "opening_balance") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      const openingBalanceRegex = /^-?([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || openingBalanceRegex.test(value)) {
          setInputs((values) => ({
            ...values,
            [name]: value === "" ? 0 : value,
          }));
        }
      }
    } else if (name === "is_category") {
      setInputs((values) => ({ ...values, [name]: isCategoryValue }));
      setIsCategoryChecked(isCategoryValue);
    } else if (name === "account_name") {
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  /**
   * Function to round off default input elements after out of focus.
   */
  const handleItemOnBlur = (
    name: string,
    amount: number,
    currencyCode: string
  ) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
      if (name === "opening_balance" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        setInputs({ ...inputs, [name]: roundedAmount });
      }
    }
  };
  /**
   * Function to check whether account name exist or not
   */
  const isAccountNameExist = async (
    name: string,
    field: string,
    signal?: AbortSignal
  ) => {
    if (name) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkAccountNameExist({
            accountName: name,
            orgId: currentUserInfo.organization_id,
            editAccountId: 0,
            signal: signal,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.name_available === true) {
              formErrors.account_name && delete formErrors.account_name;
              setFormErrors({ ...formErrors });
              setAccNameAvailable(true);
            } else {
              setFormErrors({
                ...formErrors,
                account_name: "Account name already exists!",
              });
              setAccNameAvailable(false);
            }
          }
        }
      }, 1000);
    } else {
      formErrors.account_name && delete formErrors.account_name;
      setFormErrors({ ...formErrors });
      setAccNameAvailable(true);
    }
  };
  const glCodeExist = async (glCode: string | number, signal: AbortSignal) => {
    const responseAction = await dispatch(
      checkGLCodeExists({
        accountId: 0,
        orgId: currentUserInfo.organization_id,
        glCode: glCode,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.is_available === true) {
          formErrors.account_name && delete formErrors.account_name;
          formErrors.gl_start_code && delete formErrors.gl_start_code;
          setFormErrors({ ...formErrors });
          setGLCodeAvailable(true);
        } else {
          setFormErrors({
            ...formErrors,
            gl_start_code: "Ledger code already exists!",
          });
          setGLCodeAvailable(false);
        }
      }
    }
  };
  /**
   * Add account form submission handler
   */
  const handleCreateAccount = (e: any) => {
    e.preventDefault();
    const errors = validateCreateAccount(inputs);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#add-account-button").addClass("add-account-form-section-disable");
      createAccountSubmitHandler({ parentId: parentId, value: inputs });
    }
  };
  /**
   * Validate add account form field
   */
  const validateCreateAccount = (values: COA) => {
    const errors: ObjectType = {};

    if (!values.account_name?.trim()) {
      errors.account_name = "Please enter your account name";
    }
    if (!isAccNameAvailable) {
      errors.account_name = "Account name already exists!";
    }
    if (
      !values.currency_id &&
      selectedBranch.slice(0, 20) === "Assets/Bank Accounts" &&
      !values.is_category
    ) {
      errors.currency = "Please enter currency";
    }
    const glCodeRegex = /^[0-9\b]+$/;
    if (
      values.gl_start_code?.trim() &&
      !glCodeRegex.test(values.gl_start_code)
    ) {
      errors.gl_start_code = "Please enter valid ledger code";
    } else if (
      values.gl_start_code?.trim() &&
      values.gl_start_code.length !== 6
    ) {
      errors.gl_start_code = "The ledger code should be 6 digits";
    } else if (
      values.gl_start_code?.trim() &&
      (values.gl_start_code <= parentGlStartCode ||
        values.gl_start_code >= parentGlEndCode)
    ) {
      errors.gl_start_code = "The ledger code is out of the range";
    } else if (!isGLCodeAvailable) {
      errors.gl_start_code = "Ledger code already exists!";
    }
    return errors;
  };
  /**
   * Create COA
   */
  const createAccountSubmitHandler = async (data: {
    parentId: number;
    value: COA;
  }) => {
    setIsLoading(true);
    let accountInputs: any = { ...inputs };
    let key: keyof typeof accountInputs;
    for (key in accountInputs) {
      if (accountInputs[key] === "" && key === "opening_balance") {
        accountInputs[key] = 0;
      }
    }
    let inputData = JSON.parse(JSON.stringify(accountInputs));
    if (inputData.gl_start_code === "") {
      delete inputData.gl_start_code;
    }
    let updatedData = {
      parentId: parentId,
      value: inputData,
      orgId: currentUserInfo.organization_id,
    };
    const responseAction = await dispatch(create(updatedData));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setIsLoading(false);
        $(".cancel-button").click();
        await dispatch(
          getCoaListView({
            page: page,
            itemsPerPage: itemsPerPage,
            accountName: accountName,
            nameSort: nameSort,
            typeSort: typeSort,
            balanceSort: balanceSort,
            orgId: currentUserInfo.organization_id,
          })
        );
        fetchAccountTreeViewList();
        fetchAccountTreeViewInModal();
        toast.success("Account created successfully!", {
          toastId: "account-create-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        setIsLoading(false);
        if (
          response.error?.detail?.[0]?.msg === "GL Code already exists" ||
          response.error?.detail?.[0]?.msg === "Invalid GL Code"
        ) {
          $("#add-account-button").removeClass(
            "add-account-form-section-disable"
          );
          setFormErrors({
            ...formErrors,
            gl_start_code: "The ledger code already exist",
          });
        } else {
          $(".cancel-button").click();
          $("#add-account-button").removeClass(
            "add-account-form-section-disable"
          );
        }
      }
    }
  };

  /**
   * Add account modal close handler
   */
  $(document).ready(function () {
    $(".cancel-button")
      .off()
      .on("click", function () {
        setFormErrors({});
        setInputs(initialValues);
        setIsSelected(false);
        setSelectedNode("");
        $("#add-account-button").removeClass(
          "add-account-form-section-disable"
        );
      });
  });

  /**
   * set the branch after selecting on the account name
   */
  const selectedBranchHandler = async (id: number) => {
    const responseAction = await dispatch(
      fetchAccountType({
        accountId: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setIsSelected(true);
      setParentId(id);
      setIsAccountToggle(!isAccountToggle);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setSelectedBranch(response.account_type);
        setIsCategory(response.is_category);
        const parentAccount = accountsTreeData.filter(
          (account) => account.id === response.super_parent_id
        )[0];
        setParentGlStartCode(parentAccount.gl_start_code);
        setParentGlEndCode(parentAccount.gl_end_code);
      } else if ("error" in response) {
        setSelectedBranch("Failed to fetch the branch name");
      }
    }
    setInputs((prevValue) => {
      return {
        ...prevValue,
        opening_balance: initialValues.opening_balance,
        currency_id: initialValues.currency_id,
      };
    });
  };

  useEffect(() => {
    setInputs(initialValues);
    setIsCategoryChecked(false);
  }, [isAccountToggle]);

  const handleCollapseAndSelect = () => {
    setExpanded([]);
  };

  const resetConvertedAmount = () => {
    setConvertedAmount("");
  };

  const currencyOptions = currencies?.map((curr) => {
    return {
      label: curr.code,
      value: curr.id,
    };
  });

  const onChangeCurrency = (e: any) => {
    const name = e.label;
    const value = e.value;
    setInputs((values) => ({ ...values, currency_id: value }));
    setConvertedAmount("");
    setBankCurrencyCode(e.label);
  };

  const dateFormatHandler = (date: string | null) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };
  const openingBalanceToolTip = currentUserInfo.is_ledger_org_closed
    ? `Opening Balance is as of ${dateFormatHandler(
        fiscal_year_date.startDate
      )}`
    : `Opening Balance is as of ${dateFormatHandler(
        currentUserInfo.migration_date
      )}`;

  const openingBalanceDate = currentUserInfo.is_ledger_org_closed
    ? fiscal_year_date.startDate
    : currentUserInfo.migration_date;

  return (
    <div>
      <div
        className="modal add-modal fade"
        id="addAccountModal"
        tabIndex={-1}
        aria-labelledby="addAccountModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered delete-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header position-relative"></div>
              <div className="modal-body">
                <div className="tree-section">
                  <div className="popup-header">Select Branch</div>
                  <div className="alert-msg d-flex align-items-center">
                    <img src={InfoIconImg} alt="info icon" />
                    <p className="mb-0 mx-2">
                      Please select a branch to create a new account
                    </p>
                  </div>
                  <AddAccountTreeView
                    selectedBranchHandler={selectedBranchHandler}
                    resetConvertedAmount={resetConvertedAmount}
                    selectedNode={selectedNode}
                    expanded={expanded}
                    isCreate={true}
                    superParentId={superParentId}
                    editAccountId={0}
                    parentId={0}
                    onCloseModal={() => {}}
                    refreshList={() => {}}
                    setAccountTypeHandler={() => {}}
                  />
                </div>
                <div
                  className={
                    isSelected
                      ? "form-section mb-4"
                      : "form-section-disable mb-4"
                  }
                >
                  <div className="popup-header">Add Account</div>
                  <div className="form-labels">
                    {isSelected ? selectedBranch : "No branch selected"}
                  </div>
                  <div className="alert-msg d-flex align-items-center">
                    <img src={InfoIconImg} alt="info icon" />
                    <p className="mb-0 mx-2">
                      Accounts will be created under the selected branch
                    </p>
                  </div>
                  <form
                    className="add-account-form-wrapper"
                    onSubmit={handleCreateAccount}
                  >
                    <div className={formErrors.account_name ? "error" : "mb-0"}>
                      <label
                        htmlFor="exampleInputName"
                        className="form-label form-labels required"
                      >
                        Account Name
                      </label>
                      <input
                        type="text"
                        className={
                          formErrors.account_name
                            ? "form-control error"
                            : "form-control"
                        }
                        id="exampleInputName"
                        aria-describedby="accountName"
                        name="account_name"
                        value={inputs.account_name || ""}
                        placeholder="Enter account name"
                        onChange={inputChangeHandler}
                        disabled={isSelected ? false : true}
                        maxLength={40}
                      />
                      <span className="error">{formErrors.account_name}</span>
                    </div>
                    <div className="checkbox-wrap account-category-checkbox mb-4">
                      <input
                        name="is_category"
                        className="form-check-input"
                        type="checkbox"
                        checked={inputs.is_category}
                        disabled={!isSelected || !isCategory ? true : false}
                        id="addAccountCategory"
                        onChange={inputChangeHandler}
                      />
                      <label
                        className="form-check-label fw-normal"
                        htmlFor="addAccountCategory"
                      >
                        Add as an account category
                      </label>
                      <CustomTooltips
                        placement="right"
                        title="Add as an account category"
                      >
                        <a
                          href="#"
                          className="info-btn position-relative"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img src={InfoIconImg} width="16" alt="info icon" />
                        </a>
                      </CustomTooltips>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="accountDescription"
                        className="form-label form-labels"
                      >
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="accountDescription"
                        name="description"
                        value={inputs.description || ""}
                        placeholder="Add description"
                        onChange={inputChangeHandler}
                        disabled={isSelected ? false : true}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="ledgerCode"
                        className="form-label form-labels"
                      >
                        Ledger Code{" "}
                        {isSelected
                          ? "(Range between " +
                            parentGlStartCode +
                            " - " +
                            parentGlEndCode +
                            ")"
                          : null}
                      </label>
                      <input
                        type="text"
                        className={
                          formErrors.gl_start_code
                            ? "form-control error"
                            : "form-control"
                        }
                        id="ledgerCode"
                        name="gl_start_code"
                        maxLength={6}
                        value={inputs.gl_start_code || ""}
                        placeholder="Enter ledger code"
                        onChange={inputChangeHandler}
                        disabled={
                          !isSelected || isCategoryChecked ? true : false
                        }
                      />
                      <span className="error">{formErrors.gl_start_code}</span>
                    </div>
                    {(selectedBranch.slice(0, 20) === "Assets/Bank Accounts" ||
                      selectedBranch.slice(0, 20) ===
                        "Liabilities/Credit Card") &&
                      !inputs.is_category && (
                        <div className="mb-3">
                          <label
                            htmlFor="currency"
                            className="form-label form-labels required"
                          >
                            Currency
                          </label>
                          <Select
                            name="currency"
                            options={currencyOptions}
                            placeholder="Choose currency"
                            className={
                              formErrors.currency
                                ? "form-select custom-select accounts-select error"
                                : "form-select custom-select accounts-select"
                            }
                            value={
                              inputs.currency_id == null ||
                              inputs.currency_id == 0
                                ? ""
                                : {
                                    label: currencies?.filter((curr) => {
                                      return curr.id == inputs.currency_id;
                                    })[0]?.code,
                                    value: inputs.currency_id,
                                  }
                            }
                            styles={customSelectStyle}
                            isDisabled={
                              !isSelected || isCategoryChecked ? true : false
                            }
                            onChange={onChangeCurrency}
                            isSearchable={true}
                          />
                          <span className="error">{formErrors.currency}</span>
                        </div>
                      )}
                    {(selectedBranch.slice(0, 20) === "Assets/Bank Accounts" ||
                      selectedBranch.slice(0, 20) ===
                        "Liabilities/Credit Card") &&
                      !inputs.is_category && (
                        <div
                          className={
                            "mb-3" +
                            (inputs.currency_id !== 0
                              ? ""
                              : " hide-base-currency")
                          }
                        >
                          <div className="opening-balance-wrap">
                            <label
                              htmlFor="accountBalance"
                              className="form-label form-labels"
                              style={{ width: "200px" }}
                            >
                              Opening Balance{" "}
                              <span className="sub-label">{`(Bank Currency)`}</span>
                            </label>
                            <span className="opening-balance-exchange-rate">
                              <ExchangeRate
                                currencyId={inputs.currency_id}
                                currencyCode={bankCurrencyCode}
                                editId={""}
                                conversionId={""}
                                conversionCurrencyId={
                                  currentUserInfo.organization_currency
                                }
                                conversionDate={
                                  openingBalanceDate
                                    ? openingBalanceDate
                                    : getFormatedDate(new Date())
                                }
                                newConversionDate={
                                  openingBalanceDate
                                    ? openingBalanceDate
                                    : getFormatedDate(new Date())
                                }
                                ref={exchangeRef}
                                module="COA"
                              />
                            </span>
                          </div>
                          <InputPrefix
                            inputType="text"
                            className="form-control"
                            wrapperClass="coa-opening-balance-field"
                            id="accountBalance"
                            name={
                              (selectedBranch.slice(0, 20) ===
                                "Assets/Bank Accounts" ||
                                selectedBranch.slice(0, 20) ===
                                  "Liabilities/Credit Card") &&
                              !inputs.is_category
                                ? "opening_balance"
                                : ""
                            }
                            value={
                              inputs?.opening_balance
                                ? inputs?.opening_balance
                                : ""
                            }
                            placeholder="Enter opening balance"
                            onChange={inputChangeHandler}
                            disabled={
                              !isSelected || isCategoryChecked ? true : false
                            }
                            onBlur={() =>
                              handleItemOnBlur(
                                "opening_balance",
                                inputs?.opening_balance
                                  ? Number(inputs?.opening_balance)
                                  : 0,
                                getCurrencyCode(inputs.currency_id)
                              )
                            }
                            prefix={
                              inputs.currency_id === null ||
                              inputs.currency_id === 0
                                ? ""
                                : currencies?.filter((curr) => {
                                    return curr.id === inputs.currency_id;
                                  })[0]?.code
                            }
                            error=""
                          />
                        </div>
                      )}
                    {inputs.currency_id !==
                      currentUserInfo.organization_currency &&
                      !inputs.is_category && (
                        <div
                          className={
                            inputs.currency_id !==
                            currentUserInfo.organization_currency
                              ? "mb-0"
                              : "mb-3"
                          }
                        >
                          <label
                            htmlFor="accountBalance"
                            className="form-label form-labels"
                          >
                            Opening Balance{" "}
                            <span className="sub-label">{`(Base Currency)`}</span>
                            <InfoTooltip
                              title={openingBalanceToolTip}
                              placement={"top"}
                            />
                          </label>
                          <InputPrefix
                            inputType="text"
                            className="form-control"
                            wrapperClass="coa-opening-balance-field"
                            id="accountBalance"
                            name={
                              (selectedBranch.slice(0, 20) ===
                                "Assets/Bank Accounts" ||
                                selectedBranch.slice(0, 20) ===
                                  "Liabilities/Credit Card") &&
                              !inputs.is_category
                                ? ""
                                : "opening_balance"
                            }
                            value={
                              inputs?.currency_id
                                ? convertedAmount
                                : inputs?.opening_balance
                                ? inputs?.opening_balance
                                : ""
                            }
                            placeholder="Enter opening balance"
                            onChange={inputChangeHandler}
                            disabled={
                              inputs.currency_id !==
                                currentUserInfo.organization_currency &&
                              (selectedBranch.slice(0, 20) ===
                                "Assets/Bank Accounts" ||
                                selectedBranch.slice(0, 20) ===
                                  "Liabilities/Credit Card")
                                ? true
                                : false
                            }
                            onBlur={() =>
                              handleItemOnBlur(
                                "opening_balance",
                                inputs?.opening_balance
                                  ? Number(inputs?.opening_balance)
                                  : 0,
                                getCurrencyCode(
                                  currentUserInfo.organization_currency
                                )
                              )
                            }
                            prefix={
                              currencies?.filter((curr) => {
                                return (
                                  curr.id ===
                                  currentUserInfo.organization_currency
                                );
                              })[0]?.code
                            }
                            error=""
                          />
                        </div>
                      )}
                    <div className="row mt-4 pt-2">
                      <div className="col">
                        <div
                          className="form-button-wrapper w-100"
                          id="add-account-button"
                        >
                          <button
                            className="save-button"
                            type="submit"
                            disabled={
                              !isSelected ||
                              !isAccNameAvailable ||
                              !isGLCodeAvailable ||
                              exchangeRateAPiStatus
                                ? true
                                : false
                            }
                          >
                            Add Account
                          </button>
                          <button
                            className="cancel-button"
                            data-bs-dismiss="modal"
                            type="button"
                            onClick={handleCollapseAndSelect}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default Create;
