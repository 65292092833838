import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import {
  CreateTaxRateValues,
  CreateTaxValues,
  ObjectType,
  TaxConfigurations,
  TaxGroupValues,
} from "../../../../types";
import {
  createNewTax,
  createNewTaxRate,
  deleteTaxItem,
  editTaxItem,
  editTaxRateItem,
  createNewTaxGroup,
  getTaxRateList,
  getTaxRateGroupList,
  getTaxePayable,
  getTaxeReceivable,
  getTaxesList,
  updateNewTaxGroup,
  removeTaxGroup,
  deleteTaxRateItem,
  validateTaxGroup,
} from "./taxRateAPI";

export const taxesList = createAsyncThunk(
  "taxList",
  async (data: { page: number; itemsPerPage: number; orgId: number }) => {
    return await getTaxesList(data);
  }
);

export const taxesRateList = createAsyncThunk(
  "taxRateList",
  async (data: { orgId: number }) => {
    return await getTaxRateList(data);
  }
);
export const taxRateGroupList = createAsyncThunk(
  "taxRateGroupList",
  async (data: { page: number; itemsPerPage: number; orgId: number }) => {
    return await getTaxRateGroupList(data);
  }
);

export const createTax = createAsyncThunk(
  "createTax",
  async (data: { values: CreateTaxValues; orgId: number }) => {
    return await createNewTax(data);
  }
);

export const createTaxRate = createAsyncThunk(
  "createTax",
  async (data: { values: CreateTaxRateValues; orgId: number }) => {
    return await createNewTaxRate(data);
  }
);

export const editTax = createAsyncThunk(
  "editTax",
  async (data: { taxId: number; values: CreateTaxValues; orgId: number }) => {
    return await editTaxItem(data);
  }
);

export const editTaxRate = createAsyncThunk(
  "editTax",
  async (data: {
    taxId: number;
    values: CreateTaxRateValues;
    orgId: number;
  }) => {
    return await editTaxRateItem(data);
  }
);

export const deleteTax = createAsyncThunk(
  "deleteTax",
  async (data: { taxId: number; orgId: number }) => {
    return await deleteTaxItem(data);
  }
);

export const deleteTaxRate = createAsyncThunk(
  "deleteTaxRate",
  async (data: { taxId: number; orgId: number }) => {
    return await deleteTaxRateItem(data);
  }
);

export const taxPayable = createAsyncThunk(
  "taxList/payable",
  async (data: { orgId: number }) => {
    return await getTaxePayable(data);
  }
);

export const taxReceivable = createAsyncThunk(
  "taxList/receivable",
  async (data: { orgId: number }) => {
    return await getTaxeReceivable(data);
  }
);

export const createTaxGroup = createAsyncThunk(
  "taxGroup/create",
  async (data: { orgId: number; values: TaxGroupValues }) => {
    return await createNewTaxGroup(data);
  }
);

export const editTaxGroup = createAsyncThunk(
  "taxGroup/edit",
  async (data: {
    taxGroupId: number;
    orgId: number;
    values: TaxGroupValues;
  }) => {
    return await updateNewTaxGroup(data);
  }
);

export const deleteTaxGroup = createAsyncThunk(
  "taxGroup/delete",
  async (data: { taxGroupId: number; orgId: number }) => {
    return await removeTaxGroup(data);
  }
);

export const taxGroupBackendValidation = createAsyncThunk(
  "taxGroup/validation",
  async (data: {
    titleValidation: boolean;
    groupName: string;
    taxIds: number[];
    orgId: number;
    signal?: AbortSignal;
  }) => {
    return await validateTaxGroup(data);
  }
);

interface TaxConfigurationState {
  taxes: TaxConfigurations;
  taxPayableList: ObjectType[];
  taxReceivableList: ObjectType[];
  taxesRateLists: ObjectType[];
  // taxPayableList: {
  //   tc_list_payable: ObjectType[];
  // };
  // taxReceivableList: {
  //   tc_list_receivable: ObjectType[];
  // };
}

const initialState: TaxConfigurationState = {
  taxes: {
    tax_configuration: [],
    total: 0,
    page_size: 10,
    pagination: {
      next: null,
      previous: null,
    },
  },
  taxPayableList: [{}],
  taxReceivableList: [{}],
  taxesRateLists: [{}],
  // taxPayableList: {
  //   tc_list_payable: [{}],
  // },
  // taxReceivableList: {
  //   tc_list_receivable: [{}],
  // },
};

export const taxConfigurationSlice = createSlice({
  name: "taxConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(taxesList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxes = payload;
        }
      })
      .addCase(taxPayable.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxPayableList = payload.tc_list_payable;
        }
      })
      .addCase(taxReceivable.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxReceivableList = payload.tc_list_receivable;
        }
      })
      .addCase(taxesRateList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxesRateLists = payload;
        }
      });
  },
});

export default taxConfigurationSlice.reducer;

export const taxesSelector = (state: RootState) => state.taxConfiguration.taxes;
export const taxPayableSelector = (state: RootState) =>
  state.taxConfiguration.taxPayableList;
export const taxReceivableSelector = (state: RootState) =>
  state.taxConfiguration.taxReceivableList;
export const taxRateSelector = (state: RootState) =>
  state.taxConfiguration.taxesRateLists;
