import InvoicePreviewHeader from "./InvoicePreviewHeader";
import "./InvoicePreview.css";
function InvoicePreview() {
  return (
    <div className="">
      <div className="card card-dashboard w-100">
        <InvoicePreviewHeader />
      </div>
    </div>
  );
}

export default InvoicePreview;
