import IconButton from "@mui/material/IconButton";
import { colors } from "../../constants/colors";

export const CopyButton = (props: { onClick: () => void; color: string }) => {
  return (
    <IconButton className="copy-text-btn" onClick={props.onClick}>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.11 3.111V.778c0-.43.35-.778.779-.778h9.333c.43 0 .778.348.778.778v10.889c0 .43-.348.777-.778.777h-2.333v2.333a.78.78 0 0 1-.783.779H.783A.779.779 0 0 1 0 14.777L.002 3.89c0-.43.35-.779.783-.779h2.326zM1.558 4.667 1.556 14h7.777V4.667H1.557zm3.11-1.556h6.222v7.778h1.555V1.556H4.666V3.11z"
          fill={props.color === "red" ? colors.tomato : colors.ceruleanBlue}
          fillRule={"nonzero"}
        />
      </svg>
    </IconButton>
  );
};
