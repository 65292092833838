import React, { useRef, useState } from "react";
import ListHeader from "./ListHeader";
import TaxRateTableContainer from "./taxRateTable/TaxRateTableContainer";
import Pagination from "../../../../common/components/table/Pagination";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import DeleteTax from "../Delete";

const List = () => {
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const [deleteTaxId, setDeleteTaxId] = useState(-1);
  const listRef = useRef<any>([]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const deleteListData = (id: number) => {
    setDeleteTaxId(id);
  };
  const refreshList = () => {
    listRef.current.fetchTaxRatelist();
  };
  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader refreshList={refreshList} />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <TaxRateTableContainer
            deleteListData={deleteListData}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteTax deleteTaxId={deleteTaxId} handleModalClose={refreshList} />
    </>
  );
};

export default List;
