import React from "react";
import { OrgSmall } from "../../../assets/images";
import "../styles/OrgTag.css";

function OrgTag({ organization }: { organization: string }) {
  return (
    <span className="org-tag me-2">
      <img src={OrgSmall} alt="organization" width={18} height={18} />
      <span className="orgname">{organization}</span>
    </span>
  );
}

export default OrgTag;
