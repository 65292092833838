function PermissionMatrixHeader() {
  return (
    <div className="col section-header">
      <h3>Permissions</h3>
      <ul className="legends">
        <li className="full-access">Full access</li>
        <li className="part-access">Partial access</li>
        <li className="no-access">No access</li>
      </ul>
    </div>
  );
}
export default PermissionMatrixHeader;
