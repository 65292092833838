import React, { useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { currenciesSelector, currencyDropdown } from "../commonSlice";
import Select from "react-select";
import { SelectCustomStyleCustomer } from "./SelectCustomStyleCustomer";
import useCommonData from "../../hooks/useCommon";

type Props = {
  label: string;
  currencyId: number | string;
  onChange: (data: { name: string; value: number; code?: string }) => void;
  isDisabled: boolean;
  dropPosition?: 'auto' | 'top' | 'bottom';
  error: string;
  wrapperClass?: string;
};

function CurrencySelect(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const currencies = useAppSelector(currenciesSelector);

  useEffect(() => {
    if (currentUserInfo.organization_id) getCurrencyList();
  }, [currentUserInfo.organization_id]);
  /**
   * Fetch all currency list
   */
  const getCurrencyList = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };
  /**
   * Get the list of all currencies
   */
  const currencyListOptions = currencies.map((currency, index) => ({
    label: currency.code,
    value: currency.id
  }));
  /*
   * Get the currency name in the selection dropdown
   */
  const getCurrencyName = currencies
    .filter((currency) => currency.id === props.currencyId)
    .map((currency) => ({
      label: currency.code,
      value: currency.id
    }));
  return (
    <>
      <label htmlFor="c_currency" className="required">
        {props.label}
      </label>
      <Select
        name="currency_id"
        id="c_currency"
        className={`form-select custom-select currency-select ${
          props.error ? "error" : ""
        } ${props.wrapperClass}`}
        value={getCurrencyName}
        options={currencyListOptions}
        placeholder="Choose currency"
        isSearchable={true}
        onChange={(e) => {
          if (e?.value) {
            props.onChange({
              name: "currency_id",
              value: e.value,
              code: e.label
            });
          }
        }}
        styles={SelectCustomStyleCustomer}
        isDisabled={props.isDisabled}
        menuPlacement={props.dropPosition? props.dropPosition: "bottom"}
      />
      <span className="error">{props.error}</span>
    </>
  );
}

export default CurrencySelect;
