import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormInputField } from "./formInputField/FormInputField";

interface ComponetRef {
  getData: () => { value: string; isValid: boolean };
}
const EmailInput: ForwardRefRenderFunction<ComponetRef, any> = (props, ref) => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMeassage] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      getData: getData,
    }),
    [email, isValid]
  );

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  useEffect(() => {
    if (props.error) {
      setIsValid(false);
      setErrorMeassage(props.error);
    }
  }, [props.error]);

  useEffect(() => {
    if (props.onChange) props.onChange();
  }, [props.onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    props.handleChange({ name: props.name, value });
  };

  const getData = () => {
    let isValid = validateEmail();
    setIsValid(isValid);
    return { value: email, isValid };
  };

  const validateEmail = () => {
    const emailRegex = /^(?:\w+[-.])*\w{1,64}@(?:\w+[-.])*\w+\.\w{2,3}$/i;
    if (
      (props.isRequired && email === "") ||
      (email && !emailRegex.test(email))
    ) {
      setErrorMeassage("Please enter valid email address");
      return false;
    } else {
      setErrorMeassage("");
      return true;
    }
  };
  return (
    <FormInputField
      type="text"
      name={props.name}
      id={props.id}
      className={isValid ? "form-control" : "form-control error"}
      placeholder="Enter valid email address"
      label={props.label}
      value={email}
      onChange={handleChange}
      maxLength={200}
      isRequired={props.isRequired}
      error={errorMessage}
    />
  );
};

export default forwardRef(EmailInput);
