import { SvgIconProp } from "../../../../types";

export const OrgIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : ""}
      style={{ margin: props.margin ? props.margin : "" }}
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule={"evenodd"}>
        <path d="M0 0h18v18H0z" />
        <path
          d="M15.75 14.25h1.5v1.5H.75v-1.5h1.5V3A.75.75 0 0 1 3 2.25h7.5a.75.75 0 0 1 .75.75v11.25h3v-6h-1.5v-1.5H15a.75.75 0 0 1 .75.75v6.75zm-12-10.5v10.5h6V3.75h-6zm1.5 4.5h3v1.5h-3v-1.5zm0-3h3v1.5h-3v-1.5z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
