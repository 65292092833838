import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import StopResumeModal from "../../../common/components/StopResumeModal";
import { changeRecurringInvoiceStatus } from "../recurringInvoiceSlice";
import { toast } from "react-toastify";

type Props = {
  invoiceId: number;
  profileName: string;
  triggerAction: string;
  refreshRInvoices: () => void;
  organizationId: number;
};
const RIStopResume = (props: Props) => {
  const dispatch = useAppDispatch();
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };
  
    /**
   * Handler to resume the recurring invoices
   */
    const resumeHandler = async (userId: number) => {
      $("#delete-button").addClass("customer-form-section-disable");
      const responseAction = await dispatch(
        changeRecurringInvoiceStatus({
          id: userId,
          status: "Active",
          orgId: props.organizationId
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (responseAction) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          props.refreshRInvoices();
          $(".close-btn").click();
          toast.success("Resumed Recurring Invoice successfully!", {
            toastId: "resume-recurring-invoice-success",
            closeButton: false,
            position: "top-center",
          });
        }else if ("error" in response) {
          $(".close-btn").click();
        }
      } 
    };
  
    /**
     *  Handler to stop the recurring invoices
     */
    const stopHandler = async (userId: number) => {
      $("#delete-button").addClass("customer-form-section-disable");
      const responseAction = await dispatch(
        changeRecurringInvoiceStatus({
          id: userId,
          status: "Stopped",
          orgId: props.organizationId
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (responseAction) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          props.refreshRInvoices();
          $(".close-btn").click();
          toast.success("Stopped recurring invoice successfully!", {
            toastId: "stop-recurring-invoice-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in response) {
          $(".close-btn").click();
        }
      }
    };
  
  return (
    <StopResumeModal
    heading={`${props.triggerAction} Recurring Invoice!`}
    subHeading={`Are you sure you want to ${props.triggerAction.toLowerCase()} the recurring invoice`}
    transactionName={joinTransactionNumber(props.profileName)}
    modalCancelHandler={modalCancelHandler}
    modalSubmitHandler={() => {props.triggerAction === "Stop"
    ? stopHandler(props.invoiceId)
    : resumeHandler(props.invoiceId)}}
    triggerAction={props.triggerAction}
  />
  );
};
export default RIStopResume;