import { useEffect, useState } from "react";
import { ObjectType } from "../../../../../types";
import AccordianItem from "../../../../common/components/detailsPage/AccordianItem";
import { CustomFieldTable } from "../customFieldTable/customFieldTable";

type Props = {
  purchasesModuleList: {
    Bills: ObjectType[];
    "Debit Notes": ObjectType[];
    "Payments Made": ObjectType[];
  };
};
export const PurchaseCustomFields = (props: Props) => {
  const [customFieldModuleList, setCustomFieldModuleList] = useState(
    props.purchasesModuleList
  );

  useEffect(() => {
    setCustomFieldModuleList(props.purchasesModuleList);
  }, [props.purchasesModuleList]);

  const billsFields = customFieldModuleList.Bills
    ? customFieldModuleList.Bills?.filter(
        (field) => field.is_deleted === false
      ).map((field) => {
        return {
          show: {
            id: field.id,
            field_name: field.field_name,
            mandatory_field: field.mandatory_field ? "Yes" : "No",
            show_in_pdf: field.show_in_pdf ? "Yes" : "No",
            actions: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field,
              show_in_pdf: field.show_in_pdf,
            },
          },
        };
      })
    : [];

  const debitNoteFields = customFieldModuleList["Debit Notes"]
    ? customFieldModuleList["Debit Notes"]
        .filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];
  const paymentMadeFields = customFieldModuleList["Payments Made"]
    ? customFieldModuleList["Payments Made"]
        .filter((field) => field.is_deleted === false)
        .map((field) => {
          return {
            show: {
              id: field.id,
              field_name: field.field_name,
              mandatory_field: field.mandatory_field ? "Yes" : "No",
              show_in_pdf: field.show_in_pdf ? "Yes" : "No",
              actions: {
                id: field.id,
                field_name: field.field_name,
                mandatory_field: field.mandatory_field,
                show_in_pdf: field.show_in_pdf,
              },
            },
          };
        })
    : [];
  return (
    <div
      className="accordion transactions-accordion"
      id="transactions-accordion"
    >
      {" "}
      <AccordianItem
        title="Bills"
        index={1}
        module="Custom Fields"
        subModule="Bills"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        {" "}
        <CustomFieldTable
          data={billsFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Bills"}
        />
      </AccordianItem>
      <AccordianItem
        title="Debit Notes"
        index={2}
        module="Custom Fields"
        subModule="Debit Notes"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        <CustomFieldTable
          data={debitNoteFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Debit Notes"}
        />
      </AccordianItem>
      <AccordianItem
        title="Payments Made"
        index={3}
        module="Custom Fields"
        subModule="Payments Made"
        createUrl=""
        checkLimit={async (s: string) => {
          return true;
        }}
        wrapperClass="custom-field-accordion-item"
      >
        {" "}
        <CustomFieldTable
          data={paymentMadeFields}
          isLoading={false}
          sortHandler={() => {}}
          editPermission={true}
          module={"Payments Made"}
        />
      </AccordianItem>
    </div>
  );
};
