import React from "react";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../common/components/kebabMenu/Edit";
import DownloadPdf from "../../../../common/components/kebabMenu/DownloadPdf";
import SendEmail from "../../../../common/components/kebabMenu/SendEmail";
import Delete from "../../../../common/components/Delete";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  value: any;
  onClickSendMail: () => void;
  onClickDownloadPdf: () => void;
  onClickDelete: () => void;
  fetchPurchaseOrderList: () => Promise<void>;
};
function ListActions({
  value,
  onClickSendMail,
  onClickDownloadPdf,
  onClickDelete,
  fetchPurchaseOrderList,
}: Props) {
  const { purchaseOrderRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {purchaseOrderRolePermission.includes("Update") &&
      value.status !== "Billed" ? (
        <li>
          <Edit
            url={`/purchase-order/edit/${value.id}`}
            isMutable={true}
            name="Purchase Order"
            isLocked={value.isLocked}
            className={"dropdown-item"}
            lockDate={value.lockDate}
            isFiscalClosed={value.is_fiscal_closed}
            isVoid={value.status === "Void"}
          />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <>
          <li>
            <SendEmail onClick={onClickSendMail} />
          </li>
          <li>
            <DownloadPdf onClick={onClickDownloadPdf} />
          </li>
        </>
      ) : null}
      {purchaseOrderRolePermission.includes("Delete") &&
      !(
        value.status === "Billed" &&
        value.purchase_orders.is_bill_associated === true
      ) ? (
        <li>
          <Delete
            className="dropdown-item delete"
            deleteIcon={true}
            isMutable={true}
            onClick={onClickDelete}
            isLocked={value.isLocked}
            lockDate={value.lockDate}
            name="Purchase Order"
            isFiscalClosed={value.is_fiscal_closed}
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default React.memo(ListActions);
