import { useState } from "react";
import { ArrowRightToggle } from "../../../../assets/images";
import "../../Home.css";
import "./sideBar.css";
import { SideBarAccordionItem } from "./sideBarAccordionItem/sideBarAccordionItem";
import { SideBarItem } from "./sideBarItem/sideBarItem";
import { ObjectType } from "../../../../types";
import useCommonData from "../../../hooks/useCommon";

type Props = {
  sidebarHandler?: (isExpanded: boolean) => void;
  menuList: any[];
  headerComponent?: React.ReactNode;
  wrapperClass?: string;
};

function Sidebar(props: Props) {
  const [scrollTopMenu, setScrollTopMenu] = useState(0);
  const { currentUserInfo } = useCommonData();
  const sidebarToggleHandler = () => {
    sidebarCollapse();
  };

  function sidebarCollapse() {
    ($(".submenu") as any).collapse("hide");
    $(".menu-text").toggle(300);
    $(".sidebar").toggleClass("expanded");
    $(".sidebar-custom").toggleClass("expanded");
    $(".sidebar-togglebtn").toggleClass("expanded");
    var hasExpanded = $(".sidebar").hasClass("expanded");
    props.sidebarHandler && props.sidebarHandler(hasExpanded);
    return false;
  }

  $(function () {
    $(
      ".sidebar .menu-item, .sidebar .sidebar-search input, .sidebar .pinned"
    ).on("click", function (e: any) {
      var hasExpanded = $(".sidebar").hasClass("expanded");
      if (!hasExpanded) {
        sidebarCollapse();
      }
    });
    var scrollItem: any = document.querySelector(".menu-wrapper");
    scrollItem &&
      scrollItem.addEventListener("scroll", function (e: any) {
        setScrollTopMenu(scrollItem.scrollTop);
      });
    $("#sidebar").on("show.bs.dropdown", function (e: any) {
      $(".menu-wrapper").css({
        overflow: "initial",
        "margin-top": "-" + scrollTopMenu + "px",
      });
    });
    $("#sidebar").on("hide.bs.dropdown", function (e: any) {
      $(".menu-wrapper").css({
        overflow: "hidden",
        "overflow-y": "auto",
        "margin-top": 0,
      });
    });
  });

  return (
    <>
      {props.sidebarHandler && (
        <button
          className="btn sidebar-togglebtn expanded position-fixed"
          id="sidebarTogglebutton"
          onClick={sidebarToggleHandler}
        >
          <img src={ArrowRightToggle} alt="arrow-toggle" />
        </button>
      )}
      <div
        className={`col sidebar-custom expanded ${props.wrapperClass}`}
        id="sidebarWrapper"
        key={`${
          String(currentUserInfo.is_super_parent) +
          String(currentUserInfo.is_tags_enabled) +
          String(currentUserInfo.organization_id)
        }`}
      >
        <nav id="sidebar" className="col sidebar expanded">
          {props.headerComponent}
          {/* hidding the Search option and Pinned option dropdown from SideBar as per ticket: https://app.clickup.com/t/85zrmgcae */}
          <div
            className="menu-wrapper"
            style={{
              height: "calc(100vh - 107px)",
              maxHeight: "calc(100vh - 107px)",
            }}
          >
            <ul className="nav flex-column vertical-nav menu-list">
              {props.menuList.map((item: ObjectType, index: number) => {
                return item.isAccordion ? (
                  <SideBarAccordionItem {...item.props} key={index} />
                ) : (
                  <SideBarItem {...item.props} key={index} />
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Sidebar;
