import React, { useEffect, useRef, useState } from "react";
import "../../../Users.css";
import { getRoleLimitStatus, usersList } from "../../../userSlice";
import { Transactionlimit } from "../../../../../../types";
import DeleteUserRole from "../Delete";
import NotificationModal from "../../../../../common/components/NotificationModal";
import { IsFeatureEnabled } from "../../../../../../helpers/featureFlagHelper";
import useListData from "../../../../../hooks/useListData";
import RolesListHeader from "./components/RolesListHeader";
import RoleTableContainer from "./roleTable/RoleTableContainer";
import useCommonData from "../../../../../hooks/useCommon";

function List() {
  const { dispatch, currentUserInfo } = useCommonData();
  const listRef = useRef<any>([]);
  const { page, itemsPerPage } = useListData();
  const [deleteUserRoleId, setDeleteUserRoleId] = useState(-1);
  const [roleCount, setRoleCount] = useState(-1);
  const [roleName, setRoleName] = useState("");
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: -1,
  });

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  useEffect(() => {
    fetchRoleLimit();
    if (
      usersPermission.includes("Read") &&
      IsFeatureEnabled("Users", currentUserInfo.disabled_feature)
    ) {
      fetchUsersList();
    }
  }, [currentUserInfo.organization_id]);

  const fetchUsersList = async () => {
    const data = {
      page,
      itemsPerPage,
      roleId: 0,
      userSort: "ASC",
      orgId: currentUserInfo.organization_id,
    };
    await dispatch(usersList(data));
  };
  /**
   * Fetch transaction limit
   */
  const fetchRoleLimit = async () => {
    const responseAction = await dispatch(
      getRoleLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Delete Role Handler
   */
  const deleteListData = (
    id: number,
    assignedUsersCount: number,
    name: string
  ) => {
    setDeleteUserRoleId(id);
    setRoleCount(assignedUsersCount);
    setRoleName(name);
  };

  const refreshList = (roleSort: string) => {
    listRef.current.fetchUserRolesList(roleSort);
  };

  return (
    <div className="card card-user-management main-card overflowX-hidden h-100">
      <RolesListHeader transactionLimit={transactionLimit} />
      <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
        <RoleTableContainer deleteListData={deleteListData} ref={listRef} />
      </div>
      <DeleteUserRole
        deleteUserRoleId={deleteUserRoleId}
        roleCount={roleCount}
        roleName={roleName}
        refreshList={refreshList}
      />
      <NotificationModal
        modalHeader={"Maximum limit reached!"}
        modalBody="Sorry, You have reached the maximum limit of 15 roles."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
      <NotificationModal
        modalId="roleLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </div>
  );
}

export default List;
