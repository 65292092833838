import React from "react";
import { SelectedFiltersType } from "../../../../../types";

type Props = {
  label: string;
  name: string;
  index: number;
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChecked: boolean;
};
function FilterOption({
  label,
  name,
  index,
  onFilterChange,
  handleChecked,
}: Props) {
  return (
    <div className="form-check ">
      <input
        className="form-check-input filterSelect"
        type="checkbox"
        id={name}
        name={name}
        tabIndex={index}
        onChange={onFilterChange}
        checked={handleChecked}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export default FilterOption;
