import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  putFormData,
  remove,
} from "../../../helpers/apiHelper";

export {};

/**
 * API call updating Organization Profile
 */
export async function editOrganizationProfile(data: {
  orgId: number;
  values: FormData;
  isLogoRemoved: boolean;
}) {
  const result = await putFormData(
    Api.organizationProfile +
      `/update-profile?is_logo_removed=${data.isLogoRemoved}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for getting organization details
 */
export async function getOrganizationProfileDetails(orgId: number) {
  const result = await get(
    Api.organizationProfile +
      `/get-profile-detail?organization_id=${orgId}&current_organization=${orgId}`
  );
  return result;
}

/**
 * API to upload profile logo
 */
export async function profileLogoAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.organizationProfile +
      `/upload-logo?organization_id=${data.orgId}&current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * API to remove logo
 */
export async function removeLogoAttachment(orgId: number) {
  const result = await remove(
    Api.organizationProfile +
      `/delete-logo?organization_id=${orgId}&current_organization=${orgId}`
  );
  return result;
}

/**
 * API to set fiscal year on login
 */
export async function fiscalYearSetter(data: {
  fiscalYear: string;
  orgId: number;
}) {
  const result = await post(
    Api.organizationProfile +
      `/add-fiscal-year?organization_id=${data.orgId}&fiscal_year=${data.fiscalYear}`
  );
  return result;
}

/**
 * API to check whether organization profile is set
 */
export async function organizationProfileCreationStatus(data: {
  orgId: number;
}) {
  const result = await get(
    Api.organizationProfile +
      `/organization-profile-exist?current_organization=${data.orgId}`
  );
  return result;
}
