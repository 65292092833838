import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormikProps } from "formik";
import React from "react";
import "../../../../index.css";
import "./radioButtonGroup.css";

type RadioButtonProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: string) => void;
  value: boolean | string;
  name: string;
  id: string;
  valuesArray: {
    label: string | React.ReactElement;
    value: string;
  }[];
  isDisabled: boolean;
  formik?: FormikProps<any>;
  icon: React.ReactElement;
  checkedIcon: React.ReactElement;
  radioStyle?: string;
  disableClickRenderFunction?: (value?: string) => void;
};

type CustomRadioButtonProps = {
  label: string | React.ReactElement;
  isChecked: boolean;
};
export const RadioButtonGroup = (props: RadioButtonProps) => {
  const CustomRadioButton = (radioProps: CustomRadioButtonProps) => {
    return radioProps.isChecked === true ? props.checkedIcon : props.icon;
  };
  return (
    <FormControl
      className="form-control-reusable-radio-button"
      disabled={props.isDisabled}
    >
      <RadioGroup
        row
        className="reusable-radio-group"
        aria-labelledby={props.id}
        name={props.name}
        id={props.id}
        value={props.value}
      >
        {props.valuesArray.map((item, index) => {
          return (
            <FormControlLabel
              key={`${item.label}-${index}`}
              tabIndex={index}
              className={`reusable-form-control-label ${props.radioStyle}`}
              value={item.value}
              disabled={props.isDisabled}
              onClick={(e) => {
                if (props.isDisabled) {
                  e.preventDefault();
                  props.disableClickRenderFunction &&
                    props.disableClickRenderFunction(item.value);
                } else {
                  props.formik &&
                    props.formik.setFieldValue(props.name, item.value);
                  props.formik?.setFieldTouched(props.name, true);
                  props.onClick && props.onClick(item.value);
                }
              }}
              control={
                <Radio
                  icon={
                    <CustomRadioButton label={item.label} isChecked={false} />
                  }
                  checkedIcon={
                    <CustomRadioButton label={item.label} isChecked={true} />
                  }
                />
              }
              label={item.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
