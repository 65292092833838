import React, { memo, useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { ObjectType } from "../../../../types";
import { fetchAccountsGlCode } from "../../../home/chartOfAccounts/coaSlice";
import "../../../../index.css";
import "./accountSelect.css";
import List from "../ui/formElements/Ul/Ul";
import Li from "../ui/formElements/Li/Li";
import { Link } from "react-router-dom";
import { Span } from "../htmlTags/Span";

type Props = {
  itemAccount: number;
  itemAccountName?: string;
  organizationId: number;
  onChange: (account: any) => void;
  itemIndex: number;
  error: string;
  errorClass?:string;
  errorMessage?: string;
  baseAccount?: string;
  currencyFilter?: number;
  className?: string;
  isDisabled?: boolean;
  name?: string;
  id?: string;
  wrapperClass?: string;
  onBlur?: (e: any) => void;
};

let abortController = new AbortController();

const AccountSelector = (props: Props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState<number | null>();
  const [selectValue, setSelectValue] =
    React.useState<string | undefined>(undefined);
  const [accountList, setAccountList] = React.useState<ObjectType[]>([]);
  const [accountListFilter, setAccountListFilter] = React.useState<
    ObjectType[]
  >([]);

  useEffect(() => {
    setValue(props.itemAccount);
  }, []);
  useEffect(() => {
    if (!props.itemAccount) {
      setValue(null);
      setSelectValue("");
    }
  }, [props.itemAccount]);
  useEffect(() => {
    getAccountList();
  }, [props.organizationId]);

  useEffect(() => {
    if (accountList.length && props.itemAccount) {
      let selectedAccount = accountList.filter(
        (account) => account.id === props.itemAccount
      );
      if (selectedAccount.length === 0) {
        setValue(null);
        props.onChange(null);
      }
    } else if (!props.itemAccount) {
      setValue(Number(0));
    }
  }, [accountList, props.itemAccount]);

  useEffect(() => {
    if (props.currencyFilter) {
      let accounts = accountList.filter(
        (account) =>
          account.currency_id === props.currencyFilter ||
          account.currency_id === null
      );
      setAccountListFilter([...accounts]);
    } else {
      setAccountListFilter(accountList);
    }
  }, [props.currencyFilter, accountList]);

  useEffect(() => {
    if (
      (props.itemAccountName || props.itemAccountName == "") &&
      selectValue !== props.itemAccountName
    ) {
      setSelectValue(props.itemAccountName);
    }
  }, [props.itemAccountName]);

  useEffect(() => {
    resetDropDown();
  }, []);
  useEffect(() => {
    $(".dropdown-toggle").dropdown("hide");
  }, [props.error]);

  const getAccountList = async () => {
    await getSearchAccountList("");
  };

  /**
   * Get account list based on search string
   */
  const getSearchAccountList = async (searchString: string) => {
    const signal = abortController.signal;
    const responseAction = await dispatch(
      fetchAccountsGlCode({
        accountSearchTerm: searchString,
        baseAccount: props.baseAccount ? props.baseAccount : "",
        orgId: props.organizationId,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAccountList(response.account_list);
        let accounts: ObjectType[];
        if (props.currencyFilter) {
          accounts = response.account_list.filter((account: ObjectType) => {
            return (
              account.currency_id === props.currencyFilter ||
              account.currency_id === null
            );
          });
        } else {
          accounts = response.account_list;
        }
        return accounts;
      }
    }
    return [];
  };
  /**
   * Function to set search string
   */
  const searchAccount = (e: any) => {
    setSelectValue(e.target.value);
    abortController.abort();
    abortController = new AbortController();
    getSearchAccountList(e.target.value);
    if (e.target.value.trim().length === 0) {
      props.onChange(null);
    }
  };

  const accountSearchOnBlur = (e: any) => {
    props.onBlur && props.onBlur(e);

    if (accountList.length && props.itemAccount) {
      let selectedAccount = accountList.filter(
        (account) => account.id === props.itemAccount
      );
      if (selectedAccount.length === 0) {
        setValue(null);
        props.onChange(selectedAccount);
      } else {
        setValue(Number(selectedAccount[0].id));
        setSelectValue(selectedAccount[0].account_name);
      }
    }
  };

  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  return (
    <>
      <div
        className={`btn-group accounts-dropdown flex-column ${
          props.wrapperClass ? props.wrapperClass : ""
        } ${props.error}`}
      >
        <input
          type="text"
          name={props.name ? props.name : "account_id"}
          id={props.id ? props.id : ""}
          className={` search-dropdown dropdown-toggle w-100 ${
            props.className || ""
          } ${props.error ? "error " : ""}`}
          placeholder="Choose account"
          data-bs-toggle="dropdown"
          // aria-expanded="false"
          disabled={props.isDisabled ? props.isDisabled : false}
          value={
            selectValue !== undefined
              ? selectValue.toString()
              : props.itemAccountName
          }
          onChange={(e) => {
            searchAccount(e);
          }}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          onBlur={accountSearchOnBlur}
        />
        {props.error && <span className={`error ${props.errorClass ? props.errorClass : ""}`}>{props.error}</span>}
        <List wrapperClass="dropdown-menu dropdown-menu-start">
          <>
            {accountListFilter.length ? (
              accountListFilter.map((account, accountIndex) => {
                return (
                  <Li key={accountIndex}>
                    <Link
                      className={
                        value === account.id
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        props.onChange(account);
                        setValue(Number(account.id));
                        setSelectValue(account.account_name);
                        return true;
                      }}
                    >
                      <Span className="account">{account.account_name}</Span>
                      <Span className="account-type">
                        {account.account_type}
                      </Span>
                    </Link>
                  </Li>
                );
              })
            ) : (
              <Li key="no-result" wrapperClass="no-result">
                <Link
                  className="dropdown-item no-result"
                  to="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <Span className="no-result">No accounts found</Span>
                </Link>
              </Li>
            )}
          </>
        </List>
        {/* <ul className="dropdown-menu dropdown-menu-start">
          {accountListFilter.length ? (
            accountListFilter.map((account, accountIndex) => {
              return (
                <li key={accountIndex}>
                  <button
                    type="button"
                    className={
                      value === account.id
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    onClick={(e) => {
                      console.log("test 123", account);
                      props.onChange(account);
                      setValue(Number(account.id));
                      setSelectValue(account.account_name);
                      return true;
                    }}
                  >
                    <span className="account">{account.account_name}</span>
                    <span className="account-type">{account.account_type}</span>
                  </button>
                </li>
              );
            })
          ) : (
            <li className="no-result">
              <a
                className="dropdown-item no-result"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No accounts found</span>
              </a>
            </li>
          )}
        </ul> */}
      </div>
    </>
  );
};

export default memo(AccountSelector);
