import { FormikProps } from "formik";
import { AttachFiles } from "../../../../../common/components";
import { attachFiles, deleteJournalFile } from "../../../journalVoucherSlice";

type Props = {
  formik: FormikProps<any>;
};
export const JournalVoucherFileUpload = (props: Props) => {
  return (
    <AttachFiles
      formik={props.formik}
      attachFiles={attachFiles}
      deleteFile={deleteJournalFile}
      appendName="journal_file"
    />
  );
};
