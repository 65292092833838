type ButtonProps = {
  isDisabled?: boolean;
  onClick?: (e: any) => void;
};
export const SolidCancelButton = (props: ButtonProps) => {
  return (
    <button
      type="button"
      className="btn btn-cancel btn-green"
      onClick={props.onClick}
      disabled={props.isDisabled === true ? true : false}
    >
      Cancel
    </button>
  );
};
