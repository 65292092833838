import { getFormatedDate } from "../../helpers/helper";
import {
  getLatestCurrencyRate,
  setExchangeApiSatus,
} from "../common/commonSlice";
import { conversionMinDate } from "../constants/constants";
import { isDateValid } from "./isDateValid";

export const getLatestCurrencyConversion = async (
  dispatch: any,
  base_currency: number,
  to_currency: number,
  conversionDate: string
) => {
  if (
    base_currency &&
    base_currency !== 0 &&
    to_currency !== 0 &&
    conversionDate &&
    !(new Date(conversionDate).toString() === "Invalid Date") &&
    isDateValid(conversionDate)
  ) {
    if (new Date(conversionDate) < new Date(conversionMinDate)) {
      return;
    }

    dispatch(setExchangeApiSatus(true));
    const responseAction = await dispatch(
      getLatestCurrencyRate({
        base_currency: Number(base_currency),
        to_currency: Number(to_currency),
        date:
          new Date(conversionDate) > new Date()
            ? getFormatedDate()
            : conversionDate,
      })
    );
    let response = responseAction.payload;

    if (Object.keys(response).length && !("error" in response)) {
      dispatch(setExchangeApiSatus(false));
      return response.latest_conversion;
    }
  }
};
