import React from "react";
import { useParams } from "react-router-dom";
import CreateCustomer from "./customerForm/CustomerForm";
import TransactionHeader from "../../../common/components/TransactionHeader";

function CustomerContainer() {
  const { id } = useParams();
  const customerId = id ? Number(id) : 0;
  return (
    <>
      <div className="card card-user-management card-customer card-create-item card-add-new-customer main-card overflowX-hidden h-100">
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Customer"
            transactionNumber={Number(customerId)}
          />
        </div>
        <div className="card-body">
          <CreateCustomer id={customerId} redirectTo="/customers" />
        </div>
      </div>
    </>
  );
}

export default CustomerContainer;
