import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DebitNoteAllocation,
  DebitNoteValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";
import {
  billsToAllocate,
  calculateDebitNoteAllocation,
  calculateDebitNoteTaxableAmount,
  calculateDebitNoteTotalAmount,
  createNewDebitNote,
  debitNoteAllocation,
  debitNoteAttachments,
  debitNoteNumberExist,
  debitNotePdfLink,
  deleteDebitNoteAllocation,
  editDebitNote,
  editDebitNoteAllocation,
  getDebitNoteDetails,
  getDebitNoteList,
  getDebitNoteNumber,
  removeDebitNote,
  removeDebitNoteAttachments,
  removeDebitNoteItem,
  removeDebitNoteItemTax,
  removeDebitNoteRecipient,
  sendDebitNote,
  transactionLimitStatus,
  getReportingTagJournals,
  putReportingTags,
  debitNoteVoidMarker,
} from "./debitNoteApi";

export const debitNoteList = createAsyncThunk(
  "debit-note-list",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    vendorList: number[];
    orgIds: number[];
    statusList: string[];
    billId?: number | null;
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getDebitNoteList(data);
  }
);

export const createDebitNote = createAsyncThunk(
  "create/debitNote",
  async (data: { values: DebitNoteValues; status: string; orgId: number }) => {
    return await createNewDebitNote(data);
  }
);

export const editDebitNoteDetails = createAsyncThunk(
  "edit/debitNote",
  async (data: {
    debitNoteId: number;
    debitNoteStatus: string;
    values: DebitNoteValues;
    orgId: number;
  }) => {
    return await editDebitNote(data);
  }
);

export const deleteDebitNote = createAsyncThunk(
  "delete/debitNote",
  async (data: { debitNoteId: number; orgId: number }) => {
    return await removeDebitNote(data);
  }
);

export const debitNoteDetails = createAsyncThunk(
  "debit-note-detail",
  async (data: { debitNoteId: number; orgId: number }) => {
    return await getDebitNoteDetails(data);
  }
);

export const deleteDebitNoteItem = createAsyncThunk(
  "delete/debitNoteItem",
  async (data: { debitNoteId: number; itemId: number; orgId: number }) => {
    return await removeDebitNoteItem(data);
  }
);

export const deleteDebitNoteItemTax = createAsyncThunk(
  "/delete/debitNoteItemTax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await removeDebitNoteItemTax(data);
  }
);

export const deleteDebitNoteRecipient = createAsyncThunk(
  "debit-note/recipient/delete",
  async (data: { debitNoteId: number; emailIds: number[]; orgId: number }) => {
    return await removeDebitNoteRecipient(data);
  }
);

export const getNewDebitNoteNumber = createAsyncThunk(
  "debitNote/number",
  async (orgId: number) => {
    return await getDebitNoteNumber(orgId);
  }
);

export const checkDebitNoteNumberExist = createAsyncThunk(
  "debitNote/number-exist",
  async (data: {
    debitNoteNumber: string;
    orgId: number;
    debitNoteId: number;
  }) => {
    return await debitNoteNumberExist(data);
  }
);

export const getDebitNoteTaxableAmount = createAsyncThunk(
  "taxableAmount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateDebitNoteTaxableAmount(data);
  }
);

export const getDebitNoteTotalAmount = createAsyncThunk(
  "totalAmount",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateDebitNoteTotalAmount(data);
  }
);

export const attachDebitNoteFiles = createAsyncThunk(
  "debit-note/file/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await debitNoteAttachments(data);
  }
);

export const deleteDebitNoteFile = createAsyncThunk(
  "debit-note/file/delete",
  async (data: { debitNoteId: number; fileId: number; orgId: number }) => {
    return await removeDebitNoteAttachments(data);
  }
);

export const sendDebitNoteData = createAsyncThunk(
  "send-debit-note",
  async (data: { debitNoteId: number; emails: string[]; orgId: number }) => {
    return await sendDebitNote(data);
  }
);

export const getDebitNotePdfLink = createAsyncThunk(
  "debit-note/pdf",
  async (data: { debitNoteId: number; orgId: number }) => {
    return await debitNotePdfLink(data);
  }
);

export const getDebitNoteAllocation = createAsyncThunk(
  "debit-note/allocation",
  async (data: { debitNoteId: number; orgId: number }) => {
    return await debitNoteAllocation(data);
  }
);

export const getBillsToAllocate = createAsyncThunk(
  "bills/allocate",
  async (data: { debitNoteId: number; orgId: number; billId?: number }) => {
    return await billsToAllocate(data);
  }
);

export const updateDebitNoteAllocation = createAsyncThunk(
  "debit-note/allocation/edit",
  async (data: {
    debitNoteId: number;
    orgId: number;
    values: DebitNoteAllocation[];
  }) => {
    return await editDebitNoteAllocation(data);
  }
);

export const deleteDebitAllocation = createAsyncThunk(
  "debit-note/allocation/delete",
  async (data: {
    debitNoteId: number;
    allocationId: number;
    orgId: number;
  }) => {
    return await deleteDebitNoteAllocation(data);
  }
);

export const calculateDebitNoteAllocationAmount = createAsyncThunk(
  "debit-note/allocation/calculate",
  async (data: {
    debitNoteId: number;
    orgId: number;
    values: number[];
    signal?: AbortSignal;
  }) => {
    return await calculateDebitNoteAllocation(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "debit-note/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const generateJournal = createAsyncThunk(
  "reporting-tag/journal-generate",
  async (data: { debitNoteId: number; orgId: number }) => {
    return await getReportingTagJournals(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { debitNoteId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);

export const markDebitNoteVoid = createAsyncThunk(
  "debit-note/void",
  async (data: { debitNoteId: number | null; orgId: number }) => {
    return await debitNoteVoidMarker(data);
  }
);
