import React, { forwardRef } from "react";
import { HorizontalTab } from "../../../../common/components/HorizontalTab";
import { DebitNoteAndPaymentMadeTable } from "./DNandPMTable/DNandPMTable";
import "./DNandPMDetails.css";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useParams } from "react-router-dom";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import {
  DebitNoteListValues,
  PaymentMadeListValues,
} from "../../../../../types";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { billPaymentList } from "../../../paymentsMade/paymentsMadeSlice";
import { debitNoteList } from "../../../debitNote/debitNoteSlice";
type Props = {
  billId: number | null;
  billDetail: () => void;
  orgId: number | null;
};
const DebitNoteAndPaymentMadeDetails: ForwardRefRenderFunction<any, Props> = (
  props,
  ref
) => {
  const dispatch = useAppDispatch();
  const page = 1;
  const itemsPerPage = 20;
  const id = useParams().editBillId;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { dateSortOrder } = useListData();
  const { setDateSortOrder } = usePaginationDispatch();
  const [PMList, setPMList] = useState<PaymentMadeListValues[]>();
  const [DNList, setDNList] = useState<DebitNoteListValues[]>();
  const [isParent, setIsParent] = useState(false);
  const [reRenderFlag, setReRenderFlag] = useState<number>();
  const [DNandPMTotalCount, setDNandPMTotalCount] = useState<{
    DNTotalCount: number;
    PMTotalCount: number;
  }>({ DNTotalCount: 0, PMTotalCount: 0 });

  useEffect(() => {
    if (Number(props.orgId) !== currentUserInfo.organization_id) {
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  }, [props.orgId, currentUserInfo.organization_id]);

  useImperativeHandle(
    ref,
    () => ({
      fetchDNPMList: fetchDNPMList,
    }),
    [props.billId]
  );
  useEffect(() => {
    if (props.billId) {
      fetchDNPMList();
    }
  }, [dateSortOrder, props.billId, reRenderFlag]);
  const fetchDNPMList = () => {
    getDNList();
    getPMList();
  };
  const getPMList = async () => {
    dispatch(setLoaderState(true));
    const PMresponseAction = await dispatch(
      billPaymentList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: [],
        orgIds: [],
        statusList: [],
        billId: Number(id),
      })
    );
    const PMresponse = PMresponseAction.payload as any;
    if (Object.keys(PMresponse).length && !("error" in PMresponse)) {
      dispatch(setLoaderState(false));
      setPMList(PMresponse.payments_made);
      const length = PMresponse.payments_made?.length;
      setDNandPMTotalCount({
        ...DNandPMTotalCount,
        PMTotalCount: PMresponse.total,
      });
    } else if ("error" in PMresponse) {
      dispatch(setLoaderState(false));
    }
  };
  const getDNList = async () => {
    dispatch(setLoaderState(true));
    const DNresponseAction = await dispatch(
      debitNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        vendorList: [],
        billId: Number(id),
      })
    );
    const DNresponse = DNresponseAction.payload as any;
    if (Object.keys(DNresponse).length && !("error" in DNresponse)) {
      dispatch(setLoaderState(false));
      setDNList(DNresponse.debit_notes);
      const length = DNresponse.debit_notes?.length;
      setDNandPMTotalCount({
        ...DNandPMTotalCount,
        DNTotalCount: DNresponse.total,
      });
    } else if ("error" in DNresponse) {
      dispatch(setLoaderState(false));
    }
  };

  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (["DEBIT NOTE DATE", "PAYMENT DATE"].includes(column)) {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  const getRerenderFlag = (flag: number) => {
    setReRenderFlag(flag);
    props.billDetail();
  };
  return (
    <div className="dn-pm-detail-bill" key={`${isParent}`}>
      <HorizontalTab
        tabHeaders={[
          `Payments Made (${PMList?.length ? PMList?.length : 0})`,
          `Debits Applied (${DNList?.length ? DNList?.length : 0})`,
        ]}
        tabBody={[
          <DebitNoteAndPaymentMadeTable
            DNList={DNList}
            PMList={PMList}
            module="PM"
            sortHandler={sortHandler}
            dateSortOrder={dateSortOrder}
            billId={Number(id)}
            isParent={isParent}
            key={"1"}
            getRerenderFlag={getRerenderFlag}
          />,
          <DebitNoteAndPaymentMadeTable
            DNList={DNList}
            PMList={PMList}
            module="DN"
            sortHandler={sortHandler}
            dateSortOrder={dateSortOrder}
            billId={Number(id)}
            isParent={isParent}
            key={"1"}
            getRerenderFlag={getRerenderFlag}
          />,
        ]}
        wrapperClass="bill-dn-pm-detail-tab"
        disableRerender={true}
      />
    </div>
  );
};
export default React.memo(forwardRef(DebitNoteAndPaymentMadeDetails));
