import React from "react";
import { colors } from "../../../constants/colors";
import "../../../../index.css";
import "../../styles/KebabMenu.css";

export default function KebabMenu(props: {
  children: React.ReactNode;
  wrapperClass?: string;
  onClick? : () => void
}) {
  const handleChildClick = () => {
    $(".more-btn").dropdown("hide");
  };
  const childrenArray = React.Children.toArray(
    props.children
  ) as React.ReactElement[];
  return (
    <div
      className={`dropdown kebabMenu ${
        props.wrapperClass ? props.wrapperClass : ""
      }`}
    >
      {/* <!--dropstart --> */}
      <button
        className="dropdown-ctrl more-btn no-appearance"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        tabIndex={0}
        onKeyUp={(event: any) => {
          if (event.key === "Enter" || event.keyCode === 13) {
            event.target.click();
          }
        }}
        onClick={() => props.onClick ? props.onClick() : () => {}}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h20v20H0z" />
            <path
              d="M10 2.5c-.917 0-1.667.75-1.667 1.667 0 .916.75 1.666 1.667 1.666s1.667-.75 1.667-1.666c0-.917-.75-1.667-1.667-1.667zm0 11.667c-.917 0-1.667.75-1.667 1.666 0 .917.75 1.667 1.667 1.667s1.667-.75 1.667-1.667c0-.916-.75-1.666-1.667-1.666zm0-5.834c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667 1.667-.75 1.667-1.667-.75-1.667-1.667-1.667z"
              fill={colors.battleshipGrey}
              fillRule="nonzero"
            />
          </g>
        </svg>
      </button>
      <ul
        id="kebabMenuDropDown"
        className="dropdown-menu"
        data-popper-placement="bottom-end"
      >
        {" "}
        {React.Children.map(childrenArray, (child: React.ReactElement) =>
          React.cloneElement(child, { onClick: handleChildClick })
        )}
      </ul>
    </div>
  );
}
