import * as yup from "yup";
export const ApplyToInvoiceValidation = yup.object({
  invoice_allocate_list: yup.array().of(
    yup.object().shape({
      amount_credited: yup
        .mixed()
        .test(
          "is-amount-credited-more",
          "The credits applied is more than the invoice balance.",
          function (value) {
            const { invoice_balance } = this.parent as any;
            let invoiceBalance = isNaN(invoice_balance)
              ? 0
              : Number(invoice_balance);
            const amountToBeCredited = Number(value);
            if (amountToBeCredited > invoiceBalance) {
              return false;
            }

            return true;
          }
        ),
    })
  ),
});
