import { FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getTotalAmountDetails,
  setAmountCalculationResult,
} from "../../home/paymentsReceived/paymentReceivedSlice";
import { currentUserSelector } from "../../common/commonSlice";
import { useEffect } from "react";
import { payloadRefiner } from "../../../helpers/prHelpers/amountCalculationPayloadHelper";
import { useParams } from "react-router-dom";
type Props = {
  formik: FormikProps<any>;
};
type Invoice = {
  invoice_id: number;
  forex_amount: number;
  payment: number;
  withholding_tax: number;
  id: number;
  conversion_id: number;
  invoice_number: string;
  invoice_date: string;
  invoice_amount: string;
  amount_due: string;
  is_deleted: boolean;
};
type Payload = {
  currency_id: number | null;
  bank_currency_id: number | null;
  unpaid_invoices: Invoice[];
  bank_charges: number | string;
  amount_received: number | string;
  amount_received_bank_currency: number | string;
  bank_conversion_rate: number | string;
  bank_charges_bank_currency: number | string;
  total_refunded_amount?: string | number;
};
export const useAmountCalculation = (props: Props) => {
  const { editId } = useParams();
  const payload: Payload = {
    currency_id: props.formik.values.currency_id,
    bank_currency_id: props.formik.values.bank_currency_id,
    unpaid_invoices:
      Array.isArray(props.formik.values.unpaid_invoice_lines) &&
      props.formik.values.unpaid_invoice_lines
        .filter((value: any) => value.payment || value.withholding_tax)
        .map((value: any) => {
          return {
            invoice_id: value.invoice_id,
            invoice_number: value.invoice_number,
            invoice_amount: value.invoice_amount,
            amount_due: value.amount_due,
            payment: !isNaN(Number(value.payment)) ? Number(value.payment) : 0,
            withholding_tax: !isNaN(Number(value.withholding_tax))
              ? Number(value.withholding_tax)
              : 0,
          };
        }),
    bank_charges: props.formik.values.bank_charges_amount,
    amount_received: props.formik.values.amount_received,
    amount_received_bank_currency:
      props.formik.values.amount_received_bank_currency,
    bank_conversion_rate: props.formik.values.bank_conversion_rate,
    bank_charges_bank_currency: props.formik.values.bank_charges_amount,
    total_refunded_amount: props.formik.values.total_refunded_amount,
  };
  const refinedPayload = { ...payloadRefiner({ ...payload }) };
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAmountCalculationResult(signal);
    return () => {
      controller.abort();
    };
  }, [
    refinedPayload.amount_received,
    refinedPayload.bank_charges,
    refinedPayload.bank_conversion_rate,
    JSON.stringify(payload.unpaid_invoices),
  ]);
  const getAmountCalculationResult = async (signal: AbortSignal) => {
    if (payload.currency_id) {
      const responseAction = await dispatch(
        getTotalAmountDetails({
          values: { ...refinedPayload },
          orgId: currentUserInfo.organization_id,
          signal: signal,
        })
      );
      const response = responseAction.payload;
      if (response) {
        dispatch(setAmountCalculationResult(response));
        // if (
        //   payload.bank_currency_id &&
        //   payload.currency_id !== payload.bank_currency_id
        // ) {
        //   props.formik.setFieldValue(
        //     "amount_received_bank_currency",
        //     Number(response.total_details.amount_received_bank_currency) === 0
        //       ? ""
        //       : response.total_details.amount_received_bank_currency
        //   );
        // }
        // props.formik.setFieldValue(
        //   "bank_charges_bank_currency",

        //   Number(response.total_details.bank_charges_bank_currency) === 0
        //     ? ""
        //     : response.total_details.bank_charges_bank_currency
        // );
        // props.formik.setFieldValue(
        //   "total",
        //   Number(response.total_details.total) === 0
        //     ? ""
        //     : response.total_details.total
        // );
        props.formik.setValues((values: any) => {
          return {
            ...values,
            amount_received_bank_currency:
              payload.bank_currency_id &&
              payload.currency_id !== payload.bank_currency_id
                ? Number(
                    response.total_details.amount_received_bank_currency
                  ) === 0
                  ? ""
                  : response.total_details.amount_received_bank_currency
                : values.amount_received_bank_currency,
            bank_charges_bank_currency:
              Number(response.total_details.bank_charges_bank_currency) === 0
                ? ""
                : response.total_details.bank_charges_bank_currency,
            total:
              Number(response.total_details.total) === 0
                ? ""
                : response.total_details.total,
            amount_excess: response.total_details.amount_excess,
          };
        });
      }
    }
  };
};
