import { useState } from "react";
import parseAmount from "../../../../../../helpers/amountCopyPasteHelper";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import { zeroDisplayFormat } from "../../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../../hooks/useCommon";
import { handleNumberOnlyKeydownEntry } from "../../../../../utils/handleAmountOnlyEntry";
import Input from "../../../ui/formElements/Input/Input";
import { DiscountInputProps } from "../types";
import { splitNumberByDecimalPoint } from "../../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../../helpers/roundOffHelper";
import { handleAmountRoundOff } from "../../../../../utils/handleAmountRoundOff";

export const DiscountInput = (props: DiscountInputProps) => {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const [isCopyPaste, setCopyPaste] = useState<boolean>(false);
  const handleItemOnBlur = (amount: string) => {
    let roundedAmount = handleAmountRoundOff(
      amount,
      props.currencyCode,
      orgCurrencyList
    );
    props.onBlur(String(roundedAmount));
  };
  return (
    <Input
      type="text"
      name="discount"
      className={`discount-input ${props.error ? "error" : ""}`}
      id={"discount_" + props.index}
      onKeyDown={(e) => {
        handleNumberOnlyKeydownEntry(e, setCopyPaste);
      }}
      placeholder={zeroDisplayFormat(
        decimalPlaceOfCurrency(
          props.currencyCode
            ? props.currencyCode
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      )}
      disabled={props.disabled ? props.disabled : false}
      value={props.discount ? props.discount : ""}
      onChange={(e) => {
        const { id, name, value } = e.target;
        const regex = /^(\d*\.?\d*)$/;
        let filteredValue = parseAmount(value, isCopyPaste) || value;
        if (value === "" || regex.test(filteredValue.toString())) {
          props.onDiscountValueChange &&
            props.onDiscountValueChange(
              {
                ...e,
                target: {
                  ...e.target,
                  id: id,
                  name: name,
                  value: String(filteredValue),
                },
              },
              props.index
            );
        }
      }}
      onBlur={() => handleItemOnBlur(String(props.discount))}
    />
  );
};
