import { colors } from "../../../constants/colors";

export const boxStyle={
    borderBottom: `1px solid ${colors.approxWhisper} !important`,
    padding: "8px 0 !important",
    opacity: "1 !important",
    "&:last-child": {
      borderBottom: "0 !important",
    },
    "&.Mui-focused": {
      backgroundColor: `${colors.whisper} !important`,
    },
    "&:hover": {
      backgroundColor: `${colors.whisper} !important`,
    },
    "&:focus": {
      backgroundColor: `${colors.whisper} !important`,
    },
    "&:active": {
      backgroundColor: `${colors.whisper} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${colors.whisper} !important`,
    },
  }
  export const getVariantBasedStyle=(variant:string)=>{
    return {
        input:
          variant === "white"
            ? {
                padding: "0 8px",
                flex: 1,
              }
            : {
                margin: "0 12px",
                flex: 1,
                color: colors.white,
              },
        select:
          variant === "white"
            ? {
                padding: "0 8px",
                width: "170px",
              }
            : {
                padding: "0 12px",
                borderLeft: "0",
                width: "125px",
                color: `${colors.white}`,
              },
        icon: {
          color: variant === "white" ? `${colors.nobel}` : `${colors.white}`,
        },
        notchedOutline: {
          borderWidth: "0",
        },
      }
  }