// import * as yup from "yup";

import { ObjectType } from "../../../../types";

export const writeOffValidation = (values: ObjectType) => {
  let errors: ObjectType = {};

  if (!values.amount || values.amount === null || values.amount === "") {
    errors.amount = "Please enter amount";
  } else if (!/^\d*\.?\d*$/.test(values.amount)) {
    errors.amount = "Please enter a valid amount";
  } else if (Number(values.amount) > Number(values.max_amount)) {
    errors.amount = "Amount cannot be higher than invoice amount";
  } else if (Number(values.amount) === 0) {
    errors.amount = "Amount cannot be zero";
  }

  if (!values.account_id) {
    errors.account_id = "Please choose an account";
  }

  if (!values.write_off_date) {
    errors.write_off_date = "Please enter write off date";
  } else {
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (
      (values.write_off_date &&
        values.write_off_date === "Invalid date" &&
        !dateRegex.test(values.write_off_date)) ||
      values.write_off_date < values.transaction_date
    ) {
      errors.write_off_date = "Please enter valid write off date";
    }
  }

  return errors;
};
