import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import { billList, deleteBillDetails } from "../billsSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";
import { useEffect, useRef } from "react";

type Props = {
  deleteBillId: number;
  deleteBillItemId: string;
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  refreshBills: (action: string) => void;
  organizationId: number;
};
const DeleteBill = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteBillId]);
  
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteBillDetails({
          deleteBillId: props.deleteBillId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (
          Object.keys(deleteRoleResponse).length &&
          !("error" in deleteRoleResponse)
        ) {
          props.refreshBills("delete");
          isDeletedRef.current = true;

          $(".close-btn").click();
          await dispatch(
            billList({
              page: props.page,
              itemsPerPage: props.itemsPerPage,
              dateSortOrder: props.dateSortOrder,
              orgId: props.organizationId,
              vendorList: [],
              orgIds: [],
              statusList: [],
            })
          );
          toast.success("Bill deleted successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          $(".close-btn").click();
          // toast.error("Delete in bill action failed!", {
          //   icon: ({ theme, type }) => (
          //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
          //   ),
          //   toastId: "delete-bill-failed-error",
          //   closeButton: false,
          //   className: "toast-error",
          //   position: "top-center",
          // });
        }
      }
    }
  };
  return (
    <DeleteModal
      heading=" Delete Bill!"
      subHeading="Are you sure you want to delete the bill"
      transactionName={joinTransactionNumber(props.deleteBillItemId)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteBill;
