import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { DebitNoteDetailValues } from "../../../../../types";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import Loader from "../../../components/Loader";
import {
  debitNoteDetails,
  debitNoteList,
  sendDebitNoteData,
} from "../../debitNoteSlice";
import DeleteDebitNote from "../Delete";
import { DebitNoteDetailBody } from "./DebitNoteDetailBody";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import Edit from "../../../../common/components/kebabMenu/Edit";
import { debitNoteDetailsInitialValues } from "../createDebitNote/StateInitialization";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useCommonData from "../../../../hooks/useCommon";
import NotificationModal from "../../../../common/components/NotificationModal";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { MoreActions } from "./MoreActions";

function DebitNoteDetailHeader() {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { debitNoteRolePermission } = usePermissionHandler();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const [deleteDebitNoteId, setDeleteDebitNoteId] = useState(-1);
  const [deleteDebitItemId, setDeleteDebitItemId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [debitNoteData, setDebitNoteData] = useState<DebitNoteDetailValues>(
    debitNoteDetailsInitialValues
  );
  const [debitNoteStatus, setDebitNoteStatus] = useState("");
  const dnDetailRef = useRef<{ refreshDetails: () => void }>(null);

  useEffect(() => {
    if (editId) {
      debitNoteDetail();
    }
  }, [editId, currentUserInfo.organization_id]);
  /**
   * Set the entered emails in the debit note data to a state.
   */
  useEffect(() => {
    let emails = debitNoteData.email_to.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [debitNoteData]);
  /**
   * Fetch Debit Note Details
   */
  const debitNoteDetail = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      debitNoteDetails({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.debit_note_status === "Draft") {
          navigate("/debit-note");
        }
        setDebitNoteData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.DNDetail));
      }
    }
  };
  /**
   * Back button handler from Bill details page
   */
  const backFromDebitNoteDetailViewHandler = () => {
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
  };

  const fetchDebitNoteList = async (action: string) => {
    await dispatch(
      debitNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        vendorList: [],
        statusList: [],
      })
    );
    if (action === "delete") {
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendDebitNoteData({
        debitNoteId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchDebitNoteList("send");
      }
    }
    return responseAction;
  };
  const refreshDNDetails = () => {
    debitNoteDetail();
    dnDetailRef.current?.refreshDetails();
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromDebitNoteDetailViewHandler}
        data={{
          num: debitNoteData.debit_note_number,
          status: debitNoteData.debit_note_status,
        }}
        dataId={editId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editId}
          data={{
            num: debitNoteData.debit_note_number,
            status: debitNoteData.debit_note_status,
            userName: debitNoteData.vendor_name,
            date: debitNoteData.debit_note_date,
            orgName: debitNoteData.organization_details.organization_name,
          }}
          isSameOrg={
            debitNoteData.organization_id === currentUserInfo.organization_id
          }
        >
          {debitNoteRolePermission.includes("Update") &&
          !debitNoteData.is_fiscal_closed ? (
            <Edit
              url={`/debit-note/edit/${debitNoteData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={debitNoteData.total_details.debits_used === 0}
              name="Debit Note"
              isLocked={debitNoteData.is_locked}
              className=""
              lockDate={debitNoteData.lock_date}
              isVoid={debitNoteData.debit_note_status === "Void"}
            />
          ) : null}
          {debitNoteRolePermission.includes("Delete") &&
          !debitNoteData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={true}
              onClick={() => {
                setDeleteDebitNoteId(Number(debitNoteData.id));
                setDeleteDebitItemId(debitNoteData.debit_note_number);
                setDebitNoteStatus(debitNoteData.debit_note_status);
              }}
              isLocked={debitNoteData.is_locked}
              lockDate={debitNoteData.lock_date}
              name="Debit Note"
            />
          ) : null}
          <MoreActions
            debitNoteData={debitNoteData}
            debitNoteRolePermission={debitNoteRolePermission}
            refreshDNDetails={refreshDNDetails}
            isVoid={debitNoteData.debit_note_status === "Void"}
            isTagsAssociated={debitNoteData.is_tags_associated}
          />
        </HeaderBody>
        {isLoading ? <Loader /> : null}
        <DebitNoteDetailBody
          debitNoteData={debitNoteData}
          refreshDNDetails={refreshDNDetails}
          ref={dnDetailRef}
        />
      </div>
      <SendEmailModal
        emailLists={emailList}
        id={Number(editId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteDebitNote
        deleteDebitNoteId={deleteDebitNoteId}
        deleteDebitItemId={deleteDebitItemId}
        debitNoteStatus={debitNoteStatus}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshDebitNotes={fetchDebitNoteList}
        organizationId={currentUserInfo.organization_id}
      />
      <NotificationModal
        modalHeader={
          "Apply Debits from " +
          joinTransactionNumber(debitNoteData.debit_note_number)
        }
        modalBody="There are no bills in the open status for this vendor. Hence, debits cannot be applied."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
}
export default DebitNoteDetailHeader;
