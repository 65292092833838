import * as yup from "yup";
import { ObjectType } from "../../../../../../../types";
import messages from "./messages";

export const validation = yup.object().shape({
  reporting_tag_list: yup.array().of(
    yup.object().shape({
      tag_amount: yup
        .string()
        .test(
          "tag-amount",
          messages.errors.tagAmountRequired,
          function (value) {
            if (!Number(value)) {
              return false;
            }
            return true;
          }
        )
        .test("tag-amount-max", messages.errors.tagAmountMax, function (value) {
          const transaction_amount = this.options.context
            ?.transaction_amount as any;
          if (Number(value) > Number(transaction_amount)) {
            return false;
          }
          return true;
        })
        .test(
          "tag-amount-should-split",
          messages.errors.tagAmountEqualTotalOption,
          function (value) {
            const { option_list, split_type } = this.parent as any;
            let total_amount = option_list?.reduce(
              (acc: number, curr: ObjectType) =>
                Number(acc) + Number(curr.amount),
              0
            );
            if (
              Number(value) !== Number(total_amount) &&
              split_type !== "Split Equally"
            ) {
              return false;
            }
            return true;
          }
        ),
      reporting_tag_id: yup
        .mixed()
        .test(
          "tag-options-not-present",
          messages.errors.tagNoOption,
          function (value) {
            const { option_list, tag_amount } = this.parent as any;

            if (option_list?.length === 0 && tag_amount) {
              return false;
            }
            return true;
          }
        ),
      option_list: yup.array().of(
        yup.object().shape({
          amount: yup.string().required("Please enter a valid amount."),
        })
      ),
    })
  ),
});
