import React from "react";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../../common/components/Delete";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { TaxRateFormContainer } from "../../taxRateForm/TaxRateFormContainer";

type Props = {
  refreshList: () => void;
  deleteOnClick: () => void;
  isAccountDeleted: boolean;
  taxId: number;
};
const ListActions = (props: Props) => {
  const { taxConfigurationPermission } = usePermissionHandler();
  return (
    <KebabMenu>
      <>
        <li>
          <TaxRateFormContainer
            taxId={props.taxId}
            refreshList={props.refreshList}
            isAccountDeleted={props.isAccountDeleted}
            className=""
          >
            <Edit
              url=""
              isMutable={true}
              name="Tax Rate"
              isLocked={false}
              lockDate=""
              className={"dropdown-item"}
            />
          </TaxRateFormContainer>
        </li>
        {taxConfigurationPermission.includes("Delete") && (
          <li>
            <Delete
              className="dropdown-item delete"
              deleteIcon={true}
              isMutable={true}
              onClick={props.deleteOnClick}
              isLocked={false}
              lockDate={""}
              name={"Tax Rate"}
            />
          </li>
        )}
      </>
    </KebabMenu>
  );
};

export default ListActions;
