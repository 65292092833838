import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import "./ColorVariables.css";
import "./index.css";
import UserService from "./keycloak/UserService";
import ErrorBoundary from "./features/common/components/errorBoundary/ErrorBoundary";

const container = document.getElementById("root")!;
const root = createRoot(container);

const renderApp = () =>
  root.render(
    // <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
    // </React.StrictMode>
  );
UserService.initKeycloak(renderApp);
