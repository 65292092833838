import { useState } from "react";
import DropZone from "./dropZone/DropZone";
import { FormikProps } from "formik";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { currentUserSelector } from "../../commonSlice";

import { useParams } from "react-router-dom";
import { FilesValues, ObjectType } from "../../../../types";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { Reducer } from "@reduxjs/toolkit";
// import { AsyncThunk } from 'redux';

type Props = {
  formik: FormikProps<any>;
  attachFiles: AnyAsyncThunk;
  deleteFile:AnyAsyncThunk;
  setIsApiPending?:Reducer

};
const AttachFiles = ({ formik, attachFiles, deleteFile, appendName, setIsApiPending }: any) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const { editId } = useParams();

  /**
   * Upload selected files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append(appendName, file);
    let responseAction = await dispatch(
      attachFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (editId && formik.initialValues.file_ids.includes(fileId)) {
      await dispatch(
        deleteFile({
          paymentMadeId: Number(editId),
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
  };

  const handleSubmitAction = (status: boolean) => {
    if(setIsApiPending){
      dispatch(setIsApiPending(status))
    }
  };

  const changeFiles = (files: ObjectType[]) => {
    formik.setFieldValue("files", files);
    let file_ids_array = files
      .filter((file) => file.status !== "removed")
      .map((file) => file.id);
    formik.setFieldValue("file_ids", file_ids_array);
  };

  return (
    <section id="upload_section">
      <div className="upload-header pb-3">Attach Files</div>
      <DropZone
        uploadedFiles={formik.values.files}
        uploadFileIds={formik.values.file_ids}
        uploadAttachedFiles={uploadAttachedFiles}
        removeFile={removeFile}
        handleSubmitAction={handleSubmitAction}
        changeFiles={changeFiles}
      />
      <span className="error"></span>
    </section>
  );
};
export default AttachFiles;
