import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { useEffect, useState } from "react";
import { checkSubModuleAccessibility } from "../../../../../helpers/modulesAccessibilityHelper";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { SideBarItemProps } from "../../../../../types";
export const SideBarItem = (props: SideBarItemProps, key: number) => {
  const location = useLocation();
  const [path, setPath] = useState("");
  const subModulePermission = useSubModulePermission();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const checkModulePath = (modules: string[]) => {
    return props.moduleName !== "Dashboard"
      ? modules.filter((module) => path.includes(module)).length > 0
        ? "nav-item accordion selected"
        : "nav-item accordion"
      : path.length > 2
      ? "nav-item accordion"
      : "nav-item accordion selected";
  };
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const checkSubModulePermission = () => {
    return props.subModulePermissionToCheck.filter((permission) => {
      return checkSubModuleAccessibility(
        permission,
        subModulePermission,
        currentUserInfo.disabled_feature
      );
    }).length > 0 || props.subModulePermissionToCheck.length === 0
      ? true
      : false;
  };
  return checkSubModulePermission() ? (
    <li key={key} className={checkModulePath(props.pathsToCheck)}>
      <Link className="nav-link menu-item" to={props.path}>
        {props.icon}

        <span className="menu-text">{props.moduleName}</span>
      </Link>
    </li>
  ) : null;
};
