import { ObjectType } from "../../../../types";
import SaveIcon from "../../../../assets/images/save-icon.svg";
import "../../../../index.css";
import './Button.css';

type Props = {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  style?: ObjectType;
  type: "button" | "submit" | "reset" | undefined;
};
export const GreenTickSaveButton = (props: Props) => {
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={props.className}
      hidden={props.isHidden}
      disabled={props.isDisabled}
      style={{ ...props.style, paddingRight: "0px" }}
    >
      <span className="tick-save-btn">
        <img src={SaveIcon} alt="Save" />
      </span>
    </button>
  );
};
