import React from "react";
import { IsFeatureEnabled } from "../../../../../../../helpers/featureFlagHelper";
import { Transactionlimit } from "../../../../../../../types";
import RoleFilter from "./RoleFilter";
import UserFormContainer from "../../userForm/UserFormContainer";
import ListHeader from "../../../common/ListHeader";
import useCommonData from "../../../../../../hooks/useCommon";
import { AlertModal } from "../../../../../../common/components/alertModal/alertModal";
import { message } from "../../../../../../constants/messages";
import { ModalContext } from "../../../../../../common/components/alertModal/alertModalContext";
import UserTransactionLimitModal from "../transactionLimitModal/UserTransactionLimitModal";
import { PlusIcon } from "../../../../../../common/components/customSvgIcons/plusIcon";
import { colors } from "../../../../../../constants/colors";
type Props = {
  transactionLimit: Transactionlimit;
  roleId: number;
  filterWithRoleHandler: (e: any) => void;
  refreshList: () => void;
};
const UsersListHeader = (props: Props) => {
  const { currentUserInfo } = useCommonData();
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  return (
    <div className="card-header card-user-management-header card-no-bg-header">
      <ListHeader />
      <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
        <RoleFilter
          roleId={props.roleId}
          filterWithRoleHandler={props.filterWithRoleHandler}
        />
        <li className="nav-item">
          {usersPermission.includes("Create") &&
          IsFeatureEnabled("Users", currentUserInfo.disabled_feature) ? (
            props.transactionLimit.limit_exceed ? (
              <AlertModal
                buttonContent={
                  <span className="d-flex align-items-center">
                    <PlusIcon color={colors.white} />
                    <span className="create-invoice-button-text green-button-text">
                      Invite Users
                    </span>
                  </span>
                }
                buttonClass="btn create-invoice-button green-button right-button user-transaction-limit-wrapper-class"
                modalContent={
                  <ModalContext.Consumer>
                    {(context) => {
                      const { onCloseModal } = context;
                      return (
                        <UserTransactionLimitModal
                          handleClose={onCloseModal}
                          message={
                            message(props.transactionLimit.limit_count)
                              .transactionLimitText
                          }
                        />
                      );
                    }}
                  </ModalContext.Consumer>
                }
                modalTitle={message().transactionLimitTitle}
                isCloseButtonEnabled={true}
              />
            ) : (
              <UserFormContainer
                id={0}
                isEdit={false}
                editUrl={""}
                isMutable={true}
                name={""}
                isLocked={false}
                className={""}
                isSuperUser={false}
                isTransactionLimitExceed={props.transactionLimit.limit_exceed}
                refreshList={props.refreshList}
              />
            )
          ) : null}
        </li>
      </ul>
    </div>
  );
};

export default UsersListHeader;
