import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ObjectType } from "../../../../../../../types";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { getJVTableHeader, getUpdatedJournals } from "../utils/helper";
import { getReportingTagDropdown } from "../../../../../reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { usePermissionHandler } from "../../../../../../hooks/usePermissionHandler";
import { NumberFormat } from "../../../../../../../helpers/numberFormatHelper";
import { MAX_TAG_COUNT_IN_JOURNAL_LIST } from "../../../../../../constants/constants";
import {
  generateJournal,
  debitNoteDetails,
  updateReportingTags,
} from "../../../../debitNoteSlice";
import Header from "../../../../../../common/components/detailsPage/Header";
import Table from "../../../../../../common/components/table/Table";
import useCommonData from "../../../../../../hooks/useCommon";
import "./journalList.css";
import { Tagchip } from "../../../../../../common/components/ui";
import { AssociatedTagListPopup } from "../../../../../../common/components/reportingTagInTransactions/associatedTagListPopup/AssociatedTagListPopup";

const JournalList = (props: any) => {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const [journals, setJournals] = useState([]);
  const [isMutable, setIsMutable] = useState<boolean>(false);
  const [isVoid, setIsVoid] = useState<boolean>(false);
  const [currency, setCurrency] = useState({
    currency_id: null,
    currency_code: "",
  });
  const [total, setTotal] = useState({
    total_credit: 0,
    total_debit: 0,
  });

  useEffect(() => {
    getJournals();
    getInvoiceDetails();
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  }, [currentUserInfo.organization_id]);

  const getJournals = async () => {
    dispatch(setLoaderState(true));
    const response = await dispatch(
      generateJournal({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    dispatch(setLoaderState(false));
    const responsePayload = response.payload;
    if (Object.keys(responsePayload).length && !("error" in responsePayload)) {
      setJournals(responsePayload.transaction);
      setTotal({
        total_credit: responsePayload.total_credit,
        total_debit: responsePayload.total_debit,
      });
    }
  };

  const getInvoiceDetails = async () => {
    const responseAction = await dispatch(
      debitNoteDetails({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    setIsMutable(responseAction.payload.is_mutable);
    setCurrency({
      currency_id: responseAction.payload.currency_id,
      currency_code: responseAction.payload.currency_code,
    });
    setIsVoid(responseAction.payload.invoice_status === "Void");
  };

  /**
   * set table column headings
   */
  const data = journals
    ? journals.map((journal: ObjectType, index) => ({
        show: {
          account_name: (
            <>
              <span className="account_name ellipsis">
                {journal.account_name}
              </span>
            </>
          ),
          tags: (
            <>
              <div className="reporting-chipsWrapper">
                {journal.reporting_tag_list.map(
                  (reporting_tag: any, index: number) => {
                    if (index >= MAX_TAG_COUNT_IN_JOURNAL_LIST) return;
                    return (
                      <AssociatedTagListPopup
                        buttonContent={
                          <Tagchip margin="0px 5px 5px 0px">
                            {reporting_tag.reporting_tag_name} (
                            {reporting_tag.option_list.length})
                          </Tagchip>
                        }
                        currencyCode={currency.currency_code}
                        reportingTagList={journal.reporting_tag_list}
                        accountName={journal.account_name}
                        tagId={reporting_tag.reporting_tag_id}
                      />
                    );
                  }
                )}
                {journal.reporting_tag_list.length >
                MAX_TAG_COUNT_IN_JOURNAL_LIST ? (
                  <>
                    <span className="report-tags-more-tags">
                      <AssociatedTagListPopup
                        buttonContent={
                          <span className="blue-text-btn">
                            {journal.reporting_tag_list.length -
                              MAX_TAG_COUNT_IN_JOURNAL_LIST +
                              " more tags"}
                          </span>
                        }
                        currencyCode={currency.currency_code}
                        reportingTagList={journal.reporting_tag_list}
                        accountName={journal.account_name}
                      />
                    </span>
                  </>
                ) : null}
              </div>

              <span>
                <Link
                className="text-decoration-none"
                  to={`/debit-note/edit/${editId}/reporting-tags/${journal.account_id}/${journal.entity_id}/${journal.entity_type}`}
                >
                  {journal.reporting_tag_list.length > 0
                    ? "Edit Tag"
                    : "Select tag"}
                </Link>
              </span>
            </>
          ),
          debit:
            journal.transaction_type === "DEBIT"
              ? NumberFormat(
                  journal.transaction_amount,
                  currency.currency_code,
                  orgCurrencyList
                )
              : "",
          credit:
            journal.transaction_type === "CREDIT"
              ? NumberFormat(
                  journal.transaction_amount,
                  currency.currency_code,
                  orgCurrencyList
                )
              : "",
        },
      }))
    : [];

  data.push({
    show: {
      account_name: <></>,
      tags: <></>,
      debit: `${currency.currency_code} ${NumberFormat(
        total.total_debit,
        currency.currency_code,
        orgCurrencyList
      )}`,
      credit: `${currency.currency_code} ${NumberFormat(
        total.total_credit,
        currency.currency_code,
        orgCurrencyList
      )}`,
    },
  });

  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    return getJVTableHeader();
  }, []);

  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 reportingtag-jv docked-footer-page"
        id="invoice-reportingtag"
      >
        <Header
          onBackButtonClick={() => {
            navigate(`/debit-note/detail/${editId}`, {
              replace: true,
            });
          }}
          data={{
            num: "Apply Reporting tags to accounts",
            status: "",
          }}
          dataId={editId}
        />
        <div className="card-body">
          <div className="table-section">
            <div className="table-wrap">
              <Table
                id={""}
                columns={columns}
                data={data}
                tableClassName={"table inv-preview-table report-tags-table"}
                dataSort={false}
                isLoading={false}
                haveColumnClassName={false}
                rowClassName={"table-navigation"}
                emptyClassName={"text-center dataTables_empty"}
                tbodyRowOnClick={() => {}}
                tbodyColumnClick={() => {}}
              >
                {props.children}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JournalList;
