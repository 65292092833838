import TcsForm from "./TcsForm";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import CreateButton from "../../../../common/components/CreateButton";
import { EditIcon } from "../../../../common/components/customSvgIcons/editIcon";
import { colors } from "../../../../constants/colors";

import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import useListData from "../../../../hooks/useListData";

type Props = {
  id: number;
  isEdit: boolean;
  refreshList: () => void;
  createdFrom: string;
  label?: string;
};
function TcsFormContainer(props: Props) {
  // const { setItemsPerPage, setPage } = usePaginationDispatch();
  // const { itemsPerPage } = useListData();
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <TcsForm
                tcsId={props.id}
                refreshList={props.refreshList}
                handleClose={onCloseModal}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        props.createdFrom === "tcs_page" ? (
          props.isEdit ? (
            <span className="dropdown-item w-100">
              <EditIcon color={colors.battleshipGrey} /> Edit
            </span>
          ) : (
            <CreateButton label="Add New TCS" onClick={() => {}} />
          )
        ) : (
          <span className="itemLabel">{props.label}</span>
        )
      }
      modalTitle={props.isEdit ? "Edit" : "Add New TCS"}
      buttonClass="no-appearance w-auto"
      wrapperClass="tcs-edit-side-modal w-100"
    />
  );
}
export default TcsFormContainer;
