import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { Link, useLocation } from "react-router-dom";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { rolesSelector, usersSelector } from "../../userSlice";
import useCommonData from "../../../../hooks/useCommon";

const ListHeader = () => {
  const { currentUserInfo } = useCommonData();
  const userData = useAppSelector(usersSelector);
  const userRoles = useAppSelector(rolesSelector);
  const location = useLocation();

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  let rolePermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Roles")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  return (
    <ul className="nav tab-link top-action-lists float-end p-0 m-0">
      {usersPermission.length === 0 ||
      !IsFeatureEnabled("Users", currentUserInfo.disabled_feature) ? null : (
        <li className="nav-item">
          <Link
            className={`nav-link card-link ${
              path.includes("users") ? "card-active" : ""
            }`}
            to="/users"
          >
            Users<span>({userData.total})</span>
          </Link>
        </li>
      )}
      {rolePermission.length === 0 ||
      !IsFeatureEnabled("Roles", currentUserInfo.disabled_feature) ? null : (
        <li className="nav-item">
          <Link
            className={`nav-link card-link ${
              path.includes("roles") ? "card-active" : ""
            }`}
            to="/roles"
          >
            Roles<span>({userRoles.length})</span>
          </Link>
        </li>
      )}
    </ul>
  );
};

export default ListHeader;
