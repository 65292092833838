export const journalDetailsInitialValues = {
  id: "",
  currecncy_id: "",
  user_id: "",
  organization_id: "",
  journal_status: "",
  journal_voucher_number: "",
  reference_number: "",
  date: "",
  notes: "",
  currency_code: "",
  custom_fields: {},
  created_by: "",
  item_details: [],
  total_amount: "",
  journal_files: [],
  email_to: [],
  total_details: {
    total_amount_debit: "",
    total_amount_credit: "",
    difference_credit: "",
    difference_debit: "",
  },
  organization_name: "",
  is_locked: false,
  lock_date: "",
  is_mutable: true,
  is_fiscal_closed: false,
};
