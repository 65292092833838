import { useState } from "react";
import "./MarkAsDeclined.css";
import CheckBox from "../../../../common/components/checkBox/CheckBox";
import { useNavigate } from "react-router-dom";

const CreateInvoiceContent = (props: any) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleRadioButtonClick = (event: any) => {
    const value = event.value;
    setIsChecked(value);
  };
  const modalOnClose = () => {
    setIsChecked(false);
    props.onCloseModal();
  };
  return (
    <div className="proforma-modal invoice-creation-modal" id="emailToModal">
      <div className="modal-body" role="document">
        <CheckBox
          id="mark_as_invoiced"
          name="mark_as_incoiced"
          value={isChecked}
          checked={isChecked}
          label="Mark as Invoiced"
          onChange={handleRadioButtonClick}
        />
      </div>
      <div
        className="modal-footer justify-content-start"
        id="send-email-button"
      >
        <button
          type="button"
          className="btn send-button"
          data-dismiss="modal"
          onClick={() => {
            navigate(`/invoice/create?proformaId=${props.proformaId}`);
            if (isChecked) {
              props.handleStatusChange("Invoiced");
            }
            props.onCloseModal();
          }}
        >
          Create
        </button>
        <button
          type="button"
          className="btn cancel-button close-button"
          data-bs-dismiss="modal"
          onClick={modalOnClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default CreateInvoiceContent;
