import React from "react";
import Pagination from "../../../../../common/components/table/Pagination";
import TableContainer from "../../../../../common/components/table/TableContainer";
import TableInfo from "../../../../../common/components/table/TableInfo";
import { InvoiceImage } from "../../../../../../assets/images";
import FilterEmptyList from "../../../../../common/components/FilterEmptyList";
import { TableData, Transactionlimit } from "../../../../../../types";
import EmptyList from "../../../../../common/components/EmptyList";
import Loader from "../../../../components/Loader";

type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  JournalEntryRolePermission: any;
  columns: any[];
  data: TableData[];
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
  setPageInfo: (countPerPage: number, page: number) => void;
  sortHandler: (column: string) => void;
  hiddenColumns: string[];
  pageCount: number;
  isLoading: boolean;
  handlePageClick: (event: any) => void;
};

const JournalEntryTable = ({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  pageCount,
  JournalEntryRolePermission,
  columns,
  data,
  isFilterApplied,
  initializeFilter,
  transactionLimit,
  setPageInfo,
  sortHandler,
  hiddenColumns,
  isLoading,
  handlePageClick,
}: Props) => {
  return (
    <>
      <div className="card-body">
        <div
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper creditnote-list-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={setPageInfo}
          />
          <div className="row">
            <div className="col-md-12">
              <TableContainer
                className="list-table cd-note-table table table-striped payment-action-table w-100"
                columns={columns}
                data={data}
                url={"/journal-entry"}
                sortHandler={sortHandler}
                isLoading={isLoading}
                editPermission={JournalEntryRolePermission.includes("Update")}
                isDetailViewExist={true}
                hiddenColumns={hiddenColumns}
              >
                {!isFilterApplied ? (
                  <EmptyList
                    heading={
                      JournalEntryRolePermission.includes("Create")
                        ? "Create journal entry!"
                        : "No Journal entries found!"
                    }
                    subheading={
                      JournalEntryRolePermission.includes("Create")
                        ? "Keep a track of your transactions"
                        : "Oops, There is no Journal entry data available right now"
                    }
                    list="Journal Voucher"
                    alt="create journal entry preview"
                    buttonText="New Journal Entry"
                    navigateLink="/journal-entry/create"
                    isCrateAccess={JournalEntryRolePermission.includes(
                      "Create"
                    )}
                    icon={InvoiceImage}
                    transactionLimit={transactionLimit.limit_exceed}
                  />
                ) : (
                  <FilterEmptyList
                    list="journal vouchers"
                    icon={InvoiceImage}
                    alt="list jv empty"
                    handleReset={initializeFilter}
                  />
                )}
              </TableContainer>
              {isLoading ? <Loader /> : null}
            </div>
          </div>
        </div>

        {/* <div className="card-footer clear-both">
          {totalList > itemsPerPage ? (
            <Pagination
              pageCount={pageCount}
              page={page}
              handlePageClick={handlePageClick}
            />
          ) : (
            ""
          )}
        </div> */}
      </div>
    </>
  );
};

export default JournalEntryTable;
