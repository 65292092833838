import react from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  currentUserSelector,
  loaderStateSelector,
  orgCurrencyListSelector,
} from "../common/commonSlice";
import { useNavigate } from "react-router-dom";

const useCommonData = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const isLoading = useAppSelector(loaderStateSelector);

  return {
    dispatch,
    navigate,
    currentUserInfo,
    orgCurrencyList,
    isLoading,
  };
};

export default useCommonData;
