import { colors } from "../../constants/colors";
import "../styles/AlertModal.css";

type Props = {
  modalId?: string;
  modalHeader: string;
  modalBody: string;
  modalSubmit: string;
  modalCancel: string;
  modalSubmitHandler: () => void;
  modalCancelHandler?: () => void;
  modalType?: string;
};
const NotificationModal = (props: Props) => {
  const modalCancelHandler = () => {
    props.modalCancelHandler && props.modalCancelHandler();
    $(".close-btn").click();
  };
  return (
    <>
      <div
        className="modal alert-modal fade"
        id={
          props.modalId
            ? props.modalId
            : props.modalType === "customer_address"
            ? "addressPopUpModal"
            : "notificationPopupModal"
        }
        tabIndex={-1}
        aria-labelledby="delete_title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered delete-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header position-relative">
                <button
                  type="button"
                  className="close-btn pull-right position-absolute"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z" />
                      <path
                        fill={colors.slate}
                        fillRule="nonzero"
                        d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                      />
                    </g>
                  </svg>
                </button>
                <h5 className="modal-title" id="delete_title">
                  {props.modalHeader}
                </h5>
              </div>
              <div className="modal-body">
                <p>{props.modalBody}</p>
              </div>
              <div className="modal-footer">
                {props.modalCancel ? (
                  <button
                    type="button"
                    className="btn btn-cancel cancel-button"
                    onClick={modalCancelHandler}
                  >
                    {props.modalCancel}
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-ok btn-green"
                  onClick={() => {
                    props.modalSubmitHandler();
                    $(".close-btn").click();
                  }}
                >
                  {props.modalSubmit}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationModal;
