import React from "react";
import Modal from "@mui/material/Modal";
import CloseButton from "../../../../common/components/button/CloseButton";
import "./OrganizationListModal.css";
import { UserOrganizationValues } from "../../../../../types";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { OrgGroupIcon } from "../../../../../assets/images";
import { OrgIcon } from "../../../../common/components/customSvgIcons/orgIcon";
import { colors } from "../../../../constants/colors";
type Props = {
  invitedOrganizationList: UserOrganizationValues;
  selectOrganizationHandler: (orgId: number) => void;
  closeModal: () => void;
  showCloseButton: boolean;
};
const OrganizationListModal = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);

  /**
   * Modal close handler
   */
  const closeModalHandler = (event: any, reason: any) => {
    if (
      !props.showCloseButton &&
      reason &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      return;
    }
    props.closeModal();
  };
  const orgSelectHandler = (orgId: number) => {
    props.selectOrganizationHandler(orgId);
    props.closeModal();
  };
  return (
    <Modal
      open={true}
      tabIndex={-1}
      onClose={closeModalHandler}
      className="organization-list-modal"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-dialog">
          <div className="modal-content org-list-modal-content">
            <div className="modal-header position-relative">
              <span>Choose your organization</span>
              {props.showCloseButton && (
                <div className="close-button-container">
                  <CloseButton closeModalHandler={() => props.closeModal()} />
                </div>
              )}
            </div>
            <ul className="organization-list-wrapper">
              {props.invitedOrganizationList.organization_details
                .filter((org) => org.is_suspended !== true)
                .map((org) => {
                  return (
                    <li
                      className={`multiple-organization-list ${
                        props.invitedOrganizationList.last_login_org_id ===
                        org.id
                          ? "org-selected"
                          : ""
                      }`}
                      onClick={() => orgSelectHandler(org.id)}
                    >
                      <span className="organization-name-wrapper">
                        <OrgIcon
                          color={colors.battleshipGrey}
                          margin="0px 5px 0px 0px"
                          height="30px"
                          width="30px"
                        /><span className="organization-list-orgname">
                        {org.name}</span>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationListModal;
