import React from "react";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Clone from "../../../../../common/components/kebabMenu/CloneRole";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import StopResume from "../../../../../common/components/kebabMenu/StopResume";
import { RIListActionValueProps } from "../../../recurringInvoiceTypes";
import Void from "../../../../../common/components/Void";
import Delete from "../../../../../common/components/Delete";
import SendReminder from "../../../../../common/components/kebabMenu/SendReminder";

export type ListActionProps = {
  value: RIListActionValueProps;
  onClickDelete: () => void;
  onClickVoid: () => void;
  onStopResume: () => void;
  sendReminder: () => void;
  send_reminder: boolean;
};
const ListActions = (props: ListActionProps) => {
  
  const { recurringInvoicesRolePermission } = usePermissionHandler();

  return (
    <KebabMenu>
      {(recurringInvoicesRolePermission.includes("Update") && props.value.status.toLowerCase() !== "cancelled")  ? (
        <li className={`${(props.value.status.toLowerCase() === "expired") ? "disable-btn" : "" }`}>
          <Edit
            url={`/recurring-inv/edit/${props.value.id}`}
            isMutable
            name="Invoice"
            isLocked={false}
            className={`dropdown-item ${(props.value.status.toLowerCase() === "expired" || props.value.status.toLowerCase() === "cancelled") ? "li-disables" : "" }`}
            lockDate={""}
            isDisabled={
              props.value.status.toLowerCase() === "expired" ||
              props.value.status.toLowerCase() === "cancelled"
            }
          />
        </li>
      ) : null}
      {recurringInvoicesRolePermission.includes("Delete") ? (
          <li>
            <Delete
              className="dropdown-item delete"
              deleteIcon={true}
              isMutable
              onClick={props.onClickDelete}
              isLocked={false}
              lockDate={""}
              name="Invoice"
              child_invoice_exists={
                props.value.child_invoice_exists
                  ? "child_invoice_exists"
                  : "child_invoice_not_exists"
              }
            />
          </li>
          ) : null}
         {recurringInvoicesRolePermission.includes("Cancel") ? (<>
          {props.value.status.toLowerCase() !== "cancelled" && <li>
            <Void
              className="dropdown-item delete"
              deleteIcon={true}
              isMutable
              onClick={props.onClickVoid}
              isLocked={false}
              lockDate={""}
              name="Invoice"
              status={props.value.status}
            />
          </li> }
         </>) : null }
      {recurringInvoicesRolePermission.includes("Create") ? (
        <li>
          <Clone url={`/recurring-inv/clone/${props.value.id}`} />
        </li>
      ) : null}
      {(recurringInvoicesRolePermission.includes("Stop") && props.value.status.toLowerCase() !== "cancelled") ? (
        <li
          style={
            props.value.status.toLowerCase() === "expired"
              ? { pointerEvents: "none" }
              : {}
          }
        >
          <StopResume
            className="dropdown-item delete"
            isDisabled={props.value.status.toLowerCase() === "expired"}
            isStopped={props.value.isStopped}
            onStopResumeClick={props.onStopResume}
          />
        </li>
      ) : null}
      {recurringInvoicesRolePermission.includes("Create") ? (
      <li>
        <SendReminder
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.sendReminder}
          send_reminder={props.send_reminder}
          isLocked={false}
          lockDate={""}
          name="Invoice"
        />
      </li>) : null }
    </KebabMenu>
  );
};

export default React.memo(ListActions);
