import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { autoGenerateShortCode } from "../../../../../helpers/shortCodeHelper";
import { checkShortCode } from "../../customerSlice";
import useCommonData from "../../../../hooks/useCommon";
import ShortCode from "../../../../common/components/ShortCode/ShortCode";

type Props = {
  shortCode: string;
  customerId: number;
  customerName: string;
  handleChange: (data: { name: string; value: string }) => void;
  error: string;
  isRequired: boolean;
};
interface ComponetRef {
  // getData: () => { value: string; isValid: boolean };
  isShortCodeExist: boolean;
}
let interval: any = null;
const CustomerCode: ForwardRefRenderFunction<ComponetRef, Props> = (
  props,
  ref
) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [shortCode, setShortCode] = useState("");
  const [isShortCodeExist, setIsShortCodeExist] = useState(false);
  const [isShortCodeSuccess, setIsShortCodeSuccess] = useState(false);
  const [isShortCodeLoader, setIsShortCodeLoader] = useState(false);
  const [errorMessage, setErrorMeassage] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      isShortCodeExist,
    }),
    [isShortCodeExist]
  );

  useEffect(() => {
    setShortCode(props.shortCode);
  }, [props.shortCode]);

  useEffect(() => {
    if (props.error) {
      setErrorMeassage(props.error);
    }
  }, [props.error]);

  useEffect(() => {
    checkCustomerShortCode();
  }, [props.customerName]);

  useEffect(() => {
    props.handleChange({ name: "short_code", value: shortCode });
  }, [shortCode]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const shortCodeRegex = /^[a-zA-Z0-9]+$/;
    if (shortCodeRegex.test(value) || value === '') {
      setShortCode(value.toUpperCase());
      checkCustomShortCode(event);
    }
  };

  const checkCustomerShortCode = async () => {
    if (interval) {
      clearTimeout(interval);
    }
    interval = setTimeout(async () => {
      if (props.customerName.trim()) setIsShortCodeLoader(true);
      let shortCodeTemp = autoGenerateShortCode(props.customerName);
      if (shortCodeTemp) {
        const responseAction = await dispatch(
          checkShortCode({
            customerName: props.customerName.trim(),
            shortCode: shortCodeTemp,
            orgId: currentUserInfo.organization_id,
          })
        );

        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (!response.code_exist) {
              setShortCode(shortCodeTemp);
            } else {
              setShortCode(response.suggested_code);
            }
            setIsShortCodeExist(false);
            setErrorMeassage("");
          }
        } else {
          setShortCode(shortCodeTemp);
        }
      } else {
        setShortCode("");
      }
      setIsShortCodeLoader(false);
    }, 1000);
  };

  /**
   * Check the custom short code entered by the customer
   */
  const checkCustomShortCode = async (e: any) => {
    if (interval) {
      clearTimeout(interval);
    }
    interval = setTimeout(async () => {
      const customerName = props.customerName;
      e.preventDefault();
      let shortCode = e.target.value.trim();

      if (shortCode) {
        setIsShortCodeSuccess(false);
        setIsShortCodeExist(false);
        setIsShortCodeLoader(true);
        setErrorMeassage("");
        shortCode = shortCode.toUpperCase();
        const responseAction = await dispatch(
          checkShortCode({
            customerName,
            shortCode,
            orgId: currentUserInfo.organization_id,
          })
        );
        setIsShortCodeLoader(false);
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (!response.code_exist) {
              setIsShortCodeSuccess(true);
            } else {
              setIsShortCodeExist(true);
            }
          }
        }
      } else {
        setIsShortCodeSuccess(false);
        setIsShortCodeExist(false);
      }
    }, 1000);
  };

  return (
    <ShortCode
      label="Customer Code"
      shortCode={props.shortCode}
      isRequired={props.isRequired}
      errorMessage={
        isShortCodeExist
          ? "Customer code already exists. Please choose a different one"
          : isShortCodeSuccess && shortCode.length >= 1
          ? "Customer Code is available!"
          : errorMessage
      }
      handleChange={handleChange}
      isShortCodeSuccess={isShortCodeSuccess}
      isShortCodeExist={isShortCodeExist}
      isShortCodeLoader={isShortCodeLoader}
      infoMessage={
        "Short code can be upto 4 characters long. Short code will be used as a prefix in all customer identifiers."
      }
      isDisabled={props.customerId ? true : false}
    />
  );
};

export default forwardRef(CustomerCode);
