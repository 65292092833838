import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import {
  UploadIcon,
  PDFIcon,
  CloseImg,
  ImageIcon,
  FileLoaderImage,
  ErrorImg,
} from "../../../../../assets/images";
import { FormatBytes } from "../../../../../helpers/helper";
import { FilesValues, ObjectType } from "../../../../../types";
import "../../../../../index.css";
import "./Dropzone.css";
import { toast } from "react-toastify";
import { uploadFileValidator } from "../../../../../helpers/fileValidationHelper";

type Props = {
  uploadedFiles: any;
  uploadFileIds: [];
  uploadAttachedFiles: (data: any) => Promise<ObjectType>;
  removeFile: (id: any) => Promise<any>;
  handleSubmitAction: (e: boolean) => void;
  changeFiles?: (files: ObjectType[]) => void;
};

const Dropzone = (props: Props, ref: any) => {
  const uploadedFilesRef = useRef([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>(props?.uploadedFiles);
  const [fileIds, setFileIds] = useState<any>(props?.uploadFileIds);
  const isValidFile = useRef(false);
  useEffect(() => {
    Array.isArray(props?.uploadedFiles) &&
      setUploadedFiles([...props?.uploadedFiles]);
    setFileIds([...props?.uploadFileIds]);
  }, [props?.uploadedFiles, props?.uploadFileIds]);

  useImperativeHandle(
    ref,
    () => ({
      uploadedFiles: uploadedFiles,
      fileIds: fileIds,
    }),
    [uploadedFiles, fileIds]
  );

  useEffect(() => {
    uploadedFilesRef.current = uploadedFiles;

    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0
    ).length;
    let isProgressFileExist = false;
    if (onProgressFiles) {
      isProgressFileExist = true;
    } else {
      isProgressFileExist = false;
    }
    handleSubmit(isProgressFileExist);
  }, [uploadedFiles]);

  const handleSubmit = async (isProgressFileExist: boolean) => {
    props.handleSubmitAction(isProgressFileExist);
  };
  /**
   * Function to choose or drag and drop files
   */
  const onDrop = async (acceptedFiles: any) => {
    let uploadedCount = uploadedFiles.filter(
      (files: any) => files.status !== "removed"
    ).length;
    if (uploadedCount === 3 || uploadedCount + acceptedFiles.length > 3) {
      toast.error("Failed to upload file(s). Maximum 3 files allowed!", {
        icon: ({ theme, type }) => (
          <img src={ErrorImg} width="16" height="14" alt="error-img" />
        ),
        toastId: "file-upload-failed-error",
        closeButton: false,
        className: "toast-error",
        position: "top-center",
      });
    } else {
      for (let file of acceptedFiles) {
        isValidFile.current = await uploadFileValidator(file, ["pdf"]);
        if (isValidFile.current) {
          let index =
            uploadedFilesRef.current.push({
              file_name: file.name,
              file_size: FormatBytes(file.size),
              file_type:
                file.type === "application/pdf"
                  ? "pdf"
                  : file.type === "application/jpeg"
                  ? "jpeg"
                  : file.type === "application/png"
                  ? "png"
                  : "",
              progressInfo: 0,
              status: "inserted",
            } as never) - 1;
          uploadFile(file, 0, index);
          setUploadedFiles([...uploadedFilesRef.current]);

          // updating the upload files data into parent if change handler function(optional) is passed
          if (props.changeFiles)
            props.changeFiles([...uploadedFilesRef.current]);
        } else {
          toast.error(`Failed to upload "${file.name}". Invalid format`, {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} width="16" height="14" alt="error-img" />
            ),
            toastId: "file-upload-failed-error",
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
        }
      }
    }
  };
  const maxSize = 1572864;
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    fileRejections,
    open,
  } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    minSize: 0,
    maxSize,
    noClick: true,
    maxFiles: 3,
  });

  const [rejectedList, setRejectedList] = useState(fileRejections);

  useEffect(() => {
    setRejectedList([...rejectedList, ...fileRejections]);
  }, [fileRejections]);

  useEffect(() => {
    let errorMessage = "";
    let isLarger = false;
    let isInvalidType = false;
    let isTooMany = false;
    fileRejections.map((rejectFile, RejectKey) => {
      if (rejectFile.errors[0].code === "too-many-files") {
        isTooMany = true;
      }
      if (rejectFile.errors[0].code === "file-too-large") {
        isLarger = true;
      }
      if (rejectFile.errors[0].code === "file-invalid-type") {
        isInvalidType = true;
      }
    });
    if (isTooMany) {
      errorMessage = "Failed to upload file(s). Maximum 3 files allowed!";
    } else {
      if (isLarger && isInvalidType) {
        errorMessage =
          "Failed to upload file(s). Maximum file size is 1.5 MB and File type must be pdf, jpeg or png!";
      } else if (isLarger) {
        errorMessage = "Failed to upload file(s). Maximum file size is 1.5 MB!";
      } else if (isInvalidType) {
        errorMessage =
          "Failed to upload file(s). File type must be pdf, jpeg or png!";
      }
    }
    if (isTooMany || isLarger || isInvalidType) {
      toast.error(errorMessage, {
        icon: ({ theme, type }) => (
          <img src={ErrorImg} width="16" height="14" alt="error-img" />
        ),
        toastId: "file-upload-failed-error",
        closeButton: false,
        className: "toast-error",
        position: "top-center",
      });
    }
  }, [fileRejections]);

  const uploadFile = async (file: any, progress: number, index: number) => {
    let responseAction = await props.uploadAttachedFiles(file);
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (uploadedFilesRef.current[index]) {
          uploadedFilesRef.current[index] = {
            ...(uploadedFilesRef.current[index] as object),
            ...{
              progressInfo: 100,
              id: response.file_id,
              download_link: response.download_link,
            },
          } as never;
          fileIds.push(response.file_id);
          setUploadedFiles([...uploadedFilesRef.current]);

          // updating the upload files data into parent if change handler function(optional) is passed
          if (props.changeFiles)
            props.changeFiles([...uploadedFilesRef.current]);
        }
      } else {
        props.handleSubmitAction(false);
      }
    }
  };

  const remove = async (file: FilesValues) => {
    let index = uploadedFiles.indexOf(file);
    uploadedFiles[index] = { ...uploadedFiles[index], status: "removed" };
    if (uploadedFiles[index].id) {
      let fileId = uploadedFiles[index].id;
      fileIds.splice(fileIds.indexOf(fileId), 1);
      await props.removeFile(fileId);
    }
    setUploadedFiles([...uploadedFiles]);

    // updating the upload files data into parent if change handler function(optional) is passed
    if (props.changeFiles) props.changeFiles([...uploadedFiles]);
  };

  const fileCount = uploadedFiles?.filter(
    (files: any) => files.status !== "removed"
  ).length;

  const files = uploadedFiles
    ?.filter((files: any) => files.status !== "removed")
    .map((file: FilesValues, index: number) => {
      let fileType = file.file_type
        ? file.file_type
        : file.file_name?.split(".").pop();
      return (
        <li className="pe-0 mb-3" key={file.file_name + index}>
          <input type="hidden" name="fileId" value={file.id} />
          <div className="d-flex justify-content-between pb-3 w-100 file-details-container">
            <div className="d-flex align-items-center position-relative">
              <a
                href={file.download_link ? file.download_link : "#"}
                target={file.download_link ? "_blank" : ""}
                rel="noopener noreferrer"
                className={
                  file.download_link
                    ? "file-item-inserted d-flex"
                    : "file-item-choose d-flex"
                }
                onClick={(e) => {
                  if (!file.download_link) {
                    e.preventDefault();
                  }
                }}
              >
                <img
                  className="ps-2"
                  src={fileType === "pdf" ? PDFIcon : ImageIcon}
                  alt="file icon"
                />
                <div className="file-name">{file.file_name}</div>
              </a>
              <div className="file-size pe-3">{file.file_size}</div>
              {file?.progressInfo === 0 ? (
                <img
                  className="file-loader"
                  src={FileLoaderImage}
                  alt="file loading"
                  width="20"
                  height="20"
                />
              ) : null}
            </div>
            <a
              className="delete-button"
              type="button"
              onClick={() => remove(file)}
            >
              <img className="remove-icon" src={CloseImg} alt="remove file" />
            </a>
          </div>
        </li>
      );
    });

  return (
    <div className="mx-0 px-0 dropzone-container">
      <div {...getRootProps({ className: "dropzone" })} tabIndex={-1}>
        {fileCount < 3 ? <input {...getInputProps()} onDrop={onDrop} /> : null}
        <img src={UploadIcon} alt="upload icon" />
        <p className="upload-msg mb-0">
          Drag and drop some files here or{" "}
          <a
            type="button"
            onClick={open}
            tabIndex={0}
            className={
              fileCount >= 3 ? "upload-link disabled-link" : "upload-link"
            }
          >
            browse
          </a>
        </p>
        <p className="max-size mb-0">
          Max. Files: 3, Max. File size: 1.5 MB (pdf, jpeg, png)
        </p>
      </div>
      {fileCount ? (
        <section className="uploaded-files-section">
          <h4 className="mb-4">Uploaded Files</h4>
          <ul className="px-0 mb-0" id="uploaded_files_list">
            {files}
          </ul>
        </section>
      ) : null}
    </div>
  );
};

export default forwardRef(Dropzone);
