import React from "react";
import CustomDatepicker from "../../../utils/atoms/datepicker";
import { FormikProps } from "formik";
import { FormInputField } from "../formInputField/FormInputField";
import { FormSelectField } from "../formSelectField/FormSelectField";
import { customSelectStyle } from "../SelectCustomStyle";
import moment from "moment";
import { getFormatedDate } from "../../../../helpers/helper";
import CustomTooltips from "../Tooltip";
import { InfoIconImg } from "../../../../assets/images";

type Props = {
  isActive: boolean;
  editId: string | undefined;
  checkExpiry: string;
  checkNumber: string;
  handleDate: (date: Date | null, type: string) => void;
  handleChange: (e: any) => void;
  formErrors: any;
  formik?: FormikProps<any>;
  module?: "PR" | "PM";
};
function CheckDetails(props: Props) {
  return (
    <>
      <div className="row mb-0">
        <div
          className={
            props.isActive || props.editId
              ? "col-12 col-lg-4 mw-325 mb-0"
              : "col-12 col-lg-4 mw-325 disabled-item"
          }
        >
          <label htmlFor="check_date" className="required">
            Check Date
          </label>
          <CustomTooltips
            title={
              "Check date is the date written on a check by the issuer, indicating when the check was written or issued."
            }
            placement="bottom"
          >
            <img
              style={{ marginLeft: "5px" }}
              src={InfoIconImg}
              width="16"
              alt="info icon"
            />
          </CustomTooltips>
          <div id="date" className="date-selector-wrapper">
            <CustomDatepicker
              date={new Date(props.formik?.values.check_date)}
              handleDate={props.handleDate}
              type="paymentDate"
              error={
                props.formErrors?.check_date && props.formik?.touched.check_date
                  ? "error"
                  : ""
              }
              id="check_date"
              zIndex={0}
              placeholder="Enter check expiry date"
              enableFutureDate={true}
            />
          </div>
          <span className="error">
            {props.formErrors?.check_date && props.formik?.touched.check_date
              ? props.formErrors?.check_date
              : ""}
          </span>
        </div>
        <FormSelectField
          id="check_expiry_in"
          name="check_expiry_in"
          className=""
          wrapperClass="mw-325 mb-0"
          options={[
            { value: "90 days", label: "90 days" },
            { value: "180 days", label: "180 days" },
          ]}
          value={
            props.formik?.values.check_expiry_in
              ? {
                  value: props.formik?.values.check_expiry_in,
                  label: props.formik?.values.check_expiry_in,
                }
              : ""
          }
          onChange={(e) => {
            props.formik?.setValues((values: any) => {
              return {
                ...values,
                check_expiry_in: e && e.label,
                check_expiry: getFormatedDate(
                  moment(props.formik?.values.check_date)
                    .add(e && e.value === "90 days" ? 90 : 180, "days")
                    .toDate()
                ),
              };
            });
          }}
          isDisabled={false}
          isOnlyInRow={false}
          isRequired={true}
          label="Expiry in"
          error={
            props.formErrors?.check_expiry_in &&
            props.formik?.touched.check_expiry_in
              ? props.formErrors?.check_expiry_in
              : ""
          }
          styles={customSelectStyle}
          placeholder="Choose an option"
          isSearchable={false}
        />
      </div>
      <div className="row mb-0">
        <div
          className={
            props.isActive || props.editId
              ? "col-12 col-lg-4 mw-325 mb-3"
              : "col-12 col-lg-4 mw-325 disabled-item"
          }
        >
          <label htmlFor="check_expiry" className="">
            Expiry Date
          </label>
          <div id="date" className="date-selector-wrapper">
            <CustomDatepicker
              date={
                new Date(props.formik?.values.check_expiry || getFormatedDate())
              }
              name="check_expiry"
              handleDate={() => {}}
              type="paymentDate"
              error={
                props.formErrors?.check_expiry &&
                props.formik?.touched.check_expiry
                  ? "error"
                  : ""
              }
              id="check_expiry"
              zIndex={0}
              placeholder="Enter check expiry date"
              enableFutureDate={true}
              isDisabled={true}
            />
          </div>
          <span className="error">
            {props.formErrors?.check_expiry &&
            props.formik?.touched.check_expiry
              ? props.formErrors?.check_expiry
              : ""}
          </span>
        </div>
        <FormInputField
          type="text"
          name="check_number"
          id="check_number"
          className={`form-control bank-tax-input mw-325 mb-0 ${
            props.formErrors?.check_number && props.formik?.touched.check_number
              ? "error"
              : ""
          }`}
          onChange={(e) => {
            props.formik?.setFieldValue("check_number", e.target.value);
          }}
          placeholder="Enter check number"
          maxLength={17}
          value={props.formik?.values.check_number}
          label="Check Number"
          error={
            props.formErrors?.check_number && props.formik?.touched.check_number
              ? props.formErrors?.check_number
              : ""
          }
          isRequired={true}
          errorClass="mb-2"
        />
      </div>
    </>
  );
}

export default React.memo(CheckDetails);
