import React, { useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  vendorDropdownList,
  vendorListSelector,
} from "../../home/vendorManagement/vendorSlice";
import { currentUserSelector } from "../commonSlice";
import { customSelectStyle } from "./SelectCustomStyle";

type Props = {
  vendorId: string | number;
  errors: string;
  onChange: (e: { label: string; value: string | number }) => void;
  isDisabled?: boolean;
};
function VendorSelect({ vendorId, errors, onChange, isDisabled }: Props) {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const vendorList = useAppSelector(vendorListSelector);

  useEffect(() => {
    getSearchVendorsList();
  }, [currentUserInfo.organization_id]);

  const getSearchVendorsList = async () => {
    await dispatch(vendorDropdownList(currentUserInfo.organization_id));
  };
  /**
   * Get the list of all customers
   */
  const vendorListOptions = vendorList.map((vendor, index) => ({
    label: vendor.vendor_display_name,
    value: vendor.id,
  }));

  /**
   * Get the vendor name in the selection dropdown : Edit bill
   */
  const vendorName = vendorList
    .filter((vendor) => vendor.id === Number(vendorId))
    .map((vendor) => ({
      label: vendor.vendor_display_name,
      value: vendor.id,
    }));
  return (
    <Select
      name="vendor_id"
      value={vendorName}
      options={vendorListOptions}
      placeholder="Choose vendor"
      onChange={(e) => {
        if (e) onChange({ label: e?.label, value: e?.value });
      }}
      className={`form-select custom-select vendor-select vendor_name ${
        errors ? "error" : ""
      }`}
      styles={customSelectStyle}
      isDisabled={isDisabled}
    />
  );
}

export default VendorSelect;
