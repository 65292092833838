import { toast } from "react-toastify";
import { deleteUserRole } from "../../userSlice";
import DeleteModal from "../../../../common/components/DeleteModal";
import useCommonData from "../../../../hooks/useCommon";
import NotificationModal from "../../../../common/components/NotificationModal";

type Props = {
  deleteUserRoleId: number;
  roleCount: number;
  roleName: string;
  refreshList: (roleSort: string) => void;
};
const DeleteUserRole = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const data = {
      id: props.deleteUserRoleId,
      orgId: currentUserInfo.organization_id,
    };
    const deleteRoleResponseAction = await dispatch(deleteUserRole(data));
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length) {
        $(".close-btn").click();
        props.refreshList('');
        toast.success("Role deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <>
      <DeleteModal
        heading="Delete Role!"
        subHeading="Are you sure you want to delete the role"
        transactionName={props.roleName}
        modalCancelHandler={modalCancelHandler}
        modalSubmitHandler={modalSubmitHandler}
      />
      <NotificationModal
        modalId="deleteConfMessageModal"
        modalHeader={`Sorry! ${props.roleName} role cannot be deleted`}
        modalBody={`${
          props.roleCount === 1
            ? props.roleCount + " user is"
            : props.roleCount + " users are"
        } already assigned as ${props.roleName}. User role can be deleted
        only after all users are unassigned from that role.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};
export default DeleteUserRole;
