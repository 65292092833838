import { CreateTaxValues, ObjectType } from "../../../../../types";

export const ValidateTaxRateForm = (values: CreateTaxValues) => {
  const errors: ObjectType = {};
  if (!values.tax_code.toString().trim()) {
    errors.tax_code = "Please enter tax code";
  }
  if (values.account_id === 0) {
    errors.account = "Please choose an account";
  }
  return errors;
};
