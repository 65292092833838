import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCustomFields,
  editCustomFields,
  getCustomFieldModuleLists,
  getCustomFieldTotalLists,
  deleteCustomFields,
  customFieldExistCheck,
} from "./customFieldApi";
import { CustomFieldCreateEditValues } from "../../../../../types";

export const createCustomField = createAsyncThunk(
  "create/custom-field",
  async (data: {
    value: CustomFieldCreateEditValues;
    orgId: number;
    transaction_type: string;
  }) => {
    return await createCustomFields(data);
  }
);

export const editCustomField = createAsyncThunk(
  "edit/custom-field",
  async (data: {
    value: CustomFieldCreateEditValues;
    orgId: number;
    custom_field_id: number | string | undefined;
  }) => {
    return await editCustomFields(data);
  }
);

export const getCustomFieldModuleList = createAsyncThunk(
  "module-list/custom-field",
  async (data: { orgId: number; transaction_type: string }) => {
    return await getCustomFieldModuleLists(data);
  }
);

export const getCustomFieldTotalList = createAsyncThunk(
  "total-list/custom-field",
  async (data: { orgId: number }) => {
    return await getCustomFieldTotalLists(data);
  }
);

export const deleteCustomField = createAsyncThunk(
  "delete/custom-field",
  async (data: { orgId: number; custom_field_id: number }) => {
    return await deleteCustomFields(data);
  }
);

export const checkCustomFieldExists = createAsyncThunk(
  "custom-field/is-exist",
  async (data: {
    orgId: number;
    field_name: string;
    module: string;
    signal: AbortSignal;
  }) => {
    return await customFieldExistCheck(data);
  }
);
