import * as React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { exchangeApiStatusSelector } from "../../../../common/commonSlice";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  editId: string | undefined;
  billStatus: string;
  isFormSubmit: boolean;
  onClickSubmit: (status: string, action: string) => void;
  // redirectUrl: string;
  rolePermission: any;
  onClickDelete: (e: any) => void;
  vendorSelected?: boolean;
};
function BillFormFooter({
  editId,
  billStatus,
  isFormSubmit,
  onClickSubmit,
  // redirectUrl,
  rolePermission,
  onClickDelete,
  vendorSelected,
}: Props) {
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const navigate = useNavigate();
  return (
    <div className="row mb-0">
      <div className="col">
        <div
          className={
            isFormSubmit && !exchangeRateAPiStatus
              ? "form-button-wrapper d-flex w-100 px-4 docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          <div className="btn-group save-as-open-btn-group ps-3 ms-1">
            <button
              type="button"
              className="save-button save-only-button mx-0"
              onClick={() => onClickSubmit("Open", "Send")}
              disabled={
                isFormSubmit && !exchangeRateAPiStatus && vendorSelected
                  ? false
                  : true
              }
            >
              Save as Open
            </button>
          </div>

          <button
            type="button"
            className="cancel-button save-as-draft-button"
            onClick={() => onClickSubmit("Draft", "")}
            disabled={
              (editId && billStatus !== "Draft") || exchangeRateAPiStatus || !vendorSelected
                ? true
                : false
            }
            style={
              (editId && billStatus !== "Draft") || exchangeRateAPiStatus
                ? { opacity: 0.5 }
                : {}
            }
          >
            Save as Draft
          </button>
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="text-decoration-none cancel-button mx-4"
          >
            Cancel
          </button>

          {editId && rolePermission.includes("Delete") ? (
            <a
              href="#"
              className="text-decoration-none delete-item-button ml-auto"
              data-bs-toggle="modal"
              data-bs-target="#deleteMessageModal"
              onClick={onClickDelete}
            >
              Delete
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default BillFormFooter;
