import React from "react";
import { PlusSmallImg } from "../../../../../../assets/images";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  currencyDropdown,
  getCountries,
  getSuperParent,
} from "../../../../../common/commonSlice";
import { useNavigate } from "react-router-dom";
import CoaSearchSuggestionBox from "./CoaSearchSuggestionBox";
import CoaListViewIcon from "../../../../../common/components/customSvgIcons/CoaListViewIcon";
import CoaTreeViewIcon from "../../../../../common/components/customSvgIcons/CoaTreeViewIcon";
import useCommonData from "../../../../../hooks/useCommon";
type Props = {
  totalResults: number;
  isSearch: boolean;
  resetInput: () => void;
  resetOnOrgChange: () => void;
  setAccountName: (value: string) => void;
  searchSuggestions: (value: string, e: any) => Promise<any>;
  setPage: (value: number) => void;
};
const CoaListHeader = (props: Props) => {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const superParent = useAppSelector(getSuperParent);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let chartOfAccountPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Chart of Account"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  /**
   * Switch to tree view
   */
  const treeViewHandler = () => {
    navigate("/accounts/tree");
  };
  /**
   * Fetch all country list
   */
  const getAllCountries = async () => {
    await dispatch(getCountries());
  };
  /**
   * Fetch all currency list
   */
  const getCurrencyList = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };

  return (
    <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
      <h1 className="fw-bold">Chart of Accounts</h1>
      <CoaSearchSuggestionBox
        totalResults={props.totalResults}
        isSearch={props.isSearch}
        resetInput={props.resetInput}
        resetOnOrgChange={props.resetOnOrgChange}
        setAccountName={props.setAccountName}
        searchSuggestions={props.searchSuggestions}
        setPage={props.setPage}
      />
      <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
        <li className="nav-item">
          {chartOfAccountPermission.includes("Create") && superParent ? (
            <button
              className="btn add-customer-button green-button"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addAccountModal"
              onClick={() => {
                getAllCountries();
                getCurrencyList();
              }}
            >
              <img src={PlusSmallImg} alt="plus-img" className="Group" />
              <span className="invite-user-button-text green-button-text">
                Add Account
              </span>
            </button>
          ) : null}
        </li>
        <div className="toggle-view">
          <button className="list-view active">
            <CoaListViewIcon />
          </button>
          <button className="tree-view" onClick={() => treeViewHandler()}>
            <CoaTreeViewIcon />
          </button>
        </div>
      </ul>
    </div>
  );
};

export default CoaListHeader;
