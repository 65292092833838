import React from "react";
import { FormInputField } from "./formInputField/FormInputField";

type Props = {
  referenceNumber: string;
  handleChange: (e: any) => void;
  error: string;
  wrapperClass?: string;
  isDisabled?: boolean;
};
function ReferenceNumber(props: Props) {
  return (
    <FormInputField
      type="text"
      name="reference_number"
      id="ref_no"
      className={`${
        props.error ? "form-control error" : "form-control"
      } mw-none`}
      label="Reference Number"
      placeholder="Enter reference number"
      maxLength={16}
      value={props.referenceNumber}
      onChange={props.handleChange}
      error={props.error}
      wrapperClass={`mb-3  ${props.wrapperClass ? props.wrapperClass : ""} `}
      disabled={props.isDisabled}
    />
  );
}

export default ReferenceNumber;
