import React, { forwardRef } from "react";
import ReportFilter from "../../componets/ReportFilter";
import { getFormatedDate } from "../../../../../helpers/helper";
import ReportHeader from "../../componets/ReportHeader";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import { ObjectType } from "../../../../../types";
import "./AgingReport.css";
import CustomizeReportModal from "../../../../common/components/reportCustomizationModal/CustomizeReportModal";
import { message } from "../../../../constants/messages";
import DropdownWithActions from "../../../../common/components/dropdownWithActions/DropdownWithActions";
import SavedCustomization from "../../../../common/components/reportCustomizationModal/CustomizeAgingReport/SavedCustomization";

type Props = {
  title: string;
  handleReport: (type: string) => void;
  reportData: ObjectType;
  clearReport: () => void;
  showReport: boolean;
  type: string;
  module: string;
  children: React.ReactNode;
};
export const initialAgingReportData = {
  currency_id: 0,
  currency_code: "INR",
  date_range: "today",
  organization_id: 0,
  interval_range: 0,
  interval_type: "",
  number_of_columns: 0,
  start_date: getFormatedDate(),
  end_date: getFormatedDate(),
  items: [],
  organization_details: {
    organization_name: "",
    email: "",
    country: "",
    state: "",
  },
};
function AgingReport(props: Props, ref: any) {
  return (
    <div className="reports aging-report">
      <div
        className="card card-dashboard report-card aging-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Aging Report</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={true}
                isAdvanced={true}
                defaultValue="today"
                reportType="AR"
                handleReport={props.handleReport}
                clearReport={props.clearReport}
                ref={ref}
              />
              <div className="addition-filter-settings">
                <div className="filter-btn-col">
                  <SavedCustomization
                    module={props.module}
                    handleReport={props.handleReport}
                    reportData={props.reportData}
                    type={props.type}
                  />
                </div>
                <div className="filter-btn-col">
                  <CustomizeReportModal
                    module={props.module}
                    handleReport={props.handleReport}
                    reportData={{}}
                    type={props.type}
                    reportType="AR"
                    buttonClass="aging-report-advanced-filter cancel-button"
                  />
                </div>
              </div>
            </div>
            {props.showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center ">
                  {/* <ReportHeaderActionLinks /> */}
                </div>
                <ReportHeader
                  reportData={props.reportData}
                  reportName={props.title}
                />
                <div className="table-section report-table-section">
                  <InfoBox
                    message={
                      message(props.reportData.currency_code).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  {props.children}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(AgingReport);
