import { Api } from "../../../../constants";
import { get, post, put, remove } from "../../../../helpers/apiHelper";
import {
  CreateTaxRateValues,
  CreateTaxValues,
  TaxGroupValues,
} from "../../../../types";

/**
 * API call to get tax configuration list
 */
export async function getTaxesList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/list?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get tax rate list
 */
export async function getTaxRateList(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/list-tax?current_organization=${data.orgId}`
  );
  return result;
}

export async function getTaxRateGroupList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      "gst_configuration" +
      `/list-page?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to create a new tax
 */
export async function createNewTax(data: {
  values: CreateTaxValues;
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to create a new tax rate
 */
export async function createNewTaxRate(data: {
  values: CreateTaxRateValues;
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount +
      "gst_configuration" +
      `/create-tax?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to edit a tax
 */
export async function editTaxItem(data: {
  taxId: number;
  values: CreateTaxValues;
  orgId: number;
}) {
  const result = await put(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/edit/${data.taxId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to edit a tax rate
 */
export async function editTaxRateItem(data: {
  taxId: number;
  values: CreateTaxRateValues;
  orgId: number;
}) {
  const result = await put(
    Api.chartOfAccount +
      "gst_configuration" +
      `/edit-tax/${data.taxId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to delete a tax
 */
export async function deleteTaxItem(data: { taxId: number; orgId: number }) {
  const result = await remove(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/delete/${data.taxId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to delete a tax rate
 */
export async function deleteTaxRateItem(data: {
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.chartOfAccount +
      "gst_configuration" +
      `/delete-tax/${data.taxId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get all tax payable configuration list
 */
export async function getTaxePayable(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/dropdown-payable?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get all tax receivable configuration list
 */
export async function getTaxeReceivable(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/dropdown-receivable?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to create tax group
 */
export async function createNewTaxGroup(data: {
  orgId: number;
  values: TaxGroupValues;
}) {
  const result = await post(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/create-tax-group?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to edit tax group
 */
export async function updateNewTaxGroup(data: {
  taxGroupId: number;
  orgId: number;
  values: TaxGroupValues;
}) {
  const result = await put(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/edit-tax-group/${data.taxGroupId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to delete tax group
 */
export async function removeTaxGroup(data: {
  taxGroupId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/delete-tax-group/${data.taxGroupId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to validate tax group
 */
export async function validateTaxGroup(data: {
  titleValidation: boolean;
  groupName: string;
  taxIds: number[];
  orgId: number;
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/validate-tax-group?title_validation=${data.titleValidation}&group_name=${data.groupName}&current_organization=${data.orgId}`,
    data.taxIds,
    data.signal
  );
  return result;
}
