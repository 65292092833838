import React, { useEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CropIcon from "@mui/icons-material/Crop";
import { ImageCropperStyle } from "./ImageCropperCustomStyles";
import "cropperjs/dist/cropper.css";
import "../styles/ImageCropper.css";

type Props = {
  isOpen: boolean;
  image: string;
  getCroppedImage: (file: File) => void;
  fileName: string;
  onCancelUpload: () => void;
};
export const ImageCropper = (props: Props) => {
  const [image, setImage] = useState(props.image);
  const [isCropped, setCropped] = useState(false);
  const [cropData, setCropData] = useState(props.image);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [open, setOpen] = useState(true);
  useEffect(() => {
    setImage(props.image);
  }, [props.image]);
  const dataURLtoFile = (dataURL: string, filename: string): File => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const cropper = cropperRef.current?.cropper;
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setCropped(true);
    }
  };
  const handleUpload = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const cropper = cropperRef.current?.cropper;
      props.getCroppedImage(
        dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), props.fileName)
      );
      setCropped(false);
    }
  };
  const handleCancel = () => {
    setOpen(false);
    props.onCancelUpload();
  };
  useEffect(() => {
    props.image ? setImage(props.image) : setImage("");
    props.isOpen === true ? setOpen(true) : setOpen(false);
  }, [image, props.isOpen]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="crop_modal"
      >
        <Box className="image-crop-modal" sx={ImageCropperStyle.modalStyle}>
          {!isCropped ? (
            <Cropper
              src={image}
              style={{
                ...ImageCropperStyle.cropperStyle,
                display: isCropped ? "none" : "block",
              }}
              initialAspectRatio={1 / 1}
              aspectRatio={1 / 1}
              guides={true}
              ref={cropperRef}
              zoomable
              zoomOnWheel
              draggable={true}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={5}
              background={true}
              responsive={true}
              autoCropArea={1}
            />
          ) : (
            <></>
          )}
          {isCropped ? (
            <div className="box-cropped">
              <img src={cropData} alt="cropped" />
            </div>
          ) : (
            <></>
          )}
          <div
            className="modal-footer justify-content-start cropper-btn-wrapper"
            id="send-email-button "
          >
            <button
              type="button"
              className="btn save-button"
              onClick={isCropped ? handleUpload : getCropData}
            >
              {isCropped ? (
                "Upload"
              ) : (
                <React.Fragment>
                  <CropIcon fontSize="small" /> Crop
                </React.Fragment>
              )}
            </button>
            <button
              type="button"
              className="btn cancel-button close-button"
              data-bs-dismiss="modal"
              onClick={() => {
                isCropped ? setCropped(false) : handleCancel();
              }}
            >
              {isCropped ? "Back To Crop" : "Cancel"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
