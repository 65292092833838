import * as yup from "yup";
import { message } from "../../../../constants/messages";

export const gstSettingsValidation = yup.object({
  gst: yup.string().when("gst_enabled", {
    is: "Yes",
    then: (schema) =>
      schema
        .required(message().gstinRequired)
        .max(15, message().gstinMaxLen)
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          message().gstinInvalid
        ),
  }),
  registered_on: yup
    .mixed()
    .test({
      name: "inter_state_id",
      message: message().invalidDate,
      test: function (value) {
        const gst_enabled = this.options.context?.gst_enabled;

        if (gst_enabled === "Yes" && value === "Invalid Date") {
          return false;
        }
        return true;
      },
    })
    .test({
      name: "registered_on_future_date",
      message: message().futureDateNotAllowed,
      test: function (value: any) {
        const gst_enabled = this.options.context?.gst_enabled;
        const todayDate = new Date();
        if (gst_enabled === "Yes" && value) {
          let valueDate = new Date(value);
          if (valueDate > todayDate) {
            return false;
          }
        }
        return true;
      },
    }),
});
