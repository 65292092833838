import React from "react";
// import { EmailIcon } from "../../../../assets/images";
import { EmailIcon } from "../../../common/components";
import { colors } from "../../../constants/colors";
export default function SendEmail(props: { onClick: () => void }) {
  return (
    <a
      className="dropdown-item"
      href="#"
      data-bs-toggle="modal"
      data-bs-target="#emailToModal"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      {/* <img src={EmailIcon} alt="email-icon" />
      <span>Send Email</span> */}
      <span>
        <EmailIcon color={colors.battleshipGrey} />
        Send Email
      </span>
    </a>
  );
}
