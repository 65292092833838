import { useRef } from "react";
import UnpaidBillItem from "./UnpaidBillItem";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { NoteTextArea } from "../../../../../../common/components";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../../../common/commonSlice";
import { getAmountCalculationResult } from "../../../../paymentsMadeSlice";
import PaymentTotal from "../../PaymentTotal";
import { useAmountCalculation } from "../../../../../../hooks/amountCalculation/usePmAmountCalculation";
import useCommonData from "../../../../../../hooks/useCommon";
import "./unpaidBills.css";
import { NumberFormat } from "../../../../../../../helpers/numberFormatHelper";

type Props = {
  formik: FormikProps<any>;
};

const UnpaidBills = ({ formik }: Props) => {
  const { editId } = useParams();
  // const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const amountCalculation = useAmountCalculation({ formik: formik });
  const totalDetails = useAppSelector(getAmountCalculationResult).total_details;
  const { currentUserInfo, orgCurrencyList } = useCommonData();

  const unpaidBillTableRef = useRef<HTMLTableElement>(null);

  const calculateThCount = () => {
    const table = unpaidBillTableRef.current;
    if (table) {
      const thElements = table.querySelectorAll("th");
      return thElements.length;
    }
    return 0;
  };

  return (
    <>
      <section
        className={`item-details-wrapper  ${
          !formik.values.vendor_id ? "disabled-item" : ""
        }`}
      >
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="mb-0">Unpaid Bills</h2>
          <div>
            <span>Amount available for allocation: </span>
            <span className="fw-bold">
              {formik.values.currency_code}{" "}
              {NumberFormat(
                totalDetails.amount_excess,
                formik.values.currency_code,
                orgCurrencyList
              )}
            </span>
          </div>
        </div>
        <div className="row mb-0">
          <div className="items-entry-wrapper px-0">
            <div className="bill-item-container">
              <table
                id="billItemEntry"
                className="table unpaid-items-table valign-middle w-100"
                ref={unpaidBillTableRef}
              >
                <thead className="border-top-0 unpaid-payments-header">
                  <tr>
                    <th scope="col" className="item_number">
                      Bill Number
                    </th>
                    <th scope="col" className="item_date">
                      Date
                    </th>
                    <th scope="col" className="item_amount">
                      Bill Amount
                    </th>
                    <th scope="col" className="amount_due">
                      Amount Due
                    </th>
                    {currentUserInfo.is_payment_allocation_enabled && (
                      <th scope="col" className="allocation-date-col">
                        Allocation Date
                      </th>
                    )}
                    <th scope="col" className="payment-col">
                      Payment
                    </th>
                    <th scope="col" className="forex_gain_loss">
                      Forex Gain/Loss
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.unpaid_bill_lines.length > 0 ? (
                    formik.values.unpaid_bill_lines.map(
                      (bill: any, index: number) => {
                        return (
                          <UnpaidBillItem
                            // totalAmountError={props.totalAmountError}
                            // itemFormErrors={props.itemFormErrors}
                            // unpaidBillsOfVendor={unpaidBillValues}
                            // handleItemOnBlur={handleItemOnBlur}
                            // forexGainLossValues={forexGainLossValues}
                            index={index}
                            bill={bill}
                            currencyCode={formik.values.currency_code}
                            formik={formik}
                            handlePaymentChange={formik.handleChange}
                            errors={formik.errors.unpaid_bill_lines}
                          />
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td className="no-item" colSpan={calculateThCount()}>
                        There are no unpaid bills for this vendor
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <span className="error error_amount_for_payment total-unpaid-error">
                {formik.errors.amount_for_payment?.toString()}
              </span>
            </div>
            <div
              className={`row mb-0 g-0 calc-row  ${
                !formik.values.vendor_id ? "disabled-item" : ""
              }`}
            >
              <NoteTextArea
                label="Notes"
                name="notes"
                id="customerNotes"
                className="notes-textarea"
                placeholder="Enter notes here..."
                value={formik.values.notes}
                onChange={formik.handleChange}
                formErrors={formik.errors}
              />
              <PaymentTotal
                currencyCode={formik.values.currency_code}
                orgCurrencyList={orgCurrencyList}
                total={totalDetails.total}
                amountPaid={
                  formik.values.bank_conversion_rate !== 0
                    ? totalDetails.amount_paid
                    : formik.values.amount_paid
                }
                amountUsedForPayment={totalDetails.amount_used_payment}
                excessAmount={totalDetails.amount_excess}
                totalRefundedAmount={formik.values.total_refunded_amount}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UnpaidBills;
