import { FormikProps } from "formik";
import { EditIcon } from "../../customSvgIcons";
import { PopperContext } from "../../popper/popperContext";
import { ConsumerPaymentEditCard } from "./ConsumerPaymentEditCard";
import Popper from "../../popper/popper";
import { colors } from "../../../../constants/colors";
import { ObjectType } from "../../../../../types";

type Props = {
  formik: FormikProps<any>;
  label: string;
  subLabel: string;
  prefix: string;
  name: string;
  id: string;
  amount: string;
  bankAmount: string;
  isDisabled: boolean;
  error: string;
  onSave: (e: ObjectType, newBankAmount: string) => void;
  autoFocus?: boolean;
  conversionDate: string;
  toCurrencyId: number;
  toCurrencyCode: string;
  baseCurrencyId: number;
  baseCurrencyCode: string;
  editId: string;
  conversionRate: string;
  isButtonDisabled: boolean;
  placeholder?: string;
};
export const ConsumerPaymentEditPopper = (props: Props) => {
  return (
    <>
      <Popper
        popperContent={
          <PopperContext.Consumer>
            {(context:any) => {
              const { onClosePopper } = context;
              return (
                <ConsumerPaymentEditCard
                  {...props}
                  handleClose={onClosePopper}
                />
              );
            }}
          </PopperContext.Consumer>
        }
        position={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transform={{
          vertical: "top",
          horizontal: "right",
        }}
        buttonContent={<EditIcon color={colors.ceruleanBlue} />}
        buttonClass="no-appearance position-absolute endorment-btn"
      />
    </>
  );
};
