import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editTransactionLock,
  transactionLockOrPartialUnlock,
  transactionLockingDetail,
  transactionUnlockOrDisablePartialUnlock,
} from "./transactionLockingAPI";
import {
  CreateLockValues,
  UnlockValues,
  UpdateLockValues,
} from "../../../types";

export const getTransactionLockingDetail = createAsyncThunk(
  "get-transaction-lock",
  async (orgId: number) => {
    return await transactionLockingDetail(orgId);
  }
);

export const createTransactionLock = createAsyncThunk(
  "create-transaction-lock",
  async (data: { values: CreateLockValues; orgId: number }) => {
    return await transactionLockOrPartialUnlock(data);
  }
);

export const unlockTransaction = createAsyncThunk(
  "unlock-transaction",
  async (data: { values: UnlockValues[]; orgId: number }) => {
    return await transactionUnlockOrDisablePartialUnlock(data);
  }
);

export const updateTransactionLock = createAsyncThunk(
  "update-transaction-lock",
  async (data: { values: UpdateLockValues[]; orgId: number }) => {
    return await editTransactionLock(data);
  }
);
