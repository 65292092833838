import { OptionsMappingProps } from "../../../types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTagSelection } from "../../../hooks/useTagSelection";
import { useEffect } from "react";
import { Form } from "../../../../htmlTags/Form";
import { Container } from "../../../../htmlTags/Container";
import { Span } from "../../../../htmlTags/Span";
import { SaveButton, SecondaryButton, TextButton } from "../../../../button";
import { colors } from "../../../../../../constants/colors";
import Divider from "@mui/material/Divider";

import "./TagOptionsMapping.css";
import BackButton from "../../../../BackButton";
export const TagOptionsMapping = (props: OptionsMappingProps) => {
  const { handleSubmit, handleReset, setFieldValue, setInitialValues, values } =
    useTagSelection();
  useEffect(() => {
    getProcessedTagsOptions();
  }, [JSON.stringify(props.options), JSON.stringify(props.selectedIds)]);

  const getProcessedTagsOptions = () => {
    let processedTags = props?.options?.map((option) => {
      return {
        ...option,
        isChecked: props.selectedIds?.includes(option.id),
      };
    });
    setInitialValues({
      options: processedTags,
    });
    setFieldValue("options", processedTags);
  };
  const getSelectedIDs = () => {
    let ids = [];
    ids = values?.options
      ?.filter((option) => option.isChecked)
      .map((option) => {
        return option.id;
      });
    return { tagId: props.tagId, optionIds: ids };
  };
  const clearSelections = () => {
    let processedTags = props?.options?.map((option) => {
      return {
        ...option,
        isChecked: false,
      };
    });

    setFieldValue("options", processedTags);
  };
  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <Container className="tags-options-mapping-wrapper">
        <Container className="tags-options-mapping-header-wrapper">
          <BackButton onClick={props.handleClose} />
          <Span>Select Tags</Span>
        </Container>

        <Container className="tags-options-parent-tag-name-wrapper">
          <Span>{props?.parentTag?.name}</Span>
        </Container>

        <Divider sx={{ color: colors.battleshipGrey }} />
        <Container className="tags-options-mapping-option-array-wrapper">
          {values?.options?.map((option, index) => {
            return (
              <Container className="tags-options-mapping-option-wrapper">
                <FormControlLabel
                  control={
                    <Checkbox value={option.id} checked={option.isChecked} />
                  }
                  label={option.name}
                  onClick={() => {
                    setFieldValue(
                      `options.${index}.isChecked`,
                      !option.isChecked
                    );
                  }}
                  sx={{ marginLeft: 0, marginRight: 0 }}
                />
              </Container>
            );
          })}
        </Container>
        <Container className="tag-options-mapping-btn-wrapper">
          <Container className="tag-options-mapping-text-btn-wrapper">
            <TextButton
              onClick={clearSelections}
              buttonContent="Clear Selection"
              isDisabled={false}
              type="button"
              fontColor={colors.ceruleanBlue}
              fontSize="14px"
              fontWeight="normal"
            />
          </Container>
          <Container className="tag-options-mapping-action-btn-wrapper">
            <SecondaryButton
              type="button"
              isDisabled={false}
              onClick={() => {
                props.handleClose();
                getProcessedTagsOptions();
              }}
              buttonContent="Cancel"
            />
            <SaveButton
              type="submit"
              isDisabled={false}
              onClick={() => {
                props.getSelectedIds(getSelectedIDs());
                props.handleClose();
                props.categoryPopupClose && props.categoryPopupClose();
              }}
              buttonContent="Confirm"
              buttonClassName="m-0"
            />
          </Container>
        </Container>
      </Container>
    </Form>
  );
};
