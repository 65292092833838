import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "../../../htmlTags/Container";
import { Span } from "../../../htmlTags/Span";
import Input from "../../../ui/formElements/Input/Input";
import Li from "../../../ui/formElements/Li/Li";
import List from "../../../ui/formElements/Ul/Ul";
import { useDiscount } from "../DiscountProvider";
import { constants } from "../constants";
import { DiscountAccountDropdownProps } from "../types";

const DiscountAccountSelect = (props: DiscountAccountDropdownProps) => {
  const { discountAccounts } = useDiscount();
  const [accountFilter, setAccountFilter] = useState("");
  useEffect(() => {
    resetDropDown();
  }, []);
  useEffect(() => {
    $(".dropdown-toggle").dropdown("hide");
  }, [props.error]);

  const handleSelectChange = (
    accountId: number | string,
    accountName: string,
    index: number,
    accountType: string
  ) => {
    props.onDiscountAccountChange(accountId, accountName, index, accountType);
  };

  /**
   * Function to set search string
   */
  const searchAccount = (e: any) => {
    setAccountFilter(e.target.value);
    handleSelectChange("", e.target.value, props.index, "");
  };

  const accountSearchOnBlur = () => {
    if (discountAccounts.length && props.discountAccountId) {
      let selectedAccount = discountAccounts.filter(
        (account) => account.id === props.discountAccountId
      );
      if (selectedAccount.length === 0) {
        props.onDiscountAccountChange("", "", props.index, "");
      } else {
        props.onDiscountAccountChange(
          Number(selectedAccount[0].id),
          selectedAccount[0].account_name,
          props.index,
          selectedAccount[0].account_type
        );
      }
    }
  };

  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  return (
    <>
      <Container
        className={`btn-group accounts-dropdown discount-account-dropdown ${
          props.wrapperClass ? props.wrapperClass : ""
        } ${props.error}`}
      >
        <Input
          type="text"
          name={props.name ? props.name : "account_id"}
          id={props.id ? props.id : ""}
          className={`${
            props.error ? "error" : ""
          }  discount-select-dropdown-input dropdown-toggle ${
            props.className || ""
          }`}
          placeholder={constants.placeholder.CHOOSE_ACCOUNT}
          dataBsToggle="dropdown"
          aria-expanded="false"
          disabled={props.isDisabled ? props.isDisabled : false}
          value={props.discountAccountName}
          onChange={(e) => {
            searchAccount(e);
          }}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          onBlur={accountSearchOnBlur}
        />
        <List wrapperClass="dropdown-menu dropdown-menu-start">
          <>
            {discountAccounts.length ? (
              discountAccounts
                .filter(
                  (account) =>
                    String(account.account_name)
                      .toLowerCase()
                      .includes(
                        String(props.discountAccountName).toLowerCase()
                      ) || accountFilter === ""
                )
                .map((account, accountIndex) => {
                  return (
                    <Li key={accountIndex}>
                      <Link
                        className={
                          Number(props.discountAccountId) === Number(account.id)
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSelectChange(
                            account.id,
                            account.account_name,
                            props.index,
                            account.account_type
                          );
                          setAccountFilter("");
                          return true;
                        }}
                      >
                        <Span className="account">{account.account_name}</Span>
                        <Span className="account-type">
                          {account.account_type}
                        </Span>
                      </Link>
                    </Li>
                  );
                })
            ) : (
              <Li key="no-result" wrapperClass="no-result">
                <Link
                  className="dropdown-item no-result"
                  to="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <Span className="no-result">No accounts found</Span>
                </Link>
              </Li>
            )}
          </>
        </List>
      </Container>
    </>
  );
};

export default DiscountAccountSelect;
