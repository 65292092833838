import { forwardRef } from "react";
import { colors } from "../../../constants/colors";
import { SettingsIcon } from "../customSvgIcons/settingsIcon";
import { SideModal } from "../sideModal/SideModal";
import { ModalContext } from "../sideModal/sideModalContext";
import CustomizeAgingReport from "./CustomizeAgingReport/CustomizeAgingReport";
import { CustomizeProfitAndLossForm } from "./customizeReportPL/CustomizeProfitAndLossForm";
import { ObjectType } from "../../../../types";
import { CustomizeReportAtandDBConfigForm } from "./customizeReportATandBS/customizeReportATandDBForm";

type CustomizeReportProp = {
  module?: string;
  handleReport: (type: string, arg?: { [key: string]: any }) => void;
  handlePLReport?: (type: string, argFilters?: ObjectType[]) => void;
  onSubmit?: () => void;
  reportData?: ObjectType;
  type?: string;
  reportType: string;
  buttonClass?: string;
  buttonContent?: React.ReactNode;
  wrapperClass?: string;
  onOpenFunction?: () => void;
};
export const CustomizeReportModal = (props: CustomizeReportProp, ref: any) => {
  const reportSelector = (onCloseModal: () => void) => {
    switch (props.reportType) {
      case "AT":
        return (
          <CustomizeReportAtandDBConfigForm
            handleClose={onCloseModal}
            reportType="AT"
            handleReport={props.handleReport}
            onSubmit={props.onSubmit}
          />
        );
        break;
      case "DB":
        return (
          <CustomizeReportAtandDBConfigForm
            handleClose={onCloseModal}
            reportType="DB"
            handleReport={props.handleReport}
            onSubmit={props.onSubmit}
          />
        );
        break;
      case "AR":
        return (
          <CustomizeAgingReport
            handleClose={onCloseModal}
            handleReport={props.handleReport}
            reportData={props.reportData ? props.reportData : {}}
            type={props.type ? props.type : ""}
            module={props.module ? props.module : ""}
          />
        );
        break;
      case "PL":
        return (
          <CustomizeProfitAndLossForm
            handleClose={onCloseModal}
            handleReport={props.handlePLReport}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
  };
  return (
    <SideModal
      modalTitle="Advanced Filter"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return reportSelector(onCloseModal);
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        props.buttonContent ? (
          props.buttonContent
        ) : (
          <span className="d-flex align-items-center">
            {props.reportType !== "AR" && (
              <SettingsIcon color={colors.booger} />
            )}{" "}
            Advanced Filter
          </span>
        )
      }
      buttonClass={props.buttonClass ? props.buttonClass : "cancel-button"}
      wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
      onOpenFunction={props.onOpenFunction}
    />
  );
};

export default forwardRef(CustomizeReportModal);
