import { OrganizationTreeValues } from "../../../../../types";
import { SubOrgAddModal } from "../subOrgForm/subOrgAddModal";
import { ListAction } from "./listAction";
export const renderTreeData = (
  org: OrganizationTreeValues,
  orgHierarchyIds: number[],
  currentUserInfo: any,
  leftBorderOfChild: (count: number) => JSX.Element[],
  toggleExpanding: (e: any) => void,
  deleteSubOrg: (id: number, name: string) => void,
  fetchHierarchyList: () => Promise<any>
) => {
  const createNewSubOrg = {
    country_id: null,
    state_id: null,
    currency: "",
    currency_id:"",
    name: "",
    transaction_exist: false,
    gst: "",
    fiscal_year: "",
  };
  return org.node?.map((organization: OrganizationTreeValues) => {
    return (
      <div key={organization.organization_details.id}>
        <div
          key={organization.organization_details.id}
          data-id={organization.organization_details.id}
          className="table-collapsible-item auto-flex"
        >
          <div
            className={`parent-row item-row level-${organization.level + 1}`}
          >
            <div className="item-col account-col align-left">
              <>
                {leftBorderOfChild(organization.level)}
                <>
                  <div className="btn-container left-float horizontal-border bottom-border left-border">
                    <button
                      className={`collapsible-btn collaps-btn-${organization.organization_details.id}`}
                      onClick={(e: any) => toggleExpanding(e)}
                    ></button>
                  </div>
                </>
                {/* )} */}
              </>
              <div className="item-label left-float horizontal-border left-border">
                {organization.organization_details.name}
              </div>
            </div>
            {orgHierarchyIds?.includes(organization.organization_details.id) ? (
              <div className="item-col act-col horizontal-border">
                <div className="action">
                  <ListAction
                    organization={organization}
                    currentUserInfo={currentUserInfo}
                    deleteSubOrg={deleteSubOrg}
                    fetchHierarchyList={fetchHierarchyList}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* Recursive function call if node exists */}
          <div className="table-collapsible-content">
            <>
              {organization.node?.length !== 0 ? (
                renderTreeData(
                  organization,
                  orgHierarchyIds,
                  currentUserInfo,
                  leftBorderOfChild,
                  toggleExpanding,
                  deleteSubOrg,
                  fetchHierarchyList
                )
              ) : (
                <></>
              )}
              {orgHierarchyIds?.includes(
                organization.organization_details.id
              ) ? (
                <div
                  className={`parent-row item-row level-${
                    organization.level + 2
                  }`}
                >
                  <div className="item-col account-col align-left">
                    {leftBorderOfChild(organization.level)}
                    <div className="plain-line-column left-float left-border"></div>
                    <div className="plain-line-column left-float left-border horizontal-border">
                      <SubOrgAddModal
                        isListButton={true}
                        currentOrgId={currentUserInfo.organization_id}
                        parentOrgId={organization.organization_details.id}
                        values={createNewSubOrg}
                        fetchHierarchyList={fetchHierarchyList}
                      />
                    </div>
                    <div className="item-label add-sub-wrapper left-float horizontal-border left-border">
                      <SubOrgAddModal
                        isListLink={true}
                        currentOrgId={currentUserInfo.organization_id}
                        parentOrgId={organization.organization_details.id}
                        values={createNewSubOrg}
                        fetchHierarchyList={fetchHierarchyList}
                      />
                    </div>
                  </div>
                  <div className="item-col act-col horizontal-border">
                    <div className="action"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          </div>
        </div>
        <div
          className="table-collapsible-item auto-flex"
          key={"item-" + organization.organization_details.id}
        ></div>
      </div>
    );
  });
};
