import React from "react";
import {
  BillToAllocate,
  DebitNoteAllocation,
  DebitNoteDetailValues,
} from "../../../../../types";
import CenterModal from "../../../../common/components/centerModal/CenterModal";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import useCommonData from "../../../../hooks/useCommon";
import { useBillAllocation } from "./useBillAllocation";
import { useParams } from "react-router-dom";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import AllocationForm from "../../../../common/components/detailsPage/allocation/AllocationForm";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import ErrorInfo from "../../../../common/components/ErrorInfo";
import { updateDebitNoteAllocation } from "../../debitNoteSlice";
import { toast } from "react-toastify";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { message } from "../../../../constants/messages";

type Props = {
  billId?: number;
  buttonComponent: React.ReactNode;
  debitNoteData: DebitNoteDetailValues;
  refreshDNDetails: () => void;
  buttonClass?: string;
  isVoid?: boolean;
};
function ApplyToBillsModal({
  billId,
  buttonComponent,
  debitNoteData,
  refreshDNDetails,
  buttonClass,
  isVoid,
}: Props) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    billToAllocate,
    allocationColumn,
    allocationTableData,
    getBillsToAllocateDebitNote,
    resetAllocation,
    debitBalance,
    remainingAmount,
    allocatedAmount,
    isError,
    isFormValues,
    handleAmountRoundOff,
  } = useBillAllocation({ billId, debitNoteData });
  const { editId } = useParams();

  const balance = debitBalance
    ? debitNoteData.currency_code +
      " " +
      NumberFormat(
        Number(debitBalance),
        debitNoteData.currency_code,
        orgCurrencyList
      )
    : debitNoteData.currency_code +
      " " +
      zeroDisplayFormat(
        decimalPlaceOfCurrency(
          debitNoteData.currency_code
            ? debitNoteData.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );

  /**
   * update allocation
   */
  const updateAllocationData = async () => {
    const billDetails = JSON.parse(JSON.stringify(billToAllocate));
    let inputs: DebitNoteAllocation[] = [];
    billDetails
      .filter(
        (bill: BillToAllocate) =>
          (bill.amount_debited !== null &&
            bill.amount_debited !== 0 &&
            bill.amount_debited !== "") ||
          bill.id !== null
      )
      .map((bill: BillToAllocate, index: number) => {
        let input = {
          id: bill.allocation_id ? bill.allocation_id : null,
          bill_id: bill.bill_id,
          amount_to_debit: bill.amount_debited
            ? handleAmountRoundOff(Number(bill.amount_debited))
            : 0,
        };
        inputs.push(input);
      });
    const responseAction = await dispatch(
      updateDebitNoteAllocation({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
        values: inputs,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        refreshDNDetails();
        getBillsToAllocateDebitNote();
        toast.success("Debits applied to bill(s)!", {
          toastId: "Debits-apply-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
      }
    }
  };
  const handleDisabledAction = () => {
    ErrorToaster(message().debitNote.applyDebitNoteToBills);
  };

  return (
    <CenterModal
      modalTitle={`Apply Debits from ${joinTransactionNumber(
        debitNoteData.debit_note_number
      )}`}
      modalTitleComponent={
        <div>
          <h6 className="balance-amt">{"Balance:" + " " + balance}</h6>
          {isError ? (
            <ErrorInfo message="The debits applied is more than the bill balance." />
          ) : null}
        </div>
      }
      modalTitleClass="flex-column align-items-start"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <AllocationForm
                allocatedLabel={"Amount to Debit:"}
                allocatedAmount={allocatedAmount}
                remainingLabel={"Debit Note Balance:"}
                remainingAmount={remainingAmount}
                columns={allocationColumn}
                tableData={allocationTableData}
                isError={isError}
                errorMessage="The debits applied is more than the bill balance."
                onSubmit={updateAllocationData}
                resetAllocation={resetAllocation}
                currencyCode={debitNoteData.currency_code}
                isFormValues={isFormValues}
                handleModalClose={onCloseModal}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={buttonComponent}
      wrapperClass="cd-modal applyCreditModal"
      modalContentClass="modal-dialog-scrollable"
      buttonClass={`no-appearance w-100 ${buttonClass ? buttonClass : ""}`}
      onOpenFunction={async () => {
        resetAllocation();
        await getBillsToAllocateDebitNote();
      }}
      isButtonDisabled={isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
}

export default ApplyToBillsModal;
