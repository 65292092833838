import React from "react";
import { PDFIcon, ImageIcon } from "../../../assets/images";
import { FilesValues } from "../../../types";
import "../styles/FileList.css";

type Props = {
  files: FilesValues[];
};
const FileList: React.FC<Props> = ({ files }) => {
  return (
    <>
      <div className="file-list-container">
        <div className="fileCount">
          {files.length > 1
            ? files.length + "  Attachments"
            : files.length + "  Attachment"}
        </div>
        <ul className="file-list">
          {files.map((file, index) => {
            var fileExt = file.file_name?.split(".").pop();
            return (
              <li key={index}>
                <a
                  href={file.download_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-item d-flex"
                >
                  <img
                    src={fileExt === "pdf" ? PDFIcon : ImageIcon}
                    alt="file icon"
                  />
                  <span className="file-name">{file.file_name}</span>
                  <span className="file-size">({file.file_size})</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default FileList;
