import { Cell, Row, useTable } from "react-table";
import { TableData } from "../../../../types";
import "../../../../index.css";
import "./Table.css";

type Props = {
  id?: string;
  columns: any[];
  data: TableData[];
  tableClassName: string;
  dataSort: boolean;
  isLoading?: boolean;
  haveColumnClassName: boolean;
  onSortClick?: (e: string) => void;
  rowClassName: string;
  emptyClassName: string;
  hiddenColumns?: string[];
  tbodyRowOnClick: (value: Row<TableData>, e: React.MouseEvent) => void;
  tbodyColumnClick: (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => void;
  children: React.ReactNode;
};
export default function Table(props: Props) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns: props.columns,
    data: props.data,
    initialState: {
      hiddenColumns: props.hiddenColumns ? props.hiddenColumns : [],
    },
  });

  const getActiveColumnCount = () => {
    let activeColCount = 0;
    headerGroups[0].headers.length > 0
      ? (activeColCount = headerGroups[0].headers.length)
      : (activeColCount = 0);
    return activeColCount;
  };
  return (
    // <div className="table-container table-responsive">
    <>
      <table
        {...getTableProps()}
        id={props.id}
        className={props.tableClassName}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    data-orderable={props.dataSort ? column.render("sort") : ""}
                    onClick={() =>
                      props.dataSort && props.onSortClick
                        ? props.onSortClick(column.render("Header") as string)
                        : ""
                    }
                    className={
                      (props.dataSort
                        ? (column.render("sortOrder") as string)
                        : "") +
                      (props.haveColumnClassName
                        ? " " + (column.render("className") as string)
                        : "")
                    }
                  >
                    <span>{column.render("Header")}</span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`
                    ${props.rowClassName ? props.rowClassName + " " : ""} ${
                    row.original?.className ? row.original?.className : ""
                  } ${
                    row?.cells[4]?.value === "AccountClosing" ? "disabled" : ""
                  }`}
                  onClick={(e: React.MouseEvent) => {
                    props.tbodyRowOnClick(row, e);
                  }}
                >
                  {row.cells.map((cell, cellIndex) => {
                    const colSpan =
                      row.original.colSpan &&
                      row.original.colSpan[cell.column.id.replace("show.", "")];
                    if (
                      (row.original.colSpan && cell.value !== undefined) ||
                      row.original.colSpan === undefined
                    ) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          colSpan={colSpan || 1}
                          className={
                            props.haveColumnClassName
                              ? (cell.column.render("className") as string)
                              : ""
                          }
                          onClick={(e) =>
                            props.tbodyColumnClick(cellIndex, row, e, cell)
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })
          ) : !props.isLoading ? (
            <tr className="no-item">
              <td
                colSpan={getActiveColumnCount()}
                className={props.emptyClassName}
              >
                {props.children}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
    // </div>
  );
}
