import * as React from "react";
import { styled } from "@mui/material/styles";
import "../styles/Tooltip.css";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { colors } from "../../constants/colors";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    className={"custom-tooltip-wrapper"}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.charcoalGrey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.charcoalGrey,
    color: colors.white,
    fontFamily: "Lato",
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    padding: 8,
    maxWidth: 212,
    borderRadius: 2,
    boxShadow: `0px 0px 4px ${colors.black}`,
    opacity: 1,
    // whiteSpace: "pre-wrap"
  },
}));
export type position =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top"
  | undefined;
export default function CustomTooltips(props: {
  title: string | React.ReactNode;
  placement: position;
  children: any;
}) {
  return (
    <BootstrapTooltip
      title={props.title}
      placement={props.placement}
      enterTouchDelay={30}
    >
      <span>{props.children && props.children}</span>
    </BootstrapTooltip>
  );
}
