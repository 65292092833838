import React, { useEffect, useState } from "react";
import "./SelectTDS.css";
import { Link } from "react-router-dom";
import ItemCloseButton from "../button/ItemCloseButton";
import CustomSelectDropDown from "../customSelectDropDown/CustomSelectDropDown";

type Props = {
  id: string;
  selectPlaceholderText: string;
  inputId: string;
  inputPlaceHolder: string;
  index: number;
  item: {
    tds_id: number | null;
    tds_amount: number;
    tds_rate: number;
    tds_name: string;
    tcs_id: number | null;
    tcs_amount: number;
    tcs_rate: number;
    tcs_name: string;
  };
  handleAmountChange: (
    name: string,
    e: any,
    index: number,
    taxType: string
  ) => void;
  handleOnBlur: (name: string, tdsAmount: number, index: number) => void;
  taxType: string;
  clearTaxSelection: (index: number) => void;
  tdsIds: number[];
  tcsIds: number[];
  handleTdsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  handleTotalTcsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  applyTdsAt: string;
  error: any;
  hasError?: boolean;
};

function SelectTDSAndTCS(props: Props) {
  const [isPercentage, setIsPercentage] = useState(false);

  let isAddNewButtonShow = false;
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  if (props.taxType === "TDS") {
    let tdsPermission: string[] = organizationRole.length
      ? organizationRole
          .filter((orgPermission: any) => orgPermission.subModule === "TDS")
          .map((permission: any) => {
            return permission.permission;
          })
      : [];
    if (tdsPermission.includes("test")) {
      isAddNewButtonShow = true;
    }
  }

  if (props.taxType === "TCS") {
    let tcsPermission: string[] = organizationRole.length
      ? organizationRole
          .filter((orgPermission: any) => orgPermission.subModule === "TCS")
          .map((permission: any) => {
            return permission.permission;
          })
      : [];
    if (tcsPermission.includes("test")) {
      isAddNewButtonShow = true;
    }
  }
  useEffect(() => {
    if (
      (props.item &&
        props?.item?.tds_id &&
        !props?.item?.tds_amount &&
        props?.item?.tds_rate) ||
      (props.item &&
        props?.item?.tcs_id &&
        !props?.item?.tcs_amount &&
        props?.item?.tcs_rate)
    ) {
      setIsPercentage(true);
    }
  }, [JSON.stringify(props)]);
  return (
    <>
      <div className="w-100 d-flex align-items-center">
        <div
          className={`tds-fields-wrapper w-100 d-flex ${
            props.error?.tds_amount || props.error?.tds_name ? "error" : ""
          } ${props?.hasError ? "error" : ""}
        
        ${
          !(
            props.taxType === "TDS" ||
            (props.taxType === "TCS" && props.item.tcs_rate === 0)
          )
            ? "tds-fields-full-width"
            : ""
        }`}
        >
          <div className=" tds-dropdown">
            <CustomSelectDropDown
              taxType={props.taxType}
              tdsIds={props.tdsIds}
              tcsIds={props.tcsIds}
              index={props.index}
              handleTdsLabelChange={props.handleTdsLabelChange}
              item={props.item}
              handleTotalTcsLabelChange={props.handleTotalTcsLabelChange}
              applyTdsAt={props.applyTdsAt}
              placeholder={props.selectPlaceholderText}
              setPercentage={(value: boolean) => setIsPercentage(value)}
              isAddNewButtonShow={isAddNewButtonShow}
            />
          </div>
          {props.taxType === "TDS" ||
          (props.taxType === "TCS" && props.item.tcs_rate === 0) ? (
            <div className="tds-entry">
              <input
                type="text"
                className={`form-control tds-entry w-100`}
                name={props.inputId}
                id={props.inputId}
                placeholder={isPercentage ? "Enter %" : props.inputPlaceHolder}
                onChange={(e) => {
                  props.handleAmountChange(
                    isPercentage ? "tds_percentage" : "tds_amount",
                    e,
                    props.index,
                    props.taxType
                  );
                }}
                maxLength={15}
                value={
                  props.taxType === "TDS"
                    ? !isPercentage && props.item.tds_amount
                      ? props.item.tds_amount || ""
                      : isPercentage
                      ? props.item.tds_rate || ""
                      : props.item.tds_amount || ""
                    : props.item.tcs_amount
                    ? props.item.tcs_amount || ""
                    : props.item.tcs_rate || ""
                }
                onBlur={() => {
                  props.handleOnBlur(
                    isPercentage ? "tds_percentage" : "tds_amount",
                    props.item.tds_amount,
                    props.index
                  );
                }}
              />
            </div>
          ) : null}
        </div>
        {props.item.tds_id || props.item.tcs_id ? (
          <Link
            to="#"
            className="removeItem"
            onClick={() => {
              props.clearTaxSelection(props.index);
              setIsPercentage(false);
            }}
          >
            <ItemCloseButton />
          </Link>
        ) : null}
      </div>
      <span
        key={"tdsTaxLabel-" + props.index}
        className="tax-amount-label p-0"
        id={"tds-amount-label" + props.index}
      ></span>
    </>
  );
}
export default SelectTDSAndTCS;
// export type { Props };
