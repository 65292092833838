import { useEffect, useRef, useState } from "react";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import { BillingSideMenuModal } from "../calendarBillingSideMenu/BillingSideMenuModal";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { Container } from "../../../common/components/htmlTags/Container";
import { ConfigureProrationFormModal } from "./ConfigureProrationFormModal";
import useCommonData from "../../../hooks/useCommon";
import "./CalendarBilling.css";
import SideWideBillingList from "./siteWidelist/SiteWideBillingList";
import PlanSpecificBillingList from "./planSpecificList/PlanSpecificBillingList";
import { PlanBillingSideMenuModal } from "../planCalendarBillingSideMenu/PlanBillingSideMenuModal";
import {
  CalendarPlanSpecificBillingList,
  CalendarSiteWideBillingList,
  DeleteInitialData,
} from "../calendarBillingTypes";
import { deleteInitialData } from "../calenderBillingStateInitialization";
import CalenderDelete from "../CalenderDelete";
import {
  useFetchPlanSpecificBillingList,
  useFetchSiteWideBillingList,
} from "../hooks/useFetchBillingList";
import useListData from "../../../hooks/useListData";

export const CalendarBillingList = () => {
  const { subscriptionRolePermission } = usePermissionHandler();
  const { currentUserInfo } = useCommonData();
  const { page, itemsPerPage } = useListData();
  const { getPlanSpecificBilling } = useFetchPlanSpecificBillingList();
  const { getSiteWideBilling } = useFetchSiteWideBillingList();
  const [activeTab, setActiveTab] = useState("siteWide");
  const [planSpecificBillingList, setPlanSpecificBillingList] =
    useState<CalendarPlanSpecificBillingList>({ plan_calendar_invoicing: [] });
  const [siteWideBillingList, setSiteWideBillingList] =
    useState<CalendarSiteWideBillingList>({ calendar_invoicing: [] });
  const [deleteAction, setDeleteAction] =
    useState<DeleteInitialData>(deleteInitialData);
  /**
   * Setting active tab in session storage
   */
  function handleTabClick(tab: string) {
    setActiveTab(tab);
    sessionStorage.setItem("childInvoice", tab);
    if (tab === "siteWide") {
      siteWideList();
    } else {
      planSpecificList();
    }
  }

  const deleteListData = (actionType: string, id: number, addonName: string) =>
    setDeleteAction({ actionType: actionType, id: id, addonName: addonName });

  useEffect(() => {
    if (activeTab === "planSpecific") planSpecificList();
    else if (activeTab === "siteWide") siteWideList();
  }, [currentUserInfo.organization_id, page, itemsPerPage]);

  const siteWideList = async () => {
    getSiteWideBilling(setSiteWideBillingList);
  };

  const planSpecificList = async () => {
    getPlanSpecificBilling(setPlanSpecificBillingList);
  };

  return (
    <>
      <Card>
        <CardHeader
          headerActions={
            <Container className="d-flex w-100 reporting-tag-header-btn-group">
              {subscriptionRolePermission.includes("Create") && (
                <ConfigureProrationFormModal />
              )}
            </Container>
          }
          wrapperClass="reporting-tag-list-header"
        >
          Calendar Invoicing
        </CardHeader>
        <div className="customer-detail-card w-100" id="customer-detail-card">
          <div className="overview-wrapper tab-content-wrapper">
            <ul
              className="nav nav-pills pills-tab mt-0"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeTab === "siteWide" ? "nav-link active" : "nav-link"
                  }
                  id="pills-overview-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-overview"
                  type="button"
                  role="tab"
                  aria-controls="pills-overview"
                  aria-selected="true"
                  onClick={() => handleTabClick("siteWide")}
                >
                  Site-wide Invoicing
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeTab === "planSpecific"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="pills-statement-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-statement"
                  type="button"
                  role="tab"
                  aria-controls="pills-statement"
                  aria-selected="false"
                  onClick={() => handleTabClick("planSpecific")}
                >
                  Plan-specific Invoicing
                </button>
              </li>
            </ul>
            <div className="tab-content pb-0" id="pills-tabContent">
              <div
                className={
                  activeTab === "siteWide"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-overview"
                role="tabpanel"
                aria-labelledby="pills-overview-tab"
                tabIndex={0}
              >
                <div className="overview-wrapper tab-content-wrapper">
                  <BillingSideMenuModal
                    getSiteWideBilling={siteWideList}
                    savedFrequencies={siteWideBillingList.calendar_invoicing.map(
                      (siteWide) => siteWide.invoicing_frequency
                    )}
                  />
                  <div className="card-body-wrapper d-flex h-100">
                    <SideWideBillingList
                      siteWideBillingList={siteWideBillingList}
                      refreshList={siteWideList}
                      deleteListData={deleteListData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                activeTab === "planSpecific"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-statement"
              role="tabpanel"
              aria-labelledby="pills-statement-tab"
              tabIndex={0}
            >
              <div className="overview-wrapper tab-content-wrapper">
                <div className="m-r-9">
                  <PlanBillingSideMenuModal
                    refreshPlanSpecificList={planSpecificList}
                  />
                </div>
                <div className="card-body-wrapper d-flex h-100 overflowX-hidden m-t-18">
                  <PlanSpecificBillingList
                    planSpecificBillingList={planSpecificBillingList}
                    refreshList={planSpecificList}
                    deleteListData={deleteListData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <CalenderDelete
        deleteAddonId={deleteAction.id}
        deleteAddonName={deleteAction.addonName}
        deleteActionType={deleteAction.actionType}
        refreshAddon={siteWideList}
        refreshPlanSpecificList={planSpecificList}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
};
