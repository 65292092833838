import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customSelectStyle } from "../SelectCustomStyle";
import { SubAccountValues } from "../../../../types";
import { initialSubAccountValues } from "../../../home/paymentsMade/components/createBillPayment/StateInitialization";
import useCommonData from "../../../hooks/useCommon";
import { subAccountsOfParent } from "../../../home/chartOfAccounts/coaSlice";
import { colors } from "../../../constants/colors";

type Props = {
  bankChargesAccountId: string | number | null;
  handleBankCharge: (e: any) => void;
  error: any;
  wrapperClass?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
};
function ExpenseAccountSelect(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [expensesSubAccounts, setExpensesSubAccounts] = useState<
    SubAccountValues[]
  >([initialSubAccountValues]);

  useEffect(() => {
    getExpensesSubAccounts();
  }, [currentUserInfo.organization_id]);

  /**
   * Get Expense accounts list drop down Api
   */
  const getExpensesSubAccounts = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Expenses"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setExpensesSubAccounts(response);
      }
    }
  };
  /**
   * Get the expense sub-account account name in the selection dropdown : Edit Bill Payment
   */
  const ExpenseAccountName = expensesSubAccounts
    .filter(
      (subAccount) => subAccount.id === Number(props.bankChargesAccountId)
    )
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  /**
   * Get the list of all Expenses sub accounts
   */
  const ExpensesSubAccounts = expensesSubAccounts.map((subAccount) => ({
    label: subAccount.account_name,
    value: subAccount.id,
    account_type: subAccount.account_type,
  }));

  const LabelWithRoot = ({ data, isFocused, isSelected }: any) => (
    <div
      onClick={() => {
        props.handleBankCharge({ value: data.value, label: data.label });
        setMenuIsOpen(false);
      }}
      className="custom-select-option-wrapper"
      style={{
        backgroundColor: isFocused || isSelected ? colors.whisper : "white",
      }}
    >
      <span className="custom-select-option-account-name">{data.label}</span>
      {data.account_type && (
        <div className="custom-select-option-account-root">
          {data.account_type}
        </div>
      )}
    </div>
  );

  return (
    <Select
      name="bank_charges_account_id"
      value={ExpenseAccountName}
      options={ExpensesSubAccounts}
      placeholder="Choose"
      onChange={props.handleBankCharge}
      className={`form-select custom-select payment_method  m-0 bank_charge ${
        props.error ? "error" : ""
      } ${props.wrapperClass ? props.wrapperClass : ""}`}
      styles={customSelectStyle}
      isDisabled={props.disabled}
      isClearable={props.isClearable}
      isSearchable={props.isSearchable}
      components={{ Option: LabelWithRoot }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
}

export default ExpenseAccountSelect;
