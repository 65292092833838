import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cancelClosingFiscalYears, closeFiscalYear } from "./fiscalYearClosingAPI";

export const closeFiscalYearSlice = createAsyncThunk(
  "fiscal-year-closing",
  async (data: { orgId: number; closing_date: string[] }) => {
    return await closeFiscalYear(data);
  }
);
export const cancelClosingSchedule = createAsyncThunk(
  "cancel-closing-schedule",
  async (data: { orgId: number; closing_date: string[] }) => {
    return await cancelClosingFiscalYears(data);
  }
);
