import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import { DockedButtonGroup } from "../../../common/components/button/DockedButtonGroup";
import { Container } from "../../../common/components/htmlTags/Container";
import { Form } from "../../../common/components/htmlTags/Form";
import useCommonData from "../../../hooks/useCommon";
import {
  configurationProrationSave,
  prorationPreference,
} from "../../subscription/subscriptionSlice";
import { useEffect, useState } from "react";
import InfoTooltip from "../../../common/components/InfoTooltip";

type Props = {
  handleClose: () => void;
};
export const ConfigureProrationForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [initialValues, setInitialValues] = useState({
    is_proration_enabled: false,
  });

  async function getProrationPreferenceData() {
    const responseAction = await dispatch(
      prorationPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setInitialValues({
        is_proration_enabled: response.is_proration_enabled,
      });
      // return response;
    } else {
    }
  }

  useEffect(() => {
    getProrationPreferenceData();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (value) => {
      //call the API to save the changes
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        configurationProrationSave({
          orgId: currentUserInfo.organization_id,
          isProrationEnabled: Boolean(value.is_proration_enabled),
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          `${
            Boolean(value.is_proration_enabled)
              ? "Enabled proration for Calendar Invoicing successfully"
              : "Disabled proration for Calendar Invoicing successfully"
          }`,
          "tags-config-succcess"
        );
        props.handleClose();
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });
  return (
    <Form
      className="reporting-tag-settings-form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Container className="reporting-tags-settings-form-checkbox-wrapper">
        <FormControlLabel
          id="is_proration_enabled"
          name="is_proration_enabled"
          control={
            <Checkbox
              value={formik.values.is_proration_enabled}
              checked={formik.values.is_proration_enabled}
              onChange={formik.handleChange}
              className="reporting-tags-settings-form-checkbox"
            />
          }
          label="Enable Proration"
        />
        <InfoTooltip
          title="Automatically adjust credits when a subscription is created or changed outside of the invoicing date"
          placement={"right-end"}
        />
      </Container>
      <Container className="w-100">
        <DockedButtonGroup
          isDeleteButtonDisabled={true}
          isDeleteButtonEnabled={false}
          isEdit={false}
          isSaveDisabled={false}
          isModal={true}
          onSave={() => {
            formik.submitForm();
          }}
          onDelete={() => {}}
          onCancel={props.handleClose}
          saveButtonType="button"
          shouldDockToBottom={false}
        />
      </Container>
    </Form>
  );
};
