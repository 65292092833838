import React, { forwardRef } from "react";
import { HorizontalTab } from "../../../../common/components/HorizontalTab";
import "./CNAllocationDetails.css";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import {
  CreditNoteAllocatedList,
  CreditNoteDetailValues,
} from "../../../../../types";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { getAllocationDetails } from "../../creditNoteSlice";
import InvoiceCredited from "./InvoiceCredited";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
type Props = {
  creditNoteData: CreditNoteDetailValues;
  refreshCNDetails: () => void;
};
const CNAllocationDetails: ForwardRefRenderFunction<any, Props> = (
  { creditNoteData, refreshCNDetails },
  ref
) => {
  const dispatch = useAppDispatch();
  const page = 1;
  const itemsPerPage = 20;
  // const id = useParams().editBillId;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { creditNoteRolePermission, subModulesWithPermission } =
    usePermissionHandler();
  const [allocationCount, setAllocationCount] = useState(0);
  const [allocationList, setAllocationList] = useState<
    CreditNoteAllocatedList[]
  >([]);
  const [isParent, setIsParent] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      fetchCreditAllocation: getCreditAllocationDetails,
    }),
    [creditNoteData.id]
  );

  useEffect(() => {
    if (
      Number(creditNoteData.organization_id) !== currentUserInfo.organization_id
    ) {
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  }, [creditNoteData.organization_id, currentUserInfo.organization_id]);

  useEffect(() => {
    if (creditNoteData.id) getCreditAllocationDetails();
  }, [creditNoteData.id]);

  const getCreditAllocationDetails = async () => {
    const responseAction = await dispatch(
      getAllocationDetails({
        creditNoteId: Number(creditNoteData.id),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAllocationCount(response.total);
        setAllocationList(response.allocation_list);
      }
    }
  };
  return (
    <>
      {allocationCount > 0 && creditNoteRolePermission.includes("Read") && (
        <div className="dn-pm-detail-bill" key={`${isParent}`}>
          <HorizontalTab
            tabHeaders={[`Invoice Credited (${allocationCount})`]}
            tabBody={[
              <InvoiceCredited
                invoiceList={allocationList}
                module="INV"
                creditNoteData={creditNoteData}
                isParent={isParent}
                key={"1"}
                refreshCNDetails={refreshCNDetails}
                creditNoteRolePermission={creditNoteRolePermission}
                subModulesWithPermission={subModulesWithPermission}
              />,
            ]}
            wrapperClass="bill-dn-pm-detail-tab"
            disableRerender={true}
          />
        </div>
      )}
    </>
  );
};
export default React.memo(forwardRef(CNAllocationDetails));
