import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PlusIconGreen } from "../../../../assets/images";
import { CustomFieldFormModal } from "../../../home/customFields/components/customFieldFormModal/customFieldFomModal";

type Props = {
  title: string;
  index: number;
  createUrl: string;
  permission?: boolean;
  redirectTo?: string;
  module?: string;
  subModule?: string;
  consumerId?: string;
  children: React.ReactNode;
  checkLimit: (type: string) => Promise<Boolean>;
  wrapperClass?: string;
};
function AccordianItem(props: Props) {
  const navigate = useNavigate();
  const handleAddNew = async () => {
    let exceed = await props.checkLimit(props.title);
    if (exceed) $("#transactionLimitModal").modal("show");
    else {
      sessionStorage.setItem(
        "preservedLocation",
        JSON.stringify({
          state: {
            redirectTo: props.redirectTo,
            consumerId: props.consumerId,
          },
        })
      );
      navigate(props.createUrl, {
        state: {
          redirectTo: props.redirectTo,
          consumerId: props.consumerId,
        },
      });
    }
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={"accord-item-header-" + props.index}>
        <button
          className={`accordion-button collapsed ${
            props.wrapperClass ? props.wrapperClass : ""
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#accord-item-content-" + props.index}
          aria-expanded="true"
          aria-controls={"accord-item-content-" + props.index}
        >
          <div className="plus-icon position-relative">
            <span className="horizontal-line position-absolute"></span>
            <span className="vertical-line position-absolute"></span>
          </div>
          {props.title}
        </button>
        {props.permission && props.module !== "Custom Fields" ? (
          <Link
            to="#"
            className="add-new-item green-text"
            onClick={(e) => {
              e.preventDefault();
              handleAddNew();
            }}
          >
            <img src={PlusIconGreen} alt="plus-icon" />
            Add New
          </Link>
        ) : null}
        {props.module === "Custom Fields" && (
          <CustomFieldFormModal
            module={props.subModule ? props.subModule : ""}
          />
        )}
      </h2>
      <div
        id={"accord-item-content-" + props.index}
        className="accord-item-content accordion-collapse collapse"
        aria-labelledby={"accord-item-header-" + props.index}
      >
        <div className="accordion-body">{props.children}</div>
      </div>
    </div>
  );
}

export default AccordianItem;
