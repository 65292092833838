import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import {
  AddressRef,
  ContactRef,
  CreateAddressValues,
  CreateContactError,
  CreateContactValues,
  CreateVendorValues,
  DefaultDetailsRef,
  ObjectType,
  OtherDetailsRef,
  OtherDetailsType,
  VendorDefaultValues,
} from "../../../../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import { SalesPurchaseDefault } from "../../../../common/components/SalesPurchaseDefault/SalesPurchaseDefault";
import { noAccessErrorCodes } from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import OtherDetails from "../../../customerManagement/components/customerForm/OtherDetails";
import "../../Vendors.css";
import {
  createVendor,
  createVendorAddress,
  createVendorContact,
  deleteVendorContactDetails,
  editVendorDetails,
  updateVendorBillingAddress,
  updateVendorCombinedAddress,
  updateVendorContact,
  updateVendorShippingAddress,
  vendorDetails,
  vendorDropdownList,
} from "../../vendorSlice";
import AddressDetails from "./AddressDetails";
import ContactPerson from "./ContactPerson";
import DefaultDetails from "./DefaultDetails";
import {
  defaultValues,
  initialAddressValues,
  initialContact,
  initialVendorValues,
  otherDetailsValue,
} from "./StateInitialization";
import {
  checkContactFormError,
  validateAddressDetails,
  validateContactPerson,
  validatePurchaseDefaultForm,
  validateVendorForm,
} from "./ValidateVendorForm";
import {
  vendorCreatePayloadRefiner,
  vendorEditPayloadRefiner,
} from "../../helpers/payloadRefiner";

function VendorForm(prop: any) {
  const { dispatch, navigate, isLoading, currentUserInfo } = useCommonData();
  const { isGstOrg } = useGst();
  const defaultRef = useRef<DefaultDetailsRef | null>(null);
  const otherRef = useRef<OtherDetailsRef | null>(null);
  const contactRef = useRef<ContactRef | null>(null);
  const addressRef = useRef<AddressRef | null>(null);
  const vendorId = prop?.id ? Number(prop.id) : 0;
  const redirectTo = prop?.redirectTo;
  let [vendorFormValues, setVendorFormValues] =
    useState<CreateVendorValues>(initialVendorValues);
  const [defaultFormValues, setDefaultFormValues] =
    useState<VendorDefaultValues>(defaultValues);
  const [otherFormValues, setOtherFormValues] =
    useState<OtherDetailsType>(otherDetailsValue);
  const [defaultConsumerValues, setDefaultConsumerValues] =
    useState<ObjectType>({
      account: {
        id: null,
        name: "",
      },
      discount: {
        discount: "",
        account: {
          id: null,
          name: "",
        },
        discountType: "Absolute",
      },
      paymentTerm: {
        id: null,
        name: "",
      },
    });
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [billingFormErrors, setBillingFormErrors] = useState<ObjectType>({});
  const [shippingFormErrors, setShippingFormErrors] = useState<ObjectType>({});
  const [purchaseDefaultErrorCount, setPurchaseDefaultErrorCount] = useState(0);
  const [purchaseDefaultError, setPurchaseDefaultError] =
    useState<ObjectType>();
  const [contactFormErrors, setContactFormErrors] = useState<
    CreateContactError[]
  >([]);
  const [otherErrorCount, setOtherErrorCount] = useState(0);
  const [billingErrorCount, setBillingErrorCount] = useState(0);
  const [shippingErrorCount, setShippingErrorCount] = useState(0);
  const [contactErrorCount, setContactErrorCount] = useState(0);
  const [contactErrorTempCount, setContactErrorTempCount] = useState(0);
  const [shippingSameAsBilling, setShippingSameAsBilling] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [contactArray, setContactArray] = useState<CreateContactValues[]>([
    initialContact,
  ]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [billingFormValues, setBillingFormValues] =
    useState<CreateAddressValues>(initialAddressValues);
  const [shippingFormValues, setShippingFormValues] =
    useState<CreateAddressValues>(initialAddressValues);
  const [organizationId, setOrganizationId] = useState(null);

  useEffect(() => {
    if (vendorId) {
      getVendorDetails();
      $("#add_vendor_form").css("display", "none");
    }
  }, [currentUserInfo.organization_id]);
  /**
   * Fetch vendor details by id
   */
  const getVendorDetails = async () => {
    dispatch(setLoaderState(true));
    $("#add_vendor_form").css("display", "none");
    const responseAction = await dispatch(
      vendorDetails({ id: vendorId, orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      $("#add_vendor_form").css("display", "block");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      if (Object.keys(response).length && !("error" in response)) {
        let defaultValues = {
          company_name: response.company_name,
          vendor_email: response.vendor_email,
          vendor_phone: response.vendor_phone ? response.vendor_phone : "",
          vendor_display_name: response.vendor_display_name,
          short_code: response.short_code,
        };
        let otherValues = {
          pan_number: response.other_details.pan_number,
          gst_registration_type: response.other_details.gst_registration_type,
          gstin: response.other_details.gstin,
          opening_balance: response.other_details.opening_balance
            ? response.other_details.opening_balance
            : "",
          currency_id: response.other_details.currency_id,
          payment_term_id: response.other_details.payment_term_id,
          is_contact_org_closed: response.is_contact_org_closed,
          tag_ids: response.other_details.tags.map((tag: any) => tag.id),
          transaction_exist: response.transaction_exist,
        };
        setCurrencyCode(response.other_details.currency_code);
        let defaultConsumerValuesResponse = {
          purchase_account_id: response.purchase_default.purchase_account_id,
          purchase_account_name:
            response.purchase_default.purchase_account_name,
          discount_account_id: response.purchase_default.discount_account_id,
          discount_account_name:
            response.purchase_default.discount_account_name,
          discount_type: response.purchase_default.discount_type,
          discount: response.purchase_default.discount,
          payment_term_id: response.purchase_default.payment_term_id,
        };
        setDefaultConsumerValues({
          ...defaultConsumerValues,
          account: {
            id: defaultConsumerValuesResponse.purchase_account_id,
            name: defaultConsumerValuesResponse.purchase_account_name,
          },
          discount: {
            discount: defaultConsumerValuesResponse.discount,
            discountType: defaultConsumerValuesResponse.discount_type,
            account: {
              id: defaultConsumerValuesResponse.discount_account_id,
              name: defaultConsumerValuesResponse.discount_account_name,
            },
          },
          paymentTerm: {
            id: defaultConsumerValuesResponse.payment_term_id,
            name: "",
          },
        });
        setVendorFormValues({
          ...defaultValues,
          ...otherValues,
          ...defaultConsumerValuesResponse,
        });
        setDefaultFormValues(defaultValues);
        setOtherFormValues(otherValues);
        setOrganizationId(response.organization_id);
        let addressData = response.address_details;
        addressData.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : "",
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : "",
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : "",
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
          };
          if (addressDetails.is_billing && addressDetails.is_shipping) {
            setBillingFormValues(address);
            setShippingFormValues(address);
            setShippingSameAsBilling(true);
          } else {
            setShippingSameAsBilling(false);
            if (addressDetails.is_billing) {
              setBillingFormValues(address);
            } else if (addressDetails.is_shipping) {
              setShippingFormValues(address);
            }
          }
        });
        let contactData = response.contact_persons;
        if (contactData.length) {
          let contacts: CreateContactValues[] = [];
          contactData.forEach(async (contactDetails: any) => {
            let contactObject = {
              first_name: contactDetails.first_name,
              last_name: contactDetails.last_name,
              email: contactDetails.email,
              phone: contactDetails.phone ? contactDetails.phone : "",
              is_primary: contactDetails.is_primary,
              id: contactDetails.id,
            };
            contacts.push(contactObject);
          });
          setContactArray(contacts);
        }
      } else {
        dispatch(setErrorID(noAccessErrorCodes.vendorEdit));
        // navigate(redirectTo);
      }
    }
  };
  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let defaultFormValues = defaultRef.current?.defaultData;
    let otherFormValues = otherRef.current?.otherDetails;
    let contactArray = contactRef.current?.contactArray;
    vendorFormValues = {
      ...vendorFormValues,
      ...defaultFormValues,
      ...otherFormValues,
    };
    const { errors, count } = validateVendorForm(vendorFormValues, isGstOrg);
    setOtherErrorCount(count);
    const defaultFormErrors = validatePurchaseDefaultForm(
      defaultConsumerValues
    );
    setPurchaseDefaultError(defaultFormErrors);
    if (Object.keys(defaultFormErrors).length > 0) {
      setPurchaseDefaultErrorCount(1);
    } else {
      setPurchaseDefaultErrorCount(0);
    }
    let billingFormValues = addressRef.current?.billingFormValues;
    let shippingFormValues = addressRef.current?.shippingFormValues;
    let billingAddressErrors = { errors: {}, count: 0 };
    if (billingFormValues) {
      billingAddressErrors = validateAddressDetails(billingFormValues);
      setBillingErrorCount(billingAddressErrors?.count);
    }
    let shippingAddressErrors = { errors: {}, count: 0 };
    if (shippingFormValues) {
      shippingAddressErrors = validateAddressDetails(shippingFormValues);
      setShippingErrorCount(shippingAddressErrors?.count);
    }
    let contactErrors: any = [];
    let contErrCount = 0;
    contactArray?.map((item) => {
      let contactErrorResponse = validateContactPerson(item);
      if (contactErrorResponse) {
        contactErrors.push(contactErrorResponse);
        contErrCount += Object.keys(contactErrorResponse).length;
      } else {
        contactErrors.push({ email: "", phone: "" });
      }
    });
    let contactError = checkContactFormError(contactErrors);
    if (!contactError) setContactErrorCount(0);
    setContactErrorCount(contErrCount);
    setContactErrorTempCount(contErrCount);
    if (
      Object.keys(errors).length ||
      Object.keys(billingAddressErrors.errors).length ||
      Object.keys(shippingAddressErrors.errors).length ||
      contactError ||
      defaultRef.current?.isShortCodeExist
    ) {
      setFormErrors(errors);
      setBillingFormErrors(billingAddressErrors.errors);
      setShippingFormErrors(shippingAddressErrors.errors);
      if (contactErrors.length) {
        setContactFormErrors(contactErrors);
      } else {
        setContactFormErrors([]);
      }
    } else if (Object.keys(defaultFormErrors).length > 0) {
      return;
    } else {
      $("#form-btn").addClass("customer-form-section-disable");
      if (vendorId) {
        updateVendorSubmit();
      } else {
        createVendorSubmit();
      }
    }
  };

  /**
   * Function to handle vendor creation
   */
  const createVendorSubmit = async () => {
    dispatch(setListFilters({}));
    dispatch(setLoaderState(true));
    let vendorInputs: any = { ...vendorFormValues };
    let key: keyof typeof vendorInputs;
    for (key in vendorInputs) {
      if (vendorInputs[key] === "") {
        vendorInputs[key] = null;
      }
      if (key === "vendor_display_name") {
        vendorInputs[key] = vendorInputs[key].trim();
      }
    }

    const createVendorResponseAction = await dispatch(
      createVendor({
        values: vendorCreatePayloadRefiner(
          vendorFormValues,
          defaultConsumerValues
        ),
        orgId: currentUserInfo.organization_id,
      })
    );
    const createVendorResponse = createVendorResponseAction.payload;
    if (
      Object.keys(createVendorResponse).length &&
      !("error" in createVendorResponse)
    ) {
      dispatch(setLoaderState(false));
      let vendorId = createVendorResponse.id;
      if (vendorId) {
        let billingFormValues = addressRef.current?.billingFormValues;
        if (billingFormValues) {
          billingFormValues["is_billing"] = true;
          billingFormValues["is_shipping"] = false;
          if (addressRef.current?.shippingSameAsBilling) {
            billingFormValues["is_shipping"] = true;
          }
          let billingInputs: any = { ...billingFormValues };
          let key: keyof typeof billingInputs;
          for (key in billingInputs) {
            if (billingInputs[key] === "") {
              billingInputs[key] = null;
            }
          }
          await dispatch(
            createVendorAddress({
              id: vendorId,
              addressInputs: billingInputs,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
        if (!addressRef.current?.shippingSameAsBilling) {
          let shippingFormValues = addressRef.current?.shippingFormValues;
          if (shippingFormValues) {
            shippingFormValues["is_billing"] = false;
            shippingFormValues["is_shipping"] = true;
            let shippingInputs: any = { ...shippingFormValues };
            let key: keyof typeof shippingInputs;
            for (key in shippingInputs) {
              if (shippingInputs[key] === "") {
                shippingInputs[key] = null;
              }
            }
            await dispatch(
              createVendorAddress({
                id: vendorId,
                addressInputs: shippingInputs,
                orgId: currentUserInfo.organization_id,
              })
            );
          }
        }
        await checkContacts(vendorId);
        if (redirectTo) {
          // navigate(redirectTo);
          navigate(`/vendors/detail/${vendorId}`, { replace: true });
        }
        if (prop?.handleModalClose) {
          prop?.handleModalClose();
          await dispatch(vendorDropdownList(currentUserInfo.organization_id));
        }
        toast.success("Vendor created successfully!", {
          toastId: "vendor-create-success",
          closeButton: false,
          position: "top-center",
        });
      }

      $("#form-btn").removeClass("customer-form-section-disable");
    } else if ("error" in createVendorResponse) {
      dispatch(setLoaderState(false));
      $("#form-btn").removeClass("customer-form-section-disable");
    } else {
      dispatch(setLoaderState(false));
    }
  };
  /**
   * Function to handle update vendor action
   */
  const updateVendorSubmit = async () => {
    dispatch(setLoaderState(true));
    dispatch(setListFilters({}));
    let vendorInputs: any = { ...vendorFormValues };
    let key: keyof typeof vendorInputs;
    for (key in vendorInputs) {
      if (vendorInputs[key] === "") {
        vendorInputs[key] = null;
      }
    }
    const response = await dispatch(
      editVendorDetails({
        id: vendorId,
        editVendorInputs: vendorEditPayloadRefiner(
          vendorFormValues,
          defaultConsumerValues
        ),
        orgId: currentUserInfo.organization_id,
      })
    )
      .then(() => {
        dispatch(setLoaderState(false));
      })
      .catch(() => {
        dispatch(setLoaderState(false));
      });

    let billingFormValues: any = addressRef.current?.billingFormValues;

    billingFormValues["is_billing"] = true;
    billingFormValues["is_shipping"] = false;
    if (addressRef.current?.shippingSameAsBilling) {
      billingFormValues["is_shipping"] = true;
      let billingInputs: any = { ...billingFormValues };
      let key: keyof typeof billingInputs;
      for (key in billingInputs) {
        if (billingInputs[key] === "") {
          billingInputs[key] = null;
        }
      }
      await dispatch(
        updateVendorCombinedAddress({
          vendorId: vendorId,
          editCombinedInput: billingInputs,
          orgId: currentUserInfo.organization_id,
        })
      );
    } else {
      let billingInputs: any = { ...billingFormValues };
      let key: keyof typeof billingInputs;
      for (key in billingInputs) {
        if (billingInputs[key] === "") {
          billingInputs[key] = null;
        }
      }
      await dispatch(
        updateVendorBillingAddress({
          vendorId: vendorId,
          editBillingInput: billingInputs,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let shippingFormValues: any = addressRef.current?.shippingFormValues;

    if (!addressRef.current?.shippingSameAsBilling) {
      shippingFormValues["is_billing"] = false;
      shippingFormValues["is_shipping"] = true;
      let shippingInputs: any = { ...shippingFormValues };
      let key: keyof typeof shippingInputs;
      for (key in shippingInputs) {
        if (shippingInputs[key] === "") {
          shippingInputs[key] = null;
        }
      }
      await dispatch(
        updateVendorShippingAddress({
          vendorId: vendorId,
          editShippingInput: shippingInputs,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    await contactRef.current?.deleteContacts();
    await checkContacts(vendorId);
    // navigate(redirectTo);
    navigate(`/vendors/detail/${vendorId}`, { replace: true });
    toast.success("Vendor updated successfully!", {
      toastId: "vendor-update-success",
      closeButton: false,
      position: "top-center",
    });
  };
  /**
   * Check contacts have any value or not
   */
  const checkContacts = async (vendorId: number) => {
    let isContact = false;
    const contacts: any = contactRef.current?.contactArray;
    let contactEditApis = [];
    let contactCreateApis = [];
    let createContactArray = [];
    for (let key in contacts) {
      if (
        contacts[key].first_name !== "" ||
        contacts[key].last_name !== "" ||
        contacts[key].phone !== "" ||
        contacts[key].email !== ""
      ) {
        let contactObj: any = contacts[key];
        let index: keyof typeof contactObj;
        for (index in contactObj) {
          if (contactObj[index] === "") {
            contactObj[index] = null;
          }
        }
        if (contactObj.id) {
          let contactId = contactObj.id;
          if (
            contacts[key].first_name === null &&
            contacts[key].last_name === null &&
            contacts[key].phone === null &&
            contacts[key].email === null
          ) {
            contactEditApis.push(
              dispatch(
                deleteVendorContactDetails({
                  vendorId,
                  contactId: Number(contactId),
                  orgId: currentUserInfo.organization_id,
                })
              )
            );
          }
          delete contactObj.id;
          contactEditApis.push(
            dispatch(
              updateVendorContact({
                vendorId: vendorId,
                contactId: Number(contactId),
                editContactInputs: contactObj,
                orgId: currentUserInfo.organization_id,
              })
            )
          );
        } else {
          createContactArray.push(contactObj);
        }
        isContact = true;
      }
    }
    if (contactEditApis.length) {
      await Promise.all(contactEditApis);
    }
    for (let index = 0; index < createContactArray.length; index++) {
      contactCreateApis.push(
        dispatch(
          createVendorContact({
            id: vendorId,
            contactInputs: createContactArray[index],
            orgId: currentUserInfo.organization_id,
          })
        )
      );
    }
    if (contactCreateApis.length) {
      await Promise.all(contactCreateApis);
    }
    return isContact;
  };

  $(function () {
    setTimeout(() => {
      $(document).on("focus", ".multiselect-container .searchBox", function () {
        $(this).parent().addClass("focused");
      });
      $(document).on("blur", ".multiselect-container .searchBox", function () {
        $(this).parent().removeClass("focused");
      });
    }, 50);
  });

  const handleContactErrors = (count: number) => {
    setContactErrorCount(count);
    setContactErrorTempCount(count);
  };
  const handleAccountChange = (id: number | null) => {
    setDefaultConsumerValues((prevValues: ObjectType) => {
      return {
        ...prevValues,
        account: {
          id: id,
        },
      };
    });
  };
  const handleDiscountChange = (
    discount: string | number,
    account: ObjectType,
    discountType: string
  ) => {
    setDefaultConsumerValues((prevValues: ObjectType) => {
      return {
        ...prevValues,
        discount: {
          discount: discount,
          account: {
            id: account.id,
            name: account.name,
          },
          discountType: discountType,
        },
      };
    });
  };
  const handlePaymentChangeHandle = (paymentTerm: ObjectType) => {
    setDefaultConsumerValues((prevValues: ObjectType) => {
      return {
        ...prevValues,
        paymentTerm: {
          id: paymentTerm.value,
          name: "",
        },
      };
    });
  };
  const getCurrencyCode = (code: string) => {
    setCurrencyCode(code);
  };

  const handleCancel = async () => {
    setVendorFormValues(initialVendorValues);
    setBillingFormValues(initialAddressValues);
    setShippingFormValues(initialAddressValues);
    setContactArray([initialContact]);
    setIsCancel(!isCancel);
    setFormErrors({});
    setShippingFormErrors({});
    setBillingFormErrors({});
    setContactFormErrors([]);
    setOtherErrorCount(0);
    setContactErrorCount(0);
    setBillingErrorCount(0);
    setShippingErrorCount(0);
    $(".nav-link").removeClass("active");
    $(".tab-pane").removeClass("show active");
    $("#pills-other-tab").addClass("active");
    $("#pills-other").addClass("show active");
    // await dispatch(vendorDropdownList(currentUserInfo.organization_id));
    if (prop?.handleModalClose) {
      await dispatch(vendorDropdownList(currentUserInfo.organization_id));
      prop?.handleModalClose();
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        name="add_vendor_form"
        id="add_vendor_form"
        tabIndex={-1}
        className="add-consumer-form customer-form-wrap add_vendor_form"
      >
        <div className="fields-wrapper">
          <DefaultDetails
            defaultFormValues={defaultFormValues}
            formErrors={formErrors}
            vendorId={vendorId}
            ref={defaultRef}
          />
          <section className="form-tab-section px-0">
            <ul
              className="nav nav-pills pills-tab mt-0"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-other-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-other"
                  type="button"
                  role="tab"
                  aria-controls="pills-other"
                  aria-selected="true"
                >
                  Other Details
                  {otherErrorCount ? (
                    <span className="error-count">{otherErrorCount}</span>
                  ) : null}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-purchase-default-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-purchase-default"
                  type="button"
                  role="tab"
                  aria-controls="pills-purchase-default"
                  aria-selected="false"
                >
                  Purchase Default
                  {purchaseDefaultErrorCount ? (
                    <span className="error-count">
                      {purchaseDefaultErrorCount}
                    </span>
                  ) : null}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-address-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-address"
                  type="button"
                  role="tab"
                  aria-controls="pills-address"
                  aria-selected="false"
                >
                  Address Details
                  {billingErrorCount || shippingErrorCount ? (
                    <span className="error-count">
                      {billingErrorCount + shippingErrorCount}
                    </span>
                  ) : null}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Contact Persons
                  {contactErrorCount ? (
                    <span className="error-count">{contactErrorCount}</span>
                  ) : null}
                </button>
              </li>
            </ul>
            <div className="tab-content pb-0" id="pills-tabContent">
              <OtherDetails
                redirectTo={redirectTo}
                otherFormValues={otherFormValues}
                formErrors={formErrors}
                id={vendorId}
                isCancel={isCancel}
                type="Vendor"
                ref={otherRef}
                organizationId={organizationId}
                isContactOrgClosed={vendorFormValues.is_contact_org_closed}
                fetchCurrencyCode={getCurrencyCode}
              />
              <SalesPurchaseDefault
                module="Purchase"
                currencyCode={currencyCode}
                discount={{
                  discount: defaultConsumerValues.discount.discount,
                  discountType: defaultConsumerValues.discount.discountType,
                  account: {
                    id: defaultConsumerValues.discount.account.id,
                    name: defaultConsumerValues.discount.account.name,
                  },
                }}
                account={{
                  name: defaultConsumerValues.account.name,
                  id: defaultConsumerValues.account.id,
                }}
                paymentTerm={{
                  id: defaultConsumerValues.paymentTerm.id,
                  name: defaultConsumerValues.paymentTerm.name,
                }}
                handleAccountChange={handleAccountChange}
                handleDiscountChange={handleDiscountChange}
                handlePaymentChangeHandle={handlePaymentChangeHandle}
                errors={purchaseDefaultError || {}}
              />
              <AddressDetails
                billingFormValues={billingFormValues}
                billingFormErrors={billingFormErrors}
                shippingSameAsBilling={shippingSameAsBilling}
                shippingFormValues={shippingFormValues}
                shippingFormErrors={shippingFormErrors}
                ref={addressRef}
              />
              <ContactPerson
                vendorId={vendorId}
                contactArray={contactArray}
                contactFormErrors={contactFormErrors}
                contactErrorCount={contactErrorCount}
                contactErrorTempCount={contactErrorTempCount}
                handleContactErrors={handleContactErrors}
                ref={contactRef}
              />
            </div>
          </section>
        </div>
        <div className="submit-row row mb-0">
          <div className="col">
            <div
              className="form-button-wrapper w-100 docked-button-container"
              id="form-btn"
            >
              <button className="save-button">Save</button>
              {
                redirectTo === "/vendors" ? (
                  <Link
                    to="/vendors"
                    className="text-decoration-none cancel-button"
                  >
                    Cancel
                  </Link>
                ) : (
                  // redirectTo === "/bills" ||
                  //   redirectTo === "/payment-create" ||
                  // redirectTo === "/debit-note-create" ?
                  <button
                    type="button"
                    className="cancel-button close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                )
                // : null
              }
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default VendorForm;
