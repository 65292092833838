import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormInputField } from "./formInputField/FormInputField";

interface ComponetRef {
  getData: () => { value: string; isValid: boolean };
}

const PhoneInput: ForwardRefRenderFunction<ComponetRef, any> = (props, ref) => {
  const [phone, setPhone] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMeassage] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      getData: getData,
    }),
    [phone, isValid]
  );

  useEffect(() => {
    setPhone(props.phone);
  }, [props.phone]);

  useEffect(() => {
    if (props.error) {
      setIsValid(false);
      setErrorMeassage(props.error);
    }
  }, [props.error]);

  useEffect(() => {
    if (props.onChange) props.onChange();
  }, [props.onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = /^[\+0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setPhone(value);
      props.handleChange({ name: props.name, value });
    }
  };

  const getData = () => {
    let isValid = validatePhone();
    setIsValid(isValid);
    return { value: phone, isValid };
  };

  const validatePhone = () => {
    const phoneRegex = /^[\+]?([0-9]){6,18}$/;
    if (
      (props.isRequired && phone === "") ||
      (phone && !phoneRegex.test(phone))
    ) {
      setErrorMeassage("Please enter valid phone number");
      return false;
    } else {
      setErrorMeassage("");
      return true;
    }
  };
  return (
    <FormInputField
      type="text"
      name={props.name}
      id={props.id}
      className={isValid ? "form-control" : "form-control error"}
      placeholder="Enter phone number"
      label={props.label}
      value={phone}
      onChange={handleChange}
      maxLength={19}
      isRequired={props.isRequired}
      error={errorMessage}
    />
  );
};

export default forwardRef(PhoneInput);
