import { SolidCancelButton } from "../button/SolidCancelButton";
import { SolidDeleteButton } from "../button/SolidDeleteButton";
import "./delete.css";

type DeleteProps = {
  content: string | React.ReactNode;
  onDeleteConfirm: () => void;
  handleModalClose: () => void;
};
export const Delete = (props: DeleteProps) => {
  return (
    <div className="delete-allocation-alert-wrapper">
      <span>{props.content}</span>
      <div className="modal-footer">
        <SolidCancelButton
          onClick={props.handleModalClose}
          isDisabled={false}
        />
        <SolidDeleteButton
          onClick={() => {
            props.onDeleteConfirm();
            props.handleModalClose();
          }}
          isDisabled={false}
        />
      </div>
    </div>
  );
};
