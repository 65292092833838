import React from "react";
import InfoTooltip from "../InfoTooltip";
import "../../../../index.css";
import './ShortCode.css';

type Props = {
  label: string;
  shortCode: string;
  isRequired: boolean;
  errorMessage: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isShortCodeSuccess: boolean;
  isShortCodeExist: boolean;
  isShortCodeLoader: boolean;
  infoMessage: string;
  isDisabled: boolean;
};
function ShortCode({
  label,
  shortCode,
  isRequired,
  errorMessage,
  handleChange,
  isShortCodeSuccess,
  isShortCodeExist,
  isShortCodeLoader,
  infoMessage,
  isDisabled,
}: Props) {
  return (
    <div className="col-12 col-lg-4 mw-325">
      <label htmlFor="short_code" className={isRequired ? "required short-code-label" : "short-code-label"}>
        {label}
        <InfoTooltip title={infoMessage} />
      </label>
      <div className="input-with-loader position-relative">
        <input
          type="text"
          name="short_code"
          id="short_code"
          className={
            (errorMessage && shortCode === "") || isShortCodeExist
              ? "form-control unavailable"
              : isShortCodeSuccess && shortCode !== ""
              ? "form-control available"
              : "form-control"
          }
          placeholder=""
          value={shortCode}
          onChange={handleChange}
          maxLength={4}
          disabled={isDisabled}
        />
        {isShortCodeLoader ? <span className="loader-small show"></span> : ""}
      </div>

      <span
        className={
          isShortCodeSuccess && shortCode.length >= 1
            ? "available-span"
            : isShortCodeExist || errorMessage
            ? "unavailable-span"
            : ""
        }
      >
        {errorMessage}
      </span>
    </div>
  );
}

export default React.memo(ShortCode);
