import { useParams } from "react-router-dom";
import { JVItemValues } from "../../../../../types";
import useCommonData from "../../../../hooks/useCommon";
import { journalDetails } from "../../journalVoucherSlice";
import { jvInitialValues } from "../createJournalVoucher/StateInitialization";
import { setLoaderState } from "../../../../common/commonSlice";

export const useJVFormIntitialization = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { editId } = useParams();
  const fetchJVDetails = async (setJVValues: React.SetStateAction<any>) => {
    dispatch(setLoaderState(true));
    let details: any = {};
    const responseAction = await dispatch(
      journalDetails({
        journalId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      details = {
        ...response,
        file_ids: response.journal_files.map((file: any) => file.id),
        email_to: response.email_to.map((email: any) => email.email),
        item_details: response.item_details.map(
          (item: JVItemValues, index: number) => {
            return {
              ...item,
              index: index,
            };
          }
        ),
      };
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
    setJVValues(details);
    return editId ? details : jvInitialValues;
  };
  return { fetchJVDetails };
};
