import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { currentUserSelector } from "../commonSlice";
import "../styles/SearchSuggestionBox.css";

type Props = {
  // searchValue: string;
  searchType: string;
  totalResults: number;
  isSearch: boolean;
  resetInput: () => void;
  placeholder: string;
  searchSuggestions: (value: string, e: any) => Promise<any>;
  onSelect: (value: string) => void;
  // setSearchString: (value: string) => void;
  setTotalResults: (value: number) => void;
};
const SearchSuggestionBox = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    //load search event initially
    // props.setSearchString("");
    setSearchValue("");
    props.setTotalResults(0);
    autocomplete(document.getElementById("search_table"), []);
  }, [currentUserInfo.organization_id]);

  function autocomplete(inp: any, arr: any) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus: any;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", async function (this: any, e: any) {
      const searchedKey = this.value.trim() === "" ? "" : this.value;
      if (e.keyCode === 13) {
        e.preventDefault();
        props.onSelect(searchedKey);
      } else {
        setSearchValue(searchedKey);
        arr = await props.searchSuggestions(searchedKey, e);
        var a,
          b,
          i,
          val = searchedKey.trim(),
          matchCounter = 0;
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        if (!val) {
          return false;
        }
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
          /*check if the item starts with the same letters as the text field value:*/
          if (arr[i].toUpperCase().includes(val.toUpperCase())) {
            let index = arr[i].toUpperCase().indexOf(val.toUpperCase());
            /*create a DIV element for each matching element:*/
            b = document.createElement("DIV");
            b.innerHTML = arr[i].substr(0, index);
            /*make the matching letters bold:*/
            b.innerHTML +=
              "<span>" + arr[i].substr(index, val.length) + "</span>";
            b.innerHTML += arr[i].substr(index + val.length);
            /*insert a input field that will hold the current array item's value:*/
            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
            /*execute a function when someone clicks on the item value (DIV element):*/
            b.addEventListener("click", function (e) {
              /*insert the value for the autocomplete text field:*/
              inp.value = this.getElementsByTagName("input")[0].value;
              const end = inp.value.length;
              inp.setSelectionRange(end, end);
              inp.focus();
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
              props.onSelect(inp.value);
              setSearchValue(inp.value);
            });
            a.appendChild(b);
            matchCounter++;
          }
        }
        if (matchCounter === 0) {
          if (searchedKey.length > 2) {
            b = document.createElement("DIV");
            b.setAttribute("class", "no-result");
            b.innerHTML = `No ${props.searchType} available`;
            a.appendChild(b);
          } else {
            $(a).remove();
          }
        }
        document.body.style.overflow = "hidden";
      }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (this: any, e: any) {
      var x: HTMLElement | any = document.getElementById(
        this.id + "autocomplete-list"
      );
      if (x) {
        x = x.getElementsByTagName("div");
      }
      if (Number(e.keyCode) === 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (Number(e.keyCode) === 38) {
        //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (Number(e.keyCode) === 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
        props.onSelect(this.value);
        setSearchValue(this.value);
        closeAllLists();
      }
    });
    function addActive(x: any) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x: any) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(element?: any) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (element != x[i] && element != inp) {
          x[i].parentNode?.removeChild(x[i]);
        }
      }
      document.body.style.overflow = "auto";
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }

  const resetSearch = () => {
    setSearchValue("");
    document.getElementById("search_table")?.focus();
    props.resetInput();
  };
  return (
    <form
      className="search_table_form"
      name="search_table_form"
      autoComplete="off"
    >
      <div className="autocomplete">
        <input
          type="text"
          name="searchTable"
          id="search_table"
          placeholder={props.placeholder}
          value={searchValue}
          onChange={() => {}}
          style={{
            padding:
              props.totalResults > 0
                ? "0px 145px 0px 44px"
                : "0px 40px 0px 44px",
          }}
        />
        {props.totalResults > 0 && searchValue.length > 2 && !props.isSearch ? (
          <span className="result-count">
            {props.totalResults}{" "}
            {props.totalResults === 1 ? "Result" : "Results"} Found
          </span>
        ) : null}
        {searchValue.length > 0 ? (
          <input
            type="button"
            className="clear-search"
            value=""
            onClick={resetSearch}
          />
        ) : (
          ""
        )}
      </div>
    </form>
  );
};

export default SearchSuggestionBox;
