import React from "react";
import { SaveButton } from "./SaveButton";
import { SecondaryButton } from "./SecondaryButton";
import { SolidDeleteButton } from "./SolidDeleteButton";

type DockedButtonProps = {
  onSave: (e: React.MouseEvent) => void;
  onCancel: () => void;
  onDelete: (e: React.MouseEvent) => void;
  isSaveDisabled: boolean;
  isDeleteButtonDisabled: boolean;
  buttonText?: string;
  saveButtonType: "submit" | "button" | "reset";
  isDeleteButtonEnabled: boolean;
  isEdit: boolean;
  isModal?: boolean;
  shouldDockToBottom?: boolean;
};
export const DockedButtonGroup = (props: DockedButtonProps) => {
  return (
    <div className="row mb-0">
      <div className="col">
        <div
          className={`form-button-wrapper w-100 ${
            props.isModal ? "" : "docked-button-container docked-bottom"
          } ${props.shouldDockToBottom ? "modal-btn-docked-bottom" : ""}
          `}
          id="form-btn"
        >
          <SaveButton
            type={props.saveButtonType}
            onClick={(e) => {
              props.onSave(e);
            }}
            buttonContent={
              props.buttonText
                ? props.buttonText
                : props.isEdit
                ? "Update"
                : "Save"
            }
            buttonClassName="save-button"
            isDisabled={props.isSaveDisabled}
          />
          <SecondaryButton
            type="button"
            onClick={props.onCancel}
            buttonContent="Cancel"
            isDisabled={false}
          />
          {props.isDeleteButtonEnabled && (
            <SolidDeleteButton
              onClick={props.onDelete}
              isDisabled={props.isDeleteButtonDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};
