import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginLoaderIcon from "../../../../../assets/images/login-loader-svg.png";
import {
  getRemainingAccountSetupTime,
  setOrganizationSetupStatus,
} from "../../../../home/organizationHierarchy/OrganizationSlice";
import { isOrganizationSetup } from "../../../../home/organizationProfile/OrganizationProfileSlice";
import useCommonData from "../../../../hooks/useCommon";
import { Container } from "../../htmlTags/Container";
import "./LoginLoaderBody.css";
let intervals: any = null;

export const LoginLoaderBody = () => {
  const totalSteps = 3;
  const totalTime = 15;
  let timeout: number = (totalTime / totalSteps) * 100;
  const stepDuration = totalTime / totalSteps;
  const { currentUserInfo, dispatch } = useCommonData();
  const navigate = useNavigate();
  const [progress, setProgress] = useState<number[]>(Array(totalSteps).fill(0));
  const [estTime, setEstimatedTime] = useState(totalTime);
  const currentTime = useRef<number>(totalTime);
  const [stepMessages, setStepMessages] = useState([
    {
      message: "Setting up account permission",
      isComplete: false,
      isInProgress: true,
    },
    {
      message: "Creating your chart of accounts",
      isComplete: false,
      isInProgress: false,
    },
    {
      message: "Settings up your Organization Profile",
      isComplete: false,
      isInProgress: false,
    },
  ]);

  useEffect(() => {
    getRemainingTime();
    let currentEstTime: number = currentTime.current;
    intervals = setInterval(() => {
      currentEstTime = currentEstTime - 1 > 0 ? currentEstTime - 1 : 0;
      setEstimatedTime((prevValue) => {
        return prevValue - 1 > 0 ? prevValue - 1 : 0;
      });
      if (
        currentEstTime > totalTime - stepDuration &&
        currentEstTime < totalTime
      ) {
        let percent = ((totalTime - currentEstTime) * 100) / stepDuration;

        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[0] = percent;
          return newProgress;
        });
      }
      if (
        currentEstTime <= totalTime - stepDuration &&
        currentEstTime > stepDuration
      ) {
        let percent =
          ((totalTime - stepDuration - currentEstTime) * 100) / stepDuration;
        setProgress([100, percent, 0]);
        let prevSteps = [...stepMessages];
        prevSteps[0] = {
          ...prevSteps[0],
          isComplete: true,
          isInProgress: false,
        };
        prevSteps[1] = { ...prevSteps[1], isInProgress: true };

        setStepMessages(prevSteps);
      }
      if (currentEstTime <= stepDuration && currentEstTime > 0) {
        let percent = ((stepDuration - currentEstTime) * 100) / stepDuration;
        setProgress([100, 100, percent]);
        let prevSteps = [...stepMessages];
        prevSteps[1] = {
          ...prevSteps[1],
          isComplete: true,
          isInProgress: false,
        };
        prevSteps[0] = {
          ...prevSteps[0],
          isComplete: true,
          isInProgress: false,
        };
        prevSteps[2] = { ...prevSteps[2], isInProgress: true };
        setStepMessages(prevSteps);
      }
      if (currentEstTime === 0) {
        let prevSteps = [...stepMessages];
        prevSteps[2] = {
          ...prevSteps[2],
          isComplete: true,
          isInProgress: false,
        };
        prevSteps[1] = {
          ...prevSteps[1],
          isComplete: true,
          isInProgress: false,
        };
        prevSteps[0] = {
          ...prevSteps[0],
          isComplete: true,
          isInProgress: false,
        };
        setStepMessages(prevSteps);
        clearInterval(intervals);
        getOrgCreationStatus();
      }
    }, 1000);
    return () => {
      clearInterval(intervals);
    };
  }, [currentTime.current]);

  const getOrgCreationStatus = async () => {
    const responseAction = await dispatch(
      isOrganizationSetup({ orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload === true) {
      await dispatch(
        setOrganizationSetupStatus({
          orgId: currentUserInfo.organization_id,
          status: true,
        })
      ).then(() => {
        navigate("/");
        window.location.reload();
      });
    } else if (responseAction.payload === false) {
      setTimeout(() => {
        getOrgCreationStatus();
      }, timeout);
      timeout = timeout + 5;
    }
  };
  const getRemainingTime = async () => {
    let remainingTime: number = totalTime;
    const responseAction = await dispatch(
      getRemainingAccountSetupTime(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      remainingTime = response.remaining_time;
      currentTime.current = response.remaining_time;
      setEstimatedTime(response.remaining_time);
    }
    return remainingTime > totalTime ? totalTime : remainingTime;
  };
  return (
    <Container className="login-loader-wrapper">
      {estTime !== 0 ? (
        <>
          <Container className="login-loader-icon-wrapper">
            <img src={LoginLoaderIcon} width={"250px"} height={"180px"}></img>
          </Container>
          <Container className="login-loader-info-mesasage">
            Please wait while we are setting up your Finbooks account.
          </Container>
          <Container className="w-100 login-loader-progress-time-wrapper">
            <Container className="d-flex w-100 login-loader-linear-progress-wrapper">
              {progress.map((value, index) => (
                <Container key={index} className="login-loader-linear-progress">
                  <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                      height: "10px",
                      borderRadius: "4px",
                      backgroundColor: "#f1f1f1",
                    }}
                  />
                </Container>
              ))}
            </Container>
            <Container className="login-loader-est-time">
              Est time remaining : {estTime}sec
            </Container>
          </Container>
          <Container className="login-loader-circular-progress-steps-wrap">
            {stepMessages.map((step, index) => {
              return (
                <Container className="login-loader-circular-progress-icon-message-wrapper">
                  <Container className="login-loader-circular-progress-icon-wrap">
                    <Container
                      className={`login-loader-circular-progress-container ${
                        step.isInProgress ? "progress" : ""
                      } ${step.isComplete ? "complete" : ""} ${
                        !step.isComplete && !step.isInProgress
                          ? "incomplete"
                          : ""
                      }`}
                    >
                      {step.isComplete && step.isInProgress === false ? (
                        <CheckIcon sx={{ color: "#20bf6b" }} />
                      ) : (
                        <></>
                      )}
                    </Container>
                    {!step.isComplete && step.isInProgress === true && (
                      <CircularProgress
                        size={"32px"}
                        sx={{
                          color: "#20bf6b",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Container>
                  <Container
                    className={`login-loader-circular-progress-message-wrap ${
                      step.isInProgress === true ? "in-progress" : ""
                    }`}
                  >
                    {step.message}
                  </Container>
                </Container>
              );
            })}
          </Container>
        </>
      ) : (
        <>
          <Container className="login-loader-icon-wrapper">
            <CircularProgress
              size={"100px"}
              sx={{
                color: "#20bf6b",
                marginTop: "40px",
              }}
            />
          </Container>
          <Container className="login-loader-info-mesasage">
            Hang on! Lets get everything in order.
          </Container>
        </>
      )}
    </Container>
  );
};
