import React from "react";
import Select from "react-select";
import { customSelectStyle } from "../SelectCustomStyle";

type Props = {
  paymentMode: string;
  handleChange: (e: any) => void;
  error: string | undefined;
  isDisabled?:boolean
};
function PaymentModeSelect(props: Props) {
  const paymentModes = [
    { value: 1, label: "Bank Remittance" },
    { value: 2, label: "Bank Transfer" },
    { value: 3, label: "Cash" },
    { value: 4, label: "Check" },
    { value: 5, label: "Credit Card" },
  ];

  const paymentMode = paymentModes
    .filter((mode) => mode.label === props.paymentMode)
    .map((mode) => ({
      label: mode.label,
      value: mode.value,
    }));
  return (
    <Select
      isDisabled={props.isDisabled ? true: false}
      name="payment_mode"
      id="payment_mode"
      value={paymentModes.at(0)?.label === "" ? null : paymentMode}
      options={paymentModes}
      placeholder="Choose payment mode"
      onChange={props.handleChange}
      className={` form-select custom-select  ${props.error ? "error" : ""}`}
      styles={customSelectStyle}
    />
  );
}

export default PaymentModeSelect;
