import React from "react";
import { useParams } from "react-router-dom";
import VendorForm from "./vendorForm/VendorForm";
import TransactionHeader from "../../../common/components/TransactionHeader";

function VendorContainer() {
  const { id } = useParams();
  const vendorId = id ? Number(id) : 0;
  return (
    <>
      <div className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100">
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Vendor"
            transactionNumber={Number(vendorId)}
          />
        </div>
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body">
            <VendorForm id={vendorId} redirectTo="/vendors" />
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorContainer;
