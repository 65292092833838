import React, { useState } from "react";

import DateFilter from "../../DateFilter/DateFilter";
import { ObjectType } from "../../../../../../types";
import moment from "moment";

import "./DateRangeFilterContainer.css";
import { getFormatedDate } from "../../../../../../helpers/helper";

type Props = {
  module: string | undefined;
  category: string;
  setDateSelected: (date: Date | null, type: string) => void;
  clearDateFields: (
    category: string,
    clearStartDate: boolean,
    clearEndDate: boolean
  ) => void;
  options: any;
  handleDateErrorFlag?: (isError: boolean) => void;
  handleStartDateErrorFlag: (isError: boolean) => void;
  handleEndDateErrorFlag: (isError: boolean) => void;
};

const DateRangeFilterContainer = (props: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [prevStartDate, setPrevStartDate] = useState<Date | null>(null);
  const [prevEndDate, setPrevEndDate] = useState<Date | null>(null);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const validateDate = (date: Date) => {
    if (date) {
      const dateFormated = getFormatedDate(date);
      if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleStartDate = (date: Date | null, type: string) => {
    handleDateChange(date, "startDate");
  };

  const handleEndDate = (date: Date | null, type: string) => {
    handleDateChange(date, "endDate");
  };

  const handleDateChange = (date: Date | null, dateType: string) => {
    let setError,
      setPrevDate,
      setDate,
      oppositeDate,
      setOppositeDate,
      errorFlag,
      clearCategoryField;

    if (dateType === "startDate") {
      setError = setStartDateError;
      setPrevDate = setPrevStartDate;
      setDate = setStartDate;
      oppositeDate = endDate;
      setOppositeDate = setEndDate;
      errorFlag = props.handleStartDateErrorFlag;
      clearCategoryField = (clearStartDate: boolean, clearEndDate: boolean) => {
        props.clearDateFields(props.category, clearStartDate, clearEndDate);
      };
    } else {
      setError = setEndDateError;
      setPrevDate = setPrevEndDate;
      setDate = setEndDate;
      oppositeDate = startDate;
      setOppositeDate = setStartDate;
      errorFlag = props.handleEndDateErrorFlag;
      clearCategoryField = (clearStartDate: boolean, clearEndDate: boolean) => {
        props.clearDateFields(props.category, clearStartDate, clearEndDate);
      };
    }

    setError("");
    errorFlag(false);

    if (date) {
      if (validateDate(date)) {
        setPrevDate(dateType === "startDate" ? startDate : endDate);
        setDate(date);

        if (dateType === "endDate" && startDate && date < startDate) {
          setStartDateError("");
          setError("End date cannot be before start date");
          errorFlag(true);
        } else if (dateType === "startDate" && endDate && date > endDate) {
          setError("Start date cannot be after end date");
          setEndDateError("");
          errorFlag(true);
        } else {
          // if (
          //   !oppositeDate ||
          //   (dateType === "startDate"
          //     ? date > oppositeDate
          //     : date < oppositeDate)
          // ) {
          //   setOppositeDate(date);
          // }
          if (
            (dateType === "startDate" && oppositeDate && date > oppositeDate) ||
            (dateType === "endDate" && oppositeDate && date < oppositeDate)
          ) {
            setOppositeDate(date);
          }
          if (
            (dateType === "startDate" ? prevStartDate : prevEndDate) !== date
          ) {
            props.setDateSelected(date, dateType);
          }
        }
      } else {
        setError("Invalid date format");
        errorFlag(true);
      }
    } else {
      // Clear the date field in the state
      setPrevDate(dateType === "startDate" ? startDate : endDate);
      setDate(null);
      setError("");
      errorFlag(false);

      if (dateType === "startDate") {
        clearCategoryField(true, false);
      } else {
        clearCategoryField(false, true);
      }
    }
  };

  return (
    <>
      <div className="date-picker-wrapper w-100">
        <DateFilter
          date={props.options?.startDate ? props.options?.startDate : ""}
          handleDate={handleStartDate}
          dateType="StartDate"
          type={""}
          error={startDateError}
          title="Start Date"
          dateId={`${props.module}${props.category}StartDate`}
          zIndex={1330}
          format="dd/MM/yyyy"
          placeholder="dd/mm/yyyy"
          minDate={undefined}
          maxDate={endDate}
          wrapperClass="filterDatePicker"
        />
      </div>
      <div className="date-picker-wrapper w-100">
        <DateFilter
          date={props.options?.endDate ? props.options?.endDate : ""}
          handleDate={handleEndDate}
          dateType="EndDate"
          type={""}
          error={endDateError}
          title="End Date"
          dateId={`${props.module}${props.category}EndDate`}
          zIndex={1330}
          format="dd/MM/yyyy"
          placeholder="dd/mm/yyyy"
          minDate={startDate}
          maxDate={undefined}
          wrapperClass="filterDatePicker"
        />
      </div>
    </>
  );
};

export default DateRangeFilterContainer;
