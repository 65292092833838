import Skeleton from "@mui/material/Skeleton";

type Props = {
  variant: "circular" | "rectangular" | "rounded" | "text";
  width: string;
  height: string;
  animation: "wave" | "pulse";
  color: string;
};
export const SkeletonLoader = (props: Props) => {
  return (
    <Skeleton
      variant={props.variant}
      width={props.width}
      height={props.height}
      animation={props.animation}
      sx={{ bgcolor: props.color }}
    />
  );
};
