import "./cancelDetailsModalContent.css";

function CancelDetailsModalContent(props: any) {
  return (
    <div className="cancel-details-modal-content">
      <div className="container alert-modal">
        <p>
          {`Are you sure you want to remove the scheduled ${props.type.toLowerCase()} ?`}
        </p>
        <div className="buttons">
        <button onClick={props.cancelModalSubmitHandler} className="btn btn-cancel proceed-btn save-button">Yes Proceed</button>
          <button onClick={props.onCloseModal} id="scheduler-cancel-btn" className="btn btn-cancel cancel-btn cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
}
export default CancelDetailsModalContent;