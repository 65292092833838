import FiscalYearClosingCard from "./fiscalYearClosingCard/FiscalYearClosingCard";
import FiscalYearClosingHeader from "./fiscalYearClosingHeader/FiscalYearClosingHeader";

const FiscalYearClosing = () => {
  return (
    <>
      <FiscalYearClosingHeader>
        <FiscalYearClosingCard />
      </FiscalYearClosingHeader>
    </>
  );
};
export default FiscalYearClosing;
