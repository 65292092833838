import React, { ReactNode } from "react";
import "../../../../index.css";
import "./AlertModalPopupContent.css";
import { SaveButton } from "../button/SaveButton";

type Props = {
  handleClose: () => void;
  message: string | ReactNode;
  isSubmitEnabled: boolean;
};
const AlertModalPopupContent = (props: Props) => {
  return (
    <div className="alert-modal-pop-up-wrap">
      <div>
        <p className="alert-modal-popup-message">{props.message}</p>
      </div>
      <div className="alert-modal-popup-btn-group">
        {props.isSubmitEnabled && (
          <SaveButton
            type="button"
            isDisabled={false}
            buttonContent="Ok"
            onClick={props.handleClose}
            buttonClassName="alert-modal-popup-save-ok-button"
          />
        )}
      </div>
    </div>
  );
};

export default AlertModalPopupContent;
