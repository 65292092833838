import { useEffect, useState } from "react";
import { scheduledClosingFiscalYear } from "../home/fiscalYearClosing/fiscalYearClosingAPI";
// import { useAppSelector } from "../../app/hooks";
// import { currentUserSelector } from "../common/commonSlice";
import useCommonData from "./useCommon";
const useClosingMaintenance = () => {
  const { currentUserInfo, dispatch, orgCurrencyList } = useCommonData();
  const [isClosingRunning, setIsClosingRunning] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const fetchScheduledClosing = async () => {
      try {
        if (currentUserInfo.organization_id > 0) {
          const scheduledClosing = await scheduledClosingFiscalYear({
            orgId: currentUserInfo.organization_id,
          });
          setIsClosingRunning(scheduledClosing?.is_closing_running || false);
          if (scheduledClosing?.is_closing_running) {
            timeout = setTimeout(() => {
              fetchScheduledClosing();
            }, 60000);
          }
        }
      } catch (error) {
        setError("Error fetching status.");
      }
    };

    fetchScheduledClosing();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [currentUserInfo.organization_id]);

  return { isClosingRunning };
};

export default useClosingMaintenance;
