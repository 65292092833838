import { useRef } from "react";
import { FormValues } from "../../../../../../adapters/formAdapter/type";
import useCommonData from "../../../../../hooks/useCommon";
import { getAmountCalculation, getTaxableAmount } from "../../../invoiceSlice";
import {
  amountCalculationPayloadRefiner,
  taxableAmountPayloadRefiner,
} from "../utils/payloadRefiner";

type Props = {
  values: FormValues;
  setValues: (values: any) => void;
};
export const useInvoiceAmountCalculation = ({ values, setValues }: Props) => {
  const { dispatch, orgCurrencyList, currentUserInfo } = useCommonData();
  const abortControllerRef = useRef<AbortController | null>(null);
  const calculateInvoiceTotalAmount = async (data: { values: any }) => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const responseAction = await dispatch(
      getAmountCalculation({
        values: amountCalculationPayloadRefiner(data.values),
        orgId: currentUserInfo.organization_id,
        signal: abortControllerRef.current.signal,
      })
    );
    const response = responseAction.payload;
    if (response) {
      setValues({
        total: response["total_details"]["total"] || 0,
        roundoff_amount: response["total_details"]["roundoff_amount"] || 0,
        tcs_total: response["total_details"]["tcs_total"] || 0,
        tds_total: response["total_details"]["tds_total"] || 0,
      });
      if (values.items.length > 0) {
        values.items.map(async (item: any, index: number) => {
          await calculateTotalTaxableAmount({
            values: item,
            index: index,
          });
        });
      }
    }
  };
  const calculateTotalTaxableAmount = async (data: {
    values: any;
    index: number;
  }) => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const taxResponseAction = await dispatch(
      getTaxableAmount({
        values: taxableAmountPayloadRefiner(data.values),
        orgId: currentUserInfo.organization_id,
        signal: abortControllerRef.current?.signal || {},
      })
    );
    const response = taxResponseAction.payload;
    if (
      response["item_details"] &&
      response["item_details"]["taxable_amount"]
    ) {
      let items = values.items;
      items[data.index] = {
        ...items[data.index],
        tds_total:
          (response["item_details"]["tds_total"] &&
            response["item_details"]["tds_total"]) ||
          0,
        taxable_amount: response["item_details"]["taxable_amount"] || 0,
      };

      setValues({ items: items });
    }
  };
  return {
    calculateInvoiceTotalAmount,
    calculateTotalTaxableAmount,
    abortControllerRef,
  };
};
