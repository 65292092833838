export const GreenPlusIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          fill="#9ac23c"
          fill-rule="nonzero"
          d="M8.25 8.25v-4.5h1.5v4.5h4.5v1.5h-4.5v4.5h-1.5v-4.5h-4.5v-1.5z"
        />
      </g>
    </svg>
  );
};
