import React from "react";

import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../../constants/colors";
import { SettingsIcon } from "../../../../common/components";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import "./systemVoucherConfigurationListModal.css";

import SystemVoucherConfigurationList from "./systemVoucherConfigurationList/systemVoucherConfigurationList";

const SystemVoucherConfigurationListModal = () => {
  return (
    <>
      <SideModal
        modalTitle={"Configure System Voucher"}
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return (
                <>
                  <SystemVoucherConfigurationList
                    handleModalClose={() => {
                      onCloseModal();
                    }}
                  />
                </>
              );
            }}
          </ModalContext.Consumer>
        }
        wrapperClass="system-voucher-list-modal"
        buttonContent={<>Configure System Voucher</>}
        buttonClass={
          "no-appearance save-btn w-auto configure-system-voucher-setting-btn"
        }
        //   wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
        //   onOpenFunction={props.onOpenFunction}
      />
    </>
  );
};

export default SystemVoucherConfigurationListModal;
