import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import StopResumeModal from "../../../common/components/StopResumeModal";
import { toast } from "react-toastify";
import { changePlanStatus } from "../planSlice";

type Props = {
  planId: number;
  planName: string;
  triggerAction: string;
  refreshPlan: () => void;
  organizationId: number;
};
const PlanStopResume = (props: Props) => {
  const dispatch = useAppDispatch();
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };
  
    /**
   * Handler to resume the plan
   */
    const resumeHandler = async (userId: number) => {
      $("#delete-button").addClass("customer-form-section-disable");
      const responseAction = await dispatch(
        changePlanStatus({
          id: userId,
          status: "ACTIVE",
          orgId: props.organizationId
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (responseAction) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          props.refreshPlan();
          $(".close-btn").click();
          toast.success("Resumed Plan successfully!", {
            toastId: "resume-plan-success",
            closeButton: false,
            position: "top-center",
          });
        }else if ("error" in response) {
          $(".close-btn").click();
        }
      } 

    };
  
    /**
     *  Handler to stop the plan
     */
    const stopHandler = async (userId: number) => {
        $("#delete-button").addClass("customer-form-section-disable");
        const responseAction = await dispatch(
          changePlanStatus({
            id: userId,
            status: "STOPPED",
            orgId: props.organizationId
          })
        );
        $("#delete-button").removeClass("customer-form-section-disable");
        if (responseAction) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            props.refreshPlan();
            $(".close-btn").click();
            toast.success("Stopped plan successfully!", {
              toastId: "stop-plan-success",
              closeButton: false,
              position: "top-center",
            });
          } else if ("error" in response) {
            $(".close-btn").click();
          }
        }
    };
  
  return (
    <StopResumeModal
    heading={`${props.triggerAction} Plan!`}
    subHeading={`Are you sure you want to ${props.triggerAction.toLowerCase()} the plan`}
    transactionName={joinTransactionNumber(props.planName)}
    modalCancelHandler={modalCancelHandler}
    modalSubmitHandler={() => {props.triggerAction === "Stop"
    ? stopHandler(props.planId)
    : resumeHandler(props.planId)}}
    triggerAction={props.triggerAction}
  />
  );
};
export default PlanStopResume;