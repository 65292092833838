const labels = {
  itemDetails: {
    title: "Item Details",
    itemSelect: "ADD ITEM/DESCRIPTION",
    itemSelectPlaceholder: "Choose an item or enter a description",
    hsnCode: "hsn/sac code",
    hsnCodePlaceholder: "Enter code",
    account: "ACCOUNT",
    quantity: "QUANTITY",
    rate: "RATE",
    discount: 'DISCOUNT'
  },
};
export default labels;
