import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  BillPaymentValues,
  PaymentMadeJournalListPayload,
  ReportingTagsAllocationPayload,
  TotalPaymentMadeValues,
} from "../../../types";

/**
 * API call for getting Bill Payment Lists
 */
export async function getBillPaymentLists(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  vendorList: number[];
  orgIds: number[];
  statusList: string[];
  billId?: number | null;
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.paymentMade +
    `pm/list?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId} ${
      data.billId ? `&bill_obj_id=${data.billId}` : ""
    }${data.startDate ? `&start_date=${data.startDate.trim()}` : ""}${
      data.endDate ? `&end_date=${data.endDate.trim()}` : ""
    }${data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""}${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.vendorList.length !== 0) {
    const vendorIds = data.vendorList
      .map((vendorId) => {
        return `&vendor_ids=${vendorId}`;
      })
      .join("");
    url += vendorIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);

  return result;
}

/**
 * API call for deleting Bill Payment
 */
export async function deleteBillPayment(data: {
  deleteBillPaymentId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.paymentMade +
      `pm/delete/${data.deleteBillPaymentId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for Creating Bill Payment
 */
export async function createNewBillPayment(data: {
  values: BillPaymentValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.paymentMade +
      `pm/create?pm_status=${data.status}&current_organization=${data.orgId}`, //is_modified
    data.values
  );
  return result;
}

/**
 * API call for Edit Bill Payment
 */
export async function editBillPayment(data: {
  billPaymentId: number;
  values: BillPaymentValues;
  status: string;
  isVendorChanged: boolean;
  orgId: number;
}) {
  const result = await put(
    Api.paymentMade +
      `pm/edit/${data.billPaymentId}?is_vendor_change=${data.isVendorChanged}&pm_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for getting payment made details
 */
export async function getPaymentMadeDetails(data: {
  paymentMadeId: number;
  orgId: number;
}) {
  const result = await get(
    Api.paymentMade +
      `pm/detail/${data.paymentMadeId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete Payment made recipients
 */
export async function removePaymentMadeRecipients(data: {
  paymentMadeId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.paymentMade +
      `pm-recipient/delete/${data.paymentMadeId}?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}

/**
 * API call for get Payment Made number
 */
export async function getPaymentMadeNumber(orgId: number) {
  const result = await get(
    Api.paymentMade + `new-pm-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for checking the Payment made number exist or not
 */
export async function paymentMadeNumberExist(data: {
  paymentMadeNumber: string;
  orgId: number;
  paymentMadeId: number;
  signal?: AbortSignal;
}) {
  const result = await get(
    Api.paymentMade +
      `pm-number-exist?pm_number=${data.paymentMadeNumber}&pm_id=${data.paymentMadeId}&current_organization=${data.orgId}`,
    data.signal
  );
  return result;
}

/**
 * API call for getting unpaid vendor bills list
 */
export async function getUnpaidVendorBillList(data: {
  vendorId: number;
  orgId: number;
}) {
  const result = await get(
    Api.bills +
      `unpaid-vendor-bills/list?vendor_id=${data.vendorId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to upload payment made attachments
 */
export async function paymentMadeAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.paymentMade + `pm-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * API to delete payment made attachments
 */
export async function deletePaymentMadeAttachments(data: {
  paymentMadeId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.paymentMade +
      `pm-attachments/${data.paymentMadeId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for sending payment made to contact details
 */
export async function sendPaymentMade(data: {
  paymentMadeId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.paymentMade +
      `send-pm?pr_id=${data.paymentMadeId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API to get payment made pdf link
 */
export async function paymentMadePdfLink(data: {
  paymentMadeId: number;
  orgId: number;
}) {
  const result = await get(
    Api.paymentMade +
      `download-link?pm_id=${data.paymentMadeId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to calculate Total Amount
 */
export async function totalAmountCalculation(data: {
  values: TotalPaymentMadeValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.paymentMade +
      `pm/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.paymentMade + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to delete allocation of PM to bill
 */
export async function deletePRtoBillAllocation(data: {
  orgId: number | null;
  pmId: number | null;
  itemId: number | null;
}) {
  const result = await remove(
    Api.paymentMade +
      `pm-item/delete/${data.pmId}/${data.itemId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to generate reporting tags for PM.
 */
export async function createJournalListPM(data: {
  pmId: number | null;
  orgId: number | null;
  values: PaymentMadeJournalListPayload;
}) {
  const result = await post(
    Api.paymentMade +
      `reporting-tag/journal-generate?current_organization=${data.orgId}&pm_id=${data.pmId}`,
    data.values
  );
  return result;
}

/**
 * Api to update reporting tags association for PM.
 */
export async function editReportingTagPM(data: {
  pmId: number | null;
  orgId: number | null;
  values: ReportingTagsAllocationPayload;
}) {
  const result = await put(
    Api.paymentMade +
      `reporting-tag/edit/${data.pmId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}


/**
 * Api to create new refund.
 */
export async function createNewRefund(data: {
  orgId: number | null;
  values: any;
}) {
  const result = await post(
    Api.paymentMade +
      `payment-refund/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api to refund details
 */
export async function fetchRefundDetails(data: {
  refundId: number;
  orgId: number;
}) {
  const result = await get(
    Api.paymentMade +
      `payment-refund/detail/${data.refundId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update refund.
 */
export async function editRefund(data: {
  refundId: number;
  orgId: number;
  values: any;
}) {
  const result = await put(
    Api.paymentMade +
      `payment-refund/edit/${data.refundId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api to delete refund.
 */
export async function deleteRefund(data: { refundId: number; orgId: number }) {
  const result = await remove(
    Api.paymentMade +
      `payment-refund/delete/${data.refundId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to get reporting tag details of sundry allocated bills.
 */
export async function sundryTagAllocatedBillsTagDetails(data: {
  billIds: number[];
  orgId: number;
}) {
  let url =
    Api.bills +
    `reporting-tag/sundry-journal?current_organization=${data.orgId}`;
  if (data.billIds.length !== 0) {
    const billIds = data.billIds
      .map((billId) => {
        return `&bill_ids=${billId}`;
      })
      .join("");
    url += billIds;
  }
  const result = await get(url);
  return result;
}