import { InfoIconImg } from "../../../../../assets/images";
import CustomTooltips from "../../Tooltip";

type Props = {
  wrapperClass?: string;
  label: string;
  labelhtmlFor?: string;
  labelClass?: string;
  required: boolean;
  error?: string;
  errorClass?: string;
  tooltip?: string;
  children: any;
};

const FieldContainer = (props: Props) => {
  return (
    <div className={`col-12 col-lg-4 w-100 mb-4 ${props.wrapperClass}`}>
      <label
        htmlFor={props.labelhtmlFor}
        className={`${props.required ? "required" : ""} ${props.labelClass}`}
      >
        {props.label}
        {props.tooltip ? (
          <CustomTooltips title={props.tooltip} placement="bottom">
            <img
              style={{ marginLeft: "5px" }}
              src={InfoIconImg}
              width="16"
              alt="info icon"
            />
          </CustomTooltips>
        ) : (
          <></>
        )}
      </label>
      {props.children}
      <span className={`error mb-2 ${props.errorClass}`}>{props.error}</span>
    </div>
  );
};

export default FieldContainer;
