import React from "react";

type ModalContextType = {
  onCloseModal: () => void;
  onSaveClose: () => void
};

export const ModalContext = React.createContext<ModalContextType>({
  onCloseModal: () => {},
  onSaveClose: () => {}
});

export const useModalContext = () => React.useContext(ModalContext);
