import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../app/store";

const initialState = {
  type: "",
};

const addressTypeSlice = createSlice({
  name: "addressType",
  initialState,
  reducers: {
    addBillingAddress: (state, action) => {
      state.type = action.payload;
    },
    editBillingAddress: (state, action) => {
      state.type = action.payload;
    },
    addShippingAddress: (state, action) => {
      state.type = action.payload;
    },
    editShippingAddress: (state, action) => {
      state.type = action.payload;
    },
  },
});
export const {
  addBillingAddress,
  editBillingAddress,
  addShippingAddress,
  editShippingAddress,
} = addressTypeSlice.actions;

export default addressTypeSlice.reducer;

export const addressTypeSelector = (state: RootState) => state.addressType;
