import "./preferencePopperContent.css";

const PreferencePopperContent = () => {
  return (
    <>
      <div className="preference-popper-content">
        <div className="header-wrapper">
          <h6 className="heading">Rounding Examples</h6>
          <p className="description">
            Given below, are the examples showcasing how the rounding of the
            amount is determined by its decimal value.
          </p>
        </div>
        <table>
          <thead>
            <tr>
              <th>BEFORE ROUNDING</th>
              <th>AFTER ROUNDING</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1223.49</td>
              <td>1223.00</td>
            </tr>
            <tr>
              <td>1223.50</td>
              <td>1224.00</td>
            </tr>
            <tr>
              <td>1223.76</td>
              <td>1224.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PreferencePopperContent;
