import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import {
  Box,
  Typography,
  FormControl,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { createModalCustomStyle } from "./CreateModalCustomStyle";
import ModalCloseButton from "../../../../common/components/ModalCloseButton";
import { colors } from "../../../../constants/colors";
import "./CreateItem.css";
import CustomSearchSelect from "../../../../common/components/CustomSearchSelect/CustomSearchSelect";
import { AddTagIcon } from "../../../../../assets/images";
import Itemtax from "../../../../common/components/ItemTax";
import {
  InventoryItemValues,
  ItemTaxValues,
  ObjectType,
  TagSelector,
} from "../../../../../types";
import { createItem, exemptionDropdown, exemptionDropdownSelector, exemptionLabel, removeItemTax, updateItem } from "../../itemSlice";
import { toast } from "react-toastify";
import InputPrefix from "../../../../common/components/formPrefixInputField/InputPrefix";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import { zeroDisplayFormat } from "../../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useAppSelector } from "../../../../../app/hooks";

const theme = createTheme({
  components: {
    // Name of the component
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          transform: "none",
          clear: "both",
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "normal",
          letterSpacing: "-0.18px",
          color: `${colors.charcoalGrey} !important`,
          marginBottom: "12px",
          float: "left",
          maxWidth: "fit-content",
        },
      },
    },
  },
});

type Props = {
  open: boolean;
  closeModal: () => void;
  itemData?: InventoryItemValues;
  fetchItemDropdown?: () => void;
  currency: { id?: number; code?: string };
};
function CreateItem({
  open,
  closeModal,
  itemData,
  fetchItemDropdown,
  currency,
}: Props) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { isGstOrg } = useGst();
  const initialTaxValues = {
    tax_id: "",
    tax_percentage: "",
  };
  const initialValues: InventoryItemValues = {
    id: null,
    name: "",
    hsn_or_sac_code: "",
    account_id: "",
    account_name: "",
    isGstOrg: isGstOrg,
    rate: "",
    currency_id: null,
    tax_list: [
      {
        tax_id: "",
        tax_percentage: "",
      },
    ],
    tax_preference: undefined,
    exemption_reason_id: undefined,
    item_information: []
  };
  const [taxFormValues, setTaxFormValues] = useState<ItemTaxValues[]>([
    initialTaxValues,
  ]);
  const [itemFormErrors, setItemFormErrors] = useState<ObjectType[]>([]);
  const [deleteTaxItems, setDeleteTaxItems] = useState<number[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [actionType, setActionType] = useState("create");
  const [taxPreference, setTaxpPreference] = useState<TagSelector>();
  const [exemptionReason, setExemptionReason] = useState<TagSelector>();
  const exemptionList = useAppSelector(exemptionDropdownSelector);
  const taxPreferenceList = [
    {label: 'Taxable', value: 'TAXABLE'},
    {label: 'Non taxable', value: "NON_TAXABLE"},
    {label: 'Out of scope', value: "OUT_OF_SCOPE"},
    {label: 'Non-gst supply', value: "NON_GST_SUPPLY"}
  ]
  useEffect(() => {
    dispatch(exemptionDropdown({orgId: currentUserInfo.organization_id}));
  },[])
  useEffect(() => {
    if (itemData && Object.keys(itemData).length) {
      setActionType("edit");
      if (itemData.taxes) {
        itemData.tax_list = itemData.taxes;
        if (Object.keys(itemData.tax_list).length === 0) {
          itemData.tax_list.push(initialTaxValues);
        }
        delete itemData.taxes;
      }
      formik.setValues(itemData);
      if(itemData.tax_preference){
        const exemptionTypeLabel = taxPreferenceList.find((obj:any) => obj.value === itemData.tax_preference)
        setTaxpPreference({
          label: exemptionTypeLabel ? exemptionTypeLabel.label : "",
          value: itemData.tax_preference
        })
      }
      if(itemData.exemption_reason_id){
        getExemptionReasonDetails(itemData.exemption_reason_id);
      }
      if (itemData.tax_list) setTaxFormValues([...itemData.tax_list]);
    }
  }, [itemData]);
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      const errors = validateForm(values);
      if (Object.keys(errors).length === 0) {
        submitItem(values);
      } else {
        formik.setErrors(errors);
      }
    },
  });
  const validateForm = (values: any) => {
    const errors: ObjectType = {};

    if (!values.name) {
      errors.name = "Item name is required";
    }
    let hsnRegex = /^[1-9]\d{5}$|^[1-9]\d{7}$/;
    if (
      values.hsn_or_sac_code &&
      !hsnRegex.test(values.hsn_or_sac_code.toString()) &&
      values.hsn_or_sac_code !== ""
    ) {
      errors.hsn_or_sac_code = "Please enter valid hsn/sac code";
    }
    if (!values.account_id) {
      errors.account_id = "Please enter account";
    }
    if (!values.rate) {
      errors.rate = "Please enter rate";
    }
    if(isGstOrg) {
      if (!values.tax_preference) {
        errors.tax_preference = "Please enter tax preference";
      }
      if(values.tax_preference === "NON_TAXABLE") {
        if(!values.exemption_reason_id) {
          errors.exemption_reason_id = "Please enter tax exemption reason";
        }
      }
    }
    if (taxFormValues) {
      let taxes = taxFormValues;
      let taxLabels: any[] = [];
      let taxError: any[] = [];
      let flag = false;
      for (let tax of taxes) {
        if (taxLabels.includes(tax.tax_id)) {
          flag = true;
          taxError.push({
            tax_id: "Tax label already used",
            tax_percentage: 0,
          });
        } else if (tax.tax_id === "" && Number(tax.tax_percentage) > 0) {
          flag = true;
          taxError.push({
            tax_id: "Please choose tax code",
            tax_percentage: 0,
          });
        } else {
          taxError.push({ tax_id: "", tax_percentage: 0 });
          if (tax.tax_id) {
            taxLabels.push(tax.tax_id);
          }
        }
      }
      if (flag) {
        errors.taxes = taxError;
      }
      setItemFormErrors(taxError);
    }
    return errors;
  };
  const submitItem = async (itemData: InventoryItemValues) => {
    setIsDisabled(true);
    let itemTax = JSON.parse(JSON.stringify(taxFormValues));
    let taxData: ItemTaxValues[] = [];
    itemTax.map((item: any) => {
      if (item.tax_percentage === "") {
        item.tax_percentage = 0;
      }
      if (item.tax_id !== "" || item.tax_percentage !== 0) {
        taxData.push(item);
      }
    });
    itemData.tax_list = taxData;
    itemData.currency_id = currentUserInfo.organization_currency;
    itemData.hsn_or_sac_code =
      itemData.hsn_or_sac_code !== "" ? itemData.hsn_or_sac_code : null;
    let responseAction: ObjectType = {};
    if (actionType === "edit") {
      if (deleteTaxItems) {
        for (let item of deleteTaxItems) {
          await dispatch(
            removeItemTax({
              itemId: Number(itemData.id),
              taxId: item,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
      responseAction = await dispatch(
        updateItem({
          id: Number(itemData.id),
          values: itemData,
          orgId: currentUserInfo.organization_id,
        })
      );
    } else {
      responseAction = await dispatch(
        createItem({
          values: itemData,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    if (responseAction.payload) {
      setTimeout(function () {
        // setIsLoading(false);
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        if (fetchItemDropdown) fetchItemDropdown();
        handleClose();
        toast.success(
          `Item ${actionType === "create" ? "created" : "updated"
          } successfully!`,
          {
            toastId: "item-success",
            closeButton: false,
            position: "top-center",
          }
        );
        setIsDisabled(false);
      } else {
        setIsDisabled(false);
      }
    }
  };

  const handleItemAccountChange = (accountId: number, index: number) => {
    if (accountId) {
      formik.values.account_id = accountId;
    }
  };
  /**
   * Append item tax container
   */
  const addItemTaxContainer = () => {
    const taxObject = initialTaxValues;
    const taxes = JSON.parse(JSON.stringify(taxFormValues));
    taxes.push(taxObject);
    setTaxFormValues(taxes);
    formik.values.tax_list.push(taxObject);
  };
  const handleTaxOnBlur = (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number
  ) => {
    const taxes = JSON.parse(JSON.stringify(taxFormValues));
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      if (name === "tax_percentage" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        taxes[itemIndex] = { ...taxes[itemIndex], [name]: roundedAmount };
      }
    }
    setTaxFormValues(taxes);
  };

  const handleItemTaxChange = (e: any, taxIndex: number) => {
    const { name, value } = e.target;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "tax_percentage" && value !== "") {
      let decimalPointSplitArray: string[] = [];
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (!numberRegex.test(value) || decimalPointSplitArray[0].length > 14) {
        return;
      }
    }
    const taxes = JSON.parse(JSON.stringify(taxFormValues));
    let taxExist = [];
    if (value) {
      taxExist = taxes.filter(
        (tax: any, index: number) => tax.tax_id === value && index !== taxIndex
      );
      if (taxExist.length) {
        if (name === "tax_id") {
          if (typeof itemFormErrors[taxIndex] === "undefined") {
            itemFormErrors[taxIndex] = [];
          }
          itemFormErrors[taxIndex] = {
            tax_id: "",
            tax_percentage: 0,
          };
          itemFormErrors[taxIndex].tax_id = "Tax label already used";
          setItemFormErrors([...itemFormErrors]);
        }
      } else {
        if (typeof itemFormErrors[taxIndex] !== "undefined") {
          if (name === "tax_id") {
            if (!itemFormErrors[taxIndex]) {
              itemFormErrors[taxIndex] = [];
            }
            itemFormErrors[taxIndex] = {
              tax_id: "",
              tax_percentage: 0,
            };
            setItemFormErrors([...itemFormErrors]);
          }
        }
      }
    }
    taxes[taxIndex] = { ...taxes[taxIndex], [name]: value };
    setTaxFormValues(taxes);
  };
  /**
   * Append item tax container
   */
  const deleteItemTaxContainer = (taxIndex: number, id: number | undefined) => {
    taxFormValues.map((tax, index) => {
      if (id && tax.id === id) {
        setDeleteTaxItems((prevItems) => [...prevItems, Number(tax.id)]);
      }
    });
    const taxItems = JSON.parse(JSON.stringify(taxFormValues));
    taxItems.splice(taxIndex, 1);
    setTaxFormValues(taxItems);
    const taxErrors = [...itemFormErrors];
    taxErrors.splice(taxIndex, 1);
    setItemFormErrors(taxErrors);
  };

  const handleAmountOnBlur = (name: string, amount: number, index: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      if (name === "rate" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        formik.setFieldValue(name, roundedAmount);
      }
    }
  };

  const handleClose = () => {
    formik.setValues(initialValues);
    setTaxFormValues([initialTaxValues]);
    setItemFormErrors([]);
    formik.setErrors({});
    setActionType("create");
    setDeleteTaxItems([]);
    setTaxpPreference({label: '', value: ''})
    setExemptionReason({label: '', value: ''})
    closeModal();
  };

  const validateAmount = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    const inputElement = event.target as any;
    if (inputElement.value === "" || numberRegex.test(inputElement.value))
      return true;
    else return false;
  };

  const getExemptionReasonDetails = async (id: number) => {
    
    const result = await dispatch(exemptionLabel({exemption_id: id ,orgId: currentUserInfo.organization_id}))
    setExemptionReason({
      label: result.payload[0].exemption_reason,
      value: result.payload[0].id
    })
  }
  const handleTaxPreferenceChange = (e: any) => {
    if(e.value) {
      formik.setValues({
        ...formik.values,
        tax_list: [],
        tax_preference: e.value,
        exemption_reason_id: null
      })
      setTaxFormValues([initialTaxValues])
      setExemptionReason({
        label: '',
        value: ''
      })
      setTaxpPreference({
        label: e.label,
        value: e.value
      })
    }
  }
  const handleExpemtionReasonChange = (e: any) => {
    if(e.label) {
      formik.setValues({
        ...formik.values,
        exemption_reason_id: parseInt(e.value),
      })
      setExemptionReason({
        label: e.label,
        value: e.value
      })
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={createModalCustomStyle.sidebarModal}
    >
      <Box sx={createModalCustomStyle.modalDialogFullScreen}>
        <Box sx={createModalCustomStyle.modalContent}>
          <ModalCloseButton onClick={handleClose} />
          <Box sx={createModalCustomStyle.itemModalBody}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h4"
              sx={createModalCustomStyle.itemModalTitle}
            >
              {itemData && Object.keys(itemData).length
                ? "Edit Item"
                : "Add Item"}
            </Typography>
            <ThemeProvider theme={theme}>
              <form
                onSubmit={formik.handleSubmit}
                id="addItem-form"
                className="addItem-form"
              >
                <FormControl sx={createModalCustomStyle.addItemForm}>
                  <label htmlFor="item_name" className="required">
                    Item Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className={
                      formik.errors.name ? "form-control error" : "form-control"
                    }
                    placeholder="Enter item name"
                  />
                  <span className="error">{formik.errors.name}</span>
                </FormControl>
                {isGstOrg && (
                  <FormControl sx={createModalCustomStyle.addItemForm}>
                    <label htmlFor="hsn_or_sac_code">HSN/SAC Code</label>
                    <input
                      id="hsn_or_sac_code"
                      name="hsn_or_sac_code"
                      type="text"
                      onChange={formik.handleChange}
                      value={
                        formik.values.hsn_or_sac_code
                          ? formik.values.hsn_or_sac_code
                          : ""
                      }
                      className={
                        formik.errors.hsn_or_sac_code
                          ? "form-control error"
                          : "form-control"
                      }
                      placeholder="Enter HSN/SAC code"
                      maxLength={15}
                    />
                    <span className="error">
                      {formik.errors.hsn_or_sac_code}
                    </span>
                  </FormControl>
                )}
                {isGstOrg && 
                  <FormControl sx={createModalCustomStyle.addItemForm}>
                    <label htmlFor="taxPreference" className="required">
                      Tax Preference
                    </label>
                    <Select
                      name="tax_preference"
                      id="tax_preference"
                      options={taxPreferenceList.length > 0
                        ? taxPreferenceList.map((item) => {
                          return {
                            label: item.label,
                            value: item.value
                          }
                        })
                        : []
                      }
                      className={`state-select form-select custom-select ${formik.errors.tax_preference ? "error" : ""}`}
                      value={taxPreference
                                &&
                                  taxPreference.label !== '' &&
                                    taxPreference.value !== ''
                                    ?
                                      {
                                        label: taxPreference.label,
                                        value: taxPreference.value
                                      }
                                    : ""
                      }
                      onChange={(e) => {
                        handleTaxPreferenceChange(e);
                      }}
                      styles={customSelectStyle}
                      placeholder={"Choose tax preference"}
                      isSearchable={false}
                    />
                    <span className="error">{formik.errors.tax_preference}</span>
                    </FormControl>
                } 
                {formik.values.tax_preference === 'NON_TAXABLE' && isGstOrg &&
                  <FormControl sx={createModalCustomStyle.addItemForm}>
                    <label htmlFor="taxPreference" className="required">
                      Exemption reason
                    </label>
                    <Select
                      name="exemption_reason"
                      id="exemption_reason"
                      options={exemptionList.length > 0
                        ? exemptionList.map((item) => {
                          return {
                            label: item.exemption_reason,
                            value: item.id.toString()
                          }
                        })
                        : []
                      }
                      className={`state-select form-select custom-select ${formik.errors.exemption_reason_id ? "error" : ""}`}
                      value={exemptionReason
                                &&
                                  exemptionReason.label !== '' &&
                                    exemptionReason.value !== ''
                                    ?
                                      {
                                        label: exemptionReason.label,
                                        value: exemptionReason.value
                                      }
                                    : ""
                      }
                      onChange={(e) => {
                        handleExpemtionReasonChange(e);
                      }}
                      styles={customSelectStyle}
                      placeholder={"Choose tax exemption reason"}
                      isSearchable={false}
                    />
                    <span className="error">{formik.errors.exemption_reason_id}</span>
                  </FormControl>
                }
                <FormControl sx={createModalCustomStyle.addItemForm}>
                  <label htmlFor="account" className="required">
                    Account
                  </label>
                  <CustomSearchSelect
                    itemAccount={Number(formik.values.account_id)}
                    itemAccountName={formik.values.account_name}
                    organizationId={currentUserInfo.organization_id}
                    handleItemAccountChange={handleItemAccountChange}
                    itemIndex={0}
                    error={formik.errors.account_id ? "error" : ""}
                    baseAccount={"Incomes"}
                  />
                  <span className="error">{formik.errors.account_id}</span>
                </FormControl>
                <FormControl sx={createModalCustomStyle.addItemForm}>
                  <label htmlFor="rate" className="required">
                    Rate
                  </label>
                  <InputPrefix
                    prefix={currentUserInfo.currency_code}
                    inputType="text"
                    name="rate"
                    id="rate"
                    className="form-control border-end-0"
                    placeholder={zeroDisplayFormat(
                      decimalPlaceOfCurrency(
                        currentUserInfo.currency_code,
                        orgCurrencyList
                      )
                    )}
                    value={formik.values.rate ? formik.values.rate : ""}
                    disabled={false}
                    error={formik.errors.rate ? "error" : ""}
                    onChange={(e) => {
                      if (validateAmount(e)) formik.handleChange(e);
                    }}
                    onBlur={handleAmountOnBlur}
                  />
                  <span className="error">{formik.errors.rate}</span>
                </FormControl>
                {!isGstOrg && (
                  <FormControl
                    sx={createModalCustomStyle.addItemForm}
                    className="item-tax-section"
                  >
                    <label htmlFor="tax">Tax %</label>
                    <Itemtax
                      itemIndex={0}
                      taxes={taxFormValues}
                      taxErrors={itemFormErrors}
                      organizationId={currentUserInfo.organization_id}
                      taxType={"payable"}
                      taxLabelOnChange={(e, num, taxIndex) => {
                        const data = {
                          target: {
                            name: "tax_id",
                            value: e,
                          },
                        };
                        handleItemTaxChange(data, taxIndex);
                      }}
                      taxValueOnChange={(e, num, taxIndex) => {
                        handleItemTaxChange(e, taxIndex);
                      }}
                      deleteItemTax={(taxIndex, id) => {
                        deleteItemTaxContainer(taxIndex, id);
                      }}
                      handleTaxOnBlur={handleTaxOnBlur}
                      disabledFeature={currentUserInfo.disabled_feature}
                      currencyCode={currency.code}
                    />
                    {/* {IsFeatureEnabled(
                    "Tax Configuration",
                    currentUserInfo.disabled_feature
                  ) && ( */}
                    <a
                      href="#"
                      role="button"
                      className="add-tags-button"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div
                        className="d-flex align-items-center mx-0"
                        onClick={() => addItemTaxContainer()}
                      >
                        <img
                          className="add-tax-button"
                          src={AddTagIcon}
                          alt=""
                        />
                        <span className="px-2">Add tax </span>
                      </div>
                    </a>
                    {/* )} */}
                  </FormControl>
                )}
                <FormControl sx={createModalCustomStyle.addItemForm}>
                  <div className="form-button-wrapper w-100">
                    <button
                      className="save-button"
                      type="submit"
                      disabled={isDisabled ? true : false}
                      style={
                        isDisabled
                          ? { pointerEvents: "none", opacity: "0.5" }
                          : {}
                      }
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="cancel-button"
                      onClick={handleClose}
                      disabled={isDisabled ? true : false}
                      style={
                        isDisabled
                          ? { pointerEvents: "none", opacity: "0.5" }
                          : {}
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </FormControl>
              </form>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateItem;
