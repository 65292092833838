import React from "react";
import Popup from "reactjs-popup";
import { SuspendImg } from "../../../../../../../assets/images";
import { Link } from "react-router-dom";
import { revokeSuspendedUserDetails } from "../../../../userSlice";
import { toast } from "react-toastify";
import useCommonData from "../../../../../../hooks/useCommon";
type Props = {
  userId: number;
  refreshList: () => void;
};
const SuspendUserPopup = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();

  /**
   * Revoke User Suspend Handler
   */
  const revokeSuspendedUserHandler = async (userId: number) => {
    const responseAction = await dispatch(
      revokeSuspendedUserDetails({
        id: userId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshList();
        toast.success("Revoked suspended user successfully", {
          toastId: "user-revoke-suspend-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
  };
  return (
    <Popup
      trigger={
        <div className="btn suspended-button">
          <img src={SuspendImg} width="18" alt="suspended icon" />
        </div>
      }
      position="bottom center"
      className="suspended-popover"
    >
      <div id="popover-body" className="popover-body">
        <div className="suspended-msg">
          <h6 id="action_title">User is suspended!</h6>
          <p>Activate to revoke suspension</p>
          <Link
            to="#"
            className="action-link-activate"
            onClick={(e) => e.preventDefault()}
          >
            <span onClick={() => revokeSuspendedUserHandler(props.userId)}>
              Activate
            </span>
          </Link>
        </div>
      </div>
    </Popup>
  );
};

export default SuspendUserPopup;
