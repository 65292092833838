import React from "react";
import "./pageContainer.css";
import TransactionHeader from "../transactionHeader/TransactionHeader";
import { CardBody } from "../cardbody/CardBody";

type Props = {
  wrapperClass?: string;
  children: React.ReactNode;
  title: string;
  editId?: number;
};

export const PageContainer = (props: Props) => {
  return (
    <div
      className={`page-container card card-user-management card-customer card-create-item invoice-create main-card overflowX-hidden h-100 ${props.wrapperClass}`}
    >
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <TransactionHeader
          title={props.title}
          transactionNumber={Number(props.editId)}
        />
      </div>
      <CardBody>{props.children}</CardBody>
    </div>
  );
};
