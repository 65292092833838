import { DateFormatHandler, StatusColor } from "../../../../../helpers/helper";
import { ObjectType } from "../../../../../types";
import "./SearchResultDetails.css";

type Props = {
  details: ObjectType;
};
export const SearchResultDetails = ({ details }: Props) => {
  return (
    <>
      {Object.keys(details)
        .filter((key) => key !== "id")
        .filter(
          (key) =>
            details[key] !== null &&
            details[key] !== "" &&
            details[key] !== undefined &&
            !details[key].includes("undefined") &&
            !details[key].includes("null")
        )
        .map((key, index) => {
          return details[key] ? (
            <>
              <span className="verticals-item">
                <span>{key} :</span>
                {/* {" : "} */}
                <span
                  className={key === "Status" ? StatusColor(details[key]) : ""}
                >
                  {key.includes("Date")
                    ? DateFormatHandler(details[key])
                    : details[key]}
                </span>
              </span>
            </>
          ) : (
            <></>
          );
        })}{" "}
    </>
  );
};
