import React from 'react';
import "./Loader.css";
function Loader() {
    return (
        <div className="loader-container">
            <div className="loader-position">
                <div className="loader">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>

    );
}

export default Loader;