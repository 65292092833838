import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  ContactRef,
  CreateContactError,
  CreateContactValues,
} from "../../../../../types";
import { validateContactPerson } from "./ValidateCustomerForm";
import { deleteCustomerContactDetails } from "../../customerSlice";
import useCommonData from "../../../../hooks/useCommon";
import ContactBox from "../../../../common/components/ContactBox/ContactBox";
import { initialContact } from "./StateInitialization";

type Props = {
  customerId: number;
  contactArray: CreateContactValues[];
  contactFormErrors: CreateContactError[];
  contactErrorCount: number;
  contactErrorTempCount: number;
  handleContactErrors: (count: number) => void;
};
const ContactPerson: ForwardRefRenderFunction<ContactRef, Props> = (
  props,
  ref
) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [contactArray, setContactArray] = useState<CreateContactValues[]>([
    initialContact,
  ]);
  const [contactFormErrors, setContactFormErrors] = useState<
    CreateContactError[]
  >([]);
  const [contactErrorCount, setContactErrorCount] = useState(0);
  const [contactErrorTempCount, setContactErrorTempCount] = useState(0);
  const [deleteContactIds, setDeleteContactIds] = useState<string[]>([]);

  useImperativeHandle(
    ref,
    () => ({
      contactArray: contactArray,
      deleteContacts: deleteContacts,
    }),
    [contactArray]
  );

  useEffect(() => {
    setContactArray(props.contactArray);
  }, [props.contactArray]);

  useEffect(() => {
    setContactFormErrors(props.contactFormErrors);
    setContactErrorCount(props.contactErrorCount);
    setContactErrorTempCount(props.contactErrorTempCount);
  }, [props.contactFormErrors]);

  $(function () {
    setTimeout(() => {
      $(document).on(
        "focus",
        ".multiselect-container .searchBox",
        function (this: any) {
          $(this).parent().addClass("focused");
        }
      );
      $(document).on(
        "blur",
        ".multiselect-container .searchBox",
        function (this: any) {
          $(this).parent().removeClass("focused");
        }
      );
    }, 50);
  });

  /**
   * Append new contact container
   */
  const addContactContainer = () => {
    const contactObject = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      is_primary: contactArray.length === 0 ? true : false,
    };
    setContactArray((contactArray) => [...contactArray, contactObject]);
  };
  /**
   * Set form values of customer contacts
   */
  const contactHandleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const re = /^[+0-9\b]+$/;
    const contacts = [...contactArray];
    const data: any = {};
    if (name === "email") {
      data.email = value;
    }
    if (name === "phone") {
      data.phone = value;
    }

    let result = validateContactPerson(data);
    if (!result && contactFormErrors[index] && name === "email") {
      contactFormErrors[index].email = "";
    }
    if (!result && contactFormErrors[index] && name === "phone") {
      contactFormErrors[index].phone = "";
    }
    if (
      result === false &&
      contactFormErrors[index] &&
      contactFormErrors[index].phone === "" &&
      contactFormErrors[index].email === ""
    ) {
      delete contactFormErrors[index];
    }
    if (name === "phone") {
      if (value === "" || re.test(value)) {
        contacts[index] = { ...contacts[index], [name]: value };
      }
    } else {
      contacts[index] = { ...contacts[index], [name]: value };
    }
    setContactFormErrors([...contactFormErrors]);
    setContactArray(contacts);
    let contErrCount = 0;
    contacts.map((item) => {
      let contactErrorResponse = validateContactPerson(item);
      if (contactErrorResponse) {
        contErrCount += Object.keys(contactErrorResponse).length;
      }
    });
    if (contactErrorTempCount > contErrCount) {
      setContactErrorCount(contErrCount);
      setContactErrorTempCount(contErrCount);
      props.handleContactErrors(contErrCount);
    }
  };
  /**
   * Set customer main contact
   */
  const setCustomerMainContact = (index: number) => {
    const contacts = [...contactArray];
    for (const key in contacts) {
      if (Number(key) === index) {
        contacts[key] = { ...contacts[key], is_primary: true };
      } else {
        contacts[key] = { ...contacts[key], is_primary: false };
      }
    }
    setContactArray(contacts);
  };
  /**
   * Delete contact container
   */
  const deleteContactContainer = async (index: number) => {
    const contacts = [...contactArray];
    const contactId: any = contacts[index].id;
    if (contactId) {
      setDeleteContactIds((deleteContactIds) => [
        ...deleteContactIds,
        contactId,
      ]);
    }
    const isPrimary = contacts[index].is_primary;
    contacts.splice(index, 1);
    if (isPrimary) {
      for (const key in contacts) {
        contacts[key] = { ...contacts[key], is_primary: true };
        break;
      }
    }
    setContactArray(contacts);
    if (contactFormErrors && contactFormErrors[index]) {
      delete contactFormErrors[index];
    }
    setContactFormErrors([...contactFormErrors]);
    let contErrCount = 0;
    contacts.map((item) => {
      let contactErrorResponse = validateContactPerson(item);
      if (contactErrorResponse) {
        contErrCount += Object.keys(contactErrorResponse).length;
      }
    });
    if (contactErrorTempCount > contErrCount) {
      setContactErrorCount(contErrCount);
      setContactErrorTempCount(contErrCount);
      props.handleContactErrors(contErrCount);
    }
  };

  /**
   * Api for delete customer contacts
   */
  const deleteContacts = async () => {
    if (deleteContactIds.length) {
      let deleteContactApis: any = [];
      deleteContactIds.forEach((contactId) => {
        deleteContactApis.push(
          dispatch(
            deleteCustomerContactDetails({
              customerId: props.customerId,
              contactId: Number(contactId),
              orgId: currentUserInfo.organization_id,
            })
          )
        );
      });
      if (deleteContactApis.length) {
        await Promise.all(deleteContactApis);
      }
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className="contacts">
        {contactArray.map((contact, index) => {
          return (
            <ContactBox
              contact={contact}
              index={index}
              key={index}
              onDeleteClick={(e) => {
                e.preventDefault();
                deleteContactContainer(index);
              }}
              contactHandleChange={(e) => contactHandleChange(e, index)}
              contactFormErrors={contactFormErrors}
              setAsMainContact={() => setCustomerMainContact(index)}
            />
          );
        })}
      </div>
      <button
        type="button"
        className="add-new add-contact m-0 mb-3 float-none"
        onClick={addContactContainer}
      >
        Add contact person
      </button>
    </div>
  );
};
export default forwardRef(ContactPerson);
