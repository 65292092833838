import { useEffect, useState } from "react";
import { ErrorImg } from "../../../assets/images";
import { ObjectType } from "../../../types";
import MultiEmailBox from "./multiEmailComponent/EmailTo";
import { toast } from "react-toastify";
import '../styles/SendEmailModal.css'

type Props = {
  emailLists: string[] | [];
  id: number;
  sendEmail: (id: number, emailList: string[]) => Promise<ObjectType>;
};

const SendEmailModal: React.FC<Props> = ({ emailLists, id, sendEmail }) => {
  const [emailList, setEmailList] = useState<string[] | []>(emailLists);
  const [formErrors, setFormErrors] = useState<ObjectType>({});

  // useEffect(() => {
  //   setEmailList(emailLists);
  // }, [emailLists]);

  const setEmailHandler = (email: string[]) => {
    setEmailList(email);
  };

  /**
   * Send emails
   */
  const sendEmailHandler = async () => {
    $("#send-email-button").addClass("customer-form-section-disable");
    let errors: any = [];
    errors = validate(emailList);
    if (Object.keys(errors).length) {
      $("#send-email-button").removeClass("customer-form-section-disable");
      setFormErrors(errors);
    } else {
      if (id > 0) {
        const response = await sendEmail(id, emailList);
        if (
          response.meta.requestStatus === "fulfilled" &&
          Object.keys(response.payload).length &&
          !("error" in response.payload)
        ) {
          toast.success("Email sent successfully!", {
            toastId: "send-email-success",
            closeButton: false,
            position: "top-center",
          });
        } else {
          // toast.error("Failed to send email!", {
          //   icon: ({ theme, type }) => (
          //     <img src={ErrorImg} width="16" height="14" alt="errorImage" />
          //   ),
          //   toastId: "send-email-failure",
          //   closeButton: false,
          //   className: "toast-error",
          //   position: "top-center",
          // });
        }
      }
      $(".close-button").click();
      $("#send-email-button").removeClass("customer-form-section-disable");
    }
  };

  const removeEmailHandler = (email: string) => {};

  const validate = (email: string[] | []) => {
    const errors: ObjectType = {};
    if (email.length === 0) {
      errors.email = "Please enter at least one email address";
    }
    return errors;
  };

  const closeHandler = () => {
    setFormErrors({});
  };
  const handleEmailError = (error: string) => {
    setFormErrors({ email: error });
  };
  return (
    <div
      className="modal fade email-modal"
      id="emailToModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="emailToModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="emailToModalLabel">
              Email to
            </h5>
          </div>
          <div className="modal-body-send-email">
            <MultiEmailBox
              onChange={setEmailHandler}
              emailList={emailLists}
              removeEmailHandler={removeEmailHandler}
              onError={handleEmailError}
              className={formErrors.email !== "" ? "email-error" : ""}
            />
            <span className="error">{formErrors.email}</span>
          </div>
          <div
            className="modal-footer justify-content-start"
            id="send-email-button"
          >
            <button
              type="button"
              className="btn send-button"
              data-dismiss="modal"
              onClick={sendEmailHandler}
            >
              Send
            </button>
            <button
              type="button"
              className="btn cancel-button close-button"
              data-bs-dismiss="modal"
              onClick={closeHandler}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SendEmailModal;
