import React from "react";

type LabelProps = {
  id?: string;
  className: string;
  htmlFor: string;
  children: React.ReactNode;
};
export const Label = (props: LabelProps) => {
  return (
    <label id={props.id} className={props.className} htmlFor={props.htmlFor} >
      {props.children}
    </label>
  );
};
