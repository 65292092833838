export async function uploadFileValidator(
  file: File,
  formatsToCheck: string[]
): Promise<boolean> {
  const response = await fetch(URL.createObjectURL(file));
  const arrayBuffer = await response.arrayBuffer();
  const uintArray = new Uint8Array(arrayBuffer);
  let isValid = false;

  // Check the uintArray signature for different file formats
  if (uintArray.length > 1 && uintArray[0] === 0xff && uintArray[1] === 0xd8) {
    // JPEG format
    isValid = true;
  } else if (
    uintArray.length > 3 &&
    uintArray[0] === 0x89 &&
    uintArray[1] === 0x50 &&
    uintArray[2] === 0x4e &&
    uintArray[3] === 0x47
  ) {
    // PNG format
    isValid = true;
  } else if (
    uintArray.length > 3 &&
    uintArray[0] === 0x25 &&
    uintArray[1] === 0x50 &&
    uintArray[2] === 0x44 &&
    uintArray[3] === 0x46 &&
    formatsToCheck.includes("pdf")
  ) {
    // PDF format
    isValid = true;
  }

  return isValid;
}
