import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { colors } from "../../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  ErrorImg,
  FourDotIcon,
  GreyLockIcon,
  TableSettingsIcon,
} from "../../../../assets/images";
import {
  currentUserSelector,
  setTableCustomizationFields,
} from "../../commonSlice";
import "../../../../index.css";
import "./TableSetup.css";

type Props = {
  type: string;
  tableColumns: string[];
  customizeColumnValues: string[];
  mandatoryColumns?: string[];
  refreshList: () => void;
};
export default function TableSetup(props: Props) {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [customizeColumnValues, setCustomizeColumnValues] = useState<string[]>(
    []
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    setCustomizeColumnValues([...props.customizeColumnValues]);
    let selectedBasedOnHelperColumns: string[] = [];
    props.tableColumns.map((column) => {
      if (props.customizeColumnValues.includes(column)) {
        selectedBasedOnHelperColumns.push(column);
      }
    });
    setSelectedColumns([...selectedBasedOnHelperColumns]);
  }, [props]);

  const handleChange = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      if (selectedColumns.length < 9)
        setSelectedColumns([...selectedColumns, value]);
    } else {
      setSelectedColumns(selectedColumns.filter((column) => column !== value));
    }
  };

  const handleSubmit = async () => {
    if (selectedColumns.length >= 4) {
      setIsDisabled(true);
      setError(false);
      const responseAction = await dispatch(
        setTableCustomizationFields({
          orgId: currentUserInfo.organization_id,
          tableColumns: {
            table_name: props.type,
            list_of_column: selectedColumns,
          },
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        $(".close-btn").click();
        toast.success(
          "Success! Your table columns have been customized and saved.",
          {
            toastId: "column-update-success",
            closeButton: false,
            position: "top-center",
          }
        );
        setIsDisabled(false);
      } else {
        $(".close-btn").click();
        closeHandler();
        toast.error("Oops, something went wrong. Please try again.", {
          icon: ({ theme, type }) => (
            <img src={ErrorImg} width="16" height="14" />
          ),
          toastId: "column-update-error",
          closeButton: false,
          className: "toast-error",
          position: "top-center",
        });
        setIsDisabled(false);
      }
    } else {
      setError(true);
      setIsDisabled(false);
    }
  };

  /**
   * Reset values while closing modal
   */
  $(document).ready(function () {
    $(".modal .close-btn")
      .off()
      .on("click", function () {
        closeHandler();
      });
    $(document)
      .off()
      .on("click", ".modal-backdrop", function () {
        $(".table-settings .close-btn").trigger("click");
      });
  });
  const closeHandler = () => {
    setError(false);
    props.refreshList();
  };

  return (
    <>
      <a
        href="/"
        className="table-settings"
        data-bs-toggle="modal"
        data-bs-target="#tableSettingsModal"
      >
        <img src={TableSettingsIcon} alt="table settings" />
      </a>
      <div
        className="modal cd-modal table-settings-modal fade"
        id="tableSettingsModal"
        tabIndex={-1}
        aria-labelledby="tableSettingsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header flex-column align-items-start">
              <div className="title-section d-flex flex-column w-100">
                <div className="close-button-container w-100">
                  <button
                    type="button"
                    id="tag-close"
                    className="close-btn pull-right"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          fill={colors.slate}
                          fillRule="nonzero"
                          d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
                <h5 className="modal-title w-100" id="tableSettingsModalLabel">
                  Customize Columns
                </h5>
              </div>
              <div className="lower-section d-flex justify-content-between w-100">
                <div className="search-wrapper"></div>
                <div className="selected-count">
                  <span className="selected-count">
                    {selectedColumns.length}/
                    {customizeColumnValues.length > 9
                      ? 9
                      : customizeColumnValues.length}
                  </span>
                  Selected
                </div>
              </div>
            </div>

            <div className="modal-body">
              <form action="" className="col-settings-form">
                <ul className="col-list">
                  {customizeColumnValues.map((value, index) => {
                    return (
                      <li
                        className={
                          props.mandatoryColumns?.includes(value)
                            ? "mandatory"
                            : ""
                        }
                        key={`custom-column-${index}`}
                      >
                        <div className="d-flex column-item w-100 align-items-center">
                          <img
                            src={FourDotIcon}
                            alt="column item setting icon"
                          />
                          <div className="form-check d-flex align-items-center">
                            {props.mandatoryColumns?.includes(value) ? (
                              <img
                                className="locked-img"
                                src={GreyLockIcon}
                                alt="mandatory item"
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`check${index}`}
                                value={value}
                                checked={
                                  selectedColumns.includes(value) ? true : false
                                }
                                onChange={handleChange}
                              />
                            )}
                            <label
                              className="form-check-label"
                              htmlFor={`check${index}`}
                            >
                              {value}
                            </label>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </form>
            </div>
            <div className="modal-footer">
              {error ? (
                <span className="error modal-footer-error-center">
                  * Oops! A minimum of 4 columns must be selected to customize
                  your table.
                </span>
              ) : (
                ""
              )}
              <div className="form-button-wrapper w-100">
                <button
                  className="btn save-button"
                  onClick={handleSubmit}
                  disabled={isDisabled ? true : false}
                  style={
                    isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}
                  }
                >
                  Save
                </button>
                <button
                  type="button"
                  className={`btn cancel-button allocation-cancel}`}
                  data-bs-dismiss="modal"
                  onClick={closeHandler}
                  disabled={isDisabled ? true : false}
                  style={
                    isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
