import { NoSearchResultIcon } from "../../../common/components/customSvgIcons/noSearchResultIcon";
import { colors } from "../../../constants/colors";
import "./NoResultComponent.css";

type Props = {
  variant: "popper" | "page";
};
export const NoSearchResultComponent = (props: Props) => {
  return (
    <div className={`no-search-result-wrapper ${props.variant}`}>
      <div className="no-search-result-icon-wrapper">
        <NoSearchResultIcon color={colors.battleshipGrey} />
      </div>
      <div className="no-search-result-message-title">
        <span>No Results Found!</span>
      </div>
      <div className="no-search-result-message">
        <span>
          Sorry, there are no results for this search. Please try again.
        </span>
      </div>
    </div>
  );
};
