import { useState } from "react";
import PermissionMatrixHeader from "./PermissionMatrixHeader";
import {
  CustomSubModuleValues,
  ModuleListValues,
  ModuleStatusValues,
} from "../../../../../../../types";
import Loader from "../../../../../components/Loader";
import PermissionMatrixSideBar from "./PermissionMatrixSideBar";
import PermissionMatrixTable from "./PermissionMatrixTable";
import PermissionMatrixFooter from "./PermissionMatrixFooter";

type Props = {
  handlePermissionChange: (
    e: any,
    moduleId: number,
    subModuleId: number,
    permissionId: number,
    moduleName: string,
    subModuleName: string
  ) => void;
  handleFullAccessOfEachSubModule: (
    e: any,
    moduleId: number,
    subModuleId: number,
    moduleName: string,
    subModuleName: string
  ) => void;
  grantFullAccessOfEachModule: (
    e: any,
    moduleName: string,
    moduleId: number
  ) => void;
  isLoading: boolean;
  moduleList: ModuleListValues[];
  moduleListForFullAccess: ModuleListValues[];
  moduleListValues: ModuleListValues[];
  submodulePermissions: CustomSubModuleValues[];
  moduleStatus: ModuleStatusValues;
  resetToDefault: (e: any, moduleId: number, moduleName: string) => void;
};
function PermissionMatrix(props: Props) {
  const [selectedModule, setSelectedModule] = useState("Contacts");
  const sidebarSelectHandler = (label: string) => {
    setSelectedModule(label);
  };
  return (
    <section className="permission-section w-100" id="permission-container">
      <div className="row g-0">
        <PermissionMatrixHeader />
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="d-flex tab-wrapper">
            <div className="tab-grid d-flex w-100">
              <PermissionMatrixSideBar
                moduleListValues={props.moduleListValues}
                moduleStatus={props.moduleStatus}
                selectedModule={selectedModule}
                sidebarSelectHandler={sidebarSelectHandler}
              />
              {props.isLoading ? <Loader /> : null}
              <PermissionMatrixTable
                moduleList={props.moduleList}
                submodulePermissions={props.submodulePermissions}
                handleFullAccessOfEachSubModule={
                  props.handleFullAccessOfEachSubModule
                }
                handlePermissionChange={props.handlePermissionChange}
              />
            </div>
            {props.moduleListForFullAccess
              .filter((modules) => modules.name === selectedModule)
              .map((moduleList, index) => {
                return (
                  <PermissionMatrixFooter
                    key={index}
                    moduleList={moduleList}
                    grantFullAccessOfEachModule={
                      props.grantFullAccessOfEachModule
                    }
                    selectedModule={selectedModule}
                    moduleListValues={props.moduleListValues}
                    resetToDefault={props.resetToDefault}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}
export default PermissionMatrix;
