import { OrganizationDropdownIcon } from "../../../../common/components/customSvgIcons/organizationDropdownIcon";
import { colors } from "../../../../constants/colors";
import { OrganizationSwitchDropDownValues } from "../../../../../types";
import "./organizationDropdown.css";

type Props = {
  switchOrganizationHandler: (id: number) => void;
  userOrganization: OrganizationSwitchDropDownValues[];
  currentOrganizationName: string;
  currentOrganizationId: number;
};
export const OrganizationDropdown = (props: Props) => {
  return (
    <div className="btn-group organization-dropdwon">
      <button
        type="button"
        className="btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <OrganizationDropdownIcon color={colors.white} />
        <span className="oraganization">{props.currentOrganizationName}</span>
      </button>
      <ul className="dropdown-menu dropdown-menu-start">
        {props.userOrganization?.length < 2 ? (
          <li key={"headerOrg-" + -1}>
            <a
              className="dropdown-item active"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              <span className="organization">
                {props.currentOrganizationName}
              </span>
              {/* <span className="org-location">
                  {currentOrganizationName}
                </span> */}
            </a>
          </li>
        ) : props.userOrganization ? (
          props.userOrganization?.map((organization, index) => {
            return (
              <li
                onClick={() => props.switchOrganizationHandler(organization.id)}
                key={"headerOrg-" + index}
              >
                <a
                  className={
                    organization.id === props.currentOrganizationId
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="organization">{organization.name}</span>
                  {organization.parent_hierarchy !== organization.name && (
                    <span className="org-location">
                      {organization.parent_hierarchy}
                    </span>
                  )}
                </a>
              </li>
            );
          })
        ) : null}
      </ul>
    </div>
  );
};
