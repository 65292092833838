import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { fetchTagOptionForBills } from "../../bills/billsSlice";

export const useFetchTagOptionsForBills = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const getTagOptionsForBills = async (
    billIds: number[],
    setTagOptionsForBills: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      fetchTagOptionForBills({
        orgId: currentUserInfo.organization_id,
        billIds: billIds,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      dispatch(setLoaderState(false));
      setTagOptionsForBills(response);
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { getTagOptionsForBills };
};
