import PaymentReceivedDetailHeader from "./paymentReceivedDetailHeader";

function PaymentReceivedDetail() {
  return (
    <div className="">
      <div
        className="card card-dashboard bills-detail-card cd-detail-card w-100"
        id="bills-detail-card"
      >
        <PaymentReceivedDetailHeader />
      </div>
    </div>
  );
}

export default PaymentReceivedDetail;
