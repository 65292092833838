import React from "react";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { ObjectType } from "../../../../types";
import BackButton from "../BackButton";
import InfoTag from "../InfoTag";
import TransactionNumber from "../TransactionNumber";

type Props = {
  onBackButtonClick: () => void;
  data: ObjectType;
  dataId: string | undefined;
  statusAddon?: string;
  rightElements?: React.ReactNode;
};
export default function Header(props: Props) {
  return (
    <>
      <div className="card-header card-user-management-header card-no-bg-header">
        <div className="header-wrap">
          <BackButton onClick={props.onBackButtonClick} />
          <h1>
            {joinTransactionNumber(props.data.num)}
            <InfoTag
              status={props.data.status}
              statusAddon={props.statusAddon}
            />
            {props.data.tag}
          </h1>
          {Number(props.dataId) > 0 ?? (
            <TransactionNumber transactionNumber={Number(props.dataId)} />
          )}
          {props.rightElements ? <div style={{float:'right'}}>{props.rightElements}</div> : null}
        </div>
      </div>
    </>
  );
}
