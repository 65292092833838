import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ObjectType } from "../../../types";
import { fetchTransactionJournals } from "../../home/chartOfAccounts/coaSlice";
import { currentUserSelector, orgCurrencyListSelector } from "../commonSlice";
import { NumberFormat } from "../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../helpers/prefixHelper";
import DetailsTableContainer from "./detailsPage/DetailsTableContainer";
import ToggleButton from "./toggleButton/ToggleButton";
import AccountNameDisplay from "./accountNameDispaly/AccountNameDisplay";
import "../styles/JournelView.css";
type Props = {
  title: string;
  transactionType: string;
  transactionId: number;
  reRenderFlag?: number;
  isVoid?: boolean;
};
const JournelView: ForwardRefRenderFunction<
  { journalDetail: () => Promise<void> },
  Props
> = (props, ref) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [journalData, setJournalData] = useState<ObjectType[]>([]);
  const [payments, setPayments] = useState<ObjectType[]>([]);
  const [refunds, setRefunds] = useState<ObjectType[]>([]);
  const [isCashPosting, setIsCashPosting] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      journalDetail: journalDetail,
    }),
    []
  );

  useEffect(() => {
    if (props.transactionId !== 0 && props.transactionType !== "" && !props.isVoid)
      journalDetail();
  }, [
    props.transactionId,
    props.transactionType,
    props.reRenderFlag,
    currentUserInfo.organization_id,
    isCashPosting,
  ]);

  /**
   * Fetch Bill Details
   */
  const journalDetail = async () => {
    const responseAction = await dispatch(
      fetchTransactionJournals({
        transactionType: props.transactionType,
        transactionId: props.transactionId,
        orgId: currentUserInfo.organization_id,
        isCashPosting: isCashPosting,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {}, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let journalData = response.transactions;
        let itemTotalData = {
          account_id: "",
          debit: response.net_debit,
          credit: response.net_credit,
        };
        journalData.push(itemTotalData);
        setJournalData(journalData);
        if (response.payments) setPayments(Object.values(response.payments));

        if (response.refunds) setRefunds(Object.values(response.refunds));
      }
    }
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "ACCOUNT",
        accessor: "show.account",
      },
      {
        Header: "DEBITS",
        accessor: "show.debits",
      },
      {
        Header: "CREDITS",
        accessor: "show.credits",
      },
    ],
    []
  );
  /**
   * set table row data
   */
  const data = journalData
    ? journalData.map((itemData, index) => ({
        show: {
          account: (
            <>
              {itemData.account_name ? (
                <AccountNameDisplay
                  accountName={itemData.account_name}
                  ledgerRoot={itemData.ledger_root}
                />
              ) : (
                ""
              )}
            </>
          ),
          debits:
            itemData.transaction_side === "Debit" || itemData.account_id === ""
              ? currentUserInfo.currency_code +
                " " +
                NumberFormat(
                  itemData.debit,
                  currentUserInfo.currency_code,
                  orgCurrencyList
                )
              : "",
          credits:
            itemData.transaction_side === "Credit" || itemData.account_id === ""
              ? currentUserInfo.currency_code +
                " " +
                NumberFormat(
                  itemData.credit,
                  currentUserInfo.currency_code,
                  orgCurrencyList
                )
              : "",
        },
      }))
    : [];

  const getPaymentsData = (payments: ObjectType[]) => {
    return payments
      ? payments.map((paymentItem) => ({
          show: {
            // account: paymentItem.account_name,
            account: (
              <>
                {paymentItem.account_name ? (
                  <AccountNameDisplay
                    accountName={paymentItem.account_name}
                    ledgerRoot={paymentItem.ledger_root}
                  />
                ) : (
                  ""
                )}
              </>
            ),
            debits:
              paymentItem.transaction_side === "Debit"
                ? currentUserInfo.currency_code +
                  " " +
                  NumberFormat(
                    paymentItem.debit,
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                : "",
            credits:
              paymentItem.transaction_side === "Credit"
                ? currentUserInfo.currency_code +
                  " " +
                  NumberFormat(
                    paymentItem.credit,
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                : "",
          },
        }))
      : [];
  };

  //set the isCashPosting flag as per the option chose
  const switchHandler = (selected: string) => {
    selected === "accrual" ? setIsCashPosting(false) : setIsCashPosting(true);
  };
  return (
    <>
      <div className="journal-view-container">
        {(data.length > 1 || payments.length > 0) &&
          (props.transactionType === "Payments Made" ||
            props.transactionType === "Payments Received") && (
            <div className="cash-posting-wrapper">
              <ToggleButton
                wrapperClass="cash-posting-toggle"
                lButtonLabel="Accrual"
                rButtonLabel="Cash"
                lButtonValue="accrual"
                rButtonValue="cash"
                selected="accrual"
                width="130px"
                height="35px"
                onChoose={switchHandler}
              />
            </div>
          )}
        {data.length > 1 && !props.isVoid ? (
          <div className="jv-postings-section">
            <h2>{props.title}</h2>
            <div className="table-section">
              <DetailsTableContainer
                columns={columns}
                data={data}
                className="table fixed-table journal-preview-table"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {props.isVoid && (
          <div className="jv-postings-section">
            <h2>{props.title}</h2>
            <hr className="journal-entry-void-state-hr"></hr>
            <p className="journal-entry-void-state">
              Journal entries will not be available for {props.transactionType}{" "}
              in the Void state.
            </p>
          </div>
        )}
        {payments.map((payment) => {
          let paymentDetails: ObjectType[] = payment.transactions;
          let itemTotalData: { account: any; debits: string; credits: string } =
            {
              account: "",
              debits: payment.net_debit
                ? currentUserInfo.currency_code +
                  " " +
                  NumberFormat(
                    payment.net_debit,
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                : "",
              credits: payment.net_credit
                ? currentUserInfo.currency_code +
                  " " +
                  NumberFormat(
                    payment.net_credit,
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                : "",
            };
          let paymentTableData = getPaymentsData(paymentDetails);
          paymentTableData.push({ show: itemTotalData });
          return (
            <div className="payments-postings-section">
              {" "}
              <h2>
                {props.transactionType === "Payments Received"
                  ? "Invoice Payment "
                  : "Payment Made "}
                - {joinTransactionNumber(payment.transaction_number)}
              </h2>
              <div className="table-section">
                <DetailsTableContainer
                  columns={columns}
                  data={paymentTableData}
                  className="table fixed-table journal-preview-table"
                />
              </div>
            </div>
          );
        })}
        {refunds.map((refund) => {
          let refundDetails: ObjectType[] = refund.transactions;
          let itemTotalData: { account: any; debits: string; credits: string } =
            {
              account: "",
              debits: refund.net_debit
                ? currentUserInfo.currency_code +
                  " " +
                  NumberFormat(
                    refund.net_debit,
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                : "",
              credits: refund.net_credit
                ? currentUserInfo.currency_code +
                  " " +
                  NumberFormat(
                    refund.net_credit,
                    currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                : "",
            };
          let refundTableData = getPaymentsData(refundDetails);
          refundTableData.push({ show: itemTotalData });
          return (
            <div className="payments-postings-section">
              {" "}
              <h2>Payment Refund - {refund.transaction_number}</h2>
              <div className="table-section">
                <DetailsTableContainer
                  columns={columns}
                  data={refundTableData}
                  className="table fixed-table journal-preview-table"
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default forwardRef(JournelView);
