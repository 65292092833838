import { useEffect, useState } from "react";
import "../../TransactionLocking.css";
import LockTransactionCard from "../common/LockTransactionCard";
import LockTransactionModal from "../common/LockTransactionModal";
import { useNavigate } from "react-router-dom";
import NotificationModal from "../../../../common/components/NotificationModal";
import TransactionLockingHeader from "../common/TransactionLockingHeader";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import {
  getTransactionLockingDetail,
  unlockTransaction,
} from "../../transactionLockingSlice";
import Loader from "../../../components/Loader";
import {
  CustomLockValues,
  TransactionDetailValues,
  UnlockValues,
} from "../../../../../types";
import { toast } from "react-toastify";
import TransactionLockingFooter from "./TransactionLockingFooter";
const TransactionLocking = () => {
  const initialState = {
    modules: [{ id: 0, name: "" }],
    lockings: [
      {
        id: 0,
        module_id: 0,
        locking_type: "",
        lock_reason: "",
        unlock_reason: "",
        start_date: "",
        end_date: "",
        is_lock_all: false,
      },
    ],
  };
  const initialUnlockValues = {
    id: 0,
    module_id: 0,
    locking_type: "",
    unlock_reason: "",
  };
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lockModalShow, setLockModalShow] = useState(false);
  const [partialUnlockModalShow, setPartialUnlockModalShow] = useState(false);
  const [unlockModalShow, setUnlockModalShow] = useState(false);
  const [unlockAllModalShow, setUnlockAllModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetailValues>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleIds, setModuleIds] = useState<number[]>([]);
  const [disablePartialLockValues, setDisablePartialLockValues] = useState<
    UnlockValues[]
  >([initialUnlockValues]);

  useEffect(() => {
    getTransactionDetails();
  }, [currentUserInfo.organization_id]);
  /**
   * Open Lock / Unlock / Partial Unlock / Edit / Unlock All modal handler - Individual Transaction
   */
  const openModal = (
    action: string,
    moduleName: string,
    moduleId: number[]
  ) => {
    if (action === "partial_unlock") {
      setPartialUnlockModalShow(true);
    } else if (action === "unlock") {
      setUnlockModalShow(true);
    } else if (action === "lock") {
      setLockModalShow(true);
    } else if (action === "unlock_all") {
      setUnlockAllModalShow(true);
    } else if (action === "edit_lock") {
      setEditModalShow(true);
    }
    setModuleName(moduleName);
    setModuleIds(moduleId);
  };
  /**
   * Close Lock / Unlock / Partial Unlock / Edit / Unlock All modal handler - Individual Transaction
   */
  const closeModal = (action: string, type: string) => {
    if (action === "partial_unlock") {
      setPartialUnlockModalShow(false);
    } else if (action === "unlock") {
      setUnlockModalShow(false);
    } else if (action === "lock") {
      setLockModalShow(false);
    } else if (action === "unlock_all") {
      setUnlockAllModalShow(false);
      if (type === "submit") navigate("lock-all-transactions");
    } else if (action === "edit_lock") {
      setEditModalShow(false);
    }
  };
  /**
   * Function get transaction details
   */
  const getTransactionDetails = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getTransactionLockingDetail(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const lockedModules = responseAction.payload.lockings.filter(
        (item: any) => item.is_lock_all
      );
      if (
        lockedModules.length !== 0 &&
        (lockedModules.length === responseAction.payload.modules.length ||
          lockedModules.length === 2 * responseAction.payload.modules.length)
      ) {
        navigate("lock-all-transactions");
      } else {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
        const response = responseAction.payload;
        if (Object.keys(response) && !("error" in response)) {
          setTransactionDetails(response);
        }
      }
    }
  };
  /**
   * Function to get card subheading to display in transaction card
   */
  const getCardSubHeading = (moduleName: string) => {
    switch (moduleName) {
      case "Sales":
        return "Invoices, Credit Notes, Payment Received transactions";
      case "Purchases":
        return "Bills, Debit Notes, Payment Made transactions";
      case "Accounts":
        return "Journal transactions";
      default:
        return "";
    }
  };

  const disablePartialUnlock = (moduleId: number[]) => {
    if (moduleId.length) {
      const lockModuleIds = transactionDetails.lockings
        .filter(
          (module) =>
            module.module_id === moduleId[0] &&
            module.locking_type === "partial_unlock"
        )
        .map((module) => {
          return module.id;
        });

      setDisablePartialLockValues([
        {
          id: lockModuleIds[0],
          module_id: moduleId[0],
          locking_type: "partial_unlock",
          unlock_reason: null,
        },
      ]);
    }
  };
  /**
   * Function to handler disable partial unlock confirmation
   */
  const handleDisablePartialUnlock = async () => {
    const responseAction = await dispatch(
      unlockTransaction({
        values: disablePartialLockValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        toast.success("Locking transaction preferences have been updated", {
          toastId: "disable-lock-transaction-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
    getTransactionDetails();
  };
  /**
   * Function to get locked module array values
   */
  const getLockModuleValues = (moduleId: number[]) => {
    if (moduleId.length && transactionDetails.lockings.length) {
      const customLockValue: CustomLockValues[] = transactionDetails.lockings
        .filter((module) =>
          unlockAllModalShow
            ? module.module_id
            : module.module_id === moduleId[0]
        )
        .map((module) => {
          return {
            id: module.id,
            module_id: module.module_id,
            locking_type: module.locking_type,
            end_date: module.end_date,
            lock_reason: module.lock_reason,
            moduleName: transactionDetails.modules
              .filter((item) => item.id === module.module_id)
              .map((module) => module.name)[0],
          };
        });
      return customLockValue;
    }
    return [];
  };

  return (
    <>
      <TransactionLockingHeader>
        {!isLoading &&
          transactionDetails.modules.map((module, index) => {
            return (
              <LockTransactionCard
                key={index}
                isLockAll={false}
                lockedModules={transactionDetails.lockings
                  .filter((item) => item.module_id === module.id)
                  .map((module) => {
                    return module;
                  })}
                moduleName={module.name}
                cardSubHeading={getCardSubHeading(module.name)}
                modalId={`unlock-item-${module.id}`}
                openModal={openModal}
                moduleId={[module.id]}
                disablePartialUnlock={disablePartialUnlock}
                isAllTransactionLocked={
                  transactionDetails.lockings.length === 0
                    ? false
                    : Object.values(transactionDetails.lockings).every(
                        (module) => module.is_lock_all === true
                      )
                }
                moduleLength={transactionDetails.modules.length}
              />
            );
          })}
        {!isLoading && (
          <TransactionLockingFooter
            transactionDetails={transactionDetails}
            openModal={openModal}
          />
        )}
        {isLoading ? <Loader /> : null}
      </TransactionLockingHeader>
      {lockModalShow && (
        <LockTransactionModal
          isLockAll={false}
          firstHeading="Lock"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="lockModal"
          moduleIds={moduleIds}
          action="lock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {unlockModalShow && (
        <LockTransactionModal
          isLockAll={false}
          firstHeading="Unlock"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="unLockModal"
          moduleIds={moduleIds}
          action="unlock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {partialUnlockModalShow && (
        <LockTransactionModal
          isLockAll={false}
          firstHeading="Unlock Partially"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="partiallyUnlockModal"
          moduleIds={moduleIds}
          action="partial_unlock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {editModalShow && (
        <LockTransactionModal
          isLockAll={false}
          firstHeading="Edit"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="editModal"
          moduleIds={moduleIds}
          action="edit_lock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {unlockAllModalShow && (
        <LockTransactionModal
          isLockAll={false}
          firstHeading="Unlock"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="unlockAllModal"
          moduleIds={moduleIds}
          action="unlock_all"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      <NotificationModal
        modalHeader="Do you want to disable partial unlock?"
        modalBody="Your transactions will be locked completely."
        modalSubmit="Disable Partial Unlock"
        modalCancel="Cancel"
        modalSubmitHandler={handleDisablePartialUnlock}
      />
    </>
  );
};
export default TransactionLocking;
