import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import UserTable from "./UserTable";
import useListData from "../../../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../../../hooks/usePaginationDispatch";
import CustomTooltips from "../../../../../../common/components/Tooltip";
import useCommonData from "../../../../../../hooks/useCommon";
import { usersList } from "../../../../userSlice";
import SuspendUserPopup from "../components/SuspendUserPopup";
import OrganizationRolePopup from "../components/OrganizationRolePopup";
import Popover from "../../../../../../common/components/Popover";
import { IsFeatureEnabled } from "../../../../../../../helpers/featureFlagHelper";
import UserName from "../components/UserName";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { InviteUser } from "../../../../../../../types";
import ListActions from "./ListActions";

type Props = {
  deleteListData: (id: number, name: string) => void;
  roleId: number;
};
function UserTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [users, setUsers] = useState<InviteUser[]>([]);
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setNameSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, nameSortOrder } =
    useListData();

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useImperativeHandle(
    ref,
    () => ({
      fetchUsersList: fetchUsersList,
    }),
    []
  );
  useEffect(() => {
    fetchUsersList();
  }, [
    nameSortOrder,
    page,
    itemsPerPage,
    currentUserInfo.organization_id,
    props.roleId,
  ]);

  const fetchUsersList = async () => {
    dispatch(setLoaderState(true));
    if (IsFeatureEnabled("Users", currentUserInfo.disabled_feature)) {
      const data = {
        page,
        itemsPerPage,
        roleId: props.roleId,
        userSort: nameSortOrder,
        orgId: currentUserInfo.organization_id,
      };
      const responseAction = await dispatch(usersList(data));
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setUsers(response.users);
          let length = response.users.length;
          setTotalList(response.total);
          if (response.total !== 0)
            setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        }
        dispatch(setLoaderState(false));
      }
    }
  };

  const userSortHandler = (column: string) => {
    if (nameSortOrder === "ASC") {
      setNameSortOrder("DESC");
    } else {
      setNameSortOrder("ASC");
    }
  };

  const refreshList = () => {
    fetchUsersList();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "show.name",
        sort: true,
        sortOrder:
          nameSortOrder === "ASC"
            ? "ascending"
            : nameSortOrder === "DESC"
            ? "descending"
            : "",
        className: "name",
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <>
              <CustomTooltips
                placement={"bottom"}
                title={value?.name?.length > 15 ? value?.name : ""}
              >
                <UserName
                  name={value?.name ? value?.name : ""}
                  isSignIn={value.is_signin}
                />
              </CustomTooltips>
              {value.is_suspended && usersPermission.includes("Create") ? (
                <SuspendUserPopup userId={value.id} refreshList={refreshList} />
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Email",
        accessor: "show.email",
        sort: false,
        sortOrder: "",
        className: "email",
      },
      {
        Header: "Organization & Role",
        accessor: "show.organizationRole",
        sort: false,
        sortOrder: "",
        className: "email",
        Cell: ({ cell: { value, row } }: any) => {
          return <OrganizationRolePopup values={value} />;
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (usersPermission.length === 1 &&
            usersPermission.includes("Read")) ||
            (!value.isSignIn && !usersPermission.includes("Create")) ? null : (
            <ListActions
              value={value}
              roles={usersPermission}
              deleteOnClick={() => {
                $(".close-delete-btn").click();
                props.deleteListData(value.id, value.name);
              }}
              refreshList={refreshList}
            />
          );
        },
      },
    ],
    [nameSortOrder, currentUserInfo.organization_id]
  );
  const data = users
    ? users.map((user) => ({
        show: {
          name: user,
          email: user.email,
          organizationRole: user.effective_org_roles_mapping,
          actions: {
            id: user.id,
            isSignIn: user.is_signin,
            isSuperUser: user.is_superuser,
            isMutable: user.is_mutable,
            isSuspended: user.is_suspended,
            name: user.name,
          },
        },
        className: user.is_suspended ? "suspended" : "",
      }))
    : [];

  return (
    <UserTable
      totalList={totalList}
      startCount={startCount}
      endCount={endCount}
      itemsPerPage={itemsPerPage}
      columns={columns}
      data={data}
      sortHandler={userSortHandler}
      setPageInfo={(countPerPage, page) => {
        setItemsPerPage(countPerPage);
        setPage(page);
      }}
    />
  );
}
export default React.memo(forwardRef(UserTableContainer));
