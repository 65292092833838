import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import VoidModal from "../../../common/components/VoidModal";
import useCommonData from "../../../hooks/useCommon";
import { changeAddonStatus } from "../addonSlice";

type Props = {
  addonId: number;
  addonName: string;
  refreshAddon: () => void;
  organizationId: number;
};
const AddonVoid = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const voidRoleResponseAction = await dispatch(
      changeAddonStatus({
        id: props.addonId,
        status: "CANCELLED",
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (voidRoleResponseAction.payload) {
      const voidRoleResponse = voidRoleResponseAction.payload;
      if (
        Object.keys(voidRoleResponse).length &&
        !("error" in voidRoleResponse)
      ) {
        props.refreshAddon();
        $(".close-btn").click();
        toast.success("Addon cancelled successfully!", {
          toastId: "role-void-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in voidRoleResponse) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <VoidModal
      heading="Are you sure you want to cancel the Addon"
      subHeading={
        <span>
          Cancelled <strong>{props.addonName}</strong> cannot be used for the
          creation of any new subscriptions.
        </span>
      }
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default AddonVoid;
