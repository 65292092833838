import React from "react";
import Select from "react-select";
import { useAppSelector } from "../../../../../../../app/hooks";
import { rolesSelector } from "../../../../userSlice";
import { RoleSelectCustomStyle } from "../../../../../../common/components/RoleSelectCustomStyle";
type Props = {
  roleId: number;
  filterWithRoleHandler: (e: any) => void;
};
const RoleFilter = (props: Props) => {
  const userRoles = useAppSelector(rolesSelector);
  const roleOptionList = () => {
    let roleList = [{ label: "All Roles", value: 0 }];
    userRoles.map((role) => {
      roleList.push({
        label: role.name,
        value: role.id,
      });
    });
    return roleList;
  };
  return (
    <li className="nav-item">
      <div className="dropdown all-roles-wrapper">
        <Select
          name="all-roles"
          id="b_region"
          options={roleOptionList()}
          className="state-select form-select custom-select mt-0 all-roles-filter"
          value={
            props.roleId === 0
              ? {
                  label: "All Roles",
                  value: props.roleId,
                }
              : props.roleId !== 0
              ? {
                  label: userRoles.filter((role) => {
                    return role.id === props.roleId;
                  })[0]?.name,
                  value: props.roleId,
                }
              : []
          }
          onChange={(e) => props.filterWithRoleHandler(e)}
          styles={RoleSelectCustomStyle}
          isSearchable={false}
        />
      </div>
    </li>
  );
};

export default RoleFilter;
