import { FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setAmountCalculationResult,
  getTotalAmountDetails,
} from "../../home/paymentsMade/paymentsMadeSlice";

import { currentUserSelector } from "../../common/commonSlice";
import { useEffect } from "react";
import { payloadRefiner } from "../../../helpers/pmHelpers/amountCalculationPayloadHelper";

type Props = {
  formik: FormikProps<any>;
};
type Bill = {
  forex_amount: number;
  payment_amount: number;
  id: number;
  conversion_id: number;
  bill_number: string;
  bill_date: string;
  bill_amount: string;
  amount_due: string;
  is_deleted: boolean;
};
type Payload = {
  currency_id: number | null;
  bank_currency_id: number | null;
  unpaid_bills: Bill[];
  bank_charges: number | string;
  amount_paid: number | string;
  amount_paid_bank_currency: number | string;
  bank_conversion_rate: number | string;
  bank_charges_bank_currency: number | string;
  total_refunded_amount: number | string;
};
export const useAmountCalculation = (props: Props) => {
  let bank_charges = props.formik.values.bank_charges_amount;
  if (
    props.formik.values.bank_currency_id === props.formik.values.currency_id
  ) {
    bank_charges = props.formik.values.bank_charges_bank_currency;
  }

  const payload: Payload = {
    currency_id: props.formik.values.currency_id,
    bank_currency_id: props.formik.values.bank_currency_id,
    unpaid_bills:
      Array.isArray(props.formik.values.unpaid_bill_lines) &&
      props.formik.values.unpaid_bill_lines
        .filter((value: Bill) => value.payment_amount)
        .map((value: any) => {
          return {
            bill_id: value.bill_id,
            bill_number: value.bill_number,
            bill_amount: value.bill_amount,
            amount_due: value.amount_due,
            payment_amount: !isNaN(Number(value.payment_amount))
              ? Number(value.payment_amount)
              : 0,
          };
        }),
    bank_charges: bank_charges,
    amount_paid: props.formik.values.amount_paid,
    amount_paid_bank_currency: props.formik.values.amount_paid_bank_currency,
    bank_conversion_rate: props.formik.values.bank_conversion_rate,
    bank_charges_bank_currency: props.formik.values.bank_charges_bank_currency,
    total_refunded_amount: props.formik.values.total_refunded_amount,
  };
  const refinedPayload = payloadRefiner(payload);
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAmountCalculationResult(signal);
    return () => {
      controller.abort();
    };
  }, [
    refinedPayload.amount_paid,
    refinedPayload.bank_charges,
    refinedPayload.bank_conversion_rate,
    JSON.stringify(payload.unpaid_bills),
  ]);

  const getAmountCalculationResult = async (signal: AbortSignal) => {
    if (
      (payload.amount_paid || payload.amount_paid_bank_currency) &&
      payload.currency_id
    ) {
      const responseAction = await dispatch(
        getTotalAmountDetails({
          values: refinedPayload,
          orgId: currentUserInfo.organization_id,
          signal: signal,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        dispatch(setAmountCalculationResult(response));
        // if (
        //   payload.bank_currency_id &&
        //   payload.currency_id !== payload.bank_currency_id
        // ) {
        //   props.formik.setFieldValue(
        //     "amount_paid_bank_currency",
        //     response.total_details.amount_paid_bank_currency
        //   );
        // }
        // props.formik.setFieldValue(
        //   "bank_charges_bank_currency",
        //   response.total_details.bank_charges_bank_currency
        // );

        props.formik.setValues((values: any) => {
          return {
            ...values,
            amount_paid_bank_currency:
              payload.bank_currency_id &&
              payload.currency_id !== payload.bank_currency_id
                ? Number(response.total_details.amount_paid_bank_currency) === 0
                  ? ""
                  : response.total_details.amount_paid_bank_currency
                : values.amount_paid_bank_currency,
            bank_charges_bank_currency:
              Number(response.total_details.bank_charges_bank_currency) === 0
                ? ""
                : response.total_details.bank_charges_bank_currency,
            total:
              Number(response.total_details.total) === 0
                ? ""
                : response.total_details.total,
            amount_excess: response.total_details.amount_excess,

          };
        });
      }
    }
  };
};
