                                                                                                                                                                                                            import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { AddressRef, CreateAddressValues } from "../../../../../types";
import { initialAddressValues } from "./StateInitialization";
import AddressForm from "./AddressForm";
import "./AddressDetails.css";

type Props = {
  billingFormValues: CreateAddressValues;
  shippingFormValues: CreateAddressValues;
  shippingSameAsBilling: boolean;
  billingFormErrors: any;
  shippingFormErrors: any;
};
const AddressDetails: ForwardRefRenderFunction<AddressRef, Props> = (
  props,
  ref
) => {
  const [billingFormValues, setBillingFormValues] =
    useState<CreateAddressValues>(initialAddressValues);
  const [shippingFormValues, setShippingFormValues] =
    useState<CreateAddressValues>(initialAddressValues);
  const [shippingSameAsBilling, setShippingSameAsBilling] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      billingFormValues,
      shippingFormValues,
      shippingSameAsBilling,
    }),
    [billingFormValues, shippingFormValues, shippingSameAsBilling]
  );

  useEffect(() => {
    setBillingFormValues(props.billingFormValues);
  }, [props.billingFormValues]);

  useEffect(() => {
    setShippingFormValues(props.shippingFormValues);
  }, [props.shippingFormValues]);

  useEffect(() => {
    setShippingSameAsBilling(props.shippingSameAsBilling);
  }, [props.shippingSameAsBilling]);

  /**
   *Set form value of billing address
   */
  const billingHandleChange = (e: any) => {
    const { name, value } = e.target;
    const re = /^[+0-9\b]+$/;
    if (name === "phone") {
      if (value === "" || re.test(value)) {
        setBillingFormValues({ ...billingFormValues, [name]: value });
      }
    } else {
      setBillingFormValues({ ...billingFormValues, [name]: value });
    }
    if (shippingSameAsBilling) {
      if (name === "phone") {
        if (value === "" || re.test(value)) {
          setShippingFormValues({ ...shippingFormValues, [name]: value });
        }
      } else {
        setShippingFormValues({ ...shippingFormValues, [name]: value });
      }
    }
  };
  /**
   *Set form value of shipping address
   */
  const shippingHandleChange = (e: any) => {
    const { name, value } = e.target;
    const re = /^[+0-9\b]+$/;
    if (name === "phone") {
      if (value === "" || re.test(value)) {
        setShippingFormValues({ ...shippingFormValues, [name]: value });
      }
    } else {
      setShippingFormValues({ ...shippingFormValues, [name]: value });
    }
  };

  /**
   * Function to set the check box checked or not
   */
  const shippingAsBillingHandler = () => {
    if (shippingSameAsBilling) {
      setShippingSameAsBilling(false);
      setShippingFormValues(initialAddressValues);
    } else {
      setShippingFormValues(billingFormValues);
      setShippingSameAsBilling(true);
    }
  };

  /**
   * Function to handle country change in Address details
   */
  const handleCountryChange = (e: any, addressType: string) => {
    const value = e.value;
    if (addressType === "billing") {
      setBillingFormValues({
        ...billingFormValues,
        country_id: parseInt(value),
        state_id: 0,
      });
    } else if (addressType === "shipping") {
      setShippingFormValues({
        ...shippingFormValues,
        country_id: parseInt(value),
        state_id: 0,
      });
    }
    if (shippingSameAsBilling) {
      setShippingFormValues({
        ...shippingFormValues,
        country_id: Number(e.value),
        state_id: 0,
      });
    }
  };
  /**
   * Function to handle state change
   */
  const handleStateChange = (e: any, addressType: string) => {
    if (e.value) {
      if (addressType === "billing") {
        setBillingFormValues({
          ...billingFormValues,
          state_id: parseInt(e.value),
        });
      } else if (addressType === "shipping") {
        setShippingFormValues({
          ...shippingFormValues,
          state_id: parseInt(e.value),
        });
      }
      if (shippingSameAsBilling) {
        setShippingFormValues({
          ...shippingFormValues,
          state_id: Number(e.value),
        });
      }
    }
  };

  return (
    <div
      className="tab-pane address-tab-pane fade"
      id="pills-address"
      role="tabpanel"
      aria-labelledby="pills-address-tab"
    >
      <div className="row mb-0">
        <div className="col col-lg-12">
          <div className="row g-0 address-fields-row">
            <div className="col-12 col-lg-6 address-wrapper billing-address mw-349 rp-24">
              <h2>Billing Address</h2>
              <AddressForm
                address={billingFormValues}
                errors={props.billingFormErrors}
                countryChange={(e) => {
                  handleCountryChange(e, "billing");
                }}
                stateChange={(e) => handleStateChange(e, "billing")}
                handleChange={billingHandleChange}
                isDisabled={false}
                key={"b"}
              />
            </div>
            <div className="col-12 col-lg-6 address-wrapper shipping-address mw-349 lp-24">
              <div className="header-with-checkbox-wrapper">
                <h2>Shipping Address</h2>
                <div className="form-check right-aligned-checkbox float-end">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="same_as_billing"
                    checked={shippingSameAsBilling ? true : false}
                    onChange={shippingAsBillingHandler}
                  />
                  <label className="form-check-label" htmlFor="same_as_billing">
                    Same as billing address
                  </label>
                </div>
              </div>
              <AddressForm
                address={shippingFormValues}
                errors={props.shippingFormErrors}
                countryChange={(e) => {
                  handleCountryChange(e, "shipping");
                }}
                stateChange={(e) => handleStateChange(e, "shipping")}
                handleChange={shippingHandleChange}
                isDisabled={shippingSameAsBilling}
                key={"s"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default forwardRef(AddressDetails);
