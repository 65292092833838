import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ObjectType } from "../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  searchLoaderStateSelector,
  setIsSearching,
  setSearchBoxVariant,
  setSearchQueryValue,
  setSearchResult,
} from "../../../common/commonSlice";
import { FieldClearCrossIcon } from "../../../common/components/customSvgIcons/FieldClearCrossIcon";
import { SearchIcon } from "../../../common/components/customSvgIcons/searchIcon";
import { searchModules } from "../../../constants/constants";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import SearchOptions from "../SearchOptions/SearchOptions";
import { searchValue } from "../graphQLSetup/SearchFunction";
import { searchDetailsSetter } from "../helperFunctions/detailsSetter";
import { getModuleName } from "../helperFunctions/getModuleName";
import { majorAttributeSetter } from "../helperFunctions/majorAttributeSelector";
import { SearchLoader } from "../loader/SearchLoader";
import { NoSearchResultComponent } from "../noResultPage/NoResultComponent";
import "./searchBox.css";
import { getVariantBasedStyle } from "./searchBoxCustomStyles";
import { SearchResultDropdown } from "./searchResultDropdown";
import { useNavigate } from "react-router-dom";

type Props = {
  searchValue: string;
  moduleValue: string;
  variant: "blue" | "white";
  getSearchResult: (
    searchResult: ObjectType[],
    value: string,
    module: string
  ) => void;
  onNavigate?: () => void;
};
export const SearchBox = (props: Props) => {
  const isSearching = useAppSelector(searchLoaderStateSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [value, setSearchValue] = useState(props.searchValue);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const [module, setModule] = useState<any>(props.moduleValue);
  const [isFocused, setFocused] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [searchData, setSearchData] = useState<ObjectType[]>([]);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const navigate = useNavigate();
  const { setStartCount, setEndCount, setTotalList, setPage } =
    usePaginationDispatch();
  const { page, pageCount, itemsPerPage, totalList, startCount, endCount } =
    useListData();
  const styles = getVariantBasedStyle(props.variant);
  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialLoad(false);
    setSearchValue(e.target.value);
    dispatch(setSearchBoxVariant(props.variant));
    if (props.variant === "white") {
      if (totalResults !== 0) setStartCount(1);
      setEndCount(
        itemsPerPage * 1 < totalResults ? itemsPerPage * 1 : totalResults
      );
      setPage(1);
    }
  };
  const handleModuleChange = (e: any) => {
    setModule(e.target.value);
    if (props.variant === "white") {
      if (totalResults !== 0) setStartCount(1);
      setEndCount(
        itemsPerPage * 1 < totalResults ? itemsPerPage * 1 : totalResults
      );
      setPage(1);
    }
  };
  const handleSearch = async (
    value: string,
    module: string,
    signal: AbortSignal
  ) => {
    dispatch(setIsSearching(true));
    const results = await searchValue(
      value,
      currentUserInfo.organization_id,
      module,
      page,
      itemsPerPage,
      signal
    ).finally(() => {
      dispatch(setIsSearching(false));
    });
    setTotalResults(results.total || 0);
    let searchParam = value;
    setSearchData(
      Array.isArray(results[module])
        ? results[module].map((value: any) => {
            return {
              resultAttribute: searchDetailsSetter(
                value,
                value["section"],
                orgCurrencyList
              ),
              majorAttribute: majorAttributeSetter(value["section"], value),
              breadCrumbs: [getModuleName(value["section"]), value["section"]],
              matchedField: value["matched_field"],
              module: value["section"],
              searchValue: searchParam,
            };
          })
        : []
    );
    if (props.variant === "white") {
      setTotalList(results.total);
      if (results.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
      setEndCount(
        itemsPerPage * page < results.total
          ? itemsPerPage * page
          : results.total
      );
      props.getSearchResult(
        Array.isArray(results[module])
          ? results[module].map((value: any) => {
              return {
                resultAttribute: searchDetailsSetter(
                  value,
                  value["section"],
                  orgCurrencyList
                ),
                breadCrumbs: [
                  getModuleName(value["section"]),
                  value["section"],
                ],
                matchedField: value["matched_field"],
                module: value["section"],
                searchValue: value,
              };
            })
          : [],
        value,
        module
      );
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if ((value && value.length > 2) || (value === "" && !isInitialLoad)) {
      handleSearch(value, module, signal);
    }
    return () => {
      controller.abort();
    };
  }, [value, module, page, itemsPerPage, currentUserInfo.organization_id]);

  useEffect(() => {
    if (value && value.length > 2 && isFocused) {
      setOpen(true);
    }
  }, [value]);
  useEffect(() => {
    setSearchValue(props.searchValue);
    setModule(props.moduleValue);
  }, [props.searchValue, props.moduleValue]);

  const clearField = () => {
    setSearchValue("");
    dispatch(
      setSearchQueryValue({
        searchQuery: "",
        searchModule: module,
      })
    );
  };
  const handleClick = () => {
    setOpen(false);
    props.onNavigate && props.onNavigate();
    dispatch(
      setSearchQueryValue({
        searchQuery: value,
        searchModule: module,
      })
    );
    setSearchValue("");
    setModule("global_search");
  };
  return (
    <div
      className={`global-search-field ${
        props.variant === "blue" ? "search-header" : "search-page"
      }`}
      key={props.moduleValue + "4" + currentUserInfo.organization_id}
    >
      <Autocomplete
        key={props.moduleValue + "2" + currentUserInfo.organization_id}
        options={searchData.slice(0, 5)}
        loading={isSearching}
        loadingText={
          <SearchLoader height={"20px"} margin={"10px 0px"} variant="popper" />
        }
        getOptionLabel={(option) => {
          return option.searchValue;
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && value.length > 2) {
            navigate("/search");
            dispatch(
              setSearchQueryValue({
                searchQuery: value,
                searchModule: module,
              })
            );
            setOpen(false);
            dispatch(setSearchResult(searchData));
            handleClick();
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        inputValue={value}
        id="global_search_autocomplete"
        noOptionsText={<NoSearchResultComponent variant="popper" />}
        open={props.variant === "white" || value.length < 3 ? false : open}
        onClose={(e: any) => {
          e.preventDefault();
          e.stopPropogation();
          setOpen(false);
        }}
        renderOption={(props, option) => {
          return (
            <>
              {" "}
              <SearchResultDropdown
                props={props}
                searchValue={value}
                handleClick={handleClick}
                option={option}
              />
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={"value"}
            onChange={(e: any) => {
              handleValue(e);
            }}
            onFocus={() => setFocused(true)}
            placeholder="Search"
            style={styles.input}
            InputProps={{
              ...params.InputProps,
              value: "value",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color={styles.icon.color} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {value !== "" ? (
                    <IconButton
                      onClick={() => {
                        props.getSearchResult([], "", "global_search");
                        if(props.variant === "white"){
                          setTotalList(0);
                        }
                        clearField();
                      }}
                    >
                      <FieldClearCrossIcon color={styles.icon.color} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        PopperComponent={(props) => (
          <SearchOptions
            {...props}
            query={value}
            module={module}
            total={totalResults}
            result={searchData}
            handleClick={handleClick}
            setOpen={setOpen}
          />
        )}
      />
      <FormControl
        variant="standard"
        className="global-search-form-control-root"
        style={styles.select}
        key={props.moduleValue + "1" + currentUserInfo.organization_id}
      >
        <Select
          className="search-module"
          value={module}
          onChange={handleModuleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          disableUnderline
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: 220,
            },
            PaperProps: {
              className: "global-search-menu-root",
            },
          }}
          IconComponent={ExpandMoreIcon}
        >
          {searchModules.map((option) => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                focusVisibleClassName="focussed"
                className="global-search-dropdown-item"
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
