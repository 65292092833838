import { getFormatedDate } from "../../../../../../helpers/helper";
import { AddressValues, ObjectType } from "../../../../../../types";
import _ from "lodash";

export const initialTaxValues = {
  tax_id: "",
  tax_percentage: "",
};
export const initialItemValues = {
  uuid: _.uniqueId(),
  id: 0,
  account_id: "",
  deferred_income_id: null,
  deferred_income_account: "",
  account_name: "",
  account_type: "",
  item_name: "",
  hsn_or_sac_code: "",
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "Absolute",
  discount_account_id: "",
  deferred_discount_account: "",
  deferred_discount_id: null,
  discount_account_name: "",
  discount_account_type: "",
  taxes: [initialTaxValues],
  inventory_item_id: null,
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  default_tds_percentage: 0,
  tds_name: "",
  gst_tax: null,
  currency_id: null,
  item_exemption_type: "",
  item_exemption_id: "",
  total_taxable_amount: 0,
  is_deferred_item: false,
};

export const initialFormValues = (isGstOrg: boolean) => {
  return {
    customer_id: "",
    customer_name: "",
    customer_details: {
      gst_registration_type: "",
      sales_default: {
        discount: "",
        discount_account_id: "",
        discount_account_name: "",
        discount_type: "",
        payment_term: "",
        payment_term_id: "",
        sales_account_id: "",
        sales_account_name: "",
      },
      customer_exemption_type: "TAXABLE",
    },
    settings: {
      default_inter_state_id: "",
      default_intra_state_id: "",
      is_round_off_enabled: false,
      is_manual_edit_enabled: false,
      tds: {
        apply_tds_at: "",
        is_enabled: false,
        support_tds_for: "",
      },
    },
    customer_notes: "",
    invoice_number: "",
    is_deferred: false,
    invoice_date: getFormatedDate(new Date()),
    issue_date: getFormatedDate(new Date()),
    reference_number: "",
    payment_term_id: "",
    due_date: getFormatedDate(new Date()),
    expiry_date: getFormatedDate(new Date()),
    fiscal_year_start_date: "",
    fiscal_year_end_date: "",
    currency_id: "",
    currency_code: "",
    conversion_id: 0,
    conversion_rate: "0",
    conversion_timestamp: "",
    by_user: false,
    place_of_supply: "",
    state_type: "",
    invoice_status: "",
    custom_fields: {},
    custom_fields_array: [],
    is_mutable: true,
    emails: [],
    email_to_raw: [],
    file_ids: [],
    files: [],
    tds_id: null,
    tds_level: "",
    tds_amount: 0,
    tds_account_id: 0,
    tds_percentage: 0,
    default_tds_percentage: 0,
    tds_name: "",
    tds_total: 0,
    is_tcs_percentage: true,
    is_tds_percentage: false,
    tds_ids: [],
    tcs_id: null,
    tcs_amount: 0,
    tcs_account_id: 0,
    tcs_percentage: 0,
    tcs_name: "",
    tcs_total: 0,
    tcs_ids: [],
    total: 0,
    total_details: {} as ObjectType,
    source_tax_type: "TCS",
    roundoff_amount: 0,
    is_line_item_level: true,
    recalculate_exchange_rate: false,
    is_gst_enabled: isGstOrg,
    is_gst_org: isGstOrg,
    is_locked: false,
    lock_date: "",
    is_tcs_enabled: true,
    deferred_conf: null,
    address_details: {
      billing_address: [
        {
          address: "",
          city: "",
          country_id: 0,
          country_name: "",
          id: 0,
          is_billing: false,
          is_primary: false,
          is_shipping: false,
          phone: "",
          state_id: 0,
          state_name: "",
          zip_code: 0,
        },
      ],
      shipping_address: [
        {
          address: "",
          city: "",
          country_id: 0,
          country_name: "",
          id: 0,
          is_billing: false,
          is_primary: false,
          is_shipping: false,
          phone: "",
          state_id: 0,
          state_name: "",
          zip_code: 0,
        },
      ],
      selected_billing_address_id: 0,
      selected_shipping_address_id: 0,
      selected_shipping_address: {
        address: "",
        city: "",
        country_id: 0,
        country_name: "",
        id: 0,
        is_billing: false,
        is_primary: false,
        is_shipping: false,
        phone: "",
        state_id: 0,
        state_name: "",
        zip_code: 0,
      } as AddressValues,
      selected_billing_address: {
        address: "",
        city: "",
        country_id: 0,
        country_name: "",
        id: 0,
        is_billing: false,
        is_primary: false,
        is_shipping: false,
        phone: "",
        state_id: 0,
        state_name: "",
        zip_code: 0,
      } as AddressValues,
    },
    items: [
      {
        uuid: _.uniqueId(),
        id: 0,
        account_id: "",
        deferred_income_id: null,
        deferred_income_account: "",
        account_name: "",
        account_type: "",
        item_name: "",
        hsn_or_sac_code: "",
        quantity: 1.0,
        rate: 0.0,
        discount: 0.0,
        tcs_amount: 0,
        discount_type: "Absolute",
        discount_account_id: null,
        deferred_discount_account: "",
        deferred_discount_id: null,
        discount_account_name: "",
        discount_account_type: "",
        taxes: [{ tax_id: "", tax_percentage: "" }],
        inventory_item_id: null,
        tds_id: null,
        tds_amount: 0,
        tds_account_id: 0,
        tds_percentage: 0,
        is_tds_percentage: true,
        tds_name: "",
        gst_tax: null,
        currency_id: null,
        item_exemption_type: "",
        item_exemption_id: "",
        taxable_amount: 0,
        tds_total: 0,
        is_deferred_item: false,
      },
    ],
    deletedItemIds: [],
    deletedItemTaxIds: [],
    status: "",
    action: "",
    deleted_gst_id: [] as ObjectType[],
    deleted_emails: [],
    type: "",
    gst_registration_type: "",
    invoice_id: 0, // used for print functionality
    download_link: "", // used for download functionality
    proforma_number: null,
    deleteItemGst: [] as ObjectType[],
    is_item_modified: false,
    is_cn_allocated: false,
  };
};

export const shippingEmptyFormvalues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: false,
  is_shipping: true,
  is_primary: true,
  state_name: "",
  country_name: "",
};

export const billingEmptyFormValues = {
  country_id: 0,
  address: "",
  city: "",
  state_id: 0,
  zip_code: null,
  phone: "",
  is_billing: true,
  is_shipping: false,
  is_primary: true,
  state_name: "",
  country_name: "",
};
