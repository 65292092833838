import { useEffect, useState } from "react";
import {
  CreateTaxValues,
  ObjectType,
  SubAccountValues,
} from "../../../../../types";
import Select from "react-select";
import "../../Settings.css";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useAppSelector } from "../../../../../app/hooks";
import { subAccountsOfParent } from "../../../chartOfAccounts/coaSlice";
import { createTax, editTax, taxesSelector } from "../../taxConfigurationSlice";
import { toast } from "react-toastify";
import { message } from "../../../../constants/messages";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import {
  initialSubAccountValues,
  initialTaxRateFormValues,
} from "./StateInitialization";
import useCommonData from "../../../../hooks/useCommon";
import { ValidateTaxRateForm } from "./VallidateTaxRateForm";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import "./TaxRateForm.css";

type Props = {
  taxId: number;
  handleModalClose: () => void;
  isAccountDeleted: boolean;
  refreshList: () => void;
};
function TaxRateForm(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const taxes = useAppSelector(taxesSelector);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [backendError, setBackendError] = useState<{ [key: string]: any }>({});
  const [isSelectTax, setIsSelectTax] = useState(false);
  const [tax, setTax] = useState("");
  const [assetsSubAccounts, setAssetsSubAccounts] = useState<
    SubAccountValues[]
  >([initialSubAccountValues]);
  const [liabilitiesSubAccounts, setLiabilitiesSubAccounts] = useState<
    SubAccountValues[]
  >([initialSubAccountValues]);

  const [createNewTaxValues, setCreateNewTaxValues] = useState<CreateTaxValues>(
    initialTaxRateFormValues
  );
  const [accountSelected, setAccountSelected] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  /**
   * Store the input field values of the selected tax for edit purpose
   */
  useEffect(() => {
    if (props.taxId > 0) {
      setIsSelectTax(true);
      taxes.tax_configuration.map((tax, index) => {
        if (tax.id === props.taxId) {
          if (tax.account_type === "Sales Tax Payable") {
            setTax("payable");
          } else {
            setTax("receivable");
          }
          setCreateNewTaxValues({
            tax_code: tax.tax_code,
            account_type:
              tax.account_type === "Sales Tax Payable"
                ? "PAYABLE"
                : "RECEIVABLE",
            account_id: tax.is_account_deleted ? 0 : tax.account_id,
          });
        }
      });
    }
  }, [props.taxId]);

  /**
   * Create / edit tax form submit handler
   */
  const handleCreateTax = (e: any) => {
    e.preventDefault();
    if (props.taxId > 0) {
      editTaxHandler();
    } else {
      createTaxHandler();
    }
  };

  /**
   * Create Tax handler
   */
  const createTaxHandler = async () => {
    let taxConfigurationInputs: any = { ...createNewTaxValues };
    let errors: any = [];
    errors = ValidateTaxRateForm(taxConfigurationInputs);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#form-btn-tax").addClass("customer-form-section-disable");
      const responseAction = await dispatch(
        createTax({
          values: taxConfigurationInputs,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setBackendError({});
          props.handleModalClose();
          props.refreshList();
          toast.success("Tax created successfully!", {
            toastId: "tax-create-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in response) {
          $("#form-btn-tax").removeClass("customer-form-section-disable");
          const errorDetails = response.error.response.detail;
          if (Array.isArray(errorDetails) && errorDetails.length) {
            let errorObject: { [key: string]: any } = {};
            for (const error of errorDetails) {
              if (error.type === "value_error.exists") {
                errorObject.tax_code = error.msg;
              } else {
                props.handleModalClose();
              }
            }
            setBackendError(errorObject);
          }
        }
      }
    }
  };

  /**
   * Edit tax handler
   */
  const editTaxHandler = async () => {
    let taxConfigurationInputs: any = { ...createNewTaxValues };
    let errors: any = [];
    errors = ValidateTaxRateForm(taxConfigurationInputs);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#form-btn-tax").addClass("customer-form-section-disable");
      const responseAction = await dispatch(
        editTax({
          taxId: props.taxId,
          values: taxConfigurationInputs,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setBackendError({});
          props.handleModalClose();
          props.refreshList();
          toast.success("Tax Updated successfully!", {
            toastId: "tax-update-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in response) {
          $("#form-btn-tax").removeClass("customer-form-section-disable");
          const errorDetails = response.error.detail;
          if (Array.isArray(errorDetails) && errorDetails.length) {
            let errorObject: { [key: string]: any } = {};
            for (const error of errorDetails) {
              if (error.type === "value_error.exists") {
                errorObject.tax_code = error.msg;
              } else {
                props.handleModalClose();
              }
            }
            setBackendError(errorObject);
          }
        }
      }
    }
  };

  /**
   * Create / Edit tax form input change handler
   */
  const inputChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setCreateNewTaxValues({ ...createNewTaxValues, [name]: value });
  };

  /**
   * Radio button switch handler
   */
  const radioButtonSelectHandler = (value: any) => {
    setIsSelectTax(true);
    setAccountSelected(false);
    setBackendError({});
    setFormErrors({});
    setTax(value);
    setCreateNewTaxValues((values) => ({
      ...values,
      account_type: value.toString().toUpperCase(),
    }));
    setCreateNewTaxValues((values) => ({
      ...values,
      account_id: 0,
    }));
  };

  useEffect(() => {
    getAssetsSubAccounts();
    getLiabilitiesSubAccounts();
  }, [currentUserInfo.organization_id]);

  /**
   * Get Asset accounts list drop down Api
   */
  const getAssetsSubAccounts = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Assets", "Expenses"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAssetsSubAccounts(response);
      }
    }
  };

  /**
   * Get Expense accounts list drop down Api
   */
  const getLiabilitiesSubAccounts = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Liabilities"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setLiabilitiesSubAccounts(response);
      }
    }
  };

  /**
   * Get the asset sub-account name in the selection dropdown : Edit Tax Configuration
   */
  const AssetAccountName = assetsSubAccounts
    .filter((subAccount) => subAccount.id === createNewTaxValues.account_id)
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  /**
   * Get the list of all Assets sub accounts
   */
  const AssetSubAccounts = assetsSubAccounts
    .filter((item) => item.id !== 0)
    .map((subAccount) => ({
      label: subAccount.account_name,
      value: subAccount.id,
      account_type: subAccount.account_type,
    }));

  /**
   * Get the Liabilities sub-account account name in the selection dropdown : Edit Tax Configuration
   */
  const LiabilitiesAccountName = liabilitiesSubAccounts
    .filter((subAccount) => subAccount.id === createNewTaxValues.account_id)
    .map((account) => ({
      label: account.account_name,
      value: account.id,
      account_type: account.account_type,
    }));

  /**
   * Get the list of all Liabilities sub accounts
   */
  const LiabilitiesSubAccounts = liabilitiesSubAccounts
    .filter((item) => item.id !== 0)
    .map((subAccount) => ({
      label: subAccount.account_name,
      value: subAccount.id,
      account_type: subAccount.account_type,
    }));

  const handelAccountSelection = (val: any) => {
    setAccountSelected(true);
    setCreateNewTaxValues((values) => ({
      ...values,
      account_id: val,
    }));
    setMenuIsOpen(false);
  };

  const LabelWithRoot = ({ data }: any) => (
    <div
      onClick={() => handelAccountSelection(data.value)}
      className="tax-rate-option-wrapper"
    >
      <span className="tax-rate-option-account-name">{data.label}</span>
      {data.account_type && (
        <div className="tax-rate-option-account-root">{data.account_type}</div>
      )}
    </div>
  );

  return (
    <>
      <div id="taxCreateModal" className="add-tax-form">
        <div className="tds-form-input-wrapper">
          <div className="tds-radio-wrapper d-flex">
            <RadioButtonGroup
              name="rateOrAmount"
              id="rateOrAmount"
              valuesArray={[
                { label: "Sales Tax Payable", value: "payable" },
                { label: "Sales Tax Receivable", value: "receivable" },
              ]}
              value={tax}
              icon={
                <span>
                  <RadioButtonUncheckedIcon />
                </span>
              }
              checkedIcon={
                <span>
                  <RadioButtonCheckedIcon className="radio-btn-tds" />
                </span>
              }
              isDisabled={false}
              onClick={radioButtonSelectHandler}
            />
          </div>
        </div>
        <form
          className={`create-user-form-wrap create-settings-form-wrap ${
            isSelectTax ? "" : "disabled"
          }`}
        >
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="tax_code">
              Tax Code<span className="mandatory">*</span>
            </label>
            <input
              type="text"
              id="tax_code"
              name="tax_code"
              value={createNewTaxValues.tax_code || ""}
              className={
                formErrors.tax_code || backendError.tax_code
                  ? "form-control error"
                  : "form-control"
              }
              placeholder="Enter tax code"
              onChange={inputChangeHandler}
              maxLength={12}
            />
            <span className="error">
              {formErrors.tax_code || backendError.tax_code}
            </span>
          </div>
          <div className={`form-outline ${isSelectTax ? "" : "disabled-item"}`}>
            <label className="form-label" htmlFor="account">
              Account<span className="mandatory">*</span>
            </label>
            <Select
              name="customer_ids"
              value={
                tax === "payable"
                  ? LiabilitiesAccountName.at(0)?.label === ""
                    ? null
                    : LiabilitiesAccountName
                  : AssetAccountName.at(0)?.label === ""
                  ? null
                  : AssetAccountName
              }
              options={
                tax === "payable" ? LiabilitiesSubAccounts : AssetSubAccounts
              }
              placeholder="Choose account"
              onChange={handelAccountSelection}
              className={`form-select custom-select customer-select m-0 ${
                formErrors.account ? "error" : ""
              }`}
              styles={customSelectStyle}
              components={{ Option: LabelWithRoot }}
              menuIsOpen={menuIsOpen}
              onMenuOpen={() => setMenuIsOpen(true)}
              onMenuClose={() => setMenuIsOpen(false)}
            />
            <span className="error">{formErrors.account}</span>
          </div>
          {props.isAccountDeleted && !accountSelected && props.taxId > 0 ? (
            <InfoBox
              message={message().taxAccountDeleteInfo}
              className="info-alert info-alert-messages"
            />
          ) : null}
          <div id="form-btn-tax">
            <button
              className="btn btn-block fa-lg invite-btn save-butn mb-4"
              type="button"
              disabled={!isSelectTax}
              onClick={handleCreateTax}
            >
              {props.taxId > 0 ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-block fa-lg cancel-btn mb-4"
              type="button"
              onClick={() => {
                props.handleModalClose();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
export default TaxRateForm;
