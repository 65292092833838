import React from "react";

import "./CustomCheckbox.css";

type Props = {
  checkboxName: string;
  checkboxId: string;
  isDisabled: boolean;
  isChecked: boolean;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  wrapperClass?: string;
  checkboxWrapperClass?: string;
};

const CustomCheckbox = (props: Props) => {
  return (
    <>
      <div
        className={`checkbox-wrap check-left-checkbox ${
          props.wrapperClass ? props.wrapperClass : ""
        }`}
      >
        <input
          name={props.checkboxName}
          className={`form-check-input ${
            props.checkboxWrapperClass ? props.checkboxWrapperClass : ""
          }`}
          type="checkbox"
          id={props.checkboxId}
          disabled={props.isDisabled}
          onChange={props.changeHandler}
          checked={props.isChecked}
        />
        {props.children}
      </div>
    </>
  );
};

export default CustomCheckbox;
