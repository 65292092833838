import * as yup from "yup";

export const CustomizeReportPLValidation = yup.object({
  compare_with: yup.string(),
  compare_value: yup.string().when("compare_with", {
    is: (compareWith: string) =>
      typeof compareWith === "string" &&
      compareWith.trim() !== "" &&
      compareWith !== undefined,
    then: (schema) => schema.required("Please select number of previous data"),
  }),
});
