import React from "react";
import { colors } from "../../constants/colors";

export default function BackButton(props: { onClick: () => void, label?: string }) {
  return (
    <button className="back-button" onClick={props.onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <path
            fill={colors.ceruleanBlue}
            fillRule="nonzero"
            d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
          />
        </g>
      </svg>
    </button>
  );
}
