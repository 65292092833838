import { useState } from "react";
import { Link } from "react-router-dom";
import { FinbookLogoImgNew, LogoSmall } from "../../../../../assets/images";
import "./logoSection.css";
import { useMediaQuery } from "@react-hook/media-query";

export const LogoSection = () => {
  const mobDevices = useMediaQuery('(max-width: 767px)'); 
  const smallMobDevices = useMediaQuery('(max-width: 459px)'); 
  const [bodyScrollTop, setBodyScrollTop] = useState(0);
  function handleSideBarMenu (this:any, e:any):void {
    //document.getElementById(sidebarWrapper)?.style.display = ""
    $("#sidebarWrapper").toggleClass("show"); 
    $(".sidebar-toggler").toggleClass("show");
    setTimeout(()=> {
      $("#sidebarWrapper").hasClass("show")? $('body').css({overflow:'hidden', marginTop: "-"+bodyScrollTop+"px"}):$('body').css({overflow:'initial', marginTop: 0});
      $(".sidebar-toggler").hasClass("show")?$(".sidebar-toggler").attr("aria-expanded", "true"):$(".sidebar-toggler").attr("aria-expanded", "false"); 
    }, 30);   
  }

  window.addEventListener("scroll", ()=> {    
    setBodyScrollTop(document.body.scrollTop);
  });
  return (
    <>
    {mobDevices&&(
      <button
        className="sidebar-toggler no-appearance"
        type="button"
        aria-controls="sidebarWrapper"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={handleSideBarMenu}
      >
        <span className="navbar-toggler-icon"></span>
        <span className="navbar-toggler-icon"></span>
        <span className="navbar-toggler-icon"></span>
      </button>
    )}
      <Link className="navbar-brand p-0 m-0" to="/">
        <img src={smallMobDevices?LogoSmall:FinbookLogoImgNew} alt="logo" height={64} />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
    </>
  );
};
