import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { useAppDispatch } from "../../../../../app/hooks";
import { ErrorImg } from "../../../../../assets/images";
import { deleteSubOrg } from "../../OrganizationSlice";
import "../../../../common/styles/AlertModal.css";

import {
  currentUserDetails,
  getOrganizationListForReport,
  getOrganizationListOfUser,
} from "../../../../common/commonSlice";
import { colors } from "../../../../constants/colors";
import UserService from "../../../../../keycloak/UserService";

type Props = {
  deleteSubOrgDetails: {
    id: number;
    name: string;
  };
  handleClose: () => void;
  refreshHierarchyListing: () => void;
  organizationId: number;
};
const DeleteSubOrg = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalSubmitHandler = async () => {
    $("#organization-delete-button").addClass("customer-form-section-disable");
    const deleteResponseAction = await dispatch(
      deleteSubOrg({
        orgId: props.deleteSubOrgDetails.id,
        currentOrgId: props.organizationId,
      })
    );
    $("#organization-delete-button").removeClass(
      "customer-form-section-disable"
    );
    if (deleteResponseAction.payload) {
      const deleteRoleResponse = deleteResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length && !deleteRoleResponse.error) {
        await UserService.updateToken();
        props.handleClose();
        props.refreshHierarchyListing();
        toast.success("Sub organization deleted successfully!", {
          toastId: "subOrg-delete-success",
          closeButton: false,
          position: "top-center",
        });
        await dispatch(getOrganizationListOfUser(props.organizationId));
        await dispatch(
          currentUserDetails({
            orgId: props.organizationId,
            isOrgChanged: false,
          })
        );
        await dispatch(
          getOrganizationListForReport({ orgId: props.organizationId })
        );
      } else if ("error" in deleteRoleResponse) {
        props.handleClose();
        if (
          deleteResponseAction.payload.error.detail[0].msg ===
          "Delete Sub Organization First"
        ) {
          toast.error(
            "Unable to delete organization as it has sub-organizations",
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} width="16" height="14" alt="error-img" />
              ),
              toastId: "delete-subOrg-failed-error",
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else {
          toast.error("Delete in sub organization action failed!", {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} width="16" height="14" alt="error-img" />
            ),
            toastId: "delete-subOrg-failed-error",
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={true}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="alert-modal"
        id="deleteSubModal"
      >
        <div className="modal-dialog modal-dialog-centered delete-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header position-relative">
                <button
                  type="button"
                  className="close-btn pull-right position-absolute"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.handleClose}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z" />
                      <path
                        fill={colors.slate}
                        fillRule="nonzero"
                        d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                      />
                    </g>
                  </svg>
                </button>
                <h5 className="modal-title" id="delete_title">
                  Delete Sub Organization!
                </h5>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete the sub organization{" "}
                  <strong>{props.deleteSubOrgDetails.name}</strong>
                </p>
              </div>
              <div className="modal-footer" id="organization-delete-button">
                <button
                  type="button"
                  className="btn btn-cancel btn-green"
                  onClick={props.handleClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-ok btn-red"
                  onClick={modalSubmitHandler}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DeleteSubOrg;
