import React from "react";
import {
  ObjectType,
  SelectedFiltersType,
  DateFilterType,
} from "../../../../../types";

type Props = {
  category: string;
  categoryLabel?: string;
  categoryType?: string;
  index: number;
  selectedCategory: string;
  selectedOptions: SelectedFiltersType;
  selectedDateFilters?: DateFilterType;
  onClick: () => void;
};
function FilterCategory({
  category,
  categoryLabel,
  categoryType,
  index,
  selectedCategory,
  selectedDateFilters,
  selectedOptions,
  onClick,
}: Props) {
  const getIsFiltered = (category: string) => {
    if (categoryType && categoryType === "date") {
      for (const obj in selectedDateFilters) {
        if (obj === category) {
          if (obj === "dueDate" || obj === "date") {
            // if (
            //   selectedDateFilters &&
            //   selectedDateFilters[obj]?.filterType === "date"
            // ) {
            return true;
            // }
          }
        }
      }
      return false;
    } else {
      for (const obj in selectedOptions) {
        if (obj === category) {
          if (selectedOptions[obj].length > 0) {
            return true;
          }
        }
      }
    }

    return false;
  };
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link ${selectedCategory === category ? "active" : ""} ${
          getIsFiltered(category) ? "unsaved " : ""
        }`}
        id={`${category}-tab`}
        data-bs-toggle="tab"
        data-bs-target={`#${category}`}
        type="button"
        role="tab"
        aria-controls={category}
        aria-selected={index === 0}
        tabIndex={index + 1}
        onClick={onClick}
      >
        <span>{categoryLabel ? categoryLabel : category}</span>
      </button>
    </li>
  );
}

export default React.memo(FilterCategory);
