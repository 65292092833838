const messages = {
    errors: {
      tagAmountRequired: "Please enter tag amount",
      tagAmountMax:
        "Tag Amount should be less than or equal to transaction amount",
        tagOptionAmountInvalid:
        "Please enter valid option amounts.",
      tagAmountEqualTotalOption:
        "Tag amount should be equal to total option amount",
      tagNoOption: "Please select atleast one option to continue.",
    },
    success: {
      reportingTagSave: "Reporting tag saved successfully",
    },
  };
  
  export default messages;
  