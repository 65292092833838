import "./tableInfo.css";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import { Grid } from "@mui/material";
import { CancelVoidScheduledModal } from "./CancelVoidScheduledModal";
import { CancelChangesModal } from "./CancelChangesModal";
import Void from "../../../../common/components/Void";
import { useState } from "react";

export default function ScheduledVoidDetailsInfo(props: any) {
  const stopIndex =
    props.scheduledSubscriptionData.status_change_scheduler?.findIndex(
      (item: { new_status: string }) => item.new_status === "CANCELLED"
    );

  return (
    <>
      <Grid container className="subscription-impact-details-container">
        <Grid item xs="auto">
          <Grid container>
            <Grid item xs="auto">
              <img src={info} id="message-img" alt="clone-alt" />
            </Grid>
            <Grid item xs="auto">
              <div className="m-l-10">
                This subscription is scheduled to be cancelled on{" "}
                {
                  props.scheduledSubscriptionData.status_change_scheduler[
                    stopIndex
                  ].scheduled_date
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          {props.overviewSubscriptionData.subscription_status && (
            <Void
              className="dropdown-item delete scheduler-cancel-btn"
              deleteIcon={false}
              editCancelFromScheduler={true}
              isMutable
              onClick={() =>
                props.voidListData(
                  props.overviewSubscriptionData.id,
                  props.overviewSubscriptionData.profile_name
                )
              }
              isLocked={false}
              lockDate={""}
              name="Invoice"
              status={props.overviewSubscriptionData.subscription_status}
              isButtonClick={true}
            />
          )}
        </Grid>
        <Grid item xs="auto">
          <CancelVoidScheduledModal
            type="removeScheduledCancel"
            subscriptionStatus={props.subscriptionStatus}
            overviewSubscriptionData={props.overviewSubscriptionData}
            schedulerId={
              props.scheduledSubscriptionData?.status_change_scheduler[0]?.id
            }
            subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
          />
        </Grid>
      </Grid>
    </>
  );
}
