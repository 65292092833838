import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const usePreservedLocation = () => {
  const location = useLocation();
  const [preservedLocation, setPreservedLocation] = useState(location);

  useEffect(() => {
    const preservedLocationString = sessionStorage.getItem("preservedLocation");
    if (preservedLocationString) {
      setPreservedLocation(JSON.parse(preservedLocationString));
    }
    // return () => localStorage.removeItem("preservedLocation");
  }, []);

  useEffect(() => {
    sessionStorage.setItem("preservedLocation", JSON.stringify(location));
  }, [location]);

  return preservedLocation;
};

export default usePreservedLocation;
