import { useAppDispatch } from "../../../../../../app/hooks";
import { BillDetailValues } from "../../../../../../types";
import { SaveButton } from "../../../../../common/components/button/SaveButton";
import { setDebitNoteAutofillData } from "../../../../../common/commonSlice";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../../../../../common/components/button/SecondaryButton";
import "./createDebitNoteAlert.css";
export const CreateDebitNoteAlert = (props: {
  isCreationPossible: boolean;
  debitNoteData: BillDetailValues;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleDispatch = async () => {
    props.isCreationPossible &&
      dispatch(
        setDebitNoteAutofillData({
          isFromBill: true,
          balanceDue: props.debitNoteData.total_details.balance_due,
          billId: props.debitNoteData.id,
          billStatus: props.debitNoteData.bill_status,
          debitNoteData: {
            debitNoteFormValues: { ...props.debitNoteData, email_to: [] },
            defaultFormValues: { ...props.debitNoteData, email_to: [] },
            itemFormValue: props.debitNoteData.item_details.map((item) => {
              return {
                ...item,
                account_id: item.account_id,
                account_name: item.account_name,
                discount:
                  item.discount.discount_percentage === null
                    ? item.discount.discount_value
                    : item.discount.discount_percentage,
              };
            }),
          },
        })
      );
    props.isCreationPossible && navigate("/debit-note/create");
  };
  return (
    <>
      <div className="create-debit-note-bill-page">
        <span>
          {props.isCreationPossible ? (
            <p>
              On creating the debit note, the debit note amount will get applied
              for this bill. Are you sure about creating it?
            </p>
          ) : (
            <p>
              You cannot convert this bill into a debit note as you have already
              created packages or debit notes for the underlying items. Please
              check them or delete them to create a new debit note.
            </p>
          )}
        </span>
        <div className="create-debit-note-bill-page-btn-group">
          <SaveButton
            type="button"
            onClick={
              props.isCreationPossible ? handleDispatch : props.handleClose
            }
            buttonContent={props.isCreationPossible ? "Yes proceed" : "Ok"}
            isDisabled={false}
          />
          {props.isCreationPossible && (
            <SecondaryButton
              type="button"
              onClick={props.handleClose}
              buttonContent="Cancel"
              isDisabled={false}
            />
          )}
        </div>
      </div>
    </>
  );
};
