import * as yup from "yup";

export const customizeAgingReportValidation = (isSaved: boolean) => {
  const baseSchema = {
    interval_range: yup
      .number()
      .required("Field cannot be empty")
      .max(90, "Please enter a number less than or equal to 90")
      .min(1, "Number must be greater than 0"),

    name: yup.string(),
  };

  if (isSaved) {
    baseSchema.name = baseSchema.name.required("Filter name is required");
  }

  return yup.object(baseSchema);
};
