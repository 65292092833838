import { SkeletonLoader } from "../../../common/components/skeletonLoader/SkeletonLoader";
import "./SearchLoader.css";

type Props = {
  height: string;
  margin: string;
  variant: "popper" | "page";
};
export const SearchLoader = ({ height, margin, variant }: Props) => {
  let count = variant === "popper" ? [1, 2, 3, 4, 5] : [1];
  return (
    <div className="search-skeleton-loader-wrapper">
      {count.map((index) => {
        return (
          <div style={{ margin: margin }}>
            <SkeletonLoader
              variant="text"
              animation="wave"
              width="25%"
              height={height}
              color={"rgba(145, 145, 156, 0.1)"}
            />
            <SkeletonLoader
              variant="text"
              animation="wave"
              width="35%"
              height={height}
              color={"rgba(145, 145, 156, 0.5"}
            />
            <SkeletonLoader
              variant="text"
              animation="wave"
              width="100%"
              height={height}
              color={"rgba(145, 145, 156, 0.2)"}
            />
          </div>
        );
      })}
    </div>
  );
};
