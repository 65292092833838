import { SvgIconProp } from "../../../../types";

export const TagIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : ""}
      style={{ margin: props.margin ? props.margin : "" }}
      viewBox="0 0 16 16"
    >
      <path
        d="m6.815 1.313 6.187.883.884 6.188-5.745 5.745a.625.625 0 0 1-.884 0L1.07 7.942a.625.625 0 0 1 0-.884l5.745-5.745zm.442 1.325L2.396 7.5l5.303 5.303 4.861-4.861-.663-4.64-4.64-.664zm1.326 3.978a1.25 1.25 0 1 1 1.767-1.768 1.25 1.25 0 0 1-1.767 1.768z"
        fill={props.color}
      />
    </svg>
  );
};
