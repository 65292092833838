import { useFormik } from "formik";
import { AddTagIcon } from "../../../../../assets/images";
import { CloseRowIcon, FormInputField } from "../../../../common/components";
import InfoTooltip from "../../../../common/components/InfoTooltip";
import { message } from "../../../../constants/messages";
import {
  fequencyData,
  discountedTrialInitialValue,
} from "../../PlanStateInitialization";
import { DiscountedTrialType } from "../../planType";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { ObjectType, PlanPriceDefaultValue } from "../../../../../types";

type Props = {
  frequency?: string;
  discountedTrialData: DiscountedTrialType[];
  setDefaultFormValues: Dispatch<SetStateAction<PlanPriceDefaultValue>>;
  trialError: any;
};
const discount_trial_ranges = [
  {
    range_from: "1",
    range_to: "3",
    discounted_percentage_per_step: 10,
  },
  {
    range_from: "4",
    range_to: "7",
    discounted_percentage_per_step: 10,
  },
];
const DiscountedTrialRange = (props: Props) => {
  const addRowHandle = (e: any) => {
    e.preventDefault();
    const trialData = JSON.parse(JSON.stringify(props.discountedTrialData));
    const rangeFrom = trialData[trialData.length - 1];
    let newRangeData = {
      range_from: parseFloat(rangeFrom.range_to) + 1,
      range_to: 0,
      discounted_percentage_per_step: "",
    };
    props.setDefaultFormValues((preValues: PlanPriceDefaultValue) => ({
      ...preValues,
      discount_trial_ranges: [...preValues.discount_trial_ranges, newRangeData],
    }));
  };

  const removeRowHandle = (e: any, key: number) => {
    e.preventDefault();
    props.setDefaultFormValues((preValues: PlanPriceDefaultValue) => ({
      ...preValues,
      discount_trial_ranges: [
        ...preValues.discount_trial_ranges.slice(0, key),
        ...preValues.discount_trial_ranges.slice(key + 1),
      ],
    }));
  };
  const showAddIcon = () => {
    const lastValue =
      props.discountedTrialData[props.discountedTrialData.length - 1];
    if (
      parseFloat(lastValue.range_to as string) >=
      parseFloat(lastValue.range_from as string)
    ) {
      return true;
    }
    return false;
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>, key: number) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (
      props.discountedTrialData.length - 1 === key &&
      !Number.isNaN(Number(value))
    ) {
      props.setDefaultFormValues((preValue: PlanPriceDefaultValue) => {
        const discountedValues = JSON.parse(
          JSON.stringify(props.discountedTrialData[key])
        );

        if (name === "range_to") {
          discountedValues[name] = `${value.replaceAll(".", "").trim()}`;
        }
        if (name === "discounted_percentage_per_step" && Number(value) <= 100) {
          let splitValue = value.split(".");
          if (splitValue[1] && splitValue[1].length > 5) {
            discountedValues[name] =
              preValue.discount_trial_ranges[
                key
              ].discounted_percentage_per_step;
          } else {
            discountedValues[name] =
              Number(value) === 100
                ? Number(`${value.replaceAll(".", "").trim()}`)
                : value;
          }
        }
        return {
          ...preValue,
          discount_trial_ranges: [
            ...preValue.discount_trial_ranges.slice(0, key),
            { ...discountedValues },
            ...preValue.discount_trial_ranges.slice(key + 1),
          ],
        };
      });
    }
  };

  return (
    <div className="item-cart">
      <div className="item-header">
        <b>Discounted trial</b>
        <InfoTooltip title={message().plan.discountedTrial} placement="top" />
      </div>
      {props.discountedTrialData.map((item: any, key: number) => (
        <>
          <div className="item-price-cont" key={key}>
            <div className="pricing-field-wrap">
              <FormInputField
                type="text"
                name="range_from"
                id="range_from"
                className={"range-item-price form-control"}
                label={`From (${
                  props?.frequency
                    ? fequencyData.find(
                        (data) => data.value === props.frequency
                      )?.label
                    : ""
                })`}
                placeholder={key <= 0 ? "1" : ""}
                maxLength={14}
                value={item.range_from}
                isRequired={true}
                disabled={true}
                errorClass={"priceErrorMessage"}
              />
              <FormInputField
                type="text"
                name="range_to"
                id="range_to"
                className={
                  props.trialError[key]?.range_to
                    ? "form-control error range-item-price"
                    : "form-control range-item-price"
                }
                label="To"
                placeholder="& Above"
                maxLength={14}
                value={item.range_to === -1 ? "" : item.range_to}
                onChange={(e) => {
                  handleFieldChange(e, key);
                }}
                isRequired={false}
                error={props.trialError[key]?.range_to || ""}
                disabled={
                  props.discountedTrialData?.length - 1 !== key ? true : false
                }
                errorClass={"priceErrorMessage"}
              />
              <FormInputField
                type="text"
                name="discounted_percentage_per_step"
                id="discounted_percentage_per_step"
                className={
                  props?.trialError[key]?.discounted_percentage_per_step
                    ? "form-control error range-item-price"
                    : "form-control range-item-price"
                }
                label="Discounted Percentage"
                placeholder="0.00"
                value={
                  item.discounted_percentage_per_step === 0
                    ? "0"
                    : item.discounted_percentage_per_step
                }
                onChange={(e) => {
                  handleFieldChange(e, key);
                }}
                isRequired={true}
                error={
                  props.trialError[key]?.discounted_percentage_per_step || ""
                }
                errorClass={"priceErrorMessage"}
              />
            </div>
            <div className="close-row-container">
              {props.discountedTrialData.length - 1 === key && (
                <>
                  {key > 0 && (
                    <a
                      className="close-row"
                      onClick={(e) => removeRowHandle(e, key)}
                    >
                      <CloseRowIcon />
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
          {props.discountedTrialData.length - 1 === key && (
            <>
              {showAddIcon() && (
                <a
                  href="#"
                  className="add-row-container plan-item-add-row-container"
                  onClick={addRowHandle}
                >
                  <img
                    src={AddTagIcon}
                    className="plan-item-add-row-img"
                    alt=""
                  />
                  <span className="plan-item-add-row-text">Add New Range</span>
                </a>
              )}
            </>
          )}
        </>
      ))}
      <div className="border-container"></div>
    </div>
  );
};

export default DiscountedTrialRange;
