import React from "react";
import "./Card.css";

type CardBodyProps = {
  children: React.ReactNode;
  wrapperClass?: string;
};
export const CardBody = (props: CardBodyProps) => {
  return <div className="card-body">{props.children}</div>;
};
