import React from "react";
import { SvgIcon } from "@mui/material";

const DropDownArrowDown = (props: any) => {
  return (
    <>
      <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path d="M24 0v24H0V0z" />
          <path
            fill="#91919C"
            fill-rule="nonzero"
            d="m12 12.172 2.828-2.829 1.415 1.414L12 15l-4.243-4.243 1.415-1.414z"
          />
        </g>
      </SvgIcon>
    </>
  );
};

export default DropDownArrowDown;
