import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { NumberFormat, zeroDisplayFormat } from "../../../../../helpers/numberFormatHelper";
import { CurrencyFormType, CurrentUserValues, ObjectType } from "../../../../../types";


export const totalCalculatedAmount = (
    items: any,
    gstTaxList: any[],
    currencyId: number | undefined
) => {
    delete items["hsn_or_sac_code"];
      delete items["tds_account_id"];
      delete items["tds_id"];
      let itemInputs = items;

      // Modifing iteminput for gst tax calculation

      let gst_tax = itemInputs.gst_tax;
      let gst_details = null;
      if (gst_tax) {
        let selectedTaxArr = gstTaxList.filter(
          (tax: ObjectType) =>
            tax.id === gst_tax.tax_id && tax.tax_group === gst_tax.is_group
        );
        if (selectedTaxArr.length) {
          let selectedTax = selectedTaxArr[0] as ObjectType;

          let sgst_percentage = 0;
          let cgst_percentage = 0;
          let igst_percentage = 0;
          let cess_percentage = 0;
          let utgst_percentage = 0;

          if (!selectedTax.tax_group) {
            switch (selectedTax.tax_type) {
              case "SGST":
                sgst_percentage = selectedTax.rate;
                break;
              case "CGST":
                cgst_percentage = selectedTax.rate;
                break;
              case "IGST":
                igst_percentage = selectedTax.rate;
                break;
              case "CESS":
                cess_percentage = selectedTax.rate;
                break;
              case "UTGST":
                utgst_percentage = selectedTax.rate;
                break;
              default:
                break;
            }
          } else {
            let gst_tax_item = selectedTax.taxes;

            for (let i = 0; i < gst_tax_item.length; i++) {
              const single_gst_tax_item = gst_tax_item[i];
              switch (single_gst_tax_item.tax_type) {
                case "SGST":
                  sgst_percentage = single_gst_tax_item.rate;
                  break;
                case "CGST":
                  cgst_percentage = single_gst_tax_item.rate;
                  break;
                case "IGST":
                  igst_percentage = single_gst_tax_item.rate;
                  break;
                case "CESS":
                  cess_percentage = single_gst_tax_item.rate;
                  break;
                case "UTGST":
                  utgst_percentage = single_gst_tax_item.rate;
                  break;
                default:
                  break;
              }
            }
          }
          gst_details = {
            gst_id: gst_tax.tax_id,
            is_group: gst_tax.is_group,
            sgst_percentage: sgst_percentage,
            cgst_percentage: cgst_percentage,
            igst_percentage: igst_percentage,
            cess_percentage: cess_percentage,
            utgst_percentage: utgst_percentage,
          };
        }
      }

      itemInputs.gst_details = gst_details;
      // itemInputs.
      let itemKey: keyof typeof itemInputs;
      for (itemKey in itemInputs) {
        if (
          (itemKey === "quantity" || itemKey === "rate") &&
          (itemInputs[itemKey] === "" || itemInputs[itemKey] === null)
        ) {
          itemInputs[itemKey] = 0;
        }
        if (itemInputs[itemKey] === "") {
          itemInputs[itemKey] = null;
        }
        if (itemKey === "discount") {
          if (itemInputs[itemKey]?.discount_value == 0) {
            itemInputs[itemKey] = itemInputs[itemKey]?.discount_value;
          } else if (itemInputs[itemKey]?.discount_percentage == 0) {
            itemInputs[itemKey] = itemInputs[itemKey]?.discount_percentage;
          } else {
            itemInputs[itemKey] = Number(itemInputs[itemKey]);
          }
        }
        if (itemKey === "tds_percentage" && itemInputs[itemKey] === null) {
          itemInputs[itemKey] = 0;
        }
      }
      let itemTax = itemInputs.taxes;
      let taxInputs: any = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (
          !(item.tax_percentage === "" || item.tax_percentage === 0) &&
          !(item.tax_id === "")
        ) {
          taxInputs.push(item);
        } else if (item.tax_id !== "") {
          taxInputs.push(item);
        }
      });
      itemInputs.taxes = taxInputs;
      //Handling decimal point starting amount in tds
      itemInputs.tds_percentage = Number(itemInputs.tds_percentage);
      itemInputs.tds_amount =
        itemInputs.tds_amount === "." ? 0 : parseFloat(itemInputs.tds_amount);
      itemInputs.currency_id = currencyId ? currencyId : null;

      return itemInputs
    }

    export const taxableAmountHelper = (
        taxResponse: any,
        index: number,
        parentIndex: string,
        currencyCode?: string,
        itemFormErrors?: any[],
        orgCurrencyList?: CurrencyFormType[],
        currentUserInfo?: CurrentUserValues
    ) => {
     
        let taxes = taxResponse.item_details.taxes;
        let taxIndex = 0;
        Object.keys(taxes).map((key) => {
          let taxValue = taxes[key as keyof typeof taxes];
          if (taxValue || key) {
            $("#tax-amount-label_" + index + "_" + taxIndex + '_' + parentIndex).text(
              key +
              ": " +
              currencyCode +
              " " +
              NumberFormat(
                Number(taxValue),
                currencyCode ? currencyCode : "",
                orgCurrencyList || []
              )
            );
          } else {
            $("#tax-amount-label_" + index + "_" + taxIndex + '_' + parentIndex).text("");
          }
          if (
            itemFormErrors &&
            itemFormErrors[index]?.taxes &&
            itemFormErrors[index]?.taxes[taxIndex]?.tax_id
          ) {
            $("#tax-amount-label_" + index + "_" + taxIndex + '_' + parentIndex).text("");
          }
          ++taxIndex;
        });
        $("#taxable_amount_span_" + index + '_' + parentIndex).text(
          taxResponse.item_details.taxable_amount
            ? currencyCode +
            " " +
            NumberFormat(
              Number(taxResponse.item_details.taxable_amount),
              currencyCode ? currencyCode : "",
              orgCurrencyList || []
            )
            : currencyCode +
            " " +
            zeroDisplayFormat(
              decimalPlaceOfCurrency(
                currencyCode
                  ? currencyCode
                  : currentUserInfo?.currency_code || '',
                orgCurrencyList || []
              )
            )
        );
        if (taxResponse.item_details.tds_total) {
          $("#tds-amount-label" + index + '-' + parentIndex).text(
            "Tds: " +
            currencyCode +
            " " +
            NumberFormat(
              taxResponse.item_details.tds_total,
              currencyCode ? currencyCode : "",
              orgCurrencyList || []
            )
          );
        } else {
          $("#tds-amount-label" + index + '-' + parentIndex).text("");
        }  
    }