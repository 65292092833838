import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import useCommonData from "../../hooks/useCommon";
import { getCountryStates } from "../commonSlice";
import { customSelectStyle } from "./SelectCustomStyle";
import { LocationValues } from "../../../types";

type Props = {
  id: string;
  countryId: number | null;
  stateId: number | null;
  onChange: (e: SingleValue<string | { label: string; value: number }>) => void;
  isDisabled: boolean;
  error?: string;
  wrapperClass?: string;
  selectClass?: string;
};
function StateSelect({
  id,
  countryId,
  stateId,
  onChange,
  isDisabled,
  error,
  wrapperClass,
  selectClass,
}: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [states, setStates] = useState<LocationValues[]>([]);

  useEffect(() => {
    setStates([]);
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    if (countryId !== null || countryId !== 0) getAllCountryStates();
  }, [countryId]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [states]);

  /**
   * Fetch all states list based on country
   */
  const getAllCountryStates = async () => {
    if (countryId) {
      const responseAction = await dispatch(getCountryStates(countryId));
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setStates(response);
        } else{
          setStates([])
        }
      }
    }
  };
  return (
    <div className={wrapperClass}>
      <Select
        name="state_id"
        id={id}
        options={
          states?.length > 0
            ? states.map((state) => {
                return {
                  label: state.name,
                  value: state.id,
                };
              })
            : []
        }
        className={`state-select form-select custom-select ${
          error ? "error" : ""
        } ${selectClass}`}
        value={
          stateId == null || stateId == 0
            ? ""
            : {
                label: states?.filter((state) => {
                  return state.id == stateId;
                })[0]?.name,
                value: stateId,
              }
        }
        onChange={onChange}
        styles={customSelectStyle}
        placeholder={"Choose a state"}
        isSearchable={true}
        isDisabled={isDisabled}
      />
      <span className="error">{error}</span>
    </div>
  );
}

export default React.memo(StateSelect);
