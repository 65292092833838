import React, { useEffect } from "react";
import "../../../../../../index.css";
import "../../../../../common/components/customSelectDropDown/CustomSelectDropDown.css";
import { Link, useParams } from "react-router-dom";
import { TDSList } from "../../../../../../types";
import TdsFormContainer from "../../../../tds/components/tdsForm/TdsFormContainer";
import { useSubscriptionItemContext } from "./SubscriptionItemContext";
type Props = {
  taxType: string;
  tdsIds: number[];
  tcsIds: number[];
  index: number;
  handleTdsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  handleTotalTcsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  item: {
    tds_id: number | null;
    tds_amount: number;
    tds_rate: number;
    tds_name: string;
    tcs_id: number | null;
    tcs_amount: number;
    tcs_rate: number;
    tcs_name: string;
  };
  applyTdsAt: string;
  placeholder: string;
  setPercentage: (value: boolean) => void;
};
const SubscriptionSelectDropDown = (props: Props) => {
  const { editId } = useParams();
  const { tdsTaxes, getTdsDropDownList } = useSubscriptionItemContext();

  useEffect(() => {
    if (editId && props.item.tds_id) {
      let tdsInfo = tdsTaxes.filter(
        (tds: TDSList) => tds.id === props.item.tds_id
      )[0];
      if (tdsInfo) props.setPercentage(tdsInfo.is_percentage);
    }
  }, [props.item, tdsTaxes]);


  return (
    <>
      <div
        className={`btn-group taxcode-dropdown ${
          props.applyTdsAt === "transaction_level"
            ? "tds-tcs-dropdown-total dropup"
            : ""
        }`}
      >
        <input
          type="text"
          name="tax_id"
          className="search-dropdown dropdown-toggle"
          placeholder={props.placeholder}
          data-bs-toggle="dropdown"
          value={
            props.taxType === "TDS"
              ? props.item.tds_id
                ? props.item.tds_name
                : ""
              : props.item.tcs_id
              ? props.item.tcs_name
              : ""
          }
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          id={`tax-code-${
            props.taxType === "TDS" ? props.item.tds_id : props.item.tcs_id
          }`}
        />
        <ul className="dropdown-menu dropdown-menu-start">
          {tdsTaxes.map((tds, index) => {
                return (
                  <li
                    role="listitem"
                    onClick={() => {
                      props.handleTdsLabelChange(
                        tds.id,
                        tds.rate,
                        tds.tax_name,
                        props.index
                      );
                      props.setPercentage(tds.is_percentage);
                    }}
                    key={index}
                  >
                    <Link
                      className={`dropdown-item ${
                        props.item.tds_id === tds.id ? "active" : ""
                      }`}
                      to="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="itemLabel">{tds.tax_name}</span>
                    </Link>
                  </li>
                );
              }) }
          <li>
            <Link
              to="#"
              className="dropdown-item add-link-option"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
                <TdsFormContainer
                  id={0}
                  isEdit={false}
                  refreshList={getTdsDropDownList}
                  createdFrom="transaction_page"
                  label={"New Tds"}
                />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(SubscriptionSelectDropDown);
