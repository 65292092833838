import { Api } from "../../../constants";
import {
  get,
  post,
  put,
  remove,
  postFormData,
} from "../../../helpers/apiHelper";
import {
  InvoicePaymentValues,
  PaymentReceivedJournalListPayload,
  ReportingTagsAllocationPayload,
  TotalPaymentReceivedValues,
} from "../../../types";

/**
 * API call for listing unpaid invoices
 */
export async function getUnpaidInvoiceList(data: {
  currentOrgId: number;
  customerId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      `open-customer-invoices/list?current_organization=${data.currentOrgId}&customer_id=${data.customerId}`
  );
  return result;
}

/**
 * API call for new pr number
 */
export async function getNewPrNumber(currentOrgId: number) {
  const result = await get(
    Api.paymentReceived + `new-pr-number?current_organization=${currentOrgId}`
  );
  return result;
}

/**
 * API call for check pr number exists
 */
export async function checkPrNumberExists(data: {
  currentOrgId: number;
  prNumber: string;
  paymentReceivedId: number;
  signal?: AbortSignal;
}) {
  const result = await get(
    Api.paymentReceived +
      `pr-number-exist?current_organization=${data.currentOrgId}&pr_id=${data.paymentReceivedId}&pr_number=${data.prNumber}`,
    data.signal
  );
  return result;
}

/**
 * API call for create payment
 */
export async function createNewInvoicePayment(data: {
  values: InvoicePaymentValues;
  orgId: number;
  status: string;
}) {
  const result = await post(
    Api.paymentReceived +
      `pr/create?current_organization=${data.orgId}&pr_status=${data.status}`,
    data.values
  );
  return result;
}

/**
 * API call for Edit Invoice Payment
 */
export async function editInvoicePayment(data: {
  paymentId: number;
  values: InvoicePaymentValues;
  orgId: number;
  status: string;
  isCustomerChange: boolean;
}) {
  const result = await put(
    Api.paymentReceived +
      `pr/edit/${data.paymentId}?current_organization=${data.orgId}&pr_status=${data.status}&is_customer_change=${data.isCustomerChange}`,
    data.values
  );
  return result;
}

/**
 * API call for deleting Invoice Payment
 */
export async function deleteInvoicePayment(data: {
  deletePaymentId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.paymentReceived +
      `pr/delete/${data.deletePaymentId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to upload payment received attachments
 */
export async function paymentReceivedAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.paymentReceived + `pr-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * API to delete payment received attachments
 */
export async function deletePaymentReceivedAttachments(data: {
  prId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.paymentReceived +
      `pr-attachments/${data.prId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to get payment received list
 */
export async function paymentReceivedList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  customerList: number[];
  orgIds: number[];
  statusList: string[];
  invoiceId?: number | null;
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.paymentReceived +
    `pr/list?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId} ${
      data.invoiceId ? `&invoice_obj_id=${data.invoiceId}` : ""
    }${data.startDate ? `&start_date=${data.startDate.trim()}` : ""}${
      data.endDate ? `&end_date=${data.endDate.trim()}` : ""
    }${data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""}${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.customerList.length !== 0) {
    const customerIds = data.customerList
      .map((customerId) => {
        return `&customer_ids=${customerId}`;
      })
      .join("");
    url += customerIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

/**
 * Api to get payment details
 */
export async function paymentReceivedDetails(data: {
  prId: string;
  orgId: number;
}) {
  let result = await get(
    Api.paymentReceived +
      `pr/detail/${data.prId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for sending payment receipts
 */
export async function sendPaymentReceipt(data: {
  prId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.paymentReceived +
      `send-pr?pr_id=${data.prId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API call for getting payment received details
 */
export async function getPaymentReceivedDetails(data: {
  paymentReceivedId: number;
  orgId: number;
}) {
  const result = await get(
    Api.paymentReceived +
      `pr/detail/${data.paymentReceivedId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to get payment received pdf link
 */
export async function paymentReceivedPdfLink(data: {
  paymentReceivedId: number;
  orgId: number;
}) {
  const result = await get(
    Api.paymentReceived +
      `download-link?pr_id=${data.paymentReceivedId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete Payment received recipients
 */
export async function removePaymentReceivedRecipients(data: {
  prId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.paymentReceived +
      `pr-recipient/delete/${data.prId}?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}

/**
 * API to calculate Total Amount
 */
export async function totalAmountCalculation(data: {
  values: TotalPaymentReceivedValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.paymentReceived +
      `pr/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.paymentReceived +
      `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to delete PR allocated to an invoice.
 */
export async function deleteItemAllocation(data: {
  prId: number | null;
  itemId: number | null;
  orgId: number | null;
}) {
  const result = await remove(
    Api.paymentReceived +
      `pr-item/delete/${data.prId}/${data.itemId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to generate reporting tags for PR.
 */
export async function createJournalList(data: {
  prId: number | null;
  orgId: number | null;
  values: PaymentReceivedJournalListPayload;
}) {
  const result = await post(
    Api.paymentReceived +
      `reporting-tag/journal-generate?current_organization=${data.orgId}&pr_id=${data.prId}`,
    data.values
  );
  return result;
}

/**
 * Api to update reporting tags association for PR.
 */
export async function editReportingTagPR(data: {
  prId: number | null;
  orgId: number | null;
  values: ReportingTagsAllocationPayload;
}) {
  const result = await put(
    Api.paymentReceived +
      `reporting-tag/edit/${data.prId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api to create new refund.
 */
export async function createNewRefund(data: {
  orgId: number | null;
  values: any;
}) {
  const result = await post(
    Api.paymentReceived +
      `payment-refund/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api to refund details
 */
export async function fetchRefundDetails(data: {
  refundId: number;
  orgId: number;
}) {
  const result = await get(
    Api.paymentReceived +
      `payment-refund/detail/${data.refundId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update refund.
 */
export async function editRefund(data: {
  refundId: number;
  orgId: number;
  values: any;
}) {
  const result = await put(
    Api.paymentReceived +
      `payment-refund/edit/${data.refundId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api to delete refund.
 */
export async function deleteRefund(data: { refundId: number; orgId: number }) {
  const result = await remove(
    Api.paymentReceived +
      `payment-refund/delete/${data.refundId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to get reporting tag details of sundry allocated invoices.
 */
export async function sundryTagAllocatedInvoicesTagDetails(data: {
  invoiceIds: number[];
  orgId: number;
}) {
  let url =
    Api.salesInvoice +
    `reporting-tag/sundry-journal?current_organization=${data.orgId}`;
  if (data.invoiceIds.length !== 0) {
    const invoiceIds = data.invoiceIds
      .map((invoiceId) => {
        return `&invoice_ids=${invoiceId}`;
      })
      .join("");
    url += invoiceIds;
  }
  const result = await get(url);
  return result;
}
