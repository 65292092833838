import { useEffect } from "react";
import { ObjectType } from "../../../../types";
import { MultiEmailBox } from "..";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";

type Props = {
  formik: FormikProps<any>;
  error: string;
  onError?: (error: string) => void;
};
const EmailTo = ({ formik, error }: Props) => {
  const { editId } = useParams();

  useEffect(() => {
    if (editId) {
      formik.values.email_data.map((contact: ObjectType) => {
        formik.setFieldValue("delete_email_ids", [
          ...formik.values.delete_email_ids,
          { emailId: contact.id },
        ]);
      });
    }
  }, [formik.values.vendor_id]);

  useEffect(() => {
    formik.setFieldError("email_error", error);
  }, [error]);

  const setEmailHandler = (email: string[]) => {
    formik.setFieldValue("email_to", email);
  };

  const removeEmailHandler = (email: string) => {
    formik.values.email_data.map((contact: ObjectType) => {
      if (contact.email === email) {
        formik.setFieldValue("delete_email_ids", [
          ...formik.values.delete_email_ids,
          { emailId: contact.id },
        ]);
      }
    });
  };
  // const setEmailError = (emailError: string) => {
  //   formik.setFieldError("email_error", emailError);
  // };
  return (
    <div className={`email-reciepients-wrapper`}>
      <label htmlFor="deposit_to">Email to</label>
      <br />
      <MultiEmailBox
        onChange={setEmailHandler}
        emailList={formik.values.email_to}
        removeEmailHandler={removeEmailHandler}
        onError={(emailError: string) =>
          formik.setFieldError("email_error", emailError)
        }
        className={formik.errors.email_error || error ? "email-error" : ""}
      />
      <span className="error email_error error_email_to">
        {(formik.errors.email_error && formik.errors.email_error.toString()) ||
          error}
      </span>
    </div>
  );
};

export default EmailTo;
