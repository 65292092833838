import { FormikProps } from "formik";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import moment from "moment";
import { conversionMinDate } from "../../../../constants/constants";
import { getFormatedDate } from "../../../../../helpers/helper";

type Props = {
  formik: FormikProps<any>;
  isOpen?: boolean;
  openPicker?: () => void;
  closePicker?: () => void;
  transactionDate: string;
};
export const WriteOffDate = (props: Props) => {
  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        props.formik.setFieldValue("write_off_date", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          props.formik.setFieldValue("write_off_date", dateFormated);
        } else {
          props.formik.setFieldValue("write_off_date", "Invalid date");
        }
      }
    }
  };
  return (
    <div className={"col-12 col-lg-4 mw-325 "}>
      <label htmlFor="check_expiry" className="required">
        Write Off Date
      </label>
      <div id="date-one" className="date-selector-wrapper">
        <CustomDatepicker
          date={new Date(props.formik.values.write_off_date)}
          isOpen={props.isOpen}
          openPicker={props.openPicker}
          closePicker={props.closePicker}
          handleDate={handleDate}
          type="writeOffDate"
          error={
            props.formik.errors?.write_off_date &&
            props.formik?.touched.write_off_date
              ? "error"
              : ""
          }
          id="write_off_date"
          zIndex={1322}
          placeholder="Enter write off date"
          minDate={new Date(props.transactionDate)}
          enableFutureDate={true}
        />
      </div>
      <span className="error error_write_off_date mb-2">
        {props.formik.values?.write_off_date &&
        props.formik?.touched.write_off_date
          ? props.formik.errors?.write_off_date?.toString()
          : ""}
      </span>
    </div>
  );
};
