import { FormikProps } from "formik";
import React, { forwardRef } from "react";
import InputPrefix from "../../../../../../common/components/formPrefixInputField/InputPrefix";
import MultiExchangeRate from "../../../../../../common/components/exchangeRate/multipleExchangeRate/MutipleExchangeRate";
import { CurrencyList } from "../../../../../../../types";

type Props = {
  formik: FormikProps<any>;
  label: string;
  subLabel: string;
  prefix: string;
  name: string;
  id: string;
  value: string;
  isDisabled: boolean;
  error: string;
  handleBlur?: (name: string, amount: number, index: number) => void;
  autoFocus?: boolean;
  conversionDate: string;
  newConversionDate: string;
  conversionId: number | string;
  currencyCode: string;
  toCurrencyCode: string;
  currencyId: number | undefined;
  toCurrencyId: number;
  conversionCurrencyId: number | undefined;
  editId: string;
  setExchangeData: (values: CurrencyList) => void;
  isCurrencyChanged: boolean;
  isDateChanged: boolean;
  endAdornment?: React.ReactNode;
  byUser: boolean;
  isExchangeEditable: boolean;
  conversionRate: string;
  conversionTimestamp: string;
  placeholder?: string;
  getReverseConversionValue?: (value: CurrencyList) => void;
  isReverseConversionRequired: boolean;
  isExchangeRateHidden?: boolean;
  exchangeRateSubmitHandler?: (data: any) => void;
  exchangeRateMaxLength?: number;
};
export const PaymentAmountField = forwardRef((props: Props, ref) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  return (
    <div className="col-12 col-lg-4 mw-325 mb-2">
      <label htmlFor="amount_paid_bank_currency" className="required">
        {props.label}{" "}
        {props.subLabel && (
          <span className="sub-label">({props.subLabel})</span>
        )}
      </label>
      <InputPrefix
        prefix={props.prefix}
        inputType="text"
        name={props.name}
        id={props.id}
        className="form-control border-end-0"
        placeholder={props?.placeholder ? props?.placeholder : "0.00"}
        value={Number(props.value) !== 0 ? props.value : ""}
        disabled={props.isDisabled}
        error={props.error}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.formik.handleChange(e);
        }}
        isAmountField={true}
        onBlur={props.handleBlur}
        index={0}
        onKeyPress={handleKeyPress}
        endAdornment={props.endAdornment}
        autoFocus={props.autoFocus}
      />
      <span className="error">{props.error}</span>

      <MultiExchangeRate
        conversionDate={props.conversionDate}
        newConversionDate={props.newConversionDate}
        conversionId={props.conversionId}
        conversionRate={props.conversionRate}
        conversionTimestamp={props.conversionTimestamp}
        currencyCode={props.currencyCode}
        currencyId={props.currencyId}
        toCurrencyId={
          props.toCurrencyId ? Number(props.toCurrencyId) : undefined
        }
        toCurrencyCode={props.toCurrencyCode}
        conversionCurrencyId={props.conversionCurrencyId}
        editId={props.editId}
        setExchangeData={(values: CurrencyList) => {
          if (values) {
            props.setExchangeData({ ...values });
          }
        }}
        isCurrencyChanged={props.isCurrencyChanged}
        isDateChanged={props.isDateChanged}
        position="start"
        byUser={props.byUser}
        isExchangeRateEditable={props.isExchangeEditable}
        isReverseConversionRequired={props.isReverseConversionRequired}
        getReverseConversionValue={props.getReverseConversionValue}
        isExchangeRateHidden={props.isExchangeRateHidden}
        exchangeRateSubmitHandler={props.exchangeRateSubmitHandler}
        maxLength={props.exchangeRateMaxLength}
      />
    </div>
  );
});
