import {
  CreditApply,
  InvoiceValues,
  ProformaValues,
  TaxableAmountCalculationValues,
} from "./../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getProformaList,
  deleteProforma,
  createNewProforma,
  editProforma,
  deleteProformaItem,
  deleteProformaRecipient,
  getProformaDetails,
  getProformaNumber,
  proformaNumberExist,
  deleteProformaItemTax,
  sendProforma,
  calculateTaxableAmount,
  calculateAmount,
  proformaPdfLink,
  proformaAttachments,
  proformaAttachmentsDelete,
  transactionLimitStatus,
  transcationStatusChange,
} from "./proformaAPI";

export const proformaList = createAsyncThunk(
  "proformaList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    customerList: number[];
    orgIds: number[];
    statusList: string[];
    issueStartDate?: string;
    issueEndDate?: string;
    expiryStartDate?: string;
    expiryEndDate?: string;
  }) => {
    return await getProformaList(data);
  }
);

export const deleteProformaDetails = createAsyncThunk(
  "deleteProforma",
  async (data: { deleteProformaId: number; orgId: number }) => {
    return await deleteProforma(data);
  }
);

export const createProforma = createAsyncThunk(
  "create/proforma",
  async (data: {
    values: ProformaValues;
    status: string;
    isSendMail: boolean;
    isPrintOrDownload: boolean;
    orgId: number;
  }) => {
    return await createNewProforma(data);
  }
);

export const editProformaDetails = createAsyncThunk(
  "edit/proforma",
  async (data: {
    id: number;
    status: string;
    isSendMail: boolean;
    isPrintOrDownload: boolean;
    editProformaInputs: ProformaValues;
    orgId: number;
  }) => {
    return await editProforma(data);
  }
);

export const deleteProformaItemDetails = createAsyncThunk(
  "delete/proforma/item",
  async (data: { proformaId: number; itemId: number; orgId: number }) => {
    return await deleteProformaItem(data);
  }
);

export const deleteProformaRecipientDetails = createAsyncThunk(
  "proforma/recipient/delete",
  async (data: { proformaId: number; emailIds: number[]; orgId: number }) => {
    return await deleteProformaRecipient(data);
  }
);

export const proformaDetails = createAsyncThunk(
  "proformaDetail",
  async (data: { proformaId: number; orgId: number }) => {
    return await getProformaDetails(data);
  }
);

export const getNewProformaNumber = createAsyncThunk(
  "proforma/number",
  async (orgId: number) => {
    return await getProformaNumber(orgId);
  }
);

export const checkProformaNumber = createAsyncThunk(
  "proforma/number-exist",
  async (data: {
    proformaNumber: string;
    orgId: number;
    proformaId: number | null;
    signal?: AbortSignal;
  }) => {
    return await proformaNumberExist(data);
  }
);

export const deleteProformaItemTaxDetails = createAsyncThunk(
  "delete/proforma/item/tax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteProformaItemTax(data);
  }
);

export const attachProformaFiles = createAsyncThunk(
  "proforma/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await proformaAttachments(data);
  }
);

export const deleteProformaFile = createAsyncThunk(
  "proforma/file/delete",
  async (data: { proformaId: number; fileId: number; orgId: number }) => {
    return await proformaAttachmentsDelete(data);
  }
);

export const sendProformaData = createAsyncThunk(
  "send-proforma",
  async (data: { proformaId: number; emails: string[]; orgId: number }) => {
    return await sendProforma(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "customer/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: { values: any; orgId: number; signal: Object }) => {
    return await calculateTaxableAmount(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: { values: any; orgId: number; signal: Object }) => {
    return await calculateAmount(data);
  }
);

export const getProformaPdfLink = createAsyncThunk(
  "proforma/pdf",
  async (data: { proformaId: number; orgId: number }) => {
    return await proformaPdfLink(data);
  }
);
export const changeProformaStatus = createAsyncThunk(
  "proforma/status/change",
  async (data: {
    proforma_status: string;
    reason: string;
    proforma_id: number;
    orgId: number;
  }) => {
    return await transcationStatusChange(data);
  }
);

const initialState = {};

export const proformaSlice = createSlice({
  name: "proformaManagement",
  initialState,
  reducers: {},
});

export default proformaSlice.reducer;
