import InfoTooltip from "../../InfoTooltip";

type Props = {
  maxAdvancedFilterLimitInWords: string;
};
export const AdvanceFilterInfoTooltip = (props: Props) => {
  return (
    <InfoTooltip
      title={`You may select a maximum of ${props.maxAdvancedFilterLimitInWords} advanced filters.`}
      placement={"top-start"}
    />
  );
};
