import React from "react";

type Props = {
  children: React.ReactNode;
};
function PaymentTotalSection({ children }: Props) {
  return (
    <div className="col right-details">
      <div className="row calculation-section g-0 m-0">{children}</div>
    </div>
  );
}

export default PaymentTotalSection;
