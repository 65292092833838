import React from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { listboxCustomStyle } from "../../../../common/components/ListBoxCustomStyle";
import TcsFormContainer from "../tcsForm/TcsFormContainer";

const ListHeader = ({ refreshList }: { refreshList: () => void }) => {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let tcsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TCS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  return (
    <>
      <Box component="div" sx={listboxCustomStyle.headerBox}>
        <Grid component="h2" sx={listboxCustomStyle.h2}>
          TCS
        </Grid>
        <Grid sx={listboxCustomStyle.rightButton}>
          {tcsPermission.includes("Create") && (
            <TcsFormContainer
              id={0}
              isEdit={false}
              refreshList={refreshList}
              createdFrom="tcs_page"
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ListHeader;
