import { Api } from "../../../../constants";
import { get, post, put, remove } from "../../../../helpers/apiHelper";
import { ReportTagFormValues } from "../../../../types";

/**
 * API call for Creating Reporting Tag
 */
export async function createNewReportingTag(data: {
  values: ReportTagFormValues;
  orgId: number;
}) {
  const result = await post(
    Api.tags + `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for deleting a Reporting Tag
 */
export async function reportingTagNameUniqueness(data: {
  orgId: number;
  tagName: string;
  signal: AbortSignal;
}) {
  const result = await get(
    Api.tags +
      `/tag-name-exists?current_organization=${data.orgId}&tag_name=${data.tagName}`,
    data.signal
  );
  return result;
}
/**
 * API call for Updating Reporting Tag
 */
export async function editReportingTag(data: {
  values: ReportTagFormValues;
  orgId: number;
  id: number;
}) {
  const result = await put(
    Api.tags + `/edit/${data.id}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for Listing Reporting Tag
 */
export async function detailsReportingTags(data: {
  orgId: number;
  id: number;
}) {
  const result = await get(
    Api.tags + `/detail/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for Listing Reporting Tag
 */
export async function listReportingTags(data: {
  pageSize: number;
  pageNum: number;
  orgId: number;
}) {
  const result = await get(
    Api.tags +
      `/list?current_organization=${data.orgId}&page_num=${data.pageNum}&page_size=${data.pageSize}`
  );
  return result;
}

/**
 * API call for  Reporting Tag dropdown
 */
export async function tagOptionDropdown(data: { orgId: number }) {
  const result = await get(
    Api.tags + `/dropdown?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting a Reporting Tag
 */
export async function removeReportingTag(data: { orgId: number; id: number }) {
  const result = await remove(
    Api.tags + `/delete/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting a Reporting Tag
 */
export async function removeTagOption(data: {
  orgId: number;
  optionIds: number[];
}) {
  const result = await remove(
    Api.tags + `/options/delete?current_organization=${data.orgId}`,
    data.optionIds
  );
  return result;
}

/**
 * API call for Split amount
 */
export async function splitAmount(data: {
  orgId: number;
  splitType: string;
  transactionType: string;
  payload: any;
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.tags +
      `/split-amount?current_organization=${data.orgId}&split_type=${data.splitType}&transaction_type=${data.transactionType}`,
    data.payload,
    data.signal
  );
  return result;
}

/**
 * API call for Reporting Tag settings configuration
 */
export async function enableDisableTag(data: {
  orgId: number;
  isEnabled: boolean;
  isMandatory: boolean;
}) {
  const result = await post(
    Api.organizationHierarchy +
      `/enable-disable-tags?current_organization=${data.orgId}&is_enable=${data.isEnabled}&is_mandatory=${data.isMandatory}`
  );
  return result;
}
