import CenterModal from "../../centerModal/CenterModal";
import { ModalContext } from "../../centerModal/centerModalContext";
import { Span } from "../../htmlTags/Span";
import { AssociatedTagListPopupProps } from "../types";
import { AssociatedTagList } from "./AssociatedTagList";
import "./AssociatedTagList.css";

export const AssociatedTagListPopup = (props: AssociatedTagListPopupProps) => {
  return (
    <CenterModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <AssociatedTagList {...props} />;
          }}
        </ModalContext.Consumer>
      }
      modalTitle={""}
      buttonClass="no-appearance p-0"
      buttonContent={
        props.buttonContent ? props.buttonContent : <Span>Select Tag</Span>
      }
      isCloseButtonEnabled={true}
      isInline={true}
    />
  );
};
