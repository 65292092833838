import { CouponDefaultValue, CouponformDetailValue, CouponformValue } from "../../couponType";

export const payloadRefiner = (
  formValues: CouponDefaultValue,
  action: string
) => {
    const payload = action === 'create' ? {
        name: formValues.name,
        account_id: formValues.account_id === 0 ? null : Number(formValues.account_id),
        discount_value: Number(formValues.discount_value),
        discount_type: formValues.discount_type,
        duration_type: formValues.duration_type,
        limited_duration_unit:  formValues.limited_duration_unit === '' ? null :formValues.limited_duration_unit,
        limited_duration_value:  Number(formValues.limited_duration_value),
        valid_till: formValues.valid_till === '' ? null : formValues.valid_till,
        max_redemptions: formValues.maximum_redemptions === 0 ? -1 :Number(formValues.maximum_redemptions),
        organization_id:  formValues.organization_id
    } : {
        name: formValues.name,
        account_id: formValues.account_id === 0 ? null : Number(formValues.account_id),
        discount_value: Number(formValues.discount_value),
        discount_type: formValues.discount_type,
        duration_type: formValues.duration_type,
        limited_duration_unit:  formValues.limited_duration_unit === '' ? null :formValues.limited_duration_unit,
        limited_duration_value:  Number(formValues.limited_duration_value),
        valid_till: formValues.valid_till === '' ? null : formValues.valid_till,
        max_redemptions: (!formValues.maximum_redemptions || formValues.maximum_redemptions === 0) ? -1 :Number(formValues.maximum_redemptions),
    }
   
 
  return payload as CouponformValue;
};