import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getGstSettings,
  saveGstSettings,
  getStateTaxRates,
  getDefaultTaxPreference,
  saveDefaultTaxPreference,
  stateList,
  gstTaxList,
  taxExemptionCreation,
  taxExemptionList,
  taxExemptionEdit,
  taxExemptionRemove,
} from "./gstAPI";
import { GstSettingsValues, DefaultPreferenceValues } from "../../../types";
import { RootState } from "../../../app/store";

export const fetchGstSettings = createAsyncThunk(
  "settings/gst",
  async (data: { orgId: number }) => {
    return await getGstSettings(data);
  }
);

export const gstSettingsSave = createAsyncThunk(
  "settings/gst/save",
  async (data: { orgId: number; values: GstSettingsValues }) => {
    return await saveGstSettings(data);
  }
);

export const stateTaxRates = createAsyncThunk(
  "settings/gst/get-state-rate",
  async (data: { orgId: number; stateType: string }) => {
    return await gstTaxList(data);
  }
);

export const defaultTaxPreference = createAsyncThunk(
  "settings/gst/get-default-preference",
  async (data: { orgId: number }) => {
    return await getDefaultTaxPreference(data);
  }
);

export const updateDefaultTaxPreference = createAsyncThunk(
  "settings/gst/save-default-preference",
  async (data: { orgId: number; values: DefaultPreferenceValues }) => {
    return await saveDefaultTaxPreference(data);
  }
);

// get state list for dropdown options
export const getStateList = createAsyncThunk(
  "bills/states",
  async (data: { orgId: number }) => {
    const result = await stateList(data);
    return result;
  }
);

// get GST tax list and groups
export const getGstTaxList = createAsyncThunk(
  "list-tax-for-transactions",
  async (data: {
    stateType: string;
    orgId: number;
    is_sez?: boolean;
    transactionSource?: string | undefined;
    stateCode?: string;
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }];
    rcm_enabled?: boolean;
  }) => {
    return await gstTaxList(data);
  }
);

export const createTaxExemption = createAsyncThunk(
  "tax-exemption-creation",
  async (data: {
    organization_id: number;
    exemption_reason: string;
    description: string;
    exemption_type: "Customer" | "Item";
  }) => {
    return await taxExemptionCreation(data);
  }
);

export const getTaxExemptionList = createAsyncThunk(
  "tax-exemption-list",
  async (data: { orgId: number; pageNum: number; pageSize: number }) => {
    return await taxExemptionList(data);
  }
);

export const updateTaxExemption = createAsyncThunk(
  "tax-exemption-updation",
  async (data: {
    id: number;
    organization_id: number;
    exemption_reason: string;
    description: string;
    exemption_type: "Customer" | "Item";
  }) => {
    return await taxExemptionEdit(data);
  }
);

export const deleteTaxExemption = createAsyncThunk(
  "tax-exemption-delete",
  async (data: { orgId: number; exemptionId: number }) => {
    return await taxExemptionRemove(data);
  }
);

interface GstState {
  gstSettings: GstSettingsValues;
  defaultPreference: DefaultPreferenceValues;
}

const initialState = {
  gstSettings: {
    gst_enabled: "Yes",
    gst: "",
    business_legal_name: "",
    business_trade_name: "",
    registered_on: null,
    transaction_exist: false,
  },
  defaultPreference: {
    default_preference_enabled: "No",
    intra_state_id: null,
    inter_state_id: null,
    is_inter_state_group: false,
  },
  gstTaxList: [], // used in gst tax dropdown in item lists
  gstStateType: "", // used to populate default tax preference in gst tax dropdown in item lists
  gstTaxList_params: {
    state_type: "",
    state_code: "",
    transaction_source: "",
  },
  stateList: {},
};

export const gstSlice = createSlice({
  name: "gst",
  initialState,
  reducers: {
    clearGstTaxList: (state) => {
      state.gstTaxList = [];
      state.gstStateType = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGstSettings.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.gstSettings = payload;
        }
      })
      .addCase(defaultTaxPreference.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.defaultPreference = payload;
        }
      })
      .addCase(getGstTaxList.fulfilled, (state, action) => {
        state.gstStateType = action.meta.arg.stateType;
        state.gstTaxList_params = {
          state_type: action.meta.arg.stateType,
          state_code: action.meta.arg.stateCode
            ? action.meta.arg.stateCode
            : "",
          transaction_source: action.meta.arg.transactionSource
            ? action.meta.arg.transactionSource
            : "",
        };
        if (
          action.payload &&
          Object.keys(action.payload).length &&
          !("error" in action.payload)
        ) {
          state.gstTaxList = action.payload;
        }
      })
      .addCase(getStateList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.stateList = payload;
        }
      });
  },
});

export default gstSlice.reducer;

export const { clearGstTaxList } = gstSlice.actions;

export const gstSettingSelector = (state: RootState) => state.gst.gstSettings;
export const defaultPreferenceSelector = (state: RootState) =>
  state.gst.defaultPreference;
export const gstTaxListSelector = (state: RootState) => state.gst.gstTaxList;
export const gstStateTypeSelector = (state: RootState) =>
  state.gst.gstStateType;
export const stateListSelector = (state: RootState) => state.gst.stateList;
export const gstTaxListParamsSelector = (state: RootState) =>
  state.gst.gstTaxList_params;
