import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { listboxCustomStyle } from "../../../../common/components/ListBoxCustomStyle";
import useListData from "../../../../hooks/useListData";
import ListHeader from "../../../../common/components/list/ListHeader";
import ItemTableContainer from "./itemTable/ItemTableContainer";
import PaginationContainer from "./PaginationContainer";
import CreateItemContainer from "../createItems/CreateItemContainer";
import { InventoryItemValues } from "../../../../../types";
import useCommonData from "../../../../hooks/useCommon";
import { useAppSelector } from "../../../../../app/hooks";
import { setShowItemCreate, showItemCreateSelector } from "../../itemSlice";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

function List() {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { totalList, itemsPerPage } = useListData();
  const { itemsRolePermission } = usePermissionHandler();
  const listRef = useRef<any>([]);
  const [openCreateItemModal, setOpenCreateItemModal] = useState(false);
  const [editItemInfo, setEditItemInfo] = useState<InventoryItemValues>();
  const showCreate = useAppSelector(showItemCreateSelector);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const filterRef = useRef<any>([]);

  useEffect(() => {
    if (showCreate) onCreate();
  }, [showCreate]);

  const onCreate = () => {
    navigate("/items/create");
  };
  const onEdit = (data: InventoryItemValues) => {
    setEditItemInfo({ ...data });
  };

  const refreshList = () => {
    listRef.current.fetchItemList();
    dispatch(setShowItemCreate(false));
  };

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };
  const refreshFilter = () => {
    filterRef.current.refreshFilter();
  };

  return (
    <>
      <Box component="div" sx={listboxCustomStyle.outerBox}>
        <ListHeader
          title="Items"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Item"
          createOnClick={() => {
              navigate("/items/create");
          }}
          isFiscalYearFilterRequired={false}
          haveFilter={true}
          filterType="Items"
          applyFilter={handleApplyFilter}
          roles={itemsRolePermission}
          module="Items"
          ref={filterRef}
        />
        <div className="card-body-wrapper d-flex flex-column h-100 overflowX-hidden">
          <ItemTableContainer
            onEdit={onEdit}
            initializeFilter={refreshFilter}
            ref={listRef}
          />
          {totalList > itemsPerPage ? <PaginationContainer /> : ""}
        </div>
      </Box>
      {itemsRolePermission.includes("Create") &&
      IsFeatureEnabled("Items", currentUserInfo.disabled_feature) ? (
        <CreateItemContainer
          openModal={openCreateItemModal}
          closeModal={() => {
            setOpenCreateItemModal(false);
            refreshList();
          }}
          itemData={editItemInfo}
          currency={{
            id: currentUserInfo.organization_currency,
            code: currentUserInfo.currency_code,
          }}
        />
      ) : null}
    </>
  );
}

export default List;
