import React from "react";

import ViewmoreButton from "../../../common/components/button/ViewmoreButton";
import "./SearchButton.css";

type Props = {
  label: string;
  showMore: (e: any) => void;
};

const SearchButton = (props: Props) => {
  return (
    <button
      type="button"
      className="view-more-btn no-appearance"
      onClick={props.showMore}
    >
      <ViewmoreButton label={props.label} />
    </button>
  );
};

export default SearchButton;
