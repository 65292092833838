import { SvgIconProp } from "../../../../types";

export const OrganizationDropdownIcon = (props: SvgIconProp) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h20v20H0z" />
        <path
          d="M17.5 15.833h1.667V17.5H.833v-1.667H2.5v-12.5c0-.46.373-.833.833-.833h8.334c.46 0 .833.373.833.833v12.5h3.333V9.167h-1.666V7.5h2.5c.46 0 .833.373.833.833v7.5zM4.167 4.167v11.666h6.666V4.167H4.167zm1.666 5h3.334v1.666H5.833V9.167zm0-3.334h3.334V7.5H5.833V5.833z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
