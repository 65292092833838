import { colors } from "../../constants/colors";

const PlusIconMenuSmall = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h12v12H0z" />
        <path
          fill={colors.battleshipGrey}
          fillRule="nonzero"
          d="M5.5 5.5v-3h1v3h3v1h-3v3h-1v-3h-3v-1z"
        />
      </g>
    </svg>
  );
};
export default PlusIconMenuSmall;
