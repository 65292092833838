import { SvgIconProp } from "../../../../types";

export const VoidIconBtn = (props: SvgIconProp) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={"none"} fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5ZM9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15ZM6.39235 5.33168L12.6683 11.6077C12.5248 11.8091 12.3627 12.0013 12.1819 12.1819C12.0013 12.3627 11.8091 12.5248 11.6077 12.6683L5.33168 6.39235C5.47522 6.19094 5.63733 5.99871 5.81802 5.81802C5.99871 5.63733 6.19094 5.47522 6.39235 5.33168Z"
          fill="#747D84"
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
