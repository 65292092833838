import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Box from "@mui/material/Box";
import useCommonData from "../../../../../../hooks/useCommon";
import { ArrowDropDown } from "../../../../../../../assets/images";
import { colors } from "../../../../../../constants/colors";
import {
  OrgDropdownValues,
  OrganizationRoleValues,
} from "../../../../../../../types";
type Props = {
  index: number;
  userId: number;
  isSuperUser: boolean;
  orgDropdownValues: OrgDropdownValues[];
  item: OrganizationRoleValues;
  organizationHandler: (e: any, value: any, itemIndex: number) => void;
};
const OrganizationSelectors = (props: Props) => {
  const { currentUserInfo } = useCommonData();

  const isOrgDataAvailable =
    props.orgDropdownValues.length > 0 && props.orgDropdownValues[0].label;

  const getOrg = (item: any) => {
    const org = props.orgDropdownValues.find((opt) => opt.id == item.org_id);
    return org;
  };
  return (
    <div
      className="form-outline mb-2 position-relative"
      style={
        props.userId > 0 &&
        props.userId === currentUserInfo.id &&
        (props.isSuperUser ? (props.index === 0 ? true : false) : true)
          ? { pointerEvents: "none", opacity: "0.7" }
          : {}
      }
    >
      <label className="form-label mb-0 required" htmlFor="role">
        Organization
      </label>
      {isOrgDataAvailable && (
        <Autocomplete
          disablePortal
          popupIcon={<img src={ArrowDropDown} alt="arrow-drop-down" />}
          id="org_autocomplete"
          PopperComponent={(props) => (
            <Popper
              {...props}
              placement="bottom-start"
              sx={{
                width: "300px !important",
                padding: "0px !important",
                "margin-top": "4px !important",
                "border-radius": "6px !important",
                "-webkit-box-shadow": `0 2px 6px 0 ${colors.black_shadow1} !important`,
                "box-shadow": `0 2px 6px 0 ${colors.black_shadow1} !important`,
                border: `solid 1px ${colors.newWhisper} !important`,
                "background-color": `${colors.white} !important`,
              }}
            />
          )}
          options={props.orgDropdownValues}
          disableClearable
          value={getOrg(props.item)}
          getOptionLabel={(option) => option.label}
          noOptionsText={"No organizations found"}
          onChange={(event, value) => {
            props.organizationHandler(event, value, props.index);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Choose organization" />
            // inputProps={{ className: "error MuiAutocomplete-input" }}
            // )
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);
            return (
              <Box
                component="li"
                {...props}
                sx={{
                  borderBottom: `1px solid ${colors.approxWhisper} !important`,
                  padding: "8px 0 !important",
                  opacity: "1 !important",
                  "&:last-child": {
                    borderBottom: "0 !important",
                  },
                  "&.Mui-focused": {
                    backgroundColor: `${colors.whisper} !important`,
                  },
                  "&:hover": {
                    backgroundColor: `${colors.whisper} !important`,
                  },
                  "&:focus": {
                    backgroundColor: `${colors.whisper} !important`,
                  },
                  "&:active": {
                    backgroundColor: `${colors.whisper} !important`,
                  },
                  '&[aria-selected="true"]': {
                    backgroundColor: `${colors.whisper} !important`,
                  },
                }}
              >
                <div className="org-option">
                  <div className="org-label">
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: part.highlight
                            ? colors.blanchedAlmond
                            : "",
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                  <div className="org-desc">{option.desc}</div>
                </div>
              </Box>
            );
          }}
        />
      )}
    </div>
  );
};

export default OrganizationSelectors;
