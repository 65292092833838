import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { paymentMadeDetails } from "../paymentsMadeSlice";

export const useFetchPMDetails = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const getPMDetails = async (editId: number) => {
    dispatch(setLoaderState(true));

    const responseAction = await dispatch(
      paymentMadeDetails({
        paymentMadeId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
    return response;
  };
  return { getPMDetails };
};
