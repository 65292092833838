import SettingsIcon from "../../../../assets/images/settings-icon-blue-bg.svg";
import TaxForm from "./TaxForm";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import { CreateTaxRateValues } from "../../../../../types";

type Props = {
  taxId: number;
  taxIdReset: () => void;
  organizationId: number;
  handleModalClose: () => void;
  isAccountDeleted: boolean;
  className?: string;
  item?: CreateTaxRateValues;
  refreshList: () => void;
  children: React.ReactNode;
};
export const TaxModal = ({
  taxId,
  taxIdReset,
  organizationId,
  handleModalClose,
  isAccountDeleted,
  className,
  item,
  refreshList,
  children,
}: Props) => {
  const ButtonComponent = () => {
    return <>{children}</>;
  };
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;

            return (
              <TaxForm
                taxId={taxId}
                taxIdReset={taxIdReset}
                organizationId={organizationId}
                handleModalClose={() => {
                  handleModalClose();
                  onCloseModal();
                }}
                isAccountDeleted={isAccountDeleted}
                item={item ? item : undefined}
                refreshList={refreshList}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ButtonComponent />}
      modalTitle={taxId > 0 ? "Edit Tax" : "Add New Tax"}
      buttonClass={className ? className : "no-appearance w-100"}
      wrapperClass="tax-edit-side-modal w-100"
    />
  );
};
