import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import * as React from "react";
import DropDownArrow from "../../../../assets/images/dropdwon-arrow-down.svg";
import { ErrorToaster } from "../../../../helpers/toastHelper";
import {
  CheckedBox,
  ObjectType,
  ReportTagFormValues,
  ReportTagOptions,
} from "../../../../types";
import "./reportingTagDropDown.css";
import { message } from "../../../constants/messages";
import useCommonData from "../../../hooks/useCommon";
import { ReportingTagFormModal } from "../../../home/reportingTags/reportingTagForm/ReportingTagFormModal";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { setReportingTagListAction } from "../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";

type Props = {
  data: ReportTagFormValues[];
  checked: CheckedBox[];
  setChecked: any;
  error?: string;
  maxTags?: number | null;
  maxOptions?: number | null;
  isMutable?: boolean;
  module?: string;
  editData?: number[];
  disable?: boolean;
};
export const ReportTagDropDown = ({
  data,
  checked,
  setChecked,
  error,
  maxTags,
  maxOptions,
  isMutable,
  module,
  editData,
  disable,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [reportingTagList, setReportingTagList] = React.useState<
    ReportTagFormValues[]
  >([]);
  const [noOftags, setNoOfTags] = React.useState<number>(0);
  const sxPopover = {
    boxShadow: "0px 0px 0px 0px",
  };
  const [tagsLimit, setTagsLimit] = React.useState<boolean>(false);
  const [optionsLimit, setOptionsLimit] = React.useState<boolean>(false);
  const { currentUserInfo, dispatch } = useCommonData();
  const { reportinTagsPermission } = usePermissionHandler();

  React.useEffect(() => {
    let val = 0;
    checked.map((item: CheckedBox) => {
      val = val + item.optionIDs.length;
    });
    setNoOfTags(val);
  }, [checked]);
  React.useEffect(() => {
    setReportingTagList([...data]);
    if (editData && editData.length > 0) {
      const selectedIds = data
        .map((tag) => {
          const optionIDs = tag.options
            .filter((option) => editData.includes(option.id as number))
            .map((option) => option.id);

          return optionIDs.length > 0
            ? { tagId: tag.id, optionIDs: optionIDs }
            : null;
        })
        .filter((item) => item !== null);
      setChecked(selectedIds as CheckedBox[]);
    }
  }, [data]);
  const searchTags = (e: any) => {
    const result = data.filter((parent) => {
      if (parent.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        return parent;
      }
      const childMatch = parent.options.some((child) =>
        child.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      return childMatch;
    });
    setReportingTagList(result);
  };
  const handlePopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setReportingTagList([...data]);
  };

  const getSelectedTagIds = (checkedList: CheckedBox[]) => {
    let selectedTagIds = checkedList
      .filter((check: CheckedBox) => check.optionIDs.length > 0)
      .map((tag: CheckedBox) => tag.tagId);
    return selectedTagIds;
  };
  const handleChangeParent = (item: ReportTagFormValues) => {
    const parentId = item.id;
    if (!parentId) return;

    const currentIndex = checked.findIndex(
      (option: CheckedBox) => option.tagId === parentId
    );

    // validation starts
    let selectedTagIds = getSelectedTagIds(checked);

    if (isMutable === false) {
      ErrorToaster(message(module).reportingTag.notMutable);
      return;
    } else if (
      !selectedTagIds.includes(parentId) &&
      maxTags &&
      selectedTagIds.length >= maxTags
    ) {
      ErrorToaster(message(maxTags).reportingTag.maxTagLimit);
      return;
    } else if (maxOptions && item.options.length > maxOptions) {
      ErrorToaster(message(maxOptions).reportingTag.maxOptionLimit);
      return;
    }
    // validation ends

    if (currentIndex !== -1) {
      if (checked[currentIndex].optionIDs.length === item.options.length) {
        setChecked((prevState: CheckedBox[]) => [
          ...prevState.slice(0, currentIndex),
          ...prevState.slice(currentIndex + 1),
        ]);
      } else {
        setChecked((prevState: CheckedBox[]) => [
          ...prevState.slice(0, currentIndex),
          {
            tagId: parentId,
            optionIDs: item.options.map(
              (subItem: ReportTagOptions) => subItem.id
            ),
          },
          ...prevState.slice(currentIndex + 1),
        ]);
      }
    } else {
      setChecked((prevState: CheckedBox[]) => [
        ...prevState,
        {
          tagId: parentId,
          optionIDs: item.options.map(
            (subItem: ReportTagOptions) => subItem.id
          ),
        },
      ]);
    }
  };
  const handleChangeChild = (
    item: ReportTagFormValues,
    subItem: ReportTagOptions
  ) => {
    const parentId = item.id;
    if (!parentId) return;

    const childId = subItem.id;

    // validation starts
    let selectedTagIds = getSelectedTagIds(checked);

    if (isMutable === false) {
      ErrorToaster(message(module).reportingTag.notMutable);
      return;
    } else if (limitReachedChild(item) && !isCheckedChild(item, subItem)) {
      ErrorToaster(message(maxOptions).reportingTag.maxOptionLimit);
      return;
    } else if (
      !selectedTagIds.includes(parentId) &&
      maxTags &&
      selectedTagIds.length >= maxTags
    ) {
      ErrorToaster(message(maxTags).reportingTag.maxTagLimit);
      return;
    }
    // child validation ends

    setChecked((prevData: CheckedBox[]) => {
      const currentIndex = prevData.findIndex(
        (option: CheckedBox) => option.tagId === parentId
      );
      if (currentIndex != -1) {
        const previousData = JSON.parse(JSON.stringify(prevData));
        const updateParent = { ...previousData[currentIndex] };
        const childIndex = updateParent.optionIDs.indexOf(childId as number);
        if (childIndex !== -1) {
          updateParent.optionIDs.splice(childIndex, 1);
        } else {
          updateParent.optionIDs.push(childId as number);
        }
        return [
          ...prevData.slice(0, currentIndex),
          updateParent,
          ...prevData.slice(currentIndex + 1),
        ];
      } else {
        return [
          ...prevData,
          {
            tagId: parentId,
            optionIDs: [childId],
          },
        ];
      }
    });
  };
  const isChecked = (item: ReportTagFormValues) => {
    const parentId = item.id;
    const currentIndex = checked.findIndex(
      (option: CheckedBox) => option.tagId === parentId
    );
    if (currentIndex !== -1) {
      if (checked[currentIndex].optionIDs.length === item.options.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const isCheckedChild = (
    item: ReportTagFormValues,
    subItem: ReportTagOptions
  ) => {
    const parentId = item.id;
    const childId = subItem.id;
    const currentIndex = checked.findIndex(
      (option: CheckedBox) => option.tagId === parentId
    );
    if (currentIndex !== -1) {
      if (checked[currentIndex].optionIDs.includes(childId as number)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const isIntermediateChecked = (item: ReportTagFormValues) => {
    const parentId = item.id;
    const currentIndex = checked.findIndex(
      (option: CheckedBox) => option.tagId === parentId
    );
    if (
      checked[currentIndex]?.optionIDs.length < item.options.length &&
      checked[currentIndex]?.optionIDs.length > 0
    ) {
      return true;
    }
    return false;
  };

  const limitReachedChild = (item: ReportTagFormValues) => {
    let countOfOptions = 0;
    checked.map((sub) => {
      if (sub.tagId == item.id) {
        countOfOptions = sub.optionIDs.length;
      }
    });
    if (maxOptions && countOfOptions + 1 > maxOptions) {
      return true;
    }
    return false;
  };

  const setReportingTagsList = (newTagsList: ObjectType[]) => {
    dispatch(setReportingTagListAction(newTagsList));
  };

  return (
    <Box className="reporting-tags-dropdown">
      <TextField
        name="repoting_tags"
        id="reporting_tags"
        variant="outlined"
        className={`textFieldMui ${error ? "error" : ""}  ${
          disable ? "disable" : ""
        }`}
        value={
          noOftags !== 0
            ? noOftags === 1
              ? `${noOftags} tag selected`
              : `${noOftags} tags selected`
            : ""
        }
        placeholder="Reporting Tags"
        onClick={handlePopoverToggle}
        InputProps={{
          endAdornment: <img src={DropDownArrow} />,
          readOnly: true,
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className="popoverElement"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: "reportingtag-paper" }}
        onClose={() => setAnchorEl(null)}
        id="reportingtag-popover"
        PaperProps={{
          sx: {
            width: anchorEl ? `${anchorEl.clientWidth}px !important` : "",
          },
        }}
      >
        <Box className="reporting-tags-dropdown">
          <TextField
            placeholder="Search"
            variant="outlined"
            className="textFieldMui"
            onChange={searchTags}
          />
          {currentUserInfo.is_tags_enabled &&
            reportinTagsPermission.includes("Create") && (
              <ReportingTagFormModal
                setReportingTagsList={setReportingTagsList}
                buttonClass="add-new-reporting-tag-button"
                wrapperClass="w-100 modal-from-reporting-tag-dropdown"
              />
            )}

          {reportingTagList.length > 0 ? (
            reportingTagList.map((item: ReportTagFormValues, index: number) => (
              <Box
                key={index}
                sx={{ display: "flex", flexDirection: "column", ml: 3 }}
              >
                <FormControlLabel
                  label={item.name}
                  control={
                    <Checkbox
                      className={`checkbox chekBoxlabel ${
                        isChecked(item)
                          ? "checkBoxChecked"
                          : isIntermediateChecked(item)
                          ? "checkBoxPartiallyChecked"
                          : ""
                      }`}
                      checkedIcon={<></>}
                      icon={<></>}
                      indeterminateIcon={<></>}
                      checked={isChecked(item)}
                      indeterminate={isIntermediateChecked(item)}
                      onChange={() => {
                        handleChangeParent(item);
                      }}
                    />
                  }
                />
                <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                  {item.options.map(
                    (subItem: ReportTagOptions, subIndex: number) => (
                      <FormControlLabel
                        label={subItem.name}
                        key={subIndex}
                        control={
                          <Checkbox
                            className={`checkbox chekBoxlabel ${
                              isCheckedChild(item, subItem)
                                ? "checkBoxChecked"
                                : ""
                            }`}
                            checkedIcon={<></>}
                            icon={<></>}
                            checked={isCheckedChild(item, subItem)}
                            onChange={() => {
                              handleChangeChild(item, subItem);
                            }}
                          />
                        }
                      />
                    )
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <div className="no-options-label">no options</div>
          )}
        </Box>
      </Popover>
      {error && <span className="errorText">{error}</span>}
    </Box>
  );
};
