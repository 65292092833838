import "../styles/DoughnutGraph.css";
import { Doughnut } from "react-chartjs-2";
import { ObjectType } from "../../../../types";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { orgCurrencyListSelector } from "../../../common/commonSlice";
import { InfoIconImg, RightArrowBlue } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";
import { currentModule, setListFilters } from "../../../../appSlice";
import { getStatusIds } from "../../../../helpers/statusFilterHelper";
import {useMediaQuery, useMediaQueries} from '@react-hook/media-query'
import CustomTooltips from "../../../common/components/Tooltip";

type Props = {
  title: string;
  titleInfo: string | React.ReactNode;
  subTitle: string;
  type: string;
  data: ObjectType;
  currencySymbol: string;
  onClickUnpaidTransactions: () => void;
};

export default function DoughnutGraph(props: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const mobDevices = useMediaQuery('(max-width: 767px)');

  const data = {
    datasets: [
      {
        data: [props.data.currentPercentage, props.data.overduePercentage],
        backgroundColor: [
          props.data.backgroundColors[0],
          props.data.backgroundColors[1],
        ],
        borderColor: [
          props.data.backgroundColors[0],
          props.data.backgroundColors[1],
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    cutoutPercentage: 60,
    tooltips: {
      enabled: false,
    },
  };
  const currentBg = {
    background: props.data.backgroundColors[0],
  };
  const overdueBg = {
    background: props.data.backgroundColors[1],
  };

  /**
   * When click on "current" or "overview" links, redirect the page to bills with required filters
   */
  const showTransactions = (statusArray: string[]) => {
    const statusIds = getStatusIds(statusArray);
    if (props.type === "invoice") {
      dispatch(currentModule("Invoices"));
      dispatch(
        setListFilters({
          Organizations: [],
          Customers: [],
          Status: statusIds,
        })
      );
      navigate("/invoice", { state: { isDashboard: true } });
    }
    if (props.type === "bill") {
      dispatch(currentModule("Bills"));
      dispatch(
        setListFilters({
          Organizations: [],
          Vendors: [],
          Status: statusIds,
        })
      );
      navigate("/bill", { state: { isDashboard: true } });
    }
  };

  return (
    <>
      <div className="graph-container pie-container dashboard-half-box">
        <div className="legend-section left-section">
          <div className="header-wrapper">
            <h3>{props.title}</h3>
            <CustomTooltips placement={mobDevices? "top-end" :"right"} title={props.titleInfo}>
              <a
                href="#"
                className="info-btn position-relative"
                onClick={(e) => e.preventDefault()}
              >
                <img src={InfoIconImg} width="16" alt="info icon" />
              </a>
            </CustomTooltips>
          </div>
          <div className="row g-0">
            <div className="col p-0">
              <div className="tot-summary">
                <a
                  href="#"
                  className="title-link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onClickUnpaidTransactions();
                  }}
                >
                  <span className="title">
                    {props.subTitle + " (" + props.data.noOfTotalUnpaid + ")"}
                  </span>
                  <img
                    src={RightArrowBlue}
                    alt={`navigate to ${props.subTitle}`}
                    width={12}
                    height={12}
                  />
                </a>
                <a
                  href="#"
                  className="amount-link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onClickUnpaidTransactions();
                  }}
                >
                  <p>
                    {props.currencySymbol}{" "}
                    {NumberFormat(
                      props.data.total,
                      props.currencySymbol,
                      orgCurrencyList
                    )}
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col p-0">
              <ul className="legends-list">
                <li>
                  <span className="current">
                    <span className="legend-color" style={currentBg}></span>
                    <a
                      href="#"
                      className="title-link"
                      onClick={(e) => {
                        e.preventDefault();
                        showTransactions(["Open", "Partially Paid"]);
                      }}
                    >
                      {"Open" + " (" + props.data.noOfCurrent + ")"}
                      <img
                        src={RightArrowBlue}
                        alt={`navigate to ${props.subTitle}`}
                        width={12}
                        height={12}
                      />
                    </a>
                  </span>
                  <div className="sum-amnt">
                    {props.currencySymbol}{" "}
                    {NumberFormat(
                      props.data.current,
                      props.currencySymbol,
                      orgCurrencyList
                    )}
                  </div>
                </li>
                <li>
                  <span className="overdue">
                    <span className="legend-color" style={overdueBg}></span>
                    <a
                      href="#"
                      className="title-link"
                      onClick={(e) => {
                        e.preventDefault();
                        showTransactions(["Overdue"]);
                      }}
                    >
                      {"Overdue" + " (" + props.data.noOfOverdue + ")"}
                      <img
                        src={RightArrowBlue}
                        alt={`navigate to ${props.subTitle}`}
                        width={12}
                        height={12}
                      />
                    </a>
                  </span>
                  <div className="sum-amnt">
                    {props.currencySymbol}{" "}
                    {NumberFormat(
                      props.data.overDue,
                      props.currencySymbol,
                      orgCurrencyList
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="graph-section right-section">
          <div className="graph-wrapper" id="pie-graph-wrapper">
            <div className="doughnut-outer-container">
              <Doughnut
                width={176}
                height={176}
                data={data}
                options={options}
              />
              <div className="inner-pie-container">
                <div className="inner-round"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
