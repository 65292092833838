import React from 'react';
import "../../../../index.css";
import "./Button.css";

type Props = {
    label: string;
}

const ViewmoreButton = (props:Props) => {
  return (
    <>
         <span className="blue-button-text">{props.label}</span>
         <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <path d="M18 0v18H0V0z"/>
                <path fill="#0D5AE5" fill-rule="nonzero" d="m9 9.129 2.121-2.122 1.061 1.06L9 11.25 5.818 8.068l1.061-1.06z"/>
            </g>
        </svg>
    </>
  )
}

export default ViewmoreButton;

