import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../../common/components/htmlTags/Form";
import { jvInitialValues } from "../../components/createJournalVoucher/StateInitialization";
import { journalList } from "../../journalVoucherSlice";
import { useJVFormSubmission } from "../hooks/useFormSubmission";
import { useJVFormIntitialization } from "../hooks/useJVFormInitialization";
import { JournalVoucherBasicDetails } from "./journalVoucherBasicDetails/JournalVoucherBasicDetails";
import { JournalVoucherFileUpload } from "./journalVoucherFileUpload/JournalVoucherFileUpload";
import { JournalVoucherValidation } from "./journalVoucherFormValidation/JournalVoucherFormValidation";
import { JournalSendEmail } from "./journalVoucherSendEmail/JournalSendEmail";
import useCommonData from "../../../../hooks/useCommon";
import JournalVoucherItemDetails from "./journalVoucherItemDetails/JournalItemMapping";
import JournalVoucherFormFooter from "./journalFormFooter/JournalVoucherFormFooter";
import DeleteJournal from "../Delete";

export const JournalVoucherForm = () => {
  const { editId } = useParams();
  const { dispatch, currentUserInfo } = useCommonData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let replace = searchParams.get("replace");
  const [initialValues, setInitialValues] = useState(jvInitialValues);
  const { createJournalSubmit, updateJournalSubmit } = useJVFormSubmission();
  const { fetchJVDetails } = useJVFormIntitialization();
  useEffect(() => {
    if (editId) {
      fetchJVDetails(setInitialValues);
    }
  }, [editId]);
  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: JournalVoucherValidation,
    onSubmit: (values) => {
      if (editId) {
        updateJournalSubmit(
          values,
          formik.values.jv_status,
          formik.values.deleted_items,
          formik.values.delete_email_ids
        );
      } else {
        createJournalSubmit(
          values,
          formik.values.jv_status,
          formik.values.action
        );
      }
    },
  });
  const customFieldRef = useRef<any>();
  const handleJVFormSubmit = async (status: string, action: string) => {
    const allFields = Object.keys(formik.values);
    const touchedFields: { [key: string]: boolean } = {}; // Explicitly define the type
    allFields.forEach((fieldName) => {
      touchedFields[fieldName] = true;
    });
    formik.setTouched(touchedFields);
    formik.setFieldValue("jv_status", status);
    formik.setFieldValue("action", action);
    // validating forms
    await customFieldRef.current.handleSubmit();
    formik.validateForm();
    if (
      Object.keys(formik.errors).length > 0 ||
      customFieldRef.current.errors
    ) {
      return;
    } else if (
      Object.keys(formik.errors).length === 0 &&
      !customFieldRef.current.errors
    ) {
      formik.submitForm();
    }
  };
  const fetchJournalListAfterDelete = async () => {
    const responseAction = await dispatch(
      journalList({
        page: 1,
        itemsPerPage: 10,
        dateSortOrder: "ASC",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        tagList: [],
      })
    );
    replace ? navigate(-2) : navigate(-1);
  };
  return (
    <Card>
      <CardHeader transactionNumber={editId} isBackButtonEnabled={true}>
        {editId ? "Edit Journal Entry" : "Create Journal Entry"}
      </CardHeader>
      <CardBody>
        <Form
          name="add_invoice_form"
          id="add_invoice_form"
          className="journal-voucher-form journal-voucher-form-wrapper add-module-item-form"
          tabIndex={-1}
          // onSubmit={formik.handleSubmit}
        >
          <JournalVoucherBasicDetails
            formik={formik}
            customFieldRef={customFieldRef}
          />
          <JournalVoucherItemDetails
            formik={formik}
            formErrors={formik.errors}
            currencyCode={formik.values.currency_code}
            currencyId={Number(formik.values.currency_id)}
            handleSetAccountAmount={() => {}}
            itemFormErrors={[]}
            itemFormValues={formik.values.item_details as any}
            dateTriggerFlag={0}
            currencyTriggerFlag={6}
            organizationId={currentUserInfo.organization_id}
          />
          <JournalVoucherFileUpload formik={formik} />
          <JournalSendEmail formik={formik} emailError="" />
          <JournalVoucherFormFooter
            onClickDelete={() => {}}
            onClickSubmit={handleJVFormSubmit}
            editId={editId}
            isError={false}
            isSubmit={true}
            accountAmountExist={formik.values.is_amount_equal}
            action=""
            journalVoucherStatus={formik.values.jv_status}
            journalFormValues={formik.values as any}
            formik={formik}
          />
        </Form>
      </CardBody>
      <DeleteJournal
        deleteJournalId={Number(editId)}
        deleteJournalItemId={formik.values.journal_voucher_number}
        page={1}
        itemsPerPage={10}
        dateSortOrder={"ASC"}
        refreshJournals={fetchJournalListAfterDelete}
        organizationId={currentUserInfo.organization_id}
      />
    </Card>
  );
};
