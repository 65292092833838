import React from "react";
import { ObjectType } from "../../../../types";

export default function AddressSection(props: { addressData: ObjectType }) {
  return (
    <>
      {props.addressData.address ? (
        <>
          <br />
          <span>{props.addressData.address}</span>
        </>
      ) : (
        ""
      )}
      {props.addressData.city || props.addressData.state_name ? <br /> : ""}
      {props.addressData.city ? (
        <>
          <span>
            {props.addressData.city}
            {props.addressData.state_name ? "," : ""}
          </span>
        </>
      ) : (
        ""
      )}
      {props.addressData.state_name ? (
        <span>{props.addressData.state_name}</span>
      ) : (
        ""
      )}

      {props.addressData.country_name || props.addressData.zip_code ? (
        <br />
      ) : (
        ""
      )}
      {props.addressData.country_name ? (
        <>
          <span>
            {props.addressData.country_name}
            {props.addressData.zip_code ? "-" : ""}
          </span>
        </>
      ) : (
        ""
      )}
      {props.addressData.zip_code && Number(props.addressData.zip_code) !== 0 ? (
        <>
          <span>{props.addressData.zip_code}</span>
        </>
      ) : (
        ""
      )}
      <br />
    </>
  );
}
