import React from "react";
import TableContainer from "../../../../../../common/components/table/TableContainer";
import { TableData } from "../../../../../../../types";
type Props = {
  columns: any[];
  data: TableData[];
  sortHandler: (e: string) => void;
};
const RoleTable = (props: Props) => {
  return (
    <div className="card-body">
      <div id="role_table_wrapper">
        <TableContainer
          id="role_table"
          className="table table-stripe fixed-table w-100"
          columns={props.columns}
          data={props.data}
          url={"/roles"}
          sortHandler={props.sortHandler}
          editPermission={false}
          isDetailViewExist={false}
        >
          <tr className="odd">
            <td
              valign="top"
              colSpan={6}
              className="text-center dataTables_empty"
            >
              No data available in table
            </td>
          </tr>
        </TableContainer>
      </div>
    </div>
  );
};

export default RoleTable;
