import { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  currentUserSelector,
  getOrganizationListForReport,
  getOrganizationListOfUser,
  reportOrgSelector,
} from "../../../common/commonSlice";
import { setLoader } from "../ReportSlice";
import { colors } from "../../../constants/colors";

export default function OrganizationDropDown(props: any) {
  const dispatch = useAppDispatch();
  const userOrganization = useAppSelector(reportOrgSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [currentOrganizationId, setCurrentOrganizationId] = useState(0);
  const [currentOrganizationName, setCurrentOrganizationName] = useState("");
  const [organizationId, setOrganizationId] = useState(currentOrganizationId);
  const [orgName, setOrgName] = useState("");
  useEffect(() => {
    setCurrentOrganizationId(
      props.selectedOrg ? props.selectedOrg : currentUserInfo.organization_id
    );
    setOrganizationId(currentUserInfo.organization_id);
  }, [currentUserInfo, props.selectedOrg]);

  useEffect(() => {
    if (organizationId && organizationId !== 0) {
      getUserOrganizations();
      getUserOrganizationsForHeader();
    }
  }, [organizationId]);

  useEffect(() => {
    userOrganization
      ? userOrganization.map((org) => {
          if (org.id === currentOrganizationId) {
            setCurrentOrganizationName(org.name);
            setOrgName(org.name);
          }
        })
      : setCurrentOrganizationName("");
  }, [currentOrganizationId, userOrganization]);

  const getUserOrganizations = async () => {
    dispatch(setLoader(true));
    await dispatch(getOrganizationListForReport({ orgId: organizationId }));
    dispatch(setLoader(false));
  };

  const switchOrganizationHandler = (id: number) => {
    setCurrentOrganizationId(id);
    let selectedOrganization = userOrganization.filter((org) => org.id === id);
    props.fetchDependencies(id, selectedOrganization[0].currency_id);
  };

  useEffect(() => {
    const org = userOrganization.filter(
      (org, index) => org.id === props.selectedOrg
    )[0]?.name;
    setOrgName(org);
  }, [props.selectedOrg]);

  /**
   * Organization list to display list on header (in case of create or delete in sub organization)
   */
  const getUserOrganizationsForHeader = async () => {
    await dispatch(getOrganizationListOfUser(currentUserInfo.organization_id));
  };

  return (
    <>
      <div className="btn-group organization-dropdwon">
        <button
          type="button"
          className={`${props.error} btn dropdown-toggle `}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={props.orgDisable === true ? true : false}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 46 46"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h46v46H0z" />
              <path
                d="M40.25 36.417h3.833v3.833H1.917v-3.833H5.75V7.667c0-1.059.858-1.917 1.917-1.917h19.166c1.059 0 1.917.858 1.917 1.917v28.75h7.667V21.083h-3.834V17.25h5.75c1.059 0 1.917.858 1.917 1.917v17.25zM9.583 9.583v26.834h15.334V9.583H9.583zm3.834 11.5h7.666v3.834h-7.666v-3.834zm0-7.666h7.666v3.833h-7.666v-3.833z"
                fill={colors.slate}
                fillRule="nonzero"
              />
            </g>
          </svg>
          <span className="oraganization">{orgName}</span>
        </button>
        <ul className="dropdown-menu dropdown-menu-start">
          {userOrganization?.length < 2 ? (
            <li key={"org" + -1}>
              <a
                className="dropdown-item active"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="organization">{orgName}</span>
                <span className="org-location">{orgName}</span>
              </a>
            </li>
          ) : userOrganization ? (
            userOrganization?.map((organization, index) => {
              return (
                <li
                  onClick={() => switchOrganizationHandler(organization.id)}
                  key={"org" + index}
                >
                  <a
                    className={
                      organization.id === currentOrganizationId
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="organization">{organization.name}</span>
                    {organization.parent !== organization.name && (
                      <span className="org-location">
                        {organization.parent}
                      </span>
                    )}
                  </a>
                </li>
              );
            })
          ) : null}
        </ul>
      </div>
    </>
  );
}
