import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import { customersList, deleteCustomerDetails } from "../customerSlice";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteCustomerId: number;
  deleteCustomerName: string;
  page: number;
  itemsPerPage: number;
  customerName: string;
  refreshList: () => void;
  organizationId: number;
};
const DeleteCustomer = (props: Props) => {
  const dispatch = useAppDispatch();

  const isDeletedRef = useRef(false);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteCustomerId]);

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteCustomerDetails({
          id: props.deleteCustomerId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      $("#deleteMessageModal").modal("hide");
      props.refreshList();
      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (
          Object.keys(deleteRoleResponse).length &&
          !("error" in deleteRoleResponse)
        ) {
          isDeletedRef.current = true;
          
          $(".close-btn").click();
          // await dispatch(
          //   customersList({
          //     page: props.page,
          //     itemsPerPage: props.itemsPerPage,
          //     customerName: props.customerName,
          //     orgId: props.organizationId,
          //     orgIds:[]
          //   })
          // );
          toast.success("Customer deleted successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          const errorDetails = deleteRoleResponse.error.detail;
          let errorMsg = "Delete customer action failed!";
          if (Array.isArray(errorDetails) && errorDetails.length) {
            for (const error of errorDetails) {
              if (error.type === "value_error.invoice_exists") {
                errorMsg =
                  "Unable to delete customer. Customer has transactions posted.";
              }
            }
          }
          $(".close-btn").click();
          toast.error(errorMsg, {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} width="16" height="14" alt="error-img" />
            ),
            toastId: "delete-customer-failed-error",
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
        }
      }
    }
    else{
      $("#deleteMessageModal").modal("hide");
      props.refreshList();
    }
  };
  return (
    <DeleteModal
      heading="Delete Customer!"
      subHeading="Are you sure you want to delete the customer "
      transactionName={props.deleteCustomerName}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteCustomer;
