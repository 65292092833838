import React from "react";
import Skeleton from "@mui/material/Skeleton";

import "./TableSkeletonLoader.css";

type Props = {
    colCount:number;
    rowCount: number;
}

const TableSkeletonLoader = ({ colCount, rowCount }: Props) => {
    
    // Function to generate skeleton rows and columns
    const generateTableSkeleton = (rowCount: number, colCount: number) => {
      const rows = [];
      for (let i = 0; i < rowCount; i++) {
        const columns = [];
        for (let j = 0; j < colCount; j++) {
          columns.push(
            <td key={`col-${j}`}>
              <Skeleton variant="rectangular" width="100%" height={30} />
            </td>
          );
        }
        rows.push(<tr key={`row-${i}`}>{columns}</tr>);
      }
      return rows;
    };
  
    return (
      <table className="table-skeleton-loader">
        <tbody>{generateTableSkeleton(rowCount, colCount)}</tbody>
      </table>
    );
  };
  
  export default TableSkeletonLoader;
