import React from "react";
import "../../../../index.css";
import "./InputWithDropdown.css";
import Select from "react-select";
import { CustomSelectGreyBg } from "../SelectCustomStyle/CustomSelectGreyBg";

type Props = {
  inputType: string;
  placeholder: string;
  inputId: string;
  label: string;
  data: any;
  value: string | number;
  handleChange: (e: any) => void;
  handleDropDownChange: (e: any) => void;
  dropDownValue: any;
  error?: any;
};

export default function InputWithDropdown(props: Props) {
  return (
    <div className="flex-column input-dropdown-wrapper">
      <label htmlFor={props.inputId}>{props.label}</label>
      <div className={`input-dropdown-container ${props.error ? "error" : ""}`}>
        <input
          type={props.inputType}
          className="field-value no-appearance"
          id={props.inputId}
          name={props.inputId}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.handleChange(e)}
        />
        <div className="select-container">
          <Select
            name="interval"
            id="interval"
            options={props.data}
            className={`reusable-form-select-field`}
            value={props.dropDownValue}
            onChange={(e) => props.handleDropDownChange(e)}
            styles={CustomSelectGreyBg}
            placeholder="Choose"
            isSearchable={false}
          />
        </div>
      </div>
      <span className="error">{props.error}</span>
    </div>
  );
}
