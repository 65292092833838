import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { SuccessToaster } from "../../../../../../helpers/toastHelper";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../../common/commonSlice";
import { SolidCancelButton } from "../../../../../common/components/button/SolidCancelButton";
import { SolidDeleteButton } from "../../../../../common/components/button/SolidDeleteButton";
import "./deleteAllocation.css";
import { deleteInvoiceAllocation } from "../../../../paymentsReceived/paymentReceivedSlice";
import { ObjectType } from "../../../../../../types";
import {
  deleteCreditAllocation,
  getAllocationDetails,
} from "../../../../creditNote/creditNoteSlice";
import {
  deleteDebitAllocation,
  getDebitNoteAllocation,
} from "../../../../debitNote/debitNoteSlice";
import { deleteBillAllocation } from "../../../../paymentsMade/paymentsMadeSlice";

type DeleteModalProps = {
  id: number | null;
  billId: number | null;
  itemId?: number | null;
  module: string;
  transactionNumber: string;
  handleClose: () => void;
  getRerenderFlag: (flag: number) => void;
};
export const DeleteAllocation = (props: DeleteModalProps) => {
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [DNAllocationId, setDNAllocationId] = useState<number | null>(null);
  useEffect(() => {
    if (props.module === "DN") {
      getDNAllocationDetails();
    }
  }, []);
  const getDNAllocationDetails = async () => {
    setButtonDisabled(true);
    const responseAction = await dispatch(
      getDebitNoteAllocation({
        debitNoteId: Number(props.id),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;

    let allocations: ObjectType[] = response.allocation_list;
    if (!("error" in response)) {
      setButtonDisabled(false);
      setDNAllocationId(
        allocations.filter(
          (item: ObjectType) => Number(item.bill_id) === Number(props.billId)
        )[0].id
      );
    }
  };
  const deleteAllocation = async () => {
    setButtonDisabled(true);
    dispatch(setLoaderState(true));
    const responseAction =
      props.module === "PM"
        ? await dispatch(
            deleteBillAllocation({
              pmId: props.id,
              itemId: props.itemId ? props.itemId : 0,
              orgId: currentUserInfo.organization_id,
            })
          )
        : await dispatch(
            deleteDebitAllocation({
              debitNoteId: Number(props.id),
              allocationId: Number(DNAllocationId),
              orgId: currentUserInfo.organization_id,
            })
          );
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        `The allocation of ${props.transactionNumber} to the bill is deleted successfully`,
        "allocation-delete"
      );
      props.getRerenderFlag(Math.floor(Math.random() * 100));
      dispatch(setLoaderState(false));
      setButtonDisabled(false);
      props.handleClose();
    } else {
      dispatch(setLoaderState(false));
      setButtonDisabled(false);
      props.handleClose();
    }
  };

  return (
    <div className="delete-allocation-alert-wrapper">
      <span>
        Are you sure you want to delete the allocation of&nbsp;{" "}
        <b>{props.transactionNumber}</b> &nbsp;to this bill?
      </span>
      <div className="modal-footer">
        <SolidCancelButton
          onClick={props.handleClose}
          isDisabled={isButtonDisabled}
        />
        <SolidDeleteButton
          onClick={deleteAllocation}
          isDisabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};
