import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setCustomFieldValues } from "../../../../../appSlice";
import { TableData } from "../../../../../types";
import Delete from "../../../../common/components/Delete";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { CustomFieldFormModal } from "../customFieldFormModal/customFieldFomModal";
import TableContainer from "../../../../common/components/table/TableContainer";
import {
  deleteCustomField,
  getCustomFieldTotalList,
} from "../customFieldAPIFiles/customFieldSlice";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { DeleteCustomFieldModal } from "../deleteCustomField/deleteCustomFieldModal";

type TableProps = {
  data: TableData[];
  sortHandler: () => void;
  isLoading: boolean;
  editPermission: boolean;
  module: string;
};
export const CustomFieldTable = (props: TableProps) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const columns = useMemo(
    () => [
      {
        Header: "Field Name",
        accessor: "show.field_name",
        sort: false,
        sortOrder: "",
        className: "field_name",
      },
      {
        Header: "Mandatory",
        accessor: "show.mandatory_field",
        sort: false,
        sortOrder: "",
        className: "show.mandatory_field",
      },
      {
        Header: "Show in pdf",
        accessor: "show.show_in_pdf",
        sort: false,
        sortOrder: "",
        className: "show_in_pdf",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (
            <KebabMenu>
              <li>
                <CustomFieldFormModal
                  module={props.module}
                  action="edit"
                  onOpenFunction={() => {
                    dispatch(setCustomFieldValues(value));
                  }}
                />
              </li>
              <li>
                <DeleteCustomFieldModal
                  id={value.id}
                  module={props.module}
                  name={value.field_name}
                />
              </li>
            </KebabMenu>
          );
        },
      },
    ],
    []
  );
  const deleteCustomFields = async (id: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteCustomField({
        orgId: currentUserInfo.organization_id,
        custom_field_id: id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      SuccessToaster(
        "Custom field deleted successfully",
        "custom-field-delete"
      );
      await dispatch(
        getCustomFieldTotalList({ orgId: currentUserInfo.organization_id })
      );
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return (
    <>
      <TableContainer
        id="custom_field_table"
        className="list-table table table-striped w-100"
        columns={columns}
        data={props.data}
        url={""}
        isLoading={props.isLoading}
        sortHandler={props.sortHandler}
        isDetailViewExist={false}
        editPermission={props.editPermission}
        hiddenColumns={["actions"]}
        isNavigate={false}
      >
        <div className="no-allocation no-custom-fields">
          No custom fields found!
        </div>
      </TableContainer>
    </>
  );
};
