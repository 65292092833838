import { FormikProps } from "formik";

type Props = {
  formik: FormikProps<any>;
};

export const fieldVisibility = (props: Props) => {
  let result: boolean = false;
  if (
    props.formik.values.bank_currency_id &&
    props.formik.values.currency_id !== props.formik.values.bank_currency_id
  ) {
    result = true;
  } else {
    result = false;
  }
  return result;
};
