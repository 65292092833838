export const downloadFile = async (downloadUrl: string, fileName: string) => {
  const response = await fetch(downloadUrl);
  if (!response.ok) throw new Error("Failed to download file");
  const blob = await response.blob();
  // Convert Blob to File
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: new Date().getTime(), // Set the lastModified timestamp to current time, or use a specific timestamp if needed
  });

  return file;
};
