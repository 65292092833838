import React from "react";
import useCommonData from "../../../../hooks/useCommon";
import { getSundryCreditorDetails } from "../../ReportSlice";
import AgingDetails from "./AgingDetails";

function SundryCreditorDetails() {
  const { dispatch, currentUserInfo } = useCommonData();

  const generateReport = async (filterValues: any) => {
    const responseAction = await dispatch(
      getSundryCreditorDetails({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  return (
    <AgingDetails
      title="Sundry Creditor Aging Details for Bill Due Date"
      generateReport={generateReport}
      type="creditor"
    />
  );
}

export default SundryCreditorDetails;
