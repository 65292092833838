import TransactionLockingHeader from "../common/TransactionLockingHeader";
import LockTransactionCard from "../common/LockTransactionCard";
import NotificationModal from "../../../../common/components/NotificationModal";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getTransactionLockingDetail,
  unlockTransaction,
} from "../../transactionLockingSlice";
import { currentUserSelector } from "../../../../common/commonSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LockTransactionModal from "../common/LockTransactionModal";
import {
  CustomLockValues,
  TransactionDetailValues,
  UnlockValues,
} from "../../../../../types";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import LockAllTransactionFooter from "./LockAllTransactionFooter";

const LockAllTransactions = () => {
  const initialState = {
    modules: [{ id: 0, name: "" }],
    lockings: [
      {
        id: 0,
        module_id: 0,
        locking_type: "",
        lock_reason: "",
        unlock_reason: "",
        start_date: "",
        end_date: "",
        is_lock_all: true,
      },
    ],
  };
  const initialUnlockValues = {
    id: 0,
    module_id: 0,
    locking_type: "",
    unlock_reason: "",
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetailValues>(initialState);
  const [lockModalShow, setLockModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [unlockModalShow, setUnlockModalShow] = useState(false);
  const [partialUnlockModalShow, setPartialUnlockModalShow] = useState(false);
  const [unlockAllModalShow, setUnlockAllModalShow] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [moduleIds, setModuleIds] = useState<number[]>([]);
  const [disablePartialLockValues, setDisablePartialLockValues] = useState<
    UnlockValues[]
  >([initialUnlockValues]);

  useEffect(() => {
    getTransactionDetails();
  }, [currentUserInfo.organization_id]);
  /**
   * Open Lock / Unlock / Partial Unlock / Edit / Unlock All modal handler - All Transactions
   */
  const openModal = (
    action: string,
    moduleName: string,
    moduleId: number[]
  ) => {
    if (action === "lock") {
      setLockModalShow(true);
    } else if (action === "edit_lock") {
      setEditModalShow(true);
    } else if (action === "unlock") {
      setUnlockModalShow(true);
    } else if (action === "partial_unlock") {
      setPartialUnlockModalShow(true);
    } else if (action === "unlock_all_transaction") {
      setUnlockAllModalShow(true);
    }
    setModuleName(moduleName);
    setModuleIds(moduleId);
  };
  /**
   * Close Lock / Unlock / Partial Unlock / Edit / Unlock All modal handler - All Transactions
   */
  const closeModal = (action: string, type: string) => {
    if (action === "lock") {
      setLockModalShow(false);
    } else if (action === "edit_lock") {
      setEditModalShow(false);
    } else if (action === "unlock") {
      setUnlockModalShow(false);
    } else if (action === "partial_unlock") {
      setPartialUnlockModalShow(false);
    } else if (action === "unlock_all_transaction") {
      setUnlockAllModalShow(false);
      if (type === "submit") navigate("/transaction-locking");
    }
  };
  /**
   * Function get transaction details
   */
  const getTransactionDetails = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getTransactionLockingDetail(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response) && !("error" in response)) {
        setTransactionDetails(response);
      }
    }
  };
  /**
   * Function to get Locked Module values
   */
  const getLockModuleValues = (moduleId: number[]) => {
    if (moduleId.length && transactionDetails.lockings.length) {
      const itemId: CustomLockValues[] = transactionDetails.lockings.map(
        (module) => {
          return {
            id: module.id,
            module_id: module.module_id,
            locking_type: module.locking_type,
            end_date: module.end_date,
            lock_reason: module.lock_reason,
            moduleName: transactionDetails.modules
              .filter((item) => item.id === module.module_id)
              .map((module) => module.name)[0],
          };
        }
      );
      return itemId;
    }
    return [];
  };
  /**
   * set partial unlock module values on clicking disable partial unlock drop down
   */
  const disablePartialUnlock = (moduleId: number[]) => {
    const lockModuleIds: { id: number; moduleId: number }[] =
      transactionDetails.lockings
        .filter((module) => module.locking_type === "partial_unlock")
        .map((module) => {
          return {
            id: module.id,
            moduleId: module.module_id,
          };
        });
    const partialLockValues: UnlockValues[] = [];
    for (let module of lockModuleIds) {
      partialLockValues.push({
        id: module.id,
        module_id: module.moduleId,
        locking_type: "partial_unlock",
        unlock_reason: null,
      });
    }
    setDisablePartialLockValues(partialLockValues);
  };
  /**
   * Function to handle disable partial unlock confirmation
   */
  const handleDisablePartialUnlock = async () => {
    const responseAction = await dispatch(
      unlockTransaction({
        values: disablePartialLockValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        toast.success("Locking transaction preferences have been updated", {
          toastId: "disable-lock-transaction-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
    getTransactionDetails();
  };

  return (
    <>
      <TransactionLockingHeader>
        {!isLoading && (
          <>
            <LockTransactionCard
              isLockAll={true}
              lockedModules={transactionDetails.lockings}
              moduleName={"All Transactions"}
              cardSubHeading={"Transactions"}
              modalId={`unlock-item-${4}`}
              openModal={openModal}
              moduleId={transactionDetails.modules.map((module) => module.id)}
              disablePartialUnlock={disablePartialUnlock}
              isAllTransactionLocked={
                transactionDetails.lockings.length === 0
                  ? false
                  : Object.values(transactionDetails.lockings).every(
                      (module) => module.is_lock_all === false
                    )
              }
              moduleLength={transactionDetails.modules.length}
            />
            <LockAllTransactionFooter
              transactionDetails={transactionDetails}
              openModal={openModal}
            />
          </>
        )}
        {isLoading ? <Loader /> : null}
      </TransactionLockingHeader>
      {lockModalShow && (
        <LockTransactionModal
          isLockAll={true}
          firstHeading="Lock"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="lockModal"
          moduleIds={moduleIds}
          action="lock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {editModalShow && (
        <LockTransactionModal
          isLockAll={true}
          firstHeading="Edit"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="editModal"
          moduleIds={moduleIds}
          action="edit_lock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {unlockModalShow && (
        <LockTransactionModal
          isLockAll={true}
          firstHeading="Unlock"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="unLockModal"
          moduleIds={moduleIds}
          action="unlock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {partialUnlockModalShow && (
        <LockTransactionModal
          isLockAll={true}
          firstHeading="Unlock Partially"
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="partiallyUnlockModal"
          moduleIds={moduleIds}
          action="partial_unlock"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      {unlockAllModalShow && (
        <LockTransactionModal
          isLockAll={true}
          firstHeading=""
          closeModal={closeModal}
          moduleName={moduleName}
          modalId="unlockAllTransaction"
          moduleIds={moduleIds}
          action="unlock_all_transaction"
          getTransactionDetail={getTransactionDetails}
          lockedModuleValues={getLockModuleValues(moduleIds)}
        />
      )}
      <NotificationModal
        modalHeader="Do you want to disable partial unlock?"
        modalBody="Your transactions will be locked completely."
        modalSubmit="Disable Partial Unlock"
        modalCancel="Cancel"
        modalSubmitHandler={handleDisablePartialUnlock}
      />
    </>
  );
};

export default LockAllTransactions;
