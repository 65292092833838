import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  DeleteImg,
  EditListDataImg,
  MoreActionImg,
  PlusSmallImg,
  DirectoryIconSmall,
  ListIconSmall,
  ErrorImg,
} from "../../../../assets/images";
import { AccountsTreeValues, ObjectType } from "../../../../types";
import Loader from "../../components/Loader";
import {
  accountGlRanges,
  accountsSelector,
  accountSuggestions,
  getCoaListView,
  getCoaTreeView,
  getCoaTreeViewInAddAccount,
  getTransactionStatusOfAccount,
} from "../coaSlice";
import Create from "./Create";
import DeleteAccount from "./Delete";
import EditAccount from "./Edit";
import EditGLCode from "./EditGLCode";
import { AccountsGLCodeRanges } from "../../../../types";
import {
  currencyDropdown,
  currentUserSelector,
  getSuperParent,
  orgCurrencyListSelector,
} from "../../../common/commonSlice";
import CustomTooltips from "../../../common/components/Tooltip";
import { toast } from "react-toastify";
import { colors } from "../../../constants/colors";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";

let expandButtonsArray: any = [];
let countOfAccounts = 0;
let executionControlFlag: boolean = false;
function AccountsTree() {
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const accountsList = useAppSelector(accountsSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const superParent = useAppSelector(getSuperParent);
  const [accountData, setAccountData] = useState<AccountsTreeValues[]>([]);
  const [editAccountId, setEditAccountId] = useState(-1);
  const [accountType, setAccountType] = useState("");
  const [accountName, setAccountName] = useState("");
  const [deleteAccountId, setDeleteAccountId] = useState(-1);
  const [editAccountName, setEditAccountName] = useState("");
  const [editAccountDescription, setEditAccountDescription] = useState("");
  const [editGlStartCode, setEditGlStartCode] = useState("");
  const [editGlEndCode, setEditGlEndCode] = useState("");
  const [editParentId, setEditParentId] = useState(0);
  const [editCurrencyId, setEditCurrencyId] = useState(0);
  const [parentGlStartCode, setParentGlStartCode] = useState("");
  const [parentGlEndCode, setParentGlEndCode] = useState("");
  const [deleteAccountName, setDeleteAccountName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [nameSort, setNameSort] = useState("");
  const [typeSort, setTypeSort] = useState("");
  const [balanceSort, setBalanceSort] = useState("");
  const [searchAccountName, setSearchAccountName] = useState("");
  const [searchedName, setSearchedName] = useState("");
  const navigate = useNavigate();
  const [isName, setIsName] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [suggestedAccounts, setSuggestedAccounts] = useState([]);
  const [isEnter, setIsEnter] = useState(false);
  const [glFormValues, setGlFormValues] = useState<AccountsGLCodeRanges[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isTransactionExist, setTransactionExist] = useState(false);
  const [openingBalance, setOpeningBalance] = useState<string | number>(0);
  const [currentBalance, setCurrentBalance] = useState<string | number>(0);
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const [conversionId, setConversionId] = useState<number | null>(0);
  const [transactionStatus, setTransactionStatus] = useState<ObjectType>({});
  const orgID = useRef(currentUserInfo.organization_id);
  const [isEditGlCode, setIsEditGlCode] = useState(false);
  const [superParentId, setSuperParentId] = useState(0);
  const [parentId, setParentId] = useState(0);
  const [isMutable, setIsMutable] = useState(false);
  const [isBankAccount, setIsBankAccount] = useState(false);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let chartOfAccountPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Chart of Account"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Navigate to COA list view page from tree view
   */
  const listViewHandler = () => {
    navigate("/accounts/list");
  };

  useEffect(() => {
    fetchAccountTreeViewList(searchedName);
  }, [accountsList, searchedName, currentUserInfo.organization_id]);

  useEffect(() => {
    // load search event initially
    setTotalResults(0);
    setSearchAccountName("");
    setSearchedName("");
    orgID.current = currentUserInfo.organization_id;
    autocomplete(document.getElementById("search_table"), suggestedAccounts);
    // //fetch parent account GL code rages
    // fetchAccountGLRanges();
  }, [currentUserInfo.organization_id]);

  /**
   * Fetch COA tree view List
   */
  const fetchAccountTreeViewList = async (name: string) => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getCoaTreeView({
        name: name.trim(),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      /**
       * To remove suggestion box when search result is displayed.
       */
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        x[i].parentNode?.removeChild(x[i]);
      }
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
      initAccrodion();
      if (name !== "") {
        setIsName(true);
      } else {
        setIsName(false);
      }
      const response = responseAction.payload;
      if (Object.keys(response.length) && !("error" in response)) {
        setAccountData(response);
      } else if ("error" in response) {
        setAccountData([]);
      }
    }
  };

  /**
   * Fetch transaction status of each account
   */
  const fetchCOATransactionStatus = async (id: number, isCategory: boolean) => {
    if (currentUserInfo.is_super_parent && !isCategory) {
      const responseAction = await dispatch(
        getTransactionStatusOfAccount({
          transactionId: id,
          orgId: currentUserInfo.organization_id,
          transaction: "account_id",
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setTransactionStatus({ ...response });
        }
      }
    }
  };

  /**
   * Search suggestion API Call
   */
  const searchSuggestions = async (value: string, e: any) => {
    setIsSearch(true);
    const accountSearch = value;
    if (e.keyCode !== 13) {
      if (accountSearch.length > 2) {
        const responseAction = await dispatch(
          accountSuggestions({
            accountName: accountSearch.trim(),
            orgId: orgID.current,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            setTotalResults(response.account_list.length);
            setIsSearch(false);
            return response.account_list;
          }
        }
      } else {
        return [];
      }
    }
  };

  function autocomplete(inp: any, arr: any) {
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus: any;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", async function (this: any, e: any) {
      const searchedKey = this.value.trim() === "" ? "" : this.value;
      if (e.keyCode === 13) {
        e.preventDefault();
      } else {
        setSearchAccountName(searchedKey);
        arr = await searchSuggestions(searchedKey, e);
        var a: any,
          b,
          i: any,
          val = searchedKey.trim(),
          matchCounter = 0;
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        if (!val) {
          return false;
        }
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a);
        /*for each item in the array...*/
        arr.map((t: any) => {
          /*check if the item starts with the same letters as the text field value:*/
          if (t.account_name.toUpperCase().includes(val.toUpperCase())) {
            let index = t.account_name.toUpperCase().indexOf(val.toUpperCase());
            /*create a DIV element for each matching element:*/
            b = document.createElement("DIV");
            b.setAttribute("class", "search-item-wrapper");
            b.setAttribute("tabindex", "1");
            let c = document.createElement("DIV");
            c.setAttribute("class", "search-item w-100");
            c.innerHTML =
              "<img src='" +
              `${t.is_category ? DirectoryIconSmall : ListIconSmall}` +
              "' alt='account icon' width='16' height='16' />";
            c.innerHTML += t.account_name.substr(0, index);
            /*make the matching letters bold:*/
            c.innerHTML +=
              "<span>" + t.account_name.substr(index, val.length) + "</span>";
            c.innerHTML += t.account_name.substr(index + val.length);
            /*insert a input field that will hold the current array item's value:*/
            c.innerHTML +=
              "<input type='hidden' value='" + t.account_name + "'>";
            let d = document.createElement("DIV");
            d.setAttribute("class", "item-type w-100");
            d.innerHTML += `<span>${accountTypeHandler(t.account_type)}</span>`;
            b.appendChild(c);
            b.appendChild(d);
            /*execute a function when someone clicks on the item value (DIV element):*/
            b.addEventListener("click", function (e) {
              /*insert the value for the autocomplete text field:*/
              inp.value = this.getElementsByTagName("input")[0].value;
              const end = inp.value.length;
              inp.setSelectionRange(end, end);
              inp.focus();
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
              setSearchAccountName(t.account_name);
              setSearchedName(t.account_name);
              setIsEnter(true);
            });
            a.appendChild(b);
            matchCounter++;
          }
        });
        if (matchCounter == 0) {
          if (searchedKey.length > 2) {
            b = document.createElement("DIV");
            b.setAttribute("class", "search-item-wrapper no-result");
            b.innerHTML = "No item available";
            a.appendChild(b);
          } else {
            $(a).remove();
          }
        }
        document.body.style.overflow = "hidden";
      }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (this: any, e: any) {
      var x: HTMLElement | any = document.getElementById(
        this.id + "autocomplete-list"
      );
      if (x) {
        //x = x.getElementsByTagName("div");
        x = x.getElementsByClassName("search-item-wrapper");
      }
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) {
        //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        setPage(1);
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
        // setAccountNameTree(this.value);
        setSearchAccountName(this.value);
        setSearchedName(this.value);
        setIsEnter(true);
        closeAllLists();
      }
    });
    function addActive(x: any) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x: any) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt?: any) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode?.removeChild(x[i]);
        }
      }
      document.body.style.overflow = "auto";
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }

  /**
   * Reset to initial state when search key is changed
   */
  useEffect(() => {
    if ($(".parent-row").hasClass("expanded")) {
      $(".parent-row").removeClass("expanded");
    }
    if ($(".collapsible-btn").hasClass("active")) {
      $(".collapsible-btn").removeClass("active");
    }
    if ($(".table-collapsible-content").hasClass("show")) {
      $(".table-collapsible-content").removeClass("show");
    }
  }, [searchedName]);

  useEffect(() => {
    initAccrodion();
  }, [accountData]);

  /**
   * Pushing the buttons to be expanded to an array based on search
   */
  useEffect(() => {
    const checkSearchKey = (accountName: string, name: string) => {
      return accountName
        .trim()
        .toUpperCase()
        .includes(name.trim().toUpperCase());
    };

    const searchAccounts = (account: AccountsTreeValues) => {
      let match = false;
      if (account.node?.length) {
        expandButtonsArray.push({ id: account.id });
        let childMatch = false;
        account.node.map((childAccount) => {
          let result = searchAccounts(childAccount);
          childMatch = childMatch || result;
        });
        match = checkSearchKey(account.account_name, searchedName);
        if (match) {
          countOfAccounts++;
        }
        if (!childMatch) {
          expandButtonsArray.pop();
        }
        return childMatch || match;
      } else {
        match = checkSearchKey(account.account_name, searchedName);
        if (match) {
          countOfAccounts++;
        }

        return match;
      }
    };
    if (
      searchedName === searchAccountName &&
      searchedName.trim() !== "" &&
      isName === true
    ) {
      expandButtonsArray = [];
      countOfAccounts = 0;
      accountData.map((parentAccount) => {
        searchAccounts(parentAccount);
      });
    }
  }, [searchedName, accountData]);

  /**
   * Applying click event for the buttons added to the array.
   */
  useEffect(() => {
    expandButtonsArray.map((value: any) => {
      if (!$(`.collaps-btn-${value.id}`).hasClass("active")) {
        $(`.collaps-btn-${value.id}`).click();
      }
    });
  }, [expandButtonsArray]);

  /**
   * Removing Parent Account's Bold when deleting it's single child
   */
  const checkChildExist = (account: AccountsTreeValues) => {
    if (
      account?.node?.length === 0 &&
      $(`.parent-account-${account.id}`).hasClass("expanded") &&
      $(`.table-collapse-${account.id}`).hasClass("show")
    ) {
      $(`.parent-account-${account.id}`).removeClass("expanded");
      $(`.table-collapse-${account.id}`).removeClass("show");
    }
    account.node?.map((childNode) => {
      if (
        childNode?.node?.length === 0 &&
        $(`.parent-account-${childNode.id}`).hasClass("expanded")
      ) {
        $(`.parent-account-${childNode.id}`).removeClass("expanded");
      }
      return childNode.node?.length !== 0 ? checkChildExist(childNode) : null;
    });
  };

  useEffect(() => {
    accountData.map((account) => {
      return account.node ? checkChildExist(account) : null;
    });
  }, [accountData]);

  /**
   * Expand and Collapse handler in tree view based on Child node for each parent
   */
  const initAccrodion = () => {
    var collapseBtn: any = document.getElementsByClassName("collapsible-btn");
    for (var i: number = 0; i < collapseBtn.length; i++) {
      collapseBtn[i].addEventListener("click", function (this: any, e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.classList.toggle("active");
        this.closest(".parent-row").classList.toggle("expanded");
        var panel: any = this.closest(".parent-row").nextElementSibling;
        panel.classList.toggle("show");
      });
    }
  };

  function toggleExpanding(this: any, e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!executionControlFlag) {
      executionControlFlag = !executionControlFlag;
      this.classList.toggle("active");
      this.closest(".parent-row").classList.toggle("expanded");
      var panel: any = this.closest(".parent-row").nextElementSibling;
      panel.classList.toggle("show");
    }
    setTimeout(() => {
      executionControlFlag = !executionControlFlag;
    }, 10);
  }

  const refreshList = () => {
    fetchAccountTreeViewList(accountName);
    dispatch(getCoaTreeViewInAddAccount(currentUserInfo.organization_id));
  };

  /**
   * Set account ID, Account type, Account name, and account description to be updated
   */
  const editAccountHandler = (
    id: number,
    name: string,
    description: string,
    account_type: string,
    glCodeStart: string,
    glCodeEnd: string,
    accountSuperparentId: number,
    currencyId: number,
    isCategory: boolean,
    isTransactionExist: boolean,
    openingBalance: number | string,
    currentBalance: number | string,
    conversionId: number | null,
    isAccountMutable: boolean,
    accountParentId: number,
    accountClass: string | null
  ) => {
    setEditAccountId(id);
    setAccountType(account_type);
    setEditAccountName(name);
    setEditAccountDescription(description);
    setEditGlStartCode(glCodeStart);
    setEditGlEndCode(glCodeEnd);
    setEditCurrencyId(currencyId);
    setIsCategory(isCategory);
    setTransactionExist(isTransactionExist);
    setOpeningBalance(openingBalance);
    setCurrentBalance(currentBalance);
    setConversionId(conversionId);
    const superParentAccount = glFormValues.filter(
      (glRanges) => glRanges.id === superParentId
    )[0];
    setParentGlStartCode(superParentAccount?.gl_start_code);
    setParentGlEndCode(superParentAccount?.gl_end_code);
    setSuperParentId(accountSuperparentId);
    setIsMutable(isAccountMutable);
    setParentId(accountParentId);
    accountClass === "BankAccount"
      ? setIsBankAccount(true)
      : setIsBankAccount(false);
  };

  /**
   * Set account ID and Name to be deleted
   */
  const deleteAccountHandler = (
    id: number,
    name: string,
    transactionExist: boolean
  ) => {
    setDeleteAccountId(id);
    setDeleteAccountName(name);
    if (transactionExist) {
      toast.error(
        "Unable to delete the account, as there are transactions associated with it!",
        {
          icon: ({ theme, type }) => (
            <img src={ErrorImg} alt="error-img" width="16" height="14" />
          ),
          toastId: `coa-delete-failed`,
          closeButton: false,
          className: "toast-error",
          position: "top-center",
        }
      );
    }
  };

  /**
   * Reset Edit account Id while closing modal.
   */
  const resetEditAccountId = () => {
    setEditAccountId(-1);
  };

  /**
   * Reset Delete account ID while closing modal
   */
  const resetDeleteAccountId = () => {
    setDeleteAccountId(-1);
  };

  /**
   * Returning left border based on depth of child.
   */
  const leftBorderOfChild = (count: number) => {
    let countArray = [];
    for (let i = 0; i < count; i++) {
      countArray.push(i);
    }
    let result = countArray.map((data, index) => {
      return (
        <div
          className="plain-line-column left-float left-border"
          key={index}
        ></div>
      );
    });
    return result;
  };

  /**
   * Input submit handler in COA search
   */
  const searchSubmitHandler = (e: any) => {
    e.preventDefault();
    setSearchedName(searchAccountName);
  };

  /**
   * Input change handler in COA search
   */
  const searchInputChangeHandler = (e: any) => {
    setIsEnter(false);
  };

  const fetchAccountGLRanges = async () => {
    const responseAction = await dispatch(
      accountGlRanges(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setGlFormValues(response);
      }
    }
  };

  /**
   * Account type listing handler
   */
  const accountTypeHandler = (type: string) => {
    if (type == null) {
      return "";
    }
    let newType = type;
    let typeArray = newType.split("/");
    if (
      typeArray.length === 0 ||
      typeArray.length === 1 ||
      typeArray.length === 2
    ) {
      return type;
    } else {
      newType = typeArray[0] + " / .. / " + typeArray[typeArray.length - 1];
      return newType;
    }
  };

  /**
   * Reset search input after clicking close button
   */
  const resetInput = () => {
    setSearchAccountName("");
    setSearchedName("");
    setIsEnter(true);
    document.getElementById("search_table")?.focus();
  };

  const coaDropdownAction = () => {
    var leftScroll = 0;
    var scrollItem: any = document.querySelector(".table-container");
    let resizeFlag: boolean = false;
    scrollItem.addEventListener("scroll", function (e: any) {
      setTableScrollLeft(scrollItem.scrollLeft);
    });
    $(".dropdown-ctrl").on("click", function () {
      ($(".dropdown-ctrl") as any).dropdown("hide");
      ($(this) as any).dropdown("show");
    });
    $(".more-btn").on("show.bs.dropdown", function () {
      $(".table-container").css({ overflow: "inherit" });

      setTimeout(() => {
        $(".table-container .coa-treeview").css(
          "margin-left",
          "-" + tableScrolLeft + "px"
        );
      }, 5);
    });
    $(".more-btn").on("hide.bs.dropdown", function () {
      $(".table-container").css({
        "overflow-x": "auto",
        "overflow-y": "hidden",
      });
      leftScroll = $(".table-container .coa-treeview").data("scrollleft");
      $(".table-container .coa-treeview").css("margin-left", 0);
      setTimeout(() => {
        scrollItem.scrollLeft = leftScroll;
      }, 1);
    });

    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-ctrl") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    coaDropdownAction();
  });

  /**
   * function to highlight the searched keyword in the search results screen
   */
  const getAccountName = (name: string) => {
    if (searchedName.trim() === "") {
      return name;
    }
    const accountNameParts = name.split(
      new RegExp(`(${searchedName.trim()})`, "gi")
    );
    return (
      <span>
        {accountNameParts.map((part, index) =>
          part.toLowerCase() === searchedName.toLowerCase().trim() ? (
            <b style={{ backgroundColor: colors.blanchedAlmond }} key={index}>
              {part}
            </b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  /**
   * Fetch all currency list
   */
  const getCurrencyList = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };

  const renderTreeData = (account: AccountsTreeValues) => {
    return account.node?.map((nodeAccount) => {
      return (
        <div key={nodeAccount.id} className="table-collapsible-item auto-flex">
          <div
            className={`parent-row item-row level-${nodeAccount.depth} parent-account-${nodeAccount.id}`}
          >
            <div className="item-col account-col align-left">
              <>
                {leftBorderOfChild(nodeAccount.depth - 1)}
                {nodeAccount.node?.length === 0 ? (
                  <div className="plain-line-column left-float left-border horizontal-border"></div>
                ) : (
                  <>
                    <div className="btn-container left-float horizontal-border bottom-border left-border">
                      <button
                        className={`collapsible-btn collaps-btn-${nodeAccount.id}`}
                      ></button>
                    </div>
                  </>
                )}
              </>
              <div className="item-label left-float horizontal-border left-border">
                <span className="acc-name">
                  <img
                    width={16}
                    height={16}
                    src={
                      nodeAccount.is_category
                        ? DirectoryIconSmall
                        : ListIconSmall
                    }
                    alt="account-name-icon"
                  />
                  {getAccountName(nodeAccount.account_name)}
                </span>
              </div>
            </div>
            <CustomTooltips
              placement={"bottom"}
              title={
                nodeAccount?.description?.length > 60
                  ? nodeAccount?.description
                  : ""
              }
            >
              <div className="item-col desc-col align-left horizontal-border">
                <span className="coa_tree_description">
                  <span className="ellipsis w-100">
                    {nodeAccount.description}
                  </span>
                </span>
              </div>
            </CustomTooltips>
            <div className="item-col ledger-col align-left horizontal-border">
              <span>
                {nodeAccount.is_category ? "" : nodeAccount.gl_start_code}
              </span>
            </div>
            <div className="item-col bal-col align-right horizontal-border">
              <span>
                {NumberFormat(
                  nodeAccount.current_balance,
                  currentUserInfo.currency_code
                    ? currentUserInfo.currency_code
                    : "",
                  orgCurrencyList
                )}
              </span>
            </div>
            <div className="item-col act-col horizontal-border">
              <div className="action">
                {chartOfAccountPermission.includes("Update") ? (
                  <div className="dropdown kebabMenu">
                    <button
                      type="button"
                      className="more-btn dropdown-ctrl no-appearance"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      tabIndex={0}
                      onKeyUp={(event: any) => {
                        if (event.key === "Enter" || event.keyCode === 13) {
                          event.target.click();
                        }
                      }}
                      onClick={() =>
                        fetchCOATransactionStatus(
                          nodeAccount.id,
                          nodeAccount.is_category
                        )
                      }
                    >
                      {/* <img src={MoreActionImg} alt="more-action-img" /> */}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0h20v20H0z" />
                          <path
                            d="M10 2.5c-.917 0-1.667.75-1.667 1.667 0 .916.75 1.666 1.667 1.666s1.667-.75 1.667-1.666c0-.917-.75-1.667-1.667-1.667zm0 11.667c-.917 0-1.667.75-1.667 1.666 0 .917.75 1.667 1.667 1.667s1.667-.75 1.667-1.667c0-.916-.75-1.666-1.667-1.666zm0-5.834c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667 1.667-.75 1.667-1.667-.75-1.667-1.667-1.667z"
                            fill={colors.battleshipGrey}
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </button>
                    <ul className="dropdown-menu">
                      <li
                        onClick={() => {
                          editAccountHandler(
                            nodeAccount.id,
                            nodeAccount.account_name,
                            nodeAccount.description,
                            nodeAccount.account_type,
                            nodeAccount.gl_start_code,
                            nodeAccount.gl_end_code,
                            nodeAccount.super_parent_id,
                            nodeAccount.currency_id,
                            nodeAccount.is_category,
                            transactionStatus[nodeAccount.id],
                            nodeAccount.opening_balance,
                            nodeAccount.current_balance,
                            nodeAccount.conversion_id,
                            nodeAccount.is_mutable,
                            nodeAccount.parent_id,
                            nodeAccount.account_class
                          );
                        }}
                      >
                        <a
                          href="#"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#editAccountModal"
                          onClick={(e) => {
                            e.preventDefault();
                            getCurrencyList();
                            //fetch parent account GL code rages
                            fetchAccountGLRanges();
                          }}
                        >
                          <img src={EditListDataImg} alt="edit-list-img" />
                          <span>Edit</span>
                        </a>
                      </li>
                      {chartOfAccountPermission.includes("Delete") &&
                      superParent ? (
                        <li
                          onClick={() =>
                            deleteAccountHandler(
                              nodeAccount.id,
                              nodeAccount.account_name,
                              transactionStatus[nodeAccount.id]
                            )
                          }
                          style={
                            nodeAccount.is_mutable === false
                              ? {
                                  pointerEvents: "none",
                                  cursor: "default",
                                  opacity: "0.5",
                                }
                              : {}
                          }
                        >
                          <a
                            className="dropdown-item delete"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target={
                              transactionStatus[nodeAccount.id] ||
                              nodeAccount.is_mutable === false
                                ? ""
                                : "#deleteMessageModal"
                            }
                            onClick={(e) => e.preventDefault()}
                          >
                            <img src={DeleteImg} alt="delete-img" />
                            <span>Delete</span>
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* Recursive function call if node exists */}
          {nodeAccount.node?.length !== 0 ? (
            <div className="table-collapsible-content">
              {renderTreeData(nodeAccount)}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  const refresh = async (type: string) => {
    if (type === "submit") {
      setIsLoading(true);
      await dispatch(
        getCoaTreeView({ name: "", orgId: currentUserInfo.organization_id })
      );
      dispatch(
        getCoaListView({
          page: page,
          itemsPerPage: itemsPerPage,
          accountName: accountName,
          nameSort: nameSort,
          typeSort: typeSort,
          balanceSort: balanceSort,
          orgId: currentUserInfo.organization_id,
        })
      );

      dispatch(getCoaTreeViewInAddAccount(currentUserInfo.organization_id));
      setIsLoading(false);
    }
    setIsEditGlCode(false);
  };

  return (
    <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <h1 className="fw-bold">Chart of Accounts</h1>
        <form
          className="search_table_form type-include"
          name="search_table_form"
          autoComplete="off"
          onSubmit={searchSubmitHandler}
        >
          <div className="autocomplete">
            <input
              type="text"
              name="searchTable"
              id="search_table"
              className="ellipsis"
              placeholder="Search for Accounts"
              onChange={searchInputChangeHandler}
              value={searchAccountName}
            />
            {totalResults > 0 && searchAccountName.length > 2 && !isSearch ? (
              <span className="result-count">
                {isEnter ? countOfAccounts : totalResults}{" "}
                {totalResults === 1 ? "Result" : "Results"} Found
              </span>
            ) : null}
            {searchAccountName.length > 0 ? (
              <input
                type="button"
                className="clear-search"
                value=""
                onClick={resetInput}
              />
            ) : (
              ""
            )}
          </div>
        </form>
        <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
          <li className="nav-item">
            {chartOfAccountPermission.includes("Create") && superParent ? (
              <button
                className="btn add-customer-button green-button"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#addAccountModal"
              >
                <img src={PlusSmallImg} alt="plus-img" className="Group" />
                <span className="invite-user-button-text green-button-text">
                  Add Account
                </span>
              </button>
            ) : null}
          </li>
          <div className="toggle-view">
            <button className="list-view" onClick={() => listViewHandler()}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M0 0h20v20H0z" />
                  <path
                    id="list_view"
                    d="M6.667 3.333H17.5V5H6.667V3.333zM2.5 2.917H5v2.5H2.5v-2.5zm0 5.833H5v2.5H2.5v-2.5zm0 5.833H5v2.5H2.5v-2.5zm4.167-5.416H17.5v1.666H6.667V9.167zm0 5.833H17.5v1.667H6.667V15z"
                    fill={colors.white}
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </button>
            <button className="tree-view active">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M0 0h20v20H0z" />
                  <path
                    id="tree_view"
                    d="M8.333 1.667c.46 0 .834.373.834.833v3.333c0 .46-.374.834-.834.834H6.667v1.666h4.166V7.5c0-.46.374-.833.834-.833h5c.46 0 .833.373.833.833v3.333c0 .46-.373.834-.833.834h-5a.834.834 0 0 1-.834-.834V10H6.667v5h4.166v-.833c0-.46.374-.834.834-.834h5c.46 0 .833.374.833.834V17.5c0 .46-.373.833-.833.833h-5a.834.834 0 0 1-.834-.833v-.833h-5A.834.834 0 0 1 5 15.833V6.667H3.333a.834.834 0 0 1-.833-.834V2.5c0-.46.373-.833.833-.833h5zM15.833 15H12.5v1.667h3.333V15zm0-6.667H12.5V10h3.333V8.333zm-8.333-5H4.167V5H7.5V3.333z"
                    fill={colors.ceruleanBlue}
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </button>
          </div>
        </ul>
      </div>
      <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
        <div className="card-body">
          {/* tree view begins */}
          <div className="table-container table-responsive">
            <div className="grid-table coa-treeview">
              <div className="table-header">
                <div className="item-row">
                  <div
                    className="item-col account-col align-left"
                    // data-orderable="true"
                  >
                    <span>Account</span>
                  </div>
                  <div className="item-col desc-col align-left">
                    <span>Description</span>
                  </div>
                  <div className="item-col ledger-col align-left">
                    <span>Ledger Code</span>
                  </div>
                  <div
                    className="item-col bal-col align-right"
                    // data-orderable="true"
                  >
                    <span>Balance</span>
                  </div>
                  <div className="item-col act-col"></div>
                </div>
              </div>
              <div className="table-body">
                {accountData.length ? (
                  accountData.map((account, index) => {
                    return (
                      <div
                        key={account.id}
                        className="table-collapsible-item auto-flex"
                      >
                        <div
                          className={`parent-row item-row level-${account.depth} parent-account-${account.id}`}
                        >
                          <div className="item-col account-col align-left">
                            {account.node?.length !== 0 ? (
                              <>
                                <div className="btn-container left-float horizontal-border bottom-border left-border">
                                  <button
                                    className={`collapsible-btn collaps-btn-${account.id}`}
                                  ></button>
                                </div>
                              </>
                            ) : (
                              <div className="plain-line-column left-float left-border horizontal-border"></div>
                            )}
                            <div className="item-label left-float horizontal-border left-border">
                              <span className="acc-name">
                                <img
                                  width={16}
                                  height={16}
                                  src={
                                    account.is_category
                                      ? DirectoryIconSmall
                                      : ListIconSmall
                                  }
                                  alt="account-name-icon"
                                />
                                {getAccountName(account.account_name)}
                              </span>
                            </div>
                          </div>
                          <CustomTooltips
                            title={
                              account.description?.length > 17
                                ? account.description
                                : ""
                            }
                            placement="bottom"
                          >
                            <div className="item-col desc-col align-left horizontal-border">
                              <span className="coa_tree_description">
                                <span className="ellipsis w-100">
                                  {account.description}
                                </span>
                              </span>
                            </div>
                          </CustomTooltips>
                          <div className="item-col ledger-col align-left horizontal-border">
                            <span>
                              {account.is_category ? "" : account.gl_start_code}
                            </span>
                          </div>
                          <div className="item-col bal-col align-right horizontal-border">
                            <span className="ellipsis">
                              {NumberFormat(
                                account.current_balance,
                                currentUserInfo.currency_code
                                  ? currentUserInfo.currency_code
                                  : "",
                                orgCurrencyList
                              )}
                            </span>
                          </div>
                          <div className="item-col act-col horizontal-border">
                            <div className="action">
                              {chartOfAccountPermission.includes("Update") &&
                              superParent ? (
                                <div className="dropdown kebabMenu">
                                  <button
                                    type="button"
                                    className="more-btn dropdown-ctrl no-appearance"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    tabIndex={0}
                                    onKeyUp={(event: any) => {
                                      if (
                                        event.key === "Enter" ||
                                        event.keyCode === 13
                                      ) {
                                        event.target.click();
                                      }
                                    }}
                                  >
                                    <img
                                      src={MoreActionImg}
                                      alt="more-action-img"
                                    />
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li onClick={() => setIsEditGlCode(true)}>
                                      <a
                                        href="#"
                                        className="dropdown-item"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editLedgerRangeModal"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img
                                          src={EditListDataImg}
                                          alt="edit-list-img"
                                        />
                                        <span>Edit Ledger Range</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {/* calling child if node exists */}
                        <div
                          className={`table-collapsible-content table-collapse-${account.id}`}
                        >
                          {account.node ? renderTreeData(account) : <></>}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="table-collapsible-item auto-flex">
                    <div className="parent-row item-row level-1">
                      <div className="item-col no-item desc-col align-center horizontal-border w-100">
                        <span>No item found</span>
                      </div>
                    </div>
                  </div>
                )}

                {/* tree view ends */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Create
        page={page}
        itemsPerPage={itemsPerPage}
        accountName={accountName}
        nameSort={nameSort}
        typeSort={typeSort}
        balanceSort={""}
        superParentId={superParentId}
      />
      <EditAccount
        accountIdReset={resetEditAccountId}
        editAccountId={editAccountId}
        page={page}
        itemsPerPage={itemsPerPage}
        accountName={accountName}
        accountType={accountType}
        editAccountName={editAccountName}
        editAccountDescription={editAccountDescription}
        editGlStartCode={editGlStartCode}
        editGlEndCode={editGlEndCode}
        editCurrencyId={editCurrencyId}
        editParentId={editParentId}
        parentGlStartCode={parentGlStartCode}
        parentGlEndCode={parentGlEndCode}
        nameSort={nameSort}
        typeSort={typeSort}
        balanceSort={""}
        organizationId={currentUserInfo.organization_id}
        isCategory={isCategory}
        isTransactionExist={isTransactionExist}
        isSuperParent={superParent}
        openingBalance={openingBalance}
        currentBalance={currentBalance}
        conversionId={conversionId}
        superParentId={superParentId}
        isMutable={isMutable}
        parentId={parentId}
        isBankAccount={isBankAccount}
        refreshList={refreshList}
      />
      <DeleteAccount
        deleteIdReset={resetDeleteAccountId}
        page={page}
        itemsPerPage={itemsPerPage}
        deleteAccountId={deleteAccountId}
        deleteAccountName={deleteAccountName}
        accountName={accountName}
        nameSort={nameSort}
        typeSort={typeSort}
        balanceSort={""}
        organizationId={currentUserInfo.organization_id}
      />
      <EditGLCode
        organizationId={currentUserInfo.organization_id}
        refresh={refresh}
        isEditGlCode={isEditGlCode}
      />
      {isLoading ? <Loader /> : null}
    </div>
  );
}

export default AccountsTree;
