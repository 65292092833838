type Props = {
  heading: string;
  children: React.ReactNode;

};

const SectionHeading = (props: Props) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <span>
          <h2 className="mb-0">{props.heading}</h2>
          <span className="mt-2 error"></span>
        </span>
        {props.children}
      </div>
    </>
  );
};

export default SectionHeading;