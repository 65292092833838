// import BillsPreviewHeader from "../billsPreview/BillsDetailHeader";
import BillsDetailHeader from "./BillsDetailHeader";
import "./BillsDetail.css";
function BillsDetail() {
  return (
    <div className="">
      <div className="card card-dashboard bills-detail-card w-100" id="bills-detail-card">
        <BillsDetailHeader />
      </div>
    </div>
  );
}

export default BillsDetail;
