import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { exchangeApiStatusSelector } from "../../../../common/commonSlice";
import {
  ArrowDropRightImg,
  DownloadIcon,
  PrintIcon,
  SaveBillIcon,
} from "../../../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import Delete from "../../../../common/components/Delete";
import ErrorInfo from "../../../../common/components/ErrorInfo";

type Props = {
  editId: string | undefined;
  isLocked: boolean;
  lockDate: string;
  name: string;
  isFormSubmit: boolean;
  onClickSubmit: (status: string, action: string) => void;
  rolePermission: any;
  isPrint: boolean;
  isError?: boolean;
  action?: string;
  isButtonGroupDisabled?: boolean;
  isDisabledSave?: boolean;
  isDisabledDraft: boolean;
  onClickDelete: () => void;
};
function InvoiceFormFooter({
  editId,
  isLocked,
  lockDate,
  name,
  isFormSubmit,
  onClickSubmit,
  rolePermission,
  isPrint,
  isError,
  action,
  isButtonGroupDisabled,
  isDisabledSave,
  isDisabledDraft,
  onClickDelete,
}: Props) {
  const navigate = useNavigate();
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  return (
    <div className="ri-form-footer row mb-0">
      <div className="col">
        <div
          className={
            isFormSubmit && !exchangeRateAPiStatus
              ? "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          {isError ? (
            <div className="form-error-container w-100">
              <ErrorInfo
                message={`Oops, we were unable to ${action}. Please fix the errors.`}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-btn-container d-flex w-100">
              <button
                type="button"
                className="save-button mx-0"
                onClick={() => onClickSubmit("Open", "Send")}
                disabled={isDisabledSave ? isDisabledSave : false}
              >
                Save
              </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-decoration-none cancel-button mx-4"
            >
              Cancel
            </button>
            {/* {editId && rolePermission.includes("Delete") && (
              <Delete
                className={"text-decoration-none delete-item-button ml-auto"}
                deleteIcon={false}
                isMutable={true}
                onClick={onClickDelete}
                isLocked={isLocked}
                lockDate={lockDate}
                name={name}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceFormFooter;
