import React from "react";
import CountrySelect from "../../../../common/components/CountrySelect";
import { CreateAddressValues } from "../../../../../types";
import { SingleValue } from "react-select";
import StateSelect from "../../../../common/components/StateSelect";

type Props = {
  address: CreateAddressValues;
  errors: any;
  countryChange: (
    e: SingleValue<string | { label: string; value: number }>
  ) => void;
  stateChange: (
    e: SingleValue<string | { label: string; value: number }>
  ) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isDisabled: boolean;
  key: string;
};
function AddressForm({
  address,
  errors,
  countryChange,
  stateChange,
  handleChange,
  isDisabled,
  key,
}: Props) {
  return (
    <div className="address-fields-wrapper">
      <div className="row">
        <div className="col">
          <label htmlFor={`${key}_region`}>Country / Region</label>
          <CountrySelect
            id={`${key}_region`}
            countryId={address.country_id}
            onChange={countryChange}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor={`${key}_address`}>Address</label>
          <textarea
            name="address"
            id={`${key}_address`}
            className="form-control"
            rows={3}
            placeholder="Enter valid full address"
            value={address.address ? address.address : ""}
            onChange={handleChange}
            disabled={isDisabled}
          ></textarea>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor={`${key}_city`}>City</label>
          <input
            type="text"
            name="city"
            id={`${key}_city`}
            className="form-control"
            value={address.city ? address.city : ""}
            placeholder="Enter city name"
            onChange={handleChange}
            maxLength={200}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor={`${key}_state`}>State</label>
          <StateSelect
            id={`${key}_state`}
            countryId={address.country_id}
            stateId={address.state_id}
            onChange={stateChange}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor={`${key}_zip`}>Zip Code</label>
          <input
            type="text"
            name="zip_code"
            id={`${key}_zip`}
            className="form-control"
            value={address.zip_code ? address.zip_code : ""}
            placeholder="Enter a valid Zip code"
            onChange={handleChange}
            maxLength={15}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor={`${key}_phone`}>Phone Number</label>
          <input
            type="text"
            name="phone"
            id={`${key}_phone`}
            className={errors.phone ? "form-control error" : "form-control"}
            value={address.phone ? address.phone : ""}
            placeholder="000 000 0000"
            onChange={handleChange}
            maxLength={19}
            disabled={isDisabled}
          />
          <span className="error">{errors.phone}</span>
        </div>
      </div>
    </div>
  );
}

export default AddressForm;
