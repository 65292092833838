import { useState } from "react";
import { ObjectType, PaymentMadeJournalListPayload } from "../../../../types";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { generateJournalListPM } from "../paymentsMadeSlice";
export const useFetchJournalListPM = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [journals, setPMJournals] = useState<ObjectType>({});
  const getJournals = async (
    values: PaymentMadeJournalListPayload,
    editId: number,
    setJournals: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const response = await dispatch(
      generateJournalListPM({
        pmId: Number(editId),
        orgId: currentUserInfo.organization_id,
        values: { ...values },
      })
    );
    dispatch(setLoaderState(false));
    const responsePayload = response.payload;
    if (Object.keys(responsePayload).length && !("error" in responsePayload)) {
      setJournals(responsePayload);
      setPMJournals(responsePayload);
      return responsePayload;
    } else {
      dispatch(setLoaderState(false));
      return {};
    }
  };
  return { getJournals, journals };
};
