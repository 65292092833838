import * as yup from "yup";
export const TaxExemptionFormValidation = yup.object({
  exemption_reason: yup
    .string()
    .required("Please enter exemption reason.")
    .test(
      "exemption_reason",
      "Please enter a valid exemption reason.",
      function (value) {
        const exemption_reason = value?.trim();

        if (exemption_reason === "") {
          return false;
        }
        return true;
      }
    )
    .test(
      "exemption_reason",
      "Only alphanumeric characters are allowed.",
      function (value) {
        const alphnumericRegex = /^[\w\s]+$/;

        const exemption_reason = value?.trim();

        if (!alphnumericRegex.test(exemption_reason)) {
          return false;
        }
        return true;
      }
    ),
  description: yup
    .string()
    .required("Please enter description.")
    .test("description", "Please enter description.", function (value) {
      const description = value?.trim();

      if (description === "") {
        return false;
      }
      return true;
    }),
});
