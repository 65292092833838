import { useAppDispatch } from "../app/hooks";
import { setErrorID } from "../appSlice";
import { errorCodes } from "../features/constants/constants";
import UserService from "../keycloak/UserService";
import { ObjectType } from "../types";
import { ErrorToaster } from "./toastHelper";
import { store } from "../app/store";

type HeaderType = {
  Accept: string;
  auth?: string;
  "Content-Type": string;
};
const headers: HeaderType = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
function getErrorCode(
  input: string,
  status: number | null | undefined
): number | undefined {
  let code = 0;
  errorCodes.map((errorCode) => {
    const urlWords = input.toLowerCase().split("/");
    if (
      urlWords.includes(errorCode.error_module) &&
      errorCode.status_code === status
    ) {
      code = errorCode.error_code;
    }
  });
  return code;
}

function errorMessageHandler(e: any) {
  const pathname = window.location.pathname;
  if (e.code == 422) {
    ErrorToaster(
      `Something went wrong!\nERROR_ID: ${getErrorCode(pathname, e.code)}`,
      e.code
    );
  } else if (e.message == "Failed to fetch" || e.code === 500) {
    ErrorToaster(
      `Something went wrong!\nERROR_ID: ${getErrorCode(pathname, 500)}`,
      500
    );
  } else if ([403, 404].includes(e.code)) {
    store.dispatch(setErrorID(e.response.detail[0].error_code));
  } else if (
    e.response.detail &&
    e.response.detail[0].msg &&
    e.response.detail[0].error_code &&
    ![403, 404, 401].includes(e.code)
  ) {
    //9527 & 9526 - error codes of save customization in aging report
    ![5036, 9527, 9526].includes(e.response.detail[0].error_code) &&
      ErrorToaster(
        `${e.response.detail[0].msg}\nERROR_ID: ${e.response.detail[0].error_code}`,
        e.response.detail[0].error_code
      );
  } else if (e.code === 401) {
    ErrorToaster(
      `Invalid token!\nERROR_ID: ${e.response.detail[0].error_code}`,
      e.response.detail[0].error_code
    );
  } else {
  }
}

export async function post(
  url: string,
  data?: { [key: string]: any },
  signal?: Object
) {
  const token = sessionStorage.getItem("token");
  if (token) {
    headers["auth"] = token;
  }
  try {
    let fetchBody: ObjectType = {
      method: "POST",
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    };
    if (signal) {
      fetchBody.signal = signal;
    }
    const response = await fetch(url, fetchBody);
    const result = await response.json();
    if (!response.ok) {
      throw { code: response.status, response: result };
    }
    if (response.ok) {
      return result;
    }
    throw result;
  } catch (e: any) {
    errorMessageHandler(e);
    return errorHandler(e);
  }
}

export async function postFormData(url: string, data?: any) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        auth: token ? token : "",
      },
      body: data,
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
    if (!response.ok) {
      throw { code: response.status, response: result };
    }
    throw result;
  } catch (e: any) {
    errorMessageHandler(e);
    return errorHandler(e);
  }
}

export async function putFormData(url: string, data?: any) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        auth: token ? token : "",
      },
      body: data,
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
    if (!response.ok) {
      throw { code: response.status, response: result };
    }
    throw result;
  } catch (e: any) {
    errorMessageHandler(e);
    return errorHandler(e);
  }
}

export async function get(url: string, signal?: AbortSignal) {
  const token = sessionStorage.getItem("token");
  if (token) {
    headers["auth"] = token;
  }
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
      signal: signal ? signal : null,
    });
    const result = await response.json();
    if (!response.ok) {
      throw { code: response.status, response: result };
    }
    if (response.ok) {
      return result;
    }
    throw result;
  } catch (e: any) {
    errorMessageHandler(e);
    return errorHandler(e);
  }
}

export async function put(url: string, data?: { [key: string]: any }) {
  const token = sessionStorage.getItem("token");
  if (token) {
    headers["auth"] = token;
  }
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    });
    const result = await response.json();
    if (!response.ok) {
      throw { code: response.status, response: result };
    }
    if (response.ok) {
      return result;
    }
    throw result;
  } catch (e: any) {
    errorMessageHandler(e);
    return errorHandler(e);
  }
}

export async function remove(url: string, data?: { [key: string]: any }) {
  const token = sessionStorage.getItem("token");
  if (token) {
    headers["auth"] = token;
  }
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
    if (!response.ok) {
      throw { code: response.status, response: result };
    }
    throw result;
  } catch (e: any) {
    errorMessageHandler(e);
    return errorHandler(e);
  }
}

/**
 * Handle all api errors
 */
export function errorHandler(e: any) {
  if (e.response.detail[0].msg === "Could not validate credentials") {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh-token");
    UserService.doLogout();
    sessionStorage.removeItem("orgId");
    sessionStorage.removeItem("rolePermissions");
    sessionStorage.removeItem("organizationRole");
    sessionStorage.removeItem("OrgName");
  }
  if (e.name === "AbortError") {
    return { error: "Request was aborted." };
  }
  return { error: e };
}
