import { colors } from "../../constants/colors";
import "../styles/AlertModal.css";

type Props = {
  heading: string;
  subHeading: string;
  triggerAction?: string;
  transactionName: string | string[];
  modalCancelHandler: () => void;
  modalSubmitHandler: () => void;
};

const StopResumeModal = (props: Props) => {
  return (
    <>
      <div
        className="modal alert-modal fade"
        id="stopResumeMessageModal"
        tabIndex={-1}
        aria-labelledby="delete_title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered delete-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header position-relative">
                <button
                  type="button"
                  className={`close-btn close-delete-btn pull-right position-absolute`}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z" />
                      <path
                        fill={colors.slate}
                        fillRule="nonzero"
                        d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                      />
                    </g>
                  </svg>
                </button>
                <h5 className="modal-title" id="delete_title">
                  {props.heading}
                </h5>
              </div>
              <div className="modal-body">
                <p>
                  {props.subHeading}{" "}
                  {props.transactionName ? (
                    <>
                      <strong>{props.transactionName}</strong>
                      <span> ?</span>
                    </>
                  ) : null}
                </p>
              </div>
              <div className="modal-footer" id="delete-button">
                <button
                  type="button"
                  className="btn btn-cancel btn-green btn-color-red"
                  onClick={props.modalCancelHandler}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-ok btn-red btn-color-green"
                  onClick={props.modalSubmitHandler}
                >
                  {props.triggerAction ? props.triggerAction : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StopResumeModal;
