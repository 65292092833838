import { useEffect, useRef, useState } from "react";
import { Transactionlimit } from "../../../../../types";
import { getTransactionLimitStatus } from "../../debitNoteSlice";
import Pagination from "../../../../common/components/table/Pagination";
import DeleteDebitNote from "../Delete";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";
import DebitNoteTableContainer from "./debitNoteTable/DebitNoteTableContainer";
import ListHeader from "../../../../common/components/list/ListHeader";
import useCommonData from "../../../../hooks/useCommon";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

const DebitNoteList = () => {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { page, pageCount, itemsPerPage, totalList, dateSortOrder } =
    useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { debitNoteRolePermission } = usePermissionHandler();
  const [deleteDebitNoteId, setDeleteDebitNoteId] = useState(-1);
  const [deleteDebitItemId, setDeleteDebitItemId] = useState("");
  const [debitNoteStatus, setDebitNoteStatus] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  useEffect(() => {
    fetchTransactionLimit();
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Invoke when user click to request another page
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * Set delete data details
   */
  const deleteListData = (id: number, listNum: string, status: string) => {
    setDeleteDebitNoteId(id);
    setDeleteDebitItemId(listNum);
    setDebitNoteStatus(status);
  };

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  const refreshList = () => {
    filterRef.current.refreshFilter();
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Debit Notes"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Add Debit Note"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/debit-note/create");
          }}
          haveFilter={true}
          filterType="DN"
          applyFilter={handleApplyFilter}
          roles={debitNoteRolePermission}
          ref={filterRef}
          module="DebitNote"
          isFiscalYearFilterRequired={false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <DebitNoteTableContainer
            transactionLimit={transactionLimit}
            initializeFilter={refreshList}
            isFilterApplied={isFilterApplied}
            deleteListData={deleteListData}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteDebitNote
        deleteDebitNoteId={deleteDebitNoteId}
        deleteDebitItemId={deleteDebitItemId}
        debitNoteStatus={debitNoteStatus}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshDebitNotes={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
};
export default DebitNoteList;
