import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Transactionlimit, VendorList } from "../../../../../../types";
import useCommonData from "../../../../../hooks/useCommon";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useListData from "../../../../../hooks/useListData";
import { useAppSelector } from "../../../../../../app/hooks";
import { listFilterSelector } from "../../../../../../appSlice";
import { setLoaderState } from "../../../../../common/commonSlice";
import { vendorsList } from "../../../vendorSlice";
import CustomTooltips from "../../../../../common/components/Tooltip";
import Popover from "../../../../../common/components/Popover";
import KeywordHighlight from "../../../../../common/components/KeywordHighlight";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import VendorTable from "./VendorTable";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import ListActions from "../ListActions";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import TableSetup from "../../../../../common/components/table/TableSetup";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import {
  vendorColumnValues,
  vendorDefaultValues,
  vendorMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";

type Props = {
  vendorName: string;
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  setTotalResults: (value: number) => void;
  deleteListData: (id: number, name: string) => void;
};
function VendorTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const [vendorData, setVendorData] = useState<VendorList[]>([]);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const orgID = useRef(currentUserInfo.organization_id);
  const { vendorRolePermission } = usePermissionHandler();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();

  useImperativeHandle(
    ref,
    () => ({
      fetchVendorList: fetchVendorList,
    }),
    []
  );
  useEffect(() => {
    fetchVendorList();
  }, [
    page,
    itemsPerPage,
    props.vendorName,
    // orgID.current,
    selectedFilterData,
    currentUserInfo.organization_id,
  ]);
  useEffect(() => {
    fetchTableCustomizationFields(
      "Vendors",
      vendorDefaultValues,
      vendorMandatoryValues
    );
  }, [page, itemsPerPage, currentUserInfo.organization_id]);
  useEffect(() => {
    let hiddenItems: string[] = [];
    vendorColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [vendorData, selectedColumns]);
  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch all vendors
   */
  const fetchVendorList = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      vendorsList({
        page,
        itemsPerPage,
        vendorName: props.vendorName.trim(),
        orgId: sessionStorage.getItem("orgId")
          ? Number(sessionStorage.getItem("orgId"))
          : currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
      })
    );
    if (responseAction.payload) {
      // To remove suggestion box when search result is displayed.
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        x[i].parentNode?.removeChild(x[i]);
      }
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setVendorData(response.vendors);
        if (response.vendors.length) {
          let length = response.vendors.length;
          setTotalList(response.total);
          if (props.vendorName !== "") {
            props.setTotalResults(response.total);
          }
          setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else {
          setTotalList(response.total);
          props.setTotalResults(response.total);
        }
      } else if ("error" in response) {
      }
      dispatch(setLoaderState(false));
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "show.name",
        sort: false,
        sortOrder: "",
        className: "name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value : ""}
            >
              <span className="ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Email",
        accessor: selectedColumns.includes("Email") ? "show.email" : "Email",
        sort: false,
        sortOrder: "",
        className: "email",
      },
      {
        Header: "Phone Number",
        accessor: selectedColumns.includes("Phone Number")
          ? "show.phoneNumber"
          : "Phone Number",
        sort: false,
        sortOrder: "",
        className: "phone_num",
      },
      {
        Header: "Payables",
        accessor: "show.payables",
        sort: false,
        sortOrder: "",
        className: "balance",
      },
      {
        Header: "Unused Credits",
        accessor: "show.unusedCredits",
        sort: false,
        sortOrder: "",
        className: "balance",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Vendors"
            tableColumns={selectedColumns}
            customizeColumnValues={vendorColumnValues}
            mandatoryColumns={vendorMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Vendors",
                vendorDefaultValues,
                vendorMandatoryValues
              );
              fetchVendorList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !vendorRolePermission.includes("Update") &&
            !vendorRolePermission.includes("Delete") ? null : (
            <ListActions
              value={value}
              onClickDelete={() => {
                if (!value.transactionExist) {
                  props.deleteListData(value.id, value.listName);
                }
              }}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, vendorRolePermission]
  );

  const data = vendorData
    ? vendorData.map((vendor, index) => ({
        show: {
          name: (
            <KeywordHighlight
              name={vendor.vendor_display_name}
              searchedName={props.vendorName}
            />
          ),
          email: vendor.vendor_email,
          phoneNumber: vendor.vendor_phone,
          payables: vendor.opening_balance
            ? vendor.currency.code +
              " " +
              NumberFormat(
                Number(vendor.opening_balance),
                vendor.currency.code ? vendor.currency.code : "",
                orgCurrencyList
              )
            : vendor.currency.code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  vendor.currency.code
                    ? vendor.currency.code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          unusedCredits: vendor.unused_credit
            ? vendor.currency.code +
              " " +
              NumberFormat(
                vendor.unused_credit,
                vendor.currency.code,
                orgCurrencyList
              )
            : vendor.currency.code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  vendor.currency.code
                    ? vendor.currency.code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          organization: vendor.organization,
          actions: {
            id: vendor.id,
            transactionExist: vendor.transaction_exist,
            listName: vendor.vendor_display_name,
            openingBalance: vendor.opening_balance,
            organizationId: vendor.organization_id,
          },
        },
      }))
    : [];
  return (
    <VendorTable
      totalList={totalList}
      startCount={startCount}
      endCount={endCount}
      itemsPerPage={itemsPerPage}
      vendorRoles={vendorRolePermission}
      columns={columns}
      data={data}
      vendorName={props.vendorName}
      isFilterApplied={props.isFilterApplied}
      initializeFilter={props.initializeFilter}
      transactionLimit={props.transactionLimit}
      setPageInfo={(countPerPage, page) => {
        setItemsPerPage(countPerPage);
        setPage(page);
      }}
      hiddenColumns={hiddenColumns}
    />
  );
}

export default React.memo(forwardRef(VendorTableContainer));
