import { InfoIconImg } from "../../../../assets/images";
import CustomTooltips from "../../../common/components/Tooltip";
import CustomizePrefix from "./CustomizePrefix";

function CustomizePrefixContainer() {
  return (
    <div className="card card-user-management card-prefix-customization main-card overflowX-hidden h-100">
      <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
        <h1>
          Customize Prefix
          <CustomTooltips title={"Editing the prefix would be reflected for all new transactions. Existing transactions would retain the older prefix"} placement="right"> 
            <a
              href="#"
              className="info-btn position-relative"
              onClick={(e) => e.preventDefault()}
            >
              <img src={InfoIconImg} width="16" alt="info icon" />
            </a>
          </CustomTooltips>
        </h1>
      </div>
      <div className="card-body">
        <CustomizePrefix />
      </div>
    </div>
  );
}

export default CustomizePrefixContainer;
