import { ForexGainLossValues, ObjectType } from "../../../types";
import {
  calculateForexGainOrLoss,
  currentUserSelector,
} from "../../common/commonSlice";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

type Props = {
  payloadInput: ForexGainLossValues;
  forex?: ObjectType | null;
  isPayloadChanged?: boolean;
};
export const useCalculateForex = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [result, setResult] = useState<ObjectType>({});
  const dispatch = useAppDispatch();
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    forexApiCall([props.payloadInput], signal);
    return () => {
      abortController.abort();
    };
  }, [JSON.stringify(props.payloadInput)]);
  const forexApiCall = async (
    payloadInput: ForexGainLossValues[],
    signal: AbortSignal
  ) => {
    if (
      payloadInput[0].amount &&
      payloadInput[0].entity_id &&
      payloadInput[0].source_conversion_id &&
      payloadInput[0].target_conversion_id &&
      payloadInput[0].target_type &&
      props.isPayloadChanged === true
    ) {
      const responseAction = await dispatch(
        calculateForexGainOrLoss({
          values: payloadInput,
          orgId: currentUserInfo.organization_id,
          signal: signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        setResult(response);
      }
    } else if (payloadInput[0].amount === 0) {
      setResult({ [payloadInput[0].entity_id]: 0 });
    } else if (props.forex !== null) {
      setResult(props.forex || {});
    }
  };
  return result;
};
