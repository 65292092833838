import moment from "moment"
import { RangePriceValue } from "../features/home/subscription/subscriptionType"
import { getFormatedDate } from "./helper"

const acceptableFrequency = [
    {frequency: 'YEARLY', allowedFreq: ['YEARLY', "MONTHLY"]},
    {frequency: 'MONTHLY', allowedFreq: ["MONTHLY"]},
    {frequency: 'WEEKLY', allowedFreq: ['WEEKLY', "DAILY"]},
    {frequency: 'DAILY', allowedFreq: ['DAILY']},
]

/** Filter the Addon frequency dropdown on the basis of Plan frequency selection */
export const filterAddonFrequency = (planFrequency: string, addonFrequency: string) => (acceptableFrequency.find((data: any) => data.frequency === planFrequency)?.allowedFreq.includes(addonFrequency))

export const formateDateMonth = (date: string) => {
    const dateFormatted = new Date(date)
    const formattedDateWithMonth = dateFormatted.toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).split(" ").join("-")
    if (formattedDateWithMonth === 'Invalid-Date') return ''
    return formattedDateWithMonth
}

export const totalRangeAmount = (
  priceRange: RangePriceValue[],
  quantity: number
) => {
  let totalQuantity = quantity;
  let totalAmount = 0;
  priceRange.forEach((data, index) => {
    if (data.price_per_step) {
      if (data?.range_to && totalQuantity > 0 && data.range_to >= quantity) {
        totalAmount += totalQuantity * Number(data.price_per_step);
        totalQuantity = 0
      } else if (data?.range_to && totalQuantity > 0 && data.range_to <= quantity && data.range_from) {
        totalAmount += ((data.range_to - data.range_from) + 1) * Number(data.price_per_step);
        totalQuantity -= ((data.range_to - data.range_from) + 1);
      } else if ( index === (priceRange.length - 1) && totalQuantity > 0) {
        totalAmount += totalQuantity * Number(data.price_per_step);
      }
    }
  });
  return totalAmount;
};

export const getNextDate = () => {
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  return currentDate
}

export const findRangeDataPrice = (
  priceRange: RangePriceValue[],
  quantity: number) => {
    let totalAmount = 0;
    priceRange.forEach((data, index) => {
      if (data.price_per_step) {
        if (data?.range_to && data?.range_from && data.range_from <= quantity && data.range_to >= quantity) {
          totalAmount = Number(data.price_per_step)
        } else if (index === (priceRange.length - 1) && data?.range_from && data.range_from <= quantity) {
          totalAmount = Number(data.price_per_step)
        }
      }
    });
    return totalAmount;
}

export const getValidPastDate = (subscriptionPeriod: string) => {

  const currentDate =  moment()
  const format = 'YYYY-MM-DD'
  let previousDate =  moment();
  switch(subscriptionPeriod) {
      case 'DAILY':
          previousDate = currentDate;
          break;
      case 'WEEKLY':
          previousDate = currentDate.subtract(1, 'weeks').add(1, 'days');
          break;
      case 'MONTHLY':
          previousDate = currentDate.subtract(1, 'months').add(1, 'days');
          break;
      case 'YEARLY':
          previousDate = currentDate.subtract(1, 'years').add(1, 'days');
          break;
      default:
  }
  return previousDate.toDate();
}