import { Container } from "../htmlTags/Container";
import { SaveButton, SecondaryButton } from "../button";
import { Span } from "../htmlTags/Span";
import "./CancelMarkedPopup.css";
import { CancelMarkedBodyProps } from "./types";

export const CancelMarkedPopupBody = (props: CancelMarkedBodyProps) => {
  return (
    <Container className="cancel-mark-popup-content-wrapper">
      <Container className="">
        <Span>Are you sure you want to delete this? It can't be undone.</Span>
      </Container>
      <Container className="cancel-mark-popup-btn">
        <SaveButton
          type="button"
          isDisabled={false}
          buttonContent="Yes Proceed"
          onClick={props.onSave}
        />
        <SecondaryButton
          type="button"
          isDisabled={false}
          buttonContent="Decline"
          onClick={props.onCancel}
        />
      </Container>
    </Container>
  );
};
