import { post } from "../../../../helpers/apiHelper";
import { Api } from "../../../../constants";
import { get } from "../../../../helpers/apiHelper";
import { ExchangeRateValues } from "../../../../types";

/**
 * API call for getting exchange rate with respect to amount
 */
export async function getConversionFromAmount(data: {
  amount: number;
  amount_bank_currency: number;
  to_currency_id: number;
  orgId: number;
  signal: AbortSignal;
}) {
  const result = await get(
    Api.currency +
      Api.exchangeRate +
      `/reverse-rate/calculate?amount=${data.amount}&amount_bank_currency=${data.amount_bank_currency}&to_currency_id=${data.to_currency_id}`,
    data.signal
  );
  return result;
}


/**
 * API call for creating exchange rate
 */
export async function createReverseConversion(data: { values: ExchangeRateValues }) {
  const result = await post(
    Api.currency + Api.exchangeRate + "/reverse/create",
    data.values
  );
  return result;
}