import { Api } from "../../../constants";
import { get, post, remove } from "../../../helpers/apiHelper";
import { TCSFormValues } from "../../../types";

/**
 * API call for getting all TCS
 */
export async function getTcsList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.tcsConfiguration}/list?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for Creating or Updating TCS tax
 */
export async function createNewTcs(data: {
  values: TCSFormValues;
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount +
      `${Api.tcsConfiguration}/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call to get nature of collection drop down
 */
export async function getCollectionDropDown(orgId: number) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.tcsConfiguration}/nature-of-collection-dropdown?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting TCS drop down
 */
export async function getTcsDropDown(data: { orgId: number; tcsId: number[] }) {
  let result =
    Api.chartOfAccount +
    `${Api.tcsConfiguration}/drop-down?current_organization=${data.orgId}`;

  data.tcsId.map((id) => {
    result += `&tcs_ids=${id}`;
  });
  return await get(result);
}

/**
 * API call for delete TCS
 */
export async function deleteTcs(data: { tcsId: number; orgId: number }) {
  const result = await remove(
    Api.chartOfAccount +
      `${Api.tcsConfiguration}/delete/${data.tcsId}?current_organization=${data.orgId}`
  );
  return result;
}
