import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { consumerDetailsValues } from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import NotificationModal from "../../../../common/components/NotificationModal";
import { customersList } from "../../customerSlice";
import DeleteCustomer from "../Delete";
import OrgTag from "../../../../common/components/OrgTag";
import Edit from "../../../../common/components/kebabMenu/Edit";

type Props = {
  consumerDetails: consumerDetailsValues;
};
const CustomerDetailHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const consumerData = props.consumerDetails;
  const [deleteCustomerId, setDeleteCustomerId] = useState(-1);
  const [deleteCustomerName, setDeleteCustomerName] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let customerRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Customer")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Back button handler from customer details page
   */
  const backFromCustomerViewHandler = () => {
    // if (editId) {
    //   navigate("/customers");
    // } else {
    //   navigate("/customers/create");
    // }
    navigate(-1);
  };
  const fetchCustomerListAfterDelete = async () => {
    await dispatch(
      customersList({
        page: page,
        itemsPerPage: itemsPerPage,
        customerName: "",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
      })
    );
    navigate(-1);
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromCustomerViewHandler}
        data={{
          num: consumerData?.display_name,
          status: "",
        }}
        dataId={editId}
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          {consumerData ? (
            <>
              <div className="left-group">
                {consumerData.email && (
                  <span className="biller-label ps-2">
                    {consumerData.email}
                  </span>
                )}
                {consumerData.email && consumerData.phone && <span>|</span>}
                {consumerData.phone && (
                  <span className="bill-date pe-2 ps-2">
                    {consumerData.phone}
                  </span>
                )}
                {(consumerData.email || consumerData.phone) && <span>|</span>}
                <span>
                  <OrgTag organization={consumerData.organization_name} />
                </span>
              </div>

              <div className="actions-wrap d-flex flex-no-shrink">
                {customerRolePermission.includes("Update") ? (
                  <Edit
                    url={"/customers/edit/" + consumerData.id}
                    isMutable={true}
                    name="Customer"
                    isLocked={false}
                    className=""
                    lockDate=""
                  />
                ) : null}
                {customerRolePermission.includes("Delete") ? (
                  <Delete
                    className="text-decoration-none delete-item"
                    deleteIcon={true}
                    isMutable={consumerData.is_mutable}
                    onClick={() => {
                      if (consumerData?.is_mutable) {
                        setDeleteCustomerId(Number(consumerData.id));
                        setDeleteCustomerName(consumerData.display_name);
                      }
                    }}
                    isLocked={false}
                    lockDate=""
                    name="Customer"
                    isTransactionExist={props.consumerDetails.transaction_exist}
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <DeleteCustomer
        deleteCustomerId={deleteCustomerId}
        deleteCustomerName={deleteCustomerName}
        page={page}
        itemsPerPage={itemsPerPage}
        customerName=""
        refreshList={fetchCustomerListAfterDelete}
        organizationId={currentUserInfo.organization_id}
      />
      <NotificationModal
        modalHeader="Unable to delete customer!"
        modalBody="The customer cannot be deleted as there are dependent transactions."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};

export default CustomerDetailHeader;
