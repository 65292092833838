export const initialSubAccountValues = {
  id: 0,
  account_name: "",
};

export const initialTaxRateFormValues = {
  account_type: "",
  tax_code: "",
  account_id: 0,
};
