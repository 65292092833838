import React from "react";
import { AddTagIcon } from "../../../assets/images";

function AddLineItem({ onClick }: { onClick: () => void }) {
  return (
    <div className="w-100 add-line-button-wrapper">
      <div className="w-100 add-line d-flex align-items-center">
        <a
          href="#"
          role="button"
          className="add-tags-button add-line-button d-flex align-items-center "
          onClick={(e) => e.preventDefault()}
        >
          <img src={AddTagIcon} alt="" />
          <span className="px-2" onClick={onClick}>
            Add another line
          </span>
        </a>
      </div>
    </div>
  );
}

export default React.memo(AddLineItem);
