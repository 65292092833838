import { useState } from "react";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import "./planBillingSideMenuForm.css";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import {
  planInvoicingInitialValue,
} from "../calenderBillingStateInitialization";
import { PlanInvoicingInitialType } from "../calendarBillingTypes";
import { SelectValues } from "../../../../types";
import { Link } from "react-router-dom";
import { EditDropdownIcon } from "../../../common/components/customSvgIcons/editDropdownIcon";
import { useFetchListDetails } from "../hooks/useFetchBillingList";
import { PlanBillingSideMenuForm } from "./PlanBillingSideMenuForm";

type Props = {
  refreshPlanSpecificList: () => void;
  id?: number;
};

export const PlanBillingSideMenuModal = (props: Props) => {
  const { subscriptionRolePermission } = usePermissionHandler();
  const [planSpecificDropdown, setPlanSpecificDropdown] = useState<
    SelectValues[]
  >([]);
  const [planCount, setPlanCount] = useState<number>(1);
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(false);
  const { getListDetails } = useFetchListDetails();
  const [initialValues, setInitialValues] = useState<PlanInvoicingInitialType>({
    ...planInvoicingInitialValue
  });

  const ConfigureButton = () => {
    return (
      <>
        {props.id ? (
          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              getListDetails(
                props.id,
                setInitialValues,
                setPlanSpecificDropdown,
                setPlanCount,
                setIsNextEnabled
              );
            }}
          >
            <>
              <EditDropdownIcon />
              Edit
            </>
          </Link>
        ) : (
          <div>
            {subscriptionRolePermission.includes("Create") ? (
              <button
                id="calendar-billing-button"
                className="btn create-invoice-button green-button right-button sub-add-coupon-btn-green-background"
                type="button"
              >
                <img
                  src="/static/media/plus_small.2f281160561f3c06b529f10af809e435.svg"
                  className="Group"
                  alt="plus-small-img"
                />
                <span className="create-invoice-button-text green-button-text">
                  Create Plan Specific Calendar Invoicing
                </span>
              </button>
            ) : null}
          </div>
        )}
      </>
    );
  };

  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onSaveClose } = context;
            return (
              <PlanBillingSideMenuForm
                refreshPlanSpecificList={() => props.refreshPlanSpecificList()}
                id={props.id}
                onCloseModal={() => onSaveClose()}
                planSpecificDropdown={planSpecificDropdown}
                setPlanSpecificDropdown={setPlanSpecificDropdown}
                planCount={planCount}
                setPlanCount={setPlanCount}
                isNextEnabled={isNextEnabled}
                setIsNextEnabled={setIsNextEnabled}
                initialValues={initialValues}
             />
            );
          }}
        </ModalContext.Consumer>
      }
      onCloseFunction={() => {}}
      modalTitle="Plan-Specific Invoicing"
      isButtonDisabled={false}
      buttonContent={<ConfigureButton />}
      drawerWrapperClass="calendar-billing billing-and-proration-modal"
      wrapperClass={`${
        !props.id ? "calendar-billing-button" : "calendar-billing-button-edit"
      } w-100 d-flex`}
      buttonClass={`add-coupon-button add-tags-button`}
    />
  );
};
