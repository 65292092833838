import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import "../../../../index.css";
import "./ExpandableTable.css";
import EmptyReport from "../EmptyReport";

export const ExpandableTable = ({
  columns: userColumns,
  data,
  rowsToExpand,
  isAllRowExpanded,
  tableClassName,
  key,
  tbodyColumnClick,
  hiddenColumns,
  rowClassName,
}: any) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  useEffect(() => {
    rowsToExpand && setExpanded(rowsToExpand);
    isAllRowExpanded === true && table.toggleAllRowsExpanded();
  }, [rowsToExpand]);
  const table = useReactTable({
    data,
    columns: userColumns,

    state: {
      expanded: expanded,
      columnVisibility: hiddenColumns,
    },

    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  return (
    <div>
      <table
        className={`expandable-table ${tableClassName ? tableClassName : ""}`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getExpandedRowModel().rows.length > 0 ? (
            table.getExpandedRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className={`
                  ${rowClassName ? rowClassName + " " : ""} ${
                    row.original?.className ? row.original?.className : ""
                  }`}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    return (
                      <td
                        key={cell.id}
                        className={(cell.column.columnDef as any).className}
                        onClick={(e) =>
                          tbodyColumnClick(cellIndex, row, e, cell)
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <div className="table-collapsible-item auto-flex">
              <div className="item-row empty-row w-100">
                <div className="item-col noitem-col align-left">
                  <EmptyReport
                    heading="No transactions found!"
                    alt="Empty report"
                  />
                </div>
              </div>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};
