import React, { useEffect, useState, useId } from "react";

import "../../../../Home.css";
import "./systemVoucherConfigurationList.css";
import { Container } from "../../../../../common/components/htmlTags/Container";

import useCommonData from "../../../../../hooks/useCommon";

import {
  deleteSystemVoucherConfigurationItem,
  getSystemVoucherConfigList,
} from "../../../systemVoucherSlice";
import SystemVoucherConfigurationModal from "../../systemVoucherConfiguration/systemVoucherConfigurationModal";
import List from "../../../../../common/components/ui/formElements/Ul/Ul";
import Li from "../../../../../common/components/ui/formElements/Li/Li";
import { DeleteIcon, EditIcon } from "../../../../../common/components";
import { colors } from "../../../../../constants/colors";
import { Button } from "../../../../../common/components/ui/buttons/button/Button";
import { AddTagIcon } from "../../../../../../assets/images";
import { DeleteIconModal } from "../../../../../common/components/DeleteIconModal/deleteIconModal";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../../helpers/toastHelper";

type Props = {
  id?: string;
  handleModalClose: () => void;
};
const SystemVoucherConfigurationList = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [systemVoucherTypeList, setSystemVoucherTypeList] = useState<any>({
    system_voucher_configs: [],
  });

  useEffect(() => {
    getSystemVoucherTypeList();
  }, [currentUserInfo.organization_id]);

  //get Voucher type list
  const getSystemVoucherTypeList = async () => {
    const responseAction = await dispatch(
      getSystemVoucherConfigList({
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setSystemVoucherTypeList(response);
      }
    }
  };

  const handleSystemVoucherListItemDelete = async (
    systemConfigId: number,
    isConfigUsed: boolean
  ) => {
    if (!isConfigUsed) {
      let responseAction = await dispatch(
        deleteSystemVoucherConfigurationItem({
          systemVoucherId: systemConfigId,
          orgId: currentUserInfo.organization_id,
        })
      );
      let response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        await getSystemVoucherTypeList();
        SuccessToaster(
          "System voucher type removed successfully",
          "system-voucher-type-remove-success"
        );
        // setIsDeletedAttempted(true);
      }
    } else {
      ErrorToaster(
        `Unable to delete this system voucher configuration, as transactions are associated with it`
      );
    }
  };

  const AddSystemVoucherTypeButton = () => {
    return (
      <span>
        <img src={AddTagIcon} alt="add system voucher type" />
        Add System Voucher Type
      </span>
    );
  };
  return (
    <>
      <Container className="system-voucher-configuration-field-wrapper">
        <Container className="w-100 clear-both add-button-container">
          <SystemVoucherConfigurationModal
            buttonContent={<AddSystemVoucherTypeButton />}
            handleRefreshList={getSystemVoucherTypeList}
          />
        </Container>
        <Container className="w-100 clear-both list-container">
          {systemVoucherTypeList?.system_voucher_configs.length > 0 ? (
            <List wrapperClass="system-voucher-type-list w-100 p-0">
              {systemVoucherTypeList.system_voucher_configs?.map(
                (systemVoucherConfig: any) => {
                  return (
                    <Li
                      key={0}
                      wrapperClass="system-voucher-type-list-item w-100"
                    >
                      <Container className="system-voucher-type d-flex">
                        <Container className="label">
                          {systemVoucherConfig.system_voucher_name}
                        </Container>
                        <Container className="icon-buttons-wrapper edit-delete-icon-buttons-wrapper text-align-right">
                          <SystemVoucherConfigurationModal
                            buttonWrapperClass="edit-icon-btn"
                            buttonContent={
                              <EditIcon
                                color={colors.ceruleanBlue}
                                height="12px"
                                width="12px"
                              />
                            }
                            systemConfId={systemVoucherConfig.id}
                            handleRefreshList={getSystemVoucherTypeList}
                          />
                          <DeleteIconModal
                            title="Delete System Voucher Configuration"
                            content={
                              <>
                                Are you sure you want to delete this system
                                voucher configuration{" "}
                                <strong>
                                  {systemVoucherConfig.system_voucher_name}
                                </strong>
                                ?
                              </>
                            }
                            onDeleteConfirm={() => {
                              handleSystemVoucherListItemDelete(
                                systemVoucherConfig.id,
                                systemVoucherConfig.is_config_used
                                  ? systemVoucherConfig.is_config_used
                                  : false
                              );
                            }}
                          />
                        </Container>
                      </Container>
                    </Li>
                  );
                }
              )}
            </List>
          ) : (
            ""
          )}
        </Container>
      </Container>
    </>
  );
};

export default SystemVoucherConfigurationList;
