import Delete from "../../../../common/components/Delete";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { PriceListActionValue } from "../../planType";

export type PricingListActionsProps = {
  value: PriceListActionValue;
  onClickDelete: (
    actionType: string,
    id: number,
    planName: string,
    planExistsSubscription: boolean
  ) => void;
};

function PricingListActions(props: PricingListActionsProps) {
  return (
    <>
      {!props.value.pricingExistsSubscription && (
        <KebabMenu>
          <li>
            <Delete
              className="dropdown-item delete"
              deleteIcon={true}
              isMutable
              onClick={() =>
                props.onClickDelete(
                  "Price",
                  props.value.pricngId,
                  props.value.frequency,
                  false
                )
              }
              isLocked={false}
              lockDate={""}
              name="Plan"
            />
          </li>
        </KebabMenu>
      )}
    </>
  );
}

export default PricingListActions;
