import React, { useEffect, useState } from "react";
import "../AddressListModal.css";
import { WhitePlusSmall } from "../../../../../../assets/images";
import { AddressValues } from "../../../../../../types";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../../../../app/hooks";
import {
  addBillingAddress,
  editBillingAddress,
} from "../../../../customerManagement/components/customerForm/AddressDetails/addressTypeSlice";

type Props = {
  type: string;
  billingModalClose: boolean;
  modalCloseHandler: (type: string) => void;
  createBillingAddress: (address: AddressValues) => void;
  customerData: AddressValues[] | undefined;
  selectBillingAddressHandler: (index: number | undefined | string) => void;
  selectedBillingAddressId: number | string;
  openAddressModal: () => void;
  setAddressIdHandler: (id: number | string) => void;
};

const BillingAddressList = (props: Props) => {
  const dispatch = useAppDispatch();
  const [displayList, setDisplayList] = useState(false);
  $(document)
    .off()
    .on("click", (e: any) => {
      if (
        $(e.target).closest(".address-list-container").length === 0 &&
        $(e.target).closest(".show-address-list").length === 0
      ) {
        setDisplayList(false);
        props.modalCloseHandler(props.type);
      }
    });

  useEffect(() => {
    if (props.billingModalClose) {
      setDisplayList(false);
    }
  }, [props.billingModalClose]);

  const displayListHandler = () => {
    setTimeout(() => {
      setDisplayList(false);
    }, 400);
  };

  return (
    <>
      <div className="edit-address-wrapper position-relative">
        <Link
          to="#"
          role="button"
          className="edit-address-link show-address-list"
          onClick={(e: any) => {
            e.preventDefault();
            setDisplayList(true);
            props.modalCloseHandler(props.type);
          }}
        >
          Edit
        </Link>
        {displayList ? (
          <div className="address-list-container position-absolute">
            <ul className="address-list">
              {props.customerData?.map((item, index) => {
                return item.is_billing ? (
                  <li
                    className={`list-item ${
                      item.id === props.selectedBillingAddressId
                        ? "current"
                        : ""
                    }`}
                    key={index}
                  >
                    <div
                      className="radio-container"
                      onMouseDownCapture={() => {
                        props.createBillingAddress(item);
                        displayListHandler();
                        props.selectBillingAddressHandler(item.id);
                      }}
                    >
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="address1"
                          name="option_main_contact"
                          value="main contact person"
                          checked={
                            item.id === props.selectedBillingAddressId
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />
                        <label
                          className="form-check-label fw-normal"
                          htmlFor="address1"
                        >
                          <span>{item.address}</span>
                          <span>
                            {item.city}
                            {item.city && (item.state_name || item.country_name)
                              ? ", "
                              : ""}
                            {item.state_name}
                            {item.state_name ? ", " : ""}
                            {item.country_name}
                          </span>
                          <span>
                            {item.zip_code ? "PIN " + item.zip_code : ""}
                          </span>
                          <span>
                            {item.phone ? "Phone Number : " + item.phone : ""}
                          </span>
                        </label>
                      </div>
                    </div>
                    <Link
                      to="#"
                      role="button"
                      className="edit-address-link show-address-list"
                      onClick={(e) => {
                        e.preventDefault();
                        props.setAddressIdHandler(item.id ? item.id : "");
                        props.openAddressModal();
                        dispatch(editBillingAddress("edit_billing"));
                      }}
                    >
                      Edit
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>
            <button
              type="button"
              className="btn green-button add-address-btn w-100"
              onClick={() => {
                props.setAddressIdHandler("");
                props.openAddressModal();
                dispatch(addBillingAddress("add_billing"));
              }}
            >
              <img
                src={WhitePlusSmall}
                className="Group"
                alt="plus-small-img"
              />
              Add Address
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default BillingAddressList;
