import { useNavigate, useParams } from "react-router-dom";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { JournalItemValues, JournalValues } from "../../../../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import {
  createJournalVoucher,
  deleteJournalItemDetails,
  deleteJournalRecipientDetails,
  editJournalDetails,
} from "../../journalVoucherSlice";
import { payloadRefiner } from "../helpers/payloadRefiner";

export const useJVFormSubmission = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const navigate = useNavigate();
  const { editId } = useParams();
  /**
   * Create new journal voucher
   */
  const createJournalSubmit = async (
    journalPayload: any,
    journalStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    const createResponseAction = await dispatch(
      createJournalVoucher({
        values: payloadRefiner(journalPayload, "create") as JournalValues,
        status: journalStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      dispatch(setLoaderState(false));
      if (journalStatus === "Draft") navigate(-1);
      else
        navigate(`/journal-entry/detail/${createResponse.id}`, {
          replace: true,
        });

      SuccessToaster(
        "Journal entry created successfully!",
        "journal-voucher-create-success"
      );
    } else if ("error" in createResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const updateJournalSubmit = async (
    journalPayload: any,
    journalStatus: string,
    deleteItems: any,
    deleteEmailIds: string[]
  ) => {
    dispatch(setLoaderState(true));
    if (Array.isArray(deleteItems) && deleteItems.length) {
      for (let item of deleteItems) {
        let res = await dispatch(
          deleteJournalItemDetails({
            journalId: Number(editId),
            itemId: Number(item.itemId as any),
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }
    if (Array.isArray(deleteEmailIds) && deleteEmailIds.length) {
      let deleteContactIds: number[] = [];
      deleteEmailIds.map((emaiId) => {
        deleteContactIds.push(Number(emaiId));
      });
      if (deleteContactIds.length) {
        await dispatch(
          deleteJournalRecipientDetails({
            journalId: Number(editId),
            emailIds: deleteContactIds,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    const updateResponseAction = await dispatch(
      editJournalDetails({
        id: Number(editId),
        values: payloadRefiner(journalPayload, "edit") as JournalValues,
        status: journalStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateResponse = updateResponseAction.payload;
    if (Object.keys(updateResponse).length && !("error" in updateResponse)) {
      dispatch(setLoaderState(false));
      if (journalStatus === "Draft") navigate(-1);
      else
        navigate(`/journal-entry/detail/${Number(editId)}`, {
          replace: true,
        });
      SuccessToaster(
        "Journal entry updated successfully!",
        "journal-voucher-update-success"
      );
    } else if ("error" in updateResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { createJournalSubmit, updateJournalSubmit };
};
