export const initialUserValues = {
  name: "",
  email: "",
  organization_role_mappings: [
    {
      organization_id: 0,
      role_id: 0,
    },
  ],
  tag_ids: [],
};
export const initialRolesValues = {
  id: 0,
  name: "",
};
export const initialOrgValues = {
  org_id: "",
  role_id: 0,
  role_list: [
    {
      ...initialRolesValues,
    },
  ],
};
export const initialOrgDropdownValues = {
  label: "",
  desc: "",
  id: "",
};
export const initialEditUserValues = {
    name: "",
    organization_role_mappings: [
      {
        organization_id: 0,
        role_id: 0,
      },
    ],
    tag_ids: [],
    is_changed: false,
  };