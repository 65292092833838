import React, { useEffect, useState } from "react";
import { TreeItem, TreeView } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useAppSelector } from "../../../../app/hooks";
import {
  accountTreeModalSelector,
  moveAndUpdateHeadOfAccount
} from "../coaSlice";
import { AccountsTreeValues } from "../../../../types";
import {
  TreeItemProps,
  TreeItemContentProps,
  useTreeItem
} from "@mui/lab/TreeItem";
import clsx from "clsx";
import CustomTooltips from "../../../common/components/Tooltip";
import Typography from "@mui/material/Typography";
import { DirectoryIconSmall, ListIconSmall } from "../../../../assets/images";
import "../components/changeBranchModal/changeBranchModal.css";
import useCommonData from "../../../hooks/useCommon";
type Props = {
  selectedBranchHandler: (id: number) => void;
  resetConvertedAmount: () => void;
  selectedNode: string;
  expanded: string[];
  isCreate: boolean;
  onCloseModal: () => void;
  superParentId: number;
  editAccountId: number;
  parentId: number;
  refreshList: () => void;
  setAccountTypeHandler: (account: string) => void;
};
const AddAccountTreeView = (prop: Props) => {
  const [expanded, setExpanded] = useState<string[]>(prop.expanded);
  const [selectedNode, setSelectedNode] = useState(prop.selectedNode);
  const accountsTreeData = useAppSelector(accountTreeModalSelector);
  const { dispatch, currentUserInfo } = useCommonData();
  const [currentBranch, setCurrentBranch] = useState("");

  useEffect(() => {
    setSelectedNode(prop.selectedNode);
  }, [prop.selectedNode]);

  useEffect(() => {
    setExpanded(prop.expanded);
  }, [prop.expanded]);
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
    prop.resetConvertedAmount();
  };

  /**
   * Custom tree view content using TreeView
   */
  const CustomContent = React.forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref
  ) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon
    } = props;

    const {
      expanded,
      selected,
      handleExpansion,
      handleSelection,
      preventSelection
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      preventSelection(event);
      $(event.target).addClass("branch-select-active");
    };

    const handleExpansionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      let item = event.target as HTMLElement;
      let chosen = document.querySelectorAll(".chosen-branch");
      handleSelection(event);
      prop.selectedBranchHandler(Number(nodeId));
      setSelectedNode(nodeId);
      //setTimeout(() => {
      item.classList.add("branch-select-active");
      if (chosen.length > 0) {
        chosen.forEach((element, index) => {
          element.classList.remove("chosen-branch");
        });
      }        
      setCurrentBranch("chosen-branch");
     // }, 200);
     
    };
    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        <div onClick={handleExpansionClick}>{icon}</div>
        <CustomTooltips title={label} placement="bottom">
          <Typography
            onClick={handleSelectionClick}
            component="div"
            className={
              props.nodeId === selectedNode
                ? classes.label + ` branch-select-active ${currentBranch}`
                : classes.label
            }
            style={
              !prop.isCreate &&
              (Number(nodeId) === prop.editAccountId ||
                Number(nodeId) === prop.parentId ||
                props.title === "BankAccount")
                ? { opacity: "0.5", pointerEvents: "none" }
                : {}
            }
          >
            <img
              src={props.itemID === "true" ? DirectoryIconSmall : ListIconSmall}
              alt="category_account_icon"
            />
            {"  "}
            {label}
          </Typography>
        </CustomTooltips>
      </div>
    );
  });

  /**
   * Custom Tree item component calling in each recursive function call
   */
  const CustomTreeItem = (props: TreeItemProps) => (
    <TreeItem
      ContentComponent={CustomContent}
      {...props}
      id={"account-" + props.nodeId}
    />
  );

  /**
   * Recursive function call if node exists for account
   */
  const renderTrees = (nodes: AccountsTreeValues) => (
    <CustomTreeItem
      nodeId={nodes.id.toString()}
      label={nodes.account_name}
      ContentProps={{
        itemID: nodes.is_category ? "true" : "false",
        title: nodes.account_class ? nodes.account_class : ""
      }}
      key={nodes.id}
    >
      {Array.isArray(nodes.node)
        ? nodes.node.map((node) => renderTrees(node))
        : null}
    </CustomTreeItem>
  );

  const handleSubmit = async () => {
    $("#move-account-button").addClass("add-account-form-section-disable");
    const responseAction = await dispatch(
      moveAndUpdateHeadOfAccount({
        values: {
          existing_head_id: prop.parentId,
          new_head_id: Number(selectedNode)
        },
        account_id: prop.editAccountId,
        orgId: currentUserInfo.organization_id
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      prop.setAccountTypeHandler(response.account_type);
      prop.onCloseModal();
      prop.refreshList();
    }
    $("#move-account-button").removeClass("add-account-form-section-disable");
  };

  return (
    <>
      <div className="tree-view-container">
        <TreeView
          aria-label="icon expansion"
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowDropUpIcon />}
          onNodeToggle={handleToggle}
          expanded={expanded}
          selected={[]}
          sx={{
            height: 240,
            flexGrow: 1,
            maxWidth: 400,
            overflowY: "auto"
          }}
        >
          {prop.isCreate
            ? accountsTreeData.map((account, index) => renderTrees(account))
            : accountsTreeData
                .filter((account) => account.id === prop.superParentId)
                .map((account, index) => renderTrees(account))}
        </TreeView>
      </div>
      {!prop.isCreate && (
        <div
          className="button-fields change-branch-modal-form-button-wrapper"
          id="form-btn-move-ledger"
        >
          <button
            className="save-button"
            onClick={() => handleSubmit()}
            type="button"
            id="move-account-button"
            disabled={selectedNode.length ? false : true}
          >
            Move here
          </button>

          <button
            className="text-decoration-none cancel-button close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            type="button"
            onClick={prop.onCloseModal}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default AddAccountTreeView;
