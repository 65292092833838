import { SvgIconProp } from "../../../../types";

export const RemoveIcon = (props: SvgIconProp) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          d="M9 16.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm0-8.56L6.879 5.817 5.818 6.879 7.94 9l-2.122 2.121 1.061 1.061L9 10.06l2.121 2.122 1.061-1.061L10.06 9l2.122-2.121-1.061-1.061L9 7.94z"
          fillOpacity=".4"
          fill={props.color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
