import Dialog from "@mui/material/Dialog";
import { FiscalYearPopupForm } from "./FiscalYearPopupForm";
import DialogTitle from "@mui/material/DialogTitle";
import "./FiscalYearPopup.css";
import { useEffect, useState } from "react";

type Props = {
  shouldOpen: boolean;
};
export const FiscalYearPopupModal = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(props.shouldOpen);
  }, [props.shouldOpen]);
  const setDialogOpen = (shouldOpen: boolean) => {
    setOpen(shouldOpen);
  };
  return (
    <Dialog className="fiscal-year-popup-dialog" open={open} onClose={() => {}}>
      <DialogTitle className="dialog-title-fiscal-year-popup">
        Update Fiscal Year
      </DialogTitle>
      <FiscalYearPopupForm setDialogOpen={setDialogOpen} />
    </Dialog>
  );
};
