import React, { useState } from "react";
import { TableData } from "../../../../../types";
import "./Allocation.css";
import DetailsTableContainer from "../DetailsTableContainer";
import { Cell, Row } from "react-table";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../../hooks/useCommon";
import { AllocationProps } from "./AllocationTypes";

function AllocationModal(props: AllocationProps) {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const [formSubmit, setFormSubmit] = useState(true);
  /**
   * save allocation
   */
  const saveAllocation = async () => {
    setFormSubmit(false);
    await props.onSubmit();
    setFormSubmit(true);
    props.handleModalClose();
  };
  const closeHandler = () => {
    props.resetAllocation();
    setFormSubmit(true);
    props.handleModalClose();
  };
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (
      cell.column.Header === "Invoice Number" ||
      cell.column.Header === "Bill Number" ||
      cell.column.Header === "Transaction Id"
    ) {
      let action = row?.values?.action;
      if (action?.url && action?.permission) {
        $(".credit-close").click();
        sessionStorage.setItem(action?.type, "open");
        window.open(action?.url, "_blank");
      }
    }
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="table-wrapper w-100">
          <DetailsTableContainer
            columns={props.columns}
            data={props.tableData}
            className="table apply-creds-table w-100"
            navigation={navigation}
            haveColumnClassName={true}
            hiddenColumns={["action"]}
          />
        </div>
      </div>
      <div className="amt-summary d-flex flex-column align-items-end w-100">
        <div className="amt-grid">
          <div className="row g-0">
            <div className="item-column">{props.allocatedLabel}</div>
            <div className="amt-column">
              {props.currencyCode}{" "}
              {props.allocatedAmount
                ? NumberFormat(
                    Number(props.allocatedAmount),
                    props.currencyCode,
                    orgCurrencyList
                  )
                : zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      props.currencyCode
                        ? props.currencyCode
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )}
            </div>
          </div>
          <div className="row g-0">
            <div className="item-column">{props.remainingLabel}</div>
            <div
              className={`amt-column ${
                Number(props.remainingAmount) < 0 ? "negative-amt" : ""
              }`}
            >
              {props.currencyCode}{" "}
              {props.remainingAmount
                ? NumberFormat(
                    Number(props.remainingAmount),
                    props.currencyCode,
                    orgCurrencyList
                  )
                : zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      props.currencyCode
                        ? props.currencyCode
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )}
            </div>
          </div>
        </div>
      </div>
      <div className="allocation-button-wrapper">
        <button
          type="button"
          className={`btn save-button ${
            !formSubmit ||
            !props.isFormValues ||
            Number(props.remainingAmount) < 0 ||
            props.isError === true
              ? " customer-form-section-disable"
              : ""
          }`}
          onClick={saveAllocation}
        >
          Save
        </button>
        <button
          type="button"
          className={`btn cancel-button allocation-cancel ${
            formSubmit ? "" : " customer-form-section-disable"
          }`}
          data-bs-dismiss="modal"
          onClick={closeHandler}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
export default React.memo(AllocationModal);
