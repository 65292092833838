import { useAppSelector } from "../../../../../app/hooks";
import {
  amountInWordsConfigSelector,
  editTransactionConfig,
  fetchtransactionConfig,
  setLoaderState,
} from "../../../../common/commonSlice";
import "../../preferences.css";
import { AmountInWordsConfig } from "./amountInWordsConfig/AmountInWordsConfig";
import { useFormik } from "formik";
import { useStateInitializer } from "./resources/utils/stateInitializer";
import { payloadSetter } from "./resources/utils/payloadSetter";
import { Form } from "../../../../common/components/htmlTags/Form";
import { Container } from "../../../../common/components/htmlTags/Container";
import { SaveButton, SecondaryButton } from "../../../../common/components";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import useCommonData from "../../../../hooks/useCommon";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { ObjectType } from "../../../../../types";

const ConfigureTransactions = () => {
  const amountInWordsConfig = useAppSelector(amountInWordsConfigSelector);
  const { transactionObject, isFullyEnabled } = useStateInitializer();
  const { currentUserInfo, dispatch } = useCommonData();

  const initialValues = {
    dateFormat: null,
    isAmountInWordsFullyEnabled: isFullyEnabled,
    amountInWordsConfigObj: transactionObject,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      updateAmountInWordsConfig(values);
    },
  });
  const updateAmountInWordsConfig = async (values: ObjectType) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      editTransactionConfig({
        values: payloadSetter(values.amountInWordsConfigObj),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      await dispatch(fetchtransactionConfig(currentUserInfo.organization_id));
      dispatch(setLoaderState(false));

      SuccessToaster(
        "Updated the amount in words settings successfully!",
        "amount-in-words-config-success"
      );
    } else {
      dispatch(setLoaderState(false));
    }
  };

  return (
    <Card>
      <CardHeader>Configure Transactions</CardHeader>
      <CardBody>
        <Form
          name="configure-transaction-form"
          id="configure-transaction-form"
          className="preferences-settings-form configure-transaction-form w-100"
          onSubmit={() => {}}
        >
          <Container className="fields-wrapper">
            <section className="form-fields-section">
              <AmountInWordsConfig
                isAmountInWordsFullyEnabled={
                  formik.values.isAmountInWordsFullyEnabled
                }
                amountInWordsConfigObj={formik.values.amountInWordsConfigObj}
                setAmountConfigValue={formik.setFieldValue}
                amountInWordsConfig={amountInWordsConfig}
              />
            </section>
          </Container>
          <section className="form-button-section">
            <Container
              className="button-fields form-button-wrapper"
              id="form-btn-gst-profile"
            >
              <SaveButton
                buttonClassName=""
                type="submit"
                buttonContent="Save"
                onClick={(e) => {
                  e.preventDefault();
                  formik.submitForm();
                }}
                isDisabled={false}
              />
              <SecondaryButton
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                buttonContent="Cancel"
                isDisabled={false}
                onClick={formik.handleReset}
              />
            </Container>
          </section>
        </Form>
      </CardBody>
    </Card>
  );
};
export default ConfigureTransactions;
