import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { initialValues } from "../resources/stateInitialization";
import { useParams } from "react-router-dom";
import useCommonData from "../../../../../../hooks/useCommon";
import { getReportingTagDropdown } from "../../../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import {
  generateJournal,
  journalDetails,
  updateReportingTags,
} from "../../../../journalVoucherSlice";
import { ApplyTagsValues, ObjectType } from "../../../../../../../types";
import { validation } from "../resources/validation";
import { SuccessToaster } from "../../../../../../../helpers/toastHelper";
import messages from "../resources/messages";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { formikTouchedFieldSetter } from "../../../../../../../helpers/formikTouchedFieldSetter";

const useReportingTags = () => {
  const { editId, accountId, entityId, entityType } = useParams();
  const uniqueKey = `${accountId}${entityId}${entityType}`;
  const [otherAccountsExistingTags, setOtherAccountsExistingTags] =
    useState<any>([]);
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();

  const [formValues, setFormValues] = useState<ApplyTagsValues>(initialValues);
  const [allTags, setAllTags] = useState<ObjectType[]>([]);
  const [currencyCode, setCurrencyCode] = useState(
    currentUserInfo.currency_code
  );
  useEffect(() => {
    getAssociatedReportingTags();
    getReportingTagsList();
    getJournalEntryDetails();
  }, [currentUserInfo.organization_id]);

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      saveReportingTags();
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  const saveReportingTags = async () => {
    formikTouchedFieldSetter(formik.values, formik.setTouched);
    let reportingTagList = [] as any;
    formik.values.reporting_tag_list.map((reporting_tag) => {
      reportingTagList.push({
        account_id: accountId,
        entity_id: formik.values.entity_id,
        entity_type: formik.values.entity_type,
        transaction_amount: formik.values.transaction_amount,
        tag_amount: reporting_tag.tag_amount,
        split_type: reporting_tag.split_type,
        reporting_tag_id: reporting_tag.reporting_tag_id,
        options_list: reporting_tag.option_list.map((option) => {
          return {
            option_id: option.option_id,
            amount: option.amount,
          };
        }),
      });
    });

    // set existing reporting for other accounts
    otherAccountsExistingTags.map((otherAccount: any) => {
      otherAccount.reporting_tag_list.map((reporting_tag: any) => {
        reportingTagList.push({
          account_id: otherAccount.account_id,
          entity_id: otherAccount.entity_id,
          entity_type: otherAccount.entity_type,
          transaction_amount: otherAccount.transaction_amount,
          tag_amount: reporting_tag.tag_amount,
          split_type: reporting_tag.split_type,
          reporting_tag_id: reporting_tag.reporting_tag_id,
          options_list: reporting_tag.option_list.map((option: any) => {
            return {
              option_id: option.option_id,
              amount: option.amount,
            };
          }),
        });
      });
    });
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updateReportingTags({
        payload: { reporting_tag_list: reportingTagList },
        journalVoucherId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    SuccessToaster(messages.success.reportingTagSave, "JE-reporting-tag");
    dispatch(setLoaderState(false));

    navigate(-1);
  };

  const getReportingTagsList = async () => {
    const responseAction = await dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setAllTags(responseAction.payload);
    }
  };

  const getAssociatedReportingTags = async () => {
    const responseAction = await dispatch(
      generateJournal({
        journalVoucherId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    let newOtherAccountsExistingTags = response.transaction
    .filter(
      (singletransaction: any) => {
        const transKey = `${singletransaction.account_id}${singletransaction.entity_id}${singletransaction.entity_type}`;
        return uniqueKey !== transKey;
      }
     )
      .map((singletransaction: ObjectType) => {
        return {
          account_id: singletransaction.account_id,
          account_name: singletransaction.account_name,
          transaction_amount: singletransaction.transaction_amount,
          transaction_type: singletransaction.transaction_type,
          entity_id: singletransaction.entity_id,
          entity_type: singletransaction.entity_type,
          reporting_tag_list: singletransaction.reporting_tag_list
            ? singletransaction.reporting_tag_list
            : [],
        };
      });

    setOtherAccountsExistingTags(newOtherAccountsExistingTags);

    const transaction = response.transaction.find(
      (singletransaction: ObjectType) => {
        return Number(singletransaction.account_id) === Number(accountId) &&
        Number(singletransaction.entity_id) === Number(entityId) &&
        String(singletransaction.entity_type) === String(entityType);
      }
    );

    setFormValues((prevValues: ApplyTagsValues) => {
      return {
        ...prevValues,
        account_name: transaction.account_name,
        transaction_amount: transaction.transaction_amount,
        transaction_type: transaction.transaction_type,
        entity_id: transaction.entity_id,
        entity_type: transaction.entity_type,
        reporting_tag_list: transaction.reporting_tag_list
          ? transaction.reporting_tag_list
          : [],
      };
    });
  };

  const getJournalEntryDetails = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      journalDetails({
        journalId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setCurrencyCode(response.currency_code);
      setFormValues((prevValues: ApplyTagsValues) => {
        return {
          ...prevValues,
          currency_id: response.currency_id,
          currency_code: response.currency_code,
        };
      });
    }
    dispatch(setLoaderState(false));
  };

  return { formik, allTags, currencyCode };
};

export default useReportingTags;
