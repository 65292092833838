import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import { deleteVendorDetails, vendorsList } from "../vendorSlice";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteVendorId: number;
  deleteVendorName: string;
  page: number;
  itemsPerPage: number;
  vendorName: string;
  refreshList: () => void;
  organizationId: number;
};
const DeleteVendor = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteVendorId]);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteVendorDetails({
          id: props.deleteVendorId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      $("#deleteMessageModal").modal("hide");
      props.refreshList();

      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (
          Object.keys(deleteRoleResponse).length &&
          !("error" in deleteRoleResponse)
        ) {
          isDeletedRef.current = true;
          $(".close-btn").click();
          toast.success("Vendor deleted successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          const errorDetails = deleteRoleResponse.error.detail;
          let errorMsg = "Delete vendor action failed!";
          if (Array.isArray(errorDetails) && errorDetails.length) {
            for (const error of errorDetails) {
              if (error.type === "value_error.bill_exists") {
                errorMsg =
                  "Unable to delete vendor. Vendor has transactions posted.";
              }
            }
          }
          $(".close-btn").click();
        }
      }
    }
    else{
      $("#deleteMessageModal").modal("hide");
      props.refreshList();
    }
  };
  return (
    <DeleteModal
      heading="Delete Vendor!"
      subHeading="Are you sure you want to delete the vendor"
      transactionName={props.deleteVendorName}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteVendor;
