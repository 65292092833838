import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TCSFormValues, TcsValues } from "../../../types";
import {
  createNewTcs,
  deleteTcs,
  getCollectionDropDown,
  getTcsDropDown,
  getTcsList,
} from "./tcsAPI";
import { RootState } from "../../../app/store";

export const tcsList = createAsyncThunk(
  "tcsList",
  async (data: { page: number; itemsPerPage: number; orgId: number }) => {
    return await getTcsList(data);
  }
);

export const createTcs = createAsyncThunk(
  "createTcs",
  async (data: { values: TCSFormValues; orgId: number }) => {
    return createNewTcs(data);
  }
);

export const collectionDropdown = createAsyncThunk(
  "natureOfCollectionDropdown",
  async (orgId: number) => {
    return await getCollectionDropDown(orgId);
  }
);

export const tcsDropDown = createAsyncThunk(
  "tcsDropDown",
  async (data: {orgId: number, tcsId: number[]}) => {
    return await getTcsDropDown(data);
  }
);

export const removeTcs = createAsyncThunk(
  "removeTcs",
  async (data: { tcsId: number; orgId: number }) => {
    return await deleteTcs(data);
  }
);

interface TcsState {
  tcs: TcsValues;
}

const initialState: TcsState = {
  tcs: {
    tcs_tax: [],
    total: 0,
    page_size: 10,
    pagination: {
      next: null,
      previous: null,
    },
  },
};

export const tcsSlice = createSlice({
  name: "tcsConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tcsList.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.tcs = payload;
      }
    });
  },
});

export default tcsSlice.reducer;

export const tcsSelector = (state: RootState) => state.tcs.tcs;
