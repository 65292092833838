import { AlertModal } from "../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../common/components/alertModal/alertModalContext";
import { DeleteIcon } from "../../../../common/components/customSvgIcons/deleteIcon";
import { colors } from "../../../../constants/colors";
import { DeleteCustomField } from "./deleteCustomField";

type DeleteModalProps = {
  id: number;
  name: string;
  module: string;
};

export const DeleteCustomFieldModal = (props: DeleteModalProps) => {
  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <DeleteCustomField
                id={props.id}
                module={props.module}
                name={props.name}
                handleClose={onCloseModal}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span className="dropdown-item delete d-flex align-items-center ">
          <DeleteIcon width="18px" height="18px" color={colors.tomato} margin={"0 8px 0 0"} />
          Delete
        </span>
      }
      buttonClass="no-appearance w-100 p-0"
      modalTitle="Delete custom field!"
      isCloseButtonEnabled={true}
    />
  );
};
