import { useEffect, useState } from "react";
import Container from "../../../gst/components/settings/GstSettingsContainer";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useFormik } from "formik";
import { preferenceValidation } from "./../preferenceValidation";
import { fetchRIPreference, updateRIPreference } from "../../preferencesSlice";
import useCommonData from "../../../../hooks/useCommon";
import "../../preferences.css";
import { setLoaderState } from "../../../../common/commonSlice";
import { SuccessToaster } from "../../../../../helpers/toastHelper";

const Preferences = () => {
  const { dispatch, currentUserInfo } = useCommonData();

  const [initialValues, setInitialValues] = useState<{
    radioValue: string;
  }>({
    radioValue: "Save and Send",
  });

  useEffect(() => {
    getInvoicePreference();
  }, []);

  const getInvoicePreference = async () => {
    dispatch(setLoaderState(true));
    const response = await dispatch(
      fetchRIPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    if (payload.preferred_invoice_status) {
      setInitialValues({
        radioValue: payload.preferred_invoice_status,
      });
    }
    dispatch(setLoaderState(false));
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: preferenceValidation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      let payload = {
        preferred_invoice_status: values.radioValue,
        organization_id: currentUserInfo.organization_id
      };
      dispatch(setLoaderState(true));
      let responseAction = await dispatch(
        updateRIPreference({
          orgId: currentUserInfo.organization_id,
          payload: payload,
        })
      );
      dispatch(setLoaderState(false));
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Recurring Invoice preference updated successfully.",
          "ri-preference-success-toast"
        );
        setInitialValues({
          radioValue: response.preferred_invoice_status,
        });
      }
    },
    onReset: () => { },
  });

  const onPreferenceChanged = (val: string) => {
    formik.setFieldValue("radioValue", val);
  };

  return (
    <Container heading="Preferences">
      <form
        action=""
        name="preferences-settings-form"
        id="preferences-settings-form"
        className="preferences-settings-form w-100"
        onSubmit={formik.handleSubmit}
      >
        <div className="fields-wrapper">
          <section className="form-fields-section">
            <div className="form-fields">
              <div className="row g-0">
                <div className="col">
                  <span className="radio-label">
                    Save invoice preference
                  </span>
                  <br />
                  <RadioButtonGroup
                    radioStyle="recurring-invoice-radio"
                    name="gst_enabled"
                    id="gst_enabled"
                    valuesArray={[
                      { label: "Save as Draft", value: "Save as Draft" },
                      { label: "Save and Send", value: "Save and Send" },
                      // { label: "Save and Send with Payment Link", value: "Save and Send with Payment Link" }, // future implementation
                    ]}
                    value={formik.values.radioValue}
                    icon={
                      <span>
                        <RadioButtonUncheckedIcon />
                      </span>
                    }
                    checkedIcon={
                      <span>
                        <RadioButtonCheckedIcon className="radio-btn-tds" />
                      </span>
                    }
                    isDisabled={false}
                    onClick={onPreferenceChanged}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="form-button-section">
          <div
            className="button-fields form-button-wrapper"
            id="form-btn-gst-profile"
          >
            <button className="save-button" type="submit" disabled={false}>
              Save
            </button>
            <button
              className="text-decoration-none cancel-button close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              type="button"
              onClick={() => formik.resetForm()}
            >
              Cancel
            </button>
          </div>
        </section>
      </form>
    </Container>
  );
};
export default Preferences;
