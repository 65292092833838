import { GroupIcon } from "../../../../../../assets/images";
import EmptyList from "./EmptyList";
import TableContainer from "../../../../../common/components/table/TableContainer";

type Props = {
  columns: any[];
  data: any[];
  refreshList: () => void;
};
function ItemTable({ columns, data, refreshList }: Props) {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let tdsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TDS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  return (
    <TableContainer
      className="list-table table table-striped w-100"
      columns={columns}
      data={data}
      url={"/users"}
      sortHandler={() => {}}
      editPermission={false}
      isDetailViewExist={false}
    >
      <EmptyList
        heading={"No TDS Found"}
        subheading={
          "Configures TDS taxes to add in Invoice, Bills, Credit note and Debit notes"
        }
        list="TDS"
        alt="create TDS preview"
        buttonText="Add New TDS"
        navigateLink=""
        navigateType="modal"
        isCrateAccess={tdsPermission.includes("Create") ? true : false}
        icon={GroupIcon}
        transactionLimit={false}
        onClick={() => {}}
        refreshList={refreshList}
      />
    </TableContainer>
  );
}

export default ItemTable;
