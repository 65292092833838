import React from "react";

type Props = {
  label: string;
  value: any;
  labelClassName?: string;
  valueClassName?: string;
};
function PaymentTotalItem({
  label,
  value,
  labelClassName,
  valueClassName,
}: Props) {
  return (
    <div className="d-flex justify-content-between">
      <div className={`item-text ${labelClassName ? labelClassName : ""}`}>
        {label}
      </div>
      <div className="amt-wrapper">
        <span className={`item-amt ${valueClassName ? valueClassName : ""}`}>
          {value}
        </span>
      </div>
    </div>
  );
}

export default PaymentTotalItem;
