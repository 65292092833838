import { useEffect, useState } from "react";
import Container from "../../gst/components/settings/GstSettingsContainer";
import { RadioButtonGroup } from "../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Popper from "../../../common/components/popper/popper";
import PreferencePopperContent from "./preferencePopperContent/PreferencePopperContent";
import { InfoIconImg } from "../../../../assets/images";
import { useFormik } from "formik";
import { preferenceValidation } from "./preferenceValidation";
import {
  fetchRoundOffPreference,
  updateRoundOffPreference,
} from "../preferencesSlice";
import CustomSearchSelect from "../../../common/components/CustomSearchSelect/CustomSearchSelect";
import useCommonData from "../../../hooks/useCommon";
import "../preferences.css";
import { setLoaderState } from "../../../common/commonSlice";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import CustomTooltips from "../../../common/components/Tooltip";
import Tooltip from "@mui/material/Tooltip";
type Props = {
  roundoffTransactionType: string;
};
const Preferences = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();

  const [initialValues, setInitialValues] = useState<{
    is_enabled: string;
    account_id: number | null;
    account_name: string;
    is_total_editable: boolean;
  }>({
    is_enabled: "No",
    account_id: null,
    account_name: "",
    is_total_editable: false,
  });

  useEffect(() => {
    getRoundOffPreference();
  }, [currentUserInfo.organization_id]);

  const getRoundOffPreference = async () => {
    dispatch(setLoaderState(true));
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
        roundoff_transaction_type: props.roundoffTransactionType,
      })
    );
    let payload = response.payload;
    setInitialValues({
      is_enabled: payload.is_enabled ? "Yes" : "No",
      account_id: Number(payload.account_id),
      account_name: payload.account_name,
      is_total_editable: payload.is_total_editable,
    });
    dispatch(setLoaderState(false));
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: preferenceValidation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      let payload = {
        is_enabled: values.is_enabled === "Yes" ? true : false,
        account_id: values.account_id,
        is_total_editable: values.is_total_editable,
        roundoff_transaction_type: props.roundoffTransactionType,
      };
      if (payload.is_enabled && !payload.account_id) {
        formik.setFieldValue("account_id", null);
        formik.setFieldValue("account_name", "");
        return;
      }
      dispatch(setLoaderState(true));
      let responseAction = await dispatch(
        updateRoundOffPreference({
          orgId: currentUserInfo.organization_id,
          payload: payload,
        })
      );
      dispatch(setLoaderState(false));
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Round off preference updated successfully.",
          "round-off-preference-success-toast"
        );
        setInitialValues({
          is_enabled: response.is_enabled ? "Yes" : "No",
          account_id: response.account_id,
          account_name: response.account_name,
          is_total_editable: response.is_total_editable,
        });
      }
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  const onRoundOffEnableChanged = (val: string) => {
    formik.setFieldValue("is_enabled", val);
    if (val === "No") {
      formik.setFieldValue("account_id", null);
      formik.setFieldValue("account_name", "");
      formik.setFieldValue("is_total_editable", false);
    }
  };

  const onIsTotalEditable = (event: any) => {
    formik.setFieldValue("is_total_editable", event.target.checked);
  };

  const onAccountChange = (val: any) => {
    formik.setFieldValue("account_id", val);
  };

  const roundOffLabel = (
    <div className="d-inline-flex">
      <span className="line-height-18">
        Rounding off to the nearest whole number
      </span>
      <Tooltip
        title={<PreferencePopperContent />}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "var(--white-fill)",
              border: "1px solid #f4f4f4",
              padding: "0px",
            },
          },
        }}
        placement="bottom-start"
      >
        <img
          style={{ marginLeft: "5px" }}
          src={InfoIconImg}
          width="16"
          alt="info icon"
        />
      </Tooltip>
    </div>
  );

  return (
    <>
      <Container heading="Preferences">
        <form
          action=""
          name="preferences-settings-form"
          id="preferences-settings-form"
          className="preferences-settings-form w-100"
          onSubmit={formik.handleSubmit}
        >
          <div className="fields-wrapper">
            <section className="form-fields-section">
              <div className="form-fields">
                <div className="row g-0">
                  <div className="col">
                    <span className="radio-label">
                      Rounding off in {props.roundoffTransactionType}{" "}
                      Transactions
                    </span>
                    <br />
                    <RadioButtonGroup
                      name="gst_enabled"
                      id="gst_enabled"
                      valuesArray={[
                        { label: "No Rounding", value: "No" },
                        { label: roundOffLabel, value: "Yes" },
                      ]}
                      value={formik.values.is_enabled}
                      icon={
                        <span>
                          <RadioButtonUncheckedIcon />
                        </span>
                      }
                      checkedIcon={
                        <span>
                          <RadioButtonCheckedIcon className="radio-btn-tds" />
                        </span>
                      }
                      isDisabled={false}
                      onClick={onRoundOffEnableChanged}
                    />
                  </div>
                </div>
                {formik.values.is_enabled === "Yes" && (
                  <>
                    <div className="row g-0 roundoff-preference">
                      <div className="col accnt-col w-200 pright-20">
                        <label htmlFor="account" className="required">
                          Account
                        </label>
                        <CustomTooltips
                          placement="right"
                          title={`The account chosen in the drop-down shall record the round-off difference in the total amount of ${
                            props.roundoffTransactionType === "Sales"
                              ? "invoice/CN"
                              : "bills/DN"
                          } and the round-off difference in the amount shall be posted to the selected account`}
                        >
                          <a
                            href="#"
                            className="info-btn position-relative"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img src={InfoIconImg} width="16" alt="info icon" />
                          </a>
                        </CustomTooltips>
                        <CustomSearchSelect
                          itemAccount={Number(formik.values.account_id)}
                          itemAccountName={formik.values.account_name}
                          organizationId={currentUserInfo.organization_id}
                          handleItemAccountChange={onAccountChange}
                          itemIndex={0}
                          error={
                            formik.errors?.account_id &&
                            formik.touched.account_id
                              ? "error"
                              : ""
                          }
                          baseAccount="Incomes~Expenses"
                        />
                        <span className="error">
                          {formik.touched.account_id &&
                            formik.errors?.account_id}
                        </span>
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="grantaccess"
                        name="is_total_editable"
                        onChange={onIsTotalEditable}
                        checked={formik.values.is_total_editable}
                      />
                      <label className="form-check-label" htmlFor="grantaccess">
                        Allow users to edit the form                       
                      </label>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
          <section className="form-button-section">
            <div
              className="button-fields form-button-wrapper"
              id="form-btn-gst-profile"
            >
              <button className="save-button" type="submit" disabled={false}>
                Save
              </button>
              <button
                className="text-decoration-none cancel-button close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => formik.resetForm()}
              >
                Cancel
              </button>
            </div>
          </section>
        </form>
      </Container>
    </>
  );
};
export default Preferences;
