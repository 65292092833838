import { useAppSelector } from "../../../../../../../app/hooks";
import { ObjectType } from "../../../../../../../types";
import { amountInWordsConfigSelector } from "../../../../../../common/commonSlice";
import { AMOUNT_IN_WORDS } from "../../../../../../constants/constants";

export const useStateInitializer = () => {
  const amountInWordsConfig = useAppSelector(amountInWordsConfigSelector);
  let transactionObject: ObjectType = {};
  AMOUNT_IN_WORDS.map(
    (value) =>
      (transactionObject = {
        ...transactionObject,
        [value.value]: amountInWordsConfig[`${value.key}`],
      })
  );
  let isFullyEnabled = !Object.values(amountInWordsConfig).some(
    (value) => value === false
  );

  return { transactionObject, isFullyEnabled };
};
