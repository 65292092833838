import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { updateCreditAllocation } from "../../creditNoteSlice";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import { ApplyCreditToInvoice } from "./ApplyToInvoice/ApplyToInvoiceForm";
import { useInvoiceAllocation } from "./index";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { message } from "../../../../constants/messages";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import {
  CreditNoteAllocation,
  CreditNoteDetailValues,
  InvoiceToAllocate,
} from "../../../../../types";
import ErrorInfo from "../../../../common/components/ErrorInfo";
import CenterModal from "../../../../common/components/centerModal/CenterModal";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  invoiceId?: number;
  buttonComponent: React.ReactNode;
  creditNoteData: CreditNoteDetailValues;
  refreshCNDetails: () => void;
  buttonClass?: string;
  isVoid?: boolean;
};

function ApplyToInvoicesModal({
  invoiceId,
  buttonComponent,
  creditNoteData,
  refreshCNDetails,
  buttonClass,
  isVoid,
}: Props) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    invoiceToAllocate,
    allocationColumn,
    allocationTableData,
    getInvoicesToAllocateCreditNote,
    resetAllocation,
    creditBalance,
    remainingAmount,
    allocatedAmount,
    isError,
    isFormValues,
  } = useInvoiceAllocation({ invoiceId, creditNoteData });
  const { editId } = useParams();
  const [error, setError] = useState(false);

  const balance = creditNoteData.total_details.total
    ? creditNoteData.currency_code +
      " " +
      NumberFormat(
        Number(creditNoteData.total_details.total),
        creditNoteData.currency_code,
        orgCurrencyList
      )
    : creditNoteData.currency_code +
      " " +
      zeroDisplayFormat(
        decimalPlaceOfCurrency(
          creditNoteData.currency_code
            ? creditNoteData.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  /**
   * update allocation
   */
  const updateAllocationData = async () => {
    const invoiceDetails = JSON.parse(JSON.stringify(invoiceToAllocate));
    let inputs: CreditNoteAllocation[] = [];
    invoiceDetails
      .filter(
        (invoices: InvoiceToAllocate) =>
          (invoices.amount_credited !== null &&
            invoices.amount_credited !== 0 &&
            invoices.amount_credited !== "") ||
          invoices.allocation_id !== null
      )
      .map((invoice: InvoiceToAllocate) => {
        let input = {
          id: invoice.allocation_id ? invoice.allocation_id : null,
          invoice_id: invoice.invoice_id,
          amount_to_credit: invoice.amount_credited
            ? invoice.amount_credited
            : 0,
        };
        inputs.push(input);
      });
    const responseAction = await dispatch(
      updateCreditAllocation({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
        values: inputs,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        refreshCNDetails();
        getInvoicesToAllocateCreditNote();
        toast.success("Credits applied to invoice(s)!", {
          toastId: "credit-apply-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
      }
    }
  };
  const getIsError = (isError: boolean) => {
    setError(isError);
  };
  const handleDisabledAction = () => {
    ErrorToaster(message().creditNote.applyCreditNoteToInvoices);
  };
  return (
    <CenterModal
      modalTitle={
        "Apply Credits from " +
        joinTransactionNumber(creditNoteData.credit_note_number)
      }
      modalTitleComponent={
        <div>
          <h6 className="balance-amt">{"Balance:" + " " + balance}</h6>
          {error ? (
            <ErrorInfo message="The credits applied is more than the invoice balance." />
          ) : null}
        </div>
      }
      modalTitleClass="flex-column align-items-start"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <ApplyCreditToInvoice
                currencyCode={creditNoteData.currency_code}
                conversionId={creditNoteData.conversion_id}
                onClose={onCloseModal}
                getIsError={getIsError}
                refreshCNDetails={refreshCNDetails}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={buttonComponent}
      wrapperClass="cd-modal applyCreditModal"
      modalContentClass="modal-dialog-scrollable"
      buttonClass={`no-appearance w-100 ${buttonClass ? buttonClass : ""}`}
      onOpenFunction={async () => {
        resetAllocation();
      }}
      isButtonDisabled={isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
}

export default ApplyToInvoicesModal;
