import Delete from "../../../../common/components/Delete";
import OrgTag from "../../../../common/components/OrgTag";
import Header from "../../../../common/components/detailsPage/Header";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import Clone from "../../../../common/components/kebabMenu/CloneRole";
import Void from "../../../../common/components/Void";
import CouponDelete from "../CouponDelete";
import CouponVoid from "../CouponVoid";
import { useParams } from "react-router-dom";
import { CouponOverviewDataType, DeleteInitialData } from "../../couponType";
import {
  DateFormatHandler,
  DateFormatWithHyphen,
} from "../../../../../helpers/helper";

type Props = {
  couponDetailData: CouponOverviewDataType;
  deleteAction: DeleteInitialData;
  onClickDelete: (actionType: string, id: number, couponName: string) => void;
  onVoidClick: (id: number, couponName: string) => void;
  voidAction: any;
};

const CouponViewHeader = (props: Props) => {
  const { currentUserInfo, navigate } = useCommonData();
  const { couponRolePermission } = usePermissionHandler();
  const backFromPlanViewHandler = () => {
    navigate("/coupons");
  };
  return (
    <>
      <Header
        onBackButtonClick={backFromPlanViewHandler}
        data={{
          num: props.couponDetailData.name,
          status:
            props.couponDetailData.coupon_status.charAt(0).toUpperCase() +
            props.couponDetailData.coupon_status.slice(1).toLowerCase(),
        }}
        dataId=""
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          <div className="left-group">
            <span className="biller-label ps-2">
              {DateFormatWithHyphen(props.couponDetailData.date_of_creation)}
            </span>
            <span>|</span>
            <span>
              <OrgTag organization={props.couponDetailData.organization} />
            </span>
          </div>
          {!couponRolePermission.includes("Update") ||
          ["cancelled", "exhausted"].includes(props.couponDetailData.coupon_status.toLowerCase()) || 
          props.couponDetailData.organization_id !==
            currentUserInfo.organization_id ? null : (
            <div className="actions-wrap d-flex flex-no-shrink">
              <Edit
                url={`/coupons/edit/${props.couponDetailData.id}`}
                isMutable
                name="Plan"
                className=""
                isLocked={false}
                lockDate={""}
                isDisabled={false}
              />
              {couponRolePermission.includes("Delete") ? (
                <Delete
                  className="text-decoration-none delete-item"
                  deleteIcon={true}
                  isMutable
                  onClick={() => {
                    props.onClickDelete(
                      "Coupon",
                      props.couponDetailData.id,
                      props.couponDetailData.name
                    );
                  }}
                  isLocked={false}
                  lockDate={""}
                  name="Coupon"
                  isTransactionExist={!props.couponDetailData.is_deletable}
                  planExistsSubscription={!props.couponDetailData.is_deletable}
                />
              ) : null}
              <KebabMenu wrapperClass="plan-detail-header detail-top-action-menu">
                <>
                  <li>
                    <Clone
                      url={`/coupons/clone/${props.couponDetailData.id}`}
                    />
                  </li>
                  {couponRolePermission.includes("Cancel") ? (
                    <li>
                      <Void
                        className="dropdown-item delete"
                        deleteIcon={true}
                        isMutable
                        onClick={() =>
                          props.onVoidClick(
                            props.couponDetailData.id,
                            props.couponDetailData.name
                          )
                        }
                        isLocked={false}
                        lockDate={""}
                        name="Coupon"
                        status={props.couponDetailData.coupon_status}
                      />
                    </li>
                  ) : null}
                </>
              </KebabMenu>
            </div>
          )}
        </div>
      </div>
      <CouponDelete
        deletePlanId={props.deleteAction.id}
        deletePlanName={props.deleteAction.planName}
        deleteActionType={props.deleteAction.actionType}
        refreshPlan={backFromPlanViewHandler}
        organizationId={currentUserInfo.organization_id}
      />
      <CouponVoid
        planId={props.voidAction.id}
        planName={props.voidAction.planName}
        refreshPlan={backFromPlanViewHandler}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
};
export default CouponViewHeader;
