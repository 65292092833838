import React from "react";

import "../../styles/InfoTag.css";

type Props = {
  status: string;
  entityTag: string[];
};
const HeaderTag = ({ entityTag }: Props) => {
  const statusColor = (status: string) => {
    let value = status?.toLowerCase();

    switch (value) {
      case "paid":
        return "info-tag tag-succuss";
      case "partially paid":
        return "info-tag tag-Partial";
      case "open":
        return "info-tag tag-warning";
      case "overdue":
        return "info-tag tag-danger";
      case "draft":
        return "info-tag tag-primary";
      case "future":
        return "info-tag tag-primary";
      case "in trial":
        return "info-tag tag-primary";
      case "approved":
        return "info-tag tag-Partial";
      case "allocated":
        return "info-tag tag-succuss";
      case "published":
        return "info-tag tag-succuss";
      case "closed":
        return "info-tag tag-succuss";

      case "invoiced":
        return "info-tag tag-succuss";
      case "deferred_income":
        return "info-tag tag-succuss";
      case "excess":
        return "info-tag tag-danger";
      case "non renewing":
        return "info-tag tag-warning";
      case "stopped":
        return "info-tag tag-warning";
      case "stop":
        return "info-tag tag-warning";
      case "declined":
        return "info-tag tag-danger";
      case "active":
        return "info-tag tag-succuss";
      case "expired":
        return "info-tag tag-danger";
      case "cancelled":
        return "info-tag tag-void";
      case "void":
        return "info-tag tag-void";
      case "exhausted":
        return "info-tag tag-danger";
      case "billed":
        return "info-tag tag-succuss";
      default:
        return "";
    }
  };
  return (
    <>
      {entityTag.map((tag: string) => (
        <div
          key={tag}
          className={statusColor(
            tag.includes("Overdue")
              ? "overdue"
              : tag.includes("Deferred Income")
              ? "deferred_income"
              : tag
          )}
        >
          {tag}
        </div>
      ))}
    </>
  );
};

export default HeaderTag;
