import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ErrorImg, InfoIconImg } from "../../../../assets/images";
import { ObjectType } from "../../../../types";
import { currentUserSelector, updatePrefix } from "../../../common/commonSlice";
import CustomTooltips from "../../../common/components/Tooltip";

type Props = {
  title: string;
  currentValue: string | null;
  inputId: string;
  isTooltip: boolean;
  tooltipText: string;
  name: string;
  handleChange: (e: any) => void;
  handleClose: () => void;
  prefixes: ObjectType;
};

export default function CustomizePrifixItem(props: Props) {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");

  const editAction = (e: any) => {
    e.preventDefault();
    const elem: any = e.target;
    const _parent = elem.parentNode.parentNode;
    const _parentNext = _parent.nextSibling;
    _parent.style.display = "none";
    _parentNext.style.display = "block";
  };

  const saveAction = async (e: any) => {
    if (validate(props.currentValue)) {
      updatePrefixValue();
      e.preventDefault();
      const elem: any = e.target;
      hideInput(elem);
    }
  };

  const cancelAction = (e: any) => {
    setError("");
    props.handleClose();
    e.preventDefault();
    const elem: any = e.target;
    hideInput(elem);
  };

  const hideInput = (elem: any) => {
    const _parent = elem.parentNode.parentNode;
    const _parentPrev = _parent.previousSibling;
    _parent.style.display = "none";
    _parentPrev.style.display = "block";
  };

  const updatePrefixValue = async () => {
    const obj = props.prefixes;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]!==null && obj[key]!=='') {
        obj[key] = obj[key].trim();
      }
    }
    const updatePrefixResponseAction = await dispatch(
      updatePrefix({
        values: obj,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updatePrefixResponse = updatePrefixResponseAction.payload;
    if (
      Object.keys(updatePrefixResponse).length &&
      !("error" in updatePrefixResponse)
    ) {
      toast.success("Prefix updated!", {
        toastId: "prefix-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else {
      props.handleClose();
      // toast.error("Failed to update prefix!", {
      //   icon: ({ theme, type }) => (
      //     <img src={ErrorImg} width="16" height="14" alt="errorImage" />
      //   ),
      //   toastId: "prefix-update-failure",
      //   closeButton: false,
      //   className: "toast-error",
      //   position: "top-center",
      // });
    }
  };

  const validate = (value: string | null) => {
    if (value?.trim() === "" || value?.trim() === null) {
      setError("Please enter a valid prefix");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  return (
    <>
      <div className="prefix-item w-100">
        <label htmlFor={props.inputId}>{props.title}</label>
        <div className="current-value-wrapper w-100">
          <span className="current-value">{props.currentValue}</span>
          <div className="button-wrapper action-buttons">
            <button type="button" className="edit" onClick={editAction}>
              edit
            </button>
          </div>
        </div>
        <div className="input-wrapper w-100">
          <input
            type="text"
            name={props.name}
            id={props.inputId}
            className={props.inputId}
            value={props.currentValue ? props.currentValue : ""}
            maxLength={10}
            onChange={props.handleChange}
          />
          <div className="button-wrapper action-buttons">
            <button type="button" className="save" onClick={saveAction}>
              save
            </button>
            <button type="button" className="cancel" onClick={cancelAction}>
              cancel
            </button>
          </div>
        </div>
        {<span className="error">{error}</span>}
      </div>
    </>
  );
}
