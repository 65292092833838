const messages = {
  errors: {
    refundDate_required: "Refund date is required",
    refundDate_invalid: "Please enter a valid refund date",
    refundDate_min: "The refund date must be on or after the payment date",
    refundDate_max: "The refund date must be on or before the next fiscal year",
    paymentMode_required: "Please select a payment mode",
    checkDate_required: "Please enter check details",
    checkDate_invalid: "Please enter a valid check date",
    checkExpiryIn_required: "Please select check expiry in",
    checkExpiry_shouldGreater_refundDate:
      'Check expiry should be greater than or equal to refund date',
    checkNumber_required: "Please enter check number",
    account_required: "Please select a account",
    refundAmount_invalid: "Please enter a valid refund amount",
    refundAmount_exceedBalance:
      "The refund amount exceeds the available balance",
    bankCharge_required: "Please enter bank charges",
  },
  success: {
    refundUpdate: "The refund information is updated",
    refundCreate: "New refund created successfully",
    exchangeRateUpdate: "Exchange rate updated successfully!",
  },
};
export default messages;
