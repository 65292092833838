import React from "react";
import { InfoIconImg } from "../../../../assets/images";
import CustomTooltips, { position } from "../Tooltip";
import "./InfoTipMessage.css";
type Props = {
  title: string;
  disabled?: boolean;
  placement?: position;
  className?: string;
  iconClassName?: string;
};
export default function InfoTooltipMessage(props: Props) {
  return (
    <CustomTooltips
      title={props.title}
      // className={props.className}
      placement={props.placement ? props.placement : "top-end"}
    >
      <button
        type="button"
        className={`info-btn position-relative no-appearance ${
          props.iconClassName ? props.iconClassName : ""
        }`}
        disabled={props.disabled ? props.disabled : false}
        onClick={(e) => e.preventDefault()}
      >
        <img src={InfoIconImg} width="16" alt="info icon" />
      </button>
    </CustomTooltips>
  );
}
