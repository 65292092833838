import * as yup from "yup";
import { message } from "../../../../../constants/messages";
import { conversionMinDate } from "../../../../../constants/constants";
import { getFormatedDate } from "../../../../../../helpers/helper";

export const PMFormValidation = yup.object({
  vendor_name: yup.string().required(message().pm.vendorRequired).nonNullable(),
  payment_date: yup
    .string()
    .required(message().pm.paymentDateRequired)
    .test(
      "payment_date",
      message().pm.paymentDateShouldValid,
      function (value) {
        new Date(value).toString();
        if (value === "Invalid Date") {
          return false;
        }
        return true;
      }
    )
    .test(
      "payment_date",
      message().pm.paymentDateWithinFiscalYear,
      function (value) {
        if (value === "Old date") {
          return false;
        }
        return true;
      }
    )
    .test(
      "payment_date",
      message().pm.paymentDateAfterRefund,
      function (value) {
        const { refund_history } = this.options.context as any;
        if (refund_history && refund_history.length > 0) {
          if(getFormatedDate(new Date(value)) > getFormatedDate(refund_history[0].refunded_on)) {
            return false;
          }
        }
        return true;
      }
    )
    .test("payment_date", message().pm.paymentDateMin, function (value) {
      if (
        new Date(value).toString() !== "Invalid Date" &&
        new Date(value) >= new Date(conversionMinDate)
      ) {
        return true;
      }
      return false;
    }),
  payment_number: yup
    .string()
    .test(
      "payment_number",
      message().pm.paymentNumberShouldValid,
      function (value) {
        const payment_number_value = value?.split("~")[1];

        if (payment_number_value === "") {
          return false;
        }
        return true;
      }
    ),
  payment_mode: yup.string().required(message().pm.paymentModeRequired),
  amount_paid: yup
    .string()
    .test(
      "amount-paid-non-zero",
      message().pm.amountPaidRequired,
      function (value) {
        const { currency_id, bank_currency_id } = this.options.context as any;
        // if bank currency id and currency id are different no need to validate amount_paid, so returning true.
        if (bank_currency_id && currency_id !== bank_currency_id) {
          return true;
        }

        if (!value || Number(value) === 0) {
          return false;
        }
        return true;
      }
    )
    .test(
      "is-amount-paid-greater-than-refund",
      "Payment amount is lesser than the applied/refunded amount",
      function (value) {
        const { total_refunded_amount, amount_excess } = this.options.context as any;

        if (total_refunded_amount && Number(amount_excess) < 0) {
          return false;
        }
        return true;
      }
    ),
  amount_paid_bank_currency: yup
    .string()
    .test(
      "amount-paid-bank-currency-non-zero",
      message().pm.amountPaidRequired,
      function (value) {
        const { currency_id, bank_currency_id } = this.options.context as any;
        // if bank currency id and currency id are different, then validate
        if (
          bank_currency_id &&
          currency_id !== bank_currency_id &&
          (!value || Number(value) === 0)
        ) {
          return false;
        }
        return true;
      }
    ),
  paid_through_account_name: yup
    .string()
    .required(message().pm.paidThroughRequired)
    .nonNullable(),
  check_number: yup
    .string()
    .when("payment_mode", {
      is: "Check",
      then: (schema) => schema.required(message().pm.checkNumberRequired),
    })
    .nullable(),
  check_expiry_in: yup
    .string()
    .test(
      "is-check-expiry-in-set",
      "Please choose check expiry in.",
      function (value) {
        const { payment_mode } = this.options.context as any;
        if (
          (value === null || value === "" || value === undefined) &&
          payment_mode === "Check"
        ) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  check_date: yup
    .string()
    .test("is_check_date_set", "Please enter check date", function (value) {
      const { payment_mode } = this.options.context as any;
      if (value === null && payment_mode === "Check") {
        return false;
      }

      return true;
    })
    .test(
      "check_date_value",
      "Please enter a valid check date",
      function (value) {
        const { payment_mode } = this.options.context as any;
        new Date(value || "").toString();
        if (
          new Date(value || "").toString() === "Invalid Date" &&
          payment_mode === "Check"
        ) {
          return false;
        }
        return true;
      }
    )
    .nullable(),
  check_expiry: yup
    .string()
    .test(
      "check_date_payment_date_check",
      "Check expiry date should be greater than or equal to payment date",
      function (value) {
        const { payment_date, payment_mode } = this.options.context as any;
        if (
          new Date(value || "").toString() !== "Invalid Date" &&
          new Date(value || "") < new Date(payment_date) &&
          payment_mode === "Check"
        ) {
          return false;
        }
        return true;
      }
    ),
  bank_charges_account_id: yup
    .mixed()
    .test(
      "is-bank-charges-set",
      message().pm.bankChargesAccountRequired,
      function (value) {
        const { bank_charges_amount } = this.options.context as any;

        if (
          value === null &&
          bank_charges_amount !== undefined &&
          Number(bank_charges_amount) !== 0
        ) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  bank_charges_amount: yup
    .mixed()
    .test(
      "is-bank-currency-set",
      message().pm.bankChargeAmountRequired,
      function (value) {
        const { bank_charges_account_id } = this.options.context as any;

        if (!value && bank_charges_account_id) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  unpaid_bill_lines: yup.array().of(
    yup.object().shape({
      payment_amount: yup
        .number()
        .test(
          "is-payment-amount-more",
          message().pm.amountGreaterDue,
          function (value) {
            const { amount_due } = this.parent as any;
            let amountDue = isNaN(amount_due) ? 0 : Number(amount_due);
            let paymentAmount = isNaN(value as any) ? 0 : Number(value);
            if (paymentAmount > amountDue) {
              return false;
            }
            return true;
          }
        ),
    })
  ),
  amount_for_payment: yup
    .mixed()
    .test("totalAmount", message().pm.totalGreaterAmountPaid, function (value) {
      let sumOfPayment = 0;
      const { amount_paid, unpaid_bill_lines } = this.options.context as any;
      let amountPaid = isNaN(amount_paid) ? 0 : Number(amount_paid);
      let modifiedValue =
        unpaid_bill_lines &&
        unpaid_bill_lines.map((item: any) => {
          return {
            payment_amount: isNaN(item.payment_amount as any)
              ? 0
              : Number(item.payment_amount),
          };
        });
      modifiedValue?.map((item: any) => {
        sumOfPayment = sumOfPayment + item.payment_amount;
      });
      if (
        Math.round(sumOfPayment * 1000000) / 1000000 >
        Math.round(amountPaid * 1000000) / 1000000
      ) {
        return false;
      }
      return true;
    }),
});
