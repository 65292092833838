import React, { useEffect, useState } from "react";
import BackButton from "../../../../../../common/components/BackButton";
import { useLocation, useNavigate } from "react-router-dom";

const RoleFormHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <div className="card-header">
      <BackButton onClick={() => navigate("/roles")} />
      <h1 className="edit-auditor-btn m-0">
        {path.includes("/create")
          ? "Add New Role"
          : path.includes("/edit")
          ? "Edit Role"
          : "Clone Role"}
      </h1>
    </div>
  );
};

export default RoleFormHeader;
