import { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getPreviousFiscalYears } from "../../../../helpers/fiscalYearHelper";
import { getFiscalYearLabel } from "../../../../helpers/getFiscalYearLabelHelper";
import { ObjectType } from "../../../../types";
import "./FiscalYearSelect.css";
import {
  currentUserSelector,
  fiscalYearFilterLabelSelector,
  setFiscalYearFilter,
  setFiscalYearFilterLabel,
} from "../../commonSlice";
import { customSelectStyle } from "../SelectCustomStyle";

export const FiscalYearSelect = () => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const fiscalYearFilterLabel = useAppSelector(fiscalYearFilterLabelSelector);
  const dispatch = useAppDispatch();
  const fiscalYearArray = getPreviousFiscalYears(
    10,
    currentUserInfo.organization_fiscal_year as string
  );
  let dispatchFilterArray: ObjectType[] = [];
  let dispatchFilterObject: ObjectType = {};
  let options: { label: string; value: string }[] = [];
  let [value, setValue] = useState<
    | {
        label: string;
        value: string;
      }
    | ""
  >({
    label: getFiscalYearLabel(fiscalYearArray[0]),
    value: getFiscalYearLabel(fiscalYearArray[0]),
  });
  options.push({
    label: "All",
    value: "all",
  });
  fiscalYearArray.map((value: ObjectType) => {
    options.push({
      label: getFiscalYearLabel(value),
      value: getFiscalYearLabel(value),
    });
    dispatchFilterArray.push({
      [getFiscalYearLabel(value)]: value,
    });
  });
  const handleChange = (
    e: SingleValue<"" | { label: string; value: string | number }>
  ) => {
    setValue({ label: (e && e.label) || "", value: (e && e.label) || "" });
    if (e && e.label !== "All") {
      dispatchFilterObject = dispatchFilterArray.filter(
        (obj) => Object.keys(obj)[0] && e && e.label === Object.keys(obj)[0]
      )[0];
      dispatch(setFiscalYearFilterLabel(Object.keys(dispatchFilterObject)[0]));
      dispatch(setFiscalYearFilter(Object.values(dispatchFilterObject)[0]));
    } else {
      dispatch(setFiscalYearFilterLabel("All"));
      dispatch(setFiscalYearFilter(""));
    }
  };
  return (
    <Select
      options={options}
      className="form-select custom-select fiscal_year_filter"
      name="fiscal_year_filter"
      id="fiscal_year_filter"
      onChange={handleChange}
      value={
        fiscalYearFilterLabel
          ? {
              label: fiscalYearFilterLabel,
              value: fiscalYearFilterLabel,
            }
          : value
      }
      inputValue=""
      onInputChange={() => {}}
      onMenuClose={() => {}}
      onMenuOpen={() => {}}
      isDisabled={false}
      placeholder="Select fiscal year"
      isSearchable={true}
      styles={customSelectStyle}
    />
  );
};
