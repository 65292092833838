import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";
import "./WriteOffRadioButtonGroup.css";
import { FormikProps } from "formik";

type WriteOffProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean | string;
  name: string;
  id: string;
  valuesArray: {
    label: string;
    value: string | boolean;
  }[];
  isDisabled: boolean;
  formik?: FormikProps<any>;
};
type CustomRadioButtonProps = {
  label: string;
  isChecked: boolean;
};
export const WriteOffRadioButton = (props: WriteOffProps) => {
 
  const CustomRadioButton = (radioProps: CustomRadioButtonProps) => {
    return radioProps.isChecked === true ? (
      <div className="write-off-radio-btn-wrapper selected-radio-btn">
        <Radio className="write-off-radio-btn" checked={true} />
        {"  "}
        <span className="write-off-radio-btn-label">{radioProps.label}</span>
      </div>
    ) : (
      <div className="write-off-radio-btn-wrapper">
        <Radio className="write-off-radio-btn" checked={false} />
        {"  "}
        <span className="write-off-radio-btn-label">{radioProps.label}</span>
      </div>
    );
  };
  return (
    <FormControl
      className="form-control-write-off-radio-button"
      disabled={props.isDisabled}
    >
      <RadioGroup
        row
        className="write-off-radio-group"
        aria-labelledby={props.id}
        name={props.name}
        id={props.id}
        value={props.value}
      >
        {props.valuesArray.map((item, index) => {
          return (
            <FormControlLabel
              key={`${item.label}-${index}`}
              tabIndex={index}
              className="write-off-form-control-label"
              value={item.value}
              onClick={() => {
                props.formik &&
                  props.formik.setFieldValue(props.name, item.value);
                  props.formik?.setFieldTouched(props.name,true)
              }}
              control={
                <Radio
                  icon={
                    <CustomRadioButton label={item.label} isChecked={false} />
                  }
                  checkedIcon={
                    <CustomRadioButton label={item.label} isChecked={true} />
                  }
                />
              }
              label=""
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
