import { FormikProps } from "formik";
import CustomDatepicker from "../../../../../../utils/atoms/datepicker";
import {
  getFormatedDate,
} from "../../../../../../../helpers/helper";
import moment from "moment";
import { conversionMinDate } from "../../../../../../constants/constants";
import { usePm } from "../../usePm";
import { useAppSelector } from "../../../../../../../app/hooks";
import { fiscalTearFilterForValidationSelector } from "../../../../../../common/commonSlice";
import useCommonData from "../../../../../../hooks/useCommon";

type Props = {
  formik: FormikProps<any>;
};
export const PaymentDate = (props: Props) => {
  const { paymentDateChangeHandler } = usePm({
    formik: props.formik,
  });
  const fiscalYearFilter = useAppSelector(
    fiscalTearFilterForValidationSelector
  );
  const maxDate =
    props.formik.values.refund_history &&
    props.formik.values.refund_history.length > 0
      ? props.formik.values.refund_history[0].refunded_on
      : "";
  const { currentUserInfo } = useCommonData();
  const closedFiscalYearEndDate = currentUserInfo.account_closing_date
    ? currentUserInfo.account_closing_date?.toString()
    : "";
  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        props.formik.setFieldValue("payment_date", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (getFormatedDate(new Date(closedFiscalYearEndDate)) > dateFormated && currentUserInfo.account_closing_date) {
          props.formik.setFieldValue("payment_date", "Old date");
        } else if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          paymentDateChangeHandler(dateFormated);
        } else {
          props.formik.setFieldValue("payment_date", "Invalid date");
        }
      }
    }
  };
  return (
    <div className={"col-12 col-lg-4 mw-325 "}>
      <label htmlFor="check_expiry" className="required">
        Payment Date
      </label>
      <div id="date" className="date-selector-wrapper">
        <CustomDatepicker
          date={new Date(props.formik.values.payment_date)}
          handleDate={handleDate}
          type="paymentDate"
          error={
            props.formik.errors?.payment_date &&
            props.formik?.touched.payment_date
              ? "error"
              : ""
          }
          id="payment_date"
          zIndex={0}
          placeholder="Enter payment date"
          enableFutureDate={true}
          maxDate={new Date(maxDate)}
          minDate={new Date(closedFiscalYearEndDate)}
        />
      </div>
      <span className="error error_payment_date">
        {props.formik.values?.payment_date && props.formik?.touched.payment_date
          ? props.formik.errors?.payment_date?.toString()
          : ""}
      </span>
    </div>
  );
};
