import {
  BillValues,
  CreditApplyToBill,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteBill,
  createNewBill,
  getBillNumber,
  deleteBillItemTax,
  sendBill,
  calculateTaxableAmount,
  calculateAmount,
  billPdfLink,
  deleteBillItem,
  getBillList,
  editBill,
  billNumberExist,
  getBillDetails,
  billAttachments,
  billAttachmentsDelete,
  deleteBillRecipient,
  transactionLimitStatus,
  applyCreditsToBills,
  creditsCalculation,
  applyCreditList,
  writeOffCreate,
  writeOffUpdate,
  writeOffDetails,
  writeOffDelete,
  getReportingTagJournals,
  putReportingTags,
  getTagsOptionsAssociatedToBills,
  billVoidMarker,
} from "./billsAPI";
import { RootState } from "../../../app/store";

export const billList = createAsyncThunk(
  "billList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    vendorList: number[];
    orgIds: number[];
    statusList: string[];
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getBillList(data);
  }
);

export const deleteBillDetails = createAsyncThunk(
  "deleteInvoice",
  async (data: { deleteBillId: number; orgId: number }) => {
    return await deleteBill(data);
  }
);

export const createBill = createAsyncThunk(
  "create/bill",
  async (data: { values: BillValues; status: string; orgId: number }) => {
    return await createNewBill(data);
  }
);

export const editBillDetails = createAsyncThunk(
  "edit/bill",
  async (data: {
    id: number;
    editBillInputs: BillValues;
    status: string;
    orgId: number;
  }) => {
    return await editBill(data);
  }
);

export const deleteBillItemDetails = createAsyncThunk(
  "delete/bill/item",
  async (data: { billId: number; itemId: number; orgId: number }) => {
    return await deleteBillItem(data);
  }
);

export const deleteBillRecipientDetails = createAsyncThunk(
  "bill/recipient/delete",
  async (data: { billId: number; emailIds: number[]; orgId: number }) => {
    return await deleteBillRecipient(data);
  }
);

export const billDetails = createAsyncThunk(
  "billDetail",
  async (data: { billId: number; orgId: number }) => {
    return await getBillDetails(data);
  }
);

export const getNewBillNumber = createAsyncThunk(
  "bill/number",
  async (orgId: number) => {
    return await getBillNumber(orgId);
  }
);

export const checkBillNumber = createAsyncThunk(
  "bill/number-exist",
  async (data: { billNumber: string; orgId: number; billId: number }) => {
    return await billNumberExist(data);
  }
);

export const deleteBillItemTaxDetails = createAsyncThunk(
  "delete/invoice/item/tax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteBillItemTax(data);
  }
);

export const sendBillData = createAsyncThunk(
  "send-bill",
  async (data: { billId: number; emails: string[]; orgId: number }) => {
    return await sendBill(data);
  }
);

export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateTaxableAmount(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const getBillPdfLink = createAsyncThunk(
  "bill/pdf",
  async (data: { billId: number; orgId: number }) => {
    return await billPdfLink(data);
  }
);

export const attachBillFiles = createAsyncThunk(
  "bill/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await billAttachments(data);
  }
);

export const deleteBillFile = createAsyncThunk(
  "bill/file/delete",
  async (data: { billId: number; fileId: number; orgId: number }) => {
    return await billAttachmentsDelete(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "bill/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const creditList = createAsyncThunk(
  "bill/apply-credit/list",
  async (data: { billId: number; orgId: number }) => {
    return await applyCreditList(data);
  }
);
export const applyCreditsCalculation = createAsyncThunk(
  "bill/apply-credit/calculate",
  async (data: {
    orgId: number;
    values: {
      bill_balance: number;
      allocation_list: number[];
      currency_id: number | null;
    };
    signal?: AbortSignal;
  }) => {
    return await creditsCalculation(data);
  }
);
export const applyCredits = createAsyncThunk(
  "bill/apply-credit",
  async (data: {
    bill_id: number;
    orgId: number;
    values: CreditApplyToBill[];
  }) => {
    return await applyCreditsToBills(data);
  }
);
export const createWriteOff = createAsyncThunk(
  "bill/write-off/create",
  async (data: {
    orgId: number;
    write_off_values: {
      amount: string | number;
      account_id: number | null;
      bill_id: number | null;
      is_partial: boolean;
    };
  }) => {
    return await writeOffCreate(data);
  }
);
export const updateWriteOff = createAsyncThunk(
  "bill/write-off/edit",
  async (data: {
    orgId: number;
    write_off_id: number | null;
    write_off_values: {
      amount: string | number;
      is_partial: boolean;
    };
  }) => {
    return await writeOffUpdate(data);
  }
);
export const getWriteOffDetails = createAsyncThunk(
  "bill/write-off/details",
  async (data: { orgId: number; bill_id: number | null }) => {
    return await writeOffDetails(data);
  }
);
export const deleteWriteOff = createAsyncThunk(
  "bill/write-off/delete",
  async (data: { orgId: number; write_off_id: number | null }) => {
    return await writeOffDelete(data);
  }
);

export const generateJournal = createAsyncThunk(
  "reporting-tag/journal-generate",
  async (data: { billId: number; orgId: number }) => {
    return await getReportingTagJournals(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { billId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);

export const fetchTagOptionForBills = createAsyncThunk(
  "reporting-tag/tag-options-for-bills",
  async (data: { billIds: number[]; orgId: number }) => {
    return await getTagsOptionsAssociatedToBills(data);
  }
);
export const markBillVoid = createAsyncThunk(
  "bill/mark-void",
  async (data: { billId: number | null; orgId: number }) => {
    return await billVoidMarker(data);
  }
);

const initialState = {
  gstTaxList: [],
};

export const billSlice = createSlice({
  name: "billManagement",
  initialState,
  reducers: {},
});

export default billSlice.reducer;
