import { useParams } from "react-router-dom";
import useCommonData from "../../../../hooks/useCommon";
import { initialCouponDefaultValues, initialCouponEditValues } from "../../CouponStateInitialization";
import { setLoaderState } from "../../../../common/commonSlice";
import { couponDetailsForEdit } from "../../couponSlice";
import { useAppSelector } from "../../../../../app/hooks";
import { accountsGlCodeSelector } from "../../../chartOfAccounts/coaSlice";
import { CouponformDetailValue } from "../../couponType";

export const useCouponFormIntitialization = () => {
  const accountsGlCode = useAppSelector(accountsGlCodeSelector);
  const { currentUserInfo, dispatch } = useCommonData();
    const { editId } = useParams();
    const fetchCouponDetails = async (setCouponValues: React.SetStateAction<any>, isClone: boolean) => {
      dispatch(setLoaderState(true));
      let details: CouponformDetailValue = initialCouponEditValues;
      const responseAction = await dispatch(
        couponDetailsForEdit({
          couponId: Number(editId),
          orgId: currentUserInfo.organization_id,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        details = {
          ...response,
          is_redeemed:  isClone ? false : response.is_redeemed,
          redeemed_count: isClone ? 0 : response.redeemed_count,
          name: isClone ? '' : response.name,
          valid_till: isClone ? '' : response.valid_till,
          maximum_redemptions: response.max_redemptions === -1 ? 0 : response.max_redemptions,
          account_name: accountsGlCode.account_list.filter((data: any) => data?.id === response.account_id)?.[0]?.account_name || ''
        };
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
      setCouponValues(details);
      return editId ? details: initialCouponDefaultValues;
    };
    return { fetchCouponDetails };
  };
  