import React from "react";
import ErrorInfo from "../../../../common/components/ErrorInfo";
import { exchangeApiStatusSelector } from "../../../../common/commonSlice";
import { useAppSelector } from "../../../../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import Delete from "../../../../common/components/Delete";

type Props = {
  name: string;
  isFormSubmit: boolean;
  isError: boolean;
  action: string;
  transactionUserId: string | number | undefined;
  isUserSelect: boolean;
  onClickSave: (status: string, action: string) => void;
  onClickDelete: () => void;
  editId: string | undefined;
  rolePermission: any;
  transactionStatus: string;
  isLocked: boolean;
  lockDate: string;
  redirectUrl: string;
  isFromInvoice: boolean;
};
function CreditNoteFormFooter({
  name,
  isFormSubmit,
  isError,
  action,
  transactionUserId,
  isUserSelect,
  onClickSave,
  onClickDelete,
  editId,
  rolePermission,
  transactionStatus,
  isLocked,
  lockDate,
  redirectUrl,
  isFromInvoice,
}: Props) {
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const navigate = useNavigate();
  return (
    <div className="row mb-0">
      <div className="col">
        <div
          className={
            isFormSubmit && !exchangeRateAPiStatus
              ? "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          {isError ? (
            <div className="form-error-container w-100 flex-column">
              <ErrorInfo
                message={`Oops, we were unable to ${action}. Please fix the errors.`}
              />
            </div>
          ) : null}
          <div className="form-btn-container d-flex w-100">
            <div className="btn-group save-only-btn-group ps-3 ms-1">
              <button
                type="button"
                className={`save-button mx-0 ${
                  transactionUserId && !exchangeRateAPiStatus
                    ? ""
                    : " customer-form-section-disable"
                }`}
                onClick={() => onClickSave("Open", "Send")}
                disabled={
                  isFormSubmit && transactionUserId && !exchangeRateAPiStatus
                    ? false
                    : true
                }
              >
                Save as Open
              </button>
            </div>
            <button
              type="button"
              className={`cancel-button save-as-draft-button ${
                transactionUserId && !exchangeRateAPiStatus
                  ? ""
                  : " customer-form-section-disable"
              }`}
              onClick={() => onClickSave("Draft", "")}
              disabled={
                isFromInvoice ||
                (((isFormSubmit && transactionUserId && isUserSelect) ||
                  (editId && transactionStatus === "Draft")) &&
                  !exchangeRateAPiStatus)
                  ? false
                  : true
              }
              style={
                (editId && transactionStatus !== "Draft") ||
                exchangeRateAPiStatus
                  ? { opacity: 0.5 }
                  : {}
              }
            >
              Save as Draft
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-decoration-none cancel-button mx-4"
            >
              Cancel
            </button>
            {editId && rolePermission.includes("Delete") ? (
              <Delete
                className={"text-decoration-none delete-item-button ml-auto"}
                deleteIcon={false}
                isMutable={true}
                onClick={onClickDelete}
                isLocked={isLocked}
                lockDate={lockDate}
                name={name}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditNoteFormFooter;
