import { getFormatedDate } from "../../../../../helpers/helper";
import { ObjectType } from "../../../../../types";
import moment from "moment";

export const PLDateArrayHelper = (
  compareWith: string,
  compareValue: number,
  date: { start: string; end: string; date_range: string }
) => {
  let dateArray: { start_date: string; end_date: string }[] = [];
  if (compareWith === "Previous Period(s)") {
    switch (date.date_range) {
      case "this_month":
        for (let i = 0; i < compareValue + 1; i++) {
          dateArray.push({
            start_date: getFormatedDate(
              moment(date.start).subtract(i, "months").toDate()
            ),
            end_date: getFormatedDate(
              moment(date.end).subtract(i, "months").endOf("month").toDate()
            ),
          });
        }
        break;
      case "this_week":
        for (let i = 0; i < compareValue + 1; i++) {
          dateArray.push({
            start_date: getFormatedDate(
              moment(date.start).subtract(i, "w").toDate()
            ),
            end_date: getFormatedDate(
              moment(date.end).subtract(i, "w").toDate()
            ),
          });
        }
        break;
      case "this_year":
        for (let i = 0; i < compareValue + 1; i++) {
          dateArray.push({
            start_date: getFormatedDate(
              moment(date.start).subtract(i, "y").toDate()
            ),
            end_date: getFormatedDate(
              moment(date.end).subtract(i, "y").toDate()
            ),
          });
        }
        break;
      case "this_quarter":
        for (let i = 0; i < compareValue + 1; i++) {
          dateArray.push({
            start_date: getFormatedDate(
              moment(date.start)
                .subtract(i * 3, "months")
                .toDate()
            ),
            end_date: getFormatedDate(
              moment(date.end)
                .subtract(i * 3, "months")
                .endOf("month")
                .toDate()
            ),
          });
        }
        break;
      case "today":
        for (let i = 0; i < compareValue + 1; i++) {
          dateArray.push({
            start_date: getFormatedDate(
              moment(date.start).subtract(i, "d").toDate()
            ),
            end_date: getFormatedDate(
              moment(date.end).subtract(i, "d").toDate()
            ),
          });
        }
        break;
      case "custom":
        const diff = moment(date.end).diff(moment(date.start), "days") + 1;
        dateArray.push({ start_date: date.start, end_date: date.end });
        for (let i = 1; i < compareValue + 1; i++) {
          dateArray.push({
            start_date: getFormatedDate(
              moment(dateArray[i - 1].start_date)
                .subtract(diff, "days")
                .toDate()
            ),
            end_date: getFormatedDate(
              moment(dateArray[i - 1].end_date)
                .subtract(diff, "days")
                .toDate()
            ),
          });
        }
        break;
    }
  } else {
    for (let i = 0; i < compareValue + 1; i++) {
      dateArray.push({
        start_date: getFormatedDate(
          moment(date.start).subtract(i, "y").toDate()
        ),
        end_date: getFormatedDate(moment(date.end).subtract(i, "y").toDate()),
      });
    }
  }

  return dateArray;
};
