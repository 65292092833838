type ButtonProps = {
  isDisabled?: boolean;
  onClick?: (e: any) => void;
};
export const SolidDeleteButton = (props: ButtonProps) => {
  return (
    <button
      type="button"
      className=" no-apperance btn-red delete-item-button ml-auto   r-3"
      onClick={props.onClick}
      disabled={props.isDisabled === true ? true : false}
    >
      Delete
    </button>
  );
};
