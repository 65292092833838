import React, {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormSelectField } from "../../../../common/components/formSelectField/FormSelectField";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  ObjectType,
  PaymentTerms,
  PurchaseOrderDefaultRef,
  PurchaseOrderDefaultValues,
  PurchaseOrderValues,
} from "../../../../../types";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { useParams } from "react-router-dom";
import { vendorListSelector } from "../../../vendorManagement/vendorSlice";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import PrefixInput from "../../../../common/components/PrefixInput/PrefixInput";
import { PaymentTermsModal } from "../../../../common/components/paymentTerms/paymentTermsModal";
import { TagsModal } from "../../../../common/components/tags/TagsModal";
import TagInput from "../../../../common/components/TagInput";
import {
  initialDefaultPurchaseOrderValues,
  initialDefaultValues,
} from "./StateInitialization";
import PaymentTermSelect from "../../../../common/components/PaymentTermSelect";
import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import VendorSelect from "../../../../common/components/VendorSelect";
import {
  currentUserSelector,
  fiscalTearFilterForValidationSelector,
  paymentTermsSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import moment from "moment";
import { getNewPurchaseOrderNumber } from "../../purchaseOrderSlice";
import { getGstTaxList, getStateList } from "../../../gst/gstSlice";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { AddVendorModal } from "../../../vendorManagement/components/addVendorModal/AddVendorModal";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import useGst from "../../../../hooks/useGst";
import _ from "lodash";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import {
  purchaseOrderStatus,
  gstRegistrationTypes,
  TAX_SYSTEM,
} from "../../../../constants/constants";
import NotificationModal from "../../../../common/components/NotificationModal";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import CustomCheckbox from "../../../../common/components/CustomCheckbox/CustomCheckbox";
import { getVendorDetails } from "../../../vendorManagement/vendorAPI";
import PredefinedDateRangePicker from "../../../reports/componets/PredefinedDateRange";

type Props = {
  defaultFormValues: PurchaseOrderDefaultValues;
  purchaseOrderFormValues: PurchaseOrderValues;
  formErrors: any;
  handleVendorChange: (e: any) => void;
  checkPurchaseOrderNumberExist: (purchaseOrderNumber: string) => void;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (state_type: string, gst_registration_type: string) => void;
  setSupplyState?: any;
  initialStateType: string;
  gstRegistrationType: string;
  setIsStateChanged: Dispatch<SetStateAction<boolean>>;
  setRCMEnable: (rcmEnabled: boolean) => void;
  handleExpiryDatePopupation: (issueDate: string, expiryDate: string) => void;
};

const DefaultDetails: ForwardRefRenderFunction<PurchaseOrderDefaultRef, Props> =
  (props, ref) => {
    type LocationProps = {
      consumerId?: string;
    };
    const location = usePreservedLocation();
    const dispatch = useAppDispatch();
    const { vendorId, editId } = useParams();
    const currentUserInfo = useAppSelector(currentUserSelector);
    const vendorList = useAppSelector(vendorListSelector);
    const locationState = location?.state as LocationProps;
    const consumerId = vendorId; //locationState?.consumerId;
    const subModulePermission = useSubModulePermission();
    const paymentTerms = useAppSelector(paymentTermsSelector);

    const [defaultFormValues, setDefaultFormValues] =
      useState<PurchaseOrderDefaultValues>(initialDefaultPurchaseOrderValues);
    const [isCustom, setIsCustom] = useState<boolean>(false);
    const [purchaseOrderNumberObj, setPurchaseOrderNumberObj] =
      useState<ObjectType>({});
    const [isTermsChange, setIsTermsChange] = useState<boolean>(true);
    const [dueDate, setDueDate] = useState<Date | null>(new Date());
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(new Date());
    const [stateOptions, setStateOptions] = useState<
      { label: string; value: string | number }[]
    >([]);
    const [
      sourceDestinationUpdateModalTriggered,
      setSourceDestinationUpdateModalTriggered,
    ] = useState<number>();
    const [taxAppliedFieldName, setTaxAppliedFieldName] = useState("");
    const purchaseOrdersCustomFields = useModuleCustomFieldList("Bills");
    const { isGstOrg } = useGst();
    const sourceDestinationUpdateModalRef = useRef<any>();
    const purchaseOrderDateTempRef = useRef<Date | null>(null);
    const fiscalYearFilter = useAppSelector(
      fiscalTearFilterForValidationSelector
    );
    const [advancedDateRangeValues, setAdvancedDateRangeValues] = useState({});

    useImperativeHandle(
      ref,
      () => ({
        defaultData: defaultFormValues,
        calculateDueDate: calculateDueDate,
        purchaseOrderNumber: joinForPayload(purchaseOrderNumberObj),
      }),
      [defaultFormValues, purchaseOrderNumberObj]
    );
    useEffect(() => {
      setDefaultFormValues({
        ...defaultFormValues,
        ...props.defaultFormValues,
      });
      setDueDate(new Date(props.defaultFormValues.delivery_date));
      if (editId) {
        if (props.defaultFormValues.payment_term_id === null) setIsCustom(true);
        const transaction_obj = splitTransactionNumber(
          props.defaultFormValues.purchase_order_number
        );
        const purchaseOrderNum_obj = {
          purchase_order_prefix: transaction_obj["prefix"],
          purchase_order_number: transaction_obj["number"],
        };
        setPurchaseOrderNumberObj(purchaseOrderNum_obj);
        // fetchGstTaxList(defaultFormValues.source_of_supply, defaultFormValues.destination_of_supply )
      }
    }, [props.defaultFormValues]);

    useEffect(() => {
      if (props.defaultFormValues.payment_term_id)
        calculateDueDate(
          props.defaultFormValues.payment_term_id,
          new Date(defaultFormValues.issue_date)
        );
    }, [props.defaultFormValues.payment_term_id]);

    useEffect(() => {
      if (consumerId) {
        defaultFormValues.vendor_id = consumerId;
        let vendor = vendorList
          .filter((vendor) => vendor.id === Number(consumerId))
          .map((vendor) => ({
            label: vendor.vendor_display_name,
            value: vendor.id,
          }))[0];
        if (vendor) handleVendorChange(vendor);
      }
    }, [consumerId, vendorList]);

    useEffect(() => {
      if (!editId) purchaseOrderNumber();

      // fetch states list dropdown options
      if (isGstOrg) getStateListOptions();
    }, []);

    useEffect(() => {
      handleDate(dueDate, "dueDate");
    }, [dueDate]);
    useEffect(() => {
      if (!editId) {
        handlePurchaseOrderDate(purchaseOrderDate, "purchase-order");
      }
    }, [purchaseOrderDate]);

    useEffect(() => {
      if (!editId) {
        paymentTerms.map((term: PaymentTerms, index) => {
          if (term.term_name == "Due on receipt") {
            setDefaultFormValues((values) => ({
              ...values,
              payment_term_id: term.id,
            }));
            // setCustomerPaymentTerm(term.id);
          }
        });
      }
    }, [paymentTerms]);

    useEffect(() => {
      sourceDestinationUpdateModalTriggered &&
        sourceDestinationUpdateModalRef.current?.openAlertModal &&
        props.gstRegistrationType !== gstRegistrationTypes.overseas &&
        props.gstRegistrationType !== gstRegistrationTypes.sez &&
        props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
        sourceDestinationUpdateModalRef.current?.openAlertModal();
    }, [sourceDestinationUpdateModalTriggered]);
    useEffect(() => {
      handleAdvancedDateRangeValues(defaultFormValues.issue_date);
    }, [defaultFormValues.issue_date]);

    $(function () {
      setTimeout(() => {
        $(document).on(
          "focus",
          ".multiselect-container .searchBox",
          function () {
            $(this).parent().addClass("focused");
          }
        );
        $(document).on(
          "blur",
          ".multiselect-container .searchBox",
          function () {
            $(this).parent().removeClass("focused");
          }
        );
      }, 50);
    });

    /**
     * Fetch state dropdwon options
     */
    const getStateListOptions = async () => {
      const result = await dispatch(
        getStateList({ orgId: currentUserInfo.organization_id })
      );
      const states = result.payload as string[];
      let statesArray = _.values(states);
      let stateOptionsTmp = statesArray.map((state) => {
        return {
          label: state,
          value: state,
        };
      });
      setStateOptions(stateOptionsTmp);
    };

    /**
     * Get purchase order number
     */
    const purchaseOrderNumber = async () => {
      const responseAction = await dispatch(
        getNewPurchaseOrderNumber(currentUserInfo.organization_id)
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setPurchaseOrderNumberObj(response);
          let purchase_order_number = joinForPayload(response);
          setDefaultFormValues((values) => ({
            ...values,
            purchase_order_number: purchase_order_number
              ? purchase_order_number
              : "",
          }));
        }
      }
      dispatch(setLoaderState(false));
    };

    const getPrefix = (value: string) => {
      setPurchaseOrderNumberObj({
        ...purchaseOrderNumberObj,
        ["purchase_order_prefix"]: value.toUpperCase(),
      });
    };

    const handleVendorChange = (e: {
      label: string;
      value: string | number;
    }) => {
      setDefaultFormValues((values) => ({
        ...values,
        vendor_id: e.value.toString(),
        vendor_name: e.label.trim(),
      }));
      props.handleVendorChange(e);
    };

    const handleTagChange = (selectedTag: never[]) => {
      setDefaultFormValues((values) => ({ ...values, tag_ids: selectedTag }));
    };

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      if (name === "purchase_order_number") {
        props.checkPurchaseOrderNumberExist(
          purchaseOrderNumberObj["purchase_order_prefix"] +
            "~" +
            value.replace("~", "").toUpperCase()
        );
        setDefaultFormValues({
          ...defaultFormValues,
          [name]:
            purchaseOrderNumberObj["purchase_order_prefix"] +
            "~" +
            value.replace("~", "").toUpperCase(),
        });
        setPurchaseOrderNumberObj({
          ...purchaseOrderNumberObj,
          [name]: value.replace("~", "").toUpperCase(),
        });
      } else if (name === "payment_term_id" && value === "Custom") {
        setDefaultFormValues({ ...defaultFormValues, [name]: null });
      } else if (name === "reference_number") {
        if (value.length < 17) {
          setDefaultFormValues({ ...defaultFormValues, [name]: value });
        }
      } else {
        setDefaultFormValues({ ...defaultFormValues, [name]: value });
      }
    };

    const onStateChanged = async (
      name: string,
      option: { label: string; value: string }
    ) => {
      let newDefaultFormValues = { ...defaultFormValues, [name]: option };
      setDefaultFormValues(newDefaultFormValues);
      name === "destination_of_supply"
        ? setTaxAppliedFieldName("Destination")
        : name === "source_of_supply"
        ? setTaxAppliedFieldName("Source")
        : setTaxAppliedFieldName("");
      props.setSupplyState(option.value);
      if (
        newDefaultFormValues?.source_of_supply &&
        newDefaultFormValues?.destination_of_supply
      ) {
        if (
          props.initialStateType === "Inter State" &&
          newDefaultFormValues?.source_of_supply.value ===
            newDefaultFormValues?.destination_of_supply.value
        ) {
          setSourceDestinationUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        if (
          props.initialStateType === "Intra State" &&
          newDefaultFormValues?.source_of_supply.value !==
            newDefaultFormValues?.destination_of_supply.value
        ) {
          setSourceDestinationUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }

        let state_type =
          !newDefaultFormValues?.source_of_supply ||
          !newDefaultFormValues?.destination_of_supply
            ? ""
            : newDefaultFormValues?.source_of_supply.value ===
              newDefaultFormValues?.destination_of_supply.value
            ? "Intra State"
            : "Inter State";
        props.setIsStateChanged(true);
        props.setStateType(state_type);
        props.fetchGstTaxList(state_type, props.gstRegistrationType);
      } else {
        props.setStateType("");
      }
    };

    const handleDate = (date: Date | null, type: string) => {
      if (date) {
        if (date?.toString() === "Invalid Date") {
          if (type === "purchase-order") {
            setDefaultFormValues((values) => ({
              ...values,
              issue_date: "Invalid date",
            }));
          } else if (type === "dueDate") {
            setDefaultFormValues((values) => ({
              ...values,
              delivery_date: "Invalid date",
            }));
          }
        } else if (new Date(date) > new Date() && type !== "dueDate") {
          setDefaultFormValues((values) => ({
            ...values,
            issue_date: "Future date",
          }));
        } else {
          const dateFormated = getFormatedDate(date);
          setIsCustom(false);
          if (type === "purchase-order") {
            if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
              if (defaultFormValues.payment_term_id !== "") {
                if (defaultFormValues.payment_term_id !== null) {
                  calculateDueDate(defaultFormValues.payment_term_id, date);
                }
              }
              if (defaultFormValues.payment_term_id === null) {
                setIsCustom(true);
              }
              setDefaultFormValues((values) => ({
                ...values,
                issue_date: dateFormated,
              }));

              props.handleNewConversionDate(dateFormated);
            } else {
              setDefaultFormValues((values) => ({
                ...values,
                issue_date: "Invalid date",
              }));
            }
          } else if (type === "dueDate") {
            // if (
            //   new Date(fiscalYearFilter.startDate)
            //     .setHours(5, 30)
            //     .toLocaleString() >
            //   new Date(date).setHours(5, 30).toLocaleString()
            // ) {
            //   setDefaultFormValues((values) => ({
            //     ...values,
            //     delivery_date: "Old date",
            //   }));
            // } else
            if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
              if (
                !isTermsChange &&
                dateFormated !== defaultFormValues.delivery_date
              ) {
                setIsCustom(true);
                setDefaultFormValues((values) => ({
                  ...values,
                  payment_term_id: null,
                }));
              }
              setIsTermsChange(false);
              setDefaultFormValues((values) => ({
                ...values,
                delivery_date: dateFormated,
              }));
            } else {
              setDefaultFormValues((values) => ({
                ...values,
                delivery_date: "Invalid date",
              }));
            }
          }
        }
      }
    };

    const changePurchaseOrderDate = (date: Date | null, type: string) => {
      if (date) {
        if (date?.toString() === "Invalid Date") {
          setDefaultFormValues((values) => ({
            ...values,
            issue_date: "Invalid date",
            recalculate_exchange_rate: false,
          }));
          // } else if (
          //   new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() >
          //   new Date(date).setHours(5, 30).toLocaleString()
          // ) {
          //   setDefaultFormValues((values) => ({
          //     ...values,
          //     issue_date: "Old date",
          //   }));
        } else {
          const dateFormated = getFormatedDate(date);
          setIsCustom(false);
          if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
            if (defaultFormValues.payment_term_id !== "") {
              if (defaultFormValues.payment_term_id !== null) {
                calculateDueDate(defaultFormValues.payment_term_id, date);
              }
            }
            if (defaultFormValues.payment_term_id === null) {
              setIsCustom(true);
            }
            setDefaultFormValues((values) => ({
              ...values,
              issue_date: dateFormated,
              recalculate_exchange_rate: true,
            }));
            props.handleExpiryDatePopupation(
              dateFormated,
              defaultFormValues.delivery_date
            );
            let conversionDate =
              new Date(dateFormated) > new Date()
                ? getFormatedDate(new Date())
                : dateFormated;
            props.handleNewConversionDate(conversionDate);
          } else {
            setDefaultFormValues((values) => ({
              ...values,
              issue_date: "Invalid date",
              recalculate_exchange_rate: false,
            }));
          }
        }
      }
    };

    const handlePurchaseOrderDate = (date: Date | null, type: string) => {
      if (
        ((editId &&
          defaultFormValues.purchase_order_status ===
            purchaseOrderStatus.partiallyPaid) ||
          defaultFormValues.purchase_order_status ===
            purchaseOrderStatus.closed ||
          defaultFormValues.purchase_order_status.includes(
            purchaseOrderStatus.overdue
          )) &&
        date
      ) {
        if (fiscalYearFilter.startDate > getFormatedDate(date || undefined)) {
          ErrorToaster(
            "Invalid Transaction Date: Transaction date must be within the current fiscal year"
          );
          setDefaultFormValues((prevValue) => {
            return {
              ...prevValue,
              issue_date: defaultFormValues.issue_date,
            };
          });
          return;
        } else {
          setDefaultFormValues((prevValue) => {
            return {
              ...prevValue,
              issue_date: defaultFormValues.issue_date,
            };
          });
          purchaseOrderDateTempRef.current = date;
          $("#notificationPopupModal").modal("show");
          return;
        }
      } else {
        changePurchaseOrderDate(date, type);
      }
    };
    const handleExchangeRateCancel = (date: Date | null, type: string) => {
      if (
        ((editId &&
          defaultFormValues.purchase_order_status ===
            purchaseOrderStatus.partiallyPaid) ||
          defaultFormValues.purchase_order_status ===
            purchaseOrderStatus.closed ||
          defaultFormValues.purchase_order_status.includes(
            purchaseOrderStatus.overdue
          )) &&
        date
      ) {
        setDefaultFormValues((prevValue) => {
          return {
            ...prevValue,
            issue_date: getFormatedDate(date),
          };
        });
        if (moment(getFormatedDate(date), "YYYY-MM-DD", true).isValid()) {
          if (defaultFormValues.payment_term_id !== "") {
            if (defaultFormValues.payment_term_id !== null) {
              calculateDueDate(defaultFormValues.payment_term_id, date);
            }
          }
        }
      }
    };

    /**
     * Function to calculate the due date of the purchase order
     */
    const calculateDueDate = (
      termId: string | number,
      purchaseOrderDate: Date
    ) => {
      let currentDate = new Date();
      purchaseOrderDate = purchaseOrderDate ? purchaseOrderDate : currentDate;
      if (termId) {
        setIsTermsChange(false);
        if (termId === "Custom") {
          setDueDate(null);
          setDefaultFormValues({
            ...defaultFormValues,
            payment_term_id: null,
            delivery_date: "",
          });
        } else {
          setIsTermsChange(true);
          let selectedTermId = Number(termId);
          let selectedPaymentTerm = paymentTerms.filter(
            (tag) => tag.id === selectedTermId
          )[0];
          if (selectedPaymentTerm) {
            if (selectedPaymentTerm.term_name === "Due on receipt") {
              setDueDate(purchaseOrderDate);
            } else if (selectedPaymentTerm.term_name === "Due end of month") {
              setDueDate(
                new Date(
                  purchaseOrderDate.getFullYear(),
                  purchaseOrderDate.getMonth() + 1,
                  0
                )
              );
            } else if (
              selectedPaymentTerm.term_name === "Due end of next month"
            ) {
              setDueDate(
                new Date(
                  purchaseOrderDate.getFullYear(),
                  purchaseOrderDate.getMonth() + 2,
                  0
                )
              );
            } else {
              let termDays = selectedPaymentTerm.term_days;
              if (termDays) {
                purchaseOrderDate.setDate(
                  purchaseOrderDate.getDate() + Number(termDays)
                );
                setDueDate(purchaseOrderDate);
              } else {
                setDueDate(purchaseOrderDate);
              }
            }
          }
        }
      } else {
        setDueDate(currentDate);
      }
    };

    const handlePaymentTermChange = (e: any) => {
      if (e.value) {
        let value = e.value;
        if (e.value === "Custom") {
          value = null;
        }
        setDefaultFormValues((values) => ({
          ...values,
          payment_term_id: value,
        }));
      }
    };

    const checkVendorSelectIsDisabled = () => {
      if (editId) {
        return !props.defaultFormValues.is_mutable;
      }
      return false;
    };
    const notificationModalMessage =
      purchaseOrderDateTempRef.current &&
      `If you select a ${
        getFormatedDate(purchaseOrderDateTempRef.current) >
        getFormatedDate(new Date())
          ? "future"
          : getFormatedDate(purchaseOrderDateTempRef.current) <
            getFormatedDate(new Date())
          ? "past"
          : "current"
      } date, the exchange rate as of ${
        getFormatedDate(purchaseOrderDateTempRef.current) <
        getFormatedDate(new Date())
          ? "that day"
          : "today"
      } will be applied to this transaction. Would you like to recalculate the item rates based on the ${
        getFormatedDate(purchaseOrderDateTempRef.current) <
        getFormatedDate(new Date())
          ? "historical"
          : "current"
      } exchange rate?`;
    const rcmEnableChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      let rcmEnabled: boolean = e.target.checked;
      setDefaultFormValues((prevValue) => {
        return {
          ...prevValue,
          rcm_enabled: rcmEnabled,
        };
      });
      props.setRCMEnable(rcmEnabled);
    };
    const handleAdvancedDateRangeValues = (issueDate: string) => {
      setAdvancedDateRangeValues({
        "On issue date": [
          moment(issueDate).toDate(),
          moment(issueDate).toDate(),
        ],
        "In 7 days": [
          moment(issueDate).add(7, "days").toDate(),
          moment(issueDate).add(7, "days").toDate(),
        ],
        "In 14 days": [
          moment(issueDate).add(14, "days").toDate(),
          moment(issueDate).add(14, "days").toDate(),
        ],
        "In 30 days": [
          moment(issueDate).add(30, "days").toDate(),
          moment(issueDate).add(30, "days").toDate(),
        ],
      });
    };
    const handleDeliveryDate = (date: any) => {
      setDefaultFormValues((prevValue) => {
        return {
          ...prevValue,
          delivery_date: date,
        };
      });
    };
    return (
      <section className="form-top-section">
        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-325">
            <div className="link-label-grouped">
              <label htmlFor="customer" className="required">
                Vendor Name
              </label>
              <AddVendorModal />
            </div>
            <div>
              <VendorSelect
                vendorId={defaultFormValues.vendor_id}
                errors={props.formErrors.vendor_name}
                onChange={handleVendorChange}
                isDisabled={checkVendorSelectIsDisabled()}
              />
              <span className="error">{props.formErrors.vendor_name}</span>
            </div>
          </div>
          <div className="col-12 col-lg-4 mw-325">
            <label htmlFor="purchase_order_number" className="required">
              Purchase Order Number
            </label>
            <br />
            <PrefixInput
              prefix={defaultFormValues.purchase_order_number.split("~")[0]}
              getPrefix={getPrefix}
              name="purchase_order_number"
              id="purchase_order_number"
              error={
                props.formErrors.purchase_order_number
                  ? props.formErrors.purchase_order_number
                  : ""
              }
              className="form-control border-end-0"
              placeholder="Enter purchase order number"
              value={defaultFormValues.purchase_order_number.split("~")[1]}
              disabled={false}
              maxLength={11}
              onChange={handleChange}
            />
            <span className="error">
              {props.formErrors.purchase_order_number}
            </span>
          </div>
        </div>
        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-325">
            <label htmlFor="issue_date">Issue Date</label>
            <br />
            <div className="date-selector-wrapper">
              <CustomDatepicker
                date={new Date(defaultFormValues.issue_date)}
                handleDate={handlePurchaseOrderDate}
                type="issue_date"
                error={props.formErrors.issue_date ? "error" : ""}
                id="issue_date"
                zIndex={0}
                enableFutureDate={true}
                module="Bills"
              />
            </div>
            <span className="error">{props.formErrors.issue_date}</span>
          </div>
          <FormInputField
            type="text"
            name="reference_number"
            id="ref_no"
            className={
              props.formErrors.reference_number
                ? "form-control error"
                : "form-control"
            }
            label="Reference Number"
            placeholder="Enter reference number"
            maxLength={16}
            value={defaultFormValues.reference_number}
            onChange={handleChange}
            error={props.formErrors.reference_number}
          />
        </div>
        <div className="row custom-row">
          {/* <div className="col-12 col-lg-4 mw-325">
          <span className="align-wrapper">
            <div className="link-label-grouped">
              <label htmlFor="tags">Tags</label>
              {subModulePermission.includes("Tags") ? <TagsModal /> : null}
            </div>
          </span>
          <TagInput
            tagIds={defaultFormValues.tag_ids}
            handleChange={handleTagChange}
          />
        </div> */}
          <div className="col-12 col-lg-4 mw-325">
            <label htmlFor="delivery_date">Delivery Date</label>
            <div id="date-two" className="date-selector-wrapper">
              <PredefinedDateRangePicker
                isSingleDatePicker={true}
                isAdvanced={true}
                defaultValue={defaultFormValues.issue_date}
                issueDate={defaultFormValues.issue_date}
                reportType={"Invoice"}
                resetType={""}
                handleDateRange={handleDeliveryDate}
                filterdata={{}} //filterByNav}
                customAdvanceDateRangeValues={advancedDateRangeValues}
                error={props.formErrors.delivery_date}
              />
            </div>
          </div>
          {isGstOrg &&
            props.gstRegistrationType !== gstRegistrationTypes.overseas && (
              <FormSelectField
                key={0}
                name="source_of_supply"
                id="source_of_supply"
                label="Source of Supply"
                options={stateOptions}
                wrapperClass="mw-325"
                className="state-select form-select custom-select"
                value={
                  defaultFormValues?.source_of_supply
                    ? defaultFormValues?.source_of_supply
                    : ""
                }
                onChange={(option: any) => {
                  onStateChanged("source_of_supply", option);
                }}
                styles={customSelectStyle}
                placeholder={"Choose State"}
                isSearchable={true}
                isDisabled={false}
                error={props.formErrors.source_of_supply}
                isRequired={true}
                isOnlyInRow={false}
              />
            )}
        </div>
        <div>
          <FormInputFieldsInRows
            getFieldValues={props.getCustomFieldData}
            customFieldsArray={purchaseOrdersCustomFields}
            initialValues={props.customFieldValue}
            ref={props.customFieldRef}
            editId={editId ? Number(editId) : null}
          />
        </div>
        <div className="row custom-row">
          {isGstOrg && (
            <div className="col-12 col-lg-4 mw-325">
              <FormSelectField
                key={1}
                name="destination_of_supply"
                id="destination_of_supply"
                label="Destination of Supply"
                options={stateOptions}
                wrapperClass="mw-325"
                className="state-select form-select custom-select"
                value={
                  defaultFormValues?.destination_of_supply
                    ? defaultFormValues?.destination_of_supply
                    : ""
                }
                onChange={(option: any) => {
                  onStateChanged("destination_of_supply", option);
                }}
                styles={customSelectStyle}
                placeholder={"Choose State"}
                isSearchable={true}
                isDisabled={false}
                error={props.formErrors.destination_of_supply}
                isRequired={true}
                isOnlyInRow={false}
              />
            </div>
          )}
        </div>
        {currentUserInfo.organization_tax_system ===
          TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
        isGstOrg &&
        props.gstRegistrationType !== gstRegistrationTypes.composition &&
        props.gstRegistrationType !== gstRegistrationTypes.deemedExp ? (
          <div className="row custom-row">
            <div className="col-12 ps-3 pe-3">
              <CustomCheckbox
                checkboxName="rcm_enabled"
                checkboxId="rcm_enabled"
                isDisabled={false}
                isChecked={
                  defaultFormValues.rcm_enabled
                    ? defaultFormValues.rcm_enabled
                    : false
                }
                changeHandler={rcmEnableChangeHandler}
              >
                <label
                  className="form-check-label fw-normal"
                  htmlFor="rcm_enabled"
                >
                  This transaction is applicable for reverse charge
                </label>
              </CustomCheckbox>
            </div>
          </div>
        ) : (
          ""
        )}
        <AlertModalPopup
          message={`We will update the taxes applied to the items in this transaction as you've changed the ${taxAppliedFieldName} Of Supply`}
          modalTitle="Alert!"
          isCloseButtonEnabled={true}
          isSubmitEnabled={false}
          openRef={sourceDestinationUpdateModalRef}
        />
        <div className="notification-modal-popup">
          <NotificationModal
            modalHeader=""
            modalBody={notificationModalMessage ? notificationModalMessage : ""}
            modalSubmit="Yes"
            modalCancel="No"
            modalSubmitHandler={() =>
              changePurchaseOrderDate(
                purchaseOrderDateTempRef.current,
                "purchase-order"
              )
            }
            modalCancelHandler={() =>
              handleExchangeRateCancel(
                purchaseOrderDateTempRef.current,
                "purchase-order"
              )
            }
          />
        </div>
      </section>
    );
  };

export default React.memo(forwardRef(DefaultDetails));
