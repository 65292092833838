import { SvgIconProp } from "../../../../types";

export const RefundIcon = (props: SvgIconProp) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25342 2.00439H15.7534C16.1677 2.00439 16.5034 2.34018 16.5034 2.75439V8.5889H15.0034V5.2695C14.1559 5.01851 13.4872 4.35114 13.2344 3.50439H4.76567C4.51353 4.34889 3.84775 5.01495 3.00342 5.26748V10.7369C3.84922 10.9899 4.51584 11.6578 4.76698 12.5044H11.3583V14.0044H2.25342C1.83921 14.0044 1.50342 13.6686 1.50342 13.2544V2.75439C1.50342 2.34018 1.83921 2.00439 2.25342 2.00439Z"
        fill={props.color}
      />
      <path
        d="M11.3018 8.0022C11.3018 9.27327 10.2713 10.3037 9.00026 10.3037C7.72915 10.3037 6.69873 9.27327 6.69873 8.0022C6.69873 6.73111 7.72915 5.70068 9.00026 5.70068C10.2713 5.70068 11.3018 6.73111 11.3018 8.0022Z"
        fill={props.color}
      />
      <path
        d="M15.0028 10.537L14.1244 10.537L14.1244 8.6691L10.8609 11.28L14.1244 13.8908L14.0662 12.0496L15.0028 12.0496C15.904 12.0496 16.5764 12.6632 16.5764 13.5644C16.5764 14.4657 15.904 15.1085 15.0028 15.1085L11.2655 15.1085L11.2655 16.6111L15.0028 16.6111C16.625 16.6111 18 15.1866 18 13.5644C18 11.9423 16.625 10.537 15.0028 10.537Z"
        fill={props.color}
      />
    </svg>
  );
};
