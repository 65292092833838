import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  PlanDefaultRef,
  PlanDefaultValues,
  PlanItemDetails,
  SelectValues,
  ObjectType,
} from "../../../../../types";
import { useNavigate } from "react-router-dom";
import { FormSelectFieldPlacement } from "../../../../common/components/formSelectField/FormSelectFieldPlacement";
import { SelectCustomStyleCustomer } from "../../../../common/components/SelectCustomStyleCustomer";
import { colors } from "../../../../constants/colors";

import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { initialDefaultValues } from "./StateInitialization";
import { RemoveIcon } from "../../../../common/components/customSvgIcons/RemoveIcon";
import { validate } from "./ValidatePlanForm";
import ScrollToError from "../../../../common/components/scrollToError";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../hooks/useCommon";
import { message } from "../../../../constants/messages";

type Props = {
  itemDropdownList: any;
  checkInternalName: (internalName: string) => void;
  onClickSubmit: (data: object) => void;
  isInternalNameExist: boolean;
  planData: PlanDefaultValues;
  editId: string | undefined;
};

const DefaultDetails: ForwardRefRenderFunction<PlanDefaultRef, Props> = (
  props
) => {
  const navigate = useNavigate();
  const { orgCurrencyList, currentUserInfo } = useCommonData();

  const [defaultFormValues, setDefaultFormValues] =
    useState<PlanDefaultValues>(initialDefaultValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [items, setItems] = useState<PlanItemDetails[]>([]);
  const [dropdownValues, setDropdownValues] = useState<SelectValues[]>([]);
  const [dropdownLabel, setDropdownLabel] = useState("Select Items");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "internal_name") {
      props.checkInternalName(value);
    }
    setDefaultFormValues({ ...defaultFormValues, [name]: value });
  };
  const getItemDetails = (id: any) => {
    const accountItem = props.itemDropdownList.find(
      (data: { id: any }) => data.id === id
    );
    return accountItem;
  };
  const processPlanData = (obj: PlanItemDetails) => {
    const itemDetails = getItemDetails(obj.inventory_item_id);
    return {
      id: obj.inventory_item_id,
      name: obj.item_name,
      account_id: obj.account_id,
      hsn_or_sac_code: obj.hsn_or_sac_code,
      rate: obj.rate,
      tax_preference: itemDetails.tax_preference,
      account_name: itemDetails.account_name,
      currency_code: itemDetails.currency_code,
      dummyId: obj.id,
    };
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (props.planData && props.editId) {
      setDefaultFormValues(props.planData);
      const processedItems = props.planData.items.map(processPlanData);
      setItems(processedItems);
      setDropdownLabel(
        props.planData.items && props.planData.items.length
          ? `${props.planData.items.length} items selected`
          : "Select Items"
      );
      let result = dropdownValues
        .filter(
          (itemA) =>
            !props.planData.items.some(
              (itemB) => itemA.value === itemB.inventory_item_id
            )
        )
        .map(({ label, value }) => ({ label, value }));
      setDropdownValues(result);
    }
  }, [props.planData, props.editId, props.itemDropdownList]);

  useEffect(() => {
    const transformedArray = props.itemDropdownList.map(
      (item: { name: string; id: any }) => ({
        label: item.name.trim(),
        value: item.id,
      })
    );

    setDropdownValues(transformedArray);
  }, [props.itemDropdownList]);

  const processItems = (obj: PlanItemDetails) => {
    let objData: {
      inventory_item_id: string | number | null | undefined;
      item_name: string | undefined;
      account_id: number | undefined;
      hsn_or_sac_code: number | undefined;
      rate: number;
      id?: string | number;
    } = {
      inventory_item_id: obj.id,
      item_name: obj.name,
      account_id: obj.account_id,
      hsn_or_sac_code: obj.hsn_or_sac_code,
      rate: obj.rate,
    };
    if (obj.dummyId) {
      objData.id = obj.dummyId;
    }
    return objData;
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let errors = validate(defaultFormValues, items, props.isInternalNameExist);
    if (Object.keys(errors)[0]) {
      setFormErrors(errors);
      ScrollToError(Object.keys(errors)[0]);
    } else {
      const processedItems = items.map(processItems);
      if (defaultFormValues.external_name === "")
        defaultFormValues.external_name = defaultFormValues.internal_name;
      defaultFormValues.items = processedItems;
      if (props.planData.plan_exists_subscription) {
        defaultFormValues.items = [];
      }
      props.onClickSubmit(defaultFormValues);
    }
  };

  const handleRemove = (e: any, details: any) => {
    e.preventDefault();
    const itemsArray = items.filter((item) => item.id !== details.id);
    setItems(itemsArray);
    setDropdownLabel(
      itemsArray.length ? `${itemsArray.length} items selected` : "Select Items"
    );
    setDropdownValues((prevItems) => [
      ...prevItems,
      {
        label: details.name.trim(),
        value: details.id,
      },
    ]);
    if (props.editId) {
      const addDeleteItem = defaultFormValues.delete_items
        ? defaultFormValues.delete_items
        : [];
      const filterDefaultFormItem = defaultFormValues.items.find(
        (val: PlanItemDetails) => val.inventory_item_id === details.id
      );
      if (filterDefaultFormItem && filterDefaultFormItem.id) {
        addDeleteItem.push(filterDefaultFormItem.id);
      }
      setDefaultFormValues({
        ...defaultFormValues,
        delete_items: addDeleteItem,
      });
    }
  };

  const handleDropdownChange = (value: any) => {
    const filterItem = props.itemDropdownList.find(
      (val: { id: any }) => val.id === value.value
    );
    const transformedArray = dropdownValues.filter(
      (item) => item.value !== filterItem.id
    );
    setDropdownValues(transformedArray);
    setItems((prevItems) => {
      const updatedItems = [...prevItems, filterItem];
      setDropdownLabel(`${updatedItems.length} items selected`);
      return updatedItems;
    });
  };

  useEffect(() => {
    if (props.isInternalNameExist) {
      setFormErrors({
        ...formErrors,
        internal_name:
          "Internal Name already exists. Please choose a different one",
      });
    } else {
      setFormErrors({ ...formErrors, internal_name: "" });
    }
  }, [props.isInternalNameExist]);

  const Item = (values: any) => {
    let { details } = values;
    return (
      <>
        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-325">
            <p className="item-number">{details.name || details.item_name}</p>
            <div className="item-details">
              {details?.hsn_or_sac_code ? (
                <span>{`HSN/SAC: ${details.hsn_or_sac_code}`}</span>
              ) : null}
              <span
                className={
                  details?.hsn_or_sac_code ? "plan-item-rate" : "remove-padding"
                }
              >
                {`Rate: ${NumberFormat(
                  Number(details.rate),
                  details.currencyCode
                    ? details.currencyCode
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )}`}
              </span>
            </div>
            <div className="item-details">
              <span>Account: {details.account_name}</span>
            </div>
          </div>
          {!props.planData.plan_exists_subscription && (
            <div className="col-12 col-lg-1 mw-325 box">
              <a
                href="#"
                className="removeItem"
                onClick={(e) => handleRemove(e, details)}
              >
                <RemoveIcon color={colors.battleshipGrey} />
              </a>
            </div>
          )}
        </div>
        <div className="wd-33">
          <hr />
        </div>
      </>
    );
  };
  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <FormInputField
          type="text"
          name="internal_name"
          id="int"
          className={
            formErrors.internal_name ? "form-control error" : "form-control"
          }
          label="Plan Name (Internal Name)"
          placeholder="Enter Plan Name (Internal)"
          maxLength={100}
          value={defaultFormValues.internal_name}
          onChange={handleChange}
          error={formErrors.internal_name}
          isRequired={true}
          tooltipMessage={message().plan.planNameInt}
        />
        <div className="col-12 col-lg-4 mw-325">
          <FormInputField
            type="text"
            name="external_name"
            id="ext"
            className={
              formErrors.external_name ? "form-control error" : "form-control"
            }
            label="Plan Name (External Name)"
            placeholder="Enter Plan Name (External)"
            maxLength={100}
            value={defaultFormValues.external_name}
            onChange={handleChange}
            error={formErrors.external_name}
            tooltipMessage={message().plan.planNameExt}
          />
        </div>
      </div>

      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="mb-3">
            <label htmlFor="description" className="form-label form-labels">
              Description
            </label>
            <textarea
              className={`form-control ${
                formErrors.description ? "error" : ""
              } line-height-normal`}
              id="description"
              name="description"
              value={defaultFormValues.description}
              placeholder="Add description"
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-4 mw-325">
        <FormSelectFieldPlacement
          name="items"
          id="planItems"
          label="Select Items"
          options={dropdownValues}
          wrapperClass="mw-325"
          className="state-select form-select custom-select"
          value=""
          onChange={(option: any) => {
            handleDropdownChange(option);
          }}
          styles={SelectCustomStyleCustomer}
          placeholder={dropdownLabel}
          isSearchable={true}
          isDisabled={props.planData.plan_exists_subscription ? true : false}
          error={formErrors.planItems}
          isRequired={true}
          isOnlyInRow={false}
          menuPlacement={
            screenWidth <= 1096 || screenHeight <= 712 ? "top" : "bottom"
          }
        />
      </div>
      {items.length ? (
        <div>
          {items.map((item) => (
            <Item key={item.id} details={item} />
          ))}
        </div>
      ) : null}
      <div className="row mb-0">
        <div className="col">
          <div
            className="form-button-wrapper w-100 docked-button-container"
            id="form-btn"
          >
            <button onClick={handleSubmit} className="save-button">
              Save
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-decoration-none cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DefaultDetails;
