import React from "react";
import { Link } from "react-router-dom";
import { ActivateImg, SuspendImg } from "../../../../assets/images";
import useCommonData from "../../../hooks/useCommon";
import {
  revokeSuspendedUserDetails,
  suspendUserDetails,
} from "../../../home/userManagement/userSlice";
import { toast } from "react-toastify";
type Props = {
  className: string;
  isSuspended: boolean | undefined;
  userId: number;
  refreshList: () => void;
};
const SuspendUser = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();

  /**
   * Revoke User Suspend Handler
   */
  const revokeSuspendedUserHandler = async (userId: number) => {
    const responseAction = await dispatch(
      revokeSuspendedUserDetails({
        id: userId,
        orgId: currentUserInfo.organization_id,
      })
    );
    $(".close-delete-btn").click();
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshList();
        toast.success("Revoked suspended user successfully", {
          toastId: "user-revoke-suspend-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
  };

  /**
   * User Suspend Handler
   */
  const suspendUserHandler = async (userId: number) => {
    const responseAction = await dispatch(
      suspendUserDetails({ id: userId, orgId: currentUserInfo.organization_id })
    );
    $(".close-delete-btn").click();
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshList();
        toast.success("User suspended successfully!", {
          toastId: "user-suspend-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
  };
  return (
    <Link
      className={props.className}
      to="#"
      onClick={() =>
        props.isSuspended
          ? revokeSuspendedUserHandler(props.userId)
          : suspendUserHandler(props.userId)
      }
    >
      <span>
        <img
          src={props.isSuspended ? ActivateImg : SuspendImg}
          alt="suspend-img"
        />
        {props.isSuspended ? "Activate" : "Suspend"}
      </span>
    </Link>
  );
};

export default SuspendUser;
