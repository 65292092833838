import { FormikProps } from "formik";
import PaymentBankSelect from "../../../../../../common/components/paymentFormInputs/PaymentBankSelect";
import { SubAccountValues } from "../../../../../../../types";
import { useAppSelector } from "../../../../../../../app/hooks";
import {
  currentUserSelector,
  getLatestCurrencyRate,
} from "../../../../../../common/commonSlice";

import { usePr } from "../../usePr";
import useCommonData from "../../../../../../hooks/useCommon";
import { getFormatedDate } from "../../../../../../../helpers/helper";
type Props = {
  formik: FormikProps<any>;
};
export const DepositAccount = (props: Props) => {
  const { setBankExchangeData } = usePr({
    formik: props.formik,
  });
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { dispatch } = useCommonData();

  const handleDepositTo = (e: any) => {
    if (e.value) {
      props.formik.setFieldValue("deposit_to_account_id", e.value);
      props.formik.setFieldValue("deposit_to_account_name", e.label);
    }
  };
  const getSelectedAccount = async (account: SubAccountValues) => {
    if (!account?.currency_id) {
      props.formik.setValues({
        ...props.formik.values,
        bank_charges_account_id: null,
        bank_charges_account_name: null,
        bank_charges_amount: 0,
        bank_charges_bank_currency: 0,
        amount_received_bank_currency: 0,
        bank_conversion_rate: null,
        bank_currency_id: null,
        bank_conversion_id: null,
      });
      return;
    }

    // props.formik.setFieldValue(
    //   "bank_currency_id",
    //   account?.currency_id ? account?.currency_id : null
    // );
    // props.formik.setFieldValue(
    //   "bank_currency_code",
    //   account?.currency_code ? account.currency_code : ""
    // );

    if (
      account?.currency_id &&
      Number(account?.currency_id) ===
        Number(currentUserInfo.organization_currency) &&
      props.formik.values.conversion_id !== ""
    ) {
      props.formik.setValues((formikValues: any) => {
        return {
          ...formikValues,
          bank_currency_id: account?.currency_id ? account?.currency_id : null,
          bank_currency_code: account?.currency_code
            ? account.currency_code
            : "",
          bank_conversion_id: props.formik.values.conversion_id,
          bank_conversion_rate: props.formik.values.conversion_rate,
          bank_conversion_timestamp: props.formik.values.conversion_timestamp,
        };
      });
    } else {
      props.formik.setValues((formikValues: any) => {
        return {
          ...formikValues,
          bank_currency_id: account?.currency_id ? account?.currency_id : null,
          bank_currency_code: account?.currency_code
            ? account.currency_code
            : "",
        };
      });
      const responseAction = await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(props.formik.values.currency_id),
          to_currency: Number(account?.currency_id),
          date:
            new Date(props.formik.values.payment_date) > new Date()
              ? getFormatedDate()
              : props.formik.values.payment_date,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBankExchangeData(response.latest_conversion);
      }
    }
  };
  return (
    <div
      className={`col-12 col-lg-4 mw-325  ${
        !props.formik.values.customer_id ? "disabled-item" : ""
      }`}
    >
      <label htmlFor="deposit_to_account_name" className="required">
        Deposit To
      </label>
      <PaymentBankSelect
        name="deposit_to_account_name"
        isDisabled={!props.formik.values.customer_id}
        currencyId={props.formik.values.currency_id}
        accountId={props.formik.values.deposit_to_account_id}
        handleAccountChange={handleDepositTo}
        source="Payments Received"
        errors={
          (props.formik.touched.deposit_to_account_name &&
            props.formik.errors.deposit_to_account_name?.toString()) ||
          ""
        }
        getSelectedAccount={getSelectedAccount}
      />
      <span className="error error_deposit_to_account_name mb-2">
        {(props.formik.touched.deposit_to_account_name &&
          props.formik.errors.deposit_to_account_name?.toString()) ||
          ""}
      </span>
    </div>
  );
};
