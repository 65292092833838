import { SecondaryButton } from "../../../../common/components/button/SecondaryButton";
import { SaveButton } from "../../../../common/components/button/SaveButton";
import "./excessAllocationAlert.css";
import useCommonData from "../../../../hooks/useCommon";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";

type ExcessAmountAlertProps = {
  excessAmount: number | string | null;
  module: string;
  currencyCode: string | null;
  handleSave: () => void;
  handleClose: () => void;
};
export const ExcessAmountAlert = (props: ExcessAmountAlertProps) => {
  const { orgCurrencyList } = useCommonData();
  return (
    <div className="excess-amount-allocation-wrap">
      <div>
        <p className="main-alert-message">
          The excess amount of{" "}
          <b>
            {`${props.currencyCode} ${NumberFormat(
              Number(props.excessAmount),
              props.currencyCode ? props.currencyCode : "",
              orgCurrencyList
            )} `}{" "}
          </b>
          is being saved as an excess payment under{" "}
          {props.module === "Payments Made"
            ? '"Prepaid Expenses"'
            : '"Unearned Revenue"'}
        </p>
      </div>
      <div className="excess-amount-allocation-btn-group">
        <SaveButton
          type="submit"
          isDisabled={false}
          buttonContent="Yes proceed"
          onClick={props.handleSave}
        />
        <SecondaryButton
          type="button"
          isDisabled={false}
          buttonContent="Cancel"
          onClick={props.handleClose}
        />
      </div>
    </div>
  );
};
