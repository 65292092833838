import { colors } from "../../constants/colors";

export const RoleSelectCustomStyle = {
  container: (provided: any, state: any) => ({
    ...provided,
    clear: "both",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    width: "145px",
    height: "40px",
    position: "relative",
    margin: "0",
    padding: "0 0 0 30px",
    borderRadius: "6px",
    boxShadow: `0 2px 6px 0 rgba(${colors.blackRGB}, 0.01)`,
    border: `solid 1px rgba(${colors.ceruleanBlueRGB}, 0.2)`,
    backgroundColor: colors.newAliceBlue,
    color: colors.navyBlue,
    backgroundImage: "url('../../../../assets/images/roles_list.svg')",
    backgroundSosition: "left 8px center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px 20px",
    outline: "0 !important",
    paddingRight: "4px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "normal",
    color:
      state.isFocused || state.isSelected
        ? colors.ceruleanBlue
        : colors.charcoalGrey,
    backgroundColor: colors.white,
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.ceruleanBlue,
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "bold",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.navyBlue,
    marginLeft: "0",
    marginRight: "0",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: colors.white,
    border: `1px solid ${colors.newWhisper}`,
    borderRadius: "4px",
    boxShadow: `0 2px 6px 0 rgb(${colors.blackRGB} / 1%)`,
    zIndex: "10",
    height: "auto",
    margin: "4px 0",
    minWidth: "30px",
    padding: "5px 0",
    textAlign: "left",
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    padding: "0px 5px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.charcoalGrey,
    backgroundColor: colors.white,
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.ceruleanBlue,
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.charcoalGrey,
    margin: "2px 0",
    "&:focus": {
      border: "0",
    },
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: "0 4px 0 13px",
    margin: "0",
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: colors.navyBlue,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: `rgba(${colors.charcoalGreyRGB}, 0.55)`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "italic",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.battleshipGrey,
    textAlign: "left",
    padding: "10px 0 20px 0",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: colors.slate,
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    padding: "8px 4px",
    "&:hover": {
      color: colors.slate,
    },
    "& svg": {
      width: "12px",
      height: "12px",
      marginRight: "0px",
      "& path": {
        fill: colors.slate,
      },
    },
  }),
};
