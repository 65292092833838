import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DebitNoteDetailValues } from "../../../../../types";
import { downloadUsingURL } from "../../../../../helpers/helper";
import { getDebitNotePdfLink } from "../../debitNoteSlice";
import { toast } from "react-toastify";
import { DownloadIcon, EmailIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { TagIconDark } from "../../../../../assets/images";
import { VoidConfirmationModal } from "../../../../common/components/voidConfirmationPopup/VoidConfirmationModal";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import ApplyToBills from "./ApplyToBills";
import TagDarkIcon from "../../../../common/components/customSvgIcons/tagDarkIcon";
import useCommonData from "../../../../hooks/useCommon";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { message } from "../../../../constants/messages";

type Props = {
  debitNoteData: DebitNoteDetailValues;
  debitNoteRolePermission: any[];
  isVoid?: boolean;
  isTagsAssociated?: boolean;
  refreshDNDetails: () => void;
};
export const MoreActions = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { editId } = useParams();
  const navigate = useNavigate();

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getDebitNotePdfLink({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(editId) + ".pdf"
        );
        if (result) {
          toast.success("Debit note downloaded successfully!", {
            toastId: "debit-note-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };

  return (
    <KebabMenu wrapperClass="detail-top-action-menu">
      <li>
        <button
          className="dropdown-item"
          data-bs-toggle="modal"
          data-bs-target="#emailToModal"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span>
            <EmailIcon color={colors.battleshipGrey} />
            Send Email
          </span>
        </button>
      </li>
      <li>
        <button type="button" onClick={downloadPdf} className="dropdown-item">
          <span>
            <DownloadIcon color={colors.battleshipGrey} />
            Download PDF
          </span>
        </button>
      </li>
      {props.debitNoteRolePermission.includes("Update") &&
      props.debitNoteData.organization_id === currentUserInfo.organization_id &&
      IsFeatureEnabled("Bills", currentUserInfo.disabled_feature) ? (
        <li>
          <ApplyToBills
            debitNoteData={props.debitNoteData}
            refreshDNDetails={props.refreshDNDetails}
            isVoid={props.isVoid}
          />
        </li>
      ) : null}
      {currentUserInfo.is_tags_enabled === true && (
        <li>
          <button
            type="button"
            onClick={() => {
              if (props.isVoid && !props.isTagsAssociated) {
                ErrorToaster(message().debitNote.associateTagsToVoidDN);
              } else {
                navigate(`/debit-note/edit/${Number(editId)}/reporting-tags`, {
                  replace: true,
                });
              }
            }}
            className="dropdown-item"
          >
            <span>
              <TagDarkIcon color={colors.battleshipGrey} />
              Reporting Tags
            </span>
          </button>
        </li>
      )}
      {props.debitNoteRolePermission.includes("Update") && (
        <li>
          <VoidConfirmationModal
            transactionNumber={props.debitNoteData.debit_note_number}
            transactionStatus={props.debitNoteData.debit_note_status}
            module="Debit Note"
            isTransactionExist={
              Number(props.debitNoteData.total_details.debits_used) !== 0
            }
            transactionId={Number(props.debitNoteData.id)}
            onSubmit={async () => {
              props.refreshDNDetails();
            }}
            isFiscalClosed={props.debitNoteData.is_fiscal_closed}
          />
        </li>
      )}
    </KebabMenu>
  );
};
