import { toast } from "react-toastify";
import { useAppDispatch } from "../../../app/hooks";
import { joinTransactionNumber } from "../../../helpers/prefixHelper";
import DeleteModal from "../../common/components/DeleteModal";
import { deleteCalenderDetails } from "./calenderBillingSlice";
// import { deleteAddonDetails, deletePricingData } from "../addonSlice";

type Props = {
  deleteAddonId: number;
  deleteAddonName: string;
  deleteActionType: string;
  refreshAddon: (action: string) => void;
  refreshPlanSpecificList: (action: string) => void;
  organizationId: number;
};
const AddonDelete = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const addonModalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteRoleResponseAction = await dispatch(
      deleteCalenderDetails({
        calendarInvoicingId: props.deleteAddonId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (
        Object.keys(deleteRoleResponse).length &&
        !("error" in deleteRoleResponse)
      ) {
        props.refreshAddon(props.deleteActionType);
        props.refreshPlanSpecificList(props.deleteActionType);
        $(".close-btn").click();
        toast.success("Calender invoicing deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
  };

  function capitalizeWord(word: string) {
    if (!word) return word; // Handle empty strings
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <DeleteModal
      heading={`Delete ${props.deleteActionType}!`}
      subHeading={`Are you sure you want to delete the ${
        props.deleteActionType === "Site-Wide Invoicing"
          ? "site-wide calendar invoicing for"
          : "plan-specific calendar invoicing"
      }  `}
      transactionName={
        props.deleteActionType === "Site-Wide Invoicing"
          ? `${joinTransactionNumber(props.deleteAddonName).toLowerCase()}`
          : ""
      }
      modalType="site_wide_invoicing"
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={addonModalSubmitHandler}
    />
  );
};
export default AddonDelete;
