import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import VoidModalSubscription from "../../../common/components/VoidModalSubscription";
import {
  cancelSubscriptionAction,
  changeSubscriptionStatus,
} from "../subscriptionSlice";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { CardBody } from "../../../common/components/htmlTags/Card/CardBody";
import CloseButton from "../../../common/components/button/CloseButton";
import { Form } from "../../../common/components/htmlTags/Form";
import { getFormatedDate } from "../../../../helpers/helper";
import { ObjectType } from "../../../../types";
import { validateCancelForm } from "./ValidationSubscriptionAction";
import "./subscriptionVoid.css";
import info from "../../../../assets/images/email-info-msg-icon.svg";
import { setLoaderState } from "../../../common/commonSlice";

type Props = {
  subscriptionId: number;
  subscriptionName: string;
  status: string;
  refreshPlan: () => void;
  organizationId: number;
  didAlertModalOpen?: boolean;
  setDidAlertModalOpen?: Dispatch<SetStateAction<boolean>>;
  nextRenewableDate: string;
  stopScheduledDate?: string;
  isEditScheduled: boolean;
  subscriptionEndDate: string | null;
  isCurrentCycleInvoicePaid: boolean;
  unused_credits_days?: number;
  isUnbilledChargesExists: boolean;
  totalUnbilledCharge: string;
  currencyCode: string;
  fetchChildInvoiceList?: (invoiceId: number) => void;
  unbilledDetailsChargesList?: (subscriptionId: number) => void;
  recurring_invoice_id?: number;
};
const SubscriptionVoid = (props: Props) => {
  const dispatch = useAppDispatch();
  const [cancelFormErrors, setCancelFormErrors] = useState<ObjectType>({});
  const [cancelDetailsData, setCancelDetailsData] = useState<{
    unpaid_amount: number;
    paid_amount: number;
    available_credits: number;
  }>({ unpaid_amount: 0, paid_amount: 0, available_credits: 0 });
  const placeOfSupplyUpdateModalRef = useRef<any>();
  const modalCancelHandler = () => {
    formik.resetForm();
    if (props.setDidAlertModalOpen) props.setDidAlertModalOpen(false);
    setCancelFormErrors({});
  };

  const formik = useFormik({
    initialValues: {
      cancel_status_option: "IMMEDIATELY",
      cancel_date: getFormatedDate(new Date()),
      credit_note: "NO_CREDIT",
      unpaid_amount: "RETAIN_AS_UNPAID",
      include_cancellation_date: true,
      bank_charges_account_id: 0,
      handle_paid_amount: "RETAIN_AS_PAID",
      manage_unbilled_charges: "INVOICE",
      apply_refundable_to_unpaid: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let errors = validateCancelForm(
        values,
        props.status,
        props.subscriptionEndDate
      );
      if (Object.keys(errors).length) {
        setCancelFormErrors(errors);
      } else {
        setCancelFormErrors({});
        modalSubmitHandler(values);
      }
    },
  });

  const modalSubmitHandler = async (values: any) => {
    const cancelData = { ...values, subscription_id: props.subscriptionId };
    if (["FUTURE", "IN_TRIAL"].includes(props.status)) {
      delete cancelData.credit_note;
      delete cancelData.unpaid_amount;
      delete cancelData.include_cancellation_date;
      delete cancelData.bank_charges_account_id;
      delete cancelData.handle_paid_amount;
      delete cancelData.manage_unbilled_charges;
      delete cancelData.apply_refundable_to_unpaid;
    } else {
      if (props.status === "STOPPED") {
        delete cancelData.credit_note;
        delete cancelData.include_cancellation_date;
        delete cancelData.handle_paid_amount;
      } else if (props.status === "ACTIVE") {
        cancelData.credit_note !== "PRORATED" &&
          delete cancelData.include_cancellation_date;
        cancelData.unpaid_amount !== "WRITE_OFF" &&
          delete cancelData.bank_charges_account_id;
      }
      if (cancelData.bank_charges_account_id) {
        cancelData.account_id = cancelData.bank_charges_account_id;
        delete cancelData.bank_charges_account_id;
      }
      if (
        ["IMMEDIATELY", "SPECIFIC_DATE"].includes(
          cancelData.cancel_status_option
        ) &&
        cancelDetailsData.unpaid_amount <= 0
      ) {
        delete cancelData.unpaid_amount;
      }
      if (
        !(
          formik.values.cancel_status_option === "IMMEDIATELY" &&
          props.isUnbilledChargesExists
        )
      ) {
        delete cancelData.manage_unbilled_charges;
      }
      if (
        cancelData.cancel_status_option === "END_OF_CURRENT_TERM" &&
        props.status === "ACTIVE"
      ) {
        delete cancelData.credit_note;
      }
      if (cancelData.cancel_status_option === "SPECIFIC_DATE") {
        cancelData.credit_note = "NO_CREDIT";
        cancelData.include_cancellation_date = false;
      }
      if (
        !props.isCurrentCycleInvoicePaid ||
        cancelDetailsData.paid_amount <= 0
      ) {
        delete cancelData.handle_paid_amount;
      }

      if (
        ["SPECIFIC_DATE", "END_OF_CURRENT_TERM"].includes(
          cancelData.cancel_status_option
        ) ||
        cancelDetailsData.available_credits <= 0
      ) {
        delete cancelData.apply_refundable_to_unpaid;
      }
    }

    if (cancelData.cancel_status_option === "SPECIFIC_DATE") {
      cancelData.cancel_date = getFormatedDate(cancelData.cancel_date);
    }
    if (cancelData.cancel_status_option === "END_OF_CURRENT_TERM") {
      cancelData.cancel_status_option = "ON_NEXT_RENEWAL";
      cancelData.cancel_date = cancelData.cancel_date;
    }

    dispatch(setLoaderState(true));

    const voidRoleResponseAction = await dispatch(
      cancelSubscriptionAction({
        orgId: props.organizationId,
        cancelData,
      })
    );
    if (voidRoleResponseAction.payload) {
      const voidRoleResponse = voidRoleResponseAction.payload;
      if (
        Object.keys(voidRoleResponse).length &&
        !("error" in voidRoleResponse)
      ) {
        if (props.fetchChildInvoiceList && props.recurring_invoice_id) {
          setTimeout(() => {
            if (props.fetchChildInvoiceList && props.recurring_invoice_id) {
              props.fetchChildInvoiceList(props.recurring_invoice_id);
            }
            if (props.unbilledDetailsChargesList && props.subscriptionId) {
              props.unbilledDetailsChargesList(props.subscriptionId);
            }
            props.refreshPlan();
            modalCancelHandler();
            toast.success("Subscription cancelled successfully!", {
              toastId: "role-void-success",
              closeButton: false,
              position: "top-center",
            });
            dispatch(setLoaderState(false));
          }, 5000);
        } else {
          props.refreshPlan();
          modalCancelHandler();
          toast.success("Subscription cancelled successfully!", {
            toastId: "role-void-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else if ("error" in voidRoleResponse) {
        modalCancelHandler();
      }
      // dispatch(setLoaderState(false));
    }
  };

  useEffect(() => {
    if (formik.values.cancel_status_option === "SPECIFIC_DATE") {
      formik.setFieldValue("unpaid_amount", "RETAIN_AS_UNPAID");
    }
  }, [formik.values.cancel_status_option]);

  return (
    <>
      <Dialog
        open={props?.didAlertModalOpen || false}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Cancel Subscription"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <div className="h-100 subcription-void-form-field-wrapper">
            <CardBody>
              <Form
                name="add_invoice_form"
                id="add_invoice_form"
                className="subscription_cancel_modal_position"
                tabIndex={-1}
              >
                <div className="row">
                  <VoidModalSubscription
                    modalCancelHandler={modalCancelHandler}
                    status={props.status}
                    formik={formik}
                    nextRenewableDate={props.nextRenewableDate}
                    cancelFormErrors={cancelFormErrors}
                    isCurrentCycleInvoicePaid={props.isCurrentCycleInvoicePaid}
                    unused_credits_days={props.unused_credits_days}
                    totalUnbilledCharge={props.totalUnbilledCharge}
                    currencyCode={props.currencyCode}
                    isUnbilledChargesExists={props.isUnbilledChargesExists}
                    cancelDetailsData={cancelDetailsData}
                    setCancelDetailsData={setCancelDetailsData}
                    subscriptionId={props.subscriptionId}
                  />
                  {props.isEditScheduled || props.stopScheduledDate ? (
                    <Grid
                      container
                      className="m-t-24 subscription-impact-update-conatiner"
                      wrap="nowrap"
                    >
                      <Grid item xs="auto">
                        <img src={info} id="message-img" alt="clone-alt" />
                      </Grid>
                      <Grid item xs="auto">
                        <div className="m-l-10">
                          Edit/Stop scheduled will be removed if the cancel is
                          scheduled
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                </div>
                <div className="row mb-0">
                  <div className="col">
                    <div
                      className={`form-button-wrapper w-100 justify-content-end mt-3 
                    ${
                      props.isEditScheduled || props.stopScheduledDate
                        ? "form-button-wrapper-cancelled-modal"
                        : ""
                    }`}
                      id="form-btn"
                    >
                      <button
                        onClick={(e: any) => {
                          e.preventDefault();
                          formik.submitForm();
                        }}
                        className="save-button"
                      >
                        {"Update"}
                      </button>
                      <button
                        type="button"
                        onClick={modalCancelHandler}
                        className="text-decoration-none cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </CardBody>
          </div>
        </Card>
      </Dialog>
    </>
  );
};
export default SubscriptionVoid;
