import { FormikProps } from "formik";
import { ObjectType, UnpaidBillValues } from "../../../../../../../types";
import useCommonData from "../../../../../../hooks/useCommon";
import { joinTransactionNumber } from "../../../../../../../helpers/prefixHelper";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../../../helpers/helper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../../helpers/numberFormatHelper";
import { getForexGainOrLossAmount } from "../../../../../../../helpers/transactionHelper";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { useCalculateForex } from "../../../../../../hooks/amountCalculation/useForexCalculationHook";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import _ from "lodash";
import { useAppSelector } from "../../../../../../../app/hooks";
import { getAmountCalculationResult } from "../../../../paymentsMadeSlice";
import InputPrefix from "../../../../../../common/components/formPrefixInputField/InputPrefix";
import { useState } from "react";
import CustomDatepicker from "../../../../../../utils/atoms/datepicker";
import "./unpaidBills.css";
import { useParams } from "react-router-dom";

type Props = {
  bill: UnpaidBillValues;
  index: number;
  currencyCode: string | undefined;
  formik: FormikProps<any>;
  handlePaymentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: any;
};

const UnpaidBillItem = ({
  bill,
  index,
  currencyCode,
  formik,
  handlePaymentChange,
  errors,
}: Props) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const { editId } = useParams();
  const [isPayloadChanged, setPayloadChanged] = useState(false);
  const [isAllocationDateChanged, setAllocationDateChanged] = useState(false);
  const totalDetails = useAppSelector<{ [key: string]: any }>(
    getAmountCalculationResult
  ).total_details;
  const initialPayload = {
    entity_id: formik.initialValues?.unpaid_bill_lines[index]?.id,
    source_conversion_id:
      formik.initialValues?.unpaid_bill_lines[index]?.conversion_id,
    target_conversion_id: formik.initialValues.conversion_id,
    amount: !isNaN(
      formik.initialValues?.unpaid_bill_lines[index]?.payment_amount
    )
      ? Number(formik.initialValues?.unpaid_bill_lines[index]?.payment_amount)
      : 0,
    target_type: "PaymentsMade",
  };
  const currentPayload = {
    entity_id: bill.bill_id,
    source_conversion_id: bill.conversion_id,
    target_conversion_id: formik.values.conversion_id,
    amount: !isNaN(Number(bill.payment_amount))
      ? Number(bill.payment_amount)
      : 0,
    target_type: "PaymentsMade",
  };

  const forex = useCalculateForex({
    payloadInput: {
      entity_id: Number(bill.bill_id),
      source_conversion_id: bill.conversion_id,
      target_conversion_id: formik.values.conversion_id,
      amount: bill.payment_amount ? Number(bill.payment_amount) : 0,
      target_type: "PaymentsMade",
    },
    forex: bill.forex_amount ? { [bill.bill_id]: bill.forex_amount } : null,
    isPayloadChanged: isPayloadChanged,
  });

  const handleOnBlur = (name: string, amount: number, index: number) => {
    const decimals = decimalPlaceOfCurrency(
      formik.values.currency_code,
      orgCurrencyList
    );
    const roundedAmount = getRoundOffAmount(amount, decimals);
    formik.setFieldValue(name, roundedAmount);
  };

  return (
    <tr key={"item-" + index}>
      <td className="bill_number">{joinTransactionNumber(bill.bill_number)}</td>
      <td className="bill_date">{DateFormatHandler(bill.bill_date)}</td>
      <td className="bill_amount">
        {currencyCode}{" "}
        {NumberFormat(
          Number(bill.bill_amount),
          currencyCode ? currencyCode : "",
          orgCurrencyList
        )}
      </td>
      <td className="amount_due">
        {currencyCode}{" "}
        {NumberFormat(
          Number(bill.amount_due),
          currencyCode ? currencyCode : "",
          orgCurrencyList
        )}
      </td>
      {currentUserInfo.is_payment_allocation_enabled && (
        <td className="allocation-date-col">
          <CustomDatepicker
            handleDate={(date) => {
              if (date) {
                formik.setFieldValue(
                  `unpaid_bill_lines.${index}.allocation_date`,
                  date
                );
              }
            }}
            date={new Date(bill.allocation_date)}
            id={"allocation_date" + index}
            type="paymentDate"
            error=""
          />
        </td>
      )}
      <td className="payment-col">
        <InputPrefix
          wrapperClass={`inv-group payment-entry d-flex w-130 ${
            formik.errors.total?.length ? "error" : ""
          }`}
          prefix={currencyCode ? currencyCode : ""}
          inputType="text"
          name={`unpaid_bill_lines.${index}.payment_amount`}
          id={"payment_amount" + index}
          className="form-control payment border-end-0"
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              currencyCode ? currencyCode : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          isAmountField={true}
          value={bill.payment_amount ? bill.payment_amount : ""}
          disabled={totalDetails?.total || bill.payment_amount ? false : true}
          error={errors && errors[index] && errors[index].payment_amount}
          onChange={(e) => {
            const { value } = e.target;
            if (value !== ".") {
              handlePaymentChange(e);
              setPayloadChanged(true);
            }
            if (editId && !isAllocationDateChanged) {
              formik.setFieldValue(
                `unpaid_bill_lines.${index}.allocation_date`,
                getFormatedDate()
              );
            }
          }}
          onBlur={(e: any) => {
            e.target.parentElement.classList.add("focused");
            handleOnBlur(
              `unpaid_bill_lines.${index}.payment_amount`,
              e.target?.value,
              index
            );
          }}
          index={index}
        />
        <span className="inv-group payment-entry d-flex w-130 inv-balance">
          {typeof totalDetails === "object" &&
          totalDetails?.bill_balances_after_payment?.[bill.bill_id]
            ? "Balance due: " +
              currencyCode +
              " " +
              NumberFormat(
                totalDetails.bill_balances_after_payment?.[`${bill.bill_id}`],
                currencyCode ? currencyCode : "",
                orgCurrencyList
              )
            : Number(
                totalDetails.bill_balances_after_payment?.[`${bill.bill_id}`]
              ) === 0
            ? "Balance due: " + currencyCode + " " + "0"
            : ""}
        </span>
        <span className="error w-220 unpaid-error">
          {errors && errors[index] && errors[index].payment_amount}
        </span>
      </td>

      <td className="forex_gain_loss">
        {getForexGainOrLossAmount(
          Number(bill.bill_id),
          forex,
          currencyCode ? currencyCode : "",
          orgCurrencyList
        )}
      </td>
    </tr>
  );
};

export default UnpaidBillItem;
