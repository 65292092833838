import React, { useState } from "react";
import { Grid, Paper } from '@mui/material';

interface calendarDaysType {
day: string;
isCurrentMonth: boolean;
}
type Props = {
  handleDayChange: (day: string) => void
  selectedMonth: number;
  selectedDay: string;
  isHeaderDisabled?: boolean;
};

const CustomDays = (props: Props) => {

  const year = new Date().getFullYear();

  // Function to get the number of days in a given month and year
  const getDaysInMonth = () => {
    return new Date(year, props.selectedMonth, 0).getDate();
  };

  const daysInMonth = getDaysInMonth();

  let daysArray: calendarDaysType[] = []
  const days = [...Array(35)].forEach((_, index) => {
    const dayNumber = index + 1;
    if (dayNumber <= daysInMonth && !props.isHeaderDisabled) daysArray.push({ day: dayNumber.toString(), isCurrentMonth: true });
    else if (dayNumber > daysInMonth && !props.isHeaderDisabled) {
      [...Array(35 - daysInMonth)].forEach((_, index) => {
        const nextMonthDay = index + 1;
        daysArray.push({ day: nextMonthDay.toString(), isCurrentMonth: false });
      })
    } else if (props.isHeaderDisabled) {
      if(dayNumber <= 31) daysArray.push({ day: dayNumber.toString(), isCurrentMonth: true });
      else daysArray.push({ day: '', isCurrentMonth: false });
    }
  });

  return (
    <>
      <Grid container spacing={1} className="paper-day-main-grid">
        {Array.from({ length: 5 }).map((_, rowIndex) => (
          <Grid container item key={rowIndex} columnGap={2}>
            {Array.from({ length: 7 }).map((_, colIndex) => {
              const dayNumber = daysArray[rowIndex * 7 + colIndex];
              return (
                <Grid item xs={1} key={colIndex}>
                  <Paper
                    className={`custom-day-picker-paper ${dayNumber.isCurrentMonth
                      ? Number(props.selectedDay) === Number(dayNumber.day) ? "day-selected" : ""
                      : 'disabled-days'}`}
                    onClick={() => {
                      if(dayNumber.isCurrentMonth) {
                        props.handleDayChange(dayNumber.day.padStart(2, '0'))
                    }}}
                  >
                    {dayNumber.day}
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>

    </>
  );
};

export default CustomDays;
