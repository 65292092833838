import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTopFunction = () => {
      $("html,body").animate({ scrollTop: 0 }, 50);
      return false;
    };
    scrollToTopFunction();
  }, [pathname]);

  return null;
}
