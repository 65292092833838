import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { createPlanSpecificInvoicingHandler, editPlanSpecificInvoicingHandler } from "../calenderBillingSlice";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { CalendarPlanSpecificBillingList } from "../calendarBillingTypes";

export const useCreatePlanSpecificInvoicing = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const createPlanSpecificInvoicing = async (
    data: any,
    refreshPlanSpecificList: () => void
    ) => {
    const responseAction = await dispatch(
        createPlanSpecificInvoicingHandler({...data, organization_id: currentUserInfo.organization_id})
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Plan Specific Invoicing frequency created successfully!",
          "tags-config-succcess"
        );
        dispatch(setLoaderState(false));
        refreshPlanSpecificList()
      } else {
        dispatch(setLoaderState(false));
      }
  };
  return { createPlanSpecificInvoicing };
};

export const useEditPlanSpecificInvoicing = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const editPlanSpecificInvoicing = async (
    data: any,
    refreshPlanSpecificList: () => void,
    id: number,
    ) => {
    const responseAction = await dispatch(
      editPlanSpecificInvoicingHandler({...data, organization_id: currentUserInfo.organization_id, id: id})
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Plan Specific Invoicing frequency updated successfully!",
          "tags-config-succcess"
        );
        dispatch(setLoaderState(false));
        refreshPlanSpecificList()
      } else {
        dispatch(setLoaderState(false));
      }
  };
  return { editPlanSpecificInvoicing };
};

