import * as React from "react";
import Popover from "@mui/material/Popover";
import { PopperContext } from "./popperContext";
import "../../../../index.css";
import "./popper.css";

type ReusablePopperProps = {
  buttonContent: React.ReactNode;
  popperContent: React.ReactNode;
  wrapperClass?: string;
  buttonClass?: string;
  popperBodyClass?: string;
  position: {
    vertical: "top" | "bottom" | "center";
    horizontal: "left" | "right" | "center";
  };
  transform: {
    vertical: "top" | "bottom" | "center";
    horizontal: "left" | "right" | "center";
  };
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  showOnMouseOver?: Boolean;
};
export default function Popper(props: ReusablePopperProps) {
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    props.onOpenFunction && props.onOpenFunction();
  };

  const onMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.showOnMouseOver) {
      setAnchorEl(event.currentTarget);
      props.onOpenFunction && props.onOpenFunction();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.onCloseFunction && props.onCloseFunction();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <PopperContext.Provider value={{ onClosePopper: handleClose }}>
      <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
          onMouseEnter={onMouseOver}
          className={` no-appearance ${
            props.buttonClass ? props.buttonClass : ""
          }`}
        >
          {props.buttonContent}
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={props.wrapperClass ? props.wrapperClass : ""}
          onClose={handleClose}
          disableScrollLock
          anchorOrigin={{
            vertical: props.position.vertical,
            horizontal: props.position.horizontal,
          }}
          transformOrigin={{
            vertical: props.transform.vertical,
            horizontal: props.transform.horizontal,
          }}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        >
          <div
            className={
              props.popperBodyClass ? props.popperBodyClass : "reusable-popper"
            }
          >
            {props.popperContent}
          </div>
        </Popover>
      </div>
    </PopperContext.Provider>
  );
}
