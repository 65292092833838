import CreditNoteDetailHeader from "./CreditNoteDetailHeader";
import "./CreditNoteDetail.css";
function CreditNoteDetail() {
  return (
    <div className="">
      <div className="card card-dashboard bills-detail-card cd-detail-card w-100" id="bills-detail-card">
        <CreditNoteDetailHeader />
      </div>
    </div>
  );
}

export default CreditNoteDetail;
