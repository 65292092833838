import { getStartDate } from "../fiscalYearClosingAPI";

export const getFiscalYears = (startDate, endDate) => {
  if (!startDate || !endDate || startDate === endDate || startDate > endDate) {
    return [];
  }
const endParts = endDate.split("-");
  const endMonthDay = endParts[1] + endParts[2];
  const startParts = startDate.split("-");
  const startMonthDay = startParts[1] + startParts[2];
  const startYear = parseInt(startDate.split("-")[0], 10);
  const endYear = parseInt(endDate.split("-")[0], 10);
  const fiscalYearsList = [];
  if (endMonthDay === "1231") {  //Case in which a Fiscal year happens to be from Jan 1 to Dec 31
    if (endMonthDay === startMonthDay){
      for (let year = startYear + 1; year <= endYear; year++) {
        fiscalYearsList.push(`${year} - ${year}`);
      }
    }
    else {
      for (let year = startYear; year <= endYear; year++) {
        fiscalYearsList.push(`${year} - ${year}`);
      }
    }
  }
  else if (endMonthDay !== "1231" && startMonthDay === endMonthDay && startYear < endYear) {
    for (let year = startYear ; year <= endYear - 1; year++) {
      fiscalYearsList.push(`${year} - ${year + 1}`);
    }
  }
  else if (endMonthDay !== "1231" && startMonthDay < endMonthDay && startYear < endYear) {
    for (let year = startYear - 1; year <= endYear - 1; year++) {
      fiscalYearsList.push(`${year} - ${year + 1}`);
    }
  }
  else if (endMonthDay !== "1231" && startMonthDay < endMonthDay && startYear === endYear) {
    for (let year = startYear - 1; year <= endYear - 1; year++) {
      fiscalYearsList.push(`${year} - ${year + 1}`);
    }
  }
  else if (endMonthDay !== "1231" && startMonthDay > endMonthDay && startYear < endYear) {
    for (let year = startYear; year <= endYear - 1; year++) {
      fiscalYearsList.push(`${year} - ${year + 1}`);
    }
  }
  return fiscalYearsList;
};

export const generateClosingDates = (startDate, endDate) => {
  const startYear = parseInt(startDate.split("-")[0], 10);
  const endYear = parseInt(endDate.split("-")[0], 10);
  const monthDay = endDate.slice(4);
  const startParts = startDate.split("-");
  const endParts = endDate.split("-");
  const startMonthDay = startParts[1] + startParts[2];
  const endMonthDay = endParts[1] + endParts[2];
  const datesList = [];
  if (endMonthDay === "1231") {
    if(endMonthDay === startMonthDay){
    for (let year = endYear; year >= startYear + 1; year--) {
      datesList.push(`${year}${monthDay}`);
    }
  }
  else {
    for (let year = endYear; year >= startYear; year--) {
      datesList.push(`${year}${monthDay}`);
    }
  }
  } else if (endMonthDay > startMonthDay) {
    for (let year = endYear; year >= startYear; year--) {
      datesList.push(`${year}${monthDay}`);
    }
  } else {
    for (let year = endYear; year >= startYear + 1; year--) {
      datesList.push(`${year}${monthDay}`);
    }
  }
  return datesList;
};

export const fetchStartDate = async (
  organization_id,
  setAccountingStartDate
) => {
  const data = { orgId: organization_id };
  try {
    const result = await getStartDate(data);
    setAccountingStartDate(result.accounting_start_date);
  } catch (error) {
    console.error("Error fetching start date:", error);
  }
};
