import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appReducer from "../appSlice";
import commonReducer from "../features/common/commonSlice";
import useManagementReducer from "../features/home/userManagement/userSlice";
import customerManagementReducer from "../features/home/customerManagement/customerSlice";
import chartOfAccountsReducer from "../features/home/chartOfAccounts/coaSlice";
import vendorManagementReducer from "../features/home/vendorManagement/vendorSlice";
import taxConfigurationReducer from "../features/home/gst/taxRates/taxRateSlice";
import reportReducer from "../features/home/reports/ReportSlice";
import invoiceManagementReducer from "../features/home/invoice/invoiceSlice";
import recurringInvManagementReducer from "../features/home/recurringInvoice/recurringInvoiceSlice";
import billManagementReducer from "../features/home/bills/billsSlice";
import creditNoteManagementReducer from "../features/home/creditNote/creditNoteSlice";
import paymentsReceivedReducer from "../features/home/paymentsReceived/paymentReceivedSlice";
import addressReducer from "../features/home/customerManagement/components/customerForm/AddressDetails/addressSlice";
import addressTypeReducer from "../features/home/customerManagement/components/customerForm/AddressDetails/addressTypeSlice";
import itemReducer from "../features/home/items/itemSlice";
import tdsReducer from "../features/home/tds/tdsSlice";
import tcsReducer from "../features/home/tcs/tcsSlice";
import gstReducer from "../features/home/gst/gstSlice";
import roundOffPreferenceReducer from "../features/home/preferences/preferencesSlice";
import paymentsMadeReducer from "../features/home/paymentsMade/paymentsMadeSlice";
import exchangeRateReducer from "../features/common/components/exchangeRate/exchangeRateSlice";
import reportingTagReducer from "../features/home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import planManagementReducer from "../features/home/plan/planSlice";
import subscriptionManagementReducer from "../features/home/subscription/subscriptionSlice";
import migrationReducer from "../features/home/migrationDate/migrationSlice";
import couponConfigManagementReducer from "../features/home/couponConfiguration/couponConfigSlice";
// import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    app: appReducer,
    common: commonReducer,
    useManagement: useManagementReducer,
    customerManagement: customerManagementReducer,
    chartOfAccounts: chartOfAccountsReducer,
    vendorManagement: vendorManagementReducer,
    taxConfiguration: taxConfigurationReducer,
    report: reportReducer,
    invoiceManagement: invoiceManagementReducer,
    recurringInvoiceManagement: recurringInvManagementReducer,
    billManagement: billManagementReducer,
    creditNoteManagement: creditNoteManagementReducer,
    paymentsReceived: paymentsReceivedReducer,
    paymentsMade: paymentsMadeReducer,
    address: addressReducer,
    addressType: addressTypeReducer,
    items: itemReducer,
    tds: tdsReducer,
    tcs: tcsReducer,
    gst: gstReducer,
    roundOffPreference: roundOffPreferenceReducer,
    exchangeRate: exchangeRateReducer,
    reportingTag: reportingTagReducer,
    PlanManagement: planManagementReducer,
    subscriptionManagement: subscriptionManagementReducer,
    migration: migrationReducer,
    couponConfigurationManagement: couponConfigManagementReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
