interface TreeNode {
  id: number;
  parent_id: number | null;
  depth: number;
  is_category: boolean;
  range: { [key: string]: { total: string; transaction_exist: boolean } };
  transaction_exist: boolean;
  account_name: string;
  node: TreeNode[];
}

function filterAccounts(data: TreeNode[], condition: string): TreeNode[] {
  return condition !== "All accounts"
    ? (data
        .map((node) => {
          const newNode: TreeNode = { ...node };

          if (node.node.length > 0) {
            newNode.node = filterAccounts(node.node, condition);
          }

          if (
            condition === "Accounts without zero balance" &&
            ((node.range &&
              Object.values(node.range).some(
                (range) => parseFloat(range.total) !== 0
              )) ||
              node.is_category)
          ) {
            return newNode;
          }

          if (
            condition === "Accounts with transactions" &&
            (node.transaction_exist === true ||
              (node.range &&
                Object.values(node.range).some(
                  (range) => range.transaction_exist === true
                )) ||
              node.is_category)
          ) {
            return newNode;
          }

          if (
            condition === "Accounts without zero balance" &&
            newNode.node.length > 0
          ) {
            return newNode;
          }

          return null;
        })
        .filter((node) => node !== null) as TreeNode[])
    : data;
}
export default filterAccounts;
