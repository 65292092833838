import React from "react";
import { colors } from "../../constants/colors";

/**
 * highlight the searched keyword in the search results screen
 */
function KeywordHighlight({
  name,
  searchedName,
}: {
  name: string;
  searchedName: string;
}) {
  if (searchedName.trim() === "") {
    return <>{name}</>;
  }
  const nameParts = name.split(new RegExp(`(${searchedName})`, "gi"));
  return (
    <>
      <span>
        {nameParts.map((part, index) =>
          part.toLowerCase() === searchedName.toLowerCase() ? (
            <b key={index} style={{ backgroundColor: colors.blanchedAlmond }}>
              {part}
            </b>
          ) : (
            part
          )
        )}
      </span>
    </>
  );
}

export default KeywordHighlight;
