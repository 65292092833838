import { SideModal } from "../../../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../../../common/components/sideModal/sideModalContext";
import { DropdownStateType, RoundOffPreferences } from "../../../recurringInvoiceTypes";
import SavePreferenceForm from "./SavePreferenceForm";

type Props = {
  handleSave: (paymentMode: any) => void;
  selectedValue: string;
  buttonName: string;
  optionsArray: DropdownStateType[];
  roundoffData?: RoundOffPreferences;
  onAccountChange?: (val: DropdownStateType) => void
  onIsTotalEditable?: (event: any) => void;
  formErrors?: any;
  resetAccountData?: () => void;
};

export const PreferencesModal = ({ handleSave, selectedValue, buttonName, optionsArray, roundoffData, onAccountChange, onIsTotalEditable, formErrors, resetAccountData}: Props) => {
  const ConfigureButton = () => {
    return (
      <span>
        {buttonName}
      </span>
    );
  };
  return (
    <SideModal
    modalContent={
      <ModalContext.Consumer>
        {(context) => {
          const { onCloseModal, onSaveClose} = context;
          return (
            <SavePreferenceForm
              handleClose={onCloseModal}
              handleCloseOnSave={onSaveClose}
              handleSave={(paymentMode: any) => handleSave(paymentMode)}
              selectedValue={selectedValue}
              optionsArray={optionsArray}
              roundoffData={roundoffData}
              onAccountChange={onAccountChange}
              formErrors={formErrors}
              onIsTotalEditable={onIsTotalEditable}
            />
          );
        }}
      </ModalContext.Consumer>
    }

      modalTitle={buttonName}
      buttonContent={<ConfigureButton />}
      wrapperClass="configure-payment-term-modal"
      buttonClass="payment-term-modal-btn"
      onCloseFunction={() => resetAccountData && resetAccountData()}
    />
  );
};
