import { useAppSelector } from "../../app/hooks";
import { currentUserSelector } from "../common/commonSlice";

const useGst = () => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  let isGstOrg = false;
  let gstEnabled = process.env.REACT_APP_GST_ENABLED;

  if (gstEnabled === "true") {
    if (currentUserInfo.organization_gst_state === null) {
      isGstOrg = false;
    } else {
      isGstOrg = true;
    }
  }

  return {
    isGstOrg,
  };
};

export default useGst;
