import { LockedItemGrey, LockedItemRed } from "../../../../../assets/images";
import LockButton from "../../../../common/components/button/LockButton";
import EditButton from "../../../../common/components/button/EditButton";
import UnlockButton from "../../../../common/components/button/UnlockButton";
import { LockValues } from "../../../../../types";
import { DateFormatHandler } from "../../../../../helpers/helper";

type Props = {
  isLockAll: boolean;
  lockedModules: LockValues[];
  moduleName: string;
  cardSubHeading: string;
  modalId: string;
  openModal: (action: string, moduleName: string, moduleId: number[]) => void;
  moduleId: number[];
  disablePartialUnlock: (moduleId: number[]) => void;
  isAllTransactionLocked: boolean;
  moduleLength: number;
};
const LockTransactionCard = (props: Props) => {
  /**
   * Function to check whether individual transaction / all transaction is locked or not
   */
  const isLocked = () => {
    if (props.lockedModules.length === 0) {
      return false;
    }
    const lockedItems = props.lockedModules.filter(
      (module) => module.locking_type === "lock"
    );
    if (props.isLockAll) {
      return lockedItems.length === props.moduleLength;
    }
    return true;
  };
  /**
   * Function to check whether individual transaction / all transaction is partially unlocked or not
   */
  const isPartiallyUnlocked = () => {
    if (props.lockedModules.length === 0) {
      return false;
    }
    const partiallyUnlockedItems = props.lockedModules.filter(
      (module) => module.locking_type === "partial_unlock"
    );
    if (props.isLockAll) {
      return partiallyUnlockedItems.length === props.moduleLength;
    }
    return partiallyUnlockedItems.length !== 0;
  };
  /**
   * Function to get lock date of transaction locking
   */
  const getLockDate = () => {
    const lockDate = props.lockedModules
      .filter((module) => module.locking_type === "lock")
      .map((module) => {
        return module.end_date;
      });
    return lockDate[0];
  };
  /**
   * Function to get reason for transaction locking
   */
  const getLockReason = () => {
    const lockReason = props.lockedModules
      .filter((module) => module.locking_type === "lock")
      .map((module) => {
        return module.lock_reason;
      });
    return lockReason[0];
  };
  /**
   * Function to get start date of transaction partial unlock
   */
  const getPartialUnlockStartDate = () => {
    const startDate = props.lockedModules
      .filter((module) => module.locking_type === "partial_unlock")
      .map((module) => {
        return module.start_date;
      });
    return startDate[0];
  };
  /**
   * Function to get end date of transaction partial unlock
   */
  const getPartialUnlockEndDate = () => {
    const endDate = props.lockedModules
      .filter((module) => module.locking_type === "partial_unlock")
      .map((module) => {
        return module.end_date;
      });
    return endDate[0];
  };
  /**
   * Function to get reason for transaction partial unlock
   */
  const getPartialUnlockReason = () => {
    const partialUnlockReason = props.lockedModules
      .filter((module) => module.locking_type === "partial_unlock")
      .map((module) => {
        return module.unlock_reason;
      });
    return partialUnlockReason[0];
  };

  return (
    <li>
      <div className="item-wrapper w-100 d-flex">
        <div className="left-sect v-top">
          <img
            src={
              isLocked() && !props.isAllTransactionLocked
                ? LockedItemRed
                : LockedItemGrey
            }
            alt="locked-item-img"
            width={60}
            height={60}
          />
          <div className="item-det-sect">
            <div className="details-wrapper">
              <h5>
                {props.moduleName}{" "}
                {isPartiallyUnlocked() && !props.isAllTransactionLocked && (
                  <span className="status-tag">Partially Unlocked</span>
                )}{" "}
              </h5>
              {isLocked() && !props.isAllTransactionLocked ? (
                <>
                  <p>
                    {props.cardSubHeading} created before{" "}
                    <span className="date">
                      {DateFormatHandler(getLockDate())}
                    </span>{" "}
                    have been locked.
                  </p>
                  <span className="reason">Reason: {getLockReason()}</span>
                </>
              ) : (
                <p>
                  You have not locked {props.cardSubHeading} in this module.
                </p>
              )}
            </div>
            {isPartiallyUnlocked() && !props.isAllTransactionLocked && (
              <div className="details-wrapper">
                <p>
                  Partial Unlock enabled from{" "}
                  <span className="date">
                    {DateFormatHandler(getPartialUnlockStartDate())}
                  </span>{" "}
                  to{" "}
                  <span className="date">
                    {DateFormatHandler(getPartialUnlockEndDate())}
                  </span>
                </p>
                <span className="reason">
                  Reason: {getPartialUnlockReason()}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="btn-sect">
          {isLocked() && !props.isAllTransactionLocked ? (
            <>
              <div className="dropdown">
                <button
                  className="btn unlock-button green-button right-button dropdown-toggle"
                  type="button"
                  id={props.modalId}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <UnlockButton buttonText="Unlock" />
                </button>
                <ul className="dropdown-menu" aria-labelledby={props.modalId}>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (isPartiallyUnlocked()) {
                          props.disablePartialUnlock(props.moduleId);
                          $("#notificationPopupModal").modal("show");
                        } else {
                          props.openModal(
                            "partial_unlock",
                            props.moduleName,
                            props.moduleId
                          );
                        }
                      }}
                    >
                      {isPartiallyUnlocked()
                        ? "Disable Partial Lock"
                        : "Unlock Partially"}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        props.openModal(
                          "unlock",
                          props.moduleName,
                          props.moduleId
                        );
                      }}
                    >
                      Unlock Completely
                    </button>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="cancel-button edit-button"
                onClick={() =>
                  props.openModal("edit_lock", props.moduleName, props.moduleId)
                }
              >
                <EditButton />
              </button>
            </>
          ) : (
            <button
              className="btn lock-button green-button right-button ml-auto"
              type="button"
              onClick={() =>
                props.openModal("lock", props.moduleName, props.moduleId)
              }
              disabled={props.isAllTransactionLocked}
            >
              <LockButton buttonText="Lock" />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default LockTransactionCard;
