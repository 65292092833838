import { FormikProps } from "formik";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../../helpers/numberFormatHelper";
import { useAppSelector } from "../../../../../../../app/hooks";
import { getAmountCalculationResult } from "../../../../paymentReceivedSlice";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../../../common/commonSlice";

type Props = {
  formik: FormikProps<any>;
};
export const TotalDetails = (props: Props) => {
  const totalDetails = useAppSelector(getAmountCalculationResult).total_details;
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);

  const totalAmount = totalDetails?.total
    ? NumberFormat(
        Number(totalDetails.total),
        props.formik.values.currency_code
          ? props.formik.values.currency_code
          : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          props.formik.values.currency_code
            ? props.formik.values.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const totalWithholdingTax = totalDetails.witholding_total
    ? NumberFormat(
        Number(totalDetails.witholding_total),
        props.formik.values.currency_code
          ? props.formik.values.currency_code
          : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          props.formik.values.currency_code
            ? props.formik.values.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const amountForPayment = totalDetails?.amount_used_payment
    ? NumberFormat(
        Number(totalDetails.amount_used_payment),
        props.formik.values.currency_code
          ? props.formik.values.currency_code
          : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          props.formik.values.currency_code
            ? props.formik.values.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const excess = totalDetails?.amount_excess
    ? NumberFormat(
        Number(totalDetails.amount_excess),
        props.formik.values.currency_code
          ? props.formik.values.currency_code
          : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          props.formik.values.currency_code
            ? props.formik.values.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const refundedAmount = props.formik.values.total_refunded_amount
    ? NumberFormat(
        Number(props.formik.values.total_refunded_amount),
        props.formik.values.currency_code
          ? props.formik.values.currency_code
          : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          props.formik.values.currency_code
            ? props.formik.values.currency_code
            : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  return (
    <div className="col right-details">
      <div className="row calculation-section g-0 m-0">
        <div className="d-flex justify-content-between">
          <div className="item-text total-text">Total</div>
          <div className="amt-wrapper">
            <span className="item-amt total-amt">
              {props.formik.values.currency_code} {totalAmount}
            </span>
          </div>
        </div>
        {props.formik.values.is_tax_deducted ? (
          <div className="d-flex justify-content-between">
            <div className="item-text">Total Withholding Tax:</div>
            <div className="amt-wrapper">
              <span className="item-amt">
                {props.formik.values.currency_code} {totalWithholdingTax}
              </span>
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-between">
          <div className="item-text">Amount used for Payments:</div>
          <div className="amt-wrapper">
            <span className="item-amt">
              {props.formik.values.currency_code} {amountForPayment}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="item-text">Amount Refunded:</div>
          <div className="amt-wrapper">
            <span className="item-amt">
              {props.formik.values.currency_code} {refundedAmount}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="item-text">Amount in Excess:</div>
          <div className="amt-wrapper">
            <span className="item-amt">
              {props.formik.values.currency_code} {excess}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
