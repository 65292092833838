import "../styles/AlertModal.css";
import { RadioButtonGroup } from "./radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Select from "react-select";
import { SelectCustomStyleCustomer } from "../../common/components/SelectCustomStyleCustomer";
import { getNextDate } from "../../../helpers/subscriptionHelper";
import { getFormatedDate } from "../../../helpers/helper";
import { FormControlLabel, Switch } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DurationTypeDropdown,
  handlePaidAmountDropdown,
  manageUnbilledChargesDropdown,
  manageUnpaidAmountDropdown,
  unusedCreditsDropdown,
} from "../../home/subscription/SubscriptionStateInitialization";
import moment from "moment";
import CustomDatepicker from "../../utils/atoms/datepicker";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormSelectField } from "./formSelectField/FormSelectField";
import FormControl from "@mui/material/FormControl";
import AccountSelect from "./paymentFormInputs/AccountSelect";
import { ObjectType } from "../../../types";
import { useParams } from "react-router-dom";
import { cancelActionDetails } from "../../home/subscription/subscriptionSlice";
import { useAppDispatch } from "../../../app/hooks";
import useCommonData from "../../hooks/useCommon";
import { NumberFormat } from "../../../helpers/numberFormatHelper";
import { CustomCreditsDropdown } from "../../home/subscription/components/CustomIssueCreditDropdown";
import Grid from "@mui/material/Grid";

type Props = {
  type?: string;
  modalCancelHandler: () => void;
  status: string;
  paymentStatus?: string;
  isUnpaidInvoiceExist?: boolean;
  modalType?: string;
  closeButtonClassName?: string;
  formik: any;
  nextRenewableDate: string;
  cancelFormErrors: ObjectType;
  isCurrentCycleInvoicePaid: boolean;
  unused_credits_days?: number;
  totalUnbilledCharge: string;
  currencyCode: string;
  isUnbilledChargesExists: boolean;
  cancelDetailsData: {unpaid_amount: number, paid_amount: number, available_credits: number};
  setCancelDetailsData: Dispatch<SetStateAction<{unpaid_amount: number, paid_amount: number, available_credits: number}>>;
  subscriptionId: number;
};

const VoidModalSubscription = (props: Props) => {
  const dispatch = useAppDispatch();
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const [creditsDays, setCreditsDays] = useState<number>(0)
  const onFormSelectChange = (value: any, name: any) => {
    props.formik.setFieldValue(name.name, value.value);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDate = (date: Date | null) => {
    if (date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      if (date?.toString() === "Invalid Date" || currentDate >= targetDate) {
        props.formik.setFieldValue("cancel_date", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          props.formik.setFieldValue("cancel_date", dateFormated);
        } else {
          props.formik.setFieldValue("cancel_date", "Invalid date");
        }
      }
    } else if (date === null) {
      props.formik.setFieldValue("cancel_date", "Empty date");
    }
  };

  const getCancelData = async (unusedCreditDays: number) => {
    if (["IMMEDIATELY"].includes(props.formik.values.cancel_status_option)) {
    const cancelActionDetailsResponse = await dispatch(
      cancelActionDetails({
      credit_note: props.formik.values.credit_note,
      include_cancellation_date: props.formik.values.include_cancellation_date && props.formik.values.credit_note === 'PRORATED',
      subscription_id: Number(props.subscriptionId),
      manage_unbilled_charges: props.isUnbilledChargesExists ? props.formik.values.manage_unbilled_charges : null,
      unused_credits_days: unusedCreditDays,
      cancel_status_option: props.formik.values.cancel_status_option,
      cancel_date: getFormatedDate(props.formik.values.cancel_date),
      apply_refundable_to_unpaid: props.formik.values.credit_note !== "NO_CREDIT" && props.formik.values.apply_refundable_to_unpaid,
      orgId: currentUserInfo.organization_id,
      })
    );
    if (cancelActionDetailsResponse.payload) {
      const cancelDetailsResponse = cancelActionDetailsResponse.payload;
      if (
        Object.keys(cancelDetailsResponse).length &&
        !("error" in cancelDetailsResponse)
      ) { 
        props.setCancelDetailsData(cancelDetailsResponse)

        if (cancelDetailsResponse.unpaid_amount === 0) {
          props.formik.setFieldValue('unpaid_amount', "RETAIN_AS_UNPAID")
        }
      } else if ("error" in cancelDetailsResponse) {
        props.setCancelDetailsData({unpaid_amount: 0, paid_amount: 0, available_credits: 0})
      }
    }} else {
      props.setCancelDetailsData({unpaid_amount: 0, paid_amount: 0, available_credits: 0})
    }
  }
  useEffect(() => {
    if (["ACTIVE", "NON_RENEWING", "STOPPED"].includes(props.status)) {
      if (!props.formik.values.include_cancellation_date && props.formik.values.credit_note === 'PRORATED') {
        setCreditsDays(Number(props.unused_credits_days) + 1)
        getCancelData(Number(props.unused_credits_days) + 1)
      } else {
        setCreditsDays(Number(props.unused_credits_days))
        getCancelData(Number(props.unused_credits_days))
      }
    }
  }, [props.formik.values.credit_note,
    props.formik.values.include_cancellation_date,
    props.formik.values.manage_unbilled_charges,
    props.formik.values.cancel_date,
  ])

  return (
    <div>
      <RadioButtonGroup
        name="cancel_status_option"
        id="cancel_status_option"
        valuesArray={DurationTypeDropdown.filter((data) =>
          props.status === "NON_RENEWING"
            ? ["IMMEDIATELY", "SPECIFIC_DATE"].includes(data.value)
            : !["FUTURE", "STOPPED"].includes(props.status) ||
              data.value === "IMMEDIATELY"
        )}
        value={props.formik.values.cancel_status_option}
        icon={
          <span>
            <RadioButtonUncheckedIcon />
          </span>
        }
        checkedIcon={
          <span>
            <RadioButtonCheckedIcon className="radio-btn-tds" />
          </span>
        }
        isDisabled={false}
        formik={props.formik}
        onClick={(e) => {
          props.formik.setFieldValue("cancel_status_option", e);
          if (e === "SPECIFIC_DATE") {
            props.formik.setFieldValue("cancel_date", getNextDate());
          } else if (e === "END_OF_CURRENT_TERM") {
            const [day, month, year] = props.nextRenewableDate
              .split("-")
              .map(Number);
            props.formik.setValues({...props.formik.initialValues, // need to check this
            "cancel_date" : getFormatedDate(new Date(year, month - 1, day)),
            "cancel_status_option": e
          })
          } else if (e === "IMMEDIATELY") {
            props.formik.setFieldValue(
              "cancel_date",
              getFormatedDate(new Date())
            );
          }
        }}
      />
      <div>
        <div className="col-12 col-lg-4 mw-325 void-subscription-margin-container">
          <div className="date-selector-wrapper void-subscription-margin row">
            <label className="pause-date-label" htmlFor="cancel_date">
              Cancel Date
            </label>
            <CustomDatepicker
              date={
                props.formik.values.cancel_date
                  ? new Date(props.formik.values.cancel_date)
                  : new Date()
              }
              type="cancel_date"
              handleDate={handleDate}
              name="cancel_date"
              id="cancel_date"
              error={!props.cancelFormErrors.cancel_date ? "" : "error"}
              zIndex={0}
              placeholder="Enter Updation date"
              minDate={getNextDate()}
              enableFutureDate={true}
              isDisabled={["IMMEDIATELY", "END_OF_CURRENT_TERM"].includes(
                props.formik.values.cancel_status_option
              )}
            />
            <span className="error">
              {!props.cancelFormErrors.cancel_date
                ? ""
                : props.cancelFormErrors.cancel_date}
            </span>
          </div>

          {!["IN_TRIAL", "FUTURE"].includes(props.status)? (
            <>
              {props.formik.values.cancel_status_option === "IMMEDIATELY" && props.isUnbilledChargesExists ? <FormSelectField
                name="manage_unbilled_charges"
                id="manage_unbilled_charges"
                label={`Handling  Unbilled Charges (${props.totalUnbilledCharge})`} 
                options={manageUnbilledChargesDropdown}
                wrapperClass="mw-325"
                className="state-select custom-select"
                value={
                  manageUnbilledChargesDropdown.filter(
                    (data) => data.value === props.formik.values.manage_unbilled_charges
                  )[0] || ''
                }
                onChange={(option: any) => {
                  props.formik.setFieldValue("manage_unbilled_charges", option.value);
                }}
                styles={customSelectStyle}
                placeholder={""}
                isSearchable={true}
                isDisabled={false}
                error=""
                isRequired={false}
                isOnlyInRow={false}
              /> : null}
              {["STOPPED"].includes(props.status) || (props.status === "ACTIVE" && (['END_OF_CURRENT_TERM', 'SPECIFIC_DATE'].includes(props.formik.values.cancel_status_option) || props.formik.values.manage_unbilled_charges === 'DELETE')) ? null : (
                <>
                  <label htmlFor="credit_note">
                    Handling Unused Credits ({creditsDays} days)
                  </label>
                  <Select
                    name="credit_note"
                    id="credit_note"
                    options={CustomCreditsDropdown(unusedCreditsDropdown)}
                    className={`discount-period-count trial-period-type-radius state-select form-select custom-select void-subscription-margin`}
                    onChange={onFormSelectChange}
                    isSearchable={false}
                    placeholder=""
                    styles={SelectCustomStyleCustomer}
                    value={
                      CustomCreditsDropdown(unusedCreditsDropdown.map(data => ({label: data.label, value: data.value}))).filter(
                        (data) => data.value === props.formik.values.credit_note
                      )[0]
                    }
                    isDisabled={false}
                  />

                  {props.formik.values.credit_note === "PRORATED" && (
                    <FormControlLabel
                      className="emailEnableAndDisable void-subscription-cancellation-date-include"
                      control={
                        <Switch
                          checked={
                            props.formik.values.include_cancellation_date
                          }
                          color="primary"
                          disableRipple
                          onChange={(event) => {
                            props.formik.setFieldValue(
                              "include_cancellation_date",
                              event.target.checked // Correctly update the Formik value
                            );

                            if (!event.target.checked && ["ACTIVE", "NON_RENEWING"].includes(props.status)){
                              props.formik.setFieldValue("bank_charges_account_id");
                            }
                          }}
                        />
                      }
                      label="Include cancellation date in the invoicing period"
                      labelPlacement="start"
                      name="include_cancellation_date"
                      value={props.formik.values.include_cancellation_date}
                    />
                  )}
                </>
              )}

              {["PRORATED", "FULL_CREDIT"].includes(props.formik.values.credit_note) && ["ACTIVE", "NON_RENEWING"].includes(props.status) && Number(props.cancelDetailsData.unpaid_amount) > 0 && props.isCurrentCycleInvoicePaid && Number(props.cancelDetailsData.available_credits) > 0 &&(<>
                <FormControlLabel
                  className="emailEnableAndDisable void-subscription-apply-to-unpaid"
                  control={
                    <Switch
                      checked={
                        props.formik.values.apply_refundable_to_unpaid
                      }
                      color="primary"
                      disableRipple
                      onChange={(event) => {
                        props.formik.setFieldValue(
                          "apply_refundable_to_unpaid",
                          event.target.checked
                        );
                      }}
                    />
                  }
                  label="Automatically apply the refundable credit to the unpaid invoice"
                  labelPlacement="start"
                  name="apply_refundable_to_unpaid"
                  value={props.formik.values.apply_refundable_to_unpaid}
                />
                <Grid container columnGap={1} className="apply-credits-unpaid">
                  <Grid>
                    {`Unpaid invoices: ${`${props.currencyCode || ""} ${NumberFormat(
                      props.cancelDetailsData.available_credits || 0,
                      props.currencyCode || "",
                      orgCurrencyList
                    )}`};`}
                  </Grid>
                  <Grid>
                    {`Available credits: ${`${props.currencyCode || ""} ${NumberFormat(
                      props.cancelDetailsData.paid_amount || 0,
                      props.currencyCode || "",
                      orgCurrencyList
                    )}`}`}
                  </Grid>
                </Grid>
              </>

              )}
              {props.status === "ACTIVE"
              && (props.formik.values.cancel_status_option === 'END_OF_CURRENT_TERM' || props.formik.values.manage_unbilled_charges === 'DELETE')
              || (["ACTIVE", "NON_RENEWING"].includes(props.status) && Number(props.cancelDetailsData.unpaid_amount) === 0) ? null : <FormSelectField
                name="unpaid_amount"
                id="unpaid_amount"
                label={`Manage Unpaid Amount (${`${props.currencyCode || ""} ${NumberFormat(
                  props.cancelDetailsData.unpaid_amount || 0,
                  props.currencyCode || "",
                  orgCurrencyList
                )}`})`}
                options={manageUnpaidAmountDropdown}
                wrapperClass="mw-325"
                className="state-select custom-select"
                value={
                  manageUnpaidAmountDropdown.filter(
                    (data) => data.value === props.formik.values.unpaid_amount
                  )[0]
                }
                onChange={(option: any) => {
                  props.formik.setFieldValue("unpaid_amount", option.value);
                }}
                styles={customSelectStyle}
                placeholder={""}
                isSearchable={true}
                isDisabled={false}
                error=""
                isRequired={false}
                isOnlyInRow={false}
              />}

              {props.formik.values.unpaid_amount === "WRITE_OFF" && (
                <FormControl className="form-control-account-select mw-325 subscription-cancel-select-account">
                  <label htmlFor="account" className="fw-bold required">
                    Choose Account
                  </label>
                  <AccountSelect
                    handleBankCharge={(e) => {
                      props.formik.setFieldValue(
                        "bank_charges_account_id",
                        e.value
                      );
                    }}
                    bankChargesAccountId={
                      props.formik.values.bank_charges_account_id
                    }
                    accountNames={["Expenses", "Assets"]}
                    error={
                      !props.cancelFormErrors.bank_charges_account_id
                        ? ""
                        : "error"
                    }
                    wrapperClass="w-100"
                    placeholder="Choose Account"
                    menuPlacement={
                      screenWidth <= 1096 || screenHeight <= 1000
                        ? "top"
                        : "bottom"
                    }
                  />
                  <span className="error">
                    {!props.cancelFormErrors.bank_charges_account_id
                      ? ""
                      : props.cancelFormErrors.bank_charges_account_id}
                  </span>
                </FormControl>
              )}

              {!["STOPPED"].includes(props.status) &&
              props.isCurrentCycleInvoicePaid && Number(props.cancelDetailsData.paid_amount) > 0 ? (
                <>
                  <label htmlFor="handle_paid_amount">
                    {`How to handle the paid amount (${`${props.currencyCode || ""} ${NumberFormat(
                  props.cancelDetailsData.paid_amount || 0,
                  props.currencyCode || "",
                  orgCurrencyList
                )}`})`}
                  </label>
                  <Select
                    name="handle_paid_amount"
                    id="handle_paid_amount"
                    options={handlePaidAmountDropdown}
                    className={`discount-period-count trial-period-type-radius state-select form-select custom-select void-subscription-margin`}
                    onChange={onFormSelectChange}
                    isSearchable={false}
                    placeholder=""
                    styles={SelectCustomStyleCustomer}
                    value={
                      handlePaidAmountDropdown.filter(
                        (data) =>
                          data.value === props.formik.values.handle_paid_amount
                      )[0]
                    }
                    isDisabled={false}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VoidModalSubscription;
