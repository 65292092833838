import { SvgIconProp } from "../../../../types";

export const ExpandRowIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width?props.width:"16px"}
      height={props.height?props.height:"16px"}
      viewBox="0 0 16 16"
      style={{transform:props.rotate}}

    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 16h16V0H0z" />
        <path fill={"#323E48"} fillRule={"nonzero"} d="m8 5.333-4 4h8z" />
      </g>
    </svg>
  );
};
