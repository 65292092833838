import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import {
  deleteById,
  getCoaListView,
  getCoaTreeView,
  getCoaTreeViewInAddAccount,
} from "../coaSlice";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteIdReset: () => void;
  deleteAccountId: number;
  page: number;
  itemsPerPage: number;
  deleteAccountName: string;
  accountName: string;
  nameSort: string;
  typeSort: string;
  balanceSort: string;
  organizationId: number;
};

const DeleteAccount = (props: Props) => {
  const dispatch = useAppDispatch();
  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      deleteById({
        accountId: props.deleteAccountId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const deleteUserResponse = responseAction.payload;
      if (
        Object.keys(deleteUserResponse).length &&
        !("error" in deleteUserResponse)
      ) {
        $(".close-btn").click();
        await dispatch(
          getCoaListView({
            page: props.page,
            itemsPerPage: props.itemsPerPage,
            accountName: props.accountName,
            nameSort: props.nameSort,
            typeSort: props.typeSort,
            balanceSort: props.balanceSort,
            orgId: props.organizationId,
          })
        );
        await dispatch(
          getCoaTreeView({ name: "", orgId: props.organizationId })
        );
        await dispatch(getCoaTreeViewInAddAccount(props.organizationId));
        toast.success("Account deleted successfully!", {
          toastId: "account-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteUserResponse) {
        $(".close-btn").click();
        const errorDetails = deleteUserResponse.error.detail;
        if (Array.isArray(errorDetails) && errorDetails.length) {
          for (const error of errorDetails) {
            if (error.type === "value_error.parent_account") {
              toast.error("Delete Sub-account First", {
                icon: ({ theme, type }) => (
                  <img src={ErrorImg} width="16" height="14" alt="error-img" />
                ),
                toastId: "delete-account-failed-error",
                closeButton: false,
                className: "toast-error",
                position: "top-center",
              });
            }
          }
        }
      }
    }
  };

  /**
   * Delete cancel handler
   */
  const modalCancelHandler = () => {
    $(".close-btn").click();
    props.deleteIdReset();
  };

  return (
    <DeleteModal
      heading=" Delete Account!"
      subHeading="Are you sure you want to delete the account"
      transactionName={props.deleteAccountName}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};

export default DeleteAccount;
