import { useEffect, useState } from "react";
import { ReportTagFormValues, ReportTagOptions } from "../../../../types";

export const useInitializeForm = (
  tagDetails?: ReportTagFormValues,
  isClone?: boolean
) => {
  const [initialValues, setInitialValues] = useState<ReportTagFormValues>({
    name: "",
    options: [
      { id: null, name: "" },
      { id: null, name: "" },
      { id: null, name: "" },
    ] as ReportTagOptions[],
  });
  const filterForClone = (tagDetails: ReportTagFormValues) => {
    return {
      name: "",
      options: tagDetails.options.map((option) => {
        return {
          id: null,
          name: option.name,
        };
      }),
    };
  };
  useEffect(() => {
    if (isClone && tagDetails) {
      setInitialValues(filterForClone(tagDetails));
    } else {
      setInitialValues(
        tagDetails || {
          name: "",
          options: [
            { id: null, name: "" },
            { id: null, name: "" },
            { id: null, name: "" },
          ] as ReportTagOptions[],
        }
      );
    }
  }, [tagDetails]);

  return { initialValues };
};
