import CenterModal from "../../../../common/components/centerModal/CenterModal";
import "./cancelChangesModal.css";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import CancelDetailsModalContent from "./CancelDetailsModalContent";
import { cancelScheduler } from "../../subscriptionSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { toast } from "react-toastify";

type Props = {
  type: string,
  action: string,
  schedulerId: number,
  subscriptionSchedulerDetails: any
}

export const CancelChangesModal = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();

  const cancelModalSubmitHandler = async () => {
      const cancelSchedulerResponseAction = await dispatch(
        cancelScheduler({
          schedulerId: props.schedulerId,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (cancelSchedulerResponseAction.payload) {
        const cancelSchedulerResponse = cancelSchedulerResponseAction.payload;
        if (
          Object.keys(cancelSchedulerResponse).length &&
          !("error" in cancelSchedulerResponse)
        ) {
          props.subscriptionSchedulerDetails();
          $("#scheduler-cancel-btn").click();
          toast.success(`Remove scheduled ${props.type.toLowerCase()} successfully!`, {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in cancelSchedulerResponse) {
          $("#scheduler-cancel-btn").click();
        } 
    }
  };


  const ConfigureButton = () => {
    return (
      <>
      <span className="subscription-scheduler-cancel">{`Remove Scheduled ${props.type}`}</span>
      </>
    );
  };
  return (
    <CenterModal
      modalTitle={`Remove Scheduled ${props.type}`}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <CancelDetailsModalContent type={props.type} onCloseModal={onCloseModal} cancelModalSubmitHandler={cancelModalSubmitHandler}/>;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={props.type === 'Resume' ?  '' : <ConfigureButton />}
      wrapperClass="large-form-modal cancel-impact-details-modal"
      buttonClass="add-customer-link"
      isCloseButtonEnabled={true}
    />
  );
};
