import moment from "moment";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import {
  FormInputField,
  FormSelectField,
  getFormatedDate,
} from "../../../../home/paymentsReceived/components/paymentReceivedForm/imports";
import { customSelectStyle } from "../../SelectCustomStyle";
import "./checkdetails.css";
import { FieldContainer } from "..";
import { ObjectType } from "../../../../../types";

type Props = {
  check_date: string;
  check_expiry_in: string;
  check_expiry: string;
  check_number: string;
  onChange: (newData: any) => void;
  errors: any;
};

const CheckDetails = (props: Props) => {
  const getCheckExpiryDate = (checkDate: string, expiryIn: string) => {
    let checkExpiryDate = getFormatedDate(
      moment(checkDate)
        .add(expiryIn === "90 days" ? 90 : 180, "days")
        .toDate()
    );
    return checkExpiryDate;
  };
  const checkDateChangeHandler = (date: any) => {
    if (!date || date?.toString() === "Invalid Date") {
      props.onChange({
        check_date: "Invalid Date",
      });
      return;
    }

    const dateFormated = getFormatedDate(date);
    if (!moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
      props.onChange({
        check_date: "Invalid Date",
      });
      return;
    }

    let checkExpiryDate = getCheckExpiryDate(
      dateFormated,
      props.check_expiry_in
    );

    props.onChange({
      check_date: dateFormated,
      check_expiry: checkExpiryDate,
    });
  };

  const expiryInChangeHandler = (e: any) => {
    let checkExpiryDate = getCheckExpiryDate(props.check_date, e.value);
    props.onChange({
      check_expiry_in: e.label,
      check_expiry: checkExpiryDate,
    });
  };

  const checkNumberChangeHandler = (e: ObjectType) => {
    props.onChange({
      check_number: e.target.value,
    });
  };
  return (
    <>
      <FieldContainer
        label="Check Date"
        required={true}
        error={props.errors.check_date}
        tooltip="Check date is the date written on a check by the issuer, indicating when the check was written or issued."
      >
        <div id="date" className="date-selector-wrapper">
          <CustomDatepicker
            date={new Date(props.check_date)}
            handleDate={checkDateChangeHandler}
            type="paymentDate"
            error={props.errors.check_date}
            id="check_date"
            zIndex={0}
            placeholder="Enter check expiry date"
            enableFutureDate={true}
          />
        </div>
      </FieldContainer>
      <FormSelectField
        id="check_expiry_in"
        name="check_expiry_in"
        className=""
        wrapperClass="w-100 mb-4"
        options={[
          { value: "90 days", label: "90 days" },
          { value: "180 days", label: "180 days" },
        ]}
        value={
          props.check_expiry_in
            ? {
                value: props.check_expiry_in,
                label: props.check_expiry_in,
              }
            : ""
        }
        onChange={expiryInChangeHandler}
        isDisabled={false}
        isOnlyInRow={false}
        isRequired={true}
        label="Expiry in"
        error={props.errors.check_expiry_in}
        styles={customSelectStyle}
        placeholder="Choose an option"
        isSearchable={false}
      />
      <FieldContainer
        label="Expiry Date"
        required={true}
        error={props.errors.check_expiry}
      >
        <div id="date" className="date-selector-wrapper">
          <CustomDatepicker
            date={props.check_expiry ? new Date(props.check_expiry) : null}
            name="check_expiry"
            handleDate={() => {}}
            type="paymentDate"
            error={props.errors.check_expiry}
            id="check_expiry"
            zIndex={0}
            placeholder="Check expiry date"
            enableFutureDate={true}
            isDisabled={true}
          />
        </div>
      </FieldContainer>
      <FormInputField
        type="text"
        name="check_number"
        id="check_number"
        wrapperClass="w-100 max-w-none"
        className={`form-control bank-tax-input w-100`}
        onChange={checkNumberChangeHandler}
        placeholder="Enter check number"
        maxLength={17}
        value={props.check_number}
        label="Check Number"
        error={props.errors.check_number}
        isRequired={true}
        errorClass="mb-2"
      />
    </>
  );
};

export default CheckDetails;
