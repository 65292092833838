import { Api } from "../../../constants";
import { get, put } from "../../../helpers/apiHelper";
import { roundOffPreference, RecurringInvoicePreference } from "../../../types";

/**
 * API call for checking the credit note number exist or not
 */
export async function getRoundOffPreference(data: {
  orgId: number;
  roundoff_transaction_type?: string;
}) {
  let roundoff_transaction_type = data?.roundoff_transaction_type
    ? data?.roundoff_transaction_type
    : "Sales";
  let result = await get(
    Api.chartOfAccount +
      Api.roundOffPreference +
      `/get-roundoff-preference?current_organization=${data.orgId}&roundoff_transaction_type=${roundoff_transaction_type}`
  );
  return result;
}

/**
 * API call for updating default tax preference
 */
export async function putRoundOffPreference(data: {
  payload: roundOffPreference;
  orgId: number;
}) {
  // data.payload.roundoff_transaction_type = "Sales";
  const result = await put(
    Api.chartOfAccount +
      `${Api.roundOffPreference}/update-roundoff-preference?current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}

export async function getRIPreference(data: { orgId: number }) {
  let result = await get(
    Api.recurringSalesInvoice +
      Api.getPreferredInvoiceStatus +
      `?current_organization=${data.orgId}`
  );
  return result;
}

export async function updateRecurringPreference(data: {
  payload: RecurringInvoicePreference;
  orgId: number;
}) {
  const result = await put(
    Api.recurringSalesInvoice +
      `${Api.updatePreferredInvoiceStatus}?current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}
