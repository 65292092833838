import { useState } from "react";
import { ExchangeRateValues, ObjectType } from "../../../../../types";
import { SaveButton } from "../../button/SaveButton";
import { SecondaryButton } from "../../button/SecondaryButton";
import "../../../../../index.css";
import "./MultipleExchangeRate.css";

type MultipleExchangeRateProps = {
  module?: string;
  currencyId: number | string;
  toCurrencyId: number | string;
  toCurrencyCode: string;
  conversionDate: string;
  conversionRate: string;
  handleClose: () => void;
  exchangeRateSubmitHandler?: (data: any) => void;
  maxLength?: number;
};
export const MultipleExchangeRateForm = (props: MultipleExchangeRateProps) => {
  const [formErrors, setFormErrors] = useState<ObjectType>({});

  let initialFormValues = {
    base_currency: props.currencyId,
    to_currency: props.toCurrencyId,
    rate: props.conversionRate,
    by_user: true,
  };
  const [exchangeFormValues, setExchangeFormValues] =
    useState(initialFormValues);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const regex = /^-?([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (value === "" || regex.test(value)) {
      if(value.includes(".") && value.split(".")[1].length > 6){
        return;
      }
      setExchangeFormValues({ ...exchangeFormValues, [name]: value });
    }
  };

  const validate = (values: ExchangeRateValues) => {
    const errors: ObjectType = {};
    if (!values.rate) {
      errors.rate = "Please enter a valid exchange rate";
    }
    return errors;
  };

  const submitHandler = async () => {
    let inputs: any = { ...exchangeFormValues };
    inputs = {
      ...inputs,
      base_currency: props.currencyId,
      to_currency: props.toCurrencyId,
      by_user: true,
      rate: Number(inputs.rate),
      timestamp: props.conversionDate,
    };
    let errors = validate(inputs);
    if (Object.keys(errors).length) {
      setFormErrors({ ...errors });
    } else {
      setFormErrors({});
      props.exchangeRateSubmitHandler &&
        props.exchangeRateSubmitHandler(inputs);
    }
  };


  return (
    <div
      className={props.module === "COA" ? "coa-exchange" : ""}
      id="multipleExchangeRateEdit"
    >
      <div className="card card-body">
        <div className="row mb-0">
          <div className="col">
            <label
              htmlFor=""
              className={
                props.module === "COA"
                  ? "required fw-bold mb-2 ml-0"
                  : "required"
              }
            >
              Edit Exchange Rate
            </label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div className="input-group-text" id="basic-addon1">
                  {props.toCurrencyCode}
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label="exchange rate"
                aria-describedby="basic-addon1"
                name="rate"
                value={exchangeFormValues?.rate}
                onChange={handleInputChange}
                onFocus={(e: any) =>
                  e.target.parentElement.classList.add("focused")
                }
                onBlur={(e: any) =>
                  e.target.parentElement.classList.remove("focused")
                }
              />
              <span className="error">{formErrors?.rate}</span>
            </div>
            <div className="form-button-wrapper mt-4">
              <SaveButton
                onClick={submitHandler}
                type="button"
                isDisabled={false}
                buttonContent="Save"
              />
              <SecondaryButton
                onClick={props.handleClose}
                type="button"
                isDisabled={false}
                buttonContent="Cancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
