import React from "react";
import { InfoIcon } from "../../common/components/customSvgIcons/infoIcon";
import { InfoIconImg } from "../../../assets/images";
import CustomTooltips, { position } from "./Tooltip";

type Props = {
  title: string;
  disabled?: boolean;
  placement?: position;
  isCustomIcon?: boolean;
};
export default function InfoTooltip(props: Props) {
  return (
    <CustomTooltips
      title={props.title}
      placement={props.placement ? props.placement : "top-end"}
    >
      <button
        type="button"
        disabled={props.disabled ? props.disabled : false}
        className="info-btn position-relative no-appearance"
        onClick={(e) => e.preventDefault()}
      >
        {props.isCustomIcon ? (
          <InfoIcon />
        ) : (
          <img src={InfoIconImg} width="16" alt="info icon" />
        )}
      </button>
    </CustomTooltips>
  );
}
