import { SolidCancelButton, SolidDeleteButton } from "../button";
import "./DeleteAlert.css";

type Props = {
  deleteMessageContent: React.ReactNode;
  onSubmit: () => void;
  onCancel: () => void;
  isSubmitDisabled: boolean;
};
export const DeleteModalBody = (props: Props) => {
  return (
    <div className="delete-modal-body-wrapper">
      <div className="delete-modal-body-content">
        <div className="modal-footer">
          {props.deleteMessageContent}
          <div className="w-100 d-flex justify-content-end">
            {" "}
            <SolidCancelButton onClick={props.onCancel} isDisabled={false} />
            <SolidDeleteButton
              onClick={() => {
                props.onSubmit();
                props.onCancel();
              }}
              isDisabled={props.isSubmitDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
