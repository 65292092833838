import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BillPaymentValues,
  PaymentMadeJournalListPayload,
  ReportingTagsAllocationPayload,
  TotalPaymentMadeValues,
} from "../../../types";
import { RootState } from "../../../app/store";
import {
  createJournalListPM,
  createNewBillPayment,
  createNewRefund,
  deleteBillPayment,
  deletePRtoBillAllocation,
  deletePaymentMadeAttachments,
  deleteRefund,
  editBillPayment,
  editRefund,
  editReportingTagPM,
  fetchRefundDetails,
  getBillPaymentLists,
  getPaymentMadeDetails,
  getPaymentMadeNumber,
  getUnpaidVendorBillList,
  paymentMadeAttachments,
  paymentMadeNumberExist,
  paymentMadePdfLink,
  removePaymentMadeRecipients,
  sendPaymentMade,
  sundryTagAllocatedBillsTagDetails,
  totalAmountCalculation,
  transactionLimitStatus,
} from "./paymentsMadeAPI";

export const billPaymentList = createAsyncThunk(
  "paymentMadeList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    vendorList: number[];
    orgIds: number[];
    statusList: string[];
    billId?: number | null;
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getBillPaymentLists(data);
  }
);

export const deleteBillPaymentDetails = createAsyncThunk(
  "deletePaymentBill",
  async (data: { deleteBillPaymentId: number; orgId: number }) => {
    return await deleteBillPayment(data);
  }
);

export const createBillPayment = createAsyncThunk(
  "create/billPayment",
  async (data: {
    values: BillPaymentValues;
    status: string;
    orgId: number;
  }) => {
    return await createNewBillPayment(data);
  }
);

export const updateBillPayment = createAsyncThunk(
  "edit/billPayment",
  async (data: {
    billPaymentId: number;
    values: BillPaymentValues;
    status: string;
    isVendorChanged: boolean;
    orgId: number;
  }) => {
    return await editBillPayment(data);
  }
);

export const paymentMadeDetails = createAsyncThunk(
  "paymentMadeDetails",
  async (data: { paymentMadeId: number; orgId: number }) => {
    return await getPaymentMadeDetails(data);
  }
);

export const deletePaymentMadeRecipients = createAsyncThunk(
  "pm/delete/recipients",
  async (data: {
    paymentMadeId: number;
    emailIds: number[];
    orgId: number;
  }) => {
    return await removePaymentMadeRecipients(data);
  }
);

export const getNewPaymentMadeNumber = createAsyncThunk(
  "newPaymentMadeNumber",
  async (orgId: number) => {
    return await getPaymentMadeNumber(orgId);
  }
);

export const checkPaymentMadeNumber = createAsyncThunk(
  "checkPaymentMadeNumber",
  async (data: {
    paymentMadeNumber: string;
    orgId: number;
    paymentMadeId: number;
    signal?: AbortSignal;
  }) => {
    return await paymentMadeNumberExist(data);
  }
);

export const unpaidVendorBillsList = createAsyncThunk(
  "unpaidVendorBills",
  async (data: { vendorId: number; orgId: number }) => {
    return await getUnpaidVendorBillList(data);
  }
);

export const attachPaymentMadeFiles = createAsyncThunk(
  "pm/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await paymentMadeAttachments(data);
  }
);

export const deletePaymentMadeFile = createAsyncThunk(
  "pm/file/delete",
  async (data: { paymentMadeId: number; fileId: number; orgId: number }) => {
    return await deletePaymentMadeAttachments(data);
  }
);

export const sendPaymentMadeData = createAsyncThunk(
  "send-payment-made",
  async (data: { paymentMadeId: number; emails: string[]; orgId: number }) => {
    return await sendPaymentMade(data);
  }
);

export const getPaymentMadePdfLink = createAsyncThunk(
  "payment-made/pdf",
  async (data: { paymentMadeId: number; orgId: number }) => {
    return await paymentMadePdfLink(data);
  }
);

export const getTotalAmountDetails = createAsyncThunk(
  "total-amount",
  async (data: {
    values: TotalPaymentMadeValues;
    orgId: number;
    signal: Object;
  }) => {
    return totalAmountCalculation(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "pm/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const deleteBillAllocation = createAsyncThunk(
  "pm/limit/status",
  async (data: {
    orgId: number | null;
    pmId: number | null;
    itemId: number | null;
  }) => {
    return await deletePRtoBillAllocation(data);
  }
);

export const generateJournalListPM = createAsyncThunk(
  "pm-reporting-tag/generate",
  async (data: {
    pmId: number | null;
    orgId: number | null;
    values: PaymentMadeJournalListPayload;
  }) => {
    return await createJournalListPM(data);
  }
);

export const updateReportingTagsPM = createAsyncThunk(
  "pm-reporting-tag/allocation",
  async (data: {
    pmId: number | null;
    orgId: number | null;
    values: any;
  }) => {
    return await editReportingTagPM(data);
  }
);


export const createRefund = createAsyncThunk(
  "pr-refund/create",
  async (data: { orgId: number | null; values: any }) => {
    return await createNewRefund(data);
  }
);

export const getRefundDetails = createAsyncThunk(
  "pr-refund/get",
  async (data: { refundId: number; orgId: number }) => {
    return await fetchRefundDetails(data);
  }
);

export const updateRefund = createAsyncThunk(
  "pr-refund/edit",
  async (data: { refundId: number; orgId: number; values: any }) => {
    return await editRefund(data);
  }
);

export const removeRefund = createAsyncThunk(
  "pr-refund/remove",
  async (data: { refundId: number; orgId: number }) => {
    return await deleteRefund(data);
  }
);
export const getSundryAllocatedBillTagDetails = createAsyncThunk(
  "sundry-allocated-tag-list/get",
  async (data: { billIds: number[]; orgId: number }) => {
    return await sundryTagAllocatedBillsTagDetails(data);
  }
);


const initialState = {
  isApiPending: false,
  amountCalculationResult: {
    total_details: {
      total: 0,
      amount_used_payment: 0,
      amount_paid: 0,
      bank_charges: 0,
      amount_excess: 0,
      bill_balances_after_payment: {},
    },
    allocatable_amount: 0,
  },
};

export const paymentsMadeSlice = createSlice({
  name: "paymentsMade",
  initialState,
  reducers: {
    setIsApiPending: (state, action) => {
      state.isApiPending = action.payload;
    },
    setAmountCalculationResult: (state, action) => {
      state.amountCalculationResult = {
        ...action.payload,
      };
    },
  },
});

export default paymentsMadeSlice.reducer;

export const { setIsApiPending, setAmountCalculationResult } =
  paymentsMadeSlice.actions;

export const isApiPendingSelector = (state: RootState) =>
  state.paymentsMade.isApiPending;

export const getAmountCalculationResult = (state: RootState) =>
  state.paymentsMade.amountCalculationResult;
