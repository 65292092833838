import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import useCommonData from "../../../../../hooks/useCommon";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useListData from "../../../../../hooks/useListData";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import {
  billPaymentList,
  getPaymentMadePdfLink,
  sendPaymentMadeData,
} from "../../../paymentsMadeSlice";
import {
  fiscalYearFilterSelector,
  setLoaderState,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import { useAppSelector } from "../../../../../../app/hooks";
import { listFilterSelector } from "../../../../../../appSlice";
import {
  PaymentMadeListValues,
  Transactionlimit,
} from "../../../../../../types";
import PMTable from "./PMTable";
import {
  DateFormatHandler,
  StatusColor,
  downloadUsingURL,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import ListActions from "./ListActions";
import CustomTooltips from "../../../../../common/components/Tooltip";
import { toast } from "react-toastify";
import {
  paymentMadeColumnValues,
  paymentMadeDefaultValues,
  paymentMadeMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import Popover from "../../../../../common/components/Popover";
import TableSetup from "../../../../../common/components/table/TableSetup";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (
    id: number,
    name: string,
    status: string,
    isUnpaid: boolean
  ) => void;
};

function PMTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const { paymentsMadeRolePermission } = usePermissionHandler();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [billPaymentId, setBillPaymentId] = useState(0);
  const [billPaymentData, setBillPaymentData] = useState<
    PaymentMadeListValues[]
  >([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  useImperativeHandle(
    ref,
    () => ({
      fetchBillPaymentList: fetchBillPaymentList,
    }),
    []
  );

  useEffect(() => {
    fetchTableCustomizationFields(
      "Payments Made",
      paymentMadeDefaultValues,
      paymentMadeMandatoryValues
    );
    fetchBillPaymentList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    selectedFilterData,
    // JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    paymentMadeColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [billPaymentData, selectedColumns]);
  /**
   * Fetch all Bill Payments
   */
  const fetchBillPaymentList = async () => {
    dispatch(setLoaderState(true));
    const vendorList = selectedFilterData.hasOwnProperty("Vendors")
      ? selectedFilterData?.Vendors
      : [];
    const responseAction = await dispatch(
      billPaymentList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: vendorList,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setBillPaymentData(response.payments_made);
        let length = response.payments_made?.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Payment Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Payment Number",
        accessor: selectedColumns.includes("Payment Number")
          ? "show.paymentNumber"
          : "Payment Number",
        sort: false,
        sortOrder: "",
        className: "pay_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Vendor Name",
        accessor: "show.vendorName",
        sort: false,
        sortOrder: "",
        className: "vend_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips placement="bottom" title={value}>
              <span className={"d-block ellipsis"}>{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Bill Number",
        accessor: selectedColumns.includes("Bill Number")
          ? "show.billNumber"
          : "Bill Number",
        sort: false,
        sortOrder: "",
        className: "bill_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? <Popover values={value} tag={false} /> : "-";
        },
      },
      {
        Header: "Amount Paid",
        accessor: "show.amountPaid",
        sort: false,
        sortOrder: "",
        className: "amt_paid",
      },
      {
        Header: "Amount in Excess",
        accessor: selectedColumns.includes("Amount in Excess")
          ? "show.amountInExcess"
          : "Amount in Excess",
        sort: false,
        sortOrder: "",
        className: "amt_excess",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "pay_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Paid Through",
        accessor: selectedColumns.includes("Paid Through")
          ? "show.paidThrough"
          : "Paid Through",
        sort: false,
        sortOrder: "",
        className: "pay_through",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Payments Made"
            tableColumns={selectedColumns}
            customizeColumnValues={paymentMadeColumnValues}
            mandatoryColumns={paymentMadeMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Payments Made",
                paymentMadeDefaultValues,
                paymentMadeMandatoryValues
              );
              fetchBillPaymentList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!paymentsMadeRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id)}
              onClickDelete={() => {
                props.deleteListData(
                  value.id,
                  value.listName,
                  value.status,
                  value.isUnpaidBillExist
                );
              }}
            />
          );
        },
      },
    ],
    [
      dateSortOrder,
      selectedColumns,
      hiddenColumns,
      currentUserInfo.organization_id,
    ]
  );

  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  /**
   * set table row data
   */
  const data = billPaymentData
    ? billPaymentData?.map((paymentMade, index) => ({
        show: {
          date: DateFormatHandler(paymentMade.payment_date),
          paymentNumber: joinTransactionNumber(paymentMade.payment_number),
          referenceNumber: paymentMade.reference_number,
          vendorName: paymentMade.vendor_name,
          billNumber: paymentMade.bill_number.map((bill) => {
            return { name: joinTransactionNumber(bill) };
          }),
          status: paymentMade.pm_status,
          amountPaid:
            getCurrencyCode(paymentMade.currency_code) +
            " " +
            NumberFormat(
              Number(paymentMade.amount_paid),
              paymentMade.currency_code,
              orgCurrencyList
            ),
          amountInExcess:
            getCurrencyCode(paymentMade.currency_code) +
            " " +
            NumberFormat(
              Number(paymentMade.amount_excess),
              paymentMade.currency_code,
              orgCurrencyList
            ),
          paidThrough: paymentMade.paid_through,
          organization: paymentMade.organization,
          actions: {
            id: paymentMade.id,
            status: paymentMade.pm_status,
            listName: paymentMade.payment_number,
            emailTo: paymentMade.email_to,
            isUnpaidBillExist: paymentMade.unpaid_bill_lines_exist,
            organizationId: paymentMade.organization_id,
            isLocked: paymentMade.is_locked,
            lockDate: paymentMade.lock_date,
            is_fiscal_closed: paymentMade.is_fiscal_closed,
          },
        },
      }))
    : [];

  /**
   * Set the contact email in a state of selected invoice
   */
  const fetchContactEmail = (id: number, emailList: any[]) => {
    let emails = emailList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setBillPaymentId(id);
  };

  const downloadPdf = async (paymentMadeId: number) => {
    const responseAction = await dispatch(
      getPaymentMadePdfLink({
        paymentMadeId: paymentMadeId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          paymentMadeId + ".pdf"
        );
        if (result) {
          toast.success("Payment made bill downloaded successfully!", {
            toastId: "payment-made-bill-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };

  const sortHandler = (column: string) => {
    if (column === "Payment Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendPaymentMadeData({
        paymentMadeId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchBillPaymentList();
      }
    }
    return responseAction;
  };

  return (
    <>
      <PMTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        paymentsMadeRolePermission={paymentsMadeRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={billPaymentId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(PMTableContainer));
