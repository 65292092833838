import React, { Children, useEffect, useRef, useState } from "react";
import "./DropdownWithActions.css";
import Edit from "../kebabMenu/Edit";
import { EditIconImg } from "../../../../assets/images";
import { DeleteIcon } from "../customSvgIcons";
import { colors } from "../../../constants/colors";
import { useAppSelector } from "../../../../app/hooks";
import {
  agingCustomizationsSelector,
  setReportCustomizationFilters,
} from "../../../home/reports/ReportSlice";
import useCommonData from "../../../hooks/useCommon";
import { ObjectType } from "../../../../types";

type Props = {
  handleReport: (type: string, arg?: { [key: string]: any }) => void | null;
  children: (
    handleToggleDropdown: (show: boolean) => void,
    option: ObjectType
  ) => React.ReactNode;
};
// type Props = {
//   children: (handleToggleDropdown: any) => Element;
// };
function DropdownWithActions(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ObjectType>({});
  const agingcustomizations = useAppSelector(agingCustomizationsSelector);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Add event listener to close the dropdown when clicking outside of it
    function handleClickOutside(event: any) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !document.getElementById("sidemodal-content")?.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedOption).length) {
      dispatch(
        setReportCustomizationFilters({
          agingReportFilters: {
            ...selectedOption.filters,
            name: selectedOption.name,
            id: selectedOption.id,
          },
        })
      );
      props.handleReport("submit", { ...selectedOption.filters });
    }
  }, [selectedOption]);

  const handleEdit = (option: any) => {
    // Implement edit logic here for the selected option
    alert(`Edit ${option.label}`);
  };

  const handleDelete = (option: any) => {
    // Implement delete logic here for the selected option
    alert(`Delete ${option.label}`);
  };

  const handleOptionClick = (option: any) => {
    // event.stopPropagation();
    setSelectedOption(JSON.parse(JSON.stringify(option)));
    setShowDropdown(false); // Close the dropdown when an option is clicked
  };

  const handleToggleDropdown = (value: boolean) => {
    var element = document.querySelector(".dropdown-options") as HTMLElement;
    if (element) {
      element.style.display = "none";
    }
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className={`dropdown-toggle ${showDropdown ? "active" : ""}`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Saved Filters
      </div>
      {showDropdown && (
        <div className="dropdown-menu dropdown-options">
          <div className="dropdown-heading">Saved Customizations</div>
          {agingcustomizations.map((option) => (
            <div key={option.name} className="dropdown-option">
              <div
                className="option-content"
                onClick={(e) => handleOptionClick(option)}
              >
                {option.name}
              </div>
              <div className="option-actions">
                {props.children(handleToggleDropdown, option)}
              </div>
            </div>
          ))}
          {agingcustomizations.length === 0 && (
            <div className="dropdown-option">
              <div className="option-content">No saved filters found</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DropdownWithActions;
