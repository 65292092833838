import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { deleteRIDetails } from "../recurringInvoiceSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteRInvoiceId: number;
  deleteRIProfileName: string;
  refreshRInvoices: (action: string) => void;
  organizationId: number;
};
const RIDelete = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteRoleResponseAction = await dispatch(
        deleteRIDetails({
        deleteRInvoiceId: props.deleteRInvoiceId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length && !("error" in deleteRoleResponse)) {
        props.refreshRInvoices("delete");
        $(".close-btn").click();
        toast.success("Recurring Invoice deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete Recurring Invoice!"
      subHeading="Are you sure you want to delete the recurring invoice"
      transactionName={joinTransactionNumber(props.deleteRIProfileName)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default RIDelete;