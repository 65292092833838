import { createContext, useContext, useEffect, useState } from "react";
import { ObjectType } from "../../../../../types";
import { fetchAccountsGlCode } from "../../../../home/chartOfAccounts/coaSlice";
import useCommonData from "../../../../hooks/useCommon";
import "./Discount.css";
import { DiscountProviderProps } from "./types";

const DiscountContext = createContext({
  discountAccounts: [] as ObjectType[],
});

export const DiscountProvider = (props: DiscountProviderProps) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const [discountAccounts, setDiscountAccounts] = useState<ObjectType[]>([]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getDiscountAccounts(signal);
    return () => {
      controller.abort();
    };
  }, [currentUserInfo.organization_id]);

  const getDiscountAccounts = async (signal?: AbortSignal) => {
    const responseAction = await dispatch(
      fetchAccountsGlCode({
        accountSearchTerm: "",
        baseAccount: props.baseAccount ? props.baseAccount : "",
        orgId: currentUserInfo.organization_id,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let accounts: ObjectType[];
        if (props.currencyFilter) {
          accounts = response.account_list.filter((account: ObjectType) => {
            return (
              Number(account.currency_id) === Number(props.currencyFilter) ||
              account.currency_id === null
            );
          });
        } else {
          accounts = response.account_list;
        }
        setDiscountAccounts(accounts);
      }
    }
  };
  return (
    <DiscountContext.Provider
      value={{
        discountAccounts: discountAccounts,
      }}
    >
      {props.children}
    </DiscountContext.Provider>
  );
};
export const useDiscount = () => {
  return useContext(DiscountContext);
};
