import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import { Card } from '../htmlTags/Card/Card';
import { CardHeader } from '../htmlTags/Card/CardHeader';
import CloseButton from '../button/CloseButton';
import { CardBody } from '../htmlTags/Card/CardBody';
import './confirmationModal.css'
type ConfirmationModalProps = {
  onCancel: () => void;
  onSubmit: () => void;
  alertModalOpen: boolean;
  headerMessage: string;
  bodyMessage: string;
};

export const ConfirmationAlertModal = (
  props: ConfirmationModalProps
) => {
  return (
    <Dialog
      open={props.alertModalOpen}
      className={`confirmation-message-modal`}
      onClose={() => {
        props.onCancel()
      }
      }
    >
      <Card wrapperClass="confirmation-modal-card">
        <CardHeader wrapperClass={`confirmation-modal-card-header-wrapper`}>
          <Grid container>
            <Grid item xs={10} className="p-t-15 header">
              {props.headerMessage}
            </Grid>
            <Grid item xs={2} className="popover-close-button">
              <CloseButton closeModalHandler={() => {
                props.onCancel()
              }} />
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
          <div className="row custom-row">
            <div className="p-r-l-20">
                {props.bodyMessage + ' ?'}
            </div>
            </div>
            <div className="row mb-0">
          <div className="col">
          <div
            className={`form-button-wrapper w-100 d-flex justify-content-end mt-3`}
            id="form-btn"
          >
            <button onClick={(e: any) => {
              e.preventDefault();
              props.onSubmit()
            }} className="save-button">
              Yes Proceed
            </button>
            <button
              type="button"
              onClick={() => {
                props.onCancel()
              }}
              className="text-decoration-none cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
        </CardBody>
      </Card>
    </Dialog>
  )
};