import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "../home/components/header/Header";
import Sidebar from "./components/sidemenu/sideBar";
import "./Home.css";
import { SideBarLinks } from "./components/sidemenu/sideBarLinks";
import { SettingsSidebarLinks } from "./components/sidemenu/settingsSidebarLinks";
import BackButton from "../common/components/BackButton";
import { useAppSelector } from "../../app/hooks";
import {
  currentUserSelector,
  loaderStateSelector,
} from "../common/commonSlice";
import Loader from "./components/Loader";
import useToast from "../hooks/useToast";


function Home() {
  const [path, setPath] = useState("");
  const location = useLocation();
  const [isSideBarExpanded, setSidebarExpanded] = useState(true);
  const navigate = useNavigate();
  const { clearToast } = useToast();

  useEffect(() => {
    setPath(location.pathname);
    clearToast('error');
    sessionStorage.setItem("lastLocation", location.pathname);
  }, [location.pathname]);
  useEffect(() => {});
  const sidebarHandler = (isExpanded: boolean) => {
    setSidebarExpanded(isExpanded);
  };

  const currentUserInfo = useAppSelector(currentUserSelector);
  const SettingSideBars = SettingsSidebarLinks(currentUserInfo);
  const SideBars = SideBarLinks();
  return (
    <section className="h-100 gradient-form">
      <div className="w-100 h-100">
        <Header />
        <div className="container-fluid content-body">
          <ToastContainer style={{ paddingTop: "82px" }} />
          {!currentUserInfo.multi_org_null_last_login && (
            <div className="row flex-nowrap no-gutters">
              {path.includes("/settings") ? (
                <Sidebar
                  menuList={SettingSideBars}
                  headerComponent={
                    <div className="sidebar-header">
                      <BackButton onClick={() => navigate("/")} />
                      <h2 className="fw-bold m-0">Settings</h2>
                    </div>
                  }
                  wrapperClass="settings-sidebar"
                />
              ) : (
                <Sidebar sidebarHandler={sidebarHandler} menuList={SideBars} />
              )}
              <div
                className={`main col w-100 ${
                  isSideBarExpanded ? "expanded-submenu" : ""
                }`}
              >
                <Outlet />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Home;
