import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { debitNoteList, deleteDebitNote } from "../debitNoteSlice";
import DeleteModal from "../../../common/components/DeleteModal";
import { useEffect, useRef } from "react";

type Props = {
  deleteDebitNoteId: number;
  deleteDebitItemId: string;
  debitNoteStatus: string;
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  refreshDebitNotes: (action: string) => void;
  organizationId: number;
};
const DeleteDebitNote = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deleteDebitNoteId]);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deleteDebitNote({
          debitNoteId: props.deleteDebitNoteId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (Object.keys(deleteRoleResponse).length) {
          props.refreshDebitNotes("delete");
          isDeletedRef.current = true;

          $(".close-btn").click();
          await dispatch(
            debitNoteList({
              page: props.page,
              itemsPerPage: props.itemsPerPage,
              dateSortOrder: props.dateSortOrder,
              orgId: props.organizationId,
              orgIds: [],
              vendorList: [],
              statusList: [],
            })
          );
          toast.success("Debit note deleted successfully!", {
            toastId: "debit-note-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          $(".close-btn").click();
          // toast.error("Delete debit note action failed!", {
          //   icon: ({ theme, type }) => (
          //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
          //   ),
          //   toastId: "delete-debit-note-failed-error",
          //   closeButton: false,
          //   className: "toast-error",
          //   position: "top-center",
          // });
        }
      }
    }
  };
  let deleteBody =
    props.debitNoteStatus === "Draft" ? (
      <>
        Are you sure you want to delete the debit note{" "}
        <strong>{joinTransactionNumber(props.deleteDebitItemId)}</strong> in
        draft ?
      </>
    ) : (
      <>
        Are you sure you want to delete the debit note{" "}
        <strong>{joinTransactionNumber(props.deleteDebitItemId)}</strong>? On
        deleting this transaction all the associated bills allocations will be
        reverted.
      </>
    );
  return (
    <DeleteModal
      heading=" Delete debit note!"
      subHeading={deleteBody}
      transactionName=""
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteDebitNote;
