import "./tableInfo.css";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import { Grid } from "@mui/material";

type Props = {
  nextInvoiceAmount: string;
  unpaidAmount: string;
  nextIncoiceDate: string;
  recurringInvoiceStatus: string;
};
export default function TableInfo(props: Props) {
  return (
    <Grid container spacing={2} className="child-invoice-container">
      <Grid item xs={6} className="ri_table_info_grid">
        {["cancelled", "expired", "stopped", "non_renewing"].includes(
          props.recurringInvoiceStatus.toLowerCase()
        ) ? null : props.recurringInvoiceStatus && props.nextIncoiceDate ? (
          <div className="child_table_info">
            <img src={info} id="message-img" alt="clone-alt" />
            {`Next Invoice for ${props.nextInvoiceAmount} will be created on ${
              props.nextIncoiceDate ? props.nextIncoiceDate : ""
            }`}
          </div>
        ) : null}
      </Grid>
      <Grid item xs={6} className="child-data-info">
        {`Unpaid Invoices: ${props.unpaidAmount}`}
      </Grid>
    </Grid>
  );
}
