import React from "react";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { TaxRateFormContainer } from "../taxRateForm/TaxRateFormContainer";
import CreateButton from "../../../../common/components/CreateButton";
type Props = {
  refreshList: () => void;
};
const ListHeader = (props: Props) => {
  const { taxConfigurationPermission } = usePermissionHandler();
  return (
    <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
      <h1>All Taxes</h1>
      {taxConfigurationPermission.includes("Create") ? (
        <div className="top-action-items-wrapper">
          <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
            <li className="nav-item">
              <TaxRateFormContainer
                taxId={-1}
                refreshList={props.refreshList}
                isAccountDeleted={false}
              >
                <CreateButton label="Add New Tax" onClick={() => {}} />
              </TaxRateFormContainer>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default ListHeader;
