import React from "react";
import DownloadPdf from "../../../../common/components/kebabMenu/DownloadPdf";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../common/components/kebabMenu/Edit";
import SendEmail from "../../../../common/components/kebabMenu/SendEmail";
import Delete from "../../../../common/components/Delete";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  value: any;
  onClickSendMail: () => void;
  onClickDownloadPdf: () => void;
  onClickDelete: () => void;
};
function ListActions({
  value,
  onClickSendMail,
  onClickDownloadPdf,
  onClickDelete,
}: Props) {
  const { debitNoteRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {debitNoteRolePermission.includes("Update") ? (
        <li>
          <Edit
            url={`/debit-note/edit/${value.id}`}
            isMutable={value.isMutable}
            name="Debit Note"
            isLocked={value.isLocked}
            className={"dropdown-item"}
            lockDate={value.lockDate}
            isFiscalClosed={value.is_fiscal_closed}
            isVoid={value.status === "Void"}
          />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <li>
          <SendEmail onClick={onClickSendMail} />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <li>
          <DownloadPdf onClick={onClickDownloadPdf} />
        </li>
      ) : null}
      {debitNoteRolePermission.includes("Delete") ? (
        <li>
          <Delete
            className={"dropdown-item delete"}
            deleteIcon={true}
            isMutable={true}
            onClick={onClickDelete}
            isLocked={value.isLocked}
            lockDate={value.lockDate}
            name="Debit Note"
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default ListActions;
