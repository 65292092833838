import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from "react";
import { Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { listboxCustomStyle } from "../../../../../common/components/ListBoxCustomStyle";
import { TDSList } from "../../../../../../types";
import TableInfo from "../../../../../common/components/table/TableInfo";
import ItemTable from "./TdsTable";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Delete from "../../../../../common/components/Delete";
import useCommonData from "../../../../../hooks/useCommon";
import { setLoaderState } from "../../../../../common/commonSlice";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useListData from "../../../../../hooks/useListData";
import { tdsList } from "../../../tdsSlice";
import TdsFormContainer from "../../tdsForm/TdsFormContainer";

const TdsTableContainer = (
  {
    deleteListData,
    refreshList
  }: {
    deleteListData: (tdsId: number, taxName: string) => void;
    refreshList: () => void;
  },
  ref: any
) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const [tdsData, setTdsData] = useState<TDSList[]>([]);
  const paginationRef = useRef({
    page: page,
    itemPerPage: itemsPerPage,
  });
  useEffect(() => {
    paginationRef.current.page = page;
  }, [page]);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let tdsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TDS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  useImperativeHandle(
    ref,
    () => ({
      fetchTdsList: fetchTdsList,
    }),
    [page]
  );
  useEffect(() => {
    fetchTdsList();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);

  const fetchTdsList = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      tdsList({
        page: paginationRef.current.page,
        itemsPerPage: paginationRef.current.itemPerPage,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTdsData(response.tds_tax);
        let length = response.tds_tax.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      }
      dispatch(setLoaderState(false));
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Tax Name",
        accessor: "show.taxName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
      },
      {
        Header: "Rate(%)",
        accessor: "show.rate",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Section",
        accessor: "show.section",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return tdsPermission.includes("Update") ? (
            <KebabMenu>
              {tdsPermission.includes("Update") && (
                <li>
                  <TdsFormContainer
                    isEdit={true}
                    refreshList={fetchTdsList}
                    id={value.id}
                    createdFrom="tds_page"
                  />
                </li>
              )}
              {tdsPermission.includes("Delete") && (
                <li>
                  <Delete
                    className={"dropdown-item delete"}
                    deleteIcon={true}
                    isMutable={true}
                    onClick={() => deleteListData(value.id, value.taxName)}
                    isLocked={false}
                    lockDate={""}
                    name="Items"
                  />
                </li>
              )}
            </KebabMenu>
          ) : (
            <></>
          );
        },
      },
    ],
    [currentUserInfo.organization_id, page, itemsPerPage]
  );
  /**
   * set table row data
   */
  const data = tdsData
    ? tdsData.map((item) => ({
        show: {
          taxName: item.tax_name,
          rate: item.rate ? item.rate + "%" : "-",
          section: item.section,
          actions: {
            id: item.id,
            isMutable: true,
            tdsData: item,
            taxName: item.tax_name,
          },
        },
      }))
    : [];

  return (
    <>
      <Card variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              paginationRef.current = {
                page: page,
                itemPerPage: countPerPage,
              };
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />
          <Grid>
            <ItemTable columns={columns} data={data} refreshList={refreshList}/>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default React.memo(forwardRef(TdsTableContainer));
