import { ItemValue, PricingListData, RangePriceValue } from "../features/home/plan/planType"
import { NumberFormat } from "./numberFormatHelper"
import { pricingDefaultFrequency } from "./tableCustomizationHelper"
import { defaultFrequencyCol } from "../features/home/plan/PlanStateInitialization"
export const NON_PRICE_RANGE_PRICING_MODEL = ["FLAT_FEE", "PER_UNIT"]
export const PRICE_RANGE_PRICING_MODEL = ["TIERED", "VOLUME", "STAIRSTEP"]
export const pricingModuleRenderValue = [
  {key: "FLAT_FEE", value: "Flat Fee"},
  {key: "PER_UNIT", value: "Per Unit"},
  {key: "TIERED", value: "Tiered"},
  {key: "VOLUME", value: "Volume"},
  {key: "STAIRSTEP", value: "Stair Step"}
]
export const getPricingItemArray = (pricingModel: string, pricing: ItemValue, orgCurrencyList: any[], currencyCode: string) => {
    return {formatedPrice: PRICE_RANGE_PRICING_MODEL.includes(pricingModel)
              ? pricing?.range_price?.map((range: any) => `${currencyCode || ''} ${NumberFormat(
                  range.price_per_step || 0,
                  currencyCode || '',
                  orgCurrencyList
                )}`) || ''
              : [ `${currencyCode || '' } ${NumberFormat(
                pricing?.price || 0,
                currencyCode || '',
                orgCurrencyList
              )}`],
            actualPrice: PRICE_RANGE_PRICING_MODEL.includes(pricingModel) ? pricing?.range_price?.map((range: any) => `${Number(range.price_per_step)}` || 0): [`${Number(pricing?.price)}`]
            }
}

export const getPricingToUnitsArray = (pricingModel: string, pricing: ItemValue) => {
    return PRICE_RANGE_PRICING_MODEL.includes(pricingModel)
    ? pricing.range_price && pricing.range_price.map((range: any) => !range.range_to || range.range_to === -1  ? '& Above' : range.range_to)
    : ['']
}

export const getPricingFromUnitsArray = (pricingModel: string, pricing: ItemValue) => {
    return PRICE_RANGE_PRICING_MODEL.includes(pricingModel)
    ? pricing.range_price && pricing.range_price.map((range: any) => range?.range_from || (range?.range_from === 0 ? '0' : ''))
    : ['']
}

const rangePriceValueConcat = (rangeData: RangePriceValue[], orgCurrencyList: any[], currencyCode: string) => (rangeData
&& rangeData.length > 0
&& rangeData.map((data: RangePriceValue) => `${data.range_from} To ${data.range_to ? data.range_to : '& Above'}: ${currencyCode || ''} ${NumberFormat(
    data?.price_per_step || 0,
    currencyCode || '',
    orgCurrencyList
  )}`).join(",")) || ''
export const priceColumnValueConcat = (pricingModel: string, item: ItemValue[], orgCurrencyList: any[], currencyCode: string ) => {

    if (PRICE_RANGE_PRICING_MODEL.includes(pricingModel)) {
        return (item && item.length > 0 && item.map((data) => `${data.item_name } (${rangePriceValueConcat(data?.range_price || [], orgCurrencyList, currencyCode)})`).join(",")) || ''
    }
    else if (NON_PRICE_RANGE_PRICING_MODEL.includes(pricingModel)) {
        return  (item && item.length > 0 && item.map((data) => `${data.item_name} ( ${currencyCode || ''} ${NumberFormat(
            data?.price || 0,
            currencyCode || '',
            orgCurrencyList
          )})`).join(",")) || ''
    }
  return ''
}

export const coustomCase = (value: string | undefined) =>  value ? (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()) : ''
export const coustomCaseWithRemoveUnderscore = (value: string | undefined) =>  value ? (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).replace(/_/g, ' ') : ''

export const getModifiedPricingValue = (pricingList: PricingListData[]) => {
  return pricingList && pricingList.length > 0
  ? pricingDefaultFrequency.map(
    (data: string) =>  pricingList.find(
      (eachFre: any) => eachFre.frequency === data)
      ? { ...pricingList.find((eachFre: PricingListData) => eachFre.frequency === data.toString()), isPriceSet: true}
      : {...defaultFrequencyCol, frequency: data}) : []
}

export const getDefaultPricingTableData = () => pricingDefaultFrequency.map((data: string) =>  ({...defaultFrequencyCol, frequency: data}))