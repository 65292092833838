import React, { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { Transactionlimit } from "../../../../../types";
import {
  customerSuggestions,
  getTransactionLimitStatus,
} from "../../customerSlice";
import "../../Customers.css";
import "./customerList.css";
import DeleteCustomer from "../Delete";
import { reportOrgSelector } from "../../../../common/commonSlice";
import Pagination from "../../../../common/components/table/Pagination";
import NotificationModal from "../../../../common/components/NotificationModal";
import { addressReset } from "../customerForm/AddressDetails/addressSlice";
import useListData from "../../../../hooks/useListData";
import CustomerTableContainer from "./customerTable/CustomerTableContainer";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import ListHeader from "../../../../common/components/list/ListHeader";
import { message } from "../../../../constants/messages";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";

function CustomersList() {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { customerRolePermission } = usePermissionHandler();
  const [customerName, setCustomerName] = useState("");
  const [deleteCustomerId, setDeleteCustomerId] = useState(-1);
  const [deleteCustomerName, setDeleteCustomerName] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const orgList = useAppSelector(reportOrgSelector);
  const orgID = useRef(currentUserInfo.organization_id);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);
  sessionStorage.removeItem("activeCustomerTab");

  useEffect(() => {
    setCustomerName("");
    fetchTransactionLimit();
    setTotalResults(0);
    setIsFilterApplied(false);
    orgID.current = currentUserInfo.organization_id;
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    dispatch(addressReset());
  }, []);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Fetch search suggestions
   */
  const searchSuggestions = async (value: string, e: any) => {
    setIsSearch(true);
    const customerSearch = value;
    if (e.keyCode !== 13) {
      if (customerSearch.length > 2) {
        const responseAction = await dispatch(
          customerSuggestions({
            customerName: customerSearch.trim(),
            orgId: orgID.current,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            setTotalResults(response.customer_names.length);
            setIsSearch(false);
            return response.customer_names;
          }
        }
      } else {
        return [];
      }
    }
  };
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * Set delete data details
   */
  const deleteListData = (id: number, listName: string) => {
    setDeleteCustomerId(id);
    setDeleteCustomerName(listName);
  };

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  const refreshList = () => {
    filterRef.current.refreshFilter();
    setCustomerName("");
    listRef.current.fetchCustomerList && listRef.current.fetchCustomerList();
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Customers"
          haveSearchBox={true}
          totalResults={totalResults}
          isSearch={isSearch}
          resetInput={() => setCustomerName("")}
          searchType="Customer"
          searchPlaceholder={"Search for customers"}
          searchSuggestions={searchSuggestions}
          onSearchSelect={(value) => setCustomerName(value)}
          setTotalResults={(value) => setTotalResults(value)}
          haveCreateButton={true}
          createButtonLabel="Add Customer"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/customers/create");
          }}
          haveFilter={orgList.length > 1 ? true : false}
          applyFilter={handleApplyFilter}
          roles={customerRolePermission}
          ref={filterRef}
          isFiscalYearFilterRequired={false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <CustomerTableContainer
            customerName={customerName}
            transactionLimit={transactionLimit}
            initializeFilter={refreshList}
            isFilterApplied={isFilterApplied}
            setTotalResults={(value) => setTotalResults(value)}
            deleteListData={deleteListData}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteCustomer
        deleteCustomerId={deleteCustomerId}
        deleteCustomerName={deleteCustomerName}
        page={page}
        itemsPerPage={itemsPerPage}
        customerName={customerName}
        refreshList={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <NotificationModal
        modalHeader={message("customer").unableToDeleteTitle}
        modalBody={message("customer").unableToDeleteText}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
}

export default CustomersList;
