import {
  Response,
  TransformedResponse,
  TransformedTag
} from "../types";

export const getJVTableHeader = () => {
  return [
    {
      Header: "Account",
      accessor: "show.account_name",
      className: "rt-account",
    },
    {
      Header: "Tags",
      accessor: "show.tags",
      className: "rt-tag",
    },
    {
      Header: "Debit",
      accessor: "show.debit",
      className: "rt-debit",
    },
    {
      Header: "Credit",
      accessor: "show.credit",
      className: "rt-credit",
    },
  ];
};

export const getUpdatedJournals = (
  journals: any,
  accountId: number,
  splitType: string,
  tagsList: any,
  index?: number | null
) => {
  let options_list = tagsList.map(
    (tag: { option_id: number; amount: number }) => {
      return {
        option_id: tag.option_id,
        amount: Number(tag.amount),
      };
    }
  );
  let newJournal = [...journals] as any;
  const journalIndex = index ? index : 0;
  newJournal[journalIndex].split_type = splitType;
  newJournal[journalIndex].options_list = options_list;
  return newJournal;
};

export const filterTransactionListPayload = (
  response: Response
): TransformedResponse => {
  const result: TransformedResponse = {
    reporting_tag_list: [],
  };
  for (const key in response) {
    const transactionList = response[key].transaction_list;

    transactionList.forEach((transaction) => {
      if (
        transaction.reporting_tag_list &&
        transaction.reporting_tag_list.length > 0
      ) {
        transaction.reporting_tag_list.forEach((tag) => {
          // Mapping tag to the desired structure
          const transformedTag: TransformedTag = {
            entity_id: transaction.entity_id,
            entity_type: transaction.entity_type,
            transaction_amount: transaction.transaction_amount,
            transaction_type: transaction.transaction_type,
            account_id: transaction.account_id,
            account_name: transaction.account_name,
            split_type: tag.split_type,
            tag_amount: tag.tag_amount,
            reporting_tag_name: tag.reporting_tag_name,
            reporting_tag_id: tag.reporting_tag_id,
            options_list: tag.option_list.map((option) => ({
              tag_name: option.tag_name,
              option_name: option.option_name,
              option_id: option.option_id,
              amount: option.amount,
            })),
          };

          result.reporting_tag_list.push(transformedTag);
        });
      }
    });
  }

  return result;
};
export const autoAllocateTagsPayloadFilter = (response: any): any => {
  const transformedPayload: any = {
    reporting_tag_list: [],
  };

  Object.values(response).forEach((entity: any) => {
    entity.reporting_tag_list.forEach((tag: any) => {
      const reportingTag: any = {
        account_id: entity.account_id,
        entity_id: entity.entity_id,
        entity_type: entity.entity_type,
        transaction_amount: entity.transaction_amount,
        tag_amount: tag.tag_amount,
        split_type: tag.split_type,
        reporting_tag_id: tag.reporting_tag_id,
        options_list: tag.option_list.map((option: any) => ({
          option_id: option.option_id,
          amount: option.amount,
        })),
      };

      transformedPayload.reporting_tag_list.push(reportingTag);
    });
  });

  return transformedPayload;
};
