import React from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import CreateItem from "./CreateItem";

function CreateItemContainer() {
  const { editId } = useParams();
  return (
    <Card wrapperClass="add-item-card">
      <CardHeader transactionNumber={editId} isBackButtonEnabled={true}>
        {editId ? "Edit Item" : "Create Item"}
      </CardHeader>
      <CardBody>
        <div className="addItem-form-wrapping">
            <CreateItem />
        </div>
      </CardBody>
    </Card>
  );
}

export default CreateItemContainer;
