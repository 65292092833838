import { useState } from "react";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { InvoiceDetailValues } from "../../../../../../types";
import { ModalContext } from "../../../../../common/components/centerModal/centerModalContext";
import { ApplyCreditsForm } from "./ApplyCreditsForm";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { ErrorToaster } from "../../../../../../helpers/toastHelper";
import { message } from "../../../../../constants/messages";
import CenterModal from "../../../../../common/components/centerModal/CenterModal";
import ErrorInfo from "../../../../../common/components/ErrorInfo";
import useCommonData from "../../../../../hooks/useCommon";
import "./ApplyCredits.css";

type Props = {
  invoiceData: InvoiceDetailValues;
  refreshInvoiceDetails: () => void;
  isVoid: boolean;
};
export const ApplyCreditsFormModal = (props: Props) => {
  const [isAmountError, setAmountError] = useState(false);
  const { orgCurrencyList } = useCommonData();
  const getIsError = (isErrorExist: boolean) => {
    setAmountError(isErrorExist);
  };

  const handleDisabledAction = () => {
    ErrorToaster(message().invoice.applyCreditVoidInvoice);
  };

  return (
    <CenterModal
      modalTitle={`Apply Credit for ${joinTransactionNumber(
        props.invoiceData.invoice_number
      )}`}
      modalTitleComponent={
        <div>
          <h6 className="balance-amt">
            {"Balance:" +
              " " +
              props.invoiceData.currency_code +
              " " +
              NumberFormat(
                Number(props.invoiceData.total_details.balance_due),
                props.invoiceData.currency_code,
                orgCurrencyList
              )}
          </h6>
          {isAmountError ? (
            <ErrorInfo message="The credits applied is more than the invoice balance." />
          ) : null}
        </div>
      }
      wrapperClass="invoice-credit-allocation-modal"
      modalTitleClass="flex-column align-items-start"
      isCloseButtonEnabled={true}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <ApplyCreditsForm
                conversionId={Number(props.invoiceData.conversion_id)}
                currencyCode={props.invoiceData.currency_code || ""}
                currencyId={Number(props.invoiceData.currency_id)}
                onClose={onCloseModal}
                balance={Number(props.invoiceData.total_details.balance_due)}
                getIsError={getIsError}
                refreshInvoiceDetails={props.refreshInvoiceDetails}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<span>Apply Now</span>}
      buttonClass="cancel-button"
      isButtonDisabled={props.isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
};
