import { SvgIconProp } from "../../../../types";

export const ReactiveIconBtn = (props: SvgIconProp) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path 
                id="Shape" 
                fillRule={"nonzero"}
                clip-rule="evenodd" 
                d="M9 1.50001C7.19924 1.4975 5.45817 2.1455 4.09725 3.32476L4.845 4.67101C6.57968 3.00586 9.14093 2.53716 11.3529 3.4801C13.5648 4.42304 15.0002 6.59546 15 9.00001H12.75L15.1425 13.305C15.9975 12.087 16.5 10.602 16.5 9.00001C16.5 4.85776 13.1423 1.50001 9 1.50001ZM9 16.5C10.8008 16.5025 12.5418 15.8545 13.9028 14.6753L13.155 13.329C11.4203 14.9942 8.85907 15.4629 6.64712 14.5199C4.43516 13.577 2.9998 11.4046 3 9.00001H5.25L2.8575 4.69501C2.0025 5.91301 1.5 7.39801 1.5 9.00001C1.5 13.1423 4.85775 16.5 9 16.5Z"
                fill="#747D84" />
            </g>
        </svg>
    );
};
