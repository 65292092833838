import React, { forwardRef } from "react";
import { FiscalYearSelect } from "../fiscalYearSelect/FiscalYearSelect";
import {
  FiltersType,
  ObjectType,
  SelectedFiltersType,
} from "../../../../types";
import ListFilter from "./listFilter/ListFilter";
import SearchSuggestionBox from "../SearchSuggestionBox";
import CreateButton from "../CreateButton";
import useFilterData from "./listFilter/useFilterData";
import "./ListHeader.css";
import SystemVoucherConfigurationListModal from "../../../home/systemVoucher/components/systemVoucherConfigurationList/systemVoucherConfigurationListModal";

type Props = {
  title: string;
  haveCreateButton: boolean;
  createButtonLabel: string;
  roles: any;
  haveSearchBox?: boolean;
  totalResults?: number;
  isSearch?: boolean;
  searchType?: string;
  searchPlaceholder?: string;
  haveFilter: boolean;
  initialFilterObject?: ObjectType;
  filterType?: string;
  wrapperClass?: string;
  isFiscalYearFilterRequired?: boolean;
  module?: string;
  filters?: FiltersType[];
  isSystemVoucher?: boolean;
  createOnClick: () => void;
  resetInput?: () => void;
  onSearchSelect?: (value: string) => void;
  searchSuggestions?: (value: string, e: any) => Promise<any>;
  setTotalResults?: (value: number) => void;
  applyFilter?: (value: boolean) => void;
  onFiltersSelected?: (selectedFilters: SelectedFiltersType) => void;
};
function ListHeader(
  {
    title,
    haveCreateButton,
    createButtonLabel,
    roles,
    haveSearchBox,
    totalResults,
    isSearch,
    searchType,
    searchPlaceholder,
    haveFilter,
    filterType,
    wrapperClass,
    isFiscalYearFilterRequired,
    module,
    isSystemVoucher,
    resetInput,
    createOnClick,
    searchSuggestions,
    onSearchSelect,
    setTotalResults,
    applyFilter,
    onFiltersSelected,
  }: Props,
  ref?: any
) {
  const { initialFilterObject, filters, filterChange } = useFilterData({
    type: filterType,
    haveFilter,
  });
  return (
    <div
      className={`${
        wrapperClass
          ? wrapperClass
          : "card-header card-user-management-header card-customer-header card-no-bg-header card-main-header"
      }`}
    >
      <h1>{title}</h1>
      <div className="top-action-items-wrapper">
        {haveSearchBox &&
        searchSuggestions &&
        onSearchSelect &&
        setTotalResults ? (
          <SearchSuggestionBox
            searchType={searchType ? searchType : ""}
            totalResults={totalResults ? totalResults : 0}
            isSearch={isSearch ? isSearch : false}
            resetInput={resetInput ? resetInput : () => {}}
            placeholder={searchPlaceholder ? searchPlaceholder : ""}
            searchSuggestions={searchSuggestions}
            onSelect={onSearchSelect}
            setTotalResults={setTotalResults}
          />
        ) : null}
        <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
          {isFiscalYearFilterRequired !== false && (
            <li className="nav-item">
              <FiscalYearSelect />
            </li>
          )}
          <li
            className={`nav-item ${
              isSystemVoucher ? "system-voucher-buttons" : ""
            }`}
          >
            {roles.includes("Create") && haveCreateButton ? (
              <CreateButton label={createButtonLabel} onClick={createOnClick} />
            ) : null}
            {isSystemVoucher && <SystemVoucherConfigurationListModal />}
            {haveFilter && initialFilterObject() && filters() ? (
              <ListFilter
                initialFilterObject={initialFilterObject()}
                filters={filters()}
                applyFilter={applyFilter}
                onFiltersSelected={filterChange}
                ref={ref}
                module={module}
              />
            ) : (
              <></>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default React.memo(forwardRef(ListHeader));
