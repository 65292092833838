import { useFormik } from "formik";
import { customSelectStyle } from "../../SelectCustomStyle";
import { FormSelectField } from "../../formSelectField/FormSelectField";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { DeleteIcon } from "../../customSvgIcons/deleteIcon";
import { colors } from "../../../../constants/colors";
import Multiselect from "multiselect-react-dropdown";
import { TagSelectedClose } from "../../../../../assets/images";
import { addFilterOptions } from "../OptionConstants";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../commonSlice";
import { fetchAccountsForFilter } from "../../../../home/chartOfAccounts/coaSlice";
import { reportTableFilterSelector } from "../../../../home/reports/ReportSlice";

type Props = {
  onDelete: (index: number) => void; // Accepts an index argument
  index: number; // Add an index prop
  value: {
    field: string;
    comparator: string;
    value: string | string[];
  };
  getFilterValues: (
    data: {
      field: string;
      comparator: string;
      value: string | string[];
    },
    index: number
  ) => void;
  selectedFields: string[];
  isError: boolean;
};
type FilterRef = {
  handleSubmit: () => Promise<void>;
  isError: boolean;
};
export const AddFilter = forwardRef(
  (props: Props, ref?: React.ForwardedRef<FilterRef>) => {
    const [fieldOptions, setFieldOptions] = useState<
      { label: string; value: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState<
      {
        value: string;
        label: string;
      }[]
    >([]);
    const dispatch = useAppDispatch();
    const currentUserInfo = useAppSelector(currentUserSelector);
    const tableFilters = useAppSelector(reportTableFilterSelector);
    const formik = useFormik({
      initialValues: { ...props.value },
      enableReinitialize: true,
      onSubmit: (values) => {},
    });
    useEffect(() => {
      props.getFilterValues(formik.values, props.index);
    }, [formik.values]);
    useEffect(() => {
      if (
        ["Transaction Type", "Account"].includes(formik.values.field) &&
        selectedTags.length > 0
      ) {
        formik.setFieldValue(
          "value",
          selectedTags.map((tags) => {
            return tags.label;
          })
        );
      }
    }, [formik.values.field, selectedTags]);
    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;

      switch (formik.values.field) {
        case "Transaction Type":
          fetchFieldValueOptions("Transaction Type", signal);
          break;
        case "Account":
          fetchFieldValueOptions("Account", signal);
          break;
        case "Account Group":
          fetchFieldValueOptions("Account Group", signal);
          break;
        default:
          setFieldOptions([]);
          break;
      }
      return () => {
        controller.abort();
      };
    }, [formik.values.field]);
    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: async () => {
          await formik.submitForm();
        },
        isError: Object.keys(formik.errors).length > 0,
      }),
      [formik]
    );
    const options = {
      field: addFilterOptions.field,
      comparator: addFilterOptions.comparator,
      field_option: fieldOptions,
    };

    const handleSelectTags = (e: any) => {
      setSelectedTags((values) => {
        return [...e];
      });
    };

    const handleRemoveTags = (e: any) => {
      setSelectedTags((values) => {
        return [...e];
      });
    };
    const fetchFieldValueOptions = async (
      field: string,
      signal: AbortSignal
    ) => {
      setIsLoading(true);
      const responseAction = await dispatch(
        fetchAccountsForFilter({
          orgId: tableFilters.organization_id
            ? tableFilters.organization_id
            : currentUserInfo.organization_id,
          field: field,
          signal: signal,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        setFieldOptions(
          response.map((option: string) => {
            return { value: option, label: option };
          })
        );
        setIsLoading(false);
      } else {
        setFieldOptions([]);
        setIsLoading(false);
      }
    };

    return (
      <div
        className={`row position-relative add-filter-section ${
          props.isError ? "add-filter-error" : ""
        }`}
        key={props.index}
        tabIndex={props.index}
      >
        <FormSelectField
          name="field"
          id="field"
          className={`form-select customize-report-select ${
            props.isError && formik.values.field === ""
              ? "add-filter-error"
              : ""
          } `}
          isDisabled={isLoading}
          isOnlyInRow={false}
          styles={customSelectStyle}
          placeholder="Select a field"
          isRequired={false}
          isSearchable={true}
          error={formik.errors.field}
          label="Select Field"
          onChange={(e: any) => {
            formik.setValues({ field: e.label, comparator: "", value: "" });
          }}
          options={options.field.filter(
            (fields: { value: string; label: string }) =>
              !props?.selectedFields?.includes(fields.label)
          )}
          value={{
            label: formik.values.field,
            value: formik.values.field,
          }}
          wrapperClass={`${
            ["Transaction Type", "Account"].includes(formik.values.field)
              ? "transaction-type-select"
              : ""
          }`}
          isClearable={false}
        />
        <FormSelectField
          name="comparator"
          id="comparator"
          className={`form-select customize-report-select ${
            props.isError && formik.values.comparator === ""
              ? "add-filter-error"
              : ""
          }`}
          isDisabled={false}
          isOnlyInRow={false}
          styles={customSelectStyle}
          placeholder="Select a comparator"
          isRequired={false}
          isSearchable={true}
          error={formik.errors.comparator}
          label="Select Comparator"
          onChange={(e: any) => formik.setFieldValue("comparator", e.label)}
          options={options.comparator}
          value={{
            label: formik.values.comparator,
            value: formik.values.comparator,
          }}
          wrapperClass={`${
            ["Transaction Type", "Account"].includes(formik.values.field)
              ? "transaction-type-select"
              : ""
          }`}
          isClearable={false}
        />
        {!["Transaction Type", "Account"].includes(formik.values.field) ? (
          <FormSelectField
            name="value"
            id="value"
            className={`form-select customize-report-select ${
              props.isError && formik.values.field === ""
                ? "add-filter-error e"
                : ""
            }`}
            isDisabled={false}
            isOnlyInRow={false}
            styles={customSelectStyle}
            placeholder={`Select ${
              formik.values.field !== ""
                ? formik.values.field.toLowerCase()
                : "an option"
            }`}
            isRequired={false}
            isSearchable={true}
            error={formik.errors.value}
            label="Additional Field"
            onChange={(e: any) => formik.setFieldValue("value", [e.label])}
            options={options.field_option}
            value={{
              label: formik.values.value[0],
              value: formik.values.value[0],
            }}
            isClearable={false}
            isLoading={isLoading}
          />
        ) : (
          <div className="form-outline trans-type-item mb-4 tag-wrapper position-relative">
            <span className="align-wrapper">
              <label className="form-label" htmlFor="tags">
                Additional Field
              </label>
            </span>
            <Multiselect
              options={options.field_option}
              id="value_transaction"
              displayValue="label"
              showCheckbox={true}
              loading={isLoading}
              emptyRecordMsg={"No Matches found"}
              className={`${
                props.isError && formik.values.value === ""
                  ? "add-filter-error"
                  : ""
              }`}
              customCloseIcon={
                <img src={TagSelectedClose} alt="tag-selected-close-img" />
              }
              placeholder={`Select ${
                formik.values.field !== ""
                  ? formik.values.field.toLowerCase() + "s"
                  : "an option"
              }`}
              onSelect={handleSelectTags}
              onRemove={handleRemoveTags}
              selectedValues={
                Array.isArray(formik.values.value)
                  ? formik.values.value.map((tags) => {
                      return {
                        label: tags,
                        value: tags,
                      };
                    })
                  : []
              }
            />
          </div>
        )}

        <button
          className="no-appearance delete-button position-absolute"
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            props.onDelete(props.index);
          }}
        >
          <DeleteIcon color={colors.tomato} />
        </button>
      </div>
    );
  }
);
