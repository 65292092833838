import CenterModal from "../../../../common/components/centerModal/CenterModal";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import { EditIconImg } from "../../../../../assets/images";
import AddAccountTreeView from "../AddAccountTreeView";
import "./changeBranchModal.css";

type Props = {
  superParentId: number;
  editAccountId: number;
  parentId: number;
  refreshList: () => void;
  isBankAccount: boolean;
  setAccountTypeHandler: (account: string) => void;
};
const ChangeBranchModal = (props: Props) => {
  return (
    <CenterModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <AddAccountTreeView
                selectedBranchHandler={() => {}}
                resetConvertedAmount={() => {}}
                selectedNode=""
                expanded={[]}
                isCreate={false}
                onCloseModal={onCloseModal}
                superParentId={props.superParentId}
                editAccountId={props.editAccountId}
                parentId={props.parentId}
                refreshList={props.refreshList}
                setAccountTypeHandler={props.setAccountTypeHandler}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<img src={EditIconImg} alt="edit-list-img" />}
      modalTitle="Change branch"
      buttonClass="no-appearance"
      wrapperClass="medium-form-modal change-account-modal"
      isInline={true}
      modalContentClass="change-branch-modal-content"
    />
  );
};

export default ChangeBranchModal;
