import React, { useEffect, useMemo, useState } from "react";
import {
  CreditNoteDetailValues,
  InvoiceToAllocate,
  ObjectType,
  TableData,
} from "../../../../../types";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import {
  creditAllocationCalculation,
  getInvoicesToAllocate,
} from "../../creditNoteSlice";
import { useParams } from "react-router-dom";
import useForexCalculation from "../../../../hooks/useForexCalculation";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import {
  DateFormatHandler,
  splitNumberByDecimalPoint,
} from "../../../../../helpers/helper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { getForexGainOrLossAmount } from "../../../../../helpers/transactionHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import InputPrefix from "../../../../common/components/formPrefixInputField/InputPrefix";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { setLoaderState } from "../../../../common/commonSlice";

export const useInvoiceAllocation = ({
  invoiceId,
  creditNoteData,
}: {
  invoiceId?: number;
  creditNoteData: CreditNoteDetailValues;
}) => {
  const { editId } = useParams();
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { subModulesWithPermission } = usePermissionHandler();
  const [invoiceToAllocate, setInvoiceToAllocate] = useState<
    InvoiceToAllocate[]
  >([]);
  const [creditBalance, setCreditBalance] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [allocatedAmount, setAllocatedAmount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isFormValues, setIsFormValues] = useState(false);
  const [allocationTableData, setAllocationTableData] = useState<TableData[]>(
    []
  );
  const { getForexGainOrLoss } = useForexCalculation();
  const [allocationForex, setAllocationForex] = useState<ObjectType[]>([]);

  useEffect(() => {
    allocationData();
    const controller = new AbortController();
    if (creditNoteData.organization_id === currentUserInfo.organization_id) {
      const signal = controller.signal;
      calculateAllocationAmount(signal);
    }
    let flag = false;
    invoiceToAllocate.map((invoiceData) => {
      if (
        invoiceData.amount_credited &&
        invoiceData.amount_credited !== "" &&
        invoiceData.amount_credited !== null &&
        invoiceData.amount_credited !== 0
      ) {
        flag = true;
      }
    });
    if (flag) {
      setIsFormValues(true);
    } else {
      setIsFormValues(false);
    }
    return () => {
      controller.abort();
    };
  }, [invoiceToAllocate, creditNoteData.currency_code]);

  useEffect(() => {
    allocationData();
  }, [allocationForex]);

  const allocationData = () => {
    setAllocationTableData(
      invoiceToAllocate
        ? invoiceToAllocate.map((invoice, index) => ({
            show: {
              invoiceNumber: joinTransactionNumber(invoice.invoice_number),
              invoiceDate: DateFormatHandler(invoice.invoice_date),
              invoiceAmount:
                creditNoteData.currency_code +
                " " +
                NumberFormat(
                  Number(invoice.invoice_amount),
                  creditNoteData.currency_code,
                  orgCurrencyList
                ),
              invoiceBalance:
                creditNoteData.currency_code +
                " " +
                NumberFormat(
                  Number(invoice.invoice_balance),
                  creditNoteData.currency_code,
                  orgCurrencyList
                ),
              amountToCredit: {
                index: index,
                amountToCredit: invoice.amount_credited,
                focus: invoice.focus,
                error: invoice.error,
              },
              forex:
                allocationForex[index] &&
                getForexGainOrLossAmount(
                  invoice.invoice_id,
                  allocationForex[index],
                  creditNoteData.currency_code
                    ? creditNoteData.currency_code
                    : "",
                  orgCurrencyList
                ),
            },
            action: {
              url: `/invoice/detail/${invoice.invoice_id}?redirect=/invoice`,
              permission: subModulesWithPermission.includes("Invoices"),
              customNavigation: true,
              type: "applyToInvoiceModal",
            },
          }))
        : []
    );
  };

  /**
   * Allocation table details
   */
  const allocationColumn = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "show.invoiceNumber",
        className: "column-navigation",
      },
      {
        Header: "Invoice Date",
        accessor: "show.invoiceDate",
        className: "",
      },
      {
        Header: "Invoice Amount",
        accessor: "show.invoiceAmount",
        className: "",
      },
      {
        Header: "Invoice Balance",
        accessor: "show.invoiceBalance",
        className: "",
      },
      {
        Header: "Amount To Credit",
        accessor: "show.amountToCredit",
        className: "",
        Cell: ({ cell: { value } }: any) => {
          return (
            <InputPrefix
              prefix={creditNoteData.currency_code}
              className="credit_note_number"
              name="amt-credit-input"
              id=""
              inputType="text"
              value={value.amountToCredit ? value.amountToCredit : ""}
              placeholder={zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  creditNoteData.currency_code
                    ? creditNoteData.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ).toString()}
              autoFocus={value.focus}
              disabled={false}
              error={value.error ? " error" : ""}
              onClick={() =>
                handleAmountToCredit(value.index, value.amountToCredit, true)
              }
              onChange={(e: any) => {
                // Restricting alphabets in amount field
                const regex = /^\d*\.?\d*$/;
                let valueStr = e.target.value.toString();
                if (!regex.test(valueStr) || valueStr === ".") {
                  return;
                }
                handleAmountToCredit(value.index, e.target.value, true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  handleItemOnBlur(
                    "amount_credited",
                    value.amountToCredit,
                    value.index
                  );
                }, 200);
              }}
            />
          );
        },
      },
      {
        Header: "Forex Gain/Loss",
        accessor: "show.forex",
        className: "",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    [allocationTableData]
  );

  const getInvoicesToAllocateCreditNote = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getInvoicesToAllocate({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
        invoiceId: invoiceId,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setInvoiceToAllocate(response.invoice_allocate_list);
        setCreditBalance(response.credit_balance);
        setRemainingAmount(response.credit_balance);
        response.invoice_allocate_list.map(
          (invoice: InvoiceToAllocate, index: number) => {
            allocationForex[index] = {
              [invoice.invoice_id]: invoice.forex_amount,
            };
          }
        );
        setAllocationForex([...allocationForex]);
      }
    }
    dispatch(setLoaderState(false));
  };

  const handleAmountToCredit = async (
    index: number,
    value = 0,
    focus: boolean
  ) => {
    invoiceToAllocate.map((invoice, invoiceIndex) => {
      if (invoiceIndex !== index) {
        if (invoice.focus === true) {
          invoiceToAllocate[invoiceIndex] = {
            ...invoiceToAllocate[invoiceIndex],
            focus: false,
          };
        }
      }
    });
    let error = false;
    let updatedValue = value.toString().includes(",")
      ? Number(value.toString().split(",").join(""))
      : value;
    if (Number(invoiceToAllocate[index].invoice_balance) < updatedValue) {
      error = true;
    }
    let tempInvAllocate = [...invoiceToAllocate];
    tempInvAllocate[index] = {
      ...tempInvAllocate[index],
      amount_credited: updatedValue,
      focus: focus,
      error: error,
    };
    setInvoiceToAllocate([...tempInvAllocate]);

    const errorData = tempInvAllocate.filter(
      (invoice) => invoice.error === true
    );
    if (errorData.length) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    let forex = await getForexGainOrLoss(
      tempInvAllocate[index].conversion_id,
      creditNoteData.conversion_id,
      updatedValue ? updatedValue : 0,
      tempInvAllocate[index].invoice_id,
      "CreditNote",
      true
    );
    allocationForex[index] = forex ? forex : {};
    setAllocationForex([...allocationForex]);
  };
  /**
   * Function to round off tax input elements after out of focus.
   */
  const handleItemOnBlur = (name: string, amount: number, index: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = decimalPlaceOfCurrency(
        creditNoteData.currency_code,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        invoiceToAllocate[index] = {
          ...invoiceToAllocate[index],
          ...{ [name]: roundedAmount, focus: false },
        };
        setInvoiceToAllocate([...invoiceToAllocate]);
      }
    }
  };
  /**
   * credit note allocation calculation
   */
  const calculateAllocationAmount = async (signal: AbortSignal) => {
    let input: number[] = [];
    invoiceToAllocate.map((invoice: InvoiceToAllocate) => {
      if (
        invoice.amount_credited &&
        invoice.amount_credited !== null &&
        invoice.amount_credited !== ""
      ) {
        input.push(Number(invoice.amount_credited));
      } else {
        input.push(0);
      }
    });
    if (input.length) {
      const responseAction = await dispatch(
        creditAllocationCalculation({
          creditNoteId: Number(editId),
          orgId: currentUserInfo.organization_id,
          values: input,
          signal: signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setAllocatedAmount(response.amount_to_credit);
          setRemainingAmount(response.remaining_credits);
        }
      }
    }
  };

  const resetAllocation = () => {
    setIsError(false);
    setAllocationForex([]);
  };

  return {
    invoiceToAllocate,
    allocationData,
    allocationColumn,
    allocationTableData,
    getInvoicesToAllocateCreditNote,
    resetAllocation,
    creditBalance,
    remainingAmount,
    allocatedAmount,
    isError,
    isFormValues,
  };
};
