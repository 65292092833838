import React from "react";
import { PlusSmallImg } from "../../../assets/images";

export default function CreateButton(props: {
  label: string;
  onClick: () => void;
}) {
  return (
    <button
      className="btn create-invoice-button green-button right-button"
      type="button"
      onClick={props.onClick}
    >
      <img src={PlusSmallImg} className="Group" alt="plus-small-img"></img>
      <span className="create-invoice-button-text green-button-text">
        {props.label}
      </span>
    </button>
  );
}
