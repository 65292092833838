import * as React from "react";
import KebabMenu from "../../../../../../common/components/kebabMenu/KebabMenu";
import UserFormContainer from "../../userForm/UserFormContainer";
import SuspendUser from "../../../../../../common/components/kebabMenu/SuspendUser";
import Delete from "../../../../../../common/components/Delete";
import ResendUserInvite from "../../../../../../common/components/kebabMenu/ResendUserInvite";
import CancelUserInvite from "../../../../../../common/components/kebabMenu/CancelUserInvite";
import useCommonData from "../../../../../../hooks/useCommon";

type Props = {
  value: any;
  roles: any;
  deleteOnClick: () => void;
  refreshList?: () => void;
};
function ListActions(props: Props) {
  const { currentUserInfo } = useCommonData();
  const disabledEditClassName =
    (props.value.isSuperUser && props.value.id !== currentUserInfo.id) ||
    !props.value.isMutable
      ? "disabled-menu-item"
      : "";

  return (
    <KebabMenu>
      <>
        {props.value.isSignIn && props.roles.includes("Update") && (
          <li
            className={disabledEditClassName}
            style={
              disabledEditClassName?.length === 0
                ? {}
                : { pointerEvents: "none" }
            }
          >
            <UserFormContainer
              id={props.value.id}
              isEdit={true}
              editUrl={""}
              isMutable={true}
              name="User"
              isLocked={false}
              className={"dropdown-item"}
              isSuperUser={
                props.value.isSuperUser ? props.value.isSuperUser : false
              }
              isTransactionLimitExceed={false}
              refreshList={props.refreshList ? props.refreshList : () => {}}
            />
          </li>
        )}
        {props.value.isSignIn && (
          <li
            className={
              props.value.isSuperUser ||
              props.value.id === currentUserInfo.id ||
              !props.value.isMutable
                ? "disabled-menu-item"
                : ""
            }
          >
            <SuspendUser
              className={`dropdown-item ${
                !props.value.isSuspended
                  ? "suspend-user"
                  : "revoke-suspended-user"
              }`}
              isSuspended={props.value.isSuspended}
              userId={props.value.id}
              refreshList={props.refreshList ? props.refreshList : () => {}}
            />
          </li>
        )}
        {props.value.isSignIn && props.roles.includes("Delete") ? (
          <li
            className={
              props.value.isSuperUser ||
              props.value.id === currentUserInfo.id ||
              !props.value.isMutable
                ? "disabled-menu-item"
                : ""
            }
          >
            <Delete
              className="dropdown-item delete"
              deleteIcon={true}
              isMutable={true}
              onClick={props.deleteOnClick}
              isLocked={false}
              lockDate=""
              name="User"
            />
          </li>
        ) : null}
        {!props.value.isSignIn && props.roles.includes("Create") && (
          <li>
            <ResendUserInvite userId={props.value.id} />
          </li>
        )}
        {!props.value.isSignIn && props.roles.includes("Delete") && (
          <li>
            <CancelUserInvite
              userId={props.value.id}
              refreshList={props.refreshList ? props.refreshList : () => {}}
            />
          </li>
        )}
      </>
    </KebabMenu>
  );
}

export default React.memo(ListActions);
