import { Api } from "../../constants";
import { get, post, put, remove } from "../../helpers/apiHelper";
import {
  PaymentTermValue,
  TagValue,
  ExchangeRateValues,
  ObjectType,
  ForexGainLossValues,
} from "../../types";

/**
 * Api call for fetching county details
 */
export async function getCountryList() {
  return await get(Api.countries + "/country-list");
}

/**
 * Api call for fetching state details
 */
export async function getStatesList(countryId: number) {
  // return await get(Api.countries + '/' + countryId + '/state');
  return await get(Api.countries + "/state-list" + "?country_id=" + countryId);
}

/**
 * API call for getting Logged in user information
 */

export async function getCurrentUserDetails(data: {
  orgId: number;
  isOrgChanged: boolean;
}) {
  const result = await get(
    Api.me +
      `?current_organization=${data.orgId}&is_org_changed=${data.isOrgChanged}`
  );
  return result;
}

/**
 * API call for getting currency dropdown
 */
export async function getCurrencyDropDown(orgId: number) {
  const result = await get(
    Api.currency + `dropdown?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for creating a New Payment Term
 */
export async function createNewPaymentTerms(data: {
  values: PaymentTermValue;
  orgId: number;
}) {
  const result = await post(
    Api.utils +
      "/" +
      Api.paymentTerms +
      `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for editing a Payment term
 */
export async function editPaymentTerm(data: {
  editPaymentTermId: number;
  paymentTermInputs: PaymentTermValue;
  orgId: number;
}) {
  const result = await put(
    Api.utils +
      "/" +
      Api.paymentTerms +
      "/edit" +
      `/${data.editPaymentTermId}?current_organization=${data.orgId}`,
    data.paymentTermInputs
  );
  return result;
}

/**
 * API call for getting all Payment Terms List
 */
export async function getPaymentTermsList(orgId: number) {
  const result = await get(
    Api.utils + "/" + Api.paymentTerms + `/list?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for deleting a payment term
 */

export async function deletePaymentTerm(data: { id: number; orgId: number }) {
  const result = await remove(
    Api.utils +
      "/" +
      Api.paymentTerms +
      "/delete" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 *  API call for creating tags
 */
export async function createTag(data: { values: TagValue; orgId: number }) {
  const result = await post(
    Api.tags + `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for getting all tags
 */
export async function getTags(orgId: number) {
  const result = await get(Api.tags + `/list?current_organization=${orgId}`);
  return result;
}

/**
 * API call for getting organization list that user can switch
 */
export async function getOrganizationListToSwitch(orgId: number) {
  const result = await get(
    Api.userManagement + `/user-switch/dropdown?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting currency conversion rate list
 */
export async function getCurrencyConversionList() {
  const result = await get(Api.currency + Api.exchangeRate + "/list");
  return result;
}

/**
 * API call for getting latest currency conversion rate
 */
export async function getLatestCurrencyConversion(data: {
  base_currency: number;
  to_currency: number;
  date: string;
}) {
  const result = await get(
    Api.currency +
      Api.exchangeRate +
      `/latest?base_currency=${data.base_currency}&to_currency=${data.to_currency}&date=${data.date}`
  );
  return result;
}

/**
 * API call for creating exchange rate
 */
export async function createExchangeRate(data: { values: ExchangeRateValues }) {
  const result = await post(
    Api.currency + Api.exchangeRate + "/create",
    data.values
  );
  return result;
}
/**
 * API call for get custom conversion details
 */
export async function customConversions(data: { conversionId: number }) {
  const result = await get(
    Api.currency +
      Api.exchangeRate +
      "/custom-conversions?conversion_id=" +
      data.conversionId
  );
  return result;
}
/**
 * API call for get the currency list of org and its parent orgs
 */
export async function getOrgCurrencyList(data: {
  selectedOrgId: number;
  orgId: number;
}) {
  const result = await get(
    Api.organizationHierarchy +
      "/get-org-currency-list?organization_id=" +
      data.selectedOrgId +
      "&current_organization=" +
      data.orgId
  );
  return result;
}
/**
 * API call for get the list of sub orgs in current organization
 */
export async function getOrgListForReport(data: { orgId: number }) {
  const result = await get(
    Api.organizationHierarchy +
      "/get-hierarchy-for-report?current_organization=" +
      data.orgId
  );
  return result;
}

/**
 * API call for updating prefixes
 */
export async function updatePrefixes(data: {
  values: {
    bill_prefix?: string | null;
    invoice_prefix?: string | null;
    dn_prefix?: string | null;
    cn_prefix?: string | null;
    payments_prefix?: string | null;
    receipts_prefix?: string | null;
    journal_prefix?: string | null;
  };
  orgId: number;
}) {
  const result = await put(
    Api.organizationProfile +
      "/update-prefixes" +
      "?organization_id=" +
      data.orgId +
      "&current_organization=" +
      data.orgId,
    data.values
  );
  return result;
}

/**
 * API call for getting prefixes
 */
export async function getPrefixes(data: { orgId: number }) {
  const result = await get(
    Api.organizationProfile + "/get-prefixes?organization_id=" + data.orgId
  );
  return result;
}

/**
 * API call for get table customization fields
 */
export async function tableCustomizationFields(data: {
  tableName: string;
  orgId: number;
}) {
  const result = await get(
    Api.tableCustomization +
      "/get-field-names?table_name=" +
      data.tableName +
      "&current_organization=" +
      data.orgId
  );
  return result;
}

/**
 * API call for set table customization fields
 */
export async function addTableCustomizationFields(data: {
  tableColumns: ObjectType;
  orgId: number;
}) {
  const result = await post(
    Api.tableCustomization +
      "/set-field-names?current_organization=" +
      data.orgId,
    data.tableColumns
  );
  return result;
}

/**
 * API call for getting forex gain / loss with respect to payment
 */
export async function getForexGainOrLoss(data: {
  values: ForexGainLossValues[];
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.currency +
      Api.exchangeRate +
      `/forex-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * API call for getting role permission mapping
 */
export async function getRoleMapping() {
  const result = await get(`${Api.utils}/${Api.roles}/mapping`);
  return result;
}
/**
 * API call for getting organization list
 */
export async function getOrganizationIDListOfUser() {
  const result = await get(`${Api.userManagement}/get-organizations-list
  `);
  return result;
}
/**
 * API call for update allocation date setting
 */
export async function editAllocationDateConfig(data: {
  is_enabled: boolean;
  orgId: number;
}) {
  const result =
    await put(`${Api.organizationHierarchy}/toggle-payment-allocation-date-edit?current_organization=${data.orgId}&is_enable=${data.is_enabled}
  `);
  return result;
}
/**
 * API call for getting the transaction config of current organization
 */
export async function getTransactionConfig(orgId: number) {
  const result = await get(
    Api.transactionConfig +
      `/get-amount-in-words-config?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for updating transaction config
 */
export async function updateTransactionConfig(data: {
  values: {
    is_invoices_enabled?: boolean | null;
    is_credit_notes_enabled?: boolean | null;
    is_bills_enabled?: boolean | null;
    is_debit_notes_enabled?: boolean | null;
    is_journal_entry_enabled?: boolean | null;
    is_recurring_invoice_enabled?: boolean | null;
    is_subscription_enabled?: boolean | null;
  };
  orgId: number;
}) {
  const result = await put(
    Api.transactionConfig +
      "/update-amount-in-words-config?current_organization=" +
      data.orgId,
    data.values
  );
  return result;
}
