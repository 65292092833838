import React from "react";
import { ForexGainLossValues, ObjectType } from "../../types";
import useCommonData from "./useCommon";
import { calculateForexGainOrLoss } from "../common/commonSlice";

let abortController = new AbortController();
function useForexCalculation() {
  const { dispatch, currentUserInfo } = useCommonData();

  /**
   * Handling request payload for the forex gain / loss API call
   */
  const getForexGainOrLoss = async (
    sourceConversionId: number,
    targetConversionId: number,
    paymentAmount: number,
    transactionId: number,
    traegetType: string,
    isPayloadChanged: boolean,
    forex?: ObjectType | null | undefined
  ) => {
    let payloadInput: ForexGainLossValues[] = [];
    payloadInput.push({
      entity_id: transactionId,
      source_conversion_id: sourceConversionId,
      target_conversion_id: targetConversionId,
      amount: !Number.isNaN(Number(paymentAmount)) ? paymentAmount : 0,
      target_type: traegetType,
    });
    if (
      sourceConversionId !== null &&
      sourceConversionId !== 0 &&
      // payloadInput[0].amount &&
      payloadInput[0].entity_id &&
      payloadInput[0].source_conversion_id &&
      payloadInput[0].target_conversion_id &&
      payloadInput[0].target_type &&
      isPayloadChanged === true
    ) {
      abortController.abort();
      if (payloadInput.length) {
        return await forexApiCall(payloadInput);
      }
      return {};
    } else if (payloadInput[0].amount === 0) {
      return { [payloadInput[0].entity_id]: 0 };
    } else if (forex !== null) {
      return forex || {};
    }
  };
  /**
   * Function to handle API call response of forex gain / loss
   */
  const forexApiCall = async (payloadInput: ForexGainLossValues[]) => {
    abortController = new AbortController();
    const signal = abortController.signal;
    const responseAction = await dispatch(
      calculateForexGainOrLoss({
        values: payloadInput,
        orgId: currentUserInfo.organization_id,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      return response;
    } else {
      return {};
    }
  };
  return { getForexGainOrLoss };
}

export default useForexCalculation;
