import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { EditListDataImg, InvoiceImage } from "../../../../assets/images";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../common/commonSlice";
import KebabMenu from "../../../common/components/kebabMenu/KebabMenu";
import Loader from "../../components/Loader";
import "../CurrencyConfiguration.css";
import AddCurrency from "./Create";
import { getCurrencies } from "../currencyConfigurationSlice";
import { ObjectType } from "../../../../types";
import { Link } from "react-router-dom";
import TableContainer from "../../../common/components/table/TableContainer";
import { decimalFormatValues } from "../../../constants/constants";
import { getTransactionStatusOfAccount } from "../../chartOfAccounts/coaSlice";
import EmptySearchList from "../../../common/components/emptySearchList/EmptySearchList";

function CurrencyList() {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const currencyList = useAppSelector(orgCurrencyListSelector);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState("create");
  const [selectedCurrencyData, setSelectedCurrencyData] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const [isTransactionExist, setIsTransactionExist] = useState(false);
  const currencyFormat: ObjectType = {
    "##,##,##,###.##": "12,34,567.89",
    "###,###,###.##": "1,234,567.89",
    "###.###.###,##": "1.234.567,89",
    "### ### ###,##": "1 234 567, 89",
  };

  const getCurrencyList = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getCurrencies({ orgId: currentUserInfo.organization_id })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrencyList();
  }, [currentUserInfo.organization_id]);

  const responsiveTableDropdown = () => {
    var leftScroll = 0;
    var scrollItem: any = document.querySelector(".table-container");
    let resizeFlag: boolean = false;
    scrollItem.addEventListener("scroll", function (e: any) {
      setTableScrollLeft(scrollItem.scrollLeft);
    });
    $(".currency-table .dropdown-ctrl").on("click", function () {
      ($(".user-list-table .dropdown-ctrl") as any).dropdown("hide");
      ($(this) as any).dropdown("show");
    });
    $(".currency-table").on("show.bs.dropdown", function () {
      $(".table-container").css({ overflow: "inherit" });
      $(".table-container table").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
    });

    $(".currency-table").on("hide.bs.dropdown", function () {
      $(".table-container").css({
        "overflow-x": "auto",
        "overflow-y": "hidden",
      });

      $(".table-container table").css("margin-left", 0);
    });

    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-ctrl") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    responsiveTableDropdown();
  });

  /**
   * sort currency list
   */

  const sortCurrenyList = (currency: ObjectType[]) => {
    let currencies = [...currency];
    let sortedCurrencies: any = [];
    currencies.forEach((c) => {
      if (c.id === currentUserInfo.organization_currency) {
        sortedCurrencies.unshift(c);
      } else {
        sortedCurrencies.push(c);
      }
    });
    return sortedCurrencies;
  };

  const handleSearch = (e: any) => {
    setSearchKey(e.target.value);
  };
  /**
   * To get the format (12,34,567.899) for the request payload from (##,##,###.###)
   */
  const getKeyByIdAndValue = (decimal: number, targetValue: string) => {
    const item: any = decimalFormatValues.find(
      (obj) => obj.decimalPlaces === decimal
    );
    if (item) {
      const keys = Object.keys(item.value);
      const foundKey = keys.find((key) => item.value[key] === targetValue);
      return foundKey || null;
    }
    return null;
  };

  const fetchTransactionStatusOfCurrency = async (id: number) => {
    if (currentUserInfo.is_super_parent) {
      const responseAction = await dispatch(
        getTransactionStatusOfAccount({
          transactionId: id,
          orgId: currentUserInfo.organization_id,
          transaction: "currency_id",
        })
      );
      const response = responseAction.payload;
      setIsTransactionExist(response);
    }
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "show.name",
        sort: false,
        sortOrder: "",
        className: "name",
        Cell: ({ cell: { value } }: any) => {
          return value.isBaseCurrency ? (
            <span className="base-currency">
              {value.currencyName}
              <span className="notify">(Base Currency)</span>
            </span>
          ) : (
            <>{value.currencyName}</>
          );
        },
      },
      {
        Header: "Symbol",
        accessor: "show.symbol",
        sort: false,
        sortOrder: "",
        className: "symbol",
        Cell: ({ cell: { value } }: any) => {
          return value.isBaseCurrency ? (
            <span className="base-currency-symbol">{value.currencySymbol}</span>
          ) : (
            <>{value.currencySymbol}</>
          );
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (
            <KebabMenu
              onClick={() => fetchTransactionStatusOfCurrency(value.id)}
            >
              <li>
                <Link
                  to="#"
                  className="dropdown-item edit-user"
                  data-bs-toggle="modal"
                  data-bs-target="#addCurrencyModal"
                  onClick={(e) => {
                    setSelectedCurrencyData({
                      ...value,
                      format: getKeyByIdAndValue(value.decimal, value.format),
                    });
                    setActionType("edit");
                  }}
                >
                  <img src={EditListDataImg} alt="edit-list-data-imgs" />
                  <span>Edit</span>
                </Link>
              </li>
            </KebabMenu>
          );
        },
      },
    ],
    [currentUserInfo]
  );
  /**
   * set table row data
   */

  let sortedCurrencies = sortCurrenyList(currencyList);

  const data = sortedCurrencies
    ? sortedCurrencies
        .filter(
          (filterCurrency: ObjectType) =>
            filterCurrency.name
              .toLowerCase()
              .includes(searchKey.toLowerCase()) ||
            filterCurrency.code.toLowerCase().includes(searchKey.toLowerCase())
        )
        .map((currency: ObjectType, index: number) => ({
          show: {
            name: {
              currencyName: currency.code + " - " + currency.name,
              isBaseCurrency:
                currency.id === currentUserInfo.organization_currency,
            },
            symbol: {
              currencySymbol: currency.symbol,
              isBaseCurrency:
                currency.id === currentUserInfo.organization_currency,
            },
            actions: currency,
          },
        }))
    : [];
  return (
    <div className="card card-user-management card-customer card-currency-config main-card overflowX-hidden h-100">
      <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
        <div>
          <h1>Currencies</h1>

          <form
            className="search_table_form"
            name="search_table_form"
            autoComplete="off"
          >
            <div className="autocomplete">
              <input
                type="text"
                name="searchTable"
                id="search_table"
                className="search_table_input"
                placeholder="Search currency"
                value={searchKey}
                onChange={handleSearch}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="card-body">
        <div
          id="user_table_wrapper"
          className="dataTables_wrapper dt-bootstrap5 no-footer p-0"
        >
          <TableContainer
            id="settings_table"
            className="list-table table table-striped currency-table w-100"
            columns={columns}
            data={data}
            url=""
            sortHandler={() => {}}
            isLoading={isLoading}
            editPermission={false}
            isDetailViewExist={false}
          >
            {/* <>No results found!</> */}
            <EmptySearchList
              alt="Empty currency list"
              icon={InvoiceImage}
              emptyText="No results found!"
            />
          </TableContainer>
          {isLoading ? <Loader /> : null}
        </div>
      </div>
      <AddCurrency
        action={actionType}
        currencyData={selectedCurrencyData}
        getCurrencyList={getCurrencyList}
        isTransactionExist={isTransactionExist}
      />
    </div>
  );
}

export default CurrencyList;
