import * as yup from "yup";
import { message } from "../../../../constants/messages";

export const defaultPreferenceValidation = yup.object({
  intra_state_id: yup
    .object()
    .nullable()
    .test({
      name: "intra_state_id",
      message: message().selectIntraStateTax,
      test: function (value) {
        const default_preference_enabled =
          this.options.context?.default_preference_enabled;
        if (
          default_preference_enabled === "Yes" &&
          (value === null || value === "" || value === undefined)
        ) {
          return false;
        }
        return true;
      },
    }),
  inter_state_id: yup
    .object()
    .nullable()
    .test({
      name: "inter_state_id",
      message: message().selectInterStateTax,
      test: function (value) {
        const default_preference_enabled =
          this.options.context?.default_preference_enabled;

        if (
          default_preference_enabled === "Yes" &&
          (value === null || value === "" || value === undefined)
        ) {
          return false;
        }
        return true;
      },
    }),
});
