import { Link } from "react-router-dom";
import { DeleteImg, LocatorIconSmall } from "../../../../../assets/images";
import { AddressValues } from "../../../../../types";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  editBillingAddress,
  editShippingAddress,
} from "../../../../home/customerManagement/components/customerForm/AddressDetails/addressTypeSlice";
import { useEffect, useRef } from "react";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  id: string;
  openAddressModal: () => void;
  address: AddressValues;
  setAddressIdHandler: (id: string | undefined | number) => void;
  isSameOrg: boolean;
  shouldScrollTo: boolean;
  overviewInvoiceData: any;
};

const AddressOverviewCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const ref: any = useRef();

  const { currentUserInfo } = useCommonData();

  useEffect(() => {
    props.shouldScrollTo &&
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }, []);

  const editAddressHandler = (type: string) => {
    if (type === "billing") {
      dispatch(editBillingAddress("edit_billing"));
    } else {
      dispatch(editShippingAddress("edit_shipping"));
    }
  };
  return (
    <div ref={ref} className="address-box-wrapper">
      <div className="boxed-item address-item card m-0">
        {props.address.is_primary ? (
          <div className="card-header">
            {props.address.address && (
              <h5 className="d-flex">
                <img
                  src={LocatorIconSmall}
                  alt="default address"
                  width={"12px"}
                  height={"15px"}
                />
                <span>Default Address</span>
              </h5>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="card-body">
          {props.address.address ? (
            <>
              <p>
                {props.address.address}
                {props.address.address &&
                (props.address.city ||
                  props.address.country_id ||
                  props.address.zip_code)
                  ? ", "
                  : ""}
                {props.address.city}
                {props.address.city &&
                (props.address.country_id || props.address.zip_code)
                  ? ", "
                  : ""}
                {props.address.state_name}
                {props.address.state_id &&
                (props.address.zip_code || props.address.country_id)
                  ? ", "
                  : ""}
                {props.address.zip_code ? "Pin " + props.address.zip_code : ""}
                {props.address.zip_code && props.address.country_id ? ", " : ""}
                {props.address.country_name}
              </p>
              <p className="contact-dets">
                {" "}
                {props.address.phone ? "Phone: " + props.address.phone : ""}
              </p>
            </>
          ) : (
            <>
              <p>{props.overviewInvoiceData.customer_name}</p>
              {props.overviewInvoiceData.customer_gstin && (
                <p className="contact-dets">
                  <b>GSTIN:</b>
                  {props.overviewInvoiceData.customer_gstin}
                </p>
              )}
            </>
          )}
        </div>
        {props.isSameOrg && (
          <div className="card-footer">
            <Link
              to="#"
              role="button"
              className="edit-address-link"
              onClick={(e: any) => {
                e.preventDefault();
                editAddressHandler(props.id);
                props.setAddressIdHandler(props.address.id);
                props.openAddressModal();
              }}
            >
              Edit
            </Link>
            <Link
              className="text-decoration-none delete-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target={
                props.address.is_primary
                  ? "#addressPopUpModal"
                  : "#deleteAddressModal"
              }
              onClick={() => {
                props.setAddressIdHandler(props.address.id);
              }}
            >
              <img src={DeleteImg} alt="delete-img" />
              <span>Delete</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressOverviewCard;
