import { INITIAL_SPLIT_TYPE_OPTION } from "../../../../../constants/constants";

export const initialValues = {
  currency_id:0,
  currency_code:'',
  account_name:'',
  transaction_amount: 0,
  transaction_type: "",
  entity_id: 0,
  entity_type: "",
  reporting_tag_list: [
    // {
    //   split_type: INITIAL_SPLIT_TYPE_OPTION.value,
    //   transaction_amount: 0,
    //   reporting_tag_name:'',
    //   reporting_tag_id: 0,
    //   option_list: [
    //     {
    //       option_id: 0,
    //       option_name:'',
    //       amount: 0,
    //     },
    //   ],
    // },
  ],
};
