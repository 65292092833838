import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ObjectType } from "../../../../types";
import {
  currentUserSelector,
  searchBoxVariantSelector,
  searchQuerySelector,
  searchResultSelector,
} from "../../../common/commonSlice";
import Pagination from "../../../common/components/table/Pagination";
import TableInfo from "../../../common/components/table/TableInfo";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import { NoSearchResultComponent } from "../noResultPage/NoResultComponent";
import { SearchBox } from "../searchBox/searchBox";
import "./Search.css";
import SearchResultItem from "./SearchResultItem";

export const Search = () => {
  const { searchQuery, searchModule } = useAppSelector(searchQuerySelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const searchResultInitial = useAppSelector(searchResultSelector);
  const [value, setSearchValue] = useState(searchQuery);
  const [module, setModule] = useState<any>(searchModule);
  const variant = useAppSelector(searchBoxVariantSelector);
  const [searchResult, setSearchResult] = useState<ObjectType[]>([
    ...searchResultInitial,
  ]);
  useEffect(() => {
    setModule(searchModule);
    setSearchValue(searchQuery);
    setSearchResult([...searchResultInitial]);
  }, [JSON.stringify(searchResultInitial)]);
  useEffect(() => {
    setModule(searchModule);
    setSearchValue(searchQuery);
  }, []);

  const dispatch = useAppDispatch();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setTotalList,
    setItemOffset,
  } = usePaginationDispatch();
  const { page, pageCount, itemsPerPage, totalList, startCount, endCount } =
    useListData();
  useEffect(() => {
    const scrollToTopFunction = () => {
      $("html,body").animate({ scrollTop: 0 }, 50);
      return false;
    };
    scrollToTopFunction();
  }, [page]);
  const getSearchResult = (
    searchOutput: ObjectType[],
    searchValue: string,
    module: string
  ) => {
    setSearchResult([...searchOutput]);
    setSearchValue(searchValue);
    setModule(module);
  };
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  return (
    <>
      <div
        className="card card-user-management main-card card-search-results w-100 overflowX-hidden h-100"
        key={currentUserInfo.organization_id + searchModule + searchQuery}
      >
        <div className="card-header invoice-list-card-header main-card-header list-card-header p-0 border-0">
          <h1>Search Results</h1>
        </div>
        <div className="card-body">
          <section className="filter-block">
            <SearchBox
              moduleValue={module}
              searchValue={value}
              variant="white"
              getSearchResult={getSearchResult}
            />
          </section>
          <section className="results-block">
            {searchResult.length > 0 ? (
              <div className="results-wrapper">
                <div className="results-count-wrapper list-table-wrapper dataTables_wrapper">
                  <TableInfo
                    startCount={startCount}
                    itemsPerPage={itemsPerPage}
                    endCount={endCount}
                    total={totalList}
                    setPageInfo={(countPerPage, page) => {
                      setItemsPerPage(countPerPage);
                      setPage(page);
                    }}
                  />
                </div>

                <div className="results-list">
                  <ul>
                    {searchResult.map((result) => {
                      return (
                        <SearchResultItem
                          searchData={result}
                          searchQuery={value}
                          searchModule={module}
                          isLoading={variant === "white"}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : (
              <NoSearchResultComponent variant={"page"} />
            )}
          </section>
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
