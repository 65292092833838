import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
type Error = Record<string, string>;

export default function ScrollToError(identifier: string): void {
  const element =
    document.getElementById(identifier) ||
    document.getElementsByName(identifier)[0] ||
    document.getElementsByClassName(identifier)[0];

  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset - 150;
    window.scrollTo(0, y);
  }
  if (identifier === "customer_id" || identifier === "vendor_id") {
    window.scrollTo(0, 0);
  }
}
