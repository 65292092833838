import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Label } from "../htmlTags/Label";
import "./CheckBox.css";

type CheckBoxProps = {
  id: string;
  name: string;
  className?: string;
  checked: boolean;
  value: boolean;
  label: string;
  checkedIcon?: React.ReactElement;
  unCheckedIcon?: React.ReactElement;
  onChange: (e: { name: string; value: boolean }) => void;
  onClick?: (e: string) => void;
  wrapperClass?: string;
};
function CheckBox(props: CheckBoxProps) {
  const handleChange = (e: any) => {
    const name = e.target.id;
    const value = e.target.checked;
    props.onChange({ name, value });
  };
  return (
    <FormControlLabel
      className={`form-control-reusable-checkbox ${
        props.wrapperClass ? props.wrapperClass : ""
      }`}
      control={
        <Checkbox
          id={props.id}
          name={props.name}
          checked={props.checked}
          checkedIcon={props.checkedIcon}
          icon={props.unCheckedIcon}
          className={`${props.className}`}
          onClick={handleChange}
          //   value={props.value}
        />
      }
      label={props.label}
    />
  );
}
export default CheckBox;
