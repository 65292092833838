import { ObjectType } from "../../../../../../types";

export const getFullyAllocatedSundryBillIds = (items: ObjectType[]) => {
  const ids = items
    .filter((item) => item.is_sundry_tag_associated)
    .filter((item) => Number(item.bill_amount) === Number(item.payment_amount))
    .map((item) => {
      return item.bill_id;
    });
    
  return ids;
};
