export const convertToPascalCase = (input: string | undefined): string => {
  if (input === null || input === undefined) {
    return "";
  }
  if (typeof input !== "string") {
    throw new Error("Input must be a string");
  }
  input = input.trim(); // Trim the input string to remove leading and trailing whitespaces
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" "); // Add a space between words
};
