import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import InfoTooltip from "../../../../../common/components/InfoTooltip";
import "./amountInWordsConfig.css";
import { AmountInWordsConfigProps } from "../resources/types";

export const AmountInWordsConfig = (props: AmountInWordsConfigProps) => {
  const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    props.setAmountConfigValue("isAmountInWordsFullyEnabled", checked);
    let tempSettings = { ...props.amountInWordsConfigObj };
    Object.keys(props.amountInWordsConfigObj).map((value) => {
      tempSettings[value] = checked;
    });
    props.setAmountConfigValue("amountInWordsConfigObj", { ...tempSettings });
  };

  const handleTransactionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const { checked } = event.target;
    let tempSettings = { ...props.amountInWordsConfigObj };
    tempSettings[type] = checked;
    props.setAmountConfigValue("amountInWordsConfigObj", { ...tempSettings });
    let isFullyEnabled = !Object.values(tempSettings).some(
      (value) => value === false
    );
    props.setAmountConfigValue("isAmountInWordsFullyEnabled", isFullyEnabled);
  };

  const isPartiallyChecked = () => {
    const values = Object.values(props.amountInWordsConfigObj);
    return values.some(Boolean) && !values.every(Boolean);
  };

  return (
    <div className="amount-in-words-config">
      <h6>
        Amount in words{" "}
        <InfoTooltip title="Amount in words will be displayed in the respective detail screens, PDFs and Email" />
      </h6>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.isAmountInWordsFullyEnabled}
            onChange={handleParentChange}
            name="global"
            checkedIcon={<></>}
            icon={<></>}
            indeterminateIcon={<></>}
            className={`checkbox chekBoxlabel ${
              props.isAmountInWordsFullyEnabled
                ? "checkBoxChecked"
                : isPartiallyChecked()
                ? "checkBoxPartiallyChecked"
                : ""
            }`}
          />
        }
        label="Show the amount in words for all transactions"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 3,
          width: "100%",
        }}
      >
        {Object.keys(props.amountInWordsConfigObj).map((type) => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                className={`checkbox chekBoxlabel ${
                  props.amountInWordsConfigObj[type as string]
                    ? "checkBoxChecked"
                    : ""
                }`}
                checked={props.amountInWordsConfigObj[type as string]}
                onChange={(e) => handleTransactionChange(e, type as string)}
                name={type}
                checkedIcon={<></>}
                icon={<></>}
                indeterminateIcon={<></>}
              />
            }
            label={type}
          />
        ))}
      </Box>
    </div>
  );
};
