import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { GstSettingsValues, DefaultPreferenceValues } from "../../../types";

/**
 * API call for getting gst settings
 */
export async function getGstSettings(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.gstConfiguration}/gst-settings?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for updating gst settings
 */
export async function saveGstSettings(data: {
  values: GstSettingsValues;
  orgId: number;
}) {
  let payload: any = {};
  let gst_enabled;
  if (data.values.gst_enabled == "Yes") {
    gst_enabled = true;
    payload.gst = data.values.gst;
    payload.business_legal_name = data.values.business_legal_name;
    payload.business_trade_name = data.values.business_trade_name;
    payload.registered_on = data.values.registered_on
      ? data.values.registered_on
      : null;
  } else {
    gst_enabled = false;
  }
  const result = await put(
    Api.chartOfAccount +
      `${Api.gstConfiguration}/gst-settings?current_organization=${data.orgId}&gst_enabled=${gst_enabled}`,
    payload
  );
  return result;
}

/**
 * API call for to get intra state tax rates
 */
export async function getStateTaxRates(data: {
  orgId: number;
  stateType: string;
}) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.gstConfiguration}/list-tax-for-transactions?current_organization=${data.orgId}&state_type=${data.stateType}`
  );
  return result;
}

/**
 * API call for to get default tax preferences
 */
export async function getDefaultTaxPreference(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.gstConfiguration}/default-preference?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for updating default tax preference
 */
export async function saveDefaultTaxPreference(data: {
  values: DefaultPreferenceValues;
  orgId: number;
}) {
  let default_preference_enabled = data.values.default_preference_enabled;
  const result = await put(
    Api.chartOfAccount +
      `${Api.gstConfiguration}/default-preference?current_organization=${data.orgId}&default_preference_enabled=${default_preference_enabled}`,
    data.values
  );
  return result;
}

/**
 * API to get state list
 */

export async function stateList(data: { orgId: number }) {
  const result = await get(
    Api.countries + `/gst-state-dropdown?current_organization=${data.orgId}`
  );
  return result;
}

/**
 *  API to get GST tax list
 */
export async function gstTaxList(data: {
  stateType: string;
  orgId: number;
  is_sez?: boolean;
  transactionSource?: string | undefined;
  stateCode?: string;
  transaction_tax_details?: [{ is_group: boolean; tax_id: number }];
  rcm_enabled?: boolean;
}) {
  let is_Sez = data.is_sez ? true : false;
  let is_RCM_Enabled = data.rcm_enabled? true: false;
  // if (data.stateType === "") return;

  let result = await post(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/list-tax-for-transactions?${data.stateType !== "" ? `state_type=${data.stateType}` : ''}&is_sez=${is_Sez}&current_organization=${data.orgId}${
        data.transactionSource
          ? `&transaction_source=${data.transactionSource}`
          : ""
      }${data.stateCode ? `&state_code=${data.stateCode}` : ""}&rcm_enabled=${is_RCM_Enabled}`,
    data?.transaction_tax_details
  );
  return result;
}

/**
 *  API to create new tax exemption
 */
export async function taxExemptionCreation(data: {
  organization_id: number;
  exemption_reason: string;
  description: string;
  exemption_type: "Customer" | "Item";
}) {
  const result = await post(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/create_tax_exemption?current_organization=${data.organization_id}`,
    data
  );
  return result;
}

/**
 *  API to get tax exemption list
 */
export async function taxExemptionList(data: {
  orgId: number;
  pageNum: number;
  pageSize: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/get_tax_exemption?current_organization=${data.orgId}&page_num=${data.pageNum}&page_size=${data.pageSize}`
  );
  return result;
}

/**
 *  API to update tax exemption
 */
export async function taxExemptionEdit(data: {
  id: number;
  organization_id: number;
  exemption_reason: string;
  description: string;
  exemption_type: "Customer" | "Item";
}) {
  const result = await put(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/update_tax_exemption/${data.id}?current_organization=${data.organization_id}`,
    data
  );
  return result;
}

/**
 *  API to delete tax exemption
 */
export async function taxExemptionRemove(data: {
  exemptionId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.chartOfAccount +
      Api.gstConfiguration +
      `/delete_tax_exemption/${data.exemptionId}?current_organization=${data.orgId}`
  );
  return result;
}
