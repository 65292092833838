import CenterModal from "../../../../common/components/centerModal/CenterModal";
import VendorForm from "../vendorForm/VendorForm";
import "./AddVendorModal.css";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import { AddNewSquarePlusIcon } from "../../../../common/components/customSvgIcons/addNewSquarePlusIcon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

export const AddVendorModal = () => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { vendorRolePermission } = usePermissionHandler();

  const ConfigureButton = () => {
    return (
      <>
        {vendorRolePermission.includes("Create") &&
        IsFeatureEnabled(
          "Vendor",
          currentUserInfo.disabled_feature
            ? currentUserInfo.disabled_feature
            : []
        ) ? (
          <span>
            <AddNewSquarePlusIcon />
            Add Vendor
          </span>
        ) : null}
      </>
    );
  };
  return (
    <div id="addVendorModal">
      <CenterModal
        modalTitle="Add Vendor"
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return <VendorForm handleModalClose={onCloseModal} />;
            }}
          </ModalContext.Consumer>
        }
        buttonContent={<ConfigureButton />}
        wrapperClass="add-vendor-modal large-form-modal"
        buttonClass="add-vendor-link"
      />
    </div>
  );
};
