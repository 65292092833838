import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { ConfirmationPopupBody } from "./ConfirmationPopupBody";
import { ConfirmationPopupProps } from "./types";

export const ConfirmationPopup = (props: ConfirmationPopupProps) => {
  return (
    <AlertModal
      modalButtonWrapperClass="confirmation-popup-wrapper-class"
      buttonContent={props.popupButtonContent}
      modalBodyClass="confirmation-popup-body-wrapper"
      isCloseButtonEnabled={true}
      modalTitle={props.popupTitle}
      disabledActionFunction={() => {}}
      buttonClass={`no-appearance confirmation-popup-btn ${
        props.popupButtonClass || ""
      }`}
      ref={props.confirmationOpenRef}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <ConfirmationPopupBody
                {...props}
                onCancel={() => {
                  props.onCancel();
                  onCloseModal();
                }}
                onSubmit={() => {
                  props.onSubmit();
                  onCloseModal();
                }}
              ></ConfirmationPopupBody>
            );
          }}
        </ModalContext.Consumer>
      }
    />
  );
};
