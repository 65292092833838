import { useId, ReactNode, ReactElement, cloneElement } from "react";
import "../../../../../home/Home.css";
import "../../../../../../ColorVariables.css";
import "./FormFieldContainer.css";
import InfoTooltip from "../../../InfoTooltip";
import CustomTooltips, { position } from "../../../Tooltip";
import { Container } from "../../../htmlTags/Container";
import { Label } from "../../../htmlTags/Label";
import { Span } from "../../../htmlTags/Span";
// import WrapId from "../WrapId/WrapId";

type Props = {
  id?: string;
  wrapperClass?: string;
  label?: string;
  labelClass?: string;
  labelToolTipMessage?: string;
  labelToolTipSpanClass?: string;
  isRequired?: boolean;
  disabled?: boolean;
  tooltipMessage?: string | undefined;
  messagePlacement?: position | undefined;
  isActionLink?: boolean;
  actionElement?: ReactNode;
  children: ReactElement;
};

export const FormFieldContainer = (props: Props) => {
  const id = useId();
  // const ChildWithId = (Child: any) => {
  //   return <Child id={id} />;
  // };
  const ChildWithId = cloneElement(props.children, {
    id: id,
  });
  return (
    <Container
      className={`reusable-form-item-wrapper form-field ${props.wrapperClass}`}
    >
      <Container className="label-wrapper text-nowrap">
        <Label
          className={`fw-bold form-field-label ${
            props.isRequired ? "required" : ""
          } ${props.labelClass}`}
          htmlFor={props.id ? props.id : id}
        >
          {props.labelToolTipMessage ? (
            <CustomTooltips title={props.label} placement="right">
              <Span className={props.labelToolTipSpanClass}>{props.label}</Span>
            </CustomTooltips>
          ) : (
            props.label
          )}
        </Label>
        {props?.tooltipMessage ? (
          <Container className="label-container-infotip">
            <InfoTooltip
              title={props.tooltipMessage}
              placement={props?.messagePlacement || "top"}
              disabled={props.disabled ? props.disabled : false}
            />
          </Container>
        ) : null}
        {props.isActionLink && props.actionElement ? props.actionElement : ""}
      </Container>
      {/* {ChildWithId(props.children)} */}
      {ChildWithId}
    </Container>
  );
};
