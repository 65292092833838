import { decimalPlaceOfCurrency } from "../../helpers/decimalPlaceHelper";
import { splitNumberByDecimalPoint } from "../../helpers/helper";
import { getRoundOffAmount } from "../../helpers/roundOffHelper";
import { CurrencyFormType } from "../../types";

export const handleAmountRoundOff = (
  amount: string | number,
  currencyCode: string,
  orgCurrencyList: CurrencyFormType[]
) => {
  let roundedAmount: number = Number(amount);
  if (amount.toString().includes(".")) {
    const [, fractionalPart] = splitNumberByDecimalPoint(Number(amount));
    let decimals: number;

    decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);

    if (fractionalPart.length > decimals) {
      roundedAmount = getRoundOffAmount(Number(amount), decimals);
    }
  }
  return roundedAmount;
};
