import React, { useState } from 'react';
import { ObjectType } from "../../../../types";
import TopReceivables from './TopReceivables';
import TopPayables from './TopPayables';
import DoughnutGraph from './DoughnutGraph';
import "../styles/TabComponent.css";
import { colors } from '../../../constants/colors';

type Props = {
    totalReceivable: ObjectType;
    totalPayable: ObjectType;
    currentUserInfo: ObjectType;
    showUnpaidInvoices: () => void;
    showUnpaidBills:() => void;
}
export default function TabComponent(props:Props) {
    
    const [activeTab, setActiveTab] = useState("total-receivable");

    function handleTabClick(tab: string) {
        setActiveTab(tab);
        //sessionStorage.setItem("activeCustomerTab", tab);
    }
    return (
        <>
        <div className="tab-container dashboard-tab-content w-100">
            <div className="tab-link-wrapper w-100">
                <ul
                    className="nav nav-pills pills-tab mt-0 p-0"
                    id="pills-tab"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                    <button
                        className={
                        activeTab === "total-receivable" ? "nav-link active" : "nav-link"
                        }                    
                        id="pills-total-receivable-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-total-receivable"
                        type="button"
                        role="tab"
                        aria-controls="pills-total-receivable"
                        aria-selected="true"
                        onClick={() => handleTabClick("total-receivable")}
                    >
                        Total Receivables
                    </button>
                    </li>
                    <li className="nav-item" role="presentation">
                    <button
                        className={
                        activeTab === "total-payable" ? "nav-link active" : "nav-link"
                        }                   
                        id="pills-total-payable-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-total-payable"
                        type="button"
                        role="tab"
                        aria-controls="pills-total-payable"
                        aria-selected="true"
                        onClick={() => handleTabClick("total-payable")}
                    >
                        Total Payables
                    </button>
                    </li>
                </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
                <div
                className={
                    activeTab === "total-receivable"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-total-receivable"
                role="tabpanel"
                aria-labelledby="pills-total-receivable-tab"
                tabIndex={0}
                >
                <div className="tab-content-body">
                    <div className="row g-0">
                    <div className="col">
                        <DoughnutGraph
                        title="Total Receivables"
                        titleInfo={
                            <>
                            <span>
                                Open - Total balance of invoices that are yet to be due{" "}
                            </span>{" "}
                            <br />
                            <span>
                                Overdue - Total balance of invoices that are overdue
                            </span>
                            </>
                        }
                        subTitle="Total Unpaid Invoices"
                        type="invoice"
                        data={{
                            total: props.totalReceivable.total_unpaid,
                            current: props.totalReceivable.total_unpaid_current,
                            overDue: props.totalReceivable.total_unpaid_overdue,
                            currentPercentage:
                            props.totalReceivable.total_unpaid_current_percentage,
                            overduePercentage:
                            props.totalReceivable.total_unpaid_overdue_percentage,
                            backgroundColors: [colors.yellowGreen, colors.tomato],
                            noOfTotalUnpaid: props.totalReceivable.no_of_total_unpaid,
                            noOfCurrent: props.totalReceivable.no_of_total_unpaid_current,
                            noOfOverdue: props.totalReceivable.no_of_total_unpaid_overdue,
                        }}
                        onClickUnpaidTransactions={props.showUnpaidInvoices}
                        currencySymbol={props.currentUserInfo.currency_code}
                        />
                    </div>
                    </div>
                    <div className="row g-0">                      
                        <TopReceivables />                      
                    </div>                    
                </div>
                
                </div>
                <div
                className={
                    activeTab === "total-payable"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }                  
                id="pills-total-payable"
                role="tabpanel"
                aria-labelledby="pills-total-payable-tab"
                tabIndex={0}
                >
                <div className="tab-content-body">
                    <div className="row g-0">
                    <div className="col">
                        <DoughnutGraph
                        title="Total Payables"
                        titleInfo={
                            <>
                            <span>
                                Open - Total balance of bills that are yet to be due{" "}
                            </span>{" "}
                            <br />
                            <span>Overdue - Total balance of bills that are overdue</span>
                            </>
                        }
                        subTitle="Total Unpaid Bills"
                        type="bill"
                        data={{
                            total: props.totalPayable.total_unpaid,
                            current: props.totalPayable.total_unpaid_current,
                            overDue: props.totalPayable.total_unpaid_overdue,
                            currentPercentage: props.totalPayable.total_unpaid_current_percentage,
                            overduePercentage: props.totalPayable.total_unpaid_overdue_percentage,
                            backgroundColors: [colors.lavenderBlue, colors.tomato],
                            noOfTotalUnpaid: props.totalPayable.no_of_total_unpaid,
                            noOfCurrent: props.totalPayable.no_of_total_unpaid_current,
                            noOfOverdue: props.totalPayable.no_of_total_unpaid_overdue,
                        }}
                        onClickUnpaidTransactions={props.showUnpaidBills}
                        currencySymbol={props.currentUserInfo.currency_code}
                        />
                    </div>
                    </div>
                    <div className="row g-0">
                    <TopPayables />
                    </div>
                    </div>


                    </div>
                </div>
            </div>
        </>
    )
}
