export const deleteInitialData = {
    actionType: '',
    id: -1,
    addonName: '',
  }

export const addonDetailInitialData = {
  description: '',
  external_name: '',
  id: 0,
  item_detail: [],
  organization_id: 0,
  organization_name: '',
  addon_status: '',
  currency_code: '',
  pricings: null
}

export const defaultFrequencyCol = {
      frequency: "",
      pricing_model: "",
      isPriceSet: false,
      item_price: [],
      pricing_id: 0,
}

