import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { TDSFormValues } from "../../../types";

/**
 * API call for getting all TDS
 */
export async function getTdsList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.tdsConfiguration}/list?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for Creating or Updating TDS tax
 */
export async function createNewTds(data: {
  values: TDSFormValues;
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount +
      `${Api.tdsConfiguration}/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to get section drop down
 */
export async function getSectionDropDown(orgId: number) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.tdsConfiguration}/section-dropdown?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for updating TDS Preference
 */
export async function updateTdsPreferenceValues(data: {
  supportTds: string;
  applyTdsAt: string;
  isEnabled: boolean;
  orgId: number;
}) {
  let updateUrl = "";
  if (data.isEnabled) {
    updateUrl = `support_tds_for=${data.supportTds}&apply_tds_at=${data.applyTdsAt}&`;
  }
  const result = await put(
    Api.chartOfAccount +
      `${Api.tdsConfiguration}/update-preference?${updateUrl}is_enabled=${data.isEnabled}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting TDS tax preference details
 */
export async function getTdsTaxPreference(orgId: number) {
  const result = await get(
    Api.chartOfAccount +
      `${Api.tdsConfiguration}/organization-preference?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting TDS section drop down
 */
export async function getTdsDropDown(data: { orgId: number; tdsId: number[] }) {
  let result =
    Api.chartOfAccount +
    `${Api.tdsConfiguration}/drop-down?current_organization=${data.orgId}`;
  data.tdsId.map((id) => {
    result += `&tds_ids=${id}`;
  });
  return await get(result);
}

/**
 * API call for delete TDS
 */
export async function deleteTds(data: { tdsId: number; orgId: number }) {
  const result = await remove(
    Api.chartOfAccount +
      `${Api.tdsConfiguration}/delete/${data.tdsId}?current_organization=${data.orgId}`
  );
  return result;
}
