import React, { forwardRef } from "react";
import InputPrefix from "../../../../../../common/components/formPrefixInputField/InputPrefix";
import MultiExchangeRate from "../../../../../../common/components/exchangeRate/exchangeRateWithPopper/MutipleExchangeRate";
import { CurrencyList } from "../../../../../../../types";
import "./paymentAmount.css";

type Props = {
  changeHandler: (e: any) => void;
  label: string;
  subLabel: string;
  prefix: string;
  name: string;
  id: string;
  value: string;
  isDisabled: boolean;
  error: string;
  handleBlur?: (name: string, amount: number, index: number) => void;
  autoFocus?: boolean;
  conversionDate: string;
  conversionId: number | string;
  currencyCode: string;
  toCurrencyCode: string;
  currencyId: number | undefined;
  toCurrencyId: number;
  endAdornment?: React.ReactNode;
  isExchangeEditable: boolean;
  isExchangeRateHidden?: boolean;
  conversionRate: string;
  conversionTimestamp: string;
  placeholder?: string;
  exchangeRateSubmitHandler?: (data: any) => void;
  exchangeRateMaxLength?: number;
  wrappperClass?: string;
  byUser?: boolean;
};
const PaymentAmount = forwardRef((props: Props, ref) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  return (
    <div
      className={`col-12 col-lg-4 mb-2 payment-amount ${props.wrappperClass}`}
    >
      <label htmlFor="amount_received_bank_currency" className="required">
        {props.label}{" "}
        {props.subLabel && (
          <span className="sub-label">({props.subLabel})</span>
        )}
      </label>
      <InputPrefix
        prefix={props.prefix}
        inputType="text"
        name={props.name}
        id={props.id}
        className="form-control border-end-0 w-100"
        wrapperClass="max-w-100"
        placeholder={props.placeholder}
        value={Number(props.value) === 0 ? "" : props.value}
        disabled={props.isDisabled}
        error={props.error}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.changeHandler(e);
        }}
        isAmountField={true}
        onBlur={props.handleBlur}
        index={0}
        onKeyPress={handleKeyPress}
        endAdornment={props.endAdornment}
        autoFocus={props.autoFocus}
      />
      <span className="error">{props.error}</span>

      {!props.isExchangeRateHidden && (
        <MultiExchangeRate
          conversionDate={props.conversionDate}
          conversionId={props.conversionId}
          conversionRate={props.conversionRate}
          conversionTimestamp={props.conversionTimestamp}
          currencyCode={props.currencyCode}
          currencyId={props.currencyId}
          toCurrencyId={
            props.toCurrencyId ? Number(props.toCurrencyId) : undefined
          }
          toCurrencyCode={props.toCurrencyCode}
          position="start"
          isExchangeRateEditable={props.isExchangeEditable}
          isExchangeRateHidden={props.isExchangeRateHidden}
          exchangeRateSubmitHandler={props.exchangeRateSubmitHandler}
          maxLength={props.exchangeRateMaxLength}
          byUser={props.byUser}
        />
      )}
    </div>
  );
});

export default PaymentAmount;
