import Modal from "@mui/material/Modal";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { useEffect, useState } from "react";
import {
  CreateLockValues,
  CustomLockValues,
  ObjectType,
  UnlockValues,
  UpdateLockValues,
} from "../../../../../types";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../helpers/helper";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  createTransactionLock,
  unlockTransaction,
  updateTransactionLock,
} from "../../transactionLockingSlice";
import { currentUserSelector } from "../../../../common/commonSlice";
import { InfoIconImg, LockedItemRed } from "../../../../../assets/images";
import CloseButton from "../../../../common/components/button/CloseButton";
import { toast } from "react-toastify";
import CustomTooltips from "../../../../common/components/Tooltip";
import { Link } from "react-router-dom";

import "./LockTransactionModal.css";

type Props = {
  isLockAll: boolean;
  firstHeading: string;
  closeModal: (action: string, type: string) => void;
  moduleName: string;
  modalId: string;
  moduleIds: number[];
  action: string;
  getTransactionDetail: () => void;
  lockedModuleValues: CustomLockValues[];
};
const LockTransactionModal = (props: Props) => {
  const initialLockValues = {
    module_ids: props.moduleIds,
    locking_type: props.action,
    lock_reason: props.action === "lock" ? "" : null,
    unlock_reason: props.action === "partial_unlock" ? "" : null,
    start_date: props.action === "lock" ? null : getFormatedDate(),
    end_date: getFormatedDate(),
    is_lock_all: props.isLockAll,
  };
  const initialUnlockValues = {
    id: 0,
    module_id: 0,
    locking_type: "lock",
    unlock_reason: "",
  };
  const initialEditLockValues = {
    id: 0,
    module_id: 0,
    end_date: getFormatedDate(),
    lock_reason: "",
  };
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [lockFormValues, setLockFormValues] =
    useState<CreateLockValues>(initialLockValues);
  const [unlockFormValues, setUnlockFormValues] = useState<UnlockValues[]>([
    initialUnlockValues,
  ]);
  const [editLockFormValues, setEditLockFormValues] = useState<
    UpdateLockValues[]
  >([initialEditLockValues]);
  /**
   * set Edit and unlock form values from the transaction details
   */
  useEffect(() => {
    if (
      props.action === "unlock" ||
      props.action === "unlock_all" ||
      props.action === "unlock_all_transaction"
    ) {
      const unlockValues: UnlockValues[] = [];
      for (let module of props.lockedModuleValues) {
        unlockValues.push({
          id: module.id,
          module_id: module.module_id,
          locking_type: module.locking_type,
          unlock_reason: "",
        });
      }
      setUnlockFormValues(unlockValues);
    }
    if (props.action === "edit_lock") {
      const editLockValues: UpdateLockValues[] = [];
      for (let module of props.lockedModuleValues) {
        if (module.locking_type === "lock") {
          editLockValues.push({
            id: module.id,
            module_id: module.module_id,
            end_date: module.end_date,
            lock_reason: module.lock_reason,
          });
        }
      }
      setEditLockFormValues(editLockValues);
    }
  }, [props.lockedModuleValues]);
  /**
   * Lock / Partially Unlock / Edit / Unlock submit handler
   */
  const formSubmitHandler = async () => {
    $("#form-btn").addClass("customer-form-section-disable");
    const errors =
      props.action === "lock" || props.action === "partial_unlock"
        ? validateCreateLock(lockFormValues)
        : props.action === "unlock" ||
          props.action === "unlock_all" ||
          props.action === "unlock_all_transaction"
        ? validateUnlockForm(unlockFormValues)
        : validateEditLockForm(editLockFormValues);
    if (Object.keys(errors).length) {
      $("#form-btn").removeClass("customer-form-section-disable");
      setFormErrors(errors);
    } else {
      setFormErrors({});
      if (props.action === "lock" || props.action === "partial_unlock") {
        const responseAction = await dispatch(
          createTransactionLock({
            values: lockFormValues,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (props.action === "lock") {
              toast.success(
                "Locking transaction preferences have been updated",
                {
                  toastId: "lock-transaction-success",
                  closeButton: false,
                  position: "top-center",
                }
              );
            } else {
              toast.success("Partial unlock preferences have been updated", {
                toastId: "partial-unlock-transaction-success",
                closeButton: false,
                position: "top-center",
              });
            }
          }
        }
      }
      if (
        props.action === "unlock" ||
        props.action === "unlock_all" ||
        props.action === "unlock_all_transaction"
      ) {
        const responseAction = await dispatch(
          unlockTransaction({
            values: unlockFormValues,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            toast.success("Unlock preferences have been updated", {
              toastId: "lock-transaction-success",
              closeButton: false,
              position: "top-center",
            });
          }
        }
      }
      if (props.action === "edit_lock") {
        const responseAction = await dispatch(
          updateTransactionLock({
            values: editLockFormValues,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            toast.success("Locking transaction preferences have been updated", {
              toastId: "edit-lock-transaction-success",
              closeButton: false,
              position: "top-center",
            });
          }
        }
      }
      $("#form-btn").removeClass("customer-form-section-disable");
      props.closeModal(props.action, "submit");
      props.getTransactionDetail();
    }
  };
  /**
   * Modal close handler
   */
  const closeModalHandler = () => {
    props.closeModal(props.action, "cancel");
  };
  /**
   * Date change handler
   */
  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        if (type === "endLockDate" || type === "endPartialUnlockDate") {
          if (props.action === "edit_lock") {
            const editLockValues = [...editLockFormValues];
            for (let module of editLockValues) {
              module.end_date = "Invalid date";
            }
            setEditLockFormValues(editLockValues);
          } else {
            setLockFormValues((values) => ({
              ...values,
              end_date: "Invalid date",
            }));
          }
        } else if (type === "startPartialUnlockDate") {
          setLockFormValues((values) => ({
            ...values,
            start_date: "Invalid date",
          }));
        }
      } else if (new Date(date) > new Date()) {
        if (type === "endLockDate" || type === "endPartialUnlockDate") {
          if (props.action === "edit_lock") {
            const editLockValues = [...editLockFormValues];
            for (let module of editLockValues) {
              module.end_date = "Future date";
            }
            setEditLockFormValues(editLockValues);
          } else {
            setLockFormValues((values) => ({
              ...values,
              end_date: "Future date",
            }));
          }
        } else if (type === "startPartialUnlockDate") {
          setLockFormValues((values) => ({
            ...values,
            start_date: "Future date",
          }));
        }
      } else {
        const dateFormatted = getFormatedDate(date);
        if (type === "endLockDate" || type === "endPartialUnlockDate") {
          if (moment(dateFormatted, "YYYY-MM-DD", true).isValid()) {
            if (props.action === "edit_lock") {
              const editLockValues = [...editLockFormValues];
              for (let module of editLockValues) {
                module.end_date = dateFormatted;
              }
              setEditLockFormValues(editLockValues);
            } else {
              setLockFormValues((values) => ({
                ...values,
                end_date: dateFormatted,
              }));
            }
          }
        }
        if (type === "startPartialUnlockDate") {
          setLockFormValues((values) => ({
            ...values,
            start_date: dateFormatted,
          }));
        }
      }
    }
  };
  /**
   * Reason input change handler
   */
  const inputChangeHandler = (e: any) => {
    const { name, value } = e.target;
    if (props.action === "lock" || props.action === "partial_unlock") {
      setLockFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (
      props.action === "unlock" ||
      props.action === "unlock_all" ||
      props.action === "unlock_all_transaction"
    ) {
      const unlockValues = [...unlockFormValues];
      for (let module of unlockValues) {
        module.unlock_reason = value;
      }
      setUnlockFormValues(unlockValues);
    }
    if (props.action === "edit_lock") {
      const editLockValues = [...editLockFormValues];
      for (let module of editLockValues) {
        module.lock_reason = value;
      }
      setEditLockFormValues(editLockValues);
    }
  };
  /**
   * Validate create Lock or Partial unlock form values
   */
  const validateCreateLock = (values: CreateLockValues) => {
    const errors: ObjectType = {};
    if (props.action === "lock") {
      if (values.lock_reason?.trim() === "") {
        errors.lock_reason = "Please specify the reason";
      }
    }
    if (props.action === "partial_unlock") {
      if (values.unlock_reason?.trim() === "") {
        errors.unlock_reason = "Please specify the reason";
      }
      if (values.start_date && values.end_date) {
        if (new Date(values.start_date) > new Date(values.end_date)) {
          errors.start_date =
            "The start date cannot be greater than the end date";
          errors.end_date =
            "The start date cannot be greater than the end date";
        }
      }
    }
    if (values.end_date === "Invalid date") {
      errors.end_date = "Please choose a valid date";
    }
    if (values.end_date === "Future date") {
      errors.end_date =
        "Future dates are not allowed, the date must be on or before the current date";
    }
    if (values.start_date === "Invalid date") {
      errors.start_date = "Please choose a valid date";
    }
    if (values.start_date === "Future date") {
      errors.start_date =
        "Future dates are not allowed, the date must be on or before the current date";
    }
    return errors;
  };
  /**
   * Validate Unlock form values
   */
  const validateUnlockForm = (values: UnlockValues[]) => {
    const errors: ObjectType = {};
    for (let module of values) {
      if (module.unlock_reason?.trim() === "") {
        errors.unlock_reason = "Please specify the reason";
        break;
      }
    }
    return errors;
  };
  /**
   * Validate Edit lock form values
   */
  const validateEditLockForm = (values: UpdateLockValues[]) => {
    const errors: ObjectType = {};
    if (values.length) {
      if (values[0].lock_reason.trim() === "") {
        errors.lock_reason = "Please specify the reason";
      }
      if (values[0].end_date === "Invalid date") {
        errors.end_date = "Please choose a valid date";
      }
      if (values[0].end_date === "Future date") {
        errors.end_date =
          "Future dates are not allowed, the date must be on or before the current date";
      }
    }
    return errors;
  };
  /**
   * function to get Lock end date
   */
  const getEndDate = () => {
    if (props.action === "edit_lock") {
      return new Date(editLockFormValues[0].end_date);
    } else {
      return lockFormValues.end_date
        ? new Date(lockFormValues.end_date)
        : new Date();
    }
  };
  /**
   * function to get Lock reason
   */
  const getReason = () => {
    if (props.action === "edit_lock") {
      return editLockFormValues[0].lock_reason;
    } else {
      if (props.action === "lock" && lockFormValues.lock_reason !== null) {
        return lockFormValues.lock_reason;
      }
      if (
        props.action === "partial_unlock" &&
        lockFormValues.unlock_reason !== null
      ) {
        return lockFormValues.unlock_reason;
      }
    }
  };

  return (
    <Modal
      open={true}
      tabIndex={-1}
      onClose={closeModalHandler}
      aria-labelledby="lock_modal"
      className={`lock-modal ${
        props.action === "unlock_all"
          ? "lock-with-list-modal"
          : props.action === "unlock_all_transaction"
          ? "unlock_all_notify_modal"
          : ""
      }`}
      aria-hidden="true"
      id={props.modalId}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header position-relative">
              <h1>
                {props.firstHeading}{" "}
                {props.action !== "unlock_all_transaction" && ":"}{" "}
                {props.moduleName}
              </h1>
              <div className="close-button-container">
                <CloseButton closeModalHandler={closeModalHandler} />
              </div>
            </div>
            <div className="modal-body user-form">
              <form action="" className="lock-item-form m-0">
                <div className="form-field-container w-100">
                  {props.action === "unlock_all" && (
                    <>
                      <p>The following modules are locked:</p>
                      <div className="list-wrapper">
                        <ul className="locked-items-list p-0 m-0 w-100">
                          {props.lockedModuleValues
                            .filter((item) => item.locking_type === "lock")
                            .map((items, index) => {
                              return (
                                <li key={index}>
                                  <div className="item-wrapper w-100 d-flex">
                                    <img
                                      src={LockedItemRed}
                                      alt="locked-item-img"
                                      width={32}
                                      height={32}
                                    />
                                    <div className="item-det-sect">
                                      <h5>{items.moduleName}</h5>
                                      <p>
                                        Lock Date:{" "}
                                        {DateFormatHandler(items.end_date)}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </>
                  )}
                  {(props.action === "lock" ||
                    props.action === "edit_lock") && (
                    <div className="form-outline w-100">
                      <label htmlFor="lock_date" className="required">
                        Lock Date
                      </label>
                      <CustomTooltips
                        placement="right"
                        title="Transactions recorded before this date will be locked."
                      >
                        <Link
                          to="#"
                          className="info-btn position-relative"
                        >
                          <img src={InfoIconImg} width="16" alt="info icon" />
                        </Link>
                      </CustomTooltips>
                      <div id="date-one" className="date-selector-wrapper">
                        <CustomDatepicker
                          date={getEndDate()}
                          handleDate={handleDate}
                          type="endLockDate"
                          id="lock_date"
                          error={formErrors.end_date ? "error" : ""}
                          zIndex={1322}
                        />
                      </div>
                      <span className="error">{formErrors.end_date}</span>
                    </div>
                  )}
                  {props.action === "partial_unlock" && (
                    <div className="form-outline w-100">
                      <label
                        htmlFor="partial_unlock_date_1"
                        className="required"
                      >
                        Partial Unlock Period
                      </label>
                      <CustomTooltips
                        placement="right"
                        title="Transactions can be created, modified or deleted during the unlock period."
                      >
                        <Link
                          to="#"
                          className="info-btn position-relative"
                        >
                          <img src={InfoIconImg} width="16" alt="info icon" />
                        </Link>
                      </CustomTooltips>
                      <div className="row g-0">
                        <div className="col left-calendar">
                          <div id="date-one" className="date-selector-wrapper">
                            <CustomDatepicker
                              date={
                                lockFormValues.start_date
                                  ? new Date(lockFormValues.start_date)
                                  : new Date()
                              }
                              handleDate={handleDate}
                              type="startPartialUnlockDate"
                              id="partial_unlock_date_1"
                              error={formErrors.start_date ? "error" : ""}
                              zIndex={1322}
                            />
                          </div>
                        </div>
                        <div className="col hiphen"></div>
                        <div className="col right-calendar">
                          <div id="date-two" className="date-selector-wrapper">
                            <CustomDatepicker
                              date={
                                lockFormValues.end_date
                                  ? new Date(lockFormValues.end_date)
                                  : new Date()
                              }
                              handleDate={handleDate}
                              type="endPartialUnlockDate"
                              id="partial_unlock_date_2"
                              error={formErrors.end_date ? "error" : ""}
                              zIndex={1322}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="error">
                        {formErrors.start_date || formErrors.end_date}
                      </span>
                    </div>
                  )}
                  <div
                    className={`${
                      props.action === "unlock_all"
                        ? "form-field-container w-100 mt-24"
                        : ""
                    }`}
                  >
                    {props.action === "unlock_all_transaction" ? (
                      <div className="form-outline w-100 mb-16">
                        <p className="attention-txt">
                          All transactions have been locked
                        </p>
                        <p className="m-0">
                          If you want to individually select the modules you
                          want to lock, you must first unlock all transactions.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={`form-outline w-100 ${
                        props.action === "unlock_all" ? "mb-24" : ""
                      }`}
                    >
                      <label htmlFor="reason" className="required">
                        Reason
                      </label>
                      <textarea
                        name={
                          props.action === "lock"
                            ? "lock_reason"
                            : "unlock_reason"
                        }
                        id="reason"
                        className={`w-100 ${
                          formErrors.lock_reason || formErrors.unlock_reason
                            ? "error"
                            : ""
                        }`}
                        cols={10}
                        rows={4}
                        placeholder={`Specify a reason for ${
                          props.action === "unlock_all" ||
                          props.action === "unlock" ||
                          props.action === "unlock_all_transaction" ||
                          props.action === "partial_unlock"
                            ? "un"
                            : ""
                        }locking transactions.`}
                        onChange={(e) => inputChangeHandler(e)}
                        value={getReason()}
                      ></textarea>
                      <span className="error">
                        {formErrors.lock_reason || formErrors.unlock_reason}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="form-button-wrapper w-100" id="form-btn">
                <button className="save-button" onClick={formSubmitHandler}>
                  {(props.action === "lock" && !props.isLockAll) ||
                  props.action === "edit_lock"
                    ? "Lock"
                    : props.action === "unlock_all"
                    ? "Unlock All"
                    : props.action === "lock" && props.isLockAll
                    ? "Lock All"
                    : props.action === "unlock_all_transaction"
                    ? "Unlock all Transactions"
                    : "Unlock"}
                </button>
                <button className="cancel-button" onClick={closeModalHandler}>
                  Cancel
                </button>
              </div>
              {(props.action === "partial_unlock" ||
                props.action === "unlock_all" ||
                (props.action === "lock" && props.isLockAll)) && (
                <div className="info-block w-100">
                  {props.action === "partial_unlock" && (
                    <p>
                      During the unlock period, transactions can be created,
                      modified or deleted. However, it is important to ensure
                      that all modules affected by the transaction are also
                      unlocked to avoid any inconsistencies or issues.
                    </p>
                  )}
                  {props.action === "unlock_all" && (
                    <p>
                      You have to unlock all the modules in order to lock it
                      under a single date.
                    </p>
                  )}
                  {props.action === "lock" && props.isLockAll && (
                    <p>
                      All the modules will be locked. If you want to unlock, you
                      can either unlock individual modules or unlock all.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LockTransactionModal;
