import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import AddressModal from "../../../../common/components/AddressModal";
import {
  DateFormatHandler,
  downloadUsingURL,
  getFormatedDate,
} from "../../../../../helpers/helper";
import {
  RoundOffPreferencesData,
  billingEmptyObject,
  dropdownValueRepeatInvoices,
  initialDefaultValues,
  initialInvoiceValues,
  initialItemValues,
  initialTCSTotalValues,
  initialTDSTotalValues,
  initialTaxValues,
  initialTotalValues,
  shippingEmptyObject,
} from "./StateInitialization";
import DefaultDetails from "./DefaultDetails";
import {
  AddressValues,
  CustomerItemValues,
  FilesValues,
  RecurringInvoiceDefaultRef,
  RecurringInvoiceDefaultValues,
  RecurringInvoiceValues,
  ItemRef,
  ItemTaxValues,
  ObjectType,
  TCSTotalValues,
  TDSTotalValues,
  TotalAmountCalculationValues,
  TotalDetailsValues,
} from "../../../../../types";
import {
  exchangeApiStatusSelector,
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import {
  getTaxableAmount,
  getAmountCalculation,
  attachInvoiceFiles,
  deleteInvoiceFile,
  createInvoice,
  editRecurringInvoice,
  checkProfileName,
  recurringInvoiceDetails,
  deleteRInvoiceRecipientDetails,
  deleteRInvoiceItemTaxDetails,
  deleteRInvoiceItemDetails,
  changePreferredInvoiceStatus,
} from "../../recurringInvoiceSlice";
import DropZone from "../../../../common/components/DropZone/DropZone";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import TransactionHeader from "./TransactionHeader";
import ItemDetails from "../../../../common/components/lineItems/ItemDetails";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import ScrollToError from "../../../../common/components/scrollToError";
import {
  gstRegistrationTypes,
  noAccessErrorCodes,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import {
  addAddress,
  addressReset,
  addressSelector,
} from "../../../customerManagement/components/customerForm/AddressDetails/addressSlice";
import { customerDetailsForTransactions } from "../../../customerManagement/customerSlice";
import "./Invoice.css";
import RecurringFormFooter from "./recurringFormFooter";
import OrganizationDetails from "./OrganizationDetail";

import { validate, validateItemValues } from "./ValidateInvoiceForm";

import useGst from "../../../../hooks/useGst";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { clearGstTaxList, getGstTaxList } from "../../../gst/gstSlice";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import "./invoiceForm.css";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import {
  fetchRoundOffPreference,
  fetchRIPreference,
} from "../../../preferences/preferencesSlice";
import InvoiceAddress from "./invoiceAddress/InvoiceAddress";
import {
  DropdownDataType,
  DropdownStateType,
  RoundOffPreferences,
} from "../../recurringInvoiceTypes";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { accountsGlCodeSelector } from "../../../chartOfAccounts/coaSlice";

let interval: any = null;

const RecurringInvoiceForm = () => {
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isClone = location.pathname.includes("clone");
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { invoicesRolePermission } = usePermissionHandler();
  const defaultRef = useRef<RecurringInvoiceDefaultRef | null>(null);
  const itemRef = useRef<ItemRef | null>(null);
  const addressList = useAppSelector(addressSelector);
  const accountsGlCode = useAppSelector(accountsGlCodeSelector);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const [isCustomerChanged, setIsCustomerChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [invoiceFormValues, setInvoiceFormValues] =
    useState<RecurringInvoiceValues>(initialInvoiceValues);
  const [defaultFormValues, setDefaultFormValues] =
    useState<RecurringInvoiceDefaultValues>({
      ...initialDefaultValues,
      customer_id: "",
    });
  const [itemFormValues, setItemFormValues] = useState<CustomerItemValues[]>([
    initialItemValues,
  ]);
  const [taxFormValues, setTaxFormValues] = useState<CustomerItemValues[]>([
    initialItemValues,
  ]);
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDetailsValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [itemFormErrors, setItemFormErrors] = useState<CustomerItemValues[]>(
    []
  );
  const [isProfileNameExist, setIsProfileNameExist] = useState(false);
  const [recurringInvoiceStatus, setRecurringInvoiceStatus] = useState("");
  const [billingAddressFormValues, setBillingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [shippingAddressFormValues, setShippingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [billingAddressFormValuesForEdit, setBillingAddressFormValuesForEdit] =
    useState<AddressValues | undefined>();
  const [
    shippingAddressFormValuesForEdit,
    setShippingAddressFormValuesForEdit,
  ] = useState<AddressValues | undefined>();
  const [isCustomerName, setIsCustomerName] = useState<boolean>(false);
  const [customerData, setCustomerData] =
    useState<AddressValues[] | undefined>();
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [showCustomiseModal, setShowCustomiseModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emailId: number }[]>(
    []
  );
  const [customerIdForEdit, setCustomerIdForEdit] = useState();
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [formSubmit, setFormSubmit] = useState(true);
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const fileRef = useRef<any>([]);
  const [conversionDate, setConversionDate] = useState(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [invoiceValuesForEdit, setInvoiceValuesForEdit] =
    useState<RecurringInvoiceValues>(initialInvoiceValues);
  const [conversionCurrencyId, setConversionCurrencyId] = useState<number>();
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState<number | string>(0);
  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState<number | string>(0);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressId, setAddressId] = useState<number | string>("");
  const [customerBillingAddressId, setCustomerBillingAddressId] = useState(0);
  const [customerShippingAddressId, setCustomerShippingAddressId] = useState(0);
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(false);
  const [isShippingAddressSame, setIsShippingAddressSame] = useState(false);
  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [roundoffData, setRoundoffData] = useState<RoundOffPreferences>(
    RoundOffPreferencesData
  );
  const [roundoffDataOnSave, setRoundoffDataOnSave] =
    useState<RoundOffPreferences>(RoundOffPreferencesData);
  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [sourceTaxType, setSourceTaxType] = useState(sourceTaxTypes.TCS);
  const [totalAmount, setTotalAmount] = useState(0);
  const [unableToSaveModalTriggered, setUnableToSaveModalTriggered] =
    useState<number>();
  const [transactionTaxDetails, setTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
  const [isSez, setIsSez] = useState(false);
  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();
  const unableToSaveModalRef = useRef<any>();
  const { isGstOrg } = useGst();
  const [editTotal, setEditTotal] = useState(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);
  const [gstRegistrationType, setGstRegistrationType] = useState("");
  const [repeatEvery, setRepeatEvery] = useState<DropdownStateType>({
    label: "",
    value: "",
  });
  const [frequency, setFrequency] = useState<DropdownStateType>({
    label: "",
    value: "",
  });
  const [isSendEmailToggleEnable, setIsSendEmailToggleEnable] =
    useState<boolean>(true);
  /**
   * To check whether invoice address is same as customer address
   */

  useEffect(() => {
    if (editId) {
      let updatedAddress = addressList.map((address: any) => {
        return {
          address: address.address,
          city: address.city,
          country_id: address.country_id,
          is_billing: address.is_billing,
          is_shipping: address.is_shipping,
          phone: address.phone,
          state_id: address.state_id,
          zip_code: address.zip_code,
        };
      });
      let billingAddress = { ...billingAddressFormValues };
      let shippingAddress = { ...shippingAddressFormValues };
      delete billingAddress.country_name;
      delete billingAddress.customer_address_id;
      delete billingAddress.id;
      delete billingAddress.state_name;
      delete shippingAddress.country_name;
      delete shippingAddress.customer_address_id;
      delete shippingAddress.id;
      delete shippingAddress.state_name;
      setIsBillingAddressSame(false);
      setIsShippingAddressSame(false);
      for (let address of updatedAddress) {
        if (address.is_billing) {
          if (_.isEqual(address, billingAddress)) {
            setSelectedBillingAddressId(customerBillingAddressId);
            setIsBillingAddressSame(true);
          }
        }
        if (address.is_shipping) {
          if (_.isEqual(address, shippingAddress)) {
            setSelectedShippingAddressId(customerShippingAddressId);
            setIsShippingAddressSame(true);
          }
        }
      }
    }
  }, [addressList]);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId) {
      setIsCustomerName(false);
      getRoundOffPreference();
      getInvoicePreference();
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
    }
    if (editId) {
      fetchRecurringInvoiceDetails();
    }
    dispatch(addressReset());
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    unableToSaveModalTriggered &&
      unableToSaveModalRef.current?.openAlertModal &&
      unableToSaveModalRef.current?.openAlertModal();
  }, [unableToSaveModalTriggered]);

  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    setRoundoffData(payload);
    setRoundoffDataOnSave(payload);
  };

  const getInvoicePreference = async () => {
    const response = await dispatch(
      fetchRIPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    if (payload.preferred_invoice_status) {
      let defaultValues = defaultRef.current?.defaultData;
      setDefaultFormValues({
        ...defaultFormValues,
        ...defaultValues,
        preferred_invoice_status: payload.preferred_invoice_status,
      });
    }
  };

  const openAddressModal = () => {
    setShowAddressModal(true);
  };
  const closeAddressModal = () => {
    setShowAddressModal(false);
  };

  const getRepeatEveryDropDownLabel = (value: string) => {
    const setData = dropdownValueRepeatInvoices?.find(
      (data) => data.value === value
    ) || { label: "", value: "" };
    return setData;
  };
  /**
   * Get invoice details for the edit and clone recurring invoice purpose.
   */
  const fetchRecurringInvoiceDetails = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      recurringInvoiceDetails({
        invoiceId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );

    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;

      if (Object.keys(response).length && !("error" in response) && isSameOrg) {
        let defaultValues = {
          customer_id: response.customer_id,
          customer_name: response.customer_name,
          profile_name: isClone ? "" : response.profile_name,
          repeat_every: response.repeat_every,
          custom_repeat_count: response.custom_repeat_count,
          invoice_number: response.invoice_number,
          invoice_date: getFormatedDate(response.invoice_date),
          payment_term_id: response.payment_term_id,
          due_date: getFormatedDate(response.due_date),
          currency_id: response.currency_id,
          reference_number: response.reference_number,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          place_of_supply: response.place_of_supply,
          invoice_status: response.invoice_status,
          preferred_invoice_status: response.preferred_invoice_status,
          is_mutable: true,
          conversion_id: response.conversion_id,
          conversion_date: response.conversion_date,
          custom_fields: response.custom_fields,
          start_on: isClone ? getFormatedDate(new Date()) : response.start_on,
          ends_on: isClone ? "" : response.ends_on,
          tcs_percentage: response.tcs_percentage,
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_amount: response.tcs_amount,
          total: response.total,
          never_expire: isClone ? true : response.never_expire,
          custom_repeat_frequency: response.custom_repeat_frequency,
          tds_id: response.tds_id,
          tds_amount: response.tds_amount,
          tds_account_id: response.tds_account_id,
          items: response.item_details,
          address: response.address_details,
          customer_notes: response.customer_notes,
          file_ids:
            response?.recurring_invoice_files?.map((file: any) => file.id) ||
            [],
          tds_percentage: response.tds_percentage,
          email_to: response.email_to,
          is_email_reminder_automatic: response.is_email_reminder_automatic,
        };
        setSourceTaxType(response.source_tax_type);
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name,
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name,
        };
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setUploadedFiles(response?.recurring_invoice_files || []);
        setFileIds(
          response?.recurring_invoice_files?.map((file: any) => file.id) || []
        );
        setInsertedFiles(
          response?.recurring_invoice_files?.map((file: any) => file.id) || []
        );
        setRepeatEvery(getRepeatEveryDropDownLabel(response.repeat_every));
        setFrequency(
          getRepeatEveryDropDownLabel(response.custom_repeat_frequency)
        );
        let customerDetails = await getCustomerDetails(
          response.customer_id,
          "invoice"
        );
        let preferenceResponse = {
          account_id: response?.roundoff_account_id,
          account_name:
            accountsGlCode.account_list.filter(
              (data: any) => data?.id === response?.roundoff_account_id
            )?.[0]?.account_name || "",
          is_enabled: response?.roundoff_enabled || false,
          is_total_editable: response?.is_total_editable || false,
          roundoff_transaction_type: response?.roundoff_transaction_type,
        };
        setRoundoffData(preferenceResponse);
        setRoundoffDataOnSave(preferenceResponse);
        if (isGstOrg) {
          let stateType =
            !response.place_of_supply || !currentUserInfo.organization_gst_state
              ? ""
              : response.place_of_supply ===
                currentUserInfo.organization_gst_state
              ? "Intra State"
              : "Inter State";
          if (
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sez ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            stateType = "Inter State";
          }
          setStateType(stateType);
          setInitialStateType(stateType);

          let transaction_tax_details = [] as any;
          response.item_details.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group ? true : false,
                tax_id: gst_tax?.tax_id,
              });
            }
          });

          setTransactionTaxDetails(transaction_tax_details);

          await fetchGstTaxList(
            stateType,
            customerDetails.gst_registration_type,
            transaction_tax_details
          );
          setGstRegistrationType(customerDetails.gst_registration_type);
        }
        setConversionDate(getFormatedDate(response.invoice_date));
        setRecurringInvoiceStatus(response.invoice_status);
        setCustomerId(response.customer_id);
        setCustomerIdForEdit(response.customer_id);
        setCurrencyCode(response.currency_code);
        setCurrencyId(response.currency_id);
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setConversionCurrencyId(response.currency_id);
        setInvoiceFormValues({ ...defaultValues });
        // setDefaultFormValues({ ...defaultValues });
        setInvoiceValuesForEdit({ ...defaultValues });
        setDefaultFormValues({ ...defaultValues });
        setIsSendEmailToggleEnable(response.is_email_reminder_automatic);
        let addressData = response.address_details;
        addressData.map((values: AddressValues) => {
          if (values.is_billing) {
            setBillingAddressFormValues(values);
            setBillingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerBillingAddressId(values.customer_address_id);
            }
          }
          if (values.is_shipping) {
            setShippingAddressFormValues(values);
            setShippingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerShippingAddressId(values.customer_address_id);
            }
          }
        });
        let itemDetails = response.item_details;
        let tdsIdArray: number[] = [];
        itemDetails.map((item: any, index: number) => {
          if (item.discount_type === "Absolute") {
            item.discount = item.discount.discount_value;
          } else if (item.discount_type === "Percentage") {
            item.discount = item.discount.discount_percentage;
          }
          if (!item.taxes.length) {
            item.taxes = [initialTaxValues];
          }
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (itemDetails.length) {
          setItemFormValues(itemDetails);
        } else {
          setItemFormValues([initialItemValues]);
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
        }
        setTdsIds(tdsIdArray);
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
        }
        setTcsIds(tcsIdArray);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.email_to;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setEditTotal(response?.total);
      }
    }
  };
  /**
   * Set invoice default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    // setInvoiceFormValues({ ...invoiceFormValues, [name]: value });
    setDefaultFormValues({ ...defaultFormValues, [name]: value });
  };

  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };

  const asynchronousSubmit = async (status: string, action: string) => {
    await handleCustomSubmission();
    handleSubmit(status, action);
  };

  // const handleCustomerChange = async (e: any) => {
  //   if (e.value && e.value !== customerIdForEdit) {
  //     setIsCustomerChanged(true);
  //     let response = await getCustomerDetails(Number(e.value), "customer");
  //     let state_type = response.is_sez || response.other_details.tax_preference === 'NON_TAXABLE' ? "Inter State" : stateType;

  //     if (state_type !== "") {
  //       await fetchGstTaxList(
  //         state_type,
  //         response.is_sez,
  //         transactionTaxDetails
  //       );
  //     } else {
  //       dispatch(clearGstTaxList());
  //     }

  //     setIsSez(response.is_sez);
  //     setIsCustomerName(true);
  //     setCustomerId(Number(e.value));
  //     setBillingAddressFormValues(undefined);
  //     setShippingAddressFormValues(undefined);
  //   } else {
  //     getCustomerDetails(Number(e.value), "invoice");
  //     setInvoiceFormValues(invoiceValuesForEdit);
  //     setBillingAddressFormValues(billingAddressFormValuesForEdit);
  //     setShippingAddressFormValues(shippingAddressFormValuesForEdit);
  //     setCustomerId(customerIdForEdit);

  //     let emailContacts = invoiceValuesForEdit.email_to;
  //     let contactEmail: string[] = [];
  //     let emailDetails: any[] = [];
  //     let removeEmails: { emailId: number }[] = deleteEmailIds;
  //     emailContacts.map((contact: any) => {
  //       contactEmail.push(contact.email);
  //       emailDetails.push(contact);
  //       removeEmails = removeEmails.filter(
  //         (email) => email.emailId !== contact.id
  //       );
  //     });
  //     setEmailLists(contactEmail);
  //     setEmailData(emailDetails);
  //     setDeleteEmailIds(removeEmails);
  //     setCurrencyId(Number(invoiceValuesForEdit.currency_id));
  //   }
  //   setSelectedBillingAddressId(0);
  //   setSelectedShippingAddressId(0);
  // };

  const handleCustomerChange = async (e: any) => {
    if (e.value && e.value !== customerIdForEdit) {
      setIsCustomerChanged(true);
      let response = await getCustomerDetails(Number(e.value), "customer");
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas ||
        response.other_details.tax_preference === "NON_TAXABLE"
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }

      if (state_type !== "") {
        await fetchGstTaxList(
          state_type,
          response.gst_registration_type,
          transactionTaxDetails
        );
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsCustomerName(true);
      setCustomerId(Number(e.value));
      setBillingAddressFormValues(undefined);
      setShippingAddressFormValues(undefined);
    } else {
      getCustomerDetails(Number(e.value), "invoice");
      setInvoiceFormValues(invoiceValuesForEdit);
      setBillingAddressFormValues(billingAddressFormValuesForEdit);
      setShippingAddressFormValues(shippingAddressFormValuesForEdit);
      setCustomerId(customerIdForEdit);

      let emailContacts = invoiceValuesForEdit.email_to;
      let contactEmail: string[] = [];
      let emailDetails: any[] = [];
      let removeEmails: { emailId: number }[] = deleteEmailIds;
      emailContacts.map((contact: any) => {
        contactEmail.push(contact.email);
        emailDetails.push(contact);
        removeEmails = removeEmails.filter(
          (email) => email.emailId !== contact.id
        );
      });
      setEmailLists(contactEmail);
      setEmailData(emailDetails);
      setDeleteEmailIds(removeEmails);
      setCurrencyId(Number(invoiceValuesForEdit.currency_id));
    }
    setSelectedBillingAddressId(0);
    setSelectedShippingAddressId(0);
  };

  useEffect(() => {
    customerId && getCustomerDetails(customerId, "invoice");
  }, [customerId]);

  /**
   * Store customer email contacts to delete if customer name is changed on Edit invoice
   */
  useEffect(() => {
    if (editId) {
      emailData.map((contact) => {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      });
    }
  }, [isCustomerName]);

  const setErrorOnItemTaxChange = (errors: CustomerItemValues[]) => {
    setItemFormErrors([...errors]);
  };

  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (customerId: number, type: string) => {
    const responseAction = await dispatch(
      customerDetailsForTransactions({
        customerId: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        dispatch(addressReset());
        let addressData = response.address_details;
        addressData.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : 0,
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : 0,
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : null,
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
            id: addressDetails.id,
            state_name: addressDetails.state_name,
            country_name: addressDetails.country_name,
          };
          dispatch(addAddress(address));
        });
        if (type === "invoice") {
          setCustomerData(response.address_details);
          let defaultFormValues = {
            customer_exemption_type: response.other_details.tax_preference,
            customer_exemption_id: response.other_details.exemption_reason_id,
          };
          if (defaultRef.current?.defaultData) {
            setDefaultFormValues((prevData) => {
              return {
                ...prevData,
                ...defaultRef.current?.defaultData,
                ...defaultFormValues,
              };
            });
          }
        } else {
          setCustomerData(response.address_details);
          if (defaultRef.current?.defaultData) {
            const exemptionType =
              response.other_details.tax_preference === "Non-Taxable"
                ? "NON_TAXABLE"
                : response.other_details.tax_preference === "Taxable"
                ? "TAXABLE"
                : "";
            let defaultFormValues = {
              ...defaultRef.current?.defaultData,
              payment_term_id: response.other_details.payment_term_id,
              currency_id: response.other_details.currency_id,
            };
            setDefaultFormValues({ ...defaultFormValues });
          }
          let contacts = response.contact_persons;
          let contactEmail: string[] = [];
          contacts.map((contact: any) => {
            if (contact.email) contactEmail.push(contact.email);
          });
          setEmailLists(contactEmail);
          setCurrencyId(response.other_details.currency_id);
        }
      }
      return responseAction.payload;
    }
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (itemRef.current?.totalTdsValues.tds_id || itemDetails?.length) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string) => {
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: CustomerItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let invoiceInputs: any = {
      ...invoiceFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      custom_fields: custom_fields.current.values,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
      is_email_reminder_automatic: isSendEmailToggleEnable,
      roundoff_enabled: roundoffData.is_enabled,
    };
    let errors,
      itemErrorResponse: any = [];
    let itemErrors: any = [];
    errors = validate(
      invoiceInputs,
      action,
      "",
      emailLists,
      isProfileNameExist,
      itemValues,
      fileRef,
      isGstOrg
    );

    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;
    let taxValues = itemRef.current?.taxValues
      ? itemRef.current?.taxValues
      : taxFormValues;
    itemValues.map((item, itemIndex) => {
      itemErrorResponse = validateItemValues(item, itemIndex, "", taxValues);
      if (itemErrorResponse) {
        if (itemErrorResponse) {
          ScrollToError(Object.keys(itemErrorResponse)[0]);
        }
        errorExist = true;
        itemErrors.push(itemErrorResponse);
      } else {
        itemErrors.push({});
      }
    });
    if (
      Object.keys(errors).length ||
      (errorExist && itemErrors.length) ||
      customFieldRef.current.errors
    ) {
      setFormErrors(errors);
      setItemFormErrors(itemErrors);
    } else {
      setFormErrors({});
      setItemFormErrors([]);
      if (editId) {
        if (isClone) createInvoiceSubmit(invoiceInputs, itemValues, action);
        else UpdateRecurringInvoiceSubmit(invoiceInputs, itemValues);
      } else {
        createInvoiceSubmit(invoiceInputs, itemValues, action);
      }
    }
  };
  /**
   * Create new invoice
   */
  const createInvoiceSubmit = async (
    invoiceInputs: any,
    itemValues: CustomerItemValues[],
    action: string
  ) => {
    dispatch(setLoaderState(true));
    invoiceInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof invoiceInputs;
    for (key in invoiceInputs) {
      if (invoiceInputs[key] === "") {
        invoiceInputs[key] = null;
      }
      if (key === "tds_percentage") {
        invoiceInputs[key] =
          invoiceInputs[key] === "" || invoiceInputs[key] === null
            ? 0
            : Number(invoiceInputs[key]);
      }
      if (key === "reference_number" && invoiceInputs[key]) {
        invoiceInputs[key] = invoiceInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        invoiceInputs[key] = invoiceInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");

    let invoiceItems = JSON.parse(JSON.stringify(itemValues));

    for (let items of invoiceItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    invoiceInputs.items = invoiceItems;
    invoiceInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      invoiceInputs.email_to = emailLists;
    }
    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedBillingAddressId,
      });
    } else {
      address.push(billingEmptyObject);
    }
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedShippingAddressId,
      });
    } else {
      address.push(shippingEmptyObject);
    }
    invoiceInputs.address = address;

    invoiceInputs.total = calculatedTotal;
    invoiceInputs.is_total_editable = roundoffData.is_total_editable;
    invoiceInputs.roundoff_account_id = roundoffData.account_id;
    invoiceInputs.roundoff_transaction_type =
      roundoffData.roundoff_transaction_type;
    invoiceInputs.custom_repeat_frequency = frequency?.value || "";
    invoiceInputs.custom_fields = null;
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        invoiceInputs.tds_preference = "TRANSACTION_LEVEL";
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        invoiceInputs.tds_preference = "LINE_LEVEL";
      }
    }
    // invoiceInputs.custom_fields= {
    //   "Unit Price": invoiceInputs.unit_price
    // }
    if (!invoiceInputs.never_expire && !invoiceInputs.ends_on) {
      invoiceInputs.never_expire = true;
      invoiceInputs.ends_on = null;
    } else if (invoiceInputs.never_expire) {
      invoiceInputs.ends_on = null;
    }

    if (invoiceInputs.start_on === "Empty date") {
      invoiceInputs.start_on = getFormatedDate(new Date());
    }
    const createInvoiceResponseAction = await dispatch(
      createInvoice({
        values: invoiceInputs,
        status: "Active",
        isSendMail: action === "Send" ? true : false,
        isPrintOrDownload:
          action === "Download" || action === "Print" ? true : false,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (createInvoiceResponseAction.payload) {
      const createInvoiceResponse = createInvoiceResponseAction.payload;
      if (
        Object.keys(createInvoiceResponse).length &&
        !("error" in createInvoiceResponse)
      ) {
        dispatch(setLoaderState(false));
        navigate(`/recurring-inv/detail/${Number(createInvoiceResponse.id)}`, {
          replace: true,
        });
        toast.success("Recurring Invoice created successfully!", {
          toastId: "invoice-create-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in createInvoiceResponse) {
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };

  const UpdateRecurringInvoiceSubmit = async (
    invoiceInputs: any,
    itemValues: CustomerItemValues[]
  ) => {
    dispatch(setLoaderState(true));
    invoiceInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof invoiceInputs;
    for (key in invoiceInputs) {
      if (invoiceInputs[key] === "") {
        invoiceInputs[key] = null;
      }

      if (key === "payment_term_id" && invoiceInputs[key] !== "") {
        if (invoiceInputs[key] === null) {
          invoiceInputs[key] = null;
        } else {
          invoiceInputs[key] = Number(invoiceInputs[key]);
        }
      }

      if (key === "tds_percentage") {
        invoiceInputs[key] =
          invoiceInputs[key] === "" || invoiceInputs[key] === null
            ? 0
            : Number(invoiceInputs[key]);
      }
      if (key === "reference_number" && invoiceInputs[key]) {
        invoiceInputs[key] = invoiceInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        invoiceInputs[key] = invoiceInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    if (editId) {
      let deleteItems = itemRef.current?.deleteItems;
      if (deleteItems) {
        for (let item of deleteItems) {
          if (item.itemId) {
            await dispatch(
              deleteRInvoiceItemDetails({
                invoiceId: Number(editId),
                itemId: item.itemId,
                orgId: currentUserInfo.organization_id,
              })
            );
          }
        }
      }
    }
    let deleteTaxItems = itemRef.current?.deleteTaxItems;
    if (deleteTaxItems) {
      for (let item of deleteTaxItems) {
        await dispatch(
          deleteRInvoiceItemTaxDetails({
            itemId: item.itemId,
            taxId: item.taxItemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }
    if (isGstOrg) {
      let deleteGstItems = itemRef.current?.deleteGstItems;
      if (deleteGstItems) {
        for (let item of deleteGstItems) {
          await dispatch(
            deleteRInvoiceItemTaxDetails({
              itemId: item.itemId,
              taxId: item.id,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
    }
    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emailId);
    });

    if (deleteContactIds.length) {
      await dispatch(
        deleteRInvoiceRecipientDetails({
          invoiceId: Number(editId),
          emailIds: deleteContactIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let invoiceItems = JSON.parse(JSON.stringify(itemValues));

    for (let items of invoiceItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }
      let key: keyof typeof itemInputs;

      for (key in itemInputs) {
        if (itemInputs[key] === "") {
          if (key === "rate" || key === "quantity") {
            itemInputs[key] = 0;
          } else {
            itemInputs[key] = null;
          }
        }
        if (key === "discount") {
          if (itemInputs[key]?.discount_value) {
            itemInputs[key] = itemInputs[key]?.discount_value;
          } else if (itemInputs[key]?.discount_percentage) {
            itemInputs[key] = itemInputs[key]?.discount_percentage;
          } else {
            itemInputs[key] = Number(itemInputs[key]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });

      items.taxes = taxData;
    }
    invoiceInputs.items = invoiceItems;
    invoiceInputs.file_ids = fileRef.current.fileIds;
    invoiceInputs.email_to = emailLists;
    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedBillingAddressId,
      });
    } else {
      address.push(billingEmptyObject);
    }
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedShippingAddressId,
      });
    } else {
      address.push(shippingEmptyObject);
    }
    invoiceInputs.address = address;

    invoiceInputs.total = calculatedTotal;
    invoiceInputs.is_total_editable = roundoffData.is_total_editable;
    invoiceInputs.custom_repeat_frequency = frequency?.value || "";
    // invoiceInputs.custom_fields= {
    //   "Unit Price": invoiceInputs.unit_price
    // }

    if (!invoiceInputs.never_expire && !invoiceInputs.ends_on) {
      invoiceInputs.never_expire = true;
      invoiceInputs.ends_on = null;
    } else if (invoiceInputs.never_expire) {
      invoiceInputs.ends_on = null;
    }

    if (invoiceInputs.start_on === "Empty date") {
      invoiceInputs.start_on = getFormatedDate(new Date());
    }

    const modifiedRIdetails = JSON.parse(
      JSON.stringify({
        customer_id: invoiceInputs.customer_id,
        customer_name: invoiceInputs.customer_name,
        reference_number: invoiceInputs.reference_number,
        start_on: invoiceInputs.start_on,
        payment_term_id: invoiceInputs.payment_term_id,
        ends_on: invoiceInputs.ends_on,
        customer_notes: invoiceInputs.customer_notes,
        custom_fields: invoiceInputs.custom_fields,
        tds_id: invoiceInputs.tds_id,
        tds_amount: invoiceInputs.tds_amount,
        tds_percentage: invoiceInputs.tds_percentage,
        tds_account_id: invoiceInputs.tds_account_id,
        tds_level: invoiceInputs.tds_level,
        tcs_id: invoiceInputs.tcs_id,
        tcs_amount: invoiceInputs.tcs_amount,
        tcs_percentage: invoiceInputs.tcs_percentage,
        tcs_account_id: invoiceInputs.tcs_account_id,
        place_of_supply: invoiceInputs.place_of_supply,
        source_tax_type: invoiceInputs.source_tax_type,
        total: invoiceInputs.total,
        never_expire: invoiceInputs.never_expire,
        custom_repeat_count: invoiceInputs.custom_repeat_count,
        custom_repeat_frequency: invoiceInputs.custom_repeat_frequency,
        repeat_every: invoiceInputs.repeat_every,
        profile_name: invoiceInputs.profile_name,
        conversion_id: invoiceInputs.conversion_id,
        conversion_date: invoiceInputs.conversion_date,
        file_ids: invoiceInputs.file_ids,
        items: invoiceInputs.items,
        email_to: invoiceInputs.email_to,
        address: invoiceInputs.address,
        is_email_reminder_automatic: invoiceInputs.is_email_reminder_automatic,
        roundoff_enabled: invoiceInputs.roundoff_enabled,
        is_total_editable: roundoffData.is_total_editable,
        roundoff_account_id: roundoffData.account_id,
        roundoff_transaction_type: roundoffData.roundoff_transaction_type,
      })
    );
    const updateRInvoiceResponseAction = await dispatch(
      editRecurringInvoice({
        id: Number(editId),
        status:
          recurringInvoiceStatus.toLowerCase() === "expired"
            ? "Active"
            : recurringInvoiceStatus,
        editRecurringInvoiceInputs: modifiedRIdetails,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateRInvoiceResponse = updateRInvoiceResponseAction.payload;
    if (
      Object.keys(updateRInvoiceResponse).length &&
      !("error" in updateRInvoiceResponse)
    ) {
      dispatch(setLoaderState(false));
      navigate(`/recurring-inv/detail/${Number(editId)}`, { replace: true });
      toast.success("Recurring Invoice updated successfully!", {
        toastId: "invoice-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in updateRInvoiceResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  const handleAddressFormValues = async (values: AddressValues) => {
    if (values.is_billing === true) {
      setBillingAddressFormValues(values);
    }
    if (values.is_shipping === true) {
      setShippingAddressFormValues(values);
    }
  };

  useEffect(() => {
    customerData?.map((item: any) => {
      if (
        item.is_billing === true &&
        ((item.is_primary && selectedBillingAddressId === 0) ||
          item.id === selectedBillingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isBillingAddressSame) setSelectedBillingAddressId(0);
          const billingAddressCopy = { ...billingAddressFormValuesForEdit };
          delete billingAddressCopy.id;
          delete billingAddressCopy.is_billing;
          delete billingAddressCopy.is_shipping;
          const isNullish = Object.values(billingAddressCopy).every((value) => {
            if (value === null) {
              return true;
            }
            return false;
          });
          if (!isNullish) {
            setBillingAddressFormValues(billingAddressFormValuesForEdit);
          } else {
            setSelectedBillingAddressId(item.id);
            setBillingAddressFormValues(item);
          }
        } else {
          setSelectedBillingAddressId(item.id);
          setBillingAddressFormValues(item);
        }
      }
      if (
        item.is_shipping === true &&
        ((item.is_primary && selectedShippingAddressId === 0) ||
          item.id === selectedShippingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isShippingAddressSame) setSelectedShippingAddressId(0);
          const shippingAddressCopy = { ...shippingAddressFormValuesForEdit };
          delete shippingAddressCopy.id;
          delete shippingAddressCopy.is_billing;
          delete shippingAddressCopy.is_shipping;
          const isNullish = Object.values(shippingAddressCopy).every(
            (value) => {
              if (value === null) {
                return true;
              }
              return false;
            }
          );
          if (!isNullish) {
            setShippingAddressFormValues(shippingAddressFormValuesForEdit);
          } else {
            setSelectedShippingAddressId(item.id);
            setShippingAddressFormValues(item);
          }
        } else {
          setSelectedShippingAddressId(item.id);
          setShippingAddressFormValues(item);
        }
      }
    });
  }, [customerData]);

  /**
   * Function to set Primary billing address
   */
  const selectBillingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedBillingAddressId(index);
    }
  };
  /**
   * Function to set Primary shipping address
   */
  const selectShippingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedShippingAddressId(index);
    }
  };

  const showInvoicePreview = () => {
    navigate("../invoice-detail");
  };

  /**
   * Function to store emailIds to be deleted in Edit invoice
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      }
    });
  };
  /**
   * Check invoice number already exist
   */
  const checkInvoiceNumberExist = async (profileName: string) => {
    if (!profileName.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkProfileName({
            profileName: profileName,
            orgId: currentUserInfo.organization_id,
            invoiceId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.profile_name_available === false) {
              setIsProfileNameExist(true);
              setFormErrors({
                ...formErrors,
                ["profile_name"]:
                  "Profile Name already exists. Please choose a different one",
              });
            } else {
              setIsProfileNameExist(false);
              setFormErrors({ ...formErrors, ["profile_name"]: "" });
            }
          }
        }
      }, 1000);
    }
  };
  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    const responseAction = await dispatch(getAmountCalculation(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: CustomerItemValues;
    orgId: number;
    signal: Object;
  }) => {
    data.values.discount_type = data.values.discount_type
      ? data.values.discount_type
      : "Absolute";
    data.values.quantity = data.values.quantity ? data.values.quantity : 1;
    const taxResponseAction = await dispatch(getTaxableAmount(data));
    return taxResponseAction;
  };

  /**
   * function to get upload files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("invoice_file", file);
    let responseAction = await dispatch(
      attachInvoiceFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteInvoiceFile({
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: CustomerItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let invoiceInputs: any = {
      ...invoiceFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
    };
    let errors = validate(
      invoiceInputs,
      "",
      "",
      emailLists,
      isProfileNameExist,
      itemValues,
      fileRef,
      isGstOrg
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0 && files.status !== "removed"
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };

  /**
   * set address ID handler
   */
  const setAddressIdHandler = (id: number | string) => {
    setAddressId(id);
  };

  const fetchConsumerDetail = (customerId: number) => {
    getCustomerDetails(customerId, "invoice");
  };

  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };
  // const fetchGstTaxList = async (
  //   stateType: string,
  //   is_sez?: boolean,
  //   transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  // ) => {
  //   if (!transaction_tax_details && transactionTaxDetails) {
  //     transaction_tax_details = transactionTaxDetails;
  //   }
  //   if (is_sez === undefined) {
  //     is_sez = isSez;
  //   }
  //   if (is_sez) {
  //     stateType = "Inter State";
  //   }
  //   dispatch(clearGstTaxList());
  //   await dispatch(
  //     getGstTaxList({
  //       stateType: stateType,
  //       orgId: currentUserInfo.organization_id,
  //       is_sez: false,
  //       transaction_tax_details,
  //     })
  //   );
  // };

  // const fetchGstTaxList = async (
  //   stateType: string,
  //   gstRegType?: string,
  //   transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  // ) => {
  //   if (!transaction_tax_details && transactionTaxDetails) {
  //     transaction_tax_details = transactionTaxDetails;
  //   }
  //   if (
  //     gstRegType === gstRegistrationTypes.sez ||
  //     gstRegType === gstRegistrationTypes.sezDeveloper ||
  //     gstRegType === gstRegistrationTypes.overseas
  //   ) {
  //     stateType = "Inter State";
  //   }
  //   dispatch(clearGstTaxList());
  //   await dispatch(
  //     getGstTaxList({
  //       stateType: stateType,
  //       orgId: currentUserInfo.organization_id,
  //       is_sez: false,
  //       transaction_tax_details,
  //       transactionSource: "Invoices",
  //     })
  //   );
  // };

  const fetchGstTaxList = async (
    stateType: string,
    gstRegType?: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  ) => {
    if (!transaction_tax_details && transactionTaxDetails) {
      transaction_tax_details = transactionTaxDetails;
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      stateType = "Inter State";
    }
    dispatch(clearGstTaxList());
    await dispatch(
      getGstTaxList({
        stateType: stateType,
        orgId: currentUserInfo.organization_id,
        is_sez: false,
        transaction_tax_details,
        transactionSource: "Invoices",
      })
    );
  };

  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };

  const getTotalValues = (total: number | string) => {
    setTotalAmount(Number(total));
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };

  const handleSavePreference = async (val: any) => {
    if (editId) {
      await dispatch(
        changePreferredInvoiceStatus({
          recurring_invoice_id: editId,
          preferred_invoice_status: val,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    setDefaultFormValues({
      ...defaultFormValues,
      ...defaultValues,
      preferred_invoice_status: val,
    });
  };
  const handleSaveRoundOff = async (val: any) => {
    let data = {
      ...roundoffData,
      account_id: val === "Round off" ? roundoffData.account_id : 0,
      account_name: val === "Round off" ? roundoffData.account_name : "",
      is_enabled: val === "Round off",
    };
    setRoundoffData(data);
    setRoundoffDataOnSave(data);
  };

  const onAccountChange = async (val: DropdownStateType) => {
    if (roundoffData?.is_enabled && !val?.value)
      setFormErrors({
        ...formErrors,
        accountValidationError: "Please select the value",
      });
    else setFormErrors({ ...formErrors, accountValidationError: "" });
    setRoundoffData({
      ...roundoffData,
      account_id: Number(val.value),
      account_name: val.label,
    });
  };
  const onIsTotalEditable = async (event: any) => {
    setRoundoffData({
      ...roundoffData,
      is_total_editable: event.target.checked,
    });
  };

  const resetAccountData = async () => {
    let preferenceResponse = {
      account_id: roundoffDataOnSave.account_id,
      account_name:
        accountsGlCode.account_list.filter(
          (data: any) => data?.id === roundoffDataOnSave.account_id
        )?.[0]?.account_name || "",
      is_total_editable: roundoffDataOnSave.is_total_editable || false,
    };
    setRoundoffData({ ...roundoffData, ...preferenceResponse });
  };

  return (
    <div
      className="ri-form card card-user-management card-customer card-create-item invoice-create main-card overflowX-hidden h-100"
      id="invoice-create"
    >
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <TransactionHeader
          transactionName="Recurring Invoice"
          transactionNumber={isClone ? 0 : Number(editId)}
          handleSavePreference={handleSavePreference}
          handleSaveRoundOff={handleSaveRoundOff}
          preferredInvoiceStatus={defaultFormValues.preferred_invoice_status}
          isRoundOffEnabled={
            roundoffData.is_enabled ? "Round off" : "No rounding"
          }
          roundoffData={roundoffData}
          onAccountChange={onAccountChange}
          formErrors={formErrors}
          onIsTotalEditable={onIsTotalEditable}
          resetAccountData={resetAccountData}
        />
      </div>
      <div className="card-body">
        <form
          name="add_invoice_form"
          id="add_invoice_form"
          className={`add-module-item-form ${
            defaultRef.current?.defaultData?.customer_id ? "" : " inactive"
          }`}
          tabIndex={-1}
        >
          <div className="fields-wrapper pt-0">
            <div className="fields-wrappers">
              <DefaultDetails
                defaultFormValues={defaultFormValues}
                invoiceFormValues={invoiceFormValues}
                handleCustomerChange={handleCustomerChange}
                checkInvoiceNumberExist={checkInvoiceNumberExist}
                formErrors={formErrors}
                organizationId={currentUserInfo.organization_id}
                handleNewConversionDate={handleNewConversionDate}
                ref={defaultRef}
                customFieldRef={customFieldRef}
                getCustomFieldData={getCustomFieldData}
                customFieldValue={customFields}
                setStateType={setStateType}
                fetchGstTaxList={fetchGstTaxList}
                setSupplyState={setSupplyState}
                initialStateType={initialStateType}
                setRepeatEvery={setRepeatEvery}
                setFrequency={setFrequency}
                repeatEvery={repeatEvery}
                frequency={frequency}
                setIsStateChanged={setIsStateChanged}
              />
              {(isCustomerName || editId) && (
                <InvoiceAddress
                  billingAddressFormValues={billingAddressFormValues}
                  shippingAddressFormValues={shippingAddressFormValues}
                  customerData={customerData}
                  createBillingAddress={(values) =>
                    handleAddressFormValues(values)
                  }
                  createShippingAddress={(values) =>
                    handleAddressFormValues(values)
                  }
                  selectBillingAddressHandler={selectBillingAddressHandler}
                  selectShippingAddressHandler={selectShippingAddressHandler}
                  selectedBillingAddressId={selectedBillingAddressId}
                  selectedShippingAddressId={selectedShippingAddressId}
                  openAddressModal={openAddressModal}
                  setAddressIdHandler={setAddressIdHandler}
                  customerId={customerId}
                  customerIdForEdit={customerIdForEdit}
                />
              )}
              <ItemDetails
                formValues={invoiceFormValues}
                itemFormValues={itemFormValues}
                totalFormValues={totalFormValues}
                formErrors={formErrors}
                itemFormErrors={itemFormErrors}
                currencyId={currencyId}
                currencyCode={currencyCode}
                amountCalculation={amountCalculation}
                taxableAmount={taxableAmount}
                isHsnCode={true}
                taxType="payable"
                lineItemBaseAccount={"Incomes~Liabilities"}
                discountBaseAccount="Expenses"
                conversionDate={conversionDate}
                newConversionDate={newConversionDate}
                conversionCurrencyId={conversionCurrencyId}
                userId={customerId}
                componentType={"recurringInvoice"}
                setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                totalTdsValues={totalTdsValues}
                totalTcsValues={totalTcsValues}
                sourceTaxType={sourceTaxType}
                tdsIds={tdsIds}
                tcsIds={tcsIds}
                ref={itemRef}
                stateType={stateType}
                supplyState={supplyState}
                getTotalValue={getTotalValues}
                isSez={isSez}
                gstRegistrationType={gstRegistrationType}
                isRoundOffEnabled={roundoffData.is_enabled}
                isTotalEditable={roundoffData.is_total_editable}
                editTotal={editTotal}
                updateCalculatedTotal={updateCalculatedTotal}
                hideExchangeRate={true}
                customerDetails={defaultFormValues}
                isStateChanged={isStateChanged}
                isCustomerVendorChanged={isCustomerChanged}
              >
                <NoteTextArea
                  label="Customer Notes"
                  name="customer_notes"
                  id="customerNotes"
                  className="notes-textarea"
                  placeholder="Enter notes here..."
                  // value={invoiceFormValues.customer_notes}
                  value={defaultFormValues.customer_notes}
                  onChange={handleChange}
                  formErrors={formErrors}
                />
              </ItemDetails>
              <section id="upload_section">
                <div className="upload-header pb-3">Attach Files</div>
                <DropZone
                  uploadedFiles={uploadedFiles}
                  uploadFileIds={fileIds}
                  uploadAttachedFiles={uploadAttachedFiles}
                  removeFile={removeFile}
                  handleSubmitAction={handleSubmitAction}
                  ref={fileRef}
                />
                <span className="error">{formErrors.attachFiles}</span>
              </section>
              <div className="email-reciepients-wrapper">
                <Grid container sx={{ maxWidth: "682px" }}>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={7}
                    sx={{ alignSelf: "flex-end" }}
                  >
                    <label htmlFor="deposit_to">Email to</label>
                  </Grid>
                  <Grid
                    item
                    columns={2}
                    xs={10}
                    sm={10}
                    md={5}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <FormControlLabel
                      className="emailEnableAndDisable"
                      control={
                        <Switch
                          checked={isSendEmailToggleEnable}
                          color="primary"
                          disableRipple
                        />
                      }
                      label="Automatic Reminder Emails"
                      labelPlacement="start"
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsSendEmailToggleEnable(!isSendEmailToggleEnable);
                      }}
                    />
                  </Grid>
                </Grid>
                <MultiEmailBox
                  onChange={setEmailHandler}
                  emailList={emailLists}
                  removeEmailHandler={removeEmailHandler}
                />
                <span className="error">{formErrors.email}</span>
              </div>
              <OrganizationDetails />
            </div>
            <RecurringFormFooter
              editId={editId}
              isLocked={false}
              lockDate={""}
              name="Invoice"
              isFormSubmit={formSubmit && formSubmitAction}
              onClickSubmit={asynchronousSubmit}
              rolePermission={invoicesRolePermission}
              isPrint={true}
              isDisabledDraft={exchangeRateAPiStatus ? true : false}
              onClickDelete={() => {}}
            />
          </div>
        </form>
      </div>
      {showAddressModal ? (
        <AddressModal
          closeAddressModal={closeAddressModal}
          addressId={addressId}
          createdFrom="invoice_page"
          fetchConsumerDetail={fetchConsumerDetail}
          consumerId={customerId}
          selectedBillingAddressId={selectedBillingAddressId}
          billingAddressFormValues={billingAddressFormValues}
          storeNewAddressIdHandler={() => {}}
        />
      ) : null}
    </div>
  );
};

export default RecurringInvoiceForm;
