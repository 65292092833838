import { useFormik } from "formik";
import { useState } from "react";
import { FormValues } from "../types";

export const useTagSelection = () => {
  const [intialValues, setInitialValues] = useState<FormValues
  >({ options: []  });
  const {
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
    setFieldValue,
    setValues,
    touched,
    isSubmitting,
    submitCount,
    values,
    initialValues,
    dirty,

    ...formik
  } = useFormik({
    initialValues: intialValues,
    onSubmit: () => {},
  });
  return {
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    submitCount,
    isSubmitting,
    setFieldValue,
    setValues,
    setInitialValues,
    values,
    initialValues,
    dirty,
  };
};
