import React from "react";
import { Link } from "react-router-dom";
import { CancelInvite, EmailInfoIcon } from "../../../../assets/images";
// CancelInvite
// import CancelInvite from "../../../common/components/customSvgIcons/CancelInvite";
import { deleteUserDetails } from "../../../home/userManagement/userSlice";
import { toast } from "react-toastify";
import useCommonData from "../../../hooks/useCommon";
type Props = {
  userId: number;
  refreshList: () => void;
};
const CancelUserInvite = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  /**
   * Cancel Invite handler
   */
  const cancelInviteHandler = async (userId: number) => {
    await dispatch(
      deleteUserDetails({ id: userId, orgId: currentUserInfo.organization_id })
    );
    toast.info("Invitation cancelled", {
      icon: () => (
        <img src={EmailInfoIcon} width="17" height="17" alt="info-img" />
      ),
      closeButton: false,
      position: "top-center",
      className: "toast-info",
    });
    props.refreshList();
  };
  return (
    <Link
      className="dropdown-item delete"
      to="#"
      onClick={() => cancelInviteHandler(props.userId)}
    >
      {/* <img src={CancelInvite} alt="invite-again-img" /> */}
      <span>
        {/* <CancelInvite /> */}
        <img src={CancelInvite} alt="invite-again-img" />
        Cancel Invite
      </span>
    </Link>
  );
};

export default CancelUserInvite;
