import { useEffect, useRef, useState } from "react";
import ReportFilter from "../componets/ReportFilter";
import { PayableSummaryTable } from "./payableSummaryTable";
import ReportHeader from "../componets/ReportHeader";
import {
  getPayableSummaryReport,
  reportTableFilterSelector,
} from "../ReportSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../common/commonSlice";
import { ObjectType } from "../../../../types";
import { getFormatedDate } from "../../../../helpers/helper";
import { useLocation } from "react-router-dom";

export const PayableSummaryReport = () => {
  const location = useLocation();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const tableFilterValue = useAppSelector(reportTableFilterSelector);
  const dispatch = useAppDispatch();
  const [showReport, setShowReport] = useState(false);
  const [payableSummaryData, setPayableSummaryData] = useState<any>({});
  const reportRef = useRef<any>([]);
  const [filterString, setFilterString] = useState("");
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  const initialFiltervalues = {
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    date_range: filters ? filters.date_range : "today",
    start_date: filters ? filters.start_date : getFormatedDate(),
    end_date: filters ? filters.end_date : getFormatedDate(),
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
  };

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    )
      getPayableSummary("initial");
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const handleReport = (type: string, argFilters: any = {}) => {
    getPayableSummary("");
  };

  const getPayableSummary = async (type: string) => {
    dispatch(setLoaderState(true));
    let filterValues: any = tableFilterValue;
    if (type === "initial" || !filterValues) filterValues = initialFiltervalues;
    const tempFilterString = JSON.stringify(filterValues);
    const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
    setFilterString(hashFragment);
    const responseAction = await dispatch(
      getPayableSummaryReport({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let reportData: ObjectType[] = response.detail_list;
        if (reportData.length > 0) {
          reportData.push({
            vendor_id: null,
            currency_id: null,
            currency_code: "",
            organization_id: "",
            organization_name: "",
            vendor_name: "Total",
            transaction_id: null,
            transaction_date: "",
            transaction_number: "",
            transaction_type: "",
            transaction_status: "",
            reference_number: null,
            total: null,
            balance: null,
            total_base: response.total_amount_base,
            balance_base: response.total_balance_base,
          });
        }

        setPayableSummaryData({ ...response, detail_list: reportData });
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    } else {
      dispatch(setLoaderState(false));
    }
    setShowReport(true);
  };
  return (
    <div className="reports ">
      <div
        className="card card-dashboard report-card  w-100"
        id="soa-report-card"
      >
        <div className="card-header p-0 border-0">
          <div className="header-wrap">
            <h1>Payable Summary</h1>
          </div>
        </div>
        <div className="report-body w-100">
          <div className="top-filter-container filter-box w-100">
            <ReportFilter
              isSingleDatePicker={false}
              isAdvanced={true}
              defaultValue="today"
              reportType="PS"
              handleReport={handleReport}
              isEntity={false}
              entityType=""
              clearReport={() => {
                setShowReport(false);
              }}
              ref={reportRef}
            />
          </div>
          {showReport ? (
            <div className="report-section w-100">
              <div className="report-body-links justify-content-between align-items-center ">
                {/* <ReportHeaderActionLinks /> */}
              </div>
              <ReportHeader
                reportData={payableSummaryData}
                reportName={"Payable Summary"}
              />
              <div className="table-section report-table-section">
                <PayableSummaryTable
                  payableSummaryData={payableSummaryData}
                  filterString={filterString}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
