import { DateFormatHandler, getFormatedDate } from "../../../../../helpers/helper";
import { PRICE_RANGE_PRICING_MODEL } from "../../../../../helpers/planHelper";
import { findRangeDataPrice, totalRangeAmount } from "../../../../../helpers/subscriptionHelper";
import { ObjectType } from "../../../../../types";
import { ConfigureCouponInitialValue } from "../../../couponConfiguration/configureCouponType";
import {
  AddAddonFormInitialValueType,
  AddPlanFormInitialValueType,
  RangePriceValue,
  SubscriptionCouponList,
  SubscriptionItemValues,
  SubscriptionPlanDetails,
  SubscriptionValues,
} from "../../../subscription/subscriptionType";

/**
 * Validate form values
 */
export const validate = (
  subscriptionFormValues: SubscriptionValues,
  isSubscriptionNumberExist: boolean,
  emailLists: [] | string[],
  planItemValues: SubscriptionPlanDetails,
  isGstOrg: boolean
) => {
  const errors: ObjectType = {};
  if (isSubscriptionNumberExist) {
    errors.subscription_number =
      "Subscription number already exists. Please choose a different one";
  }
  
  if(!subscriptionFormValues.subscription_number.split('~')[1]) {
    errors.subscription_number =
      "Please enter a valid subscription number";
  }

  if (emailLists?.length === 0) {
    errors.email = "Please enter at least one email address";
  }

  if (planItemValues.itemDetails && planItemValues.itemDetails.length <= 0) {
    errors.planItems = "* Please add at least one plan";
  }

  if (isGstOrg && !subscriptionFormValues.place_of_supply) {
    errors.place_of_supply = "Please select place of supply";
  }

  if (subscriptionFormValues.start_subscription === "CHOOSE_DATE") {
    if(!isValidDateFormat(subscriptionFormValues.start_date)) {
      errors.start_date = "Please enter a valid start date";
    }
    if(subscriptionFormValues.start_date === "Empty date") {
      errors.start_date = "Please enter start date";
    }
  }

  if(subscriptionFormValues.invoicing_cycle === "FIXED") {
    if(!subscriptionFormValues.invoicing_cycle_fixed_count || subscriptionFormValues.invoicing_cycle_fixed_count < 1) {
      errors.invoicing_cycle_fixed_count = "Please enter a valid value";
    }
  }

  return errors;
};

function isValidDateFormat(dateString: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

export const validateItemValues = (
  invoiceFormValues: any,
  values: SubscriptionItemValues | any,
  itemIndex: number,
  taxFormValues: SubscriptionItemValues[],
  pricingModal: string
) => {
  const errors: ObjectType = {};
  let flag = false;
  if (
    pricingModal !== "FLAT_FEE" &&
    (!values.quantity || Number(values.quantity) === 0)
  ) {
    errors.quantity = "Please enter quantity";
    flag = true;
  }
  if (
    ["FLAT_FEE", 'PER_UNIT'].includes(pricingModal) &&
    !values.rate &&
    Number(values.rate) === 0
  ) {
    errors.rate = "Please enter rate";
    flag = true;
  }
  if (values.discount) {
    if (
      pricingModal === "PER_UNIT" &&
      values.discount_type === "ABSOLUTE" &&
      values.free_quantity &&
      Number(values.discount) >
        Number(values.rate) * Number(values.quantity - values.free_quantity)
    ) {
      errors.discount =
        "Discount cannot not be higher than rate * (quantity - free qty)";
      flag = true;
    } else if (
      pricingModal === "PER_UNIT" &&
      values.discount_type === "ABSOLUTE" &&
      !values.free_quantity &&
      Number(values.discount) > Number(values.rate) * Number(values.quantity)
    ) {
      errors.discount = "Discount cannot not be higher than rate * quantity";
      flag = true;
    } else if (
      pricingModal === "FLAT_FEE" &&
      values.discount_type === "ABSOLUTE" &&
      Number(values.discount) > Number(values.rate)
    ) {
      errors.discount = "Discount cannot not be higher than rate";
      flag = true;
    } else if (
      ["TIERED", "VOLUME"].includes(pricingModal) &&
      values.discount_type === "ABSOLUTE" &&
      Number(values.discount) >
        totalRangeAmount(values.range_price, Number(values.quantity))
    ) {
      errors.discount = "Discount cannot not be higher than pricing range";
      flag = true;
    } 
    else if (
      ["STAIRSTEP"].includes(pricingModal) &&
      values.discount_type === "ABSOLUTE" &&
      Number(values.discount) >
      findRangeDataPrice(values.range_price, Number(values.quantity))
    ) {
      errors.discount = "Discount cannot not be higher than pricing range";
      flag = true;
    }else if (
      values.discount_type === "PERCENTAGE" &&
      Number(values.discount) > 100
    ) {
      errors.discount = "Discount cannot not be higher than 100%";
      flag = true;
    }
  }

  if (
    PRICE_RANGE_PRICING_MODEL.includes(pricingModal) &&
    values.range_price &&
    values.range_price.length > 0 &&
    (!!values.range_price[values.range_price.length - 1].range_to
      && values.range_price[values.range_price.length - 1].range_to !== -1) &&
    values.quantity > values.range_price[values.range_price.length - 1].range_to
  ) {
    errors.quantity = "Quantity cannot not be higher than pricing range";
    flag = true;
  }

  if (values.tds_name === "") {
    if (Number(values.tds_amount) !== 0) {
      errors.tds_name = "Please choose TDS type";
      flag = true;
    }
  } else if (values.tds_name !== "") {
    if (Number(values.tds_amount) > values.rate) {
      errors.tds_amount = "TDS amount must not exceed total taxable amount";
      flag = true;
    }
  }
  if (!values.discount && values.discount_account_id) {
    errors.discount = "Please enter discount";
    flag = true;
  }
  if (values.discount && !values.discount_account_id) {
    errors.discount_account_id = "Please choose discount account";
    flag = true;
  }
  if (!values.account_id) {
    errors.account_id = "Please enter account";
    flag = true;
  }
  let hsnRegex = /^\d{6}$|^\d{8}$/;
  if (values.hsn_or_sac_code === null) {
    values.hsn_or_sac_code = "";
  }
  if (
    values.hsn_or_sac_code &&
    !hsnRegex.test(values.hsn_or_sac_code.toString()) &&
    values.hsn_or_sac_code !== ""
  ) {
    errors.hsn_or_sac_code = "Please enter valid hsn/sac code";
    flag = true;
  }

  // // need to check
  if (taxFormValues[itemIndex]?.taxes) {
    let taxes = taxFormValues[itemIndex].taxes;
    let taxLabels: any[] = [];
    let taxError: any[] = [];
    for (let tax of taxes) {
      if (taxLabels.includes(tax.tax_id)) {
        flag = true;
        taxError.push({
          tax_id: "Tax label already used",
          tax_percentage: 0,
        });
      } else if (tax.tax_id === "" && Number(tax.tax_percentage) > 0) {
        flag = true;
        taxError.push({
          tax_id: "Please choose tax code",
          tax_percentage: 0,
        });
      } else {
        taxError.push({ tax_id: "", tax_percentage: 0 });
        if (tax.tax_id) {
          taxLabels.push(tax.tax_id);
        }
      }
    }
    errors.taxes = taxError;
  }
  if (flag) return errors;
  else return false;
};

/**
 * Validate form Add Plan form values
 */
export const validateAddPlanForm = (
  addPlanFormValues: AddPlanFormInitialValueType
) => {
  const errors: ObjectType = {};
  if (addPlanFormValues.selectedPlan.value === "") {
    errors.select_plan = "Please select plan name";
  }

  if (
    addPlanFormValues.selectedPlan.value !== "" &&
    addPlanFormValues.selectedFrequency.value === ""
  ) {
    errors.billing_frequency = "Please select frequency";
  }

  return errors;
};

/**
 * Validate form Add Addon form values
 */
export const validateAddAddonForm = (
  addAddonFormValues: AddAddonFormInitialValueType
) => {
  const errors: ObjectType = {};
  if (addAddonFormValues.selectedAddon.value === "") {
    errors.select_addon = "Please select addon name";
  }

  if (
    addAddonFormValues.selectedAddon.value !== "" &&
    addAddonFormValues.selectedFrequency.value === ""
  ) {
    errors.billing_frequency = "Please select frequency";
  }

  return errors;
};


/**
 * Validate Update form values
 */
export const validatUpdateForm = (
  updatedValue: any,
  subscriptionStopDate: string,
  subscriptionCancelDate: string,
  subscriptionEndDate: string
) => {
  const errors: ObjectType = {};
  if ( updatedValue.update_type === 'specific_date') {
    if(!isValidDateFormat(updatedValue.updation_date)) {
      errors.updation_date = "Please enter a valid updation date";
    }
    if(updatedValue.updation_date === "Empty date") {
      errors.updation_date = "Please enter start date";
    }
  }
  if ( updatedValue.updation_date
    && !subscriptionCancelDate && subscriptionStopDate) {
      const date = new Date(updatedValue.updation_date)
      const updatedDate = date.setHours(0, 0, 0, 0);
      const [day, month, year] = subscriptionStopDate.split('-').map(Number);
      const stopScheduledDate = new Date(`${month}-${day}-${year}`)
      const stopDate = stopScheduledDate.setHours(0, 0, 0, 0);

  if (stopDate < updatedDate){
    errors.updation_date = `Edit Schedule date should be on or before Stop scheduled date ${subscriptionStopDate}`;
  } 
    }
  if ( updatedValue.updation_date
    && subscriptionCancelDate) {
      const date = new Date(updatedValue.updation_date)
      const updatedDate = date.setHours(0, 0, 0, 0);
      const [day, month, year] = subscriptionCancelDate.split('-').map(Number);
      const cancelScheduledDate = new Date(`${month}-${day}-${year}`)
      const cancelDate = cancelScheduledDate.setHours(0, 0, 0, 0);

  if (!(cancelDate > updatedDate)){
    errors.updation_date = `Edit Schedule date should be before cancel scheduled date ${subscriptionCancelDate}`;
  } 
}

if ( updatedValue.updation_date
  && subscriptionEndDate) {
    const date = new Date(updatedValue.updation_date)
    const updatedDate = date.setHours(0, 0, 0, 0);
    const [day, month, year] = DateFormatHandler(subscriptionEndDate).split('-').map(Number);
    const subEndDate = new Date(`${month}-${day}-${year}`)
    const endDate = subEndDate.setHours(0, 0, 0, 0);
if ((endDate < updatedDate)){
  errors.updation_date = `Edit Schedule date should be on or before ${DateFormatHandler(subscriptionEndDate)}`;
} 
}
  return errors;
};

/**
 * Validate form Add Plan form values
 */

function isPositiveInteger(value: any) {
  typeof value === "number" ? (value = String(value)) : (value = value);
  let arr = value.split(".");
  if (arr[1]) {
    return true;
  }
}
export const validatePricingRangeUpdate = (
  rangePrice: RangePriceValue[]
) => {
  let errors: ObjectType = {};

  let priceErrors: any[] = []
  rangePrice.forEach((priceObj, index) => {
        const price_per_step_error  =  (priceObj.price_per_step || priceObj.price_per_step === 0) ? "" : "Please enter a price"
        const range_to_error =  rangePrice.length - 1 === index && priceObj.range_to && priceObj.range_from
              ? (priceObj.range_to <
                  priceObj.range_from ||
                isPositiveInteger(priceObj.range_to)) && !(priceObj.range_to === -1)
                ? "Value should be greater than from value and a positive integer"
                : ""
              : ""
        priceErrors.push({price_per_step: price_per_step_error, range_to: range_to_error})
       
      });
    if (priceErrors?.length > 0
      && (priceErrors.filter((data) => data.price_per_step !== '').length > 0 ||
      priceErrors.filter((data) => data.range_to !== '').length > 0)) {
      errors.pricingErrors = priceErrors;
    } else errors = {}
  return errors;
};


export const isUpdationDateValid = (updationDate: string, nextRenewableDate: string) => {
  if (!!nextRenewableDate){
  const renewableDate = new Date((DateFormatHandler(nextRenewableDate)));
  renewableDate.setHours(0, 0, 0, 0);
  const targetDate = new Date(getFormatedDate(new Date(updationDate)));
  targetDate.setHours(0, 0, 0, 0);
  
  if (targetDate < renewableDate) {
    return true
  }}
  return false
}