import { useEffect, useState } from "react";
import Select from "react-select";
import "../styles/AddressModal.css";
import { colors } from "../../constants/colors";
import { customSelectStyle } from "./SelectCustomStyle";
import { AddressValues, LocationValues, ObjectType } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  countrySelector,
  currentUserSelector,
  getCountries,
  getCountryStates,
} from "../commonSlice";
import {
  addAddress,
  addressSelector,
  editAddress,
} from "../../home/customerManagement/components/customerForm/AddressDetails/addressSlice";
import { addressTypeSelector } from "../../home/customerManagement/components/customerForm/AddressDetails/addressTypeSlice";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  createCustomerAddress,
  updateCustomerAddress,
} from "../../home/customerManagement/customerSlice";

type Props = {
  closeAddressModal: () => void;
  addressId: string | number | undefined;
  createdFrom: string;
  consumerId?: number | string;
  fetchConsumerDetail: (customerId: number) => void;
  selectedBillingAddressId?: number | string;
  billingAddressFormValues?: AddressValues | undefined;
  storeNewAddressIdHandler: (id: string) => void;
};

const AddressModal = (props: Props) => {
  const address = props.billingAddressFormValues;
  const dispatch = useAppDispatch();
  const addressType = useAppSelector(addressTypeSelector);
  const addressList = useSelector(addressSelector);
  const countries = useAppSelector(countrySelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isBillingExist, setIsBillingExist] = useState(true);
  const initialState = {
    id: "",
    country_id: 0,
    address: "",
    city: "",
    state_id: 0,
    zip_code: null,
    phone: null,
    is_billing: false,
    is_shipping: false,
    is_primary: false,
    state_name: "",
    country_name: "",
  };
  
  useEffect(() => {
    dispatch(getCountries());
  },[])
  useEffect(() => {
    if (
      address === undefined ||
      (address?.address === null &&
        address.city === null &&
        address.country_id === null &&
        address.phone === null &&
        address.state_id === null &&
        address.zip_code === null)
    ) {
      setIsBillingExist(false);
    }
  }, [props.billingAddressFormValues]);

  const [addressInputs, setAddressInputs] =
    useState<AddressValues>(initialState);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [shippingSameAsBilling, setShippingSameAsBilling] = useState(false);
  const [states, setStates] = useState<LocationValues[]>([]);

  const billingAddressList = addressList.find(
    (address) => address.is_billing === true
  );
  const shippingAddressList = addressList.find(
    (address) => address.is_shipping === true
  );

  const shippingAddressArray = addressList
    .filter((item) => item.is_shipping)
    .map((address) => address);
  const billingAddressArray = addressList
    .filter((item) => item.is_billing)
    .map((address) => address);
  const firstModalHeading = addressType.type.includes("add") ? "Add" : "Edit";
  const secondModalHeading = addressType.type.includes("billing")
    ? "Billing"
    : "Shipping";

  /**
   * Set address input values in case address Edit, and reset in case of create.
   */
  useEffect(() => {
    if (props.addressId !== "") {
      addressList
        .filter((address) => address.id === props.addressId)
        .map((item) => {
          setAddressInputs({
            address: item.address,
            country_id: item.country_id ? item.country_id : 0,
            city: item.city,
            state_id: item.state_id,
            zip_code: item.zip_code,
            phone: item.phone,
            is_billing: item.is_billing,
            is_shipping: item.is_shipping,
            is_primary: item.is_primary,
            id: item.id,
            country_name: item.country_name,
            state_name: item.state_name,
          });
          getAllCountryStates(item.country_id);
        });
    } else {
      setAddressInputs(initialState);
    }
  }, [props.addressId]);

  /**
   * function to handle and set address input change
   */
  const inputChangeHandler = (e: any) => {
    const { name, value } = e.target;
    const phoneNumberRegex = /^[\+0-9\b]+$/;
    if (name === "phone") {
      if (value === "" || phoneNumberRegex.test(value)) {
        setAddressInputs({ ...addressInputs, [name]: value });
      }
      if (value === "" && "phone" in formErrors) {
        delete formErrors.phone;
      }
    } else {
      setAddressInputs({ ...addressInputs, [name]: value });
    }
  };
  /**
   * Function to handle country change in Address details
   */
  const handleCountryChange = (e: any) => {
    const countryId = e.value;
    getAllCountryStates(countryId);
    setAddressInputs({
      ...addressInputs,
      country_id: countryId,
      country_name: e.label,
      state_id: 0,
      state_name: "",
    });
  };
  /**
   * Function to handle state change in Address details
   */
  const handleStateChange = (e: any) => {
    const stateId = e.value;
    setAddressInputs({
      ...addressInputs,
      state_id: stateId,
      state_name: e.label,
    });
  };
  /**
   * Fetch all states list based on country
   */
  const getAllCountryStates = async (countryId: number) => {
    if (countryId) {
      const responseAction = await dispatch(getCountryStates(countryId));
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setStates([...response]);
        }
      }
    }
  };
  /**
   * Function to set is_primary key as true / false for billing and shipping address
   */
  const primarySelectHandler = () => {
    if (addressType.type.includes("billing")) {
      return billingAddressList ? false : true;
    } else {
      return shippingAddressList ? false : true;
    }
  };
  /**
   * Address Save / Edit handler
   */
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    $("#address-save-button").addClass("customer-form-section-disable");
    const errors = validate(addressInputs);
    if (Object.keys(errors).length) {
      $("#address-save-button").removeClass("customer-form-section-disable");
      setFormErrors(errors);
    } else {
      setFormErrors({});
      if (props.createdFrom === "customer_page") {
        /**
         * create / update customer address from customer create page : Store in redux
         *  and call create / edit address API call along with create / edit customer API call.
         */
        if (props.addressId === "") {
          const id = uuidv4();
          dispatch(
            addAddress({
              id: id,
              country_id: addressInputs.country_id,
              address: addressInputs.address,
              city: addressInputs.city,
              state_id: addressInputs.state_id ? addressInputs.state_id : 0,
              zip_code: addressInputs.zip_code,
              phone: addressInputs.phone ? addressInputs.phone : null,
              is_billing: addressType.type.includes("billing") ? true : false,
              is_shipping: addressType.type.includes("shipping") ? true : false,
              is_primary: primarySelectHandler(),
              country_name: addressInputs.country_name,
              state_name: addressInputs.state_name,
            })
          );
          $("#address-save-button").removeClass(
            "customer-form-section-disable"
          );
          props.storeNewAddressIdHandler(id);
        } else {
          dispatch(
            editAddress({
              id: addressInputs.id,
              country_id: addressInputs.country_id,
              address: addressInputs.address,
              city: addressInputs.city,
              state_id: addressInputs.state_id ? addressInputs.state_id : 0,
              zip_code: addressInputs.zip_code,
              phone: addressInputs.phone ? addressInputs.phone : null,
              is_billing: addressInputs.is_billing,
              is_shipping: addressInputs.is_shipping,
              is_primary: addressInputs.is_primary,
              country_name: addressInputs.country_name,
              state_name: addressInputs.state_name,
            })
          );
          $("#address-save-button").removeClass(
            "customer-form-section-disable"
          );
        }
      } else {
        /**
         * Address create / update from customer detail page : Direct create / update customer address API call.
         */
        if (props.addressId === "") {
          const AddressValueArray: AddressValues[] = [];
          const addressInputCopy = { ...addressInputs };
          delete addressInputCopy.country_name;
          delete addressInputCopy.state_name;
          delete addressInputCopy.customer_address_id;
          addressInputCopy.phone = addressInputCopy.phone
            ? addressInputCopy.phone
            : null;
          addressInputCopy.is_billing = addressType.type.includes("billing")
            ? true
            : false;
          addressInputCopy.is_shipping = addressType.type.includes("shipping")
            ? true
            : false;
          addressInputCopy.is_primary = primarySelectHandler();
          addressInputCopy.state_id = addressInputCopy.state_id
            ? addressInputCopy.state_id
            : 0;
          AddressValueArray.push(addressInputCopy);
          if (props.consumerId) {
            const responseAction = await dispatch(
              createCustomerAddress({
                id: Number(props.consumerId),
                addressInputs: AddressValueArray,
                orgId: currentUserInfo.organization_id,
              })
            );
            const response = responseAction.payload;
            if (Object.keys(response).length && !("error" in response)) {
              props.storeNewAddressIdHandler(response[0].id);
            }
            props.fetchConsumerDetail(Number(props.consumerId));
          }
          $("#address-save-button").removeClass(
            "customer-form-section-disable"
          );
        } else {
          const AddressValueArray: AddressValues[] = [];
          const addressInputCopy = { ...addressInputs };
          delete addressInputCopy.country_name;
          delete addressInputCopy.state_name;
          delete addressInputCopy.customer_address_id;
          addressInputCopy.phone = addressInputCopy.phone
            ? addressInputCopy.phone
            : null;
          addressInputCopy.state_id = addressInputCopy.state_id
            ? addressInputCopy.state_id
            : 0;
          AddressValueArray.push(addressInputCopy);
          if (props.consumerId) {
            await dispatch(
              updateCustomerAddress({
                customerId: Number(props.consumerId),
                addressInputs: AddressValueArray,
                orgId: currentUserInfo.organization_id,
              })
            );
            props.fetchConsumerDetail(Number(props.consumerId));
          }
          $("#address-save-button").removeClass(
            "customer-form-section-disable"
          );
        }
      }
      props.closeAddressModal();
    }
  };
  /**
   * Validate phone number in address details
   */
  const validate = (values: AddressValues) => {
    const errors: ObjectType = {};
    const phoneRegex = /^[\+]?([0-9]){6,18}$/;
    if (values.phone && !phoneRegex.test(values.phone.toString())) {
      errors.phone = "Please enter valid phone number";
    }

    if (addressType.type.includes("shipping")) {
      for (let item of shippingAddressArray) {
        if (
          item.address === values.address &&
          item.city === values.city &&
          item.country_id === values.country_id &&
          item.phone === values.phone &&
          item.state_id === values.state_id &&
          item.zip_code === values.zip_code
        ) {
          errors.submit = "Address already exists, unable to save";
        }
      }
    }
    if (addressType.type.includes("billing")) {
      for (let item of billingAddressArray) {
        if (
          item.address === values.address &&
          item.city === values.city &&
          item.country_id === values.country_id &&
          item.phone === values.phone &&
          item.state_id === values.state_id &&
          item.zip_code === values.zip_code
        ) {
          errors.submit = "Address already exists, unable to save";
        }
      }
    }
    return errors;
  };
  /**
   * Function to disable save button if no input is entered in address form
   */
  const buttonDisableHandler = () => {
    if (
      addressInputs.address === "" &&
      addressInputs.city === "" &&
      addressInputs.country_id === 0 &&
      (addressInputs.phone === "" || addressInputs.phone === null) &&
      addressInputs.state_id === 0 &&
      (addressInputs.zip_code === null ||
        addressInputs.zip_code.toString() === "")
    ) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * Function to copy primary billing address in shipping address form
   */
  const shippingSameAsBillingHandler = (e: any) => {
    setShippingSameAsBilling(!shippingSameAsBilling);

    if (e.target.checked === true) {
      if (props.createdFrom === "invoice_page" || props.createdFrom === "subscription_page") {
        let billingPrimaryAddress: any = {};
        if (props.selectedBillingAddressId !== 0) {
          billingPrimaryAddress = addressList
            .filter((item) => item.id === props.selectedBillingAddressId)
            .map((address) => address)[0];
        } else {
          billingPrimaryAddress = props.billingAddressFormValues;
        }

        const billingPrimaryAddressCopy = { ...billingPrimaryAddress };
        billingPrimaryAddressCopy.is_billing = false;
        billingPrimaryAddressCopy.is_shipping = true;
        billingPrimaryAddressCopy.is_primary = addressInputs.is_primary;
        billingPrimaryAddressCopy.id = addressInputs.id
          ? addressInputs.id.toString()
          : "";
        setAddressInputs(billingPrimaryAddressCopy);
        getAllCountryStates(billingPrimaryAddressCopy.country_id);
      } else {
        const billingPrimaryAddress = addressList
          .filter((item) => item.is_primary && item.is_billing)
          .map((address) => address)[0];
        const billingPrimaryAddressCopy = { ...billingPrimaryAddress };
        billingPrimaryAddressCopy.is_billing = false;
        billingPrimaryAddressCopy.is_shipping = true;
        billingPrimaryAddressCopy.is_primary = addressInputs.is_primary;
        billingPrimaryAddressCopy.id = addressInputs.id
          ? addressInputs.id.toString()
          : "";
        setAddressInputs(billingPrimaryAddressCopy);
        getAllCountryStates(billingPrimaryAddressCopy.country_id);
      }
    } else {
      setAddressInputs(initialState);
    }
  };

  return (
    <>
      <Modal
        open={true}
        onClose={() => props.closeAddressModal()}
        aria-labelledby="addAddressModalTitle"
        className="partial-modal addAddressModal right-partial-modal"
        aria-hidden="true"
        id="addAddressModal"
      >
        <div className="modal-dialog modal-fullscreen w-100">
          <div className="modal-content">
            <div className="modal-header close-button-container">
              <button
                type="button"
                className="close-btn pull-right"
                aria-label="Close"
                onClick={() => props.closeAddressModal()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      fill={colors.slate}
                      fillRule="nonzero"
                      d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="modal-body user-form">
              <div className="user-form-head">
                <h4 id="addAddressModalTitle">
                  {firstModalHeading} {secondModalHeading} Address
                </h4>
                <form action="" className="add-address-form m-0">
                  <div className="form-field-container w-100 shipping-address">
                    {(billingAddressList || isBillingExist) &&
                      addressType.type.includes("shipping") && (
                        <div className="form-outline mb-4 w-100">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="same_as_billing"
                              checked={shippingSameAsBilling}
                              onChange={(e) => shippingSameAsBillingHandler(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="same_as_billing"
                            >
                              Copy billing address
                            </label>
                          </div>
                        </div>
                      )}

                    <div className="form-outline w-100">
                      <label htmlFor="region">Country / Region</label>
                      <Select
                        name="country_id"
                        id="region"
                        options={
                          countries?.length > 0
                            ? countries.map((country) => {
                                return {
                                  label: country.name,
                                  value: country.id,
                                };
                              })
                            : []
                        }
                        className="state-select form-select custom-select"
                        value={
                          addressInputs?.country_id === null ||
                          addressInputs?.country_id === 0
                            ? ""
                            : {
                                label: addressInputs.country_name,
                                value: addressInputs?.country_id,
                              }
                        }
                        onChange={(e) => {
                          handleCountryChange(e);
                        }}
                        styles={customSelectStyle}
                        placeholder={"Choose country"}
                        isDisabled={shippingSameAsBilling}
                        isSearchable={false}
                      />
                      <span className="error"></span>
                    </div>
                    <div className="form-outline w-100">
                      <label htmlFor="address">Address</label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Enter Address"
                        onChange={inputChangeHandler}
                        value={
                          addressInputs.address ? addressInputs.address : ""
                        }
                        disabled={shippingSameAsBilling}
                        maxLength={100}
                      />
                      <span className="error"></span>
                    </div>
                    <div className="form-outline w-100">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        className="form-control"
                        value={addressInputs.city ? addressInputs.city : ""}
                        placeholder="Enter city name"
                        disabled={shippingSameAsBilling}
                        onChange={inputChangeHandler}
                        maxLength={200}
                      />
                      <span className="error"></span>
                    </div>
                    <div className="form-outline w-100">
                      <label htmlFor="state">State</label>
                      <Select
                        name="state_id"
                        id="state"
                        options={
                          states?.length > 0
                            ? states.map((state) => {
                                return {
                                  label: state.name,
                                  value: state.id,
                                };
                              })
                            : []
                        }
                        className="state-select form-select custom-select"
                        value={
                          addressInputs.state_id
                            ? {
                                label: addressInputs.state_name,
                                value: addressInputs.state_id,
                              }
                            : []
                        }
                        onChange={(e) => handleStateChange(e)}
                        styles={customSelectStyle}
                        placeholder={"Choose state"}
                        isDisabled={shippingSameAsBilling}
                        isSearchable={false}
                      />
                      <span className="error"></span>
                    </div>
                    <div className="form-outline w-100">
                      <label htmlFor="zip">Zip Code</label>
                      <input
                        type="text"
                        name="zip_code"
                        id="zip"
                        className="form-control"
                        value={
                          addressInputs.zip_code ? addressInputs.zip_code : ""
                        }
                        placeholder="Enter valid zip code"
                        disabled={shippingSameAsBilling}
                        onChange={inputChangeHandler}
                        maxLength={15}
                      />
                      <span className="error"></span>
                    </div>
                    <div className="form-outline w-100">
                      <label htmlFor="phone">Phone Number</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        className={
                          formErrors.phone
                            ? "form-control error"
                            : "form-control"
                        }
                        value={addressInputs.phone ? addressInputs.phone : ""}
                        placeholder="Enter phone number"
                        disabled={shippingSameAsBilling}
                        onChange={inputChangeHandler}
                        maxLength={19}
                      />
                      <span className="error">{formErrors.phone}</span>
                    </div>
                    <span className="error">{formErrors.submit}</span>
                  </div>
                  <div
                    className="form-button-wrapper w-100"
                    id="address-save-button"
                  >
                    <button
                      className="save-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={buttonDisableHandler()}
                    >
                      Save
                    </button>
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => props.closeAddressModal()}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddressModal;
