import { Api } from "../../../constants";
import { get, post, put } from "../../../helpers/apiHelper";
import {
  CreateLockValues,
  UnlockValues,
  UpdateLockValues,
} from "../../../types";

/**
 * API call to get transaction locking detail
 */
export async function transactionLockingDetail(orgId: number) {
  const result = await get(
    Api.transactionLocking + `/detail?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call to lock or partially unlock transaction
 */
export async function transactionLockOrPartialUnlock(data: {
  values: CreateLockValues;
  orgId: number;
}) {
  const result = await post(
    Api.transactionLocking + `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to disable full locking or partial unlocking
 */
export async function transactionUnlockOrDisablePartialUnlock(data: {
  values: UnlockValues[];
  orgId: number;
}) {
  const result = await put(
    Api.transactionLocking + `/disable?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to update transaction lock
 */
export async function editTransactionLock(data: {
  values: UpdateLockValues[];
  orgId: number;
}) {
  const result = await put(
    Api.transactionLocking + `/update?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
