import { ObjectType } from "../../../../types";
import "./MajorSearchAttribute.css"

type Props = {
  attribute: ObjectType;
};
export const MajorSearchAttribute = ({attribute}: Props) => {
  return (
    <span className="major-search-attribute">
      {`${Object.keys(attribute)[0]} : ${Object.values(attribute)[0]}`}
    </span>
  );
};
