import React, { Dispatch, SetStateAction, useState } from "react";
import { Grid, Paper, Typography } from '@mui/material';
import { monthOptions } from "../../../constants/constants";

type Props = {
    handleMonthChange: (month: string) => void 
    selectedMonth: number;
};

const CustomMonths = (props: Props) => {
    return (
        <>
            <Grid container spacing={0} className="paper-month-main-grid">
                {Array.from({ length: 4 }).map((_, rowIndex) => (
                    <Grid container item key={rowIndex}>
                        {Array.from({ length: 3 }).map((_, colIndex) => {
                            const dayNumber = monthOptions[rowIndex * 3 + colIndex];
                            return (
                                <Grid item xs={4} key={colIndex}>
                                    <Paper
                                        className={`custom-month-picker-paper ${Number(props.selectedMonth) === Number(dayNumber.value) ?  "month-selected": ""}`}
                                        onClick={() => props.handleMonthChange(dayNumber.value)}
                                    >
                                        {dayNumber.label.slice(0, 3)}
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default CustomMonths;
