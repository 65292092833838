import CenterModal from "../../centerModal/CenterModal";
import { ModalContext } from "../../centerModal/centerModalContext";
import { OptionsMappingPopupProps } from "../types";
import { TagOptionsMapping } from "./components/tagOptionsMapping/TagOptionsMapping";

export const TagsOptionsSelectionPopup = (props: OptionsMappingPopupProps) => {
  return (
    <CenterModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <TagOptionsMapping {...props} handleClose={onCloseModal} />;
          }}
        </ModalContext.Consumer>
      }
      modalTitle=""
      buttonClass={`no-appearance p-0 ${
        !props.isAddNewTag
          ? "tag-category-mapping-edit"
          : props.isDisabled
          ? "tag-category-mapping-disabled-category"
          : "tag-category-mapping-category-name"
      }`}
      wrapperClass="no-padding-bottom"
      buttonContent={props.buttonContent}
      isButtonDisabled={props.isDisabled}
      isCloseButtonEnabled={true}
      isModalButtonPositionStart={true}
      backdropOpacity={props.isAddNewTag ? 0.0001 : 0.5}
    />
  );
};
