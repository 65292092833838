import Keycloak from "keycloak-js";
import { permissionHandler } from "../helpers/permissionHelper";
import { store } from "../app/store";
import { roleMapping } from "../features/common/commonSlice";
const _kc = new Keycloak(process.env.REACT_APP_KEYCLOAK_JSON_URL);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
    })
    .then(async (authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      if (_kc.token) sessionStorage.setItem("token", _kc.token);
      if (_kc.refreshToken)
      sessionStorage.setItem("refresh-token", _kc.refreshToken);

      _kc.redirectUri = process.env.REACT_APP_KEYCLOAK_REDIRECT_URL;
      onAuthenticatedCallback();

      //Fetch permissing mapping data
      let response = await store.dispatch(roleMapping());
      if (
        response.payload &&
        Object.keys(response.payload).length &&
        !("error" in response.payload)
      ) {
        let permissionMapping = response.payload;
        permissionHandler(_kc.tokenParsed?.realm_access, permissionMapping);
      }
    })
    .catch(console.error);
  _kc.onTokenExpired = () => {
    _kc
      .updateToken(30)
      .success(() => {
        if (_kc.token) sessionStorage.setItem("token", _kc.token);
        if (_kc.refreshToken)
        sessionStorage.setItem("refresh-token", _kc.refreshToken);
      })
      .error(() => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh-token");
        sessionStorage.removeItem("orgId");
        sessionStorage.removeItem("rolePermissions");
        sessionStorage.removeItem("organizationRole");
        sessionStorage.removeItem("OrgName");
      });
  };
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = async () => {
  try {
    const refreshed = await _kc.updateToken(-1);
    if (refreshed) {
      if (_kc.token) sessionStorage.setItem("token", _kc.token);
      if (_kc.refreshToken)
      sessionStorage.setItem("refresh-token", _kc.refreshToken);
    }
  } catch (error) {
    console.error("Failed to update token:", error);
  }
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  // updateToken1,
  getUsername,
  hasRole,
};

export default UserService;
