import { useNavigate } from "react-router-dom";
import BackButton from "../../../../common/components/BackButton";
import OrgTag from "../../../../common/components/OrgTag";
import TransactionNumber from "../../../../common/components/TransactionNumber";

type Props = {
  transactionName: string;
  transactionNumber: number;
  organizationName: string | undefined;
};
const TransactionPlanHeader = (props: Props) => {
  const navigate = useNavigate();
  const firstHeading =
    props.transactionName === "Customer" || props.transactionName === "Vendor"
      ? "Add"
      : props.transactionName === "Credit Note" ||
        props.transactionName === "Debit Note"
      ? "Add New"
      : props.transactionName === "Record Payment" ||
        props.transactionName === "Bill Payment"
      ? ""
      : "Create";
  return (
    <>
      <BackButton onClick={() =>  navigate(-1) } />
      <h1 className="fw-bold m-0 form-plan-header">
        {props.transactionNumber
          ? `Edit ${props.transactionName}`
          : `${firstHeading} ${props.transactionName}`}
           <span className="plan-header-org-span">
          <OrgTag
                organization={props?.organizationName || ''}
              />
           </span>
      </h1>
    </>
  );
};

export default TransactionPlanHeader;