import { colors } from "../../../constants/colors";
import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { MarkAsAcceptedIcon } from "../MarkAsAcceptedIcon";
import { MarkedPopupBody } from "./MarkedPopupBody";
import { MarkedPopupProps } from "./types";

export const MarkedPopup = (props: MarkedPopupProps) => {
  return (
    <AlertModal
      modalButtonWrapperClass="marked-popup-wrapper-class"
      buttonContent={
        <span className="d-flex align-items-center" >
          {" "}
          {props.icon ? (
            props.icon
          ) : (
            <MarkAsAcceptedIcon
              color={props.iconColor ? props.iconColor : colors.ceruleanBlue}
              margin="0px 5px 0px 0px"
            />
          )}
          {props.popupButtonText}
        </span>
      }
      modalBodyClass="marked-popup-body-wrapper"
      isCloseButtonEnabled={true}
      modalTitle={props.popupTitle}
      disabledActionFunction={() => {}}
      buttonClass="no-appearance marked-popup-btn"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <MarkedPopupBody
                onCancel={onCloseModal}
                onSave={props.onSave}
                markCheckboxLabel={props.markCheckboxLabel}
                className=""
              />
            );
          }}
        </ModalContext.Consumer>
      }
    />
  );
};
