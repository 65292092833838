import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./app/store";
import { User } from "./types";

interface SelectedFiltersType {
  [category: string]: number[];
}
interface AppState {
  token: string;
  user?: User;
  module: string;
  page: number;
  itemsPerPage: number;
  startCount: number;
  endCount: number;
  pageCount: number;
  itemOffset: number;
  dateSortOrder: string;
  totalList: number;
  listFilters: SelectedFiltersType;
  isFilterApplied: boolean;
  errorID: number;
  customFieldValues: {
    id: number | string;
    field_name: string;
    mandatory_field: boolean;
    show_in_pdf: boolean;
  };
  isCustomFieldModalOpen: boolean;
  nameSortOrder: string;
  roleSortOrder: string;
}

const initialState: AppState = {
  token: "",
  module: "",
  page: 1,
  itemsPerPage: 10,
  startCount: 0,
  endCount: 0,
  pageCount: 1,
  itemOffset: 0,
  dateSortOrder: "",
  totalList: 0,
  listFilters: {},
  isFilterApplied: false,
  errorID: 0,
  customFieldValues: {
    id: "",
    field_name: "",
    mandatory_field: true,
    show_in_pdf: true,
  },
  isCustomFieldModalOpen: false,
  nameSortOrder: "ASC",
  roleSortOrder: "",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    tokenUpdated: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    userLoggedIn: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    currentModule: (state, action) => {
      state.module = action.payload;
    },
    setListPage: (state, action) => {
      state.page = action.payload;
    },
    setListItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setListStartCount: (state, action) => {
      state.startCount = action.payload;
    },
    setListEndCount: (state, action) => {
      state.endCount = action.payload;
    },
    setListPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setListItemOffset: (state, action) => {
      state.itemOffset = action.payload;
    },
    setListDateSortOrder: (state, action) => {
      state.dateSortOrder = action.payload;
    },
    setTotalListCount: (state, action) => {
      state.totalList = action.payload;
    },
    setListFilters: (state, action) => {
      state.listFilters = action.payload;
    },
    setIsFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    setErrorID: (state, action) => {
      state.errorID = action.payload;
    },
    setCustomFieldValues: (state, action) => {
      state.customFieldValues = action.payload;
    },
    setIsCustomFieldModalOpen: (state, action) => {
      state.isCustomFieldModalOpen = action.payload;
    },
    setListNameSortOrder: (state, action) => {
      state.nameSortOrder = action.payload;
    },
    setListRoleSortOrder: (state, action) => {
      state.roleSortOrder = action.payload;
    },
  },
});

export const {
  tokenUpdated,
  currentModule,
  setListPage,
  setListItemsPerPage,
  setListStartCount,
  setListEndCount,
  setListPageCount,
  setListItemOffset,
  setListDateSortOrder,
  setTotalListCount,
  setListFilters,
  setIsFilterApplied,
  setErrorID,
  setCustomFieldValues,
  setIsCustomFieldModalOpen,
  setListNameSortOrder,
  setListRoleSortOrder,
} = appSlice.actions;

export default appSlice.reducer;

// State selectors
export const getToken = (state: RootState) => state.app.token;
export const moduleSelector = (state: RootState) => state.app.module;
export const pageSelector = (state: RootState) => state.app.page;
export const itemsPerPageSelector = (state: RootState) =>
  state.app.itemsPerPage;
export const startCountSelector = (state: RootState) => state.app.startCount;
export const endCountSelector = (state: RootState) => state.app.endCount;
export const pageCountSelector = (state: RootState) => state.app.pageCount;
export const itemOffsetSelector = (state: RootState) => state.app.itemOffset;
export const dateSortOrderSelector = (state: RootState) =>
  state.app.dateSortOrder;
export const totalListSelector = (state: RootState) => state.app.totalList;
export const listFilterSelector = (state: RootState) => state.app.listFilters;
export const filterAppliedState = (state: RootState) => state.app.isFilterApplied;
export const getErrorID = (state: RootState) => state.app.errorID;
export const getCustomFieldValues = (state: RootState) =>
  state.app.customFieldValues;
export const getCustomModalOpenState = (state: RootState) =>
  state.app.isCustomFieldModalOpen;
export const nameSortOrderSelector = (state: RootState) =>
  state.app.nameSortOrder;
export const roleSortOrderSelector = (state: RootState) =>
  state.app.roleSortOrder;
