import { getFormatedDate } from "../../../../../helpers/helper";

export const initialUnpaidBillValues = {
  id: "",
  bill_number: "",
  bill_date: "",
  bill_amount: "",
  amount_due: "",
  payment_amount: "",
  conversion_id: 0,
  bill_id: 0,
  forex_amount: "",
  allocation_date: ''
};
export const initialBillPaymentValues = {
  payment_number: "",
  payment_date: getFormatedDate(new Date()),
  vendor_name: "",
  vendor_id: "",
  notes: "",
  paid_through_account_name: "",
  paid_through_account_id: "",
  amount_paid: "",
  amount_paid_bank_currency: "",
  bank_charges_amount: "",
  bank_charges_bank_currency: "",
  bank_charges_account_name: "",
  bank_charges_account_id: "",
  check_expiry: "",
  check_number: "",
  payment_mode: "Cash",
  file_ids: [],
  email_to: [],
  unpaid_bill_lines: [initialUnpaidBillValues],
  conversion_id: "",
  bank_conversion_id: null,
  currency_id: "",
  bank_currency_id:"",
  reference_number: "",
  is_locked: false,
  lock_date: "",
};

export const initialDefaultvalues = {
  payment_number: "",
  payment_date: getFormatedDate(new Date()),
  vendor_name: "",
  vendor_id: "",
  paid_through_account_name: "",
  paid_through_account_id: "",
  amount_paid: "",
  bank_charges_amount: "",
  bank_charges_account_name: "",
  bank_charges_account_id: "",
  check_expiry: "",
  check_number: "",
  payment_mode: "Cash",
  currency_id: "",
  bank_currency_id:"",
  reference_number: "",
  amount_paid_bank_currency: 0
};

export const initialSubAccountValues = {
  id: 0,
  account_name: "",
};

export const initialBankConverisonValues = {
  base_currency: "",
  base_currency_id: 0,
  by_user: false,
  id: 0,
  rate: 0,
  timestamp: "",
  to_currency: "",
  to_currency_id: 0,
};
