import "../styles/AuthorizedSignature.css";

function AuthorizedSignature() {
  return (
    <>
      <div className="signatury-wrapper">
        <span className="signature-label">Authorized Signature</span>
        <div className="signature"></div>
      </div>
    </>
  );
}
export default AuthorizedSignature;
