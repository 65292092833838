import React, { useEffect, useRef, useState } from "react";
import StatementOfAccountsBody from "./StatementOfAccountsBody";
import "../../Reports.css";
import Loader from "../../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getSoaReport,
  reportLoaderSelector,
  setLoader,
} from "../../ReportSlice";
import ReportFilter from "./ReportFilter";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";

export default function StatementOfAccounts() {
  const dispatch = useAppDispatch(); 
 
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [transactionBy, setTransactionBy] = useState("customer");
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "action",
    "Customer",
    "Vendor",
  ]);
  const [transactionOrg, setTransactionOrg] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [reportResponse, setReportResponse] = useState<any>([]);
  const reportRef = useRef<any>([]);
  const [filterString, setFilterString] = useState("");  

  const handleReport = () => {  
    const category = reportRef.current.account;
    setTransactionOrg(reportRef.current.formValues?.statement_type);
    if (category === "SundryDebtor" || category === "TDSCustomers") {
      setHiddenColumns(["Vendor"]);
      setTransactionBy("customer");
    } else if (category === "SundryCreditor" || category === "TDSVendors") {
      setHiddenColumns(["Customer"]);
      setTransactionBy("vendor");
    } else {
      if (reportRef.current.formValues?.statement_type === "Other") {
        setHiddenColumns(["Customer", "Vendor"]);
      } else {
        setHiddenColumns(["Customer", "Vendor", "Organization"]);
      }
      setTransactionBy("");
    }
    if (
      Object.values(currentUserInfo.user_access_mapping).toString().split(",")
        .length <= 1
    ) {
      setHiddenColumns((prevHiddenColumns) => {
        if (!prevHiddenColumns.includes("Organization")) {
          return [...prevHiddenColumns, "Organization"];
        }
        return prevHiddenColumns;
      });
    }
    getReport();
  };

  const getReport = async () => {
    let formValues = reportRef.current.formValues;
    const tempFilterString = JSON.stringify(formValues);
    const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
    setFilterString(hashFragment);
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getSoaReport({
        filterValues: formValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setReportResponse(response);
      }
      dispatch(setLoaderState(false));
    }
    setShowReport(true);
    dispatch(setLoaderState(false));
  };

  return (
    <div id="soa-report" className="reports soa-report">
      <div
        className="card card-dashboard report-card soa-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Statement of Accounts</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <ReportFilter
              handleReport={handleReport}
              clearReport={() => setShowReport(false)}
              reportType="SOA"
              ref={reportRef}
            />
            <StatementOfAccountsBody
              showReport={showReport}
              reportResponse={reportResponse}
              transactionBy={transactionBy}
              hiddenColumns={hiddenColumns}
              transactionOrg={transactionOrg}
              filterValues={reportRef.current.formValues}
              filterString={filterString}
            />
          </div>    
        </div>
      </div>
    </div>
  );
}
