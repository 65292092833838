import { JournalFormValues } from "../../../../../types";

export const payloadRefiner = (
  formValues: JournalFormValues,
  action: string
) => {
  const JVItems = formValues.item_details.map((item) => {
    const payload = {
      id: item.id,
      account_id: item.account_id,
      description: item.description || null,
      debit: item.debit || 0,
      credit: item.credit || 0,
      contact_id: item.contact_id || null,
      contact_name: item.contact_name || null,
      contact_type: item.contact_type || null,
      conversion_id: item.conversion_id || null,

    };
    const { id, ...rest } = payload;
    return action === "edit" ? payload : rest;
  });
  const jvPayload = {
    journal_voucher_number: formValues.journal_voucher_number,
    reference_number: formValues.reference_number || null,
    notes: formValues.notes,
    date: formValues.date,
    currency_id: Number(formValues.currency_id),
    custom_fields: formValues.custom_fields,
    file_ids: formValues.file_ids,
    item_details: JVItems,
    email_to: formValues.email_to,
    conversion_id: Number(formValues.conversion_id),
    is_cash_based: formValues.is_cash_based,
    is_locked: formValues.is_locked,
    lock_date: formValues.lock_date || null,
  };
  return jvPayload;
};
