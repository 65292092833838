import { gql } from "@apollo/client";
import { ObjectType } from "../../../../types";

const queryTemplates: ObjectType = {
  global_search: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        global_search
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  customers: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        customers {
          customer_id
          company_name
          short_code
          customer_display_name
          pan_number
          created_by_user_name
          payment_term_name
          tag_name
          organization_name
          customer_email
          customer_phone
          customer_contact_first_name
          customer_contact_last_name
          customer_contact_email
          customer_contact_phone
          customer_address_country_name
          customer_address_state_name
          customer_address_city
          customer_billing_address
          customer_shipping_address
          customer_address_zip_code
          customer_address_phone
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,

  invoices: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        invoices {
          invoice_id
          invoice_number
          reference_number
          tag_name
          customer_name
          currency_code
          invoice_date
          invoice_status
          due_date
          total
          total_taxable_amount
          balance_due
          tax_deducted
          sales_invoice_billing_address
          sales_invoice_shipping_address
          sales_invoice_phone
          created_by_user_name
          organization_name
          item_name
          hsn_or_sac_code
          account_name
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  credit_notes: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        credit_notes {
          credit_note_id
          credit_note_number
          reference_number
          created_by_user_name
          customer_name
          credit_note_date
          subject
          customer_notes
          credit_note_status
          reason
          tag_name
          invoice_number
          credit_note_balance
          total
          currency_code
          total_taxable_amount
          organization_name
          item_name
          account_name
          discount_account_name
          credit_notes_recipients_email
          credit_notes_address_country_name
          credit_notes_address_state_name
          credit_notes_address_city
          credit_notes_billing_address
          credit_notes_shipping_address
          credit_notes_address_zip_code
          credit_notes_address_phone
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  payment_received: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        payment_received {
          payment_number
          payment_received_id
          created_by_user_name
          payment_date
          customer_name
          customer_short_code
          currency_code
          amount_received
          amount_in_excess
          tax_deducted
          customer_id
          deposit_to_account_name
          bank_charges_account_name
          tax_account_name
          notes
          pr_status
          reference_number
          payment_received_recipients_email
          organization_name
          invoice_number
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  vendors: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        vendors {
          vendor_id
          company_name
          short_code
          vendor_display_name
          pan_number
          created_by_user_name
          organization_name
          payment_term_name
          tag_name
          vendor_email
          vendor_phone
          vendor_contact_first_name
          vendor_contact_last_name
          vendor_contact_email
          vendor_contact_phone
          vendor_address_country_name
          vendor_address_state_name
          vendor_address_city
          vendor_billing_address
          vendor_shipping_address
          vendor_address_zip_code
          vendor_address_phone
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  bills: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        bills {
          bill_id
          bill_date
          bill_number
          reference_number
          due_date
          tag_name
          vendor_name
          payment_term_name
          created_by_user_name
          organization_name
          total
          total_taxable_amount
          amount_due
          tax_deducted
          currency_code
          bill_status
          item_name
          hsn_or_sac_code
          account_name
          bill_recipients_email
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  debit_notes: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        debit_notes {
          debit_note_id
          debit_note_number
          reference_number
          created_by_user_name
          vendor_name
          debit_note_date
          subject
          debit_note_status
          organization_name
          bill_number
          debit_note_balance
          total
          currency_code
          total_taxable_amount
          tag_name
          item_name
          account_name
          discount_account_name
          debit_notes_recipients_email
          debit_notes_address_country_name
          debit_notes_address_state_name
          debit_notes_address_city
          debit_notes_billing_address
          debit_notes_shipping_address
          debit_notes_address_zip_code
          debit_notes_address_phone
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  payment_made: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        payment_made {
          payment_made_id
          payment_number
          created_by_user_name
          organization_name
          payment_date
          vendor_name
          paid_through
          bill_number
          pm_status
          currency_code
          amount_paid
          amount_in_excess
          reference_number
          payment_made_recipients_email
          payment_made_address_country_name
          payment_made_address_state_name
          payment_made_address_city
          payment_made_billing_address
          payment_made_shipping_address
          payment_made_address_zip_code
          payment_made_address_phone
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  journal_voucher: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        journal_voucher {
          journal_voucher_id
          journal_voucher_number
          reference_number
          journal_status
          journal_voucher_date
          currency_amount
          notes
          created_by_user_name
          organization_name
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
  chart_of_accounts: gql`
    query Search($searchQuery: String, $currentOrganization: Int!) {
      search(
        searchQuery: $searchQuery
        currentOrganization: $currentOrganization
      ) {
        chart_of_accounts {
          account_id
          account_name
          account_type
          description
          ledger_code
          section
          matched_field
        }
        total
        pagination {
          next
          previous
        }
      }
    }
  `,
};

export const generateSearchQuery = (module: string) => {
  const fragment = queryTemplates[module];

  return fragment;
};
