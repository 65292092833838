import ScrollToError from "../features/common/components/scrollToError";

export const restoreExpansionState = () => {
  const parentRows = document.querySelectorAll(".parent-row");
  const expandedIdsString = localStorage.getItem("expanded-rows");
  const expandedIds: string[] = expandedIdsString
    ? JSON.parse(expandedIdsString)
    : [];
  parentRows.forEach((row) => {
    const parentRowId = row.getAttribute("id");
    const button = row.querySelector(".collapsible-btn") as HTMLElement;
    if (button && parentRowId && expandedIds?.includes(parentRowId)) {
      button.classList.toggle("active");
      const parentRow = button.closest(".parent-row");
      if (parentRow) {
        parentRow.classList.toggle("expanded");
        var panel: any = parentRow.nextElementSibling;
        panel.classList.toggle("show");
      }
    }
  });
  const drillDownAccount = localStorage.getItem("drill-down-account");
  if (drillDownAccount) ScrollToError(drillDownAccount);
  return expandedIds;
};

export const clearStoredInfoInLocal = () => {
  localStorage.removeItem("expanded-rows");
  localStorage.removeItem("drill-down-account");
  localStorage.removeItem("drill-down-filter");
  localStorage.removeItem("drill-down-report");
};
