import React, { useState } from "react";
import CreateItem from "./CreateItem";
import { InventoryItemValues } from "../../../../../types";

type Props = {
  openModal: boolean;
  closeModal: () => void;
  itemData?: InventoryItemValues | undefined;
  fetchItemDropdown?: () => void;
  currency: {id?: number, code?: string};
  refreshPage?: () => void;
};
function CreateItemContainer({
  openModal,
  closeModal,
  itemData,
  fetchItemDropdown,
  currency
}: Props) {
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    handleOpen(openModal);
    if (openModal === true) {
      //
    }
  }, [openModal]);

  const handleOpen = (status: boolean) => setOpen(status);
  const handleClose = () => {
    setOpen(false);
    closeModal();
  };
  return (
    <CreateItem
      open={open}
      closeModal={closeModal}
      itemData={itemData}
      fetchItemDropdown={fetchItemDropdown}
      currency={currency}
    />
  );
}

export default CreateItemContainer;
