import React from "react";
import { SvgIcon } from "@mui/material";

const ChipCloseIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
      <g
        id="User-Management"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule={"evenodd"}
      >
        <g
          id="User-Management---Tags-Added"
          transform="translate(-1058.000000, -468.000000)"
        >
          <g id="Group-33" transform="translate(1011.000000, 463.000000)">
            <g id="Group" transform="translate(47.000000, 5.000000)">
              <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
              <polygon
                id="Path"
                fill="#747D84"
                fillRule={"nonzero"}
                points="7 6.17516667 9.8875 3.28766667 10.7123333 4.1125 7.82483333 7 10.7123333 9.8875 9.8875 10.7123333 7 7.82483333 4.1125 10.7123333 3.28766667 9.8875 6.17516667 7 3.28766667 4.1125 4.1125 3.28766667"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ChipCloseIcon;
