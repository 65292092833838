import { FormControl } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Popup from "reactjs-popup";
import { useAppSelector } from "../../../../../app/hooks";
import { CloseImg } from "../../../../../assets/images";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { ObjectType } from "../../../../../types";
import { gstRegistrationTypes } from "../../../../constants/constants";
import {
  defaultPreferenceSelector,
  gstStateTypeSelector,
} from "../../../../home/gst/gstSlice";
import { TaxModal } from "../../../../home/gst/taxRates/components/TaxModal";
import { gstSelectModalCustomStyle } from "./GstSelectCustomStyle";
import { useGstSelect } from "./GstSelectProvider";
import {
  CUSTOMER_NON_TAXABLE,
  EXEMPTION_TYPES,
  GST_ERROR_MSG,
  NON_TAXABLE,
  TAXABLE,
  TAX_CONFIGURATION,
} from "./constants";
import ItemCloseButton from "../../button/ItemCloseButton";
import "./GstSelect.css";
import { Button } from "../../ui";

type Props = {
  gstOnChange: (gst: any, item_exemption: any) => void;
  itemIndex: number;
  organizationId: number;
  item: ObjectType;
  disabled?: boolean;
  disabledFeature: ObjectType[];
  updateIsItemEdited?: (val: boolean) => void;
  customerExemptionType: string | "";
  gst_tax: ObjectType | null;
  clearGstSelection: (itemIndex: number, gst_object: ObjectType) => void;
  gstRegistrationType?: string;
};
export default function GstSelect({
  gstOnChange,
  itemIndex,
  organizationId,
  item,
  disabled,
  disabledFeature,
  gstRegistrationType,
  updateIsItemEdited,
  customerExemptionType,
  gst_tax,
  clearGstSelection,
}: Props) {
  const {
    gstTaxList,
    exemptionDropdownList,
    fetchGstTaxList,
    fetchExemptionList,
    stateType,
  } = useGstSelect();
  const { editId } = useParams();
  const [exemptionReason, setExemptionReason] = useState<string>("");
  const [exemptionReasonId, setExemptionReasonId] = useState<number | null>();
  const [isExemptionModalOpen, setExemptionmodalOpen] =
    useState<boolean>(false);
  const [isTaxDisabled, setTaxDisabled] = useState<boolean>(false);
  const [taxName, setTaxName] = useState<string>("");const ulRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<any>();
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const defaultPreference = useAppSelector(
    defaultPreferenceSelector
  ) as ObjectType;
  let taxConfigurationPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === TAX_CONFIGURATION
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  useEffect(() => {
    if (item) {
      const exemptionReasonItem = exemptionDropdownList?.find(
        (reason) => reason.id === item.item_exemption_id
      );
      const defaultExemptionReason = exemptionDropdownList?.find(
        (reason) => reason.exemption_reason === CUSTOMER_NON_TAXABLE
      );
      const gstNonTaxable = gstTaxList?.find(
        (type) => type.name === "Non-Taxable"
      );
      const itemTaxPreference = EXEMPTION_TYPES?.find(
        (type) => type.value === item.item_exemption_type
      )?.key;
      const gstItem = gstTaxList?.find(
        (type) => type.name === itemTaxPreference
      );
      if (customerExemptionType === NON_TAXABLE) {
        if (gstNonTaxable) {
          setExemptionReason(defaultExemptionReason?.exemption_reason);
          setExemptionReasonId(defaultExemptionReason?.id);
          gstOnChange(gstNonTaxable, {
            item_exemption_type: NON_TAXABLE,
            item_exemption_id: defaultExemptionReason?.id,
          });
        }
        setTaxDisabled(true);
      } else {
        if (exemptionReasonItem) {
          setExemptionReason(exemptionReasonItem?.exemption_reason);
          setExemptionReasonId(exemptionReasonItem?.id);
        }
        if (gstItem && editId) {
          gstOnChange(gstItem, {
            item_exemption_type: exemptionReasonItem?.exemption_reason || "",
            item_exemption_id: exemptionReasonItem?.id || 0,
          });
        }
        setTaxDisabled(false);
      }
    } else {
      setTaxDisabled(false);
    }
  }, [
    customerExemptionType,
    item.item_exemption_id,
    stateType,
    item.inventory_item_id,
  ]);
  useEffect(() => {
    handleTaxNameFormat(gst_tax ? gst_tax : {});
  }, [gst_tax, gstTaxList]);

  useEffect(() => {
    resetDropDown();
  }, []);
  // useEffect(() => {
  //   $(".dropdown-toggle").dropdown("hide");
  // }, [props.error]

  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  const liClickHandler = (gst: ObjectType) => {
    if (updateIsItemEdited) {
      updateIsItemEdited(true);
    }
    let exemptionType = TAXABLE;
    if (gst.is_tax_preference) {
      exemptionType =
        EXEMPTION_TYPES.find((type) => type.key === gst.name)?.value || "";
    }
    const exemptionId = exemptionReasonId ? exemptionReasonId : null;
    if (gst.name === "Non-Taxable") {
      handleOpenExemptionReasonmodal();
    } else {
      gstOnChange(gst, {
        item_exemption_type: exemptionType,
        item_exemption_id: exemptionId,
      });
    }
  };
  const handleTaxNameFormat = (gst: ObjectType) => {
    if (gst?.tax_id) {
      const selectedTax = gstTaxList.filter(
        (tax) => tax.id === gst.tax_id && tax.tax_group === gst.is_group
      );
      if (selectedTax.length) {
        if (selectedTax[0].is_tax_preference) {
          setTaxName(`${selectedTax[0]?.name}`);
        } else {
          setTaxName(`${selectedTax[0]?.name}-[${selectedTax[0]?.rate}%]`);
        }
      } else {
        setTaxName("");
      }
    } else {
      setTaxName("");
    }
  };

  const handleTaxExemptionChange = (e: any) => {
    setExemptionReason(e.label);
    setExemptionReasonId(e.value);
  };
  const handleExemptionReasonSubmit = (tax: any) => {
    if (!exemptionReason || exemptionReason === "") {
      ErrorToaster(GST_ERROR_MSG.exemptionReason);
      return;
    } else {
      if (!tax.is_label) {
        gstOnChange(tax, {
          item_exemption_type: "NON_TAXABLE",
          item_exemption_id: exemptionReasonId || 0,
        });
        setExemptionmodalOpen(false);
      }
      ref.current.close();
      inputRef.current?.classList.remove("show");
      inputRef.current?.setAttribute("aria-expanded", "false");
      ulRef.current?.classList.remove("show");
    }
  };
  const handleOpenExemptionReasonmodal = () => {
    if (gstRegistrationType === gstRegistrationTypes.overseas) {
      ErrorToaster(GST_ERROR_MSG.overseas);
      return;
    }
    ref.current.open();
    setExemptionmodalOpen(true);
  };
  const handleCloseExemptionReasonmodal = () => {
    setExemptionReason("");
    setExemptionReasonId(null);
    ref.current.close();
    setExemptionmodalOpen(false);
  };

  return (
    <>
      <div className={`btn-group taxcode-dropdown`}>
        <input
          type="text"
          name="tax_id"
          className={`dropdown-toggle gst-tax-input`}
          placeholder="Tax code"
          data-bs-toggle={isExemptionModalOpen ? `outside` : `dropdown`}
          value={taxName}
          onChange={() => {}}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          id={`tax-code-${itemIndex}`}
          disabled={disabled ? disabled : isTaxDisabled ? true : false}
          ref={inputRef}
          style={{ borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}
        />
        <ul className="dropdown-menu" ref={ulRef}>
          {gstTaxList.map((tax, index) => {
            return (
              <li
                onClick={() => {
                  if (!tax.is_label) {
                    liClickHandler(tax);
                  }
                }}
                key={index}
              >
                {tax.is_tax_preference && (
                  <Link
                    className={`dropdown-item ${
                      taxName === tax.name ? "active" : ""
                    }`}
                    to="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    {tax.name === "Non-Taxable" &&
                    gstRegistrationType !== gstRegistrationTypes.overseas ? (
                      <Popup
                        ref={ref}
                        trigger={
                          <Link
                            className={`non-taxable-link ${
                              taxName === tax.name ? "active" : ""
                            }`}
                            tabIndex={0}
                            to="#"
                            onClick={(e) => e.preventDefault()}
                          >
                            {tax.name}
                          </Link>
                        }
                        position="bottom center"
                        className="exemption-popup"
                      >
                        <div className="exemption-popup-body">
                          <div className="popup-heading">
                            <p className="popup-heading-text">
                              Choose the reason for exemption
                            </p>
                            <img
                              className="remove-icon"
                              src={CloseImg}
                              onClick={handleCloseExemptionReasonmodal}
                              alt="close"
                            />
                          </div>
                          <div className="popup-content-body">
                            <FormControl
                              sx={gstSelectModalCustomStyle.addItemForm}
                            >
                              <label
                                htmlFor="taxPreference"
                                className="popup-select-heading required"
                              >
                                Exemption Reason
                              </label>
                              <Select
                                name="tax_preference"
                                id="tax_preference"
                                options={
                                  exemptionDropdownList.length > 0
                                    ? exemptionDropdownList.map((item) => {
                                        return {
                                          label: item.exemption_reason,
                                          value: item.id.toString(),
                                        };
                                      })
                                    : []
                                }
                                className={`state-select form-select custom-select`}
                                value={
                                  exemptionReason
                                    ? {
                                        label: exemptionReason,
                                        value: exemptionReasonId,
                                      }
                                    : ""
                                }
                                onChange={(e) => {
                                  handleTaxExemptionChange(e);
                                }}
                                placeholder={"Select or type to add"}
                                isSearchable={false}
                              />
                              <span className="error">{}</span>
                            </FormControl>
                          </div>
                          <div className="popup-button-group">
                            <button
                              className="btn save-button mx-0"
                              onClick={() => handleExemptionReasonSubmit(tax)}
                            >
                              Update
                            </button>
                            <button
                              className="text-decoration-none cancel-button mx-3"
                              onClick={handleCloseExemptionReasonmodal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Popup>
                    ) : (
                      <span className="itemLabel">{tax.name}</span>
                    )}
                  </Link>
                )}
              </li>
            );
          })}
          {gstTaxList.map((tax, index) => {
            return (
              <li
                onClick={() => {
                  if (!tax.is_label) {
                    liClickHandler(tax);
                  }
                }}
                key={index}
              >
                {tax.is_label ? (
                  <>
                    <p className="tax-dropdown-label">{tax.name}</p>
                  </>
                ) : (
                  !tax.is_tax_preference && (
                    <Link
                      className={`dropdown-item ${
                        taxName === `${tax.name}-[${tax.rate}%]` ? "active" : ""
                      }`}
                      to="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="itemLabel">
                        {tax.name}-[{tax.rate}%]
                      </span>
                    </Link>
                  )
                )}
              </li>
            );
          })}
          {gstTaxList.length === 0 ? (
            <li className="no-result">
              <Link
                className="dropdown-item no-result"
                to="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No tax code found</span>
              </Link>
            </li>
          ) : null}
          {taxConfigurationPermission.includes("Create") &&
          IsFeatureEnabled(
            "Tax Configuration",
            disabledFeature ? disabledFeature : []
          ) ? (
            <li>
              <TaxModal
                taxId={0}
                taxIdReset={() => {}}
                organizationId={organizationId}
                handleModalClose={() => {}}
                isAccountDeleted={false}
                refreshList={() => {
                  if (stateType) {
                    fetchGstTaxList(stateType);
                  }
                }}
              >
                <Link
                  to="#"
                  className="dropdown-item add-link-option"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span className="itemLabel">New tax</span>
                </Link>
              </TaxModal>
            </li>
          ) : null}
        </ul>
        <div className="gst-tax-cancel-button">
          {item.gst_tax && item.gst_tax?.tax_id ? (
            <Button
              wrapperClass={`removeItem delete-container-btn ${
                isTaxDisabled ? "tax-delete-is-disabled" : ""
              }`}
              onClick={() => clearGstSelection(itemIndex, item.gst_tax)}
              type="button"
            >
              <ItemCloseButton />
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
}
