import { ObjectType } from "../imports";

export const getFullyAllocatedSundryInvoiceIds = (items: ObjectType[]) => {
  const ids = items
    .filter((item) => item.is_sundry_tag_associated)
    .filter(
      (item) => Number(item.invoice_amount) === Number(item.payment)
    )
    .map((item) => {
      return item.invoice_id;
    });
  return ids;
};
