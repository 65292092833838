import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { TransactionEditTagRemovalAlert } from "./TransactionEditTagRemovalAlert";

type RemovalAlertProps = {
  module: string;
  onSubmit: () => void;
  onCancel: () => void;
  hideButtonText?: boolean;
  openAlertRef: any;
};

export const TransactionEditTagRemovalAlertModal = (
  props: RemovalAlertProps
) => {
  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <TransactionEditTagRemovalAlert
                {...props}
                onCancel={() => {
                  onCloseModal();
                  props.onCancel();
                }}
                module={props.module}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      ref={props.openAlertRef}
      onCloseFunction={() => {
        props.onCancel();
      }}
      buttonContent={""}
      buttonClass="no-appearance w-100 p-0"
      modalTitle={`Are you sure you want to update this ${props.module}`}
      isCloseButtonEnabled={true}
    />
  );
};
