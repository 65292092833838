import { FormikProps } from "formik";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../../../../hooks/useCommon";
import { decimalPlaceOfCurrency } from "../../../../../../../../helpers/decimalPlaceHelper";
import { Container } from "../../../../../../../common/components/htmlTags/Container";

type Props = {
  formik: FormikProps<any>;
  currencyCode: string;
};
export const ApplyToInvoiceBalanceSummary = (props: Props) => {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  return (
    <Container className="amt-summary d-flex flex-column align-items-end w-100">
      <div className="amt-grid">
        <div className="row g-0">
          <div className="item-column">{"Amount to Credit:"}</div>
          <div className="amt-column">
            {props.currencyCode}{" "}
            {props.formik.values.amount_to_credit
              ? NumberFormat(
                  Number(props.formik.values.amount_to_credit),
                  props.currencyCode,
                  orgCurrencyList
                )
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    props.currencyCode
                      ? props.currencyCode
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
          </div>
        </div>
        <div className="row g-0">
          <div className="item-column">{"Credit Note Balance:"}</div>
          <div
            className={`amt-column ${
              Number(props.formik.values.credit_balance) < 0
                ? "negative-amt"
                : ""
            }`}
          >
            {props.currencyCode}{" "}
            {props.formik.values.balance
              ? NumberFormat(
                  Number(props.formik.values.credit_balance),
                  props.currencyCode,
                  orgCurrencyList
                )
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    props.currencyCode
                      ? props.currencyCode
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
          </div>
        </div>
      </div>
    </Container>
  );
};
