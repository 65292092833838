import _isEqual from "lodash/isEqual";
import { ObjectType } from "../../types";

//Check for changes made in field values
export default function checkObjectFieldChanged (
  baseObject: ObjectType,
  changeObject: ObjectType
):boolean {
  let hasChanged:boolean = false;
  if (baseObject && changeObject) {
    hasChanged = !_isEqual(baseObject, changeObject);    
  }
  return (hasChanged);
}