import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  AccountsGLCodeRanges,
  AccountsList,
  AccountsTreeValues,
  COA,
  ObjectType,
  coaAmountCalculation,
} from "../../../types";
import {
  createCoa,
  deleteCoa,
  editAccountGlRanges,
  fetchCoaById,
  getAccountGlRanges,
  getAccountType,
  getSearchSuggestions,
  getSubAccounts,
  glCodeValidate,
  listViewOfCoa,
  treeViewInAddAccount,
  treeViewOfCoa,
  updateCoa,
  getAccountsGLCode,
  getBankAccounts,
  accountNameExists,
  incomeExpenseTotal,
  TransactionJournals,
  TransactionStatus,
  OpeningBalanceCalculation,
  getPaymentBankAccounts,
  ReportCustomizationAccountFetch,
  TransactionStatusOfAccount,
  isGLCodeExist,
  updateHeadOfAccount,
  AuditLogs,
} from "./coaAPI";

export const create = createAsyncThunk(
  "create/coa",
  async (data: { parentId: number; value: COA; orgId: number }) => {
    return await createCoa(data);
  }
);

export const fetchById = createAsyncThunk(
  "fetch/coa",
  async (data: { accountId: number; orgId: number }) => {
    return await fetchCoaById(data);
  }
);

export const update = createAsyncThunk(
  "updateRole",
  async (data: { accountId: number; value: COA; orgId: number }) => {
    return await updateCoa(data);
  }
);

export const deleteById = createAsyncThunk(
  "deleteRole",
  async (data: { accountId: number; orgId: number }) => {
    return await deleteCoa(data);
  }
);

export const getCoaListView = createAsyncThunk(
  "coaListView",
  async (data: {
    page: number;
    itemsPerPage: number;
    accountName: string;
    nameSort: string;
    typeSort: string;
    balanceSort: string;
    orgId: number;
  }) => {
    return await listViewOfCoa(data);
  }
);

export const getCoaTreeView = createAsyncThunk(
  "coaTreeView",
  async (data: { name: string; orgId: number }) => {
    return treeViewOfCoa(data);
  }
);

export const getCoaTreeViewInAddAccount = createAsyncThunk(
  "account-modal-tree",
  async (orgId: number) => {
    return treeViewInAddAccount(orgId);
  }
);

export const accountSuggestions = createAsyncThunk(
  "account/suggestion",
  async (data: { accountName: string; orgId: number }) => {
    return await getSearchSuggestions(data);
  }
);

export const fetchAccountType = createAsyncThunk(
  "accountType",
  async (data: { accountId: number; orgId: number }) => {
    return getAccountType(data);
  }
);

export const accountGlRanges = createAsyncThunk(
  "account/GLCode",
  async (orgId: number) => {
    return getAccountGlRanges(orgId);
  }
);

export const updateAccountGlRanges = createAsyncThunk(
  "account/GLCode/Edit",
  async (data: { accounts: AccountsGLCodeRanges[]; orgId: number }) => {
    return editAccountGlRanges(data);
  }
);

export const glRangesValidate = createAsyncThunk(
  "account/GLCode/Validate",
  async (data: { glCodeRange: AccountsGLCodeRanges[]; orgId: number }) => {
    return glCodeValidate(data);
  }
);

export const subAccountsOfParent = createAsyncThunk(
  "sub-account",
  async (data: {
    parentAccountName: string[];
    orgId: number;
    transactionType?: string;
  }) => {
    return getSubAccounts(data);
  }
);

export const fetchAccountsGlCode = createAsyncThunk(
  "fetchAccountsGlCode",
  async (data: {
    accountSearchTerm: string;
    baseAccount: string | null;
    orgId: number;
    signal?: AbortSignal;
  }) => {
    return getAccountsGLCode(data);
  }
);

export const fetchBankAccounts = createAsyncThunk(
  "bank-account",
  async (data: {
    currencyId: number;
    orgId: number;
    transaction_source: string;
  }) => {
    return getBankAccounts(data);
  }
);

// Temporary slice to call API for getting bank account dropdown in PR and PM
export const fetchPaymentBankAccounts = createAsyncThunk(
  "bank-account",
  async (data: {
    currencyId: number;
    orgId: number;
    transaction_source: string;
  }) => {
    return getPaymentBankAccounts(data);
  }
);

export const checkAccountNameExist = createAsyncThunk(
  "account-exists",
  async (data: {
    accountName: string;
    editAccountId: number;
    orgId: number;
    signal: AbortSignal | undefined;
  }) => {
    return accountNameExists(data);
  }
);

export const getIncomeExpenseTotal = createAsyncThunk(
  "income-expense-total",
  async (data: {
    currencyId: number;
    startDate: string;
    endDate: string;
    orgId: number;
  }) => {
    return await incomeExpenseTotal(data);
  }
);

export const fetchTransactionJournals = createAsyncThunk(
  "transaction/journals",
  async (data: {
    transactionType: string;
    transactionId: number;
    orgId: number;
    isCashPosting: boolean;
  }) => {
    return await TransactionJournals(data);
  }
);

export const fetchAuditDetails = createAsyncThunk(
  "audit-log",
  async (data: {
    transactionType: string;
    transactionId: number;
    orgId: number;
    pageToken?: string | null;
  }) => {
    return await AuditLogs(data);
  }
);

export const coaTransactionStatus = createAsyncThunk(
  "transaction/status",
  async (data: { orgId: number }) => {
    return await TransactionStatus(data);
  }
);

export const getTransactionStatusOfAccount = createAsyncThunk(
  "transaction_status",
  async (data: {
    transactionId: number;
    orgId: number;
    transaction: string;
  }) => {
    return await TransactionStatusOfAccount(data);
  }
);

export const amountCalculation = createAsyncThunk(
  "opening-balance/amount-calculation",
  async (data: {
    orgId: number;
    values: coaAmountCalculation;
    signal?: Object;
  }) => {
    return await OpeningBalanceCalculation(data);
  }
);

export const fetchAccountsForFilter = createAsyncThunk(
  "/account-filter",
  async (data: { orgId: number; signal?: AbortSignal; field: string }) => {
    return await ReportCustomizationAccountFetch(data);
  }
);

export const moveAndUpdateHeadOfAccount = createAsyncThunk(
  "/move-ledgers",
  async (data: {
    values: { existing_head_id: number; new_head_id: number };
    account_id: number;
    orgId: number;
  }) => {
    return await updateHeadOfAccount(data);
  }
);

export const checkGLCodeExists = createAsyncThunk(
  "glCode_status",
  async (data: {
    glCode: number | string;
    accountId?: number | null;
    orgId: number;
    signal: AbortSignal;
  }) => {
    return await isGLCodeExist(data);
  }
);

interface AccountsState {
  accounts: AccountsList;
  accountsTree: AccountsTreeValues[];
  accountsTreeModal: AccountsTreeValues[];
  accountsGlCode: { account_list: ObjectType[] };
}

const initialState: AccountsState = {
  accounts: {
    list_view: [],
    total: 0,
    page_size: 10,
    pagination: {
      next: null,
      previous: null,
    },
  },
  accountsTree: [],
  accountsTreeModal: [],
  accountsGlCode: { account_list: [] },
};

export const coaSlice = createSlice({
  name: "chartOfAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoaListView.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.accounts = payload;
        }
      })
      .addCase(getCoaTreeView.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.accountsTree = payload;
        }
      })
      .addCase(getCoaTreeViewInAddAccount.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.accountsTreeModal = payload;
        }
      })
      .addCase(fetchAccountsGlCode.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.accountsGlCode = payload;
        }
      });
  },
});

export default coaSlice.reducer;

// State selectors
export const accountsSelector = (state: RootState) =>
  state.chartOfAccounts.accounts;
export const accountTreeSelector = (state: RootState) =>
  state.chartOfAccounts.accountsTree;
export const accountTreeModalSelector = (state: RootState) =>
  state.chartOfAccounts.accountsTreeModal;
export const accountsGlCodeSelector = (state: RootState) =>
  state.chartOfAccounts.accountsGlCode;
