import InvoicePreviewHeader from "./ProformaPreviewHeader";
import "./ProformaPreview.css";
function ProformaPreview() {
  return (
    <div className="">
      <div className="card card-dashboard w-100">
        <InvoicePreviewHeader />
      </div>
    </div>
  );
}

export default ProformaPreview;
