import React from "react";
import "../../styles/EmptyList.css";
import "./EmptySearchList.css";
type Props = {
  alt: string;
  icon: string;
  emptyText: string;
};
const EmptySearchList = (props: Props) => {
  return (
    <>
      <div className="no-list-item-container align-center">
        <img
          className="preview-img"
          src={props.icon}
          alt={props.alt}
          width="108"
          height="120"
        />
        <h3>{props.emptyText}</h3>

        {/* <p>{`No ${props.list} found matching that filter`}</p> */}
      </div>
    </>
  );
};

export default EmptySearchList;
