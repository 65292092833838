import { GroupIcon } from "../../../../../../assets/images";
import EmptyList from "./EmptyList";
import TableContainer from "../../../../../common/components/table/TableContainer";

type Props = {
  columns: any[];
  data: any[];
  refreshList: () => void;
};
function TcsTable({ columns, data, refreshList }: Props) {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let tcsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TCS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  return (
    <TableContainer
      className="list-table table table-striped w-100"
      columns={columns}
      data={data}
      url={"/users"}
      sortHandler={() => {}}
      editPermission={false}
      isDetailViewExist={false}
    >
      <EmptyList
        heading={"No TCS Found"}
        subheading={
          "Configures TCS taxes to add in Invoice, Bills, and Credit note"
        }
        list="TCS"
        alt="create TCS preview"
        buttonText="Add New TCS"
        navigateLink=""
        navigateType="modal"
        isCrateAccess={tcsPermission.includes("Create") ? true : false}
        icon={GroupIcon}
        transactionLimit={false}
        onClick={() => {}}
        refreshList={refreshList}
      />
    </TableContainer>
  );
}

export default TcsTable;
