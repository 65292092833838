import Grid from "@mui/material/Grid";
import { useState } from "react";
import { ItemValue } from "../../subscriptionType";
import ListCellTablePopoverSubscriptionView from "../../../../common/components/listCellPopover/ListCellTablePopoverSubscriptionView";

type CustomPriceColumnProps = {
    value: ItemValue[];
    pricingModel: string;
    index: number;
    parentIndex?: number;
};

function SubscriptionPricingDetailsView(props: CustomPriceColumnProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const onMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if(anchorEl === null) {
        setAnchorEl(event.currentTarget);
      }
    };
  
    const onPopoverClose = () => {
      setAnchorEl(null);
    };  

    const open = Boolean(anchorEl);
    const id = open ? `price-item-popover ${props.index} ${props.parentIndex}` : undefined;

    return (
      <>
        <Grid container spacing={1} sx={{ padding: '0px 0px', margin: '0px' }}>
          <Grid item xs={12} className="addon-pricing-item-grid"
          >
            <span
              aria-describedby={id}
              onClick={onMouseOver}
            >
              <span
                className="addon-pricing-item view-pricing-breakdown-btn"
                style={{color: '#0076ff'}}
              >
                View pricing
              </span>
            </span>
          </Grid>
        </Grid>

        <ListCellTablePopoverSubscriptionView
          id={id}
          open={open}
          anchorEl={anchorEl}
          value={props.value}
          onPopoverClose={onPopoverClose}
        />
      </>
    );
}

export default SubscriptionPricingDetailsView;
