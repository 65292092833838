import { ReportTagFormValues } from "../../../../types";

export const payloadRefiner = (
  value: ReportTagFormValues
): ReportTagFormValues => {
  return {
    name: value.name?.trim(),
    options: value.options?.map((option) => {
      return {
        ...option,
        name: option.name?.trim(),
      };
    }),
  };
};
