import React from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Row, useTable } from "react-table";
import { TableData } from "../../../../types";
import Table from "../table/Table";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import useCommonData from "../../../hooks/useCommon";
import SubscriptionPricingDetailsView from "../../../home/subscription/components/view/SubscriptionPricingDetailsView";

type Props = {
  columns: any[];
  // data: TableData[];
  data: any;
  className: string;
  hiddenColumns?: string[];
  children?: React.ReactNode;
  navigation?: (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => void;
  rowNavigation?: (row: Row<TableData>) => void;
  haveColumnClassName?: boolean;
  currency_code: string;
  pricingModel: string;
  component?: string;
};

export default function DetailsTableContainerSubscription(props: Props) {
  const navigate = useNavigate();
  const { orgCurrencyList } = useCommonData();

  const rateFormat = (rate: any, pricingModel: string, currency_code: any) => {
    let formatedrate =
      currency_code +
      " " +
      NumberFormat(Number(rate), currency_code, orgCurrencyList);

    if (pricingModel === "PER_UNIT") {
      formatedrate = formatedrate + "/Unit";
    }

    return formatedrate;
  };
  
  const data = props.data
    ? props.data.map(
        (
          itemData: any,
          index: any
        ) => ({
          show: {
            description: itemData.item_name,
            hsnCode: itemData.hsn_or_sac_code,
            account: itemData.account_name,
            quantity: NumberFormat(
              Number(itemData.quantity),
              props.currency_code,
              orgCurrencyList
            ),

            rate:
              props.pricingModel === "FLAT_FEE" ||
              props.pricingModel === "PER_UNIT" || props.component === "invoice"? (
                rateFormat(
                  itemData.rate,
                  props.pricingModel,
                  props.currency_code
                )
              ) : (
                <SubscriptionPricingDetailsView
                  value={itemData}
                  parentIndex={index}
                  pricingModel={props.pricingModel}
                  index={index}
                />
              ),

            discount: {
              discount:
                props.currency_code +
                " " +
                NumberFormat(
                  Number(itemData.discount),
                  props.currency_code,
                  orgCurrencyList
                ),
              discountAccount: itemData.discount_account_name,
            },
            tax:
              itemData.gst_tax?.tax_id !== null
                ? itemData.gst_tax
                : itemData.taxes,
            taxableAmount:
              props.currency_code +
              " " +
              NumberFormat(
                Number(itemData.taxable_amount),
                props.currency_code,
                orgCurrencyList
              ),
            item: itemData,
          },
        })
      )
    : [];

  const detailViewHandler = (action: {
    url: string;
    permission: boolean;
    customNavigation?: boolean;
  }) => {
    if (action.url) navigate(action.url);
  };

  const tbodyRowOnClick = (row: Row<TableData>) => {
    if (row.values.action?.customNavigation && props.rowNavigation)
      props.rowNavigation(row);
    // if (row.values.action.customNavigation && props.rowNavigation)
    //   props.rowNavigation(row);
    // else if (row.values.action) detailViewHandler(row.values.action);
  };
  

  return (
    <Table
      id={""}
      columns={props.pricingModel === "FLAT_FEE"? props.columns.filter(obj => obj.Header !== "Quantity") : props.columns}
      data={data}
      tableClassName={props.className}
      dataSort={false}
      isLoading={false}
      haveColumnClassName={
        props.haveColumnClassName ? props.haveColumnClassName : false
      }
      rowClassName={"table-navigation"}
      emptyClassName={"text-center dataTables_empty"}
      hiddenColumns={props.hiddenColumns}
      tbodyRowOnClick={tbodyRowOnClick}
      tbodyColumnClick={props.navigation ? props.navigation : () => {}}
    >
      {props.children}
    </Table>
  );
}
