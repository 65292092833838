import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { customSelectStyle } from "../SelectCustomStyle";
import { SubAccountValues } from "../../../../types";
import { initialSubAccountValues } from "../../../home/paymentsMade/components/createBillPayment/StateInitialization";
import useCommonData from "../../../hooks/useCommon";
import { subAccountsOfParent } from "../../../home/chartOfAccounts/coaSlice";

type Props = {
  bankChargesAccountId: string | number | null;
  handleBankCharge: (e: any) => void;
  accountNames: string[];
  error: any;
  wrapperClass?: string;
  disabled?: boolean;
  placeholder?: string;
  menuPlacement?: any;
};
function AccountSelect(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [subAccounts, setSubAccounts] = useState<SubAccountValues[]>([
    initialSubAccountValues,
  ]);

  useEffect(() => {
    if (props.accountNames) getSubAccounts();
  }, [currentUserInfo.organization_id]);

  /**
   * Get accounts list drop down Api
   */
  const getSubAccounts = useCallback(async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: props.accountNames,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setSubAccounts(response);
      }
    }
  }, [props.accountNames, currentUserInfo.organization_id]);
  /**
   * Get the sub-account account name in the selection dropdown : Edit Bill Payment
   */
  const AccountName = subAccounts
    .filter(
      (subAccount) => subAccount.id === Number(props.bankChargesAccountId)
    )
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  /**
   * Get the list of all sub accounts of an account
   */
  const SubAccounts = subAccounts.map((subAccount) => ({
    label: subAccount.account_name,
    value: subAccount.id,
  }));

  return (
    <Select
      name="bank_charges_account_id"
      value={AccountName}
      options={SubAccounts}
      placeholder={props.placeholder ? props.placeholder : "Choose"}
      onChange={props.handleBankCharge}
      className={`form-select custom-select payment_method  m-0 bank_charge ${
        props.error ? "error" : ""
      } ${props.wrapperClass ? props.wrapperClass : ""}`}
      styles={customSelectStyle}
      isDisabled={props.disabled}
      menuPlacement={props.menuPlacement}
    />
  );
}

export default React.memo(AccountSelect);
