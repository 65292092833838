import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  currentModule,
  getErrorID,
  moduleSelector,
  setErrorID,
  setIsFilterApplied,
  setListDateSortOrder,
  setListEndCount,
  setListFilters,
  setListItemOffset,
  setListItemsPerPage,
  setListPage,
  setListPageCount,
  setListStartCount,
} from "./appSlice";
import {
  currentUserSelector,
  setDateListFilter,
  setDueDateListFilter,
  setFiscalYearFilter,
  setFiscalYearFilterLabel,
} from "./features/common/commonSlice";
import AccessDenied from "./features/common/components/AccessDenied";
import { IsFeatureEnabled } from "./helpers/featureFlagHelper";
import {
  getFiscalYear,
  getPreviousFiscalYears,
} from "./helpers/fiscalYearHelper";
import { getFiscalYearLabel } from "./helpers/getFiscalYearLabelHelper";

export const PrivateRoute = ({
  children,
  subModule,
  permission,
}: {
  children: JSX.Element;
  subModule: string;
  permission: string;
}) => {
  const dispatch = useAppDispatch();
  const module = useAppSelector(moduleSelector);
  const errorID = useAppSelector(getErrorID);
  const currentUserInfo = useAppSelector(currentUserSelector);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const fiscalYearArray = getPreviousFiscalYears(
    10,
    currentUserInfo.organization_fiscal_year as string
  );
  const subModules = organizationRole.length
    ? organizationRole
        .filter((sub: any) => sub.subModule === subModule)
        .map((module: any) => {
          return module.subModule;
        })
    : [];

  const subModulePermissions = organizationRole.length
    ? organizationRole
        .filter((module: any) => module.subModule === subModule)
        .map((module: any) => {
          return module.permission;
        })
    : [];

  if (
    subModules.length === 0 ||
    !IsFeatureEnabled(subModule, currentUserInfo.disabled_feature)
  ) {
    return <AccessDenied />;
  }

  if (subModule.length !== 0 && !subModulePermissions.includes(permission)) {
    return <AccessDenied />;
  }

  if (errorID !== 0 && !window.location.href.includes("recurring-inv/detail")) {
    if ($(".modal").length > 0) {
      $(".modal").modal("hide");
    }
    return <AccessDenied error_id={errorID} />;
  }

  if (module !== subModule) {
    dispatch(setListPage(1));
    dispatch(setListItemsPerPage(10));
    dispatch(setListStartCount(0));
    dispatch(setListEndCount(0));
    dispatch(setListPageCount(1));
    dispatch(setListItemOffset(0));
    dispatch(setListDateSortOrder(""));
    dispatch(setListFilters({}));
    dispatch(setIsFilterApplied(false));
    dispatch(setErrorID(0));
    dispatch(currentModule(subModule));
    dispatch(
      setFiscalYearFilter({
        startDate: "",
        endDate: "",
      })
    );
    dispatch(
      setDueDateListFilter({
        dueDate: {
          startDate: "",
          endDate: "",
        },
      })
    );
    dispatch(
      setDateListFilter({
        date: {
          startDate: "",
          endDate: "",
        },
      })
    );
    dispatch(setFiscalYearFilterLabel("All"));
    return children;
  } else {
    return children;
  }
};
