export const AddNewSquarePlusIcon = () => {
  return (
    <div style={{alignSelf:"center"}}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M3.333 2.5h13.334c.46 0 .833.373.833.833v13.334c0 .46-.373.833-.833.833H3.333a.833.833 0 0 1-.833-.833V3.333c0-.46.373-.833.833-.833zm.834 1.667v11.666h11.666V4.167H4.167zm5 5V5.833h1.666v3.334h3.334v1.666h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334z"
          fill="#0D5AE5"
          fill-rule="nonzero"
        />
      </g>
    </svg>
    </div>
  );
};
