import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import BillDebited from "./BillDebited";
import { HorizontalTab } from "../../../../common/components/HorizontalTab";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import {
  DebitNoteAllocatedList,
  DebitNoteDetailValues,
} from "../../../../../types";
import { getDebitNoteAllocation } from "../../debitNoteSlice";

type Props = {
  debitNoteData: DebitNoteDetailValues;
  refreshDNDetails: () => void;
};
const DNAllocationDetails: ForwardRefRenderFunction<any, Props> = (
  { debitNoteData, refreshDNDetails },
  ref
) => {
  const { editId } = useParams();
  const dispatch = useAppDispatch();
  const page = 1;
  const itemsPerPage = 20;
  // const id = useParams().editBillId;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { debitNoteRolePermission, subModulesWithPermission } =
    usePermissionHandler();
  const [allocationCount, setAllocationCount] = useState(0);
  const [allocationList, setAllocationList] = useState<
    DebitNoteAllocatedList[]
  >([]);
  const [isParent, setIsParent] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      fetchDebitAllocation: getDebitAllocationDetails,
    }),
    [debitNoteData.id]
  );

  useEffect(() => {
    if (
      Number(debitNoteData.organization_id) !== currentUserInfo.organization_id
    ) {
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  }, [debitNoteData.organization_id, currentUserInfo.organization_id]);

  useEffect(() => {
    if (debitNoteData.id) getDebitAllocationDetails();
  }, [debitNoteData.id]);

  const getDebitAllocationDetails = async () => {
    const responseAction = await dispatch(
      getDebitNoteAllocation({
        debitNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAllocationCount(response.total);
        setAllocationList(response.allocation_list);
      }
    }
  };

  return (
    <>
      {allocationCount > 0 && debitNoteRolePermission.includes("Read") && (
        <div className="dn-pm-detail-bill" key={`${isParent}`}>
          <HorizontalTab
            tabHeaders={[`Bills Debited (${allocationCount})`]}
            tabBody={[
              <BillDebited
                billList={allocationList}
                module="BILL"
                debitNoteData={debitNoteData}
                isParent={isParent}
                key={"1"}
                refreshDNDetails={refreshDNDetails}
                debitNoteRolePermission={debitNoteRolePermission}
                subModulesWithPermission={subModulesWithPermission}
              />,
            ]}
            wrapperClass="bill-dn-pm-detail-tab"
            disableRerender={true}
          />
        </div>
      )}
    </>
  );
};

export default React.forwardRef(DNAllocationDetails);
