import { ObjectType } from "../../../../../../types";

export const deferredIncomeChecker = (items: ObjectType[]) => {
  let isDeferredIncomeEnabled = items.some(
    (value) => String(value.account_type).split("/")[0] === "Liabilities"
    // &&
    //   String(value.discount_account_type).split("/")[0] === "Liabilities"
  );
  return isDeferredIncomeEnabled;
};
