import React from "react";
import useCommonData from "../../../../hooks/useCommon";
import { getSundryDebtorDetails } from "../../ReportSlice";
import AgingDetails from "./AgingDetails";

function SundryDebtorDetails() {
  const { dispatch, currentUserInfo } = useCommonData();

  const generateReport = async (filterValues: any) => {
    const responseAction = await dispatch(
      getSundryDebtorDetails({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  return (
    <AgingDetails
      title="Sundry Debtor Aging Details for Invoice Due Date"
      generateReport={generateReport}
      type="debtor"
    />
  );
}

export default SundryDebtorDetails;
