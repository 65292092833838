import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InvoiceImage } from "../../../../../../assets/images";
import {
  DateFormatHandler,
  StatusColor,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  paymentMadeColumnValues,
  paymentMadeDefaultValues,
  paymentMadeMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import {
  PaymentMadeListValues,
  Transactionlimit,
} from "../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";
import EmptyList from "../../../../../common/components/EmptyList";
import Popover from "../../../../../common/components/Popover";
import Pagination from "../../../../../common/components/table/Pagination";
import Loader from "../../../../components/Loader";
import {
  billPaymentList,
  getTransactionLimitStatus,
} from "../../../../paymentsMade/paymentsMadeSlice";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import NotificationModal from "../../../../../common/components/NotificationModal";
import TableContainer from "../../../../../common/components/table/TableContainer";
type Props = {
  isSameOrg: boolean;
};
function PMTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [billPaymentData, setBillPaymentData] = useState<
    PaymentMadeListValues[]
  >([]);
  const [totalPaymentsMade, setTotalPaymentsMade] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["actions"]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const vendorId = editId ? editId : "";
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });

  let paymentsMadePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Payments Made"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchBillPaymentList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalPaymentsMade / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalPaymentsMade, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    paymentMadeColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [billPaymentData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });

  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Payments Made",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = paymentMadeDefaultValues;
      }
      paymentMadeMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Fetch all Bill Payments
   */
  const fetchBillPaymentList = async () => {
    fetchTransactionLimit();
    setIsLoading(true);
    if (vendorId) {
      const responseAction = await dispatch(
        billPaymentList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          orgIds: [],
          vendorList: [Number(vendorId)],
          statusList: [],
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setBillPaymentData(response.payments_made);
          let length = response.payments_made?.length;
          setTotalPaymentsMade(response.total);
          if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };

  const sortHandler = (column: string) => {
    if (column === "Payment date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Payment Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Payment Number",
        accessor: selectedColumns.includes("Payment Number")
          ? "show.paymentNumber"
          : "Payment Number",
        sort: false,
        sortOrder: "",
        className: "pay_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Bill Number",
        accessor: selectedColumns.includes("Bill Number")
          ? "show.billNumber"
          : "Bill Number",
        sort: false,
        sortOrder: "",
        className: "bill_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? (
            <div className="pills-wrapper">
              <Popover values={value} tag={false} />
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: "Amount Paid",
        accessor: "show.amountPaid",
        sort: false,
        sortOrder: "",
        className: "amt_paid",
      },
      {
        Header: "Amount in Excess",
        accessor: selectedColumns.includes("Amount in Excess")
          ? "show.amountInExcess"
          : "Amount in Excess",
        sort: false,
        sortOrder: "",
        className: "amt_excess",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "pay_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Paid Through",
        accessor: selectedColumns.includes("Paid Through")
          ? "show.paidThrough"
          : "Paid Through",
        sort: false,
        sortOrder: "",
        className: "pay_through",
      },
      {
        Header: "actions",
        accessor: "actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, selectedColumns, hiddenColumns]
  );

  /**
   * set table row data
   */
  const data = billPaymentData
    ? billPaymentData?.map((paymentMade, index) => ({
        show: {
          date: DateFormatHandler(paymentMade.payment_date),
          paymentNumber: joinTransactionNumber(paymentMade.payment_number),
          referenceNumber: paymentMade.reference_number,
          billNumber: paymentMade.bill_number.map((bill) => {
            return { name: joinTransactionNumber(bill) };
          }),
          status: paymentMade.pm_status,
          amountPaid:
            paymentMade.currency_code +
            " " +
            NumberFormat(
              Number(paymentMade.amount_paid),
              paymentMade.currency_code,
              orgCurrencyList
            ),
          amountInExcess:
            paymentMade.currency_code +
            " " +
            NumberFormat(
              Number(paymentMade.amount_excess),
              paymentMade.currency_code,
              orgCurrencyList
            ),
          paidThrough: paymentMade.paid_through,
        },
        actions: {
          id: paymentMade.id,
          status: paymentMade.pm_status,
          listName: paymentMade.payment_number,
          emailTo: paymentMade.email_to,
          isUnpaidBillExist: paymentMade.unpaid_bill_lines_exist,
          redirectTo: `/vendors/detail/${editId}`,
          consumerId: editId,
        },
      }))
    : [];

  /**
   * Invoke when user click to request another page.
   */
  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalPaymentsMade;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped PM-Overview-table w-100"
        columns={columns}
        data={data}
        url={"/payment-made"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={paymentsMadePermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            paymentsMadePermission.includes("Create")
              ? "Make bill payments!"
              : "No payments found!"
          }
          subheading={
            paymentsMadePermission.includes("Create")
              ? "Start making your payments here"
              : "Oops, There is no Bill payment data available right now"
          }
          list="Payment Made"
          alt="Create Bill Payment preview"
          buttonText="Bill Payment"
          navigateLink={`/payment-made/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/vendors/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            paymentsMadePermission.includes("Create") && props.isSameOrg
          }
          icon={InvoiceImage}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}

      <div className="table-footer pagination-container w-100 clear-both">
        {totalPaymentsMade > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
}

export default PMTransactions;
