import { Link } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import UserService from "../../../../../keycloak/UserService";
import "./ProfileDropdown.css";
import { LogoutIcon, OrgGroupIcon } from "../../../../../assets/images";
import { useEffect, useState } from "react";
import OrganizationListModal from "./OrganizationListModal";
import { UserOrganizationValues } from "../../../../../types";
import { ObjectType } from "../../../../../types";
import { Settings } from "../settings/settings";

type Props = {
  profileName: string;
  displayName: string;
  profileImage: string;
  navLinkColor: string;
  invitedOrganizationList: UserOrganizationValues;
  selectOrganizationHandler: (orgId: number) => void;
  disabledFeatureList: ObjectType[];
  isLastOrgIdNull: number;
};
export const ProfileDropdown = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(true);

  const mobDevices = useMediaQuery("(max-width: 767px)");
  const closeModalHandler = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    if (
      props.isLastOrgIdNull === 1 &&
      props.invitedOrganizationList.last_login_org_id !== 0
    ) {
      // checking number of non suspended organizations.
      // If it is one, then select that particular organization
      let nonSuspendedOrgs =
        props.invitedOrganizationList.organization_details.filter(
          (org) => org.is_suspended === false
        );
      if (nonSuspendedOrgs.length === 1 && nonSuspendedOrgs[0].id !== 0) {
        props.selectOrganizationHandler(nonSuspendedOrgs[0].id);
      } else {
        setOpenModal(true);
        setShowCloseButton(false);
      }
    }
  }, [props.isLastOrgIdNull, JSON.stringify(props.invitedOrganizationList)]);

  const getCurrentOrgName = (type: string) => {
    if (props.invitedOrganizationList.organization_details.length) {
      const orgName = props.invitedOrganizationList.organization_details
        .filter((org) => org.last_login)
        .map((org) => org.name);
      const parentOrgName = props.invitedOrganizationList.organization_details
        .filter((org) => org.last_login)
        .map((org) => org.parent_hierarchy);
      if (orgName.length && type === "name") {
        return orgName[0];
      } else if (type === "name") {
        return props.invitedOrganizationList.organization_details[0].name;
      }
      if (parentOrgName.length && type === "parent") {
        return parentOrgName[0];
      } else if (type === "parent") {
        return props.invitedOrganizationList.organization_details[0]
          .parent_hierarchy;
      }
    }
    return "";
  };
  return (
    <div className="nav-item dropdown nav-profile" key={"nav-profile"}>
      <a
        className="dropdown-toggle profile-button"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={(e) => e.preventDefault()}
      >
        {/* <span className="nav-profile-name">Admin</span> */}
        {props.profileName ? (
          <span className="p_name">{props.displayName}</span>
        ) : (
          <span className="p_name no-prof-image">
            <img
              src={props.profileImage}
              alt="default-profile-img"
              width={20}
              height={20}
            />
          </span>
        )}
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        {/* hiding the settings and Profile option from profile dropdown as per ticket: https://app.clickup.com/t/85zrmgcae */}
        <li>
          <div className="org-det-wrapper w-100">
            <div className="profile-navbar-header">
              <img
                src={OrgGroupIcon}
                alt="org-group-icon"
                className="org-icon"
              />
              <div className="org-name-wrapper">
                <span
                  className="org-name-header ellipsis"
                  style={{ maxWidth: "230px" }}
                >
                  {getCurrentOrgName("name")}
                </span>
                <span
                  className="org-name-parent ellipsis"
                  style={{ maxWidth: "230px" }}
                >
                  {getCurrentOrgName("parent")}
                </span>
              </div>
            </div>
            <div className="change-org-wrapper">
              <span
                className="change-org-content"
                onClick={() => {
                  setOpenModal(true);
                  setShowCloseButton(true);
                }}
              >
                Change Organization
              </span>
            </div>
          </div>
        </li>
        {/* <div className="profile-underline"></div> */}
        {/* hiding the Search option and All option dropdown from Header as per ticket: https://app.clickup.com/t/85zrmgcae */}
        {mobDevices && (
          <li key={"nav-settings"} className="no-padding">
            {" "}
            <Settings disabledFeatureList={props.disabledFeatureList} />
          </li>
        )}
        <li>
          <Link
            style={{ color: props.navLinkColor }}
            to="#"
            className="dropdown-items logout-item"
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("refresh-token");
              UserService.doLogout();
              sessionStorage.removeItem("orgId");
              sessionStorage.removeItem("rolePermissions");
              sessionStorage.removeItem("organizationRole");
              sessionStorage.removeItem("OrgName");
            }}
          >
            <img src={LogoutIcon} alt="logout" width={20} height={20} />
            <span>Log out</span>
          </Link>
        </li>
      </ul>
      {openModal && (
        <OrganizationListModal
          invitedOrganizationList={props.invitedOrganizationList}
          selectOrganizationHandler={props.selectOrganizationHandler}
          closeModal={closeModalHandler}
          showCloseButton={showCloseButton}
        />
      )}
    </div>
  );
};
