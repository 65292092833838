import { Api } from "../../../constants";
import {
  get, put, post, postFormData, remove
} from "../../../helpers/apiHelper";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
  RecurringInvoiceValues,
} from "../../../types";
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.salesRecurringInvoice +`transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}


export async function getRecurringInvoiceList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  customerList: number[];
  orgIds: number[];
  statusList: string[];
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.salesRecurringInvoice +
    Api.invoice + 
    "/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.startDate ? `&next_invoice_start_date=${data.startDate}` : ""
    }${data.endDate ? `&next_invoice_end_date=${data.endDate}` : ""}${
      data.startDueDate ? `&end_date_start_date=${data.startDueDate.trim()}` : ""
    }${
      data.endDueDate ? `&end_date_end_date=${data.endDueDate.trim()}` : ""
    }`.trim();
    

  if (data.customerList.length !== 0) {
    const customerIds = data.customerList
      .map((customerId) => {
        return `&customer_ids=${customerId}`;
      })
      .join("");
    url += customerIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);

  return result;
}

export async function deleteRecurringInvoice(data: {
  deleteRInvoiceId: number;
  orgId: number;
}) {
    const result = await remove(
      Api.salesRecurringInvoice +
        Api.invoice +
        "/delete" +
        `/${data.deleteRInvoiceId}?current_organization=${data.orgId}`
    );

  return result;
}

export async function getChildInvoiceList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  invoiceId: number;
  orgId: number;
}) {
  let url =
  Api.salesInvoice +
    "invoice/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&recurring_invoice_id=${data.invoiceId}&current_organization=${data.orgId}`;
  
    if (data.dateSortOrder !== "") {
      url += `&date_sort_order=${data.dateSortOrder}`;
    }
  const result = await get(url);

  return result;
}

/**
 * API call for calculating taxable amount
 */

export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.salesInvoice +
    `invoice-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating invoice amount
 */

export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.salesInvoice +
      Api.invoice +
      `/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * Api to upload invoice attachments
 */
export async function invoiceAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.salesRecurringInvoice + `invoice-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete invoice attachments
 */
export async function invoiceAttachmentsDelete(data: {
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.salesRecurringInvoice +
      `invoice-attachments/delete/${data.fileId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating invoice
 */
export async function createNewRecurringInvoice(data: {
  values: RecurringInvoiceValues;
  status: string;
  isSendMail: boolean;
  isPrintOrDownload: boolean;
  orgId: number;
}) {
  const result = await post(
    Api.recurringSalesInvoice +
      Api.recurringInvoice +
      `/create?invoice_status=${data.status}&is_send_mail=${data.isSendMail}&is_print_or_download=${data.isPrintOrDownload}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to update recurring invoice status (stop/resume)
 */
export async function updateRecurringInvoiceStatus(data: { id: number; status: string; orgId: number}) {
  const result = await put(
    Api.salesRecurringInvoice +
      "update-status" +
      `/?id=${data.id}&status=${data.status}&current_organization=${data.orgId}` ,
      {id: data.id, status: data.status, current_organization: data.orgId}
  );
  return result;
}

/**
 * API call for getting recurring invoice detail for edit and clone
 */

export async function getRecurringInvoiceDetails(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.recurringSalesInvoice +
      Api.invoice +
      `/edit-details/${data.invoiceId}?current_organization=${data.orgId}` //pass invoiceId
  );
  
  return result;
}

/**
 * API call for edit recurring invoice
 */
export async function updateRecurringInvoice(data: {
  id: number;
  status: string;
  editRecurringInvoiceInputs: RecurringInvoiceValues;
  orgId: number;
}) {
  const result = await put(
   Api.recurringSalesInvoice  +
      Api.invoice +
      "/edit/" +
      data.id +
      `?invoice_status=${data.status}&current_organization=${data.orgId}`,
    data.editRecurringInvoiceInputs
  );
  return result;
}
/**
 * API call for getting invoice detail
 */
export async function getRIOverviewDetails(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.recurringSalesInvoice +
      `overview/${data.invoiceId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for checking the invoice number exist or not
 */
export async function profileNameExist(data: {
  profileName: string;
  orgId: number;
  invoiceId: number | null;
}) {
  const encodedName = encodeURIComponent(data.profileName);
  const result = await get(
    `${Api.recurringSalesInvoice}profile-name-exist?profile_name=${encodedName}&invoice_id=${data.invoiceId}&current_organization=${data.orgId}`
  );
  return result;
}


/**
 * API call for delete recurring invoice item details
 */
export async function deleteRInvoiceItem(data: {
  invoiceId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.recurringSalesInvoice +
      "invoice-item/delete/" +
      data.invoiceId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for send reminder
 */
export async function sendReminder(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.recurringSalesInvoice +
      "send-reminder-email/" +
      data.invoiceId+
      "?current_organization="+data.orgId
  );

  return result;
}
/**
 * API call for delete recurring invoice recipients
 */
export async function deleteRInvoiceRecipient(data: {
  invoiceId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.recurringSalesInvoice +
      "invoice-recipient/delete/" +
      data.invoiceId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}

/**
 * API call for delete recurring invoice item tax
 */
export async function deleteRInvoiceItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.recurringSalesInvoice +
      "invoice-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to update recurring invoice status (stop/resume)
 */
export async function updateRecurringInvoicePreferredStatus(data: { recurring_invoice_id: string | number; preferred_invoice_status: string; }) {
  const result = await put(
    Api.salesRecurringInvoice +
      "invoice/preferred-status" ,
    {
      recurring_invoice_id: data.recurring_invoice_id,
      preferred_invoice_status: data.preferred_invoice_status
    }
  );
  return result;
}
