import { Api } from "../../../constants";
import { get, put } from "../../../helpers/apiHelper";

/**
 * Api to get the coupon configuration
 */
export async function couponCouponConfiguratio(data: { orgId: number }) {
  const result = await get(
    Api.subscription +
      `get-coupon-configuration?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to save the coupon configuration
 */
export async function saveCouponCouponConfiguratio(data: {
  organization_id: number;
  multiple_coupons_enabled: boolean | string;
  order_of_application?: string;
  discount_application_method?: string;
}) {
  const result = await put(
    Api.subscription +
      `update-coupon-configuration?current_organization=${data.organization_id}`,
    data
  );
  return result;
}
