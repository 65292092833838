import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IsFeatureEnabled } from "../../../../../../helpers/featureFlagHelper";
import { ItemTaxValues, ObjectType } from "../../../../../../types";
import { TaxRateFormContainer } from "../../../../../home/taxConfiguration/components/taxRateForm/TaxRateFormContainer";
import { useTaxSelect } from "../TaxSelectProvider";
import "./taxCode.css";

type Props = {
  taxIndex: number;
  taxes: ItemTaxValues[];
  onTaxLabelChange: (e: any, num: number, taxIndex: number) => void;
  itemIndex: number;
  organizationId: number;
  disabled?: boolean;
  disabledFeature: ObjectType[];
};
export default function TaxCode(props: Props) {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let taxConfigurationPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Tax Configuration"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  const { taxList, fetchTaxesList } = useTaxSelect();

  useEffect(() => {
    resetDropDown();
  }, []);
  // useEffect(() => {
  //   $(".dropdown-toggle").dropdown("hide");
  // }, [props.error]

  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  const taxCodeName = (taxIndex: number) => {
    let name = taxList
      .filter((tax) => tax.id === props.taxes[taxIndex].tax_id)
      .map((taxes) => {
        return taxes.tax_code;
      });
    return name[0];
  };

  const liClickHandler = (tax: ObjectType) => {
    props.onTaxLabelChange(tax.id, props.itemIndex, props.taxIndex);
  };

  return (
    <>
      <div className={`btn-group taxcode-dropdown`}>
        <input
          type="text"
          name="tax_id"
          className={`dropdown-toggle tax-code-input`}
          placeholder="Tax code"
          data-bs-toggle="dropdown"
          value={taxCodeName(props.taxIndex)}
          onChange={() => {}}
          autoComplete="off"
          disabled={props.disabled ? props.disabled : false}
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          id={`tax-code-${props.taxIndex}`}
        />
        <ul className="dropdown-menu">
          {taxList.map((tax, index) => {
            return (
              <li
                onClick={() => {
                  liClickHandler(tax);
                }}
                key={index}
              >
                {tax.is_label ? (
                  <>
                    <p className="tax-dropdown-label">{tax.tax_code}</p>
                  </>
                ) : (
                  <Link
                    className={`dropdown-item ${
                      $(`#tax-code-${props.taxIndex}`).val() === tax.tax_code
                        ? "active"
                        : ""
                    }`}
                    to="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="itemLabel">{tax.tax_code}</span>
                  </Link>
                )}
              </li>
            );
          })}

          {taxList.length === 0 ? (
            <li className="no-result">
              <Link
                className="dropdown-item no-result"
                to="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No tax code found</span>
              </Link>
            </li>
          ) : null}
          {taxConfigurationPermission.includes("Create") &&
          IsFeatureEnabled(
            "Tax Configuration",
            props.disabledFeature ? props.disabledFeature : []
          ) ? (
            <li>
              <TaxRateFormContainer
                taxId={-1}
                refreshList={fetchTaxesList}
                isAccountDeleted={false}
              >
                <Link
                  to="#"
                  className="dropdown-item add-link-option"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span className="itemLabel">New tax</span>
                </Link>
              </TaxRateFormContainer>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
}
