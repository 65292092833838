import { SvgIconProp } from "../../../types";

export const CancelIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20"}
      height={props.height ? props.height : "20"}
      viewBox="0 0 20 20"
      style={{ margin: props.margin ? props.margin : "" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={"none"} fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M10 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 1 1 0 16.666zm0-9.511L7.643 6.464l-1.179 1.18L8.822 10l-2.358 2.357 1.18 1.179L10 11.178l2.357 2.358 1.179-1.18L11.178 10l2.358-2.357-1.18-1.179L10 8.822z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
