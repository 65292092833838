import { ObjectType, TDSFormValues } from "../../../../../types";

export const validateTdsForm = (
  values: TDSFormValues,
  paymentMode: string,
  isConditionChecked: boolean,
  tdsId: number,
  isTdsChanged: boolean
) => {
  const errors: ObjectType = {};
  if (!values.tax_name) {
    errors.tax_name = "Enter the TDS Name";
  }
  if ((!values.rate || Number(values.rate) > 100) && paymentMode === "rate") {
    errors.rate = "Enter a valid Tax Rate(%)";
  }
  if (!values.section_id) {
    errors.section_id = "Select a tax type";
  }
  if (tdsId && !isConditionChecked && isTdsChanged) {
    errors.terms_and_condition =
      "Please read and accept the Terms and Conditions";
  }
  return errors;
};
