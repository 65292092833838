import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { purchaseOrderList, deletePurchaseOrder } from "../purchaseOrderSlice";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deletePurchaseOrderId: number;
  deletePurchaseOrderItemId: string;
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  organizationId: number;
  refreshPurchaseOrders: (action: string) => void;
};
const DeletePurchaseOrder = (props: Props) => {
  const dispatch = useAppDispatch();
  const isDeletedRef = useRef(false);

  useEffect(() => {
    isDeletedRef.current = false;
  }, [props.deletePurchaseOrderId]);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    if (!isDeletedRef.current) {
      $("#delete-button").addClass("customer-form-section-disable");
      const deleteRoleResponseAction = await dispatch(
        deletePurchaseOrder({
          deletePurchaseOrderId: props.deletePurchaseOrderId,
          orgId: props.organizationId,
        })
      );
      $("#delete-button").removeClass("customer-form-section-disable");
      if (deleteRoleResponseAction.payload) {
        const deleteRoleResponse = deleteRoleResponseAction.payload;
        if (
          Object.keys(deleteRoleResponse).length &&
          !("error" in deleteRoleResponse)
        ) {
          props.refreshPurchaseOrders("delete");
          isDeletedRef.current = true;

          $(".close-btn").click();
          await dispatch(
            purchaseOrderList({
              page: props.page,
              itemsPerPage: props.itemsPerPage,
              dateSortOrder: props.dateSortOrder,
              orgId: props.organizationId,
              vendorList: [],
              orgIds: [],
              statusList: [],
            })
          );
          toast.success("Purchase Order deleted successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in deleteRoleResponse) {
          $(".close-btn").click();
        }
      }
    }
  };
  return (
    <DeleteModal
      heading=" Delete Purchase Order!"
      subHeading="Are you sure you want to delete the purchase order"
      transactionName={joinTransactionNumber(props.deletePurchaseOrderItemId)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeletePurchaseOrder;
