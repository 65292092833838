import React from "react";
import "./subscriptionPreview.css";
import { NextInvoiceDateType } from "../../subscriptionType";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../hooks/useCommon";
import { formateDateMonth } from "../../../../../helpers/subscriptionHelper";
import { ObjectType } from "../../../../../types";
import InfoTag from "../../../../common/components/InfoTag";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { coustomCaseWithRemoveUnderscore } from "../../../../../helpers/planHelper";

type props = {
  previewDetails: NextInvoiceDateType;
  currencyId: number | undefined;
};
function SubscriptionPreview({ previewDetails, currencyId }: props) {
  const { orgCurrencyList } = useCommonData();
  const currencyCode =
    orgCurrencyList.filter((item: ObjectType) => {
      return item.id === currencyId;
    })[0]?.code || "";
  return (
    <>
      {previewDetails.subscription_status ? (
        <div className="subscription-invoice-preview-status">
          <InfoTag
            status={coustomCaseWithRemoveUnderscore(
              previewDetails.subscription_status
            )}
          />
          {["IN_TRIAL", "FUTURE"].includes(
            previewDetails.subscription_status
          ) ? (
            <div className="p-t-15">
              Subscription activates on{" "}
              {previewDetails.subscription_activates_on
                ? formateDateMonth(
                    DateFormatHandler(previewDetails.subscription_activates_on)
                  )
                : ""}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={`subscription-org subscription-preview-note-wrapper subscription-preview-container
      ${
        previewDetails.subscription_status === "NON_RENEWING" ||
        previewDetails.second_invoice_amount === null
          ? "non-renewing-note-wrapper"
          : ""
      }`}
      >
        <div className="note-header pb-2">Upcoming Invoices</div>
        <div className="subscription-preview-cart-container">
          <div className="subscription-preview-cart">
            <div className="subscription-preview-cart-header-container">
              <div className="subscription-preview-cart-header subscription-preview-cart-header-heading">
                First Invoice
              </div>
              <div className="subscription-preview-cart-header">
                {/* <a href="#">View Preview</a> */}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Amount
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {`${currencyCode} ${NumberFormat(
                  Number(previewDetails.first_invoice_amount),
                  currencyCode,
                  orgCurrencyList
                )}`}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Invoice Date
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {formateDateMonth(
                  previewDetails.first_invoicing_date
                    ? DateFormatHandler(previewDetails.first_invoicing_date)
                    : ""
                )}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Period
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {`${formateDateMonth(
                  DateFormatHandler(previewDetails.first_invoice_start_date)
                )} to ${formateDateMonth(
                  DateFormatHandler(previewDetails.first_invoice_end_date)
                )}`}
              </div>
            </div>
          </div>
          {previewDetails.subscription_status === "NON_RENEWING" ||
          previewDetails.second_invoice_amount === null ? null : (
            <div className="subscription-preview-cart">
              <div className="subscription-preview-cart-header-container">
                <div className="subscription-preview-cart-header subscription-preview-cart-header-heading">
                  Next Invoice
                </div>
                <div className="subscription-preview-cart-header">
                  {/* <a href="#">View Preview</a> */}
                </div>
              </div>
              <div className="subscription-preview-cart-data-cols">
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                  Amount
                </div>
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                  {`${currencyCode} ${NumberFormat(
                    Number(previewDetails.second_invoice_amount),
                    currencyCode,
                    orgCurrencyList
                  )}`}
                </div>
              </div>
              <div className="subscription-preview-cart-data-cols">
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                  Next Invoice Date
                </div>
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                  {formateDateMonth(
                    // DateFormatHandler(previewDetails.second_invoice_start_date)
                    DateFormatHandler(previewDetails.second_invoice_date)
                  )}
                </div>
              </div>
              <div className="subscription-preview-cart-data-cols">
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                  Period
                </div>
                <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                  {`${formateDateMonth(
                    DateFormatHandler(previewDetails.second_invoice_start_date)
                  )} to ${formateDateMonth(
                    DateFormatHandler(previewDetails.second_invoice_end_date)
                  )}`}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SubscriptionPreview;
