import { OrgValues } from "./../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getHierarchyList,
  createSubOrganization,
  editSubOrganization,
  deleteSubOrganization,
  checkOrganizationNameExists,
  transactionLimitStatus,
  organizationSetupStatusSetter,
  remainingTimeStatus,
} from "./OrganizationAPI";

export const hierarchyList = createAsyncThunk(
  "hierarchyList",
  async (currentOrgId: number) => {
    return await getHierarchyList(currentOrgId);
  }
);

export const createNewSubOrg = createAsyncThunk(
  "create/subOrg",
  async (data: {
    parentId: number;
    values: OrgValues;
    currentOrgId: number;
  }) => {
    return await createSubOrganization(data);
  }
);

export const editSubOrg = createAsyncThunk(
  "edit/subOrg",
  async (data: { orgId: number; values: OrgValues; currentOrgId: number }) => {
    return await editSubOrganization(data);
  }
);

export const deleteSubOrg = createAsyncThunk(
  "delete/subOrg",
  async (data: { orgId: number; currentOrgId: number }) => {
    return await deleteSubOrganization(data);
  }
);

export const ifOrganizationNameExists = createAsyncThunk(
  "ifOrganizationNameExists",
  async (data: { name: string; signal: AbortSignal }) => {
    return await checkOrganizationNameExists(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "org/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);
export const setOrganizationSetupStatus = createAsyncThunk(
  "org/setup/status",
  async (data: { orgId: number; status: boolean }) => {
    return await organizationSetupStatusSetter(data);
  }
);

export const getRemainingAccountSetupTime = createAsyncThunk(
  "org/remaining-time/status",
  async (orgId: number) => {
    return await remainingTimeStatus(orgId);
  }
);

const initialState = {};

export const organizationSlice = createSlice({
  name: "organizationHierarchy",
  initialState,
  reducers: {},
});

export default organizationSlice.reducer;
