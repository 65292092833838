import { useAppDispatch } from "../../../../../../app/hooks";
import { InvoiceDetailValues } from "../../../../../../types";
import { SaveButton } from "../../../../../common/components/button/SaveButton";
import { setCreditNoteAutofillData } from "../../../../../common/commonSlice";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../../../../../common/components/button/SecondaryButton";
import "./createCreditNoteAlert.css";

export const CreateCreditNoteAlert = (props: {
  isCreationPossible: boolean;
  creditNoteData: InvoiceDetailValues;
  handleClose: () => void;
  invoiceId: number;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleDispatch = async () => {
    // props.isCreationPossible &&
    //   dispatch(
    //     setCreditNoteAutofillData({
    //       isFromInvoice: true,
    //       balanceDue: props.creditNoteData.total_details.balance_due,
    //       invoiceId: props.creditNoteData.id,
    //       invoiceStatus: props.creditNoteData.invoice_status,
    //       creditNoteData: {
    //         creditNoteFormValues: { ...props.creditNoteData, email_to: [] },
    //         defaultFormValues: { ...props.creditNoteData, email_to: [] },
    //         itemFormValue: props.creditNoteData.item_details.map((item) => {
    //           return {
    //             ...item,
    //             discount:
    //               item.discount.discount_percentage === null
    //                 ? item.discount.discount_value
    //                 : item.discount.discount_percentage,
    //           };
    //         }),
    //       },
    //     })
    //   );
    props.isCreationPossible && navigate(`/credit-note/create?invoiceId=${props.invoiceId}`);
  };
  return (
    <>
      <div className="create-credit-note-invoice-page">
        <span>
          {props.isCreationPossible ? (
            <p>
              On creating the credit note, the credit note amount will get
              applied for this invoice. Are you sure about creating it?
            </p>
          ) : (
            <p>
              You cannot convert this invoice into a credit note as you have
              already created packages or credit notes for the underlying items.
              Please check them or delete them to create a new credit note.
            </p>
          )}
        </span>
        <div className="create-credit-note-invoice-page-btn-group">
          <SaveButton
            type="button"
            onClick={
              props.isCreationPossible ? handleDispatch : props.handleClose
            }
            buttonContent={props.isCreationPossible ? "Yes proceed" : "Ok"}
            isDisabled={false}
          />
          {props.isCreationPossible && (
            <SecondaryButton
              type="button"
              onClick={props.handleClose}
              buttonContent="Cancel"
              isDisabled={false}
            />
          )}
        </div>
      </div>
    </>
  );
};
