import { useAppDispatch } from "../../app/hooks";
import {
  setListDateSortOrder,
  setListEndCount,
  setListItemOffset,
  setListItemsPerPage,
  setListNameSortOrder,
  setListPage,
  setListPageCount,
  setListRoleSortOrder,
  setListStartCount,
  setTotalListCount,
} from "../../appSlice";

export const usePaginationDispatch = () => {
  const dispatch = useAppDispatch();

  const setPage = (param: number) => {
    dispatch(setListPage(param));
  };
  const setItemsPerPage = (param: number) => {
    dispatch(setListItemsPerPage(param));
  };
  const setStartCount = (param: number) => {
    dispatch(setListStartCount(param));
  };
  const setEndCount = (param: number) => {
    dispatch(setListEndCount(param));
  };
  const setPageCount = (param: number) => {
    dispatch(setListPageCount(param));
  };
  const setItemOffset = (param: number) => {
    dispatch(setListItemOffset(param));
  };
  const setDateSortOrder = (param: string) => {
    dispatch(setListDateSortOrder(param));
  };
  const setNameSortOrder = (param: string) => {
    dispatch(setListNameSortOrder(param));
  };
  const setRoleSortOrder = (param: string) => {
    dispatch(setListRoleSortOrder(param));
  };
  const  setTotalList = (param: number) => {
    dispatch(setTotalListCount(param));
  }

  return {
    setPage,
    setItemsPerPage,
    setStartCount,
    setEndCount,
    setPageCount,
    setItemOffset,
    setDateSortOrder,
    setNameSortOrder,
    setRoleSortOrder,
    setTotalList
  };
};
