import { useNavigate, useParams } from "react-router-dom";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { JournalItemValues, JournalValues } from "../../../../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import { createCoupon, editCoupon } from "../../couponSlice";
import { payloadRefiner } from "../helpers/couponPayloadRefiner";
import { CouponDefaultValue, CouponformDetailValue, CouponformValue } from "../../couponType";
export const useCouponFormSubmission = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const navigate = useNavigate();
  const { editId } = useParams();
  /**
   * Create new journal voucher
   */
  const createCouponSubmit = async (
    couponPayload: CouponDefaultValue,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    const createResponseAction = await dispatch(
        createCoupon({
        values: payloadRefiner(couponPayload, action),
        orgId: currentUserInfo.organization_id,
      })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      dispatch(setLoaderState(false));
      navigate(`/coupons/detail/${createResponse.id}`);
      SuccessToaster(
        "Coupon created successfully!",
        "coupon-create-success"
      );
    } else if ("error" in createResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const editCouponSubmit = async (
    couponPayload: CouponDefaultValue,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    const  updateResponseAction = await dispatch(
      editCoupon({
        couponId: editId,
        values: payloadRefiner(couponPayload, action),
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateResponse = updateResponseAction.payload;
    if (Object.keys(updateResponse).length && !("error" in updateResponse)) {
      dispatch(setLoaderState(false));
      navigate(`/coupons/detail/${updateResponse.id}`);
      SuccessToaster(
        "Coupon updated successfully!",
        "Coupon-update-success"
      );
    } else if ("error" in updateResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { createCouponSubmit, editCouponSubmit };
};
