export const EXEMPTION_TYPES = [
    { key: "Non-Taxable", value: "NON_TAXABLE" },
    { key: "Out of Scope", value: "OUT_OF_SCOPE" },
    { key: "Non-GST Supply", value: "NON_GST_SUPPLY" },
    { key: "Taxable", value: "TAXABLE" },
  ];
export const GST_ERROR_MSG = {
    overseas: "Only Zero Tax can be applied to overseas invoices.",
    exemptionReason: "Please select exemption reason",
}
export const TAX_CONFIGURATION = "Tax Configuration";
export const NON_TAXABLE = "NON_TAXABLE";
export const TAXABLE = "TAXABLE"
export const CUSTOMER_NON_TAXABLE = "Customer is non-taxable";