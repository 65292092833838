// apolloClient.js
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { Api } from "../../../../constants";
import { store } from "../../../../app/store";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  const token = store.getState().app.token || sessionStorage.getItem("token");

  return {
    headers: {
      ...headers,
      auth: token ? `${token}` : "",
    },
  };
});

const customUriLink = new ApolloLink((operation, forward) => {
  const { pageNo, pageSize } = operation.variables;
  let uri = Api.search;

  if (pageNo !== undefined && pageSize !== undefined) {
    uri += `?page_num=${pageNo}&page_size=${pageSize}`;
  }

  operation.setContext({
    uri,
  });

  return forward(operation);
});
const token =
  store.getState().app.token || (sessionStorage.getItem("token") as string);

const httpLink = new HttpLink({
  uri: Api.search,
  headers: {
    auth: token,
  },
  fetch: (uri, options: RequestInit & { signal?: AbortSignal }) => {
    return fetch(uri, options);
  },
}); // Default URI

const client = new ApolloClient({
  link: ApolloLink.from([authLink, customUriLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  
});

export default client;
