import { useEffect, useRef, useState } from "react";
import { OrgDefault } from "../../../assets/images";
import "../styles/ImageUpload.css";
import { useAppSelector } from "../../../app/hooks";
import { currentUserSelector } from "../commonSlice";
import NotificationModal from "./NotificationModal";
import { Link } from "react-router-dom";
import { uploadFileValidator } from "../../../helpers/fileValidationHelper";
import { ImageCropper } from "./ImageCropper";
type Props = {
  logoUrl: string;
  uploadLogoHandler: (logoUrlLink: any) => void;
  removeLogoHandler: () => void;
};
const ImageUpload = (props: Props) => {
  const imageInputRef = useRef<any>(null);
  const [imageFile, setImageFile] = useState("");
  const [isInvalidFormat, setIsInvalidFormat] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const currentUserInfo = useAppSelector(currentUserSelector);
  const isValidImage = useRef(false);

  useEffect(() => {
    setImageFile("");
  }, [currentUserInfo.organization_id]);

  const imageSelectHandler = async (e: any) => {
    const file = e.target.files[0];
    isValidImage.current = await uploadFileValidator(file, [
      "png",
      "jpg",
      "jpeg",
    ]);
    const supportedFormats = ["image/jpg", "image/png", "image/jpeg"];
    if (file && file.type) {
      if (!isValidImage.current) {
        setIsInvalidFormat(true);
        $("#notificationPopupModal").modal("show");
        imageInputRef.current.value = "";
      } else if (file.size > 5242880) {
        setIsInvalidFormat(false);
        $("#notificationPopupModal").modal("show");
        imageInputRef.current.value = "";
      } else {
        setImageFile(URL.createObjectURL(e.target.files[0]));
        setFileName(file.name);
        // props.uploadLogoHandler(file);
        setOpen(true);
      }
    }
  };

  const removeLogoHandler = async () => {
    imageInputRef.current.value = "";
    setImageFile("");
    props.removeLogoHandler();
    setOpen(false);
  };
  const getCroppedImage = (file: File) => {
    setImageFile(URL.createObjectURL(file));
    props.uploadLogoHandler(file);
    setOpen(false);
  };
  return (
    <>
      <div
        className={`img-upload-wrapper ${
          imageFile || props.logoUrl ? "edit-wrapper" : ""
        }`}
      >
        {" "}
        <div className="preview-block">
          <div className="img-container">
            <img
              src={
                imageFile || props.logoUrl
                  ? imageFile && !isOpen
                    ? imageFile
                    : props.logoUrl
                    ? props.logoUrl
                    : OrgDefault
                  : OrgDefault
              }
              width={40}
              loading="lazy"
              height={50}
              alt="organization_logo"
            />
          </div>
          {imageFile || props.logoUrl ? (
            <Link
              to="#"
              role="button"
              className="remove-image"
              onClick={(e) => {
                e.preventDefault();
                removeLogoHandler();
              }}
            >
              Remove logo
            </Link>
          ) : null}
        </div>
        <div className="upload-block">
          <input
            type="file"
            name="file-upload"
            id="file-upload"
            accept="image/jpg, image/jpeg, image/png"
            placeholder="Upload logo"
            tabIndex={-1}
            onChange={imageSelectHandler}
            ref={imageInputRef}
          />
          {!imageFile && !props.logoUrl ? (
            <button
              type="button"
              className="upload-file-btn green-button"
              onClick={() => {
                document.getElementById("file-upload")?.click();
              }}
            >
              Upload Logo
            </button>
          ) : null}

          <p>
            <span className="boldText">
              This logo will appear on all transactions, PDFs and email
              notifications.
            </span>
            {!imageFile && !props.logoUrl ? (
              <span className="semiBoldText">
                Max Size: 5MB (500px X 500 px). Format: JPG, PNG, JPEG
              </span>
            ) : null}
          </p>
        </div>
      </div>
      <NotificationModal
        modalHeader={
          isInvalidFormat ? "Invalid Image format!" : "File size exceeded!"
        }
        modalBody={
          isInvalidFormat
            ? "Please use .jpg, .jpeg, or .png formats for the logo."
            : "Maximum file size allowed is 5MB, please try with different file."
        }
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
      <ImageCropper
        isOpen={isOpen}
        image={imageFile}
        getCroppedImage={getCroppedImage}
        fileName={fileName}
        onCancelUpload={removeLogoHandler}
      />
    </>
  );
};

export default ImageUpload;
