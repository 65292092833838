import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { currentUserSelector } from "../common/commonSlice";

export const useSubModulePermission = () => {
  const [subModulePermission, setSubModulePermission] = useState<any[]>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  let subModules: any[] = [];
  useEffect(() => {
    const organizationRole = JSON.parse(
      sessionStorage.getItem("organizationRole") || "{}"
    );
    subModules = organizationRole.length
      ? organizationRole.map((permission: any) => {
          return permission.subModule;
        })
      : [];
    let unique = Array.from(new Set(subModules));
    setSubModulePermission(unique);
  }, [
    currentUserInfo.organization_id,
    sessionStorage.getItem("organizationRole"),
  ]);
  return subModulePermission;
};
