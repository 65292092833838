import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../app/hooks";
import { DateFormatHandler } from "../../../../../../helpers/helper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  DebitNoteListValues,
  ObjectType,
  PaymentMadeListValues,
} from "../../../../../../types";
import { orgCurrencyListSelector } from "../../../../../common/commonSlice";
import { EditIcon } from "../../../../../common/components/customSvgIcons/editIcon";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { DeleteAllocationModal } from "../DeleteAllocation/deleteAllocationModal";

type Props = {
  DNList: DebitNoteListValues[] | undefined;
  PMList: PaymentMadeListValues[] | undefined;
  dateSortOrder: string;
  sortHandler: (column: string) => void;
  module: string;
  billId: number | null;
  isParent: boolean;
  key: string;
  getRerenderFlag: (flag: number) => void;
};
export const DebitNoteAndPaymentMadeTable = ({
  DNList,
  PMList,
  dateSortOrder,
  sortHandler,
  module,
  billId,
  isParent,
  key,
  getRerenderFlag,
}: Props) => {
  const DNDetailRedirection = "/debit-note/";
  const PMDetailRedirection = "/payment-made/";
  const billBackRedirection = "/bill/detail/" + billId;
  const billsId = useParams().editBillId;
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const navigate = useNavigate();
  const handleEditRedirection = (id: number | null) => {
    navigate(
      module === "DN"
        ? DNDetailRedirection + "edit/" + id
        : PMDetailRedirection + "edit/" + id,
      {
        state: {
          redirectTo: billBackRedirection,
        },
      }
    );
  };

  /**
   * set table column headings for PM
   */
  const PMcolumns = useMemo(
    () => [
      {
        Header: "PAYMENT DATE",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "PAYMENT NUMBER",
        accessor: "show.payment_number",
        sort: false,
        sortOrder: "",
        className: "payment_number",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={`/payment-made/detail/${value.id}`}
              className="transaction-number-cn-pr"
            >
              {value.transactionNumber}
            </Link>
          );
        },
      },
      {
        Header: "PAYMENT MODE",
        accessor: "show.payment_mode",
        sort: false,
        sortOrder: "",
        className: "payment_mode",
      },
      {
        Header: "REFERENCE NUMBER",
        accessor: "show.reference_number",
        sort: false,
        sortOrder: "",
        className: "reference_number",
      },
      {
        Header: "ALLOCATION DATE",
        accessor: "show.allocation_date",
        sort: false,
        sortOrder: "",
        className: "allocation_date",
      },
      {
        Header: "AMOUNT",
        accessor: "show.amount_paid",
        sort: false,
        sortOrder: "",
        className: "amount_paid",
      },
      {
        Header: "",
        accessor: "show.action",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !isParent ? (
            <span className="dn-pm-action-btn">
              {!value.isDisabled && !value.is_fiscal_closed ? (
                <button
                  className="no-appearance"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditRedirection(value.id);
                  }}
                >
                  <EditIcon color="blue" />
                </button>
              ) : (
                <></>
              )}
              {!value.is_fiscal_closed && (
                <DeleteAllocationModal
                  id={value.id}
                  billId={Number(billsId)}
                  itemId={value.itemId}
                  module={value.module}
                  transactionNumber={value.transactionNumber}
                  getRerenderFlag={getRerenderFlag}
                />
              )}
            </span>
          ) : (
            <></>
          );
        },
      },
    ],
    []
  );
  /**
   * set table column headings for DN
   */
  const DNcolumns = useMemo(
    () => [
      {
        Header: "DEBIT NOTE DATE",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "DEBIT NOTE NUMBER",
        accessor: "show.debit_note_no",
        sort: false,
        sortOrder: "",
        className: "debit_note_no",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={`/debit-note/detail/${value.id}`}
              className="transaction-number-cn-pr"
            >
              {value.transactionNumber}
            </Link>
          );
        },
      },
      {
        Header: "AMOUNT CREDITED",
        accessor: "show.debits_applied",
        sort: false,
        sortOrder: "",
        className: "debits_applied",
      },
      {
        Header: "",
        accessor: "show.action",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !isParent ? (
            <span className="dn-pm-action-btn">
              {!value.isDisabled ? (
                <button
                  className="no-appearance"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditRedirection(value.id);
                  }}
                >
                  <EditIcon color="blue" />
                </button>
              ) : (
                <></>
              )}
              <DeleteAllocationModal
                id={value.id}
                billId={Number(billsId)}
                itemId={value.itemId}
                module={value.module}
                transactionNumber={value.transactionNumber}
                getRerenderFlag={getRerenderFlag}
              />
            </span>
          ) : (
            <></>
          );
        },
      },
    ],
    []
  );
  const DNData = DNList
    ? DNList.map((item) => {
        const billNos = item.bill_numbers?.filter(
          (billItem) => Number(billsId) === Number(billItem.id)
        );
        const cols = billNos.map((bill: ObjectType) => ({
          show: {
            date: DateFormatHandler(item.debit_note_date),
            debit_note_no: {
              transactionNumber: joinTransactionNumber(item.debit_note_no),
              id: item.id,
            },
            reference_number: item.reference_number,
            debits_applied:
              item.currency_code +
              " " +
              NumberFormat(
                Number(bill.debits_applied),
                item.currency_code,
                orgCurrencyList
              ),
            action: {
              id: item.id,
              isDisabled: true,
              transactionNumber: joinTransactionNumber(item.debit_note_no),
              module: "DN",
            },
          },
        }));
        return cols;
      }).flat()
    : [];
  const PMData = PMList
    ? PMList.map((item) => ({
        show: {
          date: DateFormatHandler(item.payment_date),
          payment_number: {
            transactionNumber: joinTransactionNumber(item.payment_number),
            id: item.id,
          },
          payment_mode: item.payment_mode,
          reference_number: item.reference_number || "-",
          allocation_date:
            DateFormatHandler(
              item.item_list.filter(
                (item: {
                  id: number | null;
                  bill_id: number | null;
                  allocation_date?: string;
                }) => Number(item.bill_id) === Number(billsId)
              )[0]?.allocation_date || ""
            ) || "",
          amount_paid:
            item.currency_code +
            " " +
            NumberFormat(
              Number(item.allocated_amount || 0),
              item.currency_code,
              orgCurrencyList
            ),
          action: {
            id: item.id,
            isDisabled: false,
            transactionNumber: joinTransactionNumber(item.payment_number),
            itemId: item.item_list.filter(
              (item: { id: number | null; bill_id: number | null }) =>
                Number(item.bill_id) === Number(billsId)
            )[0]?.id,
            module: "PM",
            is_fiscal_closed: item.is_fiscal_closed,
          },
        },
      }))
    : [];

  return (
    <TableContainer
      columns={module === "DN" ? DNcolumns : PMcolumns}
      data={module === "DN" ? DNData : PMData}
      id="dn-pm-list-table"
      className="list-table table table-striped w-100"
      url={""}
      sortHandler={sortHandler}
      editPermission={true}
      isDetailViewExist={false}
      isNavigate={false}
      hiddenColumns={["Actions"]}
      key={key}
    >
      <div className="no-allocation">No allocations found!</div>
    </TableContainer>
  );
};
