import React, { useEffect, useState } from "react";
import Popper from '@mui/material/Popper';
import TableContainer from "../table/TableContainer";
import { TableData } from "../../../../types";
import { priceItemHiddenColumnValues } from "../../../../helpers/tableCustomizationHelper";
type Props = {
  id: any;
  anchorEl: any;
  open: any;
  columns: any[];
  data: TableData[];
  savePopperHeight: (Popper: any) => void
  poperHeight: number;
  isRange: boolean;
};

const ListCellTablePopover: React.FC<Props> = (props: Props) => {
  let width = window.innerWidth;
  const responsivePricingTable = () => {
    let reponsiveIndexsClassName:any[] = []
   document.querySelectorAll(".pricing-responsive").forEach((item) => {
    let filteredIndex = item?.className?.match(/\d+/g)
    if (item?.textContent) {
      let length = item?.textContent?.length
      let height =  24
       if ( width <= 820 ) {
        height = (Math.round(length/12) + (length%12 < 6 ? 1 : 0)) * 24
       } 
       else {
        height = (Math.round(length/16) + (length%16 < 8 ? 1 : 0)) * 24
       }
      filteredIndex?.push((`${height}`))
    }
    if (filteredIndex) reponsiveIndexsClassName.push(filteredIndex)
    })
    const updatedHeight = width <= 820 ? 80 : 120
    reponsiveIndexsClassName.forEach((data) =>{ 
    $(`.popover_price .each-pricing-grid .parent-Index-${data[0]}-${data[1]}`).css({ height: "" + data[2] +"px", width: "" + updatedHeight + "px"})
    $(`.price-Units-col .each-pricing-grid .parent-Index-${data[0]}-${data[1]}`).css({ height: "" + data[2] +"px", width: "" + updatedHeight + "px"})
    $(`.popover_price .each-pricing-grid .price-parent-Index-${data[0]}${data[1]}`).css({ height: "" + data[2] +"px"})
    $(`.price-Units-col .each-pricing-grid .to-parent-Index-${data[0]}${data[1]}`).css({ height: "" + data[2] +"px"})
    $(`.price-Units-col .each-pricing-grid .from-parent-Index-${data[0]}${data[1]}`).css({ height: "" + data[2] +"px"})
  })
    
  };
  $(() => {
    responsivePricingTable();
  });
  return (
    <Popper id={props.id}
       ref={(Popper) => props.savePopperHeight(Popper)}
       open={props.open}
       anchorEl={props.anchorEl}
       placement='top-end'
       className={`pricing-item-col-popper ${props.isRange ? "range_popover": 'no_range_popover'}`}
       >
    <div className="card-body">
    <div
      id="invoice-table-wrapper"
      className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data pricing-table"
    >
      <div className="row">
        <div className="col-md-12"></div>
        <TableContainer
        className={`list-table table table-striped w-100 ${props.poperHeight >= 332 ? 'is-scroll-bar' : '' }`}
        columns={props.columns}
        data={props.data}
        url={"/plan"}
        sortHandler={() => {}}
        editPermission={["Update"].includes("Update")}
        isDetailViewExist={true}
        isNotResponsive
        hiddenColumns={priceItemHiddenColumnValues}
      >
       <div>No item</div>
        </TableContainer>
        </div>
        </div>
        </div>
      </Popper>
  );
};

export default ListCellTablePopover;