import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMigrationDate, updateMigrationDate } from "./migrationAPI";

export const fetchMigrationDate = createAsyncThunk(
  "settings/migration-date",
  async (data: { orgId: number }) => {
    return await getMigrationDate(data);
  }
);

export const saveMigrationDate = createAsyncThunk(
  "settings/save-migration-date",
  async (data: { orgId: number; migration_date: string }) => {
    return await updateMigrationDate(data);
  }
);

const initialState = {};

export const migrationSlice = createSlice({
  name: "migrationdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default migrationSlice.reducer;
