import * as React from "react";
import KebabMenu from "../kebabMenu/KebabMenu";
import Edit from "../kebabMenu/Edit";
import Delete from "../Delete";
import SuspendUser from "../kebabMenu/SuspendUser";
import ResendUserInvite from "../kebabMenu/ResendUserInvite";
import CancelUserInvite from "../kebabMenu/CancelUserInvite";
import UserFormContainer from "../../../home/userManagement/components/users/userForm/UserFormContainer";
import CloneRole from "../kebabMenu/CloneRole";

type Props = {
  id: number;
  name: string;
  isEdit: boolean;
  isDelete: boolean;
  isMutable: boolean;
  isLocked: boolean;
  lockDate?: string;
  allowDelete: boolean;
  roles: any;
  editUrl: string;
  cloneUrl?: string;
  deleteIconPresent: boolean;
  editOnClick: () => void;
  deleteOnClick: () => void;
  refreshList?: () => void;
  disabledEditClassName?: string;
  disabledClassName?: string;
  isSuspended?: boolean;
  isSuperUser?: boolean;
  assignedUserCount?: number;
};
function ListActions(props: Props) {
  return (
    <KebabMenu>
      <>
        {props.isEdit && props.roles.includes("Update") && (
          <li
            className={props.disabledEditClassName}
            style={
              props.disabledEditClassName?.length === 0
                ? {}
                : { pointerEvents: "none" }
            }
          >
            {props.name === "User" ? (
              <UserFormContainer
                id={props.id}
                isEdit={true}
                editUrl={props.editUrl}
                isMutable={props.isMutable}
                name={props.name}
                isLocked={props.isLocked}
                className={"dropdown-item"}
                isSuperUser={props.isSuperUser ? props.isSuperUser : false}
                isTransactionLimitExceed={false}
                refreshList={props.refreshList ? props.refreshList : () => {}}
              />
            ) : (
              <Edit
                url={props.editUrl}
                isMutable={props.isMutable}
                name={props.name}
                isLocked={props.isLocked}
                className={"dropdown-item"}
                lockDate=""
              />
            )}
          </li>
        )}
        {props.name === "Role" && (
          <li>
            <CloneRole url={props.cloneUrl ? props.cloneUrl : ""} />
          </li>
        )}
        {props.name === "User" && props.isEdit && (
          <li className={props.disabledClassName}>
            <SuspendUser
              className={`dropdown-item ${
                !props.isSuspended ? "suspend-user" : "revoke-suspended-user"
              }`}
              isSuspended={props.isSuspended}
              userId={props.id}
              refreshList={props.refreshList ? props.refreshList : () => {}}
            />
          </li>
        )}
        {props.isDelete && props.roles.includes("Delete") ? (
          <li className={props.disabledClassName}>
            <Delete
              className="dropdown-item delete"
              deleteIcon={props.deleteIconPresent}
              isMutable={props.allowDelete}
              onClick={props.deleteOnClick}
              isLocked={props.isLocked}
              lockDate={props.lockDate ? props.lockDate : ""}
              name={props.name}
              assignedUserCount={props.assignedUserCount}
            />
          </li>
        ) : null}
        {props.name === "User" &&
          !props.isEdit &&
          props.roles.includes("Create") && (
            <li>
              <ResendUserInvite userId={props.id} />
            </li>
          )}
        {props.name === "User" &&
          !props.isEdit &&
          props.roles.includes("Delete") && (
            <li>
              <CancelUserInvite
                userId={props.id}
                refreshList={props.refreshList ? props.refreshList : () => {}}
              />
            </li>
          )}
      </>
    </KebabMenu>
  );
}

export default React.memo(ListActions);
