import { FormikTouched } from "formik";
import { ObjectType } from "../types";

export const formikTouchedFieldSetter = (
  values: ObjectType,
  setTouched: (touched: FormikTouched<any>) => void
) => {
  // Helper function to mark nested fields as touched
  const markTouched = (obj: any): FormikTouched<any> => {
    if (typeof obj === "object" && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (Array.isArray(value)) {
          acc[key] = value.map((item) =>
            typeof item === "object" && item !== null ? markTouched(item) : true
          ) as any; // Handle arrays of touched objects
        } else if (typeof value === "object" && value !== null) {
          acc[key] = markTouched(value) as any;
        } else {
          acc[key] = true;
        }
        return acc;
      }, {} as FormikTouched<any>);
    }
    return {} as FormikTouched<any>; // Return an empty object for non-object values
  };

  // Generate the touched object
  const touchedFields = markTouched(values);

  // Set touched fields directly without using a function
  setTouched({
    ...touchedFields,
  });
};
