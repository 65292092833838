import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import {
  CreateUserRoleValues,
  createUserValues,
  TagValue,
  UpdateUserValues,
} from "../../../types";

/**
 * API call for creating new user in User management
 */
export async function userCreation(data: {
  values: createUserValues;
  orgId: number;
}) {
  const result = await post(
    Api.userManagement + `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for getting user roles
 */
export async function getUserRoles(data: { roleSort: string; orgId: number }) {
  let result;
  if (data.roleSort === "") {
    result = await get(
      Api.userManagement +
        "/" +
        Api.userRoles +
        `/list?current_organization=${data.orgId}`
    );
  } else {
    result = await get(
      Api.userManagement +
        "/" +
        Api.userRoles +
        `/list?current_organization=${data.orgId}&role_order=${data.roleSort}`
    );
  }

  return result;
}

/**
 * API call for editing tag
 */
export async function editTag(data: {
  id: number;
  newTagInput: TagValue;
  orgId: number;
}) {
  const result = await put(
    Api.tags + "/edit" + `/${data.id}?current_organization=${data.orgId}`,
    data.newTagInput
  );
  return result;
}

/**
 * API call for getting all Users.
 */
export async function getUsersList(data: {
  page: number;
  itemsPerPage: number;
  roleId: number;
  userSort: string;
  orgId: number;
}) {
  const result = await get(
    Api.userManagement +
      "/list" +
      `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}&role_id=${data.roleId}&user_sort=${data.userSort}`
  );
  return result;
}

/**
 * API call for new user verification
 */

export async function newUserVerification(data: { token: string }) {
  const result = await get(
    Api.userManagement + "/new-user-verification" + `?token=${data.token}`
  );
  return result;
}

/**
 * API call for resend invitation mail
 */

export async function resendInvitation(data: { id: number; orgId: number }) {
  const result = await post(
    Api.userManagement +
      "/resend-invitation" +
      `?current_organization=${data.orgId}&user_id=${data.id}`
  );
  return result;
}

/**
 * API call for Suspend User
 */

export async function suspendUser(data: { id: number; orgId: number }) {
  const result = await put(
    Api.userManagement +
      "/suspend" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for revoke suspended user
 */

export async function revokeSuspendedUser(data: { id: number; orgId: number }) {
  const result = await put(
    Api.userManagement +
      "/revoke-suspend" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting user
 */

export async function deleteUser(data: { id: number; orgId: number }) {
  const result = await remove(
    Api.userManagement +
      "/delete" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for update User
 */

export async function updateUser(data: {
  id: number;
  values: UpdateUserValues;
  orgId: number;
}) {
  const result = await put(
    Api.userManagement +
      "/update" +
      `/${data.id}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for creating Role
 */
export async function createRole(data: {
  values: CreateUserRoleValues;
  orgId: number;
}) {
  const result = await post(
    Api.utils + "/" + Api.roles + `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for cloning Role
 */
export async function cloneRole(data: {
  values: CreateUserRoleValues;
  orgId: number;
}) {
  const result = await post(
    Api.utils + "/" + Api.roles + `/clone?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call update Role
 */
export async function updateRole(data: {
  id: number;
  values: CreateUserRoleValues;
  orgId: number;
}) {
  const result = await put(
    Api.utils +
      "/" +
      Api.roles +
      "/update" +
      `/${data.id}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for deleting role
 */

export async function deleteRole(data: { id: number; orgId: number }) {
  const result = await remove(
    Api.utils +
      "/" +
      Api.roles +
      "/delete" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting organizations
 */
export async function getOrganizationDropdown(orgId: number) {
  const result = await get(
    Api.userManagement +
      `/organization-role/get-dropdown?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting roles
 */
export async function getRolesDropdown(data: { id: number; orgId: number }) {
  const result = await get(
    Api.userManagement +
      `/user-roles/dropdown-list?current_organization=${data.orgId}&organization_id=${data.id}`
  );
  return result;
}

/**
 * API call for getting Permission section
 */
export async function permissionSection(orgId: number) {
  const result = await get(
    Api.utils + `/get-permission-section?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to get the user limit status
 */
export async function userLimitStatus(orgId: number) {
  const result = await get(
    Api.userManagement +
      `/transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to get the role limit status
 */
export async function roleLimitStatus(orgId: number) {
  const result = await get(
    Api.utils +
      "/" +
      Api.roles +
      `/transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to update refresh token status
 */
export async function refreshToken(data: {
  userId: number;
  tokenStatus: boolean;
  orgId: number;
}) {
  const result = await put(
    Api.userManagement +
      `/refresh-token/${data.userId}?is_token_refreshed=${data.tokenStatus}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to get user's invited Organizations
 */
export async function getOrganizationList(orgId: number) {
  const result = await get(Api.userManagement + `/get-organizations`);
  return result;
}

/**
 * API to check if email is used
 */
export async function isEmailUsed(data: {
  orgId: number;
  emailToCheck: string;
  signal: AbortSignal;
  userId: number;
}) {
  const result = await get(
    Api.userManagement + `/check-email-exist/${data.emailToCheck}?user_id=${data.userId}`,
    data.signal
  );
  return result;
}
