import { FC, useEffect, useState } from "react";
import { colors } from "../../../../constants/colors";
import Modal from "@mui/material/Modal";

interface CloseFiscalYearProps {
  handleClose: () => void;
  handleSubmit: () => void;
}

const CloseFiscalYear: FC<CloseFiscalYearProps> = ({
  handleClose,
  handleSubmit,
}) => {
  const [nextDay, setNextDay] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const day = nextDate.getDate();
    const month = nextDate.toLocaleString("default", { month: "long" });
    const year = nextDate.getFullYear();

    setNextDay(`${day} ${month} ${year}`);
  }, []);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="alert-modal"
      id="deleteSubModal"
    >
      <div className="modal-dialog modal-dialog-centered delete-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header position-relative">
              <button
                type="button"
                className="close-btn pull-right position-absolute"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      fill={colors.slate}
                      fillRule="nonzero"
                      d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    />
                  </g>
                </svg>
              </button>
              <h5 className="modal-title" id="delete_title">
                Close Fiscal Years
              </h5>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to continue with the Closing Process?{" "}
              </p>
              <p>
                Closing for the fiscal year will start at 00:00, {nextDay}. Any
                transactions until the closing starts will be taken into
                account. Manual locking of transactions is possible to avoid
                this.
              </p>
            </div>
            <div className="modal-footer" id="organization-delete-button">
              <button
                type="button"
                className="btn btn-cancel btn-green"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-ok btn-red"
                onClick={handleSubmit}
              >
                Yes Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CloseFiscalYear;
