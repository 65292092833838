import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { getFormatedDate } from "../../../../../../helpers/helper";
import { getFiscalYear } from "../../../../../../helpers/fiscalYearHelper";
import { getCustomConversions } from "../../../../../common/commonSlice";
import { refundFormValidation } from "../resources/refundFormValidation";
import { getLatestCurrencyConversion } from "../../../../../utils/exchangeRateUtils";
import {
  createRefund,
  getRefundDetails,
  updateRefund,
} from "../../../paymentReceivedSlice";
import { SuccessToaster } from "../../../../../../helpers/toastHelper";
import { CurrencyFormType, CurrentUserValues } from "../../../../../../types";
import messages from "../resources/messages";
type Props = {
  handleModalClose: () => void;
  paymentReceivedData: any;
  editId: number;
  refreshList: () => void;
};

const usePrRefundForm = (
  props: Props,
  dispatch: any,
  currentUserInfo: CurrentUserValues,
  orgCurrencyList: CurrencyFormType[]
) => {
  const fiscal_year_date = useMemo(() => {
    return getFiscalYear(
      currentUserInfo.organization_fiscal_year || "April-March",
      "current"
    );
  }, [currentUserInfo.organization_fiscal_year]);

  const [initialFormValues, setInitialFormValues] = useState({
    refunded_on: getFormatedDate(new Date()),
    payment_mode: "",
    check_date: getFormatedDate(new Date()),
    check_expiry_in: "",
    check_expiry: "",
    check_number: "",
    reference_number: "",
    from_account: "",
    from_account_name: "",
    amount: "",
    description: null,
    conversion_id: 0,
    conversion_rate: "0",
    conversion_timestamp: "",
    by_user: false,
    bank_amount: "",
    bank_currency_id: 0,
    bank_currency_code: "",
    bank_conversion_id: 0,
    bank_conversion_rate: "0",
    bank_conversion_timestamp: "",
    bank_by_user: false,
    bank_charges_account_id: 0,
    bank_charge_account_name: "",
    bank_charges_amount: 0,
    forex_amount: "",
    excess_amount: props.paymentReceivedData.amount_excess,
    payment_date: props.paymentReceivedData.payment_date,
    fiscal_year_end: fiscal_year_date.endDate,
    fiscal_year_start: fiscal_year_date.startDate,
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: refundFormValidation({
      initialFormValues: initialFormValues,
      editId: props.editId,
    }),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values: any) => {
      await saveRefund();
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  useEffect(() => {
    props.editId ? updateInit() : createInit();
  }, [currentUserInfo.organization_id]);

  const createInit = async () => {
    let currencyConversionData = await getLatestCurrencyConversion(
      dispatch,
      props.paymentReceivedData.currency_id,
      currentUserInfo.organization_currency,
      getFormatedDate()
    );
    setConversionData(currencyConversionData);
  };

  const updateInit = async () => {
    let responseAction = await dispatch(
      getRefundDetails({
        refundId: props.editId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (
      Object.keys(responseAction.payload).length &&
      "error" in responseAction.payload
    ) {
      return;
    }
    let refundDetails = responseAction.payload;

    let conversionResponse = await dispatch(
      getCustomConversions({
        conversionId: refundDetails.conversion_id,
      })
    );
    if (
      Object.keys(conversionResponse.payload).length &&
      "error" in conversionResponse.payload
    ) {
      return;
    }

    let conversionData = conversionResponse.payload.latest_conversion;

    let bankConversionData: any;
    if (refundDetails.bank_conversion_id) {
      let bankConversionResponse = await dispatch(
        getCustomConversions({
          conversionId: refundDetails.bank_conversion_id,
        })
      );
      if (
        Object.keys(bankConversionResponse.payload).length &&
        "error" in bankConversionResponse.payload
      ) {
        return;
      }

      bankConversionData = bankConversionResponse.payload.latest_conversion;
    }

    setInitialFormValues((PrevValues) => {
      return {
        ...PrevValues,
        refunded_on: refundDetails.refunded_on,
        payment_mode: refundDetails.payment_mode,
        check_date: refundDetails.check_date
          ? refundDetails.check_date
          : getFormatedDate(new Date()),
        check_expiry_in: refundDetails.check_expiry_in
          ? refundDetails.check_expiry_in
          : "",
        check_expiry: refundDetails.check_expiry
          ? refundDetails.check_expiry
          : "",
        check_number: refundDetails.check_number,
        reference_number: refundDetails.reference_number,
        from_account: refundDetails.from_account,
        from_account_name: refundDetails.from_account_name,
        amount: refundDetails.amount,
        description: refundDetails.description,
        conversion_id: refundDetails.conversion_id,
        conversion_rate: conversionData.rate,
        conversion_timestamp: conversionData.timestamp,
        by_user: conversionData.by_user,
        bank_amount: refundDetails.refund_amount_bank_currency,
        bank_currency_id: refundDetails.bank_currency_id,
        ...(refundDetails.bank_conversion_id
          ? {
              bank_currency_code: bankConversionData.to_currency,
              bank_conversion_id: refundDetails.bank_conversion_id,
              bank_conversion_rate: bankConversionData.rate,
              bank_conversion_timestamp: bankConversionData.timestamp,
              bank_by_user: bankConversionData.by_user,
            }
          : {
              bank_currency_code: "",
              bank_conversion_id: 0,
              bank_conversion_rate: "0",
              bank_conversion_timestamp: "",
              bank_by_user: false,
            }),
        bank_charges_account_id: refundDetails.bank_charges_account_id,
        bank_charges_amount: refundDetails.bank_charges_amount,
      };
    });
  };

  const saveRefund = async () => {
    let payload = {
      pr_id: props.paymentReceivedData.id,
      refund_number: "",
      amount: formik.values.amount,
      conversion_id: formik.values.conversion_id,
      refunded_on: formik.values.refunded_on,
      payment_mode: formik.values.payment_mode,
      check_date:
        formik.values.payment_mode === "Check"
          ? formik.values.check_date
          : null,
      check_expiry_in:
        formik.values.payment_mode === "Check"
          ? formik.values.check_expiry_in
          : null,
      check_expiry:
        formik.values.payment_mode === "Check"
          ? formik.values.check_expiry
          : null,
      check_number:
        formik.values.payment_mode === "Check"
          ? formik.values.check_number
          : "",
      reference_number: formik.values.reference_number,
      from_account: formik.values.from_account,
      bank_currency_id: formik.values.bank_currency_id,
      bank_conversion_id: formik.values.bank_conversion_id,
      bank_charges_amount: formik.values.bank_charges_amount,
      bank_charges_account_id: formik.values.bank_charges_account_id,
      description: formik.values.description,
    };
    let response;
    if (props.editId) {
      response = await dispatch(
        updateRefund({
          refundId: props.editId,
          orgId: currentUserInfo.organization_id,
          values: payload,
        })
      );
    } else {
      response = await dispatch(
        createRefund({
          orgId: currentUserInfo.organization_id,
          values: payload,
        })
      );
    }

    if (Object.keys(response).length && !("error" in response)) {
      props.handleModalClose();
      SuccessToaster(
        `${
          props.editId
            ? messages.success.refundUpdate
            : messages.success.refundCreate
        }`,
        "refund-create-success"
      );
    }
    props.refreshList();
  };
  const setConversionData = (currencyConversionData: any) => {
    formik.setValues((values: any) => {
      return {
        ...values,
        conversion_id: currencyConversionData.id,
        conversion_rate: currencyConversionData.rate,
        conversion_timestamp: currencyConversionData.timestamp,
      };
    });
  };
  return {
    formik,
    initialFormValues,
    setInitialFormValues,
    fiscal_year_date,
  };
};

export default usePrRefundForm;
