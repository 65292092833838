import {
  PlanItemDetails,
  PlanDefaultValues,
  ObjectType,
} from "../../../../../types";

/**
 * Validate form values
 */
export const validate = (
  planFormValues: PlanDefaultValues,
  items: PlanItemDetails[],
  isInternalNameExist: boolean,
) => {
  const errors: ObjectType = {};
  if (!planFormValues.internal_name) {
    errors.internal_name = "Please enter Internal name";
  }
  if (items.length <= 0) {
    errors.planItems = "* Please add at least one item";
  }
  if (isInternalNameExist) {
    errors.internal_name =
      "Internal Name already exists. Please choose a different one";
  }
  return errors;
};
