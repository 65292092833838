import { useNavigate } from "react-router-dom";
import BackButton from "../../../../common/components/BackButton";
import { PreferencesIcon } from "../../../../../assets/images";
import "./transactionHeader.css";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { PreferencesModal } from "./preference/PreferencesModal";
import { DropdownStateType, RoundOffPreferences } from "../../recurringInvoiceTypes";

type Props = {
  transactionName: string;
  transactionNumber: number;
  handleSavePreference: (paymentMode: any) => void;
  handleSaveRoundOff: (paymentMode: any) => void;
  preferredInvoiceStatus: string;
  isRoundOffEnabled: string;
  roundoffData: RoundOffPreferences;
  onAccountChange: (val: DropdownStateType) => void;
  onIsTotalEditable: (event: any) => void;
  formErrors: any;
  resetAccountData: () => void;
};
const TransactionHeader = (props: Props) => {
  const navigate = useNavigate();
  const firstHeading =
    props.transactionName === "Customer" || props.transactionName === "Vendor"
      ? "Add"
      : props.transactionName === "Credit Note" ||
        props.transactionName === "Debit Note"
      ? "Add New"
      : props.transactionName === "Record Payment" ||
        props.transactionName === "Bill Payment"
      ? ""
      : "Create";


  return (
    <div>
      <div>
        <BackButton onClick={() => navigate(-1)} />
        <h1 className="fw-bold m-0">
          {props.transactionNumber
            ? `Edit ${props.transactionName}`
            : `${firstHeading} ${props.transactionName}`}
        </h1>
      </div>
      <div className="preferenceDropDownRI">
        <button
        type="button"
        className="btn create-invoice-button green-button"
        data-bs-toggle="dropdown"
      >
        <img src={PreferencesIcon} className="Group" alt="plus-small-img" />
          <span className="create-invoice-button-text green-button-text">
            &nbsp; Preferences
          </span>
      </button>
        <ul className="dropdown-menu dropdown-menu-start" id="kebabMenuDropDownRI">
          <Grid container spacing={2} sx={{ flexDirection: "column" }}>
            <Grid item>
              <Link
                className="dropdown-item"
                to=""
              >
                <PreferencesModal
                  selectedValue={props.preferredInvoiceStatus}
                  handleSave={(paymentMode: any) =>
                    props.handleSavePreference(paymentMode)
                  }
                  buttonName="Save Preference for Invoices"
                  optionsArray={[
                    { label: "Save as Draft", value: "Save as Draft" },
                    { label: "Save and Send", value: "Save and Send" },
                    // { label: "Save and Send with Payment Link", value: "Save and Send with Payment Link" },
                  ]}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link
                className="dropdown-item"
                to=""
              >
                <PreferencesModal
                  selectedValue={props.isRoundOffEnabled}
                  handleSave={(paymentMode: any) =>
                    props.handleSaveRoundOff(paymentMode)
                  }
                  buttonName="Rounding off Preference"
                  optionsArray={[
                    { label: "No rounding", value: "No rounding" },
                    {
                      label: "Round off the total to the nearest whole number",
                      value: "Round off",
                    },
                  ]}
                  roundoffData={props.roundoffData}
                  onAccountChange={props.onAccountChange}
                  onIsTotalEditable={(e) => props.onIsTotalEditable(e)}
                  formErrors={props.formErrors}
                  resetAccountData={props.resetAccountData}
                />
              </Link>
            </Grid>
          </Grid>
        </ul>
      </div>
    </div>
  );
};

export default TransactionHeader;