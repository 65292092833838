import { RolePermissionMappingValues } from "../types";

export const permissionHandler = (
  roles: any,
  permissionMapping: RolePermissionMappingValues
) => {
  let filteredRole = roles.roles
    .filter((roles: any) => !roles.includes("_") && !roles.includes("-"))
    .map((item: any) => {
      let value = item.split(".");
      return {
        orgId: value[0],
        module: permissionMapping.module_mapping[value[1]],
        subModule: permissionMapping.sub_module_mapping[value[2]],
        permission: permissionMapping.permission_mapping[value[3]],
      };
    });
    sessionStorage.setItem("rolePermissions", JSON.stringify(filteredRole));
};

export const getPermissionSubmodule = (items: any, moduleName: string) => {
  return items.find((item: any) => item.sub_module_name === moduleName)
} 

export const getSubmoduleEachPermission = (permission: any, id: number) => {
  return permission.find((recurringPerm: any) => recurringPerm.id === id)
}

export const isCancelStopResumeEnabled = (submodulePermissions: any, id: number) => {
  const permission = submodulePermissions.filter((items : any) => items.module_id === id).map((subModule: any) => !(subModule.permissions.length > 4))
  return permission.every(Boolean)
}