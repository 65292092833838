import * as yup from "yup";
export const ApplyCreditFormValidation = yup.object({
    transactions_to_credit: yup.array().of(
    yup.object().shape({
      allocation_date: yup
        .string()
        .required("Please enter a valid allocation date")
        .test(
          "allocation_date",
          "Please enter a valid allocation date",
          function (value) {
            if (new Date(value).toString() === "Invalid Date" || !value) {
              return false;
            }
            return true;
          }
        )
        .test(
          "allocation_date",
          "Allocation date cannot be in previous fiscal year.",
          function (value) {
            const { min_date } = this.options.context as any;
            
            if (
              new Date(value).toString() !== "Invalid Date" &&
              new Date(value)<=
                new Date(min_date)
            ) {
              return false;
            }
            return true;
          }
        ),
      amount_debit: yup
        .string()
        .test(
          "amount_debit",
          "Amount is greater than the balance.",
          function (value) {

            const { balance } = this.parent as any;
            
            if (Number(value) > Number(balance)) {
              return false;
            }
            return true;
          }
        ),
    })
  ),
});
