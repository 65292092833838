import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Row, useTable } from "react-table";
import { ObjectType, TableData } from "../../../../types";
import { useAppSelector } from "../../../../app/hooks";
import { loaderStateSelector } from "../../commonSlice";
import Table from "./Table";

type Props = {
  id?: string;
  className: string;
  columns: any[];
  data: TableData[];
  url: string;
  type?: string;
  sortHandler: (e: string) => void;
  isLoading?: boolean;
  editPermission: boolean;
  isDetailViewExist: boolean;
  hiddenColumns?: string[];
  children: React.ReactNode;
  isNavigate?: boolean;
  isNotResponsive?: boolean;
};
function TableContainer(props: Props) {
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const navigate = useNavigate();
  const isLoading = useAppSelector(loaderStateSelector);

  const responsiveTableDropdown = () => {
    var scrollItem: any = document.querySelector(".table-container");
    let resizeFlag: boolean = false;

    scrollItem &&
      scrollItem.addEventListener("scroll", function (e: any) {
        setTableScrollLeft(scrollItem.scrollLeft);
      });
    $(".dropdown-ctrl").on("click", function () {
      ($(".dropdown-ctrl") as any).dropdown("hide");
      ($(this) as any).dropdown("show");
    });
    $(".dropdown-ctrl").on("show.bs.dropdown", function () {
      $(".table-container").css({ overflow: "inherit" });
      $(".table-container table").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
    });

    $(".dropdown-ctrl").on("hide.bs.dropdown", function () {
      $(".table-container").css({
        "overflow-x": "auto",
        "overflow-y": "hidden",
      });
      $(".table-container table").css("margin-left", 0);
    });
    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-ctrl") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };

  $(() => {
    !props.isNotResponsive && responsiveTableDropdown();
  });

  const detailViewHandler = (listData: ObjectType) => {
    if (!props.isDetailViewExist) {
      props.editPermission
        ? navigate(`${props.url}/edit/${listData.id}`, {
            state: {
              redirectTo: listData.redirectTo,
              consumerId: listData.consumerId,
            },
          })
        : navigate("");
    } else if (listData.status !== "Draft") {
      let url = `${props.url}/detail/${listData.id}`;
      if(props.type) url = `${url}?type=${props.type}`
      navigate(url, {
        state: {
          haveTransaction: listData.transactionExist,
          openingBalance: listData.openingBalance,
          redirectTo: listData.redirectTo,
          consumerId: listData.consumerId,
        },
      });
    } else if (listData.status === "Draft") {
      props.editPermission
        ? navigate(`${props.url}/edit/${listData.id}`, {
            state: {
              redirectTo: listData.redirectTo,
              consumerId: listData.consumerId,
            },
          })
        : navigate("");
    }
  };

  const tbodyRowOnClick = (row: Row<TableData>, e: React.MouseEvent) => {
    if (props.isNavigate !== undefined && props.isNavigate === false) {
      e.preventDefault();
    } else {
      let listData = row.cells[row.cells.length - 1].value;
      if (listData.id) {
        detailViewHandler(listData);
      } else {
        let listData = row.allCells[row.allCells.length - 1].value;
        if (listData.id) {
          detailViewHandler(listData);
        }
      }
    }
  };

  const tbodyColumnClick = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (props.isNavigate !== undefined && props.isNavigate === false) {
      e.preventDefault();
    } else {
      if (cellIndex + 1 === row.cells.length) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };
  return (
    <div
      className={`table-container table-responsive add-on-table-container ${
        props.data.length < 1 ? "no-list-item" : ""
      }`}
    >
      {props.data.length > 0 ? (
        <Table
          id={props.id ? props.id : "invoice_list_table"}
          columns={props.columns}
          data={props.data}
          tableClassName={props.className}
          dataSort={true}
          isLoading={isLoading}
          haveColumnClassName={true}
          onSortClick={props.sortHandler}
          rowClassName={""}
          emptyClassName={"text-center dataTables_empty"}
          hiddenColumns={props.hiddenColumns}
          tbodyRowOnClick={tbodyRowOnClick}
          tbodyColumnClick={tbodyColumnClick}
        >
          {props.children}
        </Table>
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
}

export default TableContainer;
