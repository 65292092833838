import { useState } from "react";

type Invoice = {
  invoice_id: number;
  forex_amount: number;
  payment: number;
  withholding_tax: number;
  id: number;
  conversion_id: number;
  invoice_number: string;
  invoice_date: string;
  invoice_amount: string;
  amount_due: string;
  is_deleted: boolean;
};
type Props = {
  currency_id: number | null;
  bank_currency_id: number | null;
  unpaid_invoices: Invoice[];
  bank_charges: number | string;
  amount_received: number | string;
  amount_received_bank_currency: number | string;
  bank_conversion_rate: number | string;
  bank_charges_bank_currency: number | string;
  total_refunded_amount?: number | string;
};
export const payloadRefiner = (props: Props) => {
  let result: any = {};
  if (props && props.bank_currency_id === null) {
    result = {
      ...props,
      amount_received: !isNaN(Number(props.amount_received))
        ? Number(props.amount_received)
        : 0,
      bank_conversion_rate: null,
      amount_received_bank_currency: 0,
      bank_charges_bank_currency: 0,
      bank_charges: 0,
      bank_currency_id: null,
      total_refunded_amount: props.total_refunded_amount
        ? Number(props.total_refunded_amount)
        : 0,
    };
  } else if (
    props.bank_currency_id &&
    props.bank_currency_id === props.currency_id
  ) {
    result = {
      ...props,
      amount_received: !isNaN(Number(props.amount_received))
        ? Number(props.amount_received)
        : 0,
      bank_conversion_rate: null,
      amount_received_bank_currency: 0,
      bank_charges: !isNaN(Number(props.bank_charges))
        ? Number(props.bank_charges)
        : 0,
      bank_charges_bank_currency: !isNaN(
        Number(props.bank_charges_bank_currency)
      )
        ? Number(props.bank_charges_bank_currency)
        : 0,
      total_refunded_amount: props.total_refunded_amount
        ? Number(props.total_refunded_amount)
        : 0,
    };
  } else if (
    props.bank_currency_id &&
    props.bank_currency_id !== props.currency_id
  ) {
    result = {
      ...props,
      amount_received: !isNaN(Number(props.amount_received))
        ? Number(props.amount_received)
        : 0,
      amount_received_bank_currency: !isNaN(
        Number(props.amount_received_bank_currency)
      )
        ? Number(props.amount_received_bank_currency)
        : 0,
      bank_charges: !isNaN(Number(props.bank_charges))
        ? Number(props.bank_charges)
        : 0,
      bank_charges_bank_currency: !isNaN(
        Number(props.bank_charges_bank_currency)
      )
        ? Number(props.bank_charges_bank_currency)
        : 0,
      total_refunded_amount: props.total_refunded_amount
        ? Number(props.total_refunded_amount)
        : 0,
    };
  }
  return result;
};
