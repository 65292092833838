import FormControlLabel from "@mui/material/FormControlLabel";
import { Container } from "../htmlTags/Container";

import Checkbox from "@mui/material/Checkbox";
import { SaveButton, SecondaryButton } from "../button";
import "./MarkedPopup.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MarkedBodyProps } from "./types";

export const MarkedPopupBody = (props: MarkedBodyProps) => {
  const [isChecked, setChecked] = useState(false);
  const navigate = useNavigate();
  const { purchaseOrderId } = useParams();
  return (
    <Container className="mark-popup-content-wrapper">
      <Container className="">
        <FormControlLabel
          sx={{ marginLeft: "0px" }}
          control={<Checkbox checked={isChecked} />}
          label={props.markCheckboxLabel}
          onClick={() => {
            setChecked(!isChecked);
          }}
        />
      </Container>
      <Container className="mark-popup-btn">
        <SaveButton
          type="button"
          isDisabled={false}
          buttonContent="Yes Proceed"
          onClick={() => {
            props.onSave();
            if (isChecked) {
              setTimeout(() => {
                navigate(`/bill/create?purchaseOrderId=${purchaseOrderId}`);
              }, 500);
            }
          }}
        />
        <SecondaryButton
          type="button"
          isDisabled={false}
          buttonContent="Cancel"
          onClick={props.onCancel}
        />
      </Container>
    </Container>
  );
};
