import { getFiscalYear } from "./fiscalYearHelper";
import { DateFormatHandler } from "./helper";

function formatDateRange(
  dateRangeValue: string,
  dateRangeName: string,
  orgFiscalYear: string | null
): string {
  const fiscalYear = orgFiscalYear || "April-March";
  const [startDate, endDate] = dateRangeValue.split("_");
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const fiscalYearObj = getFiscalYear(fiscalYear, "Current");
  const fiscalStartDateObj = new Date(fiscalYearObj.startDate);
  const fiscalEndDateObj = new Date(fiscalYearObj.endDate);
  const [fiscalStartMonth, fiscalEndMonth] = fiscalYear.split("-");
  const startMonth = startDateObj.getMonth();
  const endMonth = endDateObj.getMonth();
  let result = "";
switch(dateRangeName){
  case "this_month":
    result = `${startDateObj.toLocaleString("en-us", {
      month: "long",
    })}-${startDateObj.getFullYear()}`;
    break;
  case "this_quarter":
    const quarter =
    Math.ceil(startMonth / 3) == 0 ? 4 : Math.ceil(startMonth / 3);
  if (
    startDateObj.getMonth() < fiscalEndDateObj.getMonth() ||
    startDateObj.getFullYear() < fiscalStartDateObj.getFullYear() ||
    Math.ceil(startMonth / 3) == 0
  ) {
    const startYear =
      Math.ceil(startMonth / 3) == 0
        ? fiscalStartDateObj.getFullYear() -
          (fiscalStartDateObj.getFullYear() - startDateObj.getFullYear()) -
          1
        : fiscalStartDateObj.getFullYear() -
          (fiscalStartDateObj.getFullYear() - startDateObj.getFullYear());
    const endYear =
      Math.ceil(startMonth / 3) == 0
        ? fiscalEndDateObj.getFullYear() -
          (fiscalStartDateObj.getFullYear() - startDateObj.getFullYear()) -
          1
        : fiscalEndDateObj.getFullYear() -
          (fiscalStartDateObj.getFullYear() - startDateObj.getFullYear());
    result =
      startYear.toString() !== endYear.toString()
        ? `Quarter${quarter}-FY-${startYear.toString()}-${endYear.toString()}`
        : `Quarter${quarter}-FY-${startYear.toString()}`;
  } else {
    const startYear = fiscalStartDateObj.getFullYear();
    const endYear = fiscalEndDateObj.getFullYear();
    result =
      startYear.toString() !== endYear.toString()
        ? `Quarter${quarter}-FY-${startYear.toString()}-${endYear.toString()}`
        : `Quarter${quarter}-FY-${startYear.toString()}`;
  }
  break;
  case "this_year":
    if (fiscalStartDateObj.getMonth() + 1 !== 1) {
      if (startMonth < parseInt(fiscalStartMonth)) {
        result = `FY-${startDateObj.getFullYear() - 1}-${
          endDateObj.getFullYear() - 1
        }`;
      } else {
        result = `FY-${startDateObj.getFullYear()}-${endDateObj.getFullYear()}`;
      }
    } else {
      result = `FY-${startDateObj.getFullYear()}`;
    }
    break;
  case "today":
    result = DateFormatHandler(startDate);
    break;
  default:
    result = `${DateFormatHandler(startDate)} TO ${DateFormatHandler(endDate)}`;
    break;
}
  return result;
}

export default formatDateRange;
