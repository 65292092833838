import PercentIcon from "@mui/icons-material/Percent";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: "none",
  borderRadius: "8px",
  padding: "6px 12px",
  color: "#000", // Default color
  "&.Mui-selected": {
    backgroundColor: "#1A73E8",
    color: "#fff",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#1669C1",
  },
  "&:hover": {
    backgroundColor: "#f1f3f4",
  },
}));
type Props = {
  handleToggle: (value: string) => void;
  currencyCode: string;
  discountType: string;
};
const DiscountToggleButton = (props: Props) => {
  const [selected, setSelected] = useState("Absolute");

  const handleToggle = (event: any, newSelection: any) => {
    if (newSelection !== null) {
      setSelected(newSelection);
      props.handleToggle(newSelection);
    }
  };
  useEffect(() => {
    setSelected(props.discountType);
  }, [props.discountType]);
  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleToggle}
      aria-label="discount toggle"
      sx={{
        width: "82px",
      }}
    >
      <CustomToggleButton
        value="Percentage"
        aria-label="Percentage"
        sx={{
          "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
            borderBottomRightRadius: "0px",
            borderTopRightRadius: "0px",
          },
        }}
      >
        <PercentIcon sx={{ fontSize: "14px" }} />
      </CustomToggleButton>
      <CustomToggleButton
        value="Absolute"
        aria-label="Absolute"
        sx={{
          borderBottomRightRadius: "4px",
          borderTopRightRadius: "4px",
          fontSize:"14px",
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px",
            height: "21px",
          },
        }}
      >
        {props.currencyCode.toUpperCase()}
      </CustomToggleButton>
    </ToggleButtonGroup>
  );
};

export default DiscountToggleButton;
