import { FormikProps } from "formik";

type Props = {
  formik: FormikProps<any>;
};

export const isAmountEditable = (props: Props) => {
  let result: boolean = false;
  if (
    props.formik.values.currency_id !== props.formik.values.bank_currency_id &&
    props.formik.values.amount_received_bank_currency &&
    props.formik.values.bank_conversion_rate &&
    props.formik.values.bank_currency_id
  ) {
    result = true;
  } else {
    result = false;
  }
  return result;
};
