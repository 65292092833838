import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InvoiceImage } from "../../../../../../assets/images";
import {
  DateFormatHandler,
  StatusColor,
} from "../../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  debitNoteColumnValues,
  debitNoteDefaultValues,
  debitNoteMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import {
  DebitNoteListValues as DebitNoteListType,
  Transactionlimit,
} from "../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  getTableCustomizationFields,
} from "../../../../../common/commonSlice";
import EmptyList from "../../../../../common/components/EmptyList";
import Popover from "../../../../../common/components/Popover";
import Pagination from "../../../../../common/components/table/Pagination";
import Loader from "../../../../components/Loader";
import { debitNoteList } from "../../../../debitNote/debitNoteSlice";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
type Props = {
  isSameOrg: boolean;
};
function DNTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [debitNoteData, setDebitNoteData] = useState<DebitNoteListType[]>([]);
  const [totalDebitNotes, setTotalDebitNotes] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Reference Number",
    "Total Taxable Amount",
    "actions",
  ]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const vendorId = editId ? editId : "";
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  let debitNoteRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Debit Notes"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTableCustomizationFields();
    fetchDebitNoteList();
  }, [page, itemsPerPage, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(totalDebitNotes / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalDebitNotes, page]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  useEffect(() => {
    let hiddenItems: string[] = [];
    debitNoteColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems, "actions"]);
  }, [debitNoteData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = async () => {
    const responseAction = await dispatch(
      getTableCustomizationFields({
        tableName: "Debit Notes",
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let selectedFields: string[] = [];
      if (response.list_of_column.length > 0) {
        selectedFields = response.list_of_column;
      } else {
        selectedFields = debitNoteDefaultValues;
      }
      debitNoteMandatoryValues.map((column) => {
        if (!selectedFields.includes(column)) {
          selectedFields.push(column);
        }
      });
      setSelectedColumns([...selectedFields]);
    }
  };
  /**
   * Fetch all Debit notes
   */
  const fetchDebitNoteList = async () => {
    setIsLoading(true);
    if (vendorId) {
      const responseAction = await dispatch(
        debitNoteList({
          page,
          itemsPerPage,
          dateSortOrder,
          orgId: currentUserInfo.organization_id,
          orgIds: [],
          vendorList: [Number(vendorId)],
          statusList: [],
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);

        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setDebitNoteData(response.debit_notes);
          let length = response.debit_notes?.length;
          setTotalDebitNotes(response.total);
          if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }
  };
  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (column === "Debit Note Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * Set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Debit Note Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Debit Note Number",
        accessor: "show.debitNoteId",
        sort: false,
        sortOrder: "",
        className: "cr_note_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "dt_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Bill Number",
        accessor: selectedColumns.includes("Bill Number")
          ? "show.billNumbers"
          : "Bill Number",
        sort: false,
        sortOrder: "",
        className: "bill_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? (
            <div className="pills-wrapper">
              <Popover values={value} tag={false} />
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        Header: "Debit Note Balance",
        accessor: selectedColumns.includes("Debit Note Balance")
          ? "show.balance"
          : "Debit Note Balance",
        sort: false,
        sortOrder: "",
        className: "cr_note_balance",
      },
      {
        Header: "actions",
        accessor: "actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return <></>;
        },
      },
    ],
    [dateSortOrder, selectedColumns, hiddenColumns]
  );

  /**
   * Set table row data
   */
  const data = debitNoteData
    ? debitNoteData?.map((debitNote, index) => ({
        show: {
          date: DateFormatHandler(debitNote.debit_note_date),
          debitNoteId: joinTransactionNumber(debitNote.debit_note_no),
          referenceNumber: debitNote.reference_number,
          billNumbers: debitNote.bill_numbers.map((bills) => {
            return { id: bills.id, name: joinTransactionNumber(bills.name) };
          }),
          status: debitNote.debit_note_status,
          totalTaxableAmount:
            debitNote.currency_code +
            " " +
            NumberFormat(
              Number(debitNote.total_taxable_amount),
              debitNote.currency_code,
              orgCurrencyList
            ),
          amount:
            debitNote.currency_code +
            " " +
            NumberFormat(
              Number(debitNote.total),
              debitNote.currency_code,
              orgCurrencyList
            ),
          balance: debitNote.balance_due
            ? debitNote.currency_code +
              " " +
              NumberFormat(
                Number(debitNote.balance_due),
                debitNote.currency_code,
                orgCurrencyList
              )
            : debitNote.currency_code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  debitNote.currency_code
                    ? debitNote.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
        },
        actions: {
          id: debitNote.id,
          status: debitNote.debit_note_status,
          listName: debitNote.debit_note_no,
          emailTo: debitNote.email_to,
          isMutable: debitNote.bill_numbers.length > 0 ? false : true,
          redirectTo: `/vendors/detail/${editId}`,
          consumerId: editId,
        },
      }))
    : [];
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalDebitNotes;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <>
      <TableContainer
        className="list-table table table-striped w-100"
        columns={columns}
        data={data}
        url={"/debit-note"}
        sortHandler={sortHandler}
        isLoading={isLoading}
        editPermission={debitNoteRolePermission.includes("Update")}
        isDetailViewExist={true}
        hiddenColumns={hiddenColumns}
      >
        <EmptyList
          heading={
            debitNoteRolePermission.includes("Create")
              ? "Create new debit note!"
              : "No Debit notes found!"
          }
          subheading={
            debitNoteRolePermission.includes("Create")
              ? "Apply debit notes to bills and reduce the Bill amount."
              : "Oops, There is no Debit note data available right now"
          }
          list="Debit Notes"
          alt="create debit note preview"
          buttonText="Add Debit Note"
          navigateLink={`/debit-note/create/${editId}`}
          navigateState={{
            state: {
              redirectTo: `/vendors/detail/${editId}`,
              consumerId: editId,
            },
          }}
          isCrateAccess={
            debitNoteRolePermission.includes("Create") && props.isSameOrg
          }
          icon={InvoiceImage}
        />
      </TableContainer>
      {isLoading ? <Loader /> : null}

      <div className="table-footer pagination-container w-100 clear-both">
        {totalDebitNotes > itemsPerPage ? (
          <Pagination
            pageCount={pageCount}
            page={page}
            handlePageClick={handlePageClick}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default DNTransactions;
