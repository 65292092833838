import { ErrorToaster } from "../../../../../../helpers/toastHelper";
import { BillDetailValues } from "../../../../../../types";
import { AlertModal } from "../../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../../common/components/alertModal/alertModalContext";
import { PlusIcon } from "../../../../../common/components/customSvgIcons/plusIcon";
import { colors } from "../../../../../constants/colors";
import { message } from "../../../../../constants/messages";
import { CreateDebitNoteAlert } from "./createDebitNoteAlert";

export const CreateDebitNoteAlertModal = (props: {
  debitNoteData: BillDetailValues;
  isCreationPossible: boolean;
  isVoid?: boolean;
}) => {
  const handleDisabledAction = () => {
    ErrorToaster(message().bill.applyDebitVoidBill);
  };
  return (
    <AlertModal
      modalTitle={
        props.isCreationPossible
          ? "Create debit note!"
          : "Cannot create debit note!"
      }
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <CreateDebitNoteAlert
                handleClose={onCloseModal}
                debitNoteData={props.debitNoteData}
                isCreationPossible={props.isCreationPossible}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span className="">
          <PlusIcon color={colors.charcoalGrey} />
          Create Debit Note
        </span>
      }
      buttonClass="dropdown-item no-appearance w-100"
      wrapperClass="create-debit-note-bill-modal"
      isButtonDisabled={props.isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
};
