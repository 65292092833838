import * as yup from "yup";
import { conversionMinDate } from "../../../../../constants/constants";
import { getFormatedDate } from "../imports";

export const PaymentDetailValidation = yup.object({
  payment_date: yup
    .string()
    .required("Please enter a payment date")
    .test(
      "payment_date",
      "Please enter a valid payment date",
      function (value) {
        new Date(value).toString();
        if (value === "Invalid Date") {
          return false;
        }
        return true;
      }
    )
    .test(
      "payment_date",
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.",
      function (value) {
        if (value === "Old date") {
          return false;
        }
        return true;
      }
    )
    .test(
      "payment_date",
      "Payment date cannot be after the refund date. Please adjust the refund date first",
      function (value) {
        const { refund_history } = this.options.context as any;
        if (refund_history && refund_history.length > 0) {
          const minRefundDate = refund_history.reduce(
            (min: any, current: any) => {
              const currentDate = new Date(current.refunded_on);
              return currentDate < min ? currentDate : min;
            },
            new Date(refund_history[0].refunded_on)
          );
          if (
            getFormatedDate(new Date(value)) > getFormatedDate(minRefundDate)
          ) {
            return false;
          }
        }
        return true;
      }
    )
    .test(
      "payment_date",
      "Please enter a date with a year above 2000",
      function (value) {
        if (
          new Date(value).toString() !== "Invalid Date" &&
          new Date(value) >= new Date(conversionMinDate)
        ) {
          return true;
        }
        return false;
      }
    ),
  customer_id: yup.number().required("Please select a customer").nonNullable(),
  payment_number: yup
    .string()
    .test(
      "payment_number",
      "Please enter a valid payment number",
      function (value) {
        const payment_number_value = value?.split("~")[1];

        if (payment_number_value === "") {
          return false;
        }
        return true;
      }
    ),
  payment_mode: yup.string().required("Please select a payment mode"),
  amount_received: yup
    .string()
    .test(
      "is-amount-received-entered",
      "Please enter a valid amount received",
      function (value) {
        const { currency_id, bank_currency_id } = this.options.context as any;
        // if bank currency id and currency id are different no need to validate amount_paid, so returning true.
        if (bank_currency_id && currency_id !== bank_currency_id) {
          return true;
        }
        if (!value || Number(value) === 0) {
          return false;
        }
        return true;
      }
    )
    .test(
      "is-amount-received-greater-than-refund",
      "Payment amount is lesser than the applied/refunded amount",
      function (value) {
        const { total_refunded_amount, amount_excess } = this.options
          .context as any;
        if (total_refunded_amount && Number(amount_excess) < 0) {
          return false;
        }
        return true;
      }
    ),
  amount_received_bank_currency: yup
    .string()
    .test(
      "is-amount-received-entered",
      "Please enter a valid amount received",
      function (value) {
        const { currency_id, bank_currency_id } = this.options.context as any;
        // if bank currency id and currency id are different, then validate
        if (
          bank_currency_id &&
          currency_id !== bank_currency_id &&
          (!value || Number(value) === 0)
        ) {
          return false;
        }
        return true;
      }
    ),
  deposit_to_account_name: yup
    .string()
    .required("Please select a deposit to account")
    .nonNullable(),
  tax_account_name: yup
    .string()
    .test(
      "is-tax-account-chosen",
      "Please select a tax account",
      function (value) {
        const { is_tax_deducted, tax_account_id } = this.options.context as any;
        if (
          (is_tax_deducted === "true" || is_tax_deducted === true) &&
          (value === null || value === "" || value === undefined)
        ) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  check_number: yup
    .string()
    .test("is-check-number-set", "Please enter check number", function (value) {
      const { payment_mode } = this.options.context as any;
      if (
        (value === null || value === "" || value === undefined) &&
        payment_mode === "Check"
      ) {
        return false;
      }

      return true;
    })
    .nullable(),
  check_expiry_in: yup
    .string()
    .test(
      "is-check-expiry-in-set",
      "Please choose check expiry in.",
      function (value) {
        const { payment_mode } = this.options.context as any;
        if (
          (value === null || value === "" || value === undefined) &&
          payment_mode === "Check"
        ) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  check_date: yup
    .string()
    .test("is_check_date_set", "Please enter check date", function (value) {
      const { payment_mode } = this.options.context as any;
      if (value === null && payment_mode === "Check") {
        return false;
      }

      return true;
    })
    .test(
      "check_date_value",
      "Please enter a valid check date",
      function (value) {
        const { payment_mode } = this.options.context as any;
        new Date(value || "").toString();
        if (
          new Date(value || "").toString() === "Invalid Date" &&
          payment_mode === "Check"
        ) {
          return false;
        }
        return true;
      }
    )
    .nullable(),
  check_expiry: yup
    .string()
    .test(
      "check_date_payment_date_check",
      "Check expiry date should be greater than or equal to payment date",
      function (value) {
        const { payment_date, payment_mode } = this.options.context as any;
        if (
          new Date(value || "").toString() !== "Invalid Date" &&
          new Date(value || "") < new Date(payment_date) &&
          payment_mode === "Check"
        ) {
          return false;
        }
        return true;
      }
    )
    .nullable(),
  bank_charges_account_id: yup
    .mixed()
    .test(
      "is-bank-charges-set",
      "Please select bank charges account",
      function (value) {
        const { bank_charges_amount } = this.options.context as any;

        if (
          value === null &&
          bank_charges_amount !== undefined &&
          Number(bank_charges_amount) !== 0
        ) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  bank_charges_amount: yup
    .mixed()
    .test(
      "is-bank-currency-set",
      "Please enter bank charges amount",
      function (value) {
        const { bank_charges_account_id } = this.options.context as any;

        if (
          (value === undefined || value === null || Number(value) === 0) &&
          !isNaN(Number(bank_charges_account_id)) &&
          Number(bank_charges_account_id) !== 0
        ) {
          return false;
        }

        return true;
      }
    )
    .nullable(),
  unpaid_invoice_lines: yup.array().of(
    yup.object().shape({
      allocation_date: yup
        .string()
        .required("Please enter a valid allocation date")
        .test(
          "allocation_date",
          "Please enter a valid allocation date",
          function (value) {
            if (new Date(value).toString() === "Invalid Date" || !value) {
              return false;
            }
            return true;
          }
        ),
      withholding_tax: yup
        .mixed()
        .test(
          "is-withholding-tax-more",
          "Tax amount is greater than amount due",
          function (value) {
            const { amount_due, payment } = this.parent as any;
            const { is_total_error } = this.options.context as any;
            let amountDue = isNaN(amount_due) ? 0 : Number(amount_due);
            let paymentAmount = isNaN(payment) ? 0 : Number(payment);
            let withholdingTax = isNaN(value as any) ? 0 : Number(value);
            if (
              withholdingTax > amountDue &&
              paymentAmount === 0 &&
              !is_total_error
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "is-total-amount-more",
          "Total amount cannot be more than balance due",
          function (value) {
            const { amount_due, payment } = this.parent as any;
            const { is_total_error } = this.options.context as any;
            let amountDue = isNaN(amount_due) ? 0 : Number(amount_due);
            let paymentAmount = isNaN(payment) ? 0 : Number(payment);
            let withholdingTax = isNaN(value as any) ? 0 : Number(value);
            if (
              withholdingTax + paymentAmount > amountDue &&
              paymentAmount !== 0 &&
              withholdingTax !== 0 &&
              !is_total_error
            ) {
              return false;
            }

            return true;
          }
        ),
      payment: yup
        .mixed()
        .test(
          "is-payment-amount-more",
          "Payment amount is greater than amount due",
          function (value) {
            const { amount_due, withholding_tax } = this.parent as any;
            const { is_total_error } = this.options.context as any;
            let amountDue = isNaN(amount_due) ? 0 : Number(amount_due);
            let withholdingTax = isNaN(withholding_tax)
              ? 0
              : Number(withholding_tax);
            let paymentAmount = isNaN(value as any) ? 0 : Number(value);
            if (
              paymentAmount > amountDue &&
              withholdingTax === 0 &&
              !is_total_error
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "is-payment-more",
          "Total amount cannot be more than balance due",
          function (value) {
            const { amount_due, withholding_tax } = this.parent as any;
            const { is_total_error } = this.options.context as any;
            let amountDue = isNaN(amount_due) ? 0 : Number(amount_due);
            let withholdingTax = isNaN(withholding_tax)
              ? 0
              : Number(withholding_tax);
            let paymentAmount = isNaN(value as any) ? 0 : Number(value);

            if (
              paymentAmount + withholdingTax > amountDue &&
              paymentAmount !== 0 &&
              withholdingTax !== 0 &&
              !is_total_error
            ) {
              return false;
            }

            return true;
          }
        ),
    })
  ),

  total: yup
    .string()
    .test(
      "totalAmount",
      "Total amount is greater than the amount received",
      function (value) {
        let sumOfPaymentAndTax = 0;
        const { amount_received, unpaid_invoice_lines, total } = this
          .parent as any;
        let amountReceived = isNaN(amount_received)
          ? 0
          : Number(amount_received);
        let totalAmount = isNaN(total) ? 0 : Number(total);
        let modifiedValue =
          unpaid_invoice_lines &&
          unpaid_invoice_lines.map((item: any) => {
            return {
              payment: isNaN(item.payment as any) ? 0 : Number(item.payment),
              withholding_tax: isNaN(item.withholding_tax as any)
                ? 0
                : Number(item.withholding_tax),
            };
          });
        modifiedValue?.map((item: any) => {
          sumOfPaymentAndTax = sumOfPaymentAndTax + item.payment;
        });
        if (
          Math.round(sumOfPaymentAndTax * 1000000) / 1000000 >
          Math.round(totalAmount * 1000000) / 1000000
        ) {
          return false;
        }
        return true;
      }
    ),
});
