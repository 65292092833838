import React from "react";
import { TableData, Transactionlimit } from "../../../../../../types";
import FilterEmptyList from "../../../../../common/components/FilterEmptyList";
import { GroupIcon, InvoiceImage } from "../../../../../../assets/images";
import TableInfo from "../../../../../common/components/table/TableInfo";
import EmptyList from "../../../../../common/components/EmptyList";
import TableContainer from "../../../../../common/components/table/TableContainer";
import EmptySearchList from "../../../../../common/components/emptySearchList/EmptySearchList";

type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  vendorRoles: any;
  columns: any[];
  data: TableData[];
  vendorName: string;
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
  setPageInfo: (countPerPage: number, page: number) => void;
  hiddenColumns: string[];
};
function VendorTable({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  vendorRoles,
  columns,
  data,
  vendorName,
  isFilterApplied,
  initializeFilter,
  transactionLimit,
  setPageInfo,
  hiddenColumns,
}: Props) {
  return (
    <div className={`card-body ${totalList === 0 ? "no-list-item-body" : ""}`}>
      <div
        id="customer_table_wrapper"
        className={
          "dataTables_wrapper dt-bootstrap5 no-footer " +
          `${totalList > 0 ? "" : "no-data"}`
        }
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={setPageInfo}
        />
        <TableContainer
          className="list-table table table-striped w-100"
          columns={columns}
          data={data}
          url={"/vendors"}
          sortHandler={() => {}}
          editPermission={vendorRoles.includes("Update")}
          isDetailViewExist={true}
          hiddenColumns={hiddenColumns}
        >
          {vendorName.trim() === "" && totalList === 0 && !isFilterApplied ? (
            <EmptyList
              heading={
                vendorRoles.includes("Create")
                  ? "Add New Vendor!"
                  : "No Vendors found!"
              }
              subheading={
                vendorRoles.includes("Create")
                  ? "Create and manage your Vendors"
                  : "Oops, There is no Vendor data available right now"
              }
              list="Vendor"
              alt="create vendor preview"
              buttonText="Add Vendor"
              navigateLink="/vendors/create"
              isCrateAccess={vendorRoles.includes("Create")}
              icon={GroupIcon}
              transactionLimit={transactionLimit.limit_exceed}
            />
          ) : isFilterApplied && vendorName.trim() === "" ? (
            <FilterEmptyList
              list="vendors"
              alt="vendor filter empty"
              icon={GroupIcon}
              handleReset={initializeFilter}
            />
          ) : (
            // "No Vendors found!"
            <EmptySearchList
              alt="Empty vendor list"
              icon={InvoiceImage}
              emptyText="No Vendors found!"
            />
          )}
        </TableContainer>
      </div>
    </div>
  );
}

export default React.memo(VendorTable);
