import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getRecurringInvoiceList,
  transactionLimitStatus,
  deleteRecurringInvoice,
  calculateTaxableAmount,
  calculateAmount,
  invoiceAttachments,
  invoiceAttachmentsDelete,
  createNewRecurringInvoice,
  updateRecurringInvoiceStatus,
  getRecurringInvoiceDetails,
  updateRecurringInvoice,
  getRIOverviewDetails,
  profileNameExist,
  deleteRInvoiceItem,
  deleteRInvoiceRecipient,
  deleteRInvoiceItemTax,
  sendReminder,
  updateRecurringInvoicePreferredStatus,
  getChildInvoiceList
} from "./recurringInvoiceAPI";
import { Transactionlimit, TaxableAmountCalculationValues, TotalAmountCalculationValues, RecurringInvoiceValues } from "../../../types";
import { RootState } from "../../../app/store";

export const recurringInvoiceList = createAsyncThunk(
  "recurringInvoicesList",
  async (data: {
    page: number;
    itemsPerPage: number;
    orgId: number;
    customerList: number[];
    orgIds: number[];
    statusList: string[];
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getRecurringInvoiceList(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "customer/limit/status",
  async (orgId: number) => {
    return transactionLimitStatus(orgId);
  });

export const deleteRIDetails = createAsyncThunk(
  "recurringInvoice/delete",
  async (data: { deleteRInvoiceId: number; orgId: number }) => {
    return await deleteRecurringInvoice(data);
  }
);

export const changeRecurringInvoiceStatus = createAsyncThunk(
  "recurringInvoice/update-status",
  async (data: { id: number; orgId: number; status: string }) => {
    return await updateRecurringInvoiceStatus(data);
  }
);


export const recurringInvoiceDetails = createAsyncThunk(
  "editRInvoiceDetail",
  async (data: { invoiceId: number; orgId: number }) => {
    return await getRecurringInvoiceDetails(data);
  }
);
export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateTaxableAmount(data);
  }
);

export const editRecurringInvoice = createAsyncThunk(
  "recurringInvoice/edit",
  async (data: {
    id: number;
    status: string;
    editRecurringInvoiceInputs: RecurringInvoiceValues;
    orgId: number;
  }) => {
    return await updateRecurringInvoice(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const invoiceDetails = createAsyncThunk(
  "invoiceDetail",
  async (data: { invoiceId: number; orgId: number }) => {
    return await getRIOverviewDetails(data);
  }
);

export const deleteRInvoiceItemDetails = createAsyncThunk(
  "recurringInvoice/delete/itemDetails",
  async (data: { invoiceId: number; itemId: number; orgId: number }) => {
    return await deleteRInvoiceItem(data);
  }
);

export const deleteRInvoiceRecipientDetails = createAsyncThunk(
  "recurringInvoice/delete/recipient",
  async (data: { invoiceId: number; emailIds: number[]; orgId: number }) => {
    return await deleteRInvoiceRecipient(data);
  }
);

export const deleteRInvoiceItemTaxDetails = createAsyncThunk(
  "recurringInvoice/delete/itemTax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteRInvoiceItemTax(data);
  }
);

export const sendReminderRI = createAsyncThunk(
  "recurringInvoice/sendReminder",
  async (data: { invoiceId: number,  orgId: number}) => {
    return await sendReminder(data);
  }
);

export const changePreferredInvoiceStatus = createAsyncThunk(
  "recurringInvoice/preferred-status",
  async (data: { recurring_invoice_id: string | number; preferred_invoice_status: string }) => {
    return await updateRecurringInvoicePreferredStatus(data);
  }
);

interface CommonState {
  transactionLimitStatus: Transactionlimit
}

const initialState: CommonState = {
  transactionLimitStatus: {
    limit_exceed: false,
    limit_count: ''
  },
};
export const commonrecurringInvoiceSlice = createSlice({
  name: "recurringInvoiceManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionLimitStatus.fulfilled, (state, { payload }) => {
        state.transactionLimitStatus = payload;
      })
      .addCase(getTransactionLimitStatus.rejected, (state) => {
        state.transactionLimitStatus = initialState.transactionLimitStatus;
      })
  },
});

export default commonrecurringInvoiceSlice.reducer;

//State selectors

export const getTransactionLimitStatusSelector = (state: RootState) =>
  state.recurringInvoiceManagement.transactionLimitStatus

export const attachInvoiceFiles = createAsyncThunk(
  "recurringInvoice/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await invoiceAttachments(data);
  }
);

export const deleteInvoiceFile = createAsyncThunk(
  "recurringInvoice/file/delete",
  async (data: { fileId: number; orgId: number }) => {
    return await invoiceAttachmentsDelete(data);
  }
);

export const createInvoice = createAsyncThunk(
  "invoice/create",
  async (data: {
    values: RecurringInvoiceValues;
    status: string;
    isSendMail: boolean;
    isPrintOrDownload: boolean;
    orgId: number;
  }) => {
    return await createNewRecurringInvoice(data);
  }
);

export const checkProfileName = createAsyncThunk(
  "invoice/number-exist",
  async (data: {
    profileName: string;
    orgId: number;
    invoiceId: number | null;
  }) => {
    return await profileNameExist(data);
  }
);

export const childInvoiceList = createAsyncThunk(
  "childInvoicesList",
  async (data: {
    page: number;
    dateSortOrder: string;
    itemsPerPage: number;
    invoiceId: number;
    orgId: number;
  }) => {
    return await getChildInvoiceList(data);
  }
);

