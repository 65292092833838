import * as React from "react";
import { Grid } from "@mui/material";
import Pagination from "../../../../common/components/table/Pagination";
import { listboxCustomStyle } from "../../../../common/components/ListBoxCustomStyle";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import useListData from "../../../../hooks/useListData";

function PaginationContainer() {
  const { page, itemsPerPage, pageCount, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();

  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  return (
    <Grid component="div" sx={listboxCustomStyle.paginationBox}>
      <Pagination
        pageCount={pageCount}
        page={page}
        handlePageClick={handlePageClick}
      />
    </Grid>
  );
}

export default PaginationContainer;
