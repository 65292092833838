import React from "react";
import { AlertModal } from "../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../common/components/alertModal/alertModalContext";
import CreateInvoiceContent from "../modalContents/CreateInvoiceContent";
type Props = {
  message: string;
  modalTitle: string;
  isCloseButtonEnabled: boolean;
  isSubmitEnabled: boolean;
  openRef: React.ForwardedRef<any>;
  handleStatusChange?: (status: string) => void;
  proformaId: number;
};
const CreateInoviceModal = (props: Props) => {
  return (
    <>
      <AlertModal
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return (
                <CreateInvoiceContent
                  onCloseModal={onCloseModal}
                  handleStatusChange={props.handleStatusChange}
                  proformaId={props.proformaId}
                />
              );
            }}
          </ModalContext.Consumer>
        }
        buttonContent={<span></span>}
        buttonClass="no-appearance d-none alert-modal-popup-wrapper-class"
        isCloseButtonEnabled={props.isCloseButtonEnabled}
        modalTitle={props.modalTitle}
        ref={props.openRef}
      />
    </>
  );
};

export default CreateInoviceModal;
