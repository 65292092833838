import { useState } from "react";
import { FormSelectField } from "../../../common/components";
import { useFormik } from "formik";
import "./billingSideMenuForm.css";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import CustomDatePicker from "../../../utils/atoms/CustomDatePicker";
import useCommonData from "../../../hooks/useCommon";
import { setLoaderState } from "../../../common/commonSlice";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import {
  createSideWideCalenderInvoicingHandler,
  updateSideWideCalenderInvoicingHandler,
} from "../calenderBillingSlice";
import * as yup from "yup";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import {
  chooseDayOption,
  selectFrequencyOption,
} from "../calenderBillingStateInitialization";
import {
  formatDateString,
  recurringInvoicingValue,
} from "../helpers/calendarInvoicingHelper";
import { getFormatedDate } from "../../../../helpers/helper";
import moment from "moment";
import { SiteWideInvoicingInitialType } from "../calendarBillingTypes";
import { CustomCreditsDropdown } from "../../subscription/components/CustomIssueCreditDropdown";
import Select from "react-select";

type Props = {
  getSiteWideBilling: () => void;
  savedFrequencies?: string[];
  id?: number;
  onCloseModal: () => void;
  initialValues: SiteWideInvoicingInitialType;
};

export const BillingSideMenuForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();

  const validate = yup.object({
    select_frequency: yup
      .string()
      .required("Please select a invoicing frequency")
      .test(
        "select_frequency",
        "This frequency is already added.",
        function (value) {
          if (
            !props.id &&
            props.savedFrequencies &&
            props.savedFrequencies.length > 0 &&
            props.savedFrequencies.includes(value)
          ) {
            return false;
          } else if (
            props.id &&
            formik.values.selectedFrequencyInEdit !== value
          ) {
            return false;
          }
          return true;
        }
      ),
    set_date: yup
      .string()
      .test("set_date", "Please enter valid date.", function (value) {
        const { set_recurring_invoicing_type, select_frequency } = this
          .parent as any;
        if (
          set_recurring_invoicing_type === "SET_DATE" &&
          select_frequency !== "WEEKLY" &&
          (value === "Invalid date" || !value)
        ) {
          return false;
        }
        return true;
      }),
    set_day: yup
      .string()
      .test("set_day", "Please select the day.", function (value) {
        const { set_recurring_invoicing_type, select_frequency } = this
          .parent as any;
        if (
          set_recurring_invoicing_type === "SET_DATE" &&
          select_frequency === "WEEKLY" &&
          !value
        ) {
          return false;
        }
        return true;
      }),
  });

  const onFormSubmit = async (data: any) => {
    dispatch(setLoaderState(true));
    if (props.id) {
      data.calendarInvoicingId = props.id;
      const responseAction = await dispatch(
        updateSideWideCalenderInvoicingHandler(data)
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Site-Wide Invoicing frequency updated successfully!",
          "tags-config-succcess"
        );
        props.getSiteWideBilling();
      } else {
        dispatch(setLoaderState(false));
      }
    } else {
      const responseAction = await dispatch(
        createSideWideCalenderInvoicingHandler(data)
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Site-Wide Invoicing frequency created successfully!",
          "tags-config-succcess"
        );
        props.getSiteWideBilling();
      } else {
        dispatch(setLoaderState(false));
      }
    }
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: { ...props.initialValues } as SiteWideInvoicingInitialType,
    enableReinitialize: true,
    validationSchema: validate,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (value) => {
      let data: any = {
        orgId: currentUserInfo.organization_id,
        invoicing_frequency: value.select_frequency,
        invoicing_type: value.set_recurring_invoicing_type,
        set_date: null,
        set_day: null,
      };

      if (
        value.select_frequency === "WEEKLY" &&
        value.set_recurring_invoicing_type === "SET_DATE"
      ) {
        data.set_day = value.set_day;
      }
      if (
        value.select_frequency !== "WEEKLY" &&
        value.set_recurring_invoicing_type === "SET_DATE"
      ) {
        data.set_date = value.set_date;
      }

      onFormSubmit(data);
      props.onCloseModal();
    },
  });

  const handleDate = (date: Date | null) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        formik.setFieldValue("set_date", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          formik.setFieldValue("set_date", dateFormated);
        } else {
          formik.setFieldValue("set_date", "Invalid date");
        }
      }
    } else if (date === null) {
      formik.setFieldValue("set_date", "");
    }
  };

  const unusedCreditsDropdown: {
    label: string;
    value: string;
    info: string;
  }[] = [
    {
      label: "Auto Set",
      value: "AUTO_SET",
      info: `Automatically schedules invoicing date based on subscription start ${
        formik.values.select_frequency === "WEEKLY" ? "day" : "date"
      }`,
    },
    {
      label: `${
        formik.values.select_frequency === "WEEKLY" ? "Set day" : "Set date"
      }`,
      value: "SET_DATE",
      info: `Manually choose the invoicing ${
        formik.values.select_frequency === "WEEKLY" ? "day" : "date"
      } for the respective frequency`,
    },
  ];

  return (
    <div className="billing-and-proration-modal-container">
      <div className="billing-and-proration-modal-inner-container">
        <FormSelectField
          name="select_frequency"
          id="select_frequency"
          label="Select Frequency"
          options={selectFrequencyOption}
          wrapperClass="mw-325"
          className="state-select custom-select billing-and-proration-select"
          value={
            selectFrequencyOption.filter(
              (data: any) => data.value === formik.values.select_frequency
            )[0] || ""
          }
          onChange={(option: any) => {
            formik.setFieldValue("select_frequency", option.value);
          }}
          placeholder={"Choose Plan Frequency"}
          isSearchable={true}
          isDisabled={false}
          error={formik.errors.select_frequency}
          isRequired={true}
          isOnlyInRow={false}
          messagePlacement="top-end"
          styles={customSelectStyle}
        />

        <label htmlFor="set_recurring_invoicing_type" className="required">
          Set a Recurring Invoicing Type
        </label>
        <Select
          name="set_recurring_invoicing_type"
          id="set_recurring_invoicing_type"
          options={CustomCreditsDropdown(unusedCreditsDropdown)}
          className={`discount-period-count trial-period-type-radius state-select form-select custom-select void-subscription-margin mb-23`}
          onChange={(option: any) => {
            formik.setFieldValue("set_recurring_invoicing_type", option.value);
          }}
          isSearchable={false}
          placeholder=""
          styles={customSelectStyle}
          value={
            CustomCreditsDropdown(
              unusedCreditsDropdown.map((data) => ({
                label: data.label,
                value: data.value,
              }))
            ).filter(
              (data) =>
                data.value === formik.values.set_recurring_invoicing_type
            )[0]
          }
          isDisabled={formik.values.select_frequency ? false : true}
        />

        {formik.values.set_recurring_invoicing_type === "SET_DATE" &&
          formik.values.select_frequency === "WEEKLY" && (
            <FormSelectField
              name="set_day"
              id="set_day"
              label={`${
                formik.values.select_frequency === "WEEKLY"
                  ? "Choose Day"
                  : "Choose Date"
              }`}
              options={chooseDayOption}
              wrapperClass="mw-325"
              className="state-select custom-select billing-and-proration-select"
              value={
                chooseDayOption.filter(
                  (data: any) => data.value === formik.values.set_day
                )[0] || ""
              }
              onChange={(option: any) => {
                formik.setFieldValue("set_day", option.value);
              }}
              placeholder={"Choose Day"}
              isSearchable={true}
              isDisabled={false}
              error={formik.errors.set_day}
              isRequired={true}
              isOnlyInRow={false}
              messagePlacement="top-end"
              styles={customSelectStyle}
            />
          )}

        {formik.values.set_recurring_invoicing_type === "SET_DATE" &&
          formik.values.select_frequency === "MONTHLY" && (
            <div className={"col-12 col-lg-4 mw-325 "}>
              <label htmlFor="check_expiry" className="required">
                Choose Date
              </label>
              <div id="set_date" className={`date-selector-wrapper`}>
                <CustomDatePicker
                  date={
                    !!formik.values.set_date
                      ? formatDateString(formik.values.set_date, "MONTHLY")
                      : ""
                  }
                  handleDate={handleDate}
                  type="set_date"
                  id="set_date"
                  placeholder="Enter invoicing date"
                  error={!formik.errors.set_date ? "" : "error"}
                  isOnChangeDisabled={true}
                  isHeaderDisabled={true}
                />
                <span className="error">
                  {!formik.errors.set_date ? "" : formik.errors.set_date}
                </span>
              </div>
            </div>
          )}

        {formik.values.set_recurring_invoicing_type === "SET_DATE" &&
          formik.values.select_frequency === "YEARLY" && (
            <div className={"col-12 col-lg-4 mw-325 "}>
              <label htmlFor="check_expiry" className="required">
                Choose Date
              </label>
              <div id="set_date" className={`date-selector-wrapper`}>
                <CustomDatePicker
                  date={
                    !!formik.values.set_date
                      ? formatDateString(formik.values.set_date, "YEARLY")
                      : ""
                  }
                  handleDate={handleDate}
                  type="set_date"
                  id="set_date"
                  placeholder="Enter invoicing date"
                  error={!formik.errors.set_date ? "" : "error"}
                  isOnChangeDisabled={true}
                />
                <span className="error">
                  {!formik.errors.set_date ? "" : formik.errors.set_date}
                </span>
              </div>
            </div>
          )}
      </div>
      <div className="billing-and-proration-modal-footer">
        <button
          type="button"
          className="save-button mx-0"
          onClick={() => {
            formik.submitForm();
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="text-decoration-none cancel-button mx-4"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onCloseModal();
            formik.resetForm();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
