import BlockIcon from "@mui/icons-material/Block";
import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { Span } from "../htmlTags/Span";
import { VoidConfirmationBody } from "./VoidConfirmationPopupBody";
import { VoidConfirmationModalProps } from "./types";
import { ErrorToaster } from "../../../../helpers/toastHelper";

export const VoidConfirmationModal = (props: VoidConfirmationModalProps) => {
  return props.transactionStatus !== "Void" &&
    (props.isTransactionExist === false ||
      (props.transactionStatus.includes("Overdue") &&
        !props.isTransactionExist) ||
      (props.transactionStatus.includes("Open") &&
        !props.isTransactionExist)) ? (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <VoidConfirmationBody
                module={props.module}
                transactionNumber={props.transactionNumber}
                onCancel={onCloseModal}
                transactionId={props.transactionId}
                onSubmit={props.onSubmit}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      modalTitle={`Void ${props.module}!`}
      modalBodyClass="void-confirmation-modal"
      isButtonDisabled={props.isFiscalClosed}
      disabledActionFunction={() => {
        ErrorToaster(
          `${props.module}s from a closed fiscal year cannot be voided.
          `
        );
      }}
      isCloseButtonEnabled={true}
      buttonContent={
        <Span>
          <BlockIcon sx={{ fontSize: "16px", marginRight: "5px" }} /> Void
        </Span>
      }
      buttonClass="no-appearance dropdown-item"
    />
  ) : (
    <></>
  );
};
