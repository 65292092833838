import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import {
  billPaymentList,
  deleteBillPaymentDetails,
} from "../paymentsMadeSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteBillPaymentId: number;
  deleteBillPaymentItemId: string;
  deleteBillPaymentStatus: string;
  // page: number;
  // itemsPerPage: number;
  // dateSortOrder: string;
  refreshBillPayments: (action: string) => void;
  organizationId: number;
  isUnpaidBillExist: boolean;
};

const DeleteBillPayment = (props: Props) => {
  const dispatch = useAppDispatch();
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      deleteBillPaymentDetails({
        deleteBillPaymentId: props.deleteBillPaymentId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const deleteRoleResponse = responseAction.payload;
      if (Object.keys(deleteRoleResponse).length) {
        props.refreshBillPayments("delete");
        $(".close-btn").click();
        // await dispatch(
        //   billPaymentList({
        //     page: props.page,
        //     itemsPerPage: props.itemsPerPage,
        //     dateSortOrder: props.dateSortOrder,
        //     orgId: props.organizationId,
        //     orgIds:[],
        //     vendorList:[],
        //     statusList:[],
        //   })
        // );
        toast.success("Bill payment deleted successfully!", {
          toastId: "bill-payment-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
        // toast.error("Delete Bill Payment action failed!", {
        //   icon: ({ theme, type }) => (
        //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
        //   ),
        //   toastId: "delete-bill-payment-failed-error",
        //   closeButton: false,
        //   className: "toast-error",
        //   position: "top-center",
        // });
      }
    }
  };
  let deleteBody =
    props.deleteBillPaymentStatus === "Draft" ? (
      <>
        Would you like to delete the transaction{" "}
        <strong>{joinTransactionNumber(props.deleteBillPaymentItemId)}</strong>{" "}
        in draft ?
      </>
    ) : props.isUnpaidBillExist ? (
      <>
        There are bill(s) allocated to{" "}
        <strong>{joinTransactionNumber(props.deleteBillPaymentItemId)}</strong>
        . On deleting this transaction the associated bill(s) payment(s) will be
        reverted. Would you like to proceed?
      </>
    ) : (
      <>Would you like to delete this transaction ?</>
    );
  return (
    <DeleteModal
      type="PM"
      heading="Delete Bill payment!"
      subHeading={deleteBody}
      transactionName=""
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
      paymentStatus={props.deleteBillPaymentStatus}
      isUnpaidInvoiceExist={props.isUnpaidBillExist}
    />
  );
};

export default DeleteBillPayment;
