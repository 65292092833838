import { CurrencyFormType } from "../types";
import { NumberFormat } from "./numberFormatHelper";

export const initialTaxValues = {
  tax_id: "",
  tax_percentage: "",
};
export const initialItemValues = {
  item_name: "",
  account_id: "",
  account_name: "",
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "Absolute",
  discount_account_id: "",
  discount_account_name: "",
  taxes: [initialTaxValues],
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  tds_name: "",
  currency_id: null,
};
export const getLineItemDetails = (itemDetails: any) => {
  itemDetails.map((item: any, index: number) => {
    if (item.discount_type === "Absolute") {
      item.discount = item.discount.discount_value;
    } else if (item.discount_type === "Percentage") {
      item.discount = item.discount.discount_percentage;
    }
    if (!item.taxes.length) {
      item.taxes = [initialTaxValues];
    }
    if (item.tds_percentage === null) {
      item.tds_percentage = 0;
    }
  });
  return itemDetails;
};
export const getForexGainOrLossAmount = (
  id: number,
  forexGainLossValues: Object,
  currencyCode: string,
  orgCurrencyList: CurrencyFormType[]
) => {
  for (const [key, value] of Object.entries(forexGainLossValues)) {
    if (Number(value) === 0) {
      return "";
    }
    if (Number(key) === id) {
      return value.toString().indexOf("-") < 0 && Number(value) !== 0
        ? "+" + NumberFormat(value, currencyCode, orgCurrencyList)
        : NumberFormat(value, currencyCode, orgCurrencyList);
    }
  }
};
