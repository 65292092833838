import React from "react";
import InfoTooltip from "./InfoTooltip";

type Props = {
  isDisabled: boolean;
  infoTitle: string;
  subject: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};
function SubjectInput({ isDisabled, infoTitle, subject, onChange }: Props) {
  return (
    <div
      className={`subject-col col-12 col-lg-4 mw-325 ${!isDisabled ? "" : " disabled-item"}`}
    >
      <div className="label-wrapper cn-subject-wrapper">
        <label htmlFor="cn_subject">Subject</label>
        <InfoTooltip title={infoTitle} />
      </div>
      <textarea
        name="subject"
        id="cn_subject"
        className="cn_subject form-control"
        maxLength={250}
        value={subject}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
}

export default SubjectInput;
