import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  CreateUserRoleValues,
  createUserValues,
  RoleList,
  UpdateUserValues,
  Users,
} from "../../../types";
import {
  cloneRole,
  createRole,
  deleteRole,
  deleteUser,
  getUserRoles,
  getUsersList,
  newUserVerification,
  resendInvitation,
  revokeSuspendedUser,
  suspendUser,
  updateRole,
  updateUser,
  userCreation,
  getOrganizationDropdown,
  getRolesDropdown,
  permissionSection,
  userLimitStatus,
  roleLimitStatus,
  refreshToken,
  getOrganizationList,
  isEmailUsed,
} from "./userAPI";

export const usersList: any = createAsyncThunk(
  "invite/userList",
  async (data: {
    page: number;
    itemsPerPage: number;
    roleId: number;
    userSort: string;
    orgId: number;
  }) => {
    return await getUsersList(data);
  }
);

export const inviteUser = createAsyncThunk(
  "invite/newUser",
  async (data: { values: createUserValues; orgId: number }) => {
    return await userCreation(data);
  }
);

export const userRolesList = createAsyncThunk(
  "userRole",
  async (data: { roleSort: string; orgId: number }) => {
    return await getUserRoles(data);
  }
);

export const userVerification = createAsyncThunk(
  "newUser/verify",
  async (data: { token: string }) => {
    return await newUserVerification(data);
  }
);

export const resendInvitationEmail = createAsyncThunk(
  "resendInvitation",
  async (data: { id: number; orgId: number }) => {
    return await resendInvitation(data);
  }
);

export const suspendUserDetails = createAsyncThunk(
  "suspend",
  async (data: { id: number; orgId: number }) => {
    return await suspendUser(data);
  }
);

export const revokeSuspendedUserDetails = createAsyncThunk(
  "revoke-suspend",
  async (data: { id: number; orgId: number }) => {
    return await revokeSuspendedUser(data);
  }
);

export const deleteUserDetails = createAsyncThunk(
  "delete",
  async (data: { id: number; orgId: number }) => {
    return await deleteUser(data);
  }
);

export const updateUserDetails = createAsyncThunk(
  "newUser/update",
  async (data: { id: number; values: UpdateUserValues; orgId: number }) => {
    return await updateUser(data);
  }
);

export const createNewUserRole = createAsyncThunk(
  "createRole",
  async (data: { values: CreateUserRoleValues; orgId: number }) => {
    return await createRole(data);
  }
);

export const cloneUserRole = createAsyncThunk(
  "cloneRole",
  async (data: { values: CreateUserRoleValues; orgId: number }) => {
    return await cloneRole(data);
  }
);

export const updateUserRole = createAsyncThunk(
  "updateRole",
  async (data: { id: number; values: CreateUserRoleValues; orgId: number }) => {
    return await updateRole(data);
  }
);

export const deleteUserRole = createAsyncThunk(
  "deleteRole",
  async (data: { id: number; orgId: number }) => {
    return await deleteRole(data);
  }
);

export const getOrganizationDropdownValues = createAsyncThunk(
  "getOrganizationDropdown",
  async (orgId: number) => {
    return await getOrganizationDropdown(orgId);
  }
);

export const getRolesDropdownValues = createAsyncThunk(
  "getRolesDropdown",
  async (data: { id: number; orgId: number }) => {
    return await getRolesDropdown(data);
  }
);

export const getPermissionSection = createAsyncThunk(
  "getPermissionSection",
  async (orgId: number) => {
    return await permissionSection(orgId);
  }
);

export const getUserLimitStatus = createAsyncThunk(
  "user/limit/status",
  async (orgId: number) => {
    return await userLimitStatus(orgId);
  }
);

export const getRoleLimitStatus = createAsyncThunk(
  "role/limit/status",
  async (orgId: number) => {
    return await roleLimitStatus(orgId);
  }
);

export const updateTokenStatus = createAsyncThunk(
  "user/token/status",
  async (data: { userId: number; tokenStatus: boolean; orgId: number }) => {
    return await refreshToken(data);
  }
);

export const getUserOrganizationsList = createAsyncThunk(
  "invited-org-list",
  async (orgId: number) => {
    return await getOrganizationList(orgId);
  }
);

export const checkIsUserEmailUsed = createAsyncThunk(
  "email-repeatability-check",
  async (data: {
    orgId: number;
    emailToCheck: string;
    signal: AbortSignal;
    userId: number;
  }) => {
    return await isEmailUsed(data);
  }
);

interface UsersState {
  roles: RoleList[];
  users: Users;
}

const initialState: UsersState = {
  roles: [],
  users: {
    users: [],
    total: 0,
    page_size: 10,
    pagination: {
      next: null,
      previous: null,
    },
  },
};
export const userSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userRolesList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.roles = payload;
        }
      })
      .addCase(usersList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.users = payload;
        }
      });
  },
});

export default userSlice.reducer;

// State selectors
export const rolesSelector = (state: RootState) => state.useManagement.roles;
export const usersSelector = (state: RootState) => state.useManagement.users;
