import React from "react";
import useCommonData from "../../../../../../hooks/useCommon";
import Select from "react-select";
import { OrganizationRoleValues } from "../../../../../../../types";
import { InviteUserRoleCustomStyle } from "../../../../../../common/components/InviteUserRoleCustomStyle";

type Props = {
  userId: number;
  isSuperUser: boolean;
  index: number;
  item: OrganizationRoleValues;
  roleInputChangeHandler: (e: any, index: number) => void;
};
const RoleSelector = (props: Props) => {
  const { currentUserInfo } = useCommonData();

  const getRoleValue = (item: any) => {
    if (item.role_id === 0) {
      return "";
    } else if (item.role_id === 1) {
      return {
        label: "Admin",
        value: item.role_id,
      };
    } else {
      let labelValue = item.role_list.filter(
        (role: any) => item.role_id === role.id
      );
      return {
        label: labelValue[0]?.name,
        value: item.role_id,
      };
    }
  };

  const roleOptions = (item: any) => {
    return item.role_list.map((roleItem: any) => ({
      label: roleItem.name,
      value: roleItem.id,
    }));
  };

  return (
    <div
      className="form-outline mb-2 position-relative role-select"
      style={
        props.userId > 0 &&
        props.userId === currentUserInfo.id &&
        (props.isSuperUser ? (props.index === 0 ? true : false) : true)
          ? { pointerEvents: "none", opacity: "0.7" }
          : {}
      }
    >
      <label className="form-label mb-0 required" htmlFor="role">
        Role
      </label>
      <Select
        name="role_id"
        value={getRoleValue(props.item)}
        options={roleOptions(props.item)}
        placeholder="Choose role"
        onChange={(e) => props.roleInputChangeHandler(e, props.index)}
        className="form-select custom-select role-select"
        styles={InviteUserRoleCustomStyle}
      />
    </div>
  );
};

export default RoleSelector;
