import { FormEventHandler } from "react";

type FormProps = {
  children: React.ReactNode;
  name?: string;
  tabIndex?: number;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  className?: string;
  id?: string;
  onReset?: FormEventHandler<HTMLFormElement>;
};
export const Form = (props: FormProps) => {
  return (
    <form
      id={props.id}
      className={props.className}
      onSubmit={props.onSubmit}
      onReset={props.onReset}
    >
      {props.children}
    </form>
  );
};
