import React from "react";
import { Link } from "react-router-dom";
import { ResendInvite } from "../../../../assets/images";
import { toast } from "react-toastify";
import { resendInvitationEmail } from "../../../home/userManagement/userSlice";
import useCommonData from "../../../hooks/useCommon";
type Props = {
  userId: number;
};
const ResendUserInvite = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  /**
   * resend invitation mail handler
   */
  const resendInvitationHandler = async (userId: number) => {
    const responseAction = await dispatch(
      resendInvitationEmail({
        id: userId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      toast.success("Invitation resend successfully", {
        toastId: "resend-invite-success",
        closeButton: false,
        position: "top-center",
      });
    }
  };
  return (
    <Link
      className="dropdown-item invite-again-user"
      to="#"
      onClick={() => resendInvitationHandler(props.userId)}
    >
      <img src={ResendInvite} alt="invite-again-img" />
      Resend
    </Link>
  );
};

export default ResendUserInvite;
