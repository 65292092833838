import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import { listboxCustomStyle } from "../../../../common/components/ListBoxCustomStyle";
import ListHeader from "./ListHeader";
import useListData from "../../../../hooks/useListData";
import PaginationContainer from "../../../items/components/list/PaginationContainer";
import TdsTableContainer from "./TdsTable/TdsTableContainer";
import DeleteTds from "../Delete";

const List = () => {
  const { itemsPerPage, totalList } = useListData();
  const [tdsId, setTdsId] = useState(0);
  const [taxName, setTaxName] = useState("");

  const listRef = useRef<any>([]);
  const refreshList = () => {
    listRef.current.fetchTdsList();
  };
  const deleteListData = (tdsId: number, taxName: string) => {
    setTdsId(tdsId);
    setTaxName(taxName);
  };
  return (
    <>
      <Box component="div" sx={listboxCustomStyle.outerBox}>
        <ListHeader refreshList={refreshList} />
        <TdsTableContainer
          ref={listRef}
          deleteListData={deleteListData}
          refreshList={refreshList}
        />
        {totalList > itemsPerPage ? <PaginationContainer /> : ""}
      </Box>
      <DeleteTds tdsId={tdsId} taxName={taxName} refreshList={refreshList} />
    </>
  );
};

export default List;
