import React from "react";
import "../../../../index.css";
import "./Button.css";

type ButtonProps = {
  type: "submit" | "reset" | "button";
  isDisabled: boolean;
  children: string |  React.ReactNode;
  onClick?: (e: any) => void;
  buttonClassName?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};
export const PrimaryButton = (props: ButtonProps) => {
  return (
    <button
      className={`save-button ${props.buttonClassName}`}
      type={props.type}
      disabled={props.isDisabled}
      style={props.isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
      onClick={props.onClick}
    >
      <span className="button-start-icon">
        {props.startIcon ? props.startIcon : ""}
      </span>
      {props.children}{" "}
      <span className="button-end-icon">
        {props.endIcon ? props.endIcon : ""}
      </span>
    </button>
  );
};
