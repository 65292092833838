import React, { useEffect, useState } from "react";
import "../styles/HorizontalTab.css";
type TabProps = {
  tabHeaders: string[];
  tabBody: React.ReactNode[];
  wrapperClass?: string;
  disableRerender?: boolean;
  tabBodyWrapperClass?: string;
};
export const HorizontalTab = (props: TabProps) => {
  const [activeTab, setActiveTab] = useState(props.tabHeaders[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // !props.disableRerender &&
    setActiveTab(props.tabHeaders[0]);
    const storedTab = sessionStorage.getItem("activeTransactionTab");
    const storedTabIndex = sessionStorage.getItem("activeTransactionTabIndex");
    if (storedTab && props.tabHeaders.includes(storedTab)) {
      setActiveTab(storedTab);
      setActiveIndex(Number(storedTabIndex));
    }
  }, [props.tabHeaders]);

  const handleTabClick = (tab: string, index: number) => {
    setActiveTab(tab);
    setActiveIndex(index);
    sessionStorage.setItem("activeTransactionTab", tab);
    sessionStorage.setItem("activeTransactionTabIndex", index.toString());
  };

  return (
    <div
      className={`tab-container w-100 px-0 ${
        props.wrapperClass ? props.wrapperClass : ""
      }`}
      id="horizontalTab"
    >
      <ul
        className="nav nav-pills pills-tab mt-0"
        id="pills-tab"
        role="tablist"
      >
        {props.tabHeaders.map((tab, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={activeTab === tab ? "nav-link active" : "nav-link"}
              id="pills-overview-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-overview"
              type="button"
              role="tab"
              aria-controls="pills-overview"
              aria-selected="true"
              onClick={() => handleTabClick(tab, index)}
            >
              {tab}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content pb-0" id="pills-tabContent">
        <div
          className={"tab-pane fade show active"}
          id="pills-overview"
          role="tabpanel"
          aria-labelledby="pills-overview-tab"
          tabIndex={0}
        >
          <div
            className={`tab-content-body ${
              props.tabBodyWrapperClass ? props.tabBodyWrapperClass : ""
            }`}
          >
            {props.tabBody[activeIndex]}
          </div>
        </div>
      </div>
    </div>
  );
};
