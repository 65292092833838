import React, { useEffect, useState } from "react";
import { PaymentTermsForm } from "./paymentTermsForm/paymentTermsForm";
import { PaymentTermValue } from "../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  currentUserSelector,
  paymentTermsList,
  paymentTermsSelector,
} from "../../commonSlice";
import "./paymentTerms.css";
export const PaymentTerms = () => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const paymentTerms = useAppSelector(paymentTermsSelector);
  const [newPTFields, setNewPTFields] = useState<PaymentTermValue[]>([]);
  const [isNewPaymentTermAdded, setNewPaymentTermAdded] = useState(false);

  useEffect(() => {
    getPaymentTermsHandler();
  }, [currentUserInfo.organization_id]);

  /**
   * Get payment terms list
   */
  const getPaymentTermsHandler = async () => {
    const responseAction = await dispatch(
      paymentTermsList(currentUserInfo.organization_id)
    );
  };

  /**
   * Adding new payment term field
   */
  const addNewPaymentTermsField = () => {
    setNewPTFields([...newPTFields, { term_name: "", term_days: "" }]);
    setNewPaymentTermAdded(true);
  };

  /**
   * Removing new payment term field
   */
  const removeField = (index: number) => {
    const updatedFields = [...newPTFields];
    updatedFields.splice(index, 1);
    setNewPTFields(updatedFields);
    setNewPaymentTermAdded(false);
  };

  return (
    <div className="payment-terms-list-wrapper">
      <div>
        {" "}
        <button
          className="add-new add-new-payment-terms"
          id="addNewButtonPaymentTerms"
          onClick={addNewPaymentTermsField}
          disabled={isNewPaymentTermAdded ? true : false}
        >
          Add New Payment Terms
        </button>
      </div>
      <div className="edit-form-wrap edit-payment-term-form-wrap">
        <div className="row title-row g-0">
          <div className="col">
            <span className="title term-title left-align">Term name</span>
          </div>
          <div className="col">
            <span className="title left-align">no. of days</span>
          </div>
          <div className="col">
            <span className="title right-align ">Action</span>
          </div>
        </div>
        <div className="tbody-container">
          {newPTFields.map((fields, index) => {
            return (
              <div key={index}>
                <PaymentTermsForm
                  values={fields}
                  removeField={() => removeField(index)}
                />
              </div>
            );
          })}
          {paymentTerms.map((terms, index) => {
            return (
              <div key={index}>
                <PaymentTermsForm
                  values={{
                    term_days: terms.term_days,
                    term_name: terms.term_name,
                  }}
                  id={terms.id}
                  is_mutable={terms.is_mutable}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
