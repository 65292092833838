import React from "react";
import ExpenseAccountSelect from "./ExpenseAccountSelect";
import { zeroDisplayFormat } from "../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../hooks/useCommon";
import InputPrefix from "../formPrefixInputField/InputPrefix";
import "./PaymentFormInputs.css";
import { FormikProps } from "formik";

type Props = {
  bankChargesAccountId: string | number | null;
  handleBankCharge: (e: any) => void;
  currencyCode: string | undefined;
  handleChange: (e: any) => void;
  handleItemOnBlur: (name: string, amount: number, index: number) => void;
  bankChargesAmount: string | number;
  formErrors: any;
  classWidth: string;
  isBankCurrency?: boolean;
  disabled?: boolean;
  bankChargeConvertedAmount?: string;
  conversionCurrencyCode?: string;
  formik?: FormikProps<any>;
};
function BankCharges(props: Props) {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  return (
    <>
      <label htmlFor="bank_charges">Bank Charges (if any)</label>
      <div className="form-group">
        <label htmlFor="payment_method" className="hidden">
          Bank Charges (if any)
        </label>
        <label htmlFor="charged_amt" className="hidden">
          Bank Charges (if any)
        </label>
        <ExpenseAccountSelect
          bankChargesAccountId={props.bankChargesAccountId}
          handleBankCharge={(e) => {
            if (!e) {
              props.formik &&
                props.formik.setValues({
                  ...props.formik.values,
                  bank_charges_account_id: null,
                  bank_charges_account_name: "",
                  bank_charges_amount: 0,
                });
              props.formik &&
                props.formik.setErrors({
                  ...props.formik.errors,
                  bank_charges_account_id: undefined,
                  bank_charges_amount: undefined,
                });
            } else {
              props.formik &&
                props.formik.setFieldValue(
                  "bank_charges_account_id",
                  e && e.value
                );
              props.formik &&
                props.formik.setFieldValue(
                  "bank_charges_account_name",
                  e && e.label
                );
              props.handleBankCharge(e);
            }
          }}
          error={
            (props.formErrors?.bank_charges_account_id &&
              props.formErrors?.bank_charges_account_id) ||
            ""
          }
          wrapperClass={props.classWidth}
          disabled={props.disabled ? props.disabled : false}
          isClearable={true}
          isSearchable={true}
        />
        <InputPrefix
          prefix={props.currencyCode ? props.currencyCode : ""}
          inputType="text"
          name={"bank_charges_amount"}
          id="charged_amt"
          className="form-control border-end-0"
          wrapperClass={`inv-group d-flex ${props.classWidth}`}
          isAmountField={true}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.currencyCode
                ? props.currencyCode
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          value={props.bankChargesAmount ? props.bankChargesAmount : ""}
          disabled={
            props.disabled === true || !props.bankChargesAccountId
              ? true
              : false
          }
          error={
            (props.formErrors?.bank_charges_amount &&
              props.formErrors?.bank_charges_amount) ||
            ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.handleChange(e);
          }}
          onBlur={(e: any) => {
            props.handleItemOnBlur(
              "bank_charges_amount",
              Number(props.bankChargesAmount),
              0
            );
          }}
          onKeyPress={handleKeyPress}
        />
      </div>
      {props.conversionCurrencyCode && props.bankChargeConvertedAmount && Number(props.bankChargeConvertedAmount)!==0 ? (
        <div className="bank-charges-converted-amount w-100 sub-label mt-1">{`Bank charges: ${props.conversionCurrencyCode} ${props.bankChargeConvertedAmount}`}</div>
      ) : (
        <></>
      )}
      {
        <>
          <span className="error">
            {(props.formErrors?.bank_charges_amount &&
              props.formErrors?.bank_charges_amount) ||
              ""}
          </span>
          <span className="error">
            {(props.formErrors?.bank_charges_account_id &&
              props.formErrors?.bank_charges_account_id) ||
              ""}
          </span>
        </>
      }
    </>
  );
}

export default React.memo(BankCharges);
