import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import {
  DateFormatHandler,
  getFormatedDate,
  getKeyByValue,
} from "../../../../../helpers/helper";
import {
  BillDefaultRef,
  BillDefaultValues,
  BillItemValues,
  BillValues,
  CustomerItemValues,
  FilesValues,
  ItemRef,
  ItemTaxValues,
  ObjectType,
  TCSTotalValues,
  TDSTotalValues,
  TotalAmountCalculationValues,
  TotalDetailsValues,
  TransactionItemValues,
} from "../../../../../types";
import {
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import DropZone from "../../../../common/components/DropZone/DropZone";
import ItemDetails from "../../../../common/components/lineItems/ItemDetails";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import ScrollToError from "../../../../common/components/scrollToError";
import TransactionHeader from "../../../../common/components/TransactionHeader";
import {
  gstRegistrationTypes,
  noAccessErrorCodes,
  PAYMENT_TERMS,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import {
  clearGstTaxList,
  getGstTaxList,
  getStateList,
  stateListSelector,
} from "../../../gst/gstSlice";
import {
  initialTCSTotalValues,
  initialTDSTotalValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import { vendorDetailsForTransactions } from "../../../vendorManagement/vendorSlice";
import "../../Bills.css";
import {
  attachBillFiles,
  billDetails,
  billList,
  checkBillNumber,
  createBill,
  deleteBillFile,
  deleteBillItemDetails,
  deleteBillItemTaxDetails,
  deleteBillRecipientDetails,
  editBillDetails,
  getAmountCalculation,
  getTaxableAmount,
} from "../../billsSlice";
import DeleteBill from "../Delete";
import BillFormFooter from "./BillFormFooter";
import DefaultDetails from "./DefaultDetails";
import {
  initialBillValues,
  initialDefaultValues,
  initialItemValues,
  initialTaxValues,
  initialTotalValues,
} from "./StateInitialization";
import { validate, validateItemValues } from "./ValidateBillForm";

import { ErrorToaster } from "../../../../../helpers/toastHelper";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import useGst from "../../../../hooks/useGst";
import { TransactionEditTagRemovalAlertModal } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";
import { purchaseOrderDetails } from "../../../purchaseOrder/purchaseOrderSlice";
import { downloadFile } from "../../../../../helpers/fileDownloader";

type LocationProps = {
  redirectTo?: string;
};
let interval: any = null;
function BillForm() {
  const location = usePreservedLocation();
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const purchaseOrderId = searchParams.get("purchaseOrderId");
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { billsRolePermission } = usePermissionHandler();
  const defaultRef = useRef<BillDefaultRef | null>(null);
  const itemRef = useRef<ItemRef | null>(null);
  const fileRef = useRef<any>([]);
  const locationState = location?.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const tdsPreference = useAppSelector(tdsPreferenceSelector);

  const [billFormValues, setBillFormValues] =
    useState<BillValues>(initialBillValues);
  const [defaultFormValues, setDefaultFormValues] = useState<BillDefaultValues>(
    JSON.parse(JSON.stringify(initialDefaultValues))
  );
  const [itemFormValues, setItemFormValues] = useState<BillItemValues[]>([
    initialItemValues,
  ]);
  const [taxFormValues, setTaxFormValues] = useState<BillItemValues[]>([
    initialItemValues,
  ]);
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDetailsValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [itemFormErrors, setItemFormErrors] = useState<BillItemValues[]>([]);
  const [emailFormErrors, setEmailFormErrors] = useState("");
  const [isBillNumberExist, setIsBillNumberExist] = useState(false);
  const [statesFlag, setStatesFlag] = useState<boolean>(false);
  const [isVendorName, setIsVendorName] = useState<boolean>(false);
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [vendorId, setVendorId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emaiId: number }[]>(
    []
  );
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [deleteBillItemId, setDeleteBillItemId] = useState("");
  const [deleteBillId, setDeleteBillId] = useState(-1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const [formSubmit, setFormSubmit] = useState(true);
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [conversionDate, setConversionDate] = useState(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [billStatus, setBillStatus] = useState("");
  const [vendorIdForEdit, setVendorIdForEdit] = useState();
  const [defaultValuesForEdit, setDefaultValuesForEdit] =
    useState<BillValues>(initialBillValues);
  const [conversionCurrencyId, setConversionCurrencyId] = useState<number>();
  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [sourceTaxType, setSourceTaxType] = useState(sourceTaxTypes.TCS);
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [isTotalEditable, setIsTotalEditable] = useState(false);
  const [editTotal, setEditTotal] = useState(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);

  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [unableToSaveModalTriggered, setUnableToSaveModalTriggered] =
    useState<number>();
  const [gstRegistrationType, setGstRegistrationType] = useState("");
  const isFileUploading = useRef(false);
  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();
  const unableToSaveModalRef = useRef<any>();
  const updateExchangeRateModalRef = useRef<any>();
  const { isGstOrg } = useGst();
  const stateList = useAppSelector(stateListSelector);
  const [isVendorChanged, setIsVendorChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const openEditAlertRef = useRef<any>();
  const [vendorPurchaseDefault, setVendorPurchaseDefault] = useState(null);
  const [rcmEnabled, setRcmEnabled] = useState<boolean>(false);
  const [purchaseOrderFileIds, setPurchaseOrderFileIds] = useState<number[]>(
    []
  );
  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId && !purchaseOrderId) {
      getRoundOffPreference();
    }
    if (purchaseOrderId) {
      getPODetails(Number(purchaseOrderId));
    }
    if (editId) {
      billDetailsResponse();
    }
    if (defaultFormValues.vendor_id) {
      getVendorDetails(Number(defaultFormValues.vendor_id));
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0 && files.status !== "removed"
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    unableToSaveModalTriggered &&
      unableToSaveModalRef.current?.openAlertModal &&
      unableToSaveModalRef.current?.openAlertModal();
  }, [unableToSaveModalTriggered]);

  const reUploadPOFilesToBills = async (files: any) => {
    isFileUploading.current = true; // Stop the loader after all files are processed
    dispatch(setLoaderState(true));
    try {
      for (const file of files) {
        const fileBlob = await downloadFile(file.download_link, file.file_name); // Download file
        await uploadAttachedFiles(fileBlob); // Upload file
      }
    } catch (error) {
      console.error("Error processing files:", error);
    } finally {
      isFileUploading.current = false;
      dispatch(setLoaderState(false));
      // Stop the loader after all files are processed
    }
  };
  const getPODetails = async (purchaseOrderId: number) => {
    dispatch(setLoaderState(true));
    isFileUploading.current = true;
    const responseAction = await dispatch(
      purchaseOrderDetails({
        orgId: currentUserInfo.organization_id,
        purchaseOrderId: purchaseOrderId,
      })
    );
    const response = responseAction.payload;
    const isSameOrg =
      response.organization_id === currentUserInfo.organization_id;
    const isLocked = response.is_locked;
    if (Object.keys(response).length && !("error" in response)) {
      let defaultValues = {
        vendor_id: response.vendor_id,
        vendor_name: response.vendor_name,
        bill_number: defaultRef.current?.billNumber || "",
        reference_number: response.reference_number,
        bill_date: getFormatedDate(),
        payment_term_id: 1,
        due_date: getFormatedDate(),
        tag_ids: [],
        currency_id: response.currency_id,
        tds_level: response.tds_level,
        source_tax_type: response.source_tax_type,
        source_of_supply: {
          label: response.source_of_supply,
          value: response.source_of_supply,
        },
        destination_of_supply: {
          label: response.destination_of_supply,
          value: response.destination_of_supply,
        },
        bill_status: "Open",
        is_mutable: response.is_mutable,
        rcm_enabled: response.rcm_enabled ? response.rcm_enabled : false,
        conversion_id: response.conversion_id,
      };

      let stateType =
        defaultValues.source_of_supply.value ===
        defaultValues.destination_of_supply.value
          ? "Intra State"
          : "Inter State";
      setInitialStateType(stateType);
      setRcmEnabled(response.rcm_enabled);
      let vendorDetails = await getVendorDetails(Number(response.vendor_id));

      if (isGstOrg) {
        let transaction_tax_details = [] as any;
        response.item_details.forEach((item: any) => {
          let gst_tax = item.gst_tax;
          if (gst_tax?.tax_id) {
            transaction_tax_details.push({
              is_group: gst_tax?.is_group,
              tax_id: gst_tax?.tax_id,
            });
          }
        });
        let state_type =
          !defaultValues.source_of_supply ||
          !defaultValues.destination_of_supply
            ? ""
            : defaultValues.source_of_supply.value ===
              defaultValues.destination_of_supply.value
            ? "Intra State"
            : "Inter State";
        setStateType(state_type);
        if (
          vendorDetails.gst_registration_type === gstRegistrationTypes.sez ||
          vendorDetails.gst_registration_type ===
            gstRegistrationTypes.sezDeveloper ||
          vendorDetails.gst_registration_type === gstRegistrationTypes.overseas
        ) {
          state_type = "Inter State";
        }

        let stateCode = await getStateCode(
          defaultValues.destination_of_supply.value
        );

        await fetchGstTaxList(
          state_type,
          vendorDetails.gst_registration_type,
          transaction_tax_details,
          stateCode
        );
        setGstRegistrationType(vendorDetails.gst_registration_type);
        setVendorPurchaseDefault(vendorDetails.purchase_default);
      }

      setSourceTaxType(response.source_tax_type);
      let otherValues = {
        vendor_notes: response.vendor_notes,
        file_ids: [],
        tag_ids: [],
        items: response.item_details,
        email_to: response.email_to,
        conversion_id: response.conversion_id,
        currency_id: response.currency_id,
        tds_percentage: response.total_details.tds_percentage,
      };
      let totalTdsValue = {
        tds_id: response.tds_id,
        tds_account_id: response.tds_account_id,
        tds_percentage: response.tds_percentage,
        tds_amount: response.tds_amount,
        tds_name: response.tds_name,
      };
      let totalTcsValue = {
        tcs_id: response.tcs_id,
        tcs_account_id: response.tcs_account_id,
        tcs_percentage: response.tcs_percentage,
        tcs_amount: response.tcs_amount,
        tcs_name: response.tcs_name,
      };
      setTotalTdsValues(totalTdsValue);
      setTotalTcsValues(totalTcsValue);
      setConversionDate(getFormatedDate());
      setCurrencyId(response.currency_id);
      setCustomFields(response.custom_fields);
      custom_fields.current = response.custom_fields;
      setConversionCurrencyId(response.currency_id);
      setUploadedFiles(response.purchase_order_files);
      setFileIds(response.purchase_order_files?.map((file: any) => file.id));
      setInsertedFiles(
        response.purchase_order_files?.map((file: any) => file.id)
      );
      await reUploadPOFilesToBills(response.purchase_order_files);

      // response.purchase_order_files?.map(async (file: any) => {
      //   setFileUploading(true);

      //   let files = await downloadFile(file.download_link, file.file_name);
      //   const fileResponseAction = await uploadAttachedFiles(files).finally(
      //     () => {
      //       setFileUploading(false);
      //     }
      //   );
      // });
      setBillStatus("Open");
      setVendorIdForEdit(response.vendor_id);
      setDefaultValuesForEdit({ ...defaultValues, ...otherValues });

      if (response.payment_term_id === null) {
        setIsCustom(true);
      }
      setCurrencyCode(response.currency_code);
      setDefaultFormValues({ ...defaultValues });
      setBillFormValues({ ...defaultValues, ...otherValues });

      const preferenceResponse = await dispatch(
        fetchRoundOffPreference({
          orgId: currentUserInfo.organization_id,
          roundoff_transaction_type: "Purchases",
        })
      );
      let preferencePayload = preferenceResponse.payload;

      setIsRoundOffEnabled((prevValue) => {
        return response?.roundoff_enabled || preferencePayload.is_enabled
          ? true
          : false;
      });

      setIsTotalEditable((prevValue) => {
        if (response?.roundoff_enabled || preferencePayload.is_enabled) {
          if (response?.roundoff_enabled) {
            return response?.is_total_editable;
          } else {
            return preferencePayload.is_total_editable;
          }
        } else {
          return false;
        }
      });

      let itemDetails = response.item_details;
      let tdsIdArray: number[] = [];
      itemDetails.map((item: any, index: number) => {
        if (item.discount_type === "Absolute") {
          item.discount = item.discount.discount_value;
        } else if (item.discount_type === "Percentage") {
          item.discount = item.discount.discount_percentage;
        }
        if (!item.taxes.length) {
          item.taxes = [initialTaxValues];
        }
        if (item.tds_id) {
          tdsIdArray.push(item.tds_id);
        }
      });
      if (itemDetails.length) {
        setItemFormValues([...itemDetails]);
        setTaxFormValues(itemDetails);
      } else {
        setItemFormValues([initialItemValues]);
        setTaxFormValues([initialItemValues]);
      }
      if (response.tds_id) {
        tdsIdArray.push(response.tds_id);
      }
      setTdsIds(tdsIdArray);
      let tcsIdArray: number[] = [];
      if (response.tcs_id) {
        tcsIdArray.push(response.tcs_id);
      }
      setTcsIds(tcsIdArray);
      let totalDetails = response.total_details;
      setTotalFormValues(totalDetails);
      let emailContacts = response.email_to;
      let contactEmail: string[] = [];
      let emailDetails: any[] = [];
      emailContacts.map((contact: any) => {
        contactEmail.push(contact.email);
        emailDetails.push(contact);
      });
      setEmailLists(contactEmail);
      setEmailData(emailDetails);
      setVendorId(response.vendor_id);
      setEditTotal(response?.total);
    } else {
      if (isLocked) {
        navigate(-1);
        ErrorToaster(
          `Transactions before ${DateFormatHandler(
            response.lock_date
          )} have been locked. Hence action cannot be performed`
        );
      } else {
        dispatch(setErrorID(noAccessErrorCodes.billEdit));
      }
    }
  };
  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
        roundoff_transaction_type: "Purchases",
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  /**
   * Get bill details for the edit bill purpose.
   */
  const billDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      billDetails({
        billId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      const isLocked = response.is_locked;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg &&
        !isLocked
      ) {
        let defaultValues = {
          vendor_id: response.vendor_id,
          vendor_name: response.vendor_name,
          bill_number: response.bill_number,
          reference_number: response.reference_number,
          bill_date: getFormatedDate(response.bill_date),
          payment_term_id: response.payment_term_id,
          due_date: getFormatedDate(response.due_date),
          tag_ids: response.tags.map((tag: any) => tag.id),
          currency_id: response.currency_id,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          source_of_supply: {
            label: response.source_of_supply,
            value: response.source_of_supply,
          },
          destination_of_supply: {
            label: response.destination_of_supply,
            value: response.destination_of_supply,
          },
          bill_status: response.bill_status,
          is_mutable: response.is_mutable,
          rcm_enabled: response.rcm_enabled ? response.rcm_enabled : false,
          conversion_id: response.conversion_id,
        };
        let stateType =
          defaultValues.source_of_supply.value ===
          defaultValues.destination_of_supply.value
            ? "Intra State"
            : "Inter State";
        setInitialStateType(stateType);
        setRcmEnabled(response.rcm_enabled);
        let vendorDetails = await getVendorDetails(Number(response.vendor_id));

        if (isGstOrg) {
          let transaction_tax_details = [] as any;
          response.item_details.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group,
                tax_id: gst_tax?.tax_id,
              });
            }
          });
          let state_type =
            !defaultValues.source_of_supply ||
            !defaultValues.destination_of_supply
              ? ""
              : defaultValues.source_of_supply.value ===
                defaultValues.destination_of_supply.value
              ? "Intra State"
              : "Inter State";
          setStateType(state_type);
          if (
            vendorDetails.gst_registration_type === gstRegistrationTypes.sez ||
            vendorDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            vendorDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            state_type = "Inter State";
          }

          let stateCode = await getStateCode(
            defaultValues.destination_of_supply.value
          );

          await fetchGstTaxList(
            state_type,
            vendorDetails.gst_registration_type,
            transaction_tax_details,
            stateCode
          );
          setGstRegistrationType(vendorDetails.gst_registration_type);
          setVendorPurchaseDefault(vendorDetails.purchase_default);
        }
        setSourceTaxType(response.source_tax_type);
        let otherValues = {
          vendor_notes: response.vendor_notes,
          file_ids: response.bill_files.map((file: any) => file.id),
          tag_ids: response.tags.map((tag: any) => tag.id),
          items: response.item_details,
          email_to: response.email_to,
          conversion_id: response.conversion_id,
          currency_id: response.currency_id,
          tds_percentage: response.total_details.tds_percentage,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name,
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name,
        };
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setConversionDate(getFormatedDate(response.bill_date));
        setCurrencyId(response.currency_id);
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setConversionCurrencyId(response.currency_id);
        setUploadedFiles(response.bill_files);
        setFileIds(response.bill_files.map((file: any) => file.id));
        setInsertedFiles(response.bill_files.map((file: any) => file.id));
        setBillStatus(response.bill_status);
        setVendorIdForEdit(response.vendor_id);
        setDefaultValuesForEdit({ ...defaultValues, ...otherValues });

        if (response.payment_term_id === null) {
          setIsCustom(true);
        }
        setCurrencyCode(response.currency_code);
        setDefaultFormValues({ ...defaultValues });
        setBillFormValues({ ...defaultValues, ...otherValues });

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Purchases",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });

        let itemDetails = response.item_details;
        let tdsIdArray: number[] = [];
        itemDetails.map((item: any, index: number) => {
          if (item.discount_type === "Absolute") {
            item.discount = item.discount.discount_value;
          } else if (item.discount_type === "Percentage") {
            item.discount = item.discount.discount_percentage;
          }
          if (!item.taxes.length) {
            item.taxes = [initialTaxValues];
          }
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (itemDetails.length) {
          setItemFormValues([...itemDetails]);
          setTaxFormValues(itemDetails);
        } else {
          setItemFormValues([initialItemValues]);
          setTaxFormValues([initialItemValues]);
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
        }
        setTdsIds(tdsIdArray);
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
        }
        setTcsIds(tcsIdArray);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.email_to;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setVendorId(response.vendor_id);
        setEditTotal(response?.total);
      } else {
        if (isLocked) {
          navigate(-1);
          ErrorToaster(
            `Transactions before ${DateFormatHandler(
              response.lock_date
            )} have been locked. Hence action cannot be performed`
          );
        } else {
          dispatch(setErrorID(noAccessErrorCodes.billEdit));
        }
      }
    }
  };

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [billFormValues, statesFlag]);

  /**
   * Check bill number already exist
   */
  const checkBillNumberExist = async (billNumber: string) => {
    if (!billNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkBillNumber({
            billNumber: billNumber,
            orgId: currentUserInfo.organization_id,
            billId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.bill_number_available === false) {
              setIsBillNumberExist(true);
              setFormErrors({
                ...formErrors,
                ["bill_number"]:
                  "Bill number already exists. Please choose a different one",
              });
            } else {
              setIsBillNumberExist(false);
              setFormErrors({ ...formErrors, ["bill_number"]: "" });
            }
          }
        }
      }, 1000);
    }
  };
  /**
   * Set bill default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBillFormValues({ ...billFormValues, [name]: value });
  };
  /**
   * Function to handle change in vendor to fetch vendor details
   */
  const handleVendorChange = async (e: any) => {
    if (e.value && e.value !== vendorIdForEdit) {
      setIsVendorChanged(true);
      let response = await getVendorDetails(Number(e.value));
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }

      if (state_type !== "") {
        await fetchGstTaxList(state_type, response.gst_registration_type);
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsVendorName(true);
      setVendorId(Number(e.value));
      setVendorPurchaseDefault(response.purchase_default);
      setDefaultFormValues((previousValues: any) => {
        return {
          ...previousValues,
          payment_term_id: response.purchase_default.payment_term_id,
        };
      });
    } else {
      setBillFormValues(defaultValuesForEdit);
      setCurrencyId(Number(defaultValuesForEdit.currency_id));
    }
  };
  /**
   * Fetch vendor details by id
   */
  const getVendorDetails = async (vendorId: number) => {
    const responseAction = await dispatch(
      vendorDetailsForTransactions({
        vendorId: vendorId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (defaultRef.current?.defaultData) {
          let defaultFormValues = {
            ...defaultRef.current?.defaultData,
            tag_ids: response.other_details.tags.map((tag: any) => tag.id),
            payment_term_id: response.other_details.payment_term_id,
            currency_id: response.other_details.currency_id,
          };
          setVendorPurchaseDefault(response.purchase_default);
          setDefaultFormValues({ ...defaultFormValues });
        }
        if (response.other_details.payment_term_id)
          defaultRef.current?.calculateDueDate(
            response.other_details.payment_term_id,
            new Date(defaultRef.current?.defaultData.bill_date)
          );
        setCurrencyId(response.other_details.currency_id);
      }
    }
    return responseAction.payload;
  };

  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };

  const asynchronousSubmit = async (status: string, action: string) => {
    await handleCustomSubmission();
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Vendors")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (itemRef.current?.totalTdsValues.tds_id || itemDetails?.length) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string) => {
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let billInputs: any = {
      ...billFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      custom_fields: custom_fields.current.values,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    let errors,
      itemErrorResponse: any = [];
    let itemErrors: any = [];
    let emailError: string = emailFormErrors ? emailFormErrors : "";

    errors = validate(
      billInputs,
      action,
      isBillNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;
    let taxValues = itemRef.current?.taxValues
      ? itemRef.current?.taxValues
      : taxFormValues;
    itemValues.map((item, itemIndex) => {
      itemErrorResponse = validateItemValues(
        item,
        itemIndex,
        taxValues,
        billInputs.rcm_enabled,
        gstRegistrationType
      );
      if (itemErrorResponse) {
        if (itemErrorResponse) {
          ScrollToError(Object.keys(itemErrorResponse)[0]);
        }
        errorExist = true;
        itemErrors.push(itemErrorResponse);
      } else {
        itemErrors.push({});
      }
    });

    if (
      Object.keys(errors).length ||
      (errorExist && itemErrors.length) ||
      customFieldRef.current.errors ||
      emailFormErrors !== ""
    ) {
      setFormErrors({
        ...errors,
        email: emailFormErrors,
      });
      setItemFormErrors(itemErrors);
    } else {
      setFormErrors({});
      setItemFormErrors([]);
      if (editId) {
        if (
          totalAmount <
          Number(totalFormValues.total) - Number(totalFormValues.balance_due)
        ) {
          setUnableToSaveModalTriggered(Math.floor(Math.random() * 1000));
        } else {
          if (editId && currentUserInfo.is_tags_enabled && !didAlertModalOpen) {
            setDidAlertModalOpen(true);
            openEditAlertRef.current?.openAlertModal &&
              openEditAlertRef.current?.openAlertModal();
            return;
          } else {
            UpdateBillSubmit(billInputs, itemValues, status, action);
          }
        }
      } else {
        createBillSubmit(billInputs, itemValues, status, action);
      }
    }
  };

  /**
   * Create new bill
   */
  const createBillSubmit = async (
    billInputs: any,
    itemValues: TransactionItemValues[],
    billStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    billInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof billInputs;
    for (key in billInputs) {
      if (billInputs[key] === "") {
        billInputs[key] = null;
      }
      if (
        key === "bill_number" &&
        billInputs[key] !== "" &&
        billInputs[key] !== null
      ) {
        billInputs[key] = defaultRef.current?.billNumber;
      }
      if (key === "tds_percentage") {
        billInputs[key] =
          billInputs[key] === "" || billInputs[key] === null
            ? 0
            : Number(billInputs[key]);
      }
      if (key === "reference_number" && billInputs[key]) {
        billInputs[key] = billInputs[key].toString().trim();
      }
      if (key === "destination_of_supply") {
        billInputs[key] = isGstOrg ? billInputs[key].value : null;
      }
      if (
        key === "source_of_supply" &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        billInputs[key] = isGstOrg ? billInputs[key].value : null;
      } else if (
        key === "source_of_supply" &&
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        billInputs[key] = null;
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        billInputs[key] = billInputs[key] === null ? 0 : billInputs[key];
      }
    }
    let billItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of billItems) {
      let itemInputs = items;

      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    billInputs.items = billItems;
    billInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      billInputs.email_to = emailLists;
    }
    billInputs.total = calculatedTotal;
    billInputs.roundoff_enabled = isRoundOffEnabled;
    billInputs.is_total_editable = isTotalEditable;

    $("#form-btn-invoice").addClass("customer-form-section-disable");
    const createBillResponseAction = await dispatch(
      createBill({
        values: {
          ...billInputs,
          purchase_order_id: purchaseOrderId || null,
          file_ids: purchaseOrderId
            ? [...purchaseOrderFileIds]
            : billInputs.file_ids,
        },
        status: billStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const createBillResponse = createBillResponseAction.payload;
    if (
      Object.keys(createBillResponse).length &&
      !("error" in createBillResponse)
    ) {
      dispatch(setLoaderState(false));
      let billId = createBillResponse.id;
      if (billStatus === "Draft") navigate(-1);
      else navigate(`/bill/detail/${billId}`, { replace: true });
      // if (redirectTo) {
      //   navigate(redirectTo);
      // } else {
      //   navigate("/bill");
      // }
      toast.success("Bill created successfully!", {
        toastId: "bill-create-success",
        closeButton: false,
        position: "top-center",
      });
      if (purchaseOrderId) {
        sessionStorage.setItem(
          "unmarkedState",
          JSON.stringify({
            purchaseOrderId: purchaseOrderId,
            isUnmarked: false,
          })
        );
      }
    } else if ("error" in createBillResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const UpdateBillSubmit = async (
    billInputs: any,
    itemValues: TransactionItemValues[],
    billStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    billInputs.conversion_id = exchangeRateValues.id;
    if (
      defaultRef.current?.defaultData?.conversion_id !== exchangeRateValues.id
    ) {
      billInputs.recalculate_exchange_rate = true;
    }
    let key: keyof typeof billInputs;
    for (key in billInputs) {
      if (billInputs[key] === "") {
        billInputs[key] = null;
      }
      if (key === "payment_term_id" && billInputs[key] !== "") {
        if (billInputs[key] === null) {
          billInputs[key] = null;
        } else {
          billInputs[key] = Number(billInputs[key]);
        }
      }
      if (
        key === "bill_number" &&
        billInputs[key] !== "" &&
        billInputs[key] !== null
      ) {
        billInputs[key] = defaultRef.current?.billNumber;
      }
      if (key === "tds_percentage") {
        billInputs[key] =
          billInputs[key] === "" || billInputs[key] === null
            ? 0
            : Number(billInputs[key]);
      }
      if (key === "reference_number" && billInputs[key]) {
        billInputs[key] = billInputs[key].toString().trim();
      }
      if (key === "destination_of_supply") {
        billInputs[key] = isGstOrg ? billInputs[key].value : null;
      }
      if (
        key === "source_of_supply" &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        billInputs[key] = isGstOrg ? billInputs[key].value : null;
      } else if (
        key === "source_of_supply" &&
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        billInputs[key] = null;
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        billInputs[key] = billInputs[key] === null ? 0 : billInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    let deleteTaxItems = itemRef.current?.deleteTaxItems;
    if (deleteTaxItems) {
      for (let item of deleteTaxItems) {
        await dispatch(
          deleteBillItemTaxDetails({
            itemId: item.itemId,
            taxId: item.taxItemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    if (isGstOrg) {
      let deleteGstItems = itemRef.current?.deleteGstItems;
      if (deleteGstItems) {
        for (let item of deleteGstItems) {
          await dispatch(
            deleteBillItemTaxDetails({
              itemId: item.itemId,
              taxId: item.id,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
    }

    let deleteItems = itemRef.current?.deleteItems;
    if (deleteItems) {
      for (let item of deleteItems) {
        let res = await dispatch(
          deleteBillItemDetails({
            billId: Number(editId),
            itemId: item.itemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emaiId);
    });

    if (deleteContactIds.length) {
      await dispatch(
        deleteBillRecipientDetails({
          billId: Number(editId),
          emailIds: deleteContactIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let billItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of billItems) {
      let itemInputs = items;

      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let key: keyof typeof itemInputs;
      for (key in itemInputs) {
        if (itemInputs[key] === "") {
          if (key === "rate" || key === "quantity") {
            itemInputs[key] = 0;
          } else {
            itemInputs[key] = null;
          }
        }
        if (key === "discount") {
          if (itemInputs[key]?.discount_value) {
            itemInputs[key] = itemInputs[key]?.discount_value;
          } else if (itemInputs[key]?.discount_percentage) {
            itemInputs[key] = itemInputs[key]?.discount_percentage;
          } else {
            itemInputs[key] = Number(itemInputs[key]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    billInputs.items = billItems;
    billInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      billInputs.email_to = emailLists;
    }
    billInputs.total = calculatedTotal;
    billInputs.roundoff_enabled = isRoundOffEnabled;
    billInputs.is_total_editable = isTotalEditable;

    const updateBillResponseAction = await dispatch(
      editBillDetails({
        id: Number(editId),
        editBillInputs: {
          ...billInputs,
          purchase_order_id: purchaseOrderId || null,
        },
        status: billStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateBillResponse = updateBillResponseAction.payload;
    if (
      Object.keys(updateBillResponse).length &&
      !("error" in updateBillResponse)
    ) {
      // if (redirectTo) {
      //   navigate(redirectTo);
      // } else {
      //   navigate("/bill");
      // }
      // navigate(-1);
      if (billStatus === "Draft") navigate(-1);
      else {
        redirectUrl
          ? navigate(`/bill/detail/${Number(editId)}?redirect=${redirectUrl}`, {
              replace: true,
            })
          : navigate(`/bill/detail/${Number(editId)}`, { replace: true });
      }
      toast.success("Bill updated successfully!", {
        toastId: "bill-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in updateBillResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };
  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  /**
   * Function to store emailIds to be deleted in Edit invoice
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emaiId: contact.id },
        ]);
      }
    });
  };
  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    const responseAction = await dispatch(getAmountCalculation(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: CustomerItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getTaxableAmount(data));
    return taxResponseAction;
  };
  /**
   * Upload selected files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("bill_file", file);
    let responseAction = await dispatch(
      attachBillFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (response && purchaseOrderFileIds) {
      let ids = purchaseOrderFileIds;
      ids.push(response.file_id);
      setPurchaseOrderFileIds(ids);
    }
    return responseAction;
  };
  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteBillFile({
          billId: Number(editId),
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let billInputs: any = {
      ...billFormValues,
      ...defaultValues,
    };
    let errors = validate(
      billInputs,
      "",
      isBillNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  const fetchBillListAfterDelete = async () => {
    const responseAction = await dispatch(
      billList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        vendorList: [],
        orgIds: [],
        statusList: [],
      })
    );
    // if (redirectTo) {
    //   navigate(redirectTo);
    // } else {
    //   navigate("/bill");
    // }
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };

  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };

  const setErrorOnItemTaxChange = (errors: any) => {
    setItemFormErrors([...errors]);
  };

  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };

  const getStateCode = async (stateName: string) => {
    let stateCode = "";
    if (Object.keys(stateList).length === 0) {
      const result = await dispatch(
        getStateList({ orgId: currentUserInfo.organization_id })
      );
      let states = result.payload;
      stateCode = getKeyByValue(states, stateName);
    } else {
      stateCode = getKeyByValue(stateList, stateName);
    }
    return stateCode;
  };

  const fetchGstTaxList = async (
    state_type: string,
    gstRegType: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }],
    stateCode?: string
  ) => {
    dispatch(clearGstTaxList());

    if (state_type === "Intra State" && !stateCode) {
      if (defaultRef.current?.defaultData.destination_of_supply?.value) {
        stateCode = await getStateCode(
          defaultRef.current?.defaultData.destination_of_supply?.value as string
        );
      } else if (defaultRef.current?.defaultData.source_of_supply?.value) {
        stateCode = await getStateCode(
          defaultRef.current?.defaultData.source_of_supply?.value as string
        );
      }
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      state_type = "Inter State";
    }
    dispatch(clearGstTaxList());

    const result = await dispatch(
      getGstTaxList({
        stateType: state_type,
        orgId: currentUserInfo.organization_id,
        transactionSource: "Bills",
        stateCode,
        transaction_tax_details,
      })
    );
  };

  const getTotalValues = (total: number | string) => {
    setTotalAmount(Number(total));
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };

  const handleEmailError = (error: string) => {
    // setFormErrors({ email: error });
    // setFormErrors({
    //   ...formErrors,
    //   ["email"]: error,
    // });
    setEmailFormErrors(error);
  };
  const rcmChangeHandler = (isRcmEnable: boolean) => {
    setRcmEnabled(isRcmEnable);
  };
  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 card-bill-management"
        id="invoice-create"
      >
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Bill"
            transactionNumber={Number(editId)}
          />
        </div>
        <div className="card-body">
          <form
            name="add_invoice_form"
            id="add_invoice_form"
            className={`cn-form add-module-item-form ${
              defaultRef.current?.defaultData?.vendor_id ? "" : " inactive"
            }`}
            tabIndex={-1}
          >
            <div className="fields-wrapper pt-0">
              <div className="fields-wrappers">
                <DefaultDetails
                  defaultFormValues={defaultFormValues}
                  billFormValues={billFormValues}
                  handleVendorChange={handleVendorChange}
                  checkBillNumberExist={checkBillNumberExist}
                  formErrors={formErrors}
                  handleNewConversionDate={handleNewConversionDate}
                  ref={defaultRef}
                  customFieldRef={customFieldRef}
                  customFieldValue={customFields}
                  getCustomFieldData={getCustomFieldData}
                  setStateType={setStateType}
                  fetchGstTaxList={fetchGstTaxList}
                  setSupplyState={setSupplyState}
                  initialStateType={initialStateType}
                  gstRegistrationType={gstRegistrationType}
                  setIsStateChanged={setIsStateChanged}
                  setRCMEnable={rcmChangeHandler}
                />
                <ItemDetails
                  formValues={billFormValues}
                  itemFormValues={itemFormValues}
                  totalFormValues={totalFormValues}
                  formErrors={formErrors}
                  itemFormErrors={itemFormErrors}
                  currencyId={currencyId}
                  currencyCode={currencyCode}
                  amountCalculation={amountCalculation}
                  taxableAmount={taxableAmount}
                  isHsnCode={true}
                  taxType="receivable"
                  lineItemBaseAccount="Expenses~Assets~Liabilities"
                  discountBaseAccount="Incomes"
                  conversionDate={conversionDate}
                  newConversionDate={newConversionDate}
                  conversionCurrencyId={conversionCurrencyId}
                  userId={vendorId}
                  componentType={"bill"}
                  setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                  totalTdsValues={totalTdsValues}
                  totalTcsValues={totalTcsValues}
                  sourceTaxType={sourceTaxType}
                  tdsIds={tdsIds}
                  tcsIds={tcsIds}
                  ref={itemRef}
                  stateType={stateType}
                  supplyState={supplyState}
                  getTotalValue={getTotalValues}
                  gstRegistrationType={gstRegistrationType}
                  isRoundOffEnabled={isRoundOffEnabled}
                  isTotalEditable={isTotalEditable}
                  editTotal={editTotal}
                  updateCalculatedTotal={updateCalculatedTotal}
                  isCustomerVendorChanged={isVendorChanged}
                  isStateChanged={isStateChanged}
                  consumerId={vendorId}
                  consumerTradeDefault={vendorPurchaseDefault}
                  rcmEnabled={rcmEnabled}
                >
                  <NoteTextArea
                    label="Notes"
                    name="vendor_notes"
                    id="customerNotes"
                    className="notes-textarea"
                    placeholder="Enter notes here..."
                    value={billFormValues.vendor_notes}
                    onChange={handleChange}
                    formErrors={formErrors}
                  />
                </ItemDetails>
                <section id="upload_section">
                  <div className="upload-header pb-3">Attach Files</div>
                  <DropZone
                    uploadedFiles={uploadedFiles}
                    uploadFileIds={fileIds}
                    uploadAttachedFiles={uploadAttachedFiles}
                    removeFile={removeFile}
                    handleSubmitAction={handleSubmitAction}
                    ref={fileRef}
                  />
                  <span className="error">{formErrors.attachFiles}</span>
                </section>
                <div className="email-reciepients-wrapper">
                  <label htmlFor="deposit_to">Email to</label>
                  <br />
                  <MultiEmailBox
                    onChange={setEmailHandler}
                    emailList={emailLists}
                    removeEmailHandler={removeEmailHandler}
                    onError={handleEmailError}
                    className={emailFormErrors !== "" ? "email-error" : ""}
                  />
                  <span className="error">{emailFormErrors}</span>
                </div>
              </div>

              <BillFormFooter
                editId={editId}
                billStatus={billStatus}
                isFormSubmit={
                  formSubmit && formSubmitAction && !isFileUploading.current
                }
                onClickSubmit={asynchronousSubmit}
                rolePermission={billsRolePermission}
                vendorSelected={
                  defaultRef.current?.defaultData?.vendor_id ? true : false
                }
                onClickDelete={(e) => {
                  e.preventDefault();
                  setDeleteBillId(Number(editId));
                  setDeleteBillItemId(billFormValues.bill_number);
                }}
              />
            </div>
          </form>
        </div>
        <DeleteBill
          deleteBillId={deleteBillId}
          deleteBillItemId={deleteBillItemId}
          page={page}
          itemsPerPage={itemsPerPage}
          dateSortOrder={dateSortOrder}
          refreshBills={fetchBillListAfterDelete}
          organizationId={currentUserInfo.organization_id}
        />
        <AlertModalPopup
          message="The payment entered is more than the total amount due for this bill. Please check and retry."
          modalTitle="Unable to save!"
          isCloseButtonEnabled={true}
          isSubmitEnabled={true}
          openRef={unableToSaveModalRef}
        />
      </div>
      <TransactionEditTagRemovalAlertModal
        module="bill"
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={() => {
          handleSubmit(buttonAction.status, buttonAction.action);
        }}
        openAlertRef={openEditAlertRef}
      />
    </>
  );
}

export default React.memo(BillForm);
