import { CSSProperties, ReactElement } from "react";

interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

export const Section = (props: ContainerProps) => {
  return (
    <section id={props.id} className={props.className} style={props.style}>
      {props.children}
    </section>
  );
};
