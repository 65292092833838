import {
  DebitNoteValues,
  ObjectType,
  TransactionItemValues,
  DebitNoteErrorFormValues,
} from "../../../../../types";
import {
  gstRegistrationTypes,
  ITEM_TYPES,
} from "../../../../constants/constants";

/**
 * Validate form values
 */
export const validate = (
  debitNoteFormValues: DebitNoteErrorFormValues,
  action: string,
  isDebitNoteNumberExist: boolean,
  itemFormValues: TransactionItemValues[],
  fileRef: React.MutableRefObject<any>,
  isGstOrg: boolean,
  gstRegistrationType: string
) => {
  const errors: ObjectType = {};
  if (!debitNoteFormValues.vendor_id) {
    errors.vendor_name = "Please enter vendor name";
  }
  if (!debitNoteFormValues.debit_note_number.split("~")[1].trim()) {
    errors.debit_note_number = "Please enter a valid debit note number";
  }
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (
    debitNoteFormValues.debit_note_date &&
    debitNoteFormValues.debit_note_date === "Invalid date" &&
    !dateRegex.test(debitNoteFormValues.debit_note_date)
  ) {
    errors.debit_note_date = "Please enter valid debit note date";
  }
  if (
    debitNoteFormValues.debit_note_date &&
    debitNoteFormValues.debit_note_date === "Future date"
  ) {
    errors.debit_note_date =
      "You have selected a future date. Please select a valid date.";
  }
  if (
    debitNoteFormValues.debit_note_date &&
    debitNoteFormValues.debit_note_date === "Old date"
  ) {
    errors.debit_note_date =
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.";
  }
  // validating state fields
  if (isGstOrg) {
    if (
      !debitNoteFormValues.source_of_supply?.value &&
      gstRegistrationType !== gstRegistrationTypes.overseas
    ) {
      errors.source_of_supply = "Please select source of supply";
    }
    if (!debitNoteFormValues.destination_of_supply?.value) {
      errors.destination_of_supply = "Please select destination of supply";
    }
  }

  if (isDebitNoteNumberExist) {
    errors.debit_note_number =
      "Debit note number already exists. Please choose a different one";
  }
  if (fileRef.current.fileIds.length > 3) {
    errors.attachFiles = "* Maximum File Limit Reached";
  }
  if (itemFormValues.length <= 0) {
    errors.items = "Please add at least one item";
  }
  if (debitNoteFormValues.vendor_notes?.length > 300) {
    errors.notes =
      "User shall be allowed to add alphanumeric text in the vendor note field of up to 300 characters";
  }
  if (
    Number(debitNoteFormValues?.tds_amount) !== 0 &&
    debitNoteFormValues?.tds_id === null
  ) {
    errors.tds_name = "Please select TDS type";
  }
  return errors;
};

export const validateItemValues = (
  values: TransactionItemValues,
  itemIndex: number,
  taxFormValues: TransactionItemValues[],
  isRcmEnabled: boolean,
  gstRegistrationType: string
) => {
  const errors: ObjectType = {};
  let flag = false;
  if (!values.item_name) {
    errors.item_name = "Please select an item or enter a description";
    flag = true;
  }
  if (
    values.type === ITEM_TYPES.service &&
    gstRegistrationType === gstRegistrationTypes.deemedExp
  ) {
    errors.item_name =
      "Only goods can be associated in the debit Note, if the debit Note raised for the Deemed Export Supply";
    flag = true;
  }
  if (!values.quantity || values.quantity === 0) {
    errors.quantity = "Please enter quantity";
    flag = true;
  }
  if (!values.rate) {
    errors.rate = "Please enter rate";
    flag = true;
  }
  if (values.discount) {
    if (
      values.discount_type === "Absolute" &&
      Number(values.discount) > Number(values.rate) * Number(values.quantity)
    ) {
      errors.discount = "Discount cannot not be higher than rate * quantity";
      flag = true;
    } else if (
      values.discount_type === "Percentage" &&
      Number(values.discount) > 100
    ) {
      errors.discount = "Discount cannot not be higher than rate * quantity";
      flag = true;
    }
  }
  if (Number(values.tds_id) === 0) {
    if (Number(values.tds_amount) != 0) {
      errors.tds_name = "Please choose TDS type";
      flag = true;
    }
  } else if (Number(values.tds_id) !== 0) {
    if (values.tds_amount > values.rate) {
      errors.tds_amount = "TDS amount must not exceed total taxable amount";
      flag = true;
    }
  }

  if (!values.discount && values.discount_account_id) {
    errors.discount = "Please enter discount";
    flag = true;
  }
  if (values.discount && !values.discount_account_id) {
    errors.discount_account_id = "Please choose discount account";
    flag = true;
  }
  if (!values.account_id) {
    errors.account_id = "Please enter account";
    flag = true;
  }
  if (isRcmEnabled) {
    if (!values.gst_tax?.tax_id) {
      errors.gst_tax_item = "Please specify a Tax for Reverse Charge";
      flag = true;
    }
  }
  if (taxFormValues[itemIndex]?.taxes) {
    let taxes = taxFormValues[itemIndex].taxes;
    let taxLabels: any[] = [];
    let taxError: any[] = [];
    for (let tax of taxes) {
      if (taxLabels.includes(tax.tax_id)) {
        flag = true;
        taxError.push({
          tax_id: "Tax label already used",
          tax_percentage: 0,
        });
      } else if (tax.tax_id === "" && Number(tax.tax_percentage) > 0) {
        flag = true;
        taxError.push({
          tax_id: "Please choose tax code",
          tax_percentage: 0,
        });
      } else {
        taxError.push({ tax_id: "", tax_percentage: 0 });
        if (tax.tax_id) {
          taxLabels.push(tax.tax_id);
        }
      }
    }
    errors.taxes = taxError;
  }
  if (flag) return errors;
  else return false;
};
