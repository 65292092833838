import client from "./ApolloClientConfig";
import { generateSearchQuery } from "./QueryTemplate";

export const searchValue = async (
  searchQuery: string,
  currentOrganization: number,
  module: string,
  pageNo: number,
  pageSize: number,
  signal: AbortSignal
) => {
  try {
    const { data } = await client.query({
      query: generateSearchQuery(module),
      variables: { searchQuery, currentOrganization, pageNo, pageSize },
      context: {
        fetchOptions: {
          signal: signal,
        },
      },
      fetchPolicy: "network-only",
    });
    return data.search;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
