import React from "react";

export default function Subject(props: { label: string; subject: string }) {
  return (
    <div className="row g-0">
      <div className="col cn-subject-col">
        <span className="cn-subject-header">{props.label}</span>
        <p>{props.subject}</p>
      </div>
    </div>
  );
}
