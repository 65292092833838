import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import { InventoryItemValues } from "../../../types";

/**
 * API call for getting all items.
 */
export async function getItemList(data: {
  page: number;
  itemsPerPage: number;
  nameSortOrder: string;
  orgId: number;
  orgIds: number[];
  status: string[];
  itemType: string[];
  transactionCategory: string[];
}) {
  let url =
    Api.inventoryItem +
    "/list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.nameSortOrder !== "") {
    url += `&name_sort_order=${data.nameSortOrder}`;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.itemType.length !== 0) {
    const statuses = data.itemType
      .map((name) => {
        return `&item_type_list=${name}`;
      })
      .join("");
    url += statuses;
  }
  if (data.transactionCategory.length !== 0) {
    const statuses = data.transactionCategory
      .map((name) => {
        return `&transaction_category_list=${name}`;
      })
      .join("");
    url += statuses;
  }
  if (data.status.length !== 0) {
    const statuses = data.status
      .map((name) => {
        return `&status_list=${name}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}
/**
 * API call for creating item
 */
export async function createNewItem(data: {
  values: InventoryItemValues;
  orgId: number;
}) {
  const result = await post(
    Api.inventoryItem + `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for creating item
 */
export async function editItem(data: {
  id: number;
  values: InventoryItemValues;
  orgId: number;
}) {
  const result = await put(
    Api.inventoryItem + `/edit/${data.id}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for deleting item
 */
export async function deleteItem(data: { id: number; orgId: number }) {
  const result = await remove(
    Api.inventoryItem +
      "/delete" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for deleting item tax
 */
export async function deleteItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.inventoryItem +
      "/delete" +
      `/${data.itemId}/${data.taxId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for getting item dropdown.
 */
export async function getItemDropdown(data: {
  currencyId: number;
  conversionId: number | null;
  orgId: number;
  transaction_type?: string;
}) {
  const result = await get(
    Api.inventoryItem +
      `/dropdown?currency_id=${data.currencyId}&conversion_id=${data.conversionId}&current_organization=${data.orgId}&transaction_type=${data.transaction_type}`
  );
  return result;
}

/**
 * API call for getting item for exemption reason dropdown
 */
export async function getExemptionDropdown(data: { orgId: number }) {
  const result = await get(
    Api.inventoryItem +
      `/tax_exemptions/dropdown?&current_organization=${data.orgId}`
  );
  return result;
}

export async function getExemptionLabel(data: {
  exemption_id: number | null;
  orgId: number;
}) {
  const result = await get(
    Api.inventoryItem +
      `/tax_exemptions/dropdown?exemption_id=${data.exemption_id}&current_organization=${data.orgId}`
  );
  return result;
}

export async function itemsStatusChange(data: { id: number, orgId: number }) {
  const result = await post(
    Api.inventoryItem + `/activate-or-deactivate/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

export async function getItemDetails(data: {
  item_id: number | string;
  orgId: number;
}) {
  const result = await get(
    Api.inventoryItem + 
      `/detail/${data.item_id}?current_organization=${data.orgId}`
  );
  return result;
}
export async function checkItemNameExists(data: {
  item_name: string;
  orgId: number;
  signal: AbortSignal;
}) {
  const result = await get(
    Api.inventoryItem +
      `/check-item-name-exist?current_organization=${data.orgId}&item_name=${data.item_name}`,
      data.signal
  );
  return result;
}
