import React from "react";
import "./itemDetailsWrapper.css";

type Props = {
  children: React.ReactNode;
};

const ItemDetailsWrapper = (props: Props) => {
  return (
    <>
      <div
        id="item-det-container"
        className="cn-item-container item-det-container overflow-coloumn"
      >
        <div className="row item-det-row cn-item-row g-0">
          <div className="col">
            <div className="item-grid-account cdn-item-grid">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ItemDetailsWrapper;
