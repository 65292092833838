import { SvgIconProp } from "../../../../types";

export const EditIcon = (props: SvgIconProp) => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 16 16"
      style={{ margin: props.margin || "" }}
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h16v16H0z" />
        <path
          d="m10.485 6.457-.942-.942-6.21 6.209v.943h.943l6.21-6.21zm.943-.942.943-.943-.943-.943-.943.943.943.943zM4.828 14H2v-2.829l8.957-8.956c.26-.26.682-.26.942 0L13.785 4.1c.26.26.26.682 0 .942L4.83 14h-.001z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
