import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { updateAllocationDateSettings } from "../../../../common/commonSlice";
import { SaveButton, SecondaryButton } from "../../../../common/components";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Container } from "../../../../common/components/htmlTags/Container";
import { Form } from "../../../../common/components/htmlTags/Form";
import { Label } from "../../../../common/components/htmlTags/Label";
import { Section } from "../../../../common/components/htmlTags/Section";
import { InfoMessage } from "../../../../common/components/infos/InfoMessage";
import useCommonData from "../../../../hooks/useCommon";
import { useMeApiTrigger } from "../../../../hooks/useTriggerOrgDetails";
import AccessDenied from "../../../../common/components/AccessDenied";

export const AllocationDatePreference = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { triggerMeApi } = useMeApiTrigger();
  const formik = useFormik({
    initialValues: {
      is_payment_allocation_enabled:
        currentUserInfo.is_payment_allocation_enabled,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseAction = await dispatch(
        updateAllocationDateSettings({
          orgId: currentUserInfo.organization_id,
          is_enabled: values.is_payment_allocation_enabled,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Allocation date setting updated successfully!",
          "allocation-date-config-update-success"
        );
        triggerMeApi();
      }
    },
  });
  return currentUserInfo.is_super_parent ? (
    <Card>
      <CardHeader>Preference</CardHeader>
      <Form
        onSubmit={formik.handleSubmit}
        className="preferences-settings-form w-100 p-3"
      >
        <Container className="d-flex align-items-center mb-3">
          <Container>
            <Checkbox
              id="is_payment_allocation_enabled"
              name="is_payment_allocation_enabled"
              onChange={formik.handleChange}
              checked={formik.values.is_payment_allocation_enabled}
              value={formik.values.is_payment_allocation_enabled}
            />
          </Container>
          <Container>
            <Label
              htmlFor="is_payment_allocation_enabled"
              className="mb-0 fw-normal"
            >
              Enable allocation date edit
            </Label>
          </Container>
        </Container>
        <InfoMessage
          message={
            formik.values.is_payment_allocation_enabled
              ? "On allocating transactions, the user can view and edit the allocation dates"
              : "On allocating transactions the allocation date would not be editable."
          }
        />
        <Section className="form-button-section mt-3">
          <Container
            className="button-fields form-button-wrapper"
            id="form-btn-allocation-date"
          >
            <SaveButton
              type="submit"
              isDisabled={false}
              buttonContent="Save"
              buttonClassName=""
              onClick={() => {}}
            />

            <SecondaryButton
              type="button"
              isDisabled={false}
              buttonContent="Cancel"
              onClick={formik.handleReset}
            />
          </Container>
        </Section>
      </Form>
    </Card>
  ) : (
    <AccessDenied />
  );
};
