export const message = (param1?: any, param2?: any) => {
  return {
    transactionLimitTitle: `Transaction limit exceeded!`,
    transactionLimitText: `You have crossed the allotted transaction limit of ${param1}. Please contact your administrator or get in touch with us for more assistance.`,
    createInvoiceText: "Create new invoice!",
    noInvoiceText: "No Invoices found!",
    createInvoiceSubText: "Get paid faster by creating invoices.",
    noInvoiceSubText: "Oops, There is no Invoice data available right now",
    createItemText: "Create new item!",
    noItemText: "No Item found!",
    createItemSubText: "Get paid faster by creating items.",
    noItemSubText: "Oops, There is no Item data available right now",
    unableToDeleteTitle: `Unable to delete ${param1}!`,
    unableToDeleteText: `The ${param1} cannot be deleted as there are dependent transactions.`,
    taxAccountDeleteInfo: `The configured account was deleted from chart of accounts. Please select
    an account for using this tax`,
    emailInfo: `On clicking "<span class="bold-text">${param1}</span>" the
    ${param2} pdf will be shared with the added email/s`,
    reportCurrencyInfo: `All transactions are displayed in <b>${param1}</b>`,
    gstTransactionExist: `Cannot deregister tax as taxes associated with one or more transaction(s). Delete or de associate the transaction(s) to proceed`,
    gstinTransactionExist: `Cannot change the GSTIN Since taxes are associated with transaction(s). Delete the transaction(s) to proceed.`,
    getSettingUpdateSuccess: `GST settings updated successfully`,
    gstinRequired: `Please enter GSTIN`,
    gstinMaxLen: `Please enter only 15 digits`,
    gstinInvalid: `Please enter a valid GSTIN`,
    selectIntraStateTax: `Please select intra state tax rate`,
    selectInterStateTax: `Please select inter state tax rate`,
    gstDefaultUpdateSuccess: `GST default tax preference updated successfully`,
    invalidDate: `Please enter a valid date`,
    futureDateNotAllowed: `Future date is not allowed`,
    editMigrationDate: `You cannot edit migration date as there are opening balance associated`,
    fiscalYearNotClosed: `Account closing could not be scheduled. Try again after sometime.`,
    pm: {
      vendorRequired: `Please select a vendor`,
      paymentDateRequired: `Please enter payment date`,
      paymentDateShouldValid: `Please enter a valid payment date`,
      paymentDateMin: `Please enter a date with a year above 2000`,
      paymentNumberShouldValid: `Please enter a valid payment number`,
      paymentModeRequired: `Please select a payment mode`,
      amountPaidRequired: `Please enter the amount paid`,
      paidThroughRequired: `Please select a paid through account`,
      checkNumberRequired: `Please enter check number`,
      checkExpiryRequired: `Please enter check date`,
      checkExpiryShouldValid: `Please enter a valid check expiry date`,
      pastDateNotAllowed: `Expiry date should be greater than or equal to payment date`,
      bankChargesAccountRequired: `Please select bank charges account`,
      bankChargeAmountRequired: `Please enter bank charges amount`,
      amountGreaterDue: `Payment amount is greater than amount due`,
      totalGreaterAmountPaid: `Total payment amount is greater than the amount paid`,
      emailRequired: `Please enter email`,
      paymentDateWithinFiscalYear: `Invalid Transaction Date: Transaction date must be within the current fiscal year.`,
      paymentDateAfterRefund: `Payment date cannot be after the refund date. Please adjust the refund date first.`,
    },
    cashBasedJVInfo: `Choose this option to make this journal entry appear only in the reports that are generated on cash basis. If not, this transaction will appear in both the accrual and cash basis reports.`,
    cashBasedSVInfo: `Choose this option to make this system voucher appear only in the reports that are generated on cash basis. If not, this transaction will appear in both the accrual and cash basis reports.`,
    listInJvInfo: `On selections this system voucher will be considered as a journal entry`,
    reportingTag: {
      reportingTagRequired: `Please associate reporting tags!`,
      splitAmountShouldMatch: `Total split amount should match the transaction amount!`,
      reportingTagUpdateSuccess: `Reporting tag updated successfully!`,
      notMutable: `Unable to edit reporting tags, as there are transactions associated with this ${param1}!`,
      maxTagLimit: `Unable to add more tag, as maximum count of option is ${param1}!`,
      maxOptionLimit: `Unable to add more option, as maximum count of option is ${param1}`,
    },
    invoice: {
      createSuccess: `Invoice created successfully!`,
      invoiceDownloadSuccess: `Invoice downloaded successfully!`,
      writeOffVoidInvoice: "You cannot write off a voided invoice",
      createCNVoidInvoice: "You cannot create credit note for a voided invoice",
      applyCreditVoidInvoice: "You cannot apply credits for a voided invoice",
      associateTagsToVoidInvoice:
        "You cannot associate reporting tag to a voided invoice",
    },
    bill: {
      writeOffVoidBill: "You cannot write off a voided bill",
      createDNVoidBill: "You cannot create debit note for a voided bill",
      applyDebitVoidBill: "You cannot apply debits for a voided bill",
      associateTagsToVoidBill:
        "You cannot associate reporting tag to a voided bill",
    },
    creditNote: {
      createSuccess: `Credit note created successfully!`,
      editVoidCreditNote: "You cannot edit a voided credit note",
      associateTagsToVoidCN:
        "You cannot associate reporting tag to a voided credit note",
      applyCreditNoteToInvoices:
        "You cannot apply credits to invoices from voided credit note",
    },
    debitNote: {
      associateTagsToVoidDN:
        "You cannot associate reporting tag to a voided debit note",
      applyDebitNoteToBills:
        "You cannot apply debits to bills from voided debit note",
    },
    plan: {
      planNameInt: `This name will help you identify this plan internally`,
      planNameExt: `This will be used in the Self-Serve Portal`,
      priceDailyPlanNameInt: `This name will help you identify this plan price point internally`,
      pricePlanNameExt: `This will be used in Invoices, Checkout and Self-Serve Portal`,
      pricingModel: `Defines how the recurring charges for the subscription are calculated ie. Flat Fee, Per Unit, Tiered, Volume, Stairstep`,
      rate: `This rate represents the current pricing for the item. This can be adjusted with respect to the plan`,
      freeQuantity: `Subscribers signing up for this plan will only be charged for the quantity more than this value`,
      discountedTrial: `This represents the discount ranges for different trial periods`
    },
    addon: {
      addonNameInt: `This name will help you identify this addon internally`,
      priceAddonNameExt: `This will be used in Invoices, Checkout and Self-Serve Portal`,
      pricingModel: `Defines how the recurring charges for the subscription are calculated ie. Flat Fee, Per Unit, Tiered, Volume, Stairstep`,
      priceDailyAddonNameInt: `This name will help you identify this addon price point internally`,
      rate: `This rate represents the current pricing for the item. This can be adjusted with respect to the addon`,
    },
    coupon: {
      discountType: `Specified percentage/amount will be deducted`,
      duration: `Duration specifies how long this coupon should be applied to the subscription`,
      validTill: `Date up to which the coupon can be applied to new subscriptions`,
      maximumRedumption: `Maximum number of times this coupon can be redeemed`,
      multiCoupon: `Add multiple coupons of the same type (percentage or fixed amount) in subscriptions`,
      couponOrder: ` The order of application will affect the amount of the discount`,
      percentageCouponOrder: `The method of application will affect the amount of the discount`,
    },
    subscription: {
      invoicingCycle: `Number of invoicing cycles this subscription should be charged. Overrides the value configured at the plan level`,
      invoicingOption: `If there are any charges, you can generate an invoice immediately, or add them to unbilled charges and invoice them later. Unbilled charges will get automatically invoiced at the next subscription renewal`,
      invoiceGeneration: `When to generate subscription invoice in align with the calendar invoicing settings`,
      exchangeRate: `This exchange rate is based on the date the subscription was created, but it changes in real time for every invoice`,
      updateInvoicingOption: `If there are any charges, you can generate an invoice immediately, or add them to unbilled charges and invoice them later. Unbilled charges will get automatically invoiced at the next subscription renewal`,
      restorePricing: `The ranges and price per unit will be restored to the default pricing as defined in the`,
      couponAmmount: `Coupon is applied at the taxed amount`,
      unbilledCharges: `Unbilled Charges will be invoiced in the next renewal cycle`,
    },
    itemDetails: {
      noItemOrDescription: "Please select an item or enter a description.",
      noQuantity: "Please enter a valid quantity.",
      noItemAccount: "Please choose an account.",
      noRate: "Please enter a valid rate.",
      noHSN: "Please enter HSN/SAC code.",
      noTDSAccount: "Please select TDS type.",
      noTDSAmount: "Please enter TDS amount.",
      noTDSPercentage: "Please enter TDS percentage.",
      noDiscountAccount: "Please choose a discount account.",
      noDiscount: "Please enter discount.",
      noTCSAccount: "Please select TCS type.",
      noTCSAmount: "Please enter TCS amount.",
      noTCSPercentage: "Please enter TCS percentage.",
      noTaxCode: "Please choose a tax code.",
      noTaxPercentage: "Please enter tax percentage.",
      tdsAmountGreater:"TDS amount must not exceed total taxable amount.",
      discountAmountGreater: "Discount cannot not be higher than rate * quantity.",
      taxDuplication:"Tax label already used."
    },
  };
};
