import { ObjectType } from "../../../../../../../types";
import { AMOUNT_IN_WORDS } from "../../../../../../constants/constants";

export const payloadSetter = (amountInWordsConfig: ObjectType) => {
  let payload: ObjectType = {};
  AMOUNT_IN_WORDS.map((value) => {
    payload[value.key] = amountInWordsConfig[value.value];
  });
  return payload;
};
