import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { AlertModal } from "../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../common/components/alertModal/alertModalContext";
import { DeleteIcon } from "../../../../common/components/customSvgIcons/deleteIcon";
import { colors } from "../../../../constants/colors";
import { DeletePurchaseOrderBody } from "./deletePurchaseOrder";
import { DeletePurchaseOrderModalProps } from "./types";
export const DeletePurchaseOrderModal = (
  props: DeletePurchaseOrderModalProps
) => {
  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <DeletePurchaseOrderBody
                id={props.id}
                purchaseOrderNumber={props.purchaseOrderNumber}
                handleClose={onCloseModal}
                reRenderPage={props.reRenderPage}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      modalButtonWrapperClass="header-action-delete-popup"
      buttonContent={
        <span className="no-appearance red-text header-action-delete-popup-btn">
          <DeleteIcon
            width="18px"
            height="18px"
            color={colors.tomato}
            margin={"0 8px 0 0"}
          />
          Delete
        </span>
      }
      buttonClass="no-appearance w-100 p-0"
      modalTitle={`Delete Purchase Order!`}
      isCloseButtonEnabled={true}
    />
  );
};
