import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getRoundOffPreference,
  putRoundOffPreference,
  getRIPreference,
  updateRecurringPreference,
} from "./preferencesAPI";
import { RootState } from "../../../app/store";
import { roundOffPreference, RecurringInvoicePreference } from "../../../types";

export const fetchRoundOffPreference = createAsyncThunk(
  "settings/get-preferences",
  async (data: { orgId: number; roundoff_transaction_type?: string }) => {
    return await getRoundOffPreference(data);
  }
);

export const updateRoundOffPreference = createAsyncThunk(
  "settings/update-preferences",
  async (data: { orgId: number; payload: roundOffPreference }) => {
    return await putRoundOffPreference(data);
  }
);

export const fetchRIPreference = createAsyncThunk(
  "settings/get-ri-preferences",
  async (data: { orgId: number }) => {
    return await getRIPreference(data);
  }
);

export const updateRIPreference = createAsyncThunk(
  "settings/update-ri-preferences",
  async (data: { orgId: number; payload: RecurringInvoicePreference }) => {
    return await updateRecurringPreference(data);
  }
);

const initialState = {
  preferenceSettings: {
    account_id: null,
    is_enabled: false,
  },
};

export const roundOffPreferenceSlice = createSlice({
  name: "gst",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRoundOffPreference.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.preferenceSettings = {
          account_id: payload.account_id,
          is_enabled: payload.is_enabled,
        };
      }
    });
  },
});

export default roundOffPreferenceSlice.reducer;

export const gstRoundOffPreferenceSelector = (state: RootState) =>
  state.roundOffPreference.preferenceSettings;
