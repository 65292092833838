import OrganizationProfile from "./OrganizationProfile";

function OrganizationProfileContainer() {
  return (
    <div className="card card-user-management card-org-profile main-card overflowX-hidden h-100">
      <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
        <h1>Organization Profile</h1>
      </div>
      <div className="card-body">
        <OrganizationProfile redirectTo="/orgProfile" />
      </div>
    </div>
  );
}

export default OrganizationProfileContainer;
