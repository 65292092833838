import { GoBackArrowWhiteIcon } from "../../../../assets/images";
import SomethingWentWrongIcon from "../../../../assets/images/something-went-wrong-icon.png";

function ErrorPage() {
  return (
    <div className="access-denied-wrapper error-boundary">
      <img
        src={SomethingWentWrongIcon}
        width={108}
        height={88}
        alt="no-access"
      />
      <h2>Oops! Something went wrong</h2>
      <span className="sub">
        Please give us a moment and try reloading the page. If the issue
        persists, contact our support team for assistance.
      </span>
      <button
        type="button"
        className="btn back-btn green-button"
        onClick={() => {
          window.location.assign("/");
        }}
      >
        <img
          src={GoBackArrowWhiteIcon}
          width={"24px"}
          height={"24px"}
          alt="Go Back"
        />
        Go Back!
      </button>
    </div>
  );
}
export default ErrorPage;
