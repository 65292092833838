import React, { useMemo } from "react";
import TableContainer from "../../../../common/components/table/TableContainer";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import AddonPricingListActions from "./AddonPricingListActions";
import CustomPriceColumn from "./AddonPriceColumn";
import useCommonData from "../../../../hooks/useCommon";
import { PricingListData } from "../../addonType";
import { coustomCase, getDefaultPricingTableData, pricingModuleRenderValue } from "../../../../../helpers/planHelper";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  pricingListData: PricingListData[] | null;
  onClickDelete: (actionType: string, id: number, addonName: string) => void;
  organizationId: number;
  frequencyRow: string[];
  addonId: number;
  addonStatus: string;
  currencyCode: string;
};
function PricingTable({
  pricingListData,
  onClickDelete,
  organizationId,
  frequencyRow,
  addonId,
  addonStatus,
  currencyCode
}: Props) {


  const currentUserInfo = useAppSelector(currentUserSelector);
  const { navigate } = useCommonData();
  const { addonsRolePermission } = usePermissionHandler();
  let showEmptyTable = addonsRolePermission.includes('Read') && addonsRolePermission.includes('Create')
  && organizationId === currentUserInfo.organization_id
  && !(addonStatus.toLowerCase() === 'cancelled')

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Frequency",
        accessor: "show.frequency",
        sort: false,
        sortOrder: '',
        className: "addon_table_col",
      },
      {
        Header: "Pricing Model",
        accessor: "show.pricingModel",
        sort: false,
        sortOrder: "",
        className: "addon_table_col",
      },
      {
        Header: "Price",
        accessor: "show.priceItem",
        sort: false,
        sortOrder: "",
        className: "addon_price",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomPriceColumn
              value={value}
              showPricingActions={showEmptyTable}
              isButtonVisible= {value.price ? addonsRolePermission.includes("Update") : addonsRolePermission.includes("Create")}
            />
          )
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!addonsRolePermission.includes("Delete") ||
            (!value.price || value.addonStatus.toLowerCase() === 'cancelled')) ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <AddonPricingListActions
              value={value}
              onClickDelete={onClickDelete}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, addonsRolePermission, showEmptyTable]
  );

  /**
   * set table row data
   */
  const getPricingData = (addon: any, index: any) => ({
    show: {
      frequency: coustomCase(addon.frequency),
      pricingModel: pricingModuleRenderValue.find((eachPriceModel) => eachPriceModel.key === addon.pricing_model)?.value ?? '',
      priceItem: {
        item: addon.item_price,
        price: addon.isPriceSet,
        index: index,
        frequency: addon.frequency,
        pricingModel: addon.pricing_model,
        organizationId: organizationId,
        addonId: addonId,
        addonStatus: addonStatus,
        currencyCode: currencyCode,
        pricingId: addon.pricing_id,
      },
      actions: {
        organizationId: organizationId,
        price: addon.isPriceSet,
        pricngId: addon.pricing_id,
        addonStatus: addonStatus,
        frequency: coustomCase(addon.frequency),
        pricingExistsSubscription: addon.pricing_exists_subscription,
      }
    },
  })

  const data = pricingListData
    ? pricingListData.map((addon, index) => getPricingData(addon, index))
    : [];
  const modifiedPricingData = getDefaultPricingTableData().map((addon, index) => getPricingData(addon, index))
  
  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data addon-table"
      >
        <div className="row">
          <div className="col-md-12">
            {pricingListData && pricingListData.length > 0 ? <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/addons"}
              sortHandler={() => { }}
              editPermission={addonsRolePermission.includes("Update")}
              isDetailViewExist={true}
            >
              <div>No Data</div>
            </TableContainer>
              : showEmptyTable ? (<div className="set-price-container align-center">
                <span className="set-price-container-span">
                  Set price for
                </span>
                {
                  frequencyRow.map((data, index) => <div key={data} className="set-price-container-div">
                    <span className="set-price-container-span">{`${[(frequencyRow.length - 1), 0].includes(index) ? (index === 0 ? '' : `  &`) : ","}`}</span>
                    <button
                      className="set-pricing-button"
                      onClick={() => { navigate(`/addons/price/create/${addonId}/${data.toLowerCase()}`, { replace: true }) }}
                    >
                      {coustomCase(data)}
                    </button>
                  </div>)
                }
              </div>) : <TableContainer
                className="list-table table table-striped w-100"
                columns={columns}
                data={modifiedPricingData}
                url={"/addons"}
                sortHandler={() => { }}
                editPermission={addonsRolePermission.includes("Update")}
                isDetailViewExist={true}
              >
                <div>No Data</div>
              </TableContainer>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PricingTable);