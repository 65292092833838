import React from "react";
import { Link } from "react-router-dom";
import useCommonData from "../../../hooks/useCommon";
import Active from "../../../../assets/images/Active.svg";
import Inactive from "../../../../assets/images/Close.svg";
import { itemStatusChange } from "../../../home/items/itemSlice";
import { toast } from "react-toastify";

type Props = {
  className?: string;
  isActive: boolean | undefined;
  itemId: number;
  refreshList: () => void;
};
const ActivateItems = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();

  const handleItemActiveOrInactive = async (itemId: number) => {
    const responseAction = await dispatch(itemStatusChange({ id: itemId, orgId: currentUserInfo.organization_id }));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshList();
        if (response.is_active) {
          toast.success("The selected item is marked as active.", {
            toastId: "item-marked-active",
            closeButton: false,
            position: "top-center",
          });
        } else {
          toast.success("The selected item is marked as inactive.", {
            toastId: "item-marked-inactive",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };

  return (
    <Link
      className={props.className}
      to="#"
      onClick={() => handleItemActiveOrInactive(props.itemId)}
    >
      <span>
        <img src={props.isActive ? Inactive : Active} alt="active-inactive-img" />
        Mark as {props.isActive ? "Inactive" : "Active"}
      </span>
    </Link>
  );
};
export default ActivateItems;
