import React from "react";
import "./TransactionLockingHeader.css";

const TransactionLockingHeader = (props: { children: React.ReactNode }) => {
  return (
    <div className="card card-user-management card-customer card-lock-transaction main-card overflowX-hidden h-100">
      <div className="card-header lock-transaction-card-header list-card-header p-0 pbottom-14 border-0">
        <h1 className="mtop-7">Transaction Locking</h1>
      </div>
      <div className="card-body">
        <div className="card-content-wrapper">
          <p>
            By implementing transaction locking, you and your users are
            restricted from making any alterations to transactions that could
            impact your accounts. Once transactions are locked, users are unable
            to edit, modify or delete any transactions recorded prior to the
            specified date with this module.
          </p>
          <ul className="lock-item-list m-0 p-0 list-style-none w-100">
            {props.children}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TransactionLockingHeader;
