import JournalVoucherHeader from "./JournalVoucherHeader";
import "./JournalVoucherDetail.css";
function JournalVoucherDetail() {
  return (
    <div className="">
      <div className="card card-dashboard bills-detail-card cd-detail-card w-100" id="bills-detail-card">
        <JournalVoucherHeader />
      </div>
    </div>
  );
}

export default JournalVoucherDetail;
