import React, { useEffect, useState } from "react";
import "../../../../../common/styles/ConsumerTransactions.css";
import AccordianItem from "../../../../../common/components/detailsPage/AccordianItem";
import DNTransactions from "./DNTransactions";
import PMTransactions from "./PMTransactions";
import BillTransactions from "./BillTransactions";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { currentUserSelector } from "../../../../../common/commonSlice";
import { useParams } from "react-router-dom";
import { IsFeatureEnabled } from "../../../../../../helpers/featureFlagHelper";
import { Transactionlimit } from "../../../../../../types";
import { getTransactionLimitStatus as billLimitStatus } from "../../../../bills/billsSlice";
import NotificationModal from "../../../../../common/components/NotificationModal";
import { getTransactionLimitStatus as dmLimitStatus } from "../../../../debitNote/debitNoteSlice";
import { getTransactionLimitStatus as pmLimitStatus } from "../../../../paymentsMade/paymentsMadeSlice";
type Props = {
  isSameOrg: boolean;
};
export default function VendorTransactions(props: Props) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const [subModulePermission, setSubModulePermission] = useState<any[]>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  let subModules: any[] = [];
  useEffect(() => {
    subModules = organizationRole.length
      ? organizationRole.map((permission: any) => {
          return permission.subModule;
        })
      : [];
    let unique = Array.from(new Set(subModules));
    setSubModulePermission(unique);
  }, [
    currentUserInfo.organization_id,
    sessionStorage.getItem("organizationRole"),
  ]);
  let billsRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Bills")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let debitNoteRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Debit Notes"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let paymentsMadePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Payments Made"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  /**
   * Fetch transaction limit
   */
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async (type: string) => {
    let responseAction: any;
    if (type === "Bill") {
      responseAction = await dispatch(
        billLimitStatus(currentUserInfo.organization_id)
      );
    }
    if (type === "Debit Notes") {
      responseAction = await dispatch(
        dmLimitStatus(currentUserInfo.organization_id)
      );
    }
    if (type === "Payments Made") {
      responseAction = await dispatch(
        pmLimitStatus(currentUserInfo.organization_id)
      );
    }
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
      return response.limit_exceed;
    } else {
      return false;
    }
  };
  return (
    <>
      {subModulePermission.includes("Bills") ||
      subModulePermission.includes("Debit Notes") ||
      subModulePermission.includes("Payments Made") ? (
        <div
          className="accordion transactions-accordion"
          id="transactions-accordion"
        >
          {subModulePermission.includes("Bills") &&
          IsFeatureEnabled("Bills", currentUserInfo.disabled_feature) ? (
            <AccordianItem
              title="Bill"
              index={1}
              createUrl={`/bill/create/${editId}`}
              permission={
                billsRolePermission.includes("Create")
              }
              redirectTo={`/vendors/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <BillTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
          {subModulePermission.includes("Debit Notes") &&
          IsFeatureEnabled("Debit Notes", currentUserInfo.disabled_feature) ? (
            <AccordianItem
              title="Debit Notes"
              index={2}
              createUrl={`/debit-note/create/${editId}`}
              permission={
                debitNoteRolePermission.includes("Create")
              }
              redirectTo={`/vendors/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <DNTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
          {subModulePermission.includes("Payments Made") &&
          IsFeatureEnabled(
            "Payments Made",
            currentUserInfo.disabled_feature
          ) ? (
            <AccordianItem
              title="Payments Made"
              index={3}
              createUrl={`/payment-made/create/${editId}`}
              permission={
                paymentsMadePermission.includes("Create")
              }
              redirectTo={`/vendors/detail/${editId}`}
              consumerId={editId}
              checkLimit={fetchTransactionLimit}
            >
              <PMTransactions isSameOrg={props.isSameOrg} />
            </AccordianItem>
          ) : null}
        </div>
      ) : null}
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
}
