import { useParams } from "react-router-dom";
import { CreditApply, CreditApplyToBill, ObjectType } from "../../../../../../../types";
import useCommonData from "../../../../../../hooks/useCommon";
import {
  applyCredits,
  applyCreditsCalculation,
  creditList,
} from "../../../../billsSlice";
import { SuccessToaster } from "../../../../../../../helpers/toastHelper";
import { useCallback } from "react";
import { FormikProps } from "formik";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../../../helpers/helper";

export const useApplyCredits = () => {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const { editBillId } = useParams();
  const getApplyCreditList = useCallback(
    async (formik: FormikProps<any>) => {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        creditList({
          billId: Number(editBillId),
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          const transToCredit = response.map((credit: ObjectType) => {
            return {
              ...credit,
              allocation_date: credit.allocation_date
                ? credit.allocation_date
                : getFormatedDate(new Date()),
            };
          });
          formik.setFieldValue(`transactions_to_credit`, transToCredit);
          // formik.setFieldValue(`invoice_balance`, response.invoice_balance);
        }
      }
      dispatch(setLoaderState(false));
    },
    [editBillId, currentUserInfo.organization_id]
  );
  /**
   * credit note allocation calculation
   */
  const calculateAllocationAmount = async (
    transactionsToCredit: CreditApplyToBill[],
    formik: FormikProps<any>,
    balance: number,
    currency_id: number,
    signal: AbortSignal
  ) => {
    let input: number[] = [];
    transactionsToCredit.map((item) => {
      if (
        item.amount_debit &&
        item.amount_debit !== null &&
        item.amount_debit !== ""
      ) {
        input.push(Number(item.amount_debit));
      } else {
        input.push(0);
      }
    });
    if (input.length) {
      const responseAction = await dispatch(
        applyCreditsCalculation({
          orgId: currentUserInfo.organization_id,
          values: {
            bill_balance: balance,
            allocation_list: input,
            currency_id: currency_id,
          },
          signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          formik.setFieldValue(`bill_balance`, response.bill_balance);
          formik.setFieldValue(`balance`, response.bill_balance);
          formik.setFieldValue(`amount_to_debit`, response.amount_to_debit);
        }
      }
    }
  };
  const updateAllocationData = async (
    transactionsToCredit: CreditApplyToBill[],
    billDetail: () => void,
    resetAllocation: () => void
  ) => {
    const transactionDetails = JSON.parse(JSON.stringify(transactionsToCredit));
    let inputs: CreditApplyToBill[] = [];
    transactionDetails
      .filter(
        (item: CreditApplyToBill) =>
          item.amount_debit !== undefined &&
          item.amount_debit !== null &&
          item.amount_debit !== 0 &&
          item.amount_debit !== "" &&
          item.object_id !== null
      )
      .map((item: CreditApplyToBill) => {
        let input = {
          object_id: item.object_id,
          debit_type: item.debit_type,
          amount_debit: item.amount_debit ? item.amount_debit : 0,
          allocation_date: item.allocation_date,
        };
        inputs.push(input);
      });
    const responseAction = await dispatch(
      applyCredits({
        bill_id: Number(editBillId),
        orgId: currentUserInfo.organization_id,
        values: inputs as any,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        billDetail();
        resetAllocation();
        SuccessToaster("Credits applied to bills!", "credit-apply-success");
      } else if ("error" in response) {
        resetAllocation();
      }
    }
  };
  return {
    updateAllocationData,
    calculateAllocationAmount,
    getApplyCreditList,
  };
};
