import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { currentModule, setListFilters } from "../../../../appSlice";
import { getStatusIds } from "../../../../helpers/statusFilterHelper";
import { ObjectType, TopList } from "../../../../types";
import { currentUserSelector } from "../../../common/commonSlice";
import { getTopReceivablesList } from "../../reports/ReportSlice";
import DashboardTable from "./DashboardTable";

function TopReceivables() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [topReceivables, setTopReceivables] = useState<TopList[]>([]);

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    ) {
      fetchTopReceivables();
    }
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const fetchTopReceivables = async () => {
    const responseAction = await dispatch(
      getTopReceivablesList({
        orgId: currentUserInfo.organization_id,
        currencyId: currentUserInfo.organization_currency,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let topReceivablesData = response.map((customer: ObjectType) => {
        let transactionsCount =
          customer.total_no_of_invoices > 1
            ? customer.total_no_of_invoices + " invoices"
            : customer.total_no_of_invoices + " invoice";
        return {
          id: customer.customer_id,
          name: customer.customer_name,
          totalAmount: customer.total_amount,
          noOfTransactions: transactionsCount,
        };
      });
      setTopReceivables(topReceivablesData);
    } else {
      setTopReceivables([]);
    }
  };
  /**
   * Clicks on customer in top receivables list navigate corresponding customer transactions in invoices
   */
  const showTransaction = (id: number) => {
    dispatch(currentModule("Invoices"));
    const statusArray = ["Open", "Overdue", "Partially Paid"];
    const statusIds = getStatusIds(statusArray);
    dispatch(
      setListFilters({
        Organizations: [],
        Customers: [id],
        Status: statusIds,
      })
    );
    navigate("/invoice", { state: { isDashboard: true } });
  };
  return (
    <DashboardTable
      title="Top Receivables"
      data={topReceivables}
      handleOnClick={showTransaction}
    />
  );
}

export default TopReceivables;
