import { DateFormatHandler } from "../../../../helpers/helper";
import { SelectValues } from "../../../../types";
import { monthOptions } from "../../../constants/constants";
import { frequencyFilters } from "../calenderBillingStateInitialization";

export const getFrequencyFilterString = (frequencyIds: number[]) => {
    const numberSet = new Set(frequencyIds);
    const names: string[] = [];

    for (const object of frequencyFilters) {
      if (numberSet.has(object.id)) {
        names.push(object.name);
      }
    }
    return names;
  };

  export const  formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const ordinalSuffix = getOrdinalSuffix(dateString);

    return `${ordinalSuffix} ${month}`;
  }

  export const getOrdinalSuffix = (dateString: string) => {
    const date = new Date(dateString);
    const number = date.getDate();

    const j = number % 10;
    const k = number % 100;
    if (j === 1 && k !== 11) {
      return number + "st";
    }
    if (j === 2 && k !== 12) {
      return number + "nd";
    }
    if (j === 3 && k !== 13) {
      return number + "rd";
    }
    return number + "th";
  };

  export const capitalizeDay = (day: any) => {
    if (!day) return day;
    // Use regex to capitalize the first character and lowercase the rest
    return day.replace(
      /^([A-Z])([A-Z]*)$/,
      (_: any, firstChar: any, rest: string) => firstChar + rest.toLowerCase()
    );
  }

  export const  toTitleCase = (str: string) => {
    // Convert the string to lowercase, split by underscore, and map each part
    return str
      .toLowerCase()
      .split("_")
      .map(
        (word, index) =>
          index === 0
            ? word.charAt(0).toUpperCase() + word.slice(1) // Capitalize first word
            : word // Keep the second word lowercase
      )
      .join(" ");
  }

 export const recurringInvoicingValue = (frequency: string) => ([
    { label: "Auto Set", value: "AUTO_SET" },
    { label: `${frequency === "WEEKLY"?'Set day':'Set date'}`, value: "SET_DATE" },
  ]);

export const getPlanDropdownInEdit = (selectedList: SelectValues[], dropdownList: SelectValues[]) => {
  let dropdownData: SelectValues[] = []
 if (selectedList.length > 0) {
  dropdownData = dropdownList.filter((filterData) => selectedList.map((data) => data.value).includes(filterData.value))
 }
return dropdownData
}

export const getOrdinalSuffixForDay = (number: number) => {

  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) {
    return number + "st";
  }
  if (j === 2 && k !== 12) {
    return number + "nd";
  }
  if (j === 3 && k !== 13) {
    return number + "rd";
  }
  return number + "th";
};

export const  formatDateYearly = (day: string, month: string) => {
  const monthSuffix = monthOptions.filter((filterMonth: any) =>  filterMonth.value === month)[0]?.label || '';
  const ordinalSuffix = getOrdinalSuffixForDay(Number(day));

  return `${ordinalSuffix} ${monthSuffix}`;
}
export const getFieldFormattedValue = (value: string) => {
  const data = value.split("-")
  let updatedValue = ''
  if (data.length === 1 && !!value) {
    updatedValue = `${getOrdinalSuffixForDay(Number(value))}`
  } else if (data.length === 2 && !!value) {
    updatedValue = formatDateYearly(data[0], data[1])
  }
  return updatedValue
}

export const formatDateString = (dateString: string, type: string) => {
  const parts = DateFormatHandler(dateString).split('-');
  if (type === "YEARLY") return `${parts[0]}-${parts[1]}`;
  else if (type === "MONTHLY") return `${parts[0]}`
  return `${parts[0]}-${parts[1]}-${parts[2]}`;
}


export const getValidDay = (year: number, month:number, day:number) => {
  const lastDayOfMonth = new Date(year, month, 0).getDate();
  return day > lastDayOfMonth ? `${lastDayOfMonth}` : `${day}`;
};