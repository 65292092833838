import React, { useEffect, useState } from "react";
import Popper from "@mui/material/Popper";
import Item from "../../../home/subscription/components/subscriptionForm/Item";
import "./defaultDetails.css";
import { RangePriceValue } from "../../../home/subscription/subscriptionType";
import { useLocation } from "react-router-dom";

type Props = {
  id: any;
  anchorEl: any;
  open: any;
  value: any;
  currencyId: number | string;
  onPopoverClose: () => void;
  onUpdateButtonClick: (updatedRange: RangePriceValue[]) => void;
};

const ListCellTablePopoverSubscription: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  return (
    <Popper
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      placement="top-end"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            rootBoundary: 'anchorEl',
          },
        },
      ]}
      // className={`pricing-item-col-popper range_popover subscription-price-popover ${location.pathname.includes('subscriptions/edit')?'':'subscription-price-popover-create'}`}
      className={`pricing-item-col-popper range_popover subscription-price-popover subscription-price-popover-create`}
    >
      <div className="card-body">
        <div
          id="invoice-table-wrapper"
          className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data pricing-table"
        >
          <div className="row">
            <div className="col-md-12"></div>
            <div className="pricing-form">
              <div className="row custom-row">
                <div className="item-container item-cont-width subscription-pricing-form">
                  {props.value.map(
                    (
                      item: {
                        id: any;
                        item_name: any;
                        currency_code: any;
                        range_price: any;
                        parentId: any;
                        itemType: any;
                        pricing_id: any;
                        is_restore_enabled: any;
                      },
                      key: any
                    ) => (
                      <Item
                        sl={key}
                        itemId={item.id}
                        itemName={item.item_name}
                        currencyCode={item.currency_code}
                        range_price={item.range_price}
                        parentId={item.parentId}
                        itemType={item.itemType}
                        pricing_id={item.pricing_id}
                        currencyId={props.currencyId}
                        isRestoredEnabled={item.is_restore_enabled}
                        onClose={() => props.onPopoverClose()}
                        onPricePointUpdate={props.onUpdateButtonClick}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popper>
  );
};

export default ListCellTablePopoverSubscription;
