import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TaxRateTable from "./TaxRateTable";
import useListData from "../../../../../hooks/useListData";
import useCommonData from "../../../../../hooks/useCommon";
import { setLoaderState } from "../../../../../common/commonSlice";
import { taxesList } from "../../../taxConfigurationSlice";
import { TaxListValues } from "../../../../../../types";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { RedInfoIcon } from "../../../../../../assets/images";
import ListActions from "./ListActions";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";

type Props = {
  deleteListData: (id: number) => void;
};
const TaxRateTableContainer = (props: Props, ref: any) => {
  const { page, itemsPerPage } = useListData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { dispatch, currentUserInfo } = useCommonData();
  const [taxRateData, setTaxRateData] = useState<TaxListValues[]>([]);
  const { taxConfigurationPermission } = usePermissionHandler();

  useImperativeHandle(
    ref,
    () => ({
      fetchTaxRatelist: fetchTaxRatelist,
    }),
    [page]
  );

  useEffect(() => {
    fetchTaxRatelist();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);

  /**
   * Fetch all Tax Lists
   */
  const fetchTaxRatelist = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      taxesList({
        page,
        itemsPerPage,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTaxRateData(response.tax_configuration);
        const length = response.tax_configuration?.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      }
      dispatch(setLoaderState(false));
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Tax Code",
        accessor: "show.taxCode",
        sort: false,
        sortOrder: "",
        className: "taxCode",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              {value.is_account_deleted ? (
                <img
                  src={RedInfoIcon}
                  className="info-icn red"
                  width={"16px"}
                  height={"16px"}
                  alt="No Account"
                />
              ) : null}
              {value.tax_code}
            </>
          );
        },
      },
      {
        Header: "Tax Type",
        accessor: "show.taxType",
        sort: false,
        sortOrder: "",
        className: "type",
      },
      {
        Header: "Account",
        accessor: "show.accountName",
        sort: false,
        sortOrder: "",
        className: "account",
        Cell: ({ cell: { value } }: any) => {
          return value ? value : <div className="s-red">Account Deleted</div>;
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return taxConfigurationPermission.includes("Update") ? (
            <ListActions
              refreshList={fetchTaxRatelist}
              deleteOnClick={() => props.deleteListData(value.taxId)}
              isAccountDeleted={value.isAccountDeleted}
              taxId={value.taxId}
            />
          ) : null;
        },
      },
    ],
    [currentUserInfo.organization_id, taxConfigurationPermission]
  );

  const data = taxRateData
    ? taxRateData.map((tax) => ({
        show: {
          taxCode: tax,
          taxType: tax.account_type,
          accountName: tax.account_name,
          actions: {
            taxCode: tax.tax_code,
            isAccountDeleted: tax.is_account_deleted,
            taxId: tax.id,
          },
        },
      }))
    : [];

  return (
    <TaxRateTable
      data={data}
      columns={columns}
      setPageInfo={(countPerPage, page) => {
        setItemsPerPage(countPerPage);
        setPage(page);
      }}
      refreshList={fetchTaxRatelist}
    />
  );
};

export default React.memo(forwardRef(TaxRateTableContainer));
