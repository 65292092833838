import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { listboxCustomStyle } from "../../../../../common/components/ListBoxCustomStyle";
import TableInfo from "../../../../../common/components/table/TableInfo";
import ItemTable from "./ItemTable";
import { itemList } from "../../../itemSlice";
import { InventoryItemValues, ItemList } from "../../../../../../types";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../../common/components/Delete";
import DeleteItem from "../../Delete";
import {
  fiscalYearFilterSelector,
  setLoaderState,
} from "../../../../../common/commonSlice";
import useCommonData from "../../../../../hooks/useCommon";
import useListData from "../../../../../hooks/useListData";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";

import "./ItemTableContainer.css";
import TableSetup from "../../../../../common/components/table/TableSetup";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import {
  itemColumsValues,
  itemDefaultValues,
  itemMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { useAppSelector } from "../../../../../../app/hooks";
import ActivateItems from "../../../../../common/components/kebabMenu/ActivateItems";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import {
  getItemTypeFilterString,
  getStatusFilterString,
  getTransactionCategoryFilterString,
} from "../../../../../../helpers/statusFilterHelper";
import { TAX_SYSTEM } from "../../../../../constants/constants";
import useGst from "../../../../../hooks/useGst";

function ItemTableContainer(
  {
    onEdit,
    initializeFilter,
  }: {
    onEdit: (data: InventoryItemValues) => void;
    initializeFilter: () => void;
  },
  ref: any
) {
  const { dispatch, currentUserInfo, orgCurrencyList, isLoading } =
    useCommonData();
  const { isGstOrg } = useGst();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { itemsRolePermission } = usePermissionHandler();
  const [itemData, setItemData] = useState<ItemList[]>([]);
  const [deleteItemId, setDeleteItemId] = useState(-1);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "HSN/SAC Code",
  ]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  useImperativeHandle(
    ref,
    () => ({
      fetchItemList: fetchItemList,
    }),
    []
  );

  useEffect(() => {
    fetchTableCustomizationFields(
      "Items",
      itemDefaultValues,
      itemMandatoryValues
    );
  }, [
    page,
    itemsPerPage,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
  ]);
  useEffect(() => {
    let hiddenItems: string[] = [];
    itemColumsValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
      if (
        column === "HSN/SAC Code" &&
        !isGstOrg &&
        !selectedColumns.includes("HSN/SAC Code")
      ) {
        hiddenItems.push(column);
      }
      if (column === "Tax" && isGstOrg && !selectedColumns.includes("Tax")) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [itemData, selectedColumns]);
  useEffect(() => {
    fetchItemList();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);
  useEffect(() => {
    if (isFilterApplied) fetchItemList();
  }, [selectedFilterData, isFilterApplied]);

  const getCurrencyCode = (code: string) => (code === null ? "" : code);

  const fetchItemList = async () => {
    dispatch(setLoaderState(true));

    const responseAction = await dispatch(
      itemList({
        page: page,
        itemsPerPage: itemsPerPage,
        nameSortOrder: "",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        status: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        itemType: selectedFilterData.ItemType
          ? getItemTypeFilterString(selectedFilterData.ItemType)
          : [],
        transactionCategory: selectedFilterData.TransactionCategory
          ? getTransactionCategoryFilterString(selectedFilterData.TransactionCategory)
          : [],
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setItemData(response.items);
        let length = response.items.length;
        setTotalList(response.total);
        if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      }
      dispatch(setLoaderState(false));
    }
  };
  const refreshList = () => {
    fetchItemList();
  };
  const getItemColumnValues = () => {
    let itemColumnvalues = itemColumsValues.filter(
      (item) => item !== "HSN/SAC Code"
    );
    itemColumnvalues = itemColumnvalues.filter((item) => item !== "Tax");
    if (
      isGstOrg &&
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !itemColumnvalues.includes("HSN/SAC Code")
    ) {
      itemColumnvalues.push("HSN/SAC Code");
    }
    if (!isGstOrg && !itemColumnvalues.includes("Tax")) {
      itemColumnvalues.push("Tax");
    }
    return itemColumnvalues;
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Item Name",
        accessor: "show.itemName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
      },
      {
        Header: "Type",
        accessor: selectedColumns.includes("Type") ? "show.type" : "Type",
        sort: false,
        sortOrder: "",
        className: "ref_type",
      },
      {
        Header: "HSN/SAC Code",
        accessor:
          selectedColumns.includes("HSN/SAC Code") &&
          currentUserInfo.organization_tax_system === "INDIAN_TAX_SYSTEM"
            ? "show.hsnCode"
            : "HSN/SAC Code",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Account",
        accessor: selectedColumns.includes("Account")
          ? "show.account"
          : "Account",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Rate",
        accessor: selectedColumns.includes("Rate") ? "show.rate" : "Rate",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Sales Description",
        accessor: selectedColumns.includes("Sales Description")
          ? "show.salesDescription"
          : "Sales Description",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Purchase Account",
        accessor: selectedColumns.includes("Purchase Account")
          ? "show.purchaseAccount"
          : "Purchase Account",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Purchase Rate",
        accessor: selectedColumns.includes("Purchase Rate")
          ? "show.purchaseRate"
          : "Purchase Rate",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Tax",
        accessor: selectedColumns.includes("Tax") ? "show.tax" : "Tax",
        sort: false,
        sortOrder: "",
        className: "email",
      },
      {
        Header: (
          <TableSetup
            type="Items"
            tableColumns={selectedColumns}
            customizeColumnValues={getItemColumnValues()}
            mandatoryColumns={itemMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Items",
                itemDefaultValues,
                itemMandatoryValues
              );
              fetchItemList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (
            <KebabMenu>
              {itemsRolePermission.includes("Update") ? (
                <li>
                  <Edit
                    url={`/items/edit/${value.id}`}
                    isMutable={value.isMutable}
                    name="Item"
                    type="modal"
                    onClick={() => onEdit(value.itemData)}
                    className={"dropdown-item"}
                    isLocked={false}
                    lockDate={""}
                  />
                </li>
              ) : null}
              <li>
                <ActivateItems
                  className={`dropdown-item`}
                  isActive={value.is_active}
                  itemId={value.id}
                  refreshList={refreshList}
                />
              </li>
              {itemsRolePermission.includes("Delete") ? (
                <li>
                  <Delete
                    className={"dropdown-item delete"}
                    deleteIcon={true}
                    isMutable={true}
                    onClick={() => setDeleteItemId(value.id)}
                    isLocked={value.is_associated}
                    lockDate={""}
                    name="Items"
                  />
                </li>
              ) : null}
            </KebabMenu>
          );
        },
      },
    ],
    [
      selectedColumns,
      hiddenColumns,
      currentUserInfo.organization_id,
      itemsRolePermission,
    ]
  );
  /**
   * set table row data
   */
  const data = itemData
    ? itemData.map((item) => ({
        show: {
          itemName: item.name,
          type: item.type,
          hsnCode: item.hsn_or_sac_code,
          account: item.sales_account_name,
          salesDescription: item.sales_description,
          purchaseAccount: item.purchase_account_name,
          rate:
            getCurrencyCode(item.currency_code) +
            " " +
            NumberFormat(item.sales_rate, item.currency_code, orgCurrencyList),
          purchaseRate:
            getCurrencyCode(item.currency_code) +
            " " +
            NumberFormat(
              item.purchase_rate,
              item.currency_code,
              orgCurrencyList
            ),
          tax: item.taxes
            .map((taxData) => `${taxData.tax_percentage}% ${taxData.tax_code}`)
            .join(", "),
          actions: {
            id: item.id,
            isMutable: true,
            itemData: item,
            is_associated: item.is_associated,
            is_active: item.is_active,
          },
        },
      }))
    : [];

  return (
    <>
      <Card variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper items-list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />

          <Grid sx={{ width: "100%" }}>
            <ItemTable
              columns={columns}
              data={data}
              isLoading={isLoading}
              itemsRolePermission={itemsRolePermission}
              hiddenColums={hiddenColumns}
              isFilterApplied={isFilterApplied}
              initializeFilter={initializeFilter}
            />
          </Grid>
        </Box>
      </Card>

      <DeleteItem
        deleteId={deleteItemId}
        refresh={fetchItemList}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
}

export default React.memo(forwardRef(ItemTableContainer));
