import { SvgIconProp } from "../../../../types";

export const DeleteIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20"}
      height={props.height ? props.height : "20"}
      viewBox="0 0 20 20"
      style={{ margin: props.margin ? props.margin : "" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={"none"} fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M5.833 3.333V1.667h8.334v1.666h4.166V5h-1.666v12.5c0 .46-.373.833-.834.833H4.167a.833.833 0 0 1-.834-.833V5H1.667V3.333h4.166zM5 5v11.667h10V5H5zm2.5 2.5h1.667v6.667H7.5V7.5zm3.333 0H12.5v6.667h-1.667V7.5z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
