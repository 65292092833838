import React from "react";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import EmptyReport from "../../../../common/components/EmptyReport";
import { TableData } from "../../../../../types";
import { Cell, Row } from "react-table";

type Props = {
  columns: any[];
  data: TableData[];
  className?: string;
  navigation?: (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => void;
  haveColumnClassName: boolean;
  hiddenColumns: string[]
};
function AgingReportTable({ columns, data, className, navigation, haveColumnClassName,hiddenColumns }: Props) {
  return (
    <div className="table-wrapper">
      <DetailsTableContainer
        columns={columns}
        data={data}
        className={`table report-table soa-table aging-report-table ${className}`}
        hiddenColumns={["action", ...hiddenColumns]}
        navigation={navigation}
        haveColumnClassName={haveColumnClassName}
      >
        <EmptyReport heading="No transactions found!" alt="Empty Report" />
      </DetailsTableContainer>
    </div>
  );
}

export default AgingReportTable;
