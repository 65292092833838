import React from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Row } from "react-table";
import { TableData } from "../../../../types";
import Table from "../table/Table";

type Props = {
  columns: any[];
  data: TableData[];
  className: string;
  hiddenColumns?: string[];
  children?: React.ReactNode;
  navigation?: (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => void;
  rowNavigation?: (row: Row<TableData>) => void;
  haveColumnClassName?: boolean;
};

export default function DetailsTableContainer(props: Props) {
  const navigate = useNavigate();

  const detailViewHandler = (action: {
    url: string;
    permission: boolean;
    customNavigation?: boolean;
  }) => {
    if (action.url) navigate(action.url);
  };

  const tbodyRowOnClick = (row: Row<TableData>) => {
    if (row.values.action.customNavigation && props.rowNavigation)
      props.rowNavigation(row);
  };

  return (
    <Table
      id={""}
      columns={props.columns}
      data={props.data}
      tableClassName={props.className}
      dataSort={false}
      isLoading={false}
      haveColumnClassName={
        props.haveColumnClassName ? props.haveColumnClassName : false
      }
      rowClassName={"table-navigation"}
      emptyClassName={"text-center dataTables_empty"}
      hiddenColumns={props.hiddenColumns}
      tbodyRowOnClick={tbodyRowOnClick}
      tbodyColumnClick={props.navigation ? props.navigation : () => {}}
    >
      {props.children}
    </Table>
  );
}
