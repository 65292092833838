import Highlighter from "react-highlight-words";
import escapeStringRegexp from "escape-string-regexp";
import "../../../../index.css";
import "./TextHighlighter.css";

type Props = {
  text: string;
  query: string;
  textClassName: string;
  maxLength: number;
};
const HighlightedText = ({
  text = "",
  query,
  textClassName,
  maxLength,
}: Props) => {
  text = text || "";
  const escapedQuery = escapeStringRegexp(query);
  const regex = new RegExp(escapedQuery, "gi");
  const matches =
    text.toString()?.matchAll(regex) &&
    Array.from(text.toString()?.matchAll(regex));
  if (matches?.length === 0 || query === "") {
    return (
      <span>
        {text.length > maxLength
          ? `${text?.toString()?.substring(0, maxLength - 3)}...`
          : text}
      </span>
    );
  }
  const firstMatchIndex = (matches && matches[0].index) || 0;
  const start = Math.max(0, firstMatchIndex - maxLength / 2 + query.length / 2);
  const end = Math.min(text?.length, start + maxLength);
  let result = text?.toString().substring(start, end);

  if (start > 0) {
    result = "..." + result;
  }
  if (end < text?.length) {
    result = result + "...";
  }
  return (
    <span className="value">
      <Highlighter
        highlightClassName="search-highlighted-text "
        searchWords={[query]}
        autoEscape={true}
        textToHighlight={result || ""}
        className={`${textClassName} text-highlighted`}
        highlightStyle={{
          padding: "0px",
        }}
      />
    </span>
  );
};

export default HighlightedText;
