import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { BillingSideMenuModal } from "../../calendarBillingSideMenu/BillingSideMenuModal";
import Delete from "../../../../common/components/Delete";

export type ListActionProps = {
  value: any;
  getSiteWideBilling: any;
  onClickDelete?: () => void;
};

function ListActions(props: ListActionProps) {
  const { subscriptionRolePermission } = usePermissionHandler();
  return (
    <>
      {subscriptionRolePermission.includes("Update") ? (
        <KebabMenu>
          <li>
            <BillingSideMenuModal
              id={props.value.id}
              getSiteWideBilling={props.getSiteWideBilling}
            />
          </li>

          {subscriptionRolePermission.includes("Delete") ? (
            <li>
              <Delete
                className="dropdown-item delete"
                deleteIcon={true}
                isMutable
                onClick={props.onClickDelete ? props.onClickDelete : () => {}}
                isLocked={false}
                lockDate={""}
                name="Side Wide Billing"
              />
            </li>
          ) : null}
        </KebabMenu>
      ) : null}
    </>
  );
}

export default ListActions;
