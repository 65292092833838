import React from "react";
import "./FiscalYearClosingHeader.css";

const FiscalYearClosingHeader = (props: { children: React.ReactNode }) => {
  return (
    <div className="fiscal-year-closing-header-wrapper">
      <div className="card card-user-management card-customer card-lock-transaction main-card overflowX-hidden h-100">
        <div className="card-header lock-transaction-card-header list-card-header p-0 pbottom-14 border-0">
          <h1 className="mtop-7">Fiscal Year Closing</h1>
        </div>
        <div className="card-body">
          <div className="card-content-wrapper-fiscal-year">
            <ul className="lock-item-list m-0 p-0 list-style-none w-100">
              {props.children}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiscalYearClosingHeader;
