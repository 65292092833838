import { CustomFields } from "./customFields";

export const CustomFieldLayout = () => {
  return (
    <div className="card card-user-management main-card overflowX-hidden h-100">
      <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
        <div className="header-wrap">
          <h1>Custom Fields</h1>
        </div>
      </div>
      <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
        <div className="card-body">
          <CustomFields />
        </div>
      </div>
    </div>
  );
};
