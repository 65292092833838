//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { DollarBlock, WalletBlock } from "../../../../assets/images";
import "../styles/BarGraph.css";
import { Bar } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  currentUserSelector,
  loaderStateSelector,
  orgCurrencyListSelector,
} from "../../../common/commonSlice";
import { getIncomeExpenseTotal } from "../../chartOfAccounts/coaSlice";
import Select from "react-select";
import { barGraphCustomSelectStyle } from "../../../common/components/BarGraphCustomSelectStyle";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import { getFormatedDate } from "../../../../helpers/helper";
import { getFiscalYear } from "../../../../helpers/fiscalYearHelper";

import Loader from "../../components/Loader";
import { colors } from "../../../constants/colors";
import moment from "moment";
import {
  NumberFormat,
  NumDifferentiation,
} from "../../../../helpers/numberFormatHelper";
import CustomTooltips from "../../../common/components/Tooltip";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
type Props = {
  currencySymbol: string;
};
export default function BarGraph(props: Props) {
  const mobDevices = useMediaQuery("(max-width: 767px)");
  const totalInitialValues = {
    expense_total: 0,
    income_total: 0,
    graph_data: [
      {
        expense_total: 0,
        income_total: 0,
        month_year: "",
      },
    ],
  };
  let currentDate = new Date();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const loaderState = useAppSelector(loaderStateSelector);
  const dispatch = useAppDispatch();
  const [totalIncomeExpense, setTotalIncomeExpense] =
    useState(totalInitialValues);
  const [startDate, setStartDate] = useState(
    moment().startOf("months").subtract(5, "month").toDate()
  );
  const [dateRange, setDateRange] = useState({
    startDate: getFormatedDate(
      moment().startOf("months").subtract(5, "month").toDate()
    ),
    endDate: getFormatedDate(currentDate),
  });
  const [startMonthYear, setStartMonthYear] = useState("");
  const [endMonthYear, setEndMonthYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!isLoading && !loaderState) {
      setLoader(false);
    }
  }, [isLoading, loaderState]);

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    ) {
      getTotalIncomeExpenseAmount();
    }
  }, [
    currentUserInfo.organization_id,
    currentUserInfo.organization_currency,
    startDate,
    dateRange,
  ]);

  const getTotalIncomeExpenseAmount = async () => {
    setIsLoading(true);
    const data = {
      currencyId: currentUserInfo.organization_currency,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      orgId: currentUserInfo.organization_id,
    };
    const responseAction = await dispatch(getIncomeExpenseTotal(data));
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
    }
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTotalIncomeExpense(response);
      response.graph_data.map((item: any, index: number) => {
        if (index === 0) {
          setStartMonthYear(item.month_year);
        }
        if (index === response.graph_data.length - 1) {
          setEndMonthYear(item.month_year);
        }
      });
    }
  };

  const barData = {
    lines: [
      [
        totalIncomeExpense.graph_data.map((total) => {
          return total.income_total;
        }),
        totalIncomeExpense.graph_data.map((total) => {
          return total.expense_total;
        }),
      ],
      [
        totalIncomeExpense.graph_data.map((total) => {
          return total.income_total;
        }),
        totalIncomeExpense.graph_data.map((total) => {
          return total.expense_total;
        }),
      ],
    ],
    labels: totalIncomeExpense.graph_data.map((item) => {
      return item.month_year;
    }),
    datasets: [
      {
        data: totalIncomeExpense.graph_data.map((total) => {
          return total.income_total;
        }),
        backgroundColor: colors.nebulaBlue,
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
      {
        data: totalIncomeExpense.graph_data.map((total) => {
          return total.expense_total;
        }),
        backgroundColor: colors.mediumAquamarine,
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
    ],
  };
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);
  useEffect(() => {
    const tooltipEl = document.createElement("div");
    tooltipEl.classList.add("custom-tooltip");
    document.body.appendChild(tooltipEl);
    tooltipRef.current = tooltipEl;

    return () => {
      document.body.removeChild(tooltipEl);
      tooltipRef.current = null;
    };
  }, []);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
      custom: function (tooltipModel: any) {
        const tooltipEl = tooltipRef.current;
        const containerEl = containerRef.current;

        if (!tooltipEl || !containerEl) return;

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.display = "none";
          return;
        }
        const tooltipItems = tooltipModel.dataPoints;
        let itemIndex: any;
        tooltipItems.forEach(function (tooltipItem: any) {
          // Get the index of the tooltip item
          itemIndex = tooltipItem.index;
        });

        if (tooltipModel.body) {
          var incomeLabel = `<span style="background: ${
            barData.datasets[0].backgroundColor
          };display: inline-block;width: 10px;height: 10px;border-radius: 2px;
          margin-right: 6px;"></span>
          <span style='color: ${barData.datasets[0].backgroundColor};'>
          Income: ${NumberFormat(
            barData.datasets[0].data[itemIndex],
            props.currencySymbol,
            orgCurrencyList
          )}</span>`;

          var expenseLabel = `<span style="background: ${
            barData.datasets[1].backgroundColor
          };display: inline-block;width: 10px;height: 10px;border-radius: 2px;
          margin-right: 6px;"></span>
          <span style='color: ${barData.datasets[1].backgroundColor} ;'>
            Expense: ${NumberFormat(
              barData.datasets[1].data[itemIndex],
              props.currencySymbol,
              orgCurrencyList
            )}</span>`;

          tooltipEl.innerHTML = `
          <div class="tooltip-container">
            <div className="tooltip-title" style="color: #fff;
            font-size: 14px;
            font-weight: 700;
            padding-bottom: 5px;">${tooltipItems[0].label}</div>
            <div className="income-tooltip">${incomeLabel}</div>
            <div className="expense-tooltip">${expenseLabel}</div>
          </div>
          <span class="tooltip-arrow"></span>
          `;
        }
        const containerBounds = containerEl.getBoundingClientRect();
        const tooltipBounds = tooltipEl.getBoundingClientRect();
        const tooltipArrow = tooltipEl.querySelector(".tooltip-arrow");

        tooltipEl.style.display = "block";
        tooltipEl.style.position = "absolute";

        const tooltipWidth = tooltipBounds.width;
        const tooltipHeight = tooltipBounds.height;

        let tooltipLeft =
          containerBounds.left + tooltipModel.caretX - tooltipWidth / 2;
        let tooltipTop =
          containerBounds.top +
          tooltipModel.caretY -
          tooltipHeight +
          window.scrollY;

        // Check if the tooltip is on the right side of the bar
        if (tooltipLeft + tooltipWidth > containerBounds.right) {
          tooltipLeft = containerBounds.right - tooltipWidth;
          tooltipArrow.style.left = `${
            tooltipModel.caretX - (containerBounds.right - tooltipLeft)
          }px`;
        } else if (tooltipLeft < containerBounds.left) {
          // Check if the tooltip is on the left side of the bar
          tooltipLeft = containerBounds.left;
          tooltipArrow.style.left = `${tooltipModel.caretX - tooltipLeft}px`;
        } else {
          tooltipArrow.style.left = `${
            tooltipWidth / 2 - tooltipArrow.offsetWidth / 2
          }px`;
        }

        tooltipEl.style.left = `${tooltipLeft}px`;
        tooltipEl.style.top = `${tooltipTop}px`;
        tooltipEl.style.pointerEvents = "none";

        // Adjust tooltip arrow position
        tooltipArrow.style.top = `${tooltipHeight}px`;
      },
    },

    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            borderDash: [3, 3],
            zeroLineColor: colors.nebulaBlueNew,
          },
          ticks: {
            beginAtZero: true,
            color: colors.slate,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            drawBorder: false,
            zeroLineColor: colors.slate,
            lineColor: colors.approxWhisper,
            zeroLineWidth: 2,
          },
          ticks: {
            beginAtZero: true,
            color: colors.slate,
            padding: 6,
            callback: function (value: any, index: any, values: any) {
              return NumDifferentiation(value, props.currencySymbol);
            },
            // min: 0,
            // stepSize: 200,
            // max: 1000,
          },
        },
      ],
    },
  };
  const incomeBg = {
    background: colors.nebulaBlue,
  };
  const expenseBg = {
    background: colors.mediumAquamarine,
  };
  const datasetKeyProvider = () => {
    return Math.random();
  };
  const filterOptions = [
    { value: "last-6", label: "Last 6 months" },
    { value: "last-12", label: "Last 12 months" },
    { value: "previous-fiscal-year", label: "Previous fiscal year" },
    { value: "this-fiscal-year", label: "This fiscal year" },
  ];
  const handleDateChange = (e: any) => {
    const currentDate = new Date();
    switch (e.value) {
      case "last-6":
        setDateRange({
          startDate: getFormatedDate(
            moment().startOf("months").subtract(5, "month").toDate()
          ),
          endDate: getFormatedDate(new Date()),
        });

        break;
      case "last-12":
        setDateRange({
          startDate: getFormatedDate(
            moment().startOf("months").subtract(11, "month").toDate()
          ),
          endDate: getFormatedDate(new Date()),
        });
        break;
      case "previous-fiscal-year":
        setDateRange(
          getFiscalYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "previous"
          )
        );
        break;
      case "this-fiscal-year":
        setDateRange(
          getFiscalYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "current"
          )
        );
        break;
    }
  };

  return (
    <>
      <div className="graph-container bargraph-container">
        <div className="graph-legend-filter-section w-100">
          <div className="header-wrapper">
            <div className="header-legend-wraper">
              <h3>Income and Expense</h3>
            </div>

            <div className="action-wrapper">
              <div className="select-wrapper">
                <Select
                  name="payment_term_id"
                  id="terms"
                  options={filterOptions}
                  className="currency-select form-select custom-select selectpicker"
                  styles={
                    mobDevices ? customSelectStyle : barGraphCustomSelectStyle
                  }
                  isSearchable={false}
                  onChange={(e: any) => {
                    handleDateChange(e);
                  }}
                  defaultValue={{
                    label: filterOptions.filter((item) => {
                      return item.label === "Last 6 months";
                    })[0].label,
                    value: "last-6",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="graph-legend-section w-100">
          <div className="graph-section position-relative">
            <ul className="legend-list position-absolute">
              <li className="income">
                <span className="legend-color" style={incomeBg}></span>Income
              </li>
              <li className="expense">
                <span className="legend-color" style={expenseBg}></span>
                Expense
              </li>
            </ul>
            <div
              className="graph-wrapper"
              id="bar-graph-wrapper"
              ref={containerRef}
            >
              <Bar
                ref={chartRef}
                width={1500}
                height={400}
                data={barData}
                options={options}
                datasetKeyProvider={datasetKeyProvider}
              />
            </div>
          </div>
          <div className="legend-section">
            <div className="summary-container income-summary blue-cloud-bg">
              <div className="img-container">
                <img
                  src={DollarBlock}
                  width="65"
                  height="65"
                  alt="income icon"
                />
              </div>
              <div className="summary-section">
                <span className="title">Total Income</span>
                <CustomTooltips
                  placement="right"
                  title={
                    props.currencySymbol +
                    " " +
                    NumberFormat(
                      totalIncomeExpense.income_total,
                      props.currencySymbol,
                      orgCurrencyList
                    )
                  }
                >
                  <span className="amt-summary">
                    {props.currencySymbol}{" "}
                    {NumDifferentiation(
                      totalIncomeExpense.income_total,
                      props.currencySymbol,
                      decimalPlaceOfCurrency(
                        props.currencySymbol,
                        orgCurrencyList
                      ),
                      orgCurrencyList
                    )}
                  </span>
                </CustomTooltips>
                <span className="daterange-summary">
                  {startMonthYear} - {endMonthYear}
                </span>
              </div>
            </div>
            <div className="summary-container expense-summary green-cloud-bg">
              <div className="img-container">
                <img
                  src={WalletBlock}
                  width="65"
                  height="65"
                  alt="income icon"
                />
              </div>
              <div className="summary-section">
                <span className="title">Total Expense</span>
                <CustomTooltips
                  placement="right"
                  title={
                    props.currencySymbol +
                    " " +
                    NumberFormat(
                      totalIncomeExpense.expense_total,
                      props.currencySymbol,
                      orgCurrencyList
                    )
                  }
                >
                  <span className="amt-summary">
                    {props.currencySymbol}{" "}
                    {NumDifferentiation(
                      totalIncomeExpense.expense_total,
                      props.currencySymbol,
                      decimalPlaceOfCurrency(
                        props.currencySymbol,
                        orgCurrencyList
                      ),
                      orgCurrencyList
                    )}
                  </span>
                </CustomTooltips>
                <span className="daterange-summary">
                  {startMonthYear} - {endMonthYear}
                </span>
              </div>
            </div>
          </div>
        </div>
        {loader || isLoading ? <Loader /> : null}
      </div>
    </>
  );
}
