import { ObjectType } from "../types";
import { IsFeatureEnabled } from "./featureFlagHelper";

export const checkSubModuleAccessibility = (
  module: string | string[],
  subModulePermission: any[],
  disabledFeatures: ObjectType[]
) => {
  if (module) {
    if (typeof module === "string") {
      return subModulePermission.includes(module) &&
        IsFeatureEnabled(module, disabledFeatures)
        ? true
        : false;
    } else {
      return module.filter(
        (value) =>
          subModulePermission.includes(value) &&
          IsFeatureEnabled(value, disabledFeatures)
      ).length > 0
        ? true
        : false;
    }
  }
};
export const checkModuleAccessibility = (
  modules: string[],
  subModulePermission: any[],
  disabledFeatures: ObjectType[]
) => {
  return modules.filter(
    (module) =>
      subModulePermission.includes(module) &&
      IsFeatureEnabled(module, disabledFeatures)
  ).length > 0
    ? true
    : false;
};
