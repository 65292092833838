import React, { useEffect, useState } from "react";
import { ReopenFiscalYears } from "../fiscalYearClosingAPI";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { useMeApiTrigger } from "../../../hooks/useTriggerOrgDetails";
import { fetchStartDate } from "../utils/helper";
import YearButton from "../../../common/components/YearButton";
import useCommonData from "../../../hooks/useCommon";
import "./ReopenFiscalYear.css";

function ReopenFiscalYear(props: {
  accounting_start_date: string;
  accountClosingDate: string;
  fiscalYearEndDate: string;
  onCloseModal: () => void;
}) {
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [accountingStartDate, setAccountingStartDate] =
    useState<string | null>(null);
  const { currentUserInfo } = useCommonData();
  const { triggerMeApi } = useMeApiTrigger();

  useEffect(() => {
    const getFiscalYears = (startDate: string, endDate: string) => {
      const startYear = parseInt(startDate.split("-")[0], 10);
      const endYear = parseInt(endDate.split("-")[0], 10);
      const startParts = startDate.split("-");
      const startMonthDay = startParts[1] + startParts[2];
      const endParts = endDate.split("-");
      const endMonthDay = endParts[1] + endParts[2];
      const fiscalYearsList = [];
      if (endMonthDay === "1231") {
        for (let year = startYear; year <= endYear; year++) {
          fiscalYearsList.push(`${year} - ${year}`);
        }
      } else if (startYear === endYear) {
        for (let year = startYear - 1; year <= endYear - 1; year++) {
          fiscalYearsList.push(`${year} - ${year + 1}`);
        }
      } else if (startMonthDay < endMonthDay) {
        for (let year = startYear - 1; year <= endYear - 1; year++) {
          fiscalYearsList.push(`${year} - ${year + 1}`);
        }
      } else {
        for (let year = startYear; year <= endYear - 1; year++) {
          fiscalYearsList.push(`${year} - ${year + 1}`);
        }
      }
      return fiscalYearsList.reverse();
    };

    const fiscalYearsList = getFiscalYears(
      props.accounting_start_date,
      props.accountClosingDate
    );
    setYears(fiscalYearsList);
  }, [props.accounting_start_date, props.accountClosingDate]);

  const toggleYearSelection = (year: string) => {
    setSelectedYears((prevSelectedYears) => {
      const index = years.indexOf(year);
      let newSelectedYears;
      if (prevSelectedYears.includes(year)) {
        newSelectedYears = prevSelectedYears.filter(
          (y) => years.indexOf(y) < index
        );
      } else {
        newSelectedYears = [...prevSelectedYears, year];
      }
      return newSelectedYears;
    });
  };

  const handleSubmit = async () => {
    const endDate = props.fiscalYearEndDate.slice(5);
    const formattedYears = selectedYears.map((y) => {
      const startYear = parseInt(y.split(" - ")[0], 10);
      if (endDate === "12-31") {
        return `${startYear}-${endDate}`;
      } else {
        return `${startYear + 1}-${endDate}`;
      }
    });
    const data = {
      orgId: currentUserInfo.organization_id,
      closing_date: formattedYears,
    };
    try {
      await ReopenFiscalYears(data);
      SuccessToaster(
        "Account reopened successfully",
        "fiscal-year-reopen-success"
      );
      await fetchStartDate(
        currentUserInfo.organization_id,
        setAccountingStartDate
      );
      triggerMeApi();
      props.onCloseModal();
    } catch (error) {}
  };

  return (
    <div className="reopen-fiscal-year-modal-wrapper">
      <div className="select-groups">Select the group of years to reopen</div>

      <div className="button-grp">
        {years.map((year, index) => (
          <YearButton
            key={year}
            label={year}
            isSelected={selectedYears.includes(year)}
            isDisabled={index > 0 && !selectedYears.includes(years[index - 1])}
            onClick={() => toggleYearSelection(year)}
          />
        ))}
      </div>
      <div className="row mb-0">
        <div className="col">
          <div>
            <button
              className="save-button"
              type="submit"
              onClick={handleSubmit}
            >
              Reopen
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={props.onCloseModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReopenFiscalYear;
