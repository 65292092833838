import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import RoleTable from "./RoleTable";
import CustomTooltips from "../../../../../../common/components/Tooltip";
import { IsFeatureEnabled } from "../../../../../../../helpers/featureFlagHelper";
import useCommonData from "../../../../../../hooks/useCommon";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { userRolesList } from "../../../../userSlice";
import { RoleList } from "../../../../../../../types";
import ListActions from "../../../../../../common/components/list/ListActions";
import useListData from "../../../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../../../hooks/usePaginationDispatch";
type Props = {
  deleteListData: (
    id: number,
    assignedUsersCount: number,
    name: string
  ) => void;
};
const RoleTableContainer = (props: Props, ref: any) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { setRoleSortOrder } = usePaginationDispatch();
  const { roleSortOrder } = useListData();
  const [roles, setRoles] = useState<RoleList[]>([]);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let rolePermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Roles")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useImperativeHandle(
    ref,
    () => ({
      fetchUserRolesList: fetchUserRolesList,
    }),
    []
  );

  useEffect(() => {
    fetchUserRolesList(roleSortOrder);
  }, [currentUserInfo.organization_id, roleSortOrder]);
  const roleSortHandler = () => {
    if (roleSortOrder === "ASC") {
      setRoleSortOrder("DESC");
    } else {
      setRoleSortOrder("ASC");
    }
  };
  const fetchUserRolesList = async (roleSort: string) => {
    if (IsFeatureEnabled("Roles", currentUserInfo.disabled_feature)) {
      dispatch(setLoaderState(true));

      const responseAction = await dispatch(
        userRolesList({
          roleSort: roleSort,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setRoles(response);
        }
        dispatch(setLoaderState(false));
      }
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "ROLE",
        accessor: "show.name",
        sort: true,
        sortOrder:
          roleSortOrder === "ASC"
            ? "ascending"
            : roleSortOrder === "DESC"
            ? "descending"
            : "",
        className: "name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement="bottom"
              title={value?.name.length > 15 ? value?.name : ""}
            >
              <span
                className={value?.is_editable ? "ellipsis" : "ellipsis locked"}
              >
                {value?.name}
              </span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "DESCRIPTION",
        accessor: "show.description",
        sort: false,
        sortOrder: "",
        className: "name",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return rolePermission.includes("Read") ? (
            <ListActions
              id={value.id}
              name={"Role"}
              isEdit={true}
              isDelete={true}
              isMutable={true}
              isLocked={false}
              allowDelete={true}
              roles={rolePermission}
              editUrl={`edit/${value.id}`}
              cloneUrl={`clone/${value.id}`}
              deleteIconPresent={true}
              editOnClick={() => {}}
              deleteOnClick={() =>
                props.deleteListData(
                  value.id,
                  value.assignedUsersCount,
                  value.name
                )
              }
              disabledEditClassName={
                !value.isEditable || !value.currentOrgRole
                  ? "disabled-menu-item"
                  : ""
              }
              disabledClassName={
                !value.isEditable || !value.currentOrgRole
                  ? "disabled-menu-item"
                  : ""
              }
              assignedUserCount={value.assignedUsersCount}
            />
          ) : null;
        },
      },
    ],
    [currentUserInfo.country_id, roleSortOrder]
  );
  const data = roles.length
    ? roles.map((role) => ({
        show: {
          name: role,
          description: role.description,
          actions: {
            id: role.id,
            isEditable: role.is_editable,
            currentOrgRole: role.current_org_role,
            assignedUsersCount: role.assigned_users_count,
            name: role.name,
          },
        },
      }))
    : [];
  return (
    <RoleTable columns={columns} data={data} sortHandler={roleSortHandler} />
  );
};

export default React.memo(forwardRef(RoleTableContainer));
