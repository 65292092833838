import { useState } from "react";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import "./billingSideMenuForm.css";
import useCommonData from "../../../hooks/useCommon";
import { setLoaderState } from "../../../common/commonSlice";
import {
  siteWideCalenderEditDetails,
} from "../calenderBillingSlice";
import { Link } from "react-router-dom";
import { EditDropdownIcon } from "../../../common/components/customSvgIcons/editDropdownIcon";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { SiteWideInvoicingInitialType } from "../calendarBillingTypes";
import { BillingSideMenuForm } from "./BillingSideMenuForm";

type Props = {
  getSiteWideBilling: () => void;
  savedFrequencies?: string[];
  id?: number}
export const BillingSideMenuModal = (props: Props) => {
  const { subscriptionRolePermission } = usePermissionHandler();
  const { dispatch, currentUserInfo } = useCommonData();
  const [initialValues, setInitialValues] = useState<SiteWideInvoicingInitialType>({
    select_frequency: "",
    set_recurring_invoicing_type: "AUTO_SET",
    set_date: "",
    set_day: "",
    selectedFrequencyInEdit: ''
  });

  async function getSideWideBillingDetails() {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      siteWideCalenderEditDetails({
        orgId: currentUserInfo.organization_id,
        calendarInvoicingId: props.id ? props.id : 0,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setInitialValues({
        select_frequency: response.invoicing_frequency,
        set_recurring_invoicing_type: response.invoicing_type,
        set_date:  response.set_date ? response.set_date : '',
        set_day: response.set_day ? response.set_day : '',
        selectedFrequencyInEdit: response.invoicing_frequency
      });
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  }

  const ConfigureButton = () => {
    return (
      <>
        {props.id ? (
          <Link to="#" className="dropdown-item billing-side-wide-edit-button" onClick={() => getSideWideBillingDetails()}>
            <>
              <EditDropdownIcon />
              Edit
            </>
          </Link>
        ) : (
          <div>
           {subscriptionRolePermission.includes("Create") ? <button
              id="calendar-billing-button"
              className="btn create-invoice-button green-button right-button sub-add-coupon-btn-green-background"
              type="button"
            >
              <img
                src="/static/media/plus_small.2f281160561f3c06b529f10af809e435.svg"
                className="Group"
                alt="plus-small-img"
              />
              <span className="create-invoice-button-text green-button-text">
                Create Site-Wide Calendar Invoicing
              </span>
            </button> : null}
          </div>
        )}
      </>
    );
  };

  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onSaveClose } = context;
            return (
            <BillingSideMenuForm
            getSiteWideBilling={() => props.getSiteWideBilling()}
            savedFrequencies={props.savedFrequencies}
            id={props.id}
            onCloseModal={onSaveClose}
            initialValues={initialValues}
            />
            );
          }}
        </ModalContext.Consumer>
      }
      onCloseFunction={() => {}}
      modalTitle="Site-Wide Invoicing"
      isButtonDisabled={false}
      buttonContent={<ConfigureButton />}
      drawerWrapperClass="calendar-billing billing-and-proration-modal"
      wrapperClass={`${
        !props.id ? "calendar-billing-button" : "calendar-billing-button-edit"
      } w-100 d-flex`}
      buttonClass={`add-coupon-button add-tags-button`}
    />
  );
};
