import React, { useEffect, useRef, useState } from "react";
import { Transactionlimit } from "../../../../../src/types";
import "../../invoice/Invoice.css";
import "../../../common/styles/ListTable.css";
import { getTransactionLimitStatus } from "../../invoice/invoiceSlice";
import DeleteInvoiceItem from "../../invoice/components/Delete";
import Pagination from "../../../common/components/table/Pagination";
import TransactionLimitModal from "../../../common/components/TransactionLimitModal";
import ListHeader from "../../../common/components/list/ListHeader";
import useCommonData from "../../../hooks/useCommon";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import ProformaInvoiceTableContainer from "./proformaInvoiceTable/ProformaInvoiceTableContainer";
import "./ProformaList.css";
import DeleteProformaInvoice from "../Delete";

const ProformaInvoiceList = () => {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { invoicesRolePermission } = usePermissionHandler();
  const [deleteProformaInvoiceItemId, setDeleteProformaInvoiceItemId] =
    useState("");
  const [deleteProformaInvoiceId, setDeleteProformaInvoiceId] = useState(-1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  useEffect(() => {
    fetchTransactionLimit();
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * set proforma invoice id and proforma invoice item id when user executes Delete invoice
   */
  const deleteListData = (id: number, listItemId: string) => {
    setDeleteProformaInvoiceId(id);
    setDeleteProformaInvoiceItemId(listItemId);
  };

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  const refreshList = () => {
    filterRef.current.refreshFilter();
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Proforma Invoice"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Proforma Invoice"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/proforma-inv/create");
          }}
          haveFilter={true}
          filterType="ProformaInvoice"
          applyFilter={handleApplyFilter}
          roles={invoicesRolePermission}
          ref={filterRef}
          module="ProformaInvoice"
          isFiscalYearFilterRequired={false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <ProformaInvoiceTableContainer
            transactionLimit={transactionLimit}
            initializeFilter={refreshList}
            isFilterApplied={isFilterApplied}
            deleteListData={deleteListData}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteProformaInvoice
        deleteInvoiceId={Number(deleteProformaInvoiceId)}
        deleteInvoiceItemId={deleteProformaInvoiceItemId}
        refreshInvoices={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
};

export default ProformaInvoiceList;
