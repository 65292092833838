import "./transactionHeader.css";

import { useNavigate } from "react-router-dom";
import BackButton from "../../../BackButton";
import TransactionNumber from "../../../TransactionNumber";

type Props = {
  title: string;
  transactionNumber: number;
};
const TransactionHeader = (props: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <h1 className="fw-bold m-0">{props.title}</h1>
      {props.transactionNumber ? (
        <TransactionNumber transactionNumber={props.transactionNumber} />
      ) : null}
    </>
  );
};

export default TransactionHeader;
