import React from "react";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import { getReportingTagList } from "../reportingTagAPIFiles/reportingTagSlice";

export const useFetchReportingTagList = () => {
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { currentUserInfo, dispatch } = useCommonData();
  const getReportingTags = async (
    setReportingTagsList: React.SetStateAction<any>
  ) => {
    if (currentUserInfo.is_tags_enabled) {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        getReportingTagList({
          orgId: currentUserInfo.organization_id,
          pageNum: page,
          pageSize: itemsPerPage,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        setReportingTagsList(response.tags || []);
        setTotalList(response.total || 0);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount(
          itemsPerPage * page < response.total
            ? itemsPerPage * page
            : response.total
        );
        dispatch(setLoaderState(false));
      }
      dispatch(setLoaderState(false));
    } else {
      setReportingTagsList([]);
      setTotalList(0)
    }
  };
  return { getReportingTags };
};
