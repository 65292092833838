import { useEffect, useState } from "react";
import { CloseImg, GrowthIcon, VendorIcon } from "../../../assets/images";
import "./NotificationModal.css";
import { colors } from "../../constants/colors";
// import {
//   CreateTaxValues,
//   ObjectType,
//   SubAccountValues,
// } from "../../../../types";

type Props = {
  
};
function NotificationModal() {
  

  /**
   * Store the input field values of the selected tax for edit purpose
   */
  useEffect(() => {
    
  }, []);

  /**
   * Create / edit tax form submit handler
   */
  const handleCreateTax = (e: any) => {
    e.preventDefault();
    
  };

 


  

  useEffect(() => {
    //setBackdropHeight();
  }, []);

  
// const setBackdropHeight = () => {
    $(function() {
        $('#notificationModal').on('show.bs.modal', function() {
            setTimeout(()=>{
                $('.modal-backdrop').css('top', '75px');
            }, 5);            
        });
        $('#notificationModal').on('hide.bs.modal', function() {
            setTimeout(()=>{
                $('.modal-backdrop').css('top', '0');
            }, 5);            
        });
    });
    
//}




 

  /**
   * Reset the values while closing Create / Edit tax form modal
   */
//   $(".modal .close-btn")
//     .off()
//     .on("click", function () {
//       setTax("");
//       setIsSelectTax(false);
//       setCreateNewTaxValues(initialTaxValues);
//       setBackendError({});
//       setFormErrors({});
//       props.taxIdReset();
//       setAccountSelected(false);
//       $("#form-btn-tax").removeClass("customer-form-section-disable");
//     });

  return (
    <>
      <div
        className="modal fade right"
        id="notificationModal"
        tabIndex={-1}
        aria-labelledby="notificationModalLabel"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-fullscreen w-100">
          <div className="modal-content">
            <div className="modal-header close-button-container">                
                
                <h4 id="notificationModalLabel">Notifications</h4>
                
                <button
                    type="button"
                    className="close-btn pull-right"
                    data-bs-dismiss="modal"
                    id="close-modal"
                    aria-label="Close"
                >
                    {/* <img src={CloseImg} width="24px" alt="close modal" /> */}
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                          <path d="M0 0h24v24H0z"/>
                          <path fill={colors.slate} fillRule="nonzero" d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
                      </g>
                  </svg>
                </button>
            </div>
            <div className="modal-body">

              <div className="notification-conatiner">
                <div className="notification-header">Today</div>
                <ul className="notification-list">
                    <li className="notification-item">
                        <div className="notification">
                            <img src={GrowthIcon} alt="notification icon" width="28" height="28" />
                            <div className="notification-details">
                                <span className="notification-title">Invoice created</span>
                                <span className="notification-desc">An Invoice number INV-234 is created by john</span>
                                <span className="notification-date">Today at 12:30 PM</span>
                            </div>
                        </div>                        
                    </li>
                    <li className="notification-item">
                        <div className="notification">
                            <img src={GrowthIcon} alt="notification icon" width="28" height="28" />
                            <div className="notification-details">
                                <span className="notification-title">Record Payment</span>
                                <span className="notification-desc">Payment of INR 3400 is recorded for Invoice number
INV - 234</span>
                                <span className="notification-date">Today at 05:00 PM</span>
                            </div>
                        </div>                        
                    </li>
                </ul>
              </div>
              
              <div className="notification-conatiner">
                <div className="notification-header">YESTERDAY</div>
                <ul className="notification-list">
                    <li className="notification-item">
                        <div className="notification">
                            <img src={VendorIcon} alt="notification icon" width="28" height="28" />
                            <div className="notification-details">
                                <span className="notification-title">Vendor Created</span>
                                <span className="notification-desc">Vendor VEND 45 was created by Michael</span>
                                <span className="notification-date">Jan 12, 2023 at 12:45 PM</span>
                            </div>
                        </div>                        
                    </li>                    
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NotificationModal;
