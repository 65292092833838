import { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { DeferredIncomeListValues } from "../../../../../types";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import { EditIcon } from "../../../../common/components/customSvgIcons/editIcon";
import TableContainer from "../../../../common/components/table/TableContainer";
// import { DeleteAllocationModal } from "../../DeleteAllocation/deleteAllocationModal";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import SendEmail from "../../../../common/components/kebabMenu/SendEmail";
import DownloadPdf from "../../../../common/components/kebabMenu/DownloadPdf";
import { toast } from "react-toastify";
import { downloadUsingURL, StatusColor } from "../../../../../helpers/helper";
import { getJournalLink } from "../../../journalVoucher/journalVoucherSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
// import InfiniteScroll from "react-infinite-scroll-component";

import "./DeferredIncomeTable.css";

type Props = {
  DIList: DeferredIncomeListValues[] | undefined;
  dateSortOrder: string;
  sortHandler: (column: string) => void;
  // module: string;
  invoiceId: number | null;
  key: string;
  getRerenderFlag: (flag: number) => void;
  isParent: boolean;
};
export const DeferredIncomeTable = ({
  DIList,
  dateSortOrder,
  sortHandler,
  // module,
  invoiceId,
  isParent,
  key,
  getRerenderFlag,
}: Props) => {
  const dispatch = useAppDispatch();
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // const page = 1;
  // const itemsPerPage = 20;
  // const id = useParams().editInvoiceId;
  const currentUserInfo = useAppSelector(currentUserSelector);
  // const { dateSortOrder } = useListData();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [journalId, setJournalId] = useState(0);
  const DIDetailRedirection = "/journal/detail";
  const invoiceBackRedirection = "/invoice/detail/" + invoiceId;
  // const invId = useParams().editInvoiceId;
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const navigate = useNavigate();
  const handleEditRedirection = (id: number | null) => {
    navigate(DIDetailRedirection + "/" + id, {
      state: {
        redirectTo: invoiceBackRedirection,
      },
    });
  };
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let journalVoucherPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Journal Entry"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * set table column headings
   */

  const DIcolumns = useMemo(
    () => [
      {
        Header: "DATE",
        accessor: "show.date",
        sort: false,
        sortOrder: "",
        // dateSortOrder === "ASC"
        //   ? "ascending"
        //   : dateSortOrder === "DESC"
        //   ? "descending"
        //   : "",
        className: "transaction_date text-align-left",
      },
      {
        Header: "JOURNAL ENTRY #",
        accessor: "show.journal_voucher_no",
        sort: false,
        sortOrder: "",
        className: "je_entry text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={`/journal-entry/detail/${value.id}`}
              className="transaction-number-je-entry"
            >
              {value.journalVoucherNum}
            </Link>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "status text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value?.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "TAG",
        accessor: "show.tag",
        sort: false,
        sortOrder: "",
        className: "tag text-align-left",
      },

      {
        Header: "INCOME",
        accessor: "show.income",
        sort: false,
        sortOrder: "",
        className: "amount text-align-right",
      },
      {
        Header: "CUMULATIVE INCOME",
        accessor: "show.cumulativeIncome",
        sort: false,
        sortOrder: "",
        className: "amount text-align-right",
      },
      {
        Header: "DEFERRED INCOME BALANCE",
        accessor: "show.deferredIncomeBalance",
        sort: false,
        sortOrder: "",
        className: "amount text-align-right",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!journalVoucherPermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <KebabMenu>
              {value.status !== "Draft" ? (
                <>
                  <li>
                    <SendEmail
                      onClick={() => fetchContactEmail(value.id, value.emailTo)}
                    />
                  </li>
                  <li>
                    <DownloadPdf onClick={() => downloadPdf(value.id)} />
                  </li>
                </>
              ) : null}
            </KebabMenu>
          );
        },
      },
    ],
    [DIList]
  );

  /**
   * set table row data
   */
  const DIdata = DIList
    ? DIList.map((journal, index) => ({
        show: {          
          date: DateFormatHandler(journal.date),
          // journalVoucherNum: joinTransactionNumber(
          //   `${journal.journal_voucher_number}`
          // ),
          journal_voucher_no: {
            journalVoucherNum: joinTransactionNumber(
              `${journal.journal_voucher_number}`
            ),
            id: journal.id,
          },
          status: journal.journal_status,
          tag: journal.entity_tag,
          // income: journal.income,
          // income:
          //   journal.income !== "-"
          //     ? journal.currency_code +
          //       " " +
          //       NumberFormat(
          //         Number(journal.income),
          //         journal.currency_code,
          //         orgCurrencyList
          //       )
          //     : journal.income,
          income:
            journal.currency_code +
            " " +
            NumberFormat(
              Number(journal.income),
              journal.currency_code,
              orgCurrencyList
            ),

          // cumulativeIncome: journal.cumulative_income,
          // journal.cumulative_income !== "-"
          //   ? journal.currency_code +
          //     " " +
          //     NumberFormat(
          //       Number(journal.cumulative_income),
          //       journal.currency_code,
          //       orgCurrencyList
          //     )
          //   : journal.cumulative_income,
          cumulativeIncome:
            journal.currency_code +
            " " +
            NumberFormat(
              Number(journal.cumulative_income),
              journal.currency_code,
              orgCurrencyList
            ),
          // deferredIncomeBalance: journal.deferred_income_balance,
          // journal.deferred_income_balance !== "-"
          //   ? journal.currency_code +
          //     " " +
          //     NumberFormat(
          //       Number(journal.deferred_income_balance),
          //       journal.currency_code,
          //       orgCurrencyList
          //     )
          //   : journal.deferred_income_balance,
          deferredIncomeBalance:
            journal.currency_code +
            " " +
            NumberFormat(
              Number(journal.deferred_income_balance),
              journal.currency_code,
              orgCurrencyList
            ),
          actions: {
            id: journal.id,
            journalVoucherNum: joinTransactionNumber(
              `${journal.journal_voucher_number}`
            ),
            status: journal.journal_status,
            listName: journal.journal_voucher_number,
            emailTo: journal.email_to,
            organizationId: journal.organization_id,
            isLocked: journal.is_locked,
            lockDate: journal.lock_date,
            isMutable: journal.is_mutable,
            is_fiscal_closed: journal.is_fiscal_closed,
          },
        },
      }))
    : [];

  /**
   * Set the contact emails
   */
  const fetchContactEmail = (id: number, emailsList: any[]) => {
    let emails = emailsList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setJournalId(id);
  };

  const downloadPdf = async (journalId: number) => {
    const responseAction = await dispatch(
      getJournalLink({
        journalId: journalId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          journalId + ".pdf"
        );
        if (result) {
          toast.success("Journal entry note downloaded successfully!", {
            toastId: "jounal-voucher-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
        // toast.error("Failed to download PDF!", {
        //   icon: ({ theme, type }) => (
        //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
        //   ),
        //   toastId: "pdf-download-failure",
        //   closeButton: false,
        //   className: "toast-error",
        //   position: "top-center",
        // });
      }
    }
  };
  const kebabMenuListAction = () => {
    var leftScroll = 0;
    // //var scrollItem: any = document.querySelector(".table-container");
    var scrollItem: any = document.querySelector(".infinite-scroll-component");
    let resizeFlag: boolean = false;
    scrollItem.addEventListener("scroll", function (e: any) {
      setTableScrollLeft(scrollItem.scrollLeft);
    });
    $(".dropdown-ctrl").on("click", function () {
      ($(".dropdown-ctrl") as any).dropdown("hide");
      ($(this) as any).dropdown("show");
    });
    $(".more-btn").on("show.bs.dropdown", function () {
      // $(".table-container").css({ overflow: "inherit" });
      $(".infinite-scroll-component").css({ overflow: "initial" });
      $(".table-container table").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
    });
    $(".more-btn").on("hide.bs.dropdown", function () {
      // $(".table-container").css({
      //   "overflow-x": "auto",
      //   "overflow-y": "auto",
      // });
      $(".infinite-scroll-component").css({
        overflow: "auto",
      });
      $(".table-container table").css("margin-left", 0);
    });
    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-ctrl") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    kebabMenuListAction();
  });
  return (
    <TableContainer
      columns={DIcolumns}
      data={DIdata}
      id="cn-pr-list-table"
      className="list-table table table-striped deferred-income-list-table w-100"
      url={""}
      sortHandler={sortHandler}
      editPermission={true}
      isDetailViewExist={false}
      isNavigate={false}
      hiddenColumns={["Actions"]}
      key={key}
    >
      <div className="no-allocation">No entry found!</div>
    </TableContainer>
  );
};
