import React from "react";
import TableInfo from "../../../../../common/components/table/TableInfo";
import useListData from "../../../../../hooks/useListData";
import { TableData } from "../../../../../../types";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { PlusSmallImg, TaxEmptyIcon } from "../../../../../../assets/images";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { TaxRateFormContainer } from "../../taxRateForm/TaxRateFormContainer";
type Props = {
  data: TableData[];
  columns: any[];
  setPageInfo: (countPerPage: number, page: number) => void;
  refreshList: () => void;
};
const TaxRateTable = (props: Props) => {
  const { taxConfigurationPermission } = usePermissionHandler();
  const { itemsPerPage, startCount, endCount, totalList } = useListData();
  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className={
          "list-table-wrapper settings-list-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
          `${totalList > 0 ? "" : "no-data"}`
        }
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={props.setPageInfo}
        />
        <TableContainer
          className="list-table table table-striped tax-conf-table"
          columns={props.columns}
          data={props.data}
          url={"/tax-rates"}
          sortHandler={() => {}}
          editPermission={false}
          isDetailViewExist={false}
          id={"settings_table"}
        >
          <div className="create-invoice-container align-center">
            <img
              className="preview-img"
              src={TaxEmptyIcon}
              alt="create invoice preview"
              width="108"
              height="120"
            />
            <h3>
              {taxConfigurationPermission.includes("Create")
                ? "No Taxes Configured"
                : "No Taxes found!"}
            </h3>
            <p>
              {taxConfigurationPermission.includes("Create")
                ? "Add Tax Code and configure taxes."
                : "Oops, There is no Tax data available right now"}
            </p>
            {taxConfigurationPermission.includes("Create") ? (
              <TaxRateFormContainer
                taxId={-1}
                refreshList={props.refreshList}
                isAccountDeleted={false}
                className="btn create-invoice-button green-button right-button mw-148"
              >
                <span>
                  <img src={PlusSmallImg} className="Group" alt="plus-icon" />
                  <span className="create-new-customer-button-text green-button-text">
                    Add New Tax
                  </span>
                </span>
              </TaxRateFormContainer>
            ) : null}
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default TaxRateTable;
