import React from "react";

import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import SystemVoucherConfigurationForm from "./systemVoucherConfigurationForm/systemVoucherConfigurationForm";

import "./systemVoucherConfiguration.css";
import { AddTagIcon } from "../../../../../assets/images";
type Props = {
  buttonContent: React.ReactNode;
  systemConfId?: number;
  handleRefreshList:() => void;
  buttonWrapperClass?:string;
}

const SystemVoucherConfigurationModal = (props:Props) => {    
  return (
    <>
      <SideModal
        modalTitle={"Add System Voucher List"}
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return (
                <>
                  <SystemVoucherConfigurationForm
                    systemVoucherTypeId={props.systemConfId?props.systemConfId: undefined}
                    handleRefreshList={props.handleRefreshList}
                    handleModalClose={() => {
                      onCloseModal();
                    }}
                  />
                </>
              );
            }}
          </ModalContext.Consumer>
        }
        // 
        buttonContent={props.buttonContent}
        buttonClass={
          `no-appearance w-auto configure-system-voucher-btn ${props.buttonWrapperClass?props.buttonWrapperClass:""}`
        }
        //   wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
        //   onOpenFunction={props.onOpenFunction}
      />
    </>
  );
};

export default SystemVoucherConfigurationModal;
