import { CSSProperties } from "react";

type SpanProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  style?: CSSProperties;
  role?: string;
  ariaLabelledBy?: string;
};
export const Span = (props: SpanProps) => {
  const { children, ariaLabelledBy, ...rest } = props;
  return (
    <span {...rest} aria-labelledby={ariaLabelledBy}>
      {props.children}
    </span>
  );
};
