import {
  NON_PRICE_RANGE_PRICING_MODEL,
  PRICE_RANGE_PRICING_MODEL,
} from "../../../../../helpers/planHelper";
import {
  CurrentUserValues,
  InventoryItemValues,
  ObjectType,
} from "../../../../../types";
import { gstRegistrationTypes } from "../../../../constants/constants";
import { initialSubscriptionItemValues } from "../../SubscriptionStateInitialization";

export const getGstTaxItem = (tax: any) => {
  if (!tax) {
    return null;
  }
  let taxes = tax?.taxes;
  let gst_tax_item: ObjectType[] = [];
  if (taxes) {
    gst_tax_item = taxes.map((singleTax: ObjectType) => {
      return {
        tax_id: singleTax.id,
        tax_percentage: singleTax.rate,
        tax_type: singleTax?.tax_type,
      };
    });
  }
  const taxObj = {
    tax_id: tax.id,
    tax_percentage: tax.rate,
    is_group: tax.tax_group,
    tax_type: tax?.tax_type,
    gst_tax_item: gst_tax_item,
  };
  return taxObj;
};

const getGstDetails = async (data: any) => await getGstTaxItem(data);

export const addSubscriptionData = async (
  added: string,
  addedData: any,
  exemption: any,
  currencyId: number | undefined,
  customerDetails: any,
  getDefaultNonTaxableGst: () => any,
  getDefaultSezGst_forSales: () => any,
  gstRegistrationType: string | undefined,
  stateType: string,
  taxList: ObjectType[],
  defaultPreference: ObjectType,
  gstTaxList: any[],
  itemDropdownList: InventoryItemValues[],
  getTaxList: () => any
) => {
  const addedDataMapping = {
    frequency: addedData.frequency,
    pricing_model: addedData.pricing_model,
    pricing_id: addedData.pricing_id,
    itemDetails: addedData.item_price.map((data: any) => {
      return {
        ...initialSubscriptionItemValues,
        item_exemption_type: data.exemption_reason_name
          ? data.tax_preference
          : exemption.item_exemption_type,
        item_exemption_id: data.exemption_reason_id
          ? data.exemption_reason_id
          : exemption.item_exemption_id,
        tax_preference: data.tax_preference,
        account_id: data.account_id,
        account_name: data.account_name,
        item_name: data.item_name,
        price: data.price,
        rate: PRICE_RANGE_PRICING_MODEL.includes(addedData.pricing_model)
          ? 0
          : data.price,
        hsn_or_sac_code: data.hsn_or_sac_code,
        quantity: addedData.pricing_model === "FLAT_FEE" ? 0 : 1.0,
        pricing_id: addedData.pricing_id,
        is_plan: added === "plan",
        free_quantity: data.free_quantity ? data.free_quantity : null,
        item_id: data[`${added}_item_id`],
        range_price: data.range_price,
        currency_id: currencyId ? currencyId : null, //need to check on the id
        currency_code: data.currency_code,
        taxes: data.taxes,
      };
    }),
  };

  const updatedAddedPlanItem = addedDataMapping.itemDetails.map(
    (eachData: any) => {
      const itemObject = eachData;
      if (customerDetails?.customer_exemption_type === "NON_TAXABLE") {
        let default_gst = getDefaultNonTaxableGst();
        const taxObj = getGstTaxItem(default_gst[0]);
        itemObject.gst_tax = { ...taxObj } as any;
        itemObject.item_exemption_type = eachData.item_exemption_type;
        itemObject.item_exemption_id = eachData.item_exemption_id;
      } else if (
        gstRegistrationType === gstRegistrationTypes.sez ||
        gstRegistrationType === gstRegistrationTypes.sezDeveloper ||
        gstRegistrationType === gstRegistrationTypes.overseas
      ) {
        let default_gst = getDefaultSezGst_forSales() as any;
        const taxObj = getGstTaxItem(default_gst[0]);
        itemObject.gst_tax = { ...taxObj } as any;
      } else {
        if (stateType === "Inter State" && defaultPreference.inter_state_id) {
          let defaultTax = taxList.filter(
            (tax) => tax.id === defaultPreference.inter_state_id
          );
          if (defaultTax.length > 0) {
            const taxObj = getGstTaxItem(defaultTax[0]);
            itemObject.gst_tax = { ...taxObj } as any;
          }
        }
      }
      return itemObject;
    }
  );

  let gstTaxListArray: any = {};
  if (gstTaxList.length > 0) {
    gstTaxListArray = {
      payload: [...gstTaxList],
    };
  } else {
    gstTaxListArray = await getTaxList();
  }
  let updateData: any[] = [];
  updatedAddedPlanItem.map(async (item: any, index: any) => {
    let taxObj = null;
    if (
      item.tax_preference === "NON_TAXABLE" ||
      item.tax_preference === "OUT_OF_SCOPE" ||
      item.tax_preference === "NON_GST_SUPPLY"
    ) {
      const taxPreference =
        item.tax_preference === "NON_TAXABLE"
          ? "Non-Taxable"
          : item.tax_preference === "OUT_OF_SCOPE"
          ? "Out of Scope"
          : item.tax_preference === "NON_GST_SUPPLY"
          ? "Non-GST Supply"
          : "";
      const gst_tax = gstTaxListArray.payload.filter((gstTax: any) => {
        return (
          gstTax.name === taxPreference &&
          gstTax.tax_type === "NONE" &&
          gstTax.rate === 0
        );
      });
      if (
        customerDetails?.customer_exemption_type !== "NON_TAXABLE" &&
        gstRegistrationType !== gstRegistrationTypes.sez &&
        gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
        gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        taxObj = await getGstDetails(gst_tax[0]);
      }
    }

    const shouldUpdate =
      customerDetails?.customer_exemption_type !== "NON_TAXABLE" &&
      gstRegistrationType !== gstRegistrationTypes.sez &&
      gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      gstRegistrationType !== gstRegistrationTypes.overseas;

    let taxArray: any[] = [];
    if (addedDataMapping.itemDetails[index].taxes) {
      addedDataMapping.itemDetails[index].taxes.map((tax: any) => {
        taxArray.push({
          tax_id: tax.tax_id,
          tax_percentage: tax.tax_percentage,
        });
      });
    }

    let items = {
      ...item,
      item_name: item.item_name,
      hsn_or_sac_code: item.hsn_or_sac_code,
      account_id: item.account_id,
      account_name: item.account_name || "",
      rate: Number(item.rate),
      taxes: taxArray,
      inventory_item_id: itemDropdownList.find(
        (data: any) => data.name === item.item_name
      )?.id,
      gst_tax: taxObj ? { ...taxObj } : item?.gst_tax,
      item_exemption_type: shouldUpdate
        ? customerDetails?.customer_exemption_type === "NON_TAXABLE"
          ? "NON_TAXABLE"
          : item.tax_preference
        : item?.item_exemption_type,
      item_exemption_id: shouldUpdate
        ? customerDetails?.customer_exemption_type === "NON_TAXABLE"
          ? 120
          : item.item_exemption_id?.toString()
        : item?.item_exemption_id?.toString(),
    };
    updateData.push(items);
    return items;
  });
  return { ...addedDataMapping, itemDetails: updateData };
};

export const formatDataOnChangeCustomer = (
  ItemFormValues: any,
  response: any,
  added: string
) => {
  const data = ItemFormValues.itemDetails.map((eachItem: any) => {
    const item = response.find(
      (data: any) => data[`${added}_item_id`] === eachItem.item_id
    );
    if (NON_PRICE_RANGE_PRICING_MODEL.includes(ItemFormValues.pricing_model)) {
      return {
        ...eachItem,
        rate: item.price,
        price: item.price,
        currency_code: item.currency_code,
      };
    }
    return {
      ...eachItem,
      range_price: item.range_price,
      currency_code: item.currency_code,
    };
  });

  return data;
};
