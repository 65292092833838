import CenterModal from "../../../../common/components/centerModal/CenterModal";
import CreateCustomer from "../customerForm/CustomerForm";
import "./AddCustomerModal.css";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import { AddNewSquarePlusIcon } from "../../../../common/components/customSvgIcons/addNewSquarePlusIcon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  isButtonDisabled?: boolean;
};

export const AddCustomerModal = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { customerRolePermission } = usePermissionHandler();
  const ConfigureButton = () => {
    return (
      <>
        {customerRolePermission.includes("Create") &&
        IsFeatureEnabled(
          "Customer",
          currentUserInfo.disabled_feature
            ? currentUserInfo.disabled_feature
            : []
        ) ? (
          <span>
            <AddNewSquarePlusIcon />
            Add Customer
          </span>
        ) : null}
      </>
    );
  };
  return (
    <CenterModal
      modalTitle="Add Customer"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <CreateCustomer handleModalClose={onCloseModal} />;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ConfigureButton />}
      wrapperClass="add-customer-modal large-form-modal"
      buttonClass="add-customer-link"
      isButtonDisabled={props.isButtonDisabled ? props.isButtonDisabled : false}
    />
  );
};
