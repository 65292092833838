import { SubscriptionCouponList } from "../../../subscriptionType";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { NoSearchResultComponent } from "../../../../search/noResultPage/NoResultComponent";
import { useEffect, useRef, useState } from "react";

type Props = {
  couponList: SubscriptionCouponList[];
  onCouponSelect: (value: any) => void;
  searchTerm: string;
  onMenuScrollToBottom: () => void;
  isMenuListOpen: boolean;
  count: number;
}
export const AddCouponList = (props: Props) => {

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.isMenuListOpen) {
    const handleScroll = (event: any) => {
      if (menuRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
        if (Math.ceil(scrollTop) + clientHeight >= scrollHeight && !loading) {
          if (props.onMenuScrollToBottom) {
            setLoading(true);
            props.onMenuScrollToBottom()
          }
        }
      }
    };

    const menuNode = document.querySelector('.modal-body .MuiList-root');
    if (menuNode) {
      menuRef.current = menuNode as HTMLDivElement;
      menuRef.current.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener on component unmount or menu close
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener('scroll', handleScroll);
      }
    }}
  });

  useEffect(() => {
    if (props.count > 0) {
      setLoading(false);
    }
  }, [props.count])
  return (
        <div className="tbody-container">
          <MenuList>
          {props?.couponList && props.couponList.length > 0 ? props.couponList.map((item) => (
            <MenuItem value={item.id} key={item.id} onClick={(e) => props.onCouponSelect(item)}>
              {`${item.name} (${item.discount})`}
            </MenuItem>
        )) : (props.searchTerm !== '' ? <NoSearchResultComponent variant="popper"/> : <div className="coupon-menu-no-result">No Coupons available</ div>)}
          </MenuList>
        </div>
  );
};
