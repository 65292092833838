import { toast } from "react-toastify";
import DeleteModal from "../../../common/components/DeleteModal";
import useCommonData from "../../../hooks/useCommon";
import { removeTcs } from "../tcsSlice";

type Props = {
  tcsId: number;
  taxName: string;
  refreshList: () => void;
};
const DeleteTcs = (props: Props) => {
  const { currentUserInfo, dispatch } = useCommonData();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };
  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      removeTcs({ tcsId: props.tcsId, orgId: currentUserInfo.organization_id })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshList();
        $(".close-btn").click();
        toast.success("TCS deleted successfully!", {
          toastId: "tcs-delete-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete TCS!"
      subHeading="Are sure you want to delete the TCS"
      transactionName={props.taxName}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteTcs;
