import {
  CreateAddressValues,
  CreateContactValues,
  CreateVendorValues,
  EditVendorValues,
  VendorDropdownList,
} from "./../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewVendorContact,
  createNewVendor,
  createNewVendorAddress,
  deleteVendor,
  deleteVendorContact,
  editVendor,
  editVendorBillingAddress,
  editVendorCombinedAddress,
  editVendorContact,
  editVendorShippingAddress,
  getVendorDetails,
  getGstTypesList,
  getSearchSuggestions,
  getVendorsList,
  shortCodeExist,
  getVendorDropdownList,
  getVendorDetailsForTransactions,
  getVendorFilterList,
  getVendorListForSoa,
} from "./vendorAPI";
import { RootState } from "../../../app/store";
import { transactionLimitStatus } from "../customerManagement/customerAPI";

export const createVendor = createAsyncThunk(
  "createVendor",
  async (data: { values: CreateVendorValues; orgId: number }) => {
    return await createNewVendor(data);
  }
);

export const checkShortCode = createAsyncThunk(
  "vendor/shortCode",
  async (data: { vendorName: string; shortCode: string; orgId: number }) => {
    return await shortCodeExist(data);
  }
);

export const editVendorDetails = createAsyncThunk(
  "editVendor",
  async (data: {
    id: number;
    editVendorInputs: EditVendorValues;
    orgId: number;
  }) => {
    return await editVendor(data);
  }
);

export const vendorsList = createAsyncThunk(
  "vendorList",
  async (data: {
    page: number;
    itemsPerPage: number;
    vendorName: string;
    orgId: number;
    orgIds: number[];
  }) => {
    return await getVendorsList(data);
  }
);

export const vendorSuggestions = createAsyncThunk(
  "vendor/suggestions",
  async (data: { vendorName: string; orgId: number }) => {
    return await getSearchSuggestions(data);
  }
);

export const vendorDetails = createAsyncThunk(
  "vendorDetails",
  async (data: { id: number; orgId: number }) => {
    return await getVendorDetails(data);
  }
);

export const vendorDetailsForTransactions = createAsyncThunk(
  "customer/transaction-detail",
  async (data: { vendorId: number; orgId: number }) => {
    return await getVendorDetailsForTransactions(data);
  }
);

export const deleteVendorDetails = createAsyncThunk(
  "deleteVendor",
  async (data: { id: number; orgId: number }) => {
    return await deleteVendor(data);
  }
);

export const createVendorAddress = createAsyncThunk(
  "createVendorAddress",
  async (data: {
    id: number;
    addressInputs: CreateAddressValues;
    orgId: number;
  }) => {
    return await createNewVendorAddress(data);
  }
);

export const updateVendorCombinedAddress = createAsyncThunk(
  "updateCombinedAddress",
  async (data: {
    vendorId: number;
    editCombinedInput: CreateAddressValues;
    orgId: number;
  }) => {
    return await editVendorCombinedAddress(data);
  }
);

export const updateVendorBillingAddress = createAsyncThunk(
  "updateBillingAddress",
  async (data: {
    vendorId: number;
    editBillingInput: CreateAddressValues;
    orgId: number;
  }) => {
    return await editVendorBillingAddress(data);
  }
);

export const updateVendorShippingAddress = createAsyncThunk(
  "updateShippingAddress",
  async (data: {
    vendorId: number;
    editShippingInput: CreateAddressValues;
    orgId: number;
  }) => {
    return await editVendorShippingAddress(data);
  }
);

export const createVendorContact = createAsyncThunk(
  "createVendorContact",
  async (data: {
    id: number;
    contactInputs: CreateContactValues;
    orgId: number;
  }) => {
    return await createNewVendorContact(data);
  }
);

export const updateVendorContact = createAsyncThunk(
  "editVendorContact",
  async (data: {
    vendorId: number;
    contactId: number;
    editContactInputs: CreateContactValues;
    orgId: number;
  }) => {
    return await editVendorContact(data);
  }
);

export const deleteVendorContactDetails = createAsyncThunk(
  "deleteVendorContact",
  async (data: { vendorId: number; contactId: number; orgId: number }) => {
    return await deleteVendorContact(data);
  }
);

export const gstTypesList = createAsyncThunk(
  "gstTypes",
  async (orgId: number) => {
    return await getGstTypesList(orgId);
  }
);

export const vendorDropdownList = createAsyncThunk(
  "vendor/dropdown-list",
  async (orgId: number) => {
    return await getVendorDropdownList(orgId);
  }
);

export const vendorFilterList = createAsyncThunk(
  "vendor/vendor-filter-list",
  async (data: { orgId: number; organizationIds: number[] }) => {
    return await getVendorFilterList(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "vendor/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const vendorDropdownListForSoa = createAsyncThunk(
  "vendor/dropdown-list-for-soa",
  async (data: {
    orgId: number;
    currentOrgId: number;
    isIntegrated?: boolean;
  }) => {
    return await getVendorListForSoa(data);
  }
);

interface UsersState {
  gstTypes: string[];
  vendorDropdownList: VendorDropdownList[];
  vendorDropdownListForSoa: VendorDropdownList[];
}

const initialState: UsersState = {
  gstTypes: [],
  vendorDropdownList: [],
  vendorDropdownListForSoa: [],
};
export const vendorSlice = createSlice({
  name: "vendorManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(gstTypesList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.gstTypes = payload;
        }
      })
      .addCase(vendorDropdownList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.vendorDropdownList = payload;
        } else {
          state.vendorDropdownList = [];
        }
      })
      .addCase(vendorDropdownListForSoa.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.vendorDropdownListForSoa = payload;
        } else {
          state.vendorDropdownListForSoa = [];
        }
      });
  },
});

export default vendorSlice.reducer;

// State selectors
export const gstTypesSelector = (state: RootState) =>
  state.vendorManagement.gstTypes;
export const vendorListSelector = (state: RootState) =>
  state.vendorManagement.vendorDropdownList;
export const vendorSoaListSelector = (state: RootState) =>
  state.vendorManagement.vendorDropdownListForSoa;
