import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useEffect, useState } from "react";
import "./TdsPreference.css";
import useCommonData from "../../../../hooks/useCommon";
import { tdsTaxPreference, updateTdsPreference } from "../../tdsSlice";
import { TdsPreferenceValues } from "../../../../../types";
import { initialPreferenceValues } from "./StateInitialization";
import { toast } from "react-toastify";
type Props = {
  closeModal: () => void;
};
function TdsPreference({ closeModal }: Props) {
  const { currentUserInfo, dispatch } = useCommonData();
  const [supportTdsFor, setSupportTdsFor] = useState(1);
  const [tdsPreferenceValues, setTdsPreferenceValues] =
    useState<TdsPreferenceValues>(initialPreferenceValues);

  useEffect(() => {
    getTdsTaxPreferenceDetails();
  }, [currentUserInfo.organization_id]);

  const handleClose = () => {
    closeModal();
  };
  const supportTdsValues = [
    { id: 1, name: "Customers and Vendors" },
    { id: 2, name: "Customers" },
    { id: 3, name: "Vendors" },
  ];

  const supportTdsName = supportTdsValues
    .filter((tds) => tds.id === supportTdsFor)
    .map((tds) => ({
      label: tds.name,
      value: tds.id,
    }));

  const supportTdsOptions = supportTdsValues.map((tds) => ({
    label: tds.name,
    value: tds.id,
  }));

  const handleSupportTdsChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setSupportTdsFor(Number(e.value));
    setTdsPreferenceValues((values) => ({
      ...values,
      support_tds_for: e.label,
    }));
  };

  const radioButtonSelectHandler = (e: any) => {
    setTdsPreferenceValues((values) => ({
      ...values,
      apply_tds_at: e.target.value,
    }));
  };

  const getTdsTaxPreferenceDetails = async () => {
    const responseAction = await dispatch(
      tdsTaxPreference(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTdsPreferenceValues(response);
        const supportTdsId = supportTdsValues
          .filter((item) => item.name === response.support_tds_for)
          .map((item) => item.id)[0];
        setSupportTdsFor(supportTdsId);
      }
    }
  };

  const formSubmitHandler = async (e: any) => {
    e.preventDefault();
    $("#tds-preference-save-button").addClass(
      "create-user-form-section-disable"
    );
    const responseAction = await dispatch(
      updateTdsPreference({
        applyTdsAt: tdsPreferenceValues.apply_tds_at,
        supportTds: tdsPreferenceValues.support_tds_for,
        isEnabled: tdsPreferenceValues.is_enabled,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        closeModal();
        toast.success("TDS Preference updated successfully!", {
          toastId: "Tds-preference-update-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
    $("#tds-preference-save-button").removeClass(
      "create-user-form-section-disable"
    );
  };
  const handleEnableTdsChange = (e: any) => {
    setTdsPreferenceValues((values) => ({
      ...values,
      is_enabled: e.target.checked,
    }));
  };
  return (
    <div className="tds-preference-form-modal-wrapper">
      <form
        id="tds-preference"
        className="tds-preference"
        onSubmit={formSubmitHandler}
      >
        <div className="tds-preference-input-wrapper">
          <input
            type="checkbox"
            name="c_i_f_access"
            id="c_i_f_access"
            className="form-check-input"
            onChange={(e) => handleEnableTdsChange(e)}
            checked={tdsPreferenceValues.is_enabled}
          />
          <span className="enable-tds-label">Enable TDS</span>
        </div>
        {tdsPreferenceValues.is_enabled && (
          <>
            {" "}
            <div className="tds-preference-input-wrapper">
              <span className="preference-modal-content">
                TDS ( Tax Deducted at Source) refers to the process of deducting
                a specified percentage of tax from invoices and bills to your
                vendors and customers. You can enable line item level or
                transaction level while creating invoices and bills also.
              </span>
            </div>
            <div className="tds-preference-input-wrapper d-flex flex-column gap-1">
              <label htmlFor="support-tds-for" className="required">
                Support TDS for
              </label>
              <Select
                name="support-tds-for"
                value={supportTdsName}
                options={supportTdsOptions}
                placeholder="Choose"
                onChange={(e) => {
                  if (e)
                    handleSupportTdsChange({
                      label: e?.label,
                      value: e?.value,
                    });
                }}
                className={`form-select custom-select customer-select m-0`}
                styles={customSelectStyle}
              />
              <span className="error">{}</span>
            </div>
            <div className="tds-preference-input-wrapper d-flex flex-column gap-2">
              <label htmlFor="apply-tds-at" className="required">
                Apply TDS at
              </label>
              <div className="tds-radio-wrapper d-flex">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="transaction_level"
                    id="inlineRadio1"
                    value="Transaction level"
                    checked={
                      tdsPreferenceValues.apply_tds_at === "Transaction level"
                    }
                    onChange={radioButtonSelectHandler}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Transaction level
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="line_item_level"
                    id="inlineRadio2"
                    value="Line item level"
                    checked={
                      tdsPreferenceValues.apply_tds_at === "Line item level"
                    }
                    onChange={radioButtonSelectHandler}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Line item level
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className="tds-preference-button-wrapper w-100"
          id="tds-preference-save-button"
        >
          <button className="save-button" type="submit">
            Save
          </button>
          <button type="button" className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
export default TdsPreference;
