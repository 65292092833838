import { ObjectType } from "../../../../types";
import Select, { MultiValue, components, OptionProps, Options } from "react-select";
import "../../../../index.css";
import "./FormSelectField.css";
import { CloseImg } from "../../../../assets/images";
import InfoTooltip from "../InfoTooltip";
import { useEffect, useRef, useState } from "react";

type SelectFieldProps = {
  name: string;
  id: string;
  options: { label: string; value: number | string }[];
  className: string;
  isDisabled: boolean;
  value: MultiValue<{ label: string; value: number | string }> | null;
  defaultValue?: MultiValue<{ label: string; value: number | string }> | null;
  onChange: (newValue: MultiValue<{ label: string; value: number | string }>) => void;
  styles: ObjectType;
  placeholder: string;
  error: string | undefined;
  label: string;
  isRequired: boolean;
  isOnlyInRow: boolean;
  wrapperClass?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  handleClearSelection?: () => void;
  tooltipMessage?: string | undefined;
  messagePlacement?: any | undefined;
  dropdownCustomMessage?: string
  onMenuScrollToBottom?: () => void;
  count: number;
  isSearchable: boolean;
}


const CustomOption = (props: OptionProps<any>) => {
    return (
      <components.Option {...props} className="multi-select-cust-option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: 8}}
        />
        <label>{props.label}</label>
      </components.Option>
    );
  };

export const MultiSelectFieldWithScroll = (props: SelectFieldProps) => {
  
  const [isMenuListOpen, setIsMenuListOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isMenuListOpen) {
    const handleScroll = (event: any) => {
      if (menuRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
        if (Math.ceil(scrollTop) + clientHeight >= scrollHeight && !loading) {
          if (props.onMenuScrollToBottom) {
            setLoading(true);
            props.onMenuScrollToBottom()
          }
        }
      }
    };

    const menuNode = document.querySelector('.select-cust-scroll__menu-list');
    if (menuNode) {
      menuRef.current = menuNode as HTMLDivElement;
      menuRef.current.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener on component unmount or menu close
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener('scroll', handleScroll);
      }
    }}
  });

  useEffect(() => {
    if (props.count > 0) {
      setLoading(false);
    }
  }, [props.count])

  return (
    <div
      className={`select-field-wrap col ${
        props.wrapperClass ? props.wrapperClass : ""
      } ${props.isOnlyInRow === true ? "row" : ""}`}
    >
      <div className="relative-wrapper">
        <label
          htmlFor={props.id}
          className={props.isRequired ? "fw-bold required" : "fw-bold"}
        >
          {props.label}
        </label>
        {props?.tooltipMessage ? <InfoTooltip title={props.tooltipMessage} placement={props?.messagePlacement || 'top'} /> : null}
        <Select
          name={props.name}
          id={props.id}
          classNamePrefix='select-cust-scroll'
          options={props.options}
          hideSelectedOptions={false}
          className={`resusable-form-select-field ${props.className} ${props.error ? "error" : ""}`}
          isDisabled={props.isDisabled}
          onMenuClose={() => setIsMenuListOpen(false)}
          onMenuOpen={() => setIsMenuListOpen(true)}
          components={{ Option: CustomOption }}
          captureMenuScroll
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          styles={{ ...props.styles }}
          placeholder={props.placeholder}
          isClearable={props.isClearable === true ? true : false}
          isLoading={props.isLoading === true ? true : false}
          noOptionsMessage={() => props.dropdownCustomMessage ? props.dropdownCustomMessage : 'No options'}
          isMulti={true}
          closeMenuOnSelect={false}
          isSearchable={props.isSearchable}
        />
      </div>
      <span className="error">{props.error}</span>
    </div>
  );
};
