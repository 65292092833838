import React from "react";
import Select, { SingleValue } from "react-select";
import { customSelectStyle } from "./SelectCustomStyle";
import { isDisabled } from "@testing-library/user-event/dist/types/utils";

type Props = {
  gstTypes: string[];
  gstRegistrationType: string;
  onGstTypeChange: (
    e: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
  error: string;
  dropPosition?: 'auto' | 'top' | 'bottom';
};
function GstSelect({ gstTypes, gstRegistrationType, onGstTypeChange, dropPosition, error }: Props) {
  return (
    <Select
      name="gst_registration_type"
      id="c_gst_type"
      options={gstTypes.map((gstType) => {
        return {
          label: gstType,
          value: gstType,
        };
      })}
      placeholder="Choose registration type"
      className={`currency-select form-select custom-select ${error ? "error" : ""}`}
      value={
        gstRegistrationType
          ? {
              label: gstRegistrationType,
              value: gstRegistrationType,
            }
          : []
      }
      onChange={onGstTypeChange}
      styles={customSelectStyle}
      isSearchable={false}
      menuPlacement={dropPosition? dropPosition: "bottom"}
    />
  );
}

export default React.memo(GstSelect);
