import React from "react";
import "./EmptyList.css";
import { PlusImage } from "../../../../../../../src/assets/images";
import { useNavigate } from "react-router-dom";
import TdsFormContainer from "../../tdsForm/TdsFormContainer";

import { ObjectType } from "../../../../../../types";

type Props = {
  heading: string;
  subheading: string;
  list: string;
  buttonText: string;
  alt: string;
  navigateLink: string;
  navigateState?: ObjectType;
  isCrateAccess: boolean;
  icon: string;
  transactionLimit?: boolean;
  navigateType?: string;
  onClick?: () => void;
  refreshList: () => void;
};

const EmptyList = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="create-invoice-container align-center">
      <img
        className="preview-img"
        src={props.icon}
        alt={props.alt}
        width="108"
        height="120"
      />
      <h3>{props.heading}</h3>
      <p>{props.subheading}</p>
      {props.isCrateAccess ? (
        <TdsFormContainer
          id={0}
          isEdit={false}
          refreshList={props.refreshList}
          createdFrom="tds_page"
          openFromEmpty={true}
        />
      ) : null}
    </div>
  );
};
export default EmptyList;
