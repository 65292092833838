import { colors } from "../../../constants/colors";
import "../../../../index.css";
import './Button.css';
type Props = {
  closeModalHandler: () => void;
};

const CloseButton = (props: Props) => {
  return (
    <button className="close-btn no-appearance" onClick={props.closeModalHandler}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <path
            fill={colors.slate}
            fillRule="nonzero"
            d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
          />
        </g>
      </svg>
    </button>
  );
};

export default CloseButton;
