  import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
  import {
    getPlanOverviewDetails,
    updatePlanStatus,
    deletePlan,
    checkInternalName,
    createNewPlan,
    getPlanList,
    getPlanForEdit,
    editPlanApi,
    deletePricing,
    getPlanItemList,
    createPlanPrice,
    getEditPriceDetails,
    editPlanPrice,
    checkPriceInternalName,
    planTransactionLimitStatus
  } from "./planAPI";
  import { 
    PlanDefaultValues,
    TaxableAmountCalculationValues,
    TotalAmountCalculationValues,
    PlanPriceDefaultValue
  } from "../../../types";

  export const checkInternalNameExist = createAsyncThunk(
    "InternalName",
    async (data: {
      internalName: string;
      orgId: number;
      plan_id: Object;
    }) => {
      return await checkInternalName(data);
    }
  );

  export const createPlan = createAsyncThunk(
    "plan/create",
    async (data: {
      values: PlanDefaultValues;
      orgId: number;
    }) => {
      return await createNewPlan(data);
    }
  );
  
  export const planList = createAsyncThunk(
    "plansList",
    async (data: {
      page: number;
      itemsPerPage: number;
      orgId: number;
      orgIds: number[];
      statusList: string[];
    }) => {
      return await getPlanList(data);
    }
  );

  export const planDetailsForEdit = createAsyncThunk(
    "planEditView",
    async (data: {
      planId: number | string;
      orgId: number
    }) => {
      return await getPlanForEdit(data);
    }
  );

  export const planItemList = createAsyncThunk(
    "planItemList",
    async (data: {
      planId: any;
      orgId: number;
    }) => {
      return await getPlanItemList(data);
    }
  );

  export const planPricePointCreate = createAsyncThunk(
    "planPricePointCreate",
    async (data: {
      values: PlanPriceDefaultValue;
      orgId: number;
    }) => {
      return await createPlanPrice(data);
    }
  );
  
 
  export const editPlan = createAsyncThunk(
    "plan/edit",
    async (data: {
      values: PlanDefaultValues;
      orgId: number;
      planId: string | undefined;
    }) => {
      return await editPlanApi(data);
    }
  );
  
  export const planDetails = createAsyncThunk(
    "planDetail",
    async (data: { planId: number; orgId: number }) => {
      return await getPlanOverviewDetails(data);
    }
  );
  
  export const changePlanStatus = createAsyncThunk(
    "plan/update-status",
    async (data: { id: number; orgId: number; status: string }) => {
      return await updatePlanStatus(data);
    }
  );

  export const deletePlanDetails = createAsyncThunk(
    "plan/delete",
    async (data: { deletePlanId: number; orgId: number }) => {
      return await deletePlan(data);
    }
  );
  
  export const deletePricingData = createAsyncThunk(
    "pricing/delete",
    async (data: { deletePricingId: number; orgId: number }) => {
      return await deletePricing(data);
    }
  );

  export const priceGetEditDetails = createAsyncThunk(
    "pricePlan",
    async (data: {
      priceId: number;
      orgId: number;
    }) => {
      return await getEditPriceDetails(data);
    }
  );

  export const planPricePointEdit = createAsyncThunk(
    "planPricePointEdit",
    async (data: {
      values: PlanPriceDefaultValue;
      orgId: number;
      priceId: any;
    }) => {
      return await editPlanPrice(data);
    }
  );
  
  export const getPlanTransactionLimitStatus = createAsyncThunk(
    "plans/limit/status",
    async (orgId: number) => {
      return await planTransactionLimitStatus(orgId);
    }
  );
 
  const initialState = {};
  
  export const invoiceSlice = createSlice({
    name: "PlanManagement",
    initialState,
    reducers: {},
  });
  
  export const checkPriceInternalNameExist = createAsyncThunk(
    "PriceInternalName",
    async (data: {
      internalName: string;
      orgId: number;
      plan_id: number;
      pricing_id: string
    }) => {
      return await checkPriceInternalName(data);
    }
  );

  export default invoiceSlice.reducer;
  