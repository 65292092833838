import { useEffect, useMemo, useState } from "react";
import { Row } from "react-table";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import { ObjectType, TableData } from "../../../../../types";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import {
  DateFormatHandler,
  handleNegativeValue,
  handleNegativeValueWithTransactionType,
} from "../../../../../helpers/helper";
import AccountNameDisplay from "../../../../common/components/accountNameDispaly/AccountNameDisplay";
import EmptyReport from "../../../../common/components/EmptyReport";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import AccountTransactionsCollapsibleTable from "./accountTransactionsCollapsibleTable/AccountTransactionsCollapsibleTable";

import { ATReportGroupedTransaction } from "../../../../../types";

type Props = {
  accountTransactionData: any;
  accountTransactionGroupData: any;
  filterString?: string;
  isGroup?: boolean;
  hasMore: boolean;
  getAccountTransactionsReports?: any;
};
const AccountTransactionTable = (props: Props) => {
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const subModulePermission = useSubModulePermission();
  const [orgCurrencyCode, setOrgCurrencyCode] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["action"]);

  useEffect(() => {
    setOrgCurrencyCode(
      orgCurrencyList.filter((item: ObjectType) => {
        return item?.id === props.accountTransactionData.currency_id;
      })[0]?.code
    );
  }, [props.accountTransactionData.currency_id]);

  useEffect(() => {
    if (
      Object.values(currentUserInfo.user_access_mapping).toString().split(",")
        .length <= 1
    )
      setHiddenColumns(["action", "Organization"]);
    else setHiddenColumns(["action"]);
  }, [currentUserInfo.user_access_mapping]);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        className: "",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "table_account_name",
      },
      {
        Header: "Organization",
        accessor:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organizationName"
            : "Organization",
        className: "",
      },
      {
        Header: "Transaction Details",
        accessor: "show.transactionDetails",
        className: "",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "",
      },
      {
        Header: "Reference #",
        accessor: "show.reference",
        className: "",
      },
      {
        Header: "Debit",
        accessor: "show.debit",
        className: "text-align-right",
      },
      {
        Header: "Credit",
        accessor: "show.credit",
        className: "text-align-right",
      },
      {
        Header: "Gross",
        accessor: "show.gross",
        className: "text-align-right",
      },
      // {
      //   Header: "Running Balance",
      //   accessor: "show.runningBalance",
      //   className: "running_balance text-align-right",
      // },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    [props.accountTransactionData, hiddenColumns]
  );
  // const showGroup = (item: any) => {
  //   return {
  //     show: {
  //       date: joinTransactionNumber(item?.group_name),
  //       action: "",
  //     },
  //     colSpan: {
  //       date:
  //         Object.values(currentUserInfo.user_access_mapping)
  //           .toString()
  //           .split(",").length > 1
  //           ? 9
  //           : 8,
  //     },
  //     className: "customRowTitle",
  //   };
  // };
  const showTransactions = (item: any, navigation: boolean, type: string) => {
    if (type === "transaction") {
      return {
        show: {
          date:
            type === "transaction"
              ? DateFormatHandler(item?.transaction_date)
              : item?.transaction_date,
          account: (
            <AccountNameDisplay
              accountName={item?.account}
              ledgerRoot={item?.account_path}
            />
          ),
          organizationName: item?.organization_name,
          transactionDetails: item?.transaction_detail,
          transactionType: item?.transaction_type,
          reference: item?.reference_number,
          debit:
            item?.transaction === "Dr"
              ? NumberFormat(
                  Number(item?.gross),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          credit:
            item?.transaction === "Cr"
              ? NumberFormat(
                  Number(item?.gross),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",

          gross: handleNegativeValueWithTransactionType(
            NumberFormat(Number(item?.gross), orgCurrencyCode, orgCurrencyList),
            item?.gross_type
          ),
          // runningBalance: NumberFormat(
          //   Number(item?.running_balance),
          //   orgCurrencyCode,
          //   orgCurrencyList
          // ),
        },
        action:
          item?.transaction_type === "Invoice"
            ? {
                url: `/invoice/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Invoices"),
                customNavigation: true,
              }
            : item?.transaction_type === "Credit Note"
            ? {
                url: `/credit-note/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Credit Notes"),
                customNavigation: true,
              }
            : item?.transaction_type === "Payments Received"
            ? {
                url: `/payment-received/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : item?.transaction_type === "Bill"
            ? {
                url: `/bill/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Bills"),
                customNavigation: true,
              }
            : item?.transaction_type === "Debit Note"
            ? {
                url: `/debit-note/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Debit Notes"),
                customNavigation: true,
              }
            : item?.transaction_type === "Payments Made"
            ? {
                url: `/payment-made/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : item?.transaction_type === "Journal Entry"
            ? {
                url: `/journal-entry/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Journal Entry"),
                customNavigation: true,
              }
            : item?.transaction_type === "System Voucher"
            ? {
                url: `/system-voucher/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("System Voucher"),
                customNavigation: true,
              }
            : item?.transaction_type === "Customer Payment Refund"
            ? {
                url: `/payment-received/detail/${item?.entity_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : item?.transaction_type === "Vendor Payment Refund"
            ? {
                url: `/payment-made/detail/${item?.entity_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : {
                url: "",
                permission: subModulePermission.includes("Invoices"),
                customNavigation: false,
              },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
        className: navigation ? "row-navigation" : "",
      };
    } else {
      return {
        show: {
          date: item?.transaction_date,
          account: item?.account,
          credit:
            item?.transaction === "Cr"
              ? NumberFormat(
                  Number(item?.gross),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          gross: "",
        },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
      };
    }
  };

  const showGroupTransactions = (
    item: any,
    navigation: boolean,
    type: string
  ) => {
    if (type === "groupTransaction") {
      return {
        show: {
          groupName: item?.group_name,
          filter: item?.filter,
          transactionType: item?.transaction_type,
          debit: item?.total_debit,
          gross: item?.total_gross,
          runningBalance: item?.total_running_balance,
          credit: item?.total_debit,
          reference: item?.total_credit,
        },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
        className: navigation ? "row-navigation" : "",
      };
    } else {
      return {
        show: {
          groupName: item?.group_name,
          filter: item?.filter,
          transactionType: item?.transaction_type,
          debit: item?.total_debit,
          gross: item?.total_gross,
          runningBalance: item?.total_running_balance,
          credit: item?.total_debit,
          reference: item?.total_credit,
        },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
      };
    }
  };

  const data = () => {
    let report: any = [];
    if (props.accountTransactionData.transactions) {
      props.accountTransactionData.transactions.map((item: any, index: any) => {
        if (item?.transactions_list !== undefined) {
          // if (item?.group_name && item?.group_name !== "") {
          //   let lineItem = {
          //     group_name: item?.group_name,
          //   };
          //   let reponse = showGroup(lineItem);
          //   report.push(reponse);
          // }
          // if (item?.opening_balance !== undefined) {
          //   let lineItem = {
          //     transaction_date: `As on ${DateFormatHandler(
          //       props.accountTransactionData.start_date
          //     )}`,
          //     account: "Opening Balance",
          //     transaction: "Cr",
          //     balance: item?.opening_balance,
          //     colspan:
          //       Object.values(currentUserInfo.user_access_mapping)
          //         .toString()
          //         .split(",").length > 1
          //         ? 6
          //         : 5,
          //   };
          //   let reponse = showTransactions(lineItem, false, "");
          //   report.push(reponse);
          // }
          if (item?.transactions_list) {
            item?.transactions_list?.map((trans: any, transIndex: any) => {
              let reponse = showTransactions(trans, true, "transaction");
              report.push(reponse);
            });
          }
          // if (item?.closing_balance !== undefined) {
          //   let lineItem = {
          //     transaction_date: `As on ${DateFormatHandler(
          //       props.accountTransactionData.end_date
          //     )}`,
          //     account: "Closing Balance",
          //     transaction: "Cr",
          //     balance: item?.closing_balance,
          //     colspan:
          //       Object.values(currentUserInfo.user_access_mapping)
          //         .toString()
          //         .split(",").length > 1
          //         ? 6
          //         : 5,
          //   };
          //   let reponse = showTransactions(lineItem, false, "");
          //   report.push(reponse);
          // }
        } else {
          let reponse = showTransactions(item, true, "transaction");
          report.push(reponse);
        }
      });
    }
    return report;
  };

  const groupData = () => {
    let report: any = [];
    if (props.accountTransactionGroupData?.groups) {
      props.accountTransactionGroupData?.groups.map((item: any, index: any) => {
        let reponse = showGroupTransactions(item, true, "groupTransaction");
        report.push(reponse);
      });
    }
    return report;
  };

  const navigation = (row: Row<TableData>) => {
    if (row.values.action.url) {
      navigate(`?${props.filterString}`);
      navigate(row.values.action.url);
    }
  };
  const tbodyRowOnClick = (row: Row<TableData>) => {
    if (row.values.action.customNavigation) navigation(row);
  };

  return (
    <div className="table-wrapper">
      {!props.isGroup ? (
        <InfiniteScroll
          dataLength={
            props.accountTransactionData?.transactions.some(
              (transaction: any) => transaction.hasOwnProperty("group_name")
            )
              ? props.accountTransactionData.transactions.flatMap(
                  (transactions: any) =>
                    (transactions as ATReportGroupedTransaction)
                      .transactions_list
                ).length
              : props.accountTransactionData?.transactions?.length
          }
          hasMore={props.hasMore}
          next={() => {
            // newLoadRef.current = false;
            props.getAccountTransactionsReports("", {});
          }}
          endMessage={
            <span className="w-100 d-flex justify-content-center"></span>
          }
          loader={<span></span>}
        >
          <DetailsTableContainer
            // key={props.accountTransactionData.transactions}
            key={`${props.accountTransactionData.organization_id}${
              props.accountTransactionData.currency_code
            }${props.accountTransactionData.currency_id}${
              props.accountTransactionData.date_range
            }${props.accountTransactionData.start_date}${
              props.accountTransactionData.end_date
            }${Math.random()}at-01`}
            columns={columns}
            data={data()}
            className={"table report-table soa-table acc-transaction"}
            hiddenColumns={hiddenColumns}
            haveColumnClassName={true}
            rowNavigation={navigation}
          >
            <EmptyReport heading="No transactions found!" alt="Empty Report" />
          </DetailsTableContainer>
        </InfiniteScroll>
      ) : (
        <AccountTransactionsCollapsibleTable
          key={`${props.accountTransactionGroupData.group_by}${
            props.accountTransactionGroupData.organization_id
          }${props.accountTransactionGroupData.currency_code}${
            props.accountTransactionData.currency_id
          }${props.accountTransactionGroupData.date_range}${
            props.accountTransactionGroupData.start_date
          }${props.accountTransactionGroupData.end_date}${
            props.accountTransactionGroupData.report_basis
          }${Math.random()}atg-02`}
          id={""}
          columns={columns}
          data={JSON.parse(JSON.stringify(props.accountTransactionGroupData))}
          // data={groupData()}
          tableClassName={
            "table report-table soa-table acc-transaction acc-transaction-grouped-report-table"
          }
          dataSort={false}
          isLoading={false}
          haveColumnClassName={true}
          rowClassName={"table-navigation"}
          emptyClassName={"text-center dataTables_empty"}
          hiddenColumns={hiddenColumns}
          tbodyRowOnClick={tbodyRowOnClick}
          tbodyColumnClick={() => {}}
          filterString={props.filterString}
        >
          <EmptyReport heading="No transactions found!" alt="Empty Report" />
        </AccountTransactionsCollapsibleTable>
        // <></>
      )}
    </div>
  );
};

export default AccountTransactionTable;
