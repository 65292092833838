import React from "react";
import NextLabelSvg from "./NextLabelSvg";
import PreviousLabelSvg from "./PreviousLabelSvg";
import ReactPaginate from "react-paginate";
import "../../../../index.css";
import "./Pagination.css";

type Props = {
  pageCount: number;
  page: number;
  handlePageClick: (e: any) => void;
};
export default function Pagination({
  pageCount,
  page,
  handlePageClick,
}: Props) {
  return (
    <div className="pagination-wrapper w-100">
      <ReactPaginate
        breakLabel="..."
        nextLabel={<NextLabelSvg />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<PreviousLabelSvg />}
        activeClassName={"pagination-active"}
        forcePage={page - 1}
        disabledClassName={"pagination__link--disabled"}
      />
    </div>
  );
}
