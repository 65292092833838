import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getStatusFilterString,
  getTagFilterString,
} from "../../../../../helpers/statusFilterHelper";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import {
  JournalList,
  ObjectType,
  Transactionlimit,
} from "../../../../../types";
import {
  getTransactionLimitStatus,
  journalList,
} from "../../journalVoucherSlice";
import {
  currentUserSelector,
  fiscalYearFilterSelector,
} from "../../../../common/commonSlice";
import {
  filterAppliedState,
  listFilterSelector,
  setListEndCount,
  setListFilters,
  setListStartCount,
} from "../../../../../appSlice";
import DeleteJournal from "../Delete";
import Pagination from "../../../../common/components/table/Pagination";
import ListHeader from "../../../../common/components/list/ListHeader";
import useListData from "../../../../hooks/useListData";
import NotificationModal from "../../../../common/components/NotificationModal";
import JournalEntryTableContainer from "./JournalEntryTable/JournalEntryTableContainer";
import "../../../../common/styles/ListTable.css";
import "../../JournalVoucher.css";

type SelectedFilterType = {
  Organizations: number[];
  Status: number[];
  Tag: number[];
};

const JournalVoucherList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterAppliedState = useAppSelector(filterAppliedState);
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { journalVoucherRolePermission } = usePermissionHandler();
  const [journalData, setJournalData] = useState<JournalList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalJournals, setTotalJournals] = useState(0);
  const [deleteJournalItemId, setDeleteJournalItemId] = useState("");
  const [deleteJournalId, setDeleteJournalId] = useState(-1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const { page, pageCount, itemsPerPage, totalList, dateSortOrder } =
    useListData();

  useEffect(() => {
    if (!Object.keys(selectedFilterData).length) {
      initializeFilter();
    }
  }, []);

  useEffect(() => {
    fetchJournalList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
  ]);

  useEffect(() => {
    if (isFilterAppliedState) fetchJournalList();
  }, [isFilterAppliedState, selectedFilterData]);

  useEffect(() => {
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  const setStartCount = (param: number) => {
    dispatch(setListStartCount(param));
  };
  const setEndCount = (param: number) => {
    dispatch(setListEndCount(param));
  };
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Fetch all journal vouchers
   */
  const fetchJournalList = async () => {
    fetchTransactionLimit();
    setIsLoading(true);
    const responseAction = await dispatch(
      journalList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        tagList: selectedFilterData.Tag
          ? getTagFilterString(selectedFilterData.Tag)
          : [],
        endDate: fiscalYearFilter.endDate || "",
        startDate: fiscalYearFilter.startDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setJournalData(response.journals);
        let length = response.journals.length;
        setTotalJournals(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalJournals;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const deleteListData = (id: number, listNum: string) => {
    setDeleteJournalId(id);
    setDeleteJournalItemId(listNum);
  };

  const initializeFilter = () => {
    setIsFilterApplied(false);
    let filterData: SelectedFilterType = {
      Organizations: [],
      Status: [],
      Tag: [],
    };
    Object.keys(selectedFilters).map((filter) => {
      filterData = {
        ...filterData,
        [Object.keys(filter)[0]]: Object.values(filter)[0],
      };
    });
    dispatch(setListFilters(filterData));
  };

  const handleApplyFilter = () => {
    setIsFilterApplied(true);
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Journal Entry"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="New Journal Entry"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/journal-entry/create");
          }}
          haveFilter={true}
          filterType="JV"
          applyFilter={handleApplyFilter}
          roles={journalVoucherRolePermission}
          ref={filterRef}
          module="JournalEntry"
          isFiscalYearFilterRequired={false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body-wrapper">
            <JournalEntryTableContainer
              transactionLimit={transactionLimit}
              initializeFilter={initializeFilter}
              isFilterApplied={isFilterApplied}
              deleteListData={deleteListData}
              ref={listRef}
            />
            <div className="card-footer clear-both">
              {totalList > itemsPerPage ? (
                <Pagination
                  pageCount={pageCount}
                  page={page}
                  handlePageClick={handlePageClick}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteJournal
        deleteJournalId={deleteJournalId}
        deleteJournalItemId={deleteJournalItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshJournals={fetchJournalList}
        organizationId={currentUserInfo.organization_id}
      />
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};

export default JournalVoucherList;
