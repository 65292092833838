import { FormikProps } from "formik";
import { isAmountEditable } from "../../../../../../../helpers/pmHelpers/amountEditHelper";
import { fieldVisibility } from "../../../../../../../helpers/pmHelpers/fieldVisibilityHelper";
import { ConsumerPaymentEditPopper } from "../../../../../../common/components/consumerPaymentEditPopper/consumerPaymentEditPopper";
import BankCharges from "../../../../../../common/components/paymentFormInputs/BankCharges";
import { CurrencyList } from "../../../../../../../types";
import { useParams } from "react-router-dom";
import { PaymentAmountField } from "./PaymentAmountField";
import useCommonData from "../../../../../../hooks/useCommon";
import { zeroDisplayFormat } from "../../../../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import { usePm } from "../../usePm";

type Props = {
  formik: FormikProps<any>;
};
export const PaymentAmountDetails = (props: Props) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setUserExchangeData,
    setBankExchangeData,
    userExchangeRateSubmitHandler,
    bankExchangeRateSubmitHandler,
  } = usePm({
    formik: props.formik,
  });
  const { editId } = useParams();

  const isEditable = isAmountEditable({ formik: props.formik });
  const isFieldVisible = fieldVisibility({ formik: props.formik });
  const handleBankChange = (e: any) => {
    // props.formik.setFieldValue("bank_charges_account_id", e && e.value);
    // props.formik.setFieldValue("bank_charges_account_name", e && e.label);
  };
  const getReverseConversionValue = (value: CurrencyList) => {
    props.formik.setValues({
      ...props.formik.values,
      by_user: true,
      conversion_id: value.id,
      conversion_rate: value.rate,
      is_date_changed: false,
    });
  };

  const handleOnBlur = (name: string, amount: number, index: number) => {
    let currencyCode = currentUserInfo.currency_code;
    switch (name) {
      case "amount_paid":
        currencyCode = props.formik.values.currency_code;
        break;
      case "amount_paid_bank_currency":
        currencyCode = props.formik.values.bank_currency_code;
        break;
      case "bank_charges_bank_currency":
        currencyCode = props.formik.values.bank_currency_code;
        break;
      default:
        break;
    }
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    const roundedAmount = getRoundOffAmount(amount, decimals);
    props.formik.setFieldValue(name, roundedAmount);
  };

  const getBankChargeConvertedAmount = () => {
    let bankChargeConvertedAmount =
      props.formik.values.currency_id === props.formik.values.bank_currency_id
        ? 0
        : props.formik.values.bank_conversion_rate *
          props.formik.values.bank_charges_amount;
    const decimals = decimalPlaceOfCurrency(
      props.formik.values.bank_currency_code,
      orgCurrencyList
    );
    const roundedAmount = getRoundOffAmount(
      bankChargeConvertedAmount,
      decimals
    );
    return roundedAmount.toString();
  };

  return (
    <>
      <div
        className={`d-flex pm-paymentdetails pl-1rem ${
          !props.formik.values.vendor_id ? "disabled-item" : ""
        }`}
      >
        <PaymentAmountField
          label="Amount Paid"
          subLabel={isFieldVisible ? "Vendor Currency" : ""}
          formik={props.formik}
          name="amount_paid"
          id="amount_paid"
          prefix={
            props.formik.values.currency_code || currentUserInfo.currency_code
          }
          value={props.formik.values.amount_paid}
          error={
            (props.formik.touched.amount_paid &&
              props.formik.errors.amount_paid?.toString()) ||
            ""
          }
          handleBlur={handleOnBlur}
          isDisabled={false}
          byUser={props.formik.values.by_user}
          conversionDate={props.formik.values.payment_date}
          newConversionDate={props.formik.values.payment_date}
          currencyCode={props.formik.values.currency_code}
          currencyId={props.formik.values.currency_id}
          conversionId={props.formik.values.conversion_id}
          conversionCurrencyId={currentUserInfo.organization_currency}
          toCurrencyId={currentUserInfo.organization_currency}
          editId={editId || ""}
          setExchangeData={setUserExchangeData}
          isCurrencyChanged={props.formik.values.is_currency_changed}
          isDateChanged={props.formik.values.is_date_changed}
          conversionRate={props.formik.values.conversion_rate}
          conversionTimestamp={props.formik.values.conversion_timestamp}
          toCurrencyCode={currentUserInfo.currency_code}
          isExchangeEditable={true}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.formik.values.currency_code
                ? props.formik.values.currency_code
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          isReverseConversionRequired={false}
          getReverseConversionValue={getReverseConversionValue}
          isExchangeRateHidden={false}
          exchangeRateSubmitHandler={userExchangeRateSubmitHandler}
          exchangeRateMaxLength={
            Number(currentUserInfo.organization_currency) ===
            Number(props.formik.values.bank_currency_id)
              ? 20
              : 6
          }
        />
        {isFieldVisible && (
          <PaymentAmountField
            label="Amount Paid"
            subLabel="Bank Currency"
            formik={props.formik}
            name="amount_paid_bank_currency"
            id="amount_paid_bank_currency"
            prefix={
              props.formik.values.bank_currency_code ||
              currentUserInfo.currency_code
            }
            value={props.formik.values.amount_paid_bank_currency}
            error={
              (props.formik.touched.amount_paid_bank_currency &&
                props.formik.errors.amount_paid_bank_currency?.toString()) ||
              ""
            }
            handleBlur={handleOnBlur}
            isDisabled={isFieldVisible || !props.formik.values.vendor_id}
            conversionDate={props.formik.values.payment_date}
            newConversionDate={props.formik.values.payment_date}
            currencyCode={props.formik.values.currency_code}
            currencyId={props.formik.values.currency_id}
            conversionId={props.formik.values.bank_conversion_id}
            conversionCurrencyId={props.formik.values.currency_id}
            toCurrencyId={props.formik.values.bank_currency_id}
            editId={editId || ""}
            setExchangeData={setBankExchangeData}
            isCurrencyChanged={props.formik.values.is_currency_changed}
            isDateChanged={props.formik.values.is_date_changed}
            byUser={props.formik.values.bank_by_user}
            conversionRate={props.formik.values.bank_conversion_rate}
            conversionTimestamp={props.formik.values.bank_conversion_timestamp}
            toCurrencyCode={props.formik.values.bank_currency_code}
            isExchangeEditable={
              currentUserInfo.organization_currency ===
              props.formik.values.bank_currency_id
                ? false
                : true
            }
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                props.formik.values.bank_currency_code
                  ? props.formik.values.bank_currency_code
                  : currentUserInfo.currency_code,
                orgCurrencyList
              )
            )}
            isReverseConversionRequired={
              props.formik.values.bank_currency_id ===
              currentUserInfo.organization_currency
            }
            getReverseConversionValue={getReverseConversionValue}
            isExchangeRateHidden={
              currentUserInfo.organization_currency ===
              props.formik.values.bank_currency_id
                ? true
                : false
            }
            exchangeRateSubmitHandler={bankExchangeRateSubmitHandler}
            endAdornment={
              isEditable ? (
                <ConsumerPaymentEditPopper
                  label="Amount Paid"
                  subLabel={
                    props.formik.values.currency_id !==
                    props.formik.values.bank_currency_id
                      ? "Bank Currency"
                      : ""
                  }
                  formik={props.formik}
                  name="amount_paid"
                  id="amount_paid"
                  prefix={
                    props.formik.values.bank_currency_code ||
                    currentUserInfo.currency_code
                  }
                  value={props.formik.values.amount_paid}
                  error={
                    (props.formik.touched.amount_paid &&
                      props.formik.errors.amount_paid?.toString()) ||
                    ""
                  }
                  handleBlur={handleOnBlur}
                  isDisabled={false}
                  conversionDate={props.formik.values.payment_date}
                  currencyCode={props.formik.values.bank_currency_code}
                  conversionCurrencyCode={props.formik.values.currency_code}
                  conversionRate={props.formik.values.bank_conversion_rate}
                  editId={editId || ""}
                  isButtonDisabled={false}
                />
              ) : (
                <></>
              )
            }
            exchangeRateMaxLength={20}
          />
        )}
      </div>
      <div
        className={`col-12 col-lg-4 mw-325 mb-3 ${
          !props.formik.values.vendor_id ? "disabled-item" : ""
        }`}
      >
        <BankCharges
          bankChargesAccountId={props.formik.values.bank_charges_account_id}
          handleBankCharge={handleBankChange}
          currencyCode={
            props.formik.values.currency_code || currentUserInfo.currency_code
          }
          handleChange={props.formik.handleChange}
          handleItemOnBlur={handleOnBlur}
          bankChargesAmount={props.formik.values.bank_charges_amount}
          formErrors={
            props.formik.touched.bank_charges_amount &&
            props.formik.touched.bank_charges_account_id &&
            props.formik.errors
          }
          classWidth="w-150"
          disabled={!props.formik.values.bank_currency_id ? true : false}
          conversionCurrencyCode={props.formik.values.bank_currency_code}
          bankChargeConvertedAmount={getBankChargeConvertedAmount()}
          isBankCurrency={true}
          formik={props.formik}
        />
      </div>
    </>
  );
};
