import RestartAltSharpIcon from "@mui/icons-material/RestartAltSharp";
import "../styles/EmptyList.css";
import "../components/emptySearchList/EmptySearchList.css";

type Props = {
  list: string;
  alt: string;
  icon: string;
  handleReset: () => void;
};

const FilterEmptyList = (props: Props) => {
  const heading = [props.list];

  return (
    <div className="create-invoice-container align-center">
      <img
        className="preview-img"
        src={props.icon}
        alt={props.alt}
        width="108"
        height="120"
      />
      {heading.includes("customers") || heading.includes("vendors") ? (
        <h3>No {props.list} found!</h3>
      ) : heading.includes("items") ? (
        <h3>No Items!</h3>
      ) : (
        <h3>No transactions!</h3>
      )}

      <p>{`No ${props.list} found matching that filter`}</p>
      <button
        className="btn create-invoice-button green-button"
        type="button"
        onClick={() => props.handleReset()}
      >
        <RestartAltSharpIcon />
        <span className="create-invoice-button-text green-button-text">
          Reset filter
        </span>
      </button>
    </div>
  );
};
export default FilterEmptyList;
