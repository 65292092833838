import { useEffect, useRef, useState } from "react";
import ListHeader from "../../../../common/components/list/ListHeader";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import AddonTableContainer from "./addonTable/AddonTableContainer";
import useListData from "../../../../hooks/useListData";
import Pagination from "../../../../common/components/table/Pagination";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import AddonDelete from "../AddonDelete";
import AddonVoid from "../AddonVoid";
import { DeleteInitialData } from "../../addonType";
import { deleteInitialData } from "../../AddonStateInitialization";
import { Transactionlimit } from "../../../../../types";
import { getAddonTransactionLimitStatus } from "../../addonSlice";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";

export default function AddonList() {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { addonsRolePermission } = usePermissionHandler();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const filterRef = useRef<any>([]);
  const listRef = useRef<any>([]);
  const [deleteAction, setDeleteAction] =
    useState<DeleteInitialData>(deleteInitialData);
  const [voidAction, setVoidAction] = useState<any>({ id: -1, addonName: "" });
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  
  useEffect(() => {
    fetchTransactionLimit();
    setIsFilterApplied(false);
  }, [currentUserInfo?.organization_id]);

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getAddonTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };


  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const refreshList = () => {
    filterRef.current.refreshFilter();
    if (listRef.current.fetchAddonList) listRef.current.fetchAddonList();
  };
  const deleteListData = (actionType: string, id: number, addonName: string) =>
    setDeleteAction({ actionType: actionType, id: id, addonName: addonName });

  const voidListData = (id: number, addonName: string) => {
    $(".dropdown-ctrl").click();
    setVoidAction({ id, addonName });
  };

  return (
    <>
      <div className="addon-list card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Addons"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Addon"
          isFiscalYearFilterRequired={false}
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/addons/create");
          }}
          haveFilter={true}
          filterType="ADDON"
          applyFilter={handleApplyFilter}
          roles={addonsRolePermission}
          ref={filterRef}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <AddonTableContainer
            transactionLimit={transactionLimit}
            deleteListData={deleteListData}
            voidListData={voidListData}
            isFilterApplied={isFilterApplied}
            initializeFilter={refreshList}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <AddonDelete
        deleteAddonId={deleteAction.id}
        deleteAddonName={deleteAction.addonName}
        deleteActionType={deleteAction.actionType}
        refreshAddon={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <AddonVoid
        addonId={voidAction.id}
        addonName={voidAction.addonName}
        refreshAddon={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
}
