import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import formatDateRange from "../../../../../helpers/headerFormatterHelper";
import { handleNegativeValue } from "../../../../../helpers/helper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import { ExpandRowIcon } from "../../../../common/components/customSvgIcons/expandRowIcon";
import { ExpandableTable } from "../../../../common/components/expandableTable/ExpandableTable";
import { colors } from "../../../../constants/colors";
import {
  reportCustomizationFilterSelector,
  reportTableFilterSelector,
} from "../../ReportSlice";
import { useNavigate } from "react-router-dom";
import { ExpandedState } from "@tanstack/react-table";
import { restoreExpansionState } from "../../../../../helpers/reportHelper";

type Props = {
  data: any;
  key: string;
};
function ProfitAndLossReportTable(props: Props) {
  const navigate = useNavigate();
  const ranges = Object.keys(props.data.profit_and_loss);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const filterValue = useAppSelector(
    reportCustomizationFilterSelector
  ).profitAndLossFilters;
  let dateRange = filterValue.date_range || "this_quarter";
  const tablefilterValues = useAppSelector(reportTableFilterSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [expanded, setExpanded] = React.useState<ExpandedState>({
    "0": true,
    "1": true,
  });
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>(["0", "1"]);
  const expandedRowIdsRef = useRef<string[]>(["0", "1"]);

  useEffect(() => {
    const expandedIds = restoreExpansionState(); // Restore expansion state on component load
    if (expandedIds.length) {
      const obj: { [key: string]: boolean } = {};
      for (const key of expandedIds) {
        obj[key] = true;
      }
      setExpanded({ ...obj });
      expandedRowIdsRef.current = expandedIds;
      setExpandedRowIds([...expandedIds]);
    } else {
      setExpanded({
        "0": true,
        "1": true,
      });
    }
  }, [currentUserSelector]);

  const toBeHighlightedRows = [
    "Incomes",
    "Expenses",
    "Total Operating Income",
    "Non Operating Income",
    "Gross Profit",
    "Total Operating Expense",
    "Total Cost of Goods Sold",
    "Net Profit / Loss before tax",
    "Net Profit / Loss after tax",
  ];
  let columnMapping = ranges.map((range, index) => {
    return {
      header: formatDateRange(
        range,
        dateRange,
        currentUserInfo.organization_fiscal_year
      ),
      className: "column-mapping text-align-right",
      accessorKey: "",
      id: formatDateRange(
        range,
        filterValue.date_range,
        currentUserInfo.organization_fiscal_year
      ),
      columns: [
        {
          header: "Total",
          id: range,
          accessorKey: range,
          className: "column-mapping text-align-right",
        },
      ],
    };
  });

  const data = () => {
    let reportData: any = [];
    props.data.items.map((item: any) => {
      let rows = rowValues(item);
      reportData.push(...rows);
    });
    return reportData;
  };
  const rowValues = (item: any) => {
    let rowItem: any = {};
    rowItem.account_name = item.account_name;
    rowItem.action = item.is_category
      ? {
          isCategory: true,
          accountId: item.id,
        }
      : {
          isCategory: false,
          accountId: item.id,
        };
    rowItem["className"] = item.is_category ? "" : "row-navigation";
    if (item.range !== null) {
      for (const key in item.range as any) {
        let totalObj: any = item.range;
        rowItem[key] = handleNegativeValue(
          NumberFormat(
            Number(totalObj[key].total),
            props.data.currency_code,
            orgCurrencyList
          ),
          ""
        );
      }
    }
    if (item.node && item.node.length > 0) {
      rowItem.subRows = [];
      item.node.map((row: any) => {
        let rows = rowValues(row);
        rowItem.subRows?.push(...rows);
      });
    }
    let profitAndLossTotals = [];
    if (
      item.account_name === "Direct Incomes" ||
      item.account_name === "Indirect Incomes" ||
      item.account_name === "Direct Expenses" ||
      item.account_name === "Indirect Expenses"
    ) {
      profitAndLossTotals.push(renderFooter(item, false));
    }
    if (item.account_name === "Direct Expenses") {
      profitAndLossTotals.push(
        renderFooter({ account_name: "Gross Profit", id: "gp" }, false)
      );
    }
    if (item.account_name === "Indirect Expenses") {
      profitAndLossTotals.push(
        renderFooter({ account_name: "Loss before tax", id: "lbt" }, false)
      );
    }
    if (item.account_name === "Expenses") {
      profitAndLossTotals.push(
        renderFooter({ account_name: "Loss after tax", id: "lft" }, false)
      );
    }

    return [rowItem, ...profitAndLossTotals];
  };
  const renderFooter = (account: any, isBold: boolean) => {
    let rowItem: any = {};
    switch (account.account_name) {
      case "Direct Incomes":
        rowItem.account_name = "Total Operating Income";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;

          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].total_operating_income),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      case "Indirect Incomes":
        rowItem.account_name = "Non Operating Income";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;
          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].non_operating_income),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      case "Gross Profit":
        rowItem.account_name = "Gross Profit";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;
          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].gross_profit),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      case "Direct Expenses":
        rowItem.account_name = "Total Cost of Goods Sold";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;
          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].total_cost_of_goods_sold),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      case "Indirect Expenses":
        rowItem.account_name = "Total Operating Expense";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;
          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].total_operating_expense),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      case "Loss before tax":
        rowItem.account_name = "Net Profit / Loss before tax";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;
          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].net_profit_loss_before_tax),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      case "Loss after tax":
        rowItem.account_name = "Net Profit / Loss after tax";
        for (const key in props.data.profit_and_loss as any) {
          let totalObj: any = props.data.profit_and_loss;
          rowItem[key] = handleNegativeValue(
            NumberFormat(
              Number(totalObj[key].net_profit_loss_after_tax),
              props.data.currency_code,
              orgCurrencyList
            ),
            ""
          );
          rowItem["className"] = "cell-highlighter fw-bold";
        }
        break;
      default:
        break;
    }
    return rowItem;
  };

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        header: "Account Name",
        accessorKey: "account_name",
        className: "account-column text-align-left",
        cell: ({ row, getValue }: any) =>
          row.getCanExpand() ? (
            <span
              style={{ paddingLeft: `${row.depth * 1}rem`, cursor: "pointer" }}
              onClick={row.getToggleExpandedHandler()}
              className={
                toBeHighlightedRows.includes(getValue()) ? "fw-bold" : ""
              }
            >
              {row.getIsExpanded() ? (
                <ExpandRowIcon
                  color={colors.battleshipGrey}
                  rotate="rotate(180deg)"
                />
              ) : (
                <ExpandRowIcon
                  color={colors.battleshipGrey}
                  rotate="rotate(90deg)"
                />
              )}
              {getValue()}
            </span>
          ) : (
            <span
              // {...row.getToggleRowExpandedProps({
              style={{
                paddingLeft: `${(row.depth + 2) * 1}rem`,
              }}
              // })}
              className={
                toBeHighlightedRows.includes(getValue())
                  ? "fw-bold cell-highlighter"
                  : ""
              }
            >
              {getValue()}
            </span>
          ),
      },
      ...columnMapping,
      {
        header: "action",
        accessorKey: "action",
        className: "",
      },
    ],
    [data]
  );
  const navigation = (
    cellIndex: number,
    row: any,
    e: React.MouseEvent,
    cell: any
  ) => {
    let rowId = row?.id;
    if (row.subRows.length) {
      if (expandedRowIds.includes(rowId)) {
        const indexToRemove = expandedRowIdsRef.current.indexOf(rowId);
        if (indexToRemove !== -1) {
          expandedRowIdsRef.current.splice(indexToRemove, 1);
        }
      } else {
        expandedRowIdsRef.current.push(rowId);
      }
      setExpandedRowIds([...expandedRowIdsRef.current]);
    }

    let action = row?.original?.action;
    if (!action.isCategory) {
      accTransactionNavHandler(action.accountId);
    }
  };
  /**
   * Navigation to account transaction page to view the transactions of a particular account
   */
  const accTransactionNavHandler = (accountId: number) => {
    let profitAndLossFilter = {
      currencyId: tablefilterValues.currency_id,
      dateRange: tablefilterValues.date_range,
      startDate: tablefilterValues.start_date,
      endDate: tablefilterValues.end_date,
      organizationId: tablefilterValues.organization_id,
      tag_option_in: tablefilterValues.tag_option_in,
    };
    localStorage.setItem(
      "drill-down-filter",
      JSON.stringify(profitAndLossFilter)
    );
    localStorage.setItem(
      "drill-down-report",
      JSON.stringify({ label: "Profit and Loss", url: "/profit-and-loss" })
    );
    localStorage.setItem("expanded-rows", JSON.stringify(expandedRowIds));
    localStorage.setItem("drill-down-account", accountId.toString());
    let dateRage = "custom";
    if (
      tablefilterValues.date_range === "today" ||
      tablefilterValues.date_range === "this_week" ||
      tablefilterValues.date_range === "this_month" ||
      tablefilterValues.date_range === "this_quarter" ||
      tablefilterValues.date_range === "this_year"
    ) {
      dateRage = tablefilterValues.date_range;
    }
    let accTransactionFilter = {
      accountId: accountId,
      currencyId: tablefilterValues.currency_id,
      dateRange: dateRage,
      startDate: tablefilterValues.start_date,
      endDate: tablefilterValues.end_date,
      organizationId: tablefilterValues.organization_id,
      tag_option_in: tablefilterValues.tag_option_in,
    };
    navigate("/account-transactions", {
      state: { accTransactionFilter: accTransactionFilter },
    });
  };

  return (
    <div className="profit-and-loss-report-table-wrapper">
      <ExpandableTable
        columns={columns}
        data={data()}
        rowsToExpand={expanded}
        key={props.key}
        tableClassName="profit-loss-table"
        hiddenColumns={{ action: false }}
        rowClassName={"table-navigation"}
        haveColumnClassName={true}
        tbodyColumnClick={navigation}
      />
    </div>
  );
}

export default ProfitAndLossReportTable;
