import { currentUserDetails } from "../common/commonSlice";
import useCommonData from "./useCommon";

export const useMeApiTrigger = () => {
  const { dispatch } = useCommonData();
  const triggerMeApi = async () => {
    await dispatch(
      currentUserDetails({
        orgId: Number(sessionStorage.getItem("orgId"))
          ? Number(sessionStorage.getItem("orgId"))
          : 0,
        isOrgChanged: false,
      })
    );
  };
  return { triggerMeApi };
};
