import { useParams } from "react-router-dom";
import useCommonData from "../../../../hooks/useCommon";
import { setLoaderState } from "../../../../common/commonSlice";
import { couponDetailsForEdit, fetchCouponDetails } from "../../couponSlice";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { CouponOverviewDataType } from "../../couponType";

export const useCouponOverViewDetail = () => {
  const { currentUserInfo, dispatch } = useCommonData();
    const { couponDetailId } = useParams();
    const fetchCouponOverviewDetails = async (setCouponOverviewData: (couponDetail: CouponOverviewDataType) => void) => {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        fetchCouponDetails({
          couponId: Number(couponDetailId),
          orgId: currentUserInfo.organization_id,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        setCouponOverviewData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
      }
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
    };
    return { fetchCouponOverviewDetails };
  };
  