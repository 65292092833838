import {
  CreateCustomerValues,
  EditCustomerValues,
  ObjectType,
} from "../../../../types";
import { initialCustomerValues } from "../components/customerForm/StateInitialization";

export const customerCreatePayloadRefiner = (
  payload: CreateCustomerValues,
  defaultConsumerValues: ObjectType
) => {
  const {
    sales_account_id,
    discount,
    discount_account_id,
    discount_type,
    payment_term_id,
    customer_email,
    customer_phone,
    pan_number,
    opening_balance,
    gst_registration_type,
    gstin,
    ...rest
  } = payload;
  return (
    {
      ...rest,
      customer_email: customer_email ? customer_email : null,
      customer_phone: customer_phone ? customer_phone : null,
      pan_number: pan_number ? pan_number : null,
      opening_balance: opening_balance ? opening_balance : 0,
      sales_account_id: defaultConsumerValues.account.id || null,
      discount: defaultConsumerValues.discount.discount || 0,
      discount_account_id: defaultConsumerValues.discount.account.id || null,
      discount_type: defaultConsumerValues.discount.discountType || "Absolute",
      payment_term_id: defaultConsumerValues.paymentTerm.id,
      gst_registration_type: gst_registration_type
        ? gst_registration_type
        : null,
      gstin: gstin ? gstin : null,
    } || initialCustomerValues
  );
};
export const customerEditPayloadRefiner = (
  payload: EditCustomerValues,
  defaultConsumerValues: ObjectType
) => {
  const {
    sales_account_id,
    discount,
    discount_account_id,
    discount_type,
    payment_term_id,
    customer_email,
    customer_phone,
    pan_number,
    opening_balance,
    gst_registration_type,
    ...rest
  } = payload;
  return (
    {
      ...rest,
      customer_email: customer_email ? customer_email : null,
      customer_phone: customer_phone ? customer_phone : null,
      pan_number: pan_number ? pan_number : null,
      opening_balance: opening_balance ? opening_balance : 0,
      sales_account_id: defaultConsumerValues.account.id || null,
      discount: defaultConsumerValues.discount.discount || 0,
      discount_account_id: defaultConsumerValues.discount.account.id || null,
      discount_type: defaultConsumerValues.discount.discountType || "Absolute",
      payment_term_id: defaultConsumerValues.paymentTerm.id,
      gst_registration_type: gst_registration_type
        ? gst_registration_type
        : null,
    } || initialCustomerValues
  );
};
