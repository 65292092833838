import {
  setLoaderState,
  useCommonData,
} from "../components/paymentReceivedForm/imports";
import { paymentReceivedDetails } from "../paymentReceivedSlice";

export const useFetchPRDetails = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const getPRDetails = async (editId: number) => {
    dispatch(setLoaderState(true));

    const responseAction = await dispatch(
      paymentReceivedDetails({
        paymentReceivedId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
    return response;
  };
  return { getPRDetails };
};
