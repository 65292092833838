import SystemVoucherHeader from "./SystemVoucherHeader";
import "./SystemVoucherDetail.css";
function SystemVoucherDetail() {
  return (
    <div className="">
      <div
        className="card card-dashboard bills-detail-card cd-detail-card w-100"
        id="bills-detail-card"
      >
        <SystemVoucherHeader />
      </div>
    </div>
  );
}

export default SystemVoucherDetail;
