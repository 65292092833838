import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { couponCouponConfiguratio, saveCouponCouponConfiguratio } from "./couponConfigAPI";
import { ConfigureCouponInitialValue } from "./configureCouponType";
import { configureCouponInitialValue } from "./ConfigureCouponStateInitialization";
import { RootState } from "../../../app/store";

 /**
 * Api to get the coupon config
 */
export const getCouponConfiguration = createAsyncThunk(
    "couponConfiguration/get-saved-config",
    async (data: {orgId: number}) => {
      return await couponCouponConfiguratio(data);
    }
  );

 /**
 * Api to save the coupon config
 */
 export const submitCouponConfiguration = createAsyncThunk(
  "couponConfiguration/get-saved-config",
  async (data: {
    organization_id: number,
    multiple_coupons_enabled: boolean | string,
    order_of_application?: string,
    discount_application_method?: string,
  }) => {
    return await saveCouponCouponConfiguratio(data);
  }
);

  interface CommonState {
    isLoadingcouponConfiguration: boolean;
    couponConfiguration: ConfigureCouponInitialValue | null;
  }
  const initialState: CommonState = {
    isLoadingcouponConfiguration: false,
    couponConfiguration: configureCouponInitialValue
  };
  
  export const couponConfigurationSlice = createSlice({
    name: "couponConfigurationManagement",
    initialState,
    reducers: {
      setCouponConfiguration: (state, action) => {
        state.couponConfiguration = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(getCouponConfiguration.pending, (state, { payload }) => {
        state.isLoadingcouponConfiguration = true;
      })
      .addCase(getCouponConfiguration.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.couponConfiguration = payload;
        }
        state.isLoadingcouponConfiguration = false;
      })
    },
  });
  
  export const {
    setCouponConfiguration
  } = couponConfigurationSlice.actions;

  export default couponConfigurationSlice.reducer;

  export const couponConfiguration = (state: RootState) => state.couponConfigurationManagement.couponConfiguration;
  export const isCouponSuccess = (state: RootState) => state.couponConfigurationManagement.isLoadingcouponConfiguration;