import { Api } from "../../../constants";
import { get, post } from "../../../helpers/apiHelper";

/**
 * API call for getting migration Date
 */
export async function getMigrationDate(data: { orgId: number }) {
  const result = await get(
    Api.organizationHierarchy +
      `/get-migration-date?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for updating migration Date
 */

export async function updateMigrationDate(data: {
  orgId: number;
  migration_date: string;
}) {
  const result = await post(
    Api.organizationHierarchy +
      `/set-migration-date?current_organization=${data.orgId}&migration_date=${data.migration_date}`
  );
  return result;
}
