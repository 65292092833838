import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CreditNoteDetailValues } from "../../../../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import { CreditNoteDetailBody, MoreActions } from "./index";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { creditNoteDetailsInitialValues } from "../creditNoteForm/StateInitialization";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { setErrorID } from "../../../../../appSlice";
import {
  creditNoteDetails,
  creditNoteList,
  sendCreditNoteData,
} from "../../creditNoteSlice";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import DeleteCreditNote from "../Delete";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import Edit from "../../../../common/components/kebabMenu/Edit";
import useCommonData from "../../../../hooks/useCommon";
import NotificationModal from "../../../../common/components/NotificationModal";
import { current } from "@reduxjs/toolkit";

function CreditNoteDetailHeader() {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { creditNoteRolePermission } = usePermissionHandler();
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deleteCreditNoteId, setDeleteCreditNoteId] = useState(-1);
  const [deleteCreditItemId, setDeleteCreditItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [creditNoteData, setCreditNoteData] = useState<CreditNoteDetailValues>(
    creditNoteDetailsInitialValues
  );
  const cnDetailRef =
    useRef<{ refreshDetails: () => void; subscription_id: number }>(null);

  useEffect(() => {
    if (editId) {
      creditNoteDetail();
    }
  }, [editId, currentUserInfo.organization_id]);
  /**
   * Set the entered emails in the bill data to a state.
   */
  useEffect(() => {
    let emails = creditNoteData.email_to.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [creditNoteData]);
  /**
   * Fetch Bill Details
   */
  const creditNoteDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      creditNoteDetails({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.credit_note_status === "Draft") {
          navigate("/credit-note");
        }
        setCreditNoteData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.CNDetail));
      }
    }
  };
  /**
   * Back button handler from Bill details page
   */
  const backFromCreditNoteViewHandler = () => {
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
  };

  const fetchCreditNoteList = async (action: string) => {
    await dispatch(
      creditNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        customerList: [],
        statusList: [],
      })
    );
    if (action === "delete") {
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendCreditNoteData({
        creditNoteId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchCreditNoteList("send");
      }
    }
    return responseAction;
  };

  const refreshCNDetails = () => {
    creditNoteDetail();
    cnDetailRef.current?.refreshDetails();
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromCreditNoteViewHandler}
        data={{
          num: creditNoteData.credit_note_number,
          status: creditNoteData.credit_note_status,
        }}
        dataId={editId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editId}
          data={{
            num: creditNoteData.credit_note_number,
            status: creditNoteData.credit_note_status,
            userName: creditNoteData.customer_name,
            date: creditNoteData.credit_note_date,
            orgName: creditNoteData.organization_details.organization_name,
          }}
          isSameOrg={
            creditNoteData.organization_id === currentUserInfo.organization_id
          }
        >
          {creditNoteRolePermission.includes("Update") &&
          !creditNoteData.is_fiscal_closed &&
          cnDetailRef.current?.subscription_id === null ? (
            <Edit
              url={`/credit-note/edit/${creditNoteData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={creditNoteData.total_details.credits_used === 0}
              name="Credit Note"
              isLocked={creditNoteData.is_locked}
              isVoid={creditNoteData.credit_note_status === "Void"}
              className=""
              lockDate={creditNoteData.lock_date}
            />
          ) : null}
          {creditNoteRolePermission.includes("Delete") &&
          !creditNoteData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={true}
              onClick={() => {
                setDeleteCreditNoteId(Number(creditNoteData.id));
                setDeleteCreditItemId(creditNoteData.credit_note_number);
              }}
              isLocked={creditNoteData.is_locked}
              lockDate={creditNoteData.lock_date}
              name="Credit Note"
            />
          ) : null}
          <MoreActions
            creditNoteData={creditNoteData}
            creditNoteRolePermission={creditNoteRolePermission}
            refreshCNDetails={refreshCNDetails}
            isVoid={creditNoteData.credit_note_status === "Void"}
            isTagsAssociated={creditNoteData.is_tags_associated}
            subscriptionId={cnDetailRef.current?.subscription_id}
          />
        </HeaderBody>
        <CreditNoteDetailBody
          creditNoteData={creditNoteData}
          refreshCNDetails={refreshCNDetails}
          ref={cnDetailRef}
        />
      </div>
      <SendEmailModal
        emailLists={emailList}
        id={Number(editId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteCreditNote
        deleteCreditNoteId={deleteCreditNoteId}
        deleteCreditItemId={deleteCreditItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshCreditNotes={fetchCreditNoteList}
        organizationId={currentUserInfo.organization_id}
        creditNoteStatus={creditNoteData.credit_note_status}
      />
      <NotificationModal
        modalHeader={
          "Apply Credits from " +
          joinTransactionNumber(creditNoteData.credit_note_number)
        }
        modalBody="There are no invoices in the open status for this customer. Hence, credits cannot be applied."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
}

export default CreditNoteDetailHeader;
