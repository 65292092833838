import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
  BillValues,
  CreditApplyToBill,
  PurchaseOrderValues,
} from "../../../types";

/**
 * API call for getting all Purchase Orders.
 */
export async function getPurchaseOrderList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  vendorList: number[];
  orgIds: number[];
  statusList: string[];
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.purchaseOrder +
    "purchase_orders/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.startDate ? `&issue_start_date=${data.startDate.trim()}` : ""
    }${data.endDate ? `&issue_end_date=${data.endDate.trim()}` : ""}${
      data.startDueDate
        ? `&delivery_start_date=${data.startDueDate.trim()}`
        : ""
    }${
      data.endDueDate ? `&delivery_end_date=${data.endDueDate.trim()}` : ""
    }`.trim();
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.vendorList.length !== 0) {
    const vendorIds = data.vendorList
      .map((vendorId) => {
        return `&vendor_ids=${vendorId}`;
      })
      .join("");
    url += vendorIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

/**
 * API call for deleting Purchase Order
 */
export async function removePurchaseOrder(data: {
  deletePurchaseOrderId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.purchaseOrder +
      `purchase-order/delete/${data.deletePurchaseOrderId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating Purchase Order
 */
export async function createNewPurchaseOrder(data: {
  values: PurchaseOrderValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.purchaseOrder +
      `purchase-order/create?purchase_order_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for edit Purchase Order
 */
export async function editPurchaseOrder(data: {
  id: number;
  editPurchaseOrderInputs: PurchaseOrderValues;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.purchaseOrder +
      "purchase-order/edit/" +
      data.id +
      `?purchase_order_status=${data.status}&current_organization=${data.orgId}`,
    data.editPurchaseOrderInputs
  );
  return result;
}

/**
 * API call for delete Purchase Order item
 */
export async function deletePurchaseOrderItem(data: {
  purchaseOrderId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.purchaseOrder +
      "purchase-order-item/delete/" +
      data.purchaseOrderId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete Purchase Order recipients
 */
export async function deletePurchaseOrderRecipient(data: {
  purchaseOrderId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.purchaseOrder +
      "purchase-order-recipient/delete/" +
      data.purchaseOrderId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get Purchase Order number
 */
export async function getPurchaseOrderNumber(orgId: number) {
  const result = await get(
    Api.purchaseOrder +
      `new-purchase-order-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for checking the Purchase Order number exist or not
 */
export async function purchaseOrderNumberExist(data: {
  purchaseOrderNumber: string;
  orgId: number;
  purchaseOrderId: number;
}) {
  const result = await get(
    Api.purchaseOrder +
      "purchase-order-number-exist?purchase_order_number=" +
      data.purchaseOrderNumber +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete Purchase Order item tax
 */
export async function deletePurchaseOrderItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.purchaseOrder +
      "purchase-order-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Purchase Order detail
 */
export async function getPurchaseOrderDetails(data: {
  purchaseOrderId: number;
  orgId: number;
}) {
  const result = await get(
    Api.purchaseOrder +
      `purchase-order/details/${data.purchaseOrderId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Purchase Order detail
 */
export async function purchaseOrderStatusChange(data: {
  purchaseOrderId: number;
  orgId: number;
  status: "Draft" | "Open" | "Billed" | "Cancelled";
}) {
  const result = await put(
    Api.purchaseOrder +
      `change-purchase-order-status?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}&purchase_order_status=${data.status}`
  );
  return result;
}

/**
 * API call for sending bill to contact details
 */
export async function sendPurchaseOrder(data: {
  purchaseOrderId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.purchaseOrder +
      `send-purchase-order?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API call for calculating taxable amount
 */

export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.purchaseOrder +
      `purchase-order-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating Purchase Order amount
 */
export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.purchaseOrder +
      `purchase-order/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * Api to get Purchase Order pdf link
 */
export async function billPdfLink(data: {
  purchaseOrderId: number;
  orgId: number;
}) {
  const result = await get(
    Api.purchaseOrder +
      "download-link?purchase_order_id=" +
      data.purchaseOrderId +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to upload Purchase Order attachments
 */
export async function billAttachments(data: { file: FormData; orgId: number }) {
  const result = await postFormData(
    Api.purchaseOrder +
      `purchase-order-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete Purchase Order attachments
 */
export async function billAttachmentsDelete(data: {
  purchaseOrderId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.purchaseOrder +
      `purchase-order-attachments/${data.purchaseOrderId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.purchaseOrder + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to get apply credit list of Purchase Order
 */
export async function applyCreditList(data: {
  purchaseOrderId: number;
  orgId: number;
}) {
  const result = await get(
    Api.purchaseOrder +
      `apply-debits/list?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to calculate credits
 */
export async function creditsCalculation(data: {
  orgId: number;
  values: {
    purchase_order_balance: number;
    allocation_list: number[];
    currency_id: number | null;
  };
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.purchaseOrder +
      `apply-debits/calculate?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to apply credits to Purchase Order
 */
export async function applyCreditsToPurchaseOrders(data: {
  purchaseOrderId: number;
  orgId: number;
  values: CreditApplyToBill[];
}) {
  const result = await put(
    Api.purchaseOrder +
      `apply-debits/edit?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * Api to create write off against Purchase Order
 */
export async function writeOffCreate(data: {
  orgId: number;
  write_off_values: {
    amount: string | number;
    account_id: number | null;
    purchase_order_id: number | null;
    is_partial: boolean;
  };
}) {
  const result = await post(
    Api.purchaseOrder + `write-off/create?current_organization=${data.orgId}`,
    data.write_off_values
  );
  return result;
}
/**
 * Api to update write off against a Purchase Order
 */
export async function writeOffUpdate(data: {
  orgId: number;
  write_off_id: number | null;
  write_off_values: {
    amount: string | number;
    is_partial: boolean;
  };
}) {
  const result = await put(
    Api.purchaseOrder +
      `write-off/edit/${data.write_off_id}?current_organization=${data.orgId}`,
    data.write_off_values
  );
  return result;
}
/**
 * Api to update write off against a bill
 */
export async function writeOffDetails(data: {
  orgId: number;
  purchaseOrderId: number | null;
}) {
  const result = await get(
    Api.purchaseOrder +
      `write-off/details?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to update write off against a bill
 */
export async function writeOffDelete(data: {
  orgId: number;
  write_off_id: number | null;
}) {
  const result = await remove(
    Api.purchaseOrder +
      `write-off/cancel/${data.write_off_id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to get journal for reporting tags
 */
export async function getReportingTagJournals(data: {
  purchaseOrderId: number;
  orgId: number;
}) {
  const result = await get(
    Api.purchaseOrder +
      `reporting-tag/journal-generate?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  purchaseOrderId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.purchaseOrder +
      `reporting-tag/edit/${data.purchaseOrderId}?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}

/**
 * Api to get tags which are associated to a particular Purchase Order
 */
export async function getTagsOptionsAssociatedToPurchaseOrders(data: {
  purchaseOrderIds: number[];
  orgId: number;
}) {
  const idsUrl = data.purchaseOrderIds.map((id) => {
    return `purchase_order_ids=${id}`;
  });
  const result = await get(
    Api.purchaseOrder +
      `reporting-tag/purchase-order/payment-association?current_organization=${
        data.orgId
      }${idsUrl.length > 0 ? `&${idsUrl.join("&")}` : ""}`
  );
  return result;
}

/**
 * Api to mark Purchase Order as void
 */
export async function billVoidMarker(data: {
  purchaseOrderId: number | null;
  orgId: number;
}) {
  const result = await put(
    Api.purchaseOrder +
      `bill/mark-void/${data.purchaseOrderId}?purchase_order_id=${data.purchaseOrderId}&current_organization=${data.orgId}`
  );
  return result;
}
