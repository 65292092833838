import React, { createContext, useContext, useEffect, useState } from "react";
import useCommonData from "../../../../hooks/useCommon";
import {
  taxPayable,
  taxReceivable,
} from "../../../../home/gst/taxRates/taxRateSlice";
import { ObjectType } from "../../../../../types";
import { TAX_TYPES } from "../../../../constants/constants";

const TaxSelectContext = createContext({
  fetchTaxesList: () => {},
  taxList: [] as ObjectType[],
});

type Props = {
  taxType: string;
  children: React.ReactNode;
};

export const TaxSelectProvider = (props: any) => {
  const { dispatch, orgCurrencyList, currentUserInfo } = useCommonData();
  const [taxList, setTaxList] = useState([]);

  useEffect(() => {
    fetchTaxesList();
  }, [currentUserInfo.organization_id]);

  const fetchTaxesList = async () => {
    if (props.taxType === TAX_TYPES.PAYABLE) {
      const responseAction = await dispatch(
        taxPayable({
          orgId: currentUserInfo.organization_id,
        })
      );
      const res = responseAction.payload;
      setTaxList(res.tc_list_payable);
    } else if (props.taxType === TAX_TYPES.RECEIVABLE) {
      const responseAction = await dispatch(
        taxReceivable({
          orgId: currentUserInfo.organization_id,
        })
      );
      const res = responseAction.payload;
      setTaxList(res.tc_list_receivable);
    }
  };

  return (
    <TaxSelectContext.Provider
      value={{
        fetchTaxesList: fetchTaxesList,
        taxList: taxList,
      }}
    >
      {props.children}
    </TaxSelectContext.Provider>
  );
};

export const useTaxSelect = () => {
  return useContext(TaxSelectContext);
};
