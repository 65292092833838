import React, { useCallback, useEffect, useRef, useState } from "react";
import { WriteOffRadioButton } from "./components/WriteOffRadioButton/WriteOffRadioButtonGroup";
import { useFormik } from "formik";
import InputPrefix from "../formPrefixInputField/InputPrefix";
import { SaveButton } from "../button/SaveButton";
import { SecondaryButton } from "../button/SecondaryButton";
import { TextButton } from "../button/TextButton";
import "../../../../index.css";
import "./WriteOffForm.css";
import FormControl from "@mui/material/FormControl";
import { writeOffValidation } from "./WriteOffFormValidation";
import { getRoundOffAmount } from "../../../../helpers/roundOffHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../hooks/useCommon";
import AccountSelect from "../paymentFormInputs/AccountSelect";
import { WriteOffDate } from "./components/WriteOffDate";

type WriteOffPropsType = {
  key?: number | null;
  writeOffId?: number | null;
  handleClose: () => void;
  createWriteOff: (
    values: {
      amount: string;
      is_partial: boolean;
      account_id: number | null;
      write_off_date: string;
    },
    onCloseModal: () => void
  ) => Promise<void>;
  updateWriteOff: (
    values: {
      amount: string;
      is_partial: boolean;
      account_id: number | null;
      write_off_date: string;
    },
    onCloseModal: () => void
  ) => Promise<void>;
  deleteWriteOff: (onCloseModal: () => void) => Promise<void>;
  itemId?: number | null;
  maxAmount?: number | string | null;
  currencyCode: string | null | undefined;
  values: {
    amount: string;
    is_partial: boolean;
    account_id: number | null;
    write_off_date: string;
  };
  accountType: string[];
  transactionDate: string;
};
export const WriteOffForm = (props: WriteOffPropsType) => {
  const { orgCurrencyList } = useCommonData();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const formik = useFormik({
    initialValues: { ...props.values },
    validate: (values) => {
      return writeOffValidation({
        ...values,
        max_amount: Number(props.maxAmount) + Number(props.values.amount),
        transaction_date: props.transactionDate,
      });
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      Number(formik.values.amount) ===
        Number(props.maxAmount) + Number(props.values.amount) &&
        formik.setFieldValue("is_partial", false);
      !props.writeOffId
        ? await props.createWriteOff(formik.values, props.handleClose)
        : await props.updateWriteOff(formik.values, props.handleClose);
    },
  });
  useEffect(() => {
    Number(formik.values.amount) ===
      Number(props.maxAmount) + Number(props.values.amount) &&
      formik.setFieldValue("is_partial", false);
  }, [formik.values.amount]);

  useEffect(() => {
    !formik.values.is_partial &&
      formik.touched.is_partial &&
      formik.setFieldValue(
        "amount",
        Number(props.maxAmount) + Number(props.values.amount)
      );
  }, [formik.values.is_partial]);

  const handleBlur = () => {
    const decimals = decimalPlaceOfCurrency(
      props.currencyCode,
      orgCurrencyList
    );
    const roundedAmount = getRoundOffAmount(
      Number(formik.values.amount),
      decimals
    );
    formik.setFieldValue("amount", roundedAmount);
  };

  const handleDatePickerClose = (
    event: React.MouseEvent<HTMLFormElement, MouseEvent>
  ) => {
    if (isDatePickerOpen) {
      const datePickerContainer = document.querySelector(
        ".MuiCalendarOrClockPicker-root"
      );
      if (
        !(
          datePickerContainer &&
          datePickerContainer.contains(event.target as Node)
        )
      ) {
        setIsDatePickerOpen(false);
      }
    }
  };

  return (
    <form
      className="write-off-form-wrapper"
      onSubmit={formik.handleSubmit}
      onClick={handleDatePickerClose}
    >
      <WriteOffRadioButton
        name="is_partial"
        id="is_partial"
        valuesArray={[
          { label: "Partial Write Off", value: true },
          { label: "Full Write Off", value: false },
        ]}
        value={formik.values.is_partial}
        onChange={formik.handleChange}
        isDisabled={formik.isSubmitting}
        formik={formik}
      />

      <WriteOffDate
        formik={formik}
        isOpen={isDatePickerOpen}
        openPicker={() => {
          setIsDatePickerOpen(true);
        }}
        closePicker={() => {
          setIsDatePickerOpen(false);
        }}
        transactionDate={props.transactionDate}
      />

      <FormControl
        className="form-control-account-select"
        disabled={formik.isSubmitting}
      >
        <label htmlFor="account" className="fw-bold required">
          Choose Account
        </label>
        <AccountSelect
          handleBankCharge={(e) => {
            formik.setFieldValue("account_id", e.value);
          }}
          bankChargesAccountId={formik.values.account_id}
          accountNames={props.accountType}
          error={
            formik.errors.account_id && formik.touched.account_id
              ? formik.errors.account_id
              : ""
          }
          wrapperClass="w-100"
        />
        <span className="error">
          {formik.errors.account_id && formik.touched.account_id
            ? formik.errors.account_id
            : ""}
        </span>
      </FormControl>
      <div className="row write-off-amount">
        <div className="col">
          <label htmlFor="amount" className="fw-bold required">
            Enter Amount
          </label>
          <InputPrefix
            name="amount"
            id="amount"
            prefix={props.currencyCode ? props.currencyCode : ""}
            value={formik.values.amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value
                .replace(/[^\d.]/g, "")
                .replace(/^(\d*\.\d*)\..*/, "$1");
              formik.setFieldValue("amount", value);
            }}
            error={
              formik.errors.amount && formik.touched.amount
                ? formik.errors.amount
                : ""
            }
            placeholder="Enter write off amount"
            disabled={
              !formik.values.is_partial || formik.isSubmitting ? true : false
            }
            className={`form-control ${
              formik.values.is_partial ? "" : "write-off-amount-disabled"
            }`}
            inputType="text"
            onBlur={handleBlur}
          />
          <span className="error">
            {formik.touched.amount && formik.errors.amount}
          </span>
        </div>
      </div>
      <div
        className={
          props.writeOffId
            ? "write-off-form-button-wrap-cancel-write-off"
            : "write-off-form-button-wrap"
        }
      >
        <SaveButton
          type="submit"
          isDisabled={formik.isSubmitting}
          buttonContent="Save"
          onClick={formik.submitForm}
        />
        <SecondaryButton
          type="button"
          isDisabled={formik.isSubmitting}
          buttonContent="Cancel"
          onClick={props.handleClose}
        />
        {props.writeOffId && (
          <TextButton
            type="button"
            isDisabled={formik.isSubmitting}
            buttonContent="Cancel Write Off"
            fontColor={"red"}
            fontSize="15px"
            fontWeight="bold"
            onClick={() => {
              formik.setSubmitting(true);
              props.deleteWriteOff && props.deleteWriteOff(props.handleClose);
            }}
          />
        )}
      </div>
    </form>
  );
};
