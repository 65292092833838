import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "@mui/material/Modal";
import CloseButton from "../button/CloseButton";
import useToast from "../../../hooks/useToast";
import { ModalContext } from "./centerModalContext";
import "./CenterModal.css";
import "../../../../index.css";

type ReusableModalProps = {
  buttonContent: React.ReactNode;
  modalContent: React.ReactNode;
  modalTitle: string;
  modalTitleComponent?: React.ReactNode;
  modalTitleClass?: string;
  wrapperClass?: string;
  buttonClass?: string;
  modalBodyClass?: string;
  modalContentClass?: string;
  isCloseButtonEnabled?: boolean;
  isInline?: boolean;
  isModalButtonPositionStart?: boolean;
  onOpenFunction?: () => Promise<void>;
  onCloseFunction?: () => void;
  disabledActionFunction?: () => void;
  isButtonDisabled?: boolean;
  backdropOpacity?: number;
};
// The content to this modal should be styled independently.
// The modal is just a container to hold the form or the upcoming component.
// Make sure that the buttons in the form are styled properly.

function CenterModal(
  {
    buttonContent,
    modalContent,
    modalTitle,
    modalTitleComponent,
    modalTitleClass,
    wrapperClass,
    buttonClass,
    modalBodyClass,
    modalContentClass,
    isCloseButtonEnabled,
    isModalButtonPositionStart,
    isInline,
    disabledActionFunction,
    isButtonDisabled,
    onOpenFunction,
    onCloseFunction,
    backdropOpacity,
  }: ReusableModalProps,
  ref?: React.ForwardedRef<any>
) {
  const [isOpen, setIsOpen] = useState(false);
  const { clearToast } = useToast();

  const openModal = async () => {
    onOpenFunction && onOpenFunction();
    setIsOpen(true);
    ($(".dropdown-ctrl") as any).dropdown("hide");
    clearToast("error");
  };

  const closeModal = () => {
    setIsOpen(false);
    onCloseFunction && onCloseFunction();
  };
  const getBrowser = (): string => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("firefox") || userAgent.includes("fxios"))
      return "firefox";
    if (
      userAgent.includes("chrome") ||
      userAgent.includes("crios") ||
      userAgent.includes("crmo")
    )
      return "chrome";
    return "other";
  };

  const handleOpenModalClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (isButtonDisabled === true) {
      e.preventDefault();
      disabledActionFunction && disabledActionFunction();
      return;
    }
    const browser = getBrowser();
    if (browser === "firefox") {
      if (e.type === "click") {
        const mouseEvent = e as React.MouseEvent<HTMLButtonElement>;
        if (mouseEvent.clientX !== 0 && mouseEvent.clientY !== 0) {
          openModal();
        }
      }
    } else {
      const nativeEvent = e.nativeEvent as PointerEvent;
      const pointerType = nativeEvent.pointerType;
      if (pointerType === "mouse" || pointerType === "touch" || !pointerType) {
        openModal();
      }
    }
  };
  useImperativeHandle(ref, () => ({
    openCenterModal: openModal,
  }));
  // const getBrowser = navigator.userAgent
  return (
    <ModalContext.Provider value={{ onCloseModal: closeModal }}>
      <div
        className={
          isInline
            ? "d-inline-block"
            : isModalButtonPositionStart
            ? "modal-open-btn-no-flex-end"
            : "reusable-modal-btn"
        }
      >
        <button
          onClick={handleOpenModalClick}
          // onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          //   e.preventDefault();
          //   openModal();
          // }}
          type="button"
          className={buttonClass}
          disabled={isButtonDisabled ? isButtonDisabled : false}
        >
          {buttonContent}
        </button>
      </div>
      <Modal
        open={isOpen}
        onClose={closeModal}
        className={`central-modal ${wrapperClass ? wrapperClass : ""}`}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: `rgba(0,0,0,${
              backdropOpacity ? backdropOpacity : 0.5
            })`,
          },
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-dialog">
            <div
              className={`center-modal-content modal-content ${
                modalContentClass ? modalContentClass : ""
              }`}
            >
              {isCloseButtonEnabled && (
                <span className="modal-close-btn">
                  <CloseButton closeModalHandler={closeModal} />
                </span>
              )}
              <div
                className={`modal-header position-relative ${
                  modalTitleClass ? modalTitleClass : ""
                }`}
              >
                <h5 className="modal-title">{modalTitle}</h5>
                <div>{modalTitleComponent}</div>
              </div>
              <div
                className={`center-modal-body modal-body ${
                  modalBodyClass ? modalBodyClass : ""
                }`}
              >
                {modalContent}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </ModalContext.Provider>
  );
}
export default React.memo(forwardRef(CenterModal));
