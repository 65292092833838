import * as Yup from "yup";

// Validation schema for the system voucher form
const systemVoucherValidationSchema = (
  checkDuplicateName: Function,
  checkDuplicatePrefix: Function,
  systemVoucherId?: number
) =>
  Yup.object({
    systemVoucherName: Yup.string()
      .required("System voucher name is required")
      .test(
        "checkDuplicateName",
        "Voucher name already exists",
        async function (value) {
          if (value) {
            const response = await checkDuplicateName(value, systemVoucherId || 0);
            if (response.system_voucher_name_exist) {
              return false;
            }
            return true;
          }
          return true;
        }
      ),
    prefix: Yup.string()
      .required("Prefix is required")
      // .max(10, "Prefix cannot exceed 10 characters")
      .test(
        "checkDuplicatePrefix",
        "Voucher prefix already exists",
        async function (value) {
          if (value) {
            const response = await checkDuplicatePrefix(value, systemVoucherId || 0);
            if (response.prefix_exist) {
              return false;
            }
            return true;
          }
          return true;
        }
      ),
    accountIds: Yup.array()
      .min(1, "At least one account must be selected")
      .required("Accounts field is required"),
  });

export default systemVoucherValidationSchema;
