import { getFormatedDate } from "../../../../../helpers/helper";
import {
  PAYMENT_TERMS,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../../constants/constants";

export const initialTaxValues = {
  tax_id: "",
  tax_percentage: "",
};
export const initialItemValues = {
  account_name: "",
  item_name: "",
  // hsn_or_sac_code: "",
  account_id: "",
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "Absolute",
  discount_account_id: "",
  discount_account_name: "",
  taxes: [initialTaxValues],
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  tds_name: "",
  currency_id: null,
};
export const initialBillValues = {
  vendor_id: "",
  vendor_name: "",
  bill_number: "",
  reference_number: "",
  bill_date: getFormatedDate(new Date()),
  payment_term_id: "",
  due_date: getFormatedDate(new Date()),
  vendor_notes: "",
  tds_percentage: "",
  file_ids: [],
  tag_ids: [],
  items: [initialItemValues],
  email_to: [],
  conversion_id: "",
  currency_id: "",
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,
  source_of_supply: null,
  destination_of_supply: null,
  recalculate_exchange_rate: false,
  // rcm_enabled: false,
};

export const initialDiscountValues = {
  discount_value: 0,
  discount_percentage: 0,
};

export const initialTotalValues = {
  total_taxable_amount: 0,
  tax_amount: 0,
  total: 0,
  taxes: [],
  tds_percentage: 0,
  tds_amount: 0,
  transaction_tds_amount: 0,
};

export const initialDefaultValues = {
  vendor_id: "",
  vendor_name: "",
  bill_number: "",
  reference_number: "",
  bill_date: getFormatedDate(new Date()),
  payment_term_id: PAYMENT_TERMS.due_on_receipt,
  due_date: getFormatedDate(new Date()),
  tag_ids: [],
  currency_id: "",
  source_of_supply: null,
  destination_state: null,
  bill_status: "",
  is_mutable: true,
  rcm_enabled: false,
};
