import React from "react";
import { colors } from "../../../constants/colors";

const CoaListViewIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          id="list_view"
          d="M6.667 3.333H17.5V5H6.667V3.333zM2.5 2.917H5v2.5H2.5v-2.5zm0 5.833H5v2.5H2.5v-2.5zm0 5.833H5v2.5H2.5v-2.5zm4.167-5.416H17.5v1.666H6.667V9.167zm0 5.833H17.5v1.667H6.667V15z"
          fill={colors.white}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default CoaListViewIcon;
