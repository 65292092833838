import { coustomCase } from "../../../../../helpers/planHelper";
import "./impactDetailsModalContent.css";

function ImpactDetailsModalContent(props: any) {
 const checkFromChangedType = (type: string, field: string) => {
  if (type === "MODIFIED" && ["billing_address", "shipping_address" ].includes(field)) return 'updated'
  else if (type === 'REMOVED') return  'removed'
  else if (type === "MODIFIED") return 'changed from'
  else return ""
 }

 const checkToChangedType = (type: string, field: string) => {
  if (type === "ADDED") return 'added'
  else if (type === "MODIFIED" && !["billing_address", "shipping_address" ].includes(field)) return 'to'
  return ''
 }
  return (
    <div className="impact-details-modal-content-wrapper">
      <div className="changes-container">
        {props?.scheduledSubscriptionData?.edit_changes && props.scheduledSubscriptionData.edit_changes.map(
          (item: any, index: number) => {
            return (
              item.changed_field ?
                <div className="change-item" key={index}>
                  {item.change_type ? (
                    <div className={`status ${item.change_type ? item.change_type.toLowerCase() : ''}`}>{item.change_type}</div>
                  ) : null}
                  <div className="change-details">
                    {!item.parent_field ? null : coustomCase(item.parent_field.replaceAll("_", " "))}
                      {coustomCase(item.changed_field.replaceAll("_", " "))}
                    {" "}
                    {checkFromChangedType(item.change_type, item.changed_field) + " "}
                    {typeof item.changed_from === "string" ? ["place_of_supply", "subscription_number"].includes(item.changed_field) ?  item.changed_from : item.changed_from.toLowerCase() : item.changed_from}{" "}
                    {checkToChangedType(item.change_type, item.changed_field) + " "}
                    {typeof item.changed_to === "string" ? ["place_of_supply", "subscription_number"].includes(item.changed_field) ? item.changed_to : item.changed_to.toLowerCase() : item.changed_to}
                  </div>
                </div>
                : <></>
            );
          }
        )}
      </div>
    </div>
  );
}

export default ImpactDetailsModalContent;
