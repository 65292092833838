import { ObjectType } from "../types";

export const IsFeatureEnabled = (
  feature: string,
  featureList: ObjectType[]
) => {
  let featureArray = featureList;
  const featureObj = featureArray?.find(
    (item) => item["feature_name"] === feature
  );
  return featureObj && featureObj["disabled"] === true ? false : true;
};
