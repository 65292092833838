export const autoGenerateShortCode = (input: any) => {
  const shortCodeRegex = /^[a-zA-Z0-9]*$/;
  let inpustringRefined = "";
  let inputString = input.trim().split(" ");
  const shortCode: HTMLElement = document.getElementById(
    "short_code"
  ) as HTMLElement;
  shortCode.className = "form-control";
  if (inputString && shortCodeRegex.test(inputString) === false) {
    inputString = inputString.toString();
    for (let i = 0; i < inputString.length; i++) {
      if (shortCodeRegex.test(inputString[i]) === true) {
        inpustringRefined = inpustringRefined + inputString[i];
      }
    }
    inputString = inpustringRefined;
  }
  if (inputString) {
    let shortCode = "";
    if (inputString.length > 1) {
      if (inputString[0].length >= 2 && inputString[1].length >= 2) {
        shortCode = inputString[0].slice(0, 2) + inputString[1].slice(0, 2);
      } else {
        shortCode = inputString[0].slice(0, 1) + inputString[1].slice(0, 1);
      }
    } else {
      if (inputString[0].length >= 4) shortCode = inputString[0].slice(0, 4);
      else shortCode = inputString[0].slice(0, inputString[0].length);
    }
    return (shortCode = shortCode.toUpperCase());
  } else {
    return "";
  }
};
