import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../../app/hooks";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../../../helpers/helper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../../../helpers/prefixHelper";
import { ObjectType } from "../../../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../../../common/commonSlice";
import InputPrefix from "../../../../../../common/components/formPrefixInputField/InputPrefix";
import { useCalculateForex } from "../../../../../../hooks/amountCalculation/useForexCalculationHook";
import { getForexGainOrLossAmount } from "../../../../../../../helpers/transactionHelper";
import * as _ from "lodash";
import { getAmountCalculationResult } from "../../../../paymentReceivedSlice";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import CustomDatepicker from "../../../../../../utils/atoms/datepicker";
import { useParams } from "react-router-dom";
type Props = {
  index: number;
  item: ObjectType;
  currencyCode: string;
  handlePaymentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formik: FormikProps<any>;
  errors: any;
};
function CustomerUnpaidInvoice({
  index,
  errors,
  item,
  currencyCode,
  handlePaymentChange,
  formik,
}: Props) {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { editId } = useParams();
  const [isPayloadChanged, setPayloadChanged] = useState(false);
  const [isAllocationDateChanged, setAllocationDateChanged] = useState(false);
  const totalDetails = useAppSelector<{ [key: string]: any }>(
    getAmountCalculationResult
  ).total_details;
  const initialPayload = {
    entity_id: formik.initialValues?.unpaid_invoice_lines[index]?.invoice_id,
    source_conversion_id:
      formik.initialValues?.unpaid_invoice_lines[index]?.conversion_id,
    target_conversion_id: formik.initialValues.conversion_id,
    amount: !isNaN(formik.initialValues?.unpaid_invoice_lines[index]?.payment)
      ? Number(formik.initialValues?.unpaid_invoice_lines[index]?.payment)
      : 0,
    target_type: "PaymentsReceived",
  };
  const currentPayload = {
    entity_id: item.invoice_id,
    source_conversion_id: item.conversion_id,
    target_conversion_id: formik.values.conversion_id,
    amount: !isNaN(item.payment) ? Number(item.payment) : 0,
    target_type: "PaymentsReceived",
  };

  const forex = useCalculateForex({
    payloadInput: {
      entity_id: item.invoice_id,
      source_conversion_id: item.conversion_id,
      target_conversion_id: formik.values.conversion_id,
      amount: !isNaN(item.payment) ? Number(item.payment) : 0,
      target_type: "PaymentsReceived",
    },
    forex: item.forex_amount ? { [item.invoice_id]: item.forex_amount } : null,
    isPayloadChanged: isPayloadChanged,
  });
  const handleOnBlur = (name: string, amount: number, index: number) => {
    const decimals = decimalPlaceOfCurrency(
      formik.values.currency_code,
      orgCurrencyList
    );
    const roundedAmount = getRoundOffAmount(amount, decimals);
    formik.setFieldValue(name, roundedAmount);
  };

  useEffect(() => {
    formik.setFieldValue("is_total_error", formik.errors.total ? true : false);
  }, [formik.errors.total]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  return Object.keys(item).length ? (
    <tr
      key={"item-" + item.id + formik.values.is_tax_deducted}
      style={{ marginBottom: "100px" }}
    >
      <td className="item_number">
        {joinTransactionNumber(item.invoice_number)}
      </td>
      <td className="item_date">{DateFormatHandler(item.invoice_date)}</td>
      <td className="item_amount">
        {NumberFormat(
          Number(item.invoice_amount),
          currencyCode ? currencyCode : "",
          orgCurrencyList
        )}
      </td>
      <td className="amount_due">
        {item.amount_due === null
          ? 0
          : NumberFormat(
              Number(item.amount_due),
              currencyCode ? currencyCode : "",
              orgCurrencyList
            )}
      </td>
      {currentUserInfo.is_payment_allocation_enabled && (
        <td className="allocation-date-col">
          <CustomDatepicker
            handleDate={(date) => {
              setAllocationDateChanged(true);
              if (date) {
                formik.setFieldValue(
                  `unpaid_invoice_lines.${index}.allocation_date`,
                  date
                );
              }
            }}
            date={new Date(item.allocation_date)}
            id={"allocation_date" + index}
            type="paymentDate"
            enableFutureDate={true}
            error={
              errors &&
              errors[index] &&
              errors[index].payment !== errors[index].allocation_date &&
              errors[index].allocation_date
            }
          />
          <span className="error">
            {errors &&
              errors[index] &&
              errors[index].payment !== errors[index].allocation_date &&
              errors[index].allocation_date}
          </span>
        </td>
      )}
      {formik.values.is_tax_deducted === "true" ||
      formik.values.is_tax_deducted === true ? (
        <td className="withholding-tax-col">
          <InputPrefix
            wrapperClass={`inv-group payment-entry d-flex w-130 ${
              formik.errors.total?.length ? "error" : ""
            }`}
            prefix={currencyCode ? currencyCode : ""}
            inputType="text"
            name={`unpaid_invoice_lines.${index}.withholding_tax`}
            id={"withholding_tax" + index}
            className="form-control payment border-end-0"
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                currencyCode ? currencyCode : currentUserInfo.currency_code,
                orgCurrencyList
              )
            )}
            value={
              item.withholding_tax !== 0 && !Number.isNaN(item.withholding_tax)
                ? item.withholding_tax
                : ""
            }
            disabled={totalDetails.total || item.withholding_tax ? false : true}
            error={errors && errors[index] && errors[index].withholding_tax}
            onChange={(e) => {
              handlePaymentChange(e);
              setPayloadChanged(true);
              if (editId && !isAllocationDateChanged) {
                formik.setFieldValue(
                  `unpaid_invoice_lines.${index}.allocation_date`,
                  getFormatedDate()
                );
              }
            }}
            index={index}
            isAmountField={true}
            onBlur={handleOnBlur}
            onKeyPress={handleKeyPress}
          />
          <span className="error w-220 unpaid-error">
            {errors &&
              errors[index] &&
              errors[index].payment !== errors[index].withholding_tax &&
              errors[index].withholding_tax}
          </span>
        </td>
      ) : (
        <></>
      )}
      <td className="payment-col">
        <InputPrefix
          wrapperClass={`inv-group payment-entry d-flex w-130 ${
            formik.errors.total?.length ? "error" : ""
          }`}
          prefix={currencyCode ? currencyCode : ""}
          inputType="text"
          name={`unpaid_invoice_lines.${index}.payment`}
          id={"payment" + index}
          className="form-control payment border-end-0"
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              currencyCode ? currencyCode : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          isAmountField={true}
          value={
            item.payment && !Number.isNaN(item.payment) ? `${item.payment}` : ""
          }
          disabled={totalDetails?.total || item.payment ? false : true}
          error={errors && errors[index] && errors[index].payment}
          onChange={(e) => {
            handlePaymentChange(e);
            setPayloadChanged(true);
            if (editId && !isAllocationDateChanged) {
              formik.setFieldValue(
                `unpaid_invoice_lines.${index}.allocation_date`,
                getFormatedDate()
              );
            }
          }}
          onBlur={handleOnBlur}
          index={index}
          onKeyPress={handleKeyPress}
        />
        <span className="inv-group payment-entry d-block inv-balance">
          {typeof totalDetails === "object" &&
          totalDetails?.invoice_balances_after_payment?.[item.invoice_id]
            ? "Balance due: " +
              currencyCode +
              " " +
              NumberFormat(
                totalDetails?.invoice_balances_after_payment?.[
                  `${item.invoice_id}`
                ],
                currencyCode ? currencyCode : "",
                orgCurrencyList
              )
            : totalDetails?.invoice_balances_after_payment &&
              Number(
                totalDetails?.invoice_balances_after_payment?.[
                  `${item.invoice_id}`
                ]
              ) === 0
            ? "Balance due: " + currencyCode + " " + "0"
            : ""}
        </span>
        <span className="error w-220 unpaid-error">
          {errors &&
            errors[index] &&
            errors &&
            errors[index] &&
            errors[index].payment !== errors[index].withholding_tax &&
            errors[index].payment}
          {errors &&
            errors[index] &&
            errors[index].payment === errors[index].withholding_tax && (
              <span className="error w-220 unpaid-error">
                {errors[index].payment}
              </span>
            )}
        </span>
      </td>
      <td className="forex_gain_loss">
        {getForexGainOrLossAmount(
          item.invoice_id,
          forex,
          currencyCode,
          orgCurrencyList
        )}
      </td>
    </tr>
  ) : (
    <></>
  );
}

export default CustomerUnpaidInvoice;
