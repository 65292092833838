import { SvgIconProp } from "../../../../types";

export const SendEmailIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : ""}
      style={{ margin: props.margin ? props.margin : "" }}
      viewBox="0 0 20 20"
    >
      {" "}
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          d="M16.5 15.005a.75.75 0 0 1-.744.745H2.244a.745.745 0 0 1-.744-.745v-.755H15V5.475l-6 5.4-7.5-6.75V3a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 .75.75v12.005zM3.325 3.75 9 8.857l5.675-5.107H3.325zM0 11.25h6v1.5H0v-1.5zM0 7.5h3.75V9H0V7.5z"
          fill={props.color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
