import TaxRateForm from "./TaxRateForm";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../common/components/sideModal/SideModal";

type Props = {
  taxId: number;
  refreshList: () => void;
  isAccountDeleted: boolean;
  className?: string;
  children: React.ReactNode;
};
export const TaxRateFormContainer = ({
  taxId,
  refreshList,
  isAccountDeleted,
  className,
  children,
}: Props) => {
  const ButtonComponent = () => {
    return <>{children}</>;
  };
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;

            return (
              <TaxRateForm
                taxId={taxId}
                handleModalClose={() => {
                  onCloseModal();
                }}
                isAccountDeleted={isAccountDeleted}
                refreshList={() => refreshList()}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ButtonComponent />}
      modalTitle={taxId > 0 ? "Edit Tax" : "Add New Tax"}
      buttonClass={className ? className : "no-appearance w-100"}
      wrapperClass="tax-edit-side-modal w-100"
    />
  );
};
