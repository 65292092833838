import * as yup from "yup";

export const taxRateFormValidation = (id: number, isChanged: boolean) =>
  yup.object({
    name: yup.string().required("Enter the Tax Name"),
    rate: yup.string().required("Enter a valid Tax Rate (%)").min(1),
    tax_type: yup.string().required("Select the Tax Type"),
    is_checked: yup.boolean().test({
      name: "is_checked",
      message: "Please read and accept the Terms and Conditions",
      test: function (value) {
        const checked = this.options.context?.is_checked;

        if (checked === false && isChanged && id) {
          return false;
        }
        return true;
      },
    }),
  });
