import {
  AssociatedTagOptionsType,
  BillPaymentDetailValues,
  ReportingTagTransactionObjectPM,
} from "../../../../../../../types";

export const getJVTableHeader = () => {
  return [
    {
      Header: "Account",
      accessor: "show.account_name",
      className: "rt-account",
    },
    {
      Header: "Debit",
      accessor: "show.debit",
      className: "rt-debit",
    },
    {
      Header: "Credit",
      accessor: "show.credit",
      className: "rt-credit",
    },
  ];
};

export const getUpdatedJournals = (
  journals: any,
  accountId: number,
  splitType: string,
  tagsList: any,
  entityId: number | null | undefined
) => {
  let options_list = tagsList.map(
    (tag: { option_id: number; amount: number }) => {
      return {
        option_id: tag.option_id,
        amount: Number(tag.amount),
      };
    }
  );
  let newJournal = [...journals] as any;
  const journalIndex = newJournal.findIndex(
    (journal: any) => journal.account_id === accountId
  );
  newJournal[journalIndex].split_type = splitType;
  newJournal[journalIndex].options_list = options_list;
  return newJournal;
};

export const getPayloadForJournalGenerate = (
  response: BillPaymentDetailValues
) => {
  return {
    vendor_id: response.vendor_id,
    amount_paid: response.amount_paid,
    unpaid_bill_line: response.unpaid_bill_lines,
    is_allocated_at_first_save: false,
    bank_charges_account_id: response.bank_charges_account_id,
    bank_charges_amount: response.bank_charges_amount,
    paid_through_account_id: response.paid_through_account_id,
    organization_id: response.organization_id,
  };
};
export const getIsTagOptionExist = (
  invId: number,
  tagOptionsForInvoices: AssociatedTagOptionsType
) => {
  return Object.keys(tagOptionsForInvoices[String(invId)] || {}).length > 0
    ? true
    : false;
};

export const filterTransactionListPayload = (
  journals: ReportingTagTransactionObjectPM
) => {
  return {
    transaction_list: Object.values(journals)
      .flatMap((values) => values.transaction_list)
      .filter((entries) => entries.options_list.length > 0),
  };
};
