import React from "react";
import PaymentTotalItem from "../../../../common/components/payments/PaymentTotalItem";
import PaymentTotalSection from "../../../../common/components/payments/PaymentTotalSection";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import { CurrencyFormType } from "../../../../../types";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  currencyCode: string | undefined;
  total: number;
  orgCurrencyList: CurrencyFormType[];
  amountPaid: string | number;
  amountUsedForPayment: number;
  excessAmount: number;
  totalRefundedAmount: string | number;
};
function PaymentTotal({
  currencyCode,
  total,
  orgCurrencyList,
  amountPaid,
  amountUsedForPayment,
  excessAmount,
  totalRefundedAmount,
}: Props) {
  const { currentUserInfo } = useCommonData();

  const totalAmount = total
    ? NumberFormat(
        Number(total),
        currencyCode ? currencyCode : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          currencyCode ? currencyCode : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const paidAmount = amountPaid
    ? NumberFormat(
        Number(amountPaid),
        currencyCode ? currencyCode : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          currencyCode ? currencyCode : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const amountForPayment = amountUsedForPayment
    ? NumberFormat(
        Number(amountUsedForPayment),
        currencyCode ? currencyCode : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          currencyCode ? currencyCode : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  const excess = excessAmount
    ? NumberFormat(
        Number(excessAmount),
        currencyCode ? currencyCode : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          currencyCode ? currencyCode : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );

  const refundedAmount = totalRefundedAmount
    ? NumberFormat(
        Number(totalRefundedAmount),
        currencyCode ? currencyCode : "",
        orgCurrencyList
      )
    : zeroDisplayFormat(
        decimalPlaceOfCurrency(
          currencyCode ? currencyCode : currentUserInfo.currency_code,
          orgCurrencyList
        )
      );
  return (
    <PaymentTotalSection>
      <PaymentTotalItem
        label="Total"
        value={`${currencyCode}${" "}${totalAmount}`}
        labelClassName="total-text"
        valueClassName="total-amt"
      />
      <PaymentTotalItem
        label="Amount Paid:"
        value={`${currencyCode}${" "}${paidAmount}`}
      />
      <PaymentTotalItem
        label="Amount used for Payments:"
        value={`${currencyCode}${" "}${amountForPayment}`}
      />
      <PaymentTotalItem
        label="Amount Refunded:"
        value={`${currencyCode}${" "}${refundedAmount}`}
      />
      <PaymentTotalItem
        label="Amount in Excess:"
        value={`${currencyCode}${" "}${excess}`}
      />
    </PaymentTotalSection>
  );
}

export default PaymentTotal;
