import { OrgValues } from "../../../../../types";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import { SubOrgForm } from "./subOrgForm";
import { AddNewSquarePlusIcon } from "../../../../common/components/customSvgIcons/addNewSquarePlusIcon";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { EditIcon } from "../../../../common/components/customSvgIcons/editIcon";
import { colors } from "../../../../constants/colors";
import { PlusIcon } from "../../../../common/components/customSvgIcons/plusIcon";
type Props = {
  currentOrgId?: number;
  parentOrgId?: number | null;
  values: OrgValues;
  editId?: number;
  isListButton?: boolean;
  isListLink?: boolean;
  fetchHierarchyList: () => Promise<any>;
};
export const SubOrgAddModal = (props: Props) => {
  const ButtonComponent = () => {
    if (props.editId) {
      return (
        <span className="dropdown-item">
          <EditIcon color={colors.battleshipGrey} />
          <span>Edit</span>
        </span>
      );
    } else if (props.isListLink === true) {
      return <span className="addSub"> Add Sub Org / Branch</span>;
    } else if (props.isListButton === true) {
      return <AddNewSquarePlusIcon />;
    } else {
      return (
        <span className="dropdown-item">
          <PlusIcon color={colors.battleshipGrey} />
          <span>Add Sub Org / Branch</span>
        </span>
      );
    }
  };
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <div className="sub-org-create-edit-form-modal-wrap">
                <SubOrgForm
                  values={props.values}
                  parentId={props.parentOrgId}
                  currentOrgId={props.currentOrgId}
                  editId={props.editId}
                  handleClose={onCloseModal}
                  fetchHierarchyList={props.fetchHierarchyList}
                />
              </div>
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ButtonComponent />}
      buttonClass={
        props.isListButton
          ? "add-new-blue-btn"
          : props.editId
          ? "no-appearance w-100 p-0"
          : "no-appearance  p-0"
      }
      modalTitle={
        props.editId ? "Edit Sub Org / Branch" : "Add Sub Org / Branch"
      }
      wrapperClass="add-sub-org-modal-wrapper w-100"
    />
  );
};
