import React from "react";
import { colors } from "../../../constants/colors";

const NextLabelSvg = React.memo(() => {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="epvl9f0z9a" d="m.938 0 4 4-4 4L0 7.062 3.063 4 0 .937z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="m6lpjky77b" fill={colors.white}>
          <use xlinkHref="#epvl9f0z9a" />
        </mask>
        <use fill={colors.charcoalGrey} xlinkHref="#epvl9f0z9a" />
        <g mask="url(#m6lpjky77b)" fill={colors.charcoalGrey}>
          <path d="M-38-32h72v72h-72z" />
        </g>
      </g>
    </svg>
  );
});

export default NextLabelSvg;
