import { colors } from "../../../constants/colors";
import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { CancelIcon } from "../CancelIcon";
import { Span } from "../htmlTags/Span";
import { CancelMarkedPopupBody } from "./CancelMarkedPopupBody";
import { CancelMarkedPopupProps } from "./types";

export const CancelMarkedPopup = (props: CancelMarkedPopupProps) => {
  return (
    <AlertModal
      modalButtonWrapperClass="cancel-marked-popup-wrapper-class w-100"
      buttonContent={
        <Span className="d-flex align-items-center">
          {" "}
          {props.icon ? (
            props.icon
          ) : (
            <CancelIcon
              color={props.iconColor ? props.iconColor : colors.battleshipGrey}
              margin="0px 5px 0px 0px"
            />
          )}
          {props.popupButtonText}
        </Span>
      }
      modalBodyClass="cancel-marked-popup-body-wrapper"
      isCloseButtonEnabled={true}
      modalTitle={props.popupTitle}
      disabledActionFunction={() => {}}
      buttonClass="no-appearance cancel-marked-popup-btn dropdown-item w-100"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <CancelMarkedPopupBody
                onCancel={onCloseModal}
                onSave={props.onSave}
                className=""
              />
            );
          }}
        </ModalContext.Consumer>
      }
    />
  );
};
