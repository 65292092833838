import React, { useEffect } from "react";
import { ItemTaxValues, ObjectType } from "../../../types";
import {
  taxPayable,
  taxReceivable,
} from "../../home/gst/taxRates/taxRateSlice";
import TaxCode from "./taxCode";
import "../styles/TaxCode.css";
import { colors } from "../../constants/colors";
import { RemoveIcon } from "./customSvgIcons/RemoveIcon";
import useGst from "../../hooks/useGst";
import { zeroDisplayFormat } from "../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../helpers/decimalPlaceHelper";
import useCommonData from "../../hooks/useCommon";

type Props = {
  itemIndex: number;
  taxes: ItemTaxValues[];
  taxErrors: ObjectType[] | undefined;
  organizationId: number;
  taxType: string;
  disabled?: boolean,
  taxLabelOnChange: (e: any, num: number, taxIndex: number) => void;
  taxValueOnChange: (e: any, num: number, taxIndex: number) => void;
  deleteItemTax: (e: any, id: number | undefined) => void;
  handleTaxOnBlur: (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number
  ) => void;
  disabledFeature: ObjectType[];
  currencyCode: string | undefined;
};
const Itemtax = (props: Props) => {
  const { dispatch, orgCurrencyList, currentUserInfo } = useCommonData();
  const { isGstOrg } = useGst();

  useEffect(() => {
    fetchTaxesList();
  }, [props.organizationId]);
  /**
   * Fetch all Tax Lists
   */
  const fetchTaxesList = async () => {
    if (props.taxType === "payable") {
      const responseAction = await dispatch(
        taxPayable({
          orgId: props.organizationId,
        })
      );
    } else if (props.taxType === "receivable") {
      const responseAction = await dispatch(
        taxReceivable({
          orgId: props.organizationId,
        })
      );
    }
  };

  return (
    <>
      {props.taxes?.map((tax, taxIndex) => {
        return (
          <div key={taxIndex}>
            <label
              htmlFor={"tax_id_" + props.itemIndex + "_" + taxIndex}
              className="hidden"
            >
              Tax
            </label>
            <label
              htmlFor={"tax_percentage_" + props.itemIndex + "_" + taxIndex}
              className="hidden"
            >
              Tax percentage
            </label>
            <div
              className={
                // props.itemFormErrors &&
                // props.itemFormErrors[props.itemIndex]?.taxes &&
                // props.itemFormErrors[props.itemIndex]?.taxes[taxIndex]?.tax_id
                props.taxErrors && props.taxErrors[taxIndex]?.tax_id
                  ? "d-flex tax-selector error align-items-center w-151 mt-0"
                  : "d-flex tax-selector align-items-center w-151 mt-0"
              }
              key={"itemtax-" + props.itemIndex + taxIndex}
            >
              {!isGstOrg && (
                <input
                  name="tax_percentage"
                  className={
                    props.taxErrors &&
                    props.taxErrors[taxIndex]?.tax_id &&
                    props.taxErrors[taxIndex]?.tax_id ===
                      "Tax label already used"
                      ? "tax_percentage border-start-0 border-end-0 ps-1 customer-form-section-disable"
                      : "tax_percentage border-start-0 border-end-0 ps-1"
                  }
                  type="text"
                  disabled={
                    props.disabled
                      ? props.disabled
                      : props.taxErrors &&
                        props.taxErrors[taxIndex]?.tax_id &&
                        props.taxErrors[taxIndex]?.tax_id ===
                          "Tax label already used"
                      ? true
                      : false
                  }
                  id={"tax_percentage_" + props.itemIndex + "_" + taxIndex}
                  placeholder={zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      props.currencyCode
                        ? props.currencyCode
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )}
                  value={
                    props.taxErrors &&
                    props.taxErrors[taxIndex]?.tax_id &&
                    props.taxErrors[taxIndex]?.tax_id ===
                      "Tax label already used"
                      ? ""
                      : tax.tax_percentage
                  }
                  onChange={(e) =>
                    props.taxValueOnChange(e, props.itemIndex, taxIndex)
                  }
                  onBlur={() =>
                    props.handleTaxOnBlur(
                      "tax_percentage",
                      tax.tax_percentage ? Number(tax.tax_percentage) : 0,
                      props.itemIndex,
                      taxIndex
                    )
                  }
                />
              )}
              <TaxCode
                taxIndex={taxIndex}
                taxType={props.taxType}
                taxes={props.taxes}
                taxLabelOnChange={props.taxLabelOnChange}
                itemIndex={props.itemIndex}
                fetchTaxesList={fetchTaxesList}
                organizationId={props.organizationId}
                disabledFeature={props.disabledFeature}
              />
              <button
                type="button"
                className="removeItem no-appearance"
                onClick={(e) => {
                  e.preventDefault();
                  props.deleteItemTax(taxIndex, tax.id);
                  $(
                    "#tax-amount-label_" + props.itemIndex + "_" + taxIndex
                  ).text("");
                }}
              >
                <RemoveIcon color={colors.battleshipGrey} />
              </button>
            </div>
            <span
              key={"itemtaxlabel-" + props.itemIndex + taxIndex}
              className={
                props.taxErrors && props.taxErrors[taxIndex]?.tax_id
                  ? "display-none tax-amount-label p-0"
                  : "tax-amount-label p-0"
              }
              id={"tax-amount-label_" + props.itemIndex + "_" + taxIndex}
            ></span>
            <span className="p-0 error">
              {props.taxErrors && props.taxErrors[taxIndex]?.tax_id}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default Itemtax;
