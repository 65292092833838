import {
  EditCustomerValues,
  CreateCustomerValues,
  CreateContactValues,
  CreateAddressValues,
  CustomerDropdownList,
} from "./../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewCustomer,
  createNewCustomerAddress,
  createNewCustomerContact,
  deleteAddress,
  deleteCustomer,
  deleteCustomerContact,
  editCustomer,
  editCustomerAddress,
  editCustomerContact,
  getCustomerDetails,
  getCustomerDetailsForTransaction,
  getCustomerDropdownList,
  getCustomerFilterList,
  getCustomerListForSoa,
  getCustomersList,
  getGstTypesList,
  getSearchSuggestions,
  shortCodeExist,
  transactionLimitStatus,
  getTaxExemptionReasonListForCustomer,
} from "./customerAPI";
import { RootState } from "../../../app/store";

export const createCustomer = createAsyncThunk(
  "createCustomer",
  async (data: { values: CreateCustomerValues; orgId: number }) => {
    return await createNewCustomer(data);
  }
);

export const checkShortCode = createAsyncThunk(
  "customer/shortCode",
  async (data: { customerName: string; shortCode: string; orgId: number }) => {
    return await shortCodeExist(data);
  }
);

export const editCustomerDetails = createAsyncThunk(
  "editCustomer",
  async (data: {
    id: number;
    editCustomerInputs: EditCustomerValues;
    orgId: number;
  }) => {
    return await editCustomer(data);
  }
);

export const customersList = createAsyncThunk(
  "customerList",
  async (data: {
    page: number;
    itemsPerPage: number;
    customerName: string;
    orgId: number;
    orgIds: number[];
  }) => {
    return await getCustomersList(data);
  }
);

export const customerSuggestions = createAsyncThunk(
  "customer/suggestions",
  async (data: { customerName: string; orgId: number }) => {
    return await getSearchSuggestions(data);
  }
);

export const customerDetails = createAsyncThunk(
  "customerDetails",
  async (data: { id: number; orgId: number }) => {
    return await getCustomerDetails(data);
  }
);

export const customerDetailsForTransactions = createAsyncThunk(
  "customer/transaction-detail",
  async (data: { customerId: number; orgId: number }) => {
    return await getCustomerDetailsForTransaction(data);
  }
);

export const deleteCustomerDetails = createAsyncThunk(
  "deleteCustomer",
  async (data: { id: number; orgId: number }) => {
    return await deleteCustomer(data);
  }
);

export const createCustomerAddress = createAsyncThunk(
  "createCustomerAddress",
  async (data: {
    id: number;
    addressInputs: CreateAddressValues[];
    orgId: number;
  }) => {
    return await createNewCustomerAddress(data);
  }
);

export const updateCustomerAddress = createAsyncThunk(
  "updateCustomerAddress",
  async (data: {
    customerId: number;
    addressInputs: CreateAddressValues[];
    orgId: number;
  }) => {
    return await editCustomerAddress(data);
  }
);

export const deleteCustomerAddress = createAsyncThunk(
  "deleteAddress",
  async (data: { customerId: number; addressId: number[]; orgId: number }) => {
    return await deleteAddress(data);
  }
);

export const createCustomerContact = createAsyncThunk(
  "createCustomerContact",
  async (data: {
    id: number;
    contactInputs: CreateContactValues;
    orgId: number;
  }) => {
    return await createNewCustomerContact(data);
  }
);

export const updateCustomerContact = createAsyncThunk(
  "editCustomerContact",
  async (data: {
    customerId: number;
    contactId: number;
    editContactInputs: CreateContactValues;
    orgId: number;
  }) => {
    return await editCustomerContact(data);
  }
);

export const deleteCustomerContactDetails = createAsyncThunk(
  "deleteCustomerContact",
  async (data: { customerId: number; contactId: number; orgId: number }) => {
    return await deleteCustomerContact(data);
  }
);

export const gstTypesList = createAsyncThunk(
  "gstTypes",
  async (orgId: number) => {
    return await getGstTypesList(orgId);
  }
);

export const customerDropdownList = createAsyncThunk(
  "customer/dropdown-list",
  async (orgId: number) => {
    return await getCustomerDropdownList(orgId);
  }
);

export const customerFilterList = createAsyncThunk(
  "customer/customer-filter-list",
  async (data: { orgId: number; organizationIds: number[] }) => {
    return await getCustomerFilterList(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "customer/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const customerDropdownListForSoa = createAsyncThunk(
  "customer/dropdown-list-for-soa",
  async (data: {
    orgId: number;
    currentOrgId: number;
    isIntegrated?: boolean;
  }) => {
    return await getCustomerListForSoa(data);
  }
);

export const getTaxExemptionReasonList = createAsyncThunk(
  "getTaxExemptionReasonList",
  async (data: {
    orgId: number;
    exemption_id?: number;
  }) => {
    return await getTaxExemptionReasonListForCustomer(data);
  }
);

interface UsersState {
  gstTypes: string[];
  customerDropdownList: CustomerDropdownList[];
  customerDropdownListForSoa: CustomerDropdownList[];
}

const initialState: UsersState = {
  gstTypes: [],
  customerDropdownList: [],
  customerDropdownListForSoa: [],
};
export const customerSlice = createSlice({
  name: "customerManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(gstTypesList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.gstTypes = payload;
        }
      })
      .addCase(customerDropdownList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.customerDropdownList = payload;
        } else {
          state.customerDropdownList = [];
        }
      })
      .addCase(customerDropdownListForSoa.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.customerDropdownListForSoa = payload;
        } else {
          state.customerDropdownListForSoa = [];
        }
      });
  },
});

export default customerSlice.reducer;

// State selectors
export const gstTypesSelector = (state: RootState) =>
  state.customerManagement.gstTypes;
export const customerListSelector = (state: RootState) =>
  state.customerManagement.customerDropdownList;
export const customerSoaListSelector = (state: RootState) =>
  state.customerManagement.customerDropdownListForSoa;
