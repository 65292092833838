import React from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { listboxCustomStyle } from "../../../../common/components/ListBoxCustomStyle";
import TdsFormContainer from "../tdsForm/TdsFormContainer";
import TdsPreferenceContainer from "../tdsPreference/TdsPreferenceContainer";

const ListHeader = ({ refreshList }: { refreshList: () => void }) => {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let tdsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TDS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  return (
    <>
      <Box component="div" sx={listboxCustomStyle.headerBox}>
        <Grid component="h2" sx={listboxCustomStyle.h2}>
          TDS
        </Grid>
        <Grid sx={listboxCustomStyle.rightButton}>
          {(
            tdsPermission.includes("Create") &&
            tdsPermission.includes("Read") &&
            tdsPermission.includes("Update") &&
            tdsPermission.includes("Delete")
          ) && <TdsPreferenceContainer />}
          {tdsPermission.includes("Create") && (
            <TdsFormContainer
              id={0}
              isEdit={false}
              refreshList={refreshList}
              createdFrom="tds_page"
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ListHeader;
