import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import RefundForm from "./components/refundForm/RefundForm";
import { BillPaymentDetailValues } from "../../../../../types";

type Props = {
  editId: number;
  refreshList: () => void;
  className?: string;
  children: React.ReactNode;
  paymentMadeData: BillPaymentDetailValues;
};
const RefundModal = ({
  editId,
  paymentMadeData,
  refreshList,
  className,
  children,
}: Props) => {
  const ButtonComponent = () => {
    return <>{children}</>;
  };
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;

            return (
              <RefundForm
                handleModalClose={() => {
                  onCloseModal();
                }}
                paymentMadeData={paymentMadeData}
                editId={editId}
                refreshList={refreshList}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ButtonComponent />}
      modalTitle={""}
      buttonClass={className ? className : "no-appearance w-100"}
      wrapperClass="tax-edit-side-modal w-100"
    />
  );
};

export default RefundModal;
