import React from "react";
import UserService from "../../../keycloak/UserService";
import MaintenancePage from "../../common/components/MaintenancePage/MaintenancePage";
import { Processing } from "../../../assets/images";

import "./AccountClosingMaintenance.css";

const AccountClosingMaintenance = () => {
  const logoutHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh-token");
    UserService.doLogout();
    sessionStorage.removeItem("orgId");
    sessionStorage.removeItem("rolePermissions");
    sessionStorage.removeItem("organizationRole");
    sessionStorage.removeItem("OrgName");
  };
  const generateFooterElement = () => {
    const orgName = sessionStorage.getItem("OrgName") || null;
    return (
      <>
        <div className="message-footer">
          <div className="signature-wrapper">
            <span className="signature-label">Signed in on</span>
            <p>{orgName ? orgName : "Organization name"}</p>
          </div>
          <button
            className="logout-btn btn btn-color-red"
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="page page-without-menu">
        <MaintenancePage
          isLogo={true}
          isFooter={true}
          headerImageUrl={Processing}
          headerText="Fiscal Year Closing in Progress"
          footerElement={generateFooterElement()}
        >
          <>
            <p className="message-salutation">Hi there,</p>

            <p className="message-body">
              We are currently closing accounts for all the completed fiscal
              years and performing essential account maintenance. During this
              time, you will be unable to perform any actions on your account.
              This process ensures that your financial data remains accurate and
              up-to-date.
            </p>

            <p className="message-closing-line">
              Thank you for your patience and understanding,
            </p>
          </>
        </MaintenancePage>
      </div>
    </>
  );
};

export default AccountClosingMaintenance;
