import { Api } from "../../../constants";
import { get, post } from "../../../helpers/apiHelper";

/**
 * API call for closing fiscal Years
 */

export async function closeFiscalYear(data: {
  orgId: number;
  closing_date: string[];
}) {
  const closingDatesQuery = data.closing_date
    .map((date) => `closing_dates=${date}`)
    .join("&");
  const result = await post(
    Api.chartOfAccount +
      `66697363-616c-636c-6f73-696e67617069?organization_id=${data.orgId}&${closingDatesQuery}`
  );
  return result;
}

/**
 * API call for Cancelling closing fiscal Years (Reopen Fiscal Years)
 */

export async function ReopenFiscalYears(data: {
  orgId: number;
  closing_date: string[];
}) {
  const closingDatesQuery = data.closing_date
    .map((date) => `closing_dates=${date}`)
    .join("&");
  const result = await post(
    Api.chartOfAccount +
      `cancel-closing-postings?organization_id=${data.orgId}&${closingDatesQuery}`
  );
  return result;
}

/**
 * API call for checking whether closing fiscal years are scheduled
 */

export async function scheduledClosingFiscalYear(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount + `get-running-closing?organization_id=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting the Start date of a closed transaction
 */

export async function getStartDate(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      `get-accounting-start-date?organization_id=${data.orgId}`
  );
  return result;
}

/**
 *
 * API call for checking whether closing fiscal years is scheduled
 */

export async function scheduledForClosingFiscalYear(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount + `get-scheduled-closing?organization_id=${data.orgId}`
  );
  return result;
}

/**
 * API call for Cancelling scheduled closing fiscal Years
 */

export async function cancelClosingFiscalYears(data: {
  orgId: number;
  closing_date: string[];
}) {
  const closingDatesQuery = data.closing_date
    .map((date) => `closing_dates=${date}`)
    .join("&");
  const result = await post(
    Api.chartOfAccount +
      `cancel-closing-schedule?organization_id=${data.orgId}&${closingDatesQuery}`
  );
  return result;
}
