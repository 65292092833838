import { useMemo, useRef } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { BillDetailValues } from "../../../../../types";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { tdsApplyLevel } from "../../../../constants/constants";
import { PurchaseOrderDetailValues } from "../../types";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import {
  amountInWordsConfigSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import JournelView from "../../../../common/components/JournelView";
import FileList from "../../../../common/components/FileList";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";

type Props = {
  purchaseOrderData: PurchaseOrderDetailValues;
  purchaseOrderDetail: () => void;
  reRenderFlag: number;
};

const PurchaseOrderDetailsBody: React.FC<Props> = ({
  purchaseOrderData,
  purchaseOrderDetail,
  reRenderFlag,
}) => {
  let taxTypes = Object.entries(purchaseOrderData.total_details.taxes);
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const { debitNoteRolePermission } = usePermissionHandler();
  const transactionRef = useRef<{ fetchDNPMList: () => void }>(null);
  const creditApplyRef = useRef<{ getApplyCreditList: () => void }>(null);
  const { isGstOrg } = useGst();
  const isAuditLogEnabled = process.env.REACT_APP_AUDIT_LOG_ENABLED;

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "DESCRIPTION",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "Hsn/sac code",
        accessor: "show.hsnCode",
        className: "hsncode text-nowrap",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "QUANTITY",
        accessor: "show.quantity",
        className: "quantity text-nowrap text-align-right",
      },
      {
        Header: "RATE",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "DISCOUNT",
        accessor: "show.discount",
        className: "discount text-nowrap text-align-right",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "TAX",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={purchaseOrderData.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          purchaseOrderData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {purchaseOrderData.currency_code}
                {NumberFormat(
                  Number(value.tds_total),
                  purchaseOrderData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {/* <span>
                
              </span> */}
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "TAXABLE AMOUNT",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ],
    [purchaseOrderData.currency_code]
  );

  /**
   * set table row data
   */
  const data = purchaseOrderData.item_details
    ? purchaseOrderData.item_details.map((itemData, index) => ({
        show: {
          description: itemData.item_name,
          hsnCode: itemData.hsn_or_sac_code,
          account: itemData.account_name,
          quantity: NumberFormat(
            Number(itemData.quantity),
            purchaseOrderData.currency_code,
            orgCurrencyList
          ),
          rate:
            purchaseOrderData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.rate),
              purchaseOrderData.currency_code,
              orgCurrencyList
            ) +
            " / Unit",
          discount: {
            discount:
              purchaseOrderData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.discount.discount_value),
                purchaseOrderData.currency_code,
                orgCurrencyList
              ),
            discountAccount: itemData.discount_account_name,
          },
          tax:
            itemData.gst_tax?.tax_id !== null
              ? itemData.gst_tax
              : itemData.taxes,
          taxableAmount:
            purchaseOrderData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.taxable_amount),
              purchaseOrderData.currency_code,
              orgCurrencyList
            ),
          item: itemData,
        },
      }))
    : [];
  return (
    <div id="invoice" className="transaction-preview">
      <div className="preview-top-butons w-100 d-flex justify-content-end">
        {purchaseOrderData.organization_id ===
          currentUserInfo.organization_id &&
        debitNoteRolePermission.includes("Read") &&
        purchaseOrderData.total_details?.balance_due &&
        Number(purchaseOrderData?.total_details?.balance_due) > 0 &&
        // purchaseOrderData.balance_due &&
        // Number(purchaseOrderData.balance_due) > 0 &&
        purchaseOrderData.excess_debit &&
        Number(purchaseOrderData.excess_debit) > 0 ? (
          <div>
            <div className="credits-available credits-available-label">
              Credits Available{" "}
              <span className="available-amount">
                {NumberFormat(
                  Number(purchaseOrderData.excess_debit),
                  purchaseOrderData.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <div className="invoice-header d-flex justify-content-between">
        <CompanyDetails
          organizationData={purchaseOrderData.organization_details}
        />
        <div className="invoice-details">
          <div className="label-invoice">Purchase Order</div>
          <div className="invoice-number-label">Purchase Order Number</div>
          <div className="invoice-number">
            {joinTransactionNumber(purchaseOrderData.purchase_order_number) ||
              ""}
          </div>

          {purchaseOrderData.reference_number ? (
            <>
              <div className="invoice-number-label">Reference Number</div>
              <div className="invoice-number">
                {purchaseOrderData.reference_number}
              </div>
            </>
          ) : null}

          {/* <div className="invoice-number-label">Balance Due</div>
          <div className="invoice-number mb-0">
            {purchaseOrderData.currency_code}{" "}
            {purchaseOrderData.balance_due
              ? NumberFormat(
                  Number(purchaseOrderData.balance_due),
                  purchaseOrderData.currency_code,
                  orgCurrencyList
                )
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    purchaseOrderData.currency_code
                      ? purchaseOrderData.currency_code
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
          </div> */}
        </div>
      </div>
      <div className="purchase-order-details-section d-flex">
        <div className="purchase-order-to">
          <span className="purchase-order-details-header">
            Purchase Order From
          </span>
          <div className="vendor-name pt-3">
            {purchaseOrderData.vendor_name}
          </div>
        </div>
        <div className="inv-date-wrap">
          <div className="inv-date">
            <span className="purchase-order-details-header">Issue Date</span>
            <div>{DateFormatHandler(purchaseOrderData.issue_date)}</div>
          </div>
          <div className="inv-date">
            <span className="purchase-order-details-header">Delivery Date</span>
            <div>{DateFormatHandler(purchaseOrderData.delivery_date)}</div>
          </div>
        </div>
        {/* <div className="payment-terms">
          <span className="purchase-order-details-header">Payment Terms</span>
          <div>
            {purchaseOrderData.payment_term_id
              ? purchaseOrderData.payment_term
              : "Custom"}
          </div>
        </div> */}
        {(purchaseOrderData?.source_of_supply !== null ||
          purchaseOrderData?.destination_of_supply !== null) && (
          <>
            <div className="inv-date-wrap purchase-order-detail-right-align">
              {purchaseOrderData?.source_of_supply && (
                <>
                  <div className="inv-date">
                    <span className="purchase-order-details-header">
                      Source Of Supply
                    </span>
                    <div>{purchaseOrderData?.source_of_supply}</div>
                  </div>
                </>
              )}

              <div
                className="inv-date"
                style={{
                  marginTop: !purchaseOrderData?.source_of_supply
                    ? "0px"
                    : "21px",
                }}
              >
                <span className="purchase-order-details-header">
                  Destination Of Supply
                </span>
                <div>{purchaseOrderData?.destination_of_supply}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <RowWiseFieldView
        mapObject={purchaseOrderData.custom_fields}
        module="Bills"
      />
      <div className="table-section">
        <div className="table-wrap">
          <DetailsTableContainer
            columns={columns}
            data={data}
            className="table purchase-orders-preview-table"
            haveColumnClassName={true}
            hiddenColumns={["TDS"]}
          />
        </div>
        <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
          <div className="thankyou-note w-50 d-flex align-items-end">
            {purchaseOrderData?.vendor_notes && (
              <div className="note-container">
                <span className="label">Vendor Notes</span>
                <p>{purchaseOrderData.vendor_notes}</p>
              </div>
            )}
          </div>
          <DetailTotalSection
            currencyCode={purchaseOrderData.currency_code}
            totalTax={purchaseOrderData.total_details.tax_amount}
            paymentLabel="Payment made"
            payment={purchaseOrderData.total_details.payment_made}
            taxTypes={taxTypes}
            subTotal={purchaseOrderData.total_details.total_taxable_amount}
            total={purchaseOrderData.total_details.total}
            totalInWords={purchaseOrderData.total_details.amount_in_words}
            balanceDueRequired={true}
            balanceLabel="Balance Due"
            balanceAmount={
              purchaseOrderData.balance_due
                ? purchaseOrderData.balance_due
                : zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      purchaseOrderData.currency_code
                        ? purchaseOrderData.currency_code
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )
            }
            tdsItems={{
              tdsAmount: purchaseOrderData.total_details.transaction_tds_amount
                ? purchaseOrderData.total_details.transaction_tds_amount
                : 0,
              tdsName: purchaseOrderData.tds_name,
              tdsPercentage: purchaseOrderData.tds_percentage,
              defaultTdsPercentage: purchaseOrderData.default_tds_percentage,
            }}
            tcsItems={{
              tcsAmount: purchaseOrderData.tcs_total,
              tcsName: purchaseOrderData.tcs_name,
              tcsPercentage: purchaseOrderData.tcs_percentage,
            }}
            roundOffValue={purchaseOrderData.total_details.roundoff_amount}
            roundoffEnabled={purchaseOrderData.roundoff_enabled}
            isAmountInWordsEnabled={purchaseOrderData.is_words_enabled}
          >
            <></>
          </DetailTotalSection>
        </div>
      </div>
      {purchaseOrderData.purchase_order_files.length ? (
        <FileList files={purchaseOrderData.purchase_order_files} />
      ) : null}
      {isAuditLogEnabled === "true" && (
        <AuditTrail
          title="Audit Trail"
          transactionType="purchase_order"
          transactionId={Number(purchaseOrderData.id)}
        />
      )}
    </div>
  );
};
export default PurchaseOrderDetailsBody;
