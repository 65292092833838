import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import VoidModal from "../../../common/components/VoidModal";
import useCommonData from "../../../hooks/useCommon";
import { changePlanStatus } from "../planSlice";

type Props = {
  planId: number;
  planName: string;
  refreshPlan: () => void;
  organizationId: number;
};
const PlanVoid = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const voidRoleResponseAction = await dispatch(
      changePlanStatus({
        id: props.planId,
        status: "CANCELLED",
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (voidRoleResponseAction.payload) {
      const voidRoleResponse = voidRoleResponseAction.payload;
      if (Object.keys(voidRoleResponse).length && !("error" in voidRoleResponse)) {
        props.refreshPlan();
        $(".close-btn").click();
        toast.success("Plan cancelled successfully!", {
          toastId: "role-void-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in voidRoleResponse) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <VoidModal
      heading="Are you sure you want to cancel the Plan"
      subHeading={<span>Cancelled <strong>{props.planName}</strong> cannot be used for the creation of any new subscriptions.</span>}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default PlanVoid;