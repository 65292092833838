import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
type Props = {
  values: string;
};

const EmailPopover: React.FC<Props> = (props: Props) => {
  const [emailArray, setEmailArray] = useState(props.values.split(", "));
  return (
    <>
      {        
        emailArray.map((item) => item)[0]
      }
      <Popup
        trigger={          
          <button
            className="more-link-btn no-appearance"
          >
            {emailArray.length - 1 > 0
              ? " + " + (emailArray.length - 1) + " more"
              : ""}
          </button>
        }
        position="bottom center"
        className="suspended-popover"
      >
        <div className="popover-body popover-email">
          {emailArray.map((item, index) => {
            if (index !== 0) return <p key={index}>{item}</p>;
          })}
        </div>
      </Popup>
    </>
  );
};

export default EmailPopover;
