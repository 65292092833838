import { fetchTagOptionForInvoices } from "../../invoice/invoiceSlice";
import {
  setLoaderState,
  useCommonData,
} from "../components/paymentReceivedForm/imports";

export const useFetchTagOptionsForInvoices = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const getTagOptionsForInvoices = async (
    invoiceIds: number[],
    setTagOptionsForInvoices: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      fetchTagOptionForInvoices({
        orgId: currentUserInfo.organization_id,
        invoiceIds: invoiceIds,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      dispatch(setLoaderState(false));
      setTagOptionsForInvoices(response);
    }
    else{
        dispatch(setLoaderState(false));
    }
  };
  return { getTagOptionsForInvoices };
};
