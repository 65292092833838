import "../../../../index.css";
import './Button.css';
type ButtonProps = {
  type: "submit"|"reset"|"button";
  isDisabled: boolean;
  buttonContent: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const SecondaryButton = (props: ButtonProps) => {
  return (
    <button
      type={props.type}
      className="cancel-button"
      onClick={props.onClick}
      disabled={props.isDisabled}
      style={props.isDisabled===true ? { pointerEvents: "none", opacity: "0.5" } : {}}
    >
      {props.buttonContent}
    </button>
  );
};
