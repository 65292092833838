import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { consumerDetailsValues } from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import NotificationModal from "../../../../common/components/NotificationModal";
import { vendorsList } from "../../vendorSlice";
import DeleteVendor from "../Delete";
import OrgTag from "../../../../common/components/OrgTag";
import Edit from "../../../../common/components/kebabMenu/Edit";
type Props = {
  consumerDetails: consumerDetailsValues;
};
const VendorDetailHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { editId } = useParams();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const consumerData = props.consumerDetails;
  const [deleteVendorId, setDeleteVendorId] = useState(-1);
  const [deleteVendorName, setDeleteVendorName] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let vendorRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Vendor")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Back button handler from vendor details page
   */
  const backFromVendorViewHandler = () => {
    // if (editId) {
    //   navigate("/vendors");
    // } else {
    //   navigate("/vendors/create");
    // }
    navigate(-1);
  };
  const fetchVendorListAfterDelete = async () => {
    await dispatch(
      vendorsList({
        page: page,
        itemsPerPage: itemsPerPage,
        vendorName: "",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
      })
    );
    // navigate("/vendors");
    navigate(-1);
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromVendorViewHandler}
        data={{
          num: consumerData?.display_name,
          status: "",
        }}
        dataId={editId}
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          {consumerData ? (
            <>
              <div className="left-group">
                {consumerData.email && (
                  <span className="biller-label ps-2">
                    {consumerData.email}
                  </span>
                )}
                {consumerData.email && consumerData.phone && <span>|</span>}
                {consumerData.phone && (
                  <span className="bill-date pe-2 ps-2">
                    {consumerData.phone}
                  </span>
                )}
                {(consumerData.email || consumerData.phone) && <span>|</span>}
                <span>
                  <OrgTag organization={consumerData.organization_name} />
                </span>
              </div>

              <div className="actions-wrap d-flex flex-no-shrink">
                {vendorRolePermission.includes("Update") ? (
                  <Edit
                    url={"/vendors/edit/" + consumerData.id}
                    isMutable={true}
                    name="Vendor"
                    isLocked={false}
                    className=""
                    lockDate=""
                  />
                ) : null}
                {vendorRolePermission.includes("Delete") ? (
                  <Delete
                    className="text-decoration-none delete-item"
                    deleteIcon={true}
                    isMutable={consumerData.is_mutable}
                    onClick={() => {
                      if (consumerData?.is_mutable) {
                        setDeleteVendorId(Number(consumerData.id));
                        setDeleteVendorName(consumerData.display_name);
                      }
                    }}
                    isLocked={false}
                    lockDate=""
                    name="Vendor"
                    isTransactionExist={props.consumerDetails.transaction_exist}
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <DeleteVendor
        deleteVendorId={deleteVendorId}
        deleteVendorName={deleteVendorName}
        page={page}
        itemsPerPage={itemsPerPage}
        vendorName=""
        refreshList={fetchVendorListAfterDelete}
        organizationId={currentUserInfo.organization_id}
      />
      <NotificationModal
        modalHeader="Unable to delete vendor!"
        modalBody="The vendor cannot be deleted as there are dependent transactions."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};

export default VendorDetailHeader;
