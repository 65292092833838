import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";

import ListActions from "./ListActions";
import useCommonData from "../../../../../hooks/useCommon";
import SubscriptionTable from "./SubscriptionTable";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { subscriptionList } from "../../../subscriptionSlice";
import useListData from "../../../../../hooks/useListData";
import { StatusColor } from "../../../../../../helpers/helper";
import {
  dateListFilterSelector,
  dueDateListFilterSelector,
  fiscalYearFilterSelector,
  setLoaderState,
} from "../../../../../common/commonSlice";
import { coustomCaseWithRemoveUnderscore } from "../../../../../../helpers/planHelper";
import { useAppSelector } from "../../../../../../app/hooks";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import { Transactionlimit } from "../../../../../../types";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import { subscriptionColumnValues } from "../../../../../../helpers/tableCustomizationHelper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";

type Props = {
  deleteListData: (
    actionType: string,
    id: number,
    planName: string,
    invoice_exists: boolean
  ) => void;
  voidListData: (
    id: number,
    planName: string,
    status: string,
    next_renewal: string,
    frequency: string,
    currencyCode: string,
    stopScheduledDate: string,
    is_edit_scheduled: boolean,
    subscription_end_date: string | null,
    isCurrentCycleInvoicePaid: boolean,
    unused_credits_days: number,
    is_unbilled_charges_exists: boolean,
    totalUnbilledCharge: string
  ) => void;
  stopResumeListData: (
    id: number,
    planName: string,
    triggerAction: boolean,
    chargeOption: string,
    is_edit_scheduled: boolean,
    next_renewal: string,
    cancelScheduledDate: string,
    status: string,
    subscriptionEndDate: string | null
  ) => void;
  isFilterApplied: boolean;
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
};

function SubscriptionTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { subscriptionRolePermission } = usePermissionHandler();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setTotalList,
    setDateSortOrder,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const [subscriptionsListData, setSubscriptionsListData] = useState<any[]>([]);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);

  useImperativeHandle(
    ref,
    () => ({
      fetchRecurringInvoiceList: fetchRecurringInvoiceList,
    }),
    []
  );

  useImperativeHandle(
    ref,
    () => ({
      subscriptionsListData: subscriptionsListData,
    }),
    [subscriptionsListData]
  );

  useEffect(() => {
    fetchRecurringInvoiceList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchRecurringInvoiceList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    subscriptionColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
  }, [subscriptionsListData, selectedColumns]);

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });

  const getInvoiceColumnValues = () => {
    let newInvoiceColumnValues = JSON.parse(
      JSON.stringify(subscriptionColumnValues)
    );
    return newInvoiceColumnValues;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Subscription Creation Date",
        accessor: "show.subscription_created_at",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "subscription_created_at",
      },
      {
        Header: "Subscription Number",
        accessor: "show.subscription_number",
        sort: false,
        sortOrder: "",
        className: "subscription_number",
      },
      {
        Header: "Customer Name",
        accessor: "show.customer_name",
        sort: false,
        sortOrder: "",
        className: "customer_name",
      },
      {
        Header: "Status",
        accessor: "show.subscription_status",
        sort: false,
        sortOrder: "",
        className: "subscription_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "SUBSCRIPTION PERIOD",
        accessor: "show.frequency",
        sort: false,
        sortOrder: "",
        className: "frequency",
      },
      {
        Header: "Start Date",
        accessor: "show.start_date",
        sort: false,
        sortOrder: "",
        className: "start_date",
      },
      {
        Header: "Next Renewal",
        accessor: "show.next_renewal",
        sort: false,
        sortOrder: "",
        className: "next_renewal",
      },
      {
        Header: "MRR",
        accessor: "show.mrr",
        sort: false,
        sortOrder: "",
        className: "mrr",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "organization",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "actions",
        Cell: ({ cell: { value } }: any) => {
          return !subscriptionRolePermission.includes("Update") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickDelete={() => {
                props.deleteListData(
                  "Subscription",
                  value.id,
                  value.name,
                  value.invoice_exists
                );
              }}
              onClickVoid={() => {
                props.voidListData(
                  value.id,
                  value.name,
                  value.status,
                  value.next_renewal,
                  value.frequency,
                  value.currencyCode,
                  value.stopScheduledDate,
                  value.is_edit_scheduled,
                  value.subscription_end_date,
                  value.isCurrentCycleInvoicePaid,
                  value.unused_credits_days,
                  value.is_unbilled_charges_exists,
                  value.totalUnbilledCharge
                );
              }}
              onStopResume={() => {
                props.stopResumeListData(
                  value.id,
                  value.name,
                  value.status.toLowerCase() === "stopped",
                  value.invoicing_option,
                  value.is_edit_scheduled,
                  value.next_renewal,
                  value.cancelScheduledDate,
                  value.status,
                  value.subscription_end_date
                );
              }}
            />
          );
        },
      },
    ],
    [dateSortOrder, currentUserInfo.organization_id, subscriptionRolePermission]
  );

  const dateFormat = (timestamp: any) => {
    if (!timestamp) return;
    var parts = timestamp.split("-");
    return parts[0] + "/" + parts[1] + "/" + parts[2];
  };

  const frequencyFilters = [
    { id: 1, name: "Daily" },
    { id: 2, name: "Weekly" },
    { id: 3, name: "Monthly" },
    { id: 4, name: "Yearly" },
  ];

  const getFrequencyFilterString = (frequencyIds: number[]) => {
    const numberSet = new Set(frequencyIds);
    const names: string[] = [];

    for (const object of frequencyFilters) {
      if (numberSet.has(object.id)) {
        names.push(object.name);
      }
    }
    return names;
  };

  const fetchRecurringInvoiceList = useCallback(async () => {
    dispatch(setLoaderState(true));

    const responseAction = await dispatch(
      subscriptionList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status).map(
              (eachStatus: string) => eachStatus.toUpperCase().replace(" ", "_")
            )
          : [],

        CustomerList: selectedFilterData.Customers
          ? selectedFilterData.Customers
          : [],

        frequency: selectedFilterData.Frequency
          ? getFrequencyFilterString(selectedFilterData.Frequency).map(
              (eachFrequency: string) => eachFrequency.toUpperCase()
            )
          : [],
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        setSubscriptionsListData(response.subscriptions);
        let length = response.subscriptions.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    subscriptionRolePermission,
    selectedFilterData,
  ]);

  function toTitleCase(str: string) {
    return str.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  }

  const data = subscriptionsListData
    ? subscriptionsListData.map((subscription) => ({
        show: {
          subscription_created_at: dateFormat(
            subscription.subscription_created_at
          ),
          subscription_number: subscription.subscription_number.replace(
            "~",
            "-"
          ),
          customer_name: subscription.customer_name,
          subscription_status: coustomCaseWithRemoveUnderscore(
            subscription.subscription_status
          ),
          frequency: toTitleCase(subscription.frequency),
          start_date: dateFormat(subscription.start_date),
          next_renewal: dateFormat(subscription.next_renewal),
          mrr:
            subscription.currency_code +
            " " +
            NumberFormat(
              subscription.monthly_recurring_revenue,
              subscription.currency_code,
              orgCurrencyList
            ),
          organization: subscription.organization,
          actions: {
            id: subscription.id,
            status: subscription.subscription_status,
            name: subscription.subscription_number,
            organizationId: subscription.organization_id,
            next_renewal: subscription.next_renewal,
            invoice_exists: subscription.invoice_exists,
            frequency: subscription.frequency,
            unpaidAmount: `${subscription.currency_code || ""} ${NumberFormat(
              subscription.total_unpaid_amount || 0,
              subscription.currency_code || "",
              orgCurrencyList
            )}`,
            stopScheduledDate: subscription.scheduled_stop_date,
            is_edit_scheduled: subscription.is_edit_scheduled,
            cancelScheduledDate: subscription.scheduled_cancel_date,
            subscription_end_date: subscription.subscription_end_date,
            isCurrentCycleInvoicePaid:
              subscription.is_current_cycle_invoice_paid,
            unused_credits_days: subscription.unused_credits_days,
            is_unbilled_charges_exists: subscription.is_unbilled_charges_exists,
            totalUnbilledCharge:  `${subscription.currency_code || ""} ${NumberFormat(
              subscription.total_unbilled_charges || 0,
              subscription.currency_code || "",
              orgCurrencyList
            )}`,
            currencyCode:  subscription.currency_code
          },
        },
      }))
    : [];

  const sortHandler = (column: string) => {
    if (column === "Subscription Creation Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };

  return (
    <>
      <SubscriptionTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        subscriptionRolePermission={subscriptionRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        sortHandler={sortHandler}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
      />
    </>
  );
}

export default React.memo(forwardRef(SubscriptionTableContainer));
