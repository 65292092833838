import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CustomSubModuleValues } from "../../../../../../../types";
type Props = {
  changeInEditRole: boolean;
  submodulePermissions: CustomSubModuleValues[];
};
const RoleFormFooter = (props: Props) => {
  const location = useLocation();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  /**
   * Check whether at least one permission exist in permission matrix
   */
  useEffect(() => {
    let permissionItems = JSON.parse(
      JSON.stringify(props.submodulePermissions)
    );
    for (let items of permissionItems) {
      let itemInputs = items;
      for (let subItems of itemInputs.permissions) {
        if (subItems.is_checked === true) {
          setIsPermissionExist(true);
          return;
        }
      }
    }
    setIsPermissionExist(false);
  }, [props.submodulePermissions]);

  const [isPermissionExist, setIsPermissionExist] = useState(false);

  return (
    <section className="button-section w-100">
      <div className="row g-0">
        <div className="col" id="role-create-form-btn">
          <button
            type="submit"
            className="save-btn form-btn"
            disabled={
              (!props.changeInEditRole && path.includes("edit")) ||
              !isPermissionExist
                ? true
                : false
            }
            style={
              (!props.changeInEditRole && path.includes("edit")) ||
              !isPermissionExist
                ? { opacity: 0.5 }
                : {}
            }
          >
            Save
          </button>
          <Link
            to="/roles"
            className="role-cancel text-decoration-none cancel-btn form-btn"
          >
            Cancel
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RoleFormFooter;
