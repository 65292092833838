import { useEffect } from "react";

export const getFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix = "",
    result = [] as any
  ) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "end" },
  submitCount,
  errors,
}: any) => {
  useEffect(() => {
    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    let element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
    if (!element) {
      element = document.getElementsByClassName(
        `error_${fieldErrorNames[0]}`
      )[0];
      if (!element) return;
    }
    // Scroll to first known error into view
    // element.scrollIntoView(scrollBehavior as any);
    const elementTop = element.getBoundingClientRect().top;
    // const y = element.getBoundingClientRect().top + window.pageYOffset - 150;
    const scrollOffset =
      typeof window.pageYOffset !== "undefined"
        ? window.pageYOffset
        : window.scrollY;
    const y = scrollOffset + elementTop - 200;
    window.scrollTo(0, y);
  }, [submitCount]);

  return null;
};
