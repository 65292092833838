import * as yup from "yup";

export const ReportingTagFormValidation = yup.object({
  name: yup
    .string()
    .required("Please enter a reporting tag name.")
    .test({
      name: "empty check",
      message: "Please enter a valid reporting tag name.",
      test: function (value) {
        if (value && value?.trim() === "") {
          return false;
        }
        return true;
      },
    }),
  options: yup
    .array()
    .min(1, "Please enter at least one option.")
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("Please enter option name.")
          .test({
            name: "empty check",
            message: "Please enter a valid option name.",
            test: function (value) {
              if (value && value?.trim() === "") {
                return false;
              }
              return true;
            },
          })
          .test({
            name: "unique-name",
            message: "Option name must be unique",
            test: function (value) {
              const { options } = this.options.context as any;
              let count = 0;
              if (Array.isArray(options)) {
                const nameArray = options.filter(
                  (obj: any) => obj.name === value
                );

                return nameArray.length > 1 ? false : true;
              }

              return true;
            },
          }),
      })
    ),
});
