import { SvgIconProp } from "../../../../types";

export const InfoIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width || "16px"}
      height={props.height || "16px"}
      viewBox="0 0 16 16"
      style={{ margin: props.margin }}
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h16v16H0z" />
        <path
          d="M8 14.667A6.667 6.667 0 1 1 8 1.334a6.667 6.667 0 0 1 0 13.333zm0-1.334A5.333 5.333 0 1 0 8 2.667a5.333 5.333 0 0 0 0 10.666zm-.667-8.666h1.334V6H7.333V4.667zm0 2.666h1.334v4H7.333v-4z"
          fill={props.color || "#0d5ae5"}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
