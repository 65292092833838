import React from "react";
import "./RefundHeading.css";

type Props = {
  consumer_name: string;
  currency_code: string;
  amount_excess: number;
};

const RefundHeading = ({
  consumer_name,
  currency_code,
  amount_excess,
}: Props) => {
  return (
    <>
      <label className="refund-customer-name mb-2">
        Refund: {consumer_name}
      </label>
      <label className="refund-balance-amount mb-4">
        Balance: {currency_code} {amount_excess}
      </label>
    </>
  );
};
export default RefundHeading;