import { toast } from "react-toastify";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";

const useToast = () => {
  const notificationCenter = useNotificationCenter();

  const clearToast = (type?: string) => {
    if (type) {
      notificationCenter.notifications.map((notification) => {
        if (notification.type === type && notification.id) {
          toast.dismiss(notification.id);
        }
      });
    } else {
      toast.dismiss();
    }
  };

  const isToastExistById = (id: string) => {
    notificationCenter.notifications.map((notification) => {
      if (notification.id === id) {
        return true;
      }
    });
    return false;
  };

  return { clearToast, isToastExistById };
};

export default useToast;
