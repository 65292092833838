import React from "react";
import "./AccountNameDisplay.css";
type Props = {
  accountName: string;
  ledgerRoot: string;
};
const AccountNameDisplay = (props: Props) => {
  return (
    <>
      {props.accountName && (
        <>
          <div className={"acnt-name"}>{props.accountName}</div>
          {props.ledgerRoot && (
            <div className={"ledger-root d-block clear-both"}>
              {props.ledgerRoot}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AccountNameDisplay;
