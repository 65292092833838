import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useEffect, useState } from "react";
import "./TdsForm.css";
import {
  ObjectType,
  SectionListValues,
  SubAccountValues,
  TDSFormValues,
} from "../../../../../types";
import { initialTdsFormValues } from "./StateInitialization";
import useCommonData from "../../../../hooks/useCommon";
import { createTds, sectionDropDown, tdsSelector } from "../../tdsSlice";
import { subAccountsOfParent } from "../../../chartOfAccounts/coaSlice";
import { validateTdsForm } from "./ValidateTdsForm";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import _ from "lodash";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Checkbox from "@mui/material/Checkbox";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import useListData from "../../../../hooks/useListData";
import CustomSearchSelect from "../../../../common/components/CustomSearchSelect/CustomSearchSelect";
type Props = {
  tdsId: number;
  handleClose: () => void;
  refreshList: () => void;
};
function TdsForm({ tdsId, handleClose, refreshList }: Props) {
  const { currentUserInfo, dispatch } = useCommonData();
  const tdsList = useAppSelector(tdsSelector);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [tdsData, setTdsData] = useState<TDSFormValues>(initialTdsFormValues);
  const [initialUpdateInputs, setInitialUpdateInputs] =
    useState<TDSFormValues>(initialTdsFormValues);
  const [paymentMode, setPaymentMode] = useState("rate");
  const [sectionList, setSectionList] = useState<SectionListValues[]>([]);
  const [currentLiabilityAccount, setCurrentLiabilityAccount] = useState<
    SubAccountValues[]
  >([]);
  const [currentAssetAccount, setCurrentAssetAccount] = useState<
    SubAccountValues[]
  >([]);
  const [isConditionChecked, setIsConditionChecked] = useState(false);
  const [isTdsChanged, setIsTdsChanged] = useState(false);

  const handleCloseModal = () => {
    handleClose();
    setFormErrors({});
    setTdsData(initialTdsFormValues);
    $(".close-btn").click();
  };

  useEffect(() => {
    getSectionDropDownList();
    getCurrentLiabilitySubAccount();
    getCurrentAssetSubAccount();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    const purchaseId = currentLiabilityAccount
      .filter((account) => account.account_name === "TDS-purchase")
      .map((account) => account.id)[0];
    const saleId = currentAssetAccount
      .filter((account) => account.account_name === "TDS-sale")
      .map((account) => account.id)[0];
    if (!tdsId && purchaseId && saleId) {
      setTdsData((values) => ({
        ...values,
        tds_payable_account: purchaseId,
        tds_receivable_account: saleId,
      }));
    }
  }, [currentLiabilityAccount, currentAssetAccount]);

  useEffect(() => {
    if (tdsId) {
      tdsList.tds_tax
        .filter((tds) => tds.id === tdsId)
        .map((tds) => {
          setTdsData({
            tax_name: tds.tax_name,
            rate: tds.rate ? tds.rate.toString() : tds.rate,
            section_id: tds.section_id,
            tds_payable_account: tds.tds_payable_account,
            tds_receivable_account: tds.tds_receivable_account,
            is_percentage: tds.rate ? true : false,
            previous_tds_id: tds.id,
          });
          setInitialUpdateInputs({
            tax_name: tds.tax_name,
            rate: tds.rate ? tds.rate.toString() : tds.rate,
            section_id: tds.section_id,
            tds_payable_account: tds.tds_payable_account,
            tds_receivable_account: tds.tds_receivable_account,
            is_percentage: tds.rate ? true : false,
            previous_tds_id: tds.id,
          });
          if (!tds.rate) {
            setPaymentMode("amount");
          }
        });
    }
  }, [tdsId]);

  const inputChangeHandler = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "rate") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || numberRegex.test(value)) {
          setTdsData((values) => ({ ...values, [name]: value }));
        }
      }
    } else {
      setTdsData((values) => ({ ...values, [name]: value }));
    }
  };

  const handleRadioClick = (value: string) => {
    setPaymentMode(value);

    setTdsData((values) => ({
      ...values,
      rate: 0,
      is_percentage: value === "rate",
    }));
    if (value === "amount") {
      delete formErrors.rate;
    }
  };
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    const errors = validateTdsForm(
      tdsData,
      paymentMode,
      isConditionChecked,
      tdsId,
      isTdsChanged
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#tds-create-button").addClass("create-user-form-section-disable");
      createNewTds();
    }
  };

  const createNewTds = async () => {
    if (tdsId) {
      tdsData.previous_tds_id = tdsId;
    }
    const responseAction = await dispatch(
      createTds({ values: tdsData, orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        toast.success("TDS created successfully!", {
          toastId: "Tds-create-success",
          closeButton: false,
          position: "top-center",
        });
        handleCloseModal();
        refreshList();
      }
    }
    $("#tds-create-button").removeClass("create-user-form-section-disable");
  };

  const getSectionDropDownList = async () => {
    const responseAction = await dispatch(
      sectionDropDown(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setSectionList(response);
      }
    }
  };

  const sectionListOptions = sectionList.map((section) => ({
    label: section.section_name,
    value: section.section_id,
  }));

  const sectionName = sectionList
    .filter((section) => section.section_id === tdsData.section_id)
    .map((section) => ({
      label: section.section_name,
      value: section.section_id,
    }));

  const handleSectionChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setTdsData((values) => ({ ...values, section_id: Number(e.value) }));
  };

  const getCurrentLiabilitySubAccount = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Current Liabilities", "Duties & Taxes"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCurrentLiabilityAccount(response);
      }
    }
  };

  const currentLiabilityAccountOptions = currentLiabilityAccount.map(
    (subAccount) => ({
      label: subAccount.account_name,
      value: subAccount.id,
    })
  );

  const currentLiabilityAccountName = currentLiabilityAccount
    .filter((subAccount) => subAccount.id === tdsData.tds_payable_account)
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  const handlePayableAccountChange = (val: string) => {
    setTdsData((values) => ({
      ...values,
      tds_payable_account: Number(val),
    }));
  };

  const getCurrentAssetSubAccount = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Current Assets", "Loans & Advances"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCurrentAssetAccount(response);
      }
    }
  };

  const currentAssetsAccountOptions = currentAssetAccount.map((subAccount) => ({
    label: subAccount.account_name,
    value: subAccount.id,
  }));

  const currentAssetsAccountName = currentAssetAccount
    .filter((subAccount) => subAccount.id === tdsData.tds_receivable_account)
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  const handleReceivableAccountChange = (val: string) => {
    setTdsData((values) => ({
      ...values,
      tds_receivable_account: Number(val),
    }));
  };

  useEffect(() => {
    isTdsValuesChanged();
  }, [tdsData, initialUpdateInputs]);

  const isTdsValuesChanged = () => {
    const tdsValues = { ...tdsData };
    delete tdsValues.section_id;
    const initialTdsValues = { ...initialUpdateInputs };
    delete initialTdsValues.section_id;
    if (_.isEqual(tdsValues, initialTdsValues)) {
      setIsTdsChanged(false);
    } else {
      setIsTdsChanged(true);
    }
  };

  const handleAmountOnBlur = (name: string, amount: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      if (name === "rate" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        setTdsData((values) => ({
          ...values,
          [name]: roundedAmount,
        }));
      }
    }
  };

  return (
    <div className="tds-form-modal-wrapper">
      <form id="tds-form" className="tds-form tds-form-body-wrapper">
        <div className="tds-form-input-wrapper">
          <label htmlFor="tax_name" className="required">
            Tax Name
          </label>
          <input
            id="tax_name"
            name="tax_name"
            type="text"
            onChange={inputChangeHandler}
            value={tdsData.tax_name}
            className={`form-control ${formErrors.tax_name ? "error" : ""}`}
            placeholder="Enter tax name"
          />
          <span className="error">{formErrors.tax_name}</span>
        </div>
        <div className="tds-form-input-wrapper">
          <div className="tds-radio-wrapper d-flex">
            <RadioButtonGroup
              name="rateOrAmount"
              id="rateOrAmount"
              valuesArray={[
                { label: "Rate", value: "rate" },
                { label: "Amount", value: "amount" },
              ]}
              value={paymentMode}
              icon={
                <span>
                  <RadioButtonUncheckedIcon />
                </span>
              }
              checkedIcon={
                <span>
                  <RadioButtonCheckedIcon className="radio-btn-tds" />
                </span>
              }
              isDisabled={false}
              onClick={handleRadioClick}
            />
          </div>
        </div>
        <div
          className="tds-form-input-wrapper"
          style={paymentMode === "amount" ? { opacity: "0.5" } : {}}
        >
          <label htmlFor="rate" className="required">
            Rate(%)
          </label>
          <input
            id="rate"
            name="rate"
            type="text"
            onChange={inputChangeHandler}
            value={tdsData.rate ? tdsData.rate : ""}
            className={`form-control ${formErrors.rate ? "error" : ""}`}
            placeholder="Enter rate"
            disabled={paymentMode === "amount"}
            onBlur={(e) => handleAmountOnBlur("rate", Number(tdsData.rate))}
          />
          <span className="error">{formErrors.rate}</span>
        </div>
        <div className="tds-form-input-wrapper">
          <label htmlFor="section" className="required">
            Section
          </label>
          <Select
            name="section"
            value={sectionName}
            options={sectionListOptions}
            placeholder="Choose section"
            onChange={(e) => {
              if (e) handleSectionChange({ label: e?.label, value: e?.value });
            }}
            className={`form-select custom-select customer-select m-0 ${
              formErrors.section_id ? "error" : ""
            }`}
            styles={customSelectStyle}
          />
          <span className="error">{formErrors.section_id}</span>
        </div>
        <div className="tds-form-input-wrapper">
          <label htmlFor="payable_account">TDS Payable Account</label>
          <CustomSearchSelect
            itemAccount={
              currentLiabilityAccountName.length > 0
                ? Number(currentLiabilityAccountName[0].value)
                : 0
            }
            itemAccountName={
              currentLiabilityAccountName.length > 0
                ? currentLiabilityAccountName[0].label
                : ""
            }
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(val) => {
              if (val) handlePayableAccountChange(val);
            }}
            itemIndex={0}
            error={""}
            baseAccount="Current Liabilities~Duties %26 Taxes"
            className="full-width"
            wrapperClass="w-100 clear-both"
          />
        </div>
        <div className="tds-form-input-wrapper">
          <label htmlFor="receivable_account">TDS Receivable Account</label>
          <CustomSearchSelect
            itemAccount={
              currentAssetsAccountName.length > 0
                ? Number(currentAssetsAccountName[0].value)
                : 0
            }
            itemAccountName={
              currentAssetsAccountName.length > 0
                ? currentAssetsAccountName[0].label
                : ""
            }
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(val) => {
              if (val) handleReceivableAccountChange(val);
            }}
            itemIndex={0}
            error={""}
            baseAccount="Current Assets~Loans %26 Advances"
            className="full-width"
            wrapperClass="w-100 clear-both"
          />
        </div>
        {tdsId && isTdsChanged ? (
          <>
            <p className="tds-terms-and-condition">Terms and Conditions</p>
            <div className="tds-form-input-wrapper tds-checkbox-wrapper">
              <Checkbox
                name="c_i_f_access"
                id="c_i_f_access"
                className="form-check-input tds-terms-checkbox"
                onClick={() => {
                  setIsConditionChecked(!isConditionChecked);
                }}
                checked={isConditionChecked === true ? true : false}
              />
              <span className="terms-and-condition-label">
                I understand that updating the TDS details will mark the
                existing TDS Tax inactive and create a new TDS Tax.
              </span>
            </div>
            <span className="error">{formErrors.terms_and_condition}</span>
          </>
        ) : null}

        <div className="tds-form-button-wrapper w-100" id="tds-create-button">
          <button
            className="save-button"
            type="button"
            disabled={isDisabled ? true : false}
            style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
            onClick={(e) => formSubmitHandler(e)}
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={handleCloseModal}
            disabled={isDisabled ? true : false}
            style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
export default TdsForm;
