const labels = {
  systemVoucherName: "System Voucher Name",
  systemVoucherPlaceholder: "Enter System Voucher Name",
  systemVoucherPrefixName: "Prefix",
  systemVoucherPrefixPlaceholder: "Enter Prefix",
  accountList: "Select Account",
  successToastMessage: "System voucher type configuration created successfully!",
  succesfullyUpdateToastMessage: "System voucher type configuration updated successfully!",
};
export default labels;
