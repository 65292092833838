import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
type Props = {
  values: any[];
};
const OrganizationRolePopup = (props: Props) => {
  return (
    <>
      {
        props.values.filter((item, index) => index === 0).map((item) => item)[0]
          .organization_role
      }
      <Popup
        trigger={
          <Link
            className="more-link"
            tabIndex={0}
            to="#"
            onClick={(e) => e.preventDefault()}
          >
            {props.values.length - 1 > 0
              ? " + " + (props.values.length - 1) + " more"
              : ""}
          </Link>
        }
        position="bottom center"
        className="suspended-popover"
      >
        <div className="popover-body popover-org">
          {props.values.map((item, index) => {
            if (index !== 0) return <p>{item.organization_role}</p>;
          })}
        </div>
      </Popup>
    </>
  );
};

export default OrganizationRolePopup;
