import { SvgIconProp } from "../../../../types";

export const SettingsIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : ""}
      style={{ margin: props.margin ? props.margin : "" }}
      viewBox="0 0 20 20"
    >
      <path
        d="M.215 12.06a9.943 9.943 0 0 1 .001-4.12c1.11.131 2.078-.236 2.395-1 .317-.765-.108-1.71-.985-2.402a9.942 9.942 0 0 1 2.911-2.913c.693.877 1.638 1.303 2.402.986.765-.317 1.133-1.286 1.002-2.396a9.942 9.942 0 0 1 4.118.001c-.13 1.11.237 2.078 1.002 2.395.764.317 1.709-.108 2.401-.985a9.943 9.943 0 0 1 2.913 2.911c-.877.693-1.303 1.638-.986 2.402.317.765 1.286 1.133 2.396 1.002a9.941 9.941 0 0 1-.001 4.118c-1.11-.13-2.078.237-2.395 1.002-.317.764.108 1.709.985 2.401a9.944 9.944 0 0 1-2.911 2.913c-.693-.877-1.638-1.303-2.402-.986-.765.317-1.133 1.286-1.002 2.396a9.941 9.941 0 0 1-4.118-.001c.13-1.11-.237-2.078-1.002-2.395-.764-.317-1.709.108-2.401.985a9.941 9.941 0 0 1-2.913-2.911c.877-.693 1.303-1.638.986-2.402-.317-.765-1.286-1.133-2.396-1.002zm1.787-1.85c1.1.304 2.007 1.001 2.456 2.086.45 1.084.3 2.218-.262 3.21.097.102.196.202.297.298.993-.562 2.127-.711 3.211-.262 1.085.449 1.781 1.356 2.086 2.456.14.003.28.004.42 0 .304-1.1 1.001-2.007 2.086-2.456 1.084-.45 2.218-.3 3.21.262.102-.097.201-.196.298-.298-.562-.992-.711-2.126-.262-3.21.449-1.085 1.356-1.781 2.456-2.086.003-.14.004-.28 0-.42-1.1-.304-2.007-1.001-2.456-2.086-.45-1.084-.3-2.218.262-3.21a7.85 7.85 0 0 0-.298-.298c-.992.562-2.126.711-3.21.262-1.085-.449-1.781-1.356-2.086-2.456a7.936 7.936 0 0 0-.42 0c-.305 1.1-1.001 2.007-2.086 2.456-1.084.45-2.218.3-3.21-.262-.102.097-.201.196-.298.297.562.993.711 2.127.262 3.211C4.01 8.79 3.102 9.485 2.002 9.79c-.003.14-.003.28 0 .42zM10 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        fill={props.color}
        fillRule={"nonzero"}
      />
    </svg>
  );
};
