import React, { useEffect } from "react";
import Select, { SingleValue } from "react-select";
import useCommonData from "../../hooks/useCommon";
import { useAppSelector } from "../../../app/hooks";
import {
  countryApiStatusSelector,
  countrySelector,
  getCountries,
  setCountryApiStatus,
} from "../commonSlice";
import { customSelectStyle } from "./SelectCustomStyle";

type Props = {
  id: string;
  countryId: number | null;
  onChange: (e: SingleValue<string | { label: string; value: number }>) => void;
  isDisabled: boolean;
  error?: string;
  wrapperClass?: string;
  selectClass?: string;
};
function CountrySelect({
  id,
  countryId,
  onChange,
  isDisabled,
  error,
  wrapperClass,
  selectClass,
}: Props) {
  const { dispatch } = useCommonData();
  const countries = useAppSelector(countrySelector);
  const apiStatus = useAppSelector(countryApiStatusSelector);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [countries]);

  /**
   * Fetch all country list
   */
  const getAllCountries = async () => {
    await dispatch(getCountries());
  };
  if (!countries.length && apiStatus === "idle") {
    dispatch(setCountryApiStatus("loading"));
    getAllCountries();
  }

  return (
    <div className={wrapperClass}>
      <Select
        name="country_id"
        id={id}
        options={
          countries?.length > 0
            ? countries.map((country) => {
                return {
                  label: country.name,
                  value: country.id,
                };
              })
            : []
        }
        className={`state-select form-select custom-select ${
          error ? "error" : ""
        } ${selectClass}`}
        value={
          countryId == null || countryId == 0
            ? ""
            : {
                label: countries?.filter((country) => {
                  return country.id == countryId;
                })[0]?.name,
                value: countryId,
              }
        }
        onChange={onChange}
        styles={customSelectStyle}
        placeholder={"Choose country or region"}
        isSearchable={true}
        isDisabled={isDisabled}
      />
      <span className="error">{error}</span>
    </div>
  );
}

export default React.memo(CountrySelect);
