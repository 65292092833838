import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import CenterModal from "../../../../common/components/centerModal/CenterModal";
import ReopenFiscalYear from "../../reopenFiscalYear/ReopenFiscalYear";

export const ReopenFiscalYearsModal = (props: any) => {
  return (
    <>
      <CenterModal
        modalTitle="Reopen fiscal years"
        modalTitleClass="reopen-modal"
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;
              return (
                <ReopenFiscalYear
                  onCloseModal={onCloseModal}
                  accounting_start_date={props.accounting_start_date}
                  accountClosingDate={props.accountClosingDate}
                  fiscalYearEndDate={props.fiscalYearEndDate}
                />
              );
            }}
          </ModalContext.Consumer>
        }
        buttonContent={props.buttonContent}
        wrapperClass="add-customer-modal large-form-modal"
        buttonClass={`add-customer-link ${props.buttonClass}`}
      />
    </>
  );
};
