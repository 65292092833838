import { useNavigate } from "react-router-dom";
import { GoBackArrowWhiteIcon, PageNotFoundIcon } from "../../../assets/images";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="access-denied-wrapper">
      <img src={PageNotFoundIcon} width={108} height={88} alt="no-access" />
      <h2>404 - Page not found</h2>
      <span className="sub">
        The link you clicked may be broken or the page may have been removed or
        renamed.
      </span>
      <button
        type="button"
        className="btn back-btn green-button"
        onClick={() => navigate("/")}
      >
        <img
          src={GoBackArrowWhiteIcon}
          width={"24px"}
          height={"24px"}
          alt="Go Back"
        />
        Back to Dashboard
      </button>
    </div>
  );
}
export default PageNotFound;
