import { SvgIconProp } from "../../../../types";

export const WriteOffIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "14px"}
      height={props.height ? props.height : "16px"}
      viewBox="0 0 14 16"
    >
      <path
        d="M6.798 15.34v-1.535H1.51V1.534h7.553v3.068h3.02v3.835h1.511V3.835L9.82 0H.754A.758.758 0 0 0 0 .76V14.58c0 .411.336.76.75.76h6.048zM14 14.664l-1.602-1.627L14 11.411l-1.068-1.084-1.602 1.627-1.603-1.627L8.66 11.41l1.602 1.627-1.602 1.627 1.068 1.085 1.603-1.627 1.602 1.627L14 14.665z"
        fill={props.color}
        fill-rule="nonzero"
      />
    </svg>
  );
};
