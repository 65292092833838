import { FormTextAreaFieldProps } from "../../../../types";
import "./formTextAreaField.css"

export const FormTextAreaField = (props: FormTextAreaFieldProps) => {
  return (
    <div
      className={`col-12 col-lg-4 ml-0  ${props.wrapperClass} reusable-form-text-area-field`}
      id={props.wrapperClass}
    >
      <label
        className={`fw-bold  ${props.isRequired ? "required" : ""}`}
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <textarea
        name={props.name}
        id={props.id}
        className={`${props.className} ${props.error ? "error" : ""}`}
        style={{ resize: props.isResizable ? "vertical" : "none" }}
        value={props.value}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        minLength={props.minLength}
        disabled={props.disabled ? true : false}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onBlur}
        onClick={props.onClick}
        onInput={props.onInput}
      />
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};
