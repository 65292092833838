import React from "react";
import "../../../../index.css";
import "./InfoBox.css";
import { AccessInfoImg } from "../../../../assets/images";

type Props = {
  message: string | React.ReactNode;
  className?: string;
};
export default function InfoBox(props: Props) {
  const isStringMessage = typeof props.message === "string";
  return (
    <div className={`info-message-box ${props.className}`}>
      <img src={AccessInfoImg} alt="icon" />
      {isStringMessage ? (
        <span
          className="message"
          dangerouslySetInnerHTML={{ __html: props.message as string }}
        />
      ) : (
        <span className="message">{props.message}</span>
      )}
    </div>
  );
}
