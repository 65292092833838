export const invoiceColumnValues = [
  "Invoice Date",
  "Invoice Number",
  "Reference Number",
  "Customer Name",
  "Status",
  "Due Date",
  "Total",
  "Balance Due",
  "Billing Address",
  "Created By",
  "Due Days",
  "Email",
  "Shipping Address",
  "Total Taxable Amount",
  "Organization",
];

export const proformaInvoiceColumnValues = [
  "Issue Date",
  "PI Number",
  "Reference Number",
  "Customer Name",
  "Status",
  "Expiry Date",
  "Total",
  "Billing Address",
  "Created By",
  "Shipping Address",
  "Total Taxable Amount",
  "Tax Deducted(TDS)",
  "Organization",
];

export const riColumnValues = [
  "Customer name",
  "Profile name",
  "Frequency",
  "Last Invoice Date",
  "Next Invoice Date",
  "Status",
  "Amount",
  "Organization",
  "End Date",
  "Billing Address",
  "Email",
  "Shipping Address",
  "Created By",
  "Total Taxable Amount",
  // "Tax Deducted (TDS)",
];

export const subscriptionColumnValues = [
  "Subscription Creation Date",
  "Subscription Number",
  "Customer Name",
  "Status",
  "SUBSCRIPTION PERIOD",
  "Next Renewal",
  "MRR",
  "Organization",
];

export const invoiceDefaultValues = [
  "Invoice Date",
  "Invoice Number",
  "Customer Name",
  "Status",
  "Due Date",
  "Total",
  "Balance Due",
  "Tags",
  "Organization",
];
export const proformaInvoiceDefaultValues = [
  "Issue Date",
  "PI Number",
  "Customer Name",
  "Status",
  "Expiry Date",
  "Total",
  "Organization",
];

export const riDefaultValues = [
  "Customer name",
  "Profile name",
  "Frequency",
  "Last Invoice Date",
  "Next Invoice Date",
  "Status",
  "Amount",
  "Organization",
];

export const subscriptionDefaultValues = [
  "Subscription Creation Date",
  "Subscription Number",
  "Customer Name",
  "Status",
  "SUBSCRIPTION PERIOD",
  "Next Renewal",
  "MRR",
  "Organization",
];

export const invoiceMandatoryValues = [
  "Invoice Date",
  "Invoice Number",
  "Customer Name",
  "Status",
  "Total",
  "Balance Due",
  "Organization",
];
export const proformaInvoiceMandatoryValues = [
  "Issue Date",
  "PI Number",
  "Customer Name",
  "Status",
  "Total",
  "Organization",
];

export const riMandatoryValues = [
  "Customer name",
  "Profile name",
  "Frequency",
  "Last Invoice Date",
  "Next Invoice Date",
  "Status",
  "Amount",
  "Organization",
];

export const subscriptionMandatoryValues = [
  "Subscription Creation Date",
  "Subscription Number",
  "Customer Name",
  "Status",
  "SUBSCRIPTION PERIOD",
  "Next Renewal",
  "MRR",
  "Organization",
];

export const billColumnValues = [
  "Bill Date",
  "Bill Number",
  "Reference Number",
  "Vendor Name",
  "Status",
  "Due Date",
  "Total Taxable Amount",
  "Total",
  "Balance Due",
  "Organization",
];

export const billDefaultValues = [
  "Bill Date",
  "Bill Number",
  "Reference Number",
  "Vendor Name",
  "Status",
  "Due Date",
  "Total",
  "Balance Due",
  "Organization",
];

export const billMandatoryValues = [
  "Bill Date",
  "Bill Number",
  "Vendor Name",
  "Status",
  "Total",
  "Balance Due",
  "Organization",
];

export const purchaseOrderColumnValues = [
  "Issue Date",
  "PO Number",
  "Reference Number",
  "Vendor Name",
  "Status",
  "Delivery Date",
  "Total Taxable Amount",
  "Total",
  // "Tax Deducted (TDS)",  //Needs to be updated after table customization API is setup for PO
  "Organization",
];

export const purchaseOrderDefaultValues = [
  "Issue Date",
  "PO Number",
  "Reference Number",
  "Vendor Name",
  "Status",
  "Delivery Date",
  "Total",
  "Balance Due",
  "Organization",
];

export const purchaseOrderMandatoryValues = [
  "Issue Date",
  "PO Number",
  "Vendor Name",
  "Status",
  "Delivery Date",
  "Total",
  "Organization",
];

export const creditNoteColumnValues = [
  "Credit Note Date",
  "Credit Note Number",
  "Reference Number",
  "Customer Name",
  "Invoice Number",
  "Status",
  "Total Taxable Amount",
  "Credit Note Balance",
  "Total",
  "Organization",
];

export const creditNoteDefaultValues = [
  "Credit Note Date",
  "Credit Note Number",
  "Customer Name",
  "Invoice Number",
  "Status",
  "Credit Note Balance",
  "Total",
  "Organization",
];

export const creditNoteMandatoryValues = [
  "Credit Note Date",
  "Credit Note Number",
  "Customer Name",
  "Status",
  "Total",
  "Organization",
];

export const debitNoteColumnValues = [
  "Debit Note Date",
  "Debit Note Number",
  "Reference Number",
  "Vendor Name",
  "Status",
  "Total Taxable Amount",
  "Total",
  "Bill Number",
  "Debit Note Balance",
  "Organization",
];

export const debitNoteDefaultValues = [
  "Debit Note Date",
  "Debit Note Number",
  "Vendor Name",
  "Status",
  "Total",
  "Bill Number",
  "Debit Note Balance",
  "Organization",
];

export const debitNoteMandatoryValues = [
  "Debit Note Date",
  "Debit Note Number",
  "Vendor Name",
  "Status",
  "Total",
  "Organization",
];

export const paymentReceivedColumnValues = [
  "Payment Date",
  "Payment Number",
  "Reference Number",
  "Customer Name",
  "Invoice Number",
  "Status",
  "Amount Received",
  "Amount in Excess",
  "Tax Deducted(TDS)",
  "Organization",
];

export const paymentReceivedDefaultValues = [
  "Payment Date",
  "Payment Number",
  "Customer Name",
  "Status",
  "Amount Received",
  "Organization",
];

export const paymentReceivedMandatoryValues = [
  "Payment Date",
  "Customer Name",
  "Status",
  "Amount Received",
  "Organization",
];

export const paymentMadeColumnValues = [
  "Payment Date",
  "Payment Number",
  "Reference Number",
  "Vendor Name",
  "Bill Number",
  "Amount Paid",
  "Amount in Excess",
  "Status",
  "Paid Through",
  "Organization",
];

export const paymentMadeDefaultValues = [
  "Payment Date",
  "Payment Number",
  "Vendor Name",
  "Amount Paid",
  "Status",
  "Organization",
];

export const paymentMadeMandatoryValues = [
  "Payment Date",
  "Vendor Name",
  "Amount Paid",
  "Status",
  "Organization",
];

export const priceItemHiddenColumnValues = ["From Units", "To Units"];

export const priceItemColumnValues = [
  "Item",
  "From Units",
  "To Units",
  "Price",
];
export const itemColumsValues = [
  "Item Name",
  "Type",
  "HSN/SAC Code",
  "Account",
  "Rate",
  "Sales Description",
  "Purchase Account",
  "Purchase Rate",
  "Tax",
];
export const itemDefaultValues = ["Item Name", "Type", "Rate", "Purchase Rate"];
export const itemMandatoryValues = ["Item Name"];

export const journalColumnValues = [
  "Date",
  "Journal Entry #",
  "Status",
  "Amount",
  "Organization",
  "Reference",
  "Notes",
  "Created By",
  "Tag",
];

export const journalDefaultValues = [
  "Date",
  "Journal Entry #",
  "Status",
  "Amount",
  "Organization",
];

export const journalMandatoryValues = [
  "Date",
  "Journal Entry #",
  "Status",
  "Amount",
  "Organization",
];

export const customerColumnValues = [
  "Name",
  "Organization",
  "Email",
  "Phone Number",
  "Receivables",
  "Unused Credits",
];
export const customerDefaultValues = [
  "Name",
  "Organization",
  "Receivables",
  "Unused Credits",
];
export const customerMandatoryValues = [
  "Name",
  "Organization",
  "Receivables",
  "Unused Credits",
];
export const vendorColumnValues = [
  "Name",
  "Organization",
  "Email",
  "Phone Number",
  "Payables",
  "Unused Credits",
];
export const vendorDefaultValues = [
  "Name",
  "Organization",
  "Payables",
  "Unused Credits",
];
export const vendorMandatoryValues = [
  "Name",
  "Organization",
  "Payables",
  "Unused Credits",
];

export const coaColumnValues = [
  "Account Name",
  "Account Type",
  "Description",
  "Ledger Code",
  "Balance",
];

export const coaDefaultValues = [
  "Account Name",
  "Account Type",
  "Description",
  "Ledger Code",
  "Balance",
];

export const coaMandatoryValues = ["Account Name", "Account Type", "Balance"];
export const pricingDefaultFrequency = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"];

export const systemVoucherColumnValues = [
  "Date",
  "System Voucher Type",
  "System Voucher #",
  "Status",
  "Amount",
  "Organization",
  "Reference",
  "Notes",
  "Created By",
];

export const systemVoucherDefaultValues = [
  "Date",
  "System Voucher Type",
  "System Voucher #",
  "Status",
  "Amount",
  "Organization",
];

export const systemVoucherMandatoryValues = [
  "Date",
  "System Voucher Type",
  "System Voucher #",
  "Status",
  "Amount",
  "Organization",
];
