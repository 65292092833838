import React from "react";
import { CreateContactError, CreateContactValues } from "../../../../types";
import { colors } from "../../../constants/colors";
import { DeleteIcon } from "../customSvgIcons/deleteIcon";
import "../../../../index.css";
import "./ContactBox.css";

type Props = {
  contact: CreateContactValues;
  index: number;
  onDeleteClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  contactHandleChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  contactFormErrors: CreateContactError[];
  setAsMainContact: () => void;
};
function ContactBox({ contact, index, onDeleteClick, contactHandleChange, contactFormErrors, setAsMainContact }: Props) {
  return (
    <div
      key={"contact-" + index}
      className={
        contact.is_primary
          ? "contact-persons-wrapper main-contact-highlight"
          : "contact-persons-wrapper"
      }
    >
      <a href="#" className="delete-icon delete-btn" onClick={onDeleteClick}>
        <DeleteIcon color={colors.slate} />
      </a>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            className="form-control"
            placeholder="Enter first name"
            value={contact.first_name}
            onChange={contactHandleChange}
            maxLength={200}
          />
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="last_name">Last Name</label>
          <input
            type="text"
            name="last_name"
            id="last_name"
            className="form-control"
            placeholder="Enter last name"
            value={contact.last_name}
            onChange={contactHandleChange}
            maxLength={200}
          />
        </div>
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="p_phone_number">Phone Number</label>
          <input
            type="text"
            name="phone"
            id="p_phone_number"
            className={
              contactFormErrors[index]?.phone
                ? "form-control error"
                : "form-control"
            }
            placeholder="Enter phone number"
            value={contact.phone}
            onChange={contactHandleChange}
            maxLength={19}
          />
          <span className="error">{contactFormErrors[index]?.phone}</span>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="p_email">Email</label>
          <input
            type="text"
            name="email"
            id="p_email"
            className={
              contactFormErrors[index]?.email
                ? "form-control error"
                : "form-control"
            }
            placeholder="Enter valid email address"
            value={contact.email}
            onChange={contactHandleChange}
            maxLength={200}
          />
          <span className="error">{contactFormErrors[index]?.email}</span>
        </div>
      </div>
      <div className="col-12 col-lg-4 mw-325">
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id="main_contact1"
            name="option_main_contact"
            value="main contact person"
            checked={contact.is_primary ? true : false}
            onChange={setAsMainContact}
          />
          <label className="form-check-label fw-normal" htmlFor="main_contact1">
            Main contact person
          </label>
        </div>
      </div>
    </div>
  );
}

export default ContactBox;
