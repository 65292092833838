import {
  JournalLineItemCalc,
  JournalValues,
  systemVoucherConfigurations,
  systemVoucherConfigurationsPayload,
} from "../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  calculateAmount,
  getSystemVoucherList,
  systemVoucherNumberExist,
  getSystemVoucherNumber,
  createNewSystemVoucher,
  getSystemVoucherDetails,
  deleteSystemVoucherItem,
  deleteSystemVoucherRecipient,
  editSystemVoucher,
  systemVoucherAttachments,
  sendSystemVoucher,
  systemVoucherAttachmentsDelete,
  deleteSystemVoucher,
  systemVoucherPdfLink,
  transactionLimitStatus,
  getReportingTagSystemVoucher,
  putReportingTags,
  getSystemVoucherConfig,
  systemVoucherPrefixExists,
  createNewSystemVoucherConfiguration,
  systemVoucherNameExists,
  getNewSystemVoucherConfigurationDetails,
  updateSystemVoucherConfiguration,
  deleteSystemVoucherConfiguration,
} from "./systemVoucherAPI";

export const systemVoucherList = createAsyncThunk(
  "system-voucher/List",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    orgIds: number[];
    statusList: string[];
    tagList: string[];
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
    voucherConfigIds?: number[];
  }) => {
    return await getSystemVoucherList(data);
  }
);

export const deleteSystemVoucherDetails = createAsyncThunk(
  "delete/system-voucher",
  async (data: { systemVoucherId: number; orgId: number }) => {
    return await deleteSystemVoucher(data);
  }
);

export const createSystemVoucherVoucher = createAsyncThunk(
  "create/system-voucher",
  async (data: { values: JournalValues; status: string; orgId: number }) => {
    return await createNewSystemVoucher(data);
  }
);

export const editSystemVoucherDetails = createAsyncThunk(
  "edit/system-voucher",
  async (data: {
    id: number;
    values: JournalValues;
    status: string;
    orgId: number;
  }) => {
    return await editSystemVoucher(data);
  }
);

export const deleteSystemVoucherItemDetails = createAsyncThunk(
  "delete/system-voucher/item",
  async (data: { systemVoucherId: number; itemId: number; orgId: number }) => {
    return await deleteSystemVoucherItem(data);
  }
);

export const deleteSystemVoucherRecipientDetails = createAsyncThunk(
  "system-voucher/recipient/delete",
  async (data: {
    systemVoucherId: number;
    emailIds: number[];
    orgId: number;
  }) => {
    return await deleteSystemVoucherRecipient(data);
  }
);

export const systemVoucherDetails = createAsyncThunk(
  "systemVoucherDetail",
  async (data: { systemVoucherId: number; orgId: number }) => {
    return await getSystemVoucherDetails(data);
  }
);

export const getNewSystemVoucherNumber = createAsyncThunk(
  "system-voucher/number",
  async (data: {orgId: number, prefix?: string}) => {
    return await getSystemVoucherNumber(data);
  }
);

export const checkSystemVoucherNumber = createAsyncThunk(
  "system-voucher/number-exist",
  async (data: {
    systemVoucherNumber: string;
    orgId: number;
    systemVoucherId: number;
  }) => {
    return await systemVoucherNumberExist(data);
  }
);

export const sendSystemVoucherData = createAsyncThunk(
  "send-system-voucher",
  async (data: {
    systemVoucherId: number;
    emails: string[];
    orgId: number;
  }) => {
    return await sendSystemVoucher(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    currencyId: number;
    values: JournalLineItemCalc[];
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const getSystemVoucherLink = createAsyncThunk(
  "system-voucher/pdf",
  async (data: { systemVoucherId: number; orgId: number }) => {
    return await systemVoucherPdfLink(data);
  }
);

export const attachFiles = createAsyncThunk(
  "system-voucher/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await systemVoucherAttachments(data);
  }
);

export const deleteSystemVoucherFile = createAsyncThunk(
  "system-voucher/file/delete",
  async (data: { systemVoucherId: number; fileId: number; orgId: number }) => {
    return await systemVoucherAttachmentsDelete(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "system-voucher/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const generateSystemVoucher = createAsyncThunk(
  "system-voucher-generate",
  async (data: { systemVoucherId: number; orgId: number }) => {
    return await getReportingTagSystemVoucher(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { systemVoucherId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);

export const getSystemVoucherConfigList = createAsyncThunk(
  "system-voucher-config-list",
  async (data: { orgId: number }) => {
    return await getSystemVoucherConfig(data);
  }
);

export const systemVoucherNameIsExists = createAsyncThunk(
  "system-voucher-name/is-exists",
  async (data: {
    systemVoucherId?: string | number;
    systemVoucherName: string;
    orgId: number;
  }) => {
    return await systemVoucherNameExists(data);
  }
);
export const systemVoucherPrefixIsExists = createAsyncThunk(
  "system-voucher-prefix/is-exists",
  async (data: {
    systemVoucherId?: string | number;
    prefix: string;
    orgId: number;
  }) => {
    return await systemVoucherPrefixExists(data);
  }
);

export const createSystemVoucherConfiguration = createAsyncThunk(
  "create/system-voucher-configuration",
  async (data: {
    values: systemVoucherConfigurationsPayload;
    orgId: number;
  }) => {
    return await createNewSystemVoucherConfiguration(data);
  }
);

export const getSystemVoucherConfigurationDetail = createAsyncThunk(
  "get/system-voucher-configuration-detail",
  async (data: { systemVoucherId: number; orgId: number }) => {
    return await getNewSystemVoucherConfigurationDetails(data);
  }
);

export const updateSystemVoucherConfigurationItem = createAsyncThunk(
  "eddit/system-voucher-configuration",
  async (data: {
    values: systemVoucherConfigurationsPayload;
    systemVoucherId: number;
    orgId: number;
  }) => {
    return await updateSystemVoucherConfiguration(data);
  }
);

export const deleteSystemVoucherConfigurationItem = createAsyncThunk(
  "system-voucher-configuration/delete",
  async (data: { systemVoucherId: number; orgId: number }) => {
    return await deleteSystemVoucherConfiguration(data);
  }
);

// const initialState = {};

// Define a type for your system voucher
interface SystemVoucher {
  id: number;
  name: string;
}
interface SystemVoucherState {
  items: SystemVoucher[]; // Array of system vouchers
  loading: boolean;
  error?: string; // Optional error property
}

// Define the initial state
const initialState: SystemVoucherState = {
  items: [], // Start with an empty array
  loading: false,
};

export const systemVoucherSlice = createSlice({
  name: "systemVoucherManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        deleteSystemVoucherConfigurationItem.fulfilled,
        (state, action) => {
          // Assuming your state has an `items` array, remove the item by its id.
          state.items = state.items.filter(
            (item) => item.id !== action.meta.arg.systemVoucherId
          );
        }
      )
      .addCase(
        deleteSystemVoucherConfigurationItem.rejected,
        (state, action) => {
          // Handle failure
          state.error = action.error.message;
        }
      )
      .addCase(
        deleteSystemVoucherConfigurationItem.pending,
        (state, action) => {
          // Handle loading state if necessary
          state.loading = true;
        }
      );
  },
});

export default systemVoucherSlice.reducer;
