import { toast } from "react-toastify";
import { ErrorImg } from "../../../../../assets/images";
import { deleteUserDetails } from "../../userSlice";
import DeleteModal from "../../../../common/components/DeleteModal";
import useCommonData from "../../../../hooks/useCommon";
type Props = {
  deleteUserId: number;
  userName: string;
  refreshList: () => void;
};
const DeleteUser = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const modalCancelHandler = () => {
    $(".close-delete-btn").click();
  };
  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      deleteUserDetails({
        id: props.deleteUserId,
        orgId: currentUserInfo.organization_id,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshList();
        $(".close-delete-btn").click();
        toast.success("User deleted successfully!", {
          toastId: "user-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        if (response.error.response.detail) {
          const errorDetails = response.response.error.detail;
          if (Array.isArray(errorDetails) && errorDetails.length) {
            for (const error of errorDetails) {
              if (error.type === "value_error.default_admin") {
                $(".close-delete-btn").click();
                toast.error("Default admin can not be deleted", {
                  icon: ({ theme, type }) => (
                    <img
                      src={ErrorImg}
                      width="16"
                      height="14"
                      alt="error-img"
                    />
                  ),
                  toastId: "delete-user-failed-error",
                  closeButton: false,
                  className: "toast-error",
                  position: "top-center",
                });
              }
            }
          }
        }
      }
    }
  };
  return (
    <DeleteModal
      heading=" Delete User!"
      subHeading="Are you sure you want to delete the user"
      transactionName={props.userName}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};

export default DeleteUser;
