import "./userForm.css";
import { useEffect, useState } from "react";
import {
  ObjectType,
  OrganizationRoleValues,
  UpdateUserValues,
  createUserValues,
} from "../../../../../../types";
import OrganizationSelector from "./components/OrganizationSelector";
import {
  initialEditUserValues,
  initialOrgDropdownValues,
  initialOrgValues,
  initialUserValues,
} from "./StateInitialization";
import useCommonData from "../../../../../hooks/useCommon";
import {
  checkIsUserEmailUsed,
  getOrganizationDropdownValues,
  getRolesDropdownValues,
  inviteUser,
  updateUserDetails,
  usersSelector,
} from "../../../userSlice";
import { useAppSelector } from "../../../../../../app/hooks";
import RoleSelector from "./components/RoleSelector";
import { Link } from "react-router-dom";
import { AddTagIcon } from "../../../../../../assets/images";
import TagsSelector from "./components/TagsSelector";
import { ValidateUserForm } from "./ValidateUserForm";
import { toast } from "react-toastify";
import _ from "lodash";
import { setLoaderState } from "../../../../../common/commonSlice";

type Props = {
  userId: number;
  isSuperUser: boolean;
  refreshList: () => void;
  handleClose: () => void;
};
export const UserForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const users = useAppSelector(usersSelector);
  const [userFormValues, setUserFormValues] =
    useState<createUserValues>(initialUserValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [backendError, setBackendError] = useState<{ [key: string]: any }>({});
  const [organizationValues, setOrganizationValues] = useState<
    OrganizationRoleValues[]
  >([initialOrgValues]);
  const [orgDropdownValues, setOrgDropdownValues] = useState([
    initialOrgDropdownValues,
  ]);
  const [organizations, setOrganizations] = useState([]);
  const [countOfTags, setCountOfTags] = useState(0);
  const [isCreate, setIsCreate] = useState(false);
  const [updateInputs, setUpdateInputs] = useState<UpdateUserValues>(
    initialEditUserValues
  );
  const [isEmailExist, setEmailExist] = useState(false);
  const [initialUpdateInputs, setInitialUpdateInputs] =
    useState<UpdateUserValues>(initialEditUserValues);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let usersPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Users")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (emailRegex.test(userFormValues.email)) {
      checkEmail(userFormValues.email, signal);
    }
    return () => {
      controller.abort();
    };
  }, [userFormValues.email]);
  /**
   * Fetch the input field for edit functionality
   */
  useEffect(() => {
    if (props.userId > 0) {
      setOrganizationValues([]);
      users.users.map((user, index) => {
        if (user.id === props.userId) {
          setUserFormValues({
            name: user.name,
            email: user.email,
            organization_role_mappings: user.organization_role_mappings,
            tag_ids: user.tags.map((tag) => tag.id),
          });
          const orgValues: any = [];
          if (usersPermission.includes("Create")) {
            setTimeout(async () => {
              const response = await dispatch(
                getOrganizationDropdownValues(currentUserInfo.organization_id)
              );
              const org_list_response = response.payload.organization_list;
              user.organization_role_mappings.map(async (item) => {
                const role_list_response = org_list_response
                  .filter((roleObj: any) => roleObj.id === item.organization_id)
                  .map((roleObj: any) => roleObj.role_list);
                orgValues.push({
                  org_id: item.organization_id,
                  role_id: item.role_id,
                  role_list: [...role_list_response[0]],
                });
                return item;
              });
              setOrganizationValues([...orgValues]);
            }, 0);
          }
        }
      });
    }
  }, [props.userId]);

  useEffect(() => {
    if (usersPermission.includes("Create")) {
      getOrganizationValues();
    }
  }, [users]);

  useEffect(() => {
    let payload = organizationValues.map((item) => {
      return {
        organization_id: parseInt(item.org_id),
        role_id: item.role_id,
      };
    });
    const payloadFiltered = payload.filter((item) => {
      if (item.organization_id && item.role_id) {
        return item;
      }
    });
    setUserFormValues((values: any) => ({
      ...values,
      organization_role_mappings: [...payloadFiltered],
    }));
  }, [organizationValues]);
  /**
   * make the update user form values from inputs.
   */
  useEffect(() => {
    if (props.userId > 0) {
      setUpdateInputs({
        name: userFormValues.name,
        organization_role_mappings: userFormValues.organization_role_mappings,
        tag_ids: userFormValues.tag_ids,
        is_changed: false,
      });
    }
  }, [userFormValues]);
  /**
   * Set the initial user form values to compare with updated form values
   */
  useEffect(() => {
    users.users
      .filter((users) => users.id === props.userId)
      .map((user) => {
        setInitialUpdateInputs({
          name: user.name,
          organization_role_mappings: user.organization_role_mappings,
          tag_ids: user.tags.map((tag) => {
            return tag.id;
          }),
          is_changed: false,
        });
      });
  }, [users, props.userId]);
  /**
   * Input Change Handler
   */
  const inputChangeHandler = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    setUserFormValues((values) => ({ ...values, [name]: value }));
  };
  /**
   * get the organization values to be shown in the organization drop down.
   */
  const getOrganizationValues = async () => {
    dispatch(setLoaderState(true));
    let response = await dispatch(
      getOrganizationDropdownValues(currentUserInfo.organization_id)
    );
    let values = response.payload.organization_list;
    let organizationsObj = values.map((item: any) => {
      return {
        label: item.name,
        desc: item.parent_hierarchy,
        id: item.id,
      };
    });
    setOrganizations(values);
    setOrgDropdownValues(organizationsObj);
    dispatch(setLoaderState(false));
  };
  /**
   * Input submit handler in organization search
   */
  const organizationHandler = async (e: any, value: any, itemIndex: number) => {
    let id: any = organizations.filter((item: any) => {
      if (item.name === value.label && item.parent_hierarchy === value.desc) {
        return item.id;
      }
    });
    let org_id = id[0].id;
    let rolesValues: any = await dispatch(
      getRolesDropdownValues({
        id: org_id,
        orgId: currentUserInfo.organization_id,
      })
    );
    organizationValues[itemIndex] = {
      org_id: org_id,
      role_id: 0,
      role_list: rolesValues.payload,
    };
    setOrganizationValues([...organizationValues]);
    let payload = organizationValues.map((item) => {
      return {
        organization_id: parseInt(item.org_id),
        role_id: item.role_id,
      };
    });
    const payloadFiltered = payload.filter((item) => {
      if (item.organization_id && item.role_id) {
        return item;
      }
    });
    setUserFormValues((values: any) => ({
      ...values,
      organization_role_mappings: [...payloadFiltered],
    }));
  };
  /**
   * Function to handle role input change
   */
  const roleInputChangeHandler = (e: any, index: number) => {
    let value = parseInt(e.value);
    if (organizationValues[index]) {
      organizationValues[index].role_id = value;
    } else {
      organizationValues[index] = {
        org_id: organizationValues[index].org_id,
        role_id: value,
        role_list: organizationValues[index].role_list,
      };
    }
    setOrganizationValues([...organizationValues]);
    let payload = organizationValues.map((item) => {
      return {
        organization_id: parseInt(item.org_id),
        role_id: item.role_id,
      };
    });
    const payloadFiltered = payload.filter((item) => {
      if (item.organization_id && item.role_id) {
        return item;
      }
    });
    setUserFormValues((values: any) => ({
      ...values,
      organization_role_mappings: [...payloadFiltered],
    }));
  };
  /**
   * Add more organization and roles
   */
  const addMoreOrganization = () => {
    const orgObject = initialOrgValues;
    setOrganizationValues([...organizationValues, orgObject]);
  };

  const handleSelectTags = (e: any) => {
    const selectedTag = e.map((tag: { value: any }) => tag.value);
    setCountOfTags(countOfTags + 1);
    setUserFormValues((values) => ({ ...values, tag_ids: selectedTag }));
  };

  const handleRemoveTags = (e: any) => {
    const selectedTag = e.map((tag: { value: any }) => tag.value);
    setCountOfTags(countOfTags - 1);
    setUserFormValues((values) => ({ ...values, tag_ids: selectedTag }));
  };
  /**
   * Create user and Edit user function call.
   */
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const errors = ValidateUserForm(userFormValues, isEmailExist);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#user-invite-form-btn").addClass("create-user-form-section-disable");
      if (props.userId > 0) {
        updateUser({
          id: props.userId,
          values: updateInputs,
          orgId: currentUserInfo.organization_id,
        });
      } else {
        createUser(userFormValues);
      }
    }
  };

  const checkEmail = async (email: string, signal: AbortSignal) => {
    const responseAction = await dispatch(
      checkIsUserEmailUsed({
        orgId: currentUserInfo.organization_id,
        emailToCheck: email,
        signal: signal,
        userId: props.userId,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      if (response.is_email_available === false) {
        setEmailExist(true);
        setFormErrors({
          email: "Email already exists! Please choose a different.",
        });
      } else {
        setEmailExist(false);
        setFormErrors({ ...formErrors, email: "" });
      }
    }
  };
  /**
   * create user handler
   */
  const createUser = async (data: createUserValues) => {
    setIsCreate(true);
    const responseAction = await dispatch(
      inviteUser({ values: data, orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBackendError({});
        setIsCreate(false);
        props.handleClose();
        props.refreshList();
        toast.success("User created successfully!", {
          toastId: "user-create-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        setIsCreate(false);
        $("#user-invite-form-btn").removeClass(
          "create-user-form-section-disable"
        );
        const errorDetails = response.error.detail;
        if (Array.isArray(errorDetails) && errorDetails.length) {
          let errorObject: { [key: string]: any } = {};
          for (const error of errorDetails) {
            if (error.type === "value_error.email") {
              errorObject.email = error.msg;
            }
          }
          setBackendError(errorObject);
        }
      }
    }
  };
  /**
   * Update user handler
   */
  const updateUser = async (data: {
    id: number;
    values: UpdateUserValues;
    orgId: number;
  }) => {
    setIsCreate(true);
    if (
      _.isEqual(
        updateInputs.organization_role_mappings,
        initialUpdateInputs.organization_role_mappings
      )
    ) {
      data.values.is_changed = false;
    } else {
      data.values.is_changed = true;
    }
    const responseAction = await dispatch(updateUserDetails(data));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setIsCreate(false);
        props.handleClose();
        props.refreshList();
        toast.success("User updated successfully!", {
          toastId: "user-update-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        setIsCreate(false);
      }
    }
  };

  return (
    <div className="user-form-modal-wrapper">
      <form className="create-user-form-wrap user-form-body">
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="name">
            Name
            <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={userFormValues.name || ""}
            className={formErrors.name ? "form-control error" : "form-control"}
            placeholder="Enter name"
            onChange={inputChangeHandler}
            maxLength={200}
          />
          <span className="error">{formErrors.name}</span>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="email">
            Email Address<span className="mandatory">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={userFormValues.email || ""}
            className={
              formErrors.email || backendError.email
                ? "form-control error"
                : "form-control"
            }
            placeholder="Enter valid email address"
            onChange={inputChangeHandler}
            disabled={props.userId > 0 ? true : false}
            maxLength={200}
          />
          <span className="error">
            {formErrors.email || backendError.email}
          </span>
        </div>
        <div className="organization-wrap">
          <hr></hr>
          <h5>Assign Organization & Roles</h5>
          {orgDropdownValues.length > 0 &&
            orgDropdownValues[0].label &&
            organizationValues.map((item, index) => {
              return (
                <div className="org-flex-wrap" key={index}>
                  <OrganizationSelector
                    index={index}
                    userId={props.userId}
                    isSuperUser={props.isSuperUser}
                    orgDropdownValues={orgDropdownValues}
                    item={item}
                    organizationHandler={organizationHandler}
                  />
                  <RoleSelector
                    userId={props.userId}
                    isSuperUser={props.isSuperUser}
                    index={index}
                    item={item}
                    roleInputChangeHandler={roleInputChangeHandler}
                  />
                </div>
              );
            })}
          {formErrors.org_role && (
            <span className="error">{formErrors.org_role}</span>
          )}
          <div className="d-flex align-items-center mb-4 add-more">
            {(props.userId > 0 && props.userId === currentUserInfo.id) ||
            props.isSuperUser ? null : (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  addMoreOrganization();
                }}
              >
                <img src={AddTagIcon} alt="" />
                <span className="px-2">Add more</span>
              </Link>
            )}
          </div>
          <hr></hr>
        </div>
        {/* <TagsSelector
          handleSelectTags={handleSelectTags}
          handleRemoveTags={handleRemoveTags}
          userFormValues={userFormValues}
        /> */}
        <div>
          <button
            className="btn btn-block fa-lg invite-btn mb-4"
            type="button"
            disabled={isCreate ? true : false}
            onClick={handleFormSubmit}
          >
            {props.userId > 0 ? "Update" : "Send Invite"}
          </button>
          <button
            className="btn btn-block fa-lg cancel-btn mb-4"
            type="button"
            onClick={props.handleClose}
            disabled={isCreate ? true : false}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
