import React from "react";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../common/components/Delete";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  value: any;
  onClickDelete: () => void;
};
function ListActions({ value, onClickDelete }: Props) {
  const { vendorRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {vendorRolePermission.includes("Update") ? (
        <li>
          <Edit
            url={`/vendors/edit/${value.id}`}
            isMutable={true}
            name="Vendors"
            isLocked={false}
            className={"dropdown-item"}
            lockDate=""
          />
        </li>
      ) : null}
      {vendorRolePermission.includes("Delete") ? (
        <li>
          <Delete
            className={"dropdown-item delete"}
            deleteIcon={true}
            isMutable={!value.transactionExist}
            onClick={onClickDelete}
            isLocked={false}
            lockDate=""
            name="Vendor"
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default ListActions;
