import React, { useEffect, useRef, useState } from "react";
import "./editPopup.css";
type Props = {
  title: string;
  inputType: string;
  currentValue: string | number;
  inputId: string;
  label: string;
  maxLimit: number;
  saveAction: (inputValue: string | number) => void;
  cancelAction: () => void;
  error?: string;
  // updateTotalError?: (errorText: string) => void;
};

export default function EditPopup(props: Props) {
  const moduleTitle: string = props.title;
  const inputType: string = props.inputType;
  const inputID: string = props.inputId;
  const ariaLabel: string = props.label;
  const initialValue: string | number = props.currentValue;
  const lengthMax: number = props.maxLimit;
  const saveHandler = props.saveAction;
  const cancelHandler = props.cancelAction;
  const [inputValue, setInput] = useState<string | number>("");
  const [errorTotal, setErrorTotal] = useState<string>("");
  const newRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    setInput(initialValue);
  }, [initialValue]);

  // useEffect(() => {
  //   props.updateTotalError && props.updateTotalError(errorTotal);
  // }, [errorTotal]);

  const handleOutsideClick = (e: any) => {
    if (
      newRef.current &&
      !(newRef.current as HTMLElement)?.contains(e.target)
    ) {
      props.cancelAction();
    }
  };
  const handleInput = (e: any) => {
    let regex = /^\d+$/;
    if (e.target.value === "") {
      setInput("");
    }
    if (regex.test(e.target.value)) {
      setInput(Number(e.target.value));
    }
  };

  const validateAmount = (value: string | number): boolean => {
    // const regex = /^(?!0(\.0{1,2})?$)\d{1,3}(,\d{3})*(\.\d{1,2})?$/;
    const regex = /^\d+$/;
    if (typeof value === "number") {
      value = value.toString();
    }
    return regex.test(value.trim());
  };

  const editTotalHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof inputValue === "string" && inputValue.trim() === "") {
      setErrorTotal("Please enter total amount");
    } else if (!validateAmount(inputValue)) {
      setErrorTotal("Please enter a valid amount");
    } else {
      setErrorTotal("");
      saveHandler(inputValue);
    }
    // props.updateTotalError && props.updateTotalError(totalErrorText);
  };
  return (
    <>
      <div className="edit-input-popup" tabIndex={-1} ref={newRef}>
        <div className="card card-body">
          <div className="row mb-0">
            <div className={"col"}>
              <label htmlFor={inputID}>{moduleTitle}</label>
              <input
                type={inputType}
                className={`form-control ${inputID}`}
                placeholder="Total amount"
                name={inputID}
                id={inputID}
                aria-label={ariaLabel}
                value={inputValue}
                maxLength={lengthMax}
                onChange={handleInput}
              />
              <span className="error clear-both text-align-left">
                {errorTotal ? errorTotal : props.error ? props.error : ""}
              </span>
              <div className="form-button-wrapper">
                <button
                  type="button"
                  className="save-button"
                  data-bs-toggle="collapse"
                  onClick={editTotalHandler}
                >
                  Save
                </button>
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  className="cancel-button"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    cancelHandler();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
