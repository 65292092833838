import React, { useEffect, useRef, useState } from "react";
import "./datepicker.css";
import "./CustomDatePicker.css";
import { TextField, InputAdornment, IconButton, Popper } from '@mui/material';
import CustomMonths from "./DatePicker/CustomMonths";
import CustomDays from "./DatePicker/CustomDays";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { getFieldFormattedValue, getValidDay } from "../../home/calendarBilling/helpers/calendarInvoicingHelper";
import { monthOptions } from "../../constants/constants";
import { OptionType } from "../../../types";
import { SxProps } from "@mui/system";

type Props = {
  date: string;
  handleDate: (date: Date | null, type?: string) => void;
  type: string;
  error: string;
  id?: string;
  name?: string;
  placeholder?: string;
  isOnChangeDisabled?: boolean;
  isHeaderDisabled?:boolean;
};

const CustomDatePicker = (props: Props) => {
const year = new Date().getFullYear()
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>(props.date || '');
  const [openMonthPicker, setOpenMonthPicker] = useState<boolean>(false)
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1)
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
  if(!!selectedDate){
    const splitSelectedDate = selectedDate.split('-')
    setSelectedDay(splitSelectedDate[0])
    if (splitSelectedDate.length > 1) setSelectedMonth(Number(splitSelectedDate[1]))
  } 
  },[selectedDate])

  const handleDayChange = (day: string) => {
    if (props.isHeaderDisabled) setSelectedDate(day);
    else setSelectedDate(`${day}-${selectedMonth.toString().padStart(2, '0')}`);
    
    if (openDatePicker){ 
    props.handleDate(new Date(year, selectedMonth-1, Number(day)))
    }
    setOpenDatePicker(false);
  };

  const handleMonthChange = (month: string) => {
    let setDay = selectedDay

    if (!selectedDay) {
      setDay = "01"
    } else {
      setDay = getValidDay(year, Number(month), Number(setDay))
    }

    props.handleDate(new Date(year, Number(month)-1, Number(setDay)))
    setSelectedDate(setDay + '-' + month.padStart(2, '0'));

    setOpenMonthPicker(false)
  }
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenDatePicker((prev) => !prev);
    setOpenMonthPicker(false)
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {

      setOpenDatePicker(false);
      setOpenMonthPicker(false)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sxProps: SxProps = {
    "&.MuiInputBase-root": {
      height: '41px',
    },
  };

  return (
    <>
      <TextField
        placeholder={props.placeholder}
        variant="outlined"
        className={`custom-date-picker date-container ${props.error ? "error" : ""} ${props.id}`}
        value={getFieldFormattedValue(selectedDate)}
        InputProps={{
          readOnly: props.isOnChangeDisabled,
          sx: sxProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton ref={buttonRef} onClick={handleIconClick} aria-describedby="custom-date-picker-dropdown" />
            </InputAdornment>
          ),
        }}
      />
      <Popper
        className="custom-date-picker-popper"
        id="custom-date-picker-dropdown"
        open={openDatePicker}
        anchorEl={anchorEl}
        ref={popperRef}
        placement="bottom-end"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              rootBoundary: 'anchorEl',
            },
          },
        ]}
      >
        {props.isHeaderDisabled ? null :<div>
          <div className="custom-calendar-header" onClick={() => setOpenMonthPicker((prev) => !prev)}>
            <div className="custom-calendar-header-label">
              {monthOptions.filter((data: OptionType) => Number(data.value) === Number(selectedMonth))[0]?.label || ''}
            </div>
            <div className="custom-calendar-header-down-arrow">
              <ArrowDropDownOutlinedIcon fill={"#91919c"} />
            </div>
          </div>
        </div>}
        {
          openMonthPicker && !props.isHeaderDisabled
            ? <CustomMonths handleMonthChange={handleMonthChange} selectedMonth={selectedMonth}/>
            : <CustomDays handleDayChange={handleDayChange} selectedMonth={selectedMonth} selectedDay={selectedDay} isHeaderDisabled={props.isHeaderDisabled}/>
        }
      </Popper>
    </>
  );
};

export default CustomDatePicker;
