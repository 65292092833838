import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import {
  getFiscalYear,
  getfiscalYearStartMonth,
  getStartYearDate,
} from "../../../../../helpers/fiscalYearHelper";
import { getFormatedDate } from "../../../../../helpers/helper";
import { currentUserSelector } from "../../../../common/commonSlice";
import EmptyReport from "../../../../common/components/EmptyReport";
import { trialBalanceSelector } from "../../ReportSlice";
import "./ReportBody.css";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { restoreExpansionState } from "../../../../../helpers/reportHelper";

let executionControlFlag: boolean = false;
function ReportBody(props: {
  trialBalanceData: any;
  startDate: string;
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const trialBalanceData = props.trialBalanceData;
  const trialBalanceValues = useAppSelector(trialBalanceSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [toggle, setToggle] = useState(false);
  const [toggleAgain, setToggleAgain] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const expandedRowIdsRef = useRef<string[]>([]);

  useEffect(() => {
    calculateLeftRowPadding();
    initAccordion();
  }, [props.trialBalanceData, trialBalanceValues]);

  useEffect(() => {
    const expandedIds = restoreExpansionState(); // Restore expansion state on component load
    if (expandedIds) {
      setExpandedRowIds(expandedIds);
    }
  }, [props.trialBalanceData]);

  useEffect(() => {
    expandedRowIdsRef.current = expandedRowIds;
  }, [expandedRowIds]);

  const calculateLeftRowPadding = () => {
    let component = document.getElementsByClassName("parent-row");
    let i: number = 0;
    for (i; i < component.length; i++) {
      const level = Number(component[i].getAttribute("data-level"));
      const pL = 20 + 18 * (level - 2);
      $(component[i]).css("padding-left", pL + "px");
    }
  };
  const initAccordion = () => {
    var collapseBtn: any = document.getElementsByClassName("collapsible-btn");
    for (var i: number = 0; i < collapseBtn.length; i++) {
      collapseBtn[i].removeEventListener("click", toggleExpanding);
      collapseBtn[i].addEventListener("click", toggleExpanding);
    }
  };

  function toggleExpanding(this: any, e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!executionControlFlag) {
      executionControlFlag = !executionControlFlag;
      this.classList.toggle("active");
      this.closest(".parent-row").classList.toggle("expanded");
      var panel: any = this.closest(".parent-row").nextElementSibling;
      panel.classList.toggle("show");
      // Store expanded row id for back navigation
      const parentRowId = this.closest(".parent-row").getAttribute("id");
      const isExpanded = this.classList.contains("active");
      if (isExpanded) {
        expandedRowIdsRef.current.push(parentRowId);
      } else {
        const indexToRemove = expandedRowIdsRef.current.indexOf(parentRowId);
        if (indexToRemove !== -1) {
          expandedRowIdsRef.current.splice(indexToRemove, 1);
        }
      }
      setExpandedRowIds([...expandedRowIdsRef.current]);
    }
    setTimeout(() => {
      executionControlFlag = !executionControlFlag;
    }, 10);
  }
  /**
   * Function to hide amount corresponding to categories while expanding it
   */
  $(document).ready(function () {
    $(".collapsible-btn").on("click", function () {
      setToggle(!toggle);
    });
  });
  useEffect(() => {
    setToggleAgain(!toggleAgain);
  }, [toggle]);

  /**
   * Function to handle whether button need to display for accounts
   * which having child with no transaction.
   */
  const isChildWithTransactionExist = (account: any) => {
    if (account.transaction_exist) {
      return true;
    }
    if (account.node.length) {
      for (let item of account.node) {
        if (item.is_category || item.transaction_exist) {
          return true;
        }
      }
    }
    return false;
  };
  /**
   * Navigation to account transaction page to view the transactions of a particular account
   */
  const accTransactionNavHandler = (accountId: number) => {
    let trialBalanceFilter = {
      currencyId: trialBalanceData.currency_id,
      dateRange: trialBalanceData.date_range,
      startDate: trialBalanceData.start_date,
      endDate: trialBalanceData.end_date,
      organizationId: trialBalanceData.organization_id,
      tag_option_in: trialBalanceData.tag_option_in,
    };
    localStorage.setItem(
      "drill-down-filter",
      JSON.stringify(trialBalanceFilter)
    );
    localStorage.setItem(
      "drill-down-report",
      JSON.stringify({ label: "Trial Balance", url: "/trial-balance" })
    );
    localStorage.setItem("expanded-rows", JSON.stringify(expandedRowIds));
    localStorage.setItem("drill-down-account", accountId.toString());
    let fiscalYear = currentUserInfo.organization_fiscal_year
      ? currentUserInfo.organization_fiscal_year
      : "";
    const startYearFullDate = getStartYearDate(
      fiscalYear,
      new Date(trialBalanceData.end_date)
    );
    let accTransactionFilter = {
      accountId: accountId,
      currencyId: trialBalanceData.currency_id,
      dateRange: "custom",
      startDate: trialBalanceData.start_date,
      endDate: trialBalanceData.end_date,
      organizationId: trialBalanceData.organization_id,
      tag_option_in: trialBalanceData.tag_option_in,
    };
    navigate("/account-transactions", {
      state: { accTransactionFilter: accTransactionFilter },
    });
  };

  const renderFooter = (account: any, isBold: boolean) => {
    if (account.net_difference === 0) {
      return <></>;
    }
    return (
      <div
        className="table-footer account-summary-footer"
        key={"ftr-" + account.id}
      >
        <div
          className={
            isBold ? "item-row summary-row text-bold" : "item-row summary-row"
          }
        >
          <div className="item-col account-col total-label align-right">
            <span>{`Total for ${account.account_name}`}</span>
          </div>
          <div className="item-col debit-col align-left">
            {account.is_debit ? (
              account.net_difference === 0 &&
              account.parent_id !== null ? null : (
                <span>
                  {NumberFormat(
                    Number(account.net_difference),
                    trialBalanceData.currency_code,
                    orgCurrencyList
                  )}
                </span>
              )
            ) : null}
          </div>
          <div className="item-col credit-col align-right">
            {!account.is_debit ? (
              account.net_difference === 0 &&
              account.parent_id !== null ? null : (
                <span>
                  {NumberFormat(
                    Number(account.net_difference),
                    trialBalanceData.currency_code,
                    orgCurrencyList
                  )}
                </span>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderTreeData = (account: any) => {
    return account.node?.map((nodeAccount: any) => {
      return (
        <div key={nodeAccount.id}>
          {nodeAccount.is_category ||
          (!nodeAccount.is_category && nodeAccount.transaction_exist) ? (
            <div className="table-collapsible-item auto-flex bottom-border">
              <div
                id={nodeAccount.id}
                className={
                  !nodeAccount.is_category
                    ? `parent-row item-row account-node button-id-${nodeAccount.id}`
                    : `parent-row item-row button-id-${nodeAccount.id}`
                }
                data-level={nodeAccount.depth}
                onClick={() => {
                  if (!nodeAccount.is_category) {
                    accTransactionNavHandler(nodeAccount.id);
                  }
                }}
              >
                <div className="item-col account-col align-left">
                  <div className="btn-container left-float">
                    {nodeAccount.node.length &&
                    isChildWithTransactionExist(nodeAccount) ? (
                      <button className="collapsible-btn"></button>
                    ) : null}
                  </div>
                  <div className="item-label left-float left-border">
                    {nodeAccount.account_name}
                  </div>
                </div>
                <>
                  <div
                    className={`item-col debit-col align-left ${
                      nodeAccount.is_category ? "category-amount-text-bold" : ""
                    }`}
                  >
                    {nodeAccount.is_debit ? (
                      nodeAccount.net_difference !== 0 &&
                      !$(`.button-id-${nodeAccount.id}`).hasClass(
                        "expanded"
                      ) ? (
                        <span>
                          {NumberFormat(
                            Number(nodeAccount.net_difference),
                            trialBalanceData.currency_code,
                            orgCurrencyList
                          )}
                        </span>
                      ) : null
                    ) : null}
                  </div>
                  <div
                    className={`item-col credit-col align-right ${
                      nodeAccount.is_category ? "category-amount-text-bold" : ""
                    }`}
                  >
                    {!nodeAccount.is_debit ? (
                      nodeAccount.net_difference !== 0 &&
                      !$(`.button-id-${nodeAccount.id}`).hasClass(
                        "expanded"
                      ) ? (
                        <span>
                          {NumberFormat(
                            Number(nodeAccount.net_difference),
                            trialBalanceData.currency_code,
                            orgCurrencyList
                          )}
                        </span>
                      ) : null
                    ) : null}
                  </div>
                </>
              </div>
              {nodeAccount.node?.length ? (
                <div className="table-collapsible-content">
                  {renderTreeData(nodeAccount)}
                  {renderFooter(nodeAccount, false)}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <div className="grid-table report-table-accordion">
      <div className="table-header">
        <div className="item-row">
          <div className="item-col account-col align-left">
            <span>account</span>
          </div>
          <div className="item-col debit-col align-left">
            <span>Net Debit</span>
          </div>
          <div className="item-col credit-col align-right">
            <span>Net Credit</span>
          </div>
        </div>
      </div>
      {trialBalanceData?.items?.length ? (
        trialBalanceData?.items?.map((item: any, index: number) => {
          return (
            <div key={item.id}>
              <div className="table-body" key={"bdy-" + index}>
                <div className="table-collapsible-item auto-flex bottom-border">
                  <div className="item-row category-row w-100">
                    <div className="item-col category-col align-left">
                      {item.account_name}
                    </div>
                  </div>
                  {item.node.map((childItem: any) => {
                    return (
                      <div key={childItem.id}>
                        {childItem.is_category ||
                        (!childItem.is_category &&
                          childItem.transaction_exist) ? (
                          <>
                            <div
                              id={childItem.id}
                              className={
                                !childItem.is_category
                                  ? `parent-row item-row account-node button-id-${childItem.id}`
                                  : `parent-row item-row button-id-${childItem.id}`
                              }
                              data-level={childItem.depth}
                              onClick={() => {
                                if (!childItem.is_category) {
                                  accTransactionNavHandler(childItem.id);
                                }
                              }}
                            >
                              <div className="item-col account-col align-left">
                                <div className="btn-container left-float">
                                  {childItem.node.length &&
                                  isChildWithTransactionExist(childItem) ? (
                                    <button className="collapsible-btn"></button>
                                  ) : null}
                                </div>
                                <div className="item-label left-float left-border">
                                  {childItem.account_name}
                                </div>
                              </div>
                              <div
                                className={`item-col debit-col align-left ${
                                  childItem.is_category
                                    ? "category-amount-text-bold"
                                    : ""
                                }`}
                              >
                                {childItem.net_difference !== 0 &&
                                childItem.is_debit &&
                                !$(`.button-id-${childItem.id}`).hasClass(
                                  "expanded"
                                ) ? (
                                  <span className="text-bold">
                                    {NumberFormat(
                                      Number(childItem.net_difference),
                                      trialBalanceData.currency_code,
                                      orgCurrencyList
                                    )}
                                  </span>
                                ) : null}
                              </div>
                              <div
                                className={`item-col credit-col align-right ${
                                  childItem.is_category
                                    ? "category-amount-text-bold"
                                    : ""
                                }`}
                              >
                                {childItem.net_difference !== 0 &&
                                !childItem.is_debit &&
                                !$(`.button-id-${childItem.id}`).hasClass(
                                  "expanded"
                                ) ? (
                                  <span>
                                    {NumberFormat(
                                      Number(childItem.net_difference),
                                      trialBalanceData.currency_code,
                                      orgCurrencyList
                                    )}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            {childItem.node.length ? (
                              <div
                                className="table-collapsible-content"
                                key={"child-" + childItem.id}
                              >
                                {renderTreeData(childItem)}
                                {renderFooter(childItem, false)}
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
              {item.is_category ? renderFooter(item, true) : null}
            </div>
          );
        })
      ) : props.isLoading === false ? (
        <div className="table-body">
          <div className="table-collapsible-item auto-flex">
            <div className="item-row empty-row w-100">
              <div className="item-col noitem-col align-left">
                <EmptyReport
                  heading="No transactions found!"
                  alt="Empty report"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {props.isLoading === false && trialBalanceData?.items?.length ? (
        <div className="table-footer summary-footer">
          <div className="item-row summary-row">
            <div className="item-col account-col total-label align-right">
              <span>Total</span>
            </div>
            <div className="item-col debit-col align-left">
              <span>
                {NumberFormat(
                  Number(trialBalanceData.total_debit),
                  trialBalanceData.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
            <div className="item-col credit-col align-right">
              <span>
                {NumberFormat(
                  Number(trialBalanceData.total_credit),
                  trialBalanceData.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ReportBody;
