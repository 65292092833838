import { colors } from "../../../constants/colors";
import "../../../../index.css";
import './Button.css';
type Props = {
  buttonText: string;
};
const UnlockButton = (props: Props) => {
  return (
    <>
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={colors.white} fillRule="nonzero">
          <path d="M2.333 5.444h10.889c.43 0 .778.349.778.778v9.334c0 .43-.348.777-.778.777H.778A.778.778 0 0 1 0 15.556V6.222c0-.43.348-.778.778-.778h1.555zM12.444 7H1.556v7.778h10.888V7zm-6.222 4.459a1.555 1.555 0 1 1 1.556 0v1.763H6.222V11.46z" />
          <path d="M2.342 5.459C2.098 2.124 4.3 0 7.07 0c1.963 0 3.536 1.184 4.353 3.09l-1.474.547c-.575-1.363-1.472-2.03-2.88-2.081C5.096 1.483 3.9 3.287 3.9 5.435l-1.557.024z" />
        </g>
      </svg>
      <span className="green-button-text">{props.buttonText}</span>
    </>
  );
};

export default UnlockButton;
