import { useState } from "react";
import { FormTextAreaField } from "../../../../common/components";
import "./MarkAsDeclined.css";

const MarkedAsDeclined = (props: any) => {
  const [text, setText] = useState<string>("");
  const handleTextAreaChange = (event: any) => {
    const value = event.target.value;
    setText(value);
  };
  return (
    <div className="proforma-modal" id="emailToModal">
      <div className="proforma-modal-body" role="document">
        <div className="modal-body-send-email">
          <FormTextAreaField
            id="reason"
            name="reason"
            className="text-area"
            placeholder="reason for decline"
            value={text}
            type="text"
            label="Reason for decline"
            wrapperClass="text-area-wrapper"
            onChange={handleTextAreaChange}
          />
          <span className="error">{}</span>
        </div>
      </div>
      <div
        className="modal-footer justify-content-start"
        id="send-email-button"
      >
        <button
          type="button"
          className="btn send-button"
          data-dismiss="modal"
          onClick={() => {
            props.handleStatusChange("Declined", text);
            props.onCloseModal();
          }}
        >
          Decline
        </button>
        <button
          type="button"
          className="btn cancel-button close-button"
          data-bs-dismiss="modal"
          onClick={props.onCloseModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default MarkedAsDeclined;
