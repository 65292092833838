import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "@mui/material/Modal";
import CloseButton from "../button/CloseButton";
import "../../../../index.css";
import "./alertModal.css";
import { ModalContext } from "./alertModalContext";
import CustomTooltips from "../Tooltip";

type ReusableModalProps = {
  buttonContent: React.ReactNode;
  modalContent: React.ReactNode;
  modalTitle: string;
  wrapperClass?: string;
  modalButtonWrapperClass?: string;
  buttonClass?: string;
  modalBodyClass?: string;
  isCloseButtonEnabled?: boolean;
  disabledActionFunction?: () => void;
  isButtonDisabled?: boolean;
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  tooltipMessage?: string;
  tooltipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
};
// The content to this modal should be styled independently.
// The modal is just a container to hold the form or the upcoming component.
// Make sure that the buttons in the form are styled properly.

export const AlertModal = forwardRef(
  (
    {
      buttonContent,
      modalContent,
      modalTitle,
      wrapperClass,
      buttonClass,
      modalBodyClass,
      modalButtonWrapperClass,
      isCloseButtonEnabled,
      disabledActionFunction,
      isButtonDisabled,
      onOpenFunction,
      onCloseFunction,
      tooltipMessage,
      tooltipPlacement,
    }: ReusableModalProps,
    ref?: React.ForwardedRef<any>
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
      onOpenFunction && onOpenFunction();
      ($(".dropdown-ctrl") as any).dropdown("hide");
    };

    const closeModal = () => {
      setIsOpen(false);
      onCloseFunction && onCloseFunction();
    };

    useImperativeHandle(ref, () => ({
      openAlertModal: openModal,
    }));

    return (
      <ModalContext.Provider value={{ onCloseModal: closeModal }}>
        <div id="reusableAlertModal" className={modalButtonWrapperClass || ""}>
          <div className="reusable-modal-btn no-appearance">
            <button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (isButtonDisabled === true) {
                  e.preventDefault();
                  disabledActionFunction && disabledActionFunction();
                } else {
                  e.preventDefault();
                  openModal();
                }
              }}
              className={`${
                isButtonDisabled === true && !disabledActionFunction
                  ? "is-disabled-alert-modal-btn"
                  : ""
              } ${buttonClass}`}
            >
              {tooltipMessage ? (
                <CustomTooltips
                  placement={tooltipPlacement || "bottom"}
                  title={tooltipMessage}
                >
                  {buttonContent}
                </CustomTooltips>
              ) : (
                buttonContent
              )}
            </button>
          </div>
          <Modal
            open={isOpen}
            onClose={closeModal}
            className={`alert-modal  ${wrapperClass}`}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-dialog">
                <div className="alert-modal-content modal-content">
                  {isCloseButtonEnabled && (
                    <span className="alert-modal-close-btn">
                      <CloseButton closeModalHandler={closeModal} />
                    </span>
                  )}
                  <div className="alert-modal-header position-relative">
                    <h5 className="alert-modal">{modalTitle}</h5>
                  </div>
                  <div
                    className={`alert-modal-body modal-body ${modalBodyClass}`}
                  >
                    {modalContent}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </ModalContext.Provider>
    );
  }
);
