import { ChangeEvent, useEffect, useState } from "react";
import { FormInputField } from "../../../../common/components";
import { CloseRowIcon } from "../../../../common/components/customSvgIcons";
import Grid from "@mui/material/Grid";
import InfoTooltip from "../../../../common/components/InfoTooltip";
import { message } from "../../../../constants/messages";
import "./item.css";
import { RangePriceValue } from "../../subscriptionType";
import useCommonData from "../../../../hooks/useCommon";
import { restoreItemPricing } from "../../subscriptionSlice";
import { useAppSelector } from "../../../../../app/hooks";
import { latestCurrencyRateSelector } from "../../../../common/commonSlice";
import { validatePricingRangeUpdate } from "./ValidateSubscriptionForm";
import { ObjectType } from "../../../../../types";

const Item = (props: any) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const [rangePrice, setRangePrice] = useState<RangePriceValue[]>(props.range_price);
  const [counter, setCounter] = useState<number>(rangePrice.length);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const [formErrors, setFormErrors] = useState<ObjectType>({});

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let errors = validatePricingRangeUpdate(rangePrice);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
    props.onPricePointUpdate(rangePrice)
    }
  };

  useEffect(() => {
    setCounter(rangePrice.length);
  }, []);

  const addRowHandle = (e: ChangeEvent<HTMLInputElement>, rangeData: any, key: any) => {
    e.preventDefault();
    e.stopPropagation();
    if(counter - 1 === key && showAddIcon()){
    const rangeFrom = rangeData[rangeData.length - 1];
    setRangePrice([...rangeData.slice(0, rangeData.length), {
      range_from: rangeFrom?.range_to ? Number(parseFloat(rangeFrom?.range_to.toString())) + 1 : 0,
      range_to: -1,
      price_per_step: '',
    }]);
    setCounter(counter + 1);}
  };

  const removeRowHandle = (event: any, index: any = "") => {
    event.stopPropagation();
    const newRangePrice = rangePrice.map((mappedData: RangePriceValue, i: any) => {
      if (index - 1 === i) {
        return { ...mappedData, range_to: -1 }
      } else return mappedData
    }).filter(
      (element: any, i: any) => i !== index
    );
    setRangePrice([...newRangePrice]);
    setCounter(counter - 1);
  };


  const showAddIcon = () => {
    const lastValue = rangePrice[counter - 1];
    if (lastValue.range_to
      && lastValue.range_from
      && parseFloat(lastValue.range_to.toString()) >= parseFloat(lastValue.range_from.toString())) {
      return true;
    }
    return false;
  };
  
  const handleChangeFrom = (e: any, index: any = "", itemDetails: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if (
      (name === "range_to" ||
        name === "price_per_step" ||
        name === "range_from") &&
      Number.isNaN(Number(value))
    ) {
      return;
    }

    if (name === "price_per_step" || name === "price") {
      let splitValue = value.split(".");
      if (
        splitValue[0].length > 14 ||
        (splitValue[1] && splitValue[1].length > 6)
      )
        return;
    }

    const updatedPriceData =
      name === "range_to" || name === "free_quantity"
        ? Number(value.replaceAll(".", "").trim()) === 0 ? -1 : Number(value.replaceAll(".", "").trim())
        : value;

    setRangePrice([...itemDetails.slice(0, index),
    {...itemDetails[index], [name]: updatedPriceData},
    ...itemDetails.slice(index + 1)]);
  };
 
  const onRestoreButtonClick = async() => {
    const responseAction = await dispatch(
      restoreItemPricing({
        itemId: props.itemId,
        isPlan: props.itemType === 'PLAN',
        pricingId: props.pricing_id,
        orgId: currentUserInfo.organization_id,
        currencyId: props.currencyId ? Number(props.currencyId) :0,
        conversionId: exchangeRateValues.id ? exchangeRateValues.id : 0,
      })
    );

    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setRangePrice(response.item_range.map((data: any) => ({range_to: data.range_to, range_from: data.range_from, price_per_step: data.price})))
        setCounter(response.item_range.length)
      }
    }
  }
  return (
    <div className="item-cart">
      <div className="item-header">
        <b>{props.itemName}</b>
      </div>

      <div className="item-cart-price-range">
        {rangePrice?.map((item: any, key: any | null | undefined) => (
          <>
            <div className="item-price-cont" key={key}>
              <div className="pricing-field-wrap">
                <FormInputField
                  type="text"
                  name="range_from"
                  id="range_from"
                  className={"range-item-price form-control"}
                  label="From (Units)"
                  placeholder={key <= 0 ? "1" : ""}
                  maxLength={14}
                  value={item.range_from}
                  onChange={(e) => {}}
                  isRequired={true}
                  disabled={true}
                  errorClass={"priceErrorMessage"}
                />
                <FormInputField
                  type="text"
                  name="range_to"
                  id="range_to"
                  className={
                    item.fromErrors
                      ? "form-control error range-item-price"
                      : "form-control range-item-price"
                  }
                  label="To"
                  placeholder="& Above"
                  maxLength={14}
                  value={item.range_to && item.range_to !== -1 ? item.range_to : 0}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => handleChangeFrom(e, key, rangePrice)}
                  onBlur={(e) => addRowHandle(e, rangePrice, key)}
                  isRequired={false}
                  error={Object.keys(formErrors).length > 0 && formErrors.pricingErrors[key].range_to 
                    ? formErrors.pricingErrors[key].range_to : ''}
                  disabled={rangePrice?.length - 1 !== key ? true : false}
                  errorClass={"priceErrorMessage"}
                />
                <FormInputField
                  type="text"
                  name="price_per_step"
                  id="price_per_step"
                  className={
                    item.price_per_stepErrors
                      ? "form-control error range-item-price"
                      : "form-control range-item-price"
                  }
                  label="Price Per Step"
                  placeholder="0.00"
                  value={item.price_per_step === 0 ? "0" : item.price_per_step}
                  onChange={(e) => handleChangeFrom(e, key, rangePrice)}
                  isRequired={true}
                  error={Object.keys(formErrors).length > 0 && formErrors.pricingErrors[key].price_per_step 
                    ? formErrors.pricingErrors[key].price_per_step : ''}
                  onClick={(e) => e.stopPropagation()}
                  errorClass={"priceErrorMessage"}
                />
              </div>
              <div className="close-row-container">
                {rangePrice.length - 1 === key && (
                  <>
                    {key > 0 && (
                      <a
                        className="close-row"
                        onClick={(event) => removeRowHandle(event, key)}
                      >
                        <CloseRowIcon />
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
      <Grid container item className="modal-footer subscription-update-pricing-modal-footer">
        <Grid>
          <button
            type="button"
            className="save-button mx-0"
            onClick={(event) => handleSubmit(event)}
          >
            Save
          </button>
          <button
            type="button"
            className="text-decoration-none cancel-button mx-4"
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
        </Grid>
        <Grid>
          <div className="restore-button">
            <button
              className="add-restore-button add-tags-button"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation()
                onRestoreButtonClick()
              }}
            >
              Restore Pricing
            </button>
            <InfoTooltip title={message().subscription.restorePricing + ` ${props.itemType.toLowerCase()}`} isCustomIcon={true}/>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Item;
