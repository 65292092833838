import { SecondaryButton } from "../button";
import { PrimaryButton } from "../button/PrimaryButton";
import { Container } from "../htmlTags/Container";
import { Span } from "../htmlTags/Span";
import { ConfirmationPopupBodyProps } from "./types";
import "./ConfirmationPopup.css";

export const ConfirmationPopupBody = (props: ConfirmationPopupBodyProps) => {
  return (
    <Container
      className={`confirmation-popup-body-wrapper ${props.bodyWrapperClass}`}
    >
      <Container className="confirmation-popup-message-wrapper">
        <Span className={props.messageSpanClass || ""}>{props.children}</Span>
      </Container>
      <Container className="confirmation-popup-button-wrapper">
        <PrimaryButton
          onClick={props.onSubmit}
          isDisabled={false}
          type="button"
        >
          {props.buttonText.submit}
        </PrimaryButton>
        <SecondaryButton
          onClick={props.onCancel}
          isDisabled={false}
          buttonContent={props.buttonText.cancel}
          type="button"
        />
      </Container>
    </Container>
  );
};
