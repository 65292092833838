export const ImageCropperStyle = {
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "fit-content",
    aspectRatio: "1",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 4,
  },
  cropperStyle: {
    width: "100%",
    height: "100%",
  },
};
