import React from "react";

type PopperContextType = {
  onClosePopper: () => void;
};

export const PopperContext = React.createContext<PopperContextType>({
  onClosePopper: () => {},
});

export const useModalContext = () => React.useContext(PopperContext);
