import { useRef } from "react";
import { FormikProps } from "formik";
import CustomerUnpaidInvoice from "./unpaidInvoiceItem";
import { getAmountCalculationResult } from "../../../../paymentReceivedSlice";
import { TotalDetails } from "../totalDetails/totalDetails";
import { NoteTextArea } from "../../../../../../common/components";
import { useAmountCalculation } from "../../../../../../hooks/amountCalculation/usePrAmountCalculation";
import { NumberFormat } from "../../../../../../../helpers/numberFormatHelper";
import { orgCurrencyListSelector } from "../../../../../../common/commonSlice";
import { useAppSelector } from "../../../../../../../app/hooks";
import { useCommonData } from "../../imports";
import "../../../../../paymentsMade/components/paymentMadeForm/components/unpaidBills/unpaidBills.css";
import "./unpaidInvoices.css";

type Props = {
  formik: FormikProps<any>;
};
export const UnpaidInvoices = (props: Props) => {
  const amountData = useAppSelector(getAmountCalculationResult);
  const amountCalculation = useAmountCalculation({ formik: props.formik });
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const { currentUserInfo } = useCommonData();

  const unpaidInvTableRef = useRef<HTMLTableElement>(null);

  const calculateThCount = () => {
    const table = unpaidInvTableRef.current;
    if (table) {
      const thElements = table.querySelectorAll("th");
      return thElements.length;
    }
    return 0;
  };

  return (
    <section
      className={`item-details-wrapper  ${
        !props.formik.values.customer_id ? "disabled-item" : ""
      }`}
    >
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="mb-0">Unpaid Invoices</h2>
        {amountData.total_details?.total &&
        props.formik.values.currency_code ? (
          <div>
            <span>Amount available for allocation: </span>
            <span className="fw-bold">
              {props.formik.values.currency_code}{" "}
              {NumberFormat(
                amountData.total_details.total,
                props.formik.values.currency_code,
                orgCurrencyList
              )}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row mb-0">
        <div className="items-entry-wrapper px-0 mb-0">
          <div className="bill-item-container">
            <table
              id="billItemEntry"
              className={
                props.formik.values.is_tax_deducted === "false" ||
                props.formik.values.is_tax_deducted === false
                  ? "table unpaid-inv-table unpaid-items-table valign-middle w-100 no-withhold"
                  : "table unpaid-inv-table unpaid-items-table valign-middle w-100"
              }
              ref={unpaidInvTableRef}
            >
              <thead className="border-top-0 unpaid-payments-header">
                <tr>
                  <th scope="col" className="item_number">
                    Invoice number
                  </th>
                  <th scope="col" className="item_date">
                    Date
                  </th>
                  <th scope="col" className="item_amount">
                    Invoice Amount
                  </th>
                  <th scope="col" className="amount_due">
                    Amount Due
                  </th>
                  {currentUserInfo.is_payment_allocation_enabled && (
                    <th className="allocation-date-col" scope="col">
                      Allocation Date
                    </th>
                  )}
                  {props.formik.values.is_tax_deducted === "true" ||
                  props.formik.values.is_tax_deducted === true ? (
                    <th className="withholding-tax-col" scope="col">
                      withholding tax
                    </th>
                  ) : (
                    <></>
                  )}
                  <th className="payment-col" scope="col">
                    Payment
                  </th>
                  <th className="forex_gain_loss" scope="col">
                    Forex Gain/Loss
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.formik.values.unpaid_invoice_lines?.length > 0 ? (
                  <>
                    {props.formik.values.unpaid_invoice_lines.map(
                      (unpaidInvoice: any, index: number) => {
                        return (
                          <CustomerUnpaidInvoice
                            index={index}
                            item={unpaidInvoice}
                            currencyCode={props.formik.values.currency_code}
                            formik={props.formik}
                            handlePaymentChange={props.formik.handleChange}
                            errors={props.formik.errors.unpaid_invoice_lines}
                          />
                        );
                      }
                    )}
                  </>
                ) : (
                  <tr>
                    <td
                      className="no-item"
                      // colSpan={
                      //   props.formik.values.is_tax_deducted === "true" ||
                      //   props.formik.values.is_tax_deducted === true
                      //     ? 7
                      //     : 6
                      // }
                      colSpan={calculateThCount()}
                    >
                      There are no unpaid invoices for this customer
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <span className="error error_total total-unpaid-error">
              {props.formik.errors.total?.toString()}
            </span>
          </div>
          <div className="row mb-0 g-0 calc-row">
            <NoteTextArea
              label="Notes (not visible to customer)"
              name="notes"
              id="customerNotes"
              className="notes-textarea"
              placeholder="Enter notes here..."
              value={props.formik.values.notes}
              onChange={props.formik.handleChange}
              formErrors={props.formik.errors}
              isDisabled={!props.formik.values.customer_id}
            />
            <TotalDetails formik={props.formik} />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};
