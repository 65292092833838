import React, { useEffect, useState } from "react";
// import { InfoIconImg } from "../../../../assets/images";
import {
  ObjectType,
  TCSTotalValues,
  TDSTotalValues,
  TransactionItemValues,
} from "../../../../../types";
import CustomTooltips from "../../../../common/components/Tooltip";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
// import "../../../../index.css";
// import "./TotalSection.css";
import { useParams } from "react-router-dom";
import {
  TAX_SYSTEM,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../../constants/constants";
import { InfoIconImg } from "../../../../../assets/images";
import EditPopup from "../../../../common/components/TotalSection/editPopup/EditPopup";
import { colors } from "../../../../constants/colors";
import { EditIcon } from "../../../../common/components/customSvgIcons";
import useCommonData from "../../../../hooks/useCommon";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import { useAppSelector } from "../../../../../app/hooks";
import useGst from "../../../../hooks/useGst";
import SelectTDSAndTCS from "../../../../common/components/lineItems/SelectTDSAndTCS";
import { SubscriptionAddonDetails, SubscriptionPlanDetails } from "../../subscriptionType";

type Props = {
  totalTaxArray: any[];
  currencyCode: string | undefined;
  subTotal: number | string;
  totalTax: number | string;
  couponDiscountValue:  number | string;
  total: number | string;
  componentType: string;
  tdsAmount: number | string;
  tcsAmount: number | string;
  formErrors: ObjectType;
  handleOnBlur: (name: string, amount: number, index: number) => void;
  handleAmountChange: (
    name: string,
    e: any,
    index: number,
    taxType: string
  ) => void;
  totalTdsValues: TDSTotalValues;
  tdsLevel: string;
  handleTcsAmountChange: (e: any, index: number) => void;
  totalTcsValues: TCSTotalValues;
  resetTaxValues: () => void;
  sourceTaxType?: string;
  clearTaxSelection: (index: number) => void;
  itemPlanFormValues: SubscriptionPlanDetails;
  itemAddonFormValues: SubscriptionAddonDetails[];
  tdsIds: number[];
  tcsIds: number[];
  gstTotalObject: ObjectType;
  handleTotalTcsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  handleTotalTdsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  roundOffValue?: number | null;
  isRoundOffEnabled?: boolean;
  isTotalEditable?: boolean;
  updateTotal?: (val: number) => void;
  tdsPreferencetoCheck: string
};
export default function SubscriptionTotalSection(props: Props) {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const [taxType, setTaxType] = useState("TCS");
  const { editId } = useParams();
  const { isGstOrg } = useGst();
  const [showTotalEdit, setShowTotalEdit] = useState(false);
  const [tdsTcsErrors, setTdsTcsErrors] = useState({
    tds_name: "",
    tcs_name: "",
  });
  const organization_tax_system = currentUserInfo.organization_tax_system;

  useEffect(() => {
    if (editId 
      && props.sourceTaxType === sourceTaxTypes.TDS 
      && props.tdsPreferencetoCheck === "TRANSACTION_LEVEL") {
      setTaxType("TDS");
    }
  }, [editId, props.sourceTaxType]);

  useEffect(() => {
    setTdsTcsErrors({
      tds_name: props.formErrors.tds_name,
      tcs_name: props.formErrors.tcs_name,
    });
  }, [props.formErrors]);

  const getTdsPreference = () => {
    if (!tdsPreference.is_enabled) {
      return false;
    }
    if (
      props.componentType === "invoice" ||
      props.componentType === "creditNote"
    ) {
      if (
        tdsPreference.support_tds_for.includes("Customers") &&
        tdsPreference.apply_tds_at === "Transaction level"
      ) {
        return true;
      }
    }
    if (props.componentType === "bill" || props.componentType === "debitNote") {
      if (
        tdsPreference.support_tds_for.includes("Vendors") &&
        tdsPreference.apply_tds_at === "Transaction level"
      ) {
        return true;
      }
    }
    return false;
  };

  const isTdsAtTransactionLevelEnabled = () => {
    if (editId) {
      if (!tdsPreference.is_enabled && props.tdsLevel === tdsApplyLevel.TRANSACTION_LEVEL) return true
      else return props.tdsPreferencetoCheck === "TRANSACTION_LEVEL" && tdsPreference.is_enabled
    } else {
      return getTdsPreference();
    }
  };

  const checkSupportTDS = () => {
    if (
      props.componentType === "invoice" ||
      props.componentType === "creditNote"
    ) {
      if (tdsPreference.support_tds_for.includes("Customers")) {
        return true;
      }
    }
    if (props.componentType === "bill" || props.componentType === "debitNote") {
      if (tdsPreference.support_tds_for.includes("Vendors")) {
        return true;
      }
    }
    return false;
  };

  const isTdsEnabledAtLineLevel = () => {
    if (editId) {
      if (!tdsPreference.is_enabled && props.tdsLevel === tdsApplyLevel.LINE_ITEM_LEVEL) return true
      else return props.tdsPreferencetoCheck === "LINE_LEVEL" && tdsPreference.is_enabled
}
    if (
      tdsPreference.is_enabled &&
      tdsPreference.apply_tds_at === "Line item level" &&
      checkSupportTDS()
    ) {
      return true;
    }
    return false;
  };

  const radioInputChangeHandler = (e: any) => {
    setTaxType(e.target.name);
    setTdsTcsErrors({
      tds_name: "",
      tcs_name: "",
    });
    props.resetTaxValues();
  };

  const isTcsLabelEnabled = () => {
    if (editId) {
      if (!tdsPreference.is_enabled && props.tdsLevel === tdsApplyLevel.NO_TDS) return true
       else return props.tdsPreferencetoCheck === "LINE_LEVEL"
}
    if (!tdsPreference.is_enabled) {
      return true;
    }
    if (
      tdsPreference.apply_tds_at === "Line item level" ||
      !checkSupportTDS()
    ) {
      return true;
    }
    return false;
  };

  const isTcsDisabled = () => {
    const tdsPlanArray = props.itemPlanFormValues.itemDetails ? props.itemPlanFormValues.itemDetails
      .filter((item: any) => item.tds_id)
      .map((item: any) => item): [];
    
      let tdsAddonArray: any[] = []
      props.itemAddonFormValues.map((eachAddon) => eachAddon.itemDetails.filter((item) => item.tds_id)
      .forEach((item) => tdsAddonArray.push(item)))
      
    return tdsPlanArray.length !== 0 || tdsAddonArray.length !== 0;
  };

  const editTotal = () => {
    setShowTotalEdit(true);
  };

  const updateTotal = (updatedValue: any) => {
    if (props.updateTotal) {
      props.updateTotal(updatedValue);
      setShowTotalEdit(false);
    }
  };

  return (
    <div className="col right-details">
      <div className="row total-wrapper g-0">
        <div className="d-flex justify-content-between">
          <div className="sub-total-label">Total Taxable Amount </div>
          <div className="text-start sub-total">
            <span>{props.currencyCode + " "}</span>
            <span id="sub-total">
              {NumberFormat(
                Number(props.subTotal),
                props.currencyCode
                  ? props.currencyCode
                  : currentUserInfo.currency_code,
                orgCurrencyList
              )}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-start discount-label">
            Total Tax
            <CustomTooltips title="Total Tax" placement="right">
              <a
                href="#"
                className="info-btn position-relative"
                onClick={(e) => e.preventDefault()}
              >
                <img src={InfoIconImg} width="16" alt="info icon" />
              </a>
            </CustomTooltips>
          </div>
          <div className="text-start discount-amount">
            <span>{props.currencyCode + " "}</span>
            <span id="total-tax">
              {NumberFormat(
                Number(props.totalTax),
                props.currencyCode
                  ? props.currencyCode
                  : currentUserInfo.currency_code,
                orgCurrencyList
              )}
            </span>
          </div>
        </div>
        {/* GST Display starts */}
        {Object.keys(props.gstTotalObject).length > 0 &&
          Object.keys(props.gstTotalObject).map((gst, index) => {
            return (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-start discount-label gst-label">
                    {gst}
                  </div>
                  <div className="text-start discount-amount gst-amount">
                    <span>{props.currencyCode + " "}</span>
                    <span id="total-tax">
                      {NumberFormat(
                        Number(Object.values(props.gstTotalObject)[index]),
                        props.currencyCode
                          ? props.currencyCode
                          : currentUserInfo.currency_code,
                        orgCurrencyList
                      )}
                    </span>
                  </div>
                </div>
              </>
            );
          })}
        {/* GST Display ends */}
        {!isGstOrg && (
          <>
            {Object.keys(props.totalTaxArray).map((key, index) => {
              return key ||
                props.totalTaxArray[key as keyof typeof props.totalTaxArray] ? (
                <div
                  className="d-flex justify-content-between align-items-center"
                  key={index}
                >
                  <div className="discount-label c-gst-label">{key}</div>
                  <div className="discount-amount c-gst-amount">
                    {(key !== "" ? props.currencyCode + " " : "") +
                      String(
                        NumberFormat(
                          Number(
                            props.totalTaxArray[
                              key as keyof typeof props.totalTaxArray
                            ]
                          ),
                          props.currencyCode
                            ? props.currencyCode
                            : currentUserInfo.currency_code,
                          orgCurrencyList
                        )
                      )}
                  </div>
                </div>
              ) : null;
            })}
          </>
        )}
        {((isTdsEnabledAtLineLevel() &&
          organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM) ||
          (props.componentType === "debitNote" &&
            isTdsAtTransactionLevelEnabled())) && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-start discount-label">TDS</div>
            {!isTdsAtTransactionLevelEnabled() && (
              <div className="text-start discount-amount">
                {Number(props.tdsAmount) > 0 && "-"}
                <span>{props.currencyCode + " "}</span>
                <span id="total-tax">
                  {NumberFormat(
                    Number(props.tdsAmount),
                    props.currencyCode
                      ? props.currencyCode
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )}
                </span>
              </div>
            )}
          </div>
        )}
        {isTcsLabelEnabled() &&
          organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
          props.componentType !== "debitNote" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-start discount-label">TCS</div>
            </div>
          )}
        <>
          {isTdsAtTransactionLevelEnabled() &&
            organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
            props.componentType !== "debitNote" && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="tds-tcs-radio-button-wrapper d-flex">
                  <div className="form-check tds-tcs-radio-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="TDS"
                      id="inlineRadio1"
                      value="tds"
                      checked={taxType === "TDS"}
                      onChange={radioInputChangeHandler}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      TDS
                    </label>
                  </div>
                  <div className="form-check tds-tcs-radio-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="TCS"
                      id="inlineRadio2"
                      value="tcs"
                      checked={taxType === "TCS"}
                      onChange={radioInputChangeHandler}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      TCS
                    </label>
                  </div>
                </div>
              </div>
            )}
          {((props.componentType === "debitNote" &&
            isTdsAtTransactionLevelEnabled()) ||
            props.componentType !== "debitNote") &&
            organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM && (
              <div
                className="d-flex justify-content-between align-items-center"
                style={
                  isTcsDisabled()
                    ? { pointerEvents: "none", opacity: "0.5" }
                    : {}
                }
              >
                <div className="discount-label">
                  <SelectTDSAndTCS  // need to check
                    id="tds-select"
                    inputId="tds-entry"
                    selectPlaceholderText={
                      taxType === "TDS" ? "Choose TDS type" : "Choose TCS type"
                    }
                    inputPlaceHolder="Enter amount"
                    index={-1}
                    item={{
                      tds_id: props.totalTdsValues.tds_id,
                      tds_amount: props.totalTdsValues.tds_amount,
                      tds_rate: props.totalTdsValues.tds_percentage,
                      tds_name: props.totalTdsValues.tds_name,
                      tcs_id: props.totalTcsValues.tcs_id,
                      tcs_amount: props.totalTcsValues.tcs_amount,
                      tcs_rate: props.totalTcsValues.tcs_percentage,
                      tcs_name: props.totalTcsValues.tcs_name,
                    }}
                    handleAmountChange={props.handleAmountChange}
                    handleOnBlur={props.handleOnBlur}
                    taxType={taxType}
                    clearTaxSelection={props.clearTaxSelection}
                    tdsIds={props.tdsIds}
                    tcsIds={props.tcsIds}
                    handleTdsLabelChange={props.handleTotalTdsLabelChange}
                    handleTotalTcsLabelChange={props.handleTotalTcsLabelChange}
                    applyTdsAt={"transaction_level"}
                    error={null}
                    hasError={
                      tdsTcsErrors.tds_name || tdsTcsErrors.tcs_name
                        ? true
                        : false
                    }
                  />
                  <span className="error">
                    {tdsTcsErrors.tds_name} {tdsTcsErrors.tcs_name}{" "}
                    {props.formErrors.tds_amount}
                  </span>
                </div>
                <div className="text-start discount-amount">
                  {props.currencyCode + " "}
                  {props.tdsAmount && taxType === "TDS"
                    ? NumberFormat(
                        Number(props.tdsAmount),
                        props.currencyCode
                          ? props.currencyCode
                          : currentUserInfo.currency_code,
                        orgCurrencyList
                      )
                    : props.tcsAmount
                    ? NumberFormat(
                        Number(props.tcsAmount),
                        props.currencyCode
                          ? props.currencyCode
                          : currentUserInfo.currency_code,
                        orgCurrencyList
                      )
                    : zeroDisplayFormat(
                        decimalPlaceOfCurrency(
                          props.currencyCode
                            ? props.currencyCode
                            : currentUserInfo.currency_code,
                          orgCurrencyList
                        )
                      )}
                </div>
              </div>
            )}
        </>
        <div className="d-flex justify-content-between align-items-center">
            <div className="text-start discount-label">Coupon</div>
            <div className="text-start discount-amount">
              <span>{(Number(props.couponDiscountValue ? props.couponDiscountValue : 0) !== 0 ? "-" : "") + " "  + props.currencyCode + " "}</span>
              <span id="total-tax">
                {NumberFormat(
                  Number(props.couponDiscountValue ? props.couponDiscountValue : 0),
                  props.currencyCode
                    ? props.currencyCode
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
          </div>
        {props.isRoundOffEnabled && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-start discount-label">Round Off</div>
            <div className="text-start discount-amount">
              <span>{(props.roundOffValue && Number(props.roundOffValue) > 0 ? '+' : Number(props.roundOffValue) === 0 ? "" : '-') + props.currencyCode + " "}</span>
              <span id="total-tax">
                {NumberFormat(
                  Number(props.roundOffValue ? Math.abs(props.roundOffValue) : 0),
                  props.currencyCode
                    ? props.currencyCode
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="total-label">Total</div>
        <div className="text-start sum-total">
          <span>{props.currencyCode + " "}</span>
          <span id="sum-total">
            {NumberFormat(
              Number(props.total),
              props.currencyCode
                ? props.currencyCode
                : currentUserInfo.currency_code,
              orgCurrencyList
            )}
          </span>
          {props?.isTotalEditable ? (
            <>
              <div
                className="border-start-0 position-relative"
                style={{
                  display: "inline-block",
                  transform: "translateY(-4px)",
                  marginLeft: "5px",
                }}
              >
                <div onClick={editTotal}>
                  <EditIcon
                    color={colors.ceruleanBlue}
                    height="12px"
                    width="12px"
                  />
                </div>
                {showTotalEdit ? (
                  <EditPopup
                    title="Edit Total"
                    inputType="text"
                    currentValue={Number(props.total)}
                    maxLimit={10}
                    inputId="edit-total"
                    label=""
                    saveAction={updateTotal}
                    error=""
                    cancelAction={() => setShowTotalEdit(false)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
