import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrency, updateCurrencies } from "./currencyConfigurationAPI";

export const updateCurrency = createAsyncThunk(
  "update-currency",
  async (data: {
    values: {
      name: string | null;
      symbol: string | null;
      format: string | null;
    };
    id: number;
    orgId: number;
  }) => {
    return await updateCurrencies(data);
  }
);
export const getCurrencies = createAsyncThunk(
  "get-currency",
  async (data: { orgId: number }) => {
    return await getCurrency(data);
  }
);
