import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { getFormatedDate } from "../../helpers/helper";
import { CurrencyList } from "../../types";
import {
  getCustomConversions,
  getLatestCurrencyRate,
  setExchangeApiSatus,
} from "../common/commonSlice";
import { conversionMinDate } from "../constants/constants";

type Props = {
  currencyId: number | undefined; // latest currency of the transaction
  currencyCode: string | undefined;
  editId: string | undefined; // curresponding transaction id, eg: invoice id
  conversionId: string | number; // saved conversion id, only have value in edit section
  conversionCurrencyId?: string | number; // currency of already saved conversion
  conversionDate: string; // date of already saved conversion
  newConversionDate: string; // new date for conversion
  module?: string; // to apply style based on module
  toCurrencyId: number | null | undefined; //currency to which conversion is needed
  byUser: boolean; //flag to check whether the rate is user entered
  isCurrencyChanged: boolean; //flag to check whether the to or from currency is changed
  isDateChanged: boolean; //flag to check whether date is changed to fetch latest  currency
  setExchangeData: (values: CurrencyList) => void; //function to set exchange rate in the parent
};

export const useExchangeRate = (props: Props) => {
  const dispatch = useAppDispatch();
  const [exchangeRateValues, setExchangeRateValues] = useState<CurrencyList>({
    base_currency: "",
    base_currency_id: 0,
    by_user: false,
    id: 0,
    rate: 0,
    timestamp: "",
    to_currency: "",
    to_currency_id: 0,
  });
  const isPropsReady = () => {
    if (props.editId && props.conversionId) {
      return props.currencyId &&
        props.toCurrencyId &&
        props.conversionDate &&
        props.editId &&
        props.conversionId
        ? true
        : false;
    } else if (props.byUser) {
      return true;
    } else if (
      (!props.editId && !props.conversionId) ||
      props.isCurrencyChanged
    ) {
      return props.currencyId && props.toCurrencyId && props.conversionDate
        ? true
        : false;
    } else if (props.isDateChanged) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Fetch conversion rate
   */
  const getLatestCurrencyConversion = async () => {
    if (
      props.currencyId &&
      props.currencyId !== 0 &&
      props.toCurrencyId !== 0 &&
      (props.newConversionDate || props.conversionDate) &&
      !(
        new Date(props.newConversionDate).toString() === "Invalid Date" ||
        new Date(props.conversionDate).toString() === "Invalid Date"
      )
    ) {
      if (props.newConversionDate) {
        if (new Date(props.newConversionDate) < new Date(conversionMinDate)) {
          return;
        }
      } else if (new Date(props.conversionDate) < new Date(conversionMinDate)) {
        return;
      }

      dispatch(setExchangeApiSatus(true));
      const responseAction = await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(props.currencyId),
          to_currency: Number(props.toCurrencyId),
          date: props.newConversionDate
            ? new Date(props.newConversionDate) > new Date()
              ? getFormatedDate()
              : props.newConversionDate
            : new Date(props.conversionDate) > new Date()
            ? getFormatedDate()
            : props.conversionDate,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        dispatch(setExchangeApiSatus(false));
        setExchangeRateValues(response.latest_conversion);
        props.setExchangeData(response.latest_conversion);
      }
    }
  };
  /**
   * Fetch convesion details by conversion id
   */
  const fetchCustomConversions = async (id: number) => {
    if (id) {
      dispatch(setExchangeApiSatus(true));
      const customConversionResponse = await dispatch(
        getCustomConversions({
          conversionId: id,
        })
      );
      const response = customConversionResponse.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setExchangeRateValues((prevExchangeRate) => {
          return { ...prevExchangeRate, ...response.latest_conversion };
        });
        props.setExchangeData({ ...response.latest_conversion });
        dispatch(setExchangeApiSatus(false));
      }
    }
  };
  useEffect(() => {
    if (isPropsReady()) {
      // if (
      //   (props.editId !== "" &&
      //     props.conversionId !== "" &&
      //     !props.isCurrencyChanged &&
      //     !props.isDateChanged) ||
      //   (props.byUser === true &&
      //     props.conversionId !== "" &&
      //     props.isDateChanged === false)
      // ) {
      //   fetchCustomConversions(Number(props.conversionId));
      // } else if (props.isCurrencyChanged || props.isDateChanged) {

        // getLatestCurrencyConversion();
      // }
    } else {
      setExchangeRateValues({
        base_currency: "",
        base_currency_id: 0,
        by_user: false,
        id: 0,
        rate: 0,
        timestamp: "",
        to_currency: "",
        to_currency_id: 0,
      });
    }
  }, [
    // props.conversionId,
    // props.conversionDate,
    // props.newConversionDate,
    // props.currencyId,
    // props.toCurrencyId,
    // props.editId,
    // props.conversionCurrencyId,
    // props.byUser,
    // props.isCurrencyChanged,
    // props.isDateChanged,
  ]);
  return { fetchCustomConversions, exchangeRateValues };
};
