import React from "react";
import "../styles/EmptyReport.css";
import { EmptyList } from "../../../../src/assets/images";

type Props = {
  heading: string;
  alt: string;
};

const EmptyReport: React.FC<Props> = (props) => {
  return (
    <div className="empty-report-container align-center">
      <img
        className="preview-img"
        src={EmptyList}
        alt={props.alt}
        width="60"
        height="68"
      />
      <h3>{props.heading}</h3>
    </div>
  );
};

export default EmptyReport;
