import React from "react";
import {
  SaveButton,
  SecondaryButton,
} from "../../../../../../../common/components";
import { Container } from "../../../../../../../common/components/htmlTags/Container";
import "./ApplyCreditFormFooter.css";

type Props = {
  onSave: () => void;
  onCancel: () => void;
  isSaveDisabled: boolean;
};
export const ApplyCreditButtonGroup = (props: Props) => {
  return (
    <Container className="form-button-wrapper apply-to-invoice-btn-group w-100 invoice-allocation-btn">
      <SaveButton
        type="submit"
        onClick={props.onSave}
        buttonContent="Save"
        isDisabled={props.isSaveDisabled}
      />
      <SecondaryButton
        type="button"
        onClick={props.onCancel}
        buttonContent="Cancel"
        isDisabled={false}
      />
    </Container>
  );
};
