import * as yup from "yup";

export const preferenceValidation = yup.object({
  account_id: yup
    .mixed()
    .nullable()
    .test({
      name: "account_id",
      message: "Please select the account",
      test: function (value) {
        const is_enabled = this.options.context?.is_enabled;

        if (is_enabled === "Yes" && value === "Invalid Date") {
          return false;
        }
        return true;
      },
    })
});
