import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { OrgValues } from "./../../../types";

/**
 * API call for listing organization
 */
export async function getHierarchyList(currentOrgId: number) {
  const result = await get(
    Api.organizationHierarchy +
      `/get-hierarchy?current_organization=${currentOrgId}`
  );
  return result;
}

/**
 * API call for creating sub organization
 */
export async function createSubOrganization(data: {
  parentId: number;
  values: OrgValues;
  currentOrgId: number;
}) {
  const result = await post(
    Api.organizationHierarchy +
      `/create-sub-organiztaion?current_organization=${data.currentOrgId}&parent_id=${data.parentId}`,
    data.values
  );
  return result;
}

/**
 * API call for editing sub organization
 */
export async function editSubOrganization(data: {
  orgId: number;
  values: OrgValues;
  currentOrgId: number;
}) {
  const result = await put(
    Api.organizationHierarchy +
      "/edit-sub-organization/" +
      data.orgId +
      `?current_organization=${data.currentOrgId}`,
    data.values
  );
  return result;
}

/**
 * API call for deleting sub organization
 */
export async function deleteSubOrganization(data: {
  orgId: number;
  currentOrgId: number;
}) {
  const result = await remove(
    Api.organizationHierarchy +
      "/delete-sub-organization/" +
      data.orgId +
      `?current_organization=${data.currentOrgId}`
  );
  return result;
}

/**
 * API call for checking if organization name exists
 */
export async function checkOrganizationNameExists(data: {
  name: string;
  signal: AbortSignal;
}) {
  const result = await get(
    Api.organization + `organization-exist?organization_name=${data.name}`,
    data.signal
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.organizationHierarchy +
      `/transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to set the onboarding status
 */
export async function organizationSetupStatusSetter(data: {
  orgId: number;
  status: boolean;
}) {
  const result = await post(
    Api.organizationHierarchy +
      `/set-onboarding-status?current_organization=${data.orgId}&onboarding_status=${data.status}`
  );
  return result;
}
/**
 * Api to get the remaining account setup time
 */
export async function remainingTimeStatus(orgId: number) {
  const result = await get(
    Api.organizationHierarchy +
      `/get-remaining-time?current_organization=${orgId}`
  );
  return result;
}
