import React from "react";
import { TableData, Transactionlimit } from "../../../../../../types";
import TableInfo from "../../../../../common/components/table/TableInfo";
import TableContainer from "../../../../../common/components/table/TableContainer";
import EmptyList from "../../../../../common/components/EmptyList";
import { InvoiceImage } from "../../../../../../assets/images";
import FilterEmptyList from "../../../../../common/components/FilterEmptyList";

type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  debitNoteRolePermission: any;
  columns: any[];
  data: TableData[];
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
  setPageInfo: (countPerPage: number, page: number) => void;
  sortHandler: (column: string) => void;
  hiddenColumns: string[];
};
function DebitNoteTable({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  debitNoteRolePermission,
  columns,
  data,
  isFilterApplied,
  initializeFilter,
  transactionLimit,
  setPageInfo,
  sortHandler,
  hiddenColumns,
}: Props) {
  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className={
          "list-table-wrapper creditnote-list-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
          `${totalList > 0 ? "" : "no-data"}`
        }
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={setPageInfo}
        />
        <div className="row">
          <div className="col-md-12">
            <TableContainer
              className="list-table cd-note-table table table-striped cd-note-list-table w-100"
              columns={columns}
              data={data}
              url={"/debit-note"}
              sortHandler={sortHandler}
              editPermission={debitNoteRolePermission.includes("Update")}
              isDetailViewExist={true}
              hiddenColumns={hiddenColumns}
            >
              {!isFilterApplied ? (
                <EmptyList
                  heading={
                    debitNoteRolePermission.includes("Create")
                      ? "Create new debit note!"
                      : "No Debit notes found!"
                  }
                  subheading={
                    debitNoteRolePermission.includes("Create")
                      ? "Apply debit notes to bills and reduce the Bill amount."
                      : "Oops, There is no Debit note data available right now"
                  }
                  list="Debit Notes"
                  alt="create debit note preview"
                  buttonText="Add Debit Note"
                  navigateLink="/debit-note/create"
                  isCrateAccess={debitNoteRolePermission.includes("Create")}
                  icon={InvoiceImage}
                  transactionLimit={transactionLimit.limit_exceed}
                />
              ) : (
                <FilterEmptyList
                  list="debit notes"
                  icon={InvoiceImage}
                  alt="list dn empty"
                  handleReset={initializeFilter}
                />
              )}
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebitNoteTable;
