import { createContext, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import {
  defaultPreferenceSelector,
  getGstTaxList,
  gstTaxListParamsSelector,
  gstTaxListSelector,
} from "../../../../home/gst/gstSlice";
import useCommonData from "../../../../hooks/useCommon";
import _ from "lodash";
import { exemptionDropdown } from "../../../../home/items/itemSlice";
import { ObjectType } from "../../../../../types";

const GstSelectContext = createContext({
  gstTaxList: [] as any[],
  exemptionDropdownList: [] as any[],
  fetchGstTaxList: (stateType: string) => {},
  fetchExemptionList: () => {},
  setGstStateType: (state: string) => {},
  defaultPreference: {} as ObjectType,
  stateType: "",
});

type Props = {
  children: React.ReactNode;
};

export const GstSelectProvider = ({ children }: Props) => {
  const gstTaxListParams = useAppSelector(gstTaxListParamsSelector);
  const [gstDropdownList, setGstDropdownList] = useState<any[]>([]);
  const [exemptionDropdownList, setexemptionDropdownList] = useState<any[]>([]);
  const { dispatch, currentUserInfo } = useCommonData();
  const [stateType, setGstStateType] = useState<string>("");
  const defaultPreference = useAppSelector(
    defaultPreferenceSelector
  ) as ObjectType;

  useEffect(() => {
    if (stateType) {
      fetchGstDropdownList(stateType);
    }
    fetchExemptionDropwonList();
  }, [currentUserInfo.organization_id]);

  const fetchGstDropdownList = async (stateType: string) => {
    let response = await dispatch(
      getGstTaxList({
        stateType: stateType,
        orgId: currentUserInfo.organization_id,
        stateCode: gstTaxListParams.state_code,
        transactionSource: "Invoices",
      })
    );
    let taxList = response.payload;
    taxList = _.orderBy(taxList, ["tax_group"], ["asc"]);
    if (taxList[0] && taxList[0].tax_group === false) {
      taxList.unshift({ id: 0, name: "Taxes", is_label: true });
    }
    let group_start_index = taxList.findIndex(
      (tax: any) => tax.tax_group === true
    );
    if (group_start_index !== -1) {
      taxList.splice(group_start_index, 0, {
        id: 0,
        name: "Tax Group",
        is_label: true,
      });
    }
    setGstDropdownList(taxList);
    return taxList;
  };
  const fetchExemptionDropwonList = async () => {
    let response = await dispatch(
      exemptionDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
    setexemptionDropdownList(response.payload);
  };

  return (
    <GstSelectContext.Provider
      value={{
        gstTaxList: gstDropdownList,
        exemptionDropdownList: exemptionDropdownList,
        fetchGstTaxList: fetchGstDropdownList,
        fetchExemptionList: fetchExemptionDropwonList,
        setGstStateType: setGstStateType,
        defaultPreference: defaultPreference,
        stateType: stateType,
      }}
    >
      {children}
    </GstSelectContext.Provider>
  );
};

export const useGstSelect = () => {
  return useContext(GstSelectContext);
};
