import React from "react";
import { SaveButton } from "../../../../../../common/components/button/SaveButton";
import "./UserTransactionLimitModal.css";

type Props = {
  handleClose: () => void;
  message: string;
};
const UserTransactionLimitModal = (props: Props) => {
  return (
    <div className="user-transaction-limit-wrap">
      <div>
        <p className="user-transaction-main-alert-message">{props.message}</p>
      </div>
      <div className="user-transaction-limit-btn-group">
        <SaveButton
          type="button"
          isDisabled={false}
          buttonContent="Ok"
          onClick={props.handleClose}
          buttonClassName="transaction-limit-ok-button"
        />
      </div>
    </div>
  );
};

export default UserTransactionLimitModal;
