import React, { useCallback, useEffect, useState } from "react";
import DefaultDetails from "./DefaultDetails";
import TransactionHeader from "./TransactionHeader";
import { useParams } from "react-router-dom";
import { setLoaderState } from "../../../../common/commonSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { priceGetEditDetails, planDetails, checkPriceInternalNameExist } from "../../planSlice";
import useCommonData from "../../../../hooks/useCommon";
import { PlanDetailData } from "../../planType";
import { planDetailInitialData } from "../../PlanStateInitialization";

function PriceForm() {
  const { frequency, planId, priceId } = useParams();
  const [planDetailData, setPlanDetailData] = useState<PlanDetailData>(planDetailInitialData)
  const [priceDetails, setPriceDetails] = useState<any[]>([]);
  const plan_id = parseInt(planId ? planId : "");
  const { currentUserInfo } = useCommonData();
  const dispatch = useAppDispatch();
  let interval: any = null;
  const [isInternalNameExist, setIsInternalNameExist] = useState(false);

  const fetchPricingDetails = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      priceGetEditDetails({ priceId: parseInt(priceId ? priceId : "") , orgId: currentUserInfo.organization_id})
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        setPriceDetails(response);
      } else if ("error" in response) {
      }
    }
  };

  const fetchPlanDetails = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      planDetails({
        planId: Number(planId),
        orgId: currentUserInfo.organization_id,
      })
    );

    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 1000);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setPlanDetailData(response);
      } else { }
    }
  };

  useEffect(() => {
    if (priceId) {
      fetchPricingDetails();
    }
  }, [priceId]);

  useEffect(() => {
    if (planId && !priceId) {
      fetchPlanDetails()
    }
  }, [planId]);

  function capitalize(str: string | undefined) {
    if (str)
      return str.charAt(0).toUpperCase() + str.slice(1);
  }


  const checkPriceInternalName = async (internalName: string) => {
    if (!internalName.includes("undefined")) {
        if (interval) {
            clearTimeout(interval);
        }
        interval = setTimeout(async () => {
            const responseAction = await dispatch(
              checkPriceInternalNameExist({
                    internalName: internalName,
                    orgId: currentUserInfo.organization_id,
                    plan_id: planId  ? Number(planId) : 0,
                    pricing_id: priceId ? priceId : ''
                })
            );
            if (responseAction.payload) {
                const response = responseAction.payload;
                if (Object.keys(response).length && !("error" in response)) {
                    if (response.internal_name_available === false) {
                        setIsInternalNameExist(true);
                    } else {
                        setIsInternalNameExist(false);
                    }
                }
            }
        }, 1000);
    }
};

  return (
    <div
      className="pricing-form card card-user-management card-customer card-create-item invoice-create main-card overflowX-hidden h-100"
      id="invoice-create"
    >
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <TransactionHeader
          transactionName={`${priceId ? "Edit Pricing Details" : "Create Pricing Details"
            } - ${capitalize(frequency)}`}
          transactionNumber={0}
          handleSavePreference={() => { }}
          handleSaveRoundOff={() => { }}
          planId={planId}
        />
      </div>
      <div className="card-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          name="add_invoice_form"
          id="add_invoice_form"
          className="add-module-item-form"
          tabIndex={-1}
        >
          <div className="fields-wrapper pt-0">
            <div className="fields-wrappers">
              <DefaultDetails
                organizationId={1}
                frequency={frequency}
                planId={plan_id}
                priceDetails={priceDetails}
                priceId={priceId}
                planDetailData={planDetailData}
                checkInternalName={checkPriceInternalName}
                isInternalNameExist={isInternalNameExist}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PriceForm;
function dispatch(arg0: { payload: any; type: string }) {
  throw new Error("Function not implemented.");
}
