import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SubAccountValues } from "../../../../../../../types";
import { initialSubAccountValues } from "../../../../../paymentsMade/components/createBillPayment/StateInitialization";
import { customSelectStyle } from "../../../../../../common/components/SelectCustomStyle";
import { subAccountsOfParent } from "../../../../../chartOfAccounts/coaSlice";
import useCommonData from "../../../../../../hooks/useCommon";
import { RadioButtonGroup } from "../../../../../../common/components/radioButtonGroup/radioButtonGroup";
import { FormikProps } from "formik";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { colors } from "../../../../../../constants/colors";

type Props = {
  formik: FormikProps<any>;
};
export const PaymentTax = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [TDSCustomerSubAccount, setTDSCustomerSubAccount] = useState<
    SubAccountValues[]
  >([initialSubAccountValues]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    getTDSCustomerAccounts();
  }, [currentUserInfo.organization_id]);
  useEffect(() => {
    Array.isArray(props.formik.values.unpaid_invoice_lines) &&
      props.formik.setFieldValue(
        "unpaid_invoice_lines",
        props.formik.values.unpaid_invoice_lines.map((unpaidInvoice: any) => {
          return {
            ...unpaidInvoice,
            withholding_tax: 0,
          };
        })
      );
  }, [props.formik.values.is_tax_deducted]);
  /**
   * Get Currents Assets accounts list drop down Api
   */
  const getTDSCustomerAccounts = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["TDS-Customers", "Loans & Advances"],
        orgId: currentUserInfo.organization_id,
        transactionType: "Payments Received",
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTDSCustomerSubAccount(response);
      } else {
        setTDSCustomerSubAccount([]);
      }
    }
  };
  /**
   * Get the list of all Current Asset sub accounts
   */
  const TDSCustomerSubAccounts = TDSCustomerSubAccount.map((subAccount) => ({
    label: subAccount.account_name,
    value: subAccount.id,
    account_type: subAccount.account_type,
  }));

  const LabelWithRoot = ({ data, isFocused, isSelected }: any) => (
    <div
      onClick={(e) => {
        props.formik.setFieldValue("tax_account_id", data && data.value);

        props.formik.setFieldValue("tax_account_name", data && data.label);
        setMenuIsOpen(false);
      }}
      className="custom-select-option-wrapper"
      style={{
        backgroundColor: isFocused || isSelected ? colors.whisper : "white",
      }}
    >
      <span className="custom-select-option-account-name">{data.label}</span>
      {data.account_type && (
        <div className="custom-select-option-account-root">
          {data.account_type}
        </div>
      )}
    </div>
  );
  return (
    <>
      <div
        className={`col-12 col-lg-4 mw-325 radio-button-wrap  ${
          !props.formik.values.customer_id ? "disabled-item" : ""
        }`}
      >
        <label htmlFor="amt_paid" className="mb-2">
          Tax deducted?
        </label>
        <RadioButtonGroup
          value={props.formik.values.is_tax_deducted}
          name="is_tax_deducted"
          id="is_tax_deducted"
          valuesArray={[
            { value: "false", label: "No tax deducted" },
            { value: "true", label: "Yes, TDS" },
          ]}
          formik={props.formik}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon sx={{ color: colors.ceruleanBlue }} />
          }
          isDisabled={!props.formik.values.customer_id}
        />
      </div>
      {props.formik.values.is_tax_deducted === "true" ||
      props.formik.values.is_tax_deducted === true ? (
        <div
          className={`col-12 col-lg-4 mw-325  ${
            !props.formik.values.customer_id ? "disabled-item" : ""
          }`}
        >
          <label htmlFor="tax_category">Tax Account</label>
          <div className="form-group">
            <div>
              <Select
                name="tax_category"
                id="tax_category"
                value={
                  props.formik.values.tax_account_name !== "" &&
                  props.formik.values.tax_account_id
                    ? {
                        value: props.formik.values.tax_account_id,
                        label: props.formik.values.tax_account_name,
                      }
                    : null
                }
                options={TDSCustomerSubAccounts && TDSCustomerSubAccounts}
                placeholder="Choose account"
                onChange={(e) => {
                  props.formik.setFieldValue("tax_account_id", e && e.value);

                  props.formik.setFieldValue("tax_account_name", e && e.label);
                }}
                className={`currency-select form-select custom-select tax_category payment_method w-325 m-0 ${
                  props.formik.touched.tax_account_name &&
                  props.formik.errors.tax_account_name
                    ? "error"
                    : ""
                }`}
                components={{ Option: LabelWithRoot }}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                styles={customSelectStyle}
                classNamePrefix="react-select"
              />
              <span className="error">
                {(props.formik.touched.tax_account_name &&
                  props.formik.errors.tax_account_name?.toString()) ||
                  ""}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentTax;
