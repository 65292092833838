import { SvgIconProp } from "../../../../types";

export const DownloadIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width?props.width:"18px"}
      height={props.height?props.height:"18px"}
      style={{ margin: props.margin ? props.margin : "" }}
      viewBox="0 0 18 18"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          d="M9.75 7.5h3.75L9 12 4.5 7.5h3.75V2.25h1.5V7.5zM3 14.25h12V9h1.5v6a.75.75 0 0 1-.75.75H2.25A.75.75 0 0 1 1.5 15V9H3v5.25z"
          fill={props.color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
