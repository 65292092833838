import React from "react";
import {
  ModuleListValues,
  ModuleStatusValues,
} from "../../../../../../../types";
type Props = {
  moduleListValues: ModuleListValues[];
  moduleStatus: ModuleStatusValues;
  selectedModule: string;
  sidebarSelectHandler: (label: string) => void;
};
const PermissionMatrixSideBar = (props: Props) => {
  const moduleStatusMap: any = {
    Contacts: props.moduleStatus.contactsStatus,
    Sales: props.moduleStatus.salesStatus,
    Purchases: props.moduleStatus.purchasesStatus,
    Accounts: props.moduleStatus.accountsStatus,
    Reports: props.moduleStatus.reportsStatus,
    Subscription: props.moduleStatus.subscriptionStatus,
    "Users and Roles": props.moduleStatus.usersAndRolesStatus,
    "Additional Settings": props.moduleStatus.additionalSettingsStatus,
    "Item": props.moduleStatus.itemStatus,
  };
  return (
    <div className="tab-container">
      <ul
        className="nav nav-tabs vertical-tab p-0 m-0"
        id="permissionTab"
        role="tablist"
      >
        {props.moduleListValues.map((module, index) => {
          return (
            <li className="nav-item" role="presentation" key={module.id}>
              <button
                className={`nav-link ${moduleStatusMap[module.name]} ${
                  props.selectedModule === module.name ? "active" : ""
                }`}
                id={`${module.name}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${module.name.replace(/ /g, "")}`}
                type="button"
                role="tab"
                aria-controls={`${module.name}`}
                aria-selected={index === 0 ? "true" : "false"}
                onClick={() => props.sidebarSelectHandler(module.name)}
              >
                <span>{module.name}</span>
                {module?.is_unsaved ? (
                  <div className="badge rounded-pill message-pills unsaved">
                    Unsaved
                  </div>
                ) : (
                  ""
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PermissionMatrixSideBar;
