import { ObjectType, TCSFormValues } from "../../../../../types";

export const validateTcsForm = (
  values: TCSFormValues,
  paymentMode: string,
  isConditionChecked: boolean,
  tcsId: number,
  isTcsChanged: boolean
) => {
  const errors: ObjectType = {};
  if (!values.tax_name) {
    errors.tax_name = "Enter the TCS Name";
  }
  if ((!values.rate || Number(values.rate) > 100) && paymentMode === "rate") {
    errors.rate = "Enter a valid Tax Rate(%)";
  }
  if (!values.nature_of_collection_id) {
    errors.nature_of_collection_id = "Select a tax type";
  }
  if (tcsId && !isConditionChecked && isTcsChanged) {
    errors.terms_and_condition =
      "Please read and accept the Terms and Conditions";
  }
  return errors;
};
