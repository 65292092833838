import React from "react";
import "./tagchip.css";

type Props = {
  children: React.ReactNode;
  margin?: string;
};

export const Tagchip = (props: Props) => {
  return (
    <span className="tagchip" style={{ margin: props.margin || "" }}>
      {props.children}
    </span>
  );
};
