import { store } from "../app/store";
import { setOrganizationRole } from "../features/common/commonSlice";

export const organizationPermission = (parentOrganizationId: number, currentOrganizationId: number) => {
  if (parentOrganizationId !== 0) {
    let storedRoles = JSON.parse(
      sessionStorage.getItem("rolePermissions") || "[]"
    )||[];
    let organizationPermissions = storedRoles
      .filter(
        (role: any) =>
          Number(role.orgId) === Number(parentOrganizationId) ||
          Number(role.orgId) === Number(currentOrganizationId) ||
          role.orgId === "default"
      )
      .map((item: any) => {
        return item;
      });
      sessionStorage.setItem(
      "organizationRole",
      JSON.stringify(organizationPermissions)
    );
    store.dispatch(setOrganizationRole(organizationPermissions));
  }
};
