import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";

type Props = {
  label: string;
  amount: number;
  currencyCode: string;
};
const TransactionBalance = (props: Props) => {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);

  return (
    <div className="summary-item">
      <div className="summary-label">{props.label}:</div>
      <div className="summary-value">
        {NumberFormat(props.amount, props.currencyCode, orgCurrencyList)}
      </div>
    </div>
  );
};

export default TransactionBalance;
