import React from "react";
import { ErrorInfoIcon } from "../../../assets/images";
import "../styles/ErrorInfo.css";

export default function ErrorInfo(props: { message: string; width?: string }) {
  return (
    <div
      className="info-box error-info"
      style={props.width ? { width: props.width } : {}}
    >
      <img src={ErrorInfoIcon} alt="error info icon" />
      <span className="info-text">{props.message}</span>
    </div>
  );
}
