import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { CreateTaxValues } from "../../../types";

/**
 * API call to get tax configuration list
 */
export async function getTaxesList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  const result = await get(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/list?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to create a new tax
 */
export async function createNewTax(data: {
  values: CreateTaxValues;
  orgId: number;
}) {
  const result = await post(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to edit a tax
 */
export async function editTaxItem(data: {
  taxId: number;
  values: CreateTaxValues;
  orgId: number;
}) {
  const result = await put(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/edit/${data.taxId}?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to delete a tax
 */
export async function deleteTaxItem(data: { taxId: number; orgId: number }) {
  const result = await remove(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/delete/${data.taxId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call to get all tax payable configuration list
 */
export async function getTaxePayable(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/dropdown-payable?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get all tax receivable configuration list
 */
export async function getTaxeReceivable(data: { orgId: number }) {
  const result = await get(
    Api.chartOfAccount +
      Api.taxConfiguration +
      `/dropdown-receivable?current_organization=${data.orgId}`
  );
  return result;
}
