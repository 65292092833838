import * as React from "react";
import { DateFormatHandler } from "../../../../helpers/helper";
import { ObjectType } from "../../../../types";
import CompanyDetails from "../../../common/components/detailsPage/CompanyDeatails";

type Props = {
  reportData: ObjectType;
  reportName: string;
};
function ReportHeader(props: Props) {
  const reportNames = ["Balance Sheet", "Trial Balance"];
  return (
    <div className="report-header">
      <div className="report-header-container d-flex justify-content-between">
        <CompanyDetails
          organizationData={props.reportData?.organization_details}
        />
        <div className="report-details align-right">
          <div className="page-type-label">{props.reportName}</div>
          <div className="preview-date-field">
            <span className="start-dt">
              {(props.reportData?.start_date && !reportNames.includes(props.reportName)
                ? DateFormatHandler(props.reportData?.start_date) + " to "
                : "As of ") + DateFormatHandler(props.reportData?.end_date)}
            </span>
            <div className="report-header-org-name mt-2">
              {props.reportData?.organization_details?.organization_name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportHeader;
