import { useEffect, useState } from "react";
import {
  AddTagIconBlack,
  ArrowDropRightImg,
  DeleteImg,
  EditListDataImg,
  ErrorImg,
  PlusSmallImg,
  TaxEmptyIcon,
} from "../../../../../assets/images";
import { currentUserSelector } from "../../../../common/commonSlice";
import TableInfo from "../../../../common/components/table/TableInfo";
import Loader from "../../../components/Loader";
import "../Settings.css";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Pagination from "../../../../common/components/table/Pagination";
import DeleteTax from "./Delete";
import { taxRateGroupList, taxesRateList } from "../taxRateSlice";
import { TaxRateValues } from "../../../../../types";
import { colors } from "../../../../constants/colors";
import { TaxModal } from "./TaxModal";
import { TaxGroupModal } from "./taxGroup/TaxGroupModal";
import DeleteTaxRate from "./Delete";
import { toast } from "react-toastify";

function TaxRateList() {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [totalTaxes, setTotalTaxes] = useState(1);
  const [page, setPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [deleteTaxId, setDeleteTaxId] = useState(-1);
  const [deleteTaxGroup, setDeleteTaxGroup] = useState(false);
  const [taxRateData, setTaxRateData] = useState<TaxRateValues[]>([]);
  const [taxId, setTaxId] = useState(-1);
  const [isAccountDeleted, setIsAccountDeleted] = useState(false);
  const [tableScrolLeft, setTableScrollLeft] = useState(0);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let taxConfigurationPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Tax Configuration"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchTaxRateGroupList();
    getTaxRatesList();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);

  /**
   * Fetch all Tax Lists
   */
  const fetchTaxRateGroupList = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      taxRateGroupList({
        page,
        itemsPerPage,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setTaxRateData(response.list_view);
        let length = response.list_view?.length;
        setTotalTaxes(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };

  const getTaxRatesList = async () => {
    await dispatch(
      taxesRateList({
        orgId: currentUserInfo.organization_id,
      })
    );
  };

  useEffect(() => {
    let pageCount = Math.ceil(totalTaxes / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount !== 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalTaxes, page]);

  /**
   * Invoke when user click to request another page
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalTaxes;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  /**
   * Function to store the id of tax item to be updated
   */
  const editTaxHandler = (id: number, isAccountExist: boolean) => {
    setTaxId(id);
    setIsAccountDeleted(isAccountExist);
    // $(".create-invoice-button").click();
  };

  /**
   * Function to store the id of tax item to be deleted
   */
  const deleteTaxHandler = (
    id: number,
    taxGroup: boolean,
    isTaxAssociated: boolean
  ) => {
    setDeleteTaxId(id);
    setDeleteTaxGroup(taxGroup);
    if (isTaxAssociated) {
      toast.error(
        `This tax is associated with the tax group(s) . Hence, it cannot be deleted`,
        {
          icon: ({ theme, type }) => (
            <img src={ErrorImg} alt="error-img" width="16" height="14" />
          ),
          toastId: `bill-delete-failed`,
          closeButton: false,
          className: "toast-error",
          position: "top-center",
        }
      );
    }
  };

  /**
   * reset the tax id while closing create / edit tax modal
   */
  const handleTaxId = () => {
    setTaxId(-1);
    setIsAccountDeleted(false);
  };

  const taxListDropdownAction = () => {
    var leftScroll = 0;
    var scrollItem: any = document.querySelector(".table-container");
    let resizeFlag: boolean = false;
    scrollItem.addEventListener("scroll", function (e: any) {
      setTableScrollLeft(scrollItem.scrollLeft);
    });
    $(".more-btn").on("show.bs.dropdown", function () {
      $(".table-container").css({ overflow: "inherit" });
      $(".table-container table").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
    });
    $(".more-btn").on("hide.bs.dropdown", function () {
      $(".table-container").css({
        "overflow-x": "auto",
        "overflow-y": "hidden",
      });
      $(".table-container table").css("margin-left", 0);
    });
    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-ctrl") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(function () {
    taxListDropdownAction();
  });

  /**
   * Function to call the Taxes list api call after create / edit / delete action is being made
   */
  const onCreateTax = () => {
    fetchTaxRateGroupList();
    getTaxRatesList();
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
          <h1>Tax Rates</h1>
          {taxConfigurationPermission.includes("Create") ? (
            <div className="top-action-items-wrapper">
              <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
                <li className="nav-item">
                  <div className="form-btn-container d-flex w-100 add-tax-btn-green">
                    <div
                      className={`btn-group save-and-send-btn-group ps-3 ms-1
                      }`}
                    >
                      <TaxModal
                        taxId={0}
                        taxIdReset={handleTaxId}
                        organizationId={currentUserInfo.organization_id}
                        handleModalClose={() => {}}
                        isAccountDeleted={isAccountDeleted}
                        className="btn create-invoice-button green-button right-button btn-radius-right-0"
                        refreshList={() => {
                          fetchTaxRateGroupList();
                          getTaxRatesList();
                        }}
                      >
                        <span>
                          <img
                            src={PlusSmallImg}
                            className="Group"
                            alt="plus-small-img"
                          ></img>
                          <span className="create-invoice-button-text green-button-text">
                            Add New Tax
                          </span>
                        </span>
                      </TaxModal>
                      <button
                        type="button"
                        className="btn dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          <img src={ArrowDropRightImg} alt="" />
                        </span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <TaxGroupModal
                            taxGroupId={0}
                            taxIdReset={handleTaxId}
                            organizationId={currentUserInfo.organization_id}
                            handleModalClose={onCreateTax}
                            isAccountDeleted={isAccountDeleted}
                            className="dropdown-item"
                          >
                            <span>
                              <img
                                src={AddTagIconBlack}
                                className="Group"
                                alt="plus-small-img"
                              ></img>
                              <span className="">Add Tax Group</span>
                            </span>
                          </TaxGroupModal>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body">
            <div
              id="invoice-table-wrapper"
              className={
                "list-table-wrapper settings-list-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
                `${totalTaxes > 0 ? "" : "no-data"}`
              }
            >
              {!isLoading ? (
                <TableInfo
                  total={totalTaxes}
                  startCount={startCount}
                  endCount={endCount}
                  itemsPerPage={itemsPerPage}
                  setPageInfo={(countPerPage, page) => {
                    setItemsPerPage(countPerPage);
                    setPage(page);
                  }}
                />
              ) : null}

              {/* table */}
              <div className="table-container table-responsive">
                <table
                  id="settings_table"
                  className="list-table table table-striped tax-conf-table"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <span>TAX NAME</span>
                      </th>
                      <th>
                        <span>TAX TYPE</span>
                      </th>
                      <th>
                        <span>RATE(%)</span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {!isLoading ? (
                    <tbody>
                      {taxRateData.length ? (
                        taxRateData.map((tax, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {tax.name}{" "}
                                {tax.tax_group ? (
                                  <span className="tax-group">(Tax Group)</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>{tax.tax_type}</td>
                              <td>{tax.rate}</td>
                              <td className="action">
                                {taxConfigurationPermission.includes(
                                  "Update"
                                ) && tax.is_mutable ? (
                                  <div className="dropdown kebabMenu">
                                    <div
                                      className="more-btn"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      tabIndex={0}
                                      onKeyUp={(event: any) => {
                                        if (
                                          event.key === "Enter" ||
                                          event.keyCode === 13
                                        ) {
                                          event.target.click();
                                        }
                                      }}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g fill="none" fillRule="evenodd">
                                          <path d="M0 0h20v20H0z" />
                                          <path
                                            d="M10 2.5c-.917 0-1.667.75-1.667 1.667 0 .916.75 1.666 1.667 1.666s1.667-.75 1.667-1.666c0-.917-.75-1.667-1.667-1.667zm0 11.667c-.917 0-1.667.75-1.667 1.666 0 .917.75 1.667 1.667 1.667s1.667-.75 1.667-1.667c0-.916-.75-1.666-1.667-1.666zm0-5.834c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667 1.667-.75 1.667-1.667-.75-1.667-1.667-1.667z"
                                            fill={colors.battleshipGrey}
                                            fillRule="nonzero"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                    <ul
                                      className="dropdown-menu"
                                      data-popper-placement="bottom-end"
                                    >
                                      <li
                                        onClick={() =>
                                          editTaxHandler(tax.id, false)
                                        }
                                      >
                                        {tax.tax_group ? (
                                          <TaxGroupModal
                                            taxGroupId={tax.id}
                                            taxIdReset={handleTaxId}
                                            organizationId={
                                              currentUserInfo.organization_id
                                            }
                                            handleModalClose={onCreateTax}
                                            isAccountDeleted={isAccountDeleted}
                                            className=""
                                            data={tax}
                                          >
                                            <a
                                              href="#"
                                              className="dropdown-item edit-user"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <img
                                                src={EditListDataImg}
                                                alt="edit-list-data-imgs"
                                              />
                                              <span>Edit</span>
                                            </a>
                                          </TaxGroupModal>
                                        ) : (
                                          <TaxModal
                                            taxId={tax.id}
                                            taxIdReset={handleTaxId}
                                            organizationId={
                                              currentUserInfo.organization_id
                                            }
                                            handleModalClose={() => {}}
                                            isAccountDeleted={isAccountDeleted}
                                            className=""
                                            item={{
                                              name: tax.name,
                                              rate: tax.rate,
                                              tax_type: tax.tax_type,
                                            }}
                                            refreshList={() => {
                                              fetchTaxRateGroupList();
                                              getTaxRatesList();
                                            }}
                                          >
                                            <a
                                              href="#"
                                              className="dropdown-item edit-user"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <img
                                                src={EditListDataImg}
                                                alt="edit-list-data-imgs"
                                              />
                                              <span>Edit</span>
                                            </a>
                                          </TaxModal>
                                        )}
                                      </li>
                                      {taxConfigurationPermission.includes(
                                        "Delete"
                                      ) ? (
                                        <li
                                          onClick={() => {
                                            deleteTaxHandler(
                                              tax.id,
                                              tax.tax_group,
                                              tax.is_tax_associated
                                            );
                                          }}
                                        >
                                          <a
                                            className="dropdown-item delete"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target={
                                              tax.is_tax_associated
                                                ? ""
                                                : "#deleteMessageModal"
                                            }
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <img
                                              src={DeleteImg}
                                              alt="edit-list-data-imgs"
                                            />
                                            <span>Delete</span>
                                          </a>
                                        </li>
                                      ) : null}
                                    </ul>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-item">
                          <td colSpan={6}>
                            <div className="create-invoice-container align-center">
                              <img
                                className="preview-img"
                                src={TaxEmptyIcon}
                                alt="create invoice preview"
                                width="108"
                                height="120"
                              />
                              <h3>
                                {taxConfigurationPermission.includes("Create")
                                  ? "No Taxes Configured"
                                  : "No Taxes found!"}
                              </h3>
                              <p>
                                {taxConfigurationPermission.includes("Create")
                                  ? "Add Tax Code and configure taxes."
                                  : "Oops, There is no Tax data available right now"}
                              </p>
                              {taxConfigurationPermission.includes("Create") ? (
                                <button
                                  className="btn create-invoice-button green-button"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#taxCreateModal"
                                >
                                  <img
                                    src={PlusSmallImg}
                                    className="Group"
                                    alt="plus-small-img"
                                  />
                                  <span className="create-new-customer-button-text green-button-text">
                                    Add New Tax
                                  </span>
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : null}
                </table>
              </div>
              {isLoading ? <Loader /> : null}
            </div>
          </div>

          <div className="card-footer clear-both">
            {totalTaxes > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteTaxRate
        deleteTaxId={deleteTaxId}
        deleteTaxGroup={deleteTaxGroup}
        organizationId={currentUserInfo.organization_id}
        handleModalClose={onCreateTax}
      />
    </>
  );
}

export default TaxRateList;
