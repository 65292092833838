import React from "react";
import ExpenseAccountSelect from "../../../../common/components/paymentFormInputs/ExpenseAccountSelect";
import { FormikProps } from "formik";
import { zeroDisplayFormat } from "../../../../../helpers/numberFormatHelper";
import InputPrefix from "../../../../common/components/formPrefixInputField/InputPrefix";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import "./bankCharges.css";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  bankChargesAccountId: string | number | null;
  onBankChargeAccountChange: (e: any) => void;
  bankChargesAmount: string | number;
  onBankChargeChange: (e: any) => void;
  currencyCode: string;
  bankCurrencyCode: string;
  formErrors: any;
  classWidth: string;
  isBankCurrency?: boolean;
  disabled?: boolean;
  bankChargeConvertedAmount?: string;
};
function BankCharges(props: Props) {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };

  const decimals = decimalPlaceOfCurrency(
    props.bankCurrencyCode,
    orgCurrencyList
  );
  const bankChargeConvertedAmount_rounded = getRoundOffAmount(
    Number(props.bankChargeConvertedAmount),
    decimals
  );
  return (
    <>
      <label htmlFor="bank_charges">Bank Charges (if any)</label>
      <div className="form-group">
        <label htmlFor="payment_method" className="hidden">
          Bank Charges (if any)
        </label>
        <label htmlFor="charged_amt" className="hidden">
          Bank Charges (if any)
        </label>
        <ExpenseAccountSelect
          bankChargesAccountId={props.bankChargesAccountId}
          handleBankCharge={(e) => {
            props.onBankChargeAccountChange(e);
          }}
          error={
            (props.formErrors?.bank_charges_account_id &&
              props.formErrors?.bank_charges_account_id) ||
            ""
          }
          wrapperClass={props.classWidth}
          disabled={props.disabled ? props.disabled : false}
          isClearable={true}
          isSearchable={true}
        />
        <InputPrefix
          prefix={props.currencyCode ? props.currencyCode : ""}
          inputType="text"
          name={"bank_charges_amount"}
          id="charged_amt"
          className="form-control border-end-0"
          wrapperClass={`inv-group d-flex ${props.classWidth}`}
          isAmountField={true}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.currencyCode
                ? props.currencyCode
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          value={props.bankChargesAmount ? props.bankChargesAmount : ""}
          disabled={
            props.disabled === true || !props.bankChargesAccountId
              ? true
              : false
          }
          error={
            (props.formErrors?.bank_charges_amount &&
              props.formErrors?.bank_charges_amount) ||
            ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onBankChargeChange(e);
          }}
          onBlur={(e: any) => {}}
          onKeyPress={handleKeyPress}
        />
      </div>
      {props.currencyCode &&
      props.bankChargeConvertedAmount &&
      Number(props.bankChargeConvertedAmount) !== 0 ? (
        <div className="bank-charges-converted-amount w-100 sub-label mt-1">{`Bank charges: ${props.bankCurrencyCode} ${bankChargeConvertedAmount_rounded}`}</div>
      ) : (
        <></>
      )}
      {
        <>
          <span className="error">
            {(props.formErrors)}
          </span>
        </>
      }
    </>
  );
}

export default React.memo(BankCharges);
