import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { currentUserSelector } from "../../../../../common/commonSlice";
import {
  DirectoryIconSmall,
  ListIconSmall,
} from "../../../../../../assets/images";
type Props = {
  totalResults: number;
  isSearch: boolean;
  resetInput: () => void;
  resetOnOrgChange: () => void;
  setAccountName: (value: string) => void;
  searchSuggestions: (value: string, e: any) => Promise<any>;
  setPage: (value: number) => void;
};
const CoaSearchSuggestionBox = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue("");
    props.resetOnOrgChange();
    autocomplete(document.getElementById("search_table"), []);
  }, [currentUserInfo.organization_id]);

  /**
   * Account type listing handler
   */
  const accountTypeHandler = (type: string) => {
    if (type == null) {
      return "";
    }
    let newType = type;
    let typeArray = newType.split("/");
    if (
      typeArray.length === 0 ||
      typeArray.length === 1 ||
      typeArray.length === 2
    ) {
      return type;
    } else {
      newType = typeArray[0] + " / .. / " + typeArray[typeArray.length - 1];
      return newType;
    }
  };

  function autocomplete(inp: any, arr: any) {
    var currentFocus: any;
    inp.addEventListener("input", async function (this: any, e: any) {
      const searchedKey = this.value.trim() === "" ? "" : this.value;
      if (e.keyCode === 13) {
        e.preventDefault();
        props.setAccountName(searchedKey);
      } else {
        setSearchValue(searchedKey);
        arr = await props.searchSuggestions(searchedKey, e);
        var a: any,
          b,
          i: any,
          val = searchedKey.trim(),
          matchCounter = 0;
        closeAllLists();
        if (!val) {
          return false;
        }
        currentFocus = -1;
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        this.parentNode.appendChild(a);
        arr.map((t: any) => {
          if (t.account_name.toUpperCase().includes(val.toUpperCase())) {
            let index = t.account_name.toUpperCase().indexOf(val.toUpperCase());
            b = document.createElement("DIV");
            b.setAttribute("class", "search-item-wrapper");
            b.setAttribute("tabindex", "1");
            let c = document.createElement("DIV");
            c.setAttribute("class", "search-item w-100");
            c.innerHTML =
              "<img src='" +
              `${t.is_category ? DirectoryIconSmall : ListIconSmall}` +
              "' alt='account icon' width='16' height='16' />";
            c.innerHTML += t.account_name.substr(0, index);
            c.innerHTML +=
              "<span>" + t.account_name.substr(index, val.length) + "</span>";
            c.innerHTML += t.account_name.substr(index + val.length);
            c.innerHTML +=
              "<input type='hidden' value='" + t.account_name + "'>";
            let d = document.createElement("DIV");
            d.setAttribute("class", "item-type w-100");
            d.innerHTML += `<span>${accountTypeHandler(t.account_type)}</span>`;
            b.appendChild(c);
            b.appendChild(d);
            b.addEventListener("click", function (e) {
              inp.value = this.getElementsByTagName("input")[0].value;
              const end = inp.value.length;
              inp.setSelectionRange(end, end);
              inp.focus();
              closeAllLists();
              props.setAccountName(t.account_name);
              setSearchValue(t.account_name);
            });
            a.appendChild(b);
            matchCounter++;
          }
        });
        if (matchCounter === 0) {
          if (searchedKey.length > 2) {
            b = document.createElement("DIV");
            b.setAttribute("class", "search-item-wrapper no-result");
            b.innerHTML = "No item available";
            a.appendChild(b);
          } else {
            $(a).remove();
          }
        }
        document.body.style.overflow = "hidden";
      }
    });
    inp.addEventListener("keydown", function (this: any, e: any) {
      var x: HTMLElement | any = document.getElementById(
        this.id + "autocomplete-list"
      );
      if (x) {
        x = x.getElementsByClassName("search-item-wrapper");
      }
      if (Number(e.keyCode) === 40) {
        currentFocus++;
        addActive(x);
      } else if (Number(e.keyCode) === 38) {
        currentFocus--;
        addActive(x);
      } else if (Number(e.keyCode) === 13) {
        e.preventDefault();
        props.setPage(1);
        if (currentFocus > -1) {
          if (x) x[currentFocus].click();
        }
        props.setAccountName(this.value);
        setSearchValue(this.value);
        closeAllLists();
      }
    });
    function addActive(x: any) {
      if (!x) return false;
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x: any) {
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(element?: any) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (element !== x[i] && element !== inp) {
          x[i].parentNode?.removeChild(x[i]);
        }
      }
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }
  const resetSearch = () => {
    setSearchValue("");
    document.getElementById("search_table")?.focus();
    props.resetInput();
  };
  return (
    <form
      className="search_table_form type-include"
      name="search_table_form"
      autoComplete="off"
    >
      <div className="autocomplete">
        <div className="field-container">
          <input
            type="text"
            name="searchTable"
            id="search_table"
            className="ellipsis"
            placeholder="Search for Accounts"
            value={searchValue}
            onChange={() => {}}
          />
          {props.totalResults > 0 &&
          searchValue.length > 2 &&
          !props.isSearch ? (
            <span className="result-count">
              {props.totalResults}{" "}
              {props.totalResults === 1 ? "Result" : "Results"} Found
            </span>
          ) : null}
          {searchValue.length > 0 ? (
            <input
              type="button"
              className="clear-search"
              value=""
              onClick={resetSearch}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </form>
  );
};

export default CoaSearchSuggestionBox;
