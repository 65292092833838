import { colors } from "../../../constants/colors";
import "../../../../index.css";
import './Button.css';

const EditButton = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule={"evenodd"}>
          <path d="M0 0h18v18H0z" />
          <path
            d="m11.796 7.264-1.06-1.06L3.75 13.19v1.06h1.06l6.986-6.986zm1.06-1.06 1.061-1.06-1.06-1.061-1.061 1.06 1.06 1.061zM5.432 15.75H2.25v-3.182L12.326 2.492a.75.75 0 0 1 1.06 0l2.123 2.121a.75.75 0 0 1 0 1.06L5.431 15.75z"
            fill={colors.lynch}
            fillRule={"nonzero"}
          />
        </g>
      </svg>
      <span className="green-button-text">Edit</span>
    </>
  );
};

export default EditButton;
