import { FormikProps } from "formik";
import { joinTransactionNumber } from "../../../../../../../../helpers/prefixHelper";
import {
  DateFormatHandler,
  getFormatedDate,
  splitNumberByDecimalPoint,
} from "../../../../../../../../helpers/helper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../../../../hooks/useCommon";
import CustomDatepicker from "../../../../../../../utils/atoms/datepicker";
import InputPrefix from "../../../../../../../common/components/formPrefixInputField/InputPrefix";
import { decimalPlaceOfCurrency } from "../../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../../helpers/roundOffHelper";

type Props = {
  formik: FormikProps<any>;
  currencyCode: string;
  errors: any;
  index: number;
  item: {
    credit_type: string;
    transaction_number: string;
    date: string;
    amount: string;
    balance: string;
    allocation_date: string;
    amount_credit: string;
  };
};
export const ApplyCreditItem = (props: Props) => {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const handleItemOnBlur = (name: string, amount: number, index: number) => {
    if (amount && amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = decimalPlaceOfCurrency(
        props.currencyCode,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
      }
    }
  };
  return (
    <tr className="w-100">
      <td scope="col" className="apply-credit-credit-type-col">
        {props.item.credit_type}
      </td>
      <td scope="col" className="apply-credit-transaction-id-col">
        {joinTransactionNumber(props.item.transaction_number)}
      </td>
      <td scope="col" className="apply-credit-date-col">
        {DateFormatHandler(props.item.date)}
      </td>
      <td scope="col" className="apply-credit-amount-col">
        {NumberFormat(
          Number(props.item.amount),
          props.currencyCode,
          orgCurrencyList
        )}
      </td>
      <td scope="col" className="apply-credit-balance-col">
        {NumberFormat(
          Number(props.item.balance),
          props.currencyCode,
          orgCurrencyList
        )}
      </td>
      <td scope="col" className="apply-credit-allocation-date-col">
        {currentUserInfo.is_payment_allocation_enabled &&
        props.item.credit_type === "Excess Payment" ? (
          <>
            <CustomDatepicker
              id="allocation_date"
              handleDate={(date) => {
                if (date) {
                  props.formik.setFieldValue(
                    `transactions_to_credit.${props.index}.allocation_date`,
                    getFormatedDate(date)
                  );
                }
              }}
              date={new Date(props.item.allocation_date)}
              minDate={new Date(currentUserInfo.previous_fiscal_end_date)}
              type="paymentDate"
              error={
                props.errors &&
                props.errors[props.index] &&
                props.errors[props.index]?.allocation_date
                  ? "error"
                  : ""
              }
              enableFutureDate={true}
            />
            <span className="error">
              {props.errors &&
                props.errors[props.index] &&
                props.errors[props.index]?.allocation_date}
            </span>
          </>
        ) : (
          <>{DateFormatHandler(props.item.allocation_date)}</>
        )}
      </td>
      <td scope="col" className="apply-credit-amount-to-credit-col">
        <InputPrefix
          prefix={props.currencyCode}
          isAmountField={true}
          className="credit_note_number"
          name={`transactions_to_credit.${props.index}.amount_credit`}
          id={"amount_credit" + props.index}
          inputType="text"
          value={props.item.amount_credit ? props.item.amount_credit : ""}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.currencyCode
                ? props.currencyCode
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          disabled={false}
          error={
            props.errors &&
            props.errors[props.index] &&
            props.errors[props.index]?.amount_credit
          }
          onClick={() => {}}
          onChange={(e: any) => {
            props.formik.handleChange(e);
          }}
          onBlur={() => {
            setTimeout(() => {
              handleItemOnBlur(
                "amount_credit",
                Number(props.item.amount_credit),
                props.index
              );
            }, 200);
          }}
        />
        {/* <span className="error">
          {props.errors &&
            props.errors[props.index] &&
            props.errors[props.index]?.amount_credit}
        </span> */}
      </td>
    </tr>
  );
};
