import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { ObjectType } from "../../../../../types";
import { SettingsImg } from "../../../../../assets/images";
import { useNavigate } from "react-router-dom";
import "./settings.css";
import { useMediaQuery } from "@react-hook/media-query";

type Props = {
  disabledFeatureList: ObjectType[];
};
export const Settings = (props: Props) => {
  const mobDevices = useMediaQuery("(max-width: 767px)");
  const navigate = useNavigate();
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let subModulesWithPermission = organizationRole.length
    ? organizationRole.map((permission: any) => {
        return permission.subModule;
      })
    : [];

  const settingsHandler = () => {
    if (!$(".sidebar-togglebtn").hasClass("expanded")) {
      $(".sidebar-togglebtn").trigger("click");
    }
    setTimeout(() => {
      if (
        subModulesWithPermission.includes("Organization Profile") &&
        IsFeatureEnabled("Organization Profile", props.disabledFeatureList)
      ) {
        navigate("/settings/organization-profile");
      } else if (
        subModulesWithPermission.includes("Tax Configuration") &&
        IsFeatureEnabled("Tax Configuration", props.disabledFeatureList)
      ) {
        navigate("/settings/taxes/tax-rates");
      } else if (
        subModulesWithPermission.includes("Customize Prefix") &&
        IsFeatureEnabled("Customize Prefix", props.disabledFeatureList)
      ) {
        navigate("/settings/customize-prefix");
      } else if (subModulesWithPermission.includes("TDS")) {
        navigate("/settings/taxes/tds");
      } else if (subModulesWithPermission.includes("TCS")) {
        navigate("/settings/taxes/tcs");
      } else if (subModulesWithPermission.includes("Reporting Tag")) {
        navigate("/settings/preferences/reporting-tags")
      }
    }, 5);
  };
  return (subModulesWithPermission.includes("Organization Profile") &&
    IsFeatureEnabled("Organization Profile", props.disabledFeatureList)) ||
    (subModulesWithPermission.includes("Tax Configuration") &&
      IsFeatureEnabled("Tax Configuration", props.disabledFeatureList)) ||
    (subModulesWithPermission.includes("Customize Prefix") &&
      IsFeatureEnabled("Customize Prefix", props.disabledFeatureList)) ||
    subModulesWithPermission.includes("TDS") ||
    subModulesWithPermission.includes("TCS") ||
    subModulesWithPermission.includes("Reporting Tag") ? (
    <div
      className="nav-item"
      // onClick={settingsHandler}
      // style={{ cursor: "pointer" }}
      key={"nav-setting"}
    >
      <a
        href="#"
        className="settings-link"
        onClick={settingsHandler}
        style={{ cursor: "pointer" }}
      >
        <div className="nav-oval-Copy" tabIndex={0}>
          <img src={SettingsImg} alt="settings-img" />
          {mobDevices && <span>Settings</span>}
        </div>
      </a>
    </div>
  ) : null;
};
