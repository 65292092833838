import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { exchangeApiStatusSelector } from "../../../common/commonSlice";
import {
  ArrowDropRightImg,
  DownloadIcon,
  PrintIcon,
  SaveBillIcon,
} from "../../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import Delete from "../../../common/components/Delete";
import ErrorInfo from "../../../common/components/ErrorInfo";
import { exchangeRateApiStatusSelector } from "../exchangeRate/exchangeRateSlice";
import { FormikProps } from "formik";

type Props = {
  editId: string | undefined;
  isLocked: boolean;
  lockDate: string;
  name: string;
  isFormSubmitting: boolean;
  onClickSubmit: (status: string, action: string) => void;
  // redirectUrl: string;
  rolePermission: any;
  isPrint: boolean;
  isError?: boolean;
  action?: string;
  isButtonGroupDisabled?: boolean;
  isDisabledSave?: boolean;
  isDisabledDraft: boolean;
  onClickDelete: () => void;
};
function FormActionButtons({
  editId,
  isLocked,
  lockDate,
  name,
  isFormSubmitting,
  onClickSubmit,
  // redirectUrl,
  rolePermission,
  isPrint,
  isError,
  action,
  isButtonGroupDisabled,
  isDisabledSave,
  isDisabledDraft,
  onClickDelete,
}: Props) {
  const exchangeRateAPiStatus = useAppSelector(exchangeRateApiStatusSelector);
  const navigate = useNavigate();
  return (
    <div className="row mb-0">
      <div className="col">
        <div
          className={
            !isFormSubmitting || !exchangeRateAPiStatus
              ? "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 flex-column docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          {isError ? (
            <div className="form-error-container w-100">
              <ErrorInfo
                message={`Oops, we were unable to ${action}. Please fix the errors.`}
              />
            </div>
          ) : (
            ""
          )}
          <div className="form-btn-container d-flex w-100">
            <div
              className={`btn-group save-and-send-btn-group ps-3 ms-1 ${
                isButtonGroupDisabled ? "disabled-item" : ""
              }`}
            >
              <button
                type="button"
                className="btn save-button mx-0"
                onClick={() => onClickSubmit("Open", "Send")}
                disabled={isDisabledSave ? isDisabledSave : false}
              >
                Save and Send
              </button>
              <button
                type="button"
                className="btn dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <img src={ArrowDropRightImg} alt="" />
                </span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => onClickSubmit("Open", "Download")}
                  >
                    <img src={DownloadIcon} alt="" />
                    Save and Download
                  </button>
                </li>
                {isPrint ? (
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => onClickSubmit("Open", "Print")}
                    >
                      <img src={PrintIcon} alt="" />
                      Save and Print
                    </button>
                  </li>
                ) : null}
                <li>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => onClickSubmit("Open", "Save")}
                  >
                    <img src={SaveBillIcon} alt="" />
                    Save
                  </button>
                </li>
              </ul>
            </div>

            <button
              type="button"
              className="cancel-button save-as-draft-button"
              onClick={() => onClickSubmit("Draft", "")}
              disabled={isDisabledDraft}
              style={isDisabledDraft ? { opacity: 0.5 } : {}}
            >
              Save as Draft
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-decoration-none cancel-button mx-4"
            >
              Cancel
            </button>
            {editId && rolePermission.includes("Delete") && (
              <Delete
                className={"text-decoration-none delete-item-button ml-auto"}
                deleteIcon={false}
                isMutable={true}
                onClick={onClickDelete}
                isLocked={isLocked}
                lockDate={lockDate}
                name={name}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormActionButtons;
