import * as yup from "yup";

const CustomFieldValidation = yup.object({
  field_name: yup
    .string()
    .trim("Enter a valid field name")
    .strict(true)
    .required("Field name is required"),
});
export default CustomFieldValidation;
