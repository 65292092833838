// import { SendReminderIcon } from "../../components/customSvgIcons";
// import "./sendReminder.css";

// export default function SendReminder(props: {
//   onClick: () => void;
//   send_reminder?: boolean | null;
// }) {
//   return (
//     <a
//       className={`dropdown-item sendReminder-p0 ${!props.send_reminder ? "disable-btn" : ""}`}
//       href="#"
//       data-bs-toggle="modal"
//       data-bs-target="#sendReminderModal"
//       onClick={(e) => {
//         e.preventDefault();
//         props.onClick();
//       }}
//     >
//       <SendReminderIcon color={!props.send_reminder ? "#91919c" : "black"} />
//       <span style={{'marginLeft':'3px'}}>Send Reminder</span>
//     </a>
//   );
// }

import { DeleteImg, ErrorImg } from "../../../../assets/images";
import { SendReminderIcon } from "../../components/customSvgIcons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DateFormatHandler } from "../../../../helpers/helper";
import { colors } from "../../../constants/colors";
import { VoidIconBtn } from "../customSvgIcons/VoidIcon";
import "./sendReminder.css";

type Props = {
  className: string;
  deleteIcon: boolean;
  isMutable: boolean;
  onClick: () => void;
  isLocked: boolean;
  lockDate: string;
  name: string;
  assignedUserCount?: number;
  iconColor?: string;
  send_reminder?: boolean | null;
};
export default function SendReminder(props: Props) {
  
  return (
    <div className="sendReminderLinkContainer">
    <Link
      className={(!props.send_reminder ? "disable-btn" : "")+" dropdown-item delete"}
      style={{ textDecoration: "none" }}
      to="#"
      data-bs-toggle="modal"
      data-bs-target={
        props.isMutable && !props.isLocked && !props.assignedUserCount
          ? "#reminderMessageModal"
          : !props.isMutable && !props.isLocked
          ? "#notificationPopupModal"
          : props.assignedUserCount
          ? "#reminderConfMessageModal"
          : ""
      }
      onClick={(e) => {
        e.preventDefault();
        if (props.isLocked) {
          toast.error(
            `Transactions before ${DateFormatHandler(
              props.lockDate
            )} have been locked. Hence action cannot be performed`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `reminder-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else {
          props.onClick();
        }
      }}
    >
      <span className="d-flex align-items-center">
        {props.deleteIcon ?<SendReminderIcon color={!props.send_reminder ? "#91919c" : "black"} /> : null}
        Send Reminder
      </span>
    </Link>
    </div>
  );
}

