import { CurrencyFormType } from "../types";

export const decimalPlaceOfCurrency = (
  currencyCode: string | undefined | null,
  orgCurrencyList: CurrencyFormType[]
) => {
  const currency = orgCurrencyList.find(
    (currency) => currency.code === currencyCode
  );
  const decimalPlaces = currency ? currency.decimal : 2;
  return decimalPlaces;
};
