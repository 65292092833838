import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getStateList } from "../../home/gst/gstSlice";
import { currentUserSelector } from "../commonSlice";
import { FormSelectField } from "./formSelectField/FormSelectField";
import { customSelectStyle } from "./SelectCustomStyle";
import _ from "lodash";

type Props = {
  name: string;
  id: string;
  label: string;
  value:
    | ""
    | {
        label: string;
        value: string | number;
      };
  onChange: (option: any) => void;
  isDisabled?: boolean;
  error: any;
  onBlur?: (e: any) => void;
};
function SupplyStates({
  name,
  id,
  label,
  value,
  onChange,
  isDisabled,
  error,
  onBlur
}: Props) {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [stateOptions, setStateOptions] = useState<
    { label: string; value: string | number }[]
  >([]);

  useEffect(() => {
    // fetch states list dropdown options
    getStateListOptions();
  }, []);
  /**
   * Fetch state dropdwon options
   */
  const getStateListOptions = async () => {
    const result = await dispatch(
      getStateList({ orgId: currentUserInfo.organization_id })
    );
    const states = result.payload as string[];
    let statesArray = _.values(states);
    let stateOptionsTmp = statesArray.map((state) => {
      return {
        label: state,
        value: state,
      };
    });
    setStateOptions(stateOptionsTmp);
  };

  return (
    <FormSelectField
      name={name}
      id={id}
      label={label}
      options={stateOptions}
      wrapperClass="mw-325 supply-state-select-field"
      className="state-select form-select custom-select"
      value={value ? value : ""}
      onChange={onChange}
      styles={customSelectStyle}
      placeholder={"Choose State"}
      isSearchable={true}
      isDisabled={isDisabled ? isDisabled : false}
      error={error}
      isRequired={true}
      isOnlyInRow={false}
      onBlur={onBlur}
      
    />
  );
}

export default SupplyStates;
