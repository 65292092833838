import React, { useState } from "react";
import { AddTagIcon } from "../../../assets/images";
import Dialog from '@mui/material/Dialog';
import { Card } from "./htmlTags/Card/Card";
import { CardBody } from "./htmlTags/Card/CardBody";
import { CardHeader } from "./htmlTags/Card/CardHeader";
import { Form } from "./htmlTags/Form";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormSelectField } from "./formSelectField/FormSelectField";
import CloseButton from "./button/CloseButton";
import Grid from "@mui/material/Grid";
import useCommonData from "../../hooks/useCommon";
import { AddAddonFormInitialValueType } from "../../home/subscription/subscriptionType";
import { subscriptionAddonDropdown, subscriptionAddonFrequency, subscriptionAddonFrequencyItems } from "../../home/subscription/subscriptionSlice";
import { setLoaderState } from "../commonSlice";
import { useFormik } from "formik";
import { addAddonFormInitialValue } from "../../home/subscription/SubscriptionStateInitialization"
import { coustomCase } from "../../../helpers/planHelper";
import { filterAddonFrequency } from "../../../helpers/subscriptionHelper";
import { validateAddAddonForm } from "../../home/subscription/components/subscriptionForm/ValidateSubscriptionForm";
import { ObjectType } from "../../../types";
import { FormSelectFieldWithScroll } from "./formSelectField/FormSelectFiledWithScroll";

type Props = {
  onSaveClick: (selectedFrequency: any) => void;
  planFrequency: string;
  selectedAddonlist: number[];
  currencyId: number | undefined,
  conversionId: number | undefined,
}
function AddLineAddon(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [addonFormErrors, setAddonFormErrors] = useState<ObjectType>({})
  
  const formik = useFormik({
    initialValues: { ...addAddonFormInitialValue } as AddAddonFormInitialValueType,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let errors = validateAddAddonForm(values)
      if (Object.keys(errors).length) {
        setAddonFormErrors(errors)
      } else {
        let passingValue = values.addonFrequencyData.filter((data: any) => data.frequency === values.selectedFrequency.value)[0]
        const itemDetails = await getSelectedAddonFrequencyItemDetails(values.selectedAddon.value, passingValue.pricing_id)
        props.onSaveClick({
          ...passingValue,
          addonId: values.selectedAddon.value,
          addonName: values.selectedAddon.label,
          item_price: itemDetails
        })
        formik.resetForm()
      }
    }
  });

  const fetchAddonDropdown = async (count: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionAddonDropdown({
        page: count,
        itemsPerPage: 10,
        orgId: currentUserInfo.organization_id,
        frequency: props.planFrequency,
        addonIds: props.selectedAddonlist,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        const modifiedResponse = response.addons.map((data: any) => ({ value: data.id.toString(), label: data.external_name }))
        formik.setFieldValue("addonDropdownData", modifiedResponse)
        formik.setFieldValue("isNextEnabled", !(response.pagination.next === null))
        if (response.pagination.next !== null) {
          const regex = new RegExp(`page_num=(\\d+)`);
          const modifiedString = response.pagination.next.match(regex)
          const count = modifiedString[1] ? modifiedString[1] : formik.values.count
          formik.setFieldValue("count", count)
        }
      } else if ("error" in response) {
      }
    }
  }

  const fetchBillingDropdown = async (addonId: number) => {
    const responseAction = await dispatch(
      subscriptionAddonFrequency({
        addonId: addonId,
        orgId: currentUserInfo.organization_id,
      })
    );

    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        const modifiedResponse = response.pricings.filter((data: any) => filterAddonFrequency(props.planFrequency, data.frequency)).map((data: any) => ({ value: data.frequency, label: coustomCase(data.frequency) }))
        formik.setFieldValue("frequencyDropdownData", modifiedResponse)
        formik.setFieldValue("addonFrequencyData", response.pricings)
      } else if ("error" in response) {
      }
    }
  }

  const getSelectedAddonFrequencyItemDetails = async (addonId: number | string, pricingId: number) => {
    const responseAction = await dispatch(
      subscriptionAddonFrequencyItems({
        addonId: addonId as number,
        pricingId: pricingId,
        orgId: currentUserInfo.organization_id,
        currencyId: props.currencyId ? props.currencyId: 0,
        conversionId: props.conversionId ? props.conversionId : 0,
      })
    );
    let itemDetails: any[] = []
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        itemDetails = response
      }
    }
    return itemDetails
  }
  return (
    <>
      <div className="w-100 add-line-button-wrapper add-line">
        <div className="w-100 d-flex align-items-center p-b-18">
          <a
            href="#"
            role="button"
            className={`add-tags-button add-line-button d-flex align-items-center ${props?.planFrequency ? '' : 'add-detail-disabled'}`}
            onClick={(e) => e.preventDefault()}
          >
            <img src={AddTagIcon} alt="" />
            <span className="px-2" onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              fetchAddonDropdown(1)
              formik.setFieldValue("openPlanPopover", true)
            }}>
              Select Addons
            </span>
          </a>
        </div>
      </div>
      <Dialog
        open={formik.values.openPlanPopover}
        className="subscription-addon-popover"
        onClose={() => formik.resetForm()}
      >
        <Card wrapperClass="addon-card">
          <CardHeader wrapperClass="addon-card-header-wrapper">
            <Grid container>
              <Grid item xs={10} className="p-t-15">
                Select Addons
              </Grid>
              <Grid xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={() => { formik.resetForm() }} />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row custom-row">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectFieldWithScroll
                    count={formik.values.count}
                    name="select_addon"
                    id="subscription_select_addon"
                    label="Select Addon"
                    options={formik.values.addonDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedAddon}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedAddon", option)
                      formik.setFieldValue("selectedFrequency", { value: '', label: '' })
                      fetchBillingDropdown(option.value)
                    }}
                    onMenuScrollToBottom={() => {
                      if (formik.values.isNextEnabled)
                        fetchAddonDropdown(formik.values.count)
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    isDisabled={false}
                    error={addonFormErrors?.select_addon ? addonFormErrors.select_addon : ''}
                    isRequired={false}
                    isOnlyInRow={false}
                    dropdownCustomMessage='No Addons available'
                  />
                </div>
              </div>
              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="billing_frequency"
                    id="subscription_billing_frequency"
                    label="Frequency"
                    options={formik.values.frequencyDropdownData}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={formik.values.selectedFrequency}
                    onChange={(option: any) => {
                      formik.setFieldValue("selectedFrequency", option)
                    }}
                    styles={customSelectStyle}
                    placeholder={"Select"}
                    isSearchable={true}
                    isDisabled={formik.values.selectedAddon?.label && formik.values.selectedAddon.label !== '' ? false : true}
                    error={addonFormErrors?.billing_frequency ? addonFormErrors.billing_frequency : ''}
                    isRequired={false}
                    isOnlyInRow={false}
                    classNamePrefix="billing-frequency-select"
                  />
                </div>
              </div>
              <div className="row mb-0">
                <div className="col">
                  <div
                    className="form-button-wrapper w-100"
                    id="form-btn"
                  >
                    <button onClick={(e: any) => {
                      e.preventDefault();
                      formik.submitForm()
                    }} className="save-button">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setAddonFormErrors({})
                        formik.resetForm()
                      }}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
}

export default React.memo(AddLineAddon);