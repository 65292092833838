import { createContext, useContext, useEffect, useState } from "react";
import { TCSList } from "../../../../../types";
import { tcsDropDown } from "../../../../home/tcs/tcsSlice";
import useCommonData from "../../../../hooks/useCommon";
import "./TcsSelect.css";
import { TcsSelectWrapperProps } from "./types";

const TCSOptionsContext = createContext({
  setTcsIds: (ids: number[]) => {},
  tcsTaxes: [] as TCSList[],
  fetchTCSSelectOptions: async () => {},
  setIsPercentage: (isPercentage: boolean) => {},
  isPercentage: false,
});
export const TcsSelectProvider = (props: TcsSelectWrapperProps) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const [isPercentage, setPercentage] = useState<boolean>(false);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [tcsTaxes, setTcsTaxes] = useState<TCSList[]>([]);

  useEffect(() => {
    getTcsDropDownList();
  }, [currentUserInfo.organization_id, tcsIds]);

  const getTcsDropDownList = async () => {
    const responseAction = await dispatch(
      tcsDropDown({
        orgId: currentUserInfo.organization_id,
        tcsId: tcsIds,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setTcsTaxes(response);
    }
  };
  const setIsPercentage = (percentage: boolean) => {
    setPercentage(percentage);
  };

  return (
    <TCSOptionsContext.Provider
      value={{
        setTcsIds: setTcsIds,
        tcsTaxes: tcsTaxes,
        fetchTCSSelectOptions: getTcsDropDownList,
        setIsPercentage: setIsPercentage,
        isPercentage: isPercentage,
      }}
    >
      {props.children}
    </TCSOptionsContext.Provider>
  );
};
export const useTCSSelect = () => {
  return useContext(TCSOptionsContext);
};
