export const groupByOptions = [
  { label: "Date", value: "date" },
  { label: "Account", value: "account" },
  { label: "Transaction Type", value: "transaction_type" },
  { label: "Transaction", value: "transaction" },
  { label: "Account Group", value: "account_group" },
  { label: "Customers", value: "customers" },
  { label: "Vendors", value: "vendors" },
];
export const reportBasis = [
  {
    label: "Accrual",
    value: "Accrual",
  },
  {
    label: "Cash",
    value: "Cash",
  },
];
export const addFilterOptions = {
  field: [
    { label: "Transaction Type", value: "transaction_type" },
    {
      label: "Account",
      value: "account",
    },
    { label: "Account Group", value: "account_group" },
  ],
  comparator: [
    {
      label: "is",
      value: "true",
    },
    {
      label: "is not",
      value: "false",
    },
  ],
};
export const customizePLOptions = {
  compareWithValueOptions: [
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
    {
      label: "6",
      value: "6",
    },
    {
      label: "7",
      value: "7",
    },
    {
      label: "8",
      value: "8",
    },
    {
      label: "9",
      value: "9",
    },
    {
      label: "10",
      value: "10",
    },
    {
      label: "11",
      value: "11",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "13",
      value: "13",
    },
    {
      label: "14",
      value: "14",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "16",
      value: "16",
    },
    {
      label: "17",
      value: "17",
    },
    {
      label: "18",
      value: "18",
    },
    {
      label: "19",
      value: "19",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "21",
      value: "21",
    },
    {
      label: "22",
      value: "22",
    },
    {
      label: "23",
      value: "23",
    },
    {
      label: "24",
      value: "24",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "26",
      value: "26",
    },
    {
      label: "27",
      value: "27",
    },
    {
      label: "28",
      value: "28",
    },
    {
      label: "29",
      value: "29",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "31",
      value: "31",
    },
    {
      label: "32",
      value: "32",
    },
    {
      label: "33",
      value: "33",
    },
    {
      label: "34",
      value: "34",
    },
    {
      label: "35",
      value: "35",
    },
  ],
  compareWithOptions: [
    {
      label: "Previous Year(s)",
      value: "Previous Year(s)",
    },
    {
      label: "Previous Period(s)",
      value: "Previous Period(s)",
    },
  ],
  filterAccountsOptions: [
    {
      label: "Accounts without zero balance",
      value: "Accounts without zero balance",
    },
    {
      label: "Accounts with transactions",
      value: "Accounts with transactions",
    },
    { label: "All accounts", value: "All accounts" },
  ],
  reportBasisOptions: [
    {
      label: "Accrual",
      value: "Accrual",
    },
    {
      label: "Cash",
      value: "Cash",
      // value: "Accrual",
    },
  ],
};
