import { FormikProps } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { joinForPayload } from "../../../../../../../helpers/prefixHelper";
import { currentUserSelector } from "../../../../../../common/commonSlice";
import {
  checkPrNumber,
  getPaymentReceivedNumber,
} from "../../../../paymentReceivedSlice";
import { PrefixInput, setLoaderState } from "../../imports";

type Props = {
  formik: FormikProps<any>;
};
export const PaymentNumber = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { editId } = useParams();
  let interval: any = null;
  useEffect(() => {
    if (!editId) paymentNumber();
  }, [currentUserInfo.organization_id]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let paymentNumberSplit = props.formik.values.payment_number?.split("~");
    checkPaymentNumberExist(
      joinForPayload({
        payment_prefix: paymentNumberSplit[0],
        payment_number: paymentNumberSplit[1],
      }),
      signal
    );
    return () => {
      controller.abort();
    };
  }, [props.formik.values.payment_number]);
  const paymentNumber = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getPaymentReceivedNumber(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let PR_number = joinForPayload(response);
        props.formik.setFieldValue("payment_number", PR_number);
      }
    }
    dispatch(setLoaderState(false));
  };
  /**
   * Check payment number already exist
   */
  const checkPaymentNumberExist = async (
    paymentNumber: string,
    signal: AbortSignal
  ) => {
    if (!paymentNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkPrNumber({
            prNumber: paymentNumber,
            currentOrgId: currentUserInfo.organization_id,
            paymentReceivedId: editId ? Number(editId) : 0,
            signal: signal,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (response) {
            if (response.pr_number_available === true) {
              props.formik.setFieldError("payment_number", undefined);
            } else {
              props.formik.setFieldError(
                "payment_number",
                "Payment number already exists. Please choose a different one"
              );
            }
          }
        }
      }, 1000);
    }
  };
  // To get prefix from PrefixInput component.
  const getPrefix = (value: string) => {
    props.formik.setFieldTouched("payment_number", true);
    let paymentNumberSplit = props.formik.values.payment_number?.split("~");
    props.formik.setFieldValue(
      "payment_number",
      joinForPayload({
        payment_prefix: value,
        payment_number: paymentNumberSplit[1],
      })
    );
  };
  return (
    <div className="col-12 col-lg-4 mw-325">
      <label htmlFor="payment_number" className="required">
        Payment Number
      </label>
      <br />
      <PrefixInput
        prefix={props.formik.values.payment_number?.split("~")[0] || ""}
        name="payment_number"
        id="payment_number"
        className="form-control border-end-0"
        placeholder="Enter payment number"
        value={props.formik.values.payment_number?.split("~")[1] || ""}
        disabled={false}
        error={
          (props.formik.touched.payment_number &&
            props.formik.errors.payment_number?.toString()) ||
          ""
        }
        maxLength={12}
        getPrefix={getPrefix}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.formik.setFieldTouched("payment_number", true);
          let paymentNumberSplit =
            props.formik.values.payment_number?.split("~");
          props.formik.setFieldValue(
            "payment_number",
            joinForPayload({
              payment_prefix: paymentNumberSplit[0],
              payment_number: e.target.value,
            })
          );
        }}
      />
      <span className="error">
        {props.formik.touched.payment_number &&
          props.formik.errors.payment_number?.toString()}
      </span>
    </div>
  );
};
