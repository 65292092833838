import React, { useCallback, useEffect, useState } from "react";
import DefaultDetails from "./DefaultDetails";
import TransactionHeader from "./TransactionHeader";
import { useParams } from "react-router-dom";
import { setLoaderState } from "../../../../common/commonSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  addonPriceGetEditDetails,
  addonDetails,
  checkPriceInternalNameExist,
} from "../../addonSlice";
import useCommonData from "../../../../hooks/useCommon";
import { AddonDetailData } from "../../addonType";
import { addonDetailInitialData } from "../../AddonStateInitialization";

function AddonPriceForm() {
  const { frequency, addonId, priceId } = useParams();
  const [amount, setAmount] = useState(0);
  const [planItemListData, setPlanItemListData] = useState<any[]>([]);
  const [addonDetailData, setAddonDetailData] = useState<AddonDetailData>(
    addonDetailInitialData
  );
  const [priceDetails, setPriceDetails] = useState<any[]>([]);
  const addon_id = parseInt(addonId ? addonId : "");
  // const planId = 13;
  const { currentUserInfo } = useCommonData();
  const dispatch = useAppDispatch();
  let interval: any = null;
  const [isInternalNameExist, setIsInternalNameExist] = useState(false);

  const fetchPricingDetails = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      addonPriceGetEditDetails({ priceId: parseInt(priceId ? priceId : ""), orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        setPriceDetails(response);
      } else if ("error" in response) {
      }
    }
  };

  const fetchPlanDetails = async () => {
    // dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      addonDetails({
        addonId: Number(addonId),
        orgId: currentUserInfo.organization_id,
      })
    );

    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 1000);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAddonDetailData(response);
      } else {
      }
    }
  };

  useEffect(() => {
    if (priceId) {
      fetchPricingDetails();
    }
  }, [priceId]);

  useEffect(() => {
    if (addonId && !priceId) {
      fetchPlanDetails();
    }
  }, [addonId]);

  function capitalize(str: string | undefined) {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const checkPriceInternalName = async (internalName: string) => {
    if (!internalName.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkPriceInternalNameExist({
            internalName: internalName,
            orgId: currentUserInfo.organization_id,
            addon_id: addonId ? Number(addonId) : 0,
            pricing_id: priceId ? priceId : "",
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.internal_name_available === false) {
              setIsInternalNameExist(true);
            } else {
              setIsInternalNameExist(false);
            }
          }
        }
      }, 1000);
    }
  };

  return (
    <div
      className="addon-pricing-form card card-user-management card-customer card-create-item invoice-create main-card overflowX-hidden h-100"
      id="invoice-create"
    >
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <TransactionHeader
          transactionName={`${
            priceId ? "Edit Pricing Details" : "Create Pricing Details"
          } - ${capitalize(frequency)}`}
          transactionNumber={0}
          handleSavePreference={() => {}}
          handleSaveRoundOff={() => {}}
          addonId={addonId}
        />
      </div>
      <div className="card-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          name="add_invoice_form"
          id="add_invoice_form"
          className="add-module-item-form"
          tabIndex={-1}
        >
          <div className="fields-wrapper pt-0">
            <div className="fields-wrappers">
              <DefaultDetails
                organizationId={1}
                frequency={frequency}
                totalAmount={amount}
                addonId={addon_id}
                priceDetails={priceDetails}
                priceId={priceId}
                addonDetailData={addonDetailData}
                checkInternalName={checkPriceInternalName}
                isInternalNameExist={isInternalNameExist}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddonPriceForm;
function dispatch(arg0: { payload: any; type: string }) {
  throw new Error("Function not implemented.");
}
