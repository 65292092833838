import { useState } from "react";
import { CloseRowIcon, FormInputField } from "../../../../common/components";
import Grid from "@mui/material/Grid";
import "./item.css";
import { RangePriceValue } from "../../subscriptionType";
import { ObjectType } from "../../../../../types";
import CloseButton from "../../../../common/components/button/CloseButton";

const Item = (props: any) => {
  const [rangePrice, setRangePrice] = useState<RangePriceValue[]>(
    props.range_price
  );
  const [formErrors, setFormErrors] = useState<ObjectType>({});

  return (
    <div className="item-cart subscription-view-pricing-item-container">
      <div className="item-cart-header-subscription-view">
        <div className="item-header item-header-subscription-view">
          <b>{props.itemName}</b>
        </div>
        <CloseButton closeModalHandler={props.onClose} />
      </div>
      <div className="item-cart-price-range">
        {rangePrice?.map((item: any, key: any | null | undefined) => (
          <>
            <div className="item-price-cont" key={key}>
              <div className="pricing-field-wrap">
                <FormInputField
                  type="text"
                  name="range_from"
                  id="range_from"
                  className={"range-item-price form-control"}
                  label="From (Units)"
                  placeholder={key <= 0 ? "1" : ""}
                  maxLength={14}
                  value={item.range_from}
                  isRequired={true}
                  disabled={true}
                  errorClass={"priceErrorMessage"}
                />
                <FormInputField
                  type="text"
                  name="range_to"
                  id="range_to"
                  className={
                    item.fromErrors
                      ? "form-control error range-item-price"
                      : "form-control range-item-price"
                  }
                  label="To"
                  placeholder="& Above"
                  maxLength={14}
                  value={
                    item.range_to && item.range_to !== -1 ? item.range_to : ""
                  }
                  onClick={(e) => e.stopPropagation()}
                  isRequired={false}
                  disabled={true}
                  errorClass={"priceErrorMessage"}
                />
                <FormInputField
                  type="text"
                  name="price_per_step"
                  id="price_per_step"
                  className={
                    item.price_per_stepErrors
                      ? "form-control error range-item-price"
                      : "form-control range-item-price"
                  }
                  label="Price Per Step"
                  placeholder="0.00"
                  value={item.price_per_step === 0 ? "0" : item.price_per_step}
                  isRequired={true}
                  onClick={(e) => e.stopPropagation()}
                  errorClass={"priceErrorMessage"}
                  disabled={true}
                />
              </div>
              <div className="close-row-container"></div>
            </div>
          </>
        ))}
      </div>
      <Grid
        container
        item
        className="modal-footer subscription-update-pricing-modal-footer"
      >
        {/* <button
            type="button"
            className="text-decoration-none cancel-button mx-4"
            onClick={() => props.onClose()}
          >
            Cancel
          </button> */}
      </Grid>
    </div>
  );
};

export default Item;
