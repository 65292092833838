import { AlertModal } from "../alertModal/alertModal";
import { ModalContext } from "../alertModal/alertModalContext";
import { DeleteIcon } from "../customSvgIcons/deleteIcon";
import { colors } from "../../../constants/colors";
import { Delete } from "./delete";

type DeleteModalProps = {
  title:string;
  content: string | React.ReactNode;
  onDeleteConfirm: () => void;
};

export const DeleteIconModal = (props: DeleteModalProps) => {
  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <Delete
                content={props.content}
                onDeleteConfirm={props.onDeleteConfirm}
                handleModalClose={() => {
                  onCloseModal();
                }}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span className="dropdown-item delete d-flex align-items-center ">
          <DeleteIcon width="18px" height="18px" color={colors.tomato} />
        </span>
      }
      buttonClass="no-appearance"
      modalTitle={props.title}
      isCloseButtonEnabled={true}
    />
  );
};
