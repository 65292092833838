import React from "react";
import { ModuleListValues } from "../../../../../../../types";
type Props = {
  moduleList: ModuleListValues;
  grantFullAccessOfEachModule: (
    e: any,
    moduleName: string,
    moduleId: number
  ) => void;
  selectedModule: string;
  moduleListValues: ModuleListValues[];
  resetToDefault: (e: any, moduleId: number, moduleName: string) => void;
};
const PermissionMatrixFooter = (props: Props) => {
  return (
    <div
      className="permission-table-footer d-flex w-100"
      key={props.moduleList.id}
    >
      <div className="permission-full-controls">
        <label
          className="form-check-label full-access-check"
          htmlFor="c_full_access"
        >
          <input
            type="checkbox"
            name="c_full_access"
            id="c_full_access"
            className="form-check-input"
            onChange={(e) =>
              props.grantFullAccessOfEachModule(
                e,
                props.selectedModule,
                props.moduleList.id
              )
            }
            checked={props.moduleList.grant_full_access}
          />
          <span>
            Grant full access to{" "}
            <span className="category">{props.selectedModule}</span>
          </span>
        </label>
        {props.moduleListValues
          .filter((sub) => sub.id === props.moduleList.id)
          .map((subMod) => {
            return subMod.is_unsaved ? (
              <button
                className="permission-reset"
                onClick={(e) =>
                  props.resetToDefault(e, subMod.id, props.moduleList.name)
                }
                key={subMod.id}
              >
                Reset to Default
              </button>
            ) : null;
          })}
      </div>
    </div>
  );
};

export default PermissionMatrixFooter;
