import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Form } from "../../../../../common/components/htmlTags/Form";
import { ApplyToInvoiceBalanceSummary } from "./components/applyToInvoiceFormFooter/ApplyToInvoiceBalanceSummary";
import { ApplyToInvoiceItem } from "./components/applyToInvoiceItem/ApplyToInvoiceItem";
import { useApplyToInvoice } from "./hooks/useApplyToInvoice";
import { ApplyToInvoiceButtonGroup } from "./components/applyToInvoiceFormFooter/ApplyToInvoiceButtonGroup";
import { ApplyToInvoiceValidation } from "./applyToInvoiceValidation/ApplyToInvoiceValidation";
import { InvoiceToAllocate, ObjectType } from "../../../../../../types";
import "./ApplyToInvoice.css";

type ApplyCreditToInvoiceProps = {
  conversionId: number;
  currencyCode: string;
  onClose: () => void;
  getIsError: (isError: boolean) => void;
  refreshCNDetails: () => void;
};
export const ApplyCreditToInvoice = (props: ApplyCreditToInvoiceProps) => {
  const {
    getInvoicesToAllocateCreditNote,
    calculateAllocationAmount,
    submitApplyToInvoiceAllocation,
  } = useApplyToInvoice();

  const [isError, setIsError] = useState(false);

  const formik = useFormik({
    initialValues: {
      balance: "",
      amount_to_credit: "",
      credit_balance: "",
      invoice_allocate_list: [],
    },
    validationSchema: ApplyToInvoiceValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const filteredInvoiceAllocationList: any = values.invoice_allocate_list
        .filter(
          (value: InvoiceToAllocate) =>
            (!Number.isNaN(value.amount_credited) &&
              Number(value.amount_credited) !== 0) ||
            value.allocation_id
        )
        .map((val: InvoiceToAllocate) => {
          return {
            id: val.allocation_id || null,
            amount_to_credit: Number.isNaN(val.amount_credited)
              ? 0
              : Number(val.amount_credited),
            invoice_id: val.invoice_id,
          };
        });
      await submitApplyToInvoiceAllocation(
        filteredInvoiceAllocationList,
        props.refreshCNDetails,
        props.onClose
      );
    },
  });
  useEffect(() => {
    getInvoicesToAllocateCreditNote(formik);
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    calculateAllocationAmount(
      formik.values.invoice_allocate_list,
      formik,
      signal
    );

    return () => {
      controller.abort();
    };
  }, [JSON.stringify(formik.values.invoice_allocate_list)]);
  useEffect(() => {
    const hasErrors =
      formik.errors &&
      (formik.errors.invoice_allocate_list as ObjectType[])?.length > 0;
    setIsError(hasErrors);
    props.getIsError(hasErrors);
  }, [JSON.stringify(formik.values.invoice_allocate_list)]);
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container className="allocatable-invoice-list-table-wrapper">
        <table className="table apply-creds-table w-100 position-relative credit-note-allocation-table">
          <thead
            className="border-top-0 unpaid-payments-header sticky-top"
            style={{ top: 0, left: 0, backgroundColor: "white" }}
          >
            <tr>
              <th scope="col">INVOICE NUMBER</th>
              <th scope="col">INVOICE DATE </th>
              <th scope="col">INVOICE AMOUNT</th>
              <th scope="col">INVOICE BALANCE</th>
              <th scope="col">AMOUNT TO CREDIT</th>
              <th>FOREX GAIN/LOSS</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.invoice_allocate_list.map(
              (invoice: any, index: number) => {
                return (
                  <ApplyToInvoiceItem
                    formik={formik}
                    errors={formik.errors.invoice_allocate_list}
                    invoiceItem={invoice}
                    currencyCode={props.currencyCode}
                    index={index}
                    conversionId={props.conversionId}
                  />
                );
              }
            )}
          </tbody>
        </table>
      </Container>
      <ApplyToInvoiceBalanceSummary
        formik={formik}
        currencyCode={props.currencyCode}
      />
      {formik.errors.credit_balance}
      <ApplyToInvoiceButtonGroup
        onCancel={() => {
          props.onClose();
        }}
        onSave={() => {}}
        isSaveDisabled={Number(formik.values.credit_balance) < 0 || isError}
      />
    </Form>
  );
};
