import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../../common/components/htmlTags/Form";
import { useLocation, useParams } from "react-router-dom";
import "./couponForm.css";
import { initialCouponDefaultValues } from "../../CouponStateInitialization";
import CouponDetails from "./CouponDetails";
import { useCouponFormIntitialization } from "../hooks/useCouponFormInitialization";
import OrgTag from "../../../../common/components/OrgTag";
import { CouponDefaultValue, CouponformDetailValue } from "../../couponType";
import { useCouponFormSubmission } from "../hooks/useCouponFormSubmission";
import useCommonData from "../../../../hooks/useCommon";
import { ObjectType } from "../../../../../types";
import ScrollToError from "../../../../common/components/scrollToError";
import { validate } from "./couponFormValidation/CouponFormValidation";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserOrgSelector } from "../../../../common/commonSlice";

const CouponForm = () => {
  const { editId } = useParams();
  const location = useLocation()
  const isClone = location.pathname.includes('clone')
  const [initialCouponValues, setInitialCouponValues] = useState<CouponDefaultValue | CouponformDetailValue>(initialCouponDefaultValues);
  const { createCouponSubmit, editCouponSubmit } = useCouponFormSubmission();
  const { fetchCouponDetails } = useCouponFormIntitialization();
  const { currentUserInfo } = useCommonData();
  const userOrganization = useAppSelector(currentUserOrgSelector)
  const [formErrors, setFormErrors] = useState<ObjectType>({})
  useEffect(() => {
    if (editId) {
      fetchCouponDetails(setInitialCouponValues, isClone);
    }
  }, [editId, currentUserInfo.organization_id]);
  
  const formik = useFormik({
    initialValues: { ...initialCouponValues },
    enableReinitialize: true,
    onSubmit: (values) => {
      let errors = validate(values, formik.errors?.name ?? '')
        if (Object.keys(errors).length) {
          setFormErrors(errors);
          ScrollToError(Object.keys(errors)[0]);
        }
        else {
        if (editId && !isClone) {
        editCouponSubmit(
          {...values, organization_id: currentUserInfo.organization_id},
           "edit"
        );;
      } else {
        createCouponSubmit(
          {...values, organization_id: currentUserInfo.organization_id},
           "create"
        );
      }
    }}
  });

  return (
    <Card wrapperClass="coupon-form card-create-item ">
    <CardHeader isBackButtonEnabled={true}>
        <div className="form-coupon-header">
         {editId && !isClone ? "Edit Coupon" : "Create Coupon"}
        <span className="coupon-header-org-span">
          <OrgTag organization={userOrganization?.find((org) => org.id === currentUserInfo.organization_id)?.name || ''} />
        </span> </div>
    </CardHeader>
    <CardBody>
      <Form
        name="add_invoice_form"
        id="add_invoice_form"
        className="add-module-item-form"
        tabIndex={-1}
      >
         <div className="fields-wrapper">
          <div className="fields-wrappers">
          <CouponDetails
            formik={formik}
            editId={editId || ''}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          </div>
          </div>
      </Form>
    </CardBody>
  </Card>
  )
};

export default CouponForm;
