import React from "react";
import { ObjectType } from "../../../types";
import "../styles/ListTable.css";

export default function Popover(props: { values: ObjectType; tag: boolean }) {
  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });
  return (
    <>
      {props.values
        .filter((value: ObjectType, index: number) => index < 2)
        .map((value: ObjectType, index: number) => {
          return (
            <>
              <div
                className={props.tag ? "tag-box" : "list-Box"}
                key={"tag-" + index}
              >
                {value.name}
                {!props.tag && props.values.length > 1 && index === 0
                  ? ","
                  : ""}
              </div>
            </>
          );
        })}
      {props.values
        .filter((value: ObjectType, index: number) => index == 2)
        .map((value: ObjectType, index: number) => {
          return (
            <a
              key={"popover-link-" + index}
              tabIndex={index}
              role="button"
              type="button"
              data-bs-toggle="popover"
              data-bs-trigger="focus"
              className="btn tag-box tag-count"
              data-bs-container="body"
              data-bs-placement="bottom"
              data-html="true"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              +{props.values.length - 2}
            </a>
          );
        })}
      <div id="popover-content-1" className="popover-content d-none">
        {props.values
          .filter((value: ObjectType, index: number) => index > 1)
          .map((value: ObjectType, index: number) => {
            return (
              <div
                className={props.tag ? "tag-box" : "list-box"}
                key={"tag-" + index}
              >
                {value.name}
              </div>
            );
          })}
      </div>
    </>
  );
}
