/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../RecurringInvoice.css";
import "../../../../common/styles/ListTable.css";
import { getTransactionLimitStatus, getTransactionLimitStatusSelector } from "../../recurringInvoiceSlice";
import Pagination from "../../../../common/components/table/Pagination";
import ListHeader from "../../../../common/components/list/ListHeader";
import useCommonData from "../../../../hooks/useCommon";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import RecurringInvoiceTableContainer from "./recurringInvoiceTable/RITableContainer";
import RIDelete from "../RIDelete";
import { useAppSelector } from "../../../../../app/hooks";
import RIVoid from "../RIVoid";
import RIStopResume from "../RIStopResume";
import { StopResumeActionData } from "../../../../../types";
import RIReminder from "../RIReminder";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";

const RecurringInvoiceList = () => {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { recurringInvoicesRolePermission } = usePermissionHandler();
  const [deleteRIProfileName, setDeleteRIProfileName] = useState("");
  const [deleteRInvoiceId, setDeleteRInvoiceId] = useState<number>(-1);
  const [reminderRInvoiceId, setReminderRInvoiceId] = useState<number>(-1);
  const [voidRInvoiceId, setVoidRInvoiceId] = useState<number>(-1);
  const [statusChangeOnAction, setStatusChangeOnAction] = useState<StopResumeActionData>({id: -1, profileName: '', triggerAction: '' });
  const [voidStatus, setVoidStatus] = useState<string>('Void');
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const transactionLimit = useAppSelector(getTransactionLimitStatusSelector);
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  useEffect(() => {
    fetchTransactionLimit();
    setIsFilterApplied(false);
  }, [currentUserInfo?.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
    sessionStorage.setItem("childInvoice", "overview");
  }, []);

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
     await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
  };
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * set recurring invoice id and recurring invoice item id when user executes Delete invoice
   */
  const deleteListData = (id: number, profileName: string) => {
    $(".dropdown-ctrl").click();
    setDeleteRInvoiceId(id);
    setDeleteRIProfileName(profileName);
  };

  const voidListData = (id: number, status: string) => {
    $(".dropdown-ctrl").click();
    setVoidRInvoiceId(id);
    setVoidStatus(status);
  };

  const sendReminder = (invoiceId: number) => {
    setReminderRInvoiceId(invoiceId);
  };

  const stopResumeListData = (id: number, profileName: string, triggerAction: boolean) => {
    setStatusChangeOnAction({id: id, profileName: profileName, triggerAction: triggerAction ? 'Resume' : 'Stop' })
  };

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };
  
  const refreshList = () => {
    filterRef.current.refreshFilter();
    if (listRef.current.fetchRecurringInvoiceList) listRef.current.fetchRecurringInvoiceList();
  };

  return (
    <>
      <div className="recurring-invoice card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Recurring Invoices"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Recurring Invoice"
          createOnClick={() => {
            transactionLimit.limit_exceed
            ? $("#transactionLimitModal").modal("show")
            : navigate("/recurring-inv/create");
          }}
          haveFilter={true}
          filterType="RI"
          applyFilter={handleApplyFilter}
          roles={recurringInvoicesRolePermission}
          ref={filterRef}
          isFiscalYearFilterRequired={false}
          module="RI"
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <RecurringInvoiceTableContainer
            transactionLimit={transactionLimit}
            initializeFilter={refreshList}
            isFilterApplied={isFilterApplied}
            deleteListData={deleteListData}
            voidListData={voidListData}
            sendReminder={sendReminder}
            stopResumeListData={stopResumeListData}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <RIDelete
        deleteRInvoiceId={deleteRInvoiceId}
        deleteRIProfileName={deleteRIProfileName}
        refreshRInvoices={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <RIVoid
        voidRInvoiceId={voidRInvoiceId}
        voidStatus={voidStatus}
        refreshRInvoices={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <RIStopResume
        invoiceId={statusChangeOnAction.id}
        profileName={statusChangeOnAction.profileName}
        triggerAction={statusChangeOnAction.triggerAction}
        refreshRInvoices={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <RIReminder
        reminderRInvoiceId={reminderRInvoiceId}
        refreshRInvoices={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
};

export default RecurringInvoiceList;
