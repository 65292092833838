import React from "react";
import { SideModal } from "../../../../../common/components/sideModal/SideModal";
import { UserForm } from "./UserForm";
import "./userForm.css";
import CreateButton from "../../../../../common/components/CreateButton";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import { ModalContext } from "../../../../../common/components/sideModal/sideModalContext";
import { EditListDataImg } from "../../../../../../assets/images";
type Props = {
  id: number;
  isEdit: boolean;
  editUrl: string;
  isMutable: boolean;
  name: string;
  isLocked: boolean;
  className: string;
  isSuperUser: boolean;
  isTransactionLimitExceed: boolean;
  refreshList: () => void;
};
const UserFormContainer = (props: Props) => {
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return props.isTransactionLimitExceed ? (
              <></>
            ) : (
              <UserForm
                userId={props.id}
                isSuperUser={props.isSuperUser}
                refreshList={props.refreshList}
                handleClose={onCloseModal}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        props.isEdit ? (
          <span>
            <img src={EditListDataImg} alt="edit-list-img" />
            <span>Edit</span>
          </span>
        ) : (
          <CreateButton label={"Invite Users"} onClick={() => {}} />
        )
      }
      modalTitle={props.id ? "Edit User" : "Invite Users"}
      buttonClass={props.isEdit ? "dropdown-item" : "user-invite-button"}
      wrapperClass="user-side-modal-wrapper"
    />
  );
};

export default UserFormContainer;
