type Props = {
  children: React.ReactNode;
  wrapperClass?: string;
  onClick?: any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
};
export const Button = (props: Props) => {
  return (
    <button
      type={props.type ? props.type : "submit"}
      onClick={props.onClick}
      className={props.wrapperClass}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.children}
    </button>
  );
};
