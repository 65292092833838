import { ObjectType } from "../../../../../../types";

// export const itemErrorSetter = (
//   fieldName: string,
//   errorArray: ObjectType[],
//   index: number
// ) => {
//   let errors: ObjectType[] = errorArray || [];
//   return errors[index]
//     ? errors[index][fieldName]
//       ? errors[index][fieldName]
//       : ""
//     : "";
// };
export const itemErrorSetter = (
  fieldName: string,
  errorArray: ObjectType[],
  touchedArray: ObjectType[],
  index: number
) => {
  let errors: ObjectType[] = errorArray || [];
  let touched: ObjectType[] = touchedArray || []
  return errors[index] && touched[index]
    ? errors[index][fieldName] && touched[index][fieldName]
      ? errors[index][fieldName]
      : ""
    : "";
};
