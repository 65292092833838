/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
  } from "react";
  import RecurringInvoiceTable from "./RecurringInvoiceTable";
  import useCommonData from "../../../../../hooks/useCommon";
  import useListData from "../../../../../hooks/useListData";
  import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
  import {
    Transactionlimit,
  } from "../../../../../../types";
  import {
    filterAppliedState,
    listFilterSelector,
  } from "../../../../../../appSlice";
  import { useAppSelector } from "../../../../../../app/hooks";
  import {
    dateListFilterSelector,
    dueDateListFilterSelector,
    fiscalYearFilterSelector,
    setLoaderState,
  } from "../../../../../common/commonSlice";
  import { recurringInvoiceList } from "../../../recurringInvoiceSlice";
  import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
  import {
    DateFormatHandler,
    StatusColor,
  } from "../../../../../../helpers/helper";
  import CustomTooltips from "../../../../../common/components/Tooltip";
  import ListActions from "./ListActions";
  import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { RecurringInvoiceList } from "../../../recurringInvoiceTypes";
import {
  riColumnValues,
  riDefaultValues,
  riMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { TAX_SYSTEM } from "../../../../../constants/constants";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import TableSetup from "../../../../../common/components/table/TableSetup";
import EmailPopover from "../../../../../common/components/EmailPopover";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, name: string) => void;
  voidListData: (id: number, name: string) => void;
  sendReminder: (id: number) => void;
  stopResumeListData: (id: number, name: string, triggerAction: boolean) => void;
};
function RecurringInvoiceTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { recurringInvoicesRolePermission } = usePermissionHandler();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } = useListData();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const [recurringInvoiceListData, setRecurringInvoiceListData] = useState<RecurringInvoiceList[]>([]); 
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["Terms"]);
  

  useImperativeHandle(
    ref,
    () => ({
      fetchRecurringInvoiceList: fetchRecurringInvoiceList,
    }),
    []
  );

  useEffect(() => {
    fetchRecurringInvoiceList();
  }, [
    page,
    itemsPerPage,
    currentUserInfo.organization_id,
    selectedFilterData,
    JSON.stringify(fiscalYearFilter),
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchRecurringInvoiceList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    riColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    if (
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !selectedColumns.includes("Tax Deducted(TDS)")
    ) {
      hiddenItems.push("Tax Deducted(TDS)");
    }
    setHiddenColumns([...hiddenItems]);
  }, [recurringInvoiceListData, selectedColumns]);

    /**
     * Fetch all Recurring invoices
     */
    const fetchRecurringInvoiceList = useCallback(async () => {
      dispatch(setLoaderState(true));
      const customerList = selectedFilterData.hasOwnProperty("Customers")
        ? selectedFilterData?.Customers
        : [];
      const responseAction = await dispatch(
        recurringInvoiceList({
          page,
          itemsPerPage,
          orgId: currentUserInfo.organization_id,
          orgIds: selectedFilterData.Organizations
            ? selectedFilterData.Organizations
            : [],
          statusList: selectedFilterData.Status
            ? getStatusFilterString(selectedFilterData.Status)
            : [],
          customerList: customerList,
          // endDate: fiscalYearFilter.endDate || "",
          // startDate: fiscalYearFilter.startDate || "",
          endDate: dateListFilter.date.endDate || "",
          startDate: dateListFilter.date.startDate || "",
          startDueDate: dueDateListFilter.dueDate.startDate,
          endDueDate: dueDateListFilter.dueDate.endDate,
        })
      );
      if (responseAction.payload) {
        setTimeout(function () {
          dispatch(setLoaderState(false));
        }, 500);
        const response = responseAction.payload as any;
        if (Object.keys(response).length && !("error" in response)) {
          setRecurringInvoiceListData(response.recurring_invoices);
          let length = response.recurring_invoices.length;
          setTotalList(response.total);
          if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else if ("error" in response) {
        }
      }
    }, [
      page,
      itemsPerPage,
      selectedFilterData,
      currentUserInfo.organization_id,
      JSON.stringify(fiscalYearFilter),
      selectedFilterData.Status,
      dateSortOrder,
      dueDateListFilter,
      dateListFilter,
    ]);

  function capitalizeWords(str: string, count: string) {
    str = count == '1'?str.replace("s", ""):str;
    let newStr = str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
    return newStr;
  }

  useEffect(() => {
    fetchTableCustomizationFields(
      "Recurring Invoices",
      riDefaultValues,
      riMandatoryValues
    );
    fetchRecurringInvoiceList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    dateListFilter,
    dueDateListFilter,
  ]);

  const getRIColumnValues = () => {
    let newRiColumnValues = JSON.parse(JSON.stringify(riColumnValues));
    if (
      currentUserInfo.organization_tax_system ===
        TAX_SYSTEM.INDIAN_TAX_SYSTEM &&
      !newRiColumnValues.includes("Tax Deducted(TDS)")
    ) {
      newRiColumnValues.push("Tax Deducted(TDS)");
    }
    return newRiColumnValues;
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => {let columns = 
      [ {
        Header: "Customer name",
        accessor: "show.customerName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value?.customer_name : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Profile name",
        accessor: "show.profileName",
        sort: false,
        sortOrder: "",
        className: "profile_name",
      },
      {
        Header: "Frequency",
        accessor: "show.frequency",
        sort: false,
        sortOrder: "",
        className: "frequency",
      },
      {
        Header: "Last Invoice Date",
        accessor: "show.lastDate",
        sort: false,
        sortOrder: "",
        className: "last_date",
      },
      {
        Header: "Next Invoice Date",
        accessor: "show.nextDate",
        sort: false,
        sortOrder: "",
        className: "next_date",
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "inv_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Amount",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "amount",
      },
      {
        Header: "Organization",
        accessor: "show.Organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: "End Date",
        accessor: selectedColumns.includes("End Date")
          ? "show.endDate"
          : "End Date",
        sort: false,
        sortOrder: "",
        className: "endDate",
        Cell: ({ cell: { value } }: any) => {
          return !!value ? value : "-";
        },
      },
      {
        Header: "Billing Address",
        accessor: selectedColumns.includes("Billing Address")
          ? "show.billingAddress"
          : "Billing Address",
        sort: false,
        sortOrder: "",
        className: "billing_addr",
      },
      {
        Header: "Email",
        accessor: selectedColumns.includes("Email") ? "show.email" : "Email",
        sort: false,
        sortOrder: "",
        className: "email",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? <EmailPopover values={value} /> : "-";
        },
      },
      {
        Header: "Shipping Address",
        accessor: selectedColumns.includes("Shipping Address")
          ? "show.shippingAddress"
          : "Shipping Address",
        sort: false,
        sortOrder: "",
        className: "shipping_addr",
      },
      {
        Header: "Created By",
        accessor: selectedColumns.includes("Created By")
          ? "show.createdBy"
          : "Created By",
        sort: false,
        sortOrder: "",
        className: "created_by",
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: (
          <TableSetup
            type="Recurring Invoices"
            tableColumns={selectedColumns}
            customizeColumnValues={getRIColumnValues()}
            mandatoryColumns={riMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Recurring Invoices",
                riDefaultValues,
                riMandatoryValues
              );
              fetchRecurringInvoiceList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!recurringInvoicesRolePermission.includes("Update") && !recurringInvoicesRolePermission.includes("Create")) ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickDelete={() => {
                props.deleteListData(value.id, value.profileName);
              }}
              onClickVoid={() => {
                props.voidListData(value.id, value.profileName);
              }}
              onStopResume={() => {
                props.stopResumeListData(value.id, value.profileName, value.isStopped)
              }}
              sendReminder={() => {
                props.sendReminder(value.id)
              }}
              send_reminder={value.send_reminder}
            />
          );
        },
      },
    ];
    if (
      currentUserInfo.organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM
    ) {
      let index = columns.length - 1;
      columns.splice(index - 1, 0, {
        Header: "Tax Deducted(TDS)",
        accessor: selectedColumns.includes("Tax Deducted(TDS)")
          ? "show.tds"
          : "Tax Deducted(TDS)",
        sort: false,
        sortOrder: "",
        className: "tds",
      });
    }
    return columns;
  },[currentUserInfo.organization_id, recurringInvoicesRolePermission, hiddenColumns, selectedColumns]
  );
  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  /**
   * set table row data
   */
  const data = recurringInvoiceListData
    ? recurringInvoiceListData.map((recurringInvoice) => ({
        show: {
          frequency:
            recurringInvoice.repeat_every === "custom"
              ? (Number(recurringInvoice.custom_repeat_count) === 1 && recurringInvoice.custom_repeat_frequency === 'days' ? 'Daily':`Every ${recurringInvoice.custom_repeat_count} ${capitalizeWords(recurringInvoice.custom_repeat_frequency, recurringInvoice.custom_repeat_count)}`)
              : capitalizeWords(recurringInvoice.repeat_every, recurringInvoice.custom_repeat_count)+'ly',
          customerName: recurringInvoice.customer_name,
          status: recurringInvoice.invoice_status,
          lastDate: DateFormatHandler(recurringInvoice.last_invoice_date),
          // nextDate: (recurringInvoice.invoice_status.toLowerCase()),
          nextDate: recurringInvoice.invoice_status.toLowerCase() === 'cancelled' ? '__' : DateFormatHandler(recurringInvoice.next_invoice_date),
          amount:
            getCurrencyCode(recurringInvoice.currency_code) +
            " " +
            NumberFormat(
              recurringInvoice.total_amount,
              recurringInvoice.currency_code,
              orgCurrencyList
            ),
          profileName: recurringInvoice.profile_name,
          Organization: recurringInvoice.organization,
          endDate: DateFormatHandler(recurringInvoice.end_date),
          billingAddress: recurringInvoice.billing_address,
          email: recurringInvoice.email_to
            .map((emailData) => emailData.email)
            .join(", "),
          shippingAddress: recurringInvoice.shipping_address,
          createdBy: recurringInvoice.created_by,
          totalTaxableAmount:
            getCurrencyCode(recurringInvoice.currency_code) +
            " " +
            NumberFormat(
              recurringInvoice.total_taxable_amount,
              recurringInvoice.currency_code,
              orgCurrencyList
            ),
          tds:
            getCurrencyCode(recurringInvoice.currency_code) +
            " " +
            NumberFormat(
              recurringInvoice.tax_deducted,
              recurringInvoice.currency_code,
              orgCurrencyList
            ),
          actions: {
            id: recurringInvoice.id,
            profileName: recurringInvoice.profile_name,
            status: recurringInvoice.invoice_status,
            organizationId: recurringInvoice.organization_id,
            isStopped: recurringInvoice.is_stopped,
            child_invoice_exists: recurringInvoice.child_invoice_exists,
            send_reminder: recurringInvoice.send_reminder
          },
        },
      }))
    : [];

  return (
      <RecurringInvoiceTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        invoicesRolePermission={recurringInvoicesRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        hiddenColumns={hiddenColumns}
      />
  );
}

export default React.memo(forwardRef(RecurringInvoiceTableContainer));
