import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ObjectType } from "../../../../../types";
import { DownloadIcon } from "../../../../common/components/customSvgIcons/downloadIcon";
import { EmailIcon } from "../../../../common/components/customSvgIcons/emailIcon";
import { VoidConfirmationModal } from "../../../../common/components/voidConfirmationPopup/VoidConfirmationModal";
import { colors } from "../../../../constants/colors";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { PurchaseOrderDetailValues } from "../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { MarkAsAcceptedIcon } from "../../../../common/components/MarkAsAcceptedIcon";
import { MarkedPopup } from "../../../../common/components/markedPopup/MarkedPopup";
import { Container } from "../../../../common/components/htmlTags/Container";
import { Span } from "../../../../common/components/htmlTags/Span";
import { CancelMarkedPopup } from "../../../../common/components/cancelMarkedPopup/CancelMarkedPopup";
import { UnmarkIcon } from "../../../../common/components/UnmarkIcon";
import { CreateInvoiceIcon } from "../../../../common/components/CreateInvoiceIcon";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { ErrorToaster, InfoToaster } from "../../../../../helpers/toastHelper";
import { DeletePurchaseOrderModal } from "../deleteCustomField/deletePurchaseOrderModal";
import {
  changePurchaseOrderStatus,
  getPurchaseOrderPdfLink,
  purchaseOrderDetails,
  purchaseOrderList,
  sendPurchaseOrderData,
} from "../../purchaseOrderSlice";
import useCommonData from "../../../../hooks/useCommon";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import PurchaseOrderDetailsBody from "./PurchaseOrderDetailsBody";
import Li from "../../../../common/components/ui/formElements/Li/Li";
import DeletePurchaseOrder from "../Delete";
import { toast } from "react-toastify";
import { downloadUsingURL } from "../../../../../helpers/helper";
import SendEmailModal from "../../../../common/components/SendEmailModal";

function PurchaseOrderDetailsHeader() {
  type LocationProps = {
    redirectTo?: string;
  };
  const location = usePreservedLocation();
  const { dispatch, currentUserInfo, navigate } = useCommonData();
  const locationState = location?.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const { purchaseOrderId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const isUnMarked = searchParams.get("unmarked");
  const { paymentsMadeRolePermission } = usePermissionHandler();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deletepurchaseOrderId, setDeletepurchaseOrderId] = useState(-1);
  const [deletepurchaseOrderItemId, setDeletepurchaseOrderItemId] =
    useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [purchaseOrderToPay, setpurchaseOrderToPay] = useState<ObjectType>({});
  const [reRenderFlag, setReRenderFlag] = useState(0);

  const purchaseOrderDetailsInitialValues = {
    id: "",
    vendor_id: "",
    user_id: "",
    organization_id: "",
    purchase_order_status: "",
    vendor_name: "",
    vendor_notes: "",
    currency_code: "",
    currency_id: null,
    purchase_order_number: "",
    reference_number: "",
    issue_date: "",
    delivery_date: "",
    payment_term_id: "",
    due_date: "",
    address_details: [],
    custom_fields: {},
    item_details: [],
    total_details: {
      total_taxable_amount: "",
      tax_amount: "",
      total: "",
      taxes: [],
      tds_percentage: 0,
      transaction_tds_amount: 0,
    },
    purchase_order_files: [],
    email_to: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
      address: "",
      logo_path: "",
      phone_number: "",
      zip_code: "",
      city: "",
    },
    is_mutable: true,
    payment_term: "",
    is_locked: false,
    lock_date: "",
    tcs_total: 0,
    excess_debit: 0,
    tds_level: "",
    tds_name: null,
    tds_percentage: null,
    tcs_name: null,
    tcs_percentage: null,
    is_fiscal_closed: false,
  };
  const [purchaseOrderData, setpurchaseOrderData] =
    useState<PurchaseOrderDetailValues>(purchaseOrderDetailsInitialValues);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  const unmarkedState = JSON.parse(
    sessionStorage.getItem("unmarkedState") ||
      JSON.stringify({ purchaseOrderId: null, isUnmarked: false })
  );
  let purchaseOrdersRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Purchase Order"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Fetch purchaseOrder Details
   */
  useEffect(() => {
    if (purchaseOrderId) {
      purchaseOrderDetail();
    }
  }, [purchaseOrderId, currentUserInfo.organization_id]);
  /**
   * Set the entered emails in the purchaseOrder data to a state.
   */
  // useEffect(() => {
  //   let emails = purchaseOrderData.email_to.map((data) => {
  //     return data.email;
  //   });
  //   setEmailList(emails);
  // }, [purchaseOrderData]);
  /**
   * Fetch purchaseOrder details
   */
  const purchaseOrderDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      purchaseOrderDetails({
        purchaseOrderId: Number(purchaseOrderId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.purchaseOrder_status === "Draft") {
          navigate("/purchaseOrder");
        }
        setpurchaseOrderData(response);
        let purchaseOrderToPayment: ObjectType = response;
        purchaseOrderToPayment["purchaseOrder_ID"] =
          response.purchaseOrder_number;
        setpurchaseOrderToPay(purchaseOrderToPayment);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.PODetail));
      }
    }
  };
  /**
   * Back button handler from purchaseOrder details page
   */
  const backFromPurchaseOrderViewHandler = () => {
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
  };
  const reRenderJournal = () => {
    setReRenderFlag(Math.floor(Math.random() * 1000));
  };
  const changePOStatus = async (
    status: "Draft" | "Open" | "Billed" | "Cancelled"
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      changePurchaseOrderStatus({
        purchaseOrderId: Number(purchaseOrderId),
        status: status,
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (response) {
      dispatch(setLoaderState(false));
      await purchaseOrderDetail();
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getPurchaseOrderPdfLink({
        purchaseOrderId: Number(purchaseOrderId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(purchaseOrderId) + ".pdf"
        );
        if (result) {
          toast.success("Purchase Order downloaded successfully!", {
            toastId: "purchase-order-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendPurchaseOrderData({
        purchaseOrderId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    // if (responseAction.payload) {
    //   const response = responseAction.payload;
    //   if (Object.keys(response).length && !("error" in response)) {
    //     toast.success("Email sent successfully!", {
    //       toastId: "po-email-sent-success",
    //       closeButton: false,
    //       position: "top-center",
    //     });
    //   }
    // }
    return responseAction;
  };
  return (
    <>
      <Header
        onBackButtonClick={() => {
          navigate(-1);
        }}
        data={{
          num: purchaseOrderData.purchase_order_number,
          status: purchaseOrderData.purchase_order_status,
        }}
        dataId={purchaseOrderId}
      />
      <Container className="card-body">
        <HeaderBody
          dataId={purchaseOrderId}
          data={{
            num: purchaseOrderData.purchase_order_number,
            status: purchaseOrderData.purchase_order_status,
            userName: purchaseOrderData.vendor_name,
            date: purchaseOrderData.issue_date,
            orgName: purchaseOrderData.organization_details.organization_name,
          }}
          isSameOrg={
            purchaseOrderData.organization_id ===
            currentUserInfo.organization_id
          }
        >
          {purchaseOrdersRolePermission.includes("Update") &&
          !purchaseOrderData.is_fiscal_closed &&
          purchaseOrderData.purchase_order_status === "Open" ? (
            <Edit
              url={`/purchase-order/edit/${purchaseOrderData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={true} //change to true after implementing edit of closed and partially paid purchaseOrder.
              name="purchaseOrder"
              isLocked={purchaseOrderData.is_locked}
              className=""
              lockDate={purchaseOrderData.lock_date}
              isVoid={false}
              // type="modal"
            />
          ) : null}
          {purchaseOrdersRolePermission.includes("Delete") &&
          !purchaseOrderData.is_fiscal_closed &&
          !purchaseOrderData.is_bill_associated &&
          purchaseOrderData.purchase_order_status !== "Cancelled" ? (
            // <Delete
            //   className="text-decoration-none delete-item"
            //   deleteIcon={true}
            //   isMutable={!purchaseOrderData.is_bill_associated}
            //   onClick={() => {
            //     if (purchaseOrderData.is_bill_associated) {
            //       setDeletepurchaseOrderId(Number(purchaseOrderData.id));
            //       setDeletepurchaseOrderItemId(
            //         purchaseOrderData.purchase_order_number
            //       );
            //     }
            //   }}
            //   isLocked={purchaseOrderData.is_locked}
            //   lockDate={purchaseOrderData.lock_date}
            //   name="Purchase Order"
            // />
            <DeletePurchaseOrderModal
              id={Number(purchaseOrderId)}
              purchaseOrderNumber={purchaseOrderData.purchase_order_number}
              reRenderPage={async () => {
                navigate("/purchase-order", { replace: true });
                await dispatch(
                  purchaseOrderList({
                    page: 1,
                    itemsPerPage: 10,
                    dateSortOrder: "ASC",
                    orgId: currentUserInfo.organization_id,
                    vendorList: [],
                    orgIds: [],
                    statusList: [],
                  })
                );
              }}
            />
          ) : null}
          {((purchaseOrderData.purchase_order_status === "Billed" &&
            purchaseOrderData.is_bill_associated === false) ||
            (purchaseOrderData.purchase_order_status === "Billed" &&
              unmarkedState.isUnmarked &&
              Number(unmarkedState.purchaseOrderId) ===
                Number(purchaseOrderData.id))) && (
            <Link
              className={""}
              style={{ textDecoration: "none" }}
              to={
                purchaseOrderData.purchase_order_status === "Billed"
                  ? `/bill/create?purchaseOrderId=${purchaseOrderId}`
                  : "#"
              }
              onClick={() => {
                if (purchaseOrderData.purchase_order_status !== "Billed") {
                  ErrorToaster(
                    "Mark the Purchase Order as Billed to create the draft bill!"
                  );
                }
              }}
            >
              <Span className="d-flex align-items-center">
                {" "}
                <CreateInvoiceIcon
                  color={colors.ceruleanBlue}
                  margin="0px 5px 0px 0px"
                />
                Create Bill
              </Span>
            </Link>
          )}
          {purchaseOrderData.purchase_order_status === "Open" && (
            <MarkedPopup
              onSave={() => {
                changePOStatus("Billed");
              }}
              onCancel={() => {}}
              className=""
              markCheckboxLabel="Copy purchase order to a bill"
              popupButtonText="Mark as Billed"
              popupTitle={`Mark ${" "} ${joinTransactionNumber(
                purchaseOrderData.purchase_order_number
              )} as Billed`}
            />
          )}
          {purchaseOrderData.purchase_order_status !== "Cancelled" && (
            <KebabMenu wrapperClass="detail-top-action-menu">
              <Li key={"emailToModal"}>
                <button
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#emailToModal"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Span>
                    <EmailIcon color={colors.battleshipGrey} />
                    Send Email
                  </Span>
                </button>
              </Li>
              <Li key={"downloadPdf"}>
                <button
                  type="button"
                  onClick={downloadPdf}
                  className="dropdown-item"
                >
                  <Span>
                    <DownloadIcon color={colors.battleshipGrey} />
                    Download PDF
                  </Span>
                </button>
              </Li>
              {purchaseOrderData.purchase_order_status === "Billed" && (
                <Li key={"openPO"}>
                  {
                    <button
                      type="button"
                      onClick={() => {
                        changePOStatus("Open");
                        sessionStorage.setItem(
                          "unmarkedState",
                          JSON.stringify({
                            purchaseOrderId: purchaseOrderId,
                            isUnmarked: true,
                          })
                        );
                      }}
                      className="dropdown-item"
                    >
                      <Span>
                        <UnmarkIcon color={colors.battleshipGrey} />
                        Unmark as Billed
                      </Span>
                    </button>
                  }
                </Li>
              )}
              {purchaseOrderData.purchase_order_status !== "Cancelled" && (
                <Li key={"cancelPO"}>
                  {
                    <CancelMarkedPopup
                      onCancel={() => {}}
                      onSave={() => {
                        changePOStatus("Cancelled");
                      }}
                      popupButtonText="Cancel"
                      popupTitle={`Cancel  ${joinTransactionNumber(
                        purchaseOrderData.purchase_order_number
                      )}`}
                    />
                  }
                </Li>
              )}
            </KebabMenu>
          )}
        </HeaderBody>
        <PurchaseOrderDetailsBody
          purchaseOrderData={purchaseOrderData}
          purchaseOrderDetail={purchaseOrderDetail}
          reRenderFlag={reRenderFlag}
        />
      </Container>
      <SendEmailModal
        emailLists={emailList}
        id={Number(purchaseOrderId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default PurchaseOrderDetailsHeader;
