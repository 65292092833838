import { CSSObject } from "@emotion/react";
import { StylesConfig } from "react-select";
import { colors } from "../../../../../../constants/colors";

const baseFontStyle: CSSObject = {
  fontFamily: "Lato",
  fontSize: "14px",
  fontStretch: "normal",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "normal",
  lineHeight: "normal",
};

type Props = {
  selectBackgroundColor?: string;
  controlHeight?: string;
  focusBorderColor?: string;
  focusTextColor?: string;
  hoverTextColor?: string;
  borderRadius?: string;
};

export const useCustomSelectStyles = (
  props: Props
): StylesConfig<any, boolean> => {
  return {
    container: (base: CSSObject) => ({
      ...base,
      clear: "both",
    }),
    control: (base: CSSObject, state: any) => ({
      ...base,
      height: props.controlHeight || "41px",
      paddingRight: "4px",
      boxShadow: "none",
      borderRadius: props.borderRadius || "0 4px 4px 0",
      border: state.isFocused
        ? `1px solid ${props.focusBorderColor || colors.ceruleanBlue}`
        : state.isDisabled
        ? `${colors.warmWhite}`
        : `1px solid ${colors.newWhisper}`,
      "&:hover": {
        border: state.isFocused
          ? `1px solid ${props.focusBorderColor || colors.ceruleanBlue}`
          : `1px solid ${colors.newWhisper}`,
      },
      backgroundColor: state.isDisabled
        ? `${colors.warmWhite}`
        : `${
            props.selectBackgroundColor
              ? props.selectBackgroundColor
              : colors.white
          }`,
    }),
    option: (base: CSSObject, state: any) => ({
      ...base,
      ...baseFontStyle,
      color:
        state.isFocused || state.isSelected
          ? props.focusTextColor || colors.ceruleanBlue
          : colors.charcoalGrey,
      backgroundColor: colors.white,
      "&:hover": {
        backgroundColor: colors.white,
        color: props.hoverTextColor || colors.ceruleanBlue,
      },
    }),
    singleValue: (base: CSSObject, state: any) => ({
      ...base,
      ...baseFontStyle,
      color: state.isDisabled
        ? `rgba(${colors.charcoalGreyRGB}, 0.5)`
        : colors.charcoalGrey,
    }),
    menu: (base: CSSObject, state: any) => ({
      ...base,
      backgroundColor: colors.white,
      border: `1px solid ${colors.newWhisper}`,
      borderRadius: "4px",
      boxShadow: `0 2px 6px 0 rgb(${colors.blackRGB} / 1%)`,
      zIndex: "15",
      height: "auto",
      marginLeft: "0",
      marginRight: "0",
      marginTop: state.placement === "bottom" ? "6px" : 0,
      marginBottom: state.placement === "top" ? "6px" : 0,
      minWidth: "30px",
      padding: "5px 0",
      textAlign: "left",
    }),
    menuList: (base: CSSObject) => ({
      ...base,
      ...baseFontStyle,
      padding: "0px 5px",
      color: colors.charcoalGrey,
      backgroundColor: colors.white,
      "&:hover": {
        backgroundColor: colors.white,
        color: colors.ceruleanBlue,
      },
    }),
    input: (base: CSSObject) => ({
      ...base,
      ...baseFontStyle,
      lineHeight: "0.71",
      color: colors.charcoalGrey,
      border: "0",
      "&:focus": {
        border: "0",
      },
      "& input": {
        border: "0",
        "&:focus": {
          border: "0 !important",
        },
      },
    }),
    valueContainer: (base: CSSObject) => ({
      ...base,
      padding: "0 12px",
      margin: "0",
    }),
    placeholder: (base: CSSObject) => ({
      ...base,
      ...baseFontStyle,
      fontWeight: "normal",
      color: `rgba(${colors.charcoalGreyRGB}, 0.55)`,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    noOptionsMessage: (base: CSSObject) => ({
      ...base,
      ...baseFontStyle,
      fontStyle: "italic",
      fontWeight: "normal",
      color: colors.battleshipGrey,
      textAlign: "left",
      padding: "10px 0 20px 0",
    }),
    indicatorSeparator: (base: CSSObject) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base: CSSObject, state: any) => ({
      ...base,
      color: colors.slate,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
      "&:hover": {
        color: colors.slate,
      },
      "& svg": {
        width: "12px",
        height: "12px",
        marginRight: "0px",
        "& path": {
          fill: colors.slate,
        },
      },
    }),
  };
};
