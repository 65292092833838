import React from "react";
type Props = {
  name: string;
  isSignIn: boolean;
};
const UserName = (props: Props) => {
  return (
    <div className="user-name-with-pending">
      <span className="ellipsis">{props.name}</span>
      {!props.isSignIn && (
        <div className="pending-rectangle">
          <span className="invite-pending">Pending</span>
        </div>
      )}
    </div>
  );
};

export default UserName;
