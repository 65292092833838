import { useRef } from "react";
import { PlanItemDetails } from "../../../home/plan/planType";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import useCommonData from "../../../hooks/useCommon";

type Props = {
    itemDetail: PlanItemDetails;
    index: number;
    currencyCode: string;
};

const ItemDetails = (props: Props) => {
  const ref: any = useRef();
  const { orgCurrencyList } = useCommonData();

  return (
    <div ref={ref} className={`plan-box-wrapper ` + (props.index === 0 ? "" : "plan-add-line")}>
          <div className="plan-item">
              <div className="plan-item-header">
                  {props.itemDetail.item_name}
              </div>
              <div className="plan-item-detail">
                  <p className="plan-item-detail-data">
                      {props?.itemDetail?.hsn_or_sac_code ? <span>HSN/SAC: {props.itemDetail.hsn_or_sac_code}</span> : null}<span className={props?.itemDetail?.hsn_or_sac_code ? "plan-item-rate" : "remove-padding"}> Rate: <span>{`${props.currencyCode || ''} ${NumberFormat(
                          props.itemDetail.rate,
                          props.currencyCode,
                          orgCurrencyList
                      ) || ''}`}</span></span>
                  </p>
                  <p >
                      {`Account: ${props?.itemDetail?.account_name || ''}`}
                  </p>
              </div>
          </div>
    </div>
  );
};

export default ItemDetails;