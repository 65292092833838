import { FormControl, RadioGroup } from "@mui/material";

type Props = {
  name: string;
  value: string;
  children: React.ReactNode;
  handleChange: (e: any) => void;
};
function RadioButton(props: Props) {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
      >
        {props.children}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButton;
