import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { currentModule, setListFilters } from "../../../../appSlice";
import { getStatusIds } from "../../../../helpers/statusFilterHelper";
import { ObjectType, TopList } from "../../../../types";
import { currentUserSelector } from "../../../common/commonSlice";
import { getTopPayablesList } from "../../reports/ReportSlice";
import DashboardTable from "./DashboardTable";

function TopPayables() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [topPayables, setTopPayables] = useState<TopList[]>([]);

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    ) {
      fetchTopPayables();
    }
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const fetchTopPayables = async () => {
    const responseAction = await dispatch(
      getTopPayablesList({
        orgId: currentUserInfo.organization_id,
        currencyId: currentUserInfo.organization_currency,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      let topPayablesData = response.map((vendor: ObjectType) => {
        let transactionsCount =
          vendor.total_no_of_bills > 1
            ? vendor.total_no_of_bills + " bills"
            : vendor.total_no_of_bills + " bill";
        return {
          id: vendor.vendor_id,
          name: vendor.vendor_name,
          totalAmount: vendor.total_amount,
          noOfTransactions: transactionsCount,
        };
      });
      setTopPayables(topPayablesData);
    } else {
      setTopPayables([]);
    }
  };
  /**
   * Clicks on vendor in top payable list navigate corresponding vendor transactions in bills
   */
  const showTransaction = (id: number) => {
    dispatch(currentModule("Bills"));
    const statusArray = ["Open", "Overdue", "Partially Paid"];
    const statusIds = getStatusIds(statusArray);
    dispatch(
      setListFilters({
        Organizations: [],
        Vendors: [id],
        Status: statusIds,
      })
    );
    navigate("/bill", { state: { isDashboard: true } });
  };
  return (
    <DashboardTable
      title="Top Payables"
      data={topPayables}
      handleOnClick={showTransaction}
    />
  );
}

export default React.memo(TopPayables);
