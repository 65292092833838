import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReportTagFormValues } from "../../../../types";
import {
  createNewReportingTag,
  detailsReportingTags,
  editReportingTag,
  enableDisableTag,
  listReportingTags,
  removeReportingTag,
  removeTagOption,
  reportingTagNameUniqueness,
  tagOptionDropdown,
  splitAmount,
} from "./reportingTagAPI";
import { RootState } from "../../../../app/store";

export const createReportingTag = createAsyncThunk(
  "createReportingTag",
  async (data: { values: ReportTagFormValues; orgId: number }) => {
    return await createNewReportingTag(data);
  }
);
export const checkReportingTagNamexists = createAsyncThunk(
  "reportingTagNameUniqueness",
  async (data: { orgId: number; tagName: string; signal: AbortSignal }) => {
    return await reportingTagNameUniqueness(data);
  }
);

export const updateReportingTag = createAsyncThunk(
  "updateReportingTag",
  async (data: { values: ReportTagFormValues; orgId: number; id: number }) => {
    return await editReportingTag(data);
  }
);

export const getReportingTagList = createAsyncThunk(
  "listReportingTags",
  async (data: { pageSize: number; pageNum: number; orgId: number }) => {
    return await listReportingTags(data);
  }
);

export const getReportingTagDropdown = createAsyncThunk(
  "reportingTagsDropdown",
  async (data: { orgId: number }) => {
    return await tagOptionDropdown(data);
  }
);

export const getReportingDetails = createAsyncThunk(
  "reportingTagsDetails",
  async (data: { orgId: number; id: number }) => {
    return await detailsReportingTags(data);
  }
);

export const deleteReportingTag = createAsyncThunk(
  "reportingTagsDetails",
  async (data: { orgId: number; id: number }) => {
    return await removeReportingTag(data);
  }
);

export const deleteReportingTagOption = createAsyncThunk(
  "reportingTagsDetails",
  async (data: { orgId: number; optionIds: number[] }) => {
    return await removeTagOption(data);
  }
);

export const configureReportingTags = createAsyncThunk(
  "reportingTagsConfiguration",
  async (data: { orgId: number; isEnabled: boolean; isMandatory: boolean }) => {
    return await enableDisableTag(data);
  }
);

export const getSplitAmount = createAsyncThunk(
  "splitAmount",
  async (data: {
    orgId: number;
    splitType: string;
    transactionType: string;
    payload: any;
    signal?:AbortSignal;
  }) => {
    return await splitAmount(data);
  }
);

interface ReportingTagState {
  reportingTagsDropdown: ReportTagFormValues[];
}
const initialState: ReportingTagState = {
  reportingTagsDropdown: [],
};

export const reportingTagSlice = createSlice({
  name: "reportingTag",
  initialState,
  reducers: {
    clearReportingTagList: (state) => {
      state.reportingTagsDropdown = [];
    },
    setReportingTagListAction: (state, { payload }) => {
      state.reportingTagsDropdown = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportingTagDropdown.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.reportingTagsDropdown = payload;
      }
    });
  },
});

export default reportingTagSlice.reducer;

export const { clearReportingTagList, setReportingTagListAction } =
  reportingTagSlice.actions;

export const reportingTagsListSelector = (state: RootState) =>
  state.reportingTag.reportingTagsDropdown;
