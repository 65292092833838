import { useEffect, useState } from "react";
import "./SavePreferenceForm.css";
import _ from "lodash";
import { RadioButtonGroup } from "../../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Grid } from "@mui/material";
import { DropdownStateType, RoundOffPreferences } from "../../../recurringInvoiceTypes";
import CustomSearchSelect from "../../../../../common/components/CustomSearchSelect/CustomSearchSelect";
import useCommonData from "../../../../../hooks/useCommon";
import { accountsGlCodeSelector } from "../../../../chartOfAccounts/coaSlice";
import { useAppSelector } from "../../../../../../app/hooks";
type Props = {
  handleClose: () => void;
  handleSave: (paymentMode: any) => void;
  selectedValue: string;
  optionsArray: DropdownStateType[];
  roundoffData?: RoundOffPreferences;
  onAccountChange?: (val: DropdownStateType) => void;
  onIsTotalEditable?: (event: any) => void;
  formErrors?: any;
  handleCloseOnSave: () => void;
};
function SavePreferenceForm({ handleClose, handleSave, selectedValue, optionsArray, roundoffData, onAccountChange, onIsTotalEditable, formErrors, handleCloseOnSave }: Props) {
  const [paymentMode, setPaymentMode] = useState(selectedValue);
  const { currentUserInfo } = useCommonData();
  const accountsGlCode = useAppSelector(accountsGlCodeSelector);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [accountValidationError, setAccountValidationError] = useState<string>('');
  const handleCloseModal = () => {
    handleClose();
  };
  const onSave = async () => {
    if(paymentMode === "Round off" && !roundoffData?.account_id) {
      setAccountValidationError(formErrors.accountValidationError || "Please select the value")
      return
    }
    handleSave(paymentMode)
    handleCloseOnSave();
  }

  useEffect(() => {
    setIsChecked(roundoffData?.is_total_editable || false);
  }, [roundoffData?.is_total_editable]);

  const onPreferenceAccountChange = async (val: any) => {
    if (onAccountChange){
    let value: DropdownStateType = { label: accountsGlCode.account_list.filter((data) => data?.id === val)?.[0]?.account_name || '', value: val || 0}
    onAccountChange(value)
  }
  }
  return (
    <div className="ri-preference tds-form-modal-wrapper">
      <form id="tds-form" className="tds-form tds-form-body-wrapper">
        <div className="tds-form-input-wrapper">
        </div>
        <div className="tds-form-input-wrapper">
          <div className="tds-radio-wrapper d-flex">
            <RadioButtonGroup
              name="rateOrAmount"
              id="rateOrAmount"
              valuesArray={optionsArray}
              value={paymentMode}
              icon={
                <span>
                  <RadioButtonUncheckedIcon />
                </span>
              }
              checkedIcon={
                <span>
                  <RadioButtonCheckedIcon className="radio-btn-tds" />
                </span>
              }
              isDisabled={false}
              onClick={(e) => setPaymentMode(e)}
              radioStyle="radioButtonLabel"
            />
          </div>
        </div>
        {paymentMode === "Round off" && roundoffData ? <><div className="row roundoff-preference">
          <div className="col accnt-col w-200 pright-20">
            <label htmlFor="account" className="required">
              Account
            </label>
            <CustomSearchSelect
              itemAccount={Number(roundoffData.account_id)}
              itemAccountName={roundoffData.account_name}
              organizationId={currentUserInfo.organization_id}
              handleItemAccountChange={onPreferenceAccountChange}
              itemIndex={0}
              error={
                accountValidationError !== ''
                  ? "error"
                  : ""
              }
              baseAccount="Incomes~Expenses"
            />
            <span className="error">
              {accountValidationError !== '' ? accountValidationError : ''}
            </span>
          </div>
        </div>
        <Grid container className="form-check">
          <Grid item>
          <input
            className="form-check-input"
            type="checkbox"
            id="grantaccess"
            name="is_total_editable"
            onChange={(e) => {
              if (onIsTotalEditable) onIsTotalEditable(e)
            }}
            checked={isChecked}
          />
          </Grid>
          <Grid item>
          <label className="form-check-label" htmlFor="grantaccess">
            Allow users to edit the form
          </label>
          </Grid>
        </Grid>
        </> : null}
        <div className="tds-form-button-wrapper w-100" id="tds-create-button">
          <button
            className="save-button"
            type="button"
            onClick={onSave}
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
export default SavePreferenceForm;
