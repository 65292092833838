import React, { useMemo } from "react";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import {
  CreditNoteAllocatedList,
  CreditNoteDetailValues,
} from "../../../../../types";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import useCommonData from "../../../../hooks/useCommon";
import { Link } from "react-router-dom";
import { EditIcon } from "../../../../common/components";
import ApplyToInvoicesModal from "../creditNoteDetail/ApplyToInvoicesModal";

type Props = {
  invoiceList: CreditNoteAllocatedList[];
  module: string;
  creditNoteData: CreditNoteDetailValues;
  isParent: boolean;
  key: string;
  refreshCNDetails: () => void;
  creditNoteRolePermission: any;
  subModulesWithPermission: any;
};

function InvoiceCredited(props: Props) {
  const { currentUserInfo, orgCurrencyList } = useCommonData();

  /**
   * Credited table details
   */
  const creditedColumn = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "show.invoiceNumber",
        className: "column-navigation",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={
                props.subModulesWithPermission.includes("Invoices")
                  ? `/invoice/detail/${value.id}`
                  : ""
              }
              className="transaction-number-cn-pr"
            >
              {value.transactionNumber}
            </Link>
          );
        },
      },
      {
        Header: "Invoice Date",
        accessor: "show.date",
        className: "",
      },
      {
        Header: "Invoice Amount",
        accessor: "show.invoiceAmount",
        className: "",
      },
      {
        Header: "Invoice Balance",
        accessor: "show.invoiceBalance",
        className: "",
      },
      {
        Header: "Amount Credited",
        accessor: "show.amountCredited",
        className: "",
      },
      {
        Header: "Forex Gain/Loss",
        accessor: "show.forex",
        className: "",
      },
      {
        Header: "",
        accessor: "action",
        className: "",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              {props.creditNoteRolePermission.includes("Update") &&
              props.creditNoteData.organization_id ===
                currentUserInfo.organization_id && 
                !value.is_fiscal_closed ? (
                <ApplyToInvoicesModal
                  invoiceId={value.invoiceId}
                  buttonComponent={<EditIcon color="blue" />}
                  creditNoteData={props.creditNoteData}
                  refreshCNDetails={props.refreshCNDetails}
                />
              ) : null}
            </>
          );
        },
      },
    ],
    [
      currentUserInfo.organization_id,
      props.creditNoteData.organization_id,
      // props.creditNoteRolePermission,
    ]
  );

  const creditedTableData = props.invoiceList
    ? props.invoiceList.map((invoice, index) => ({
        show: {
          date: DateFormatHandler(invoice.date),
          invoiceNumber: {
            transactionNumber: joinTransactionNumber(invoice.invoice_number),
            id: invoice.invoice_id,
          },
          invoiceAmount:
            props.creditNoteData.currency_code +
            " " +
            NumberFormat(
              Number(invoice.invoice_amount),
              props.creditNoteData.currency_code,
              orgCurrencyList
            ),
          invoiceBalance:
            props.creditNoteData.currency_code +
            " " +
            NumberFormat(
              Number(invoice.invoice_balance),
              props.creditNoteData.currency_code,
              orgCurrencyList
            ),
          amountCredited:
            props.creditNoteData.currency_code +
            " " +
            NumberFormat(
              Number(invoice.amount_credited),
              props.creditNoteData.currency_code,
              orgCurrencyList
            ),
          forex: invoice["forex_gain/loss"]
            ? invoice?.currency_code +
              " " +
              NumberFormat(
                Number(invoice["forex_gain/loss"]),
                invoice?.currency_code,
                orgCurrencyList
              )
            : "-",
        },
        action: {
          url: `/invoice/detail/${invoice.invoice_id}`,
          permission: props.subModulesWithPermission.includes("Invoices"),
          customNavigation: true,
          type: "creditAllocationModal",
          allocationId: invoice.id,
          invoiceNumber: joinTransactionNumber(invoice.invoice_number),
          invoiceId: invoice.invoice_id,
          is_fiscal_closed: invoice.is_fiscal_closed,
        },
      }))
    : [];

  return (
    <DetailsTableContainer
      columns={creditedColumn}
      data={creditedTableData}
      className="list-table table cd-list-table w-100"
      haveColumnClassName={true}
      hiddenColumns={[]}
    />
  );
}

export default InvoiceCredited;
