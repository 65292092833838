import { FormikProps } from "formik";
import { DateFormatHandler, getFormatedDate } from "../../../../helpers/helper";
import { SaveButton, SecondaryButton } from "../button";
import CloseButton from "../button/CloseButton";
import InputPrefix from "../formPrefixInputField/InputPrefix";
import "../../../../index.css";
import "./consumerPaymentEdit.css";
import { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  createExchangeRateList,
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../commonSlice";
import {
  calculateConversionFromAmount,
  createReverseExchangeRate,
  exchangeRateApiStatusSelector,
} from "../exchangeRate/exchangeRateSlice";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../helpers/roundOffHelper";
import useDebounced from "../../../hooks/useDebounced";

type Props = {
  formik: FormikProps<any>;
  label: string;
  subLabel: string;
  prefix: string;
  name: string;
  id: string;
  value: string;
  isDisabled: boolean;
  error: string;
  handleBlur: (name: string, amount: number, index: number) => void;
  autoFocus?: boolean;
  conversionDate: string;
  currencyCode: string;
  conversionCurrencyCode: string;
  editId: string;
  conversionRate: string;
  isButtonDisabled: boolean;
  handleClose: () => void;
  placeholder?: string;
};
export const ConsumerPaymentEditCard = (props: Props) => {
  const [value, setValue] = useState(
    props.formik.values[`${props.name}_bank_currency`]
  );
  const [isApiPending, setApiPending] = useState(false);
  const [reverseConversion, setReverseConversion] = useState<number>(0);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const isCurrencyApiPending = useAppSelector(exchangeRateApiStatusSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);

  const debouncedValue = useDebounced(value, 500);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    setReverseConversion(props.formik.values.bank_conversion_rate);
  }, [props.formik.values.bank_conversion_rate]);

  const handleSave = async () => {
    await setReverseCustomConversionRate();
  };
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getConversionRate(signal).finally(() => {
      setApiPending(false);
    });
    return () => {
      controller.abort();
    };
  }, [debouncedValue]);

  const setReverseCustomConversionRate = async () => {
    const responseAction = await dispatch(
      createExchangeRateList({
        values: {
          to_currency: props.formik.values.bank_currency_id,
          base_currency: props.formik.values.currency_id,
          rate: reverseConversion,
          by_user: true,
          get_reverse_conversion: true,
          timestamp: props.conversionDate,
          enable_rounding:
            String(reverseConversion).includes(".") &&
            String(reverseConversion).split(".")[1].length > 6
              ? false
              : true,
        },
      })
    ).finally(() => {
      props.handleClose();
    });
    const response = responseAction.payload;
    if (response) {
      if (
        props.formik.values.bank_currency_id ===
        currentUserInfo.organization_currency
      ) {
        props.formik.setValues((formikValues: any) => {
          return {
            ...formikValues,
            conversion_id: response.id,
            conversion_rate: response.rate,
            conversion_timestamp: getFormatedDate(
              response.timestamp
            ),
            bank_conversion_id: response.id,
            bank_conversion_rate: response.rate,
            bank_conversion_timestamp: getFormatedDate(
              response.timestamp
            ),
            [`${props.name}_bank_currency`]: value,
            by_user: true,
            is_currency_changed: false,
            is_date_changed: false,
          };
        });
      } else {
        props.formik.setValues((formikValues: any) => {
          return {
            ...formikValues,
            bank_conversion_id: response.id,
            bank_conversion_rate: response.rate,
            bank_conversion_timestamp: getFormatedDate(
              response.timestamp
            ),
            [`${props.name}_bank_currency`]: value,
            by_user: true,
            is_currency_changed: false,
            is_date_changed: false,
          };
        });
      }
      // props.formik.setFieldValue(`${props.name}_bank_currency`, value);
    }
  };
  const getConversionRate = async (signal: AbortSignal) => {
    const decimals = decimalPlaceOfCurrency(
      props.formik.values.bank_currency_code,
      orgCurrencyList
    );
    if (value[value.length - 1] === "." || isInitialLoad) {
      return;
    }
    const roundedAmount = getRoundOffAmount(value, decimals);
    setValue(roundedAmount);

    if (!roundedAmount || Number(roundedAmount) === 0) {
      return;
    }
    const responseAction = await dispatch(
      calculateConversionFromAmount({
        orgId: currentUserInfo.organization_id,
        amount: Number(props.formik.values[`${props.name}`]),
        amount_bank_currency: Number(roundedAmount),
        to_currency_id: props.formik.values.bank_currency_id,
        signal: signal,
      })
    );
    const response = responseAction.payload;
    if (response) {
      setReverseConversion(response.reverse_conversion);
    }
  };
  return (
    <div className="consumer-payment-card">
      <div className="consumer-payment-title ">
        <span>Update {props.label}</span>
        <div>
          <CloseButton closeModalHandler={props.handleClose} />
        </div>
      </div>
      <div className="consumer-payment-amount-field">
        <label
          htmlFor="amount_received_bank_currency mw-325"
          className="required"
        >
          {props.label}{" "}
          {props.subLabel && (
            <span className="sub-label">({props.subLabel})</span>
          )}
        </label>
        <InputPrefix
          prefix={props.prefix}
          inputType="text"
          name={`${props.name}_popper`}
          id={props.id}
          className="form-control border-end-0"
          placeholder={props.placeholder}
          value={value}
          disabled={props.isDisabled}
          error={props.error}
          isAmountField={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            setApiPending(true);
            setInitialLoad(false);
          }}
          onBlur={() => {
            // handleOnBlur(`${props.name}_popper`, value, 0);
          }}
          index={0}
          onKeyPress={handleKeyPress}
          autoFocus={true}
          wrapperClass="consumer-payment-edit-prefix-input"
        />
        <span className="error">{props.error}</span>
      </div>
      {Number(value) !== 0 && (
        <div className="consumer-exchange-rate-wrap">
          <span className="fw-bold">Exchange rate</span>
          <div className="consumer-exchange-rate-display-wrap d-flex">
            <span className="consumer-exchange-date">
              {`As on (${DateFormatHandler(props.conversionDate)})`}
            </span>
            <span className="consumer-exchange-rate fw-bold">
              {!isApiPending &&
                `1 ${props.conversionCurrencyCode} = ${reverseConversion} ${props.currencyCode}`}
            </span>
          </div>
        </div>
      )}
      <div className="consumer-amount-edit-info">
        <span>
          The exchange rate will adjust based on the edits to the "{props.label}
          "{" "}
        </span>
      </div>
      <div className="consumer-amount-edit-action-btns">
        <SaveButton
          type="button"
          isDisabled={
            !value ||
            Number(value) === 0 ||
            isApiPending ||
            Number(reverseConversion) === 0 ||
            value[value.length - 1] === "."
          }
          onClick={handleSave}
          buttonContent="Save"
        />
        <SecondaryButton
          type="button"
          isDisabled={props.isButtonDisabled}
          onClick={props.handleClose}
          buttonContent="Cancel"
        />
      </div>
    </div>
  );
};
