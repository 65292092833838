import { decimalPlaceOfCurrency } from "../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../helpers/roundOffHelper";

export const getRoundedAmount = (
  currencyCode: string,
  orgCurrencyList: any,
  amount: number
) => {
  const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
  const roundedAmount = getRoundOffAmount(amount, decimals);
  return roundedAmount;
};
