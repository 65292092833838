import { SvgIconProp } from "../../../../types";

export const NoSearchResultIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width || "30px"}
      height={props.height || "30px"}
      viewBox="0 0 30 30"
    >
      <g fill={props.color} fillRule={"nonzero"}>
        <path d="M22.526 20.734A12.713 12.713 0 0 0 3.7 3.7a12.714 12.714 0 0 0 8.044 21.64c3.246.248 6.464-.76 8.99-2.814l7.168 7.168a1.272 1.272 0 0 0 1.792-1.792l-7.168-7.168zm-17.034-.851a10.17 10.17 0 1 1 14.377 0 10.107 10.107 0 0 1-14.377 0z" />
        <path d="M8.59 17.3c.199.2.456.3.713.3.257 0 .514-.1.714-.3l2.783-2.784 2.784 2.784c.2.2.456.3.713.3a1.006 1.006 0 0 0 .714-1.714l-2.798-2.784 2.798-2.8a1.009 1.009 0 0 0 0-1.413 1.008 1.008 0 0 0-1.413 0L12.8 11.688l-2.783-2.785a1.008 1.008 0 0 0-1.414 0 1.009 1.009 0 0 0 0 1.414l2.784 2.785L8.589 15.9a.991.991 0 0 0 0 1.399z" />
      </g>
    </svg>
  );
};
