import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";

import useCommonData from "../../../../hooks/useCommon";
import TransactionPlanHeader from "./TransactionPlanHeader";
import DefaultDetails from "./DefaultDetails";
import { useParams } from "react-router-dom";

import { PlanDefaultValues } from "../../../../../types";
import { currentUserOrgSelector, setLoaderState } from "../../../../common/commonSlice";
import { itemDropdown, itemDropdownSelector } from "../../../../home/items/itemSlice";
import { checkInternalNameExist, createPlan, planDetailsForEdit, editPlan } from "../../planSlice";
import {
    initialDefaultValues,
} from "./StateInitialization";
import "./planForm.css";
import { toast } from "react-toastify";

let interval: any = null;

const PlanForm = () => {
    const { editId } = useParams();

    const { dispatch, navigate, currentUserInfo } = useCommonData();
    const itemDropdownList = useAppSelector(itemDropdownSelector);
    const userOrganization = useAppSelector(currentUserOrgSelector)
    const [isInternalNameExist, setIsInternalNameExist] = useState(false);
    const [planData, setPlanData] = useState<PlanDefaultValues>({
        ...initialDefaultValues,
    });

    const checkInternalName = async (internalName: string) => {
        if (!internalName.includes("undefined")) {
            if (interval) {
                clearTimeout(interval);
            }
            interval = setTimeout(async () => {
                const responseAction = await dispatch(
                    checkInternalNameExist({
                        internalName: internalName,
                        orgId: currentUserInfo.organization_id,
                        plan_id: editId ? Number(editId) : 0,
                    })
                );
                if (responseAction.payload) {
                    const response = responseAction.payload;
                    if (Object.keys(response).length && !("error" in response)) {
                        if (response.internal_name_available === false) {
                            setIsInternalNameExist(true);
                        } else {
                            setIsInternalNameExist(false);
                        }
                    }
                }
            }, 1000);
        }
    };

    useEffect(() => {

        dispatch(setLoaderState(true));
        const fetchItems = async () => {
            if (currentUserInfo.organization_id) {
                await dispatch(
                    itemDropdown({
                        currencyId: currentUserInfo.organization_currency,
                        conversionId: 0,
                        orgId: currentUserInfo.organization_id,
                        transaction_type: "SALES",
                    })
                );
                if (editId) {
                    const responseAction = await dispatch(
                        planDetailsForEdit({
                            planId: editId,
                            orgId: currentUserInfo.organization_id,
                        })
                    );
                    if (responseAction.payload) {
                        setTimeout(function () {
                            dispatch(setLoaderState(false));
                        }, 500);
                        const response = responseAction.payload;
                        if (Object.keys(response).length && !("error" in response)) {
                            setPlanData(response);
                        } else if ("error" in response) {
                        }
                    }
                }
            }
            dispatch(setLoaderState(false));
        }
        fetchItems()
    }, [currentUserInfo, dispatch]);


    const createPlanAPI = async (data: any) => {
        dispatch(setLoaderState(true));
        const createPlanAction = await dispatch(
            createPlan({
                values: data,
                orgId: currentUserInfo.organization_id,
            })
        );
        if (createPlanAction.payload) {
            const createInvoiceResponse = createPlanAction.payload;
            if (
                Object.keys(createInvoiceResponse).length &&
                !("error" in createInvoiceResponse)
            ) {
                if (createInvoiceResponse.plan_id) {
                    navigate(`/plan/detail/${createInvoiceResponse.plan_id}`, {
                        replace: true,
                    });
                } else {
                    navigate(`/plan`, {
                        replace: true,
                    })
                }
                dispatch(setLoaderState(false));
                toast.success("Plan created successfully!", {
                    toastId: "invoice-create-success",
                    closeButton: false,
                    position: "top-center",
                });
            } else if ("error" in createInvoiceResponse) {
                dispatch(setLoaderState(false));
            } else {
                dispatch(setLoaderState(false));
            }
        }
    }

    const editPlanAPI = async (data: any) => {
        dispatch(setLoaderState(true));
        const editPlanAction = await dispatch(
            editPlan({
                values: data,
                planId: editId,
                orgId: currentUserInfo.organization_id,
            })
        );
        if (editPlanAction.payload) {
            const editInvoiceResponse = editPlanAction.payload;
            if (
                Object.keys(editInvoiceResponse).length &&
                !("error" in editInvoiceResponse)
            ) {
                navigate(`/plan/detail/${editId}`, {
                    replace: true,
                });
                dispatch(setLoaderState(false));
                toast.success("Plan updated successfully!", {
                    toastId: "invoice-create-success",
                    closeButton: false,
                    position: "top-center",
                });
            } else if ("error" in editInvoiceResponse) {
                dispatch(setLoaderState(false));
            } else {
                dispatch(setLoaderState(false));
            }
        }
    }

    const onSubmit = (data: any) => {
        if (editId) {
            editPlanAPI(data)
        } else {
            createPlanAPI(data)
        }
    }

    return (
        <div
            className="plan-form card card-user-management card-customer card-create-item invoice-create main-card overflowX-hidden h-100"
            id="invoice-create"
        >
            <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
                <TransactionPlanHeader
                    transactionName="Plan"
                    transactionNumber={Number(editId)}
                    organizationName={userOrganization.find((org) => org.id === currentUserInfo.organization_id)?.name}
                />
            </div>

            <div className="card-body">
                <form
                    name="add_invoice_form"
                    id="add_invoice_form"
                    className="add-module-item-form"
                    tabIndex={-1}
                >
                    <div className="fields-wrapper pt-0">
                        <div className="fields-wrappers">
                            <DefaultDetails
                                itemDropdownList={itemDropdownList}
                                checkInternalName={checkInternalName}
                                isInternalNameExist={isInternalNameExist}
                                onClickSubmit={onSubmit}
                                planData={planData}
                                editId={editId}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PlanForm;
