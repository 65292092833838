import React from "react";
import useCommonData from "../../../../hooks/useCommon";
import { getSundryDebtorSummary } from "../../ReportSlice";
import AgingSummary from "./AgingSummary";

function SundryDebtorSummary() {
  const { dispatch, currentUserInfo } = useCommonData();

  const generateReport = async (filterValues: any) => {
    const responseAction = await dispatch(
      getSundryDebtorSummary({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  return (
    <AgingSummary
      title="Sundry Debtor Aging Summary for Invoice Due Date"
      generateReport={generateReport}
      type="debtor"
    />
  );
}

export default SundryDebtorSummary;
