import TdsPreference from "./TdsPreference";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import TDSPreferenceButton from "../../../../common/components/TDSPreferenceButton";
import { SettingsGreenIcon } from "../../../../../assets/images";
import { SettingsIcon } from "../../../../common/components/customSvgIcons/settingsIcon";
import { colors } from "../../../../constants/colors";
// import "../../../../common/styles/TDSPreferenceButton.css";

function TdsPreferenceContainer() {
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <TdsPreference closeModal={onCloseModal} />;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        // <TDSPreferenceButton label="TDS Preferences" />

        <span className="d-flex align-center">
          <SettingsIcon color={colors.booger} /> TDS Preferences
        </span>
      }
      modalTitle="TDS Preference"
      buttonClass="no-appearance w-100 text-nowrap cancel-button"
      wrapperClass="w-100"
    />
  );
}
export default TdsPreferenceContainer;
