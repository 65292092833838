import { CSSProperties } from "react";
import { EmailInfoIcon } from "../../../../assets/images";
import "./InfoMessage.css";
type Props = {
  message: string;
  textClass?: string;
  wrapperClass?: string;
  hideInfoIcon?: boolean;
};
export const InfoMessage = (props: Props) => {
  return (
    <div
      className={`alert-msg info-alert-masg d-flex align-items-center w-100 mb-2 mt-1 alert-message-background-class ${
        props.wrapperClass || ""
      }`}
    >
      {props.hideInfoIcon !== true ? (
        <img
          src={EmailInfoIcon}
          alt="info icon"
          style={{ marginRight: "5px" }}
        />
      ) : (
        <></>
      )}
      <p className={`mb-0 ${props.textClass || ""}`}>{props.message}</p>
    </div>
  );
};
