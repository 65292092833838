import { SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { deleteReportingTag } from "../reportingTagAPIFiles/reportingTagSlice";
import { useFetchReportingTagList } from "./useFetchReportingTagList";

export const useDeleteTag = () => {
  const { getReportingTags } = useFetchReportingTagList();
  const { currentUserInfo, dispatch } = useCommonData();
  const deleteTag = async (
    id: number,
    setReportingTagsList?: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteReportingTag({
        orgId: currentUserInfo.organization_id,
        id: id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Reporting tag deleted successfully!",
        "reporting-tag-delete-success"
      );
      getReportingTags(setReportingTagsList);
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { deleteTag };
};
