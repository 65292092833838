import { toast } from "react-toastify";
import { deleteTax } from "../taxConfigurationSlice";
import DeleteModal from "../../../common/components/DeleteModal";
import useCommonData from "../../../hooks/useCommon";
type Props = {
  deleteTaxId: number;
  handleModalClose: () => void;
};
function DeleteTax(props: Props) {
  const { dispatch, currentUserInfo } = useCommonData();

  /**
   * Function to handle delete tax
   */
  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      deleteTax({
        taxId: props.deleteTaxId,
        orgId: currentUserInfo.organization_id,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        $(".delete-tax-close-button").click();
        toast.success("Tax deleted successfully!", {
          toastId: "tax-delete-success",
          closeButton: false,
          position: "top-center",
        });
        props.handleModalClose();
      } else if ("error" in response) {
        $(".delete-tax-close-button").click();
      }
    }
  };

  /**
   * Cancel button handler in delete tax pop up
   */
  const modalCancelHandler = () => {
    $(".delete-tax-close-button").click();
  };

  return (
    <DeleteModal
      heading=" Delete Tax!"
      subHeading="Are you sure you want to delete this tax?"
      transactionName=""
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
      closeButtonClassName="delete-tax-close-button"
    />
  );
}
export default DeleteTax;
