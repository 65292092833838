import Popover from "@mui/material/Popover";
import { useState } from "react";
import "../../../../../index.css";
import { CurrencyList } from "../../../../../types";
import { colors } from "../../../../constants/colors";
import { EditIcon } from "../../customSvgIcons/editIcon";
import { Container } from "../../htmlTags/Container";
import "./MultipleExchangeRate.css";
import { MultipleExchangeRateForm } from "./MultipleExchangeRateForm";

type MultipleExchangeRateProps = {
  module?: string;
  currencyId: number | string;
  toCurrencyId: number | string;
  toCurrencyCode: string;
  conversionDate: string;
  conversionRate: string;
  exchangeRateSubmitHandler?: (data: CurrencyList) => void;
  maxLength?: number;
};
export const MultipleExchangeRatePopper = (
  props: MultipleExchangeRateProps
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Container className="">
      <button
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
        className="no-appearance"
      >
        <EditIcon color={colors.ceruleanBlue} height="12px" width="12px" />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MultipleExchangeRateForm
          {...props}
          maxLength={props.maxLength}
          handleClose={handleClose}
        />
      </Popover>
    </Container>
  );
};
