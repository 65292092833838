import { ObjectType } from "../../../../../types";

export const errorSetter = (
  touched: ObjectType[],
  errors: ObjectType[],
  fieldName: string,
  index: number
) => {
  return errors && touched && errors[index] && touched[index]
    ? errors[index][fieldName] && touched[index][fieldName]
      ? errors[index][fieldName]
      : ""
    : "";
};
