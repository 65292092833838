import React, { useEffect } from "react";
import Select from "react-select";
import { tableRowCountSelectStyle } from "../TableRowCountSelectStyle";

type Props = {
  total: number;
  startCount: number;
  endCount: number;
  itemsPerPage?: number;
  setPageInfo: (count: number, page: number) => void;
};
export default function TableInfo(props: Props) {
  const resultPerPage = [10, 25, 50, 100];
  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  return (
    <div className="row table-info">
      <div className="col-md-6 data-info">
        <div
          className="dataTables_info"
          id="list-table-info"
          role="status"
          aria-live="polite"
        >
          Showing
          {props.total > 1 ? (
            <b>
              {" "}
              {props.startCount} - {props.endCount}{" "}
            </b>
          ) : (
            <b> {props.total} </b>
          )}
          of <b>{props.total}</b>
        </div>
      </div>
      <div className="col-md-6 results-per-page">
        <div className="dataTables_length" id="list-table-length">
          <label>
            Results per page:
            <span className="row-count-wrapper">
              <Select
                name="list-table-length"
                options={resultPerPage.map((data) => {
                  return {
                    label: data,
                    value: data,
                  };
                })}
                className="state-select form-select custom-select mt-0"
                value={{
                  label: props.itemsPerPage,
                  value: props.itemsPerPage,
                }}
                onChange={(e: any) => {
                  props.setPageInfo(Number(e.value), 1);
                }}
                styles={tableRowCountSelectStyle}
                isSearchable={false}
              />
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}
