import { useCallback, useState } from "react";
import { getTableCustomizationFields } from "../common/commonSlice";
import useCommonData from "./useCommon";

export const useCustomizedTable = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  /**
   * Fetch customized table columns
   */
  const fetchTableCustomizationFields = useCallback(
    async (
      type: string,
      defaultValues: string[],
      mandatoryValues: string[]
    ) => {
      const responseAction = await dispatch(
        getTableCustomizationFields({
          tableName: type,
          orgId: currentUserInfo.organization_id,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let selectedFields: string[] = [];
        if (response.list_of_column.length > 0) {
          selectedFields = response.list_of_column;
        } else {
          selectedFields = defaultValues;
        }
        mandatoryValues.map((column) => {
          if (!selectedFields.includes(column)) {
            selectedFields.push(column);
          }
        });
        setSelectedColumns([...selectedFields]);
      } else {
        setSelectedColumns(defaultValues);
      }
    },
    [selectedColumns, currentUserInfo.organization_id]
  );

  return { selectedColumns, fetchTableCustomizationFields };
};
