import DeleteModal from "../../../../common/components/DeleteModal";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { deleteCustomerAddress } from "../../customerSlice";
import { toast } from "react-toastify";
import { ErrorImg } from "../../../../../assets/images";

type Props = {
  addressId: number | string | undefined;
  fetchConsumerDetail: (customerId: number) => void;
  consumerId?: number | string;
};

const DeleteAddress = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };
  const modalSubmitHandler = async () => {
    $("#delete-button-address").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      deleteCustomerAddress({
        customerId: props.consumerId ? Number(props.consumerId) : 0,
        addressId: [Number(props.addressId)],
        orgId: currentUserInfo.organization_id,
      })
    );
    $("#delete-button-address").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (props.consumerId) {
          props.fetchConsumerDetail(Number(props.consumerId));
        }

        $(".close-btn").click();
        toast.success("Address deleted successfully!", {
          toastId: "address-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        $(".close-btn").click();
        // toast.error("Delete address action failed", {
        //   icon: ({ theme, type }) => (
        //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
        //   ),
        //   toastId: "delete-customer-failed-error",
        //   closeButton: false,
        //   className: "toast-error",
        //   position: "top-center",
        // });
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete Address!"
      subHeading="Are you sure you want to delete the address ?"
      transactionName={""}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
      modalType="customer_address"
    />
  );
};

export default DeleteAddress;
