import CenterModal from "../../../../common/components/centerModal/CenterModal";
import "./impactDetailsInfoModal.css";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import ImpactDetailsModalContent from "./ImpactDetailsModalContent";

export const ImpactDetailsInfoModal = (props: any) => {
  const ConfigureButton = () => {
    return (
      <>
        <span>View all changes</span>
      </>
    );
  };
  return (
    <CenterModal
      modalTitle="View scheduled changes"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <ImpactDetailsModalContent scheduledSubscriptionData={props.scheduledSubscriptionData} />;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ConfigureButton />}
      wrapperClass="show-impact-details-modal large-form-modal view-impact-details-modal"
      buttonClass="add-customer-link"
      isCloseButtonEnabled={true}
    />
  );
};
