import React from "react";
// import { DownloadIcon } from "../../../../assets/images";
import { DownloadIcon } from "../../../common/components";
import { colors } from "../../../constants/colors";

export default function DownloadPdf(props: { onClick: () => void }) {
  return (
    <button type="button" onClick={props.onClick} className="dropdown-item">
      {/* <img src={DownloadIcon} alt="download-img" />
      <span>Download PDF</span> */}
      <span>
        <DownloadIcon color={colors.battleshipGrey} />
        Download PDF
      </span>
    </button>
  );
}
