import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { SolidCancelButton } from "../../../../common/components/button/SolidCancelButton";
import { SolidDeleteButton } from "../../../../common/components/button/SolidDeleteButton";
import { deletePurchaseOrder } from "../../purchaseOrderSlice";
import "./deletePurchaseOrder.css";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { DeletePurchaseOrderBodyProps } from "./types";

export const DeletePurchaseOrderBody = (props: DeletePurchaseOrderBodyProps) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const deleteCustomFields = async () => {
    setButtonDisabled(true);
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deletePurchaseOrder({
        orgId: currentUserInfo.organization_id,
        deletePurchaseOrderId: props.id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      SuccessToaster(
        `Purchase Order ${joinTransactionNumber(
          props.purchaseOrderNumber
        )} deleted successfully`,
        "Po-delete"
      );
      await props.reRenderPage();
      dispatch(setLoaderState(false));
      setButtonDisabled(false);
      props.handleClose();
    } else {
      dispatch(setLoaderState(false));
      setButtonDisabled(false);
      props.handleClose();
    }
  };

  return (
    <div className="delete-purchase-order-alert-wrapper">
      <div className="delete-purchase-order-content">
        <span>
          Are you sure you want to delete purchase order&nbsp;{" "}
          <b>{joinTransactionNumber(props.purchaseOrderNumber)}</b> ?
        </span>
        <div className="modal-footer">
          <SolidCancelButton
            onClick={props.handleClose}
            isDisabled={isButtonDisabled}
          />
          <SolidDeleteButton
            onClick={deleteCustomFields}
            isDisabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};
