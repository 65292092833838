import { colors } from "../../constants/colors";

export const listboxCustomStyle = {
  outerBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    wordWrap: "break-word",
    backgroundClip: "border-box",
    borderRadius: "0.25rem",
    border: "0px",
    backgroundColor: colors.approxWhite,
    padding: 0,
    marginTop: "35px !important",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "5px",
    width: "100%",
    height: "100%",
  },
  headerBox: {
    paddingBottom: "23px !important",
    backgroundColor: `${colors.approxWhite} !important`,
    padding: "0",
    border: 0,
  },
  h2: {
    marginTop: "7px !important",
    fontFamily: "Lato !important",
    fontStyle: "normal !important",
    fontStretch: "normal !important",
    fontSize: "24px !important",
    fontWeight: "900 !important",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: colors.charcoalGrey,
    float: "left",
    marginBottom: "0px !important",
  },
  cardBox: {
    width: "100%",
    height: "auto",
    flex: "0 0 auto",
    padding: "0 !important",
    border: "0px !important",
    overflow: "initial !important",
  },
  cardBoxForPlanSpecific: {
    width: "100%",
    height: "auto",
    flex: "0 0 auto",
    padding: "0 !important",
    border: "0px !important",
    overflow: "visible !important",
  },
  paginationBox: {
    backgroundColor: colors.approxWhite,
    borderRadius: 0,
    borderTop: 0,
    marginTop: "15px",
    padding: "10px 0 20px 0",
    clear: "both",
  },
  rightButton: {
    display: "flex",
    float: "right",
    gap: "20px",
  },
};
