import { useParams } from "react-router-dom";
import { CreditApply, ObjectType } from "../../../../../../../types";
import useCommonData from "../../../../../../hooks/useCommon";
import {
  applyCredits,
  applyCreditsCalculation,
  creditList,
} from "../../../../invoiceSlice";
import { SuccessToaster } from "../../../../../../../helpers/toastHelper";
import { useCallback } from "react";
import { FormikProps } from "formik";
import { setLoaderState } from "../../../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../../../helpers/helper";

export const useApplyCredits = () => {
  const { currentUserInfo, orgCurrencyList, dispatch } = useCommonData();
  const { editInvoiceId } = useParams();
  const getApplyCreditList = useCallback(
    async (formik: FormikProps<any>) => {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        creditList({
          invoiceId: Number(editInvoiceId),
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          const transToCredit = response.map((credit: ObjectType) => {
            return {
              ...credit,
              allocation_date: credit.allocation_date
                ? credit.allocation_date
                : getFormatedDate(new Date()),
            };
          });
          formik.setFieldValue(`transactions_to_credit`, transToCredit);
          formik.setFieldValue(`invoice_balance`, response.invoice_balance);
        }
      }
      dispatch(setLoaderState(false));
    },
    [editInvoiceId, currentUserInfo.organization_id]
  );
  /**
   * credit note allocation calculation
   */
  const calculateAllocationAmount = async (
    transactionsToCredit: CreditApply[],
    formik: FormikProps<any>,
    balance: number,
    currency_id: number,
    signal: AbortSignal
  ) => {
    let input: number[] = [];
    transactionsToCredit.map((item) => {
      if (
        item.amount_credit &&
        item.amount_credit !== null &&
        item.amount_credit !== ""
      ) {
        input.push(Number(item.amount_credit));
      } else {
        input.push(0);
      }
    });
    if (input.length) {
      const responseAction = await dispatch(
        applyCreditsCalculation({
          orgId: currentUserInfo.organization_id,
          values: {
            invoice_balance: balance,
            allocation_list: input,
            currency_id: currency_id,
          },
          signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          formik.setFieldValue(`inv_balance`, response.invoice_balance);
          formik.setFieldValue(`balance`, response.invoice_balance);
          formik.setFieldValue(`amount_to_credit`, response.amount_to_credit);
        }
      }
    }
  };
  const updateAllocationData = async (
    transactionsToCredit: CreditApply[],
    invoiceDetail: () => void,
    resetAllocation: () => void
  ) => {
    const transactionDetails = JSON.parse(JSON.stringify(transactionsToCredit));
    let inputs: CreditApply[] = [];
    transactionDetails
      .filter(
        (item: CreditApply) =>
          item.amount_credit !== undefined &&
          item.amount_credit !== null &&
          item.amount_credit !== 0 &&
          item.amount_credit !== "" &&
          item.object_id !== null
      )
      .map((item: CreditApply) => {
        let input = {
          object_id: item.object_id,
          credit_type: item.credit_type,
          amount_credit: item.amount_credit ? item.amount_credit : 0,
          allocation_date: item.allocation_date,
        };
        inputs.push(input);
      });
    const responseAction = await dispatch(
      applyCredits({
        invoice_id: Number(editInvoiceId),
        orgId: currentUserInfo.organization_id,
        values: inputs,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        invoiceDetail();
        resetAllocation();
        SuccessToaster("Credits applied to invoice!", "credit-apply-success");
      } else if ("error" in response) {
        resetAllocation();
      }
    }
  };
  return {
    updateAllocationData,
    calculateAllocationAmount,
    getApplyCreditList,
  };
};
