import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";
import { deleteCouponDetails } from "../couponSlice";

type Props = {
  deletePlanId: number;
  deletePlanName: string;
  deleteActionType: string;
  refreshPlan: (action: string) => void;
  organizationId: number;
};
const CouponDelete = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const planModalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteRoleResponseAction = await dispatch(
      deleteCouponDetails({
        deletePlanId: props.deletePlanId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length && !("error" in deleteRoleResponse)) {
        props.refreshPlan(props.deleteActionType);
        $(".close-btn").click();
        toast.success("Coupon deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
};



  return (
    <DeleteModal
      heading={`Delete ${props.deleteActionType}!`}
      subHeading={`Are you sure you want to delete the ${props.deleteActionType.toLowerCase()} ${props.deleteActionType === 'Coupon' ? '' : ''}`}
      transactionName={joinTransactionNumber(props.deletePlanName)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={ planModalSubmitHandler }
    />
  );
};
export default CouponDelete;