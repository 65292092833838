import { useMemo } from "react";
import useCommonData from "../../../../hooks/useCommon";
import {
  DebitNoteAllocatedList,
  DebitNoteDetailValues,
} from "../../../../../types";
import { EditIcon } from "../../../../common/components";
import ApplyToBillsModal from "../debitNoteDetail/ApplyToBillsModal";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { Link } from "react-router-dom";

type Props = {
  billList: DebitNoteAllocatedList[];
  module: string;
  debitNoteData: DebitNoteDetailValues;
  isParent: boolean;
  key: string;
  refreshDNDetails: () => void;
  debitNoteRolePermission: any;
  subModulesWithPermission: any;
};
function BillDebited(props: Props) {
  const { currentUserInfo, orgCurrencyList } = useCommonData();

  /**
   * Debited table details
   */
  const debitedColumn = useMemo(
    () => [
      {
        Header: "Bill Number",
        accessor: "show.billNumber",
        className: "column-navigation",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={
                props.subModulesWithPermission.includes("Bills")
                  ? `/bill/detail/${value.id}`
                  : ""
              }
              className="transaction-number-cn-pr"
            >
              {value.transactionNumber}
            </Link>
          );
        },
      },
      {
        Header: "Bill Date",
        accessor: "show.date",
        className: "",
      },
      {
        Header: "Bill Amount",
        accessor: "show.billAmount",
        className: "",
      },
      {
        Header: "Bill Balance",
        accessor: "show.billBalance",
        className: "",
      },
      {
        Header: "Amount Debited",
        accessor: "show.amountDebited",
        className: "",
      },
      {
        Header: "Forex Gain/Loss",
        accessor: "show.forex",
        className: "",
      },
      {
        Header: "",
        accessor: "action",
        className: "",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              {props.debitNoteRolePermission.includes("Update") &&
              props.debitNoteData.organization_id ===
                currentUserInfo.organization_id &&
                !value.is_fiscal_closed ? (
                <ApplyToBillsModal
                  billId={value.billId}
                  buttonComponent={<EditIcon color="blue" />}
                  debitNoteData={props.debitNoteData}
                  refreshDNDetails={props.refreshDNDetails}
                />
              ) : null}
            </>
          );
        },
      },
    ],
    [
      currentUserInfo.organization_id,
      props.debitNoteData.organization_id,
      // props.debitNoteRolePermission,
    ]
  );

  const debitedTableData = props.billList
    ? props.billList.map((bill, index) => ({
        show: {
          date: DateFormatHandler(bill.date),
          billNumber: {
            transactionNumber: joinTransactionNumber(bill.bill_number),
            id: bill.bill_id,
          },
          billAmount:
            props.debitNoteData.currency_code +
            " " +
            NumberFormat(
              Number(bill.bill_amount),
              props.debitNoteData.currency_code,
              orgCurrencyList
            ),
          billBalance:
            props.debitNoteData.currency_code +
            " " +
            NumberFormat(
              Number(bill.bill_balance),
              props.debitNoteData.currency_code,
              orgCurrencyList
            ),
          amountDebited:
            props.debitNoteData.currency_code +
            " " +
            NumberFormat(
              Number(bill.amount_debited),
              props.debitNoteData.currency_code,
              orgCurrencyList
            ),
          forex: bill["forex_gain/loss"]
            ? bill.currency_code +
              " " +
              NumberFormat(
                Number(bill["forex_gain/loss"]),
                bill.currency_code,
                orgCurrencyList
              )
            : "-",
        },
        action: {
          url: `/bill/detail/${bill.bill_id}`,
          permission: props.subModulesWithPermission.includes("Bills"),
          customNavigation: true,
          type: "debitAllocationModal",
          allocationId: bill.id,
          billNumber: joinTransactionNumber(bill.bill_number),
          billId: bill.bill_id,
          is_fiscal_closed: bill.is_fiscal_closed,
        },
      }))
    : [];

  return (
    <DetailsTableContainer
      columns={debitedColumn}
      data={debitedTableData}
      className="list-table table cd-list-table w-100"
      haveColumnClassName={true}
      hiddenColumns={[]}
    />
  );
}

export default BillDebited;
