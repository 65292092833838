import React from "react";
import { EditIconBlueImg } from "../../../../assets/images";
import { ObjectType } from "../../../../types";
import "../../../../index.css";
import './Button.css';

type Props = {
  className: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  style: ObjectType;
  type: "button" | "submit" | "reset" | undefined;
};
export const EditBlueButton = (props: Props) => {
  return (
    <button
      onClick={props.onClick}
      className={props.className}
      hidden={props.isHidden}
      disabled={props.isDisabled}
      style={props.style}
      type={props.type}
    >
      <span className="blue-edit-btn">
        <img src={EditIconBlueImg} alt="Edit" />
        Edit
      </span>
    </button>
  );
};
