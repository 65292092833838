import { SvgIconProp } from "../../../../types";

export const SearchIcon = (props: SvgIconProp) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h20v20H0z" />
        <path
          d="M9.167 1.667c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5zm0 13.333A5.832 5.832 0 0 0 15 9.167a5.832 5.832 0 0 0-5.833-5.834 5.831 5.831 0 0 0-5.834 5.834A5.832 5.832 0 0 0 9.167 15zm7.07.06 2.358 2.356-1.18 1.179-2.356-2.357 1.179-1.179z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
