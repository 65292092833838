import React from "react";
import {
  CustomSubModuleValues,
  ModuleListValues,
} from "../../../../../../../types";
import { getSubmoduleEachPermission, getPermissionSubmodule, isCancelStopResumeEnabled } from "../../../../../../../helpers/permissionHelper";
type Props = {
  moduleList: ModuleListValues[];
  submodulePermissions: CustomSubModuleValues[];
  handleFullAccessOfEachSubModule: (
    e: any,
    moduleId: number,
    subModuleId: number,
    moduleName: string,
    subModuleName: string
  ) => void;
  handlePermissionChange: (
    e: any,
    moduleId: number,
    subModuleId: number,
    permissionId: number,
    moduleName: string,
    subModuleName: string
  ) => void;
};
const PermissionMatrixTable = (props: Props) => {
  return (
    <div className="tab-content">
      {props.moduleList.map((module, index) => {
        return (
          <div
            className={`tab-pane ${
              module.name === "Contacts" ? " active" : ""
            }`}
            id={`${module.name.replace(/ /g, "")}`}
            role="tabpanel"
            aria-labelledby={`${module.name}-tab`}
            key={module.id}
          >
            <table className="w-100 permission-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Full Access</th>
                  {module.name !== "Reports" ? (
                    <>
                      <th>Create</th>
                      <th>View</th>
                      <th>Edit</th>
                      <th>Delete</th>
                      {isCancelStopResumeEnabled(props.submodulePermissions, module.id)
                        ? null
                        : <>
                          <th>Cancel</th>
                          <th>Stop/Resume</th>
                        </>}
                    </>
                  ) : (
                    <>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                        {isCancelStopResumeEnabled(props.submodulePermissions, module.id)
                          ? null
                          : 
                          <>
                            <th></th>
                            <th></th>
                          </>}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {props.submodulePermissions
                  .filter((items) => items.module_id === module.id)
                  .map((subModule) => {
                    return (
                      <tr key={subModule.sub_id}>
                        <td>{subModule.sub_module_name}</td>
                        <td>
                          <input
                            type="checkbox"
                            name="c_i_f_access"
                            id="c_i_f_access"
                            className="form-check-input"
                            onChange={(e) =>
                              props.handleFullAccessOfEachSubModule(
                                e,
                                module.id,
                                subModule.sub_id,
                                module.name,
                                subModule.sub_module_name
                              )
                            }
                            checked={subModule.sub_module_name === 'Recurring Invoices'
                            && getPermissionSubmodule(props.submodulePermissions, "Invoices")?.is_full_access
                            ? subModule.is_full_access
                            : subModule.sub_module_name !== 'Recurring Invoices'
                            && subModule.is_full_access}
                            disabled={subModule.sub_module_name === 'Recurring Invoices'
                            && !getPermissionSubmodule(props.submodulePermissions, "Invoices")?.is_full_access
                            ? true : false}
                          />
                        </td>
                        {subModule.permissions.length === 2 && <td></td>}
                        {subModule.permissions.map((permissions, index) => {
                          return (
                            <td key={permissions.id}>
                              <input
                                type="checkbox"
                                name="c_i_read"
                                id="c_i_read"
                                className="form-check-input"
                                onChange={(e) =>
                                  props.handlePermissionChange(
                                    e,
                                    module.id,
                                    subModule.sub_id,
                                    permissions.id,
                                    module.name,
                                    subModule.sub_module_name
                                  )
                                }
                                disabled={subModule.sub_module_name === 'Recurring Invoices'
                                && !getSubmoduleEachPermission(getPermissionSubmodule(props.submodulePermissions, "Invoices").permissions,permissions.id)?.is_checked  && !(['Cancel', 'Stop'].includes(permissions.name) && subModule.permissions.find((eachData) => eachData.name === 'Update')?.is_checked)
                                ? true : ['Cancel', 'Stop'].includes(permissions.name) && !subModule.permissions.find((eachData) => eachData.name === 'Update')?.is_checked ? true : false}
                                checked={subModule.sub_module_name === 'Recurring Invoices'
                                &&  getSubmoduleEachPermission(getPermissionSubmodule(props.submodulePermissions, "Invoices").permissions,permissions.id)?.is_checked
                                ? permissions.is_checked: ['Cancel', 'Stop'].includes(permissions.name) ? subModule.permissions.find((eachData) => eachData.name === 'Update')?.is_checked && permissions.is_checked: subModule.sub_module_name !== 'Recurring Invoices' && permissions.is_checked}
                                hidden={
                                  subModule.permissions.length === 1
                                    ? true
                                    : false
                                }
                              />
                            </td>
                          );
                        })}
                        {subModule.permissions.length === 1 && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}
                        {subModule.permissions.length === 2 && <td></td>}
                        {isCancelStopResumeEnabled(props.submodulePermissions, module.id)
                          ? null
                          : <>
                            {subModule.permissions.length <= 4 && (
                              <>
                                <td></td>
                                <td></td>
                              </>
                            )}
                            {subModule.permissions.length === 5 && (
                              <>
                                <td></td>
                              </>
                            )}
                          </>}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        );
      })}
      {/* table ends */}
    </div>
  );
};

export default PermissionMatrixTable;
