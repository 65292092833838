import { CreateTaxRateValues } from "../../../../../types";
import "../Settings.css";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { createTaxRate, editTaxRate } from "../taxRateSlice";
import { toast } from "react-toastify";
import { FormSelectField } from "../../../../common/components/formSelectField/FormSelectField";
import { useFormik } from "formik";
import { taxRateFormValidation } from "./taxRateFormValidation";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
type Props = {
  taxId: number;
  taxIdReset: () => void;
  organizationId: number;
  handleModalClose: () => void;
  isAccountDeleted: boolean;
  item: CreateTaxRateValues | undefined;
  refreshList: () => void;
};
function TaxForm(props: Props) {
  const [isTaxFormChanged, setIsTaxFormChanged] = useState(false);
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const options = {
    tax_type: [
      { label: "CGST", value: "CGST" },
      { label: "SGST", value: "SGST" },
      { label: "UTGST", value: "UTGST" },
      { label: "IGST", value: "IGST" },
      { label: "CESS", value: "CESS" },
    ],
  };

  const formik = useFormik({
    initialValues: props.taxId
      ? { ...props.item, is_checked: false }
      : {
          name: "",
          tax_type: "",
          rate: "",
          is_checked: true,
        },
    enableReinitialize: true,
    validationSchema: taxRateFormValidation(props.taxId, isTaxFormChanged),
    onSubmit: (values) => {
      props.taxId ? editTaxHandler(values) : createTaxHandler(values);
    },
  });

  useEffect(() => {
    if (props.taxId) {
      if (
        props.item?.name === formik.values.name &&
        props.item?.rate.toString() === formik.values.rate?.toString()
      ) {
        setIsTaxFormChanged(false);
      } else {
        setIsTaxFormChanged(true);
      }
    }
  }, [formik.values.name, formik.values.rate]);

  /**
   * Create Tax handler
   */
  const createTaxHandler = async (values: any) => {
    $("#form-btn-tax").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      createTaxRate({
        values: values,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      $("#form-btn-tax").removeClass("customer-form-section-disable");
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        $(".tax-close-btn").click();
        props.handleModalClose();
        props.refreshList();
        toast.success("Tax rate created successfully!", {
          toastId: "tax-create-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
  };

  /**
   * Edit tax handler
   */
  const editTaxHandler = async (values: any) => {
    $("#form-btn-tax").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      editTaxRate({
        taxId: props.taxId,
        values: values,
        orgId: props.organizationId,
      })
    );
    if (responseAction.payload) {
      $("#form-btn-tax").removeClass("customer-form-section-disable");
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        $(".tax-close-btn").click();
        props.handleModalClose();
        props.refreshList();
        toast.success("Tax information is updated!", {
          toastId: "tax-update-success",
          closeButton: false,
          position: "top-center",
        });
      }
    }
  };
  const handleInputChange = (e: any) => {
    if (/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(e.target.value)) {
      formik.handleChange(e);
    }
  };

  const handleChange = (e: any) => {
    formik.setFieldValue("is_checked", e.target.checked);
  };

  const handleAmountOnBlur = (name: string, amount: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      if (name === "rate" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        formik.setFieldValue(name, roundedAmount);
      }
    }
  };

  return (
    <>
      <div id="taxCreateModal" className="add-tax-form">
        <form
          className={`create-user-form-wrap create-settings-form-wrap`}
          onSubmit={formik.handleSubmit}
        >
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="tax_name">
              Tax Name<span className="mandatory">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formik.values.name || ""}
              className={
                formik.touched.name && formik.errors.name
                  ? "form-control error"
                  : "form-control"
              }
              placeholder="Enter tax name"
              onChange={formik.handleChange}
              maxLength={12}
            />
            <span className="error">
              {formik.touched.name && formik.errors.name}
            </span>
          </div>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="tax_name">
              Rate(%)<span className="mandatory">*</span>
            </label>
            <input
              type="text"
              id="rate"
              name="rate"
              value={formik.values.rate || ""}
              className={
                formik.touched.rate && formik.errors.rate
                  ? "form-control error"
                  : "form-control"
              }
              placeholder="Enter tax rate"
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) =>
                handleAmountOnBlur("rate", Number(formik.values.rate))
              }
            />
            <span className="error">
              {formik.touched.rate && formik.errors.rate}
            </span>
          </div>
          <FormSelectField
            name="consumer_id"
            id="consumer_id"
            className="form-select custom-select customer-select m-0"
            isDisabled={false}
            isOnlyInRow={true}
            styles={customSelectStyle}
            placeholder="Choose tax type"
            isRequired={true}
            isSearchable={true}
            error={
              formik.touched.tax_type && formik.errors.tax_type
                ? formik.errors.tax_type
                : ""
            }
            label={"Tax Type"}
            onChange={(e) => formik.setFieldValue("tax_type", e && e.value)}
            options={options.tax_type}
            value={{
              label: formik.values.tax_type ? formik.values.tax_type : "",
              value: formik.values.tax_type ? formik.values.tax_type : "",
            }}
          />
          {props.taxId && isTaxFormChanged ? (
            <>
              <p className="tds-terms-and-condition">Terms and Conditions</p>
              <div className="tds-form-input-wrapper tds-checkbox-wrapper">
                <Checkbox
                  name="c_i_f_access"
                  id="c_i_f_access"
                  className="form-check-input tds-terms-checkbox"
                  onChange={handleChange}
                  checked={formik.values.is_checked}
                />
                <span className="terms-and-condition-label">
                  I understand that updating the tax will mark the existing tax
                  inactive, create a new tax. New Tax will be applicable only
                  for future transactions.
                </span>
              </div>
              <span className="error">{formik.errors.is_checked}</span>
            </>
          ) : null}
          <div id="form-btn-tax">
            <button
              className="btn btn-block fa-lg invite-btn save-butn mb-4"
              type="submit"
              onClick={() => {}}
            >
              {props.taxId > 0 ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-block fa-lg cancel-btn mb-4"
              type="button"
              onClick={() => props.handleModalClose()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
export default TaxForm;
