import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { SolidCancelButton } from "../../../../common/components/button/SolidCancelButton";
import { SolidDeleteButton } from "../../../../common/components/button/SolidDeleteButton";
import {
  deleteCustomField,
  getCustomFieldTotalList,
} from "../customFieldAPIFiles/customFieldSlice";
import "./deleteCustomField.css";

type DeleteModalProps = {
  id: number;
  name: string;
  module: string;
  handleClose: () => void;
};
export const DeleteCustomField = (props: DeleteModalProps) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const deleteCustomFields = async () => {
    setButtonDisabled(true);
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteCustomField({
        orgId: currentUserInfo.organization_id,
        custom_field_id: props.id,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      SuccessToaster(
        `${props.name} field deleted successfully`,
        "custom-field-delete"
      );
      await dispatch(
        getCustomFieldTotalList({ orgId: currentUserInfo.organization_id })
      );
      dispatch(setLoaderState(false));
      setButtonDisabled(false);
      props.handleClose();
    } else {
      dispatch(setLoaderState(false));
      setButtonDisabled(false);
      props.handleClose();
    }
  };

  return (
    <div className="delete-custom-field-alert-wrapper">
      <div className="delete-custom-field-content">
        <span>
          Are you sure you want to delete &nbsp; <b>{props.name}</b> &nbsp;
          field under&nbsp; <b>{props.module}</b>?
        </span>
        <div className="modal-footer">
          <SolidCancelButton
            onClick={props.handleClose}
            isDisabled={isButtonDisabled}
          />
          <SolidDeleteButton
            onClick={deleteCustomFields}
            isDisabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};
