import { colors } from "../../constants/colors";
import "../styles/AlertModal.css";
import { getFormatedDate } from "../../../helpers/helper";
import { FormSelectField } from "./formSelectField/FormSelectField";
import { invoicingCycleOption, invoicingOptions } from "../../home/subscription/SubscriptionStateInitialization";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormInputField } from "./formInputField/FormInputField";
import moment from "moment";
import CustomDatepicker from "../../utils/atoms/datepicker";
import { FormikProps } from "formik";
import { ObjectType } from "../../../types";
import { getValidPastDate } from "../../../helpers/subscriptionHelper";

type Props = {
    type?: string;
    modalCancelHandler: () => void;
    status: string;
    paymentStatus?: string;
    isUnpaidInvoiceExist?: boolean;
    modalType?: string;
    closeButtonClassName?: string;
    formik: FormikProps<any>;
    reactivateFormErrors: ObjectType;
    subscriptionPeriod: string;
};

const ReactivateModalSubscription = (props: Props, ) => {
    const handleDate = (date: Date | null, name: string) => {
        if (date) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const targetDate = new Date(date);
            targetDate.setHours(0, 0, 0, 0);
            if (date?.toString() === "Invalid Date" ||
            (name === "trial_end_date" && currentDate > targetDate)
            || (name === "reactivation_date" && (currentDate < targetDate))) {
                props.formik.setFieldValue(name, "Invalid date")
            } else {
                const dateFormated = getFormatedDate(date);
                if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
                    props.formik.setFieldValue(name, dateFormated)
                } else {
                    props.formik.setFieldValue(name, "Invalid date")
                }

            }
        } else {
            props.formik.setFieldValue(name, '')
        }
    };
    
    const handleFieldChange = (e: any) => {
        const { name, value } = e.target;
        if ([!Number.isNaN(Number(value))].every(Boolean)) {
            props.formik.setFieldValue(name, `${value.replaceAll('.', '').trim()}`)
        }
    }
    return (
        <div>
            <div className="col-12 col-lg-4 mw-325 void-subscription-margin-container">
                <div className="date-selector-wrapper reactive-subscription-margin row">
                    <label className="pause-date-label" htmlFor="cancel_date">
                        Reactivation Date
                    </label>
                    <CustomDatepicker
                        date={!props.formik.values?.reactivation_date ? null : new Date(props.formik.values.reactivation_date)}
                        handleDate={(date) => handleDate(date, 'reactivation_date')}
                        type="reactivation_date"
                        name="reactivation_date"
                        id="reactivation_date"
                        error={!props.reactivateFormErrors.reactivation_date ? "" : "error"}
                        zIndex={0}
                        placeholder="Enter reactivation date"
                        enableFutureDate={false}
                        isDisabled={false}
                        minDate={new Date(getValidPastDate(props.subscriptionPeriod))}
                    />
                    <span className="error">{!props.reactivateFormErrors.reactivation_date ? "" : props.reactivateFormErrors.reactivation_date}</span>
                </div>
                <div className="date-selector-wrapper reactive-subscription-margin row">
                    <label className="pause-date-label" htmlFor="cancel_date">
                        Trial end date
                    </label>
                    <CustomDatepicker
                        date={!props.formik.values?.trial_end_date ? null : new Date(props.formik.values.trial_end_date)}
                        handleDate={(date) => handleDate(date, 'trial_end_date')}
                        type="trial_end_date"
                        name="trial_end_date"
                        id="trial_end_date"
                        error={!props.reactivateFormErrors.trial_end_date ? "" : props.reactivateFormErrors.trial_end_date}
                        zIndex={0}
                        enableFutureDate={true}
                        isDisabled={false}
                        minDate={new Date()}
                        placeholder="Enter trial end date"
                    />
                    <span className="error">{!props.reactivateFormErrors.trial_end_date ? "" : props.reactivateFormErrors.trial_end_date}</span>
                </div>
                <FormSelectField
                    name="invoicing_cycle"
                    id="invoicing_cycle"
                    label="Invoicing Cycle"
                    options={invoicingCycleOption}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={invoicingCycleOption.filter((data => data.value === props.formik.values.invoicing_cycle))[0] || ''}
                    onChange={(option: any) => {
                        props.formik.setFieldValue('invoicing_cycle', option.value)
                    }}
                    styles={customSelectStyle}
                    placeholder={"Choose invoicing cycle"}
                    isSearchable={true}
                    isDisabled={false}
                    error=""
                    isRequired={false}
                    isOnlyInRow={false}
                />
                {props.formik.values.invoicing_cycle === "FIXED" ?
                    <FormInputField
                        type="text"
                        name="invoicing_cycle_fixed_count"
                        id="ref_no"
                        className={
                            props.reactivateFormErrors.invoicing_cycle_fixed_count
                              ? "form-control error"
                              : "form-control"
                          }
                        wrapperClass="no-of-cycle-wrapper h-89"
                        label="Number of Cycles"
                        placeholder="Enter number of cycles"
                        maxLength={16}
                        value={!props.formik.values?.invoicing_cycle_fixed_count ? null : props.formik.values.invoicing_cycle_fixed_count}
                        onChange={handleFieldChange}
                        error={props.reactivateFormErrors.invoicing_cycle_fixed_count || ''}
                    />
                    : null}
                <FormSelectField
                    name="invoicing_option"
                    id="invoicing_option"
                    label="Invoicing Option"
                    options={invoicingOptions}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={invoicingOptions.filter((data) => data.value === props.formik.values.invoicing_option)[0]}
                    onChange={(option: any) => {
                        props.formik.setFieldValue('invoicing_option', option.value)
                    }}
                    styles={customSelectStyle}
                    placeholder={""}
                    isSearchable={true}
                    isDisabled={false}
                    error=""
                    isRequired={false}
                    isOnlyInRow={false}
                />
            </div>
        </div>
    );
};

export default ReactivateModalSubscription;
