import { RadioButtonGroup } from "../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { InfoMessage } from "../../../common/components/infos/InfoMessage";
import { Label } from "../../../common/components/htmlTags/Label";
import { Container } from "../../../common/components/htmlTags/Container";
import InfoTooltip from "../../../common/components/InfoTooltip";
import { FormikProps } from "formik";
type Props = {
  heading: string;
  selectedValue: string | boolean;
  valuesArray: {
    label: string | React.ReactElement;
    value: string;
  }[];
  message: string;
  formik: FormikProps<any>;
  name: string;
}
export const ConfigureCouponSelection = (props: Props) => {

  return (
    <Container className="d-flex align-items-center mb-3 fields-wrapper row">
      <Container>
        <Label
          htmlFor="is_payment_allocation_enabled"
          className="fw-normal"
        >
          {props.heading}
          <InfoTooltip title={props.message} />
        </Label>
      </Container>
      <Container>
        <RadioButtonGroup
          radioStyle="recurring-invoice-radio"
          name="gst_enabled"
          id="gst_enabled"
          valuesArray={props.valuesArray}
          value={props.selectedValue}
          icon={
            <span>
              <RadioButtonUncheckedIcon />
            </span>
          }
          checkedIcon={
            <span>
              <RadioButtonCheckedIcon className="radio-btn-tds" />
            </span>
          }
          isDisabled={false}
          onClick={(value) => props.formik.setFieldValue(props.name, value)}
        />
      </Container>
    </Container>
  )
}