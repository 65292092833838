import React from "react";
import { Link } from "react-router-dom";
import { CloneIcon } from "../customSvgIcons/cloneIcon";
type Props = {
  url: string;
};
const CloneRole = (props: Props) => {
  return (
    <div>
    <Link className="dropdown-item" to={props.url} onClick={() => {}}>
    <span className="d-flex align-items-center">
            <CloneIcon />
        Clone
      </span>
    </Link>
    </div>
  );
};

export default CloneRole;
