import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useCommonData from "../../../../../hooks/useCommon";
import CustomTooltips from "../../../../../common/components/Tooltip";
import Popover from "../../../../../common/components/Popover";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { CustomerList, Transactionlimit } from "../../../../../../types";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import KeywordHighlight from "../../../../../common/components/KeywordHighlight";
import { setLoaderState } from "../../../../../common/commonSlice";
import { customersList } from "../../../customerSlice";
import { useAppSelector } from "../../../../../../app/hooks";
import { listFilterSelector } from "../../../../../../appSlice";
import CustomerTable from "./CustomerTable";
import useListData from "../../../../../hooks/useListData";
import ListActions from "../ListActions";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import TableSetup from "../../../../../common/components/table/TableSetup";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import {
  customerMandatoryValues,
  customerColumnValues,
  customerDefaultValues,
} from "../../../../../../helpers/tableCustomizationHelper";

type Props = {
  customerName: string;
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  setTotalResults: (value: number) => void;
  deleteListData: (id: number, name: string) => void;
};
function CustomerTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const [customerData, setCustomerData] = useState<CustomerList[]>([]);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const orgID = useRef(currentUserInfo.organization_id);
  const { customerRolePermission, invoicesRolePermission } =
    usePermissionHandler();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();

  useImperativeHandle(
    ref,
    () => ({
      fetchCustomerList: fetchCustomerList,
    }),
    []
  );
  useEffect(() => {
    fetchTableCustomizationFields(
      "Customers",
      customerColumnValues,
      customerMandatoryValues
    );
  }, [page, itemsPerPage, currentUserInfo.organization_id]);
  useEffect(() => {
    fetchCustomerList();
  }, [
    page,
    itemsPerPage,
    props.customerName,
    // orgID.current,
    selectedFilterData.Organizations,
    currentUserInfo.organization_id,
  ]);
  useEffect(() => {
    let hiddenItems: string[] = [];
    customerColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  },[customerData, selectedColumns])

  $(document).ready(function () {
    $(".tag-count").each(function () {
      ($('[data-bs-toggle="popover"]') as any).popover({
        trigger: "focus",
        html: true,
        content: function () {
          return $(this).next(".popover-content").html();
        },
      });
    });
  });

  /**
   * Fetch all customers
   */
  const fetchCustomerList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      customersList({
        page: page,
        itemsPerPage: itemsPerPage,
        customerName: props.customerName.trim(),
        orgId: sessionStorage.getItem("orgId")
          ? Number(sessionStorage.getItem("orgId"))
          : currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
      })
    );
    if (responseAction.payload) {
      // To remove suggestion box when search result is displayed.
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        x[i].parentNode?.removeChild(x[i]);
      }
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCustomerData(response.customers);
        if (response.customers.length) {
          let length = response.customers.length;
          setTotalList(response.total);
          if (props.customerName !== "") {
            props.setTotalResults(response.total);
          }
          if (response.total != 0) setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else {
          setTotalList(response.total);
          props.setTotalResults(response.total);
        }
      } else if ("error" in response) {
      }
      dispatch(setLoaderState(false));
    }
  }, [
    selectedFilterData.Organizations,
    page,
    itemsPerPage,
    currentUserInfo.organization_id,
    props.customerName,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "show.name",
        sort: false,
        sortOrder: "",
        className: "name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement={"bottom"}
              title={value?.length > 15 ? value : ""}
            >
              <span className="ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Email",
        accessor: selectedColumns.includes("Email") ? "show.email" : "Email",
        sort: false,
        sortOrder: "",
        className: "email",
      },
      {
        Header: "Phone Number",
        accessor: selectedColumns.includes("Phone Number")
          ? "show.phoneNumber"
          : "Phone Number",
        sort: false,
        sortOrder: "",
        className: "phone_num",
      },
      {
        Header: "Receivables",
        accessor: "show.receivables",
        sort: false,
        sortOrder: "",
        className: "balance",
      },
      {
        Header: "Unused Credits",
        accessor: "show.unusedCredits",
        sort: false,
        sortOrder: "",
        className: "balance",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Customers"
            tableColumns={selectedColumns}
            customizeColumnValues={customerColumnValues}
            mandatoryColumns={customerMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Customers",
                customerDefaultValues,
                customerMandatoryValues
              );
              fetchCustomerList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return customerRolePermission.includes("Update") ||
            customerRolePermission.includes("Delete") ? (
            <ListActions
              value={value}
              onClickDelete={() => {
                if (!value.transactionExist) {
                  props.deleteListData(value.id, value.listName);
                }
              }}
            />
          ) : null;
        },
      },
    ],
    [currentUserInfo.organization_id, customerRolePermission]
  );
  const data = customerData
    ? customerData.map((customer, index) => ({
        show: {
          name: (
            <KeywordHighlight
              name={customer.customer_display_name}
              searchedName={props.customerName}
            />
          ),
          email: customer.customer_email,
          phoneNumber: customer.customer_phone,
          receivables: customer.opening_balance
            ? customer.currency.code +
              " " +
              NumberFormat(
                customer.opening_balance,
                customer.currency.code,
                orgCurrencyList
              )
            : customer.currency.code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(customer.currency.code, orgCurrencyList)
              ),
          unusedCredits: customer.unused_credit
            ? customer.currency.code +
              " " +
              NumberFormat(
                customer.unused_credit,
                customer.currency.code,
                orgCurrencyList
              )
            : customer.currency.code +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(customer.currency.code, orgCurrencyList)
              ),
          organization: customer.organization,
          actions: {
            id: customer.id,
            transactionExist: customer.transaction_exist,
            listName: customer.customer_display_name,
            openingBalance: customer.opening_balance,
            organizationId: customer.organization_id,
          },
        },
      }))
    : [];
  return (
    <CustomerTable
      totalList={totalList}
      startCount={startCount}
      endCount={endCount}
      itemsPerPage={itemsPerPage}
      customerRoles={customerRolePermission}
      columns={columns}
      data={data}
      customerName={props.customerName}
      isFilterApplied={props.isFilterApplied}
      initializeFilter={props.initializeFilter}
      transactionLimit={props.transactionLimit}
      setPageInfo={(countPerPage, page) => {
        setItemsPerPage(countPerPage);
        setPage(page);
      }}
      hiddenColumns={hiddenColumns}
    />
  );
}

export default React.memo(forwardRef(CustomerTableContainer));
