import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewTds,
  deleteTds,
  getSectionDropDown,
  getTdsDropDown,
  getTdsList,
  getTdsTaxPreference,
  updateTdsPreferenceValues,
} from "./tdsAPI";
import { TDSFormValues, TdsPreferenceValues, TdsValues } from "../../../types";
import { RootState } from "../../../app/store";

export const tdsList = createAsyncThunk(
  "tdsList",
  async (data: { page: number; itemsPerPage: number; orgId: number }) => {
    return await getTdsList(data);
  }
);

export const createTds = createAsyncThunk(
  "createTds",
  async (data: { values: TDSFormValues; orgId: number }) => {
    return createNewTds(data);
  }
);

export const sectionDropDown = createAsyncThunk(
  "sectionDropdown",
  async (orgId: number) => {
    return await getSectionDropDown(orgId);
  }
);

export const updateTdsPreference = createAsyncThunk(
  "updateTdsPreference",
  async (data: {
    supportTds: string;
    applyTdsAt: string;
    isEnabled: boolean;
    orgId: number;
  }) => {
    return await updateTdsPreferenceValues(data);
  }
);

export const tdsTaxPreference = createAsyncThunk(
  "tdsPreference",
  async (orgId: number) => {
    return await getTdsTaxPreference(orgId);
  }
);

export const tdsDropDown = createAsyncThunk(
  "tdsDropDown",
  async (data: {orgId: number, tdsId: number[]}) => {
    return await getTdsDropDown(data);
  }
);

export const removeTds = createAsyncThunk(
  "removeTds",
  async (data: { tdsId: number; orgId: number }) => {
    return await deleteTds(data);
  }
);

interface TdsState {
  tds: TdsValues;
  tdsPreference: TdsPreferenceValues;
}

const initialState: TdsState = {
  tds: {
    tds_tax: [],
    total: 0,
    page_size: 10,
    pagination: {
      next: null,
      previous: null,
    },
  },
  tdsPreference: {
    apply_tds_at: "",
    id: 0,
    is_enabled: true,
    organization_id: 0,
    support_tds_for: "",
  },
};

export const tdsSlice = createSlice({
  name: "tdsConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tdsList.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.tds = payload;
      }
    });
    builder.addCase(tdsTaxPreference.fulfilled, (state, { payload }) => {
      if (payload && Object.keys(payload).length && !("error" in payload)) {
        state.tdsPreference = payload;
      }
    });
  },
});

export default tdsSlice.reducer;

export const tdsSelector = (state: RootState) => state.tds.tds;
export const tdsPreferenceSelector = (state: RootState) =>
  state.tds.tdsPreference;
