import { getFormatedDate } from "../../../../../../helpers/helper";

const messages = {
  errors: {
    noCustomer: "Please choose a customer.",
    noInvoiceNumber: "Please enter invoice number",
    noInvoiceDate: "Please enter a invoice date",
    invalidInvoiceDate: "Please enter a valid invoice date.",
    dateBelowFiscalYear:
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.",
    minDate: "Please enter a date with a year above 2000.",
    noDueDate: "Please enter a due date",
    invalidDueDate: "Please enter a valid due date.",
    minDueDate: "Please enter a date greater than invoice date",
    noPlaceOfSupply: "Please choose a place of supply.",
    noEmail: "Please enter email.",
    invalidEmail: "Please enter valid email/emails",
    invalidTransactionDate:
      "Invalid Transaction Date: Transaction date must be within the current fiscal year",
    transactionsLocked: (lockDate: string) =>
      `Transactions before ${lockDate} have been locked. Hence action cannot be performed`,
    onlyIGSTForSez : "The tax rate for supplies to SEZ when you have a letter of Undertaking/Bond should be IGST 0%.",
    onlyZeroTaxForOverseas: "Only Zero Tax can be applied to overseas invoices."
  },
  success: {
    conversionUpdated: "Conversion rate updated succesSfully",
    createSuccess: "Invoice created successfully!",
    updateSuccess: "Invoice updated successfully!",
    downloadSuccess: "Invoice downloaded successfully!",
  },
  info: {
    exchangeRateNotification: (invoiceDate: Date) =>
      `If you select a ${
        getFormatedDate(invoiceDate) > getFormatedDate(new Date())
          ? "future"
          : getFormatedDate(invoiceDate) < getFormatedDate(new Date())
          ? "past"
          : "current"
      } date, the exchange rate as of ${
        getFormatedDate(invoiceDate) < getFormatedDate(new Date())
          ? "that day"
          : "today"
      } will be applied to this transaction. Would you like to recalculate the item rates based on the ${
        getFormatedDate(invoiceDate) < getFormatedDate(new Date())
          ? "historical"
          : "current"
      } exchange rate?`,
  },
};
export default messages;
