import { useId } from "react";
import { handleNumberOnlyKeydownEntry } from "../../../../../utils/handleAmountOnlyEntry";
import { Container } from "../../../htmlTags/Container";
import Input from "../../../ui/formElements/Input/Input";
import "../TdsSelect.css";
import { constants } from "../constants";
import { TdsInputProps } from "../types";

export const TdsSelectInputComponent = (props: TdsInputProps) => {
  const id = useId();
  const getValue = () => {
    let value =
      !props.item.is_tds_percentage && props.item.tds_amount
        ? props.item.tds_amount || ""
        : props.item.is_tds_percentage
        ? props.item.tds_percentage || ""
        : props.item.tds_amount || "";
    return String(value);
  };

  return (
    <Container className="tds-entry-wrapper">
      <Input
        type="text"
        className={`h-100`}
        name={props.name}
        id={id}
        placeholder={
          props.item.is_tds_percentage
            ? constants.placeholders.TDS_INPUT_PERCENTAGE
            : constants.placeholders.TDS_INPUT_AMOUNT
        }
        disabled={props.disabled ? props.disabled : false}
        onChange={(e) => {
          props.handleTDSAmountChange(
            props.item.is_tds_percentage ? "tds_percentage" : "tds_amount",
            e,
            props.index
          );
        }}
        maxLength={15}
        value={getValue()}
        onBlur={() => {
          props.handleOnBlur(
            props.item.is_tds_percentage ? "tds_percentage" : "tds_amount",
            props.item.is_tds_percentage ? props.item.tds_percentage : props.item.tds_amount,
            props.index
          );
        }}
        onKeyDown={handleNumberOnlyKeydownEntry}
      />
    </Container>
  );
};
