import React from "react";
// import UserService from "../../../../keycloak/UserService";
import "./MaintenancePage.css";
import { Link } from "react-router-dom";
import {
  LogoBlack,
  //   LogoSmall,
  Processing,
} from "../../../../assets/images";
// import { useMediaQuery } from "@react-hook/media-query";

type Props = {
  isLogo: boolean;
  isFooter: boolean;
  headerImageUrl: string;
  headerText: string;
  children: React.ReactNode;
  // orgName?: string;
  // buttonElement?:React.ReactNode;
  // clickAction: (e: React.MouseEvent<HTMLButtonElement>) => void;
  footerElement?: React.ReactNode;
};

const MaintenancePage: React.FC<Props> = ({
  isLogo,
  isFooter,
  headerImageUrl,
  headerText,
  children,
  footerElement,
}) => {
  //   const smallMobDevices = useMediaQuery("(max-width: 459px)");

  return (
    <>
      <div className="maintenance-page-wrapper">
        {isLogo && (
          <Link to={"/"} className="logo-link">
            <img src={LogoBlack} alt="logo" height={70} />
          </Link>
        )}

        <div className="content-wrapper maintenance-content-wrapper">
          <div className="align-center maintenance-header">
            <img
              src={headerImageUrl ? headerImageUrl : Processing}
              alt="processing"
              width="135"
            />
            <h1>{headerText}</h1>
          </div>
          <div className="align-left maintenance-message">{children}</div>
          {isFooter && footerElement}
        </div>
      </div>
    </>
  );
};

export default MaintenancePage;
