import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { CreateTaxValues, ObjectType, TaxConfigurations } from "../../../types";
import {
  createNewTax,
  deleteTaxItem,
  editTaxItem,
  getTaxePayable,
  getTaxeReceivable,
  getTaxesList,
} from "./taxConfigurationAPI";

export const taxesList = createAsyncThunk(
  "taxList",
  async (data: { page: number; itemsPerPage: number; orgId: number }) => {
    return await getTaxesList(data);
  }
);

export const createTax = createAsyncThunk(
  "createTax",
  async (data: { values: CreateTaxValues; orgId: number }) => {
    return await createNewTax(data);
  }
);

export const editTax = createAsyncThunk(
  "editTax",
  async (data: { taxId: number; values: CreateTaxValues; orgId: number }) => {
    return await editTaxItem(data);
  }
);

export const deleteTax = createAsyncThunk(
  "deleteTax",
  async (data: { taxId: number; orgId: number }) => {
    return await deleteTaxItem(data);
  }
);

export const taxPayable = createAsyncThunk(
  "taxList/payable",
  async (data: { orgId: number }) => {
    return await getTaxePayable(data);
  }
);

export const taxReceivable = createAsyncThunk(
  "taxList/receivable",
  async (data: { orgId: number }) => {
    return await getTaxeReceivable(data);
  }
);

interface TaxConfigurationState {
  taxes: TaxConfigurations;
  taxPayableList: ObjectType[];
  taxReceivableList: ObjectType[];
}

const initialState: TaxConfigurationState = {
  taxes: {
    tax_configuration: [],
    total: 0,
    page_size: 10,
    pagination: {
      next: null,
      previous: null,
    },
  },
  taxPayableList: [{}],
  taxReceivableList: [{}],
};

export const taxConfigurationSlice = createSlice({
  name: "taxConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(taxesList.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxes = payload;
        }
      })
      .addCase(taxPayable.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxPayableList = payload.tc_list_payable;
        }
      })
      .addCase(taxReceivable.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length && !("error" in payload)) {
          state.taxReceivableList = payload.tc_list_receivable;
        }
      });
  },
});

export default taxConfigurationSlice.reducer;

export const taxesSelector = (state: RootState) => state.taxConfiguration.taxes;
export const taxPayableSelector = (state: RootState) =>
  state.taxConfiguration.taxPayableList;
export const taxReceivableSelector = (state: RootState) =>
  state.taxConfiguration.taxReceivableList;
