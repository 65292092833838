import React from "react";
import { MenuProps } from "react-select";

interface CustomMenuProps extends MenuProps<any> {
  className?: string;
}

const CustomMenu: React.FC<CustomMenuProps> = (props) => {
  const { innerProps, children, selectProps } = props;
  const { styles, menuIsOpen } = selectProps;

  const menuStyles = styles.menu ? styles.menu({}, props) : {};

  return (
    <div
      className={`select-field ${props.className || ""}`}
      style={
        {
          ...menuStyles,
          position: "absolute",
          zIndex: 15,
        } as React.CSSProperties
      }
      {...innerProps}
    >
      {menuIsOpen && children}
    </div>
  );
};

export default CustomMenu;
