import React from "react";
import { colors } from "../../../constants/colors";

const PreviousLabelSvg = React.memo(() => {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="9mhpebgn8a" d="m.938 0 4 4-4 4L0 7.062 3.063 4 0 .937z" />
      </defs>
      <g transform="matrix(-1 0 0 1 5 0)" fill="none" fillRule="evenodd">
        <mask id="fviaarhavb" fill={colors.white}>
          <use xlinkHref="#9mhpebgn8a" />
        </mask>
        <use fill={colors.charcoalGrey} xlinkHref="#9mhpebgn8a" />
        <g mask="url(#fviaarhavb)" fill={colors.charcoalGrey}>
          <path d="M-38-32h72v72h-72z" />
        </g>
      </g>
    </svg>
  );
});

export default PreviousLabelSvg;
