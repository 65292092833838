import Delete from "../../../../common/components/Delete";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { PriceListActionValue } from "../../addonType";

export type PricingListActionsProps = {
  value: PriceListActionValue;
  onClickDelete: (actionType: string, id: number, addonName: string) => void;
};

function AddonPricingListActions(props: PricingListActionsProps) {
  return (
    <>
      {!props.value.pricingExistsSubscription && (
        <KebabMenu>
          <li>
            <Delete
              className="dropdown-item delete"
              deleteIcon={true}
              isMutable
              onClick={() =>
                props.onClickDelete(
                  "Price",
                  props.value.pricngId,
                  props.value.frequency
                )
              }
              isLocked={false}
              lockDate={""}
              name="Addon"
            />
          </li>
        </KebabMenu>
      )}
    </>
  );
}

export default AddonPricingListActions;
