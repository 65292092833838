import React from "react";
import TableContainer from "../../../../../common/components/table/TableContainer";
import TableInfo from "../../../../../common/components/table/TableInfo";
import { InvoiceImage } from "../../../../../../assets/images";
import FilterEmptyList from "../../../../../common/components/FilterEmptyList";
import { TableData, Transactionlimit } from "../../../../../../types";
import EmptyList from "../../../../../common/components/EmptyList";

type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  billsRolePermission: any;
  columns: any[];
  data: TableData[];
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
  setPageInfo: (countPerPage: number, page: number) => void;
  sortHandler: (column: string) => void;
  hiddenColumns: string[];
};
function BillTable({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  billsRolePermission,
  columns,
  data,
  isFilterApplied,
  initializeFilter,
  transactionLimit,
  setPageInfo,
  sortHandler,
  hiddenColumns,
}: Props) {
  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className={
          "list-table-wrapper bills-list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
          `${totalList > 0 ? "" : "no-data"}`
        }
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={setPageInfo}
        />
        <div className="row">
          <div className="col-md-12">
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/bill"}
              sortHandler={sortHandler}
              editPermission={billsRolePermission.includes("Update")}
              isDetailViewExist={true}
              hiddenColumns={hiddenColumns}
            >
              {!isFilterApplied ? (
                <EmptyList
                  heading={
                    billsRolePermission.includes("Create")
                      ? "Create new bill!"
                      : "No Bills found!"
                  }
                  subheading={
                    billsRolePermission.includes("Create")
                      ? "Get paid faster by creating bills."
                      : "Oops, There is no Bill data available right now"
                  }
                  list="Bills"
                  alt="create bill preview"
                  buttonText="Create Bill"
                  navigateLink="/bill/create"
                  isCrateAccess={billsRolePermission.includes("Create")}
                  icon={InvoiceImage}
                  transactionLimit={transactionLimit.limit_exceed}
                />
              ) : (
                <FilterEmptyList
                  list="bills"
                  icon={InvoiceImage}
                  alt="list bill empty"
                  handleReset={initializeFilter}
                />
              )}
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(BillTable);
