import { useNavigate } from "react-router-dom";
import BackButton from "../../../../common/components/BackButton";
// import "./transactionHeader.css";

type Props = {
  transactionName: string;
  transactionNumber: number;
  handleSavePreference: (paymentMode: any) => void;
  handleSaveRoundOff: (paymentMode: any) => void;
  planId: string | undefined;
};
const TransactionHeader = (props: Props) => {
  const navigate = useNavigate();


  return (
    <div>
      <div>
        <BackButton onClick={() => navigate(`/plan/detail/${props.planId}`, {
                    replace: true,
                })} />
        <h1 className="fw-bold m-0">
            {props.transactionName}
        </h1>
      </div>
    </div>
  );
};

export default TransactionHeader;
