import { FormikProps } from "formik";
import { checkReportingTagNamexists } from "../reportingTagAPIFiles/reportingTagSlice";
import useCommonData from "../../../hooks/useCommon";

export const useCheckTagNameUniqueness = () => {
  // function to check tag name uniqueness
  const { dispatch, currentUserInfo } = useCommonData();
  const checkTagNameUniquiness = async (
    signal: AbortSignal,
    formik: FormikProps<any>
  ) => {
    formik.values.name.length > 1
      ? formik.setFieldTouched("name", true, true)
      : formik.setFieldTouched("name", false, true);
    const responseAction = await dispatch(
      checkReportingTagNamexists({
        orgId: currentUserInfo.organization_id,
        tagName: formik.values.name,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (response) {
        if (response.is_available === true) {
          formik.setFieldError("name", undefined);
        } else {
          if (formik.initialValues.name !== formik.values.name) {
            formik.setFieldError("name", "Reporting tag name already exists!");
          }
        }
      }
    }
  };
  return {checkTagNameUniquiness}
};
