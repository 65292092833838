import { Link } from "react-router-dom";
import { LockIconBlue } from "../../../../../assets/images";
import { TransactionDetailValues } from "../../../../../types";
import "../common/LockAllTransactionFooter.css";

type Props = {
  transactionDetails: TransactionDetailValues;
  openModal: (action: string, moduleName: string, moduleId: number[]) => void;
};
const TransactionLockingFooter = (props: Props) => {
  /**
   * Function to get name of locked transaction to show in footer
   */
  const getLockedTransactions = () => {
    const lockedModuleIds = props.transactionDetails.lockings
      .filter((module) => module.locking_type === "lock")
      .map((module) => module.module_id);
    const lockedModules: string[] = props.transactionDetails.modules
      .filter((module) => lockedModuleIds.includes(module.id))
      .map((module) => module.name);

    return lockedModules.length === 1
      ? lockedModules[0]
      : lockedModules.length === 2
      ? lockedModules[0] + " and " + lockedModules[1]
      : lockedModules.length === 3
      ? lockedModules[0] + ", " + lockedModules[1] + " and " + lockedModules[2]
      : "";
  };
  /**
   * Function to get whether any transaction is locked or not
   */
  const isLocked = () => {
    if (props.transactionDetails.lockings.length === 0) {
      return false;
    }
    return true;
  };
  return (
    <div className="lock-all-container bg-battleship-grey-light w-100 mtop-60">
      <h2>Lock All Transactions At Once</h2>
      <div className="detail-section w-100 d-flex">
        <div className="left-sect">
          {props.transactionDetails.lockings.length === 0 ? (
            <p>
              You can lock all transactions at once instead of locking the
              Sales, Purchases and Account transactions individually.
            </p>
          ) : (
            <p>
              You have only locked your {getLockedTransactions()} transactions.
              If you wish to lock all transactions on the same date, you can opt
              to lock all transaction at once.
            </p>
          )}
        </div>
        <div className="btn-sect">
          <Link
            to={isLocked() ? "#" : "lock-all-transactions"}
            className="lock-btn-link"
            onClick={() => {
              if (isLocked()) {
                props.openModal(
                  "unlock_all",
                  "Modules",
                  props.transactionDetails.lockings.map(
                    (module) => module.module_id
                  )
                );
              }
            }}
          >
            <img src={LockIconBlue} alt="lock icon" width={14} height={17} />
            <span className="lock-btn-text">Switch to Lock all Transactions</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TransactionLockingFooter;
