import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { Form } from "../../../../../common/components/htmlTags/Form";
import useCommonData from "../../../../../hooks/useCommon";
import "./ApplyCredits.css";
import { useApplyCredits } from "./hooks/useApplyCredits";
import { ApplyCreditItem } from "./components/ApplyCreditsItem/ApplyCreditItem";
import { ApplyCreditBalanceSummary } from "./components/ApplyCreditFormFooter/ApplyCreditBalanceSummary";
import { ApplyCreditButtonGroup } from "./components/ApplyCreditFormFooter/ApplyCreditButtonGroup";
import { ApplyCreditFormValidation } from "./components/validation/ApplyCreditFormValidation";

type ApplyCreditToBillProps = {
  conversionId: number;
  currencyCode: string;
  currencyId: number;
  balance: number;
  onClose: () => void;
  getIsError: (isError: boolean) => void;
  refreshBillDetails: () => void;
};
export const ApplyCreditsForm = (props: ApplyCreditToBillProps) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    updateAllocationData,
    calculateAllocationAmount,
    getApplyCreditList,
  } = useApplyCredits();
  const formik = useFormik({
    initialValues: {
      balance: "",
      amount_to_debit: "",
      bill_balance: "",
      transactions_to_credit: [],
      min_date: currentUserInfo.previous_fiscal_end_date,
    },
    validationSchema: ApplyCreditFormValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);
      updateAllocationData(
        values.transactions_to_credit,
        props.refreshBillDetails,
        () => {}
      ).finally(() => {
        setSubmitting(false);
        props.onClose();
      });
    },
  });
  useEffect(() => {
    getApplyCreditList(formik);
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    calculateAllocationAmount(
      formik.values.transactions_to_credit,
      formik,
      props.balance,
      props.currencyId,
      signal
    );

    return () => {
      controller.abort();
    };
  }, [JSON.stringify(formik.values.transactions_to_credit)]);
  useEffect(() => {
    const isError = JSON.stringify(formik.errors.transactions_to_credit || [])
      .toString()
      .includes("amount_debit");
    props.getIsError(isError);
  }, [, JSON.stringify(formik.errors)]);
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container className="invoice-allocation-list-table-wrapper">
        <table className="table apply-creds-table w-100 position-relative invoice-allocation-table">
          <thead
            className="border-top-0 unpaid-payments-header sticky-top"
            style={{ top: 0, left: 0, backgroundColor: "white" }}
          >
            <tr className="w-100">
              <th scope="col">CREDIT TYPE</th>
              <th scope="col">TRANSACTION ID </th>
              <th>DATE</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">BALANCE</th>
              <th scope="col">ALLOCATION DATE</th>
              <th>AMOUNT TO CREDIT</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.transactions_to_credit.map(
              (item: any, index: number) => {
                return (
                  <ApplyCreditItem
                    formik={formik}
                    errors={formik.errors.transactions_to_credit}
                    item={item}
                    currencyCode={props.currencyCode}
                    index={index}
                  />
                );
              }
            )}
          </tbody>
        </table>
      </Container>
      <ApplyCreditBalanceSummary
        formik={formik}
        currencyCode={props.currencyCode}
      />
      <ApplyCreditButtonGroup
        onCancel={() => {
          props.onClose();
        }}
        onSave={() => {}}
        isSaveDisabled={
          Number(formik.values.balance) < 0 ||
          Object.keys(formik.errors).length > 0 ||
          isSubmitting
        }
      />
    </Form>
  );
};
