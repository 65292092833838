import React from "react";
import { PlusIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import { DebitNoteDetailValues } from "../../../../../types";
import useToast from "../../../../hooks/useToast";
import ApplyToBillsModal from "./ApplyToBillsModal";

type Props = {
  debitNoteData: DebitNoteDetailValues;
  isVoid?: boolean;
  refreshDNDetails: () => void;
};
function ApplyToBills(props: Props) {
  const { clearToast } = useToast();

  const buttonComponet = (
    <span>
      <PlusIcon color={colors.charcoalGrey} />
      Apply to Bills
    </span>
  );
  return (
    <>
      {props.debitNoteData.bill_status || props.isVoid ? (
        <ApplyToBillsModal
          buttonComponent={buttonComponet}
          debitNoteData={props.debitNoteData}
          refreshDNDetails={props.refreshDNDetails}
          buttonClass="dropdown-item"
          isVoid={props.isVoid}
        />
      ) : (
        <>
          <button
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#notificationPopupModal"
            onClick={() => {
              clearToast("error");
            }}
          >
            {buttonComponet}
          </button>
        </>
      )}
    </>
  );
}

export default ApplyToBills;
