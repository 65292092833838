import React, { useEffect, useId, useState } from "react";
import MultiSelectAutocomplete from "../../../../../common/components/ui/formElements/MultiSelectAutocomplete/MultiSelectAutocomplete";
import { fetchAccountsGlCode } from "../../../../chartOfAccounts/coaSlice";
import { Account } from "../../../../../../types";
import useCommonData from "../../../../../hooks/useCommon";
import DropDownArrowDown from "../../../../../common/components/customSvgIcons/DropDownArrowDown";
import "./MultiselectAccount.css";

type Props = {
  organizationId: number;
  baseAccount?: string;
  currencyFilter?: number;
  onChange: (accountIds: number[]) => void;
  error: string;
  errorClass?: string;
  errorMessage?: string;
  className?: string;
  isDisabled?: boolean;
  name?: string;
  id?: string;
  wrapperClass?: string;
  onBlur?: (e: any) => void;
  selectedAccounts?: number[];
};

const MultiselectAccount = (props: Props) => {
  const { dispatch } = useCommonData();
  const [accountListResp, setAccountListResp] = useState<Account[]>([]);
  const [selectedValues, setSelectedValues] = useState<Account[]>([]);

  const fieldId = useId();
  let abortController = new AbortController();

  // Fetch accounts when organizationId changes
  useEffect(() => {
    getAccountList();
  }, [props.organizationId]);

  // Populate selected values based on props.selectedAccounts (for editing)
  useEffect(() => {
    if (accountListResp.length > 0 && props.selectedAccounts) {
      const selectedAccounts = accountListResp.filter((account) =>
        props.selectedAccounts?.includes(account.id)
      );
      setSelectedValues(selectedAccounts);
    }
  }, [accountListResp, props.selectedAccounts]);

  // Fetch account list from API
  const getAccountList = async () => {
    const signal = abortController.signal;
    const responseAction = await dispatch(
      fetchAccountsGlCode({
        accountSearchTerm: "",
        baseAccount: props.baseAccount || "",
        orgId: props.organizationId,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAccountListResp(response.account_list); // Set account list
      }
    } else {
      setAccountListResp([]); // Empty list if no response
    }
  };

  // Handler for selection (handles adding new selected accounts)
  const handleSelect = (selectedList: Account[]) => {
    setSelectedValues(selectedList);
    props.onChange(selectedList.map((item) => item.id));
  };

  return (
    <>
      <MultiSelectAutocomplete
        id={props.id ? props.id : fieldId}
        error={props.error ? props.error : ""}
        data={accountListResp}
        selectedValues={selectedValues}
        getOptionLabel={(option) => option.account_name} // Primary label for display
        getOptionSecondaryLabel={(option) => option.account_type} // Secondary label for display
        getOptionKey={(option) => option.id} // Unique key for each account
        changeHandler={handleSelect}
        noOptionText="No Matches found"
        placeholderText="Select accounts"
        popupIcon={<DropDownArrowDown />}
        clearIcon={null}
        isDisabled={props.isDisabled ? props.isDisabled : false}
      />
      {props.error ? (
        <span
          className={`error clear-both ${
            props.errorClass ? props.errorClass : ""
          }`}
        >
          {props.error}
        </span>
      ) : null}
    </>
  );
};

export default MultiselectAccount;
