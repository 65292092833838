import {
  CustomerItemValues,
  RecurringInvoiceValues,
  ObjectType,
} from "../../../../../types";

/**
 * Validate form values
 */
export const validate = (
  invoiceFormValues: RecurringInvoiceValues,
  action: string,
  status: string | boolean,
  emailLists: [] | string[],
  isProfileNameExist: boolean,
  itemFormValues: CustomerItemValues[],
  fileRef: React.MutableRefObject<any>,
  isGstOrg: boolean
) => {
  
  const errors: ObjectType = {};
  if (!invoiceFormValues.customer_id) {
    errors.customer_name = "Please enter customer name";
  }
  if (!invoiceFormValues.profile_name) {
    errors.profile_name = "Please enter Profile name";
  }
  if (!invoiceFormValues.repeat_every) {
    errors.repeat_every = "Please enter repeat";
  }
  if (isGstOrg && !invoiceFormValues.place_of_supply) {
    errors.place_of_supply = "Please select place of supply";
  }

  if( invoiceFormValues.repeat_every === 'custom' && !invoiceFormValues.custom_repeat_frequency) {
    errors.custom_repeat_frequency = "Please enter custom repeat frequency";
  }

  if( invoiceFormValues.repeat_every === 'custom' && !invoiceFormValues.custom_repeat_count) {
    errors.custom_repeat_count = "Please enter custom repeat count";
  }

  if (
    invoiceFormValues.start_on &&
    invoiceFormValues.start_on === "Invalid date" 
  ) {
    errors.start_on = "Please enter valid start date";
  }
  if (invoiceFormValues.ends_on &&
    invoiceFormValues.ends_on === "Invalid date" ) {
    errors.ends_on = "Please enter valid end date";
  }

  if (
    invoiceFormValues.start_on &&
    invoiceFormValues.ends_on &&
    new Date(invoiceFormValues.ends_on) < new Date(invoiceFormValues.start_on)
  ) {
    errors.ends_on = "End date cannot be later than the start date.";
  }

  if (invoiceFormValues.tds_percentage > 100) {
    errors.tds_percentage = "TDS should be less than 100 %";
  }
  if (isProfileNameExist) {
    errors.profile_name =
      "Invoice number already exists. Please choose a different one";
  }
  if (invoiceFormValues.preferred_invoice_status !== "Save as Draft" || invoiceFormValues.is_email_reminder_automatic) {
    if (emailLists?.length === 0 && action === "Send") {
      errors.email = "Please enter at least one email address";
    }
  } else {
    errors.email = "";
    delete errors.email;
  }
  if (itemFormValues.length <= 0) {
    errors.items = "* Please add at least one item";
  }
  if (fileRef.current.fileIds.length > 3) {
    errors.attachFiles = "* Maximum File Limit Reached";
  }
  return errors;
};
export const validateItemValues = (
  values: CustomerItemValues,
  itemIndex: number,
  status: string,
  taxFormValues: CustomerItemValues[]
) => {
  const errors: ObjectType = {};
  let flag = false;
  if (status !== "Draft") {
    if (!values.quantity || values.quantity === 0) {
      errors.quantity = "Please enter quantity";
      flag = true;
    }
    if (!values.rate) {
      errors.rate = "Please enter rate";
      flag = true;
    }
    if (values.discount) {
      if (
        values.discount_type === "Absolute" &&
        Number(values.discount) > Number(values.rate) * Number(values.quantity)
      ) {
        errors.discount = "Discount cannot not be higher than rate * quantity";
        flag = true;
      } else if (
        values.discount_type === "Percentage" &&
        Number(values.discount) > 100
      ) {
        errors.discount = "Discount cannot not be higher than rate * quantity";
        flag = true;
      }
    }
  }
  if (!values.discount && values.discount_account_id) {
    errors.discount = "Please enter discount";
    flag = true;
  }
  if (values.discount && !values.discount_account_id) {
    errors.discount_account_id = "Please choose discount account";
    flag = true;
  }
  if (!values.item_name) {
    errors.item_name = "Please enter item name";
    flag = true;
  }
  if (!values.account_id) {
    errors.account_id = "Please enter account";
    flag = true;
  }
  let hsnRegex = /^\d{6}$|^\d{8}$/;
  if (values.hsn_or_sac_code === null) {
    values.hsn_or_sac_code = "";
  }
  if (
    values.hsn_or_sac_code &&
    !hsnRegex.test(values.hsn_or_sac_code.toString()) &&
    values.hsn_or_sac_code !== ""
  ) {
    errors.hsn_or_sac_code = "Please enter valid hsn/sac code";
    flag = true;
  }
  if (taxFormValues[itemIndex]?.taxes) {
    let taxes = taxFormValues[itemIndex].taxes;
    let taxLabels: any[] = [];
    let taxError: any[] = [];
    for (let tax of taxes) {
      if (taxLabels.includes(tax.tax_id)) {
        flag = true;
        taxError.push({
          tax_id: "Tax label already used",
          tax_percentage: 0,
        });
      } else if (tax.tax_id === "" && Number(tax.tax_percentage) > 0) {
        flag = true;
        taxError.push({
          tax_id: "Please choose tax code",
          tax_percentage: 0,
        });
      } else {
        taxError.push({ tax_id: "", tax_percentage: 0 });
        if (tax.tax_id) {
          taxLabels.push(tax.tax_id);
        }
      }
    }
    errors.taxes = taxError;
  }
  if (flag) return errors;
  else return false;
};
