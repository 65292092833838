import { useAppDispatch } from "../../../../../app/hooks";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { formateDateMonth } from "../../../../../helpers/subscriptionHelper";
import { SubscriptionDetailHeaderValues } from "../../../../../types";
import Delete from "../../../../common/components/Delete";
import InfoTooltip from "../../../../common/components/InfoTooltip";
import { message } from "../../../../constants/messages";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import {
  deleteUnbilledInvoice,
  invoiceUnbilledCharges,
} from "../../subscriptionSlice";
import { UnbilledChangesList } from "../../subscriptionType";
import { toast } from "react-toastify";
import DeleteModal from "../../../../common/components/DeleteModal";
import { useState } from "react";
import { setLoaderState } from "../../../../common/commonSlice";
import { ConfirmationAlertModal } from "../../../../common/components/confirmationModal/ConfirmationModal";
import { useParams } from "react-router-dom";

type Props = {
  unbilledChargesData: UnbilledChangesList[];
  overviewSubscriptionData: SubscriptionDetailHeaderValues;
  refreshUnbilledList: () => void;
};
function UnbilledTable(props: Props) {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { subscriptionRolePermission } = usePermissionHandler();
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const [unbilledId, setUnbilledId] = useState<number>(0);
  const [isInvoiceNowOpen, setIsInvoiceNowOpen] = useState<boolean>(false);

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const unbilledChargesDeleteHandler = async () => {
    const deleteRoleResponseAction = await dispatch(
      deleteUnbilledInvoice({
        deleteUnbilledId: unbilledId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (
        Object.keys(deleteRoleResponse).length &&
        !("error" in deleteRoleResponse)
      ) {
        modalCancelHandler();
        dispatch(setLoaderState(true));
        props.refreshUnbilledList();
        toast.success("Unbilled charges deleted successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        modalCancelHandler();
      }
    }
  };

  const unbilledChargesInvoiceNowHandler = async () => {
    const deleteRoleResponseAction = await dispatch(
      invoiceUnbilledCharges({
        subscriptionId: Number(subscriptionId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (
        Object.keys(deleteRoleResponse).length &&
        !("error" in deleteRoleResponse)
      ) {
        setIsInvoiceNowOpen(false);
        dispatch(setLoaderState(true));
        props.refreshUnbilledList();
        toast.success("Unbilled charges invoiced successfully!", {
          toastId: "role-invoiced-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        setIsInvoiceNowOpen(false);
      }
    }
  };
  return (
    <>
      <div className="card-body unbilled-charges-list">
        <div
          id="invoice-table-wrapper-1"
          className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="table-container table-responsive ">
                <table
                  role="table"
                  id="invoice_list_table_1"
                  className="list-table table w-100 subscription-unbilled-table"
                >
                  <thead>
                    <tr role="row">
                      <th
                        role="columnheader"
                        data-orderable="false"
                        className="title-header"
                      >
                        <div>
                          Unbilled Charges
                          <InfoTooltip
                            title={message().subscription.unbilledCharges}
                            placement={"top"}
                          />
                        </div>
                      </th>
                      <th role="columnheader" data-orderable="false"></th>
                      <th role="columnheader" data-orderable="false"></th>
                      <th
                        role="columnheader"
                        data-orderable="true"
                        className="text-table-end"
                      >
                        <div
                          className="invoice-now-button"
                          onClick={() => {
                            setIsInvoiceNowOpen(true);
                          }}
                        >
                          Invoice Now
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {props.unbilledChargesData &&
                      props.unbilledChargesData.length > 0 &&
                      props.unbilledChargesData.map(
                        (unbilledCharges: UnbilledChangesList) => {
                          return (
                            <tr key={unbilledCharges.id} role="row">
                              <td role="cell">
                                {`Subscription charges for ${formateDateMonth(
                                  DateFormatHandler(unbilledCharges.start_date)
                                )} to ${formateDateMonth(
                                  DateFormatHandler(unbilledCharges.end_date)
                                )}`}
                              </td>
                              <td role="cell">
                                {`${
                                  unbilledCharges.currency_code || ""
                                } ${NumberFormat(
                                  Number(unbilledCharges.unbilled_amount),
                                  unbilledCharges.currency_code || "",
                                  orgCurrencyList
                                )}`}
                              </td>
                              <td role="cell">
                                {`Created on: ${formateDateMonth(
                                  DateFormatHandler(unbilledCharges.created_on)
                                )}`}
                              </td>
                              <td role="cell" className="">
                                {!subscriptionRolePermission.includes(
                                  "Delete"
                                ) &&
                                props.overviewSubscriptionData
                                  .organization_id !==
                                  currentUserInfo.organization_id ? null : (
                                  <div>
                                    <Delete
                                      className="text-decoration-none delete-item delete-col"
                                      deleteIcon={true}
                                      isMutable={true}
                                      onClick={() => {
                                        setUnbilledId(unbilledCharges.id);
                                      }}
                                      isLocked={false}
                                      lockDate=""
                                      name="Subscription"
                                      isChildInvoice={false}
                                      modalType="unbilledCharges"
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        heading={`Delete Unbilled Charges!`}
        subHeading={`Are you sure you want to delete the unbilled charges ?`}
        transactionName={""}
        modalCancelHandler={modalCancelHandler}
        modalSubmitHandler={() => {
          unbilledChargesDeleteHandler();
        }}
        modalType="unbilledCharges"
      />
      <ConfirmationAlertModal
        onCancel={() => {
          setIsInvoiceNowOpen(false);
        }}
        onSubmit={() => {
          unbilledChargesInvoiceNowHandler();
        }}
        alertModalOpen={isInvoiceNowOpen}
        headerMessage="Invoice Unbilled Charges!"
        bodyMessage="Are you sure you want to invoice all unbilled charges"
      />
    </>
  );
}

export default UnbilledTable;
