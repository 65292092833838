export const handleNumberOnlyKeydownEntry = (
  event: React.KeyboardEvent<HTMLInputElement>,
  setCopyPaste?: (isCopyPaste: boolean) => void
) => {
  // Check for copy (Ctrl+C) and paste (Ctrl+V) shortcuts
  if (
    (event.ctrlKey || event.metaKey) &&
    (event.key === "c" ||
      event.key === "v" ||
      event.key === "a" ||
      event.key === "x")
  ) {
    setCopyPaste && setCopyPaste(true);
    return; // Allow copy-paste functionality
  } else {
    setCopyPaste && setCopyPaste(false);
  }
  // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
  if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
    event.preventDefault(); // Prevent the input of special characters
  }
};
