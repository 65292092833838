import { convertToPascalCase } from "../../../../helpers/convertToPascalHelper";
import "../../../../index.css";
import "./BreadCrumbs.css";
type Props = {
  crumbsArray: string[];
  fontSize: string;
};
export const BreadCrumbs = (props: Props) => {
  function joinAndCapitalize(words: string[]): string {
    return words.map((word) => convertToPascalCase(word)).join(" > ");
  }
  return (
    <span className="bread-crumbs" style={{ fontSize: props.fontSize }}>
      {joinAndCapitalize(props.crumbsArray)}
    </span>
  );
};
