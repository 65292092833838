import React from "react";
import { colors } from "../../../constants/colors";

const CoaTreeViewIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          id="tree_view"
          d="M8.333 1.667c.46 0 .834.373.834.833v3.333c0 .46-.374.834-.834.834H6.667v1.666h4.166V7.5c0-.46.374-.833.834-.833h5c.46 0 .833.373.833.833v3.333c0 .46-.373.834-.833.834h-5a.834.834 0 0 1-.834-.834V10H6.667v5h4.166v-.833c0-.46.374-.834.834-.834h5c.46 0 .833.374.833.834V17.5c0 .46-.373.833-.833.833h-5a.834.834 0 0 1-.834-.833v-.833h-5A.834.834 0 0 1 5 15.833V6.667H3.333a.834.834 0 0 1-.833-.834V2.5c0-.46.373-.833.833-.833h5zM15.833 15H12.5v1.667h3.333V15zm0-6.667H12.5V10h3.333V8.333zm-8.333-5H4.167V5H7.5V3.333z"
          fill={colors.ceruleanBlue}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default CoaTreeViewIcon;
