import React, { useEffect, useMemo, useState } from "react";
import BalanceTable from "./BalanceTable";
import ReportFilter from "../componets/ReportFilter";
import ReportHeader from "../componets/ReportHeader";
import "./Balance.css";
import { getVendorBalanceReport } from "../ReportSlice";
import useCommonData from "../../../hooks/useCommon";
import { setLoaderState } from "../../../common/commonSlice";
import { getFormatedDate } from "../../../../helpers/helper";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { Cell, Row } from "react-table";
import { TableData } from "../../../../types";
import { useLocation } from "react-router-dom";

function VendorBalance() {
  const initialData = {
    currency_id: 0,
    currency_code: "INR",
    date_range: "today",
    organization_id: 0,
    end_date: getFormatedDate(),
    balance_list: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
    },
    total_balance_base: 0,
  };

  const location = useLocation();
  const { dispatch, currentUserInfo, orgCurrencyList, navigate } =
    useCommonData();
  const { subModulesWithPermission } = usePermissionHandler();
  const [reportData, setReportData] = useState<any>(initialData);
  const [showReport, setShowReport] = useState(true);
  const reportRef = React.useRef<any>([]);
  const [filterString, setFilterString] = useState("");
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  const initialFiltervalues = {
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    date_range: filters ? filters.date_range : "today",
    end_date: filters ? filters.end_date : getFormatedDate(),
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
    vendor_id: filters ? filters.vendor_id : 0,
  };
  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    )
      getReport("initial");
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const handleReport = (type: string) => {
    getReport(type);
  };
  const getReport = async (type: string) => {
    dispatch(setLoaderState(true));
    let filterValues: any = reportRef.current?.filterValues;
    if (type === "initial" || !filterValues) filterValues = initialFiltervalues;
    if (filterValues.vendor_id === 0)
      filterValues = {
        ...filterValues,
        vendor_id: null,
      };
    const tempFilterString = JSON.stringify(filterValues);
    const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
    setFilterString(hashFragment);
    const responseAction = await generateReport(filterValues);
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let reportDetails = response;
        setReportData(reportDetails);
        dispatch(setLoaderState(false));
        setShowReport(true);
      }
    }
    dispatch(setLoaderState(false));
  };
  const generateReport = async (filterValues: any) => {
    const responseAction = await dispatch(
      getVendorBalanceReport({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Vendor Name",
        accessor: "show.name",
        className: "column-navigation consumer-name  text-align-left",
      },
      {
        Header: "Organization",
        accessor:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organization"
            : "Organization",
        className: "org",
      },
      {
        Header: "Bill Balance (FCY)",
        accessor: "show.billBalance",
        className:
          "column-navigation bill-balance-fcy  text-nowrap text-align-right",
      },
      {
        Header: "Excess Payment (FCY)",
        accessor: "show.excessPayment",
        className:
          "column-navigation excess-payment-fcy text-nowrap text-align-right",
      },
      {
        Header: "Balance (FCY)",
        accessor: "show.balanceFcy",
        className: "column-navigation balance-fcy text-nowrap text-align-right",
      },
      {
        Header: "Balance (BCY)",
        accessor: "show.balanceBcy",
        className: "balance-bcy text-nowrap text-align-right",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    []
  );
  const data = () => {
    let report = [];
    if (reportData.balance_list && reportData.balance_list.length > 0) {
      report = reportData.balance_list.map((item: any, index: number) => ({
        show: {
          name: item.vendor_name,
          organization: item.organization_name,
          billBalance: `${item.currency_code} ${NumberFormat(
            Number(item.bill_balance),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          excessPayment: `${item.currency_code} ${NumberFormat(
            Number(item.excess_payment),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          balanceFcy: `${item.currency_code} ${NumberFormat(
            Number(item.balance),
            reportData.currency_code,
            orgCurrencyList
          )}`,
          balanceBcy: `${reportData.currency_code} ${NumberFormat(
            Number(item.balance_base),
            reportData.currency_code,
            orgCurrencyList
          )}`,
        },
        action: {
          userId: item.vendor_id,
          detailReportUrl: `/vendor-balance-details`,
          userUrl: `/vendors/detail/${item.vendor_id}${filterString}`,
          userPermission: subModulesWithPermission.includes("Vendor"),
          customNavigation: true,
        },
      }));
      report.push({
        show: {
          name: "",
          billBalance: "",
          excessPayment: "",
          balanceFcy: "Total",
          balanceBcy: `${reportData.currency_code} ${NumberFormat(
            Number(reportData.total_balance_base),
            reportData.currency_code,
            orgCurrencyList
          )}`,
        },
        className: "customRowTitle total-row no-click-action",
      });
    }
    return report;
  };
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (cell.column.Header) {
      let action = row?.values?.action;
      if (
        cell.column.Header === "Bill Balance (FCY)" ||
        cell.column.Header === "Excess Payment (FCY)" ||
        cell.column.Header === "Balance (FCY)"
      ) {
        let balanceType =
          cell.column.Header === "Bill Balance (FCY)"
            ? "bill_balance"
            : cell.column.Header === "Excess Payment (FCY)"
            ? "excess_payment"
            : cell.column.Header === "Balance (FCY)"
            ? "balance"
            : "";
        if (action?.detailReportUrl) {
          navigate(`?${filterString}`);
          navigate(
            `${action?.detailReportUrl}?id=${action.userId}&type=${balanceType}${filterString}`
          );
        }
      } else if (cell.column.Header === "Vendor Name") {
        if (action?.userUrl && action?.userPermission) {
          navigate(`?${filterString}`);
          navigate(action?.userUrl);
        }
      }
    }
  };

  return (
    <div className="reports vendor-balance">
      <div
        className="card card-dashboard report-card balance-sheet-report-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Vendor Balances</h1>
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={true}
                isAdvanced={true}
                defaultValue="today"
                reportType="VB"
                handleReport={handleReport}
                clearReport={() => setShowReport(false)}
                isEntity={true}
                entityType="Vendor"
                ref={reportRef}
              />
            </div>
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center ">
                  {/* <ReportHeaderActionLinks /> */}
                </div>
                <ReportHeader
                  reportName="Vendor Balances"
                  reportData={reportData}
                />
                <div className="table-section report-table-section">
                  <BalanceTable
                    columns={columns}
                    data={data()}
                    className="vendor-balance-report navigation"
                    navigation={navigation}
                    haveColumnClassName={true}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorBalance;
