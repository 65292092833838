import "./lineItemWrapper.css";

type Props = {
  children: React.ReactNode;
  wrapperClass?: string;
};

const LineItemWrapper = (props: Props) => {
  return (
    <div className={`item-row ${props.wrapperClass ? props.wrapperClass : ""}`}>
      {props.children}
    </div>
  );
};

export default LineItemWrapper;
