import Grid from "@mui/material/Grid";
import ListCellTablePopoverSubscription from "../../../../common/components/listCellPopover/ListCellTablePopoverSubscription";
import { useState } from "react";
import { ItemValue, RangePriceValue } from "../../subscriptionType";
import { BlueAccountSelectIcon } from "../../../../../assets/images";

type CustomPriceColumnProps = {
    value: ItemValue[];
    pricingModel: string;
    index: number;
    parentIndex?: number;
    currencyCode: string;
    currencyId: number | string;
    onPricePointUpdate: (updatedRange: RangePriceValue[]) => void;
};

function SubscriptionPricingDetails(props: CustomPriceColumnProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false)
    const onMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if(anchorEl === null) {
        setOpen(true)
        setAnchorEl(event.currentTarget);
      }
    };
  
    const onPopoverClose = () => {
      setOpen(false)
      setAnchorEl(null);
    };  

    const onUpdateButtonClick = (updatedRange: RangePriceValue[]) => {
      props.onPricePointUpdate(updatedRange)
      onPopoverClose()
    }

    const id = `price-item-popover ${props.index} ${props.parentIndex}`

    return (
      <>
        <Grid container spacing={1} sx={{ padding: '0px 0px', margin: '0px' }}>
          <Grid item xs={12} className="addon-pricing-item-grid"
          >
            <span
              aria-describedby={id}
              onClick={onMouseOver}
            >
              <img src={BlueAccountSelectIcon} alt="Selected" style={{ paddingRight: "10px", height: "18px", marginBottom: "4px" }} />
              <span
                className="addon-pricing-item view-pricing-breakdown-btn"
                style={{ color: '#0D5AE5' }}
              >
                View pricing breakdown
              </span>
            </span>
          </Grid>
        </Grid>

        <ListCellTablePopoverSubscription
          id={id}
          open={open}
          anchorEl={anchorEl}
          value={props.value}
          currencyId={props.currencyId}
          onPopoverClose={onPopoverClose}
          onUpdateButtonClick={onUpdateButtonClick}
        />
      </>
    );
}

export default SubscriptionPricingDetails;
