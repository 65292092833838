import { useEffect, useState } from "react";
import { Transactionlimit } from "../../types";
import {
  dateSortOrderSelector,
  endCountSelector,
  itemOffsetSelector,
  itemsPerPageSelector,
  nameSortOrderSelector,
  pageCountSelector,
  pageSelector,
  roleSortOrderSelector,
  startCountSelector,
  totalListSelector,
} from "../../appSlice";
import { useAppSelector } from "../../app/hooks";
import { usePaginationDispatch } from "./usePaginationDispatch";

const useListData = () => {
  const {
    page,
    itemsPerPage,
    startCount,
    endCount,
    pageCount,
    itemOffset,
    dateSortOrder,
    totalList,
    nameSortOrder,
    roleSortOrder,
  } = useAppSelector((state) => ({
    page: pageSelector(state),
    itemsPerPage: itemsPerPageSelector(state),
    startCount: startCountSelector(state),
    endCount: endCountSelector(state),
    pageCount: pageCountSelector(state),
    itemOffset: itemOffsetSelector(state),
    dateSortOrder: dateSortOrderSelector(state),
    totalList: totalListSelector(state),
    nameSortOrder: nameSortOrderSelector(state),
    roleSortOrder: roleSortOrderSelector(state),
  }));
  // const [totalList, setTotalList] = useState(0);
  const { setPage, setPageCount, setItemOffset } = usePaginationDispatch();

  useEffect(() => {
    let pageCount = Math.ceil(totalList / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount != 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalList, page]);

  // const handleTotalList = (value: number) => {
  //   setTotalList(value);
  // };

  return {
    page,
    itemsPerPage,
    startCount,
    endCount,
    pageCount,
    itemOffset,
    dateSortOrder,
    totalList,
    setPage,
    setPageCount,
    setItemOffset,
    nameSortOrder,
    roleSortOrder,
  };
};

export default useListData;
