import { useEffect, useState } from "react";
import { CustomFieldValues } from "../../types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { currentUserSelector } from "../common/commonSlice";
import { getCustomFieldModuleList } from "../home/customFields/components/customFieldAPIFiles/customFieldSlice";

export const useModuleCustomFieldList = (module: string) => {
  const [customFieldList, setCustomFieldList] = useState<CustomFieldValues[]>(
    []
  );
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const fetchCustomField = async () => {
    const responseAction = dispatch(
      getCustomFieldModuleList({
        orgId: currentUserInfo.organization_id,
        transaction_type: module,
      })
    );
    const response = (await responseAction).payload;
    if (Object.keys(response).length && !("error" in response)) {
      setCustomFieldList(response);
    } else {
      setCustomFieldList([]);
    }
  };
  useEffect(() => {
    fetchCustomField();
  }, [currentUserInfo.organization_id]);
  return customFieldList;
};
