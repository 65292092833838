/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import RecurringInvoiceViewHeader from "./RecurringInvoiceViewHeader";
import InvoiceTable from "./InvoiceTable";
import { setErrorID } from "../../../../../appSlice";
import useCommonData from "../../../../hooks/useCommon";
import {
  noAccessErrorCodes,
  tdsApplyLevel,
} from "../../../../constants/constants";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";

import { DocIconSquare, Locator } from "../../../../../assets/images";
import "./recurringInvoiceView.css";
import { useParams } from "react-router-dom";
import { setLoaderState } from "../../../../common/commonSlice";
import { invoiceDetails, childInvoiceList } from "../../recurringInvoiceSlice";
import {
  RecurringInvoiceDetailValues,
  RecurringInvoiceAddressValuesForView,
  InvoiceList,
} from "../../../../../types";
import {
  previewDetailsInitialValues,
  previewDetailsInitialValuesView,
  recurringInvoiceAddressInitialValues,
} from "../recurringInvoiceForm/StateInitialization";
import AddressOverviewCard from "./AddressOverviewCard";
import TableInfo from "./TableInfo";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import FileList from "../../../../common/components/FileList";
import useGst from "../../../../hooks/useGst";

const RecurringInvoiceView = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [billingAddress, setBillingAddress] =
    useState<RecurringInvoiceAddressValuesForView>(
      recurringInvoiceAddressInitialValues
    );
  const [shippingAddress, setShippingAddress] =
    useState<RecurringInvoiceAddressValuesForView>(
      recurringInvoiceAddressInitialValues
    );
  const { recurringInvoiceId } = useParams();
  const { isGstOrg } = useGst();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();

  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const [totalList, setTotalList] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [overviewInvoiceData, setOverviewInvoiceData] =
    useState<RecurringInvoiceDetailValues>(previewDetailsInitialValuesView);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [childInvoiceData, setChildInvoiceData] = useState<InvoiceList[]>([]);
  // let taxTypes = [{}];
  let taxTypes = overviewInvoiceData.total_details.taxes
    ? Object.entries(overviewInvoiceData.total_details.taxes)
    : [];

  /**
   * Format date from YYYY-MM-DD to DD-MM-YYYY
   */
  const dateFormatHandler = (date: string) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };
  /**
   * Fetch Invoice Details
   */
  useEffect(() => {
    if (recurringInvoiceId) {
      recurringInvoiceDetail();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    let pageCount = Math.ceil(totalList / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount !== 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalList, page]);

  const recurringInvoiceDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      invoiceDetails({
        invoiceId: Number(recurringInvoiceId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setOverviewInvoiceData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        navigate("/recurring-inv");
      }
    }
  };

  /**
   * Fetch Child Invoice List
   */
  const fetchChildInvoiceList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      childInvoiceList({
        page,
        itemsPerPage,
        dateSortOrder,
        invoiceId: Number(recurringInvoiceId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setChildInvoiceData(response.invoices);
        setTotalList(response.total);
      }
    }
  }, [page, dateSortOrder, currentUserInfo.organization_id]);

  useEffect(() => {
    fetchChildInvoiceList();
  }, [page, currentUserInfo.organization_id, dateSortOrder]);

  useEffect(() => {
    const billing = overviewInvoiceData.address_details.find(
      (e) => e.is_billing === true
    );
    setBillingAddress(billing ?? recurringInvoiceAddressInitialValues);
    const shipping = overviewInvoiceData.address_details.find(
      (e) => e.is_shipping === true
    );
    setShippingAddress(shipping ?? recurringInvoiceAddressInitialValues);
  }, [overviewInvoiceData.address_details]);

  /**
   * Setting active tab in session storage
   */
  function handleTabClick(tab: string) {
    setActiveTab(tab);
    sessionStorage.setItem("childInvoice", tab);
  }

  useEffect(() => {
    const storedTab = sessionStorage.getItem("childInvoice");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  function capitalizeWords(str: string, count: string) {
    str = count == "1" ? str.replace("s", "") : str;
    let newStr = str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
    return newStr;
  }

  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={overviewInvoiceData.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          overviewInvoiceData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {overviewInvoiceData.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  overviewInvoiceData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    if (isGstOrg) {
      baseColumns.splice(1, 0, {
        Header: "Hsn/sac code",
        accessor: "show.hsnCode",
        className: "hsncode text-nowrap",
      });
    }

    return baseColumns;
  }, [overviewInvoiceData.currency_code, isGstOrg]);

  /**
   * set table row data
   */
  const data = overviewInvoiceData.item_details
    ? overviewInvoiceData.item_details.map(
        (
          itemData: {
            item_name: any;
            hsn_or_sac_code: any;
            account_name: any;
            quantity: any;
            rate: any;
            discount: { discount_value: any };
            discount_account_name: any;
            gst_tax: { tax_id: null };
            taxes: any;
            taxable_amount: any;
          },
          index: any
        ) => ({
          show: {
            description: itemData.item_name,
            hsnCode: itemData.hsn_or_sac_code,
            account: itemData.account_name,
            quantity: NumberFormat(
              Number(itemData.quantity),
              overviewInvoiceData.currency_code,
              orgCurrencyList
            ),
            rate:
              overviewInvoiceData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.rate),
                overviewInvoiceData.currency_code,
                orgCurrencyList
              ) +
              " / Unit",
            discount: {
              discount:
                overviewInvoiceData.currency_code +
                " " +
                NumberFormat(
                  Number(itemData.discount.discount_value),
                  overviewInvoiceData.currency_code,
                  orgCurrencyList
                ),
              discountAccount: itemData.discount_account_name,
            },
            tax:
              itemData.gst_tax?.tax_id !== null
                ? itemData.gst_tax
                : itemData.taxes,
            taxableAmount:
              overviewInvoiceData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.taxable_amount),
                overviewInvoiceData.currency_code,
                orgCurrencyList
              ),
            item: itemData,
          },
        })
      )
    : [];

  return (
    <div className="recurring-invoice-view ri-view-container">
      <div
        className="card card-dashboard customer-detail-card w-100"
        id="customer-detail-card"
      >
        <RecurringInvoiceViewHeader
          overviewInvoiceData={overviewInvoiceData}
          childInvoiceListData={childInvoiceData}
          recurringInvoiceDetail={recurringInvoiceDetail}
        />
        <div className="overview-wrapper tab-content-wrapper">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overview" ? "nav-link active" : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overview")}
              >
                Overview
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "childInvoice" ? "nav-link active" : "nav-link"
                }
                id="pills-statement-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-statement"
                type="button"
                role="tab"
                aria-controls="pills-statement"
                aria-selected="false"
                onClick={() => handleTabClick("childInvoice")}
              >
                Child Invoices
              </button>
            </li>
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overview"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="overview-wrapper tab-content-wrapper">
                <section className="section-blocks other-dets-section">
                  <h2>
                    <img
                      src={DocIconSquare}
                      width="24"
                      height="24"
                      alt="Other details"
                    />
                    Details
                  </h2>
                  <div className="other-wrapper section-content-wrapper">
                    <div className="row g-0">
                      <div className="col">
                        <div className="field-row">
                          <div className="field-title">
                            <span>Start date</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {dateFormatHandler(overviewInvoiceData.start_on)}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>End date</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {overviewInvoiceData.never_expire
                                ? "Never Expires"
                                : dateFormatHandler(
                                    overviewInvoiceData.ends_on
                                  )}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Recurring Period</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {overviewInvoiceData.repeat_every === "custom"
                                ? Number(
                                    overviewInvoiceData.custom_repeat_count
                                  ) === 1 &&
                                  overviewInvoiceData.custom_repeat_frequency ===
                                    "days"
                                  ? "Daily"
                                  : `Every ${
                                      overviewInvoiceData.custom_repeat_count
                                    } ${capitalizeWords(
                                      overviewInvoiceData.custom_repeat_frequency,
                                      overviewInvoiceData.custom_repeat_count.toString()
                                    )}`
                                : overviewInvoiceData.repeat_every
                                ? capitalizeWords(
                                    overviewInvoiceData.repeat_every,
                                    overviewInvoiceData.custom_repeat_count.toString()
                                  ) + "ly"
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Invoice Amount</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {`${
                                overviewInvoiceData.currency_code
                              } ${NumberFormat(
                                Number(overviewInvoiceData.total),
                                overviewInvoiceData.currency_code,
                                orgCurrencyList
                              )}`}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Payment Terms</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {overviewInvoiceData.payment_term_id
                                ? overviewInvoiceData.payment_term
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Invoice Created</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {dateFormatHandler(
                                overviewInvoiceData.created_at
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section-blocks addressSection">
                  <h2>
                    <img
                      src={Locator}
                      width="24"
                      height="24"
                      alt="Locator Icon"
                    />
                    Address
                  </h2>
                  <div className="address-wrapper section-content-wrapper flex-nowrap">
                    <div className="address-container billing-address-container">
                      <h3>Billing Address</h3>
                      <div className="address-box-container d-flex">
                        <AddressOverviewCard
                          openAddressModal={() => {}}
                          id="billing"
                          key={billingAddress.id}
                          address={{ ...billingAddress, is_primary: true }}
                          setAddressIdHandler={() => {}}
                          isSameOrg={false}
                          shouldScrollTo={false}
                          overviewInvoiceData={overviewInvoiceData}
                        />
                      </div>
                    </div>
                    <div
                      className={`address-container shipping-address-container`}
                    >
                      <h3>Shipping Address</h3>
                      <div className="address-box-container d-flex">
                        <AddressOverviewCard
                          openAddressModal={() => {}}
                          id="shipping"
                          key={shippingAddress.id}
                          address={{ ...shippingAddress, is_primary: true }}
                          setAddressIdHandler={() => {}}
                          isSameOrg={false}
                          shouldScrollTo={false}
                          overviewInvoiceData={overviewInvoiceData}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section-blocks-item-table" id="invoice">
                  <RowWiseFieldView
                    mapObject={overviewInvoiceData.custom_fields}
                    module="Invoices"
                  />
                  <div className="table-section">
                    <div className="table-wrap">
                      <DetailsTableContainer
                        columns={columns}
                        data={data}
                        haveColumnClassName={true}
                        className="table inv-preview-table"
                        hiddenColumns={["TDS"]}
                      />
                    </div>
                    <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
                      <div className="thankyou-note w-50 d-flex align-items-end">
                        {overviewInvoiceData?.customer_notes && (
                          <div className="note-container">
                            <span className="label">Customer Notes</span>
                            <p>{overviewInvoiceData.customer_notes}</p>
                          </div>
                        )}
                      </div>
                      <DetailTotalSection
                        currencyCode={overviewInvoiceData.currency_code}
                        totalTax={overviewInvoiceData.total_details.tax_amount}
                        paymentLabel="Payment received"
                        payment={overviewInvoiceData.total_details.payment_made}
                        taxTypes={taxTypes}
                        subTotal={
                          overviewInvoiceData.total_details.total_taxable_amount
                        }
                        total={overviewInvoiceData.total_details.total}
                        totalInWords={
                          overviewInvoiceData.total_details.amount_in_words
                        }
                        isAmountInWordsEnabled={
                          !!overviewInvoiceData.is_words_enabled
                        }
                        balanceLabel={"Balance Due"}
                        balanceDueRequired={true}
                        balanceAmount={
                          overviewInvoiceData.total_details.balance_due
                            ? overviewInvoiceData.total_details.balance_due
                            : zeroDisplayFormat(
                                decimalPlaceOfCurrency(
                                  overviewInvoiceData.currency_code
                                    ? overviewInvoiceData.currency_code
                                    : currentUserInfo.currency_code,
                                  orgCurrencyList
                                )
                              )
                        }
                        tdsItems={{
                          tdsAmount: overviewInvoiceData.total_details
                            .transaction_tds_amount
                            ? overviewInvoiceData.total_details
                                .transaction_tds_amount
                            : 0,
                          tdsName: overviewInvoiceData.tds_name,
                          tdsPercentage: overviewInvoiceData.tds_percentage,
                          defaultTdsPercentage:
                            overviewInvoiceData.default_tds_percentage,
                        }}
                        tcsItems={{
                          tcsAmount: overviewInvoiceData.tcs_total,
                          tcsName: overviewInvoiceData.tcs_name,
                          tcsPercentage: overviewInvoiceData.tcs_percentage,
                        }}
                        roundOffValue={
                          overviewInvoiceData.total_details.roundoff_amount
                        }
                        roundoffEnabled={
                          overviewInvoiceData.total_details.roundoff_amount != 0
                            ? true
                            : false
                        }
                      >
                        <></>
                      </DetailTotalSection>
                    </div>
                  </div>
                  {overviewInvoiceData.recurring_invoice_files?.length ? (
                    <FileList
                      files={overviewInvoiceData.recurring_invoice_files}
                    />
                  ) : null}
                </section>
              </div>
            </div>
          </div>
          <div className="tab-content pb-0" id="pills-tabContent-2">
            <div
              className={
                activeTab === "childInvoice"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-statement"
              role="tabpanel"
              aria-labelledby="pills-statement-tab"
              tabIndex={0}
            >
              <TableInfo
                nextInvoiceAmount={`${
                  overviewInvoiceData.currency_code
                } ${NumberFormat(
                  Number(overviewInvoiceData.total),
                  overviewInvoiceData.currency_code,
                  orgCurrencyList
                )}`}
                unpaidAmount={`${
                  overviewInvoiceData.organization_currency_code
                } ${NumberFormat(
                  Number(overviewInvoiceData.total_unpaid_amount),
                  overviewInvoiceData.organization_currency_code,
                  orgCurrencyList
                )}`}
                nextIncoiceDate={overviewInvoiceData.next_invoice_date}
                recurringInvoiceStatus={overviewInvoiceData.invoice_status}
              />
              <InvoiceTable
                childInvoiceData={childInvoiceData}
                dateSortOrder={dateSortOrder}
                setDateSortOrder={setDateSortOrder}
                totalList={totalList}
                handlePageClick={handlePageClick}
                itemsPerPage={itemsPerPage}
                page={page}
                pageCount={pageCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringInvoiceView;
