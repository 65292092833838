import { ObjectType } from "../../../../types";
import { useModuleCustomFieldList } from "../../../hooks/useModuleCustomFieldList";
import "./rowWiseFieldsView.css";

type RowWiseProps = {
  mapObject: ObjectType;
  module: string;
};

export const RowWiseFieldView = (props: RowWiseProps) => {
  const moduleCustomFields = useModuleCustomFieldList(props.module);
  const pdfCustomFields = moduleCustomFields.filter(
    (field) => field.show_in_pdf
  );

  return (
    <div className="row-wise-field-wrapper d-flex row">
      {pdfCustomFields.map((field) => {
        const fieldValue = props.mapObject[field.field_name];

        return fieldValue ? (
          <div className="row-wise-field-item" key={field.id}>
            <span className="row-wise-field-item-header">
              {field.field_name}
            </span>
            <div className="row-wise-field-item-details pt-3">{fieldValue}</div>
          </div>
        ) : null;
      })}
    </div>
  );
};
