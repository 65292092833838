import React from "react";
import TableInfo from "../../../../../common/components/table/TableInfo";
import FilterEmptyList from "../../../../../common/components/FilterEmptyList";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { TableData, Transactionlimit } from "../../../../../../types";
import { InvoiceImage } from "../../../../../../assets/images";
import EmptyList from "../../../../../common/components/EmptyList";

type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  invoicesRolePermission: any;
  columns: any[];
  data: TableData[];
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
  setPageInfo: (countPerPage: number, page: number) => void;
};

function AddonTable({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  invoicesRolePermission,
  columns,
  data,
  isFilterApplied,
  initializeFilter,
  transactionLimit,
  setPageInfo,
}: Props) {
  return (
    <div className="card-body">
      <div
        id="addon-table-wrapper"
        className={
          "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
          `${totalList > 0 ? "" : "no-data"}`
        }
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={setPageInfo}
        />
        <div className="row">
          <div className="col-md-12">
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/addons"}
              sortHandler={() => {}}
              editPermission={invoicesRolePermission.includes("Update")}
              isDetailViewExist={true}
            >
              {!isFilterApplied ? (
                <EmptyList
                  heading={
                    invoicesRolePermission.includes("Create")
                      ? "Create new addon!"
                      : "No Addons found!"
                  }
                  subheading={
                    invoicesRolePermission.includes("Create")
                      ? "Get paid faster by addons."
                      : "Oops, There is no Addon data available right now"
                  }
                  list="addons"
                  alt="create addon preview"
                  buttonText="Create Addon"
                  navigateLink="/addons/create"
                  isCrateAccess={invoicesRolePermission.includes("Create")}
                  icon={InvoiceImage}
                  transactionLimit={transactionLimit.limit_exceed}
                />
              ) : (
                <FilterEmptyList
                  list="addons"
                  icon={InvoiceImage}
                  alt="list addon empty"
                  handleReset={initializeFilter}
                />
              )}
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(AddonTable);
