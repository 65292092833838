import React from "react";
import { InvoiceImage } from "../../../../../assets/images";
import { TableData, Transactionlimit } from "../../../../../types";
import EmptyList from "../../../../common/components/EmptyList";
import FilterEmptyList from "../../../../common/components/FilterEmptyList";
import TableContainer from "../../../../common/components/table/TableContainer";
import TableInfo from "../../../../common/components/table/TableInfo";

type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  invoicesRolePermission: any;
  columns: any[];
  data: TableData[];
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
  setPageInfo: (countPerPage: number, page: number) => void;
  sortHandler: (column: string) => void;
  hiddenColumns: string[];
};
function ProformaInvoiceTable({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  invoicesRolePermission,
  columns,
  data,
  isFilterApplied,
  initializeFilter,
  transactionLimit,
  setPageInfo,
  sortHandler,
  hiddenColumns,
}: Props) {
  return (
    <div className="card-body">
      <div
        id="invoice-table-wrapper"
        className={
          "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
          `${totalList > 0 ? "" : "no-data"}`
        }
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={setPageInfo}
        />
        <div className="row">
          <div className="col-md-12">
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/proforma-inv"}
              sortHandler={sortHandler}
              editPermission={invoicesRolePermission.includes("Update")}
              isDetailViewExist={true}
              hiddenColumns={hiddenColumns}
            >
              {!isFilterApplied ? (
                <EmptyList
                  heading={
                    invoicesRolePermission.includes("Create")
                      ? "Create new proforma invoice!"
                      : "No Proforma Invoices found!"
                  }
                  subheading={
                    invoicesRolePermission.includes("Create")
                      ? "Get paid faster by creating proforma invoices."
                      : "Oops, There is no Proforma Invoice data available right now"
                  }
                  list="Invoice"
                  alt="create proforma invoice preview"
                  buttonText="Create Proforma Invoice"
                  navigateLink="/proforma-inv/create"
                  isCrateAccess={invoicesRolePermission.includes("Create")}
                  icon={InvoiceImage}
                  transactionLimit={transactionLimit.limit_exceed}
                />
              ) : (
                <FilterEmptyList
                  list="proforma invoices"
                  icon={InvoiceImage}
                  alt="list invoice empty"
                  handleReset={initializeFilter}
                />
              )}
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProformaInvoiceTable);
