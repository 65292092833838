import React from "react";
import { setLoaderState } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { getPlanSpecificDropdown} from "../calenderBillingSlice";
import { PlanDropdownType } from "../calendarBillingTypes";
import { SelectValues } from "../../../../types";

export const useFetchPlanSpecificDropdown = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const getPlanDropdown = async (
    setPlanSpecificDropdown: (dropdowns: SelectValues[]) => void,
    planSpecificDropdown: SelectValues[],
    selectedfrequency: string,
    planCount: number,
    setPlanCount: (count: number) => void,
    setIsNextEnabled: (nextEnabled: boolean) => void,
    id?: number,
    itemsPerPage?: number,
  ) => {
    if (planCount && planCount <= 1)  dispatch(setLoaderState(true));
    const responseAction = await dispatch(
        getPlanSpecificDropdown({
            page: planCount,
            itemsPerPage: itemsPerPage ? itemsPerPage : 10,
            orgId: currentUserInfo.organization_id,
            frequency: selectedfrequency,
            calendarInvoicingID: id ? id : 0,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
     const planDropdownData = response.pricings.map((data: PlanDropdownType) => ({label: data.plan_name, value: data.id}))
     setPlanSpecificDropdown(planSpecificDropdown.concat(planDropdownData));
     setIsNextEnabled(!(response.pagination.next === null))
     if (response.pagination.next !== null)  {
      const regex = new RegExp(`page_num=(\\d+)`);
      const modifiedString = response.pagination.next.match(regex)
      const count = modifiedString[1] ? modifiedString[1] : planCount
      setPlanCount(count)
    }
     dispatch(setLoaderState(false));
    } else {
    }
    dispatch(setLoaderState(false));
  };
  return { getPlanDropdown };
};