import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { CloseImg, ErrorImg, ErrorInfoIcon } from "../../../../assets/images";
import { AccountsGLCodeRanges, ObjectType } from "../../../../types";
import "../ChartOfAccounts.css";
import {
  accountGlRanges,
  glRangesValidate,
  updateAccountGlRanges,
} from "../coaSlice";
import { colors } from "../../../constants/colors";
import { setLoaderState } from "../../../common/commonSlice";

type Props = {
  organizationId: number;
  refresh: (type: string) => void;
  isEditGlCode: boolean;
};

const EditGLCode: React.FC<Props> = ({
  organizationId,
  refresh,
  isEditGlCode,
}) => {
  const dispatch = useAppDispatch();
  const [glFormValues, setGlFormValues] = useState<AccountsGLCodeRanges[]>([]);
  const [glFormErrors, setGlFormErrors] = useState<ObjectType[]>([]);
  const [glFormErrorsArray, setGlFormErrorsArray] = useState<string[]>([]);
  const [glError, setGlError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (isEditGlCode === true) fetchAccountGLRanges();
  }, [isEditGlCode]);

  const fetchAccountGLRanges = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(accountGlRanges(organizationId));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setGlFormValues(response);
      }
    }
    setGlFormErrors([{}]);
    setGlFormErrorsArray([]);
    dispatch(setLoaderState(false));
  };

  const cancelAction = (e: any) => {
    // fetchAccountGLRanges();
    refresh("");
    setGlFormErrors([{}]);
    setGlFormErrorsArray([]);
    e.preventDefault();
    $(".modal .close-btn").trigger("click");
  };

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const glCodes = [...glFormValues];
    if (name === "gl_start_code") {
      const glCodeRegex = /^[0-9\b]+$/;
      if (value === "" || glCodeRegex.test(value)) {
        glCodes[index] = { ...glCodes[index], [name]: value };
      }
    }
    if (name === "gl_end_code") {
      const glCodeRegex = /^[0-9\b]+$/;
      if (value === "" || glCodeRegex.test(value)) {
        glCodes[index] = { ...glCodes[index], [name]: value };
      }
    }
    setGlFormValues(glCodes);
  };

  const handleUpdateGLCodeRanges = (e: any) => {
    setIsSubmit(true);
    checkCodeOverLapping(0);
    e.preventDefault();
    // if (glError === "") {
    //   updateGLCodeSubmit();
    // }
  };
  const updateGLCodeSubmit = async () => {
    const gLCodeInputs = JSON.parse(JSON.stringify(glFormValues));
    gLCodeInputs.forEach((glcode: AccountsGLCodeRanges) => {
      delete glcode.account_name;
      delete glcode.description;
    });
    const responseAction = await dispatch(
      updateAccountGlRanges({ accounts: gLCodeInputs, orgId: organizationId })
    );
    if (responseAction.payload) {
      setIsSubmit(false);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        $(".close-btn").click();
        refresh("submit");
        toast.success("Ledger code ranges updated successfully!", {
          toastId: "ledger-code-update-success",
          closeButton: false,
          position: "top-center",
        });
      } else {
        $("#update-glrange-button").removeClass(
          "update-account-form-section-disable"
        );
        // toast.error("Failed to update ledger code ranges", {
        //   icon: ({ theme, type }) => (
        //     <img src={ErrorImg} width="16" height="14" />
        //   ),
        //   toastId: "update-ledger-code-failed-error",
        //   closeButton: false,
        //   className: "toast-error",
        //   position: "top-center",
        // });
      }
      // fetchAccountGLRanges();
    }
  };

  const checkCodeOverLapping = (currentIndex: number) => {
    const errors: ObjectType = [];
    let result = false;

    for (let i = 0; i < glFormValues.length; i++) {
      const formRow = glFormValues[i];
      let validError = false;

      let rangeLengthError = false;
      // Checking 6 digit and range
      if (formRow.gl_start_code.length !== 6) {
        errors[i] = {
          ...errors[i],
          gl_start_code: "The ledger code should be 6 digits.",
        };
        rangeLengthError = true;
      }
      if (formRow.gl_end_code.length !== 6) {
        errors[i] = {
          ...errors[i],
          gl_end_code: "The ledger code should be 6 digits.",
        };
        rangeLengthError = true;
      }
      if (rangeLengthError) {
        continue;
      }
      if (Number(formRow.gl_start_code) > Number(formRow.gl_end_code)) {
        errors[i] = {
          ...errors[i],
          gl_start_code: "Enter a valid range.",
          gl_end_code: "Enter a valid range.",
        };
        validError = true;
        // setGlFormErrors(errors);
        result = true;
        continue;
      } else if (
        errors[i]?.gl_start_code ===
        "You have ledger/s outside of this range in the hierarchy."
      ) {
        continue;
      }

      let hasformRowError = false;
      for (let j = 0; j < glFormValues.length; j++) {
        const formRowOther = glFormValues[j];
        // if (Number(formRowOther.gl_start_code) > Number(formRowOther.gl_end_code)) {
        //     continue;
        // }
        if (formRow.id !== formRowOther.id) {
          if (
            formRow.gl_start_code.length === 6 &&
            formRowOther.gl_start_code.length === 6 &&
            formRow.gl_end_code.length === 6 &&
            formRowOther.gl_end_code.length === 6
          ) {
            const current_start_code = Number(formRow.gl_start_code);
            const other_start_code = Number(formRowOther.gl_start_code);
            const current_end_code = Number(formRow.gl_end_code);
            const other_end_code = Number(formRowOther.gl_end_code);
            let flag = false;
            if (i < j) {
              if (
                current_start_code >= other_start_code &&
                current_start_code <= other_end_code
              ) {
                flag = true;
                hasformRowError = true;
                result = true;
                errors[i] = {
                  ...errors[i],
                  // gl_start_code: "Ledger codes overlapping.",
                  gl_end_code: "Ledger codes overlapping.",
                };
                errors[j] = {
                  ...errors[j],
                  gl_start_code: "Ledger codes overlapping.",
                  //   gl_end_code: "Ledger codes overlapping.",
                };
              }
              if (
                current_end_code >= other_start_code &&
                current_end_code <= other_end_code
              ) {
                flag = true;
                hasformRowError = true;
                result = true;
                errors[i] = {
                  ...errors[i],
                  // gl_start_code: "Ledger codes overlapping.",
                  gl_end_code: "Ledger codes overlapping.",
                };
                errors[j] = {
                  ...errors[j],
                  gl_start_code: "Ledger codes overlapping.",
                  //   gl_end_code: "Ledger codes overlapping.",
                };
              }
              if (
                current_start_code < other_start_code &&
                current_end_code > other_end_code
              ) {
                flag = true;
                hasformRowError = true;
                result = true;
                errors[i] = {
                  ...errors[i],
                  // gl_start_code: "Ledger codes overlapping.",
                  gl_end_code: "Ledger codes overlapping.",
                };
                errors[j] = {
                  ...errors[j],
                  gl_start_code: "Ledger codes overlapping.",
                };
              }
            }
          }
        }
      }
    }
    checkGlCodeValidation(
      glFormValues[currentIndex].gl_start_code,
      glFormValues[currentIndex].gl_end_code,
      glFormValues[currentIndex].id,
      currentIndex,
      errors
    );
  };

  const checkGlCodeValidation = async (
    startRange: string,
    endRage: string,
    accountId: number,
    index: number,
    errors: any
  ) => {
    const input = {
      id: accountId,
      gl_start_code: startRange,
      gl_end_code: endRage,
    };
    const gLCodeInputs = JSON.parse(JSON.stringify(glFormValues));
    gLCodeInputs.forEach((glcode: AccountsGLCodeRanges) => {
      delete glcode.account_name;
      delete glcode.description;
    });
    const responseAction = await dispatch(
      glRangesValidate({ glCodeRange: gLCodeInputs, orgId: organizationId })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (typeof response === "object" && "error" in response) {
        if (
          response.error?.detail?.[0]?.msg ===
          "You have ledger/s outside of this range in the hierarchy"
        ) {
          errors[index] = {
            ...errors[index],
            gl_start_code:
              "You have ledger/s outside of this range in the hierarchy.",
          };
          errors[index] = {
            ...errors[index],
            gl_end_code:
              "You have ledger/s outside of this range in the hierarchy.",
          };
        } else if (
          errors[index]?.gl_start_code ===
          "You have ledger/s outside of this range in the hierarchy."
        ) {
          delete errors[index]?.gl_start_code;
          delete errors[index]?.gl_end_code;
        }
      } else {
        if (
          errors[index]?.gl_start_code ===
          "You have ledger/s outside of this range in the hierarchy."
        ) {
          delete errors[index]?.gl_start_code;
          delete errors[index]?.gl_end_code;
        }
      }
    }
    setGlFormErrors(errors);
  };

  useEffect(() => {
    let errorText = "";
    let errorMessages: string[] = [];
    for (let element of glFormErrors) {
      if (element?.gl_start_code && element?.gl_start_code !== "") {
        if (element.gl_start_code == "Ledger codes overlapping.") {
          errorText = element.gl_start_code;
          continue;
        } else {
          errorText = element.gl_start_code;
          break;
        }
      } else if (element?.gl_end_code && element?.gl_end_code !== "") {
        if (element.gl_end_code == "Ledger codes overlapping.") {
          errorText = element.gl_end_code;
          continue;
        } else {
          errorText = element.gl_end_code;
          break;
        }
      }
    }
    for (let element1 of glFormErrors) {
      // Array for display error message
      if (element1?.gl_start_code !== "" || element1?.gl_end_code !== "") {
        if (element1?.gl_start_code === element1?.gl_end_code) {
          if (errorMessages.includes(element1?.gl_start_code)) {
            continue;
          } else {
            errorMessages.push(element1?.gl_start_code);
          }
        } else {
          if (element1?.gl_start_code !== "") {
            if (errorMessages.includes(element1?.gl_start_code)) {
              continue;
            } else {
              errorMessages.push(element1?.gl_start_code);
            }
          }
          if (element1?.gl_end_code !== "") {
            if (errorMessages.includes(element1?.gl_end_code)) {
              continue;
            } else {
              errorMessages.push(element1?.gl_end_code);
            }
          }
        }
      }
    }
    setGlError(errorText);
    setGlFormErrorsArray(errorMessages);
    if (errorText === "" && isSubmit) {
      updateGLCodeSubmit();
    } else {
      setIsSubmit(false);
    }
  }, [glFormErrors]);

  /**
   * Edit gl rage modal close handler
   */
  $(document).ready(function () {
    $(".modal .close-btn").on("click", function () {
      setGlFormErrors([{}]);
      setGlFormErrorsArray([]);
      setIsSubmit(false);
      $("#update-glrange-button").removeClass(
        "update-account-form-section-disable"
      );
    });
  });

  return (
    <div>
      <div
        className="modal partial-modal editLedgerRangeModal fade w-480 right"
        id="editLedgerRangeModal"
        role="dialog"
        tabIndex={-1}
        aria-labelledby="modalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen w-100">
          <div className="modal-content">
            <div className="modal-header close-button-container">
              <button
                type="button"
                className="close-btn pull-right"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  // fetchAccountGLRanges();
                  refresh("");
                  setGlFormErrors([{}]);
                  setGlFormErrorsArray([]);
                }}
              >
                {/* <img src={CloseImg} width="24px" alt="close modal" /> */}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      fill={colors.slate}
                      fillRule="nonzero"
                      d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="modal-body user-form">
              <div className="user-form-head">
                <h4 id="modalTitle" className="mbottom-20">
                  Edit Ledger Code Range
                </h4>
              </div>
              {glError
                ? glFormErrorsArray.map((errorMsg) => {
                    if (errorMsg) {
                      return (
                        <div className="info-box error-info">
                          <img src={ErrorInfoIcon} alt="error info icon" />
                          <span className="info-text">{errorMsg}</span>
                        </div>
                      );
                    }
                  })
                : null}
              <form
                onSubmit={handleUpdateGLCodeRanges}
                className="edit-LCR-form-wrap edit-ledger-code-range-form-wrap"
                id="edit-ledger-code-range-form-wrap"
              >
                <div className="row title-row g-0">
                  <div className="col">
                    <span className="title term-title left-align">Account</span>
                  </div>
                  <div className="col">
                    <span className="title left-align">Range</span>
                  </div>
                </div>
                <div className="tbody-container">
                  {glFormValues.map((glCode, index) => {
                    return (
                      <div className="row g-0" key={"gl_" + index}>
                        <div className="col">
                          <label>{glCode.account_name}</label>
                        </div>
                        <div className="col input-col">
                          <input
                            type="text"
                            id="gl_start_code"
                            name="gl_start_code"
                            className={
                              glFormErrors[index]?.gl_start_code
                                ? "form-control range-1 error"
                                : "form-control range-1"
                            }
                            maxLength={6}
                            value={glCode.gl_start_code}
                            onChange={(e) => handleChange(e, index)}
                            onBlur={(e) => {
                              checkCodeOverLapping(index);
                            }}
                          />
                          <div className="hyphonWrapper"></div>
                          <input
                            type="text"
                            id="gl_end_code"
                            name="gl_end_code"
                            className={
                              glFormErrors[index]?.gl_end_code
                                ? "form-control range-1 error"
                                : "form-control range-1"
                            }
                            value={glCode.gl_end_code}
                            maxLength={6}
                            onChange={(e) => handleChange(e, index)}
                            onBlur={(e) => {
                              checkCodeOverLapping(index);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="button-wrapper" id="update-glrange-button">
                    <button
                      className="btn btn-block fa-lg save-btn mb-4"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-block fa-lg cancel-btn mb-4"
                      type="button"
                      onClick={cancelAction}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGLCode;
