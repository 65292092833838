import React, { useEffect, useState } from "react";
import { useMediaQuery, useMediaQueries } from "@react-hook/media-query";
import BarGraph from "./BarGraph";
import DoughnutGraph from "./DoughnutGraph";
import "../styles/Dashboard.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  currentUserOrgSelector,
  currentUserSelector,
} from "../../../common/commonSlice";
import {
  getTotalPayableAmount,
  getTotalReceivableAmount,
} from "../../reports/ReportSlice";
import { colors } from "../../../constants/colors";
import TopReceivables from "./TopReceivables";
import TopPayables from "./TopPayables";
import { currentModule, setListFilters } from "../../../../appSlice";
import { useNavigate } from "react-router-dom";
import { getStatusIds } from "../../../../helpers/statusFilterHelper";
import OrgTag from "../../../common/components/OrgTag";
import { classNames } from "react-select/dist/declarations/src/utils";
import TabComponent from "./TabComponent";

function Dashboard() {
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const userOrganization = useAppSelector(currentUserOrgSelector);
  const largeDevice = useMediaQuery("(min-width: 1076px)");
  const touchDevices = useMediaQuery("(max-width: 1075px)");
  const [orgName, setOrgName] = useState(sessionStorage.getItem("OrgName"));

  useEffect(() => {
    dispatch(setListFilters({}));
    dispatch(currentModule("Dashboard"));
  }, []);
  const totalInitialValues = {
    total_unpaid: 0,
    total_unpaid_current: 0,
    total_unpaid_current_percentage: 0,
    total_unpaid_overdue: 0,
    total_unpaid_overdue_percentage: 0,
    no_of_total_unpaid: 0,
    no_of_total_unpaid_current: 0,
    no_of_total_unpaid_overdue: 0,
  };
  const [totalPayable, setTotalPayable] = useState(totalInitialValues);
  const [totalReceivable, setTotalReceivable] = useState(totalInitialValues);

  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    ) {
      totalPayableAmount();
      totalReceivableAmount();
    }
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  useEffect(() => {
    userOrganization
      ? userOrganization.map((org) => {
          if (org.id === currentUserInfo.organization_id) {
            setOrgName(org.name);
          }
        })
      : setOrgName(sessionStorage.getItem("OrgName"));
  }, [currentUserInfo.organization_id, userOrganization]);

  useEffect(() => {
    setOrgName(sessionStorage.getItem("OrgName"));
  }, [currentUserInfo]);

  const totalPayableAmount = async () => {
    const responseAction = await dispatch(
      getTotalPayableAmount({
        orgId: currentUserInfo.organization_id,
        currencyId: currentUserInfo.organization_currency,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTotalPayable(response);
    }
  };

  const totalReceivableAmount = async () => {
    const responseAction = await dispatch(
      getTotalReceivableAmount({
        orgId: currentUserInfo.organization_id,
        currencyId: currentUserInfo.organization_currency,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTotalReceivable(response);
    }
  };

  /**
   * When click on unpaid invoices, overview links, redirect the page to invoice with required filters
   */
  const showUnpaidInvoices = () => {
    dispatch(currentModule("Invoices"));
    const statusIds = getStatusIds(["Open", "Overdue", "Partially Paid"]);
    dispatch(
      setListFilters({
        Organizations: [],
        Customers: [],
        Status: statusIds,
      })
    );
    navigate("/invoice", { state: { isDashboard: true } });
  };
  /**
   * When click on unpaid bills, overview links, redirect the page to bills with required filters
   */
  const showUnpaidBills = () => {
    dispatch(currentModule("Bills"));
    const statusIds = getStatusIds(["Open", "Overdue", "Partially Paid"]);
    dispatch(
      setListFilters({
        Organizations: [],
        Vendors: [],
        Status: statusIds,
      })
    );
    navigate("/bill", { state: { isDashboard: true } });
  };

  return (
    <div className="card card-user-management card-dashboard main-card overflowX-hidden h-100">
      <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
        <h1>Dashboard</h1>
        <div className="dashboard-org-tag">
          <OrgTag organization={orgName ? "Data for " + orgName : ""} />
        </div>
      </div>
      <div className="card-body">
        <div className="row g-0">
          <div className="col">
            <BarGraph currencySymbol={currentUserInfo.currency_code} />
          </div>
        </div>
        {largeDevice && (
          <>
            <div className="row two-column-row mt-32 p-rl-12">
              <div className="col">
                <DoughnutGraph
                  title="Total Receivables"
                  titleInfo={
                    <>
                      <span>
                        Open - Total balance of invoices that are yet to be due{" "}
                      </span>{" "}
                      <br />
                      <span>
                        Overdue - Total balance of invoices that are overdue
                      </span>
                    </>
                  }
                  subTitle="Total Unpaid Invoices"
                  type="invoice"
                  data={{
                    total: totalReceivable.total_unpaid,
                    current: totalReceivable.total_unpaid_current,
                    overDue: totalReceivable.total_unpaid_overdue,
                    currentPercentage:
                      totalReceivable.total_unpaid_current_percentage,
                    overduePercentage:
                      totalReceivable.total_unpaid_overdue_percentage,
                    backgroundColors: [colors.yellowGreen, colors.tomato],
                    noOfTotalUnpaid: totalReceivable.no_of_total_unpaid,
                    noOfCurrent: totalReceivable.no_of_total_unpaid_current,
                    noOfOverdue: totalReceivable.no_of_total_unpaid_overdue,
                  }}
                  onClickUnpaidTransactions={showUnpaidInvoices}
                  currencySymbol={currentUserInfo.currency_code}
                />
              </div>
              <div className="col">
                <DoughnutGraph
                  title="Total Payables"
                  titleInfo={
                    <>
                      <span>
                        Open - Total balance of bills that are yet to be due{" "}
                      </span>{" "}
                      <br />
                      <span>
                        Overdue - Total balance of bills that are overdue
                      </span>
                    </>
                  }
                  subTitle="Total Unpaid Bills"
                  type="bill"
                  data={{
                    total: totalPayable.total_unpaid,
                    current: totalPayable.total_unpaid_current,
                    overDue: totalPayable.total_unpaid_overdue,
                    currentPercentage:
                      totalPayable.total_unpaid_current_percentage,
                    overduePercentage:
                      totalPayable.total_unpaid_overdue_percentage,
                    backgroundColors: [colors.lavenderBlue, colors.tomato],
                    noOfTotalUnpaid: totalPayable.no_of_total_unpaid,
                    noOfCurrent: totalPayable.no_of_total_unpaid_current,
                    noOfOverdue: totalPayable.no_of_total_unpaid_overdue,
                  }}
                  onClickUnpaidTransactions={showUnpaidBills}
                  currencySymbol={currentUserInfo.currency_code}
                />
              </div>
            </div>
            <div className="row two-column-row mt-32 p-rl-12">
              <TopReceivables />
              <TopPayables />
            </div>
          </>
        )}
        {touchDevices && (
          <div className="row mt-32">
            <div className="col">
              <TabComponent
                totalReceivable={totalReceivable}
                totalPayable={totalPayable}
                showUnpaidInvoices={showUnpaidInvoices}
                showUnpaidBills={showUnpaidBills}
                currentUserInfo={currentUserInfo}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
