import React from "react";
import "./Card.css"

type CardProps = {
  children: React.ReactNode;
  wrapperClass?: string;
};
export const Card = (props: CardProps) => {
  return (
    <div
      className={`card  main-card overflowX-hidden h-100 ${
        props.wrapperClass ? props.wrapperClass : ""
      }`}
    >
      {props.children}
    </div>
  );
};
