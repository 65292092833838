import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import {
  createReverseConversion,
  getConversionFromAmount,
} from "./exchangeRateApi";
import { ExchangeRateValues } from "../../../../types";

export const calculateConversionFromAmount = createAsyncThunk(
  "conversion-from-amount",
  async (data: {
    amount: number;
    amount_bank_currency: number;
    to_currency_id: number;
    orgId: number;
    signal: AbortSignal;
  }) => {
    return await getConversionFromAmount(data);
  }
);

interface ExchangeRateState {
  isCurrencyApiPending: boolean;
}
const initialState: ExchangeRateState = {
  isCurrencyApiPending: false,
};

export const createReverseExchangeRate = createAsyncThunk(
  "create-conversion-list",
  async (data: { values: ExchangeRateValues }) => {
    return await createReverseConversion(data);
  }
);
export const exchangeRateSlice = createSlice({
  name: "exchangeRate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(calculateConversionFromAmount.pending, (state, { payload }) => {
        state.isCurrencyApiPending = true;
      })
      .addCase(
        calculateConversionFromAmount.fulfilled,
        (state, { payload }) => {
          state.isCurrencyApiPending = false;
        }
      )
      .addCase(calculateConversionFromAmount.rejected, (state, { payload }) => {
        state.isCurrencyApiPending = false;
      })
      .addCase(createReverseExchangeRate.pending, (state, { payload }) => {
        state.isCurrencyApiPending = true;
      })
      .addCase(createReverseExchangeRate.fulfilled, (state, { payload }) => {
        state.isCurrencyApiPending = false;
      })
      .addCase(createReverseExchangeRate.rejected, (state, { payload }) => {
        state.isCurrencyApiPending = false;
      });
  },
});
export default exchangeRateSlice.reducer;

// State selectors
export const exchangeRateApiStatusSelector = (state: RootState) =>
  state.exchangeRate.isCurrencyApiPending;
