import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getFiscalQuarterYear } from "../../helpers/fiscalYearHelper";
import { getFormatedDate } from "../../helpers/helper";
import { ObjectType, ReportFilters, SoaFilters } from "../../types";
import { currentUserSelector } from "../common/commonSlice";
import {
  reportTableFilterSelector,
  setDateRange,
  setReportCustomizationFlag,
  setReportTableFilters,
} from "../home/reports/ReportSlice";
import { useLocation } from "react-router-dom";

export const useReportFilterInitialization = (reportType: string) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const [initializedFilter, setInitializedFilter] = useState<ReportFilters>();
  const tableFilterValues = useAppSelector(reportTableFilterSelector);
  const quarterStartDate = getFiscalQuarterYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "Current"
  ).startDate;
  const quarterEndDate = getFiscalQuarterYear(
    currentUserInfo.organization_fiscal_year || "April-March",
    "Current"
  ).endDate;
  const today = getFormatedDate(new Date());
  type LocationProps = {
    accTransactionFilter: ObjectType;
  };
  const location = useLocation();
  const locationState = location.state as LocationProps;
  const accTransactionFilter = locationState?.accTransactionFilter
    ? locationState.accTransactionFilter
    : {};
  const storedFilter = localStorage.getItem("drill-down-filter");
  const filterParsed: ObjectType = storedFilter ? JSON.parse(storedFilter) : {};
  let isDrillDownBack = Object.keys(filterParsed).length ? true : false;

  const hashFragment = location.hash;
  // Remove the '#' character from the hash fragment
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  // // Parse the filter data as needed
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  useEffect(() => {
    switch (reportType) {
      case "SOA":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "this_quarter",
            start_date: filters ? filters.start_date : quarterStartDate,
            end_date: filters ? filters.end_date : quarterEndDate,
            account_id: filters ? filters.account_id : 0,
            statement_type: filters ? filters.statement_type : "",
            contact_or_account_id: filters ? filters.contact_or_account_id : "",
            // customer_id: null,
            // vendor_id: null,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            is_sundry: filters ? filters.is_sundry : false,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(
          setDateRange({
            date_range: "today",
            start_date: today,
            end_date: today,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        setInitializedFilter({
          date_range: "today",
          start_date: today,
          end_date: today,
          account_id: 0,
          customer_id: null,
          vendor_id: null,
          currency_id: currentUserInfo.organization_currency,
          organization_id: currentUserInfo.organization_id,
        });
        break;
      case "TB":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: isDrillDownBack ? filterParsed.dateRange : "today",
            start_date: isDrillDownBack ? filterParsed.startDate : "",
            end_date: isDrillDownBack ? filterParsed.endDate : today,
            currency_id: isDrillDownBack
              ? filterParsed.currencyId
              : currentUserInfo.organization_currency,
            organization_id: isDrillDownBack
              ? filterParsed.organizationId
              : currentUserInfo.organization_id,
            tag_option_in: isDrillDownBack
              ? filterParsed.tag_option_in
              : []
          })
        );
        dispatch(setReportCustomizationFlag(true));
        dispatch(
          setDateRange({
            date_range: isDrillDownBack ? filterParsed.dateRange : "today",
            start_date: isDrillDownBack ? filterParsed.startDate : "",
            end_date: isDrillDownBack ? filterParsed.endDate : today,
          })
        );
        setInitializedFilter({
          date_range: isDrillDownBack ? filterParsed.dateRange : "today",
          start_date: isDrillDownBack ? filterParsed.startDate : "",
          end_date: isDrillDownBack ? filterParsed.endDate : today,
          currency_id: isDrillDownBack
            ? filterParsed.currencyId
            : currentUserInfo.organization_currency,
          organization_id: isDrillDownBack
            ? filterParsed.organizationId
            : currentUserInfo.organization_id,
        });
        break;
      case "PL":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: isDrillDownBack
              ? filterParsed.dateRange
              : "this_quarter",
            start_date: isDrillDownBack
              ? filterParsed.startDate
              : quarterStartDate,
            end_date: isDrillDownBack ? filterParsed.endDate : quarterEndDate,
            currency_id: isDrillDownBack
              ? filterParsed.currencyId
              : currentUserInfo.organization_currency,
            organization_id: isDrillDownBack
              ? filterParsed.organizationId
              : currentUserInfo.organization_id,
            tag_option_in: isDrillDownBack
              ? filterParsed.tag_option_in
              : [],
          })
        );
        dispatch(setReportCustomizationFlag(true));
        dispatch(
          setDateRange({
            date_range: isDrillDownBack
              ? filterParsed.dateRange
              : "this_quarter",
            start_date: isDrillDownBack
              ? filterParsed.startDate
              : quarterStartDate,
            end_date: isDrillDownBack ? filterParsed.endDate : quarterEndDate,
          })
        );

        setInitializedFilter({
          date_range: isDrillDownBack ? filterParsed.dateRange : "this_quarter",
          start_date: isDrillDownBack
            ? filterParsed.startDate
            : quarterStartDate,
          end_date: isDrillDownBack ? filterParsed.endDate : quarterEndDate,
          currency_id: isDrillDownBack
            ? filterParsed.currencyId
            : currentUserInfo.organization_currency,
          organization_id: isDrillDownBack
            ? filterParsed.organizationId
            : currentUserInfo.organization_id,
          tag_option_in: isDrillDownBack
            ? filterParsed.tag_option_in
            : [],
        });
        break;
      case "BS":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: isDrillDownBack ? filterParsed.dateRange : "today",
            start_date: isDrillDownBack ? filterParsed.startDate : "",
            end_date: isDrillDownBack ? filterParsed.endDate : today,
            currency_id: isDrillDownBack
              ? filterParsed.currencyId
              : currentUserInfo.organization_currency,
            organization_id: isDrillDownBack
              ? filterParsed.organizationId
              : currentUserInfo.organization_id,
            tag_option_in: isDrillDownBack
              ? filterParsed.tag_option_in
              : [],
          })
        );
        dispatch(
          setDateRange({
            date_range: isDrillDownBack ? filterParsed.dateRange : "today",
            start_date: isDrillDownBack ? filterParsed.startDate : "",
            end_date: isDrillDownBack ? filterParsed.endDate : today,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        setInitializedFilter({
          date_range: isDrillDownBack ? filterParsed.dateRange : "today",
          start_date: isDrillDownBack ? filterParsed.startDate : "",
          end_date: isDrillDownBack ? filterParsed.endDate : today,
          currency_id: isDrillDownBack
            ? filterParsed.currencyId
            : currentUserInfo.organization_currency,
          organization_id: isDrillDownBack
            ? filterParsed.organizationId
            : currentUserInfo.organization_id,
        });
        break;
      case "AT":
        let isDrillDown =
          accTransactionFilter && Object.keys(accTransactionFilter).length;
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: isDrillDown
              ? accTransactionFilter.dateRange
              : filters
              ? filters.date_range
              : "this_quarter",
            start_date: isDrillDown
              ? accTransactionFilter.startDate
              : filters
              ? filters.start_date
              : quarterStartDate,
            end_date: isDrillDown
              ? accTransactionFilter.endDate
              : filters
              ? filters.end_date
              : quarterEndDate,
            currency_id: isDrillDown
              ? accTransactionFilter.currencyId
              : filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: isDrillDown
              ? accTransactionFilter.organizationId
              : filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
            account_id: isDrillDown ? accTransactionFilter.accountId : null,
            tag_option_in: isDrillDownBack
              ? accTransactionFilter.tag_option_in
              : [],
          })
        );
        dispatch(
          setDateRange({
            date_range: isDrillDown
              ? accTransactionFilter.dateRange
              : filters
              ? filters.date_range
              : "this_quarter",
            start_date: isDrillDown
              ? accTransactionFilter.startDate
              : filters
              ? filters.start_date
              : quarterStartDate,
            end_date: isDrillDown
              ? accTransactionFilter.endDate
              : filters
              ? filters.end_date
              : quarterEndDate,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        setInitializedFilter({
          date_range: isDrillDown
            ? accTransactionFilter.dateRange
            : filters
            ? filters.date_range
            : "this_quarter",
          start_date: isDrillDown
            ? accTransactionFilter.startDate
            : filters
            ? filters.start_date
            : quarterStartDate,
          end_date: isDrillDown
            ? accTransactionFilter.endDate
            : filters
            ? filters.end_date
            : quarterEndDate,
          currency_id: isDrillDown
            ? accTransactionFilter.currencyId
            : filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: isDrillDown
            ? accTransactionFilter.organizationId
            : filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
          tag_option_in: isDrillDownBack
            ? accTransactionFilter.tag_option_in
            : [],
        });
        break;
      case "DB":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : today,
            end_date: filters ? filters.end_date : today,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(
          setDateRange({
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : today,
            end_date: filters ? filters.end_date : today,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        setInitializedFilter({
          date_range: filters ? filters.date_range : "today",
          start_date: filters ? filters.start_date : today,
          end_date: filters ? filters.end_date : today,
          currency_id: filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
        });
        break;
      case "AR":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : "",
            end_date: filters ? filters.end_date : today,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        dispatch(
          setDateRange({
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : "",
            end_date: filters ? filters.end_date : today,
          })
        );
        setInitializedFilter({
          date_range: filters ? filters.start_date : "today",
          start_date: filters ? filters.start_date : "",
          end_date: filters ? filters.end_date : today,
          currency_id: filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
        });
        break;
      case "VB":
      case "VBD":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : "",
            end_date: filters ? filters.end_date : today,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        dispatch(
          setDateRange({
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : "",
            end_date: filters ? filters.end_date : today,
          })
        );
        setInitializedFilter({
          date_range: filters ? filters.date_range : "today",
          start_date: filters ? filters.start_date : "",
          end_date: filters ? filters.end_date : today,
          vendor_id: filters ? filters.vendor_id : null,
          currency_id: filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
        });
        break;
      case "CB":
      case "CBD":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : "",
            end_date: filters ? filters.end_date : today,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        dispatch(
          setDateRange({
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : "",
            end_date: filters ? filters.end_date : today,
          })
        );
        setInitializedFilter({
          date_range: filters ? filters.date_range : "today",
          start_date: filters ? filters.start_date : "",
          end_date: filters ? filters.end_date : today,
          customer_id: filters ? filters.customer_id : null,
          currency_id: filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
        });
        break;
      case "RS":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : today,
            end_date: filters ? filters.end_date : today,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(
          setDateRange({
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : today,
            end_date: filters ? filters.end_date : today,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        setInitializedFilter({
          date_range: filters ? filters.date_range : "today",
          start_date: filters ? filters.start_date : today,
          end_date: filters ? filters.end_date : today,
          currency_id: filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
        });
        break;
      case "PS":
        dispatch(
          setReportTableFilters({
            ...tableFilterValues,
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : today,
            end_date: filters ? filters.end_date : today,
            currency_id: filters
              ? filters.currency_id
              : currentUserInfo.organization_currency,
            organization_id: filters
              ? filters.organization_id
              : currentUserInfo.organization_id,
          })
        );
        dispatch(setReportCustomizationFlag(true));
        dispatch(
          setDateRange({
            date_range: filters ? filters.date_range : "today",
            start_date: filters ? filters.start_date : today,
            end_date: filters ? filters.end_date : today,
          })
        );
        setInitializedFilter({
          date_range: filters ? filters.date_range : "today",
          start_date: filters ? filters.start_date : today,
          end_date: filters ? filters.end_date : today,
          currency_id: filters
            ? filters.currency_id
            : currentUserInfo.organization_currency,
          organization_id: filters
            ? filters.organization_id
            : currentUserInfo.organization_id,
        });
        break;
      default:
        setInitializedFilter({
          date_range: "today",
          currency_id: currentUserInfo.organization_currency,
          end_date: getFormatedDate(),
          organization_id: currentUserInfo.organization_id,
          start_date: getFormatedDate(),
        });
    }
    return () => {
      dispatch(setReportCustomizationFlag(false));
    };
  }, [currentUserInfo.organization_id, reportType]);
  return initializedFilter;
};
