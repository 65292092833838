import { Api } from "../../../../../constants";
import { get, post, put, remove } from "../../../../../helpers/apiHelper";
import { CustomFieldCreateEditValues } from "../../../../../types";

/**
 * API call for create custom field
 */
export async function createCustomFields(data: {
  value: CustomFieldCreateEditValues;
  orgId: number;
  transaction_type: string;
}) {
  const result = await post(
    Api.customField +
      `/create?current_organization=${data.orgId}&transaction_type=${data.transaction_type}`,
    data.value
  );
  return result;
}

/**
 * API call for edit custom field
 */
export async function editCustomFields(data: {
  value: CustomFieldCreateEditValues;
  orgId: number;
  custom_field_id: number | string | undefined;
}) {
  const result = await put(
    Api.customField +
      `/edit/${data.custom_field_id}?current_organization=${data.orgId}`,
    data.value
  );
  return result;
}

/**
 * API call for get custom field list for module
 */
export async function getCustomFieldModuleLists(data: {
  orgId: number;
  transaction_type: string;
}) {
  const result = await get(
    Api.customField +
      `/list?current_organization=${data.orgId}&transaction_type=${data.transaction_type}`
  );
  return result;
}

/**
 * API call for get custom field total list
 */
export async function getCustomFieldTotalLists(data: { orgId: number }) {
  const result = await get(
    Api.customField + `/detail?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting custom field
 */
export async function deleteCustomFields(data: {
  orgId: number;
  custom_field_id: number;
}) {
  const result = await remove(
    Api.customField +
      `/delete/${data.custom_field_id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for checking whether custom field exists
 */
export async function customFieldExistCheck(data: {
  orgId: number;
  field_name: string;
  module: string;
  signal: AbortSignal;
}) {
  const result = await get(
    Api.customField +
      `/is-exist?field_name=${data.field_name}&transaction_type=${data.module}&current_organization=${data.orgId}`,
    data.signal
  );
  return result;
}
