import { colors } from "../../../constants/colors";
import "../../../../index.css";
import './Button.css';

type Props = {
  buttonText: string;
};
const LockButton = (props: Props) => {
  return (
    <>
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.333 5.444v-.777a4.667 4.667 0 1 1 9.334 0v.777h1.555c.43 0 .778.349.778.778v9.334c0 .43-.348.777-.778.777H.778A.778.778 0 0 1 0 15.556V6.222c0-.43.348-.778.778-.778h1.555zM12.444 7H1.556v7.778h10.888V7zm-6.222 4.459a1.555 1.555 0 1 1 1.556 0v1.763H6.222V11.46zM3.89 5.444h6.222v-.777a3.111 3.111 0 0 0-6.222 0v.777z"
          fill={colors.white}
          fillRule="nonzero"
        />
      </svg>
      <span className="green-button-text">{props.buttonText}</span>
    </>
  );
};

export default LockButton;
