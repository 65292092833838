import React from "react";

export default function Notes(props: { notes: string }) {
  return (
    <div className="row g-0">
      <div className="col cn-subject-col">
        <span className="cn-subject-header">Notes</span>
        <p>{props.notes}</p>
      </div>
    </div>
  );
}
