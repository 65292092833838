export default function parseAmount(
  input: string,
  isCopyPaste: boolean
): number | null {
  let formattedNumber: number | null = null;
  const commaLastIndex = input.lastIndexOf(",");
  const decimalLastIndex = input.lastIndexOf(".");
  const spaceLastIndex = input.trim().lastIndexOf(" ");
  if (!isNaN(Number(input)) && isCopyPaste) {
    formattedNumber = parseFloat(input);
  } else if (
    (input.includes(",") && decimalLastIndex > commaLastIndex) ||
    (input.includes(",") &&
      !input.includes(".") &&
      commaLastIndex !== -1 &&
      decimalLastIndex !== -1) ||
    (input.includes(",") && decimalLastIndex === -1 && spaceLastIndex === -1)
  ) {
    formattedNumber = parseFloat(input.replaceAll(",", ""));
  } else if (input.includes(".") && isCopyPaste) {
    formattedNumber =
      input.replaceAll(".", "").includes(",") &&
      decimalLastIndex < commaLastIndex
        ? parseFloat(input.replaceAll(".", "").replace(",", "."))
        : parseFloat(input.replaceAll(".", ""));
  } else if (input.trim().includes(" ")) {
    formattedNumber = input.replaceAll(" ", "").includes(",")
      ? parseFloat(input.trim().replaceAll(" ", "").replace(",", "."))
      : parseFloat(input.trim().replaceAll(" ", ""));
  }
  return formattedNumber;
}
 
