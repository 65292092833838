import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { ItemTaxValues, ObjectType } from "../../../types";
import {
  taxPayableSelector,
  taxReceivableSelector,
} from "../../home/gst/taxRates/taxRateSlice";
import { gstTaxListSelector } from "../../home/gst/gstSlice";
import "../styles/TaxCode.css";
import { IsFeatureEnabled } from "../../../helpers/featureFlagHelper";
// import { TaxModal } from "../../home/gst/taxRates/components/TaxModal";
import { Link } from "react-router-dom";
import useGst from "../../hooks/useGst";
import _ from "lodash";
import { TaxRateFormContainer } from "../../home/taxConfiguration/components/taxRateForm/TaxRateFormContainer";

type Props = {
  taxIndex: number;
  taxType: string;
  taxes: ItemTaxValues[];
  taxLabelOnChange: (e: any, num: number, taxIndex: number) => void;
  itemIndex: number;
  fetchTaxesList: () => void;
  organizationId: number;
  disabledFeature: ObjectType[];
};
export default function TaxCode(props: Props) {
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let taxConfigurationPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Tax Configuration"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  // const { isGstOrg } = useGst();
  const taxList = useAppSelector(
    props.taxType === "payable" ? taxPayableSelector : taxReceivableSelector
  );

  // let taxList = useAppSelector(
  //   isGstOrg
  //     ? gstTaxListSelector
  //     : props.taxType === "payable"
  //     ? taxPayableSelector
  //     : taxReceivableSelector
  // );

  // if (isGstOrg) {
  //   taxList = _.orderBy(taxList, ["tax_group"], ["asc"]);
  //   if (taxList[0] && taxList[0].tax_group === false) {
  //     taxList.unshift({ id: 0, tax_code: "Taxes", is_label: true });
  //   }
  //   let group_start_index = taxList.findIndex((tax) => tax.tax_group === true);
  //   if (group_start_index !== -1) {
  //     taxList.splice(group_start_index, 0, {
  //       id: 0,
  //       tax_code: "Tax Group",
  //       is_label: true,
  //     });
  //   }
  // }

  const taxCodeName = (taxIndex: number) => {
    let name = taxList
      .filter((tax) => tax.id === props.taxes[taxIndex].tax_id)
      .map((taxes) => {
        return taxes.tax_code;
      });
    return name[0];
  };

  const liClickHandler = (tax: ObjectType) => {
    props.taxLabelOnChange(tax.id, props.itemIndex, props.taxIndex);
  };

  return (
    <>
      <div className={`btn-group taxcode-dropdown`}>
        <input
          type="text"
          name="tax_id"
          className={`search-dropdown dropdown-toggle`}
          placeholder="Tax code"
          data-bs-toggle="dropdown"
          value={taxCodeName(props.taxIndex)}
          onChange={() => {}}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          id={`tax-code-${props.taxIndex}`}
        />
        <ul className="dropdown-menu">
          {taxList.map((tax, index) => {
            return (
              <li
                onClick={() => {
                  liClickHandler(tax);
                }}
                key={index}
              >
                {tax.is_label ? (
                  <>
                    <p className="tax-dropdown-label">{tax.tax_code}</p>
                  </>
                ) : (
                  <Link
                    className={`dropdown-item ${
                      $(`#tax-code-${props.taxIndex}`).val() === tax.tax_code
                        ? "active"
                        : ""
                    }`}
                    to="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="itemLabel">{tax.tax_code}</span>
                  </Link>
                )}
              </li>
            );
          })}

          {taxList.length === 0 ? (
            <li className="no-result">
              <Link
                className="dropdown-item no-result"
                to="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No tax code found</span>
              </Link>
            </li>
          ) : null}
          {taxConfigurationPermission.includes("Create") &&
          IsFeatureEnabled(
            "Tax Configuration",
            props.disabledFeature ? props.disabledFeature : []
          ) ? (
            <li>
              <TaxRateFormContainer
                taxId={-1}
                refreshList={props.fetchTaxesList}
                isAccountDeleted={false}
              >
                <Link
                  to="#"
                  className="dropdown-item add-link-option"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span className="itemLabel">New tax</span>
                </Link>
              </TaxRateFormContainer>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
}
