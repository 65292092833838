import React, { useEffect, useState } from "react";
import DropdownWithActions from "../../dropdownWithActions/DropdownWithActions";
import { ObjectType } from "../../../../../types";
import CustomizeReportModal from "../CustomizeReportModal";
import { EditIconImg } from "../../../../../assets/images";
import { DeleteIcon } from "../../customSvgIcons";
import { colors } from "../../../../constants/colors";
import useCommonData from "../../../../hooks/useCommon";
import {
  getAgingCustomizations,
  removeAgingCustomizations,
} from "../../../../home/reports/ReportSlice";
import { DeleteAlertModal } from "../../deleteModal/DeleteAlertModal";
import { setLoaderState } from "../../../commonSlice";
import { SuccessToaster } from "../../../../../helpers/toastHelper";

type Props = {
  module: string;
  handleReport: (type: string, arg?: { [key: string]: any }) => void | null;
  reportData: ObjectType;
  type: string;
};

function SavedCustomization(props: Props) {
  const { currentUserInfo, dispatch } = useCommonData();

  useEffect(() => {
    getSavedCustomizations();
  }, []);

  const getSavedCustomizations = async () => {
    let reportType = "";
    if (props.type === "debtor" && props.module === "aging_summary") {
      reportType = "DebtorSummary";
    } else if (props.type === "debtor" && props.module === "aging_details") {
      reportType = "DebtorDetails";
    } else if (props.type === "creditor" && props.module === "aging_summary") {
      reportType = "CreditorSummary";
    } else if (props.type === "creditor" && props.module === "aging_details") {
      reportType = "CreditorDetails";
    }

    await dispatch(
      getAgingCustomizations({
        orgId: currentUserInfo.organization_id,
        reportType: reportType,
      })
    );
  };

  const handleDelete = async (id: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      removeAgingCustomizations({
        orgId: currentUserInfo.organization_id,
        customizationId: id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Customization deleted successfully",
        "customization-delete-success"
      );
      getSavedCustomizations();
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  return (
    <DropdownWithActions handleReport={props.handleReport}>
      {(handleToggleDropdown, option) => (
        <>
          <CustomizeReportModal
            module={props.module}
            handleReport={props.handleReport}
            reportData={option}
            type={props.type}
            reportType="AR"
            buttonClass="edit"
            buttonContent={<img src={EditIconImg} alt="edit-list-img" />}
            wrapperClass="edit-customization"
            onOpenFunction={() => handleToggleDropdown(false)}
          />
          <DeleteAlertModal
            onCancel={() => {}}
            onSubmit={() => {
              handleDelete(option.id);
            }}
            isSubmitDisabled={false}
            deleteMessageContent={
              <span>
                Are you sure you want to delete <b>{option.name}</b> ?
              </span>
            }
            deleteModalTitle="Delete Customization"
            hideButtonText={true}
          />
        </>
      )}
    </DropdownWithActions>
  );
}

export default SavedCustomization;
