import * as React from "react";
import CustomItemSelect from "../CustomItemSelect";
import { CustomerItemValues, InventoryItemValues } from "../../../../types";
import { useAppSelector } from "../../../../app/hooks";
import { currentUserSelector } from "../../commonSlice";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";

type Props = {
  index: number;
  item: { inventory_item_id: number | null | undefined; item_name: string };
  componentType: string;
  currencyId: number | string | undefined;
  currencyCode: string;
  handleItemDropdownChange: (item: InventoryItemValues, index: number) => void;
  handleInputItemChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | {
          target: {
            name: string;
            value: string;
          };
        },
    index: number
  ) => void;
  error: string;
};
function ItemField({
  index,
  item,
  componentType,
  currencyId,
  currencyCode,
  handleItemDropdownChange,
  handleInputItemChange,
  error,
}: Props) {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { itemsRolePermission } = usePermissionHandler();
  return (
    <div className="col w-380 item-input">
      <label htmlFor="cnitem" className="text-upper required">
        Add Item/Description
      </label>
      {[
        "invoice",
        "creditNote",
        "bill",
        "debitNote",
        "recurringInvoice",
      ].includes(componentType) ? (
        <CustomItemSelect
          selectedItem={{
            id: item.inventory_item_id,
            name: item.item_name,
          }}
          currencyId={currencyId ? currencyId : ""}
          itemIndex={index}
          error={error ? " error" : ""}
          handleItemChange={handleItemDropdownChange}
          handleInputItemChange={handleInputItemChange}
          currencyCode={currencyCode}
          componentType={componentType}
          placeholder={"Choose an item or enter a description"}
        />
      ) : (
        <input
          type="text"
          id="cnitem"
          className={`form-control cnitem ${error ? " error" : ""}`}
          name="item_name"
          placeholder="Add item"
          value={item.item_name}
          maxLength={100}
          onChange={(e) => handleInputItemChange(e, index)}
        />
      )}
      <span className="error">{error}</span>
    </div>
  );
}

export default React.memo(ItemField);
