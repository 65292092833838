import React from "react";
import useCommonData from "../../../../hooks/useCommon";
import { getSundryCreditorSummary } from "../../ReportSlice";
import AgingSummary from "./AgingSummary";

function SundryCreditorSummary() {
  const { dispatch, currentUserInfo } = useCommonData();

  const generateReport = async (filterValues: any) => {
    const responseAction = await dispatch(
      getSundryCreditorSummary({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  return (
    <AgingSummary
      title="Sundry Creditor Aging Summary for Bill Due Date"
      generateReport={generateReport}
      type="creditor"
    />
  );
}

export default SundryCreditorSummary;
