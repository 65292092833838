import { ObjectType } from "../types";
/**
 * Download pdf
 */
export const downloadUsingURL = (url: string, name: string) => {
  return new Promise((resolve, reject) => {
    interface Document {
      documentMode?: any;
    }
    // let link: any = document.createElement("a");
    // link.download = name;
    // link.href = url;
    // link.target = "_blank";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // link = null;
    // var url = url;
    // url = "http://localhost:3000/pdf/samplePdfForFinbook.pdf"

    //Create XMLHTTP Request.
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      var blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.

      // var isIE = false || !!document.DOCUMENT_NODE;
      // if (isIE) {
      //     // if (navigator.msSaveBlob) {
      //     //     window.navigator.msSaveBlob(blob, fileName);
      //     // }

      // } else {
      var url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("download", name);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      resolve(true);
      // }
    };
    req.send();
  });
};

/**
 * Function to convert bytes to KB, MB, GB etc..
 */
export const FormatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Set color for status
 */
export const StatusColor = (status: string) => {
  // let value = status?.includes("Overdue") ? "overdue" : status?.toLowerCase();
  let value: string = "";
  if (String(status)?.includes("Overdue")) {
    value = "overdue";
  } else if (String(status)?.includes("Open")) {
    value = "open";
  } else if (status?.includes("Partially")) {
    value = "partially paid";
  } else {
    value = String(status)?.toLowerCase();
  }
  switch (value) {
    case "paid":
      return "s-green";
    case "partially paid":
      return "s-purple";
    case "open":
      return "s-orange";
    case "open (deferred income pending)":
      return "s-orange";
    case "non renewing":
      return "s-orange";
    case "unpaid":
      return "s-orange";
    case "overdue":
      return "s-red";
    case "overdue (deferred income pending)":
      return "s-red";
    case "draft":
      return "s-blue";
    case "future":
      return "s-blue";
    case "in trial":
      return "s-blue";
    case "excess":
      return "s-red";
    case "allocated":
      return "s-green";
    case "closed":
      return "s-green";
    case "closed (deferred income pending)":
      return "s-green";
    case "published":
      return "s-green";
    case "active":
      return "s-green";
    case "stopped":
      return "s-orange";
    case "expired":
      return "s-red";
    case "cancelled":
      return "s-gray";
    case "stop":
      return "s-orange";
    case "void":
      return "s-gray";
    case "exhausted":
      return "s-red";
    case "billed":
      return "s-green";
    case "approved":
      return "s-green";
    case "declined":
      return "s-red";
    case "invoiced":
      return "s-green";
    default:
      return "";
  }
};

/**
 * Format date from YYYY-MM-DD to DD-MM-YYYY
 */
export const DateFormatHandler = (date: string | undefined) => {
  if (!date) {
    return "";
  }
  let newDate = date.split("-").reverse().join("-");
  return newDate;
};
/**
 * Format date from Date() format to YYYY-MM-DD
 */
export const getFormatedDate = (date?: Date) => {
  const dateTemp = date ? new Date(date) : new Date();
  const year = dateTemp.getFullYear();
  let month = dateTemp.getMonth() + 1;
  let monthNumber =
    month < 10 && month.toString().length === 1 ? "0" + month : month;
  month = Number(monthNumber);
  const day = dateTemp.getDate();
  let dayNumber = day < 10 && day.toString().length === 1 ? "0" + day : day;
  return year + "-" + monthNumber + "-" + dayNumber;
};
/**
 * Display the "-" sign before currency code for -ve values
 */

export const getFormatedDateDMY = (date?: Date) => {
  const dateTemp = date ? new Date(date) : new Date();
  const year = dateTemp.getFullYear();
  let month = dateTemp.getMonth() + 1;
  let monthNumber =
    month < 10 && month.toString().length === 1 ? "0" + month : month;
  month = Number(monthNumber);
  const day = dateTemp.getDate();
  let dayNumber = day < 10 && day.toString().length === 1 ? "0" + day : day;
  return dayNumber + "-" + monthNumber + "-" + year;
};

export const handleNegativeValue = (value: string, currencyCode: string) => {
  if (
    value === "0" ||
    value === "0.00" ||
    value === "-0" ||
    value === "-0.00"
  ) {
    return currencyCode + " " + value.replace("-", "");
  }
  if (value && value.includes("-")) {
    let result = value.replace("-", "");
    return "- " + currencyCode + " " + result;
  } else {
    return currencyCode + " " + value;
  }
};
/**
 * generate randrom string
 */
export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Helper function for splitting a number into an array based on the decimal point
 */
export const splitNumberByDecimalPoint = (amount: number) => {
  const decimalPointSplitArray = amount.toString().split(".");
  return decimalPointSplitArray;
};

export const getKeyByValue = (
  object: ObjectType,
  value: string | number | undefined
) => {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return "";
};

/**
 * Helper function for handling a amount with - symbol prepended if it is a debit
 */
export const handleNegativeValueWithTransactionType = (
  value: string,
  transactType: string = "",
  currencyCode: string = ""
) => {
  const formattedValue = value.replace("-", "");
  if (
    !value ||
    value === "0" ||
    value === "0.00" ||
    value === "-0" ||
    value === "-0.00"
  ) {
    return currencyCode
      ? `${currencyCode} ${formattedValue}`
      : `${formattedValue}`;
  }
  if (transactType === "-") {
    return currencyCode
      ? `${transactType}${currencyCode} ${formattedValue}`
      : `${transactType}${formattedValue}`;
  } else {
    return currencyCode ? `${currencyCode} ${formattedValue}` : formattedValue;
  }
};
/* Format date from DD/MM/YYYY to DD-MM-YYYY
 */
export const DateFormatWithHyphen = (date: string) => {
  if (!date) {
    return "";
  }
  let newDate = date.split("/").join("-");
  return newDate;
};
