import React, { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { SxProps } from "@mui/system";
import "./datepicker.css";
import { useAppSelector } from "../../../app/hooks";
import { fiscalTearFilterForValidationSelector } from "../../common/commonSlice";
import useCommonData from "../../hooks/useCommon";

type Props = {
  date: Date | null;
  type: string;
  error: string;
  id?: string;
  zIndex?: number;
  inset?: string;
  placeholder?: string;
  minDate?: Date;
  isOpen?: boolean;
  name?: string;
  openPicker?: () => void;
  closePicker?: () => void;
  enableFutureDate?: boolean;
  formik: any;
  disabled?: boolean;
  isDisabled?: boolean;
};

const CustomDatepickerFormic = (props: Props) => {
  
  const handleOpen = () => {
    if (props.openPicker) props.openPicker();
    // setIsDatePickerOpen(true);
  };

  const handleClose = () => {
    if (props.closePicker) props.closePicker();
    // setIsDatePickerOpen(false);
  };
  
  const sxProps: SxProps = {
    "&.MuiPickersPopper-root": {
      zIndex: props.zIndex,
      inset: props.inset,
    },
  };

  let minDate = undefined;
  if (props.minDate) {
    minDate = props.minDate;
  } else if (props.type === "expiryDate") {
    minDate = new Date();
  }
 
  return (
    <div className={`date-container ${props.error ? "error" : ""} ${props.id}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            value={props.formik.values.pause_date}
            open={props.isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            className={props.id}
            onChange={(e) => props.formik.setFieldValue(props.name, e)}
            renderInput={(params) => (
              <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
                name: props.name,
                disabled:  props.disabled,
                onChange: props.formik.handleChange,
                onBlur: props.formik.handleBlur,
              }}
              />
            )}
            minDate={minDate}
            maxDate={props.enableFutureDate ? undefined : new Date()}
            PopperProps={props.zIndex !== 0 ? { sx: sxProps } : {}}
            disabled={props.isDisabled ? true : false}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatepickerFormic;
