import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  InvoicePaymentValues,
  PaymentReceivedJournalListPayload,
  ReportingTagsAllocationPayload,
  TotalPaymentReceivedValues,
} from "../../../types";
import {
  getUnpaidInvoiceList,
  getNewPrNumber,
  checkPrNumberExists,
  createNewInvoicePayment,
  paymentReceivedList,
  deleteInvoicePayment,
  paymentReceivedAttachments,
  deletePaymentReceivedAttachments,
  getPaymentReceivedDetails,
  paymentReceivedPdfLink,
  editInvoicePayment,
  sendPaymentReceipt,
  removePaymentReceivedRecipients,
  totalAmountCalculation,
  transactionLimitStatus,
  deleteItemAllocation,
  createJournalList,
  editReportingTagPR,
  createNewRefund,
  fetchRefundDetails,
  editRefund,
  deleteRefund,
  sundryTagAllocatedInvoicesTagDetails,
} from "./paymentReceivedAPI";
import { StdioNull } from "child_process";

export const invoicePaymentList = createAsyncThunk(
  "invoicePaymentList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    customerList: number[];
    orgIds: number[];
    statusList: string[];
    invoiceId?: number | null;
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await paymentReceivedList(data);
  }
);

export const unpaidInvoiceList = createAsyncThunk(
  "unpaidInvoiceList",
  async (data: { currentOrgId: number; customerId: number }) => {
    return await getUnpaidInvoiceList(data);
  }
);

export const getPaymentReceivedNumber = createAsyncThunk(
  "getPaymentReceivedNumber",
  async (currentOrgId: number) => {
    return await getNewPrNumber(currentOrgId);
  }
);

export const checkPrNumber = createAsyncThunk(
  "checkPrNumber",
  async (data: {
    currentOrgId: number;
    prNumber: string;
    paymentReceivedId: number;
    signal?: AbortSignal;
  }) => {
    return await checkPrNumberExists(data);
  }
);

export const createInvoicePayment = createAsyncThunk(
  "createInvoicePayment",
  async (data: {
    values: InvoicePaymentValues;
    orgId: number;
    status: string;
  }) => {
    return await createNewInvoicePayment(data);
  }
);

export const updateInvoicePayment = createAsyncThunk(
  "updateInvoicePayment",
  async (data: {
    paymentId: number;
    values: InvoicePaymentValues;
    status: string;
    orgId: number;
    isCustomerChange: boolean;
  }) => {
    return await editInvoicePayment(data);
  }
);

export const deletePayment = createAsyncThunk(
  "deletePayment",
  async (data: { deletePaymentId: number; orgId: number }) => {
    return await deleteInvoicePayment(data);
  }
);

export const attachPaymentReceivedFiles = createAsyncThunk(
  "pr/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await paymentReceivedAttachments(data);
  }
);

export const deletePaymentReceivedFile = createAsyncThunk(
  "pr/file/delete",
  async (data: { prId: number; fileId: number; orgId: number }) => {
    return await deletePaymentReceivedAttachments(data);
  }
);

export const paymentReceivedDetails = createAsyncThunk(
  "paymentReceivedDetails",
  async (data: { paymentReceivedId: number; orgId: number }) => {
    return await getPaymentReceivedDetails(data);
  }
);

export const getPaymentReceivedPdfLink = createAsyncThunk(
  "payment-received/pdf",
  async (data: { paymentReceivedId: number; orgId: number }) => {
    return await paymentReceivedPdfLink(data);
  }
);

export const sendPaymentReceivedData = createAsyncThunk(
  "send-payment-received",
  async (data: { prId: number; emails: string[]; orgId: number }) => {
    return await sendPaymentReceipt(data);
  }
);

export const deletePaymentReceivedRecipients = createAsyncThunk(
  "pr/delete/recipients",
  async (data: { prId: number; emailIds: number[]; orgId: number }) => {
    return await removePaymentReceivedRecipients(data);
  }
);

export const getTotalAmountDetails = createAsyncThunk(
  "total-amount",
  async (data: {
    values: TotalPaymentReceivedValues;
    orgId: number;
    signal: Object;
  }) => {
    return totalAmountCalculation(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "pr/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const deleteInvoiceAllocation = createAsyncThunk(
  "pr-item/delete",
  async (data: {
    prId: number | null;
    itemId: number | null;
    orgId: number | null;
  }) => {
    return await deleteItemAllocation(data);
  }
);

export const generateJournalListPR = createAsyncThunk(
  "pr-reporting-tag/generate",
  async (data: {
    prId: number | null;
    orgId: number | null;
    values: PaymentReceivedJournalListPayload;
  }) => {
    return await createJournalList(data);
  }
);

export const updateReportingTagsPR = createAsyncThunk(
  "pr-reporting-tag/allocation",
  async (data: { prId: number | null; orgId: number | null; values: any }) => {
    return await editReportingTagPR(data);
  }
);

export const createRefund = createAsyncThunk(
  "pr-refund/create",
  async (data: { orgId: number | null; values: any }) => {
    return await createNewRefund(data);
  }
);

export const getRefundDetails = createAsyncThunk(
  "pr-refund/get",
  async (data: { refundId: number; orgId: number }) => {
    return await fetchRefundDetails(data);
  }
);

export const updateRefund = createAsyncThunk(
  "pr-refund/edit",
  async (data: { refundId: number; orgId: number; values: any }) => {
    return await editRefund(data);
  }
);

export const removeRefund = createAsyncThunk(
  "pr-refund/remove",
  async (data: { refundId: number; orgId: number }) => {
    return await deleteRefund(data);
  }
);
export const getSundryAllocatedInvoiceTagDetails = createAsyncThunk(
  "sundry-allocated-tag-list/get",
  async (data: { invoiceIds: number[]; orgId: number }) => {
    return await sundryTagAllocatedInvoicesTagDetails(data);
  }
);

const initialState = {
  listParams: {
    page: 1,
    itemsPerPage: 10,
    startCount: 0,
    endCount: 0,
    pageCount: 1,
    itemOffset: 0,
    dateSortOrder: "",
  },
  amountCalculationResult: {
    total_details: {
      total: 0,
      witholding_total: 0,
      amount_used_payment: 0,
      amount_received: 0,
      bank_charges: 0,
      amount_excess: 0,
      invoice_balances_after_payment: {},
    },
    allocatable_amount: 0,
  },
};

export const paymentReceivedSlice = createSlice({
  name: "paymentsReceived",
  initialState,
  reducers: {
    setListParams: (state, action) => {
      state.listParams = { ...state.listParams, ...action.payload };
    },
    setAmountCalculationResult: (state, action) => {
      state.amountCalculationResult = {
        ...action.payload,
      };
    },
  },
});

export const { setListParams, setAmountCalculationResult } =
  paymentReceivedSlice.actions;
export default paymentReceivedSlice.reducer;

export const listParamsSelector = (state: RootState) =>
  state.paymentsReceived.listParams;
export const getAmountCalculationResult = (state: RootState) =>
  state.paymentsReceived.amountCalculationResult;
