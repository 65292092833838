import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { CurrencyFormType, ObjectType } from "../../../../types";
import { CurrencySelectCustomStyle } from "../../../common/components/CurrencySelectCustomStyle";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../common/commonSlice";
import { updateCurrency } from "../currencyConfigurationSlice";
import { decimalFormatValues } from "../../../constants/constants";

type Props = {
  action: string;
  currencyData: ObjectType;
  getCurrencyList: () => void;
  isTransactionExist: boolean;
};
type OptionType = {
  value: string;
  label: string;
};
const AddCurrency = (props: Props) => {
  const initialFormValues = {
    id: 0,
    code: "",
    symbol: "",
    name: "",
    decimal: 2,
    format: "",
    is_org_currency: false,
  };
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [currencyCodeList, setCurrencyCodeList] = useState<OptionType[]>();
  const [isSubmit, setSubmit] = useState(false);
  const [currencyFormValues, setCurrencyFormValues] =
    useState<CurrencyFormType>(initialFormValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [initialDecimalPlace, setInitialDecimalPlace] = useState(0);
  useEffect(() => {
    if (props.action === "create") {
      setCurrencyFormValues(initialFormValues);
    } else if (props.action === "edit") {
      setCurrencyFormValues(props.currencyData as CurrencyFormType);
      setInitialDecimalPlace(props.currencyData.decimal);
    }
  }, [props.action, props.currencyData]);

  const getCurrencyCode = () => {
    let currencyCodes: OptionType[] = [];
    orgCurrencyList.map((item: ObjectType) => {
      currencyCodes.push({ value: item.code, label: item.code });
    });
    setCurrencyCodeList(currencyCodes);
  };

  useEffect(() => {
    getCurrencyCode();
  }, []);

  const handleInputChange = (name: string, value: any) => {
    if (name === "symbol" && value.length <= 5) {
      setCurrencyFormValues({ ...currencyFormValues, [name]: value });
    } else if (name === "name" && value.length <= 40) {
      setCurrencyFormValues({ ...currencyFormValues, [name]: value });
    } else if (name === "decimal") {
      setCurrencyFormValues({
        ...currencyFormValues,
        [name]: value,
        format: getFormatValue(value),
      });
    } else {
      setCurrencyFormValues({ ...currencyFormValues, [name]: value });
    }
  };
  /**
   * submit currency form
   */
  const submit = async () => {
    const errors = validate();
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setSubmit(true);
      // api call to submit form values
      const { code, id, ...rest } = currencyFormValues;
      const updateCurrencyResponseAction = await dispatch(
        updateCurrency({
          values: {
            ...rest,
            format: getValueByIdAndKey(rest.decimal, rest.format),
          },
          id: Number(currencyFormValues.id),
          orgId: currentUserInfo.organization_id,
        })
      );
      const updatePrefixResponse = updateCurrencyResponseAction.payload;
      if (
        Object.keys(updatePrefixResponse).length &&
        !("error" in updatePrefixResponse)
      ) {
        toast.success("Currency details updated!", {
          toastId: "currency-update-success",
          closeButton: false,
          position: "top-center",
        });
        props.getCurrencyList();
        setSubmit(false);
      } else {
        setSubmit(false);
      }
      $(".close-btn").click();
    }
  };

  const validate = () => {
    let errors: ObjectType = {};
    if (!currencyFormValues.code) {
      errors.code = "Please choose currency code";
    }
    if (!currencyFormValues.symbol || currencyFormValues.symbol === "") {
      errors.symbol = "Please enter currency symbol";
    }
    if (!currencyFormValues.name || currencyFormValues.name === "") {
      errors.name = "Please enter currency name";
    }
    if (props.isTransactionExist) {
      if (currencyFormValues.decimal !== initialDecimalPlace) {
        errors.decimal = `There are existing transactions associated with this currency. To change the decimal configuration please connect with our IT support`;
      }
    }
    return errors;
  };
  /**
   * Reset values while closing modal
   */
  $(document).ready(function () {
    $(".modal .close-btn")
      .off()
      .on("click", function () {
        setCurrencyFormValues(initialFormValues);
        setFormErrors({});
      });
  });

  /**
   * To get the first value of the format based on selected decimal
   */
  const getFormatValue = (decimals: number) => {
    const item = decimalFormatValues.find(
      (obj) => obj.decimalPlaces === decimals
    );
    return item ? Object.keys(item.value)[0] : "";
  };

  /**
   * To get the format options based on selected decimal
   */
  const getFormatOptions = (decimals: number) => {
    const format = decimalFormatValues
      .filter((item) => item.decimalPlaces === decimals)
      .map((item) => item.value);

    const resultArray = [];

    for (const [key, value] of Object.entries(format[0])) {
      resultArray.push({ value: key, label: key });
    }
    return resultArray;
  };

  /**
   * To get the format (##,##,###.###) for the request payload from (12,34,567.899)
   */
  const getValueByIdAndKey = (decimal: number, key: string) => {
    const item: any = decimalFormatValues.find(
      (obj) => obj.decimalPlaces === decimal
    );
    if (item && item.value.hasOwnProperty(key)) {
      return item.value[key];
    }
    return null;
  };

  return (
    <>
      <div
        className="modal fade right"
        id="addCurrencyModal"
        tabIndex={-1}
        aria-labelledby="addCurrencyModalLabel"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-fullscreen w-100">
          <div className="modal-content">
            <div className="modal-header close-button-container">
              <button
                type="button"
                className="close-btn pull-right"
                data-bs-dismiss="modal"
                id="close-modal"
                aria-label="Close"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      fill="#91919c"
                      fillRule="nonzero"
                      d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="modal-body user-form">
              <div className="addCurrency-form-head">
                <h4 id="addCurrencyModalLabel">
                  {props.action === "create" ? "Add Currency" : "Edit Currency"}
                </h4>
              </div>
              <form className="create-user-form-wrap add-currency-form-wrap create-settings-form-wrap">
                <div className={"form-outline"}>
                  <label className="form-label" htmlFor="code">
                    Currency Code<span className="mandatory">*</span>
                  </label>
                  <Select
                    name="code"
                    isDisabled={true}
                    value={
                      currencyFormValues.code
                        ? {
                            label: currencyFormValues.code,
                            value: currencyFormValues.code,
                          }
                        : []
                    }
                    options={currencyCodeList}
                    placeholder="Choose currency code"
                    onChange={(e) => {
                      if (e?.value) handleInputChange("code", e.value);
                    }}
                    className={`form-select custom-select customer-select m-0 ${
                      formErrors.code ? "error" : ""
                    }`}
                    styles={CurrencySelectCustomStyle}
                    isSearchable={false}
                  />
                  <span className="error">{formErrors.code}</span>
                </div>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="symbol">
                    Currency Symbol<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="symbol"
                    name="symbol"
                    value={currencyFormValues.symbol}
                    className={
                      formErrors.symbol ? "form-control error" : "form-control"
                    }
                    placeholder="Enter symbol"
                    onChange={(e) => {
                      handleInputChange(e?.target.name, e?.target.value);
                    }}
                    maxLength={5}
                  />
                  <span className="error">{formErrors.symbol}</span>
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="name">
                    Currency Name<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={currencyFormValues.name}
                    className={
                      formErrors.name ? "form-control error" : "form-control"
                    }
                    placeholder="Enter name"
                    onChange={(e) => {
                      handleInputChange(e?.target.name, e?.target.value);
                    }}
                    maxLength={40}
                  />
                  <span className="error">{formErrors.name}</span>
                </div>
                <div className={"form-outline"}>
                  <label className="form-label" htmlFor="decimal">
                    Decimal Places<span className="mandatory">*</span>
                  </label>
                  <Select
                    name="decimal"
                    isDisabled={!currentUserInfo.is_super_parent}
                    value={{
                      label: currencyFormValues.decimal,
                      value: currencyFormValues.decimal,
                    }}
                    options={[
                      { label: 0, value: 0 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                    ]}
                    placeholder="Choose decimal"
                    onChange={(e: any) => {
                      handleInputChange("decimal", e.value);
                    }}
                    className={`form-select custom-select customer-select m-0 ${
                      formErrors.decimal ? "error" : ""
                    }`}
                    styles={CurrencySelectCustomStyle}
                    isSearchable={false}
                  />
                  <span className="error">{formErrors.decimal}</span>
                </div>
                <div className={"form-outline"}>
                  <label className="form-label" htmlFor="currency_format">
                    Format
                  </label>
                  <Select
                    name="currency_format"
                    value={
                      currencyFormValues.format
                        ? {
                            label: currencyFormValues.format,
                            value: currencyFormValues.format,
                          }
                        : []
                    }
                    options={getFormatOptions(currencyFormValues.decimal)}
                    placeholder="Choose currency format"
                    onChange={(e) => {
                      if (e?.value) handleInputChange("format", e.value);
                    }}
                    styles={CurrencySelectCustomStyle}
                    isDisabled={!currentUserInfo.is_super_parent}
                  />
                  <span className="error">{}</span>
                </div>
                <div>
                  <button
                    className="btn btn-block fa-lg invite-btn save-butn mb-4"
                    type="button"
                    onClick={submit}
                    disabled={isSubmit ? true : false}
                  >
                    {"Save"}
                  </button>
                  <button
                    className="btn btn-block fa-lg cancel-btn mb-4"
                    type="button"
                    onClick={() => $(".close-btn").click()}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCurrency;
