import React from "react";
import { useAppSelector } from "../../../../../../../app/hooks";
import { rolesSelector } from "../../../../userSlice";
import { IsFeatureEnabled } from "../../../../../../../helpers/featureFlagHelper";
import { PlusSmallImg } from "../../../../../../../assets/images";
import ListHeader from "../../../common/ListHeader";
import { Transactionlimit } from "../../../../../../../types";
import useCommonData from "../../../../../../hooks/useCommon";
import { useNavigate } from "react-router-dom";
type Props = {
  transactionLimit: Transactionlimit;
};
const RolesListHeader = (props: Props) => {
  const { currentUserInfo } = useCommonData();
  const userRoles = useAppSelector(rolesSelector);
  const navigate = useNavigate();

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let rolePermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Roles")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  /**
   * Create Role Handler
   */
  const handleCreate = () => {
    userRoles.length < 15 && !props.transactionLimit.limit_exceed
      ? navigate("/roles/create", {
          state: { action_type: "create", roleSort: "" },
        })
      : navigate("");
  };
  return (
    <div className="card-header card-user-management-header card-no-bg-header">
      <ListHeader />
      <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
        {rolePermission.includes("Create") &&
        IsFeatureEnabled("Roles", currentUserInfo.disabled_feature) ? (
          <li className="nav-item" onClick={handleCreate}>
            <button
              className="btn add-role-button green-button"
              type="button"
              data-bs-toggle={
                userRoles.length >= 15 || props.transactionLimit.limit_exceed
                  ? "modal"
                  : ""
              }
              data-bs-target={
                props.transactionLimit.limit_exceed
                  ? "#roleLimitModal"
                  : "#notificationPopupModal"
              }
            >
              <img src={PlusSmallImg} className="Group" alt="plus-small-img" />
              <span className="add-role-button-text green-button-text">
                New Role
              </span>
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default RolesListHeader;
