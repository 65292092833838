import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";

import ListActions from "./ListActions";
import useCommonData from "../../../../../hooks/useCommon";
import AddonTable from "./AddonTable";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { addonList } from "../../../addonSlice";
import useListData from "../../../../../hooks/useListData";
import { StatusColor } from "../../../../../../helpers/helper";
import { setLoaderState } from "../../../../../common/commonSlice";
import { coustomCase } from "../../../../../../helpers/planHelper";
import { useAppSelector } from "../../../../../../app/hooks";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import { Transactionlimit } from "../../../../../../types";
import "./addonTableContainer.css";

type Props = {
  deleteListData: (actionType: string, id: number, addonName: string) => void;
  voidListData: (id: number, addonName: string) => void;
  isFilterApplied: boolean;
  initializeFilter: () => void;
  transactionLimit: Transactionlimit;
};

function AddonTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo } = useCommonData();
  const { addonsRolePermission } = usePermissionHandler();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const [addonListData, setAddonListData] = useState<any[]>([]);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);

  useImperativeHandle(
    ref,
    () => ({
      fetchAddonList: fetchAddonList,
    }),
    []
  );

  useEffect(() => {
    fetchAddonList();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);

  useEffect(() => {
    if (isFilterApplied) fetchAddonList();
  }, [selectedFilterData, isFilterApplied]);

  const columns = useMemo(
    () => [
      {
        Header: "Addon Name",
        accessor: "show.external_name",
        sort: false,
        sortOrder: "",
        className: "external_name",
      },
      {
        Header: "Status",
        accessor: "show.addon_status",
        sort: false,
        sortOrder: "",
        className: "plan_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Item",
        accessor: "show.items",
        sort: false,
        sortOrder: "",
        className: "items",
      },
      {
        Header: "Last modified",
        accessor: "show.last_modified",
        sort: false,
        sortOrder: "",
        className: "last_modified",
      },
      {
        Header: "Organisation",
        accessor: "show.organisation",
        sort: false,
        sortOrder: "",
        className: "organisation",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "actions addon_actions",
        Cell: ({ cell: { value } }: any) => {
          return !addonsRolePermission.includes("Update") ||
            value.status.toLowerCase() === "cancelled" ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickDelete={() => {
                props.deleteListData("Addon", value.id, value.name);
              }}
              onClickVoid={() => {
                props.voidListData(value.id, value.name);
              }}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, addonsRolePermission]
  );

  const dateFormat = (timestamp: any) => {
    const dateObj = new Date(timestamp);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const date = String(dateObj.getDate()).padStart(2, "0");

    const formattedDate = `${date}/${month}/${year}`;

    return `${formattedDate}`;
  };

  const fetchAddonList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      addonList({
        page,
        itemsPerPage,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status).map((eachStatus: string) => eachStatus.toUpperCase())
          : [],
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setAddonListData(response.addons);
        let length = response.addons.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [
    page,
    itemsPerPage,
    selectedFilterData,
    currentUserInfo.organization_id,
    selectedFilterData.Status,
  ]);

  const data = addonListData
    ? addonListData.map((addon) => ({
        show: {
          external_name: addon.external_name,
          addon_status: coustomCase(addon.addon_status),
          items: addon.items.length > 0 ? addon.items.join(", ") : "",
          last_modified: dateFormat(addon.last_modified),
          organisation: addon.organization,
          actions: {
            id: addon.id,
            status: addon.addon_status,
            name: addon.external_name,
            organizationId: addon.organization_id,
            addon_exists_subscription: addon.addon_exists_subscription,
          },
        },
      }))
    : [];

  return (
    <>
      <AddonTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        invoicesRolePermission={addonsRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
      />
    </>
  );
}

export default React.memo(forwardRef(AddonTableContainer));
