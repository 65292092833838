import { Link } from "react-router-dom";
import { LockIconBlue } from "../../../../../assets/images";
import { TransactionDetailValues } from "../../../../../types";
import "../common/LockAllTransactionFooter.css";

type Props = {
  transactionDetails: TransactionDetailValues;
  openModal: (action: string, moduleName: string, moduleId: number[]) => void;
};
const LockAllTransactionFooter = (props: Props) => {
  return (
    <div className="lock-all-container bg-battleship-grey-light w-100 mtop-60">
      <h2>Lock Individual Modules</h2>
      <p>You will be able to:</p>
      <div className="detail-section w-100 d-flex">
        <div className="left-sect">
          <ul>
            <li>
              <p>
                Select the modules (Sales, Purchases, Accounts) individually you
                wish to lock.
              </p>
            </li>
            <li>
              <p>Select different lock dates for each modules</p>
            </li>
          </ul>
        </div>
        <div className="btn-sect">
          <Link
            to={
              Object.values(props.transactionDetails.lockings).every(
                (module) => module.is_lock_all === false
              )
                ? "/transaction-locking"
                : "#"
            }
            className="lock-btn-link"
            onClick={() => {
              if (
                Object.values(props.transactionDetails.lockings).every(
                  (module) => module.is_lock_all === true
                )
              ) {
                props.openModal(
                  "unlock_all_transaction",
                  "Attention!",
                  props.transactionDetails.lockings.map(
                    (module) => module.module_id
                  )
                );
              }
            }}
          >
            <img src={LockIconBlue} alt="lock icon" width={14} height={17} />
            Lock Individual Modules
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LockAllTransactionFooter;
