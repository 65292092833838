import { useFormik } from "formik";
import { PaymentTermValue } from "../../../../../types";
import "./paymentTermsForm.css";
import React, { useEffect, useState } from "react";
import { PaymentTermsValidation } from "./paymentTermsFormValidation";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  createPaymentTerm,
  currentUserSelector,
  paymentTermsList,
  updatePaymentTerm,
} from "../../../commonSlice";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../helpers/toastHelper";
import { EditBlueButton } from "../../button/EditBlueButton";
import { GreenTickSaveButton } from "../../button/GreenTickSaveButton";
import { RedCrossCancelButton } from "../../button/RedCrossCancelButton";
type Props = {
  values: PaymentTermValue;
  id?: number;
  is_mutable?: boolean;
  removeField?: () => void;
};
export const PaymentTermsForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isEdit, setEdit] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const numberOnlyRegex = /^[0-9]*$/;
  const formik = useFormik({
    initialValues: {
      ...props.values,
    },
    enableReinitialize: true,
    validationSchema: PaymentTermsValidation,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      setSubmitting(true);
      const paymentTermResponseAction = !props.id
        ? await dispatch(
            createPaymentTerm({
              values: values,
              orgId: currentUserInfo.organization_id,
            })
          )
        : await dispatch(
            updatePaymentTerm({
              editPaymentTermId: props.id ? props.id : 0,
              paymentTermInputs: values,
              orgId: currentUserInfo.organization_id,
            })
          );
      const response = paymentTermResponseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setSubmitting(false);
        SuccessToaster(
          props.id
            ? "Payment term updated successfully!"
            : "Payment term created successfully!",
          "payment-term-succes"
        );
        await dispatch(paymentTermsList(currentUserInfo.organization_id));
        setEdit(false);
        props.removeField && props.removeField();
      } else {
        setSubmitting(false);
      }
    },
    onReset: () => {
      setEdit(false);
    },
  });
  useEffect(() => {
    const throwError = () => {
      if (formik.errors) {
        formik.errors.term_name && ErrorToaster(formik.errors.term_name);
        formik.errors.term_days && ErrorToaster(formik.errors.term_days);
      }
    };
    throwError();
  }, [formik.errors]);

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleTermDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    numberOnlyRegex.test(e.target.value)
      ? formik.setFieldValue("term_days", e.target.value)
      : formik.setFieldValue("term_days", "");
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      className="edit-form-wrap edit-payment-term-form-wrap"
    >
      <div>
        {
          <div className="row g-0">
            <div className="col">
              {isEdit || props.removeField ? (
                <input
                  type="text"
                  id={`term-00`}
                  className="form-control terms"
                  name="term_name"
                  maxLength={25}
                  value={formik.values.term_name}
                  onChange={formik.handleChange}
                />
              ) : (
                <span className="tagName terms">{formik.values.term_name}</span>
              )}
            </div>
            <div className="col">
              {isEdit || props.removeField ? (
                <input
                  type="text"
                  id="day-1"
                  value={formik.values.term_days}
                  className="form-control days"
                  name="term_days"
                  onChange={handleTermDaysChange}
                />
              ) : (
                <span className="tagName term-days">
                  {formik.values.term_days}
                </span>
              )}
            </div>
            <div className="col">
              <div
                className="button-wrapper action-buttons right-align"
                id={"term-form-button-"}
              >
                <>
                  <GreenTickSaveButton
                    onClick={() => {}}
                    type="submit"
                    isHidden={isEdit || props.removeField ? false : true}
                    className="no-appearance"
                    style={
                      isSubmitting
                        ? {
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: "0.5",
                          }
                        : {}
                    }
                  />
                  <RedCrossCancelButton
                    type="reset"
                    className="no-appearance"
                    isHidden={isEdit || props.removeField ? false : true}
                    onClick={() => {
                      props.id
                        ? handleEditClick()
                        : props.removeField && props.removeField();
                    }}
                    style={
                      isSubmitting
                        ? {
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: "0.5",
                          }
                        : {}
                    }
                  />
                </>
                {!isEdit && !props.removeField && (
                  <EditBlueButton
                    className=""
                    onClick={handleEditClick}
                    isHidden={isEdit ? true : false}
                    style={
                      !props.is_mutable
                        ? {
                            pointerEvents: "none",
                            cursor: "default",
                            opacity: "0.5",
                          }
                        : {}
                    }
                    type="button"
                  />
                )}
              </div>
            </div>
          </div>
        }
      </div>
    </form>
  );
};
