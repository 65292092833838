import { ErrorToaster } from "../../../../../../helpers/toastHelper";
import { InvoiceDetailValues } from "../../../../../../types";
import { AlertModal } from "../../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../../common/components/alertModal/alertModalContext";
import AlertModalPopupContent from "../../../../../common/components/alertModalPopup/AlertModalPopupContent";
import { PlusIcon } from "../../../../../common/components/customSvgIcons/plusIcon";
import { colors } from "../../../../../constants/colors";
import { message } from "../../../../../constants/messages";
import { CreateCreditNoteAlert } from "./createCreditNoteAlert";

export const CreateCreditNoteAlertModal = (props: {
  creditNoteData: InvoiceDetailValues;
  isCreationPossible: boolean;
  isVoid?: boolean;
  invoiceId:number
}) => {
  const handleDisabledAction = () => {
    ErrorToaster(message().invoice.createCNVoidInvoice);
  };

  return (
    <AlertModal
      modalTitle={
        props.isCreationPossible
          ? "Create credit note!"
          : "Cannot create credit note!"
      }
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return props.creditNoteData.entity_tag?.includes(
              "Deferred Income"
            ) ? (
              <AlertModalPopupContent
                handleClose={onCloseModal}
                message={
                  <div>
                    Credit note for this{" "}
                    <span>{props.creditNoteData.invoice_number}</span> cannot be
                    generated. Please get in touch with our support team for
                    further guidance{" "}
                    <a href="https://finbooks.app/#faq" target="_blank">
                      https://finbooks.app/#faq
                    </a>
                  </div>
                }
                isSubmitEnabled={true}
              />
            ) : (
              <CreateCreditNoteAlert
                handleClose={onCloseModal}
                creditNoteData={props.creditNoteData}
                  isCreationPossible={props.isCreationPossible}
                  invoiceId={props.invoiceId}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span>
          <PlusIcon color={colors.charcoalGrey} />
          Create Credit Note
        </span>
      }
      buttonClass="dropdown-item no-appearance w-100"
      wrapperClass="create-credit-note-invoice-modal"
      isButtonDisabled={props.isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
};
