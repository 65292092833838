import { useAppDispatch } from "../../../../../app/hooks";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import { CustomFieldForm } from "../customFieldForm/customFieldForm";
import { EditListDataImg } from "../../../../../assets/images";
import { setCustomFieldValues } from "../../../../../appSlice";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { GreenPlusIcon } from "../../../../common/components/customSvgIcons/greenPlusIcon";
import "./customFieldFormModal.css";
import { EditIcon } from "../../../../common/components/customSvgIcons/editIcon";
import { colors } from "../../../../constants/colors";

type Props = {
  module: string;
  action?: string;
  onOpenFunction?: () => void;
};
export const CustomFieldFormModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const ButtonContent = () => {
    return props.action !== "edit" ? (
      <span className="add-new-item green-text ">
        <GreenPlusIcon />
        Add New
      </span>
    ) : (
      <span
        className="dropdown-item d-flex align-items-center "
        style={{ textDecoration: "none" }}
      >
        <EditIcon color={colors.battleshipGrey} margin={"0 8px 0 0"} />
        <span>Edit</span>
      </span>
    );
  };

  return (
    <div id="customFieldModal">
      <SideModal
        modalContent={
          <ModalContext.Consumer>
            {(context) => {
              const { onCloseModal } = context;

              return (
                <CustomFieldForm
                  action={props.action}
                  module={props.module}
                  handleClose={onCloseModal}
                />
              );
            }}
          </ModalContext.Consumer>
        }
        buttonContent={<ButtonContent />}
        buttonClass={`custom-field-modal-btn ${
          props.action === "edit" ? "w-100 p-0" : ""
        }`}
        onOpenFunction={() => {
          props.action !== "edit" &&
            dispatch(
              setCustomFieldValues({
                field_name: "",
                mandatory_field: true,
                show_in_pdf: true,
              })
            );
          props.onOpenFunction && props.onOpenFunction();
        }}
        modalTitle={
          props.action === "edit"
            ? `Edit Custom Field - ${props.module}`
            : `Add New Field - ${props.module}`
        }
        wrapperClass="w-100"
      />
    </div>
  );
};
