import React, { useEffect, useState } from "react";
import Popper from "@mui/material/Popper";
import Item from "../../../home/subscription/components/subscriptionForm/Item";
import "./defaultDetails.css";
import { RangePriceValue } from "../../../home/subscription/subscriptionType";
import ItemView from "../../../home/subscription/components/subscriptionForm/ItemView";

type Props = {
  id: any;
  anchorEl: any;
  open: any;
  value: any;
  onPopoverClose: () => void;
};

const ListCellTablePopoverSubscriptionView: React.FC<Props> = (
  props: Props
) => {
  return (
    <Popper
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      placement="top-start"
      modifiers={[
        {
          name: "flip",
          enabled: true,
          options: {
            rootBoundary: "anchorEl",
          },
        },
      ]}
      className={`pricing-item-col-popper range_popover subscription-price-popover`}
    >
      <div className="card-body">
        <div
          id="invoice-table-wrapper"
          className="list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer no-data pricing-table"
        >
          <div className="row">
            <div className="col-md-12"></div>
            <div className="pricing-form">
              <div className="row custom-row">
                <div className="item-container item-cont-width  subscription-pricing-form">
                  {/* {props.value.map(
                    (
                      item: {
                        item_name: any;
                        range_price: any;
                      },
                      key: any
                    ) => ( */}
                  <ItemView
                    itemName={props.value.item_name}
                    range_price={props.value.range_price}
                    onClose={() => props.onPopoverClose()}
                  />
                  {/* ) */}
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popper>
  );
};

export default ListCellTablePopoverSubscriptionView;
