import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";

/**
 * API call for updating currencies
 */
export async function updateCurrencies(data: {
  values: {
    name: string | null;
    symbol: string | null;
    format: string | null;
  };
  id: number;
  orgId: number;
}) {
  const result = await put(
    Api.currency + "edit/" + data.id + "?current_organization=" + data.orgId,
    data.values
  );
  return result;
}

/**
 * API call for getting prefixes
 */
export async function getCurrency(data: { orgId: number }) {
  const result = await get(
    Api.currency + "list?current_organization=" + data.orgId
  );
  return result;
}
