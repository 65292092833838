import { SvgIconProp } from "../../../../types";

export const FieldClearCrossIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "16px"}
      viewBox="0 0 16 16"
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h16v16H0z" />
        <path
          d="M8 14.667A6.667 6.667 0 1 1 8 1.334a6.667 6.667 0 0 1 0 13.333zm0-7.61L6.115 5.171l-.944.944L7.057 8 5.171 9.885l.944.944L8 8.943l1.885 1.886.944-.944L8.943 8l1.886-1.885-.944-.944L8 7.057z"
          fill={props.color}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
