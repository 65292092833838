import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import { SaveButton } from "../button";
import { SolidRedButton } from "../button/SolidRedButton";
import { Container } from "../htmlTags/Container";
import { Span } from "../htmlTags/Span";
import { VoidConfirmationProps } from "./types";
import { useMarkVoidSubmission } from "./useMarkVoidAPISelection";
import "./VoidConfirmation.css";

export const VoidConfirmationBody = (props: VoidConfirmationProps) => {
  const { submitMarkAsVoid } = useMarkVoidSubmission();
  const submissionHandler = async () => {
    await submitMarkAsVoid({
      module: props.module as any,
      transactionId: props.transactionId,
      transactionNumber: props.transactionNumber,
      onClose: props.onCancel,
    });
    props.onSubmit && (await props.onSubmit());
  };
  return (
    <Container className="void-confirmation-wrapper">
      <Container className="void-confirmation-content">
        <Span>
          Are you sure you want to void the {props.module.toLowerCase()} -{" "}
          <b>{joinTransactionNumber(props.transactionNumber)}</b> ?
        </Span>
      </Container>
      <Container className="void-confirmation-btn-group">
        <SaveButton
          type="button"
          buttonContent="Cancel"
          buttonClassName="ms-0"
          onClick={props.onCancel}
          isDisabled={false}
        />
        <SolidRedButton
          type="button"
          buttonContent="Void"
          onClick={submissionHandler}
          isDisabled={false}
        />
      </Container>
    </Container>
  );
};
