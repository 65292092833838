import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
type Props = {
  valuesArray: string[];
  displayElementCount: number;
};

const ListCellPopover: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.valuesArray
        .slice(0, props.displayElementCount)
        .map((item) => item)
        .join(", ")}
      <Popup
        trigger={
          <button className="more-link-btn no-appearance">
            {props.valuesArray.length - props.displayElementCount > 0
              ? " + " +
                (props.valuesArray.length - props.displayElementCount) +
                " more"
              : ""}
          </button>
        }
        position="bottom center"
        className="suspended-popover"
      >
        <div className="popover-body popover-email">
          {props.valuesArray.map((item, index) => {
            if (index >= props.displayElementCount)
              return <p key={index}>{item}</p>;
          })}
        </div>
      </Popup>
    </>
  );
};

export default ListCellPopover;
