import { ObjectType } from "../../../../types";

export const majorAttributeSetter = (
  module: string,
  attributes: ObjectType
): ObjectType => {
  const moduleMappings: ObjectType = {
    customers: { "Customer Name": "customer_display_name" },
    invoices: { "Invoice Number": "invoice_number" },
    credit_notes: { "Credit Note Number": "credit_note_number" },
    payment_received: { "Payment Number": "payment_number" },
    vendors: { "Vendor Name": "vendor_display_name" },
    bills: { "Bill Number": "bill_number" },
    debit_notes: { "Debit Note Number": "debit_note_number" },
    payment_made: { "Payment Number": "payment_number" },
    journal_voucher: { "Journal Voucher Number": "journal_voucher_number" },
    chart_of_accounts: { "Account Name": "account_name" },
  };

  if (typeof module !== "string") {
    throw new Error("Module must be a string");
  }

  if (!moduleMappings.hasOwnProperty(module)) {
    throw new Error(`Invalid module: ${module}`);
  }

  const attributeKey = Object.keys(moduleMappings[module])[0];
  const attributeValue = attributes[moduleMappings[module][attributeKey]];

  return { [attributeKey]: attributeValue };
};
