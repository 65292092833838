import { ObjectType } from "../../../../../../types";
import {
  EXEMPTION_TYPES,
  NON_TAXABLE,
  TAXABLE,
} from "../../gstSelect/constants";

export const getDefaultNonTaxableGst = (gstTaxList: any) => {
  let default_gst = gstTaxList.filter((gstTax: any) => {
    return (
      gstTax.name === "Non-Taxable" &&
      gstTax.tax_type === "NONE" &&
      gstTax.rate === 0
    );
  });
  return default_gst;
};

export const getGstTaxItem = (tax: any) => {
  if (!tax) {
    return null;
  }
  let taxes = tax?.taxes;
  let gst_tax_item: ObjectType[] = [];
  if (taxes) {
    gst_tax_item = taxes.map((singleTax: ObjectType) => {
      return {
        tax_id: singleTax.id,
        tax_percentage: singleTax.rate,
        tax_type: singleTax?.tax_type,
      };
    });
  }
  const taxObj = {
    tax_id: tax.id,
    tax_percentage: tax.rate,
    is_group: tax.tax_group,
    tax_type: tax?.tax_type,
    gst_tax_item: gst_tax_item,
  };
  return taxObj;
};

export const getDefaultSezGst_forSales = (gstTaxList: ObjectType[]) => {
  let default_gst = gstTaxList.filter((gstTax: any) => {
    return (
      gstTax.name === "IGST0" && gstTax.tax_type === "IGST" && gstTax.rate === 0
    );
  });
  return default_gst;
};

export const getDefaltGstItems = (
  values: any,
  gstTaxList: any,
  gstRegistrationTypes: any,
  indexOfItem?: number
) => {
  const gstTaxForItems = values.items?.map((item: any) => item.gstTaxOfItems);
  const items = values.items;
  let itemsCount = values.items.length;
  if (indexOfItem) {
    if (
      gstTaxList.length &&
      (values.customer_details.gst_registration_type ===
        gstRegistrationTypes.sez ||
        values.customer_details.gst_registration_type ===
          gstRegistrationTypes.sezDeveloper ||
        values.customer_details.gst_registration_type ===
          gstRegistrationTypes.overseas) &&
      values.customer_details.customer_exemption_type !== NON_TAXABLE
    ) {
      let taxObj = getGstTaxItem(getDefaultSezGst_forSales(gstTaxList)[0]);
      items[indexOfItem] = {
        ...items[indexOfItem],
        gst_tax: taxObj ? taxObj : null,
        item_exemption_type: TAXABLE,
      };
      return items;
    } else if (
      gstTaxList.length &&
      values.customer_details.gst_registration_type !==
        gstRegistrationTypes.sez &&
      values.customer_details.gst_registration_type !==
        gstRegistrationTypes.sezDeveloper &&
      values.customer_details.gst_registration_type !==
        gstRegistrationTypes.overseas
    ) {
      let taxObj = null;
      if (
        values.items[indexOfItem].item_exemption_type &&
        values.items[indexOfItem].item_exemption_type !== TAXABLE
      ) {
        const taxPreference = EXEMPTION_TYPES?.find(
          (type) => type.value === values.items[indexOfItem].item_exemption_type
        )?.key;
        const gst_tax = gstTaxList.filter((gstTax: any) => {
          return (
            gstTax.name === taxPreference &&
            gstTax.tax_type === "NONE" &&
            gstTax.rate === 0
          );
        });
        taxObj = getGstTaxItem(gst_tax[0]);
        items[indexOfItem] = {
          ...items[indexOfItem],
          gst_tax: taxObj ? taxObj : null,
          item_exemption_type: values.items[indexOfItem]?.item_exemption_type,
          item_exemption_id: values.items[indexOfItem]?.item_exemption_id,
        };
        return items;
      } else {
        const interStateGst = Array.isArray(gstTaxList)
          ? gstTaxList?.filter((gstTax: any) => {
              if (gstTaxForItems[indexOfItem]) {
                if (gstTax.id === gstTaxForItems[indexOfItem].inter_state_id) {
                  return gstTax;
                }
              }
            })
          : [];
        const intraStateGst = Array.isArray(gstTaxList)
          ? gstTaxList?.filter((gstTax: any) => {
              if (gstTaxForItems[indexOfItem]) {
                if (gstTax.id === gstTaxForItems[indexOfItem].intra_state_id) {
                  return gstTax;
                }
              }
            })
          : [];
        if (values.customer_details.customer_exemption_type !== "NON_TAXABLE") {
          if (values.state_type === "Inter State" && interStateGst.length > 0) {
            taxObj = getGstTaxItem(interStateGst[0]);
            items[indexOfItem] = {
              ...items[indexOfItem],
              gst_tax: taxObj ? taxObj : null,
              item_exemption_type: "",
              item_exemption_id: 0,
            };
            return items;
          } else if (
            values.state_type === "Inter State" &&
            values.settings.default_inter_state_id
          ) {
            let defaultTax = gstTaxList?.filter(
              (tax: any) => tax.id === values.settings.default_inter_state_id
            );
            if (defaultTax.length > 0) {
              taxObj = getGstTaxItem(defaultTax[0]);
              items[indexOfItem] = {
                ...items[indexOfItem],
                gst_tax: taxObj ? taxObj : null,
                item_exemption_type: "",
                item_exemption_id: 0,
              };
              return items;
            }
          }
          if (values.state_type === "Intra State" && intraStateGst.length > 0) {
            taxObj = getGstTaxItem(intraStateGst[0]);
            items[indexOfItem] = {
              ...items[indexOfItem],
              gst_tax: taxObj ? taxObj : null,
              item_exemption_type: "",
              item_exemption_id: 0,
            };
            return items;
          } else if (
            values.state_type === "Intra State" &&
            values.settings.default_intra_state_id
          ) {
            let defaultTax = gstTaxList?.filter(
              (tax: any) => tax.id === values.settings.default_intra_state_id
            );
            if (defaultTax.length > 0) {
              taxObj = getGstTaxItem(defaultTax[0]);
              items[indexOfItem] = {
                ...items[indexOfItem],
                gst_tax: taxObj ? taxObj : null,
                item_exemption_type: "",
                item_exemption_id: 0,
              };
              return items;
            }
          }
        }
      }
    }
  } else {
    if (
      gstTaxList.length &&
      (values.customer_details.gst_registration_type ===
        gstRegistrationTypes.sez ||
        values.customer_details.gst_registration_type ===
          gstRegistrationTypes.sezDeveloper ||
        values.customer_details.gst_registration_type ===
          gstRegistrationTypes.overseas) &&
      values.customer_details.customer_exemption_type !== NON_TAXABLE
    ) {
      let taxObj = getGstTaxItem(getDefaultSezGst_forSales(gstTaxList)[0]);
      for (let index = 0; index < itemsCount; index++) {
        items[index] = {
          ...items[index],
          gst_tax: taxObj ? taxObj : null,
          item_exemption_type: TAXABLE,
        };
      }
      return items;
    } else if (
      gstTaxList.length &&
      values.customer_details.gst_registration_type !==
        gstRegistrationTypes.sez &&
      values.customer_details.gst_registration_type !==
        gstRegistrationTypes.sezDeveloper &&
      values.customer_details.gst_registration_type !==
        gstRegistrationTypes.overseas
    ) {
      let taxObj = null;
      for (let index = 0; index < itemsCount; index++) {
        if (
          values.items[index].item_exemption_type &&
          values.items[index].item_exemption_type !== TAXABLE
        ) {
          const taxPreference = EXEMPTION_TYPES?.find(
            (type) => type.value === values.items[index].item_exemption_type
          )?.key;
          const gst_tax = gstTaxList.filter((gstTax: any) => {
            return (
              gstTax.name === taxPreference &&
              gstTax.tax_type === "NONE" &&
              gstTax.rate === 0
            );
          });
          taxObj = getGstTaxItem(gst_tax[0]);
          items[index] = {
            ...items[index],
            gst_tax: taxObj ? taxObj : null,
            item_exemption_type: values.items[index]?.item_exemption_type,
            item_exemption_id: values.items[index]?.item_exemption_id,
          };
          return items;
        } else {
          const interStateGst = Array.isArray(gstTaxList)
            ? gstTaxList?.filter((gstTax: any) => {
                if (gstTaxForItems[index]) {
                  if (gstTax.id === gstTaxForItems[index].inter_state_id) {
                    return gstTax;
                  }
                }
              })
            : [];
          const intraStateGst = Array.isArray(gstTaxList)
            ? gstTaxList?.filter((gstTax: any) => {
                if (gstTaxForItems[index]) {
                  if (gstTax.id === gstTaxForItems[index].intra_state_id) {
                    return gstTax;
                  }
                }
              })
            : [];
          if (
            values.customer_details.customer_exemption_type !== "NON_TAXABLE"
          ) {
            if (
              values.state_type === "Inter State" &&
              interStateGst.length > 0
            ) {
              taxObj = getGstTaxItem(interStateGst[0]);
              items[index] = {
                ...items[index],
                gst_tax: taxObj ? taxObj : null,
                item_exemption_type: "",
                item_exemption_id: 0,
              };
              return items;
            } else if (
              values.state_type === "Inter State" &&
              values.settings.default_inter_state_id
            ) {
              let defaultTax = gstTaxList?.filter(
                (tax: any) => tax.id === values.settings.default_inter_state_id
              );
              if (defaultTax.length > 0) {
                taxObj = getGstTaxItem(defaultTax[0]);
                items[index] = {
                  ...items[index],
                  gst_tax: taxObj ? taxObj : null,
                  item_exemption_type: "",
                  item_exemption_id: 0,
                };
                return items;
              }
            }
            if (
              values.state_type === "Intra State" &&
              intraStateGst.length > 0
            ) {
              taxObj = getGstTaxItem(intraStateGst[0]);
              items[index] = {
                ...items[index],
                gst_tax: taxObj ? taxObj : null,
                item_exemption_type: "",
                item_exemption_id: 0,
              };
              return items;
            } else if (
              values.state_type === "Intra State" &&
              values.settings.default_intra_state_id
            ) {
              let defaultTax = gstTaxList?.filter(
                (tax: any) => tax.id === values.settings.default_intra_state_id
              );
              if (defaultTax.length > 0) {
                taxObj = getGstTaxItem(defaultTax[0]);
                items[index] = {
                  ...items[index],
                  gst_tax: taxObj ? taxObj : null,
                  item_exemption_type: "",
                  item_exemption_id: 0,
                };
                return items;
              }
            }
          }
        }
      }
    }
  }
  return items;
};

export const getDeletedItemGst = (deleteItemGst: ObjectType[], item: any) => {
  let gst_tax = item?.gst_tax;
  if (gst_tax?.is_group) {
    let prev_gst_tax_item = gst_tax?.gst_tax_item;
    if (prev_gst_tax_item !== null && prev_gst_tax_item.length > 0) {
      // looping gst_tax_item in the case of tax group
      prev_gst_tax_item.forEach((single_gst_tax_item: ObjectType) => {
        if (single_gst_tax_item.id) {
          let filteredDeleteGstItems = deleteItemGst?.filter(
            (gstItem: any) =>
              gstItem.itemId === item.id &&
              gstItem.id === single_gst_tax_item.id
          );
          if (filteredDeleteGstItems?.length === 0) {
            deleteItemGst.push({
              itemId: item.id,
              id: single_gst_tax_item.id,
            });
          }
        }
      });
      return deleteItemGst;
    }
  } else if (!gst_tax.is_group && gst_tax.id) {
    let filteredDeleteGstItems = deleteItemGst?.filter(
      (gstItem: any) => gstItem.itemId === item.id && gstItem.id === item.id
    );
    if (filteredDeleteGstItems.length === 0) {
      deleteItemGst?.push({ itemId: item.id, id: gst_tax.id });
    }
    return deleteItemGst;
  }
};
