import { Link } from "react-router-dom";
import { convertToPascalCase } from "../../../../helpers/convertToPascalHelper";
import { ObjectType } from "../../../../types";
import { BreadCrumbs } from "../../../common/components/breadCrumbs/BreadCrumbs";
import { MajorSearchAttribute } from "../../../common/components/majorSearchAttribute/MajorSearchAttribute";
import HighlightedText from "../../../common/components/textHighlighter/TextHighlighter";
import Box from "@mui/material/Box";
import { boxStyle } from "./searchBoxCustomStyles";
import { redirectLinkSetter } from "../helperFunctions/redirectLinkSetter";
import { DateFormatHandler } from "../../../../helpers/helper";

type Props = {
  option: ObjectType;
  props: any;
  searchValue: string;
  handleClick: () => void;
};
export const SearchResultDropdown = ({ option, props, searchValue }: Props) => {
  return (
    <Box
      component="li"
      {...props}
      className="global-search-option"
      sx={{ ...boxStyle }}
    >
      <Link
        to={redirectLinkSetter(
          option?.module,
          option?.resultAttribute?.id,
          option?.resultAttribute["Status"]
        )}
        className="search-item"
        onClick={props.handleClick}
      >
        <BreadCrumbs crumbsArray={option?.breadCrumbs} fontSize="13px" />

        {option?.matchedField["field_name"] ? (
          <div className="search-data">
            <span className="label query-match-label">
              {`${convertToPascalCase(
                option?.matchedField["field_name"] || ""
              )}:  `}
            </span>{" "}
            <HighlightedText
              text={
                option?.matchedField["field_name"].includes("date") ||
                option?.matchedField["field_name"].split("_").includes("date")
                  ? DateFormatHandler(option?.matchedField["value"])
                  : Array.isArray(option?.matchedField["value"])
                  ? option?.matchedField["value"].filter((value, index) =>
                      value
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.toString().toLowerCase())
                    )[0]
                  : option?.matchedField["value"]
              }
              query={searchValue}
              textClassName="search-result"
              maxLength={30}
            />
          </div>
        ) : (
          <div className="search-data">
            <span className="label">
              {`${Object.keys(option?.majorAttribute)[0]}:  `}
            </span>
            <span>{Object.values(option?.majorAttribute)[0] as string}</span>
          </div>
        )}
        <MajorSearchAttribute attribute={option?.majorAttribute} />
      </Link>
    </Box>
  );
};
