import { FormikProps } from "formik";
import { isAmountEditable } from "../../../../../../../helpers/prHelpers/amountEditHelper";
import { fieldVisibility } from "../../../../../../../helpers/prHelpers/fieldVisibilityHelper";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../../../common/commonSlice";
import { ConsumerPaymentEditPopper } from "../../../../../../common/components/consumerPaymentEditPopper/consumerPaymentEditPopper";
import BankCharges from "../../../../../../common/components/paymentFormInputs/BankCharges";
import { CurrencyList } from "../../imports";
import { PaymentAmountField } from "./paymentAmountField";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import { zeroDisplayFormat } from "../../../../../../../helpers/numberFormatHelper";
import { useAppSelector } from "../../../../../../../app/hooks";
import { useParams } from "react-router-dom";
import { usePr } from "../../usePr";

type Props = {
  formik: FormikProps<any>;
  taxObject: React.ReactNode;
};
export const PaymentAmountDetails = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const {
    setUserExchangeData,
    setBankExchangeData,
    userExchangeRateSubmitHandler,
    bankExchangeRateSubmitHandler,
  } = usePr({
    formik: props.formik,
  });
  const { editId } = useParams();

  const isEditable = isAmountEditable({ formik: props.formik });
  const isFieldVisible = fieldVisibility({ formik: props.formik });
  const handleBankChange = (e: any) => {
    //handleFunction
  };
  const getReverseConversionValue = (value: CurrencyList) => {
    props.formik.setValues({
      ...props.formik.values,
      by_user: true,
      conversion_id: value.id,
      conversion_rate: value.rate,
      is_date_changed: false,
    });
  };
  const handleOnBlur = (name: string, amount: number, index: number) => {
    let currencyCode = currentUserInfo.currency_code;
    switch (name) {
      case "amount_received":
        currencyCode = props.formik.values.currency_code;
        break;
      case "amount_received_bank_currency":
        currencyCode = props.formik.values.bank_currency_code;
        break;
      case "bank_charges_bank_currency":
        currencyCode = props.formik.values.bank_currency_code;
        break;
      default:
        break;
    }
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    const roundedAmount = getRoundOffAmount(amount, decimals);
    props.formik.setFieldValue(name, roundedAmount);
    if (name === "bank_charges_amount") {
      props.formik.setFieldValue(
        "bank_charges_amount",
        getRoundOffAmount(props.formik.values.bank_charges_amount, decimals)
      );
    }
  };
  const getBankChargeConvertedAmount = () => {
    let bankChargeConvertedAmount =
      props.formik.values.currency_id === props.formik.values.bank_currency_id
        ? 0
        : props.formik.values.bank_conversion_rate *
          props.formik.values.bank_charges_amount;
    const decimals = decimalPlaceOfCurrency(
      props.formik.values.bank_currency_code,
      orgCurrencyList
    );
    const roundedAmount = getRoundOffAmount(
      bankChargeConvertedAmount,
      decimals
    );
    return roundedAmount.toString();
  };

  return (
    <div
      className={`row ${
        !props.formik.values.customer_id ? "disabled-item" : ""
      }`}
    >
      {
        <PaymentAmountField
          label="Amount Received"
          subLabel={isFieldVisible ? "Customer Currency" : ""}
          formik={props.formik}
          name="amount_received"
          id="amount_received"
          prefix={
            props.formik.values.currency_code || currentUserInfo.currency_code
          }
          value={props.formik.values.amount_received}
          error={
            (props.formik.touched.amount_received &&
              props.formik.errors.amount_received?.toString()) ||
            ""
          }
          handleBlur={handleOnBlur}
          isDisabled={false}
          byUser={props.formik.values.by_user}
          conversionDate={props.formik.values.payment_date}
          newConversionDate={props.formik.values.payment_date}
          currencyCode={props.formik.values.currency_code}
          currencyId={props.formik.values.currency_id}
          conversionId={props.formik.values.conversion_id}
          conversionCurrencyId={currentUserInfo.organization_currency}
          toCurrencyId={currentUserInfo.organization_currency}
          editId={editId || ""}
          setExchangeData={setUserExchangeData}
          isCurrencyChanged={props.formik.values.is_currency_changed}
          isDateChanged={props.formik.values.is_date_changed}
          conversionRate={props.formik.values.conversion_rate}
          conversionTimestamp={props.formik.values.conversion_timestamp}
          toCurrencyCode={currentUserInfo.currency_code}
          isExchangeRateHidden={true}
          isReverseConversionRequired={false}
          isExchangeEditable={true}
          exchangeRateSubmitHandler={userExchangeRateSubmitHandler}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.formik.values.currency_code
                ? props.formik.values.currency_code
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          exchangeRateMaxLength={
            Number(currentUserInfo.organization_currency) ===
            Number(props.formik.values.bank_currency_id)
              ? 20
              : 6
          }
        />
      }
      {isFieldVisible && (
        <PaymentAmountField
          label="Amount Received"
          subLabel="Bank Currency"
          formik={props.formik}
          name="amount_received_bank_currency"
          id="amount_received_bank_currency"
          prefix={
            props.formik.values.bank_currency_code ||
            currentUserInfo.currency_code
          }
          value={props.formik.values.amount_received_bank_currency}
          error={
            (props.formik.touched.amount_received_bank_currency &&
              props.formik.errors.amount_received_bank_currency?.toString()) ||
            ""
          }
          handleBlur={handleOnBlur}
          isDisabled={isFieldVisible || !props.formik.values.customer_id}
          conversionDate={props.formik.values.payment_date}
          newConversionDate={props.formik.values.payment_date}
          currencyCode={props.formik.values.currency_code}
          currencyId={props.formik.values.currency_id}
          conversionId={props.formik.values.bank_conversion_id}
          conversionCurrencyId={props.formik.values.currency_id}
          toCurrencyId={props.formik.values.bank_currency_id}
          editId={editId || ""}
          setExchangeData={setBankExchangeData}
          isCurrencyChanged={props.formik.values.is_currency_changed}
          isDateChanged={props.formik.values.is_date_changed}
          byUser={props.formik.values.bank_by_user}
          conversionRate={props.formik.values.bank_conversion_rate}
          conversionTimestamp={props.formik.values.bank_conversion_timestamp}
          toCurrencyCode={props.formik.values.bank_currency_code}
          isExchangeRateHidden={
            currentUserInfo.organization_currency ===
            props.formik.values.bank_currency_id
              ? true
              : false
          }
          isExchangeEditable={
            currentUserInfo.organization_currency ===
            props.formik.values.bank_currency_id
              ? false
              : true
          }
          isReverseConversionRequired={false}
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.formik.values.bank_currency_code
                ? props.formik.values.bank_currency_code
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          )}
          // getReverseConversionValue={getReverseConversionValue}
          exchangeRateSubmitHandler={bankExchangeRateSubmitHandler}
          endAdornment={
            isEditable ? (
              <ConsumerPaymentEditPopper
                label="Amount Received"
                subLabel={
                  props.formik.values.currency_id !==
                  props.formik.values.bank_currency_id
                    ? "Bank Currency"
                    : ""
                }
                formik={props.formik}
                name="amount_received"
                id="amount_received"
                prefix={
                  props.formik.values.bank_currency_code ||
                  currentUserInfo.currency_code
                }
                value={props.formik.values.amount_received_bank_currency}
                error={
                  (props.formik.touched.amount_received_bank_currency &&
                    props.formik.errors.amount_received_bank_currency?.toString()) ||
                  ""
                }
                handleBlur={handleOnBlur}
                isDisabled={false}
                conversionDate={props.formik.values.payment_date}
                currencyCode={props.formik.values.bank_currency_code}
                conversionCurrencyCode={props.formik.values.currency_code}
                conversionRate={props.formik.values.bank_conversion_rate}
                editId={editId || ""}
                isButtonDisabled={false}
                placeholder={zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    props.formik.values.currency_code
                      ? props.formik.values.currency_code
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
              />
            ) : (
              <></>
            )
          }
          exchangeRateMaxLength={20}
        />
      )}

      <div className="col-12 col-lg-4 mw-325">
        <BankCharges
          bankChargesAccountId={props.formik.values.bank_charges_account_id}
          handleBankCharge={handleBankChange}
          currencyCode={
            props.formik.values.currency_code || currentUserInfo.currency_code
          }
          handleChange={props.formik.handleChange}
          handleItemOnBlur={handleOnBlur}
          bankChargesAmount={props.formik.values.bank_charges_amount}
          formErrors={
            props.formik.touched.bank_charges_amount &&
            props.formik.touched.bank_charges_account_id &&
            props.formik.errors
          }
          classWidth="w-150"
          disabled={!props.formik.values.bank_currency_id ? true : false}
          conversionCurrencyCode={props.formik.values.bank_currency_code}
          bankChargeConvertedAmount={getBankChargeConvertedAmount()}
          isBankCurrency={true}
          formik={props.formik}
        />
      </div>
      {props.taxObject}
    </div>
  );
};
