import { colors } from "../../constants/colors";

export const RegionSelectCustomStyle = {
  container: (provided: any, state: any) => ({
    ...provided,
    clear: "both",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    paddingRight: "4px",
    height: "40px",
    boxShadow: "none",
    border: state.isFocused
      ? `1px solid ${colors.ceruleanBlue}`
      : `1px solid ${colors.newWhisper}`,
    "&:hover": {
      border: state.isFocused
        ? `1px solid ${colors.ceruleanBlue}`
        : `1px solid ${colors.newWhisper}`,
    },
    "&:focus": {
      border: `1px solid ${colors.ceruleanBlue}`,
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "normal",
    color:
      state.isFocused || state.isSelected
        ? colors.ceruleanBlue
        : colors.charcoalGrey,
    backgroundColor: colors.white,
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.ceruleanBlue,
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.charcoalGrey,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: colors.white,
    border: `1px solid ${colors.newWhisper}`,
    borderRadius: "4px",
    boxShadow: `0 2px 6px 0 rgb(${colors.blackRGB} / 1%)`,
    zIndex: "10",
    height: "auto",
    margin: "4px 0",
    minWidth: "30px",
    padding: "5px 0",
    textAlign: "left",
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    padding: "0px 5px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.charcoalGrey,
    backgroundColor: colors.white,
    maxHeight: "150px",
    overflow: "auto",
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.ceruleanBlue,
    },
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "0.71",
    color: colors.charcoalGrey,
    border: "0",
    "&:focus": {
      border: "0",
    },
    "& input": {
      border: "0",
      "&:focus": {
        border: "0 !important",
      },
    },
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: "0 12px",
    margin: "0",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: `rgba(${colors.charcoalGreyRGB}, 0.55)`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "italic",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    color: colors.battleshipGrey,
    textAlign: "left",
    padding: "10px 0 20px 0",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: colors.slate,
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    "&:hover": {
      color: colors.slate,
    },
    "& svg": {
      width: "12px",
      height: "12px",
      marginRight: "0",
      "& path": {
        fill: colors.slate,
      },
    },
  }),
};
