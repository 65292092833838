import RoleFormHeader from "./components/RoleFormHeader";
import RoleForm from "./RoleForm";
import "./RoleForm.css";

function RoleFormContainer() {
  return (
    <div className="card card-permission-matrix w-100">
      <RoleFormHeader />
      <RoleForm />
    </div>
  );
}

export default RoleFormContainer;
