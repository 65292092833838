import { useAppSelector } from "../../../../../../app/hooks";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { orgCurrencyListSelector } from "../../../../../common/commonSlice";

type Props = {
  currencyCode: string;
  totalAmountDebit: string;
  totalAmountCredit: string;
  differenceDebit: string;
  differenceCredit: string;
};
function JournalItemTotal({
  currencyCode,
  totalAmountDebit,
  totalAmountCredit,
  differenceDebit,
  differenceCredit,
}: Props) {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  return (
    <div className="row mb-0 px-3">
      <div className="col calc-details">
        <div className="jv-toatal">
          <table className="table calctable mb-0">
            <thead>
              <tr>
                <th>Total Amount</th>
                <th>
                  {currencyCode +
                    " " +
                    NumberFormat(
                      Number(totalAmountDebit),
                      currencyCode,
                      orgCurrencyList
                    )}
                </th>
                <th>
                  {currencyCode +
                    " " +
                    NumberFormat(
                      Number(totalAmountCredit),
                      currencyCode,
                      orgCurrencyList
                    )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Difference</td>
                <td>
                  {differenceDebit
                    ? NumberFormat(
                        Number(differenceDebit),
                        currencyCode,
                        orgCurrencyList
                      )
                    : ""}
                </td>
                <td>
                  {differenceCredit ? (
                    differenceCredit
                  ) : differenceDebit ? (
                    ""
                  ) : (
                    <span id="difference-success">0.00 </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="info-container">
            The difference between Credit and Debit needs to be 0.00
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournalItemTotal;
