import { colors } from "../../../../constants/colors";

export const createModalCustomStyle = {
  sidebarModal: {
    width: "480px",
  },
  modalDialogFullScreen: {
    right: 0,
    position: "fixed",
    width: "480px !important",
    maxWidth: "none",
    height: "100%",
    margin: 0,
  },
  modalContent: {
    height: "100%",
    border: 0,
    borderRadius: 0,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    pointerEvents: "auto",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    outline: 0,
  },
  itemModalBody: {
    overflowY: "auto",
    maxWidth: "480px",
    padding: "0 40px 40px 40px",
    position: "relative",
    flex: "1 1 auto",
  },
  itemModalTitle: {
    paddingLeft: "0!important",
    paddingRight: "0!important",
    marginBottom: "30px",
    fontSize: "24px",
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: `${colors.charcoalGrey} !important`,
    fontFamily: "Lato !important",
    fontStyle: "normal !important",
    fontStretch: "normal !important",
  },
  addItemForm: {
    width: "100%",
    marginBottom: "20px",
    clear: "both",
  },
  itemFormLabel: {
    transform: "none",
    clear: "both",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "-0.18px",
    color: `${colors.charcoalGrey} !important`,
    marginBottom: "12px",
    float: "left",
    maxWidth: "fit-content",
  },
  input: {
    width: "100%",
    clear: "both",
    appearance: "none",
    boxShadow: "none",
    padding: "11px 12px",
    borderRadius: "6px",
    border: "solid 1px var(--new-whisper)",
    backgroundColor: "var(--white-fill)",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "0.71",
    letterSpacing: "normal",
    color: "var(--charcoal-grey)",

    "&:focus": {
      border: `1px solid ${colors.ceruleanBlue} !important`,
      boxShadow: "none !important",
      outline: "0 !important",
    },
  },
};
