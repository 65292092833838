import React, { useEffect, useState, useRef, useCallback } from "react";
import { TCSList, TDSList } from "../../../../types";
import useCommonData from "../../../hooks/useCommon";
import { tdsDropDown } from "../../../home/tds/tdsSlice";
import { Link, useParams } from "react-router-dom";
import { tcsDropDown } from "../../../home/tcs/tcsSlice";
import TdsFormContainer from "../../../home/tds/components/tdsForm/TdsFormContainer";
import TcsFormContainer from "../../../home/tcs/components/tcsForm/TcsFormContainer";
import "../../../../index.css";
import "./CustomSelectDropDown.css";
type Props = {
  taxType: string;
  tdsIds: number[];
  tcsIds: number[];
  index: number;
  handleTdsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  handleTotalTcsLabelChange: (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => void;
  item: {
    tds_id: number | null;
    tds_amount: number;
    tds_rate: number;
    tds_name: string;
    tcs_id: number | null;
    tcs_amount: number;
    tcs_rate: number;
    tcs_name: string;
  };
  applyTdsAt: string;
  placeholder: string;
  setPercentage: (value: boolean) => void;
  isAddNewButtonShow?: boolean;
};
const CustomSelectDropDown = (props: Props) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { editId } = useParams();

  const [tdsTaxes, setTdsTaxes] = useState<TDSList[]>([]);
  const [tcsTaxes, setTcsTaxes] = useState<TCSList[]>([]);

  let isFetch = useRef(false);

  useEffect(() => {
    if (props.taxType === "TCS") getTcsDropDownList();
  }, [currentUserInfo.organization_id, props.tcsIds, props.taxType]);

  useEffect(() => {
    if (props.taxType === "TDS") getTdsDropDownList();
  }, [currentUserInfo.organization_id, props.tdsIds, props.taxType]);

  useEffect(() => {
    if (editId && props.item.tds_id) {
      let tdsInfo = tdsTaxes.filter(
        (tds: TDSList) => tds.id === props.item.tds_id
      )[0];
      if (tdsInfo) props.setPercentage(tdsInfo.is_percentage);
    }
  }, [props.item, tdsTaxes]);

  const getTdsDropDownList = async () => {
    const responseAction = await dispatch(
      tdsDropDown({
        orgId: currentUserInfo.organization_id,
        tdsId: editId && props.tdsIds.length ? props.tdsIds : [],
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setTdsTaxes(response);
    }
  };

  const getTcsDropDownList = async () => {
    const responseAction = await dispatch(
      tcsDropDown({
        orgId: currentUserInfo.organization_id,
        tcsId: editId && props.tcsIds ? props.tcsIds : [],
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setTcsTaxes(response);
    }
  };
  return (
    <>
      <div
        className={`btn-group taxcode-dropdown ${
          props.applyTdsAt === "transaction_level"
            ? "tds-tcs-dropdown-total dropup"
            : ""
        }`}
      >
        <input
          type="text"
          name="tax_id"
          className="search-dropdown dropdown-toggle"
          placeholder={props.placeholder}
          data-bs-toggle="dropdown"
          value={
            props.taxType === "TDS"
              ? props.item.tds_id
                ? props.item.tds_name
                : ""
              : props.item.tcs_id
              ? props.item.tcs_name
              : ""
          }
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          id={`tax-code-${
            props.taxType === "TDS" ? props.item.tds_id : props.item.tcs_id
          }`}
        />
        <ul className="dropdown-menu dropdown-menu-start">
          {props.taxType === "TDS"
            ? tdsTaxes.map((tds, index) => {
                return (
                  <li
                    role="listitem"
                    onClick={() => {
                      props.handleTdsLabelChange(
                        tds.id,
                        tds.rate,
                        tds.tax_name,
                        props.index
                      );
                      props.setPercentage(tds.is_percentage);
                    }}
                    key={index}
                  >
                    <Link
                      className={`dropdown-item ${
                        props.item.tds_id === tds.id ? "active" : ""
                      }`}
                      to="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="itemLabel">{tds.tax_name}</span>
                    </Link>
                  </li>
                );
              })
            : tcsTaxes.map((tcs, index) => {
                return (
                  <li
                    role="listitem"
                    onClick={() =>
                      props.handleTotalTcsLabelChange(
                        tcs.id,
                        tcs.rate,
                        tcs.tax_name,
                        props.index
                      )
                    }
                    key={index}
                  >
                    <Link
                      className={`dropdown-item ${
                        props.item.tcs_id === tcs.id ? "active" : ""
                      }`}
                      to="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="itemLabel">{tcs.tax_name}</span>
                    </Link>
                  </li>
                );
              })}
          {props.isAddNewButtonShow && (
            <li>
              <Link
                to="#"
                className="dropdown-item add-link-option"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {props.taxType === "TDS" ? (
                  <TdsFormContainer
                    id={0}
                    isEdit={false}
                    refreshList={getTdsDropDownList}
                    createdFrom="transaction_page"
                    label={"New Tds"}
                  />
                ) : (
                  <TcsFormContainer
                    id={0}
                    isEdit={false}
                    refreshList={getTcsDropDownList}
                    createdFrom="transaction_page"
                    label={"New TCS"}
                  />
                )}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default React.memo(CustomSelectDropDown);
