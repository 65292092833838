import * as yup from "yup";
import { conversionMinDate } from "../../../../../constants/constants";

export const SystemVoucherValidation = yup.object({
  date: yup
    .string()
    .required("Please enter a payment date")
    .test("date", "Please enter a valid payment date", function (value) {
      new Date(value).toString();
      if (value === "Invalid Date") {
        return false;
      }
      return true;
    })
    .test(
      "date",
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.",
      function (value) {
        if (value === "Old date") {
          return false;
        }
        return true;
      }
    )
    .test(
      "date",
      "Please enter a date with a year above 2000",
      function (value) {
        if (
          new Date(value).toString() !== "Invalid Date" &&
          new Date(value) >= new Date(conversionMinDate)
        ) {
          return true;
        }
        return false;
      }
    ),
  currency_id: yup.number().required("Please select a currency").nonNullable(),
  journal_voucher_number: yup
    .string()
    .test(
      "journal_voucher_number",
      "Please enter a valid payment number",
      function (value) {
        const payment_number_value = value?.split("~")[1];

        if (payment_number_value === "") {
          return false;
        }
        return true;
      }
    ),
  notes: yup.string().required("Please enter notes."),
  email_to: yup
    .array()
    .of(yup.string().email("Please enter a valid email address"))
    .nullable()
    .notRequired(),
  item_details: yup.array().of(
    yup.object().shape({
      account_id: yup
        .mixed()
        .test("account_id_missing", "Choose an account.", function (value) {
          if (!value) {
            return false;
          }
          return true;
        }),
      contact_id: yup
        .mixed()
        .test(
          "is-contact-id-missing",
          "Please select a customer.",
          function (value) {
            const { account_name } = this.parent as any;

            if (!value && account_name === "Sundry Debtors") {
              return false;
            }

            return true;
          }
        )
        .test(
          "is-contact-id-missing",
          "Please select a vendor.",
          function (value) {
            const { account_name } = this.parent as any;

            if (!value && account_name === "Sundry Creditors") {
              return false;
            }

            return true;
          }
        )
        .nullable(),
    })
  ),
});
