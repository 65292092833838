import React, { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ObjectType } from "../../../types";

import {
  gstTaxListSelector,
  gstStateTypeSelector,
  defaultPreferenceSelector,
  getGstTaxList,
  gstTaxListParamsSelector,
} from "../../home/gst/gstSlice";
import { FormControl } from "@mui/material";
import "../styles/TaxCode.css";
import { IsFeatureEnabled } from "../../../helpers/featureFlagHelper";
import { TaxModal } from "../../home/gst/taxRates/components/TaxModal";
import { Link, useParams } from "react-router-dom";
import _, { isRegExp } from "lodash";
import { useEffect, useRef } from "react";

import useCommonData from "../../hooks/useCommon";
import { gstRegistrationTypes } from "../../constants/constants";
import Popup from "reactjs-popup";
import { CloseImg } from "../../../assets/images";
import Select from "react-select";
import { createModalCustomStyle } from "../../home/items/components/createItems/CreateModalCustomStyle";
import {
  exemptionDropdownSelector,
  exemptionDropdown,
} from "../../home/items/itemSlice";
import { ErrorToaster } from "../../../helpers/toastHelper";

type Props = {
  item: ObjectType;
  gst_tax: ObjectType | null;
  gstOnChange: (e: any, itemExemption: any) => void;
  itemIndex: number;
  organizationId: number;
  disabledFeature: ObjectType[];
  supplyState: string;
  isSez?: boolean;
  gstRegistrationType?: string;
  updateIsItemEdited?: (val: boolean) => void;
  isTaxDisabled: boolean;
  setTaxDisabled: (val: boolean) => void;
  customerDetails: any;
};
export default function GstTaxCode(props: Props) {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  let taxList = useAppSelector(gstTaxListSelector) as ObjectType[];
  const stateType = useAppSelector(gstStateTypeSelector) as string;
  const gstTaxListParams = useAppSelector(gstTaxListParamsSelector);
  const defaultPreference = useAppSelector(
    defaultPreferenceSelector
  ) as ObjectType;
  const prevStatetype = useRef("");
  const [exemptionReason, setExemptionReason] =
    useState<{
      label: string;
      value: string;
    }>();
  const ref = useRef<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const taxExemptionReasonList = useAppSelector(exemptionDropdownSelector);
  const [exemptionReasonError, setExemptionReasonError] = useState<string>();
  const [isExemptionModalOpen, setExemptionmodalOpen] =
    useState<boolean>(false);

  // useEffect(() => {
  //   // if (prevStatetype.current !== "") {
  //   populateDefault();
  //   // }
  //   // prevStatetype.current = stateType;
  // }, [stateType]);

  // useEffect(() => {
  //   if (!props.gst_tax || !props.gst_tax?.tax_id) {
  //     populateDefault();
  //   }
  // }, [props.supplyState]);
  useEffect(() => {
    dispatch(exemptionDropdown({ orgId: currentUserInfo.organization_id }));
  }, []);
  useEffect(() => {
    if (props.item) {
      if (props.customerDetails?.customer_exemption_type === "NON_TAXABLE") {
        const exemption_reason = taxExemptionReasonList.find(
          (obj: any) => obj.id === props.item.item_exemption_id
        );
        if (exemption_reason) {
          setExemptionReason({
            label: exemption_reason.exemption_reason,
            value: `${exemption_reason.id}`,
          });
        } else {
          const defaultExemption = taxExemptionReasonList.find(
            (obj: any) => obj.id === 120
          );
          setExemptionReason({
            label: defaultExemption ? defaultExemption.exemption_reason : "",
            value: defaultExemption ? `${defaultExemption.id}` : "",
          });
          props.setTaxDisabled(true);
        }
      } else if (props.item) {
        const exemption_reason = taxExemptionReasonList.find(
          (obj: any) => obj.id === parseInt(props.item.item_exemption_id)
        );
        if (exemption_reason) {
          setExemptionReason({
            label: exemption_reason.exemption_reason,
            value: `${exemption_reason.id}`,
          });
        } else {
          setExemptionReason({
            label: "",
            value: "",
          });
        }
      } else {
        props.setTaxDisabled(false);
      }
    } else {
      props.setTaxDisabled(false);
    }
    if (props.customerDetails?.customer_exemption_type === "TAXABLE") {
      props.setTaxDisabled(false);
    }
  }, [
    props.item?.item_exemption_type,
    props.item?.item_exemption_id,
    props.item,
    props.customerDetails?.customer_exemption_type,
  ]);
  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );

  let taxConfigurationPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Tax Configuration"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  taxList = _.orderBy(taxList, ["tax_group"], ["asc"]);
  if (taxList[0] && taxList[0].tax_group === false) {
    taxList.unshift({ id: 0, name: "Taxes", is_label: true });
  }
  let group_start_index = taxList.findIndex((tax) => tax.tax_group === true);
  if (group_start_index !== -1) {
    taxList.splice(group_start_index, 0, {
      id: 0,
      name: "Tax Group",
      is_label: true,
    });
  }
  const populateDefault = () => {
    if (
      taxList.length &&
      props?.gstRegistrationType !== gstRegistrationTypes.sez &&
      props?.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      props?.gstRegistrationType !== gstRegistrationTypes.overseas
    ) {
      if (stateType === "Inter State" && defaultPreference.inter_state_id) {
        let defaultTax = taxList.filter(
          (tax) => tax.id === defaultPreference.inter_state_id
        );
        if (defaultTax.length > 0) {
          props.gstOnChange(defaultTax[0], null);
        }
      }
      if (stateType === "Intra State" && defaultPreference.intra_state_id) {
        let defaultTax = taxList.filter(
          (tax) => tax.id === defaultPreference.intra_state_id
        );
        if (defaultTax.length > 0) {
          props.gstOnChange(defaultTax[0], null);
        }
      }
    }
  };

  const liClickHandler = (gst: ObjectType) => {
    if (props.updateIsItemEdited) {
      props?.updateIsItemEdited(true);
    }
    let exemptionType = "";
    if (gst.is_tax_preference) {
      exemptionType =
        gst.name === "Non-Taxable"
          ? "NON_TAXABLE"
          : gst.name === "Out of Scope"
          ? "OUT_OF_SCOPE"
          : gst.name === "Non-GST Supply"
          ? "NON_GST_SUPPLY"
          : "";
    }
    const exemptionId =
      exemptionReason && exemptionReason?.value !== ""
        ? parseInt(exemptionReason.value)
        : null;
    props.gstOnChange(gst, {
      item_exemption_type: exemptionType,
      item_exemption_id: exemptionId,
    });
  };

  let taxName = "";
  if (props?.gst_tax?.tax_id) {
    const selectedTax = taxList.filter(
      (tax) =>
        tax.id === props?.gst_tax?.tax_id &&
        tax.tax_group === props?.gst_tax?.is_group
    );
    if (selectedTax.length) {
      if (selectedTax[0].is_tax_preference) {
        taxName = `${selectedTax[0]?.name}`;
      } else {
        taxName = `${selectedTax[0]?.name}-[${selectedTax[0]?.rate}%]`;
      }
    }
  }
  const handleTaxExemptionChange = (e: any) => {
    setExemptionReason({
      label: e.label,
      value: e.value,
    });
    setExemptionReasonError("");
  };
  const handleExemptionReasonSubmit = (tax: any) => {
    if (!exemptionReason || exemptionReason?.value === "") {
      setExemptionReasonError("Please select exemption reason");
    } else {
      setExemptionReasonError("");
      if (!tax.is_label) {
        liClickHandler(tax);
      }
      ref.current.close();
      inputRef.current?.classList.remove("show");
      inputRef.current?.setAttribute("aria-expanded", "false");
      ulRef.current?.classList.remove("show");
    }
  };
  const handleOpenExemptionReasonmodal = () => {
    if (props?.gstRegistrationType === gstRegistrationTypes.overseas) {
      ErrorToaster("Only Zero Tax can be applied to overseas invoices.");
      return;
    }
    ref.current.open();
    setExemptionmodalOpen(true);
  };
  const handleCloseExemptionReasonmodal = () => {
    setExemptionReasonError("");
    ref.current.close();
    setExemptionmodalOpen(false);
  };
  return (
    <>
      <div className={`btn-group taxcode-dropdown`}>
        <input
          type="text"
          name="tax_id"
          className={`search-dropdown dropdown-toggle`}
          placeholder="Tax code"
          data-bs-toggle={isExemptionModalOpen ? `outside` : `dropdown`}
          value={taxName}
          onChange={() => {}}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          id={`tax-code-${props.itemIndex}`}
          disabled={props.isTaxDisabled ? true : false}
          ref={inputRef}
        />
        <ul className="dropdown-menu" ref={ulRef}>
          {taxList.map((tax, index) => {
            return (
              <li
                onClick={() => {
                  if (!tax.is_label) {
                    if (tax.name === "Non-Taxable") {
                      handleOpenExemptionReasonmodal();
                    } else {
                      liClickHandler(tax);
                    }
                  }
                }}
                key={index}
              >
                {tax.is_tax_preference && (
                  <Link
                    className={`dropdown-item ${
                      $(`#tax-code-${props.itemIndex}`).val() === tax.name
                        ? "active"
                        : ""
                    }`}
                    to="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    {tax.name === "Non-Taxable" &&
                    props?.gstRegistrationType !==
                      gstRegistrationTypes.overseas ? (
                      <Popup
                        ref={ref}
                        trigger={
                          <Link
                            className="non-taxable-link"
                            tabIndex={0}
                            to="#"
                            onClick={(e) => e.preventDefault()}
                          >
                            {tax.name}
                          </Link>
                        }
                        position="bottom center"
                        className="exemption-popup"
                      >
                        <div className="exemption-popup-body">
                          <div className="popup-heading">
                            <p className="popup-heading-text">
                              Choose the reason for exemption
                            </p>
                            <img
                              className="remove-icon"
                              src={CloseImg}
                              onClick={handleCloseExemptionReasonmodal}
                              alt="close"
                            />
                          </div>
                          <div className="popup-content-body">
                            <FormControl
                              sx={createModalCustomStyle.addItemForm}
                            >
                              <label
                                htmlFor="taxPreference"
                                className="popup-select-heading required"
                              >
                                Exemption Reason
                              </label>
                              <Select
                                name="tax_preference"
                                id="tax_preference"
                                options={
                                  taxExemptionReasonList.length > 0
                                    ? taxExemptionReasonList.map((item) => {
                                        return {
                                          label: item.exemption_reason,
                                          value: item.id.toString(),
                                        };
                                      })
                                    : []
                                }
                                className={`state-select form-select custom-select`}
                                value={
                                  exemptionReason &&
                                  exemptionReason.label !== "" &&
                                  exemptionReason.value !== ""
                                    ? {
                                        label: exemptionReason.label,
                                        value: exemptionReason.value,
                                      }
                                    : ""
                                }
                                onChange={(e) => {
                                  handleTaxExemptionChange(e);
                                }}
                                // styles={customSelectStyle}
                                placeholder={"Select or type to add"}
                                isSearchable={false}
                              />
                              <span className="error">
                                {exemptionReasonError}
                              </span>
                            </FormControl>
                          </div>
                          <div className="popup-button-group">
                            <button
                              className="btn save-button mx-0"
                              onClick={() => handleExemptionReasonSubmit(tax)}
                            >
                              Update
                            </button>
                            <button
                              className="text-decoration-none cancel-button mx-3"
                              onClick={handleCloseExemptionReasonmodal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Popup>
                    ) : (
                      <span className="itemLabel">{tax.name}</span>
                    )}
                  </Link>
                )}
              </li>
            );
          })}
          {taxList.map((tax, index) => {
            return (
              <li
                onClick={() => {
                  if (!tax.is_label) {
                    liClickHandler(tax);
                  }
                }}
                key={index}
              >
                {tax.is_label ? (
                  <>
                    <p className="tax-dropdown-label">{tax.name}</p>
                  </>
                ) : (
                  !tax.is_tax_preference && (
                    <Link
                      className={`dropdown-item ${
                        $(`#tax-code-${props.itemIndex}`).val() === tax.name
                          ? "active"
                          : ""
                      }`}
                      to="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="itemLabel">
                        {tax.name}-[{tax.rate}%]
                      </span>
                    </Link>
                  )
                )}
              </li>
            );
          })}
          {taxList.length === 0 ? (
            <li className="no-result">
              <Link
                className="dropdown-item no-result"
                to="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No tax code found</span>
              </Link>
            </li>
          ) : null}
          {taxConfigurationPermission.includes("Create") &&
          IsFeatureEnabled(
            "Tax Configuration",
            props.disabledFeature ? props.disabledFeature : []
          ) ? (
            <li>
              <TaxModal
                taxId={0}
                taxIdReset={() => {}}
                organizationId={props.organizationId}
                handleModalClose={() => {}}
                isAccountDeleted={false}
                refreshList={() => {
                  if (stateType) {
                    dispatch(
                      getGstTaxList({
                        stateType: stateType,
                        orgId: currentUserInfo.organization_id,
                        stateCode: gstTaxListParams.state_code,
                        transactionSource: gstTaxListParams.transaction_source,
                      })
                    );
                  }
                }}
              >
                <Link
                  to="#"
                  className="dropdown-item add-link-option"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span className="itemLabel">New tax</span>
                </Link>
              </TaxModal>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
}
