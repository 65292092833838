import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { changeRecurringInvoiceStatus } from "../recurringInvoiceSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import VoidModal from "../../../common/components/VoidModal";
import useCommonData from "../../../hooks/useCommon";

type Props = {
  voidRInvoiceId: number;
  voidStatus: string;
  refreshRInvoices: (action: string) => void;
  organizationId: number;
};
const RIVoid = (props: Props) => {
  const dispatch = useAppDispatch();
  const { currentUserInfo } = useCommonData();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteRoleResponseAction = await dispatch(
      changeRecurringInvoiceStatus({
        id: props.voidRInvoiceId,
        status: "Cancelled",
        orgId: currentUserInfo.organization_id,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length && !("error" in deleteRoleResponse)) {
        props.refreshRInvoices("delete");
        $(".close-btn").click();
        toast.success("Recurring Invoice cancelled successfully!", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <VoidModal
      heading="Are you sure you want to cancel the recurring invoice"
      subHeading={<span>Cancelled <strong>{props.voidStatus}</strong> will halt the creation of any future child invoices and maintain those already generated.</span>}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default RIVoid;