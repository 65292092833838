import "./MarkAsDeclined.css";

const MarkeAsCancelled = (props: any) => {
  return (
    <div className="proforma-modal mark-as-cancelled-modal" id="emailToModal">
      <div className="modal-body" role="document">
          <span>{props.message}</span>
      </div>
      <div
        className="modal-footer justify-content-start"
        id="send-email-button"
      >
        <button
          type="button"
          className="btn send-button"
          data-dismiss="modal"
          onClick={() => {
            props.handleStatusChange("Cancelled");
            props.onCloseModal();
          }}
        >
          Yes Proceed
        </button>
        <button
          type="button"
          className="btn cancel-button close-button"
          data-bs-dismiss="modal"
          onClick={props.onCloseModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default MarkeAsCancelled;
