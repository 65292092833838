import { Dispatch, SetStateAction, useState } from "react";
import { FormSelectField } from "../../../common/components";
import { useFormik } from "formik";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import {
  chooseDayOption,
  selectFrequencyOption,
} from "../calenderBillingStateInitialization";
import { PlanInvoicingInitialType } from "../calendarBillingTypes";
import { useFetchPlanSpecificDropdown } from "../hooks/useFetchPlanSpecificDropdown";
import { SelectValues } from "../../../../types";
import { MultiSelectFieldWithScroll } from "../../../common/components/formSelectField/MultiSelectFieldWithScroll";
import { customMultiSelectStyle } from "../../../common/components/MultiSelectCustomStyle";
import moment from "moment";
import { getFormatedDate } from "../../../../helpers/helper";
import { validatePlanSpecificForm } from "../list/calendarInvoicingForValidation";
import {
  useCreatePlanSpecificInvoicing,
  useEditPlanSpecificInvoicing,
} from "../hooks/useFetchPlanSpecificCreateEdit";
import {
  formatDateString,
  getPlanDropdownInEdit,
  recurringInvoicingValue,
} from "../helpers/calendarInvoicingHelper";
import { planInvoicingInitialValue } from "../calenderBillingStateInitialization";
import CustomDatePicker from "../../../utils/atoms/CustomDatePicker";
import Select from "react-select";
import { CustomCreditsDropdown } from "../../subscription/components/CustomIssueCreditDropdown";

type Props = {
  refreshPlanSpecificList: () => void;
  id?: number;
  onCloseModal: () => void;
  planSpecificDropdown: SelectValues[];
  setPlanSpecificDropdown: Dispatch<SetStateAction<SelectValues[]>>;
  planCount: number;
  setPlanCount: Dispatch<SetStateAction<number>>;
  isNextEnabled: boolean;
  setIsNextEnabled: Dispatch<SetStateAction<boolean>>;
  initialValues: PlanInvoicingInitialType;
};

export const PlanBillingSideMenuForm = (props: Props) => {
  const { createPlanSpecificInvoicing } = useCreatePlanSpecificInvoicing();
  const { editPlanSpecificInvoicing } = useEditPlanSpecificInvoicing();
  const { getPlanDropdown } = useFetchPlanSpecificDropdown();
  const formik = useFormik({
    initialValues: { ...props.initialValues } as PlanInvoicingInitialType,
    enableReinitialize: true,
    validationSchema: validatePlanSpecificForm,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (value) => {
      let data: any = {
        invoicing_frequency: value.invoicing_frequency,
        invoicing_type: value.invoicing_type,
        set_date: null,
        set_day: null,
        pricing_ids: value.choose_plan.map((data: SelectValues) => data.value),
      };

      if (
        value.invoicing_frequency === "WEEKLY" &&
        value.invoicing_type === "SET_DATE"
      ) {
        data.set_day = value.set_day;
      }
      if (
        value.invoicing_frequency !== "WEEKLY" &&
        value.invoicing_type === "SET_DATE"
      ) {
        data.set_date = value.set_date;
      }
      if (props.id) {
        await editPlanSpecificInvoicing(
          data,
          props.refreshPlanSpecificList,
          props.id
        );
      } else {
        await createPlanSpecificInvoicing(data, props.refreshPlanSpecificList);
      }
      onFormClose();
    },
  });

  const onFormClose = () => {
    props.onCloseModal();
    formik.resetForm();
    props.setIsNextEnabled(false);
    props.setPlanCount(1);
    props.setPlanSpecificDropdown([]);
  };

  const getPlanDropdownOnScroll = () => {
    getPlanDropdown(
      props.setPlanSpecificDropdown,
      props.planSpecificDropdown,
      formik.values.invoicing_frequency,
      props.planCount,
      props.setPlanCount,
      props.setIsNextEnabled,
      props.id
    );
  };

  const onInvoicingfrequencyChange = (option: any) => {
    if (option.value !== formik.values.invoicing_frequency) {
      formik.setValues({
        ...planInvoicingInitialValue,
        invoicing_frequency: option.value,
      });
      getPlanDropdown(
        props.setPlanSpecificDropdown,
        [],
        option.value,
        1,
        props.setPlanCount,
        props.setIsNextEnabled,
        props.id
      );
    }
  };

  const handleDate = (date: Date | null) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        formik.setFieldValue("set_date", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          formik.setFieldValue("set_date", dateFormated);
        } else {
          formik.setFieldValue("set_date", "Invalid date");
        }
      }
    } else if (date === null) {
      formik.setFieldValue("set_date", "");
    }
  };

  const unusedCreditsDropdown: {
    label: string;
    value: string;
    info: string;
  }[] = [
    {
      label: "Auto Set",
      value: "AUTO_SET",
      info: `Automatically schedules invoicing date based on subscription start ${
        formik.values.invoicing_frequency === "WEEKLY" ? "day" : "date"
      }`,
    },
    {
      label: `${
        formik.values.invoicing_frequency === "WEEKLY" ? "Set day" : "Set date"
      }`,
      value: "SET_DATE",
      info: `Manually choose the invoicing ${
        formik.values.invoicing_frequency === "WEEKLY" ? "day" : "date"
      } for the respective frequency`,
    },
  ];

  return (
    <div className="billing-and-proration-modal-container">
      <div className="billing-and-proration-modal-inner-container">
        <FormSelectField
          name="invoicing_frequency"
          id="invoicing_frequency"
          label="Select Frequency"
          options={selectFrequencyOption}
          wrapperClass="mw-325"
          className="state-select custom-select billing-and-proration-select"
          value={
            selectFrequencyOption.filter(
              (data: any) => data.value === formik.values.invoicing_frequency
            )[0] || ""
          }
          onChange={onInvoicingfrequencyChange}
          placeholder={"Choose Plan Frequency"}
          isSearchable={true}
          isDisabled={false}
          error={formik.errors.invoicing_frequency}
          isRequired={true}
          isOnlyInRow={false}
          messagePlacement="top-end"
          styles={customSelectStyle}
        />

        <label htmlFor="invoicing_type" className="required">
          Recurring Invoicing Type
        </label>
        <Select
          name="invoicing_type"
          id="invoicing_type"
          options={CustomCreditsDropdown(unusedCreditsDropdown)}
          className={`discount-period-count trial-period-type-radius state-select form-select custom-select void-subscription-margin mb-23`}
          onChange={(option: any) => {
            formik.setFieldValue("invoicing_type", option.value);
          }}
          isSearchable={false}
          placeholder=""
          styles={customSelectStyle}
          value={
            CustomCreditsDropdown(
              unusedCreditsDropdown.map((data) => ({
                label: data.label,
                value: data.value,
              }))
            ).filter((data) => data.value === formik.values.invoicing_type)[0]
          }
          isDisabled={formik.values.invoicing_frequency ? false : true}
        />

        {formik.values.invoicing_type === "SET_DATE" &&
          formik.values.invoicing_frequency === "WEEKLY" && (
            <FormSelectField
              name="set_day"
              id="set_day"
              label="Choose Day"
              options={chooseDayOption}
              wrapperClass="mw-325"
              className="state-select custom-select billing-and-proration-select choose-day-select-mb"
              value={
                chooseDayOption.filter(
                  (data: any) => data.value === formik.values.set_day
                )[0] || ""
              }
              onChange={(option: any) => {
                formik.setFieldValue("set_day", option.value);
              }}
              placeholder={"Choose Day"}
              isSearchable={true}
              isDisabled={false}
              error={formik.errors.set_day}
              isRequired={true}
              isOnlyInRow={false}
              messagePlacement="top-end"
              styles={customSelectStyle}
            />
          )}

        {formik.values.invoicing_type === "SET_DATE" &&
          formik.values.invoicing_frequency === "MONTHLY" && (
            <div className={"col-12 col-lg-4 mw-325 "}>
              <label htmlFor="check_expiry" className="required">
                Choose Date
              </label>
              <div
                id="set_date"
                className={`date-selector-wrapper choose-day-select-mb`}
              >
                <CustomDatePicker
                  date={
                    !!formik.values.set_date
                      ? formatDateString(formik.values.set_date, "MONTHLY")
                      : ""
                  }
                  handleDate={handleDate}
                  type="set_date"
                  id="set_date"
                  placeholder="Enter invoicing date"
                  error={!formik.errors.set_date ? "" : "error"}
                  isOnChangeDisabled={true}
                  isHeaderDisabled={true}
                />
                <span className="error">
                  {!formik.errors.set_date ? "" : formik.errors.set_date}
                </span>
              </div>
            </div>
          )}
        {formik.values.invoicing_type === "SET_DATE" &&
          formik.values.invoicing_frequency === "YEARLY" && (
            <div className={"col-12 col-lg-4 mw-325 choose-day-select-mb"}>
              <label htmlFor="check_expiry" className="required">
                Choose Date
              </label>
              <div id="set_date" className={`date-selector-wrapper`}>
                <CustomDatePicker
                  date={
                    !!formik.values.set_date
                      ? formatDateString(formik.values.set_date, "YEARLY")
                      : ""
                  }
                  handleDate={handleDate}
                  type="set_date"
                  id="set_date"
                  placeholder="Enter invoicing date"
                  error={!formik.errors.set_date ? "" : "error"}
                  isOnChangeDisabled={true}
                />
                <span className="error">
                  {!formik.errors.set_date ? "" : formik.errors.set_date}
                </span>
              </div>
            </div>
          )}
        <div className="relative-wrapper choose-plan-wrapper">
          <MultiSelectFieldWithScroll
            count={props.planCount}
            name="choose_plan"
            id="subscription_select_plan"
            label="Choose Plan"
            options={props.planSpecificDropdown}
            wrapperClass="mw-325"
            className="custom-select"
            value={
              props.id
                ? getPlanDropdownInEdit(
                    formik.values.choose_plan,
                    props.planSpecificDropdown
                  )
                : formik.values.choose_plan
            }
            onChange={(newValue: any) => {
              formik.setFieldValue("choose_plan", newValue);
            }}
            onMenuScrollToBottom={() => {
              if (props.isNextEnabled) {
                getPlanDropdownOnScroll();
              }
            }}
            styles={customMultiSelectStyle}
            placeholder={"Choose Plan"}
            isDisabled={!formik.values.invoicing_frequency}
            error={formik.errors.choose_plan as string}
            isRequired={true}
            isOnlyInRow={false}
            isClearable={false}
            isSearchable={false}
            dropdownCustomMessage="No Plans available"
          />
        </div>
      </div>
      <div className="billing-and-proration-modal-footer">
        <button
          type="button"
          className="save-button mx-0"
          onClick={() => {
            formik.submitForm();
          }}
        >
          Save
        </button>
        <button
          type="button"
          className="text-decoration-none cancel-button mx-4"
          onClick={() => onFormClose()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
