import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { useEffect, useState } from "react";
import "./sideBarAccordionItem.css";
import {
  checkModuleAccessibility,
  checkSubModuleAccessibility,
} from "../../../../../helpers/modulesAccessibilityHelper";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { SideBarAccordionItemProps } from "../../../../../types";
import { clearStoredInfoInLocal } from "../../../../../helpers/reportHelper";

export const SideBarAccordionItem = (
  props: SideBarAccordionItemProps,
  key: number
) => {
  const location = useLocation();
  const subModulePermission = useSubModulePermission();
  const [path, setPath] = useState(window.location.pathname);
  const currentUserInfo = useAppSelector(currentUserSelector);
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);
  const pathLinkCheck = (paths: string[]) => {
    return paths.filter((pathKey) => path.includes(pathKey)).length > 0
      ? true
      : false;
  };
  const checkModulePath = (modules: string[]) => {
    return modules.filter((module) => path.includes(module)).length > 0
      ? "nav-item accordion selected"
      : "nav-item accordion";
  };
  const checkSubModulePath = (modules: string[]) => {
    return modules.filter((module) => path.includes(module)).length > 0
      ? true
      : false;
  };
  return checkModuleAccessibility(
    props.modulePermissionsToCheck ? props.modulePermissionsToCheck : [],
    subModulePermission,
    currentUserInfo.disabled_feature
  ) ? (
    <li
      className={checkModulePath(
        props.modulePathsToCheck ? props.modulePathsToCheck : []
      )}
      key={key}
    >
      <div className="accordion-item">
        <div
          className="accordion-header w-100 d-flex"
          id={"panelsStayOpen-" + props.moduleClass}
        >
          <a
            className={
              checkSubModulePath(
                props.modulePathsToCheck ? props.modulePathsToCheck : []
              )
                ? "nav-link accordion-button menu-item"
                : "nav-link accordion-button menu-item collapsed"
            }
            href="#"
            data-bs-toggle="collapse"
            data-bs-target={`#panelsStayOpen-collapse${String(props.accordionTitle)?.replaceAll(" ","")}`}
            aria-expanded={checkSubModulePath(
              props.modulePathsToCheck ? props.modulePathsToCheck : []
            )}
            aria-controls={`panelsStayOpen-collapse${String(props.accordionTitle)?.replaceAll(" ","")}`}
            onClick={(e) => {
              e.preventDefault();
              clearStoredInfoInLocal();
            }}
          >
            {props.icon}
            <span className="menu-text">{props.accordionTitle}</span>
          </a>
        </div>
        <ul
          id={`panelsStayOpen-collapse${String(props.accordionTitle)?.replaceAll(" ","")}`}
          className={
            checkSubModulePath(
              props.modulePathsToCheck ? props.modulePathsToCheck : []
            )
              ? "submenu accordion-collapse collapse show"
              : "submenu accordion-collapse collapse "
          }
          aria-labelledby={`panelsStayOpen-${props.moduleClass}`}
        >
          {props.accordionSubItems.map((accordionItem, index) => {
            return checkSubModuleAccessibility(
              accordionItem.subModulePermissionToCheck,
              subModulePermission,
              currentUserInfo.disabled_feature
            ) ? (
              accordionItem.isAccordion && accordionItem.props ? (
                <SideBarAccordionItem {...accordionItem.props} key={index} />
              ) : accordionItem.isExpandable ? (
                <li key={index} className="nav-item dropend">
                  <Link
                    className={
                      pathLinkCheck(accordionItem.subModulePathToCheck)
                        ? "nav-link dropdown-toggle current"
                        : "nav-link dropdown-toggle"
                    }
                    to={accordionItem.path}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {accordionItem.subModuleName}
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {accordionItem?.childItems &&
                      accordionItem.childItems.map((child) => {
                        return checkSubModuleAccessibility(
                          child.subModulePermissionToCheck,
                          subModulePermission,
                          currentUserInfo.disabled_feature
                        ) ? (
                          <li
                            key={child.subModuleName}
                            className={
                              pathLinkCheck(child.subModulePathToCheck)
                                ? "selected"
                                : ""
                            }
                          >
                            <Link
                              to={"/" + child.path}
                              className={
                                pathLinkCheck(child.subModulePathToCheck)
                                  ? "dropdown-item current"
                                  : "dropdown-item"
                              }
                            >
                              {child.subModuleName}
                            </Link>
                          </li>
                        ) : null;
                      })}
                  </ul>
                </li>
              ) : (
                <li key={index}>
                  <Link
                    className={
                      pathLinkCheck(accordionItem.subModulePathToCheck)
                        ? "nav-link current"
                        : "nav-link"
                    }
                    to={"/" + accordionItem.path}
                    onClick={(e) => {
                      clearStoredInfoInLocal();
                    }}
                  >
                    {accordionItem.subModuleName}
                  </Link>
                </li>
              )
            ) : null;
          })}
        </ul>
      </div>
    </li>
  ) : null;
};
