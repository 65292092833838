import React from "react";
// import CustomDatepicker from "../../../../utils/atoms/datepicker";
import DatePicker from "../../../../utils/atoms/DatePicker/DatePicker";

import "./DateFilter.css";

type Props = {
  title: string;
  dateId: string;
  moduleType?: string | undefined;
  date: Date | null;
  dateType?: string;
  handleDate: (date: Date | null, type: string) => void;
  format?: string;
  type: string;
  error: string;
  id?: string;
  zIndex?: number;
  inset?: string;
  placeholder?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  isOpen?: boolean;
  wrapperClass?: string;
};

const DateFilter = (props: Props) => {
  const handleDateChange = (date: Date | null, type: string) => {
    // Adapt the handleStartDate function to fit the expected type
    props.handleDate(date !== null ? date : null, type);
  };
  return (
    <>
      <label htmlFor={props.dateId}>{props.title}</label>
      <div
        id="date"
        className={`date-selector-wrapper ${
          props.wrapperClass ? props.wrapperClass : ""
        }`}
      >
        <DatePicker
          date={props.date !== null ? props.date : undefined}
          handleDate={handleDateChange}
          type={""}
          error={props.error}
          id={props.dateId}
          format={props.format ? props.format : "dd/MM/yyyy"}
          // open={props.isOpen}
          // onOpen={handleOpen}
          // onClose={handleClose}
          placeholder={props.placeholder ? props.placeholder : ""}
          minDate={props.minDate ? props.minDate : undefined}
          maxDate={props.maxDate ? props.maxDate : undefined}
          zIndex={props.zIndex ? props.zIndex : 0}
        />
      </div>
      <span className="error">{props.error}</span>
    </>
  );
};

export default DateFilter;
