import { createContext, useContext, useEffect, useState } from "react";
import { TDSList } from "../../../../../types";
import { tdsDropDown } from "../../../../home/tds/tdsSlice";
import useCommonData from "../../../../hooks/useCommon";
import "./TdsSelect.css";
import { TdsSelectWrapperProps } from "./types";

const TDSOptionsContext = createContext({
  setTdsIds: (ids: number[]) => {},
  tdsTaxes: [] as TDSList[],
  fetchTDSSelectOptions: async () => {},
  setIsPercentage: (isPercentage: boolean) => {},
  isPercentage: false,
});
export const TdsSelectProvider = (props: TdsSelectWrapperProps) => {
  const { currentUserInfo, dispatch } = useCommonData();
  const [isPercentage, setPercentage] = useState<boolean>(false);
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tdsTaxes, setTdsTaxes] = useState<TDSList[]>([]);

  useEffect(() => {
    getTdsDropDownList();
  }, [currentUserInfo.organization_id, tdsIds]);

  const getTdsDropDownList = async () => {
    const responseAction = await dispatch(
      tdsDropDown({
        orgId: currentUserInfo.organization_id,
        tdsId: tdsIds,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setTdsTaxes(response);
    }
  };
  const setIsPercentage = (percentage: boolean) => {
    setPercentage(percentage);
  };

  return (
    <TDSOptionsContext.Provider
      value={{
        setTdsIds: setTdsIds,
        tdsTaxes: tdsTaxes,
        fetchTDSSelectOptions: getTdsDropDownList,
        setIsPercentage: setIsPercentage,
        isPercentage: isPercentage,
      }}
    >
      {props.children}
    </TDSOptionsContext.Provider>
  );
};
export const useTDSSelect = () => {
  return useContext(TDSOptionsContext);
};
