import { SuccessToaster } from "../../../../helpers/toastHelper";
import { markBillVoid } from "../../../home/bills/billsSlice";
import { markCreditNoteVoid } from "../../../home/creditNote/creditNoteSlice";
import { markDebitNoteVoid } from "../../../home/debitNote/debitNoteSlice";
import { markInvoiceVoid } from "../../../home/invoice/invoiceSlice";
import useCommonData from "../../../hooks/useCommon";
import { setLoaderState } from "../../commonSlice";
import { MarkAsVoidProps } from "./types";

export const useMarkVoidSubmission = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const submitMarkAsVoid = async (props: MarkAsVoidProps) => {
    dispatch(setLoaderState(true));
    switch (props.module) {
      case "Invoice":
        const invoiceResponseAction = await dispatch(
          markInvoiceVoid({
            orgId: currentUserInfo.organization_id,
            invoiceId: props.transactionId,
          })
        );
        ResponseHandler(
          invoiceResponseAction.payload,
          props.module,
          props.transactionNumber
        );
        break;
      case "Bill":
        const billResponseAction = await dispatch(
          markBillVoid({
            orgId: currentUserInfo.organization_id,
            billId: props.transactionId,
          })
        );
        ResponseHandler(
          billResponseAction.payload,
          props.module,
          props.transactionNumber
        );
        break;
      case "Credit Note":
        const creditNoteResponseAction = await dispatch(
          markCreditNoteVoid({
            orgId: currentUserInfo.organization_id,
            creditNoteId: props.transactionId,
          })
        );
        ResponseHandler(
          creditNoteResponseAction.payload,
          props.module,
          props.transactionNumber
        );
        break;
      case "Debit Note":
        const debitNoteResponseAction = await dispatch(
          markDebitNoteVoid({
            orgId: currentUserInfo.organization_id,
            debitNoteId: props.transactionId,
          })
        );
        ResponseHandler(
          debitNoteResponseAction.payload,
          props.module,
          props.transactionNumber
        );
        break;
      default:
        console.log("Wrong module passed");
    }
    props.onClose && props.onClose();
  };
  const ResponseHandler = (
    response: any,
    module: string,
    transactionNumber: string
  ) => {
    if (!("error" in response)) {
      dispatch(setLoaderState(false));
      SuccessToaster(
        `${module} voided successfully!`,
        `${transactionNumber}-voided-success`
      );
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { submitMarkAsVoid };
};
