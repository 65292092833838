import React, { useState } from "react";
import { OptionProps } from "react-select";

interface CustomOptionProps extends OptionProps<any> {
  className?: string;
}

const CustomOption: React.FC<CustomOptionProps> = (props) => {
  const { innerRef, innerProps, isFocused, isSelected, children } = props;
  const [focused, setFocused] = useState(isFocused);

  const handleMouseEnter = () => {
    setFocused(true);
  };

  const handleMouseLeave = () => {
    setFocused(false);
  };
  // const { styles } = props.selectProps;

  // const optionStyles = styles.option ? styles.option({}, props) : {};

  return (
    <div
      ref={innerRef}
      className={`select-option ${props.className || ""} ${
        isFocused || focused ? "select-option-focused" : ""
      } ${isSelected ? "select-option-selected" : ""}`}
      // style={optionStyles as React.CSSProperties}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...innerProps}
    >
      {children}
    </div>
  );
};

export default CustomOption;
