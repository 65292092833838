import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../../app/hooks";
import "../../../Users.css";
import {
  getUserLimitStatus,
  userRolesList,
  usersSelector,
} from "../../../userSlice";
import DeleteUser from "../Delete";
import {
  getOrganizationListOfUser,
  tagsList,
} from "../../../../../common/commonSlice";
import "reactjs-popup/dist/index.css";
import Pagination from "../../../../../common/components/table/Pagination";
import { Transactionlimit } from "../../../../../../types";
import NotificationModal from "../../../../../common/components/NotificationModal";
import UsersListHeader from "./components/UsersListHeader";
import UserTableContainer from "./userTable/UserTableContainer";
import useListData from "../../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useCommonData from "../../../../../hooks/useCommon";

function List() {
  const { dispatch, currentUserInfo } = useCommonData();
  const { page, pageCount, itemsPerPage, totalList, nameSortOrder } =
    useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const listRef = useRef<any>([]);
  const userData = useAppSelector(usersSelector);
  const location = useLocation();
  type LocationProps = {
    action: string;
  };
  const locationState = location.state as LocationProps;
  const locationAction = locationState?.action;
  const [deleteUserId, setDeleteUserId] = useState(-1);
  const [roleId, setRoleId] = useState(0);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: -1,
  });
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (locationAction && locationAction === "create") {
      $("#userCreate").click();
    }
  }, [locationAction]);

  useEffect(() => {
    getUserOrganizations();
  }, []);

  useEffect(() => {
    getUsersRolesHandler();
    getTagsHandler();
    setRoleId(0);
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    fetchUserLimit();
  }, [
    page,
    itemsPerPage,
    roleId,
    nameSortOrder,
    currentUserInfo.organization_id,
  ]);
  /**
   * Set id of the user to be deleted
   */
  const deleteListData = (id: number, name: string) => {
    setDeleteUserId(id);
    setUserName(name);
  };
  /**
   * Fetch transaction limit
   */
  const fetchUserLimit = async () => {
    const responseAction = await dispatch(
      getUserLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * Get roles list
   */
  const getUsersRolesHandler = async () => {
    await dispatch(
      userRolesList({ roleSort: "", orgId: currentUserInfo.organization_id })
    );
  };
  /**
   * Get Tags List
   */
  const getTagsHandler = async () => {
    await dispatch(tagsList(currentUserInfo.organization_id));
  };
  /**
   * Role change handler in the All roles filter.
   */
  const filterWithRoleHandler = (e: any) => {
    setRoleId(e.value);
    setPage(1);
  };
  /**
   * Organization list to display list on header (in case of create or delete in sub organization)
   */
  const getUserOrganizations = async () => {
    await dispatch(getOrganizationListOfUser(currentUserInfo.organization_id));
  };
  /**
   * function to fetch users list
   */
  const refreshList = () => {
    listRef.current.fetchUsersList();
  };

  return (
    <>
      <div className="card card-user-management main-card overflowX-hidden h-100">
        <UsersListHeader
          transactionLimit={transactionLimit}
          roleId={roleId}
          filterWithRoleHandler={filterWithRoleHandler}
          refreshList={refreshList}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <UserTableContainer
            deleteListData={deleteListData}
            roleId={roleId}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {userData.total > itemsPerPage ? (
              <Pagination
                page={page}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeleteUser
        deleteUserId={deleteUserId}
        userName={userName}
        refreshList={refreshList}
      />
      <NotificationModal
        modalId="userLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
}

export default List;
