import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { ObjectType } from "../../../../../types";
import { getGstTaxItem } from "./subscriptionDetailsHelper";

export const itemGstChangeHelper = (
  gstRegistrationType: string | undefined,
  gstRegistrationTypes: any,
  tax: any
) => {
  if ((gstRegistrationType === gstRegistrationTypes.sez ||
      gstRegistrationType === gstRegistrationTypes.sezDeveloper) && tax?.rate !== 0
  ) {
    ErrorToaster(
      "The tax rate for supplies to SEZ when you have a letter of Undertaking/Bond should be IGST 0%."
    );
    return true;
  } else if (
    gstRegistrationType === gstRegistrationTypes.overseas &&
    tax?.rate !== 0
  ) {
    ErrorToaster("Only Zero Tax can be applied to overseas invoices.");
    return true;
  }
  return false;
};

export const itemGstChangeSet = (itemDetails: any, itemExemption: any, isItemGstTaxable: any, itemIndex: number, taxObj: any) => {
    const itemValues = JSON.parse(JSON.stringify(itemDetails));
    const exemption = {
      item_exemption_type: itemExemption?.item_exemption_type
        ? itemExemption?.item_exemption_type
        : isItemGstTaxable ? itemValues[itemIndex]?.item_exemption_type : "TAXABLE",
      item_exemption_id: itemExemption?.item_exemption_id
        ? itemExemption?.item_exemption_id
        : isItemGstTaxable ? itemValues[itemIndex]?.item_exemption_id : '',
    };
    itemValues[itemIndex] = {
      ...itemValues[itemIndex],
      gst_tax: taxObj ? { ...taxObj } : null,
      ...exemption,
    };
    return itemValues
}

export const defaultGstHelper = (
    itemDetails: any,
    gstTaxList: any[],
    customerDetails: any,
    stateType: string,
    taxList: ObjectType[],
    defaultPreference: ObjectType,
    handleItemGstChange: (tax: any, index: number, itemExemption: any, parentIndex?: number) => void,
    parentIndex?: number,
    ) => {
    let itemsCount = JSON.parse(JSON.stringify(itemDetails)).length;
    for (let index = 0; index < itemsCount; index++) {
      if (
        itemDetails[index].item_exemption_type &&
        itemDetails[index].item_exemption_type !== "TAXABLE"
      ) {
        let taxObj = null;
        const taxPreference =
          itemDetails[index].item_exemption_type === "NON_TAXABLE"
            ? "Non-Taxable"
            : itemDetails[index].item_exemption_type === "OUT_OF_SCOPE"
              ? "Out of Scope"
              : itemDetails[index].item_exemption_type === "NON_GST_SUPPLY"
                ? "Non-GST Supply"
                : "";
        const gst_tax = gstTaxList.filter((gstTax: any) => {
          return (
            gstTax.name === taxPreference &&
            gstTax.tax_type === "NONE" &&
            gstTax.rate === 0
          );
        });
        if (
          customerDetails?.customer_exemption_type !== "NON_TAXABLE"
        ) {
          taxObj = getGstTaxItem(gst_tax[0]);
          handleItemGstChange(gst_tax[0], index, null, parentIndex);
        }
      } else {
        if (stateType === "Inter State" && defaultPreference.inter_state_id) {
          let defaultTax = taxList.filter(
            (tax) => tax.id === defaultPreference.inter_state_id
          );
          if (defaultTax.length > 0) {
            handleItemGstChange(defaultTax[0], index, null, parentIndex);
          }
        }
        if (stateType === "Intra State" && defaultPreference.intra_state_id) {
          let defaultTax = taxList.filter(
            (tax) => tax.id === defaultPreference.intra_state_id
          );
          if (defaultTax.length > 0) {
            handleItemGstChange(defaultTax[0], index, null, parentIndex);
          }
        }
      }
    }
}

export const setGstonFieldChange = (
    customerDetails: any, 
    handleItemGstChange: (tax: any, index: number, itemExemption: any, parentIndex?: number) => void,
    getDefaultNonTaxableGst: () => any,
    getDefaultSezGst_forSales: () => any,
    gstRegistrationType: string | undefined,
    gstRegistrationTypes: any,
    itemDetails: any,
    parentIndex?: number,
    ) => {
        if (customerDetails?.customer_exemption_type === "NON_TAXABLE") {
          let itemsCount = JSON.parse(JSON.stringify(itemDetails)).length;
          let default_gst = getDefaultNonTaxableGst();

          if (default_gst.length > 0) {
            for (let index = 0; index < itemsCount; index++) {
              handleItemGstChange(default_gst[0], index, {
                item_exemption_type:
                  customerDetails?.customer_exemption_type,
                item_exemption_id: "",
              }, parentIndex);
            }
          }
        } else if (
          (gstRegistrationType === gstRegistrationTypes.sez ||
            gstRegistrationType === gstRegistrationTypes.sezDeveloper ||
            gstRegistrationType === gstRegistrationTypes.overseas) 
        ) {
          let itemsCount = JSON.parse(JSON.stringify(itemDetails)).length;
          let default_gst = getDefaultSezGst_forSales();
          if (default_gst.length > 0) {
            for (let index = 0; index < itemsCount; index++) {
              handleItemGstChange(default_gst[0], index, null, parentIndex);
            }
          }
        } else {
          let itemsCount = JSON.parse(JSON.stringify(itemDetails)).length;
          for (let index = 0; index < itemsCount; index++) {
            handleItemGstChange(null, index, null, parentIndex);
          }
        }
}

export const taxOnBlurHelper = (
  name: string,
  amount: number,
  itemIndex: number,
  taxIndex: number,
  items: any,
  taxItems: any,
  ) => {
      let roundedAmount: number = getRoundOffAmount(amount, 6);
      items[itemIndex].taxes[taxIndex] = {
        ...items[itemIndex].taxes[taxIndex],
        [name]: roundedAmount,
      };
      taxItems[itemIndex].taxes[taxIndex] = {
        ...taxItems[itemIndex].taxes[taxIndex],
        [name]: roundedAmount,
      };
}