import React from "react";
import ApplyToInvoicesModal from "./ApplyToInvoicesModal";
import { PlusIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import { CreditNoteDetailValues } from "../../../../../types";
import useToast from "../../../../hooks/useToast";

type Props = {
  creditNoteData: CreditNoteDetailValues;
  isVoid?: boolean;
  refreshCNDetails: () => void;
};
function ApplyToInvoices(props: Props) {
  const { clearToast } = useToast();

  const buttonComponet = (
    <span>
      <PlusIcon color={colors.charcoalGrey} />
      Apply to Invoices
    </span>
  );
  return (
    <>
      {props.creditNoteData.invoice_status || props.isVoid ? (
        <ApplyToInvoicesModal
          buttonComponent={buttonComponet}
          creditNoteData={props.creditNoteData}
          refreshCNDetails={props.refreshCNDetails}
          buttonClass="dropdown-item"
          isVoid={props.isVoid}
        />
      ) : (
        <>
          <button
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#notificationPopupModal"
            onClick={() => {
              clearToast("error");
            }}
          >
            {buttonComponet}
          </button>
        </>
      )}
    </>
  );
}

export default ApplyToInvoices;
