import React, { useEffect } from "react";
import "../styles/DashboardTable.css";
import { useAppSelector } from "../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../common/commonSlice";
import { NumberFormat } from "../../../../helpers/numberFormatHelper";

type Props = {
  title: string;
  data: {
    id: number;
    name: string;
    totalAmount: string;
    noOfTransactions: string;
  }[];
  handleOnClick: (e: any) => void;
};
function DashboardTable({ title, data, handleOnClick }: Props) {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  return (
    <div className="col">
      <div className="dashboard-half-box table-wraper-section flex-column">
        <div className="header-wrapper w-100">
          <h3>{title}</h3>
        </div>
        <div className="table-section report-table-section w-100">
          <div className="table-wrapper">
            <table role="table" className="table report-table w-100">
              <tbody>
                {data.map((row, index) => {
                  return (
                    <tr onClick={() => handleOnClick(row.id)} key={row.id}>
                      <td>{row.name + " (" + row.noOfTransactions + ")"} </td>
                      <td>
                        {currentUserInfo.currency_code +
                          " " +
                          NumberFormat(
                            Number(row.totalAmount),
                            currentUserInfo.currency_code,
                            orgCurrencyList
                          )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTable;
