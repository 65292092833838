import { FormikProps } from "formik";
import { DeleteIcon, FormInputField } from "../../../../common/components";

import IconButton from "@mui/material/IconButton";
import { colors } from "../../../../constants/colors";

type Props = {
  formik: FormikProps<any>;
  index: number;
  errors: any;
  option: string;
  isTouched: boolean;
  id: number | null;
  isEdit: boolean;
  editId: string | undefined;
  wrapperClass: string;
  isDisabled: boolean;
  isSideIconEnabled: boolean;
  deleteOption: (index: number, optionId?: number) => void;
};
export const TagOptionComponent = (props: Props) => {
  return (
    <>
      <FormInputField
        type="text"
        name={`options.${props.index}.name`}
        id={`options${props.index}`}
        className={`${
          props.errors && props.errors[props.index] && props.isTouched
            ? "form-control error"
            : "form-control"
        } mw-none`}
        label=""
        placeholder="Enter option"
        maxLength={50}
        value={props.option}
        onChange={(e) => {
          if(props.isDisabled){
            return;
          }
          props.formik.handleChange(e);
        }}
        error={
          props.errors && props.isTouched && props.errors[props.index]?.name
            ? props.errors[props.index]?.name
            : ""
        }
        wrapperClass={`mb-3  ${props.wrapperClass ? props.wrapperClass : ""} ${
          props.isDisabled ? "disabled-form-input-field" : ""
        } `}
        disabled={props.isDisabled}
        // onFocus={() => {
        //   if (props.isDisabled) {
        //     ErrorToaster(
        //       "Unable to edit this option as there are transactions associated with it!"
        //     );
        //   }
        // }}
        isSideIcon={props.isSideIconEnabled}
        sideIcon={
          <IconButton
            onClick={() => {
              props.deleteOption(props.index, Number(props.id));
            }}
            disabled={props.isDisabled}
          >
            <DeleteIcon color={colors.tomato} />
          </IconButton>
        }
      />
    </>
  );
};
