export const constants = {
  placeholders: {
    TDS_SELECT: "Choose TDS type",
    TDS_INPUT_AMOUNT: "Enter amount",
    TDS_INPUT_PERCENTAGE: "Enter %",
  },
  labels: {
    NEW_TDS: "New Tds",
  },
};
