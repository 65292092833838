import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from "react";
import { Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { listboxCustomStyle } from "../../../../../common/components/ListBoxCustomStyle";
import { TCSList } from "../../../../../../types";
import TableInfo from "../../../../../common/components/table/TableInfo";
import TcsTable from "./TcsTable";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Delete from "../../../../../common/components/Delete";
import useCommonData from "../../../../../hooks/useCommon";
import { setLoaderState } from "../../../../../common/commonSlice";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useListData from "../../../../../hooks/useListData";
import TcsFormContainer from "../../tcsForm/TcsFormContainer";
import { tcsList } from "../../../tcsSlice";

const TcsTableContainer = (
  {
    deleteListData,
    refreshList,
  }: {
    deleteListData: (tcsId: number, taxName: string) => void;
    refreshList: () => void;
  },
  ref: any
) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const [tcsData, setTcsData] = useState<TCSList[]>([]);
  const tcsPaginationRef = useRef({
    page: page,
    itemPerPage: itemsPerPage,
  });
  useEffect(() => {
    tcsPaginationRef.current.page = page;
  }, [page]);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let tcsPermission: string[] = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "TCS")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  useImperativeHandle(
    ref,
    () => ({
      fetchTcsList: fetchTcsList,
    }),
    [page]
  );
  useEffect(() => {
    fetchTcsList();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);

  const fetchTcsList = async (
    rowsPerPage: number = 0,
    isItemAdded: boolean = false
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      tcsList({
        page: tcsPaginationRef.current.page,
        itemsPerPage: tcsPaginationRef.current.itemPerPage,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTcsData(response.tcs_tax);
        let length = response.tcs_tax.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      }
      dispatch(setLoaderState(false));
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Tax Name",
        accessor: "show.taxName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
      },
      {
        Header: "Rate(%)",
        accessor: "show.rate",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Nature of collection",
        accessor: "show.natureOfCollection",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return tcsPermission.includes("Update") ? (
            <KebabMenu>
              {tcsPermission.includes("Update") && (
                <li>
                  <TcsFormContainer
                    isEdit={true}
                    refreshList={fetchTcsList}
                    id={value.id}
                    createdFrom="tcs_page"
                  />
                </li>
              )}
              {tcsPermission.includes("Delete") && (
                <li>
                  <Delete
                    className={"dropdown-item delete"}
                    deleteIcon={true}
                    isMutable={true}
                    onClick={() => deleteListData(value.id, value.taxName)}
                    isLocked={false}
                    lockDate={""}
                    name="Items"
                  />
                </li>
              )}
            </KebabMenu>
          ) : (
            <></>
          );
        },
      },
    ],
    [currentUserInfo.organization_id, page, itemsPerPage]
  );
  /**
   * set table row data
   */
  const data = tcsData
    ? tcsData.map((item) => ({
        show: {
          taxName: item.tax_name,
          rate: item.rate ? item.rate + "%" : "-",
          natureOfCollection: item.nature_of_collection,
          actions: {
            id: item.id,
            isMutable: true,
            tcsData: item,
            taxName: item.tax_name,
          },
        },
      }))
    : [];

  return (
    <>
      <Card variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              tcsPaginationRef.current = {
                page: page,
                itemPerPage: countPerPage,
              };
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />
          <Grid>
            <TcsTable columns={columns} data={data} refreshList={refreshList} />
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default React.memo(forwardRef(TcsTableContainer));
