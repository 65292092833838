import React from "react";
import EmptyList from "../../../../../common/components/EmptyList";
import { InvoiceImage } from "../../../../../../assets/images";
import { message } from "../../../../../constants/messages";
import { useAppDispatch } from "../../../../../../app/hooks";
import { setShowItemCreate } from "../../../itemSlice";
import TableContainer from "../../../../../common/components/table/TableContainer";
import useCommonData from "../../../../../hooks/useCommon";
import FilterEmptyList from "../../../../../common/components/FilterEmptyList";

type Props = {
  columns: any[];
  data: any[];
  isLoading: boolean;
  itemsRolePermission: any;
  hiddenColums: string[];
  isFilterApplied: boolean;
  initializeFilter: () => void;
};
function ItemTable({
  columns,
  data,
  isLoading,
  itemsRolePermission,
  hiddenColums,
  isFilterApplied,
  initializeFilter,
}: Props) {
  const dispatch = useAppDispatch();
  const { navigate } = useCommonData();
  return (
    <TableContainer
      className="list-table table table-striped w-100"
      columns={columns}
      data={data}
      url={"/items"}
      sortHandler={() => {}}
      isLoading={isLoading}
      editPermission={false}
      isDetailViewExist={true}
      hiddenColumns={hiddenColums}
    >
      {!isFilterApplied ? (
        <EmptyList
          heading={
            itemsRolePermission.includes("Create")
              ? message().createItemText
              : message().noItemText
          }
          subheading={
            itemsRolePermission.includes("Create")
              ? message().createItemSubText
              : message().noItemSubText
          }
          list="Items"
          alt="create item preview"
          buttonText="Create Item"
          navigateLink=""
          navigateType="modal"
          isCrateAccess={itemsRolePermission.includes("Create")}
          icon={InvoiceImage}
          onClick={() => navigate("/items/create")}
        />
      ) : (
        <FilterEmptyList
          list="items"
          icon={InvoiceImage}
          alt="list item empty"
          handleReset={initializeFilter}
        />
      )}
    </TableContainer>
  );
}

export default ItemTable;
