import TdsForm from "./TdsForm";
import { SideModal } from "../../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../../common/components/sideModal/sideModalContext";
import { EditIcon } from "../../../../common/components/customSvgIcons/editIcon";
import { colors } from "../../../../constants/colors";
import { PlusIcon } from "../../../../common/components/customSvgIcons/plusIcon";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import useListData from "../../../../hooks/useListData";

type Props = {
  id: number;
  isEdit: boolean;
  refreshList: () => void;
  createdFrom: string;
  label?: string;
  openFromEmpty?: boolean;
};
function TdsFormContainer(props: Props) {
  // const { setItemsPerPage, setPage } = usePaginationDispatch();
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <TdsForm
                tdsId={props.id}
                refreshList={props.refreshList}
                handleClose={onCloseModal}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        props.createdFrom === "tds_page" ? (
          props.isEdit ? (
            <span className="dropdown-item w-100">
              <EditIcon color={colors.battleshipGrey} /> Edit
            </span>
          ) : (
            <span className="create-invoice-button-text green-button-text d-flex align-center">
              <PlusIcon color={colors.white} />
              Add New TDS
            </span>
          )
        ) : (
          <span className="itemLabel">{props.label}</span>
        )
      }
      modalTitle={props.isEdit ? "Edit" : "Add New TDS"}
      buttonClass={`no-appearance btn create-invoice-button right-button ${
        !props.isEdit && props.createdFrom === "tds_page" ? "green-button" : ""
      }`}
      wrapperClass={`tds-edit-side-modal ${
        props.openFromEmpty ?  "":"w-100"
      }`}
    />
  );
}
export default TdsFormContainer;
