import { SvgIconProp } from "../../../../types";

export const CloneIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width || "18px"}
      height={props.height || "18px"}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: props.margin }}
    >
      <g fill={"none"} fillRule={"evenodd"}>
        <path d="M0 0h18v18H0z" />
        <path
          d="M5.25 4.5V2.25A.75.75 0 0 1 6 1.5h9a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75h-2.25v2.25c0 .414-.338.75-.755.75h-8.99a.75.75 0 0 1-.755-.75l.002-10.5c0-.414.338-.75.756-.75H5.25zM3.752 6l-.002 9h7.5V6H3.752zM6.75 4.5h6V12h1.5V3h-7.5v1.5z"
          fill={props.color || "#747D84"}
          fillRule={"nonzero"}
        />
      </g>
    </svg>
  );
};
