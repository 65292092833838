import React from "react";
import NotificationModal from "./NotificationModal";
import { message } from "../../constants/messages";

function TransactionLimitModal({ limit }: { limit: number | string }) {
  return (
    <NotificationModal
      modalId="transactionLimitModal"
      modalHeader={message().transactionLimitTitle}
      modalBody={message(limit).transactionLimitText}
      modalSubmit="OK"
      modalCancel=""
      modalSubmitHandler={() => {}}
    />
  );
}

export default TransactionLimitModal;
