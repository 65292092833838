import { Container } from "../../../common/components/htmlTags/Container";
import { Form } from "../../../common/components/htmlTags/Form";
import { useState } from "react";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import { SaveButton } from "../../../common/components";
import { OrgFiscalYearSelect } from "../../../common/components/orgFiscalYearSelect/OrgFiscalYearSelect";
import useCommonData from "../../../hooks/useCommon";
import { useMeApiTrigger } from "../../../hooks/useTriggerOrgDetails";
import { updateFiscalYear } from "../../organizationProfile/OrganizationProfileSlice";
import "./FiscalYearPopup.css";
import { InfoMessage } from "../../../common/components/infos/InfoMessage";

type Props = {
  setDialogOpen: (shouldOpen: boolean) => void;
};
export const FiscalYearPopupForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { triggerMeApi } = useMeApiTrigger();

  const [selectedFiscalOption, setSelectedFiscalOption] = useState<string>("");
  const handleFiscalYearChange = (fiscalYear: string) => {
    setSelectedFiscalOption(fiscalYear);
  };
  const handleSubmit = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updateFiscalYear({
        orgId: currentUserInfo.organization_id,
        fiscalYear: selectedFiscalOption,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Fiscal year updated successfully!",
        "fiscal-year-update-success"
      );
      props.setDialogOpen(false);
      triggerMeApi();
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return (
    <Form className="fiscal-year-popup-form">
      <Container className="w-100 ">
        <Container className="fiscal-year-info-message mb-4">
          <InfoMessage message="Please select a fiscal year for your parent organization before proceeding." />
        </Container>
        <OrgFiscalYearSelect
          handleFiscalYearChange={handleFiscalYearChange}
          fiscalYear={selectedFiscalOption}
          isDisabled={false}
        />
        <Container>
          <SaveButton
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            isDisabled={!selectedFiscalOption}
            buttonContent="Continue"
            buttonClassName="fiscal-year-popup-submit-btn"
          />
        </Container>
      </Container>
    </Form>
  );
};
