import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { useEffect, useState } from "react";
import "./TcsForm.css";
import {
  NatureOfCollectionValues,
  ObjectType,
  SubAccountValues,
  TCSFormValues,
} from "../../../../../types";
import { initialTcsFormValues } from "./StateInitialization";
import useCommonData from "../../../../hooks/useCommon";
import { subAccountsOfParent } from "../../../chartOfAccounts/coaSlice";
import { validateTcsForm } from "./ValidateTcsForm";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import _ from "lodash";
import { collectionDropdown, createTcs, tcsSelector } from "../../tcsSlice";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Checkbox from "@mui/material/Checkbox";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import useListData from "../../../../hooks/useListData";
import CustomSearchSelect from "../../../../common/components/CustomSearchSelect/CustomSearchSelect";
type Props = {
  tcsId: number;
  handleClose: () => void;
  refreshList: () => void;
};
function TcsForm({ tcsId, handleClose, refreshList }: Props) {
  const { currentUserInfo, dispatch } = useCommonData();
  const tcsList = useAppSelector(tcsSelector);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [tcsData, setTcsData] = useState<TCSFormValues>(initialTcsFormValues);
  const [initialUpdateInputs, setInitialUpdateInputs] =
    useState<TCSFormValues>(initialTcsFormValues);
  const [paymentMode, setPaymentMode] = useState("rate");
  const [natureOfCollection, setNatureOfCollection] = useState<
    NatureOfCollectionValues[]
  >([]);
  const [currentLiabilityAccount, setCurrentLiabilityAccount] = useState<
    SubAccountValues[]
  >([]);
  const [currentAssetAccount, setCurrentAssetAccount] = useState<
    SubAccountValues[]
  >([]);
  const [isConditionChecked, setIsConditionChecked] = useState(false);
  const [isTcsChanged, setIsTcsChanged] = useState(false);

  const handleCloseModal = () => {
    handleClose();
    setFormErrors({});
    setTcsData(initialTcsFormValues);
    $(".close-btn").click();
  };

  useEffect(() => {
    getCollectionDropDownList();
    getCurrentLiabilitySubAccount();
    getCurrentAssetSubAccount();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    const purchaseId = currentLiabilityAccount
      .filter((account) => account.account_name === "TCS-purchase")
      .map((account) => account.id)[0];
    const saleId = currentAssetAccount
      .filter((account) => account.account_name === "TCS-sale")
      .map((account) => account.id)[0];
    if (!tcsId && purchaseId && saleId) {
      setTcsData((values) => ({
        ...values,
        tcs_payable_account: purchaseId,
        tcs_receivable_account: saleId,
      }));
    }
  }, [currentLiabilityAccount, currentAssetAccount]);

  useEffect(() => {
    if (tcsId) {
      tcsList.tcs_tax
        .filter((tcs) => tcs.id === tcsId)
        .map((tcs) => {
          setTcsData({
            tax_name: tcs.tax_name,
            rate: tcs.rate ? tcs.rate.toString() : tcs.rate,
            nature_of_collection_id: tcs.nature_of_collection_id,
            tcs_payable_account: tcs.tcs_payable_account,
            tcs_receivable_account: tcs.tcs_receivable_account,
            is_percentage: tcs.rate ? true : false,
            previous_tcs_id: tcs.id,
          });
          setInitialUpdateInputs({
            tax_name: tcs.tax_name,
            rate: tcs.rate ? tcs.rate.toString() : tcs.rate,
            nature_of_collection_id: tcs.nature_of_collection_id,
            tcs_payable_account: tcs.tcs_payable_account,
            tcs_receivable_account: tcs.tcs_receivable_account,
            is_percentage: tcs.rate ? true : false,
            previous_tcs_id: tcs.id,
          });
          if (!tcs.rate) {
            setPaymentMode("amount");
          }
        });
    }
  }, [tcsId]);

  const inputChangeHandler = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "rate") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || numberRegex.test(value)) {
          setTcsData((values) => ({ ...values, [name]: value }));
        }
      }
    } else {
      setTcsData((values) => ({ ...values, [name]: value }));
    }
  };

  const handleRadioClick = (value: string) => {
    setPaymentMode(value);
    setTcsData((values) => ({
      ...values,
      rate: 0,
      is_percentage: value === "rate",
    }));
    if (value === "amount") {
      delete formErrors.rate;
    }
  };

  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    const errors = validateTcsForm(
      tcsData,
      paymentMode,
      isConditionChecked,
      tcsId,
      isTcsChanged
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#tcs-create-button").addClass("create-user-form-section-disable");
      createNewTcs();
    }
  };

  const createNewTcs = async () => {
    if (tcsId) {
      tcsData.previous_tcs_id = tcsId;
    }
    const responseAction = await dispatch(
      createTcs({ values: tcsData, orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        toast.success("TCS created successfully!", {
          toastId: "Tcs-create-success",
          closeButton: false,
          position: "top-center",
        });
        handleCloseModal();
        refreshList();
      }
    }
    $("#tcs-create-button").removeClass("create-user-form-section-disable");
  };

  const getCollectionDropDownList = async () => {
    const responseAction = await dispatch(
      collectionDropdown(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setNatureOfCollection(response);
      }
    }
  };

  const natureOfCollectionListOptions = natureOfCollection.map(
    (collection) => ({
      label: collection.nature_of_collection,
      value: collection.nature_of_collection_id,
    })
  );

  const natureOfCollectionName = natureOfCollection
    .filter(
      (collection) =>
        collection.nature_of_collection_id === tcsData.nature_of_collection_id
    )
    .map((collection) => ({
      label: collection.nature_of_collection,
      value: collection.nature_of_collection_id,
    }));

  const handleNatureOfCollectionChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setTcsData((values) => ({
      ...values,
      nature_of_collection_id: Number(e.value),
    }));
  };

  const getCurrentLiabilitySubAccount = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Current Liabilities"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCurrentLiabilityAccount(response);
      }
    }
  };

  const currentLiabilityAccountOptions = currentLiabilityAccount.map(
    (subAccount) => ({
      label: subAccount.account_name,
      value: subAccount.id,
    })
  );

  const currentLiabilityAccountName = currentLiabilityAccount
    .filter((subAccount) => subAccount.id === tcsData.tcs_payable_account)
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  const handlePayableAccountChange = (val:string) => {
    setTcsData((values) => ({
      ...values,
      tcs_payable_account: Number(val),
    }));
  };

  const getCurrentAssetSubAccount = async () => {
    const responseAction = await dispatch(
      subAccountsOfParent({
        parentAccountName: ["Current Assets"],
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCurrentAssetAccount(response);
      }
    }
  };

  const currentAssetsAccountOptions = currentAssetAccount.map((subAccount) => ({
    label: subAccount.account_name,
    value: subAccount.id,
  }));

  const currentAssetsAccountName = currentAssetAccount
    .filter((subAccount) => subAccount.id === tcsData.tcs_receivable_account)
    .map((account) => ({
      label: account.account_name,
      value: account.id,
    }));

  const handleReceivableAccountChange = (val:string) => {
    setTcsData((values) => ({
      ...values,
      tcs_receivable_account: Number(val),
    }));
  };

  useEffect(() => {
    isTcsValuesChanged();
  }, [tcsData, initialUpdateInputs]);

  const isTcsValuesChanged = () => {
    const tcsValues = { ...tcsData };
    delete tcsValues.nature_of_collection_id;
    const initialTcsValues = { ...initialUpdateInputs };
    delete initialTcsValues.nature_of_collection_id;
    if (_.isEqual(tcsValues, initialTcsValues)) {
      setIsTcsChanged(false);
    } else {
      setIsTcsChanged(true);
    }
  };

  const handleAmountOnBlur = (name: string, amount: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let roundedAmount: number = amount;
      const decimals = 6;
      if (name === "rate" && fractionalPart.length > decimals) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        setTcsData((values) => ({
          ...values,
          [name]: roundedAmount,
        }));
      }
    }
  };

  return (
    <div className="tcs-form-modal-wrapper">
      <form id="tcs-form" className="tcs-form tcs-form-body-wrapper">
        <div className="tcs-form-input-wrapper">
          <label htmlFor="tax_name" className="required">
            Tax Name
          </label>
          <input
            id="tax_name"
            name="tax_name"
            type="text"
            onChange={inputChangeHandler}
            value={tcsData.tax_name}
            className={`form-control ${formErrors.tax_name ? "error" : ""}`}
            placeholder="Enter tax name"
          />
          <span className="error">{formErrors.tax_name}</span>
        </div>
        <div className="tcs-form-input-wrapper">
          <div className="tcs-radio-wrapper d-flex">
            <RadioButtonGroup
              name="rateOrAmount"
              id="rateOrAmount"
              valuesArray={[
                { label: "Rate", value: "rate" },
                { label: "Amount", value: "amount" },
              ]}
              value={paymentMode}
              icon={
                <span>
                  <RadioButtonUncheckedIcon />
                </span>
              }
              checkedIcon={
                <span>
                  <RadioButtonCheckedIcon className="radio-btn-tds" />
                </span>
              }
              isDisabled={false}
              onClick={handleRadioClick}
            />
          </div>
        </div>
        <div
          className="tcs-form-input-wrapper"
          style={paymentMode === "amount" ? { opacity: "0.5" } : {}}
        >
          <label htmlFor="rate" className="required">
            Rate(%)
          </label>
          <input
            id="rate"
            name="rate"
            type="text"
            onChange={inputChangeHandler}
            value={tcsData.rate ? tcsData.rate : ""}
            className={`form-control ${formErrors.rate ? "error" : ""}`}
            placeholder="Enter rate"
            disabled={paymentMode === "amount"}
            onBlur={() => handleAmountOnBlur("rate", Number(tcsData.rate))}
          />
          <span className="error">{formErrors.rate}</span>
        </div>
        <div className="tcs-form-input-wrapper">
          <label htmlFor="nature_of_collection" className="required">
            Nature of collection
          </label>
          <Select
            name="nature_of_collection"
            value={natureOfCollectionName}
            options={natureOfCollectionListOptions}
            placeholder="Choose nature of collection"
            onChange={(e) => {
              if (e)
                handleNatureOfCollectionChange({
                  label: e?.label,
                  value: e?.value,
                });
            }}
            className={`form-select custom-select customer-select m-0 ${
              formErrors.nature_of_collection_id ? "error" : ""
            }`}
            styles={customSelectStyle}
          />
          <span className="error">{formErrors.nature_of_collection_id}</span>
        </div>
        <div className="tcs-form-input-wrapper">
          <label htmlFor="payable_account">TCS Payable Account</label>
          {/* <Select
            name="payable_account"
            value={currentLiabilityAccountName}
            options={currentLiabilityAccountOptions}
            placeholder="Choose payable account"
            onChange={(e) => {
              if (e)
                handlePayableAccountChange({
                  label: e?.label,
                  value: e?.value,
                });
            }}
            className={`form-select custom-select customer-select m-0`}
            styles={customSelectStyle}
          /> */}
          <CustomSearchSelect
            itemAccount={
              currentLiabilityAccountName.length > 0
                ? Number(currentLiabilityAccountName[0].value)
                : 0
            }
            itemAccountName={
              currentLiabilityAccountName.length > 0
                ? currentLiabilityAccountName[0].label
                : ""
            }
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(val) => {
              if (val)
                handlePayableAccountChange(val);
            }}
            itemIndex={0}
            error={""}
            baseAccount="Current Liabilities"
            className="full-width"
            wrapperClass="w-100 clear-both"
          />
        </div>
        <div className="tcs-form-input-wrapper">
          <label htmlFor="receivable_account">TCS Receivable Account</label>
          {/* <Select
            name="receivable_account"
            value={currentAssetsAccountName}
            options={currentAssetsAccountOptions}
            placeholder="Choose receivable account"
            onChange={(e) => {
              if (e)
                handleReceivableAccountChange({
                  label: e?.label,
                  value: e?.value,
                });
            }}
            className={`form-select custom-select customer-select m-0`}
            styles={customSelectStyle}
          /> */}
          <CustomSearchSelect
            itemAccount={
              currentAssetsAccountName.length > 0
                ? Number(currentAssetsAccountName[0].value)
                : 0
            }
            itemAccountName={
              currentAssetsAccountName.length > 0
                ? currentAssetsAccountName[0].label
                : ""
            }
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(val) => {
              if (val)
                handleReceivableAccountChange(val);
            }}
            itemIndex={0}
            error={""}
            baseAccount="Current Assets"
            className="full-width"
            wrapperClass="w-100 clear-both"
          />
        </div>
        {tcsId && isTcsChanged ? (
          <>
            <p className="tcs-terms-and-condition">Terms and Conditions</p>
            <div className="tds-form-input-wrapper tds-checkbox-wrapper">
              <Checkbox
                name="c_i_f_access"
                id="c_i_f_access"
                className="form-check-input tds-terms-checkbox"
                onClick={() => {
                  setIsConditionChecked(!isConditionChecked);
                }}
                checked={isConditionChecked === true ? true : false}
              />
              <span className="terms-and-condition-label">
                I understand that updating the TCS details will mark the
                existing TCS Tax inactive and create a new TCS Tax.
              </span>
            </div>
            <span className="error">{formErrors.terms_and_condition}</span>
          </>
        ) : null}

        <div className="tcs-form-button-wrapper w-100" id="tcs-create-button">
          <button
            className="save-button"
            type="button"
            disabled={isDisabled ? true : false}
            style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
            onClick={(e) => formSubmitHandler(e)}
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={handleCloseModal}
            disabled={isDisabled ? true : false}
            style={isDisabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
export default TcsForm;
