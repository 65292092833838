import React from "react";
import { BlueCheck } from "../../../assets/images";

type ButtonProps = {
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

const YearButton: React.FC<ButtonProps> = ({
  label,
  isSelected,
  isDisabled,
  onClick,
}) => {
  const baseStyle = {
    width: "174px",
    padding: "12px 16px",
    borderRadius: "32px",
    fontSize: "14px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "solid 1px",
    cursor: isDisabled ? "not-allowed" : "pointer",
  };

  const defaultStyle = {
    ...baseStyle,
    borderColor: "#9daabb",
    color: "var(--charcoal-grey)",
  };

  const selectedStyle = {
    ...baseStyle,
    padding: "12px 22px 12px 2px",
    borderColor: "var(--cerulean-blue)",
    color: "var(--cerulean-blue)",
    backgroundColor: "rgba(13, 90, 229, 0.1)",
  };

  const disabledStyle = {
    ...baseStyle,
    borderColor: "#c9d0da",
    color: "#b9c2cf",
  };

  const style = isDisabled
    ? disabledStyle
    : isSelected
    ? selectedStyle
    : defaultStyle;

  return (
    <button
      style={style}
      disabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
    >
      {isSelected && (
        <img src={BlueCheck} alt="Selected" style={{ paddingRight: "6px" }} />
      )}
      {label}
    </button>
  );
};

export default YearButton;
