import React from "react";
import { StopIcon } from "../customSvgIcons/stopIcon";
import { ResumeIcon } from "../customSvgIcons/resumeIcon";
type Props = {
  className: string;
  isStopped: boolean;
  onStopResumeClick:  () => void;
  isDisabled: boolean;
};
const StopResume = (props: Props) => {

  return (
    <button
      className={props.className}
      style={{ textDecoration: "none" }}
      data-bs-toggle="modal"
      data-bs-target="#stopResumeMessageModal"
      onClick={props.onStopResumeClick}
      disabled={props.isDisabled}
    >
      <span className="d-flex align-items-center">
        {" "}
        {props.isStopped ?
          <ResumeIcon /> : <StopIcon />}
        <span className={props.isStopped ? "" : "delete"}>{props.isStopped ? "Resume" : "Stop"}</span>
      </span>
    </button>
  );
};

export default StopResume;