type Props = {
  transactionNumber: number;
};
const TransactionNumber = (props: Props) => {
  return (
    <span className="sys-transact-num ml-auto">
      System Transaction Number:{" "}
      <span className="transact-num">{props.transactionNumber}</span>
    </span>
  );
};

export default TransactionNumber;
