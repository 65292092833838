import { ObjectType } from "../types";

export const splitTransactionNumber = (transactionNumber: string) => {
    const fields = transactionNumber?.split("~");
    return transactionNumber?{ prefix: fields && fields[0], number: fields && fields[1] }:{};

};

export const joinTransactionNumber=(transactionNumber: string)=>{
   return transactionNumber?transactionNumber.replace('~',' - '):""
};

export const joinForPayload=(obj:ObjectType)=>{
  return obj?(obj[Object.keys(obj)[0]]+"~"+obj[Object.keys(obj)[1]]).trim():""
};
