import { AlertModal } from "../../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../../common/components/alertModal/alertModalContext";
import { DeleteIcon } from "../../../../../common/components/customSvgIcons/deleteIcon";
import { colors } from "../../../../../constants/colors";
import { DeleteAllocation } from "./deleteAllocation";

type DeleteModalProps = {
  id: number | null;
  billId: number | null;
  itemId: number | null;
  module: string;
  transactionNumber: string;
  getRerenderFlag: (flag: number) => void;
};

export const DeleteAllocationModal = (props: DeleteModalProps) => {
  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <DeleteAllocation
                id={props.id}
                module={props.module}
                billId={props.billId}
                itemId={props.itemId}
                handleClose={onCloseModal}
                transactionNumber={props.transactionNumber}
                getRerenderFlag={props.getRerenderFlag}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span className="dropdown-item delete d-flex align-items-center ">
          <DeleteIcon width="18px" height="18px" color={colors.tomato} />
        </span>
      }
      buttonClass="no-appearance"
      modalTitle="Delete bill allocation!"
      isCloseButtonEnabled={true}
    />
  );
};
