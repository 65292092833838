import { SvgIconProp } from "../../../types";

export const CreateInvoiceIcon = (props: SvgIconProp) => {
  return (
    <svg
      width={props.width ? props.width : "20"}
      height={props.height ? props.height : "20"}
      viewBox="0 0 25 25"
      style={{ margin: props.margin ? props.margin : "" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={"none"} fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM8 9H16V11H8V9ZM8 13H16V15H8V13Z"
          fill={props.color}
          fillRule={"nonzero"}
        ></path>
      </g>
    </svg>
  );
};
