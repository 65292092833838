import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  AssociatedTagOptionsType,
  ObjectType,
  ReportingTagTransactionObjectPR,
} from "../../../../../../types";
import { setLoaderState } from "../../../../../common/commonSlice";
import Header from "../../../../../common/components/detailsPage/Header";
import { Container } from "../../../../../common/components/htmlTags/Container";
import { InfoMessage } from "../../../../../common/components/infos/InfoMessage";
import { AssociatedTagListPopup } from "../../../../../common/components/reportingTagInTransactions/associatedTagListPopup/AssociatedTagListPopup";
import Table from "../../../../../common/components/table/Table";
import { Tagchip } from "../../../../../common/components/ui";
import { MAX_TAG_COUNT_IN_JOURNAL_LIST } from "../../../../../constants/constants";
import useCommonData from "../../../../../hooks/useCommon";
import { getReportingTagDropdown } from "../../../../reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { useFetchJournalListPR } from "../../../hooks/useJournalListPR";
import { useFetchPRDetails } from "../../../hooks/usePRDetails";
import { useFetchTagOptionsForInvoices } from "../../../hooks/useTagOptionsForInvoice";
import {
  getIsTagOptionExist,
  getPayloadForJournalGenerate,
} from "../../paymentReceivedForm/reportingtags/utils/helper";
import { getJVTableHeader } from "../utils/helper";
import "./journalList.css";

const JournalList = (props: any) => {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const [journals, setJournals] = useState<ReportingTagTransactionObjectPR>({});
  const [tagOptionsForInvoices, setTagOptionsForInvoices] =
    useState<AssociatedTagOptionsType>({});

  const { getPRDetails } = useFetchPRDetails();
  const { getTagOptionsForInvoices } = useFetchTagOptionsForInvoices();
  const { getJournals } = useFetchJournalListPR();
  const [isMutable, setIsMutable] = useState<boolean>(false);
  const [isVoid, setIsVoid] = useState<boolean>(false);
  const [currency, setCurrency] = useState({
    currency_id: null,
    currency_code: "",
  });
  const [total, setTotal] = useState({
    total_credit: 0,
    total_debit: 0,
  });
  useEffect(() => {
    dispatch(setLoaderState(true));
    onLoadFunctions().finally(() => {
      setTimeout(() => {
        dispatch(setLoaderState(false));
      }, 2000);
    });
  }, [currentUserInfo.organization_id]);
  const onLoadFunctions = async () => {
    const response = await getPRDetails(Number(editId));
    if (!("error" in response)) {
      setCurrency({
        currency_id: response.currency_id,
        currency_code: response.currency_code,
      });
    }
    let invoicesIds = Array.isArray(response.unpaid_invoice_lines)
      ? response.unpaid_invoice_lines.map((invoices: ObjectType) => {
          return invoices.invoice_id;
        })
      : [];
    getTagOptionsForInvoices(invoicesIds, setTagOptionsForInvoices);
    getJournals(
      getPayloadForJournalGenerate(response),
      Number(editId),
      setJournals
    );
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  }, [currentUserInfo.organization_id]);
  const getData = (
    journals: ObjectType[],
    total: ObjectType,
    invId: number
  ) => {
    const data = journals
      ? journals.map((journal: ObjectType, index) => ({
          show: {
            account_name: (
              <>
                <span className="account_name ellipsis">
                  {journal.account_name}
                </span>
              </>
            ),
            tags: (
              <>
                <div className="reporting-chipsWrapper">
                  {journal.reporting_tag_list.map(
                    (reporting_tag: any, index: number) => {
                      if (index >= MAX_TAG_COUNT_IN_JOURNAL_LIST) return;
                      return  (
                        <AssociatedTagListPopup
                          buttonContent={
                            <Tagchip margin="0px 5px 5px 0px">
                              {reporting_tag.reporting_tag_name} (
                              {reporting_tag.option_list.length})
                            </Tagchip>
                          }
                          currencyCode={currency.currency_code}
                          reportingTagList={journal.reporting_tag_list}
                          accountName={journal.account_name}
                          tagId={reporting_tag.reporting_tag_id}
                        />
                      );
                    }
                  )}
                  {journal.reporting_tag_list.length >
                  MAX_TAG_COUNT_IN_JOURNAL_LIST ? (
                    <>
                      <span className="report-tags-more-tags">
                        <AssociatedTagListPopup
                          buttonContent={
                            <span className="blue-text-btn">
                              {journal.reporting_tag_list.length -
                                MAX_TAG_COUNT_IN_JOURNAL_LIST +
                                " more tags"}
                            </span>
                          }
                          currencyCode={currency.currency_code}
                          reportingTagList={journal.reporting_tag_list}
                          accountName={journal.account_name}
                        />
                      </span>
                    </>
                  ) : null}
                </div>

                <span>
                  <Link
                  className="text-decoration-none"
                    to={`/payment-received/edit/${editId}/reporting-tags/${invId}/${journal.account_id}/${journal.entity_id}/${journal.entity_type}`}
                  >
                    {journal.reporting_tag_list.length > 0
                      ? "Edit Tag"
                      : "Select tag"}
                  </Link>
                </span>
              </>
            ),
            debit:
              journal.transaction_type === "DEBIT"
                ? NumberFormat(
                    journal.transaction_amount,
                    currency.currency_code,
                    orgCurrencyList
                  )
                : "",
            credit:
              journal.transaction_type === "CREDIT"
                ? NumberFormat(
                    journal.transaction_amount,
                    currency.currency_code,
                    orgCurrencyList
                  )
                : "",
          },
        }))
      : [];
    data.push({
      show: {
        account_name: <></>,
        debit: `${currency.currency_code} ${NumberFormat(
          total.total_debit,
          currency.currency_code,
          orgCurrencyList
        )}`,
        tags: <></>,
        credit: `${currency.currency_code} ${NumberFormat(
          total.total_credit,
          currency.currency_code,
          orgCurrencyList
        )}`,
      },
    });
    return data;
  };
  const getTableMapData = () => {
    const tableData = Object.keys(journals || {}).map((value: any) => {
      return {
        invoiceNumber: (journals[value] as any).invoice_number,
        invoiceId: Number(value),
        tableData: getData(
          (journals[value] as any).transaction_list,
          {
            total_debit: (journals[value] as any).total_debit,
            total_credit: (journals[value] as any).total_credit,
          },
          Number(value)
        ),
      };
    });
    return tableData;
  };
  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    return getJVTableHeader();
  }, []);

  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 reportingtag-jv docked-footer-page"
        id="invoice-reportingtag"
      >
        <Header
          onBackButtonClick={() => {
            navigate(`/payment-received/detail/${editId}`, {
              replace: true,
            });
          }}
          data={{
            num: "Apply Reporting tags to accounts",
            status: "",
          }}
          dataId={editId}
        />
        <div className="card-body">
          <div className="table-section">
            <div className="table-wrap">
              <Container className="journal-list-table-mapping-wrapper">
                {getTableMapData().map((tableData) => {
                  return (
                    <Container className="card-body journal-list-table">
                      <h2>{joinTransactionNumber(tableData.invoiceNumber)}</h2>
                      {!getIsTagOptionExist(
                        tableData.invoiceId,
                        tagOptionsForInvoices
                      ) && (
                        <Container className="disabled-invoice-item-info-wrapper">
                          <InfoMessage message="Cannot add tags as there are no tags associated to this invoice." />
                        </Container>
                      )}
                      <Container
                        className={`table-section ${
                          !getIsTagOptionExist(
                            tableData.invoiceId,
                            tagOptionsForInvoices
                          )
                            ? "disabled-invoice-item"
                            : ""
                        }`}
                      >
                        <Container className="table-wrap">
                          <Table
                            id={""}
                            columns={columns}
                            data={tableData.tableData}
                            tableClassName={
                              "table inv-preview-table report-tags-table"
                            }
                            dataSort={false}
                            isLoading={false}
                            haveColumnClassName={false}
                            rowClassName={"table-navigation"}
                            emptyClassName={"text-center dataTables_empty"}
                            tbodyRowOnClick={() => {}}
                            tbodyColumnClick={() => {}}
                          >
                            {props.children}
                          </Table>
                        </Container>
                      </Container>
                    </Container>
                  );
                })}
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JournalList;
