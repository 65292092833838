import React, { ReactNode } from "react";
import "../../../../../../ColorVariables.css";
import "./Li.css";

type Props = {
  key: string | number | undefined;
  wrapperClass?: string;
  children: ReactNode;
};

const Li = (props: Props) => {
  return (
    <>
      <li key={props.key?props.key: ""} className={`list-li ${props.wrapperClass ? props.wrapperClass : ""}`}>
        {props.children}
      </li>
    </>
  );
};

export default Li;
