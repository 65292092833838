import {
  DeleteImg,
  EditListDataImg,
  DirectoryIconSmall,
  ListIconSmall,
  ErrorImg,
  InvoiceImage,
} from "../../../../../assets/images";
import "../../ChartOfAccounts.css";
import "../../TreeGrid.css";
import "../../../userManagement/Users.css";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useEffect, useRef, useState } from "react";
import {
  accountGlRanges,
  accountsSelector,
  accountSuggestions,
  getCoaListView,
  getCoaTreeView,
  getCoaTreeViewInAddAccount,
  getTransactionStatusOfAccount,
} from "../../coaSlice";
import DeleteAccount from "../Delete";
import Create from "../Create";
import EditAccount from "../Edit";
import Loader from "../../../components/Loader";
import EditGLCode from "../EditGLCode";
import { AccountsGLCodeRanges, ObjectType } from "../../../../../types";
import {
  currencyDropdown,
  currentUserSelector,
  getSuperParent,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import TableInfo from "../../../../common/components/table/TableInfo";
import Pagination from "../../../../common/components/table/Pagination";
import CustomTooltips from "../../../../common/components/Tooltip";
import { toast } from "react-toastify";
import { colors } from "../../../../constants/colors";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import CoaListHeader from "./components/CoaListHeader";
import { useCustomizedTable } from "../../../../hooks/useCustomizedTable";
import Table from "../../../../common/components/table/Table";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import {
  coaColumnValues,
  coaDefaultValues,
  coaMandatoryValues,
} from "../../../../../helpers/tableCustomizationHelper";
import TableSetup from "../../../../common/components/table/TableSetup";
import EmptySearchList from "../../../../common/components/emptySearchList/EmptySearchList";

function AccountsList() {
  const dispatch = useAppDispatch();
  const accountsData = useAppSelector(accountsSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const superParent = useAppSelector(getSuperParent);
  const accounts = accountsData.list_view;
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [accountName, setAccountName] = useState("");
  const [deleteAccountId, setDeleteAccountId] = useState(-1);
  const [editAccountId, setEditAccountId] = useState(-1);
  const [accountType, setAccountType] = useState("");
  const [nameSort, setNameSort] = useState("ASC");
  const [typeSort, setTypeSort] = useState("");
  const [balanceSort, setBalanceSort] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editAccountName, setEditAccountName] = useState("");
  const [editAccountDescription, setEditAccountDescription] = useState("");
  const [editGlStartCode, setEditGlStartCode] = useState("");
  const [editGlEndCode, setEditGlEndCode] = useState("");
  const [editParentId, setEditParentId] = useState(0);
  const [editCurrencyId, setEditCurrencyId] = useState(0);
  const [parentGlStartCode, setParentGlStartCode] = useState("");
  const [parentGlEndCode, setParentGlEndCode] = useState("");
  const [deleteAccountName, setDeleteAccountName] = useState("");
  // const [glFormValues, setGlFormValues] = useState<AccountsGLCodeRanges[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isTransactionExist, setTransactionExist] = useState(false);
  const [openingBalance, setOpeningBalance] = useState<string | number>(0);
  const [currentBalance, setCurrentBalance] = useState<string | number>(0);
  const [conversionId, setConversionId] = useState<number | null>(0);
  const [totalResults, setTotalResults] = useState(0);
  const [transactionStatus, setTransactionStatus] = useState<ObjectType>({});
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const orgID = useRef(currentUserInfo.organization_id);
  const [isEditGlCode, setIsEditGlCode] = useState(false);
  const [superParentId, setSuperParentId] = useState(0);
  const [isMutable, setIsMutable] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [isBankAccount, setIsBankAccount] = useState(false);

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "Description",
    "Ledger Code",
  ]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let chartOfAccountPermission: string[] = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Chart of Account"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    fetchAccountsList();
  }, [
    page,
    itemsPerPage,
    accountName,
    accountsData.total,
    nameSort,
    typeSort,
    balanceSort,
    currentUserInfo.organization_id,
  ]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    coaColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });

    setHiddenColumns([...hiddenItems]);
  }, [accountsData, selectedColumns]);

  useEffect(() => {
    fetchTableCustomizationFields("COA", coaDefaultValues, coaMandatoryValues);
    fetchAccountsList();
  }, [
    page,
    itemsPerPage,
    nameSort,
    typeSort,
    balanceSort,
    currentUserInfo.organization_id,
    // JSON.stringify(fiscalYearFilter),
    // selectedFilterData,
  ]);

  // useEffect(() => {
  //   //fetch parent account GL code rages
  //   fetchAccountGLRanges();
  // }, [currentUserInfo.organization_id]);

  const resetOnOrgChange = () => {
    setTotalResults(0);
    setAccountName("");
    orgID.current = currentUserInfo.organization_id;
  };
  /**
   * Get COA List View
   */
  const fetchAccountsList = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getCoaListView({
        page,
        itemsPerPage,
        accountName: accountName.trim(),
        nameSort,
        typeSort,
        balanceSort,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      /**
       * To remove suggestion box when search result is displayed.
       */
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        x[i].parentNode?.removeChild(x[i]);
      }
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.list_view.length) {
          let length = response.list_view.length;
          setTotalAccounts(response.total);
          if (accountName !== "") {
            setTotalResults(response.total);
          }
          setStartCount((page - 1) * itemsPerPage + 1);
          setEndCount((page - 1) * itemsPerPage + length);
        } else {
          setTotalAccounts(response.total);
          setTotalResults(response.total);
        }
      } else if ("error" in response) {
      }
    }
  };

  /**
   * Fetch transaction status of each account
   */
  const fetchCOATransactionStatus = async (id: number, isCategory: boolean) => {
    if (currentUserInfo.is_super_parent && !isCategory) {
      const responseAction = await dispatch(
        getTransactionStatusOfAccount({
          transactionId: id,
          orgId: currentUserInfo.organization_id,
          transaction: "account_id",
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setTransactionStatus({ ...response });
        }
      }
    }
  };
  /**
   * Search suggestion API Call
   */
  const searchSuggestions = async (value: string, e: any) => {
    setIsSearch(true);
    const accountSearch = value;
    if (e.keyCode !== 13) {
      if (accountSearch.length > 2) {
        const responseAction = await dispatch(
          accountSuggestions({
            accountName: accountSearch.trim(),
            orgId: orgID.current,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            setTotalResults(response.account_list.length);
            setIsSearch(false);
            return response.account_list;
          }
        }
      } else {
        return [];
      }
    }
  };

  /**
   * Set account ID and Name to be deleted
   */
  const deleteAccountHandler = (
    id: number,
    name: string,
    transactionExist: boolean
  ) => {
    setDeleteAccountId(id);
    setDeleteAccountName(name);
    if (transactionExist) {
      toast.error(
        "Unable to delete the account, as there are transactions associated with it!",
        {
          icon: ({ theme, type }) => (
            <img src={ErrorImg} alt="error-img" width="16" height="14" />
          ),
          toastId: `coa-delete-failed`,
          closeButton: false,
          className: "toast-error",
          position: "top-center",
        }
      );
    }
  };

  /**
   * Set account ID, Account type, Account name, and account description to be updated
   */
  const editAccountHandler = async (
    id: number,
    accountType: string,
    name: string,
    description: string,
    glCodeStart: string,
    giCodeEnd: string,
    accountSuperparentId: number,
    currencyId: number,
    isCategory: boolean,
    isTransactionExist: boolean,
    openingBalance: number | string,
    currentBalance: number | string,
    conversionId: number | null,
    isAccountMutable: boolean,
    accountParentId: number,
    accountClass: string | null
  ) => {
    //fetch parent account GL code rages
    let glFormValues = await fetchAccountGLRanges();
    setEditAccountId(id);
    setAccountType(accountType);
    setEditAccountName(name);
    setEditAccountDescription(description);
    setEditGlStartCode(glCodeStart);
    setEditGlEndCode(giCodeEnd);
    setEditCurrencyId(currencyId);
    setTransactionExist(isTransactionExist);
    setIsCategory(isCategory);
    setOpeningBalance(openingBalance);
    setCurrentBalance(currentBalance);
    setConversionId(conversionId);

    const superParentAccount = glFormValues.filter(
      (glRanges) => glRanges.id === accountSuperparentId
    )[0];
    setParentGlStartCode(superParentAccount?.gl_start_code);
    setParentGlEndCode(superParentAccount?.gl_end_code);
    setSuperParentId(accountSuperparentId);
    setIsMutable(isAccountMutable);
    setParentId(accountParentId);
    accountClass === "BankAccount"
      ? setIsBankAccount(true)
      : setIsBankAccount(false);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    let pageCount = Math.ceil(accountsData.total / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount !== 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, accountsData.total, totalAccounts, page]);

  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalAccounts;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  /**
   * Account type listing handler
   */
  const accountTypeHandler = (type: string) => {
    if (type == null) {
      return "";
    }
    let newType = type;
    let typeArray = newType.split("/");
    if (
      typeArray.length === 0 ||
      typeArray.length === 1 ||
      typeArray.length === 2
    ) {
      return type;
    } else {
      newType = typeArray[0] + " / .. / " + typeArray[typeArray.length - 1];
      return newType;
    }
  };

  /**
   * Account name sort handler
   */
  const accountNameSortHandler = () => {
    if (nameSort === "ASC") {
      setNameSort("DESC");
      setTypeSort("");
      setBalanceSort("");
    } else {
      setNameSort("ASC");
      setTypeSort("");
      setBalanceSort("");
    }
  };

  /**
   * Account type sort handler
   */
  const accountTypeSortHandler = () => {
    if (typeSort === "ASC") {
      setTypeSort("DESC");
      setNameSort("");
      setBalanceSort("");
    } else {
      setTypeSort("ASC");
      setNameSort("");
      setBalanceSort("");
    }
  };

  /**
   * Balance sort handler
   */
  const balanceSortHandler = () => {
    if (balanceSort === "ASC") {
      setBalanceSort("DESC");
      setNameSort("");
      setTypeSort("");
    } else {
      setBalanceSort("ASC");
      setNameSort("");
      setTypeSort("");
    }
  };

  /**
   * Reset Edit account Id while closing modal.
   */
  const resetEditAccountId = () => {
    setEditAccountId(-1);
  };

  /**
   * Reset Delete account ID while closing modal
   */
  const resetDeleteAccountId = () => {
    setDeleteAccountId(-1);
  };

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);

  /* reset search input */
  const resetInput = () => {
    setAccountName("");
    fetchAccountsList();
  };

  const fetchAccountGLRanges = async () => {
    let glFormValues: AccountsGLCodeRanges[] = [];
    const responseAction = await dispatch(
      accountGlRanges(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        // setGlFormValues(response);
        glFormValues = response;
      }
    }
    return glFormValues;
  };

  const coaListAction = () => {
    var leftScroll = 0;
    var scrollItem: any = document.querySelector(".table-container");
    let resizeFlag: boolean = false;
    scrollItem.addEventListener("scroll", function (e: any) {
      setTableScrollLeft(scrollItem.scrollLeft);
    });
    $(".dropdown-ctrl").on("click", function () {
      ($(".dropdown-ctrl") as any).dropdown("hide");
      ($(this) as any).dropdown("show");
    });
    $(".more-btn").on("show.bs.dropdown", function () {
      $(".table-container").css({ overflow: "inherit" });
      $(".table-container table").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
    });
    $(".more-btn").on("hide.bs.dropdown", function () {
      $(".table-container").css({
        "overflow-x": "auto",
        "overflow-y": "hidden",
      });
      $(".table-container table").css("margin-left", 0);
    });
    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-ctrl") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    coaListAction();
  });

  /**
   * function to highlight the searched keyword in the search results screen
   */
  const getAccountName = (name: string) => {
    if (accountName.trim() === "") {
      return name;
    }
    const accountNameParts = name.split(new RegExp(`(${accountName})`, "gi"));
    return (
      <span>
        {accountNameParts.map((part) =>
          part.toLowerCase() === accountName.toLowerCase() ? (
            <b style={{ backgroundColor: colors.blanchedAlmond }}>{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const refresh = async (type: string) => {
    if (type === "submit") {
      setIsLoading(true);
      await dispatch(
        getCoaListView({
          page: page,
          itemsPerPage: itemsPerPage,
          accountName: accountName,
          nameSort: nameSort,
          typeSort: typeSort,
          balanceSort: balanceSort,
          orgId: currentUserInfo.organization_id,
        })
      );
      dispatch(
        getCoaTreeView({ name: "", orgId: currentUserInfo.organization_id })
      );
      dispatch(getCoaTreeViewInAddAccount(currentUserInfo.organization_id));
      setIsLoading(false);
    }
    setIsEditGlCode(false);
  };
  /**
   * Fetch all currency list
   */
  const getCurrencyList = async () => {
    await dispatch(currencyDropdown(currentUserInfo.organization_id));
  };

  const refreshList = async () => {
    await dispatch(
      getCoaListView({
        page: page,
        itemsPerPage: itemsPerPage,
        accountName: accountName,
        nameSort: nameSort,
        typeSort: typeSort,
        balanceSort: balanceSort,
        orgId: currentUserInfo.organization_id,
      })
    );
    dispatch(getCoaTreeViewInAddAccount(currentUserInfo.organization_id));
  };

  const sortHandler = (columnName: string) => {
    switch (columnName) {
      case "Account Name":
        accountNameSortHandler();
        break;
      case "Account Type":
        accountTypeSortHandler();
        break;
      case "Balance":
        balanceSortHandler();
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      Header: "Account Name",
      accessor: "show.account_name",
      sort: true,
      sortOrder: `${
        nameSort === "ASC"
          ? "ascending"
          : nameSort === "DESC"
          ? "descending"
          : ""
      }`,
      className: "item_name",
    },
    {
      Header: "Account Type",
      accessor: "show.account_type",
      sort: true,
      sortOrder: `${
        typeSort === "ASC"
          ? "ascending"
          : typeSort === "DESC"
          ? "descending"
          : ""
      }`,
      className: "account_type",
    },
    {
      Header: "Description",
      accessor: selectedColumns.includes("Description")
        ? "show.description"
        : "Description",
      sort: false,
      sortOrder: "",
      className: "coa_description ellipsis",
    },
    {
      Header: "Ledger Code",
      accessor: selectedColumns.includes("Ledger Code")
        ? "show.ledger_code"
        : "Ledger Code",
      sort: false,
      sortOrder: "",
      className: "ledger_code",
    },
    {
      Header: "Balance",
      accessor: "show.balance",
      sort: true,
      sortOrder: `${
        balanceSort === "ASC"
          ? "ascending"
          : balanceSort === "DESC"
          ? "descending"
          : ""
      }`,
      className: "talign-right balance_amt",
    },
    {
      Header: (
        <TableSetup
          type="COA"
          tableColumns={selectedColumns}
          customizeColumnValues={coaColumnValues}
          mandatoryColumns={coaMandatoryValues}
          refreshList={() => {
            fetchTableCustomizationFields(
              "COA",
              coaDefaultValues,
              coaMandatoryValues
            );
            fetchAccountsList();
          }}
        />
      ),
      accessor: "show.action",
      sort: false,
      sortOrder: "",
      className: "action",
      Cell: ({ cell: { value } }: any) => {
        let account = value.account;
        return (
          <>
            {chartOfAccountPermission.includes("Update") ? (
              account.parent_id === null && superParent ? (
                <KebabMenu>
                  <li onClick={() => setIsEditGlCode(true)}>
                    <a
                      href="#"
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#editLedgerRangeModal"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img src={EditListDataImg} alt="edit-list-img" />
                      <span>Edit Ledger Range</span>
                    </a>
                  </li>
                </KebabMenu>
              ) : superParent ||
                (account.parent_id !== null && !superParent) ? (
                <KebabMenu>
                  <>
                    <li
                      onClick={() =>
                        editAccountHandler(
                          account.id,
                          account.account_type,
                          account.account_name,
                          account.description,
                          account.gl_start_code,
                          account.gl_end_code,
                          account.super_parent_id,
                          account.currency_id,
                          account.is_category,
                          transactionStatus[account.id],
                          account.opening_balance,
                          account.current_balance,
                          account.conversion_id,
                          account.is_mutable,
                          account.parent_id,
                          account.account_class
                        )
                      }
                    >
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#editAccountModal"
                        onClick={(e) => {
                          e.preventDefault();
                          getCurrencyList();
                        }}
                      >
                        <img src={EditListDataImg} alt="edit-img" />
                        <span>Edit</span>
                      </a>
                    </li>
                    {chartOfAccountPermission.includes("Delete") &&
                    superParent ? (
                      <li
                        onClick={() =>
                          deleteAccountHandler(
                            account.id,
                            account.account_name,
                            transactionStatus[account.id]
                          )
                        }
                        style={
                          account.parent_id === null ||
                          account.is_mutable === false
                            ? {
                                pointerEvents: "none",
                                cursor: "default",
                                opacity: "0.5",
                              }
                            : {}
                        }
                      >
                        <a
                          className="dropdown-item delete"
                          href="#"
                          data-id={account.id}
                          data-bs-toggle="modal"
                          data-bs-target={
                            transactionStatus[account.id] ||
                            account.is_mutable === false
                              ? ""
                              : "#deleteMessageModal"
                          }
                          onClick={(e) => e.preventDefault()}
                        >
                          <img src={DeleteImg} alt="delete-img" />
                          <span>Delete</span>
                        </a>
                      </li>
                    ) : null}
                  </>
                </KebabMenu>
              ) : null
            ) : null}
          </>
        );
      },
    },
  ];

  const data = accounts.map((account, index) => {
    return {
      show: {
        account_name: (
          <span className="acc-name">
            <img
              src={account.is_category ? DirectoryIconSmall : ListIconSmall}
              width="16"
              height="16"
              alt="list-item-icon"
            />
            {getAccountName(account.account_name)}
          </span>
        ),
        account_type: accountTypeHandler(account.account_type),
        description: (
          <CustomTooltips
            placement="bottom"
            title={
              account?.description?.length > 60 ? account?.description : ""
            }
          >
            <span className="coa_description ellipsis">
              {account.description}
            </span>
          </CustomTooltips>
        ),
        ledger_code: account.is_category ? "" : account.gl_start_code,
        balance: NumberFormat(
          account.current_balance,
          currentUserInfo.currency_code,
          orgCurrencyList
        ),
        action: {
          account: account,
        },
      },
    };
  });
  return (
    <div className="card card-user-management card-customer card-coa main-card overflowX-hidden h-100">
      <CoaListHeader
        totalResults={totalResults}
        isSearch={isSearch}
        resetInput={resetInput}
        resetOnOrgChange={resetOnOrgChange}
        setAccountName={(value) => setAccountName(value)}
        searchSuggestions={searchSuggestions}
        setPage={(value) => setPage(value)}
      />
      <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
        <div
          className={`card-body ${
            totalAccounts === 0 ? "no-list-item-body" : ""
          }`}
        >
          <div
            id="coa_table_wrapper"
            className="dataTables_wrapper dt-bootstrap5 no-footer"
          >
            {totalAccounts > 0 ? (
              <TableInfo
                total={totalAccounts}
                startCount={startCount}
                endCount={endCount}
                itemsPerPage={itemsPerPage}
                setPageInfo={(countPerPage, page) => {
                  setItemsPerPage(countPerPage);
                  setPage(page);
                }}
              />
            ) : (
              ""
            )}
            <div
              className={`table-container table-responsive add-on-table-container ${
                totalAccounts === 0 ? "no-list-item" : ""
              }`}
            >
              {totalAccounts > 0 ? (
                <Table
                  id={"coa_listing_table"}
                  columns={columns}
                  data={data}
                  tableClassName={"list-table table table-striped w-100"}
                  dataSort={true}
                  isLoading={undefined}
                  haveColumnClassName={true}
                  onSortClick={sortHandler}
                  rowClassName={""}
                  emptyClassName={"text-center dataTables_empty"}
                  hiddenColumns={hiddenColumns}
                  tbodyRowOnClick={() => {}}
                  tbodyColumnClick={() => {}}
                >
                  No data available in table
                </Table>
              ) : (
                <EmptySearchList
                  alt="Empty account list"
                  icon={InvoiceImage}
                  emptyText="No data available in table"
                />
              )}
            </div>
          </div>
          <div className="card-footer clear-both">
            {totalAccounts > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Create
        page={page}
        itemsPerPage={itemsPerPage}
        accountName={accountName}
        nameSort={nameSort}
        typeSort={typeSort}
        balanceSort={balanceSort}
        superParentId={superParentId}
      />
      <EditAccount
        accountIdReset={resetEditAccountId}
        editAccountId={editAccountId}
        page={page}
        itemsPerPage={itemsPerPage}
        accountName={accountName}
        accountType={accountType}
        editAccountName={editAccountName}
        editAccountDescription={editAccountDescription}
        editGlStartCode={editGlStartCode}
        editGlEndCode={editGlEndCode}
        editCurrencyId={editCurrencyId}
        editParentId={editParentId}
        parentGlStartCode={parentGlStartCode}
        parentGlEndCode={parentGlEndCode}
        nameSort={nameSort}
        typeSort={typeSort}
        balanceSort={balanceSort}
        organizationId={currentUserInfo.organization_id}
        isCategory={isCategory}
        isTransactionExist={isTransactionExist}
        isSuperParent={superParent}
        openingBalance={openingBalance}
        currentBalance={currentBalance}
        conversionId={conversionId}
        superParentId={superParentId}
        isMutable={isMutable}
        parentId={parentId}
        isBankAccount={isBankAccount}
        refreshList={refreshList}
      />
      <DeleteAccount
        deleteIdReset={resetDeleteAccountId}
        page={page}
        itemsPerPage={itemsPerPage}
        deleteAccountId={deleteAccountId}
        deleteAccountName={deleteAccountName}
        accountName={accountName}
        nameSort={nameSort}
        typeSort={typeSort}
        balanceSort={balanceSort}
        organizationId={currentUserInfo.organization_id}
      />
      <EditGLCode
        organizationId={currentUserInfo.organization_id}
        refresh={refresh}
        isEditGlCode={isEditGlCode}
      />
    </div>
  );
}

export default AccountsList;
