import { useEffect, useState } from "react";
import useCommonData from "./useCommon";

export const useReportTagSettingsDetail = () => {
  const { currentUserInfo } = useCommonData();
  const [isReportingTagMandatory, setReportingTagMandatory] = useState(
    currentUserInfo.is_tags_mandatory
  );
  const [isReportingTagEnabled, setReportingTagEnabled] = useState(
    currentUserInfo.is_tags_enabled
  );
  useEffect(() => {
    setReportingTagEnabled(currentUserInfo.is_tags_enabled);
    setReportingTagMandatory(currentUserInfo.is_tags_mandatory);
  }, [currentUserInfo.organization_id]);

  return { isReportingTagEnabled, isReportingTagMandatory };
};
