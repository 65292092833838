import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { InventoryItemValues } from "../../../types";
import { CreateItemModal } from "../../home/items/components/createItemFormModal/createItemFormModal";
import {
  getUserEditedItemsList,
  itemDropdown,
  itemDropdownSelector,
} from "../../home/items/itemSlice";
import { usePermissionHandler } from "../../hooks/usePermissionHandler";
import { useSubModulePermission } from "../../hooks/useSubModulePermissions";
import {
  currentUserSelector,
  latestCurrencyRateSelector,
} from "../commonSlice";
import "../styles/CustomItemSelect.css";

type Props = {
  selectedItem: { id?: number | null; name: string };
  currencyId?: number | string;
  currencyCode?: string;
  itemIndex: number;
  error: string;
  wrapperClass?: string;
  handleItemChange?: (item: InventoryItemValues, itemIndex: number) => void;
  handleInputItemChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | {
          target: {
            name: string;
            value: string;
          };
        },
    index: number
  ) => void;
  componentType: string;
  placeholder?: string;
};
function CustomItemSelect(props: Props, ref: any) {
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const userEnteredItemsList = useAppSelector(getUserEditedItemsList);
  const [selectValue, setSelectValue] = useState("");
  const [selectId, setSelectId] = useState<number>();
  const itemDropdownList = useAppSelector(itemDropdownSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [openCreateItemModal, setOpenCreateItemModal] = useState(false);
  const subModulePermission = useSubModulePermission();
  const { itemsRolePermission } = usePermissionHandler();

  useEffect(() => {
    if (props.selectedItem.id) {
      setSelectId(props.selectedItem.id);
    }
    if (props.selectedItem.name) {
      setSelectValue(props.selectedItem.name);
    }
  }, [props.selectedItem]);

  useEffect(() => {
    // if (props.currencyId === currentUserInfo.organization_currency) {
    if (
      (editId && exchangeRateValues.id && props.currencyId) ||
      editId === undefined
    )
      fetchItemDropdown();
    // }
  }, [
    currentUserInfo.organization_id,
    currentUserInfo.organization_currency,
    exchangeRateValues.id,
    props.currencyId,
  ]);

  // useEffect(() => {
  //   if (currentUserInfo.organization_id) {
  //     fetchItemDropdown();
  //   }
  // }, [exchangeRateValues.id]);

  useEffect(() => {
    if (selectId) {
      let selectedItem = itemDropdownList.filter(
        (item: InventoryItemValues) => item.id === selectId
      )[0];
      if (selectedItem && props.handleItemChange)
        props.handleItemChange(selectedItem, props.itemIndex);
    }
  }, [JSON.stringify(itemDropdownList)]);

  const fetchItemDropdown = useCallback(async () => {
    let currencyId = props.currencyId
      ? props.currencyId
      : currentUserInfo.organization_currency;
    let conversionId = exchangeRateValues.id;
    if (currencyId && currentUserInfo.organization_id) {
      await dispatch(
        itemDropdown({
          currencyId: Number(currencyId),
          conversionId: conversionId,
          orgId: currentUserInfo.organization_id,
          transaction_type: [
            "invoice",
            "creditNote",
            "recurringInvoice",
          ].includes(props.componentType)
            ? "SALES"
            : "PURCHASE",
        })
      );
    }
  }, [
    props.currencyId,
    exchangeRateValues.id,
    currentUserInfo.organization_id,
  ]);

  const onCreate = () => {
    setOpenCreateItemModal(true);
  };

  const handleChange = (item: InventoryItemValues) => {
    if (props.handleItemChange) {
      setSelectValue(item.name);
      if (item.id) setSelectId(item.id);
      props.handleItemChange(item, props.itemIndex);
    }
  };

  return (
    <>
      <div
        className={`btn-group accounts-dropdown ${
          props.wrapperClass ? props.wrapperClass : ""
        }`}
      >
        <input
          type="text"
          name="item_name"
          className={`${props.error} search-dropdown dropdown-toggle `}
          placeholder={props.placeholder ? props.placeholder : "Choose item"}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          value={selectValue ? selectValue.toString() : ""}
          onChange={(e) => {
            // if (props.componentType !== "recurringInvoice") {
            setSelectValue(e.target.value);
            props.handleInputItemChange(e, props.itemIndex);
            // }
          }}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
        />
        <ul className="dropdown-menu dropdown-menu-start">
          {itemDropdownList.length ? (
            itemDropdownList.map((item: any, index: number) => {
              return (
                <li
                  onClick={() => {
                    handleChange(item);
                  }}
                  key={index}
                >
                  <a
                    className={
                      selectId === item.id
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="account">{item.name}</span>
                    <span className="account-type">{`${
                      item.hsn_or_sac_code
                        ? `HSN/SAC: ${item.hsn_or_sac_code} |`
                        : ``
                    }  Rate: ${item.currency_code} ${item.rate} `}</span>
                  </a>
                </li>
              );
            })
          ) : (
            <li className="no-result">
              <a
                className="dropdown-item no-result"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No Items found</span>
              </a>
            </li>
          )}
          {itemsRolePermission.includes("Create") ? (
            <li onClick={() => onCreate()}>
              <Link
                className={"dropdown-item add-link-option"}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <CreateItemModal fetchItemDropdown={fetchItemDropdown} />
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
      {/* <CreateItemContainer
        openModal={openCreateItemModal}
        closeModal={() => {
          setOpenCreateItemModal(false);
        }}
        fetchItemDropdown={fetchItemDropdown}
        currency={{ id: Number(props.currencyId), code: props.currencyCode }}
      /> */}
    </>
  );
}

export default React.memo(forwardRef(CustomItemSelect));
