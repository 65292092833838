import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import "../../../../../index.css";
import "./MultipleExchangeRate.css";
import { currentUserSelector } from "../../../commonSlice";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { CurrencyList } from "../../../../../types";
import { useExchangeRate } from "../../../../hooks/useExchangeRate";
import { MultipleExchangeRatePopper } from "./MultipleExchangeRatePopper";

type Props = {
  currencyId: number | undefined; // latest currency of the transaction
  currencyCode: string | undefined;
  editId: string | undefined; // curresponding transaction id, eg: invoice id
  conversionId: string | number; // saved conversion id, only have value in edit section
  conversionRate: string;
  conversionTimestamp: string;
  conversionCurrencyId?: string | number; // currency of already saved conversion
  conversionDate: string; // date of already saved conversion
  newConversionDate: string; // new date for conversion
  module?: string; // to apply style based on module
  toCurrencyId: number | null | undefined;
  toCurrencyCode: string;
  setExchangeData: (values: CurrencyList) => void;
  isCurrencyChanged: boolean;
  isDateChanged: boolean;
  position: "start" | "end";
  byUser?: boolean;
  isExchangeRateEditable?: boolean;
  isReverseConversionRequired: boolean;
  getReverseConversionValue?: (value: CurrencyList) => void;
  isExchangeRateHidden?: boolean;
  exchangeRateSubmitHandler?: (data: any) => void;
  maxLength?: number;
};

const MultiExchangeRate = (props: Props) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [conversionId, setConversionId] = useState<number | null | string>();
  const [byUser, setByUser] = useState(false);
  const { fetchCustomConversions, exchangeRateValues } = useExchangeRate({
    ...props,
    conversionId: conversionId ? conversionId : props.conversionId,
    byUser: byUser,
    isCurrencyChanged: props.isCurrencyChanged,
  });

  useEffect(() => {
    setByUser(props.byUser ? props.byUser : byUser);
  }, [props.byUser, conversionId, byUser]);
  useEffect(() => {
    setConversionId(props.conversionId);
  }, [props.conversionId]);
  useEffect(() => {
    setByUser(false);
  }, [
    props.conversionDate,
    props.newConversionDate,
    props.currencyId,
    props.toCurrencyId,
  ]);
  const getConversionId = (id: number) => {
    if (id) {
      setConversionId(id);
      setByUser(true);
    }
  };
  // useEffect(() => {
  //   props.setExchangeData(exchangeRateValues);
  // }, [exchangeRateValues]);

  return (
    <div
      className="multiple-exchange-rate-wrapper position-relative mt-1"
      key={`${
        Number(props.conversionId) +
        Number(props.currencyId) +
        Number(props.toCurrencyId)
      }`}
    >
      {props.currencyId !== props.toCurrencyId &&
      Number(props.conversionRate) !== 0 &&
      !Number.isNaN(props.conversionRate) &&
      props.currencyCode &&
      props.toCurrencyCode &&
      !["null", "undefined"].includes(props.conversionRate) &&
      Number(props.conversionRate) !== 1 &&
      Number(props.toCurrencyCode) !== Number(props.currencyId) ? (
        props.isExchangeRateEditable !== false ? (
          <div
            className={`multiple-exchange-rate-display-wrapper justify-content-${props.position}`}
          >
            <div
              className={
                props.module === "COA"
                  ? "multiple-exchange-date-rate-wrapper-multiline"
                  : "multiple-exchange-date-rate-wrapper"
              }
            >
              <div className="multiple-exchange-date-wrapper">
                ({props.byUser ? "Edited as on " : "As on "}{" "}
                {DateFormatHandler(
                  props.conversionTimestamp?.split(" ", 1).toString()
                )}{" "}
                )
              </div>
              <div className="fw-bold multiple-exchange-converted-rate-wrapper">
                1 {" " + props.currencyCode} ={" "}
                {props.conversionRate + " " + props.toCurrencyCode}
              </div>
            </div>
            <div className="multiple-exchange-rate-icon-wrapper">
              <MultipleExchangeRatePopper
                exchangeRateValues={exchangeRateValues}
                conversionDate={props.conversionDate}
                conversionRate={props.conversionRate}
                fetchCustomConversions={fetchCustomConversions}
                currencyId={Number(props.currencyId)}
                toCurrencyId={Number(props.toCurrencyId)}
                toCurrencyCode={props.toCurrencyCode}
                module={props.module}
                getConversionId={getConversionId}
                isReverseConversionRequired={props.isReverseConversionRequired}
                getReverseConversionValue={props.getReverseConversionValue}
                exchangeRateSubmitHandler={props.exchangeRateSubmitHandler}
                maxLength={props.maxLength}
              />
            </div>
          </div>
        ) : props.isExchangeRateHidden === true ? (
          <></>
        ) : (
          <span className="sub-label">
            1 {" " + props.currencyCode} ={" "}
            {props.conversionRate + " " + props.toCurrencyCode}
          </span>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default MultiExchangeRate;
