import React, { useState, useEffect } from "react";
import "../../../../index.css";
import "./ToggleButton.css";

type Props = {
  lButtonLabel: string;
  rButtonLabel: string;
  lButtonValue: string;
  rButtonValue: string;
  selected?: string;
  onChoose?: (e: string) => void;
  width?: string;
  height?: string;
  wrapperClass?: string;
};

const ToggleButton = (props: Props) => {
  const [selectedOption, setSelectedOption] = useState(props.lButtonValue);
  const [activeClass, setActiveClass] = useState("");

  const handleToggle = (selected: string) => {
    props.onChoose && props.onChoose(selected);
    setSelectedOption(selected);
    setActiveClass(selected);
  };

  useEffect(() => {
    setSelectedOption(props.selected ? props.selected : props.lButtonValue);
    setActiveClass(props.selected ? props.selected : props.lButtonValue);
  }, [props.selected]);
  return (
    <>
      <div
        className={`toggle-btn-wrapper d-flex ${
          props.wrapperClass ? props.wrapperClass : ""
        }`}
        style={{
          width: props.width ? props.width : "82px",
          height: props.height ? props.height : "20px"
        }}
      >
        <button
          className={`left-btn no-appearance ${props.lButtonValue} ${
            activeClass === props.lButtonValue ? "active" : ""
          }`}
          onClick={(e: any) => {
            handleToggle(props.lButtonValue);
          }}
        >
          {props.lButtonLabel}
        </button>
        <button
          className={`right-btn no-appearance ${props.rButtonValue} ${
            activeClass === props.rButtonValue ? "active" : ""
          }`}
          onClick={(e) => {
            handleToggle(props.rButtonValue);
          }}
        >
          {props.rButtonLabel}
        </button>
      </div>
    </>
  );
};

export default ToggleButton;
