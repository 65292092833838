import {
  CreditApplyToBill,
  PurchaseOrderValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewPurchaseOrder,
  getPurchaseOrderNumber,
  deletePurchaseOrderItemTax,
  sendPurchaseOrder,
  calculateTaxableAmount,
  calculateAmount,
  billPdfLink,
  deletePurchaseOrderItem,
  getPurchaseOrderList,
  editPurchaseOrder,
  purchaseOrderNumberExist,
  getPurchaseOrderDetails,
  billAttachments,
  billAttachmentsDelete,
  deletePurchaseOrderRecipient,
  transactionLimitStatus,
  applyCreditsToPurchaseOrders,
  creditsCalculation,
  applyCreditList,
  writeOffCreate,
  writeOffUpdate,
  writeOffDetails,
  writeOffDelete,
  getReportingTagJournals,
  putReportingTags,
  getTagsOptionsAssociatedToPurchaseOrders,
  billVoidMarker,
  purchaseOrderStatusChange,
  removePurchaseOrder,
} from "./purchaseOrderAPI";

export const purchaseOrderList = createAsyncThunk(
  "purchaseOrderList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    vendorList: number[];
    orgIds: number[];
    statusList: string[];
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getPurchaseOrderList(data);
  }
);

export const deletePurchaseOrder = createAsyncThunk(
  "deleteInvoice",
  async (data: { deletePurchaseOrderId: number; orgId: number }) => {
    return await removePurchaseOrder(data);
  }
);

export const createPurchaseOrder = createAsyncThunk(
  "create/purchaseOrder",
  async (data: {
    values: PurchaseOrderValues;
    status: string;
    orgId: number;
  }) => {
    return await createNewPurchaseOrder(data);
  }
);

export const editPurchaseOrderDetails = createAsyncThunk(
  "edit/purchaseOrder",
  async (data: {
    id: number;
    editPurchaseOrderInputs: PurchaseOrderValues;
    status: string;
    orgId: number;
  }) => {
    return await editPurchaseOrder(data);
  }
);
export const changePurchaseOrderStatus = createAsyncThunk(
  "PO/status-change",
  async (data: {
    purchaseOrderId: number;
    orgId: number;
    status: "Draft" | "Open" | "Billed" | "Cancelled";
  }) => {
    return await purchaseOrderStatusChange(data);
  }
);

export const deletePurchaseOrderItemDetails = createAsyncThunk(
  "delete/purchaseOrder/item",
  async (data: { purchaseOrderId: number; itemId: number; orgId: number }) => {
    return await deletePurchaseOrderItem(data);
  }
);

export const deletePurchaseOrderRecipientDetails = createAsyncThunk(
  "purchaseOrder/recipient/delete",
  async (data: {
    purchaseOrderId: number;
    emailIds: number[];
    orgId: number;
  }) => {
    return await deletePurchaseOrderRecipient(data);
  }
);

export const purchaseOrderDetails = createAsyncThunk(
  "purchaseOrderDetail",
  async (data: { purchaseOrderId: number; orgId: number }) => {
    return await getPurchaseOrderDetails(data);
  }
);

export const getNewPurchaseOrderNumber = createAsyncThunk(
  "purchaseOrder/number",
  async (orgId: number) => {
    return await getPurchaseOrderNumber(orgId);
  }
);

export const checkPurchaseOrderNumber = createAsyncThunk(
  "purchaseOrder/number-exist",
  async (data: {
    purchaseOrderNumber: string;
    orgId: number;
    purchaseOrderId: number;
  }) => {
    return await purchaseOrderNumberExist(data);
  }
);

export const deletePurchaseOrderItemTaxDetails = createAsyncThunk(
  "delete/invoice/item/tax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deletePurchaseOrderItemTax(data);
  }
);

export const sendPurchaseOrderData = createAsyncThunk(
  "send-bill",
  async (data: {
    purchaseOrderId: number;
    emails: string[];
    orgId: number;
  }) => {
    return await sendPurchaseOrder(data);
  }
);

export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateTaxableAmount(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const getPurchaseOrderPdfLink = createAsyncThunk(
  "bill/pdf",
  async (data: { purchaseOrderId: number; orgId: number }) => {
    return await billPdfLink(data);
  }
);

export const attachPurchaseOrderFiles = createAsyncThunk(
  "bill/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await billAttachments(data);
  }
);

export const deletePurchaseOrderFile = createAsyncThunk(
  "bill/file/delete",
  async (data: { purchaseOrderId: number; fileId: number; orgId: number }) => {
    return await billAttachmentsDelete(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "bill/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const creditList = createAsyncThunk(
  "bill/apply-credit/list",
  async (data: { purchaseOrderId: number; orgId: number }) => {
    return await applyCreditList(data);
  }
);
export const applyCreditsCalculation = createAsyncThunk(
  "bill/apply-credit/calculate",
  async (data: {
    orgId: number;
    values: {
      purchase_order_balance: number;
      allocation_list: number[];
      currency_id: number | null;
    };
    signal?: AbortSignal;
  }) => {
    return await creditsCalculation(data);
  }
);
export const applyCredits = createAsyncThunk(
  "bill/apply-credit",
  async (data: {
    purchaseOrderId: number;
    orgId: number;
    values: CreditApplyToBill[];
  }) => {
    return await applyCreditsToPurchaseOrders(data);
  }
);
export const createWriteOff = createAsyncThunk(
  "bill/write-off/create",
  async (data: {
    orgId: number;
    write_off_values: {
      amount: string | number;
      account_id: number | null;
      purchase_order_id: number | null;
      is_partial: boolean;
    };
  }) => {
    return await writeOffCreate(data);
  }
);
export const updateWriteOff = createAsyncThunk(
  "bill/write-off/edit",
  async (data: {
    orgId: number;
    write_off_id: number | null;
    write_off_values: {
      amount: string | number;
      is_partial: boolean;
    };
  }) => {
    return await writeOffUpdate(data);
  }
);
export const getWriteOffDetails = createAsyncThunk(
  "bill/write-off/details",
  async (data: { orgId: number; purchaseOrderId: number | null }) => {
    return await writeOffDetails(data);
  }
);
export const deleteWriteOff = createAsyncThunk(
  "bill/write-off/delete",
  async (data: { orgId: number; write_off_id: number | null }) => {
    return await writeOffDelete(data);
  }
);

export const generateJournal = createAsyncThunk(
  "reporting-tag/journal-generate",
  async (data: { purchaseOrderId: number; orgId: number }) => {
    return await getReportingTagJournals(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { purchaseOrderId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);

export const fetchTagOptionForPurchaseOrders = createAsyncThunk(
  "reporting-tag/tag-options-for-bills",
  async (data: { purchaseOrderIds: number[]; orgId: number }) => {
    return await getTagsOptionsAssociatedToPurchaseOrders(data);
  }
);
export const markPurchaseOrderVoid = createAsyncThunk(
  "bill/mark-void",
  async (data: { purchaseOrderId: number | null; orgId: number }) => {
    return await billVoidMarker(data);
  }
);

const initialState = {
  gstTaxList: [],
};

export const purchaseOrderSlice = createSlice({
  name: "purchaseOrderManagement",
  initialState,
  reducers: {},
});

export default purchaseOrderSlice.reducer;
