export const initialPermissionValues = {
  permission_id: 0,
  name: "",
  selected: false,
};
const initialSubModuleValues = {
  submodule_id: 0,
  name: "",
  permissions: [initialPermissionValues],
};
export const initialModules = {
  module_id: 0,
  name: "",
  sub_modules: [initialSubModuleValues],
};
export const initialRoleValues = {
  name: "",
  description: "",
  permission_list: [initialModules],
  grant_full_access: false,
  is_changed: false,
};
export const initialModuleStatus = {
  contactsStatus: "no-access",
  salesStatus: "no-access",
  purchasesStatus: "no-access",
  accountsStatus: "no-access",
  reportsStatus: "no-access",
  usersAndRolesStatus: "no-access",
  additionalSettingsStatus: "no-access",
  subscriptionStatus: "no-access",
  itemStatus: "no-access",
};
