import React from "react";
import TableInfo from "../../../../../../common/components/table/TableInfo";
import TableContainer from "../../../../../../common/components/table/TableContainer";
import { TableData } from "../../../../../../../types";
type Props = {
  totalList: number;
  startCount: number;
  endCount: number;
  itemsPerPage: number;
  columns: any[];
  data: TableData[];
  sortHandler: (e: string) => void;
  setPageInfo: (countPerPage: number, page: number) => void;
};
const UserTable = ({
  totalList,
  startCount,
  endCount,
  itemsPerPage,
  columns,
  data,
  sortHandler,
  setPageInfo,
}: Props) => {
  return (
    <div className="card-body">
      <div
        id="user_table_wrapper"
        className="dataTables_wrapper dt-bootstrap5 no-footer"
      >
        <TableInfo
          total={totalList}
          startCount={startCount}
          endCount={endCount}
          itemsPerPage={itemsPerPage}
          setPageInfo={setPageInfo}
        />
        <TableContainer
          className="list-table table table-striped w-100"
          columns={columns}
          data={data}
          url={"/users"}
          sortHandler={sortHandler}
          editPermission={false}
          isDetailViewExist={false}
          id="user_table"
        >
          <></>
        </TableContainer>
      </div>
    </div>
  );
};

export default UserTable;
