import { FormikProps } from "formik";
import { Container } from "../../../../../../../common/components/htmlTags/Container";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../../../../hooks/useCommon";
import { joinTransactionNumber } from "../../../../../../../../helpers/prefixHelper";
import { DateFormatHandler } from "../../../../../../../../helpers/helper";
import InputPrefix from "../../../../../../../common/components/formPrefixInputField/InputPrefix";
import { decimalPlaceOfCurrency } from "../../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../../helpers/roundOffHelper";
import { getForexGainOrLossAmount } from "../../../../../../../../helpers/transactionHelper";
import { useApplyToInvoice } from "../../hooks/useApplyToInvoice";
import { useEffect } from "react";
import { ObjectType } from "../../../../../../../../types";

type ApplyToInvoiceItemProps = {
  index: number;
  invoiceItem: {
    allocation_id: number | null;
    forex_amount: number;
    invoice_id: number;
    amount_credited: number;
    conversion_id: number;
    invoice_number: string;
    invoice_date: string;
    invoice_amount: number;
    invoice_balance: number;
    focus: boolean;
  };
  currencyCode: string;
  formik: FormikProps<any>;
  errors: any;
  conversionId: number;
};
export const ApplyToInvoiceItem = (props: ApplyToInvoiceItemProps) => {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const { getInvoiceForex } = useApplyToInvoice();
  const handleOnBlur = (name: string, amount: number, index: number) => {
    const decimals = decimalPlaceOfCurrency(
      props.currencyCode,
      orgCurrencyList
    );
    const roundedAmount = getRoundOffAmount(amount, decimals);
    props.formik.setFieldValue(name, roundedAmount);
  };

  return (
    <tr>
      <td>{joinTransactionNumber(props.invoiceItem.invoice_number)}</td>
      <td>{DateFormatHandler(props.invoiceItem.invoice_date)}</td>
      <td>{`${props.currencyCode} ${NumberFormat(
        Number(props.invoiceItem.invoice_amount),
        props.currencyCode,
        orgCurrencyList
      )}`}</td>
      <td>{`${props.currencyCode} ${NumberFormat(
        Number(props.invoiceItem.invoice_balance),
        props.currencyCode,
        orgCurrencyList
      )}`}</td>
      <td>
        <InputPrefix
          prefix={props.currencyCode}
          isAmountField={true}
          className={`mw-125 `}
          name={`invoice_allocate_list.${props.index}.amount_credited`}
          id={"amount_credited" + props.index}
          inputType="text"
          value={
            props.invoiceItem.amount_credited === 0
              ? ""
              : props.invoiceItem.amount_credited
          }
          placeholder={zeroDisplayFormat(
            decimalPlaceOfCurrency(
              props.currencyCode
                ? props.currencyCode
                : currentUserInfo.currency_code,
              orgCurrencyList
            )
          ).toString()}
          autoFocus={props.invoiceItem.focus}
          disabled={false}
          error={
            props?.errors &&
            props?.errors[props.index] &&
            props.errors[props.index]?.amount_credited
          }
          onChange={(e: any) => {
            // Restricting alphabets in amount field
            const regex = /^\d*\.?\d*$/;
            let valueStr = e.target.value.toString();
            getInvoiceForex(
              props.invoiceItem.conversion_id,
              props.conversionId,
              valueStr,
              props.invoiceItem.invoice_id,
              props.index,
              props.formik
            );
            if (!regex.test(valueStr) || valueStr === ".") {
              return;
            }
            props.formik.handleChange(e);
          }}
          onBlur={() => {
            setTimeout(() => {
              handleOnBlur(
                `invoice_allocate_list.${props.index}.amount_credited`,
                props.invoiceItem.amount_credited,
                props.index
              );
            }, 200);
          }}
        />
      </td>
      <td>
        {Number(props.invoiceItem.amount_credited) > 0 &&
          getForexGainOrLossAmount(
            props.invoiceItem.invoice_id,
            props.invoiceItem.forex_amount,
            props.currencyCode,
            orgCurrencyList
          )}
      </td>
    </tr>
  );
};
