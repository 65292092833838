import { ObjectType, createUserValues } from "../../../../../../types";

export const ValidateUserForm = (
  values: createUserValues,
  isEmailExist?: boolean
) => {
  const errors: ObjectType = {};
  const regex = /^(?:\w+[-.])*\w{1,64}@(?:\w+[-.])*\w+\.\w{2,3}$/i;
  if (!values.email) {
    errors.email = "Please enter email address";
  } else if (!regex.test(values.email)) {
    errors.email = "Please enter a valid email address";
  } else if (isEmailExist) {
    errors.email = "Email already exists! Please choose a different.";
  }
  if (!values.name?.trim()) {
    errors.name = "Please enter name";
  }

  if(values.organization_role_mappings.length > 0) {
    let orgRoleFlag = false;
    values.organization_role_mappings.map((items, index) => {
      if (
        items.organization_id.toString() !== '' &&
        items.role_id !== 0
      ) {
        orgRoleFlag = true;
      }
    });
    if(orgRoleFlag === false) {
      errors.org_role = "Please enter organization and role";
    }
  } else {
      errors.org_role = "Please enter organization and role";
  }
  /**
   * validation to find duplicates in org and role array of objects
   */
  let orgRoleArr = values.organization_role_mappings.map(
    (item) => item.organization_id.toString() + item.role_id.toString()
  );

  let duplicates = [];
  for (let i = 0; i < orgRoleArr.length; i++) {
    let index = orgRoleArr.indexOf(orgRoleArr[i], i + 1);
    if (index !== -1) {
      duplicates.push(i);
      duplicates.push(index);
    }
  }

  if (!errors.org_role) {
    errors.org_role = [];
  }

  duplicates.map((item) => {
    if (errors.org_role[item] === undefined) {
      errors.org_role[item] =
        "Please enter a different role for the same organization";
    }
  });

  if (errors.org_role?.length === 0) {
    delete errors.org_role;
  }
  return errors;
};
