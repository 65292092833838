import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { CreditNoteDetailValues } from "../../../../../types";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import { tdsApplyLevel } from "../../../../constants/constants";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { amountInWordsConfigSelector } from "../../../../common/commonSlice";
import { useAppSelector } from "../../../../../app/hooks";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import AuthorizedSignature from "../../../../common/components/AuthorizedSignature";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import AddressSection from "../../../../common/components/detailsPage/AddressSection";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import Subject from "../../../../common/components/detailsPage/Subject";
import FileList from "../../../../common/components/FileList";
import JournelView from "../../../../common/components/JournelView";
import useGst from "../../../../hooks/useGst";
import CNAllocationDetails from "../creditNoteAllocationDetails/CNAllocationDetails";
import useCommonData from "../../../../hooks/useCommon";
import SubscriptionDeatilsTableContainer from "./SubscriptionDetailsTableContainer";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";

type Props = {
  creditNoteData: CreditNoteDetailValues;
  refreshCNDetails: () => void;
};

export const CreditNoteDetailBody = forwardRef(
  ({ creditNoteData, refreshCNDetails }: Props, ref) => {
    let taxTypes = Object.entries(creditNoteData.total_details.taxes);
    const { currentUserInfo, orgCurrencyList } = useCommonData();
    const { isGstOrg } = useGst();
    const allocationRef = useRef<{ fetchCreditAllocation: () => void }>(null);
    const journalRef = useRef<{ journalDetail: () => Promise<void> }>(null);
    const [reRenderFlag, setReRenderFlag] = useState<number>(0);
    const creditNoteId = creditNoteData.id;
    const amountInWordsConfig = useAppSelector(amountInWordsConfigSelector);
    const isAuditLogEnabled = process.env.REACT_APP_AUDIT_LOG_ENABLED;

    useImperativeHandle(
      ref,
      () => ({
        refreshDetails: refreshDetails,
      }),
      []
    );

    useImperativeHandle(
      ref,
      () => ({
        subscription_id: creditNoteData.subscription_id,
      }),
      [creditNoteData]
    );
    /**
     * set table column headings
     */
    const columns = useMemo(
      () => [
        {
          Header: "DESCRIPTION",
          accessor: "show.description",
          className: "description",
          Cell: ({ cell: { value } }: any) => {
            return <span className="item-bold">{value}</span>;
          },
        },
        {
          Header: "Account",
          accessor: "show.account",
          className: "account",
        },
        {
          Header: "QUANTITY",
          accessor: "show.quantity",
          className: "quantity text-nowrap",
        },
        {
          Header: "RATE",
          accessor: "show.rate",
          className: "rate text-nowrap",
        },
        {
          Header: "DISCOUNT",
          accessor: "show.discount",
          className: "discount text-nowrap",
          Cell: ({ cell: { value } }: any) => {
            let discAccount = value?.discountAccount
              ? value.discountAccount.split(" (")
              : null;
            return (
              <>
                <span className="amt">{value.discount}</span>
                <span className="acnt">
                  {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                  <br />{" "}
                  {discAccount && discAccount.length === 2
                    ? "(" + discAccount[1]
                    : ""}
                </span>
              </>
            );
          },
        },
        {
          Header: "TAX",
          accessor: "show.tax",
          className: "tax text-nowrap text-align-left",
          Cell: ({ cell: { value } }: any) => {
            return (
              <TaxColumn
                values={value}
                currencyCode={creditNoteData.currency_code}
              />
            );
          },
        },
        {
          Header: "TDS",
          accessor:
            creditNoteData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
              ? "show.item"
              : "TDS",
          className: "tds text-nowrap text-align-left",
          Cell: ({ cell: { value } }: any) => {
            return (
              <>
                <span className="amt">
                  {creditNoteData.currency_code}{" "}
                  {NumberFormat(
                    Number(value.tds_total),
                    creditNoteData.currency_code,
                    orgCurrencyList
                  )}
                </span>
                <span className="actual-value d-block w-100 clear-both">
                  {value.tds_name}{" "}
                  {value.default_tds_percentage
                    ? "(" + value.default_tds_percentage + "%)"
                    : ""}
                </span>

                {value.tds_percentage !== 0 &&
                value.tds_percentage !== value.default_tds_percentage ? (
                  <span className="current-value  d-block w-100 clear-both">
                    Updated Rate {`(${value.tds_percentage}%)`}
                  </span>
                ) : null}
              </>
            );
          },
        },
        {
          Header: "TAXABLE AMOUNT",
          accessor: "show.taxableAmount",
          className: "taxableAmount text-nowrap text-align-right",
        },
      ],
      [creditNoteData.currency_code]
    );

    /**
     * set table row data
     */
    const data = creditNoteData.item_details
      ? creditNoteData.item_details.map((itemData, index) => ({
          show: {
            description: itemData.item_name,
            account: itemData.account_name,
            quantity: NumberFormat(
              Number(itemData.quantity),
              creditNoteData.currency_code,
              orgCurrencyList
            ),
            rate:
              creditNoteData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.rate),
                creditNoteData.currency_code,
                orgCurrencyList
              ) +
              " / Unit",
            discount: {
              discount:
                creditNoteData.currency_code +
                " " +
                NumberFormat(
                  Number(itemData.discount.discount_value),
                  creditNoteData.currency_code,
                  orgCurrencyList
                ),
              discountAccount: itemData.discount_account_name,
            },
            tax:
              itemData.gst_tax?.tax_id !== null
                ? itemData.gst_tax
                : itemData.taxes,
            taxableAmount:
              creditNoteData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.taxable_amount),
                creditNoteData.currency_code,
                orgCurrencyList
              ),
            item: itemData,
          },
        }))
      : [];

    const refreshDetails = () => {
      allocationRef.current?.fetchCreditAllocation();
      journalRef.current?.journalDetail();
      setReRenderFlag((prevData) => prevData + 1);
    };

    return (
      <>
        <div id="invoice" className="cn-preview transaction-preview">
          {/* hiding the Refund option from CN details header as per ticket: https://app.clickup.com/t/85zrmgcae */}

          {/* <button className="btn cancel-button refund-button">Refund</button> */}
          <CNAllocationDetails
            creditNoteData={creditNoteData}
            refreshCNDetails={refreshCNDetails}
            ref={allocationRef}
          />
          <div className="invoice-header d-flex justify-content-between">
            <CompanyDetails
              organizationData={creditNoteData.organization_details}
            />
            <div className="invoice-details">
              <div className="label-invoice">Credit Note</div>
              <div className="invoice-number-label">Credit Note #</div>
              <div className="invoice-number">
                {joinTransactionNumber(creditNoteData.credit_note_number) || ""}
              </div>
              {creditNoteData.reference_number ? (
                <>
                  <div className="invoice-number-label">Reference Number</div>
                  <div className="invoice-number">
                    {creditNoteData.reference_number}
                  </div>
                </>
              ) : null}

              <div className="invoice-number-label">Credit Note Balance</div>
              <div className="invoice-number mb-0">
                {creditNoteData.currency_code}{" "}
                {creditNoteData.credit_note_balance
                  ? NumberFormat(
                      Number(creditNoteData.credit_note_balance),
                      creditNoteData.currency_code,
                      orgCurrencyList
                    )
                  : zeroDisplayFormat(
                      decimalPlaceOfCurrency(
                        creditNoteData.currency_code
                          ? creditNoteData.currency_code
                          : currentUserInfo.currency_code,
                        orgCurrencyList
                      )
                    )}
              </div>
            </div>
          </div>
          <div className="bill-details-section d-flex justify-content-start">
            <div className="billed-to">
              <span className="bill-details-header">Billed To</span>
              <div className="company-address pt-3">
                <span id="address-name">{creditNoteData.customer_name}</span>
                {creditNoteData.address ? (
                  <AddressSection addressData={creditNoteData.address} />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="inv-date-wrap">
              <div className="inv-date m-0">
                <span className="bill-details-header">Credit Date</span>
                <div>{DateFormatHandler(creditNoteData.credit_note_date)}</div>
              </div>
            </div>
            <div className="inv-date-wrap">
              <div className="inv-date m-0">
                <span className="bill-details-header">Reason</span>
                <div>{creditNoteData.reason}</div>
              </div>
            </div>
            {creditNoteData?.place_of_supply !== null && (
              <div className="cn-supply-wrap">
                <div className="cn-supply m-0">
                  <span className="cn-details-header">Place of supply</span>
                  <div>{creditNoteData.place_of_supply}</div>
                </div>
              </div>
            )}
          </div>
          <RowWiseFieldView
            mapObject={creditNoteData.custom_fields}
            module="Credit Notes"
          />
          {creditNoteData.subject ? (
            <Subject label="Subject" subject={creditNoteData.subject} />
          ) : null}

          <div className="table-section">
            <div className="table-wrap">
              {creditNoteData.subscription_id &&
              creditNoteData.subscription_id !== null ? (
                <SubscriptionDeatilsTableContainer
                  creditNoteData={creditNoteData}
                />
              ) : (
                <DetailsTableContainer
                  columns={columns}
                  data={data}
                  haveColumnClassName={true}
                  className="table cnote-preview-table"
                  hiddenColumns={["TDS"]}
                />
              )}
            </div>
            <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
              <div className="thankyou-note w-50 d-flex flex-column multi-items-container">
                {creditNoteData.customer_notes && (
                  <div className="note-container mt-auto">
                    <span className="label">Customer Notes</span>
                    <p>{creditNoteData.customer_notes}</p>
                  </div>
                )}
                <AuthorizedSignature />
              </div>

              <DetailTotalSection
                currencyCode={creditNoteData.currency_code}
                totalTax={creditNoteData.total_details.tax_amount}
                taxTypes={taxTypes}
                subTotal={creditNoteData.total_details.total_taxable_amount}
                total={creditNoteData.total_details.total}
                totalInWords={creditNoteData.total_details.amount_in_words}
                balanceLabel="Credit Note Balance"
                balanceAmount={
                  creditNoteData.credit_note_balance
                    ? creditNoteData.credit_note_balance
                    : "0.00"
                }
                tdsItems={{
                  tdsAmount: creditNoteData.total_details.transaction_tds_amount
                    ? creditNoteData.total_details.transaction_tds_amount
                    : 0,
                  tdsName: creditNoteData.tds_name,
                  tdsPercentage: creditNoteData.tds_percentage,
                  defaultTdsPercentage: creditNoteData.default_tds_percentage,
                }}
                tcsItems={{
                  tcsAmount: creditNoteData.tcs_total,
                  tcsName: creditNoteData.tcs_name,
                  tcsPercentage: creditNoteData.tcs_percentage,
                }}
                roundOffValue={creditNoteData.total_details.roundoff_amount}
                roundoffEnabled={creditNoteData.roundoff_enabled}
                isAmountInWordsEnabled={creditNoteData.is_words_enabled}
                coupon_amount={creditNoteData.total_details?.coupon_amount}
                couponRequired={
                  creditNoteData.total_details?.coupon_amount ? true : false
                }
              >
                <div className="credits-used pb-4">
                  <div>Credits Used</div>
                  <div>
                    (-){" "}
                    {creditNoteData.total_details.credits_used
                      ? NumberFormat(
                          Number(creditNoteData.total_details.credits_used),
                          creditNoteData.currency_code,
                          orgCurrencyList
                        )
                      : zeroDisplayFormat(
                          decimalPlaceOfCurrency(
                            creditNoteData.currency_code
                              ? creditNoteData.currency_code
                              : currentUserInfo.currency_code,
                            orgCurrencyList
                          )
                        )}
                  </div>
                </div>
              </DetailTotalSection>
            </div>
          </div>
          {creditNoteData.credit_note_files.length ? (
            <FileList files={creditNoteData.credit_note_files} />
          ) : null}
          <JournelView
            title="Journal - Credit Note"
            transactionType="Credit Notes"
            transactionId={Number(creditNoteId)}
            ref={journalRef}
            reRenderFlag={reRenderFlag}
            isVoid={creditNoteData.credit_note_status === "Void" ? true : false}
          />
          {isAuditLogEnabled === "true" && (
            <AuditTrail
              title="Audit Trail"
              transactionType="credit_notes"
              transactionId={Number(creditNoteId)}
            />
          )}
        </div>
      </>
    );
  }
);
