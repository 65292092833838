import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import DeleteModal from "../../../common/components/DeleteModal";
import { removeItem } from "../itemSlice";

type Props = {
  deleteId: number;
  refresh: (action: string) => void;
  organizationId: number;
};
const DeleteItem = (props: Props) => {
  const dispatch = useAppDispatch();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      removeItem({
        id: props.deleteId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response)
      ) {
        props.refresh("delete");
        $(".close-btn").click();
        toast.success("Item deleted successfully!", {
          toastId: "item-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete Item!"
      subHeading="Are you sure you want to delete this item"
      transactionName={""}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteItem;
