
export const CustomCreditsDropdown = (unusedCreditsDropdown: {label: string, value: string, info?: string}[]) => {

  return (
    unusedCreditsDropdown.map((dropdownData) => (        {
        label: (
          <div className="configure-coupon-options">
            <div>{dropdownData.label}</div>
            {dropdownData.info ? <div className="grey-color pt-2 credit-custom-dropdown-info">
              {dropdownData.info}
            </div>: null}
          </div>
        ),
        value: dropdownData.value,
      }))
  );
};
